import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MentionsInput, Mention } from "react-mentions";

const EditCommentMentionInput = (props) => {
  const { value, onChange, usersList } = props;

  const inputRef = useRef(null);

  const handleManualUpdate = useCallback(
    (event, newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <CommentInputWrapper>
      <MentionsInput
        inputRef={inputRef}
        value={value}
        className="commentMention browser-default common-input"
        onChange={handleManualUpdate}
        allowSpaceInQuery
        singleLine={false}
        placeholder="Comment here..."
        style={{
          control: {
            overflowY: "hidden",
          },
          input: {
            lineHeight: "1",
            paddingLeft: "12px",
            paddingTop: "12px",
            paddingBottom: "14px",
            outline: "none",
            overflow: "auto",
            height: "84px",
            boxSizing: "border-box",
          },
          highlighter: {
            lineHeight: "1",
            overflow: "hidden",
            paddingLeft: "0",
            paddingTop: "12px",
            paddingBottom: "12px",
            height: "84px",
            boxSizing: "border-box",
          },
        }}
      >
        <Mention
          trigger="@"
          markup="@[[__display__]]"
          style={{ color: "#519acc", backgroundColor: "#f5f5f5" }}
          displayTransform={(id, display) => `@${display}`}
          data={usersList}
          appendSpaceOnAdd
          renderSuggestion={(suggestion, search, highlightedDisplay) => {
            const user = usersList.find((u) => u.value === suggestion.id);
            return (
              <div className="user">
                {user ? user.label : highlightedDisplay}
              </div>
            );
          }}
        />
      </MentionsInput>
    </CommentInputWrapper>
  );
};

const CommentInputWrapper = styled.div`
  height: 84px;
  /*
     * mentions alignment is off, this is a fix.
     * see: https://stackoverflow.com/questions/69439961/material-uis-cssbaseline-breaking-react-mentions
     */

  * {
    line-height: 1;
    box-sizing: border-box;
  }

  .commentMention {
    height: 84px;
  }

  .commentMention__highlighter {
    strong {
      line-height: 1;
    }
  }
`;

EditCommentMentionInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  usersList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
};

export default EditCommentMentionInput;
