import React from "react";
import PropTypes from "prop-types";

const FolderIcon = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyles}
    >
      <path
        d="M1.15654 14.0002V14V2C1.15654 1.50045 1.32411 1.0791 1.66146 0.724946C1.99853 0.371091 2.39506 0.199407 2.86141 0.200002H2.86167H7.79117C8.01811 0.200002 8.23326 0.244501 8.43837 0.333481C8.64406 0.422709 8.82409 0.548745 8.97968 0.712683L8.97993 0.712946L10.3373 2.13795L10.3964 2.2H10.4821H18.1026C18.5698 2.2 18.9667 2.37209 19.3038 2.72595C19.5951 3.03179 19.7596 3.38745 19.7986 3.8H9.78199L7.93598 1.86206L7.87687 1.8H7.79117H2.86167H2.66167V2V14L3.05395 14.055L4.9349 7.48125C4.93496 7.48106 4.93501 7.48087 4.93507 7.48067C5.05075 7.0864 5.26128 6.77768 5.56669 6.54646C5.87499 6.31304 6.20971 6.19941 6.57633 6.2H6.57665H18.8647C19.4489 6.2 19.8999 6.43792 20.2365 6.92647C20.5762 7.41967 20.665 7.94568 20.5058 8.52086C20.5058 8.52111 20.5057 8.52136 20.5056 8.52161L18.7918 14.5188C18.7918 14.519 18.7917 14.5192 18.7916 14.5194C18.676 14.9136 18.4654 15.2223 18.16 15.4535C17.8517 15.687 17.517 15.8006 17.1504 15.8H17.1501H2.86167C2.39451 15.8 1.99759 15.6279 1.66051 15.2741C1.32317 14.9199 1.15597 14.499 1.15654 14.0002ZM4.66974 13.945L4.59688 14.2H4.86204H17.1501H17.3009L17.3424 14.055L19.057 8.05496L19.1298 7.8H18.8647H6.57665H6.4258L6.38435 7.94505L4.66974 13.945Z"
        fill={color}
        stroke={color}
        strokeWidth="0.4"
      />
    </svg>
  );
};

FolderIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

FolderIcon.defaultProps = {
  height: "16",
  width: "21",
  svgStyles: {},
  color: "#519acc",
};

export default FolderIcon;
