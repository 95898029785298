import React from 'react'

class TestPassIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        stroke={this.props.stroke}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        className="icons"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      >
        <path  d="M12.87 52.51a3 3 0 002.84 3.17h32.58a3 3 0 002.84-3.17v-41a3 3 0 00-2.84-3.17H12.87z"/>
        <path  d="M18.61 17.21l2.6 2.8 4.85-4.95m3.56 2.98H45.4M18.61 31.33l2.6 2.8 4.85-4.95m3.56 3.26H45.4M28.99 45.77h15.78m-25.54 2.47l4.94-4.93m-4.94 0l4.94 4.93"/>
      </svg>
    );
  }
}
TestPassIcon.defaultProps = {
  color: "#fff",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default TestPassIcon