import React from "react";

const CustomDateSelected = (props) => {
  return (
    <svg
      id="Icons_Custom_Date_-_Outline_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Icons_Custom_Date_-_Outline_svg__cls-1,.Icons_Custom_Date_-_Outline_svg__cls-2{fill:#414042}.Icons_Custom_Date_-_Outline_svg__cls-2{opacity:.33}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={22.2}
        y={27.81}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={14.53}
        y={27.81}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={30.35}
        y={27.81}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={38.53}
        y={27.81}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={46.71}
        y={27.81}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={22.2}
        y={34.59}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={14.53}
        y={34.59}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={30.35}
        y={34.59}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-2"
        x={22.2}
        y={41.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-2"
        x={14.53}
        y={41.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-2"
        x={30.35}
        y={41.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-2"
        x={38.53}
        y={41.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-2"
        x={46.71}
        y={41.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={38.53}
        y={34.59}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        x={46.71}
        y={34.59}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <path
        className="Icons_Custom_Date_-_Outline_svg__cls-1"
        d="M13.05 50.74H51A3.76 3.76 0 0054.71 47V17A3.76 3.76 0 0051 13.26H13.05A3.77 3.77 0 009.29 17v30a3.77 3.77 0 003.76 3.74zm37.9-2h-37.9A1.76 1.76 0 0111.29 47V25h41.42v22A1.76 1.76 0 0151 48.74zm-37.9-33.48H51A1.76 1.76 0 0152.71 17v6H11.29v-6a1.76 1.76 0 011.76-1.74z"
      />{" "}
    </svg>
  );
};

export default CustomDateSelected;
