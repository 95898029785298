import ReactRailsUJS from "react_ujs";
import { viteConstructorRequireContext } from "../utils/viteConstructorRequireContext";

import "@/setup";
import "@/globals";

import * as ActiveStorage from "@rails/activestorage";
// import { Turbo } from "@hotwired/turbo-rails";

ActiveStorage.start();

// Turbo.start();
// window.Turbo = Turbo;
// ReactRailsUJS.detectEvents();
// delete window.Turbo;

// import react components
const componentsRequireContext = import.meta.glob("@components/**/*.{js,jsx}", {
  eager: true,
});

ReactRailsUJS.getConstructor = viteConstructorRequireContext(
  componentsRequireContext
);

// import sass stylesheets
import.meta.glob("@styles/**/*.{css,scss}", { eager: true });

// hide loading spinner and show main after initial load
// document.addEventListener("turbo:load", () => {
document.addEventListener("DOMContentLoaded", () => {
  const preloader = document.getElementById("preloader");
  const preloaderOverlay = document.getElementById("preloader-overlay");
  const main = document.getElementById("main");
  const footer = document.querySelector("footer");

  preloader.style.display = "none";
  preloader.style.opacity = 0;
  preloaderOverlay.style.display = "none";
  preloaderOverlay.style.opacity = 0;
  main.style.display = "block";
  footer.style.display = "block";
  setTimeout(() => {
    main.style.opacity = 1;
    footer.style.opacity = 1;
  }, 100);
});

// ReactRailsUJS.useContext(componentsRequireContext);
// ReactRailsUJS._firstTurboLoadSkipped = false;
// ReactRailsUJS.handleEvent("turbo:load", () => {
//   if (ReactRailsUJS._firstTurboLoadSkipped) {
//     ReactRailsUJS.handleMount();
//   }
//   ReactRailsUJS._firstTurboLoadSkipped = true;
// });

// ReactRailsUJS.handleEvent("turbo:before-visit", (e) => {
//   ReactRailsUJS.unmountComponents(e.target);
// });

// // https://turbo.hotwired.dev/reference/events
// document.addEventListener("turbo:load", () => {
//   ReactRailsUJS.mountComponents();
// });

document.addEventListener("turbo:before-visit", () => {
  console.log("turbo:before-visit");
  window.scrollTo(0, 0);
  ReactRailsUJS.unmountComponents();
});

document.addEventListener("turbo:load", () => {
  console.log("turbo:load");
  ReactRailsUJS.mountComponents();
});

// debugging turbo events
document.addEventListener("turbo:click", (e) => {
  console.log("turbo:click");
  console.log(e);
});

document.addEventListener("turbo:before-visit", (e) => {
  console.log("turbo:before-visit");
  console.log(e);
});

document.addEventListener("turbo:visit", (e) => {
  console.log("turbo:visit");
  console.log(e);
});

document.addEventListener("turbo:before-cache", (e) => {
  console.log("turbo:before-cache");
  console.log(e);
});

document.addEventListener("turbo:before-stream-render", (e) => {
  console.log("turbo:before-stream-render");
  console.log(e);
});

document.addEventListener("turbo:before-render", (e) => {
  console.log("turbo:before-render");
  console.log(e);
});

document.addEventListener("turbo:render", (e) => {
  console.log("turbo:render");
  console.log(e);
});

document.addEventListener("turbo:frame-missing", (e) => {
  console.log("turbo:frame-missing");
  console.log(e);
});

document.addEventListener("turbo:frame-render", (e) => {
  console.log("turbo:frame-render");
  console.log(e);
});

document.addEventListener("turbo:frame-load", (e) => {
  console.log("turbo:frame-load");
  console.log(e);
});

document.addEventListener("turbo:before-fetch-request", (e) => {
  console.log("turbo:before-fetch-request");
  console.log(e);
});
