import React from "react";

const BackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
    >
      <path
        d="M1.40241 9.73291L1.40216 9.73264C1.3046 9.62948 1.23061 9.51213 1.18654 9.38057C1.14554 9.25969 1.12598 9.13277 1.12598 9.00147C1.12598 8.86997 1.1456 8.74285 1.18673 8.6218M1.40241 9.73291L1.18692 8.62123C1.18686 8.62142 1.18679 8.62161 1.18673 8.6218M1.40241 9.73291L7.76905 16.4454C8.00039 16.69 8.28462 16.8233 8.61194 16.8233C8.93926 16.8233 9.22348 16.69 9.45483 16.4454C9.68736 16.1995 9.80658 15.9016 9.80658 15.5639C9.80658 15.2317 9.6969 14.9393 9.47372 14.7033L4.08167 9.00147M1.40241 9.73291L4.08167 9.00147M1.18673 8.6218C1.23083 8.49047 1.30474 8.37331 1.40216 8.2703L7.76905 1.53761C7.99421 1.29951 8.28109 1.17969 8.61194 1.17969C8.94817 1.17969 9.23968 1.3101 9.47372 1.55759C9.70498 1.80213 9.82547 2.09609 9.82547 2.4286C9.82547 2.76112 9.70498 3.05507 9.47372 3.29962L4.08167 9.00147M1.18673 8.6218L4.08167 9.00147"
        fill="#519ACC"
        stroke="#519ACC"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default BackArrow;
