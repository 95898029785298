import React from "react";
import axios from "axios";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    const url = new URL(location.href);
    const token = url.searchParams.get("reset_password_token");
    this.state = {
      password: "",
      confirmPassword: "",
      showPass: false,
      showPassConfirm: false,
      lengthPass: false,
      matchPass: false,
      numberPass: false,
      lowerCasePass: false,
      upperCasePass: false,
      specialPass: false,
      loading: false,
      token,
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  passwordHandler = (e) => {
    const password = e.target.value ? e.target.value : "";
    this.setState({ password }, () => {
      this.passwordChecker();
    });
  };

  confirmPasswordHandler = (e) => {
    const confirmPassword = e.target.value ? e.target.value : "";
    this.setState({ confirmPassword }, () => {
      this.passwordChecker();
    });
  };

  passwordChecker = () => {
    const { password, confirmPassword } = this.state;
    const passwordArray = password.split("");
    const matchPass = password !== "" && password === confirmPassword;
    const numberPass = passwordArray.some((char) => !isNaN(char));
    const lowerCasePass = passwordArray.some(
      (char) => isNaN(char) && char.toLowerCase() === char
    );
    const upperCasePass = passwordArray.some(
      (char) => isNaN(char) && char.toUpperCase() === char
    );
    const specialPass = passwordArray.some(
      (char) => "#?!@$%^&*-".split("").indexOf(char) !== -1
    );
    const lengthPass = password.length >= 8;
    this.setState({
      matchPass,
      numberPass,
      lowerCasePass,
      upperCasePass,
      specialPass,
      lengthPass,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("user[password]", this.state.password);
    formData.append("user[password_confirmation]", this.state.confirmPassword);
    formData.append("user[reset_password_token]", this.state.token);
    formData.append("user[change_password]", false);
    axios
      .put("/users/password", formData)
      .then((res) => {
        if (res.data.includes("Password was used previously")) {
          M.toast({
            html: '<span aria-live="assertive">Password was used previously</span>',
            displayLength: 3000,
            classes: "red",
          });
          this.setState({ loading: false });
        } else if (
          res.data.includes(
            "Reset password token has expired, please request a new one"
          )
        ) {
          M.toast({
            html: '<span aria-live="assertive">Reset password token has expired, please request a new one</span>',
            displayLength: 3000,
            classes: "red",
          });
          this.setState({ loading: false });
        } else {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  handleShowPassButtons = (e) => {
    e.preventDefault();

    const { showPass, showPassConfirm } = this.state;
    if (e.target.id === "show-pass-btn") {
      this.setState({ showPass: !showPass });
    } else if (e.target.id === "show-pass-btn-confirm") {
      this.setState({ showPassConfirm: !showPassConfirm });
    }
  };

  render() {
    const {
      lengthPass,
      matchPass,
      numberPass,
      lowerCasePass,
      upperCasePass,
      specialPass,
      password,
      confirmPassword,
      loading,
      showPass,
      showPassConfirm,
    } = this.state;
    return (
      <div>
        <div id="password-header" style={{ marginTop: "60px" }}>
          <img
            alt="Test Platform Logo"
            style={{ width: "210px" }}
            id="login_logo"
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/TPLogo_2021_Color.png"
          />
          <p
            tabIndex={0}
            id="main-content"
            style={{ fontFamily: "Manrope", outline: "none" }}
            className="center"
          >
            Change your password
          </p>
        </div>
        <div
          aria-label="password requirements"
          style={{
            width: "270px",
            border: "solid lightgrey 1px",
            borderRadius: "4px",
            padding: "10px",
            margin: "20px auto 0",
          }}
        >
          <div
            aria-label={`8 Character Minimum, requirement ${
              lengthPass ? "satisfied" : "not satisfied"
            }`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span
              aria-hidden="true"
              style={{ lineHeight: "20px", fontSize: "13px" }}
            >
              8 Character Minimum
            </span>
            <i
              aria-hidden="true"
              style={{ color: lengthPass ? "green" : "red", fontSize: "18px" }}
              className="material-icons"
            >
              {lengthPass ? "done" : "close"}
            </i>
          </div>
          <div
            aria-label={`1 Number Character, requirement ${
              numberPass ? "satisfied" : "not satisfied"
            }`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p
              aria-hidden="true"
              style={{ lineHeight: "20px", fontSize: "13px" }}
            >
              1 Number Character
            </p>
            <i
              aria-hidden="true"
              style={{ color: numberPass ? "green" : "red", fontSize: "18px" }}
              className="material-icons"
            >
              {numberPass ? "done" : "close"}
            </i>
          </div>
          <div
            aria-label={`1 Lower Case Character, requirement ${
              lowerCasePass ? "satisfied" : "not satisfied"
            }`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p
              aria-hidden="true"
              style={{ lineHeight: "20px", fontSize: "13px" }}
            >
              1 Lower Case Character
            </p>
            <i
              aria-hidden="true"
              style={{
                color: lowerCasePass ? "green" : "red",
                fontSize: "18px",
              }}
              className="material-icons"
            >
              {lowerCasePass ? "done" : "close"}
            </i>
          </div>
          <div
            aria-label={`1 Upper Case Character, requirement ${
              upperCasePass ? "satisfied" : "not satisfied"
            }`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p
              aria-hidden="true"
              style={{ lineHeight: "20px", fontSize: "13px" }}
            >
              1 Upper Case Character
            </p>
            <i
              aria-hidden="true"
              style={{
                color: upperCasePass ? "green" : "red",
                fontSize: "18px",
              }}
              className="material-icons"
            >
              {upperCasePass ? "done" : "close"}
            </i>
          </div>
          <div
            aria-label={`1 Special Character, requirement ${
              specialPass ? "satisfied" : "not satisfied"
            }`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p
              aria-hidden="true"
              style={{ lineHeight: "20px", fontSize: "13px" }}
            >
              1 Special Character (#?!@$%^&*)
            </p>
            <i
              aria-hidden="true"
              style={{ color: specialPass ? "green" : "red", fontSize: "18px" }}
              className="material-icons"
            >
              {specialPass ? "done" : "close"}
            </i>
          </div>
          <div
            aria-label={`Password Match, requirement ${
              matchPass ? "satisfied" : "not satisfied"
            }`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p
              aria-hidden="true"
              style={{ lineHeight: "20px", fontSize: "13px" }}
            >
              Password Match
            </p>
            <i
              aria-hidden="true"
              style={{ color: matchPass ? "green" : "red", fontSize: "18px" }}
              className="material-icons"
            >
              {matchPass ? "done" : "close"}
            </i>
          </div>
        </div>
        <div className="col s4 offset-s4">
          <div id="password_box">
            <form
              onSubmit={this.handleSubmit}
              className="edit_user"
              id="edit_user"
            >
              <div className="field" style={{ position: "relative" }}>
                <img
                  aria-hidden="true"
                  src="https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/Key_Blue.svg"
                  style={{
                    width: "26px",
                    height: "26px",
                    display: "block",
                    position: "absolute",
                    left: "10px",
                    top: "42px",
                  }}
                />
                <label htmlFor="user_password">Set password</label>
                <input
                  onKeyDown={this.handleKeyDown}
                  value={password}
                  onChange={this.passwordHandler}
                  autoFocus="autofocus"
                  autoComplete="off"
                  style={{
                    border: "1px solid #519acc",
                    height: "55px",
                    boxSizing: "border-box",
                    paddingLeft: "46px",
                  }}
                  type={`${showPass ? "text" : "password"}`}
                  name="user[password]"
                  id="user_password"
                />
                <img
                  id="show-pass-btn"
                  onClick={this.handleShowPassButtons}
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "block",
                    position: "absolute",
                    right: "10px",
                    top: "46px",
                    cursor: "pointer",
                  }}
                  src={`https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons%2FIcon_${
                    showPass ? "Show" : "Hide"
                  }_Password.svg`}
                />
              </div>
              <div className="field" style={{ position: "relative" }}>
                <img
                  aria-hidden="true"
                  src="https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/Key_Blue.svg"
                  style={{
                    width: "26px",
                    height: "26px",
                    display: "block",
                    position: "absolute",
                    left: "10px",
                    top: "42px",
                  }}
                />
                <label htmlFor="user_password">Confirm new password</label>
                <input
                  onKeyDown={this.handleKeyDown}
                  value={confirmPassword}
                  onChange={this.confirmPasswordHandler}
                  autoFocus="autofocus"
                  autoComplete="off"
                  style={{
                    border: "1px solid #519acc",
                    height: "55px",
                    boxSizing: "border-box",
                    paddingLeft: "46px",
                  }}
                  type={`${showPassConfirm ? "text" : "password"}`}
                  name="user[password_confirmation]"
                  id="user_password_confirmation"
                />
                <img
                  id="show-pass-btn-confirm"
                  onClick={this.handleShowPassButtons}
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "block",
                    position: "absolute",
                    right: "10px",
                    top: "46px",
                    cursor: "pointer",
                  }}
                  src={`https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons%2FIcon_${
                    showPassConfirm ? "Show" : "Hide"
                  }_Password.svg`}
                />
              </div>
              <div className="actions" style={{ marginTop: "10px" }}>
                <input
                  disabled={
                    loading ||
                    [
                      lengthPass,
                      matchPass,
                      numberPass,
                      lowerCasePass,
                      upperCasePass,
                      specialPass,
                    ].indexOf(false) !== -1
                  }
                  type="submit"
                  name="commit"
                  value="Submit"
                  className="common-button-submit"
                  data-disable-with="Submit"
                />
              </div>
            </form>
            <div id="link-box" style={{ marginTop: "10px" }}>
              <a href="/users/sign_in">Log in</a>
              <a href="/users/confirmation/new">
                Didn't receive confirmation instructions?
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
