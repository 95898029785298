import React from "react";
import PropTypes from "prop-types";

const TestDirectories = ({ active, ...props }) => {
  const pathClassName = active
    ? "active-path-fill"
    : "Icons_Test_Directory_Black_FullSize_svg__cls-1";

  return (
    <svg
      id="Icons_Test_Directory_Black_FullSize_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        className={pathClassName}
        d="M57.66 56.17H6.33C3 56.17.41 54.31.41 51.93V9.08a1.43 1.43 0 011.43-1.43H18a5 5 0 012.55.7l5 3h34.59a3.69 3.69 0 012.75.91A3.46 3.46 0 0163.6 15v36.93c-.02 2.38-2.6 4.24-5.94 4.24zM3.27 10.51v41.42c0 .48 1.16 1.38 3.06 1.38h51.33c1.9 0 3.06-.9 3.06-1.38V14.27a2.68 2.68 0 00-.61 0H25.2a1.46 1.46 0 01-.73-.2l-5.36-3.21a2 2 0 00-1.07-.3z"
      />
      <path
        className={pathClassName}
        d="M22 22.37H2v-2.86h19.31l2.1-2.66a1.43 1.43 0 011.12-.54h37.74v2.86H25.22l-2.09 2.66a1.45 1.45 0 01-1.13.54z"
      />
    </svg>
  );
};

TestDirectories.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default TestDirectories;
