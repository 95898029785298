import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Select from "react-select";
import SingleSelectStyles from "../common/select_styles.js";
import { dropdownIndicator, customControl } from "../common/select_utils";

const EmailForm = styled.form`
  display: flex;
  padding: 20px;
  align-items: flex-end;
  width: 60%;
  margin: auto;
  justify-content: space-around;
`;

const ViewEod = (props) => {
  const [htmlData, setHtmlData] = useState("");
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  function getData() {
    setLoading(true);
    const url = `/eod_reports/${props.data.id}/modal.html`;
    axios
      .get(url)
      .then((res) => {
        setHtmlData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (mounted === false) {
      getData();
      setMounted(true);
    }
    return () => {};
  }, []);

  function sendEmail(e) {
    e.preventDefault();
    const url = `/eod_reports/${props.data.id}/users/${selectedUser.value}.json`;
    axios
      .get(url)
      .then((res) => {
        M.toast({ html: "Email Send", classes: "green" });
        setSelectedUser(null);
      })
      .catch((err) => {
        M.toast({ html: err, classes: "green" });
        console.log(err);
      });
  }

  function handleSelectedUser(e) {
    setSelectedUser(e);
  }

  return (
    <div>
      {loading ? (
        <div style={{ height: "70vh", display: "flex", alignItems: "center" }}>
          <div id="test-step-bugs-loader" />
        </div>
      ) : (
        <div>
          <div dangerouslySetInnerHTML={{ __html: htmlData }} />
          <EmailForm>
            <div>
              <p>Send Report To:</p>
              <Select
                aria-label="User dropdown for eod report submissions"
                value={selectedUser}
                styles={SingleSelectStyles}
                onChange={handleSelectedUser}
                menuPlacement="top"
                isClearable={false}
                blurInputOnSelect
                name="user"
                closeMenuOnSelect
                appendToBody
                isDisabled={loading}
                options={props.data.managerOptions}
                components={{
                  DropdownIndicator: dropdownIndicator,
                  Control: customControl,
                }}
                classNamePrefix="selectbox-menu"
              />
            </div>
            <button
              type="button"
              disabled={selectedUser == null}
              className="common-button-submit"
              onClick={sendEmail}
            >
              Send
            </button>
          </EmailForm>
        </div>
      )}
    </div>
  );
};

export default ViewEod;
