import React, { useState, useMemo, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import moment from "moment";
import ApiClient from "@utils/ApiClient";
import { userTypes } from "@types";
import { commentTypes } from "@types/ticket";
import AttachmentCarousel from "./attachment_carousel";
import EditCommentMentionInput from "./EditCommentMentionInput";
import CommentLikes from "./CommentLikes";

const Comment = (props) => {
  const {
    comment,
    users: usersProp,
    currentUser,
    handler,
    setLoadingClass,
    setLoadingText,
    handleA11yAttachmentDownload,
    setModal,
    deleteAttachmentHandler,
  } = props;

  const users = useMemo(() => {
    return usersProp
      .map((user) => {
        return {
          id: user.value,
          display: user.label.props ? user.label.props.children[1] : user.label,
        };
      })
      .filter((user) => user.id > 0);
  }, [usersProp]);

  const canEdit = useMemo(() => {
    return currentUser.id === comment.user_id;
  }, [comment, currentUser]);

  const fileUploadRef = useRef();

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(comment.value);
  const [newAttachments, setNewAttachments] = useState([]);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setNewAttachments([]);
  }, []);

  const handleOutsideClick = useCallback(
    (e) => {
      if (e.target.classList.contains("comment-edit-button")) {
        handleCancelEdit();
      }
    },
    [handleCancelEdit]
  );

  const onCommentEdit = useCallback(
    (formData) => {
      const api = new ApiClient();
      window.removeEventListener("mousedown", handleOutsideClick);

      api
        .patch(`/comments/${comment.id}`, formData)
        .then((res) => {
          handler(res.data, "update");
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsEditing(false);
          setLoadingClass("form-update-end-loading");
          setLoadingText(false);
        });
    },
    [comment, handleOutsideClick, setLoadingClass, setLoadingText, handler]
  );

  const openEdit = useCallback(() => {
    window.removeEventListener("mousedown", handleOutsideClick);
    setIsEditing(true);
    setNewAttachments([]);
  }, [handleOutsideClick]);

  const closeEdit = useCallback(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    setLoadingClass("form-update-start-loading");
    setLoadingText(true);
    const formData = new FormData();
    formData.append("comment[value]", inputValue);
    for (const file of newAttachments) {
      formData.append("attachments[]", file);
      formData.append("filenames[]", file.name);
    }
    setIsEditing(false);
    onCommentEdit(formData);
  }, [
    handleOutsideClick,
    newAttachments,
    setLoadingClass,
    setLoadingText,
    onCommentEdit,
    inputValue,
  ]);

  const editableToggle = useCallback(() => {
    if (isEditing || !canEdit) {
      closeEdit();
    } else {
      openEdit();
    }
  }, [isEditing, canEdit, openEdit, closeEdit]);

  const deleteComment = () => {
    if (comment.user_id !== currentUser.id) {
      return null;
    }

    const api = new ApiClient();
    Swal.fire({
      title: "Delete Comment",
      text: "Are you sure that you want to delete this Comment?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
      // ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      window.removeEventListener("mousedown", handleOutsideClick);
      if (result.value) {
        setLoadingClass("form-update-start-loading");
        setLoadingText(true);
        api
          .delete(`/comments/${comment.id}.json`, {})
          .then(() => {
            handler(comment, "delete");
            setLoadingClass("form-update-end-loading");
            setLoadingText(false);
          })
          .catch((err) => {
            console.error(err);
            Swal.fire({
              title: "Comment Delete Error",
              text: "There was a problem deleting this comment",
              customClass: "swal2-error-modal",
            });
          });
      }
    });

    return null;
  };

  const handleInputChange = (nextValue) => {
    setInputValue(nextValue);
  };

  // a11y stuff//
  const handleEditableToggleKeyDown = (e) => {
    const code = e.which;
    if (code === 13) {
      editableToggle();
      setTimeout(() => {
        document
          .querySelector(
            `#comment-card-${comment.id} > ul > div > div > textarea`
          )
          ?.focus();
      }, 100);
    }
  };

  const handleEditableToggleDoneKeyDown = (e) => {
    const code = e.which;
    if (code === 13) {
      editableToggle();
    }
  };

  const handleCancelEditKeyDown = (e) => {
    const code = e.which;
    if (code === 13) {
      handleCancelEdit();
    }
  };

  const handleDeleteCommentKeyDown = (e) => {
    const code = e.which;
    if (code === 13) {
      setTimeout(() => {
        deleteComment();
      }, 300);
    }
  };

  const formatMentions = (text) => {
    return text
      ?.replace(/@\[\[/g, "<span style='color:#519acc'>@")
      .replace(/\]\]/g, "</span>")
      .replace(/\n/g, "<br>");
  };

  const mentionClick = () => {
    const elem = document.querySelector(`#comment-card-${comment.id} textarea`);
    if (elem) {
      elem.focus();
      setInputValue(`${inputValue} @`);
    }
  };

  const handleAddFile = () => {
    try {
      const fileExtension = document
        .getElementById(`comment-${comment.id}-file`)
        .value.split(".")
        .pop()
        .toLowerCase();
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "chls",
        "rtf",
        "txt",
        "doc",
        "docx",
        "zip",
        "pdf",
        "mp4",
        "mov",
        "json",
      ];

      if (!allowedExtensions.includes(fileExtension)) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Invalid File Type",
          classes: "red darken-1 error-toast",
        });
        fileUploadRef.current.file.value = "";
        return null;
      }

      const { files } = document.getElementById(`comment-${comment.id}-file`);

      const checkedFiles = [];
      for (const key in files) {
        if (Object.prototype.hasOwnProperty.call(files, key)) {
          const file = files[key];
          // file size limit: 100MB
          if (file.size > 100 * 1024 * 1024) {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "File exceeds size limit",
              classes: "red darken-1 error-toast",
            });
            fileUploadRef.current.file.value = "";
            return null;
          }

          if (files.length > 3) {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "Please limit selection to 3 files at a time",
              classes: "red darken-1 error-toast",
            });
            fileUploadRef.current.file.value = "";
            return null;
          }
          checkedFiles.push(file);
        }
      }

      setNewAttachments([...newAttachments, ...checkedFiles]);

      fileUploadRef.current.file.value = "";
    } catch (err) {
      err.message = `Comment Attachment Add Error - frontend: ${err.message}`;
      console.error(err);
    }
  };

  const deleteNewAttachmentHandler = (file) => {
    setNewAttachments(
      newAttachments.filter(
        (attachment) => attachment.name !== file.description
      )
    );
  };

  const attachmentClick = () => {
    if (fileUploadRef.current?.file) {
      fileUploadRef.current.file.click();
    }
  };

  return (
    <CommentContainer className="card col s12 commentCard">
      <AuthorAvatar
        alt="user avatar of comment author"
        aria-hidden="true"
        src={
          comment.avatar_image
            ? comment.avatar_image
            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
        }
      />
      <CardHeadline
        className="card-content comment-card-content card-text-outline"
        id={`comment-card-${comment.id}`}
      >
        <AuthorBlock>
          <p style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "600" }}>{comment.user_name}</span> -{" "}
            {moment(comment.created_at).format("lll")}
          </p>

          {canEdit && (
            <EditButtonsContainer aria-hidden="true">
              <button
                type="button"
                onKeyDown={
                  isEditing
                    ? handleEditableToggleDoneKeyDown
                    : handleEditableToggleKeyDown
                }
                tabIndex="0"
                className={["light-button", !isEditing && "comment-edit-button"]
                  .filter(Boolean)
                  .join(" ")}
                onClick={editableToggle}
              >
                {isEditing ? "Save" : "Edit"}
              </button>

              <button
                type="button"
                onKeyDown={
                  isEditing
                    ? handleCancelEditKeyDown
                    : handleDeleteCommentKeyDown
                }
                tabIndex="0"
                className="light-button"
                onClick={isEditing ? handleCancelEdit : deleteComment}
              >
                {isEditing ? "Cancel" : "Delete"}
              </button>
            </EditButtonsContainer>
          )}
        </AuthorBlock>
        <LikesBlock>
          <CommentLikes
            comment={comment}
            currentUser={currentUser}
            updateCommentState={(c) => handler(c, "update")}
          />
        </LikesBlock>
      </CardHeadline>
      <ContentBlock>
        <CommentBody $isEditable={isEditing}>
          {canEdit && (
            <CommentControls
              $isEditable={isEditing}
              className="comment-icon"
              id="new-comment-icons"
            >
              <FileUpload
                aria-hidden="true"
                id={`comment-${comment.id}-file`}
                type="file"
                accept=".jpg,.gif,.png,.chls,.rtf,.txt,.doc,.docx,.pdf,.mp4,.mov,.json"
                ref={(node) => {
                  fileUploadRef.current = node;
                }}
                onChange={handleAddFile}
                multiple
              />
              <MentionIcon
                aria-hidden="true"
                alt="Mention Icon"
                id="new-comment-mention-icon"
                onClick={mentionClick}
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Mention.svg"
              />
              <AttachmentIcon
                alt="Attachment Icon"
                aria-hidden="true"
                onClick={attachmentClick}
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Image.svg"
              />
            </CommentControls>
          )}
          {!isEditing && (
            <div className="none-edit-state">
              <p style={{ lineHeight: "20px" }}>
                {parse(formatMentions(comment.value))}
              </p>
            </div>
          )}
          {isEditing && (
            <EditCommentMentionInput
              value={inputValue}
              onChange={handleInputChange}
              usersList={users}
            />
          )}
          {comment.ticket_attachments.length > 0 && (
            <>
              <ul aria-label="Comment attachments">
                {comment.ticket_attachments.map((a) => {
                  const extension = a.image_file_name
                    .split(".")
                    [a.image_file_name.split(".").length - 1].toUpperCase();
                  return (
                    <li
                      key={`${extension}-${a.image_file_name}`}
                      aria-label={`Download ${extension} file "${a.image_file_name}"`}
                      role="button"
                      onClick={handleA11yAttachmentDownload}
                      data-file-name={a.image_file_name}
                      data-file-url={a.download_url}
                    />
                  );
                })}
              </ul>
              <div aria-hidden="true">
                <AttachmentCarousel
                  setModal={setModal}
                  deleteAttachmentHandler={deleteAttachmentHandler}
                  setLoadingClass={setLoadingClass}
                  setLoadingText={setLoadingText}
                  images={comment.ticket_attachments}
                  newTicket={false}
                  comment
                />
              </div>
            </>
          )}
          {newAttachments.length > 0 && (
            <AttachmentCarousel
              deleteAttachmentHandler={deleteNewAttachmentHandler}
              setLoadingClass={setLoadingClass}
              setLoadingText={setLoadingText}
              images={newAttachments}
              newTicket
            />
          )}
        </CommentBody>
      </ContentBlock>
    </CommentContainer>
  );
};

const EditButtonsContainer = styled.div`
  /* opacity: 0; */
  opacity: 1;
  display: inline-flex;
  gap: 8px;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  button {
    max-height: 18px;
    margin-left: 12px;
    font-size: 10pt;
    padding: 5px;

    &:last-child {
      margin-left: 0;
    }
  }
`;

const AuthorAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  display: block;
  left: -26px;
  top: 4px;
`;

const CommentContainer = styled.li`
  display: flex;
  flex-direction: column;

  &:hover {
    ${EditButtonsContainer} {
      opacity: 1;
    }
  }
`;

const CardHeadline = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AuthorBlock = styled.div``;

const LikesBlock = styled.div``;

const ContentBlock = styled.div``;

const MentionIcon = styled.img`
  outline: none;
  height: 24px;
  width: 24px;
  vertical-align: top;
  opacity: 0.7;
  cursor: pointer;
`;

const AttachmentIcon = styled.img`
  outline: none;
  height: 27px;
  width: 27px;
  cursor: pointer;
`;

const FileUpload = styled.input`
  height: 0px;
  width: 0px;
  opacity: 0;
  pointer-events: none;
`;

const CommentControls = styled.span`
  position: absolute;
  opacity: ${({ $isEditable }) => ($isEditable ? "1" : "0")};
  transition: opacity 0.2s;
  transition-delay: ${({ $isEditable }) => ($isEditable ? "0.2s" : "0s")};
  height: 30px;
  left: 22px;
  top: 28px;
`;

const CommentBody = styled.div`
  margin-left: ${({ $isEditable }) => ($isEditable ? "9px" : "10px")};
  transition: margin-top 0.2s;
  pointer-events: ${({ $isEditable }) => ($isEditable ? "all" : "none")};
  margin-top: ${({ $isEditable }) => ($isEditable ? "24px" : "0px")};
  background-color: ${({ $isEditable }) => ($isEditable ? "#f5f5f5" : "white")};
  outline: none;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ $isEditable }) => ($isEditable ? "#2684ff" : "white")};
  border-left-width: ${({ $isEditable }) => ($isEditable ? "2px" : "1px")};
  border-left-color: ${({ $isEditable }) =>
    $isEditable ? "#2684ff" : "lightgray"};
`;

Comment.propTypes = {
  comment: commentTypes,
  currentUser: userTypes,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      email_notifications: PropTypes.bool,
      id: PropTypes.number,
      image: PropTypes.string,
      label: PropTypes.string,
      user_name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  handler: PropTypes.func.isRequired,
  handleA11yAttachmentDownload: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  deleteAttachmentHandler: PropTypes.func.isRequired,
  setLoadingClass: PropTypes.func.isRequired,
  setLoadingText: PropTypes.func.isRequired,
};

export default Comment;
