import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Colors,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { ASSESSMENT_PERFORMANCE_CATEGORIES } from "../constants";
import { WidgetTitle, TitleRow, TitleSection } from "../styles";
import PerformanceScoreIcon from "../Components/PerformanceScoreIcon";
import { radarPlugins } from "./chartPlugins";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Colors
);

const AssessmentPerformance = (props) => {
  const { data } = props;

  const chartData = useMemo(() => {
    return {
      labels: data.map((item) => _.startCase(item.category).split(" ")),
      datasets: [
        {
          label: "Performance Score",
          data: data.map((item) => item.percentage),
          backgroundColor: "#35ADFF8A",
          borderColor: "#0A60E2",
          pointBackgroundColor: "#0A60E2",
          borderWidth: 1,
          pointStyle: "circle",
          pointRadius: 3,
        },
      ],
    };
  }, [data]);

  const chartOptions = {
    scales: {
      r: {
        grid: {
          circular: true,
          color: "#ACACAC",
        },
        angleLines: {
          borderDash: [2, 5],
          color: "#ACACAC",
        },
        pointLabels: {
          color: "#000000",
          padding: 25,
          font: {
            size: 13,
            weight: "700",
            family: "Manrope",
          },
        },
        ticks: {
          display: false,
          stepSize: 1 / 4,
          min: -0.25,
          max: 1,
        },
        beginAtZero: false,
        min: -0.25,
        max: 1,
      },
    },
    chartArea: {
      backgroundColor: "#F0F0F0",
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      colors: {
        enabled: true,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (context) => {
            const title = context[0].label.join(" ");
            return title;
          },
          label: (context) => {
            const category = _.snakeCase(context.label.join(" "));
            const categoryData = data.find(
              (item) => item.category === category
            );
            if (!categoryData) return null;
            const PASSED = `Assessments Completed: ${categoryData.total_passed}`;
            const TOTAL = `Total Assessments: ${categoryData.total_assessments}`;
            const PERCENTAGE = `Percentage: ${Math.round(
              categoryData.percentage * 100
            )}%`;
            return [PASSED, TOTAL, PERCENTAGE];
          },
        },
      },
    },
  };

  return (
    <AssessmentPerformanceContainer>
      <TitleRow>
        <TitleSection>
          <PerformanceScoreIcon />
          <WidgetTitle>Performance Score</WidgetTitle>
        </TitleSection>
      </TitleRow>
      {data && data.length > 0 && (
        <ChartSection>
          <ChartWrapper>
            <Radar
              data={chartData}
              options={chartOptions}
              plugins={radarPlugins}
            />
          </ChartWrapper>
        </ChartSection>
      )}
    </AssessmentPerformanceContainer>
  );
};

const AssessmentPerformanceContainer = styled.div`
  padding: 32px;
`;

const ChartSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 12px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  max-width: 425px;
  max-height: 425px;
`;

AssessmentPerformance.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.oneOf(ASSESSMENT_PERFORMANCE_CATEGORIES),
      total_assessments: PropTypes.number,
      total_passed: PropTypes.number,
      percentage: PropTypes.number,
    })
  ),
};

const A = ASSESSMENT_PERFORMANCE_CATEGORIES;
AssessmentPerformance.defaultProps = {
  data: [
    {
      category: A[0],
      total_assessments: 3,
      total_passed: 2,
      percentage: 0.67,
    },
    {
      category: A[1],
      total_assessments: 3,
      total_passed: 1,
      percentage: 0.33,
    },
    {
      category: A[2],
      total_assessments: 5,
      total_passed: 3,
      percentage: 0.6,
    },
    {
      category: A[3],
      total_assessments: 0,
      total_passed: 0,
      percentage: 0,
    },
    {
      category: A[4],
      total_assessments: 1,
      total_passed: 1,
      percentage: 1,
    },
    {
      category: A[5],
      total_assessments: 6,
      total_passed: 0,
      percentage: 0,
    },
  ],
};

export default AssessmentPerformance;
