import React, { useContext } from "react";
import { arrayOf, func, number, string } from "prop-types";
import CheckboxMenu from "../common/checkbox_menu";
import WikiContext from "./WikiContext";

const VisibleTeamsDropdown = ({ key, selectedTeams, onTeamSelect }) => {
  const { teamOptions } = useContext(WikiContext);

  return (
    <CheckboxMenu
      maxWidth="100%"
      key={key}
      count={selectedTeams.length}
      active
      options={teamOptions.map((t) => ({
        ...t,
        handler: onTeamSelect,
        checked: selectedTeams.indexOf(t.value) !== -1,
      }))}
      name="filters"
      title="Teams Visible To"
      id="2"
      containerMinWidth="160px"
      menuStyles={{
        maxWidth: "255px",
        width: "255px",
        right: "-44px",
      }}
      containerStyles={{ marginLeft: "10px" }}
      narrow
      icons={[
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
      ]}
    />
  );
};

VisibleTeamsDropdown.propTypes = {
  selectedTeams: arrayOf(number),
  onTeamSelect: func,
  key: string,
};

VisibleTeamsDropdown.defaultProps = {
  selectedTeams: [],
  onTeamSelect: () => null,
  key: "team-options-dropdown",
};

export default VisibleTeamsDropdown;
