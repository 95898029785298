import React, { useState, useContext } from "react";
import styled from "styled-components";
// import TwoCoolGuysIcon from "../../../icons/two_cool_guys_icon";
import TwoCoolGuysIcon from "@icons/two_cool_guys_icon";
import ReactModalV2 from "../../../modals/react_modal_v2";
import { DeleteAssessmentButton as ManageParticipantsButton } from "./DeleteAssessmentModal";
import { wikiAssessmentTypes } from "../../../../types/wiki";
import InviteUsersForm from "../InviteUsersForm";
import { WikiAssessmentsContext } from "..";

const ManageParticipantsModal = ({ assessment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { updateSingleAssessment } = useContext(WikiAssessmentsContext);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ManageParticipantsContainer>
      <ManageParticipantsButton onClick={toggleModal}>
        <TwoCoolGuysIcon color="#519acc" />
      </ManageParticipantsButton>
      <ReactModalV2
        id="manage-participants-modal"
        isShowing={isModalOpen}
        data={{ customClass: "manage-participants-modal" }}
        modalAction={toggleModal}
        page={() => (
          <InviteUsersForm
            assessment={assessment}
            toggleModal={toggleModal}
            updateAssessmentData={updateSingleAssessment}
          />
        )}
      />
    </ManageParticipantsContainer>
  );
};

const ManageParticipantsContainer = styled.div``;

ManageParticipantsModal.propTypes = {
  assessment: wikiAssessmentTypes,
};

export default ManageParticipantsModal;
