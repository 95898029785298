import React from "react";

const TrialistIconSmall = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1080 1080"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__linear-gradient"
          x1={567.42}
          y1={239.8}
          x2={524.63}
          y2={783.41}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.11} stopColor="#5cb0ea" />
          <stop offset={0.8} stopColor="#519acc" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-2"
          x1={362.37}
          y1={220.61}
          x2={284.45}
          y2={1210.56}
          xlinkHref="#prefix__linear-gradient"
        />
        <linearGradient
          id="prefix__linear-gradient-3"
          x1={549.99}
          y1={234.69}
          x2={471.6}
          y2={1230.59}
          xlinkHref="#prefix__linear-gradient"
        />
        <linearGradient
          id="prefix__linear-gradient-4"
          x1={534.17}
          y1={340.43}
          x2={535.98}
          y2={603.26}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4fc0ff" />
          <stop offset={0.25} stopColor="#4fbdfc" />
          <stop offset={0.73} stopColor="#4583ad" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-5"
          x1={541.38}
          y1={497.89}
          x2={543.61}
          y2={643.19}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#52525e" />
          <stop offset={1} stopColor="#5d5e6b" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-6"
          x1={541.73}
          y1={325.07}
          x2={541.73}
          y2={324.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5db1eb" />
          <stop offset={0.77} stopColor="#5dbcfc" />
        </linearGradient>
        <style>
          {
            ".prefix__cls-1{fill:#edcc43}.prefix__cls-2{fill:#519acc}.prefix__cls-7{fill:#ffd99c}.prefix__cls-8{fill:#64c3f9}.prefix__cls-10{fill:#fff}.prefix__cls-11{fill:#5c5d68}.prefix__cls-15{fill:#43444c}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <circle className="prefix__cls-1" cx={542.42} cy={540} r={418.69} />
        <circle className="prefix__cls-2" cx={542.42} cy={540} r={361.19} />
        <path
          className="prefix__cls-1"
          d="M540.85 308.13h-1.23c.86 0 1.69 0 2.51.06h.49zM411.77 591.29v.11l.09-.13zm-71.94 34.05c-.08.2-.14.39-.2.59 1.05-1.58 1.09-1.79.2-.59zm371.47 14.09a19.81 19.81 0 01-4.3.54l17.57 31.79h4.69zm-53.5-71v3c0-.19 0-.4.08-.59a7 7 0 00-.08-2.44zm-15.16 23.32c-.13.16-.28.31-.41.47a6.72 6.72 0 001.13-.41c-.25-.04-.48-.06-.72-.09zm14.36-7.54c0 .92 0 1.82.1 2.74l.55.13v-2.61zm-12.94-15.91c-.09.25 0 .57 0 0z"
        />
        <path
          className="prefix__cls-1"
          d="M339.67 625.93c.06-.2.12-.39.2-.59.85-1.2.81-.99-.2.59zm72.1-34.53v-.11h.09zm131.6-283.21h-1.24c-.82 0-1.65-.05-2.51-.06H545c-.56.01-1.09.04-1.63.06zM657 584.21c0 .92 0 1.82.1 2.74l.55.13v-2.61z"
        />
        <path
          className="prefix__cls-1"
          d="M733.72 679.81a13.34 13.34 0 006.13-5.62 17.69 17.69 0 001.2-2.43h.66c20.82-35.52 22.61-41.94 31.47-82.69 2-9.05 5.09-27.36 5.71-36.61 2.56-37.77-26.57-103-31.69-115.28a1.76 1.76 0 011.28-2.43 1.76 1.76 0 001-2.82c-8.16-10.75-43.08-55.92-63-71.12-.34-.24 6.18-.52 5.84-.77-22.21-16.81-28-23.46-53.56-32 3-2.89 5.47-6.37 8.31-9.66 14.26-16.51 8.47-45.78 6.74-58.87a8.25 8.25 0 00-10.32-6.81c-13 3.36-47.09 7.84-60.57 27.37-.68 1-1.3 2-1.89 3-2.72-.59-5.42-1.19-8.13-1.73.09-1.18.14-2.37.14-3.58.07-23.61-19.61-45.46-28.49-55.39a8.39 8.39 0 00-12.49 0c-8.88 9.88-26.29 32.84-26.32 56.41 0 .9 0 1.85.07 2.75-3.45.91-6.91 1.87-10.31 2.87-.64-1.06-1.32-2.1-2-3.13-13.48-19.53-35.63-30.67-48.65-34-4.83-1.23-9.25 1.6-11.37 6.07-6.5 13.72-7.72 38.82-1.72 60.69a32.51 32.51 0 008.53 13.53C415.79 336 407 343.84 385.62 360c-.37.25 8.44.53 8.1.77-28.59 21.82-56 52.41-70.88 87.89-.16.37 6.55.74 6.38 1.12l-16.47 65a198 198 0 00.23 105c.84 3.1 1.79 6.15 2.81 9.2 3.84 11.69 5.56 16.21 11.45 27l5.49 12.09a25.81 25.81 0 001.33 4c3.05 7.19 12.7 11 19.62 14l.59.26a19.37 19.37 0 003 1.11l-14.83 26.85c-16.16 32.75 1.1 78.11 34.8 88.87 28.92 9.27 103.21 15.07 168 12.56 64.8 2.51 139.09-3.29 168-12.56 33.68-10.76 51-56.12 34.8-88.87l-18.84-34.1zm-75.9-109c0 .19-.06.4-.08.59v-3a7 7 0 01.08 2.43z"
        />
        <path
          className="prefix__cls-1"
          d="M315.79 629a48.07 48.07 0 01-2.81-9.2c.84 3.08 1.79 6.13 2.81 9.2zM545 308.13c-.55 0-1.08 0-1.62.06h-2.52zM339.67 625.93c.06-.2.12-.39.2-.59.85-1.2.81-.99-.2.59zM644.06 568.3c0 .57-.09.25 0 0z"
        />
        <path
          className="prefix__cls-1"
          d="M644.06 568.3c0 .57-.09.25 0 0zM339.67 625.93c.06-.2.12-.39.2-.59.85-1.2.81-.99-.2.59zM657.82 570.83c0 .19-.06.4-.08.59v-3a7 7 0 01.08 2.41z"
        />
        <path
          className="prefix__cls-1"
          d="M339.67 625.93c.06-.2.12-.39.2-.59.85-1.2.81-.99-.2.59z"
        />
        <path
          d="M644.06 568.3c0 .57-.09.25 0 0z"
          opacity={0.35}
          fill="#edcc43"
        />
        <path
          className="prefix__cls-1"
          d="M339.67 625.93c.06-.2.12-.39.2-.59.85-1.2.81-.99-.2.59zM411.86 591.27l-.09.13v-.11zM733.72 679.81L753 714.5c16.54 33.43-1.15 79.69-35.66 90.68-29.61 9.45-105.72 17.57-172.1 15-66.37 2.55-142.48-5.57-172.1-15-34.5-11-52.19-57.25-35.66-90.68l16.2-28.5.59.26a19.37 19.37 0 003 1.11l-14.83 26.85c-16.16 32.75 1.1 78.11 34.8 88.87 28.92 9.27 103.21 15.07 168 12.56 64.8 2.51 139.09-3.29 168-12.56 33.68-10.76 51-56.12 34.8-88.87l-18.84-34.1M539.62 308.13c.86 0 1.69 0 2.51.06h1.6z"
        />
        <path
          d="M727.45 667.73c19.34-33.48 21-39.52 29.22-77.94 1.82-8.53 4.73-25.79 5.31-34.5 2.38-35.61-24.67-97.06-29.44-108.66a1.68 1.68 0 011.2-2.29 1.67 1.67 0 001-2.66c-7.57-10.12-40-52.7-58.5-67-.33-.24 5.73-.49 5.41-.73-20.62-15.84-43-28.2-66.66-36.28a58.17 58.17 0 007.72-9.1c12.47-18.44 16.84-42.28 15.23-54.62a7.67 7.67 0 00-9.58-6.42c-12.09 3.17-39.92 11.27-52.43 29.69-.62.91-1.2 1.85-1.76 2.81-2.52-.58-5-1.13-7.55-1.64.09-1.11.13-2.23.13-3.38.06-22.25-14.05-41.54-22.29-50.89a7.72 7.72 0 00-11.59 0c-8.26 9.32-22.44 28.57-22.47 50.79 0 .85 0 1.74.07 2.6-3.21.85-6.42 1.74-9.59 2.7-.57-1-1.21-2-1.88-3-12.51-18.42-35.06-26.52-47.15-29.69a7.69 7.69 0 00-9.6 6.42c-1.61 12.34-2.5 36.18 10 54.62a56.77 56.77 0 008.73 10 233.68 233.68 0 00-64.09 35.37c-.35.24 7.83.49 7.51.73-26.53 20.56-52 49.4-65.81 82.83-.15.36 6.08.7 5.93 1.06l-15.3 61.23a189.62 189.62 0 00.22 99c.79 2.93 1.67 5.81 2.61 8.68 3.57 11 5.15 15.29 10.63 25.43l6.65 14.89M544.81 325l-1.5.06L541 325z"
          fill="url(#prefix__linear-gradient)"
        />
        <path
          d="M332 627.41a46.28 46.28 0 01-2.61-8.68c.79 2.93 1.67 5.81 2.61 8.68z"
          fill="url(#prefix__linear-gradient-2)"
        />
        <path
          d="M544.81 325l-1.5.06L541 325z"
          fill="url(#prefix__linear-gradient-3)"
        />
        <path
          className="prefix__cls-7"
          d="M354.17 624.54a3.69 3.69 0 01.19-.56c.79-1.12.75-.93-.19.56zM643.72 590.08c-.8.41-1.59.81-2.4 1.19a10.39 10.39 0 01-5.85 1c-.55.64-1.13 1.26-1.66 1.92-4.12 5.23-9.8 4.17-13.46.51a11.41 11.41 0 01-10.72-.49q11.43-1 22.76-2.47c3.78-.47 7.55-1.02 11.33-1.66z"
        />
        <path className="prefix__cls-8" d="M636.79 570.21c0 .54-.08.24 0 0z" />
        <path className="prefix__cls-8" d="M636.79 570.21c0 .54-.08.24 0 0z" />
        <path
          className="prefix__cls-7"
          d="M718.21 675.86a11.49 11.49 0 007.51-5.83 30.55 30.55 0 003.06-9.27c1.36-6.56 1.75-13.39 3.37-19.85 1.51-6.06 4.4-11.77 6.51-17.63 3.42-9.48 4.38-19.76 1.49-29.65a39.86 39.86 0 00-10.66-17.5 87.904 87.904 0 00-4.91-3.32 40.16 40.16 0 00-8.7-3.95c-9.82-.7-19.63-1.15-29.58.23-13.72 1.93-36.79 1.22-36.79 1.22l-.14 21.41s-11.73-.13-12.45.26a8.64 8.64 0 01-5.25 1c-.49.63-1 1.25-1.49 1.91-3.68 5.23-8.79 4.17-12.06.51l-33.56 1.26c-7.87.42-15.74.68-23.61.87l-45.3-.7c-7.87-.2-15.74-.45-23.6-.88l-33.56-1.25c-3.28 3.66-24.95 19-28.65 13.74-.49-.66-1-1.28-1.49-1.92a8.55 8.55 0 01-5.25-1c-.73-.38-2.15-1.19-2.15-1.19l.31-23.32-.61-10.7s-19.21-.36-30.35-1.92c-9.95-1.38-19.75-.94-29.58-.23a40.6 40.6 0 00-8.7 4c-1.68 1-3.32 2.17-4.91 3.32a39.9 39.9 0 00-10.66 17.5c-2.89 9.89-1.93 20.16 1.49 29.65 2.39 6.66 7.38 13.16 7.83 20.33.48 7.76.14 17.83 3.19 25.09 2.92 7 12.32 10.58 18.77 13.41a15.3 15.3 0 003.76 1.27c4.11.6 7.88-2.21 10.9-5 3.23-3 6.32-6.44 7.87-10.6s1.42-8.42 1.33-12.7c-.09-4.08-.51-8.82-3.9-11.1"
        />
        <path
          className="prefix__cls-7"
          d="M354.17 624.54a3.69 3.69 0 01.19-.56c.79-1.12.75-.93-.19.56z"
        />
        <path
          d="M709.13 495a49.47 49.47 0 013.36 28.62c-3.65 20.16-16.6 31-32 36.58a118.62 118.62 0 01-29.06 6.91c-1.31.13-2.2 1.66-1.91 3.19a6.42 6.42 0 010 2.32 76.59 76.59 0 00-.37 19.08c.25 2.63-.89 8-3 7.8a25.42 25.42 0 01-2.08 2.51c-2.07 0-1 .92-3.77.36-27-5.29-37.14-40-57.16-57.91-10.7-9.55-34.88-12.27-48-13.12-13.1.85-37.29 3.57-48 13.12-20 17.91-30.16 52.62-57.16 57.91-2.8.56-1.7-.36-3.78-.36a26.81 26.81 0 01-2.07-2.51c-2.09.17-3.23-5.17-3-7.8a76.11 76.11 0 00-.38-19.08 6.91 6.91 0 010-2.32c.29-1.53-.62-3.06-1.9-3.19a118.29 118.29 0 01-29.06-6.91c-15.38-5.59-28.33-16.42-32-36.58a49.47 49.47 0 013.52-28.62c.31-.77.66-1.49 1-2.24H350s9-26.54 13.8-35.22c7.22-13.16 19.84-29.2 19.84-29.2h-11.42s22.36-23.75 29.28-30.88c6.78-6.95 29.44-25.24 29.44-25.24l-10-.89s25.92-16 54.22-23.87c19.08-5.29 45.8-6.95 59.77-7h.54c14 0 40.68 1.7 59.77 7 28.27 7.83 54.22 23.87 54.22 23.87l-10 .89s22.63 18.29 29.41 25.24c6.94 7.13 29.29 30.88 29.29 30.88h-11.4s12.6 16 19.83 29.2c4.75 8.68 13.81 35.22 13.81 35.22h-12.31c.38.73.73 1.45 1.04 2.24z"
          fill="url(#prefix__linear-gradient-4)"
          opacity={0.35}
        />
        <path
          className="prefix__cls-10"
          d="M393.16 513.84c12.94-27.73 32.8-42.69 47.14-41.59 24 1.85 22.18 3.7 34.19 49 0 0-23.1 15.71-41.59 12s-39.74-19.41-39.74-19.41z"
        />
        <rect
          className="prefix__cls-11"
          x={420.07}
          y={477.8}
          width={36.76}
          height={53.61}
          rx={16.35}
        />
        <circle className="prefix__cls-10" cx={433.83} cy={491.66} r={13.86} />
        <path
          className="prefix__cls-10"
          d="M672.33 511.71c-12.94-27.71-32.8-42.71-47.14-41.59-24 1.85-22.18 3.7-34.19 49 0 0 23.1 15.71 41.59 12s39.74-19.41 39.74-19.41z"
        />
        <rect
          className="prefix__cls-11"
          x={608.66}
          y={475.67}
          width={36.76}
          height={53.61}
          rx={16.35}
          transform="rotate(-180 627.045 502.47)"
        />
        <circle className="prefix__cls-10" cx={631.66} cy={489.53} r={13.86} />
        <path
          d="M701 791.53c-26.85 8.73-95.83 14.2-156 11.83-60.16 2.37-129.14-3.1-156-11.83-31.27-10.15-47.31-52.89-32.32-83.77l34.68-63.68-17.88-6.32c-13-4.73-22.47-10-22.47-22.47a22.43 22.43 0 0122.47-22.47l47.28-.83a2.21 2.21 0 01.42-.12c13.61-3 47.35-8.85 52.51-18 6.67-11.83 26.73-33.12 71.3-33.12s64.63 21.29 71.3 33.12c5.16 9.13 35.54 14.48 49.14 17.53.38.1.75.19 1.13.33l33.38-1.3a22.48 22.48 0 0115.9 38.37c-4.07 4.07-14.43 8.94-20.63 8.94l38.11 70c15 30.9-1.04 73.64-32.32 83.79z"
          fill="#52535e"
        />
        <path
          d="M698.89 636.61l39 71.42c15.36 31.49-1.07 75.1-33.11 85.46-27.5 8.9-98.16 16.57-159.79 14.15-61.62 2.42-132.29-5.25-159.79-14.15-32-10.36-48.47-54-33.1-85.46l36.76-65.53 32.32-50.63a2.64 2.64 0 01.44-.12c13.93-3.12 40.32-18.08 45.6-27.4 6.84-12.06 27.39-33.79 73-33.79s66.21 21.73 73 33.79c5.28 9.32 41.13 24.28 55.06 27.4a10.24 10.24 0 011.17.33z"
          fill="url(#prefix__linear-gradient-5)"
        />
        <path
          d="M539.81 325l2.34.06 1.49-.06z"
          fill="url(#prefix__linear-gradient-6)"
        />
        <ellipse
          className="prefix__cls-15"
          cx={507.4}
          cy={758.16}
          rx={16.56}
          ry={10.65}
        />
        <ellipse
          className="prefix__cls-15"
          cx={579.8}
          cy={756.25}
          rx={10.65}
          ry={17.94}
          transform="rotate(-85.98 579.847 756.257)"
        />
        <path
          className="prefix__cls-2"
          d="M591.91 442.77l2.09-16.41c1.36.13 51.85 6.54 53.23 6.72 22.82 2.91 20.06 9.22 30.53 20.65zM477.63 442.77l-2.09-16.41c-1.37.13-51.85 6.54-53.23 6.72-22.82 2.91-20.06 9.22-30.53 20.65z"
        />
      </g>
    </svg>
  );
};

export default TrialistIconSmall;
