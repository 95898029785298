import React from 'react'
import moment from 'moment'
import Swal from "sweetalert2";

class Build extends React.Component{

  constructor(props) {
    super(props);
    this.state = {availableUntilDate: this.props.build.end_of_availability_date};
    this.confirmSwal = this.confirmSwal.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  stateNode() {
    var now = new Date();
    var date = Date.parse(this.state.availableUntilDate)
    if (date < now) {
      return (
        <p><span className="label label-danger">Expired</span></p>
      );
    } else {
      return (
      <div><span>Available</span><a className="expire-link pointer" onClick={this.confirmSwal} > - Expire now</a></div>
      );
    }
  }

  confirmSwal() {
      var object = this.props
      var self = this
      Swal.fire({
        title: "Expire Build",
        text: "Are you sure that you want to expire this build?",
        reverseButtons: true,
        showCancelButton:true,
confirmButtonAriaLabel: 'Yes',
cancelButtonAriaLabel: 'cancel',
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          // Send ajax call to delete application choosen
          $.ajax({
            url: "/builds/" + object.build.id,
            dataType: "json",
            type: "DELETE",
            cache: false,
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                $('meta[name="csrf-token"]').attr("content")
              );
            },
            success: function () {
              var now = moment().subtract(1, "day")._d;
              Swal.fire("Deleted", "This build was successfully expired");
              self.setState({ availableUntilDate: now });
            }.bind(self),
            error: function () {
              Swal.fire("Oops", "There was a problem with that request");
            },
          });
        } else if (result.dismiss) {
          // Swal.fire("Cancelled", "Your file is safe");
        }
      });
  }

  copyToClipboard() {
    var copyId = "copy-url"+this.props.build.build_code;
    var elem = document.getElementById(copyId);
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(elem).text()).select();
    document.execCommand("copy");
    $temp.remove();
      Swal.fire({
        html: "<div style='display: flex; align-items: center;'><i class='material-icons medium green-text'>check</i> URL Copied</div>",
        showConfirmButton: false,
        width: 300
      })
  }

  render() {
    var url = "testplatform.plusqa.com/get/" + this.props.build.build_code;
    if (this.props.width > 600) {
     return (
      <tr>
        <td className="truncate">
          <a href={"/get/"+this.props.build.build_code} target="_blank" rel="noopener noreferrer">{this.props.build.build_name}</a><br/>
        </td>
        <td style={{position:"relative", whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden"}}>
          <span id={"copy-url"+this.props.build.build_code}>{url}</span>
          <i style={{position: "absolute", transition: "opacity .33s", opacity:"0", right:"5px", top:"9px", fontSize:"20px", cursor:"pointer"}} onClick={this.copyToClipboard} className="material-icons grey-text darken-text-1">content_copy</i>
        </td>
        <td>
          {this.stateNode()}
        </td>
        <td style={{whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden"}}>
          <span>Uploaded {this.props.build.time_difference} ago</span>
        </td>
      </tr>
    );
  } else {
    return (
      <div className="col s12 m6 card">
        <div className="card-content">
          <ul>
            <li className="truncate">
              <span>Build Code: </span>
              <a
                href={"/get/" + this.props.build.build_code}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.build.build_name}
              </a>
            </li>
            <li>
              <span>Url: </span>
              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                id={"copy-url" + this.props.build.build_code}
              >
                {url}
              </span>
            </li>
            <li>
              <span>{this.stateNode()}</span>
            </li>
            <li>
              <span>
                <span>Uploaded {this.props.build.time_difference} ago</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
    }
  }
}

export default Build