import React from 'react'

class PointTextBoxIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 41.12 40.67"
        className="icons"
      >
        <g id="Layer_3" data-name="Layer 3">
          <path d="M7,8.24A.78.78,0,0,0,7.53,6.9a.78.78,0,0,0-1.33.55A.71.71,0,0,0,6.43,8,.82.82,0,0,0,7,8.24ZM5.88,6.35a.76.76,0,0,0,0-1.09.77.77,0,0,0-1.1,1.09A.8.8,0,0,0,5.88,6.35Zm-3-2.22a.81.81,0,0,0,.23.55.75.75,0,0,0,1.1,0,.77.77,0,0,0,0-1.1.77.77,0,0,0-1.1,0A.81.81,0,0,0,2.88,4.13Zm13.72-.5a.74.74,0,0,0,.24.55.74.74,0,0,0,.55.24.75.75,0,0,0,.55-.24.76.76,0,0,0,0-1.09.75.75,0,0,0-.55-.24.79.79,0,0,0-.58.21A.87.87,0,0,0,16.6,3.63Zm-.31,2.2a.78.78,0,0,0-1.1-1.1.78.78,0,0,0,0,1.1A.8.8,0,0,0,16.29,5.83ZM14.64,7.48a.79.79,0,0,0-.55-1.34.79.79,0,0,0-.57.21.75.75,0,0,0,0,1.1.81.81,0,0,0,1.12,0ZM10.85.24a.75.75,0,0,0-1.1,0,.76.76,0,0,0,0,1.09.75.75,0,0,0,1.1,0,.74.74,0,0,0,0-1.09Zm0,3.45a.82.82,0,0,0,.24-.55.81.81,0,0,0-.79-.79.8.8,0,0,0-.78.79.79.79,0,0,0,.78.78A.81.81,0,0,0,10.85,3.69ZM9.54,5.46A.74.74,0,0,0,9.78,6a.75.75,0,0,0,1.1,0,.76.76,0,0,0,0-1.09.75.75,0,0,0-1.1,0A.64.64,0,0,0,9.54,5.46ZM6.59,13.65a.77.77,0,0,0,0,1.1.77.77,0,0,0,1.1,0,.77.77,0,0,0,0-1.1.75.75,0,0,0-1.1,0ZM6,16.4a.75.75,0,0,0,0-1.1.78.78,0,0,0-1.1,0,.79.79,0,0,0,0,1.1A.77.77,0,0,0,6,16.4Zm-1.65.54a.79.79,0,0,0-1.33.55A.74.74,0,0,0,3.3,18a.74.74,0,0,0,1.09,0,.74.74,0,0,0,.24-.55A.75.75,0,0,0,4.39,16.94ZM1.33,11.69a.75.75,0,0,0,0-1.1.74.74,0,0,0-1.09,0,.75.75,0,0,0,0,1.1.76.76,0,0,0,.54.23A.9.9,0,0,0,1.33,11.69Zm1-.58a.8.8,0,0,0,.78.79.82.82,0,0,0,.79-.79.8.8,0,0,0-.79-.78A.79.79,0,0,0,2.33,11.11Zm2.56.58A.78.78,0,1,0,6,10.59a.78.78,0,1,0-1.1,1.1Z"/>
          <path d="M41.12,40.67V32H37.91V18.14h3.21V9.49H32.47v3.22H18.6V9.49H10v8.65h3.23V32H10v8.66H18.6V37.45H32.47v3.22Zm-2.21-6.44v4.22H34.69V34.23ZM34.69,11.71h4.22v4.21H34.69ZM12.16,15.92V11.71h4.22v4.21Zm4.22,22.53H12.16V34.23h4.22Zm2.22-3.22V32H15.4V18.14h3.2V14.92H32.47v3.22h3.22V32H32.47v3.22Z"/><path class="cls-1" d="M30.82,22.32a6.32,6.32,0,0,0-.87-2c-.36-.42-.52-.58-1.7-.58H27v9.44c0,1.69.2,1.81,2,1.95v.74H22.28v-.74c1.71-.14,1.89-.26,1.89-1.95V19.77H23c-1.13,0-1.45.18-1.83.66a7.89,7.89,0,0,0-.82,1.91h-.77c.14-1.46.28-3,.32-4h.61c.3.46.54.5,1.14.5h8a1.33,1.33,0,0,0,1.17-.5h.58c0,.84.1,2.61.2,3.91Z"/>
        </g>
      </svg>
    );
  }
}
PointTextBoxIcon.defaultProps = {
  color: "white",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default PointTextBoxIcon