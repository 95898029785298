import React from 'react'
import PropTypes from 'prop-types'
import UserImage from '../tickets/user_image.jsx'
import axios from 'axios'
import styled from 'styled-components'

const Tag = styled.p`
background-color: #519acc;
border: none;
width: 60px; 
height: 20px;
padding: 15px;
border-radius: 15px;
font-size: 13px;
text-transform: capitalize;
font-family: Arial;
font-weight: 400;
color: white;
display: flex;
align-items: center;
justify-content: center;
`

const TeamCardDiv = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px lightgrey;
  padding: 10px 30px 20px;
  margin: 5px;
  min-width: 350px;
  height: 100%;
  flex: 1;
  > div:nth-child(1) {
    height: 70px;
    align-items: center;
    display: flex;
    flex-direction: row;
    > p {
      margin-right: 15px;
    }
  }
}
`

class TeamCard extends React.PureComponent {
   constructor(props) {
    super(props);
    this.state = {
      users: [], 
      activeUser: null,
      loading: false,
      randomNum: 0
    }
  }

  onScroll(e) {
    var elem = e.target
    if (elem && elem.clientHeight < elem.scrollHeight) {
      if (elem.clientHeight + elem.scrollTop === elem.scrollHeight && elem.scrollTop != 0) {
        elem.previousElementSibling.style.opacity = "1"
        elem.nextElementSibling.style.opacity = "0"
      } else if (elem.scrollHeight > 1) {
        elem.nextElementSibling.style.opacity = "1"
      }
      if (elem.scrollTop === 0 && elem.scrollHeight > 1) {
          elem.previousElementSibling.style.opacity = "0"
          elem.nextElementSibling.style.opacity = "1"
      }
      if (elem.scrollTop != 0) {
        elem.previousElementSibling.style.opacity = "1"
      }
    }
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
    this.getUsers()
  }

  componentWillUnmount() {
    var elem = document.getElementById('teamBlur')
    if (elem) {
      elem.removeEventListener('scroll', this.onScroll, {passive: true});
    }
  }

  closeUser = () => {
    this.setState({activeUser: null})
  }

  getUsers = () => {
    this.setState({loading: true})
    var url = `/v1/projects/${this.props.projectId}/user_images.json`
   axios.get(url)
    .then((res) => {
      this.setState({users: res.data, loading: false}, () => {
        var elem = document.getElementById('teamBlur')
        if (elem) {
          elem.addEventListener('scroll', this.onScroll, {passive: true});
        }
      })
    })
    .catch((err) => {
      console.log(err)
    })
  }

  activeUserHandler = (id) => {
    if (id != this.state.activeUser) {
      this.setState({activeUser: id})
    } else {
      this.setState({activeUser: null})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.projectId != prevProps.projectId) {
      this.getUsers()
    } else if (this.state.loading == false && prevState.loading == true) {
      this.random()
    }
  }

  displayLoader() {
    return (
    <div className="display-loader">
      <div id="test-step-bugs-loader" />
    </div>
    )
  }

  random = () => {
    this.setState({randomNum: Math.random()})
  }

  render() {
    return (
      <TeamCardDiv>
        <div>
          <p className="large-title">Your Team</p>
        </div>
        <div className="top-team-blur"></div>
        <div
          className="new-scrollbar"
          id="teamBlur"
          style={{
            justifyContent: "flex-start",
            overflowX: "hidden",
            overflowY: "auto",
            marginTop: "-30px",
            height: "100%",
            flexDirection: "column",
            maxHeight: "calc(100% - 70px)",
          }}
        >
           {this.state.loading ? (
            this.displayLoader()
          ) : (
            this.state.users.map((user, i) => (
              <div
                style={{borderBottom: "1px solid lightgrey",width: "100%", paddingRight: "10px", height: "70px", textAlign: "center", maxHeight: "90px", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                key={i}
              >
                <UserImage
                  closeUser={this.closeUser}
                  showCard={this.props.showCard ? this.props.showCard : false}
                  activeCardHandler={this.activeUserHandler}
                  showName={this.props.showName ? this.props.showName : false}
                  active={user.id == this.state.activeUser}
                  user={user}
                  url={user.avatar_image}
                />
                <p
                  style={{
                    lineHeight: "15px",
                    fontSize: "15px",
                    fontFamily: 'arial',
                    fontWeight: "600",
                    paddingLeft: "30px",
                    textAlign: "left",
                    flex: "1 1 100px",
                    wordBreak: `${
                      user.name
                        ? user.name.indexOf(" ") >= 0
                          ? "break-word"
                          : "break-all"
                        : user.email
                        ? user.email.indexOf(" ") >= 0
                          ? "break-word"
                          : "break-all"
                        : "break-word"
                    }`,
                  }}
                >
                  {user.name ? user.name : user.email ? user.email : ""}
                </p>
                <Tag>{user.role.includes("project") ? "PM" : user.role}</Tag>
              </div>
            )))}
        </div>
        <div className="bottom-team-blur"></div>
      </TeamCardDiv>
    );
  }
}

export default TeamCard

TeamCard.propTypes = {
  showCard: PropTypes.bool,
  showName: PropTypes.bool,
  big: PropTypes.bool,
  projectId: PropTypes.number
};