import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ApiClient from "@utils/ApiClient";
import ImpersonateIcon from "@icons/impersonate_icon";
import userTypes from "@types/user";

const UserImpersonationStatus = (props) => {
  const { currentUser, trueUser, session } = props;

  const stopImpersonation = () => {
    const api = new ApiClient();
    api.post("/v1/users/end_impersonate").then(() => {
      window.location.href = "/admin_panel?current_page=2";
    });
  };

  if (!session?.impersonated_user_id) {
    return null;
  }

  return (
    <UserImpersonationHover>
      <UserImpersonationContent>
        <ImpersonationIcon>
          <ImpersonateIcon height="14" width="15" />
        </ImpersonationIcon>
        <UserImpersonationBody>
          <ImpersonationMessage>
            You are impersonating {currentUser.name}
          </ImpersonationMessage>
          <StopImpersonation>
            To end session, click{" "}
            <StopButton onClick={stopImpersonation} type="button">
              here
            </StopButton>
          </StopImpersonation>
        </UserImpersonationBody>
      </UserImpersonationContent>
    </UserImpersonationHover>
  );
};

const UserImpersonationHover = styled.div`
  position: fixed;
  bottom: 0;
  right: -4px;
  padding: 8px 16px;
  box-shadow: lightgrey 0px 0px 3px 1px;
  border-radius: 0;
  border-top-left-radius: 5px;
  z-index: 100000;
  opacity: 0.8;
  background-color: white;
  max-width: 60px;
  overflow: hidden;
  transition: max-width 0.3s ease-out;

  &:hover {
    max-width: 600px;
  }
`;

const UserImpersonationContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: hidden;
`;

const ImpersonationIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: 1px solid #519acc;
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  flex-shrink: 1;
`;

const UserImpersonationBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const ImpersonationMessage = styled.div`
  font-size: 9px;
  line-height: 12px;
`;

const StopImpersonation = styled.div`
  height: 18px;
  padding: 2px 8px;
  font-size: 9px;
  line-height: 12px;
`;

const StopButton = styled.button`
  height: 16px;
  background-color: transparent;
  border: 1px solid #519acc;
  border-radius: 5px;
  cursor: pointer;
`;

UserImpersonationStatus.propTypes = {
  currentUser: userTypes,
  trueUser: userTypes,
  session: PropTypes.shape({
    impersonated_user_id: PropTypes.number,
    session_id: PropTypes.string,
  }),
};

export default UserImpersonationStatus;
