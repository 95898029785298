import React from 'react'
import MediaSquare from './media_square'
import {AutoSizer, ColumnSizer, Grid} from 'react-virtualized';
import "react-virtualized/styles.css";

function MediaFlex(props) {

  function customRowRenderer(innerProps) {
    var itemIndex = (innerProps.rowIndex * 7) + innerProps.columnIndex
    var item = props.media_items[itemIndex];
    if (item) {
      return (
        <div key={`${innerProps.index}_${item.id}`} style={innerProps.style}>
            <MediaSquare
              key={innerProps.index}
              item={item}
              editorHandler={props.editorHandler}
              selected={props.selectedImages.includes(item.id)}
              mediaHandler={props.mediaHandler}
              handleSelected={props.handleSelected}
            />
        </div>
      );
    }
  }  

  return (
    <div id="media-grid" style={{ display: "flex", flex: 1, width: "100%", minHeight: "70vh" }}>
      <AutoSizer>
        {({ width, height }) => (
           <ColumnSizer
            columnMaxWidth={400}
            columnMinWidth={100}
            columnCount={7}
            width={width}
           >
           {({adjustedWidth, getColumnWidth, registerChild}) => (
             <Grid
               ref={registerChild}
               columnWidth={getColumnWidth}
               columnCount={7}
               height={height}
               cellRenderer={customRowRenderer}
               rowHeight={width/7}
               rowCount={Math.ceil(props.media_items.length/7)}
               width={adjustedWidth}
             />
           )}
         </ColumnSizer>
        )}
      </AutoSizer>
    </div>
  );
}

export default MediaFlex