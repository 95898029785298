import React from "react";
import { deviceTypes } from "@types";
import styled from "styled-components";

const DeviceCount = styled.p`
  text-align: center;
  font-size: 18px;
  color: #519acc;
  font-weight: 600;
`;

const UserDeviceCountCell = ({ device }) => {
  return (
    <div>
      {!Number.isNaN(device?.user?.devices_checked_out) && (
        <DeviceCount aria-hidden="true">
          {device.user.devices_checked_out}
        </DeviceCount>
      )}
    </div>
  );
};

UserDeviceCountCell.propTypes = {
  device: deviceTypes,
};

export default UserDeviceCountCell;
