import React from 'react'

class LoginIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
      >
        <path d="M32,15.14A16.64,16.64,0,0,0,15.42,31.83V41.7H13A10.46,10.46,0,0,0,2.54,52.14V80A10.46,10.46,0,0,0,13,90.47H25.45l.06,1.45a12.92,12.92,0,0,0,12.83,12.35h74.48a12.85,12.85,0,0,0,12.83-12.83V76A12.85,12.85,0,0,0,112.82,63.2H61.53V52.28A10.45,10.45,0,0,0,51.09,41.83H48.66v-10A16.71,16.71,0,0,0,32,15.14ZM20.91,31.83a11.06,11.06,0,0,1,22.12,0V41.7H20.91ZM112.82,68a8,8,0,0,1,8,8V91.3a8,8,0,0,1-8,8H38.34a8,8,0,0,1-8-8V76a8,8,0,0,1,8-8ZM51.09,47.46a4.77,4.77,0,0,1,4.82,4.82V63.2H38.34A12.85,12.85,0,0,0,25.51,76V85H13a4.77,4.77,0,0,1-4.82-4.82V52.28A4.77,4.77,0,0,1,13,47.46Z"/>
        <circle cx="46.31" cy="83.6" r="4.87"/>
        <circle cx="65.82" cy="83.6" r="4.87"/>
        <circle cx="85.34" cy="83.6" r="4.87"/>
        <circle cx="104.85" cy="83.6" r="4.87"/>
      </svg>
    );
  }
}
LoginIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default LoginIcon