import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import Swal from "sweetalert2";
import _ from "lodash";
import { deviceTypes } from "@types";
import useDeviceLab from "@hooks/data/useDeviceLab";
import RoundDropdown from "../icons/round_dropdown";
import { CalendarIcon } from "../common/icons";
import { dropdownIndicator } from "../common/select_utils";
import LastFive from "./last_five";

const ACTIVE_OPTIONS = [
  { label: "Active", value: true },
  { label: "Retired", value: false },
];

const AddDeviceModal = (props) => {
  const {
    data: { device, closeModal },
  } = props;

  const {
    settings: { options },
    hooks: {
      mutations: { useCreateDevice, useEditDevice },
    },
  } = useDeviceLab();

  const [createDevice] = useCreateDevice();
  const [editDevice] = useEditDevice();

  const isEditing = useMemo(() => {
    return !!device;
  }, [device]);

  const methods = useForm({
    defaultValues: {
      device_type:
        isEditing &&
        options.Type.find((option) => device.device_type_id === option.value),
      device_os: isEditing && {
        label: device.device_os,
        value: device.device_os_id,
      },
      device_name:
        isEditing &&
        options.Name.find((option) => device.device_name_id === option.value),
      device_platform:
        isEditing &&
        options.Platform.find(
          (option) => device.device_platform_id === option.value
        ),
      device_make:
        isEditing &&
        options.Make.find((o) => o.value === device.device_make_id),
      device_size:
        isEditing &&
        options.Size.find((o) => o.value === device.device_size_id),
      serial: device?.serial || "",
      imei: device?.imei || "",
      sim: device?.sim || "",
      udid: device?.udid || "",
      notes: device?.notes || "",
      visible_notes: device?.visible_notes || "",
      visible: !!device?.visible,
      checkout_active: !!device?.checkout_active,
      office_only: !!device?.office_only,
      active: device ? device.active : true,
      main_building: device ? device.main_building : true,
      release_date: device?.release_date
        ? moment(device.release_date)
        : moment(),
    },
  });

  const { register, handleSubmit, watch, setValue, reset, formState } = methods;

  const [isDatePickerFocused, setIsDatePickerFocused] = useState(false);
  const [isAddAnotherDevice, setIsAddAnotherDevice] = useState(false);

  const isSubmitDisabled = useMemo(() => {
    return !formState.isDirty;
  }, [formState]);

  const handleResetForm = () => {
    reset();
    setIsDatePickerFocused(false);
    setIsAddAnotherDevice(false);
  };

  const handlePatchDevice = async (data) => {
    const result = await editDevice({ device: { ...data, id: device.id } });

    if (result.error) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: `<span role='alert'>${result.error.data.message}</span>`,
        displayLength: 5000,
        classes: "red",
      });
    } else {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "<span role='alert'>Device Updated Successfully</span>",
        displayLength: 3000,
        classes: "green",
      });

      closeModal();
    }
  };

  const handleCreateDevice = async (data) => {
    const result = await createDevice({ device: data });

    if (result.error) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: `<span role='alert'>${result.error.data.message}</span>`,
        displayLength: 5000,
        classes: "red",
      });
    } else {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "<span role='alert'>Device Created Successfully</span>",
        displayLength: 3000,
        classes: "green",
      });

      if (isAddAnotherDevice) {
        handleResetForm();
      } else {
        closeModal();
      }
    }
  };

  const onSubmit = (data) => {
    const body = _.pick(data, [
      "active",
      "checkout_active",
      "imei",
      "main_building",
      "notes",
      "office_only",
      "serial",
      "sim",
      "udid",
      "visible",
      "visible_notes",
    ]);
    if (data.release_date) {
      body.release_date = moment(data.release_date).format("YYYY-MM-DD");
    }
    for (const key of [
      "device_type",
      "device_os",
      "device_name",
      "device_platform",
      "device_make",
      "device_size",
    ]) {
      // yes, this is a confusing way to do this. what we want to do is pass the id
      // of an existing record, which is the "value" of the option, or pass the
      // name of a new record, which is the "label" on options with value marked as "new".
      // this will pass directly to accepts_nested_attributes_for and either
      // create a new record or associate our device with an existing one.
      const { value, label } = data[key];
      if (value === "new") {
        body[`${key}_attributes`] = { value: label };
      } else {
        body[`${key}_id`] = value;
      }
    }
    if (isEditing) {
      handlePatchDevice({ ...body, device_id: device.id });
    } else {
      handleCreateDevice(body);
    }
  };

  const onError = (errors) => {
    for (const error of Object.values(errors)) {
      if (error.message) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: error.message,
          classes: "red darken-1 error-toast",
        });
      }
    }
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    // open confirm if new device or form touched
    if (!isSubmitDisabled) {
      Swal.fire({
        title: !device ? "Discard New Device" : "Discard Changes",
        text: `Are you sure you would like to discard ${
          !device ? "this New Device" : "changes to this Device"
        }?`,
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          closeModal();
        }
      });
    } else {
      closeModal();
    }
  };

  const generateNewDeviceOption = (key, label) => {
    setValue(key, { value: "new", label }, { shouldDirty: true });
  };

  const [
    currentActive,
    currentDeviceType,
    currentDevicePlatform,
    currentDeviceName,
    currentDeviceMake,
    currentDeviceOs,
    currentDeviceSize,
    currentVisible,
    currentCheckoutActive,
    currentOfficeOnly,
    currentReleaseDate,
  ] = watch([
    "active",
    "device_type",
    "device_platform",
    "device_name",
    "device_make",
    "device_os",
    "device_size",
    "visible",
    "checkout_active",
    "office_only",
    "release_date",
  ]);

  return (
    <div className="modal-content">
      <div style={{ position: "relative" }}>
        <h2 className="large-title">
          {device ? "Edit Device" : "Add New Device"}
        </h2>
        <p className="subtitle" style={{ marginBottom: "15px" }}>
          Please provide as much information about the device as possible.
        </p>
        <CloseButtonContainer />
        <CloseButton
          type="button"
          onClick={handleCancelButton}
          className="icon-close-btn"
        >
          <span style={{ display: "none" }}>Close Add Device Form</span>
        </CloseButton>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="new-scrollbar"
        style={{
          maxHeight: "calc(95vh - 120px)",
          overflowY: "auto",
          marginTop: "20px",
          overflowX: "hidden",
        }}
      >
        <div className="new-device-general" style={{ marginRight: "20px" }}>
          <h3 className="small-title" style={{ margin: "0px 0 20px" }}>
            General
          </h3>
          <GeneralWrapper>
            <GeneralFormItem>
              <p className="field-title">Device Type</p>
              <CreatableSelect
                {...register("device_type", {
                  required: {
                    value: true,
                    message: "Device type is required.",
                  },
                })}
                name="device_type"
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={options.Type}
                value={currentDeviceType}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                onChange={(selected) => {
                  setValue("device_type", selected, { shouldDirty: true });
                }}
                onCreateOption={(inputValue) => {
                  generateNewDeviceOption("device_type", inputValue);
                }}
              />
            </GeneralFormItem>
            <GeneralFormItem>
              <p className="field-title">Device Platform</p>
              <CreatableSelect
                {...register("device_platform", {
                  required: {
                    value: true,
                    message: "Device platform is required.",
                  },
                })}
                name="device_platform"
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={options.Platform}
                value={currentDevicePlatform}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                onChange={(selected) => {
                  setValue("device_platform", selected, { shouldDirty: true });
                }}
                onCreateOption={(inputValue) => {
                  generateNewDeviceOption("device_platform", inputValue);
                }}
              />
            </GeneralFormItem>
            <GeneralFormItem>
              <p className="field-title">Device Name</p>
              <CreatableSelect
                {...register("device_name", {
                  required: {
                    value: true,
                    message: "Device name is required.",
                  },
                })}
                name="device_name"
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={options.Name}
                value={currentDeviceName}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                onChange={(selected) => {
                  setValue("device_name", selected, { shouldDirty: true });
                }}
                onCreateOption={(inputValue) => {
                  generateNewDeviceOption("device_name", inputValue);
                }}
              />
            </GeneralFormItem>
            <GeneralFormItem>
              <p className="field-title">Device Make</p>
              <CreatableSelect
                {...register("device_make", {
                  required: {
                    value: true,
                    message: "Device make is required.",
                  },
                })}
                name="device_make"
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={options.Make}
                value={currentDeviceMake}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                onChange={(selected) => {
                  setValue("device_make", selected, { shouldDirty: true });
                }}
                onCreateOption={(inputValue) => {
                  generateNewDeviceOption("device_make", inputValue);
                }}
              />
            </GeneralFormItem>
            <GeneralFormItem>
              <p className="field-title">Device OS</p>
              <CreatableSelect
                {...register("device_os", {
                  required: { value: true, message: "Device OS is required." },
                })}
                name="device_os"
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={options.Os}
                value={currentDeviceOs}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                onChange={(selected) => {
                  setValue("device_os", selected, { shouldDirty: true });
                }}
                onCreateOption={(inputValue) => {
                  generateNewDeviceOption("device_os", inputValue);
                }}
              />
            </GeneralFormItem>
            <GeneralFormItem>
              <p className="field-title">Device Size</p>
              <CreatableSelect
                {...register("device_size")}
                name="device_size"
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={options.Size}
                value={currentDeviceSize}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                onChange={(selected) => {
                  setValue("device_size", selected, { shouldDirty: true });
                }}
                onCreateOption={(inputValue) => {
                  generateNewDeviceOption("device_size", inputValue);
                }}
              />
            </GeneralFormItem>
          </GeneralWrapper>
        </div>
        <div className="new-device-details" style={{ marginRight: "20px" }}>
          <h3 style={{ margin: "20px 0" }}>More Details</h3>
          {device && <LastFive device={device} />}

          <MoreDetailsSection>
            <MoreDetailsInputItem>
              <p className="common-input-label">Serial Number</p>
              <input
                {...register("serial", {
                  required: {
                    value: true,
                    message: "Serial number is required",
                  },
                })}
                maxLength={42}
                autoComplete="off"
                placeholder="Enter a serial number"
                type="text"
                className="common-input browser-default"
                name="serial"
              />
            </MoreDetailsInputItem>
            <MoreDetailsInputItem>
              <p className="common-input-label">IMEI</p>
              <input
                {...register("imei")}
                maxLength={42}
                autoComplete="off"
                placeholder="Enter a 15 digit IMEI number"
                type="text"
                className="common-input browser-default"
                name="imei"
              />
            </MoreDetailsInputItem>
            <MoreDetailsInputItem>
              <p className="common-input-label"> SIM </p>
              <input
                {...register("sim")}
                maxLength={42}
                autoComplete="off"
                placeholder="eg. (503) 555-5555"
                type="text"
                className="common-input browser-default"
                name="sim"
              />
            </MoreDetailsInputItem>
            <MoreDetailsInputItem>
              <p className="common-input-label">UDID</p>
              <input
                {...register("udid")}
                maxLength={42}
                autoComplete="off"
                placeholder="Enter a 25 or 40 digit UDID number"
                type="text"
                className="common-input browser-default"
                name="udid"
              />
            </MoreDetailsInputItem>
            <MoreDetailsInputItem>
              <p className="common-input-label"> Notes </p>
              <textarea
                {...register("notes")}
                name="notes"
                placeholder="eg. Device has three scratches on screen"
                className="common-textarea"
                maxLength={1000}
              />
            </MoreDetailsInputItem>
            <MoreDetailsInputItem>
              <p className="common-input-label">Notes Visible to Testers</p>
              <textarea
                {...register("visible_notes")}
                placeholder="eg. This device has a blue case"
                name="visible_notes"
                className="common-textarea"
                maxLength={1000}
              />
            </MoreDetailsInputItem>
          </MoreDetailsSection>
          <FinalDetailsRow>
            <div style={{ width: "120px", textAlign: "center" }}>
              <p
                className="common-input-label"
                style={{ marginBottom: "10px", whiteSpace: "nowrap" }}
              >
                Visible to Clients
              </p>
              <label style={{ position: "relative" }}>
                <input
                  {...register("visible")}
                  checked={currentVisible === true}
                  name="visible"
                  className="filled-in"
                  type="checkbox"
                  onChange={() => {
                    setValue("visible", !currentVisible, {
                      shouldDirty: true,
                    });
                  }}
                />
                <span />
              </label>
            </div>
            <div style={{ width: "130px", textAlign: "center" }}>
              <p
                className="common-input-label"
                style={{ marginBottom: "10px", whiteSpace: "nowrap" }}
              >
                Visible to Checkout
              </p>
              <label style={{ position: "relative" }}>
                <input
                  {...register("checkout_active")}
                  checked={currentCheckoutActive === true}
                  name="checkout_active"
                  className="filled-in"
                  type="checkbox"
                  onChange={() => {
                    setValue("checkout_active", !currentCheckoutActive, {
                      shouldDirty: true,
                    });
                  }}
                />
                <span />
              </label>
            </div>
            <div style={{ width: "80px", textAlign: "center" }}>
              <p
                className="common-input-label"
                style={{ marginBottom: "10px", whiteSpace: "nowrap" }}
              >
                Office Only
              </p>
              <label style={{ position: "relative" }}>
                <input
                  {...register("office_only")}
                  checked={currentOfficeOnly === true}
                  name="office_only"
                  className="filled-in"
                  type="checkbox"
                  onChange={() => {
                    setValue("office_only", !currentOfficeOnly, {
                      shouldDirty: true,
                    });
                  }}
                />
                <span />
              </label>
            </div>
            <div>
              <p style={{ marginBottom: "0px" }} className="common-input-label">
                Status
              </p>
              <Select
                {...register("active")}
                name="active"
                onChange={(option) => {
                  setValue("active", option.value, { shouldDirty: true });
                }}
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={ACTIVE_OPTIONS}
                value={ACTIVE_OPTIONS.find((o) => o.value === currentActive)}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                isSearchable={false}
                className="short"
              />
            </div>
            <div>
              <p style={{ marginBottom: "0px" }} className="common-input-label">
                Device Lab
              </p>
              <Select
                {...register("main_building")}
                name="main_building"
                onChange={(option) => {
                  setValue("main_building", option.value, {
                    shouldDirty: true,
                  });
                }}
                styles={{
                  menu: (styles) => {
                    return { ...styles, zIndex: "2" };
                  },
                }}
                options={[{ value: true, label: "Main Building" }]}
                defaultValue={{ value: true, label: "Main Building" }}
                components={{ DropdownIndicator: dropdownIndicator }}
                classNamePrefix="select"
                blurInputOnSelect
                isSearchable={false}
                className="short"
              />
            </div>
            <div style={{ width: "145px" }}>
              <p
                className="small-title"
                style={{
                  position: "relative",
                  color: "#242424",
                  marginTop: "11px",
                  display: "block",
                }}
              >
                Release Date
              </p>
              <DatePickerWrapper>
                <DatePickerCalendar>
                  <CalendarIcon
                    style={{ width: "30px", verticalAlign: "bottom" }}
                    className="icons"
                  />
                </DatePickerCalendar>
                <SingleDatePicker
                  {...register("release_date")}
                  id="add_device_release_date_select"
                  autoFocus
                  date={currentReleaseDate}
                  numberOfMonths={1}
                  placeholder="Select a date"
                  isOutsideRange={() => false}
                  focused={isDatePickerFocused}
                  onFocusChange={() =>
                    setIsDatePickerFocused(!isDatePickerFocused)
                  }
                  onDateChange={(date) =>
                    setValue("release_date", date, {
                      shouldDirty: true,
                    })
                  }
                  orientation="horizontal"
                  openDirection="down"
                  daySize={30}
                  navPrev={
                    <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                      <RoundDropdown rotate="rotate(90deg)" />
                    </div>
                  }
                  navNext={
                    <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                      <RoundDropdown rotate="rotate(-90deg)" />
                    </div>
                  }
                  hideKeyboardShortcutsPanel
                  showDefaultInputIcon={false}
                />
              </DatePickerWrapper>
            </div>
          </FinalDetailsRow>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "40px 0 30px",
          }}
        >
          {!isEditing && (
            <label style={{ position: "relative", marginRight: "20px" }}>
              <input
                onChange={() => setIsAddAnotherDevice(!isAddAnotherDevice)}
                checked={isAddAnotherDevice}
                name="add_another"
                className="filled-in"
                type="checkbox"
              />
              <span
                style={{
                  lineHeight: "20px",
                  color: "#242424",
                  fontSize: "15px",
                }}
              >
                Add Another
              </span>
            </label>
          )}
          <button
            type="button"
            className="common-button-cancel"
            onClick={handleCancelButton}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitDisabled}
            className="common-button-submit"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const DatePickerWrapper = styled.div`
  position: relative;

  .SingleDatePickerInput {
    padding-left: 36px;
    width: 110px;
  }

  .SingleDatePicker_picker {
    bottom: 37px;
    right: 0px;
    top: unset !important;
    left: unset !important;
  }

  .DateInput {
    width: auto;
  }
`;

const DatePickerCalendar = styled.div`
  position: absolute;
  left: 4px;
  z-index: 1;
  top: 5px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: -5px;
`;

const CloseButtonContainer = styled.div`
  width: calc(100% + 40px);
  position: relative;
  border-bottom: solid 1px #d0d0d0;
  left: -20px;
`;

const FinalDetailsRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  margin-top: 20px;
  justify-content: space-between;
`;

const MORE_DETAILS_FORM_ITEMS_GUTTER = "16px";
const MoreDetailsSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: ${MORE_DETAILS_FORM_ITEMS_GUTTER};
`;

const MoreDetailsInputItem = styled.div`
  padding-right: 10px;
  flex-basis: calc(50% - ${MORE_DETAILS_FORM_ITEMS_GUTTER});

  textarea,
  input,
  p {
    margin: 0;
  }

  textarea {
    height: 120px;
    border: 1px solid lightgrey;
  }
`;

const GENERAL_FORM_ITEMS_GUTTER = "16px";
const GeneralFormItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: calc(33% - ${GENERAL_FORM_ITEMS_GUTTER});
`;

const GeneralWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${GENERAL_FORM_ITEMS_GUTTER};
`;

AddDeviceModal.propTypes = {
  data: PropTypes.shape({
    device: deviceTypes.isRequired,
    closeModal: PropTypes.func.isRequired,
  }),
};

export default AddDeviceModal;
