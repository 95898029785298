import React from "react";
import BugChart from "./bug_chart";

function BugsRow(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
        borderRadius: "5px",
        boxShadow: "0px 0px 3px 1px lightgrey",
        margin: "5px 5px 0 5px",
        minHeight: "325px",
      }}
      className="dash-row-1"
    >
      <div style={{ display: "flex", flex: 1 }}>
        <div style={{ flex: "1", height: "325px" }}>
          <BugChart bugCount={props.bugCount} projectId={props.project.id} />
        </div>
      </div>
    </div>
  );
}

export default BugsRow;
