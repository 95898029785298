import React from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import SingleSelectStyles from "../common/select_styles";
import MultiSelectWrapper from "../common/multi_select_wrapper";
import { customControl, dropdownIndicator } from "../common/select_utils";

class TeamModal extends React.Component {
  constructor(props) {
    super(props);
    var options = [];
    var projectOptions = [];
    this.props.projects.map((x) =>
      projectOptions.push({ value: x.id, label: x.name })
    );
    this.props.users.map((x) =>
      options.push({
        value: x.id,
        label: x.name != null ? (x.name != "" ? x.name : x.email) : x.email,
      })
    );
    this.state = {
      nameInput: this.props.team ? this.props.team.name : "",
      selectedUsers: this.props.team ? this.props.team.team_members : [],
      users: this.props.users,
      options: options,
      selectedOptionOwner: {
        label:
          this.props.current_user.name != null
            ? this.props.current_user.name
            : this.props.current_user.email,
        value: this.props.current_user.id,
      },
      loading: false,
    };

    this.NewTeamModal = React.createRef();
  }

  componentDidMount() {
    M.Modal.init(this.NewTeamModal, { dismissible: false, endingTop: "10%" });
    M.Modal.getInstance(this.NewTeamModal).open();
  }

  closeAddTeamMemberModal = () => {
    M.Modal.getInstance(this.NewTeamModal).close();
    ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer"));
  };

  handleChange = (e) => {
    if (e == null) {
      e = [];
    }
    this.setState({ selectedUsers: e });
  };

  submitNewTeam = (formData) => {
    this.setState({ loading: true });
    var component = this;
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/teams.json", true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 201) {
        var resp = JSON.parse(xhr.response);
        component.props.handler(resp.team);
        Swal.fire({
          title: "Team Created",
          text: "Successfully Created",
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(() => {
          component.setState({ loading: true });
          component.closeAddTeamMemberModal();
        });
      } else {
        component.setState({ loading: true });
        Swal.fire("New team error", "There was an issue");
      }
    };
    xhr.send(formData);
  };

  submitEditTeam = (formData) => {
    this.setState({ loading: true });
    var component = this;
    var xhr = new XMLHttpRequest();
    xhr.open("PATCH", `/teams/${this.props.team.id}.json`, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 200) {
        var resp = JSON.parse(xhr.response);
        component.props.editHandler(resp);
        Swal.fire({
          title: "Team Updated",
          text: "Successfully Updated",
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(() => {
          component.setState({ loading: true });
          component.closeAddTeamMemberModal();
        });
      } else {
        component.setState({ loading: true });
        Swal.fire("New team error", "There was an issue");
      }
    };
    xhr.send(formData);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var selectedUsers = this.state.selectedUsers;
    if (!selectedUsers) {
      return;
    }
    var formData = new FormData();
    var memberIds = [];
    for (var i = 0; i < selectedUsers.length; i++) {
      var user = selectedUsers[i];
      memberIds.push(user.value);
    }
    formData.append("team[name]", this.state.nameInput);
    formData.append("team[owner_id]", this.state.selectedOptionOwner.value);
    formData.append("team_member[user_ids][]", memberIds);
    if (this.props.edit) {
      this.submitEditTeam(formData);
    } else {
      this.submitNewTeam(formData);
    }
    return;
  };

  handleInputChange = (event) => {
    var target = event.target;
    var value = target.value;
    var name = target.name;
    this.setState({ [name]: value });
  };

  render() {
    var users = this.state.users;
    var members = this.state.selectedUsers;
    members.map(function (member) {
      if (member.value == undefined) {
        if (member.user == undefined) {
          member.value = member.user_id;
          member.label = member.user_name;
        } else {
          member.value = member.user.id;
          member.label =
            member.user_name != "" ? member.user_name : member.user.email;
        }
      }
      if (member.user == undefined) {
        member.user = {};
        member.user.id = member.value;
      }
      return member;
    });

    users.map(function (user) {
      user.value = user.id;
      user.label = user.name != "" ? user.name : user.email;
      return user;
    });

    var handleSelectChangeOwner = (selectedOption) => {
      this.setState({ selectedOptionOwner: selectedOption });
    };
    var disabled = !(
      this.state.selectedUsers.length > 0 && this.state.nameInput != ""
    );

    return (
      <div
        ref={(node) => {
          this.NewTeamModal = node;
        }}
        id="newTeamModal"
        className="modal gray lighten-3 row adminModal"
      >
        <div
          className={`modal-backdrop-loader ${
            this.state.loading
              ? "modal-backdrop-loader-open"
              : "modal-backdrop-loader-close"
          }`}
        >
          {this.state.loading && <div id="test-step-bugs-loader"></div>}
        </div>
        <div className="modal-content">
          <div className="flex-container flex-column">
            <div>
              <h3 className="small-title">
                {this.props.edit
                  ? "Edit an existing Team"
                  : "Create a new Team"}
              </h3>
              <p style={{ marginBottom: "20px" }} className="subtitle">
                {this.props.edit
                  ? "Modify you existing team details below."
                  : "Complete all required fields below."}
              </p>
              <button
                type="button"
                style={{ position: "absolute", top: "21px", right: "19px" }}
                className="right icon-close-btn"
                onClick={this.closeAddTeamMemberModal}
              ></button>
            </div>
            <div style={{ marginTop: "35px", height: "216px" }} id="team-form">
              <form
                style={{ height: "100%" }}
                className="form-horizontal"
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <div style={{ height: "100%" }} className="form-group">
                  <div
                    style={{ height: "100%", marginBottom: "2px" }}
                    className="flex-container"
                  >
                    <div
                      style={{
                        borderRight: "1px solid lightgrey",
                        padding: "0 20px 0 0",
                      }}
                    >
                      <p className="input-title">
                        Team Name<span className="bc-blue">*</span>
                      </p>
                      <input
                        style={{ width: "100%", marginTop: "7px" }}
                        type="text"
                        className="common-input browser-default"
                        name="nameInput"
                        id="teamMember[name]"
                        value={this.state.nameInput}
                        placeholder="Team Name"
                        onChange={this.handleInputChange}
                        required
                      />
                      <div>
                        <p className="input-title">
                          Team Owner<span className="bc-blue">*</span>
                        </p>
                        <Select
                          aria-label="Owner dropdown for new team modal"
                          value={this.state.selectedOptionOwner}
                          onChange={handleSelectChangeOwner}
                          options={this.state.options}
                          styles={SingleSelectStyles}
                          blurInputOnSelect
                          components={{
                            DropdownIndicator: dropdownIndicator,
                            Control: customControl,
                          }}
                          classNamePrefix="selectbox-menu"
                          required
                        />
                      </div>
                    </div>
                    <MultiSelectWrapper
                      label={
                        <label
                          className="select-labels"
                          htmlFor="filter_filters"
                        >
                          Select Users for Team
                          <span className="bc-blue">*</span>
                        </label>
                      }
                      aria-label="Users dropdown for edit team modal"
                      value={members}
                      isSearchable
                      onChange={this.handleChange}
                      name="colors"
                      isClearable={false}
                      closeMenuOnSelect={false}
                      options={users}
                      placeholder="Search for Users"
                      width="600px"
                      tagStyle
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    margin: "13px 0px 20px",
                  }}
                >
                  <button
                    onClick={this.closeAddTeamMemberModal}
                    type="button"
                    className="common-button-cancel"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={disabled}
                    type="submit"
                    className="common-button-submit"
                  >
                    {this.props.edit ? "Save & Exit" : "Create Team"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamModal;
