import React, { useMemo, useState, useEffect } from "react";
import ApiClient from "@utils/ApiClient";
import BasicSelect from "../common/basic_select";

const [WINDOWS, MAC, IOS, ANDROID, CHROMEOS, WEARABLE] = [1, 2, 3, 4, 5, 6];

const PlatformLabels = {
  [WINDOWS]: "Windows",
  [MAC]: "Mac",
  [IOS]: "iOS",
  [ANDROID]: "Android",
  [CHROMEOS]: "ChromeOS",
  [WEARABLE]: "Wearable",
};

const AddTestDevice = (props) => {
  /*
   * TODO:
   * Our method for grabbing device/platform/os options data is messy and inconsistent. We grab data via API call to `/tc_device_options`, then
   * do cumbersome data massaging here in our front end components. Further, other calls to this same endpoint do their own data massaging,
   * and not the same methods taken here. Ideally, we should be grabbing available options on the backend, shaping that data as uniform JSON
   * provided as response, and then that data can be passed directly from there to our dropdown selects below, and have this process be
   * the same everywhere on the frontend that calls `/tc_device_options`.
   * Currently, calls to that endpoint are happening in edit_test_device.jsx and new_test_pass.jsx.
   * For now, leaving this here as, hopefully, a clearer way to present available and valid platform/browser/os options (e.g. do not allow
   * Platform: Windows, Browser: Safari). As a further enhancement, that data validation check should be done on the backend as well.
   */
  const { data, setBackdropLoader, modalAction } = props;

  const [selectedPlatform, setSelectedPlatform] = useState({
    label: PlatformLabels[data.currentPlatform] || "Wearable",
    value: data.currentPlatform,
  });

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedOS, setSelectedOS] = useState(null);
  const [selectedBrowser, setSelectedBrowser] = useState(null);

  const [deviceOptionData, setDeviceOptionData] = useState({});

  const fetchDeviceOptions = () => {
    setBackdropLoader(true);
    const api = new ApiClient();
    api
      .get("/tc_device_options.json")
      .then((res) => {
        setDeviceOptionData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setBackdropLoader(false);
      });
  };

  const PLATFORM = useMemo(() => {
    const current = selectedPlatform;
    const options = [
      { label: "Windows", value: WINDOWS },
      { label: "Mac", value: MAC },
      { label: "iOS", value: IOS },
      { label: "Android", value: ANDROID },
      { label: "ChromeOS", value: CHROMEOS },
      { label: "Wearable", value: WEARABLE },
    ];

    const cb = (option) => {
      setSelectedPlatform(option);
      setSelectedOS(null);
      setSelectedDevice(null);
      setSelectedBrowser(null);
    };

    return { options, cb, current };
  }, [selectedPlatform]);

  const DEVICE = useMemo(() => {
    const render = [IOS, ANDROID, WEARABLE].includes(selectedPlatform.value);
    const current = selectedDevice;
    const options = {
      [IOS]: [...new Set(deviceOptionData.iosDeviceOptions)]
        .sort()
        .map((device) => {
          return {
            label: device[0],
            value: device[0],
            deviceType: device[1],
          };
        }),
      [ANDROID]: [...new Set(deviceOptionData.androidDeviceOptions)]
        .sort()
        .map((device) => {
          return {
            label: device[0],
            value: device[0],
            deviceType: device[1],
          };
        }),
      [WEARABLE]: Array.from(
        new Set(deviceOptionData.wearableDeviceOptions?.map(JSON.stringify)) ||
          [],
        JSON.parse
      )
        .sort()
        .map((device) => {
          return {
            label: device[0],
            value: device[0],
            deviceType: device[1],
          };
        }),
    };
    const cb = (option) => {
      setSelectedDevice(option);
    };

    return {
      render,
      options: options[selectedPlatform.value] || [],
      cb,
      current,
    };
  }, [selectedDevice, selectedPlatform, deviceOptionData]);

  const OS = useMemo(() => {
    const current = selectedOS;

    const dataToOptions = (list) => {
      if (!list) return [];
      return [...new Set(list)].map((os) => ({ label: os, value: os }));
    };

    const buildOptions = {
      [WINDOWS]: [
        "Windows 11",
        "Windows 10",
        "Windows 8",
        "Windows 7",
        "Windows Vista",
        "Windows XP",
      ].map((os) => ({ label: os, value: os })),
      [MAC]: dataToOptions(
        [...(deviceOptionData.macOsOptions || [])].map((os) => {
          return os.split(".").length > 2
            ? os.slice(0, os.lastIndexOf("."))
            : os.slice(0, os.indexOf(".") + 3);
        })
      ),
      [IOS]: dataToOptions(
        [...(deviceOptionData.iosOsOptions || [])]
          .map((os) => os.slice(0, os.indexOf(".")))
          .sort(
            (a, b) =>
              parseInt(b.split(" ")[1], 10) - parseInt(a.split(" ")[1], 10)
          )
      ),
      [ANDROID]: dataToOptions(
        [...(deviceOptionData.androidOsOptions || [])]
          .map((os) => os.slice(0, os.indexOf(".")))
          .sort(
            (a, b) =>
              parseInt(b.split(" ")[1], 10) - parseInt(a.split(" ")[1], 10)
          )
      ),
      [CHROMEOS]: dataToOptions([...(deviceOptionData.chromeOsOptions || [])]),
      [WEARABLE]: dataToOptions([
        ...(deviceOptionData.wearableOsOptions || []),
      ]),
    };

    const cb = (option) => {
      setSelectedOS(option);
    };

    return {
      options: buildOptions[selectedPlatform.value] || [],
      cb,
      current,
    };
  }, [selectedOS, selectedPlatform, deviceOptionData]);

  const BROWSER = useMemo(() => {
    const render = [WINDOWS, MAC, IOS, ANDROID, CHROMEOS].includes(
      selectedPlatform.value
    );
    const current = selectedBrowser;
    const options = [
      {
        value: "Internet Explorer 11",
        label: "Internet Explorer 11",
        allowed: [WINDOWS],
      },
      {
        value: "Microsoft Edge",
        label: "Microsoft Edge",
      },
      {
        value: "Chrome",
        label: "Chrome",
      },
      {
        value: "Firefox",
        label: "Firefox",
      },
      {
        value: "Safari",
        label: "Safari",
        allowed: [IOS, MAC],
      },
      {
        value: "Safari14",
        label: "Safari 14",
        allowed: [IOS, MAC],
      },
      {
        value: "Safari15",
        label: "Safari 15",
        allowed: [IOS, MAC],
      },
      {
        value: "Safari16",
        label: "Safari 16",
        allowed: [IOS, MAC],
      },
      {
        value: "Safari17",
        label: "Safari 17",
        allowed: [IOS, MAC],
      },
      {
        value: "Opera",
        label: "Opera",
      },
      {
        value: "Native",
        label: "Native",
        allowed: [ANDROID],
      },
      {
        value: "Facebook",
        label: "Facebook",
      },
      {
        value: "Instagram",
        label: "Instagram",
      },
      {
        value: "Twitter",
        label: "Twitter",
      },
      {
        value: "Other",
        label: "Other",
      },
    ]
      .filter(({ allowed }) => {
        if (!allowed) return true;
        return allowed.includes(selectedPlatform.value);
      })
      .map((b) => ({ label: b.label, value: b.value }));

    const cb = (option) => {
      setSelectedBrowser(option);
    };

    return { render, options, cb, current };
  }, [selectedBrowser, selectedPlatform]);

  const handleSubmit = () => {
    const api = new ApiClient();
    const position =
      [
        ...data.testDevices,
        { name: "new_device", device_platform_id: selectedPlatform.value },
      ]
        .sort((a, b) => a.device_platform_id - b.device_platform_id)
        .map((d) => d.name)
        .indexOf("new_device") + 1;
    setBackdropLoader(true);

    api
      .post("/test_devices.json", {
        test_device: {
          test_pass_id: data.testPassId,
          position,
          test_device: {
            device_name: selectedDevice?.label || "",
            os_name: selectedOS?.label,
            browser_name: selectedBrowser?.label || "",
            value: selectedPlatform?.value,
            device_type_id:
              selectedDevice?.deviceType ||
              (selectedPlatform.value === 5 ? 3 : 4),
          },
        },
      })
      .then(() => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `${selectedPlatform.label} Device Added`,
          displayLength: 2000,
          classes: "green darken-1 shift-toast",
        });
        modalAction(false, "", {});
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setBackdropLoader(false);
      });
  };

  useEffect(() => {
    fetchDeviceOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div id="add-test-device-form">
        <h4 style={{ marginBottom: "15px" }}>Add Test Device</h4>
        <div
          style={{
            position: "relative",
            marginBottom: "20px",
            display: "block",
            justifyContent: "space-between",
          }}
        >
          <BasicSelect
            name="device-platform"
            options={PLATFORM.options}
            onChange={PLATFORM.cb}
            selectedOption={PLATFORM.current}
            placeholder="Platform"
            width="122px"
            maxHeight="145px"
            display="inline-block"
            cascadeTransition={false}
            selectedTextStyle={{
              color: "#242424",
              fontWeight: "500",
              display: "block",
              width: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            containerStyle={{ verticalAlign: "bottom" }}
            menuTransitionTime={0.2}
            scrollIntoView="react-modal"
          />

          {DEVICE.render && (
            <BasicSelect
              name="device-device"
              options={DEVICE.options}
              onChange={DEVICE.cb}
              selectedOption={DEVICE.current}
              placeholder="Device"
              width="200px"
              maxHeight="145px"
              display="inline-block"
              cascadeTransition={false}
              selectedTextStyle={{
                color: "#242424",
                fontWeight: "500",
                display: "block",
                width: "162px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              containerStyle={{ verticalAlign: "bottom" }}
              menuTransitionTime={0.2}
              scrollIntoView="react-modal"
              searchEnabled
            />
          )}
          <BasicSelect
            name="device-os"
            options={OS.options}
            onChange={OS.cb}
            selectedOption={OS.current}
            placeholder="Operating System"
            width="200px"
            maxHeight="145px"
            display="inline-block"
            cascadeTransition={false}
            selectedTextStyle={{
              color: "#242424",
              fontWeight: "500",
              display: "block",
              width: "162px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            containerStyle={{ verticalAlign: "bottom" }}
            menuTransitionTime={0.2}
            scrollIntoView="react-modal"
            searchEnabled
          />
          {BROWSER.render && (
            <BasicSelect
              name="device-browser"
              options={BROWSER.options}
              onChange={BROWSER.cb}
              selectedOption={BROWSER.current}
              placeholder={
                <span>
                  Browser <span style={{ color: "#b7b7b7" }}>(Optional)</span>
                </span>
              }
              width="200px"
              maxHeight="145px"
              display="inline-block"
              cascadeTransition={false}
              selectedTextStyle={{
                color: "#242424",
                fontWeight: "500",
                display: "block",
                width: "162px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              containerStyle={{
                verticalAlign: "bottom",
                marginRight: "-15px",
              }}
              menuTransitionTime={0.2}
              scrollIntoView="react-modal"
            />
          )}
        </div>
        <button
          type="button"
          disabled={!selectedPlatform?.value || !selectedOS?.value}
          style={{ marginTop: "30px", display: "block", marginLeft: "auto" }}
          onClick={handleSubmit}
          className="btn"
        >
          Add Device
        </button>
      </div>
    </div>
  );
};

export default AddTestDevice;
