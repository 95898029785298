import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  PerformancePageLayout,
  PerformanceTitleRow,
  PerformanceTitle,
  HeaderIconContainer,
  PerformanceBody,
  ContextControlRow,
  ContextControl,
  ProjectAndDateSelectors,
  DashboardWidget,
  WidgetTitle,
  SplitWidget,
  SplitWidgetRow,
} from "./styles";
import {
  HoursBreakdown,
  PieBreakdown,
  BugsStepsDualBreakdown,
  UserList,
} from "./Widgets";
import DashboardContext from "./DashboardContext";
import PerformanceHeaderIcon from "./Components/PerformanceHeaderIcon";
import PillboxToggles from "./Components/PillboxToggles";
import FiltersSelect from "./Components/FiltersSelect";
import PerformanceDataCSVExport from "./Components/PerformanceDataCSVExport";
import usePerformanceDashboardData from "../../hooks/data/usePerformanceDashboardData";
import IconsLibrary from "../icons";
import { PROJECT, TEAM, USER, PROJECT_ID, TEAM_ID, USER_ID } from "./constants";

const { UserIcon, FolderIcon, CommunityIcon } = IconsLibrary;

const DEFAULT_FILTER = PROJECT;

const PerformanceDashboard = (props) => {
  const { projects, teams } = props;
  const [filterSelect, setFilterSelect] = useState(DEFAULT_FILTER);

  const {
    data: { dashboardData, queryParams },
    dashboardLoading,
    userSearch,
    error,
  } = usePerformanceDashboardData();

  const DashboardContextValue = useMemo(() => {
    return {
      dashboardData,
      dashboardLoading,
      userSearch,
      error,
      projects,
      teams,
      queryParams,
    };
  }, [
    dashboardData,
    dashboardLoading,
    userSearch,
    error,
    projects,
    teams,
    queryParams,
  ]);

  const filtersOptions = useMemo(() => {
    return [
      {
        label: "Project",
        key: PROJECT,
        icon: (
          <FolderIcon
            width={19}
            height={15}
            color={filterSelect === PROJECT ? "#ffffff" : "#519acc"}
          />
        ),
      },
      {
        label: "Team",
        key: TEAM,
        icon: (
          <CommunityIcon
            width={28}
            height={28}
            color={filterSelect === TEAM ? "#ffffff" : "#519acc"}
          />
        ),
      },
      {
        label: "User",
        key: USER,
        icon: (
          <UserIcon
            width={16}
            height={21}
            color={filterSelect === USER ? "#ffffff" : "#519acc"}
          />
        ),
      },
    ];
  }, [filterSelect]);

  useEffect(() => {
    if (USER_ID in queryParams) {
      setFilterSelect(USER);
    } else if (TEAM_ID in queryParams) {
      setFilterSelect(TEAM);
    } else if (PROJECT_ID in queryParams) {
      setFilterSelect(PROJECT);
    }
  }, [queryParams]);

  return (
    <DashboardContext.Provider value={DashboardContextValue}>
      <PerformancePageLayout>
        <PerformanceTitleRow>
          <HeaderIconContainer>
            <PerformanceHeaderIcon />
          </HeaderIconContainer>
          <PerformanceTitle>Performance</PerformanceTitle>
        </PerformanceTitleRow>
        <PerformanceBody>
          <ContextControlRow>
            <ContextControl>
              <PillboxToggles
                options={filtersOptions}
                defaultSelected={DEFAULT_FILTER || PROJECT}
                onSelect={setFilterSelect}
                controlledSelected={filterSelect}
              />
            </ContextControl>
            <ProjectAndDateSelectors>
              <FiltersSelect
                selected={filterSelect}
                users={dashboardData.users}
              />
            </ProjectAndDateSelectors>
          </ContextControlRow>
          <DashboardWidget>
            <WidgetTitle>Bugs and Test Step Breakdown</WidgetTitle>
            <BugsStepsDualBreakdown
              bugs={dashboardData.bugs}
              users={dashboardData.users}
              loading={dashboardLoading}
              parentFilterValue={filterSelect}
              queryParams={queryParams}
            />
          </DashboardWidget>
          <DashboardWidget>
            <WidgetTitle>Hours Breakdown</WidgetTitle>
            <HoursBreakdown
              data={dashboardData.hours}
              loading={dashboardLoading}
              parentFilterValue={filterSelect}
            />
          </DashboardWidget>
          <SplitWidgetRow>
            <SplitWidget>
              {dashboardData.platforms && (
                <PieBreakdown
                  data={dashboardData.platforms}
                  title="Platforms Tested"
                  parentFilterValue={filterSelect}
                />
              )}
            </SplitWidget>
            <SplitWidget>
              {dashboardData.tasks && (
                <PieBreakdown
                  data={dashboardData.tasks}
                  title="Task Breakdown"
                  parentFilterValue={filterSelect}
                />
              )}
            </SplitWidget>
          </SplitWidgetRow>
          <DataExportRow>
            <PerformanceDataCSVExport
              data={dashboardData}
              queryParams={queryParams}
            />
          </DataExportRow>
          <DashboardWidget>
            <UserList users={dashboardData?.users || []} title="User List" />
          </DashboardWidget>
        </PerformanceBody>
      </PerformancePageLayout>
    </DashboardContext.Provider>
  );
};

const DataExportRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 24px;
`;

PerformanceDashboard.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
      identifier: PropTypes.string,
    })
  ).isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default PerformanceDashboard;
