import React from 'react'
import AccountComponent from './account_component.jsx'


class AccountContainer extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  userHandler = (user) => {
    this.setState({user: user})
  }

  render() {
    return (
      <AccountComponent achievements={this.props.achievements} user={this.state.user} project={this.props.project} userHandler={this.userHandler} />    
    );
  }
}

export default AccountContainer