import React from "react";

class PlusIcon extends React.Component {
  render() {
    return (
      <svg
        aria-hidden="true"
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path d="M55.83,25.68a6.53,6.53,0,0,1,6.65,5.85,6.23,6.23,0,0,1-6.21,6.58H38.08v.21l0,18a6.22,6.22,0,0,1-12.44,0V38.41c0-.06,0-.1-.09-.09H8.19a6.51,6.51,0,0,1-6.67-5.84,6.25,6.25,0,0,1,6.22-6.59h18.2V7.68a6.22,6.22,0,0,1,12.44,0V25.6a.13.13,0,0,0,.09.09Z"/>
      </svg>
    );
  }
}

PlusIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "white"
}

export default PlusIcon;