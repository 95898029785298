import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import moment from "moment";
import ProgressRing from "../common/progress_ring.jsx";

class UserShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      nameInput: this.props.user.name != null ? this.props.user.name : "",
      emailInput: this.props.user.email != null ? this.props.user.email : "",
      factInput: this.props.user.fact != null ? this.props.user.fact : "",
      titleInput: this.props.user.title != null ? this.props.user.title : "",
      employee_started_at: moment(this.props.user.employee_started_at).format(
        "YYYY-MM-DD"
      ),
      oldPass: "",
      newPass: "",
      avatar_image:
        this.props.user.avatar_image != null
          ? this.props.user.avatar_image
          : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svgns_User%20Default.svg",
      selectAllState: false,
      newPassConfirm: "",
      loadingImage: false,
      passwordValidate: 0,
      percentCompleted: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitEditUser = this.submitEditUser.bind(this);
    this.updateMembership = this.updateMembership.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.passwordValidate = this.passwordValidate.bind(this);
    this.updateAllMemberships = this.updateAllMemberships.bind(this);
    this.addAvatar = this.addAvatar.bind(this);
    this.file = React.createRef();
    this.handleDeleteAvatar = this.handleDeleteAvatar.bind(this);
    this.deleteAvatar = this.deleteAvatar.bind(this);

    this.handleFileDragEnter = this.handleFileDragEnter.bind(this);
    this.handleFileDragLeaveOrDrop = this.handleFileDragLeaveOrDrop.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    // this.setSidebarWidth = this.setSidebarWidth.bind(this)
  }

  // setSidebarWidth() {
  //   var width = this.state.sideBarWidth == 300 ? 100 : 300
  //   this.setState({sideBarWidth: width, expanded: !this.state.expanded})
  // }

  handleFileDragEnter() {
    if (
      $(".avatar-background").css("background-color") == "rgb(211, 211, 211)"
    ) {
      $(".avatar-background").css("background-color", "#519acc");
    }
  }

  handleFileDragLeaveOrDrop(e) {
    if (
      e.type === "drop" ||
      e.target.parentElement.classList.contains("avatar-background")
    ) {
      $(".avatar-background").css("background-color", "rgb(211, 211, 211)");
    }
  }

  handleChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  passwordValidate() {
    if (
      (this.state.newPass != "" || this.state.newPassConfirm != "") &&
      this.state.newPassConfirm == this.state.newPass
    ) {
      // password match and aren't empty throw valid message
      return 1;
    }
    if (
      this.state.newPass != "" ||
      (this.state.newPassConfirm != "" &&
        this.state.newPassConfirm != this.state.newPass)
    ) {
      // passwords don't match and aren't empty throw invalid
      return 0;
    }
    // nothing is entered don't THROW ANYTHING AT ANYONE!!!
    return 2;
  }

  formSubmittable() {
    if (this.state.oldPass == "") {
      return false;
    }
    if (this.passwordValidate == 0) {
      return true;
    }
    if (this.passwordValidate == 1) {
      return false;
    }
    return true;
  }

  submitEditUser() {
    console.log("Sorry you can't do that here");
  }

  handleDeleteAvatar() {
    console.log("Sorry you can't do that here");
  }

  deleteAvatar() {
    console.log("Sorry you can't do that here");
  }

  addAvatar() {
    console.log("Sorry you can't do that here");
  }

  handleSubmit() {
    console.log("Sorry you can't do that here");
  }

  updateAllMemberships() {
    console.log("Sorry you can't do that here");
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  handleDateChange(e) {
    this.setState({ employee_started_at: e.target.value });
  }

  updateMembership() {
    console.log("Sorry you can't do that here");
  }

  render() {
    const valid = this.passwordValidate();
    const submittable = this.formSubmittable();
    const projects = [];
    for (let i = 0; i < this.state.user.memberships.length; i++) {
      const membership = this.state.user.memberships[i];
      const checked = membership.email_notifications;
      projects.push(
        <div className="col s11" key={membership.id}>
          <span>
            {membership.project.name != undefined
              ? membership.project.name
              : "moo"}
          </span>
          <div className="switch right">
            <label>
              Off
              <input type="hidden" value={membership.id} />
              <input
                type="checkbox"
                key={membership.id}
                checked={checked}
                onChange={this.updateMembership}
              />
              <span className="lever" />
              On
            </label>
          </div>
        </div>
      );
    }
    if (this.state.user.memberships.length >= 2) {
      projects.push(
        <div className="col s11" key="last">
          <span>Select All</span>
          <div className="switch right">
            <label>
              Off
              <input
                disabled
                type="checkbox"
                key="last"
                checked={this.state.selectAllState}
                onChange={this.updateAllMemberships}
              />
              <span className="lever" />
              On
            </label>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ padding: "25px 10px" }}>
            <form>
              <div className="col s4">
                <div className="col s9 offset-s1">
                  <p style={{ fontSize: "12pt" }} className="bold">
                    About Me
                  </p>
                  <p
                    style={{
                      lineHeight: `${15}px`,
                      paddingTop: `${10}px`,
                      color: "#9e9e9e",
                      marginBottom: "6px",
                    }}
                  >
                    Name
                  </p>
                  <input
                    disabled
                    type="text"
                    className="form-control account-inputs"
                    name="nameInput"
                    id="user[name]"
                    value={this.state.nameInput}
                    placeholder="Edit User Name"
                    onChange={this.handleChange}
                  />
                  <p
                    style={{
                      lineHeight: `${15}px`,
                      paddingTop: `${10}px`,
                      color: "#9e9e9e",
                      marginBottom: "6px",
                    }}
                  >
                    Fun Fact
                  </p>
                  <input
                    disabled
                    type="text"
                    className="form-control account-inputs"
                    name="factInput"
                    id="user[fact]"
                    value={this.state.factInput}
                    placeholder="Edit Fun Fact"
                    onChange={this.handleChange}
                  />
                  <p
                    style={{
                      lineHeight: `${15}px`,
                      paddingTop: `${10}px`,
                      color: "#9e9e9e",
                      marginBottom: "6px",
                    }}
                  >
                    When did you start at Plus QA?
                  </p>
                  <input
                    disabled
                    type="date"
                    onChange={this.handleDateChange}
                    id="employee_start"
                    name="employee_started_at"
                    value={moment(this.state.employee_started_at).format(
                      "YYYY-MM-DD"
                    )}
                    min="2008-01-01"
                    max={moment(new Date()).format("YYYY-MM-DD")}
                  />
                  <p
                    style={{
                      lineHeight: `${15}px`,
                      paddingTop: `${10}px`,
                      color: "#9e9e9e",
                      marginBottom: "6px",
                    }}
                  >
                    Title
                  </p>
                  <input
                    disabled
                    type="text"
                    className="form-control account-inputs"
                    name="titleInput"
                    id="user[title]"
                    value={this.state.titleInput}
                    placeholder="Edit Title"
                    onChange={this.handleChange}
                  />
                  <label
                    style={{
                      lineHeight: `${18}px`,
                      display: "block",
                      marginBottom: "6px",
                    }}
                  >
                    Email
                  </label>
                  <input
                    disabled
                    type="email"
                    className="form-control account-inputs"
                    name="emailInput"
                    id="user[email]"
                    value={this.state.emailInput}
                    placeholder="User Email"
                    onChange={this.handleChange}
                  />
                  <label>New Password</label>
                  <input
                    disabled
                    type="password"
                    className={
                      valid == 1
                        ? "form-control account-inputs validate valid"
                        : valid == 2
                        ? "form-control account-inputs"
                        : "form-control account-inputs validate invalid"
                    }
                    name="newPass"
                    id="user[newPass]"
                    value={this.state.newPass}
                    placeholder="New Password"
                    minLength="8"
                    onChange={this.handleChange}
                  />
                  <p
                    className="helper-text"
                    data-error="Password confirmation does not match"
                    data-success="Password and confirmation match"
                  />
                  <label>Confirm new password</label>
                  <input
                    disabled
                    type="password"
                    className="form-control account-inputs"
                    name="newPassConfirm"
                    id="user[newPassConfirm]"
                    value={this.state.newPassConfirm}
                    placeholder="New Password Confirmation"
                    minLength="8"
                    onChange={this.handleChange}
                  />
                  <p style={{ marginTop: "30px" }}>
                    Enter current password if making changes to anything above
                  </p>
                  <label>Current Password</label>
                  <input
                    disabled
                    type="password"
                    className="form-control account-inputs"
                    name="oldPass"
                    id="user[oldPass]"
                    value={this.state.oldPass}
                    placeholder="Current Password"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col s3">
                <div
                  className="avatar-background hoverable"
                  id="avatar-img"
                  style={{
                    cursor: pointer,
                    width: "250px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                      mouseEvents: "none",
                    }}
                    src={this.state.avatar_image}
                  />
                  <input
                    disabled
                    onChange={this.addAvatar}
                    id="avatar-btn-file"
                    type="file"
                    accept=".jpg,.gif,.png,.svg"
                    ref={this.file}
                  />
                  {this.state.loadingImage && (
                    <ProgressRing
                      radius={125}
                      stroke={5}
                      progress={this.state.percentCompleted}
                    />
                  )}
                </div>
              </div>
              <div className="col s4 offset-s1">
                <div id="userProjectContainer">
                  <p className="bold">Email Notifications?</p>
                  {projects}
                </div>
              </div>
              <div className="col s12">
                <div className="col s4">
                  <button
                    className="btn bc-btn col s3 offset-s1"
                    disabled={!submittable}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default UserShow;
