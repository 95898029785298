import React from 'react'
import styled from 'styled-components'

const UserCard = styled.div.attrs(props => ({
  active: props.active
  }))`
      border-radius: 10px;
      box-shadow: 0px 0px 5px #0000008f;
      border: solid 2px ${props=>props.active?"#519acc":"white"};
      height: 80px;
      margin-bottom: 14px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    `;

class SkillsUserCard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      
    }
    this.setSelectedUser = this.setSelectedUser.bind(this)
  }

  setSelectedUser(e){
    if(!this.props.active){
      this.props.setSelectedUser(this.props.user, e.target.id === `${this.props.user.id}-more`?true:false)
    }
  }

  render() {
    var user = this.props.user
    return(
      <UserCard active={this.props.active} onClick={this.setSelectedUser} >
        <div style={{display:"flex", alignItems:"center", paddingLeft:"20px"}}>
          <img style={{width:"40px", height:"40px", borderRadius:"50%", display:"inline-block", marginRight:"20px"}}
           src={user.avatar_image?user.avatar_image:"https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"}/>
          <span style={{lineHeight:"22px"}}>
            <span style={{whiteSpace:"normal"}}>{user.name}</span>
           {(user.languages.length > 1 || user.languages[0] !== "") && <><br/>
            <span style={{fontSize:"10pt", whiteSpace:"normal"}}>Languages: {user.languages.join(", ")}</span></>
           }
          </span>
        </div>
        <div style={{display:"flex", alignItems:"center", justifyContent:"flex-end", width:"150px", minWidth:"150px", flexWrap:"wrap", marginRight:"20px"}} className="user-badges">
          {user.badges.slice(0,7).map((b)=>{
            return(
              this.props.smallBadges[b.identity]
            )
          })}
          {user.badges.length > 7 && <span id={`${user.id}-more`} style={{display:"inline-block", width:"37px", color:"#519acc"}}>+{user.badges.length-7} more</span>}
        </div>
      </UserCard>
    )
  }

}

export default SkillsUserCard