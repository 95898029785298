import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const RadioButton = (props) => {
  const {
    className,
    containerProps,
    labelProps,
    spanProps,
    children,
    ...rest
  } = props;

  return (
    <Container
      className={`${className ? `${className} ` : ""}common-radio radio-button`}
      {...containerProps}
    >
      <Label {...labelProps}>
        <Input type="radio" className="with-gap radio-button-input" {...rest} />
        <Span className="radio-button-label" {...spanProps}>
          {children}
        </Span>
      </Label>
    </Container>
  );
};

RadioButton.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.objectOf(PropTypes.string),
  labelProps: PropTypes.objectOf(PropTypes.string),
  spanProps: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
};

const Container = styled.div``;

const Label = styled.label``;

const Input = styled.input``;

const Span = styled.span``;

export default RadioButton;
