import React from 'react'
import Sleep from '../common/sleep.js'
import PropTypes from "prop-types";

class SkillsSettingRow extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      level:this.props.userLevel
    }
    this.setLevel = this.setLevel.bind(this)
    this.clearLevel = this.clearLevel.bind(this)
    this.transitioning = false
  }

  async clearLevel(e){
    if(this.state.level === 1){
      var fillCircles = document.querySelectorAll(`.${this.props.skillValue}-radio-fill`)
      fillCircles[0].style.width = "0%"
      this.setState({level:0}, ()=>{
        this.props.updateUserLevel(this.props.skillValue, this.state.level)
      })
    }
  }

  async setLevel(e){
    if(!this.transitioning){
      this.transitioning = true
      var value = parseInt(e.target.value)
      var fillCircles = document.querySelectorAll(`.${this.props.skillValue}-radio-fill`)
      var fillLine = document.querySelector(`.${this.props.skillValue}-fill-line`)
      switch(this.state.level){
        case 0:
          switch(value){
            case 1:
              fillCircles[0].style.width = "100%"
              break
            case 2:
              fillCircles[0].style.width = "100%"
              await Sleep(100)
              fillLine.style.width = "84px"
              await Sleep(200)
              fillCircles[1].style.width = "100%"
              break
            case 3:
              fillCircles[0].style.width = "100%"
              await Sleep(100)
              fillLine.style.width = "84px"
              await Sleep(200)
              fillCircles[1].style.width = "100%"
              await Sleep(100)
              fillLine.style.width = "176px"
              await Sleep(200)
              fillCircles[2].style.width = "100%"
              break
            }
            break
          case 1:
            switch(value){
              case 1:
                fillCircles[0].style.width = "0%"
                break
              case 2:
                fillLine.style.width = "84px"
                await Sleep(200)
                fillCircles[1].style.width = "100%"
                break
              case 3:
                fillLine.style.width = "84px"
                await Sleep(200)
                fillCircles[1].style.width = "100%"
                await Sleep(100)
                fillLine.style.width = "176px"
                await Sleep(200)
                fillCircles[2].style.width = "100%"
                break
              }
            break
          case 2:
            switch(value){
              case 1:
                fillCircles[1].style.width = "0%"
                await Sleep(100)
                fillLine.style.width = "0px"
                break
              case 2:
                break
              case 3:
                fillLine.style.width = "176px"
                await Sleep(200)
                fillCircles[2].style.width = "100%"
                break
              }
            break
          case 3:
            switch(value){
              case 1:
                fillCircles[2].style.width = "0%"
                await Sleep(100)
                fillLine.style.width = "84px"
                await Sleep(200)
                fillCircles[1].style.width = "0%"
                await Sleep(100)
                fillLine.style.width = "0px"
                break
              case 2:
                fillCircles[2].style.width = "0%"
                await Sleep(100)
                fillLine.style.width = "84px"
                break
              case 3:
                break
            }
            break
      }
      if(value !== this.state.level){
        this.setState({level:value}, ()=>{
          this.props.updateUserLevel(this.props.skillValue, this.state.level)
        })
      }
      await Sleep(200)
      this.transitioning = false
    }
    
  }

  render() {
    return(
      <div style={{height:"70px", borderBottom:"solid 1px lightgray", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        <span style={{paddingLeft:"15px",fontSize:"11pt"}}>{this.props.label}</span>
        <div style={{width:"220px", position:"relative", height:"15px"}}>
          {/* empty line */}
          <div style={{position:"absolute", left:"3px", top:"5px", height:"5px", backgroundColor:"#dedede", width:"182px", zIndex:"2"}} />
          {/* fill line */}
          <div className={`${this.props.skillValue}-fill-line`} style={{width: this.props.userLevel < 2 ? "0%" : this.props.userLevel === 2 ? "84px" : "176px", position:"absolute", left:"3px", top:"5px", backgroundColor: this.props.userLevel === 666 ? "rebeccapurple" : "#519acc", height:"5px", zIndex:"3", transition:"width 0.2s"}} />
          {/* first circle */}
          <div style={{position:"absolute", left:"-5px", height:"15px", width:"15px", backgroundColor:"#dedede", border:"solid 1px lightgray", borderRadius:"50%", zIndex:"1"}} />
          <div style={{position:"absolute", left:"-5px", height:"15px", width:"15px", overflow:"hidden", border:"solid 1px transparent", borderRadius:"50%", zIndex:"4"}}>
            <div className={`${this.props.skillValue}-radio-fill`} style={{height:"100%", width: this.props.userLevel > 0 ? "100%":"0%", backgroundColor: this.props.userLevel === 666 ? "rebeccapurple" : "#519acc", transition:"width 0.1s"}} />
          </div>
          <input onChange={this.setLevel} onClick={this.clearLevel} checked={this.state.level === 1} style={{position:"absolute", left:"-5px", zIndex:"5", top:"1px", pointerEvents:"all", cursor:"pointer"}} type="radio" value={1} name={this.props.skillValue} />
          {/* 2nd circle */}
          <div style={{position:"absolute", left:"85px", overflow:"hidden", height:"15px", width:"15px", backgroundColor:"#dedede", border:"solid 1px lightgray", borderRadius:"50%", zIndex:"1"}} />
          <div style={{position:"absolute", left:"85px", height:"15px", width:"15px", overflow:"hidden", border:"solid 1px transparent", borderRadius:"50%", zIndex:"4"}}>
            <div className={`${this.props.skillValue}-radio-fill`} style={{height:"100%", width:this.props.userLevel > 1 ? "100%":"0%", backgroundColor: this.props.userLevel === 666 ? "rebeccapurple" : "#519acc", transition:"width 0.1s"}} />
          </div>
          <input checked={this.state.level === 2} onChange={this.setLevel} style={{position:"absolute", left:"85px", zIndex:"5", top:"1px", pointerEvents:"all", cursor:"pointer"}} type="radio" value={2} name={this.props.skillValue} />
          {/* 3rd circle */}
          <div style={{position:"absolute", left:"177px", overflow:"hidden", height:"15px", width:"15px", backgroundColor:"#dedede", border:"solid 1px lightgray", borderRadius:"50%", zIndex:"1"}} />
          <div style={{position:"absolute", left:"177px", height:"15px", width:"15px", overflow:"hidden", border:"solid 1px transparent", borderRadius:"50%", zIndex:"4"}}>
            <div className={`${this.props.skillValue}-radio-fill`} style={{height:"100%", width:this.props.userLevel > 2 ? "100%":"0%", backgroundColor: this.props.userLevel === 666 ? "rebeccapurple" : "#519acc", transition:"width 0.1s"}} />
          </div>
          <input checked={this.state.level === 3} onChange={this.setLevel} style={{position:"absolute", left:"177px", zIndex:"5", top:"1px", pointerEvents:"all", cursor:"pointer"}} type="radio" value={3} name={this.props.skillValue} />
        </div>
      </div>
    )
  }

}

export default SkillsSettingRow

SkillsSettingRow.propTypes = {
  skillValue: PropTypes.string,
  testPass: PropTypes.object,
  updateUserLevel: PropTypes.func,
  userLevel: PropTypes.number
};