import React from "react";

const TestCasesGoldSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Test_Cases_-_Gold_-_Small_svg__cls-1{fill:#d1b542}.Skills__Test_Cases_-_Gold_-_Small_svg__cls-2{fill:#edcc43}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Test_Cases_-_Gold_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <rect
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          x={261.07}
          y={122.48}
          width={557.39}
          height={832.9}
          rx={254.7}
        />{" "}
        <rect
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-2"
          x={260.72}
          y={108.55}
          width={557.39}
          height={832.9}
          rx={254.7}
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          d="M613.83 344.96H466.36l70.53-157.47 76.94 157.47zM463.85 822.87h151.48v53.34c0 6.52-6 11.85-13.34 11.85h-124.8c-7.34 0-13.34-5.33-13.34-11.85z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          d="M590.13 325.81a25.21 25.21 0 0125.2 25.19v382h-50.41V351a25.21 25.21 0 0125.21-25.19z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          d="M540.09 325.81A25.21 25.21 0 01565.3 351v382h-50.41V351a25.21 25.21 0 0125.2-25.19z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          d="M489.92 325.81A25.21 25.21 0 01515.13 351v382h-50.42V351a25.21 25.21 0 0125.21-25.19z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          d="M464.28 732.92h151.05v89.94H464.28z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          d="M570.58 732.96h11.62v89.89h-11.62zM536.89 187.49l-16.56 36.28a40.53 40.53 0 0016.56 3.35c8.3 0 18.07-3.35 18.07-3.35z"
        />{" "}
        <path
          fill="#ffe68a"
          d="M613.83 333.35H466.36l70.53-157.48 76.94 157.48z"
        />{" "}
        <path
          d="M463.85 811.25h151.48v53.35c0 6.52-6 11.85-13.34 11.85h-124.8c-7.34 0-13.34-5.33-13.34-11.85z"
          fill="#ff74af"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-1"
          d="M564.92 314.2h50.42v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 590.125 517.78)"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Gold_-_Small_svg__cls-2"
          d="M514.89 314.2h50.41v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 540.095 517.78)"
        />{" "}
        <path
          d="M464.71 314.2h50.42v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(180 489.92 517.78)"
          fill="#f7d64b"
        />{" "}
        <path
          transform="rotate(180 539.81 766.28)"
          fill="#5c5c5c"
          d="M464.28 721.31h151.05v89.94H464.28z"
        />{" "}
        <path
          transform="rotate(180 576.39 766.29)"
          fill="#e0e0e0"
          d="M570.58 721.35h11.62v89.89h-11.62z"
        />{" "}
        <path
          d="M536.89 175.87l-16.56 36.29a40.53 40.53 0 0016.56 3.35c8.3 0 18.07-3.35 18.07-3.35z"
          fill="#242424"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default TestCasesGoldSmall;
