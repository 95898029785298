import React from "react";

const Location = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
    >
      <path
        d="M6.49935 8.49967C6.93477 8.49967 7.30764 8.34451 7.61797 8.03417C7.92831 7.72384 8.08321 7.35123 8.08268 6.91634C8.08268 6.48092 7.92752 6.10805 7.61718 5.79772C7.30685 5.48738 6.93424 5.33248 6.49935 5.33301C6.06393 5.33301 5.69106 5.48817 5.38072 5.79851C5.07039 6.10884 4.91549 6.48145 4.91602 6.91634C4.91602 7.35176 5.07118 7.72463 5.38152 8.03497C5.69185 8.3453 6.06446 8.5002 6.49935 8.49967ZM6.49935 14.3184C8.10907 12.8406 9.30317 11.498 10.0816 10.2904C10.8601 9.08287 11.2493 8.01095 11.2493 7.07467C11.2493 5.63648 10.7907 4.45901 9.87343 3.54226C8.95615 2.62551 7.83146 2.16687 6.49935 2.16634C5.16671 2.16634 4.04175 2.62498 3.12447 3.54226C2.2072 4.45954 1.74882 5.63701 1.74935 7.07467C1.74935 8.01148 2.13859 9.08366 2.91706 10.2912C3.69553 11.4988 4.88963 12.8412 6.49935 14.3184ZM6.49935 16.1195C6.39379 16.1195 6.28824 16.0997 6.18268 16.0601C6.07713 16.0205 5.98477 15.9677 5.9056 15.9018C3.97921 14.1997 2.54102 12.6195 1.59102 11.1613C0.641016 9.70301 0.166016 8.34081 0.166016 7.07467C0.166016 5.09551 0.80278 3.51877 2.07631 2.34447C3.34984 1.17016 4.82418 0.583008 6.49935 0.583008C8.17504 0.583008 9.64965 1.17016 10.9232 2.34447C12.1967 3.51877 12.8332 5.09551 12.8327 7.07467C12.8327 8.34134 12.3577 9.7038 11.4077 11.162C10.4577 12.6203 9.01949 14.2002 7.0931 15.9018C7.01393 15.9677 6.92157 16.0205 6.81602 16.0601C6.71046 16.0997 6.6049 16.1195 6.49935 16.1195Z"
        fill="#519ACC"
      />
    </svg>
  );
};

export default Location;
