import React from 'react'
import PropTypes from "prop-types";

class SvgFill extends React.PureComponent {
  constructor(props) {
    super(props);

  }



  render() {
    return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <defs>  
        <linearGradient x2="0%" y1="100%" y2="0%" id="half1">
          <stop offset="0" stopColor="#519acc">
            <animate dur="2s" attributeName="offset" fill="freeze" from="0" to=".62" />
          </stop>
          <stop offset="0" stopColor="#fff">
            <animate dur="2s" attributeName="offset" fill="freeze" from="0" to=".62" />
          </stop>
        </linearGradient>
        <linearGradient x2="0%" y1="100%" y2="0%" id="half2">
          <stop offset="0" stopColor="#fff">
           <animate dur=".3s" begin="1.7s" attributeName="offset" fill="freeze" from="0" to=".62" />
          </stop>
          <stop offset="0" stopColor="#519acc">
            <animate dur=".2s" begin="1.8s" attributeName="offset" fill="freeze" from="0" to=".62" />
            </stop>
        </linearGradient>
        <style>.cls-1{"stroke:#fff;stroke-miterlimit:10;stroke-width:.2px; border: 1px solid black;"}.cls-2{"fill:#fff;border: 2px solid black;:"}</style>
        </defs>
      <title>Icons</title>
      <path fill="#fff" className="cls-2" d="M27.67,5.26h8.76a1,1,0,0,1,1,1v.29A1.53,1.53,0,0,1,35.88,8H28.23A1.53,1.53,0,0,1,26.7,6.52V6.23a1,1,0,0,1,1-1Z" transform="translate(0.02 -0.07) rotate(0.13)"/>
      <rect fill="#fff" transition="fill .5s" stroke="#519acc" strokeWidth=".1px"  x="17.89" y="6.26" width="28.21" height="53.49" rx="3.16"/>
      <rect fill="url(#half1)" className="cls-1" x="19.89" y="8.26" width="24.21" height="50.49" rx="3.16"/>
      <text
      x="35%"
      y="51%"
      alignmentBaseline="central"
      textAnchor="middle"
      dominantBaseline="middle"
      style="font-size: 11px; fill: url(#half2);"
      >44%
      </text>
    </svg>
    );
  }
}

export default SvgFill

SvgFill.propTypes = {
  
};

