import React from "react";

const TodayFilled = (props) => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 64 64" {...props}>
      {" "}
      <rect
        x={36.69}
        y={28.3}
        width={7.53}
        height={7.53}
        rx={2.19}
        fill="#519acc"
      />{" "}
      <rect
        x={19.86}
        y={28.3}
        width={7.53}
        height={7.53}
        rx={2.19}
        opacity={0.33}
        fill="#519acc"
      />{" "}
    </svg>
  );
};

export default TodayFilled;
