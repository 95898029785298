import React from 'react'

class RotateIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28.55 28.55"
        className="icons"
      >
        <g id="Layer_3" data-name="Layer 3">
          <polygon points="11.89 11.89 14.27 11.89 14.27 0 0 0 0 22.6 2.38 22.6 2.38 2.38 11.89 2.38 11.89 11.89"/>
          <path d="M4.76,14.27V28.55H28.55V14.27Zm21.41,11.9h-19V16.65h19Z"/>
          <path d="M20.61,4.37a2.68,2.68,0,0,1,.8,1.92V7.62L20.08,6.29,18.39,8l4.21,4.2L26.8,8,25.12,6.29,23.79,7.64V6.29a5.12,5.12,0,0,0-5.1-5.1h-2V3.57h2A2.72,2.72,0,0,1,20.61,4.37Z"/>
        </g>
      </svg>
    );
  }
}
RotateIcon.defaultProps = {
  color: "white",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default RotateIcon