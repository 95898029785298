import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import DownloadIcon from '../icons/download_icon'
import TrashIcon from '../icons/trash_icon'

function MediaItem(props) {
  const [editable, setEditable] = useState(false);
  const [fileName, setFileName] = useState(props.item.file_name);
  const [loading, setLoading] = useState(props.loading)
  const focusRef = useRef(null)

  function handleCheckChange() {
    props.handleSelected(props.item.id)
  }

  function stopProp(e) {
    e.stopPropagation()
  }

  function editClick(e) {
    e.stopPropagation()
    setEditable(!editable)
  }

  function editClickHandler(e) {
    e.stopPropagation()
  }

  function handleFileName(e) {
    setFileName(e.target.value)
  }

  function fileKeyHandler(e) {
    if (e.keyCode === 13) {
      e.target.blur()
    } else if (e.keyCode === 27) {
      editClick()
    }
  }

  function handleFileNameSave() {
    if (fileName == props.item.file_name) {
      setEditable(!editable)
    } else {
      axios.patch(`/media_items/${props.item.id}.json`, {
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        media_item: {file_name: fileName}
      }).then((res) => {
        if (res.status === 201) {
          M.toast({html: "File Name Updated", classes: "green"})
          props.mediaHandler(res, "update")
          setEditable(!editable)
        }
      })
      .catch((err) => {
        err.message = "Update Media Item Backend Error: " + err.message
        console.error(err)
        Swal.fire({title: "Media Item update error", text:"There was a problem updating this media", customClass: "swal2-error-modal"});
      })
    }
  }

  useEffect(() => {
    if (editable && focusRef && focusRef.current) {
      focusRef.current.focus()
    }
    return () => {
      
    };
  }, [editable]);

  function handleMediaDelete(e) {
    e.stopPropagation()
    Swal.fire({
      title: "Delete File",
      text: "Are you sure that you want to delete this file?",
      reverseButtons: true,
      showCancelButton:true,
      confirmButtonAriaLabel: 'Yes',
      cancelButtonAriaLabel: 'cancel',
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios.delete(`/media_items/${props.item.id}.json`, {
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        }).then((res) => {
          if (res.status === 200) {
            M.toast({html: "Media Deleted", classes: "green"})
            props.mediaHandler(props.item.id, "remove")
          }
        })
        .catch((err) => {
          err.message = "Delete Media Item Backend Error: " + err.message
          console.error(err)
          Swal.fire({title: "Media Item delete error", text:"There was a problem deleting this media", customClass: "swal2-error-modal"});
        })
      } else if (result.dismiss) {
          // Cancelled Delete
      }
    });
  }

  function handleMediaDownload(e) {
    e.stopPropagation()
    setLoading(true)
    var dl = document.createElement('a')
    dl.setAttribute('href', `${props.item.download_url}`)
    dl.setAttribute('download', `${props.item.file_name}`)
    dl.click()
    dl.remove()
    setLoading(false)
  }

  function handleEditorClick() {
    props.editorHandler(props.item)
  }

  function displayFile() {
    var element = <img style={{width: "30px", height: "30px", borderRadius: "3px"}} alt="media item" src="//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/file-icon.png"/>
    if(props.item.file_type && props.item.file_type.includes("image") && props.item.file_type.includes("svg")) {
      // svgs download from digital ocean so gotta find a way around this maybe. People likely don't take screenshots with svg
    } else if (props.item.file_type && props.item.file_type.includes("image")) {
      element = <img style={{width: "30px", height: "30px", borderRadius: "3px"}} alt="media item" src={props.item.url}/>
    } else if (props.item.file_type && props.item.file_type.includes("video")) {
      element = <video autoPlay={props.item.owner_name.toLowerCase().includes("alex") || props.item.owner_name.toLowerCase().includes("guerd") || props.item.owner_name.toLowerCase().includes("dale") ? true : false} style={{width: "30px", height: "30px", borderRadius: "3px"}} alt="media item" src={props.item.url}/>
    }
    return element
  }

  useEffect(() => {
    setFileName(props.item.file_name)
  }, [props.rowData]);

  return (
    <div style={props.style} onClick={handleEditorClick} className={`ReactVirtualized__Table__row ${props.selected ? "selected" : ""} ${(props.index) % 2 == 0 ? "even" : "odd"}`}>
      <div
        style={{ display: "flex", alignItems: "center", overflow: "visible", flex: "1 1 500px", margin: 0, paddingLeft: "10px"}}
        className="ReactVirtualized__Table__rowColumn"
      >
        {displayFile()}
        {editable ? (
          <input
          style={{marginTop: "0px", backgroundColor: "white", margin: "0 10px", transition: "width .4s", width: `${7*fileName.length+30 >= 140 ? 7*fileName.length+30 : 140 }px`}}
          onBlur={handleFileNameSave}
          onClick={editClickHandler}
          maxLength={90}
          onKeyDown={fileKeyHandler}
          onChange={handleFileName}
          name="fileName"
          ref={focusRef}
          className="browser-default border-inputs"
          value={fileName}
          type="text"
          />
        ) : (
          <p onClick={editClick} style={{padding: "0 10px"}}>{fileName}</p>
        )}
        {loading && (
           <div id="test-step-bugs-loader"></div>
        )}
      </div>
      <p  style={{ display: "flex", alignItems: "center", overflow: "visible", flex: "0 1 200px", margin: 0}}
        className="ReactVirtualized__Table__rowColumn">{props.item.owner_name}</p>
      <p  style={{ display: "flex", alignItems: "center", overflow: "visible", flex: "0 1 200px", margin: 0}}
        className="ReactVirtualized__Table__rowColumn">{props.item.created_at}</p>
      <p  style={{ display: "flex", alignItems: "center", overflow: "visible", flex: "0 1 100px", margin: 0}}
        className="ReactVirtualized__Table__rowColumn">{props.item.human_file_size}</p>
      <div style={{ display: "flex", alignItems: "center", overflow: "visible", gap: "2px", flex: "0 1 120px", margin: 0, justifyContent: "space-evenly"}}
        className="ReactVirtualized__Table__rowColumn">
          <p>
            <label style={{pointerEvents: "none"}} htmlFor={`checkbox_${props.item.id}`}>
              <input
                style={{pointerEvents: "all"}}
                onClick={stopProp}
                onChange={handleCheckChange}
                checked={props.selected}
                name="visible"
                id={`checkbox_${props.item.id}`}
                className="filled-in media-check"
                value={props.selected}
                type="checkbox"
              />
            </label>
          </p>
          <button data-id={props.item.id} onClick={handleMediaDownload} style={{cursor:"pointer", right: "6px", bottom:props.item.owner_name?"5px":"0px"}} className="media-download-button">
            <DownloadIcon svgStyles={{pointerEvents:"none"}}/>
          </button>
          <button data-id={props.item.id} onClick={handleMediaDelete} style={{cursor:"pointer", bottom:props.item.owner_name?"5px":"0px"}} className="media-delete-button">
            <TrashIcon svgStyles={{pointerEvents:"none",strokeLinecap: "round", strokeWidth: "3px"}} /> 
          </button>
      </div>
    </div>
  );
}

export default MediaItem