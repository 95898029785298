import React, { useState, useContext } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import ApiClient from "@utils/ApiClient";
import TrashSimpleIcon from "@components/icons/trash_simple_icon";
import { wikiAssessmentTypes } from "@types/wiki";
import { WikiAssessmentsContext } from "..";

const DeleteAssessment = ({ assessment }) => {
  const { setAssessments } = useContext(WikiAssessmentsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteAssessment = async () => {
    try {
      const api = new ApiClient();
      const { id } = assessment;
      await api.delete(`/wiki-assessments/${id}`).catch((error) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "There was an error deleting the assessment",
          classes: "red darken-1 error-toast",
        });
        throw error;
      });
      await setAssessments((prevAssessments) => {
        return prevAssessments.filter((a) => a.id !== id);
      });
      // eslint-disable-next-line no-undef
      M.toast({
        html: "Assessment Deleted",
        classes: "green darken-1 success-toast",
      });
      toggleModal();
    } catch (e) {
      console.error(e);
    }
  };

  const openConfirmDialog = () => {
    Swal.fire({
      title: "Delete Assessment",
      text: "Are you sure that you want to delete this assessment? This will also permanently delete all associated questions and assessment attempts.",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "common-button-submit",
        cancelButton: "common-button-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAssessment();
      }
    });
  };

  return (
    <DeleteAssessmentContainer>
      <DeleteAssessmentButton onClick={openConfirmDialog}>
        <TrashSimpleIcon color="#519acc" />
      </DeleteAssessmentButton>
    </DeleteAssessmentContainer>
  );
};

const DeleteAssessmentContainer = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 2px;
  border: 1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteAssessmentButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

DeleteAssessment.propTypes = {
  assessment: wikiAssessmentTypes.isRequired,
};

export default DeleteAssessment;
