import React from 'react'
import PropTypes from 'prop-types'

class NestedNavMenu extends React.Component {

constructor(props) {
    super(props)
    this.state = {
			open: false,
			openArray: Array(this.props.groups.length).fill(false),
			activeItem: {}
		}
		this.setOpen = this.setOpen.bind(this)
		this.setOpenArray = this.setOpenArray.bind(this)
		this.handleOutsideClick = (e) => {
			if(!this.refs[`nested_nav_menu_container_${this.props.id}`].contains(e.target)){
				this.setOpen()
			}
		} 
	}
	
	setOpenArray(e){
		var groupIndex = e.target.dataset.groupIndex
		var newOpenArray = this.state.openArray
		newOpenArray[parseInt(groupIndex)] = !newOpenArray[parseInt(groupIndex)]
		this.setState({openArray:newOpenArray})
	}

	setOpen(){
		if(!this.state.open === true){
			window.addEventListener("click", this.handleOutsideClick)
		} else {
			window.removeEventListener("click", this.handleOutsideClick)
		}
		this.setState({
			open: !this.state.open
		})
	}
  
	render() {
		var openArray = this.state.openArray
		return (
			<div ref={`nested_nav_menu_container_${this.props.id}`} className="checkbox-menu-container" style={{position: "relative", width: "fit-content", marginRight:"15px"}}>
				<div className={`${this.state.open ? "checkbox-menu-selector-open" : "checkbox-menu-selector-closed"}`} onClick={this.setOpen} 
					style={{height: "36px", color:this.state.active?"white":"initial", paddingLeft:"10px", paddingRight:"10px", backgroundColor:"#f5f5f5", border:"solid #e0e0e0 2px", cursor:"pointer"}}>
					<span style={{fontSize:"15px", lineHeight:"33px", whiteSpace:"nowrap"}}>{this.props.title}<i style={{verticalAlign:"middle"}} className="material-icons">arrow_drop_down</i></span>
				</div>
				{this.state.open && <div className="checkbox-menu" style={{position:"absolute", zIndex:"1", left:"0px", top:"calc(100% - 2px)", padding:"15px", width:"fit-content", maxWidth:"500px", minHeight:"80px", backgroundColor:"#f5f5f5", border:"solid rgb(196, 196, 196) 2px"}}>
					{this.props.groups.map((group, index) => {
						var index1 = index
						return (
							<div style={{ whiteSpace: "nowrap" }} key={index1} >
                <span onClick={this.setOpenArray} data-group-index={index1}>{group.label}</span>
								<div 
									style={{
										height: openArray[index1] ? `${group.options.length * 30}px` : "0px", 
										overflow:"hidden", 
										transition:"height 0.3s"}} 
								>
									{group.options.map((option, index)=>{
										var index2 = index
										return (
											<div style={{paddingLeft:"15px"}} key={`${index1}-${index2}`}>
												{option.label}
											</div>
										)
									})}
								</div>
								{/* <label>
								<input onChange={option.handler} className="filled-in" checked={option.checked} value={option.value} type="checkbox"/>
								<span style={{whiteSpace:"nowrap", color:"black"}}>{option.label}</span>
								</label> */}
							</div>
						)
					})}
				</div>}
			</div>
		)
  }
}

export default NestedNavMenu