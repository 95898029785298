const customStyles = {
	control: styles => ({ ...styles,
		backgroundColor: 'white',
		borderRadius: "22px",
		minHeight: "30px",
		height: "30px"
	}),
	indicatorSeparator: styles => ({ ...styles,
		height: "100%",
		marginTop: "0",
		backgroundColor: "#519acc"
	}),
	input: styles => ({ ...styles,
		boxShadow: "none !important"
	}),
	menu: styles => ({ ...styles,
		marginTop: "2px"
	}),
	valueContainer: styles => ({ ...styles,
		maxHeight: "28px"
	}),
	indicatorsContainer: styles => ({ ...styles,
		maxHeight: "28px"
	}),
	dropdownIndicator: styles => ({ ...styles, 
		color: "#519acc"
	}),
	singleValue: styles => ({...styles,
		backgroundColor: "transparent"
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isDisabled
				? "white"
				: isSelected ? "#519acc" : isFocused ? "lightblue" : "white",
			color: isDisabled
				? '#ccc'
				: isSelected
					? "white" ? 'white' : 'black'
					: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default'
		};
	}
}

export default customStyles