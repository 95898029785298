import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Select from 'react-select'
import Swal from "sweetalert2";

class WatchersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userOptions: this.props.users, selectedWatchers: this.props.watchers
    }
    this.autoComplete = React.createRef();
    this.onWatcherSubmit = this.onWatcherSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  onWatcherSubmit(formData) {
     axios.post(`/watcher/` + this.state.props.ticket_id, {
        data: formData,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then(() => {
          M.toast({html: `Watchers Updated`, displayLength: 3000, classes: 'green' });
        })
        .catch((err) => {
          Swal.fire('Watcher edit error', 'There was an error editing the watchers for this ticket');
          console.error(err)
        })
    }

    handleChange(e) {
      var value = e.target.value
        this.setState({
          value: value
        });
    }

    handleSubmit(e) {
      e.preventDefault();
      var value = this.state.value
      if (!value) {
        return;
      }
      if (value == "") {
        return;
      }
      var formData = new FormData();
      formData.append('ticket[watcher_ids]', value);
      formData.append('ticket[ticket_id', this.props.ticketId);
      this.onWatcherSubmit(formData)
    }

    handleCheckChange(e) {
    this.setState({ selectedWatchers: e})  
  }

  render() {
    return (
          <div className="col s12">
            <form id="watcherForm" onSubmit={this.handleSubmit} ref="commentForm" encType="multipart/form-data">
              <Select
                isMulti
                aria-label="Watchers dropdown for watcher form"
                onChange={this.handleCheckChange}
                name="watchers"
                closeMenuOnSelect={false}
                options={this.props.userOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            <button className="btn left" encType="submit" id="ticket-watcher-submit">Add</button>
          </form>
      </div>
    );
  }
};

export default WatchersForm