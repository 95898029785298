import React from "react";
import { components } from "react-select";

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        id="projectSideDrop"
        message={
          props.selectProps.inputValue != ""
            ? props.selectProps.inputValue
            : props.selectProps.value.label
            ? props.selectProps.value.label
            : ""
        }
      />
      {props.selectProps.inputValue && props.selectProps.inputValue != "" ? (
        <components.Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
      ) : (
        <p>{props.selectProps.inputValue}</p>
      )}
      {React.Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null
      )}
    </components.ValueContainer>
  );
};

export default CustomValueContainer;
