import React from "react";
import xss from "xss";
import PropTypes from "prop-types";

class TestStep extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      //  showDetails: this.props.testStep.details ? true : false,
    };

    this.handleStepDelete = this.handleStepDelete.bind(this);
    this.inputRef = React.createRef();
    this.detailsInputRef = React.createRef();
    this.clearInput = this.clearInput.bind(this);
    this.updateTestStep = this.updateTestStep.bind(this);
    this.keydownHandler = this.keydownHandler.bind(this);
    this.handleDetailsPaste = this.handleDetailsPaste.bind(this);
    this.handleInputPaste = this.handleInputPaste.bind(this);
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    if (this.updated) {
      var id = this.props.testStep.id;
      var titleText = document.getElementById(`tc-title-input-${id}`).innerHTML;
      var detailsText = document.getElementById(
        `tc-details-input-${id}`
      ).innerHTML;
      var data = {};
      data = { test_step: { text: titleText, details: detailsText } };
      var self = this;
      var url = "/test_steps/" + id + ".json";
      $.ajax({
        url: url,
        type: "PUT",
        data: data,
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        cache: false,
        success: function (data) {
          this.updated = false;
          var step = data.test_steps.filter(
            (i) => i.id === this.props.testStep.id
          )[0];
          self.props.stepHandler(step);
        }.bind(this),
        error: function (status, err) {
          this.updated = false;
          console.error(url, status, err);
        }.bind(this),
      });
    }
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
  }

  stripHtmlToText = (tmp) => {
    var res = tmp.textContent || tmp.innerText || "";
    res.replace("\u200B", ""); // zero width space
    res = res.trim();
    return res;
  };

  updateTestStep(e) {
    if (this.updated) {
      this.updated = false;
      var text = e.target.innerHTML ? e.target.innerHTML : "";
      var id = this.props.testStep.id;
      var data = {};
      if (e.target.classList.contains("tc-title-input")) {
        data = { test_step: { text: text } };
        this.props.suggestionHandler(
          "testStep",
          this.stripHtmlToText(e.target)
        );
      } else {
        data = { test_step: { details: text } };
        this.props.suggestionHandler(
          "testStepDetail",
          this.stripHtmlToText(e.target)
        );
      }
      var self = this;
      var url = "/test_steps/" + id + ".json";
      $.ajax({
        url: url,
        type: "PUT",
        data: data,
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        cache: false,
        success: function (data) {
          var step = data.test_steps.filter(
            (i) => i.id === this.props.testStep.id
          )[0];
          self.props.stepHandler(step);
        }.bind(this),
        error: function (status, err) {
          console.error(url, status, err);
        }.bind(this),
      });
    }
  }

  clearInput() {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }
  }

  keydownHandler(e) {
    var target = e.target;
    if (e.key.length === 1 && target.innerHTML.length + 1 > 3000) {
      e.preventDefault();
      // var currentText = this.detailsInputRef.current.innerHTML
      M.toast({
        html: `Character limit reached`,
        displayLength: 3000,
        classes: "red",
      });
      // setTimeout(() =>  {this.detailsInputRef.current.innerHTML = currentText}, 50)
    } else {
      if (!this.updated && e.keyCode !== 9) {
        this.updated = true;
      }
    }
    switch (e.keyCode) {
      case 13:
        if (!this.updated) {
          this.updated = true;
        }
        break;
      case 32:
        if (!this.updated) {
          this.updated = true;
        }
        break;
      case 16:
        this.shift = true;
        break;
    }
  }

  handleStepDelete() {
    this.props.handleStepDelete(this.props.testStep.id);
  }

  handleInputPaste(e) {
    e.preventDefault();
    var clipboardData = e.clipboardData.getData("text/plain");
    if (this.inputRef.current) {
      if (
        clipboardData.length + this.inputRef.current.innerHTML.length >
        3000
      ) {
        M.toast({
          html: `Cannot paste. Character limit reached`,
          displayLength: 3000,
          classes: "red",
        });
      } else {
        document.execCommand(
          "insertText",
          false,
          e.clipboardData.getData("text/plain")
        );
        if (!this.updated) {
          this.updated = true;
        }
      }
    }
  }

  handleDetailsPaste(e) {
    e.preventDefault();
    var clipboardData = e.clipboardData.getData("text/plain");
    if (this.detailsInputRef.current) {
      if (
        clipboardData.length + this.detailsInputRef.current.innerHTML.length >
        3000
      ) {
        M.toast({
          html: `Cannot paste. Character limit reached`,
          displayLength: 3000,
          classes: "red",
        });
      } else {
        document.execCommand(
          "insertText",
          false,
          e.clipboardData.getData("text/plain")
        );
        if (!this.updated) {
          this.updated = true;
        }
      }
    }
  }

  render() {
    var testStep = this.props.testStep;
    return (
      <li
        style={{
          padding: "20px 0px",
          marginLeft: "26px",
          position: "relative",
          width: "calc(100% - 110px)",
        }}
        data-tcid={this.props.testCase.id}
        id={`${testStep.id}-${testStep.position}-step`}
      >
        <div
          onDragOver={this.props.onStepDragOver}
          onDrop={this.props.onStepDragDrop}
          onDragEnter={this.props.onStepDragEnter}
          onDragLeave={this.props.onStepDragLeave}
          data-tsid={testStep.id}
          data-tcid={this.props.testCase.id}
          data-tsposition={testStep.position}
          data-type={"step"}
          data-text={testStep.text}
          data-details={testStep.details}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: `${this.props.stepDragging ? "4" : "-1"}`,
          }}
          id={`${testStep.id}-${testStep.position}-step-drag-area`}
        />
        <div
          id={`${testStep.id}-${testStep.position}-step-drag-hover-top`}
          className="test-case-drag-hover"
          style={{
            height: "0px",
            backgroundColor: "rgba(211, 211, 211, 0.555)",
          }}
        />
        <div className="flex-row">
          <div
            style={{ width: "100%", display: "inline-flex" }}
            data-ts-id={this.props.testStep.id}
            data-tc-id={this.props.testCase.id}
            data-ts-attribute="text"
            id={`${testStep.id}-${testStep.position}-step-heading`}
            className=" tc-item"
          >
            <span
              draggable={true}
              id={`${testStep.id}-${testStep.position}-step-grab`}
              style={{
                whiteSpace: "pre",
                fontWeight: 600,
                width: "63px",
                cursor: "grab",
                textAlign: "right",
                position: "relative",
                marginLeft: "-9px",
              }}
              data-tsid={testStep.id}
              data-tcid={this.props.testCase.id}
              data-tsposition={testStep.position}
              data-type={"step"}
              onDragStart={this.props.onStepDragStart}
              onDragEnd={this.props.onStepDragEnd}
            >
              <div
                style={{
                  position: "absolute",
                  height: "calc(100% + 13px)",
                  width: "38px",
                  borderBottom: "solid lightgray 2px",
                  borderLeft: "solid lightgray 2px",
                  left: "29px",
                  bottom: "-46px",
                }}
              />
              TC {`${this.props.i + 1}.${this.props.y + 1} `}
            </span>
            <div
              role="textbox"
              tabIndex={0}
              contentEditable
              ref={this.inputRef}
              placeholder="Test Case"
              className="common-input tc-title-input"
              onKeyDown={this.keydownHandler}
              onPaste={this.handleInputPaste}
              onBlur={this.updateTestStep}
              id={`tc-title-input-${testStep.id}`}
              list="teststep-flavors"
              defaultValue={this.props.testStep.text}
              style={{
                paddingLeft: "3px",
                // border: "1px solid black",
                flex: 1,
                wordBreak: "break-word",
                lineHeight: "20px",
                position: "relative",
                marginTop: "0px",
              }}
              dangerouslySetInnerHTML={{
                __html: xss(this.props.testStep.text),
              }}
            ></div>
          </div>
          <div
            style={{
              position: "absolute",
              right: "-49px",
              width: "40px",
              textAlign: "right",
            }}
            className="tc-actions"
          >
            <button
              tabIndex="0"
              className="span-to-button"
              data-index={this.props.i}
              onClick={this.handleStepDelete}
              data-tc-id={this.props.testCase.id}
              data-ts-id={this.props.testStep.id}
              style={{
                pointerEvents: "all",
                fontSize: "9pt",
                cursor: "pointer",
                color: "#519acc",
              }}
            >
              Delete
            </button>
          </div>
        </div>
        <div
          className="flex-row"
          style={{ position: "relative", marginTop: "20px" }}
        >
          <div
            className="tc-details-container"
            style={{
              minHeight: "30px",
              width: "100%",
              marginLeft: "64px",
              border: "solid 2px #d0d0d0",
              borderRadius: "6px",
              padding: "7px",
              position: "relative",
            }}
          >
            <span
              style={{
                position: "absolute",
                display: "block",
                fontSize: "9pt",
                top: "-15px",
                left: "5px",
                backgroundColor: "white",
                height: "18px",
                color: "#929292",
                padding: "0px 3px",
              }}
            >
              Details/Expected Results
            </span>
            <div
              role="textbox"
              tabIndex={0}
              ref={this.detailsInputRef}
              contentEditable
              suppressContentEditableWarning={true}
              placeholder="Expected Result"
              style={{
                width: "100%",
                minHeight: "30px",
                padding: "4px 8px",
                lineHeight: "20px",
                backgroundColor: "white",
              }}
              data-ts-id={this.props.testStep.id}
              data-tc-id={this.props.testCase.id}
              data-ts-attribute="details"
              onKeyDown={this.keydownHandler}
              onPaste={this.handleDetailsPaste}
              onBlur={this.updateTestStep}
              list="teststepdetails-flavors"
              id={`tc-details-input-${testStep.id}`}
              className="tc-item tc-details-input tc-common-input"
              dangerouslySetInnerHTML={{
                __html: xss(this.props.testStep.details),
              }}
            ></div>
          </div>
        </div>
        <div
          id={`${testStep.id}-${testStep.position}-step-drag-hover-bottom`}
          className="test-case-drag-hover"
          style={{
            height: "0px",
            backgroundColor: "rgba(211, 211, 211, 0.555)",
          }}
        />
      </li>
    );
  }
}

export default TestStep;

TestStep.propTypes = {
  position: PropTypes.number,
  testStep: PropTypes.object,
  testCase: PropTypes.object,
  id: PropTypes.string,
  projectId: PropTypes.number,
  pathName: PropTypes.string,
  stepDragging: PropTypes.bool,
  y: PropTypes.number,
  i: PropTypes.number,
  handleCaseDelete: PropTypes.func,
  handleOutsideStepClick: PropTypes.func,
  onStepDragStart: PropTypes.func,
  onStepDragEnd: PropTypes.func,
  onStepDragDrop: PropTypes.func,
  onStepDragLeave: PropTypes.func,
  onStepDragEnter: PropTypes.func,
  onStepDragOver: PropTypes.func,
  oneSection: PropTypes.bool,
  handleStepDelete: PropTypes.func,
  caseDragging: PropTypes.bool,
};
