import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";

const EarnedBadges = ({ earnedBadges, currentUser }) => {
  if (earnedBadges && earnedBadges.length > 0) {
    return (
      <>
        {earnedBadges.map((b) => {
          return (
            <BadgeContainer key={b.identity}>
              <BadgeImage
                aria-hidden="true"
                $isTimeDependent={b.time_dependent}
                src={b.url}
              />
              <BadgeName>{b.name}</BadgeName>
              <BadgeEarned>
                Earned on {moment(b.created_at).format("L")}
              </BadgeEarned>
            </BadgeContainer>
          );
        })}
      </>
    );
  }

  return (
    <NoBadgesEarned>
      {currentUser
        ? "You have yet to earn any Badges"
        : "This user has yet to earn any Badges"}
    </NoBadgesEarned>
  );
};

export const BadgeContainer = styled.div`
  width: 200px;
  height: 225px;
  margin-bottom: 10px;
  margin-right: 8px;
  text-align: center;
  border-radius: 6px;
  box-shadow: lightgrey 0px 0px 2px 1px;
`;

export const BadgeImage = styled.img`
  max-height: ${({ $isTimeDependent }) =>
    $isTimeDependent ? "130px" : "170px"};
  margin: ${({ $isTimeDependent }) => ($isTimeDependent ? "20px" : "0")};
`;

export const BadgeName = styled.p`
  font-weight: 600;
  font-size: 11pt;
  margin-top: -20px;
`;

export const BadgeEarned = styled.p`
  font-size: 13px;
  line-height: 20px;
`;

export const NoBadgesEarned = styled.p``;

EarnedBadges.propTypes = {
  earnedBadges: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      details: PropTypes.string,
      id: PropTypes.number,
      identity: PropTypes.string,
      name: PropTypes.string,
      time_dependent: PropTypes.bool,
      url: PropTypes.string,
    })
  ),
  currentUser: PropTypes.bool,
};

export default EarnedBadges;
