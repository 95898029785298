import React from 'react'

class ShapeIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30.09 25.55"
        className="icons"
      >
        <g id="Layer_3" data-name="Layer 3">
          <polygon points="30.09 25.55 25.3 25.55 25.3 22.79 27.33 22.79 27.33 20.77 30.09 20.77 30.09 25.55"/>
          <path d="M22.75,25.55H16.32V22.79h6.43Zm-9,0H7.34V22.79h6.42Z"/>
          <polygon points="4.78 25.55 0 25.55 0 20.77 2.75 20.77 2.75 22.79 4.78 22.79 4.78 25.55"/>
          <rect y="8.35" width="2.75" height="8.84"/>
          <polygon points="2.75 4.78 0 4.78 0 0 4.78 0 4.78 2.75 2.75 2.75 2.75 4.78"/>
          <path d="M22.75,2.75H16.32V0h6.43Zm-9,0H7.34V0h6.42Z"/>
          <polygon points="30.09 4.78 27.33 4.78 27.33 2.75 25.3 2.75 25.3 0 30.09 0 30.09 4.78"/>
          <rect x="27.33" y="8.35" width="2.75" height="8.84"/>
        </g>
      </svg>
    );
  }
}
ShapeIcon.defaultProps = {
  color: "white",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default ShapeIcon