import React from "react";
import PropTypes from "prop-types";

const AssessmentPassed = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 73 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.375 2H6.29167C3.92144 2 2 3.92144 2 6.29167V83.5417C2 85.912 3.92144 87.8333 6.29167 87.8333H66.375C68.7453 87.8333 70.6667 85.912 70.6667 83.5417V6.29167C70.6667 3.92144 68.7453 2 66.375 2Z"
        fill="#519ACC"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M21.3125 57.792H51.3542H21.3125Z" fill="#519ACC" />
      <path
        d="M21.3125 57.792H51.3542"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M21.3125 70.667H36.3333H21.3125Z" fill="#519ACC" />
      <path
        d="M21.3125 70.667H36.3333"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M27.75 38.4792L44.9167 21.3125L27.75 38.4792Z" fill="#519ACC" />
      <path
        d="M27.75 38.4792L44.9167 21.3125"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.9167 38.4792L27.75 21.3125L44.9167 38.4792Z"
        fill="#519ACC"
      />
      <path
        d="M44.9167 38.4792L27.75 21.3125"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_2328_12721)">
        <path
          d="M36.834 6.41699C24.1297 6.41699 13.834 16.7128 13.834 29.417C13.834 42.1212 24.1297 52.417 36.834 52.417C49.5382 52.417 59.834 42.1212 59.834 29.417C59.834 16.7128 49.5382 6.41699 36.834 6.41699Z"
          fill="#519ACC"
        />
        <path
          d="M48.5179 21.6401C47.8284 20.969 46.7253 20.9782 46.0543 21.6676L34.4256 33.5261L28.7353 27.4222C28.4136 27.0821 27.9907 26.8891 27.5219 26.8707C27.5035 26.8707 27.4759 26.8707 27.4575 26.8707C27.0163 26.8707 26.5934 27.0361 26.2717 27.3395C25.9316 27.6612 25.7385 28.0841 25.7201 28.5529C25.7017 29.0217 25.8672 29.463 26.189 29.8031L33.1202 37.24C33.442 37.5893 33.9016 37.7915 34.3704 37.7915H34.3888C34.8576 37.7915 35.3081 37.5985 35.639 37.2675L48.5363 24.1037C49.2073 23.4142 49.1981 22.3111 48.5087 21.6401H48.5179Z"
          fill="white"
        />
        <path
          d="M34.3986 38.7106C33.6356 38.7015 32.9462 38.3889 32.4498 37.8649L25.5185 30.4281C25.0313 29.9133 24.7831 29.233 24.8107 28.516C24.8382 27.8082 25.1324 27.1463 25.6564 26.6591C26.1528 26.1995 26.7963 25.9421 27.4673 25.9421H27.5685C28.2763 25.9696 28.929 26.273 29.4162 26.7878L34.4446 32.1839L45.393 21.0056C46.4226 19.9576 48.114 19.9393 49.162 20.9688C50.2099 21.9984 50.2283 23.6807 49.1988 24.7286L36.2923 37.8925C35.7959 38.3981 35.0972 38.6923 34.3894 38.6923L34.3986 38.7106ZM27.4949 27.7898C27.2559 27.7898 27.0629 27.8633 26.9066 28.0104C26.7411 28.1667 26.6492 28.3597 26.64 28.5803C26.64 28.801 26.7135 29.0124 26.8606 29.1687L33.7919 36.6055C33.9482 36.771 34.1596 36.8629 34.3894 36.8721C34.6192 36.8721 34.8307 36.7802 34.9869 36.6239L47.8842 23.46C48.1968 23.1383 48.1968 22.6143 47.875 22.2926C47.5533 21.9708 47.0293 21.98 46.7075 22.311L34.4078 34.8589L28.0649 28.0564C27.9086 27.8909 27.7155 27.799 27.4949 27.7898Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2328_12721">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(13.834 6.41699)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

AssessmentPassed.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

AssessmentPassed.defaultProps = {
  height: "90",
  width: "73",
};

export default AssessmentPassed;
