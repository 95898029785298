import React from "react";
import TextTransition, { presets } from "react-text-transition";
import useDeviceLab from "../../hooks/data/useDeviceLab";

const MobileDeviceStats = () => {
  const { checkoutStats } = useDeviceLab();

  if (
    !checkoutStats ||
    "available" in checkoutStats === false ||
    "unavailable_android" in checkoutStats === false ||
    "unavailable_ios" in checkoutStats === false
  ) {
    return null;
  }

  const { available, unavailable_android, unavailable_ios } = checkoutStats;

  return (
    <div
      aria-hidden="true"
      style={{
        display: "flex",
        flex: 1,
        transition: "flex .5s",
        flexDirection: "column",
        paddingLeft: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            paddingRight: "15px",
            fontWeight: "400",
            fontSize: "12px",
          }}
        >
          MOBILE DEVICE STATS
        </p>
        <div
          style={{
            flex: 1,
            height: "1px",
            backgroundColor: "#e0e0e0",
            width: "calc(100% + 25px)",
          }}
        />
      </div>
      <div style={{ display: "inline-flex" }}>
        <div style={{ flex: 1, textAlign: "center" }}>
          <p style={{ color: "#c4c4c4" }}>Available</p>
          <TextTransition
            direction="up"
            inline
            style={{ color: "#519acc", fontSize: "22px", fontWeight: "600" }}
            text={available}
            springConfig={presets.wobbly}
          />
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <p style={{ color: "#c4c4c4" }}>
            Checked Out <span style={{ color: "#43962a" }}>- Android</span>
          </p>
          <TextTransition
            direction="up"
            inline
            style={{ color: "#43962a", fontSize: "22px", fontWeight: "600" }}
            text={unavailable_android}
            springConfig={presets.wobbly}
          />
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <p style={{ color: "#c4c4c4" }}>
            Checked Out <span style={{ color: "#65c1ff" }}>- iOS</span>
          </p>
          <TextTransition
            direction="up"
            inline
            style={{ color: "#65c1ff", fontSize: "22px", fontWeight: "600" }}
            text={unavailable_ios}
            springConfig={presets.wobbly}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileDeviceStats;
