import React from 'react'
import moment from 'moment'

class RewardsHistory extends React.Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }

  handleKudosModal =(e)=>{
    var message = e.target.dataset.message
    var description = e.target.dataset.description
    var descriptionArray = description.split(" ")
    var name = description.slice(-1) === "." ? descriptionArray.slice(-2, -1) : descriptionArray.slice(-1)
    this.props.handleKudosModal(name, message)
  }

  render(){
    var items = this.props.rewardHistories
    return(
      <div style={{height:"100%"}}>
        <h3 className="large-title">Rewards History</h3>
        <div role="list" className="reward-history-table new-scrollbar">
          <div aria-hidden="true">
            <p>Description</p>
            <p>Date/Time</p>
            <p>Points</p>
          </div>
          {items.map((item, i)=>{
            var dateTime = moment(item.created_at).format("M/DD/YYYY - h:mmA")
            return(
              <div role="listitem" aria-label={`${item.description}, ${item.points > 0 ? "plus" : "minus"} ${item.points} points, ${dateTime}.${item.kudos_message?"Click to read the message they left":""}`} style={{backgroundColor:i%2===0?"white":"lightgray"}} key={item.id}>
                {item.kudos_message?
                  <button onClick={this.handleKudosModal} data-message={item.kudos_message} data-description={item.description} style={{padding:"0", fontSize:"12px", color:"#519acc", textAlign:"left"}} className="link-btn" aria-hidden="true">{item.description}</button>:
                  <p aria-hidden="true"> {item.description} </p>
                }
                <p aria-hidden="true">{dateTime}</p>
                <p aria-hidden="true" className={item.points > 0?"":"negative"}>{item.points > 0 ? "+" : "-"}{item.points}</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default RewardsHistory