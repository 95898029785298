import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Swal from "sweetalert2";
import ApiClient from "@utils/ApiClient";
import styled from "styled-components";
import ImpersonateIcon from "@icons/impersonate_icon";
import UserModal from "./user_modal";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.deactivateUser = this.deactivateUser.bind(this);
    this.submitDeleteUser = this.submitDeleteUser.bind(this);
    this.createEditModal = this.createEditModal.bind(this);
  }

  async createEditModal() {
    this.props.loadingHandler();
    const resp = await axios.get(
      `/admin_panel/users/${this.props.user.id}.json`
    );
    ReactDOM.render(
      <UserModal
        action="edit"
        currentUser={this.props.current_user}
        handleEditUser={this.props.handleEditUser}
        users={resp.data.user}
      />,
      document.getElementById("modalContainer")
    );
    this.props.loadingHandler();
    $("#userModal").width("545px");
  }

  deactivateUser() {
    const user = { ...this.props.user };
    const isActive = !user.active;
    const that = this;
    const formData = new FormData();
    formData.append("user[active]", isActive);
    const xhr = new XMLHttpRequest();
    xhr.open("PATCH", `/users_activate/${user.id}.json`, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 200) {
        const res = JSON.parse(xhr.response);
        that.props.handleEditUser(res);
        that.setState({ activeState: res.active });
        Swal.fire(
          !res.active ? "Deactivated" : "Activated",
          !res.active
            ? "Your User was successfully deactivated"
            : "Your User was successfully activated"
        );
      } else {
        Swal.fire("Deactivate user error", "There was an issue");
      }
    };
    Swal.fire({
      title: !user.active
        ? `Deactivate User: ${
            user ? user.name : user.name == "" ? user.email : user.email
          }`
        : `Reactivate User: ${
            user ? user.name : user.name == "" ? user.email : user.email
          }`,
      text: !user.active
        ? "Are you sure you want to reactivate this User?"
        : "Are you sure you want to deactivate this User?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        xhr.send(formData);
      } else if (result.dismiss) {
        // Swal.fire("Cancelled", "Your user is safe");
      }
    });
  }

  submitDeleteUser() {
    const object = this.props;
    const data = { user: { deactivated: true, active: false } };
    // Sweet alert that presents when user clicks trashcan icon
    Swal.fire({
      title: `Delete User: ${
        object.user
          ? object.user.name
          : object.user.name == ""
          ? object.user.email
          : object.user.email
      }`,
      text: "Are you sure that you want to delete this User?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        // Send ajax call to delete application choosen
        $.ajax({
          url: `/v1/users/${object.user.id}.json`,
          dataType: "json",
          data,
          type: "PATCH",
          cache: false,
          beforeSend(xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          success(data) {
            object.handleDeleteUser(object.user.id);
          },
          error(data) {
            // If error present error swal message
            Swal.fire({
              title: "Delete User Error",
              text: data.error,
              customClass: "swal2-error-modal",
            });
          },
        });
      } else if (result.dismiss) {
        // Swal.fire(
        //   'Cancelled',
        //   'This User is safe.'
        // )
      }
    });
  }

  impersonateUser = () => {
    const api = new ApiClient();
    api
      .post(`/v1/users/${this.props.user.id}/impersonate.json`, {})
      .then((res) => {
        const { user } = res.data;
        M.toast({
          html: `You are now impersonating user ${user.name}. You will redirect to user profile in 5 seconds.`,
          classes: "green darken-1",
        });
        setTimeout(() => {
          window.location.href = "/profile";
        }, 5000);
      });
  };

  render() {
    const { teams } = this.props.user;
    const options = [];
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      if (team.active) {
        options.push(
          <li
            className="teamlist"
            style={{
              overflow: "hidden",
              maxWidth: "calc(100% - 47px)",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            key={team.id}
          >
            {team.name}
          </li>
        );
      }
    }

    return (
      <tr style={{ color: this.props.user.active ? "" : "#a5a5a5" }}>
        <td
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {this.props.user.name != null
            ? this.props.user.name
            : this.props.user.email}
        </td>
        <td
          style={{
            wordBreak: "break-all",
            lineHeight: "22px",
            flexDirection: "column",
            padding: "5px 5px 5px 0",
            alignContent: "center",
          }}
        >
          {this.props.user.email}
        </td>
        <td>{this.props.user.created_at}</td>
        <td>{this.props.user.current_sign_in_at}</td>
        <td className="capitalize">{this.props.user.role}</td>
        <td>
          <ul style={{ padding: "5px 0", display: "flex", flexWrap: "wrap" }}>
            {options}
          </ul>
        </td>
        <td>
          <button className="link-btn" onClick={this.deactivateUser}>
            <i
              className="material-icons deactivate-icon"
              style={{
                color: `${this.props.user.active ? "#2bde2b" : "#ff5353"}`,
                fontSize: "28px",
                lineHeight: "30px",
              }}
            >
              power_settings_new
            </i>
          </button>
        </td>
        <td>
          <button className="link-btn" onClick={this.createEditModal}>
            <img
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/noun_edit.png"
              id="team-edit"
              className="hoverable responsive-img circle pointer"
              width="30"
              height="30"
            />
          </button>
        </td>
        <td>
          <button className="link-btn" onClick={this.submitDeleteUser}>
            <img
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/noun_Delete.png"
              id="team-delete"
              className="hoverable responsive-img circle pointer"
              width="30"
              height="40"
            />
          </button>
        </td>
        <td>
          <ImpersonateButton
            className="link-btn hoverable responsive-img circle pointer"
            onClick={this.impersonateUser}
          >
            <ImpersonateIcon />
          </ImpersonateButton>
        </td>
      </tr>
    );
  }
}

const ImpersonateButton = styled.button`
  height: 30px;
  width: 30px;
  display: flex;
  background-color: transparent;
  cursor: pointer;
  border: none;
`;

export default User;
