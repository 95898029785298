import React, { useState, useContext, useCallback } from "react";
import styled from "styled-components";
import ReactModalV2 from "@components/modals/react_modal_v2";
import { SubmitButton } from "@components/common/modal_buttons";
import AssessmentForm from "./form";
import { WikiAssessmentsContext } from "..";

const CreateAssessmentModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { assessments, setAssessments, wiki_resources } = useContext(
    WikiAssessmentsContext
  );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const updateAssessmentsList = useCallback(
    (newAssessmentData) => {
      setAssessments([...assessments, newAssessmentData]);
      window.location.href = `/wiki-assessments/${newAssessmentData.id}/edit`;
    },
    [assessments, setAssessments]
  );

  return (
    <CreateAssessmentContainer>
      <CreateAssessmentButton
        className="create-assessment-button"
        onClick={toggleModal}
      >
        Create Assessment
      </CreateAssessmentButton>
      <ReactModalV2
        id="create-new-assessment-modal"
        isShowing={isModalOpen}
        data={{ customClass: "create-new-assessment-modal" }}
        modalAction={toggleModal}
        page={() => (
          <AssessmentForm
            wiki_resources={wiki_resources}
            toggleModalVisible={toggleModal}
            onSubmitSuccessCb={updateAssessmentsList}
          />
        )}
      />
    </CreateAssessmentContainer>
  );
};

const CreateAssessmentContainer = styled.div``;

const CreateAssessmentButton = styled(SubmitButton)`
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export default CreateAssessmentModal;
