import React from "react";

const AutomationExpertSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Automation_Testing_Expert_-_Small_svg__cls-2{fill:#519acc}.Skills__Automation_Testing_Expert_-_Small_svg__cls-6{fill:#fff}.Skills__Automation_Testing_Expert_-_Small_svg__cls-10{fill:#5fbaed}.Skills__Automation_Testing_Expert_-_Small_svg__cls-11{fill:#68cffc}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Automation_Testing_Expert_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <circle cx={540} cy={540} r={418.69} fill="#edcc43" />{" "}
        <circle
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-2"
          cx={540}
          cy={540}
          r={365.71}
        />{" "}
        <rect
          x={247.47}
          y={345.6}
          width={585.06}
          height={384.37}
          rx={24.19}
          fill="#f7d64b"
        />{" "}
        <path fill="#002856" d="M267.63 368h544.74v336.88H267.63z" />{" "}
        <path fill="#362f31" d="M280.92 408.36h516.71v288.11H280.92z" />{" "}
        <path
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-6"
          d="M280.92 377.6h516.71v27.7H280.92z"
        />{" "}
        <circle cx={301.59} cy={391} r={7.44} fill="#b71d15" />{" "}
        <circle cx={323.7} cy={391} r={7.44} fill="#f4c404" />{" "}
        <circle cx={345.82} cy={391} r={7.44} fill="#2ecc71" />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-2"
          x={317.85}
          y={469.82}
          width={326.28}
          height={12.13}
          rx={6.06}
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-10"
          x={317.85}
          y={502.4}
          width={149.29}
          height={12.13}
          rx={6.06}
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-11"
          x={345.96}
          y={538.64}
          width={121.8}
          height={12.13}
          rx={6.06}
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-11"
          x={479.06}
          y={538.64}
          width={88.74}
          height={12.13}
          rx={5.87}
        />{" "}
        <rect
          x={327.25}
          y={430.27}
          width={88.74}
          height={12.13}
          rx={5.87}
          fill="#4588ad"
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-10"
          x={317.85}
          y={609.4}
          width={74.47}
          height={12.13}
          rx={6.06}
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-10"
          x={317.85}
          y={639.99}
          width={74.47}
          height={12.13}
          rx={6.06}
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-11"
          x={579.09}
          y={538.64}
          width={49.88}
          height={12.13}
          rx={6.06}
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-11"
          x={345.96}
          y={670.09}
          width={121.8}
          height={12.13}
          rx={6.06}
        />{" "}
        <rect
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-11"
          x={481.13}
          y={670.09}
          width={49.88}
          height={12.13}
          rx={6.06}
        />{" "}
        <path
          className="Skills__Automation_Testing_Expert_-_Small_svg__cls-6"
          d="M301.41 592.73a4 4 0 01-3.43-6l14.22-23.68a4 4 0 013.35-1.92 4 4 0 013.43 6l-14.23 23.69a3.93 3.93 0 01-3.34 1.91zM324.57 564.57v.11a3.17 3.17 0 001.49 2.66l15.19 9.49-15.07 9.66a3.15 3.15 0 00-1.45 2.68 3.16 3.16 0 004.87 2.64l20-12.82a2.68 2.68 0 000-4.52l-20.18-12.6a3.17 3.17 0 00-4.85 2.7zM419.65 424.37v.11a3.12 3.12 0 001.48 2.66l15.2 9.49-15.07 9.66a3.18 3.18 0 00-1.46 2.71 3.16 3.16 0 004.87 2.64l20-12.82a2.67 2.67 0 000-4.52l-20.18-12.6a3.16 3.16 0 00-4.84 2.67zM323.59 448.72v-.11a3.18 3.18 0 00-1.49-2.61l-15.19-9.49 15.09-9.7a3.17 3.17 0 001.45-2.69 3.16 3.16 0 00-4.86-2.64l-20 12.83a2.67 2.67 0 000 4.51l20.18 12.61a3.16 3.16 0 004.82-2.71z"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default AutomationExpertSmall;
