import React from 'react'

class EditIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        stroke={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path fill="none" strokeLinejoin="round" strokeWidth="2.5px" d="M2.88,58.5,9.13,45.29l43-37c1.57-1.35,3.67-1.48,4.7-.29l4,4.69c1,1.18.59,3.23-1,4.57l-43,37.07Z"/>
        <path fill="none" strokeLinejoin="round" strokeWidth="2.5px" d="M10.36,44.23a54.76,54.76,0,0,0,3.22,5.06,24.59,24.59,0,0,0,4.53,3.93"/>
        <path fill="none" strokeLinejoin="round" strokeWidth="2.5px" d="M10.36,44.23a28.48,28.48,0,0,0,3.22,5.06,24.59,24.59,0,0,0,4.53,3.93"/>
        <path fill="none" strokeLinejoin="round" strokeWidth="2.5px" d="M47.67,12.09a28.15,28.15,0,0,0,3.21,5.06,25,25,0,0,0,4.53,3.93"/>
      </svg>
    );
  }
}
EditIcon.defaultProps = {
  color: "#444344",
  height: "16px",
  svgStyles: {},
  width: "16px",
};
export default EditIcon