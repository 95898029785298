import React from "react";

const TrashSimpleIcon = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyles}
    >
      <path
        d="M5.4375 1.71H5.25C5.35313 1.71 5.4375 1.6245 5.4375 1.52V1.71H12.5625V1.52C12.5625 1.6245 12.6469 1.71 12.75 1.71H12.5625V3.42H14.25V1.52C14.25 0.681625 13.5773 0 12.75 0H5.25C4.42266 0 3.75 0.681625 3.75 1.52V3.42H5.4375V1.71ZM17.25 3.42H0.75C0.335156 3.42 0 3.75962 0 4.18V4.94C0 5.0445 0.084375 5.13 0.1875 5.13H1.60312L2.18203 17.5513C2.21953 18.3611 2.88047 19 3.67969 19H14.3203C15.1219 19 15.7805 18.3635 15.818 17.5513L16.3969 5.13H17.8125C17.9156 5.13 18 5.0445 18 4.94V4.18C18 3.75962 17.6648 3.42 17.25 3.42ZM14.1398 17.29H3.86016L3.29297 5.13H14.707L14.1398 17.29Z"
        fill={color}
      />
    </svg>
  );
};

TrashSimpleIcon.defaultProps = {
  width: "19",
  height: "20",
  svgStyles: {},
  color: "#519acc",
};

export default TrashSimpleIcon;
