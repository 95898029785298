import React from 'react'
import CancelIcon from '../icons/cancel_icon'
import EditIcon from '../icons/edit_icon'
import Swal from 'sweetalert2'

class PersonalInfoBox extends React.Component {
  constructor(props){
    super(props)
    var dataIds = Array.isArray(this.props.data)?this.props.data.map((d)=>d.id):[]
    this.state = {
      edit: false,
      data: this.props.data,
      options: dataIds.length>0&&this.props.currentUser?this.props.options.filter((o)=>dataIds.indexOf(o.id) === -1):this.props.options,
      search: "",
      searchResults: [],
      currentMenuIndex: null,
      keyMoving: false,
      loading: false
    }
    this.textareaRef = React.createRef()
    this.inputRef = React.createRef()
    this.infoSelectRef = React.createRef()
    this.searchMenuRef = React.createRef()
    this.addButtonRef = React.createRef()
  }

  handleEdit=()=>{this.setState({edit:!this.state.edit})}

  handleSave=()=>{
    this.setState({loading: true})
    var value = this.textareaRef.current.value?this.textareaRef.current.value:""
    var handlerPromise = new Promise((resolve, reject) => {
      this.props.handler(value, resolve, reject);
    })
    handlerPromise.then(()=>{
      this.setState({data: value.trim(), edit: false, loading:false})
    })
    .catch(()=>{
      this.setState({loading:false})
    })
  }

  handleInputFocus=()=>{
    if(this.state.search){
      this.setState({
        searchResults: this.state.options.filter((o)=>o.value.toLowerCase().includes(this.state.search.toLowerCase()))
      })
    }
  }

  handleInputChange=(e)=>{
    if(e.target.value){
      this.setState({
        search: e.target.value,
        searchResults: this.state.options.filter((o)=>o.value.toLowerCase().includes(e.target.value.toLowerCase())),
        currentMenuIndex: null,
        keyMoving: false
      })
    } else {
      this.setState({
        search: "",
        searchResults: [],
        currentMenuIndex: null,
        keyMoving: false
      })
    }
  }

  handleInputBlur=(e)=>{
    setTimeout(()=>{
      this.setState({searchResults: [], currentMenuIndex: null, keyMoving: false})
    }, 10)
    
  }

  handleOptionClick=(e)=>{
    var value = e.target.dataset.value
    this.setState({search: value})
  }

  handleOptionKeyDown=(e)=>{
    var code = e.keyCode
    var {currentMenuIndex, searchResults} = this.state
    if([9,13].indexOf(code)!==-1&&currentMenuIndex!==null){
      this.setState({search: searchResults[currentMenuIndex].value})
      this.addButtonRef.current.focus()
    } else if(code === 40 && searchResults.length>0 && currentMenuIndex!==searchResults.length-1){
      this.setState({currentMenuIndex: currentMenuIndex===null?0:currentMenuIndex+1, keyMoving:true})
    } else if(code === 38 && searchResults.length>0 && currentMenuIndex){
      this.setState({currentMenuIndex: currentMenuIndex-1, keyMoving:true})
    }
  }

  handleOptionMouseEnter=(e)=>{
    var index = parseInt(e.target.dataset.index)
    this.setState({currentMenuIndex: index, keyMoving:false})
  }

  handleAddItem=(e)=>{
    this.setState({loading: true})
    var {options, data, search} = this.state
    var optionNamesArray = options.map((o)=>o.value.trim().toLowerCase())
    var dataNamseArray = data.map((o)=>o.value.trim().toLowerCase())
    if(dataNamseArray.indexOf(search.trim().toLowerCase())!==-1){
      M.toast({html: `<span role="alert">You Already Have This ${this.props.item.charAt(0).toUpperCase() + this.props.item.slice(1)}</span>`, classes: "red"})
      this.setState({loading: false})
      return
    }
    var index = optionNamesArray.indexOf(search.trim().toLowerCase())
    if(index !== -1){
      var dataIds = data.map((d)=>d.id)
      dataIds.push(options[index].id)
      data.push(options[index])
      options.splice(index, 1)
      var handlerPromise = new Promise((resolve, reject) => {
        this.props.handler(dataIds, `${this.props.item}`, {[`${this.props.item}s`]:data}, resolve, reject);
      })
      handlerPromise.then(()=>{
        this.setState({data, options, loading:false, search:""})
      }).catch(()=>{
        this.setState({loading:false})
      })
    } else {
      Swal.fire({
        title: `Create ${this.props.item.charAt(0).toUpperCase() + this.props.item.slice(1)}`,
        text: `Create a new ${this.props.item} titled "${search}"? (Make sure your spelling is correct)`,
        reverseButtons: true,
        showCancelButton:true,
        confirmButtonAriaLabel: 'Yes',
        cancelButtonAriaLabel: 'cancel',
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          var handlerPromise = new Promise((resolve, reject) => {
            this.props.createHandler(this.props.item, search, resolve, reject)
          })
          handlerPromise.then((item)=>{
            data.push(item)
            this.setState({data, loading:false, search:""})
          }).catch((err)=>{
            console.error(err)
            this.setState({loading:false})
          })
          
        } else if (result.dismiss) {
          this.setState({loading:false})
        }
      })
    }
  }

  handleRemoveItem=(e)=>{
    this.setState({loading: true})
    var {options, data} = this.state
    var index = parseInt(e.target.dataset.index)
    var item = data[index]
    if(item){
      var dataIds = data.filter((d)=>d.id!==item.id).map((d)=>d.id)
      data.splice(index, 1)
      options.push(item)
      var handlerPromise = new Promise((resolve, reject) => {
        this.props.handler(dataIds, `${this.props.item}`, {[`${this.props.item}s`]:data}, resolve, reject);
      })
      handlerPromise.then(()=>{
        this.setState({data, options, loading:false})
      }).catch(()=>{
        this.setState({loading:false})
      })
    }
  }

  render(){
    var {edit, data, search, searchResults, currentMenuIndex, keyMoving, loading} = this.state
    var {type, item, placeHolder, currentUser} =  this.props
    return(
      <div className="personal-info-box">
        <div>
          <div style={{display:"flex", alignItems:"center"}}>
            <this.props.image svgStyles={{width:"30px", height:"30px", marginRight:"8px"}} />
            <h3>{this.props.title}</h3>
          </div>
          {(type==="text"&&currentUser)&&
            <div>
              {edit?
                <div style={{display:"flex"}}>
                  <button aria-label={`Save changes to ${this.props.title}`} onClick={this.handleSave} style={{marginRight:"4px"}}><i style={{fontSize:"20px", color:"#519acc"}} className="material-icons">done</i></button>
                  <button aria-label={`Cancel ${this.props.title} edit`} onClick={this.handleEdit}><i style={{fontSize:"20px", color:"#519acc"}} className="material-icons">close</i></button>
                </div>:
                <button aria-label={`Edit ${this.props.title}`} onClick={this.handleEdit}><EditIcon svgStyles={{width:"30px", height:"30px", pointerEvents:"none"}} /></button>
              }
            </div>
          }
        </div>
        {type==="text"?
          <div className="info-type-text">
            {edit?
              <textarea autoFocus={true} ref={this.textareaRef} maxLength={1000} type="text" placeholder={placeHolder} className="common-input browser-default" defaultValue={data} /> :
              <p>{data}</p>
            }
          </div> : 
          <div className="info-type-select">
            {currentUser?<p>Add a new {item} using the field below</p>:<p/>}
            {currentUser?<div>
              <input disabled={loading} onKeyDown={this.handleOptionKeyDown} maxLength={45} ref={this.inputRef} onChange={this.handleInputChange} onBlur={this.handleInputBlur} value={search} onFocus={this.handleInputFocus} type="text" placeholder={placeHolder} className="common-input browser-default" />
              <button aria-label={`Add ${item} ${search?search:""}`} onClick={this.handleAddItem} disabled={!search||loading} ref={this.addButtonRef} className="common-button-submit">Add {item}</button>
              {(search&&searchResults.length>0)&&
                <div ref={this.searchMenuRef} className={`info-box-search-menu new-scrollbar`}>
                  {searchResults.map((o,i)=>{
                    return(
                      <div 
                        data-index={i} 
                        onMouseEnter={this.handleOptionMouseEnter} 
                        className={`${i===currentMenuIndex?"key-down-highlight":""} ${keyMoving?"key-moving":""}`} 
                        data-id={o.id} data-value={o.value} 
                        onMouseDown={this.handleOptionClick} 
                        key={o.id}
                      >
                        {o.value}
                      </div>
                    )
                  })}
                </div>
              }
            </div>:<div style={{display:"none"}}/>}
            <div style={{maxHeight:currentUser?"calc(100% - 96px)":"100%"}} className="new-scrollbar">
                {data.map((d, i)=>{
                  return(
                    <div className={currentUser?"current-user":""} key={d.id}>
                      <span>{d.value}</span>
                      {currentUser&&<button aria-label={`Remove ${this.props.item} ${d.value}`} data-index={i} onClick={this.handleRemoveItem}>x</button>}
                    </div>
                  )
                })}
              </div>
          </div>
        }
      </div>
    )
  }
}

export default PersonalInfoBox