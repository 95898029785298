import React from "react";
import { components } from "react-select";
import RoundDropdown from "./round_dropdown";

export function assigneeOption(props) {
  return (
    <div aria-hidden="true">
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.data.image ? (
            <div
              aria-hidden="true"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <img
                alt="user"
                style={{ height: "30px", width: "30px" }}
                className="select-avatar-image"
                src={props.data.image}
              />
            </div>
          ) : (
            <div
              aria-hidden="true"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <img
                alt="user"
                style={{ height: "30px", width: "30px" }}
                className="select-avatar-image"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
              />
            </div>
          )}
          <p style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
            {props.data.label}
          </p>
        </div>
      </components.Option>
    </div>
  );
}

export function assigneeSingleValue({ children, ...props }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        position: "relative",
        top: "-2px",
        height: "40px",
        alignItems: "center",
      }}
      className="flex-container flex-al-center"
    >
      {props.data.image && (
        <img
          alt="user-img"
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            marginRight: "5px",
          }}
          src={props.data.image}
        />
      )}
      <components.SingleValue {...props}>{children}</components.SingleValue>
    </div>
  );
}

export function singleValue(props) {
  return (
    <div aria-hidden="true">
      <components.SingleValue {...props} />
    </div>
  );
}

export function dropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <RoundDropdown />
    </components.DropdownIndicator>
  );
}

export function customControl(props) {
  return (
    <div>
      <p className="custom-select-input">{props.selectProps.inputValue}</p>
      <components.Control {...props} />
    </div>
  );
}

export function customMultiControl(props) {
  var color = "";
  if (props.selectProps.inputValue != "") {
    color = "";
  } else {
    color = "hsl(0,0%,50%)";
  }
  return (
    <div>
      <p
        style={{
          color: color,
          left:
            props.selectProps.placeholder.includes("watchers") ||
            props.selectProps.placeholder.includes("labels")
              ? "15px"
              : "",
        }}
        className="custom-select-input"
      >
        {props.selectProps.inputValue != ""
          ? props.selectProps.inputValue
          : props.selectProps.placeholder}
      </p>
      <components.Control {...props} />
    </div>
  );
}

export function customMultiValueRemove(props) {
  const { data, selectProps, innerProps } = props;
  return (
    <components.MultiValueRemove
      data={data}
      selectProps={selectProps}
      innerProps={{
        ...innerProps,
        role: "button",
        "aria-label": `Remove ${selectProps["aria-label"].split(" ")[0]} ${
          data.label
        }`,
      }}
    />
  );
}

export function groupHeading(props) {
  return (
    <div style={{ display: "flex", alignItems: "center", flex: "1 0 100%" }}>
      <components.GroupHeading {...props}>
        {props ? props.children : ""}
      </components.GroupHeading>
      <div
        style={{
          flex: 0.95,
          height: "1px",
          backgroundColor: "#e0e0e0",
        }}
      ></div>
    </div>
  );
}

export function labelInput(props) {
  return <components.Input {...props} maxLength={20} />;
}

export function CustomValueContainer({ children, ...props }) {
  return (
    <components.ValueContainer {...props}>
      <div
        id="projectSideDrop"
        message={
          props.selectProps.inputValue != ""
            ? props.selectProps.inputValue
            : props.selectProps.value.label
            ? props.selectProps.value.label
            : ""
        }
      ></div>
      {props.selectProps.inputValue && props.selectProps.inputValue != "" ? (
        <components.Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
      ) : (
        <p>{props.selectProps.inputValue}</p>
      )}
      {React.Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null
      )}
    </components.ValueContainer>
  );
}

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className="material-icons base-color-hover"
        style={{ color: "inherit" }}
      >
        search
      </i>
    </components.DropdownIndicator>
  );
}
