import React from "react";
import axios from "axios";
import ReactGA from "react-ga";
import Swal from "sweetalert2";

ReactGA.initialize(`${process.env.G_ANALYTICS_TAG}`, {
  debug: !process.env.NODE_ENV == "production",
  titleCase: false,
});

class TestStepBugs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bugs: [],
      loading: true,
      validBug: null,
      bugCheckLoading: false,
      bugCheckStatusText: "",
    };
    this.bugCheckTimeout = null;
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    document.getElementById("test-step-bugs-container-title").focus();
    const elem = document.querySelector(".new-modal-open");
    elem.style.top = "5%";
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    if (this.props.data.existingBugs) {
      axios
        .get(`/get_test_step_bugs/${this.props.data.testStepId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: this.props.data.device
            ? { device: this.props.data.device }
            : {},
        })
        .then((res) => {
          this.setState({
            bugs: res.data,
            loading: false,
          });
        })
        .catch((err) => console.error(err));
    }
  }

  componentWillUnmount() {
    const elem = document.querySelector(".new-modal-open");
    elem.style.top = "12%";
    const previousFocusElem = document.querySelector(
      this.props.data.currentResultSelector
    );
    if (previousFocusElem) {
      previousFocusElem.focus();
    }
  }

  handleAddBug = (e) => {
    this.buttonRef.current.disabled = true;
    axios
      .post(
        "/add_test_step_bug",
        this.props.data.device
          ? {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              project_id: this.props.data.projectId,
              id: this.refs.bug_text.value,
              test_step_id: this.props.data.testStepId,
              test_pass_id: this.props.data.testPassId,
              device: this.props.data.device,
            }
          : {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              project_id: this.props.data.projectId,
              id: this.refs.bug_text.value,
              test_step_id: this.props.data.testStepId,
              test_pass_id: this.props.data.testPassId,
            }
      )
      .then((res) => {
        M.toast({
          html: '<p role="alert">Bug added!</p>',
          displayLength: 3000,
          classes: "green",
        });
        this.props.modalAction(false, "", {});
        ReactGA.event({
          category: "Test Step",
          action: "Test Step Bug Link Created",
        });
      })
      .catch((err) => {
        ReactGA.event({
          category: "Test Step",
          action: "Test Step Bug Link Error",
        });
        console.error(err);
      });
  };

  handleDeleteBug = (e) => {
    const ticketId = e.target.dataset.bugId;
    const { testDeviceTicketId } = e.target.dataset;
    Swal.fire({
      title: "Remove Bug",
      text: "Are you sure that you want to remove this Bug?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete("/delete_test_step_bug", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: testDeviceTicketId
              ? {
                  ticket_id: ticketId,
                  test_step_id: this.props.data.testStepId,
                  test_pass_id: this.props.data.testPassId,
                  test_device_ticket_id: testDeviceTicketId,
                }
              : {
                  ticket_id: ticketId,
                  test_step_id: this.props.data.testStepId,
                  test_pass_id: this.props.data.testPassId,
                },
          })
          .then((res) => {
            M.toast({
              html: "Bug removed",
              displayLength: 3000,
              classes: "green",
            });
            let updatedBugs = this.state.bugs;
            updatedBugs = updatedBugs.filter(
              (bug) => bug.ticket_id !== res.data.ticket_id
            );
            this.setState({ bugs: updatedBugs });
            ReactGA.event({
              category: "Test Step",
              action: "Test Step Bug Link Deleted",
            });
          })
          .catch((err) => console.error(err));
      } else if (result.dismiss) {
      }
    });
  };

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  checkForBug = (identifier) => {
    axios
      .get(
        `/check_for_bug/${identifier}?projectId=${this.props.data.projectId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.ticket_found === true) {
          const ticketIdsArray = this.state.bugs.map((bug) => bug.ticket_id);
          if (ticketIdsArray.includes(res.data.ticket_id)) {
            this.setState({
              validBug: false,
              bugCheckStatusText: "Bug already linked to test case.",
              bugCheckLoading: false,
            });
          } else {
            this.setState(
              {
                validBug: true,
                bugCheckStatusText: (
                  <span
                    aria-live="assertive"
                    role="text"
                    aria-label={`Bug Found. ${res.data.ticket_title}.`}
                  >
                    Bug Found -{" "}
                    <span style={{ fontStyle: "italic", color: "#77b0cc" }}>
                      {res.data.ticket_title}
                    </span>
                  </span>
                ),
                bugCheckLoading: false,
              },
              () => {
                if (this.repeatHelper === ".") {
                  this.repeatHelper = "";
                } else {
                  this.repeatHelper = ".";
                }
                this.buttonRef.current.focus();
                this.buttonRef.current.setAttribute(
                  "aria-label",
                  `Add Bug${this.repeatHelper}`
                );
              }
            );
          }
        } else {
          this.setState({
            validBug: false,
            bugCheckStatusText: "Invalid indentifier or bug not found.",
            bugCheckLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          validBug: false,
          bugCheckStatusText: "Invalid indentifier or bug not found.",
          bugCheckLoading: false,
        });
        console.error(err);
      });
  };

  handleNewBugChange = (e) => {
    let text = e.target.value;
    if (text === "") {
      this.setState({
        validBug: null,
        bugCheckStatusText: "",
        bugCheckLoading: false,
      });
    } else {
      // this.refs.comment_add_button.disabled = false;
      if (text.includes("<") || text.includes(">")) {
        text = text.replace(/</g, "");
        text = text.replace(/>/g, "");
        e.target.value = text;
      }
      if (this.state.bugCheckLoading === false) {
        this.setState({ bugCheckLoading: true });
      }
    }

    if (this.bugCheckTimeout) {
      clearTimeout(this.bugCheckTimeout);
      this.bugCheckTimeout = null;
    }
    this.bugCheckTimeout = setTimeout(() => {
      if (text !== "") {
        this.checkForBug(text);
      }
    }, 1000);
  };

  render() {
    return (
      <div
        role="dialog"
        aria-label="Test Case Bugs Modal"
        className="modal-content"
      >
        <div id="test-step-bugs-container">
          {this.props.data.existingBugs && (
            <div id="test-step-existing-bugs-container">
              <h2
                style={{ outline: "none" }}
                tabIndex={0}
                id="test-step-bugs-container-title"
              >
                <span role="text">
                  TC {this.props.data.testCaseNumber}
                  {this.props.data.device
                    ? ` ${this.props.data.device}`
                    : ""}{" "}
                  Linked Bugs
                </span>
              </h2>
              {this.state.loading ? (
                <div id="test-step-bugs-loader" />
              ) : (
                <div>
                  {this.state.bugs.map((bug, index) => {
                    const status = bug.ticket_status;
                    const statusLabel =
                      status === 1
                        ? "Open"
                        : status === 2
                        ? "Closed"
                        : status === 3
                        ? "Fixed"
                        : status === 5
                        ? "Won't Fix"
                        : "In Progress";
                    return (
                      <div
                        key={index}
                        className="test-step-single-bug-container"
                      >
                        <span className="test-step-bug-title">
                          <a
                            href={bug.ticket_url}
                            aria-label={`${bug.ticket_identifier}, ${bug.ticket_title}, status ${statusLabel}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {bug.ticket_identifier}
                          </a>
                          <span aria-hidden="true">{` - ${bug.ticket_title} - `}</span>
                          <span
                            aria-hidden="true"
                            style={{
                              color: `${
                                status === 1
                                  ? "green"
                                  : status === 2 || status === 5
                                  ? "black"
                                  : status === 3
                                  ? "red"
                                  : "#cab200"
                              }`,
                            }}
                          >
                            {statusLabel}
                          </span>
                          <i
                            role="button"
                            aria-label={`remove bug ${bug.ticket_identifier}`}
                            className="material-icons test-step-comment-delete-icon"
                            style={{ opacity: "0" }}
                            onClick={this.handleDeleteBug}
                            data-test-device-ticket-id={
                              bug.test_device_ticket_id
                            }
                            data-bug-id={bug.ticket_id}
                          >
                            delete
                          </i>
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div id="test-step-new-bug-container">
            <h2
              style={{ outline: "none" }}
              tabIndex={0}
              className="small-title"
              id="test-step-bugs-container-title"
            >
              Link New Bug
            </h2>
            <span style={{ color: "#519acc", fontSize: "18px" }}>
              {this.props.data.projectIdentifier}-
            </span>
            <input
              ref="bug_text"
              maxLength={12}
              style={{ height: "30px", width: "150px" }}
              aria-label="Enter bug ID, not including the project identifier"
              placeholder="Enter bug ID"
              onChange={this.handleNewBugChange}
            />
            <span
              style={{ display: "block", height: "28px", lineHeight: "14px" }}
            >
              {this.state.bugCheckLoading ? (
                <div id="test-step-check-bug-loader" />
              ) : (
                this.state.bugCheckStatusText
              )}
            </span>
            <button
              aria-label="Add Bug"
              aria-live="polite"
              ref={this.buttonRef}
              disabled={!this.state.validBug}
              className="add-test-step-bug-button btn"
              onClick={this.handleAddBug}
            >
              Add Bug
            </button>
          </div>
        </div>
        <button
          aria-label="close bug modal"
          className="icon-close-btn"
          style={{
            display: "block",
            position: "absolute",
            top: "10px",
            right: "15px",
          }}
          onClick={this.closeModal}
        />
      </div>
    );
  }
}
export default TestStepBugs;
