import React from 'react'

class BugsIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        className="icons"
      >
        <line strokeMiterlimit="10" stroke="#fff" strokeWidth="2px" x1="22.95" y1="11.49" x2="17.87" y2="3.31"/>
        <line strokeMiterlimit="10" stroke="#fff" strokeWidth="2px" x1="41.05" y1="11.49" x2="46.55" y2="3.31"/>
        <path strokeMiterlimit="10" stroke="#519acc" d="M18.87,21.78c0-7.14,5.79-17.28,12.94-17.28S44.74,14.64,44.74,21.78"/>
        <circle strokeMiterlimit="10" stroke="#519acc" strokeWidth="2px" cx="32" cy="40.79" r="21.9"/>
      </svg>
    );
  }
}
BugsIcon.defaultProps = {
  color: "#fff",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default BugsIcon