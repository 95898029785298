import React from 'react'
import axios from 'axios'

class AddIntegration extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.props.data.type === "slack" && !this.refs.integration_webhook.value.includes("http://")){
      M.toast({html: `Invalid webhook`, displayLength: 3000, classes: 'red' });
      return
    }
    axios.post(`/api/v1/integrations/${this.props.data.application.id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
         name: this.props.data.type,
         webhook: this.refs.integration_webhook.value,
         channel: this.refs.integration_channel ? this.refs.integration_channel.value : ""
      })
    .then((res)=>{
        M.toast({html: `Integration created!`, displayLength: 3000, classes: 'green' });
        this.props.data.refreshIntegrations()
        this.props.modalAction(false, "", {})
    })
    .catch((err)=>console.error(err))
  }
  
  closeModal = () => {
    this.props.modalAction(false, "", {})
  }

  getIntegrationType = () =>{
    if(this.props.data.type === "slack"){
      return (
        <div>
          <button className="right icon-close-btn" onClick={this.closeModal}></button>
          <form onSubmit={this.handleSubmit}>
            <div id="slack-modal-form">
              <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img alt="slack icon" className="center" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/slack.png" width="50" height="50"/>
              </div>
              <p className="center">Integrate {this.props.data.application.name} builds with Slack </p>
            <div  >
              <label style={{display:"inline-block", width:"90%", textAlign:"left", color:"black", fontSize:"16px"}} htmlFor="integration_webhook">Webhook URL</label>
              <input className="integration-field" style={{height:"30px", boxShadow:"none", width:"90%", borderRadius:"0px", backgroundColor:"#f5f5f5", padding:"0px 8px", border:"solid 2px #e0e0e0"}} required="required" type="text" name="integration[webhook]" id="integration_webhook" ref="integration_webhook" />
            </div>
            <div >
              <label style={{display:"inline-block", width:"90%", textAlign:"left", color:"black", fontSize:"16px"}} htmlFor="integration_channel">Channel</label>
              <input className="integration-field" style={{height:"30px", boxShadow:"none", width:"90%", borderRadius:"0px", backgroundColor:"#f5f5f5", padding:"0px 8px", border:"solid 2px #e0e0e0"}} required="required" type="text" name="integration[channel]" id="integration_channel" ref="integration_channel" />
            </div>
            <div className="center">
              <input type="submit" className="btn" name="commit" value="Create" id="integration-button" />
            </div>
          </div>
          </form>
        </div>
      )
    } else if (this.props.data.type === "email"){
      return (
        <div>
          <button
            className="right icon-close-btn"
            onClick={this.closeModal}
          ></button>
          <form onSubmit={this.handleSubmit}>
            <div className="center" id="email-modal-form">
              <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img
                  alt="mail icon"
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/mail-icon.png"
                  width="50"
                  height="50"
                />
              </div>
              <p className="center">
                Integrate Test Project&apos;s builds with your email
              </p>
              <input
                style={{
                  height: "30px",
                  boxShadow: "none",
                  width: "90%",
                  borderRadius: "0px",
                  backgroundColor: "#f5f5f5",
                  padding: "0px 8px",
                  border: "solid 2px #e0e0e0",
                }}
                required="required"
                type="text"
                name="integration[webhook]"
                id="integration_webhook"
                ref="integration_webhook"
                className="integration-field block"
              />
              <div>
                <input
                  type="submit"
                  className="btn"
                  name="commit"
                  value="Create"
                  id="integration-button"
                />
              </div>
            </div>
          </form>
        </div>
      );
    } else if (this.props.data.type === "android" || this.props.data.type === "xcode"){
      return (
        <div>
          <button className="right icon-close-btn" onClick={this.closeModal}></button>
          <div className="center" id={`${this.props.data.type}-modal-form`}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <img alt="modal icon" src={`https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/${ this.props.data.type == "android" ? "android-studio-logo.png" : "xcode_icon.png"}`} width="50" height="50"/>
            </div>
            <p>Integrate Test Project&apos;s builds with {this.props.data.type == "android" ? "Android Studio" : "Xcode"}</p>
            <p className="subtitle">Follow the steps<i className="material-icons">arrow_downward</i></p>
            <a id="integration-button" className="btn btn-overflow" href={this.props.data.type == "android" ? "http://docs.plusqa.com/" : "http://docs.plusqa.com/#xcode-integration"} target="_blank">{this.props.data.type} integration</a>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
    <div className="modal-content">
        {this.getIntegrationType()}
    </div>
    )
  }

}
export default AddIntegration