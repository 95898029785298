import React from 'react'

class NoEODReportIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        stroke={this.props.stroke}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        className="icons"
      >
        <g style={{isolation:"isolate", opacity:"0.2"}}>
          <circle style={{fill:"#00100f"}} cx="32.01" cy="33.16" r="29.38"/>
        </g>
        <circle style={{fill:"#519acc"}} cx="32.01" cy="30.84" r="29.38"/>
        <path style={{isolation:"isolate", opacity:"0.2", fill:"#fff"}} d="M32,1.62a29.22,29.22,0,0,0-29.39,29c0,.4,0,.81.05,1.21a29.37,29.37,0,0,1,58.68-.36c0-.28,0-.57,0-.85A29.22,29.22,0,0,0,32,1.62Z"/>
        <g style={{opacity:"0.33"}}>
          <path style={{fill:"#231f20"}} d="M46.94,16.49V49.05a1.61,1.61,0,0,1-1.46,1.73H19.62a1.61,1.61,0,0,1-1.47-1.73V14.76H45.48A1.61,1.61,0,0,1,46.94,16.49Z"/>
        </g>
        <path style={{fill:"#fff"}} d="M46.94,15.11V47.67a1.6,1.6,0,0,1-1.46,1.72H19.62a1.61,1.61,0,0,1-1.47-1.72V13.38H45.48A1.61,1.61,0,0,1,46.94,15.11Z"/>
        <path style={{fill:"#519acc"}} d="M37,33.58a1.52,1.52,0,0,1,.13,2,1.44,1.44,0,0,1-2.09.06l-3-3,0,0-2.94,2.93a1.44,1.44,0,0,1-2,0,1.45,1.45,0,0,1,0-2L30,30.74s0,0,0,0l-2.82-2.84a1.49,1.49,0,0,1-.14-2,1.46,1.46,0,0,1,2.09-.06l3,3,3-3a1.44,1.44,0,0,1,2,2l-2.93,2.93v0Z"/>

      </svg>
    );
  }
}
NoEODReportIcon.defaultProps = {
  color: "#fff",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default NoEODReportIcon