import React from 'react'
import axios from "axios";

class QRCode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      qr:""
    }
  }

  componentDidMount(){
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
    this.props.setBackdropLoader(true)
    axios.get(`/qr_code/${this.props.data.projectId}?user_id=${this.props.data.userId}${this.props.data.ticketId?`&ticket_id=${this.props.data.ticketId}`:""}`)
    .then((res)=>{
      this.setState({ qr:res.data })
      this.props.setBackdropLoader(false)
    })
    .catch((err)=>{
      this.props.setBackdropLoader(false)
      console.error(err)
    })
  }

  handleCancelButton = () => {
    this.props.modalAction(false, "", {})
  }

  render() {
    return (
    <div style={{padding:"30px 50px", textAlign:"center", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
      <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"90px", width:"90px", borderRadius:"50%", padding:"4px", boxShadow:"0px 1px 3px #c3c3c3"}} aria-hidden="true">
        <img style={{position:"relative", bottom:"2px"}} src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/Tess_Head.svg" />
      </div>
      <h2 style={{margin:"20px 0 12px"}}>{this.props.data.ticketId?"Add a screenshot or console log ": this.props.data.media ? "Add files to the Media Library " : "Create a new bug "} via the Test Platform mobile app</h2>
      <p>Open the Test Platform app on your mobile device and scan the QR code below to get started.</p>
      <div style={{padding:"20px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", borderRadius:"6px", boxShadow:"#d8d8d8 0px 1px 3px 1px", margin:"20px 0"}}>
        {this.props.data.ticketId&&<p style={{fontWeight:"600", marginBottom:"20px", lineHeight:"0"}}>{this.props.data.identifier}</p>}
        {/* empty div is just to fill 175x175 space while qr code is pulled */}
        {this.state.qr?
          <img style={{height:"175px", width:"175px", position:"relative", display:"inline-block"}} src={`data:image/svg+xml;utf8,${encodeURIComponent(this.state.qr)}`} /> :
          <div style={{width:"175px", height:"175px"}} />
        }
      </div>
      <p>Don't see the app on your device? <a href="mailto:testlab@plusqa.com">Let us know</a></p>
      <button style={{position:"absolute", top:"20px", right:"25px"}} onClick={this.handleCancelButton} aria-label="close-modal" className="icon-close-btn" ></button>
    </div>
    )
  }
}
export default QRCode