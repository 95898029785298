import BugsBreakdown from "./BugsBreakdown";
import BugsStepsDualBreakdown from "./BugsStepsDualBreakdown";
import HoursBreakdown from "./HoursBreakdown";
import PieBreakdown from "./PieBreakdown";
import TestStepBreakdown from "./TestStepBreakdown";
import UserList from "./UserList";

const Widgets = {
  BugsBreakdown,
  BugsStepsDualBreakdown,
  HoursBreakdown,
  PieBreakdown,
  TestStepBreakdown,
  UserList,
};

export {
  BugsBreakdown,
  BugsStepsDualBreakdown,
  HoursBreakdown,
  PieBreakdown,
  TestStepBreakdown,
  UserList,
};

export default Widgets;
