import React from 'react'

class AddDeviceIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52.07 76.9"
        className="icons"
      >
        <circle cx="25.7" cy="65.51" r="10.36"/>
        <path fill="#fff" d="M27,70.37a1.35,1.35,0,0,1-1.22,1.38,1.27,1.27,0,0,1-1-.37,1.33,1.33,0,0,1-.38-.92V66.68H20.65a1.3,1.3,0,1,1,0-2.59h3.72a0,0,0,0,0,0,0v-3.6a1.35,1.35,0,0,1,1.22-1.39A1.3,1.3,0,0,1,27,60.37v3.79h3.78a1.29,1.29,0,1,1,0,2.58H27s0,0,0,0Z"/>
        <path d="M6.29,1.66A2.62,2.62,0,0,0,3.65,4.3V40.8a2.62,2.62,0,0,0,2.64,2.64.6.6,0,0,0,.47-.15l-.16.15H25.08a1.4,1.4,0,1,0,0-2.79H6.29V4.3H34.56V11a1.4,1.4,0,1,0,2.79,0V4.3a2.62,2.62,0,0,0-2.64-2.64ZM30.36,13.78a2.62,2.62,0,0,0-2.64,2.64V41a2.62,2.62,0,0,0,2.64,2.64H45.12A2.62,2.62,0,0,0,47.76,41V16.42a2.61,2.61,0,0,0-2.64-2.64Zm0,2.64H45.12V41H30.36ZM20.27,35.52a2,2,0,1,0,2,2A2,2,0,0,0,20.27,35.52Zm17.55,0a2,2,0,1,0,2,2A2.05,2.05,0,0,0,37.82,35.52Z"/>
      </svg>
    );
  }
}
AddDeviceIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default AddDeviceIcon