import React from "react";

const WeekSelectedFilled = (props) => {
  return (
    <svg
      id="Icons_Week_-_Outline_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>{".Icons_Week_-_Outline_svg__cls-1{fill:#519acc}"}</style>{" "}
      </defs>{" "}
      <rect
        className="Icons_Week_-_Outline_svg__cls-1"
        x={21.81}
        y={34.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_-_Outline_svg__cls-1"
        x={14.14}
        y={34.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_-_Outline_svg__cls-1"
        x={29.97}
        y={34.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_-_Outline_svg__cls-1"
        x={38.15}
        y={34.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_-_Outline_svg__cls-1"
        x={46.32}
        y={34.36}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <path
        className="Icons_Week_-_Outline_svg__cls-1"
        d="M13.34 50.74h37.91A3.75 3.75 0 0055 47V17a3.75 3.75 0 00-3.75-3.75H13.34A3.75 3.75 0 009.59 17v30a3.75 3.75 0 003.75 3.74zm37.91-2H13.34A1.75 1.75 0 0111.59 47V25H53v22a1.76 1.76 0 01-1.75 1.75zM13.34 15.26h37.91A1.76 1.76 0 0153 17v6H11.59v-6a1.75 1.75 0 011.75-1.74z"
      />{" "}
    </svg>
  );
};

export default WeekSelectedFilled;
