import React from "react";

const PerformanceBreakdownIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="35" height="35" fill="#E5E5E5" />
      <g id="High Fidelity - Performance Profile V2">
        <rect
          width="1442"
          height="2788"
          transform="translate(-311 -707)"
          fill="white"
        />
        <g id="Content">
          <rect
            width="1148"
            height="2705"
            transform="translate(-43 -624)"
            fill="#F8F8F8"
          />
          <g id="Frame 56">
            <rect
              x="-32.5"
              y="-39.5"
              width="1130"
              height="907"
              rx="4.5"
              fill="white"
            />
            <rect
              x="-32.5"
              y="-39.5"
              width="1130"
              height="907"
              rx="4.5"
              stroke="#D7D7D7"
            />
          </g>
          <g id="Icon">
            <rect width="35" height="35" rx="5" fill="#DBF2FF" />
            <path
              id="Vector"
              d="M10 28.3333V13.75H13.5185V28.3333H10ZM16.1574 28.3333V7.5H19.6759V28.3333H16.1574ZM22.3148 28.3333V20H25.8333V28.3333H22.3148Z"
              fill="#62C6FF"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PerformanceBreakdownIcon;
