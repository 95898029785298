import React from "react";
import PropTypes from "prop-types";
import RoundDropdown from "./round_dropdown";

class CheckboxMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      active: false,
      search: "",
    };
    // transition speed is an int
    this.container = React.createRef();
    this.loading = false;
    this.inputRef = React.createRef();
    this.handleOutsideClick = (e) => {
      if (
        this.container.current &&
        !this.container.current.contains(e.target)
      ) {
        this.setOpen();
      }
    };
  }

  setOpen = () => {
    const optionsCount = this.props.options.length;
    if (!this.loading) {
      this.loading = true;
      if (!this.state.open === true) {
        window.addEventListener("click", this.handleOutsideClick);
        // window.addEventListener("keydown", this.handleKeyDown)
        this.setState(
          {
            open: true,
            active:
              this.props.options
                .map((option) => option.checked)
                .indexOf(true) !== -1,
          },
          () => {
            const elem = document.getElementById(
              `checkbox-menu-${this.props.id}`
            );
            if (this.inputRef.current) {
              this.inputRef.current.focus();
            }
            setTimeout(() => {
              elem.style.maxHeight = this.props.maxHeight
                ? this.props.maxHeight
                : "250px";
              elem.style.overflowY =
                this.props.options.length <= 7 ? "hidden" : "";
              // elem.style.padding = "10px 15px"
              const optionElems = document.querySelectorAll(
                `#checkbox-menu-${this.props.id} > div`
              );
              optionElems.forEach((elm, index) => {
                elm.style.transitionDelay = `${(0.2 / optionsCount) * index}s`;
                elm.style.transform = "translateX(0px)";
                elm.style.opacity = "1";
              });
            }, 50);
            setTimeout(() => {
              elem.style.transitionDelay = "0.2s";
              this.loading = false;
            }, 450);
          }
        );
      } else {
        window.removeEventListener("click", this.handleOutsideClick);
        // window.removeEventListener("keydown", this.handleKeyDown)
        const elem = document.getElementById(`checkbox-menu-${this.props.id}`);
        elem.style.maxHeight = "0px";
        const optionElems = document.querySelectorAll(
          `#checkbox-menu-${this.props.id} > div`
        );
        optionElems.forEach((elm, index) => {
          elm.style.transitionDelay = `${
            (0.2 / optionsCount) * (optionsCount - (index + 1))
          }s`;
          elm.style.transform = "translateX(-14px)";
          elm.style.opacity = "0";
        });
        setTimeout(() => {
          this.setState(
            {
              open: false,
              search: "",
              active:
                this.props.options
                  .map((option) => option.checked)
                  .indexOf(true) !== -1,
            },
            () => {
              this.loading = false;
            }
          );
        }, 600);
      }
    }
  };

  handleInput = (e) => {
    this.setState({ search: e.target.value ? e.target.value : null });
  };

  handleOption = (e, handler) => {
    handler(e);
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  handleClear = (e) => {
    if (this.props.clearFunction) {
      e.stopPropagation();
      this.props.clearFunction(this.props.name);
    }
  };

  render() {
    const options = this.props.searchEnabled
      ? this.props.options.filter((option) =>
          this.state.search
            ? option.label
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
            : true
        )
      : this.props.options;

    return (
      <div
        style={{
          ...this.props.containerStyles,
          position: "relative",
          opacity: this.props.disabled ? "0.5" : "1",
          pointerEvents: this.props.disabled ? "none" : "all",
        }}
      >
        <div
          ref={this.container}
          key={`checkbox_menu_container_${this.props.id}`}
          className="checkbox-menu-container"
          style={{
            position: "relative",
            flex: 1,
            minWidth: this.props.containerMinWidth
              ? this.props.containerMinWidth
              : "160px",
            width: this.props.width ? this.props.width : "100%",
          }}
        >
          <div
            className={`${
              this.state.open
                ? "checkbox-menu-selector-open"
                : "checkbox-menu-selector-closed"
            }`}
            onClick={this.setOpen}
            style={{
              height: "36px",
              color: this.props.count >= 1 ? "#519acc" : "initial",
              paddingLeft: "10px",
              paddingRight: "10px",
              cursor: "pointer",
              borderBottomColor: this.props.count >= 1 ? "#519acc" : "",
            }}
          >
            <span
              style={{
                fontSize: "15px",
                height: "34px",
                whiteSpace: "nowrap",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                aria-hidden
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  onClick={this.handleClear}
                  data-text={this.props.count}
                  className={`${
                    this.props.count >= 1 ? "blue-circle active" : "blue-circle"
                  } ${this.props.clearFunction ? "clear-function" : ""}`}
                />
              </div>
              <span
                aria-label={`${this.props.title}, ${
                  this.props.count
                } options selected${
                  this.state.open && this.props.searchEnabled
                    ? ", start typing to filter options"
                    : ""
                }`}
                aria-expanded={this.state.open}
                role="button"
                className="p-input-button"
                style={{
                  position: "relative",
                  color:
                    this.state.open && this.props.searchEnabled
                      ? "transparent"
                      : "black",
                }}
              >
                {/* {this.state.search?this.state.search:this.props.title} */}
                <span aria-hidden>{this.props.title}</span>
                {this.state.open && this.props.searchEnabled && (
                  <input
                    onChange={this.handleInput}
                    placeholder={this.props.title}
                    maxLength={50}
                    aria-hidden
                    aria-label={`${this.props.title}, start typing to filter options`}
                    ref={this.inputRef}
                    autoComplete={false}
                    style={{
                      height: "33px",
                      marginBottom: "0",
                      border: "none",
                      boxShadow: "none",
                      fontSize: "14px",
                      outline: "none",
                      position: "absolute",
                      left: "0",
                      bottom: "-2px",
                    }}
                    value={this.state.search ? this.state.search : ""}
                  />
                )}
              </span>
              <RoundDropdown svgStyles={{ marginLeft: "10px" }} />
            </span>
          </div>
          {this.state.open && (
            <div
              className="checkbox-menu new-scrollbar"
              id={`checkbox-menu-${this.props.id}`}
              style={{
                position: "absolute",
                zIndex: "11",
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "2px",
                transition: "max-height 0.2s",
                right: this.props.last || this.props.narrow ? "0px" : "-29px",
                top: "120%",
                padding: "10px",
                width: this.props.narrow ? "100%" : "225px",
                maxWidth: this.props.width ? this.props.width : "225px",
                ...this.props.menuStyles,
              }}
            >
              {options.length > 0 ? (
                options.map((option, index) => {
                  const { label, value, checked } = option;
                  return (
                    <div
                      key={value}
                      style={{
                        height: "35px",
                        marginTop: index === 0 ? "10px" : "0px",
                        marginBottom: "0px",
                        transform:
                          this.state.search === ""
                            ? "translateX(-14px)"
                            : "translateX(0px)",
                        opacity: this.state.search === "" ? "0" : "1",
                        paddingTop: index === 0 ? "0px" : "0px",
                        transition: "transform 0.2s, opacity 0.2s",
                      }}
                      className={option.classList}
                    >
                      <label
                        aria-label={`${option.label}, ${
                          option.checked ? "checked" : "unchecked"
                        }. Option ${index + 1} of ${options.length}`}
                        style={{ height: "35px" }}
                      >
                        <input
                          aria-hidden
                          onChange={
                            this.props.searchEnabled
                              ? (e) => {
                                  this.handleOption(e, option.handler);
                                }
                              : option.handler
                          }
                          className="filled-in"
                          checked={checked}
                          value={value}
                          type="checkbox"
                        />
                        <span
                          aria-hidden
                          style={{
                            whiteSpace: "nowrap",
                            height: "100%",
                            width: "100%",
                            paddingLeft: "30px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontSize: "14px",
                            color: checked ? "#519acc" : "black",
                          }}
                        >
                          {label}
                        </span>
                      </label>
                    </div>
                  );
                })
              ) : (
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      height: "100%",
                      width: "100%",
                      textAlign: "center",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      fontSize: "14px",
                      color: "#bfbfbf",
                    }}
                  >
                    No options found
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

CheckboxMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      handler: PropTypes.func,
      checked: PropTypes.bool,
      classList: PropTypes.string,
    })
  ),
  count: PropTypes.number,
  containerMinWidth: PropTypes.string,
  width: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  title: PropTypes.string,
  clearFunction: PropTypes.func,
  searchEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  last: PropTypes.bool,
  narrow: PropTypes.bool,
  containerStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  menuStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

CheckboxMenu.defaultProps = {
  menuStyles: {},
  containerStyles: {},
};

export default CheckboxMenu;
