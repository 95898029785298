import React from 'react'

class Reports extends React.Component {

  constructor(props) {
    super(props);
    this.state = {report: null, showSection: true};
  }

  reportDetails() {
    var report = this.state.report;
    if (!report) {
      return (
        <div></div>
      );
    } else {
      return (
        <div className="col m8">
          <Report report={this.state.report}/>
        </div>
      );
    }
  }

  handleNoReport() {
    this.setState({noreport: true});
  }
  setSelectedReport(report) {
    this.setState({report: report});
  }

  showHideSection() {
    this.setState({showSection: !this.state.showSection});
  }
  render() {
    if (this.state.noreport) {
      return (
        <div className="row reports">
          <div className="center-block">
            <h4>You don't have any reports.</h4>
            <ul>
              <li>Your app is awesome!</li>
              <li>or</li>
              <li>You need to install the ShakeReport (iOS only).</li>
            </ul>
            <a target="_blank" href="https://github.com/jayztemplier/ShakeReport" className="btn btn-lg btn-success">Install ShakeReport</a>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row reports">
          <h4 onClick={this.showHideSection}> <i className={"fa fa-chevron-" + (this.state.showSection ? "up" : "down")}></i> Reports</h4>
          {this.state.showSection ? <ReportsList url={this.props.url} handleNoReport={this.handleNoReport} application_id={this.props.application_id} onReportSelect={this.setSelectedReport} selectedReport={this.state.report}/>
        : ""}
          {this.state.showSection ?  this.reportDetails() : ""}
        </div>
      );
    }
  }
};

class ReportsList extends React.Component{

  constructor(props) {
    super(props);
    this.state = {data: [], loading: false};
  }

  downloadReports() {
    var params = {};
    if (this.props.application_id) {
      params['application_id'] = this.props.application_id;
    }
    $.ajax({
      url: this.props.url,
      dataType: 'json',
      cache: false,
      data: params,
      success: function(data) {
        this.setState({data: data});
        if (data && data.length) {
          this.handleSelectReport(data[0]);
        } else {
          this.props.handleNoReport();
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });
  }

  componentDidMount() {
    this.downloadReports();
  }

  updateStatus(report) {
    if (report.status == null || report.status == 0) {
      var params = {};
      params['report'] = {'status' : 1};
      $.ajax({
        url: '/v1/applications/'+report.application_id+'/reports/'+report.id+'/status.json',
        dataType: 'json',
        cache: false,
        type: 'PUT',
        data: params,
        headers: {'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content')},
        success: function(data) {
          var reports = this.state.data;
          reports.forEach(function(report, i) {
            if (report.id == data.id) {
              reports[i] = data;
            }
          }.bind(this));
          this.setState({data: reports});
          this.props.onReportSelect(data);
        }.bind(this),
        error: function(xhr, status, err) {
          console.log("oops!" + err);
        }.bind(this)
      });
    }
  }
  
  handleSelectReport(report) {
    this.updateStatus(report);
    this.props.onReportSelect(report);
  }

  render() {
    var nodes = []
    for (var i = 0; i < this.state.data.length; i++) {
      var report = this.state.data[i];
      nodes.push(<ReportRow key={report.id} report={report} onReportSelect={this.handleSelectReport} selected={this.props.selectedReport && this.props.selectedReport.id == report.id } />);
    }
    return (
      <div className={this.props.selectedReport ? "col-md-3 reportsList" : "reportsList"}>
        <table className="table">
          <tbody>
            {nodes}
          </tbody>
        </table>
      </div>
    );
  }
};

class ReportRow extends React.Component {

  statusNode() {
    var status = this.props.report.status
    if (!status || status == 0) {
      return (
        <span className="label label-danger">New</span>
      );
    } else if (status == 1) {
      return (
        <span className="label label-default">Read</span>
      );
    } else {
      return (
      <span className="label label-success">Done</span>
      );
    }
  }

  goToReport(e) {
    e.preventDefault();
    this.props.onReportSelect(this.props.report);
  }

  render() {
    var date = new Date(this.props.report.created_at);
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    var dateString = local.toJSON().slice(0, 10);

    return (
      <tr onClick={this.goToReport} className={this.props.selected ? "reportRow info" : "reportRow"}>
        <td>
          {this.statusNode()}<span id="title">{this.props.report.title}</span>
          <p className="subtitle">{dateString}</p>
        </td>
      </tr>
    );
  }
};

class Report extends React.Component {

  constructor(props) {
    super(props);
    this.state = {logs: null};
  }

  componentWillReceiveProps() {
    // this.downloadLogs();
    this.setState({logs: null});
  }

  statusNode() {
    var status = this.props.report.status
    if (!status || status == 0) {
      return (
        <span className="label label-danger">New</span>
      );
    } else if (status == 1) {
      return (
        <span className="label label-default">Read</span>
      );
    } else {
      return (
      <span className="label label-success">Done</span>
      );
    }
  }

  downloadLogs() {
    if (this.props.report.logs) {
      $.ajax({
        url : this.props.report.logs.url,
        success : function(result){
          this.setState({logs: result});
        }.bind(this),
        error: function(xhr, status, err) {
          console.error(this.props.url, status, err.toString());
          this.setState({logs: null});
        }.bind(this)
      });
    } else {
      this.setState({logs: null});
    }
  }

  logsNode() {
    if (this.state.logs) {
      var lines = this.state.logs.split('\n');
      var logs = [];
      for (var i = 0; i < lines.length; i++) {
        var line = lines[i];
        logs.push(<tr key={i}><td>{line}</td></tr>);
      }
      return (
        <div id="logs">
          <p className="subtitle">Logs</p>
          <table className="table table-striped">
            <tbody>
              {logs}
            </tbody>
          </table>
        </div>
      );
    } else {
      this.downloadLogs();
      return (
        <div id="logs"></div>
      );
    }
  }

  render() {
    var creationDate = new Date(this.props.report.created_at);
    return (
      <div className="report">
        <div className="row">
          <h3>{this.statusNode()} {this.props.report.title} </h3>
        </div>
        <div className="row">
          <div className="col m2 preview">
            <a href={this.props.report.screenshot.url} target="_blank"><img src={this.props.report.screenshot.url}/></a>
          </div>
          <div className="col m9 info">
            <p>{this.props.report.device_model} on iOS {this.props.report.os_version}</p>
            <p>{this.props.report.message ? this.props.report.message : "No Description"}</p>
          </div>
        </div>
        <div className="row">
          {this.logsNode()}
        </div>
      </div>
    );
  }
};

export default Reports