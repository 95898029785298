import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  ChartInnerContainer,
  LoadingContainer,
} from "./BugsStepsDualBreakdown";
import LoadingSpinner from "../../common/loading_spinner";
import chartAreaBackgroundColorPlugin from "./chartPlugins";

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip, Legend);

const TestStepBreakdown = (props) => {
  const { data, loading } = props;

  const datasets = useMemo(() => {
    const testSteps = {
      label: "Test Steps",
      backgroundColor: "#FD772B",
      data: data.map((item) => item.test_steps),
      borderWidth: 0,
    };

    return {
      labels: data.map((item) => item.label),
      datasets: [testSteps],
    };
  }, [data]);

  const chartOptions = useMemo(() => {
    const xAxis = {
      linear: true,
      stacked: false,
      grid: {
        drawTicks: false,
        drawOnChartArea: false,
      },
      ticks: {
        source: "auto",
        autoSkip: true,
        padding: 16,
        font: {
          color: "#242424",
          size: 14,
          family: "Arial",
          weight: 400,
        },
      },
    };

    const yAxis = {
      position: "left",
      scalePositionLeft: true,
      stacked: false,
      grid: {
        drawTicks: false,
      },
      border: {
        width: 3,
        color: "#FD772B",
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 6,
        font: {
          size: 14,
          family: "Arial",
          weight: 700,
        },
        color: "#FD772B",
        padding: 14,
      },
    };

    const axes = {
      x: xAxis,
      y: yAxis,
    };

    return {
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 1,
      categoryPercentage: 0.5,
      interaction: {
        intersect: true,
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "center",
          labels: {
            boxWidth: 6,
            boxHeight: 6,
            padding: data.length === 0 ? 40 : 10,
            usePointStyle: true,
            font: {
              size: 14,
              family: "Arial",
              weight: 400,
            },
          },
        },
      },
      scales: {
        ...axes,
      },
    };
  }, [data]);

  return (
    <ChartContainer>
      <ChartInnerContainer $loading={loading}>
        <Bar
          datasetIdKey="test_step_breakdown"
          data={datasets}
          options={chartOptions}
          plugins={[chartAreaBackgroundColorPlugin]}
        />
      </ChartInnerContainer>
      {loading && (
        <LoadingContainer>
          <LoadingSpinner loading={loading} size={48} />
        </LoadingContainer>
      )}
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
`;

TestStepBreakdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      label: PropTypes.string,
      new_bugs: PropTypes.number,
      updated_bugs: PropTypes.number,
      test_steps: PropTypes.number,
      test_runs: PropTypes.number,
    })
  ),
  loading: PropTypes.bool,
};

TestStepBreakdown.defaultProps = {
  data: [
    {
      count: 1,
      label: "2023/06/01",
      test_steps: 2,
    },
    {
      count: 2,
      label: "2023/06/02",
      test_steps: 4,
    },
    {
      count: 1,
      label: "2023/06/06",
      test_steps: 6,
    },
    {
      count: 1,
      label: "2023/06/07",
      test_steps: 1,
    },
  ],
};

export default TestStepBreakdown;
