import React from "react";

const TestDirectoriesFilled = (props) => {
  return (
    <svg
      id="test_case_blue_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>{".test_case_blue_svg__cls-1{fill:#519acc}"}</style>{" "}
      </defs>{" "}
      <path
        className="test_case_blue_svg__cls-1"
        d="M51.45 50.31H12.52C10 50.31 8 48.89 8 47.09v-32.5a1.08 1.08 0 011.09-1.08H21.4a3.74 3.74 0 011.93.53l3.81 2.28h26.19a2.83 2.83 0 012.09.69A2.63 2.63 0 0156 19.1v27.99c-.06 1.8-2 3.22-4.55 3.22zM10.2 15.68v31.41c0 .37.88 1 2.32 1h38.93c1.44 0 2.32-.68 2.32-1V18.53a2.23 2.23 0 00-.47 0H26.84a1.09 1.09 0 01-.56-.15l-4.06-2.48a1.68 1.68 0 00-.82-.22z"
      />{" "}
      <path
        className="test_case_blue_svg__cls-1"
        d="M24.41 24.68H9.21V22.5h14.67l1.59-2a1.1 1.1 0 01.86-.41H55v2.17H26.85l-1.59 2a1.09 1.09 0 01-.85.42z"
      />{" "}
    </svg>
  );
};

export default TestDirectoriesFilled;
