import React from "react";

class LibraryFilledIcon extends React.Component {
  render() {
    return (
      <svg
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        stroke={this.props.stroke}
        style={this.props.svgStyles}
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 64 64"
      >
        <circle fill="#519acc" cx="31.65" cy="31.98" r="29.26"/>
        <path fill="#519acc" d="M55.25,19.4V41.82a1.07,1.07,0,0,1-1.07,1.08H31.9v1.64a1.07,1.07,0,0,1-1.07,1.08H9.11A1.07,1.07,0,0,1,8,44.54V26.82a1.06,1.06,0,0,1,1.07-1.07H23.56V19.4a1.06,1.06,0,0,1,1.08-1.06H54.23A1,1,0,0,1,55.25,19.4ZM53.16,40.82V24.88H25.7v.87h5.13a1.07,1.07,0,0,1,1.07,1.07v14Zm0-18V20.48H25.7v2.31ZM30.27,43.9V39.81l-5.6-5.61-7.19,7.2a1.19,1.19,0,0,1-.78.3,1.13,1.13,0,0,1-.78-.3l-2-2L9.72,43.51v.39H30.27Zm0-7.25V27.54H9.78v12.8L13.14,37a1.13,1.13,0,0,1,1.55,0l2,2,7.19-7.2a1.14,1.14,0,0,1,1.56,0Z"/>
        <path fill="#fff" d="M53.16,24.88V40.82H31.9v-14a1.07,1.07,0,0,0-1.07-1.07H25.7v-.87ZM42.9,33.08a.86.86,0,0,0-.4-.72l-4-2.41a.86.86,0,0,0-.9,0,1,1,0,0,0-.43.72v4.84a.85.85,0,0,0,.86.86.91.91,0,0,0,.46-.16l4.06-2.42A.89.89,0,0,0,42.9,33.08Z"/>
        <rect fill="#fff" x="25.7" y="20.48" width="27.46" height="2.31"/>
        <path fill="#519acc" d="M42.5,32.36a.86.86,0,0,1,.4.72.89.89,0,0,1-.39.71l-4.06,2.42a.91.91,0,0,1-.46.16.85.85,0,0,1-.86-.86V30.67a1,1,0,0,1,.43-.72.86.86,0,0,1,.9,0Z"/>
        <path fill="#fff" d="M30.27,39.81V43.9H16.7V41.7a1.19,1.19,0,0,0,.78-.3l7.19-7.2Z"/>
        <path fill="#fff" d="M30.27,27.54v9.11l-4.79-4.81a1.14,1.14,0,0,0-1.56,0L16.73,39l-2-2a1.13,1.13,0,0,0-1.55,0L9.78,40.34V27.54Zm-12.75,5a1.81,1.81,0,1,0-1.81,1.8A1.81,1.81,0,0,0,17.52,32.56Z"/>
        <circle fill="#519acc" cx="15.71" cy="32.56" r="1.8"/>
        <path fill="#fff" d="M16.7,41.7v2.2h-7v-.39l4.16-4.15,2,2A1.13,1.13,0,0,0,16.7,41.7Z"/>
      </svg>
    );
  }
}

LibraryFilledIcon.defaultProps = {
  height: "25px",
  width: "25px",
  svgStyles: {},
  color: "#444344"
}

export default LibraryFilledIcon