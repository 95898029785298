import React from "react";

const EmptyPie = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="236"
      height="236"
      viewBox="0 0 177 177"
      fill="none"
    >
      <g clipPath="url(#clip0_4388_1477)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.5 176C137.377 176 177 136.377 177 87.5C177 38.6228 137.377 -1 88.5 -1C39.6228 -1 0 38.6228 0 87.5C0 136.377 39.6228 176 88.5 176ZM88.5 148C121.913 148 149 120.913 149 87.5C149 54.0868 121.913 27 88.5 27C55.0868 27 28 54.0868 28 87.5C28 120.913 55.0868 148 88.5 148Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_4388_1477">
          <rect width="177" height="177" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyPie;
