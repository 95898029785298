import React from 'react'
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
          <div 
            style={{textAlign:"center", width: "100%", display: 'flex', alignItems: "center", justifyContent: "center"}}>
            <Sentry.ErrorBoundary fallback={"An error has occurred"}>
            <div className="col s6">
              <h1 className="hinge" style={{width: "200px", marginLeft: "auto", marginRight: "auto"}}>Sorry!</h1>
              <p style={{fontWeight: 500, fontSize: "11pt"}}>Something unexpected happened.</p>
            </div>
            <div className="col s6">
              <img alt="404 error, funny bug" width="200px" height="10%" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/sleeping_tess.png" />
            </div>
            </Sentry.ErrorBoundary>
          </div>
        );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary