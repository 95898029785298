import React from "react";
import axios from "axios";

class UserImageCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Unassigned",
      avatarUrl:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg",
    };
    this.getUser = this.getUser.bind(this);
  }

  componentDidMount() {
    if (!this.props.avatarUrl) {
      this.getUser();
    }
  }

  getUser() {
    const self = this;
    const config = {
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
      },
    };
    axios.get(`/users/image/${self.props.user.id}.json`, config).then((res) => {
      if (res.status === 200) {
        this.setState({
          avatarUrl: res.data.avatar_image,
          name: res.data.name,
        });
      }
    });
  }

  render() {
    return (
      <div
        id="userImage"
        style={{
          height: `${this.props.divHeight ? this.props.divHeight : "100%"}`,
          width: `${this.props.divWidth ? this.props.divWidth : "100%"}`,
          pointerEvents: `${this.props.pointer ? "initial" : "none"}`,
        }}
      >
        <img
          style={{
            borderRadius: "50%",
            height: `${this.props.height ? this.props.height : "30px"}`,
            width: `${this.props.width ? this.props.width : "30px"}`,
          }}
          src={
            this.props.avatarUrl ? this.props.avatarUrl : this.state.avatarUrl
          }
        />
        <p
          style={{
            width: "80%",
            display: "inline-block",
            verticalAlign: "10px",
            fontWeight: "600",
            fontFamily: "arial",
            paddingLeft: "10px",
          }}
        >
          {" "}
          {this.props.name
            ? this.props.name
            : this.state.name
            ? this.state.name.split(" ")[0]
            : ""}
        </p>
      </div>
    );
  }
}

export default UserImageCard;
