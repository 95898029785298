import React from "react";

const AllTimeSelectedFilled = (props) => {
  return (
    <svg
      id="Icons_All_Time_-_Outline_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {".Icons_All_Time_-_Outline_svg__cls-1{fill:#519acc}"}
        </style>{" "}
      </defs>{" "}
      <path
        className="Icons_All_Time_-_Outline_svg__cls-1"
        d="M13 51.26h37.94a3.77 3.77 0 003.75-3.76v-30a3.75 3.75 0 00-3.75-3.75H13a3.75 3.75 0 00-3.75 3.75v30A3.77 3.77 0 0013 51.26zm37.91-2H13a1.76 1.76 0 01-1.75-1.76v-22h41.44v22a1.75 1.75 0 01-1.75 1.76zM13 15.78h37.94a1.75 1.75 0 011.75 1.75v5.94H11.28v-5.94A1.75 1.75 0 0113 15.78z"
      />{" "}
      <path
        className="Icons_All_Time_-_Outline_svg__cls-1"
        d="M37.71 31.07A5.3 5.3 0 0033.63 33l-.2.26-.58.78.87 1.16.81-1.09.18-.23A3.89 3.89 0 1135 39.2l-.44-.5-.17-.23-4-5.34a5.29 5.29 0 10-4.17 8.54 5.25 5.25 0 003.79-1.6l.32-.35.74-1-.86-1.17-1 1.29-.24.27a3.85 3.85 0 01-2.79 1.17 3.9 3.9 0 113.17-6.17l1.68 2.26.97 1.16 1.52 2 .52.58A5.29 5.29 0 1037.7 31z"
      />{" "}
      <path
        className="Icons_All_Time_-_Outline_svg__cls-1"
        d="M37.71 42a5.66 5.66 0 01-3.92-1.57l-.56-.62-2.59-3.47-1.52-2a3.52 3.52 0 10-.35 4.5l.22-.26 1.25-1.67 1.33 1.8-.91 1.23-.33.37a5.67 5.67 0 11-4.08-9.6 5.73 5.73 0 014.47 2.17l4.17 5.61.42.47a3.53 3.53 0 10-.31-4.84l-1.28 1.71L32.38 34l.95-1.27A5.67 5.67 0 1137.71 42zM32 36.88l1.83 2.45.51.58a4.91 4.91 0 10-.39-6.67l-.6.8.4.53.69-.93a4.28 4.28 0 113.3 7 4.24 4.24 0 01-2.94-1.17l-.66-.78-4-5.36a5 5 0 00-3.87-1.88 4.92 4.92 0 000 9.83 4.86 4.86 0 003.52-1.49l.3-.34.56-.75-.39-.53-.92 1.17a4.22 4.22 0 01-3.07 1.3 4.28 4.28 0 113.48-6.77l1.85 2.49z"
      />{" "}
    </svg>
  );
};

export default AllTimeSelectedFilled;
