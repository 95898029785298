import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import useDeviceLab from "@hooks/data/useDeviceLab";
import { dropdownIndicator } from "../common/select_utils";
import { DeviceLabContext } from "./device_lab";

const DeviceCheckin = (props) => {
  const {
    list,
    settings: { options, settings },
    hooks: {
      queries: { useGetUserDeviceTier },
      mutations: { useCheckOutDevice },
    },
  } = useDeviceLab();

  const { currentUser } = useContext(DeviceLabContext);

  const {
    data: { deviceId, closeModal },
  } = props;

  const device = useMemo(() => {
    if (!deviceId || !list) {
      throw new Error("Invalid device ID");
    }

    const d = list.find((x) => x.id === parseInt(deviceId));
    if (!d) {
      throw new Error("Device not found");
    }

    return d;
  }, [deviceId, list]);

  const tier = useMemo(() => {
    return device.tier || 2;
  }, [device]);

  const userOptions = options.Users;

  const [selectedUser, setSelectedUser] = useState(() => {
    if (currentUser.name !== "Tablet Checkout") {
      return {
        value: currentUser.id,
        label: currentUser.name,
      };
    }
    return null;
  });

  const [expanded, setExpanded] = useState(
    currentUser.name === "Tablet Checkout"
  );

  const { data: tiers, isLoading: loadingTiers } = useGetUserDeviceTier(
    selectedUser.value
  );
  const [checkOutDevice, checkOutDeviceMutationResult] = useCheckOutDevice();
  const { isLoading: checkOutLoading } = checkOutDeviceMutationResult;

  const isLoading = useMemo(() => {
    return loadingTiers || checkOutLoading;
  }, [loadingTiers, checkOutLoading]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDeviceCheckOut = useCallback(
    async (office) => {
      const result = await checkOutDevice({
        office,
        user_id: selectedUser.value,
        device_id: device.id,
      });

      if (result.error) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role='alert'>${result.error.data.message}</span>`,
          displayLength: 5000,
          classes: "red",
        });
      } else {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "<span role='alert'>Device Checked Out Successfully</span>",
          displayLength: 3000,
          classes: "green",
        });
      }

      closeModal();
    },
    [device, selectedUser, checkOutDevice, closeModal]
  );

  const handleUserChange = (user) => {
    setSelectedUser(user);
  };

  const officeButton = () => {
    handleDeviceCheckOut(true, selectedUser.value);
    closeModal();
  };

  const homeButton = () => {
    handleDeviceCheckOut(false, selectedUser.value);
    closeModal();
  };

  useEffect(() => {
    const elem = document.getElementById("device-checkout-heading");
    if (elem) {
      elem.focus();
    }

    return () => {
      const tableContainer = document.querySelector(
        ".ReactVirtualized__Grid__innerScrollContainer"
      );
      if (tableContainer) {
        tableContainer.focus();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      role="dialog"
      aria-label={`Check Out Tier ${tier} Device`}
      style={{
        textAlign: "center",
        transition: "height 0.3s",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #eaeaea",
          textAlign: "left",
          height: "80px",
        }}
      >
        <p
          className="small-title"
          id="device-checkout-heading"
          aria-level="1"
          role="heading"
          tabIndex={-1}
          style={{ outline: "white" }}
        >
          Checkout Tier {tier} Device
        </p>
        <p className="subtitle">Select where this device will be used below.</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {currentUser &&
          (currentUser.role === "admin" ||
            currentUser.role === "tester" ||
            (currentUser.additional_permissions &&
              currentUser.additional_permissions.indexOf("Device Checkout") !==
                -1)) && (
            <div id="checkout-device-selector-container">
              <div>
                {expanded && (
                  <Select
                    name="checkout-user"
                    styles={{
                      singleValue: (styles, state) => {
                        return {
                          ...styles,
                          color: state.selectProps.menuIsOpen
                            ? "transparent"
                            : "black",
                        };
                      },
                      menu: (styles) => {
                        return { ...styles, zIndex: "2" };
                      },
                      control: (styles) => {
                        return { ...styles, height: "32px" };
                      },
                      input: (styles) => {
                        return {
                          ...styles,
                          position: "relative",
                          top: "-14px",
                        };
                      },
                      container: (styles) => {
                        return { ...styles, width: "200px", margin: "auto" };
                      },
                    }}
                    options={userOptions}
                    onChange={handleUserChange}
                    value={selectedUser}
                    appendToBody
                    placeholder="Select a checkout user"
                    components={{ DropdownIndicator: dropdownIndicator }}
                    classNamePrefix="select"
                    blurInputOnSelect
                    className="short"
                  />
                )}
                {currentUser.role === "admin" ||
                  (currentUser.role === "tester" && (
                    <div className="flex-container flex-al-center flex-j-center flex-column">
                      <p>Currently Checked Out For Home Use</p>
                      <div
                        style={{ width: "75%" }}
                        className="flex-container flex-al-center flex-j-center flex-row"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flex: 0.5,
                            padding: "0 20px",
                          }}
                        >
                          Tier 1:
                          {loadingTiers ? (
                            <div
                              style={{
                                height: "20px",
                                width: "20px",
                                borderWidth: "2px",
                              }}
                              id="test-step-bugs-loader"
                            />
                          ) : (
                            <span
                              style={{
                                padding: "0 10px",
                                color:
                                  tiers[0] >= settings.tier_1_limit
                                    ? "red"
                                    : "#519acc",
                                fontSize: 16,
                              }}
                            >
                              {tiers[0]}
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flex: 0.5,
                            padding: "0 20px",
                          }}
                        >
                          Tier 2:
                          {loadingTiers ? (
                            <div
                              style={{
                                height: "20px",
                                width: "20px",
                                borderWidth: "2px",
                              }}
                              id="test-step-bugs-loader"
                            />
                          ) : (
                            <span
                              style={{
                                padding: "0 10px",
                                color:
                                  tiers[1] >= settings.tier_2_limit
                                    ? "red"
                                    : "#519acc",
                                fontSize: 16,
                              }}
                            >
                              {tiers[1]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            padding: "20px",
          }}
        >
          <img
            alt="office icon"
            aria-hidden="true"
            style={{ height: "70px", width: "70px" }}
            src="https://plusqa-assets.sfo2.digitaloceanspaces.com/test-platform/Icons_Office.svg"
          />
          <span style={{ fontWeight: 600 }}>In Office</span>
          <span
            style={{
              marginBottom: "15px",
              fontSize: "15px",
              padding: "10px 40px 0",
            }}
          >
            Device will be used at PLUS QA HQ
          </span>
          <button
            type="button"
            disabled={isLoading}
            data-device-id={deviceId}
            data-user-slack-id={currentUser.slack_id}
            data-user-name={currentUser.name}
            aria-label="Choose In Office"
            className="btn"
            onClick={officeButton}
          >
            Choose
          </button>
        </div>
        {device.office_only === false && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
              padding: "20px",
            }}
          >
            <img
              alt="home icon"
              aria-hidden="true"
              className="draw-svg"
              style={{ height: "70px", width: "70px" }}
              src="https://plusqa-assets.sfo2.digitaloceanspaces.com/test-platform/Icons_Home.svg"
            />
            <span style={{ fontWeight: 600 }}>At Home</span>
            <span
              style={{
                marginBottom: "15px",
                fontSize: "15px",
                padding: "10px 40px 0",
              }}
            >
              Device will be used at HOME
            </span>
            <button
              type="button"
              disabled={isLoading || tiers[tier - 1] >= settings.tier_1_limit}
              data-device-id={deviceId}
              data-user-slack-id={currentUser.slack_id}
              data-user-name={currentUser.name}
              aria-label="Choose At Home"
              className="btn"
              onClick={homeButton}
            >
              Choose
            </button>
          </div>
        )}
      </div>
      <button
        type="button"
        style={{
          position: "absolute",
          top: "-2px",
          right: "-3px",
        }}
        className="icon-close-btn"
        onClick={closeModal}
        aria-label="close modal"
      />
      {expanded === false &&
        (currentUser.role === "admin" ||
          (currentUser.additional_permissions &&
            currentUser.additional_permissions.indexOf("Device Checkout") !==
              -1)) && (
          <button
            type="button"
            className="link-btn"
            style={{ color: "#519acc" }}
            onClick={toggleExpand}
            aria-label="close modal"
          >
            Checking out a device for someone else? Click Here.
          </button>
        )}
    </div>
  );
};

const { shape, string, func } = PropTypes;
DeviceCheckin.propTypes = {
  data: shape({
    deviceId: string.isRequired,
    closeModal: func.isRequired,
  }),
};

export default DeviceCheckin;
