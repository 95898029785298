import React from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import * as Sentry from "@sentry/react";
import AdminProjects from "@components/projects/AdminProjects";
import ErrorBoundary from "../common/error_boundary.jsx";
import Switcher from "../common/switcher.jsx";
import Users from "../users/users.jsx";
import Teams from "../teams/teams.jsx";
import SubNavbar from "../common/sub_navbar.jsx";
import AppVersion from "./app_version.jsx";
import ReactModalV2 from "../modals/react_modal_v2";
import TrialistDirectory from "../trialist/trialist_directory";
import SendClientSurvey from "../modals/send_client_survey.jsx";
import DebugPanel from "../modals/debug_panel.jsx";

const modals = {
  "client-survey": SendClientSurvey,
  "debug-panel": DebugPanel,
};

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: "https://662459c60a3a405daa0c3aa17f38ee25@sentry.io/2023356",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        if (event.exception.values.some((x) => x.handled === false)) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            title: "It looks like we dozed off and there was an error.",
            subtitle: "The Dev team has been notified.",
          });
        }
      }
      return event;
    },
  });
} else {
  Sentry.init({
    dsn: "https://3c2ed8a8f50148e582c6050933c50c31@sentry.io/5372657",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: "It looks like we dozed off and there was an error.",
          subtitle: "The Dev team has been notified.",
        });
      }
      return event;
    },
  });
}

class AdminPanel extends React.Component {
  constructor(props) {
    const url = new URL(location.href);
    const currentPage = url.searchParams.get("current_page");
    super(props);
    this.state = {
      currentPage: currentPage !== null ? parseInt(currentPage) : 0,
      searchString: "",
      modalOpen: false,
      currentModal: "",
      modalData: {},
      loading: false,
    };
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  switcherHandler = (value) => {
    const url = new URL(location.href);
    url.searchParams.set("current_page", value);
    const stateObj = {};
    window.history.replaceState(stateObj, "", url.href);
    this.setState({ currentPage: value });
  };

  updateSearch = (event) => {
    this.setState({ searchString: event.target.value });
  };

  clientSurveyModal = () => {
    this.setModal(true, "client-survey", {
      customClass: "client-survey-modal",
    });
  };

  debugModal = () => {
    const projects = [];
    this.props.projects.map((x) =>
      projects.push({ label: x.name, value: x.id })
    );
    this.setModal(true, "debug-panel", {
      projects,
      customClass: "debug-panel",
      backdropClickCheck: {
        title: "Close Debug",
        text: "Are you sure that you are done debugging?",
      },
    });
  };

  hideXScrollBar = () => {
    document.querySelector("body").style.overflowX = "hidden";
  };

  showXScrollBar = () => {
    document.querySelector("body").style.overflowX = "auto";
  };

  loadToggle = () => [this.setState({ loading: !this.state.loading })];

  render() {
    return (
      <div style={{ padding: "10px 25px 10px 25px", overflowY: "hidden" }}>
        {this.state.loading && (
          <div
            className={`modal-backdrop-loader ${
              this.state.loading
                ? "modal-backdrop-loader-open"
                : "modal-backdrop-loader-close"
            }`}
          >
            {this.state.loading && <div id="test-step-bugs-loader" />}
          </div>
        )}
        <div
          style={{
            flex: 1,
            height: "1px",
            backgroundColor: "#e0e0e0",
            top: "80px",
            position: "relative",
            width: "calc(100% + 25px)",
          }}
        />
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div id="adminPanelContainer">
            <SubNavbar
              center
              pageName="Admin Panel"
              iconStyle={{ width: "45px", height: "45px" }}
              icon="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Admin_Panel_Filled.svg"
              containerStyle={{
                height: "70px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              sectionOneChildren={[]}
              sectionThreeChildren={[
                <Switcher
                  key={1}
                  classes="col s12"
                  activeValue={this.state.currentPage}
                  switcherOptions={["Projects", "Teams", "Users", "Trialist"]}
                  handlerFunction={this.switcherHandler}
                />,
              ]}
            />
            <div style={{ paddingTop: "25px" }} id="adminPanel">
              <SwitchTransition>
                <CSSTransition
                  key={this.state.currentPage}
                  timeout={{
                    appear: 300,
                    enter: 390,
                    exit: 390,
                  }}
                  onExit={this.hideXScrollBar}
                  onEntered={this.showXScrollBar}
                  classNames="transition"
                >
                  {this.state.currentPage == 0 ? (
                    <ErrorBoundary>
                      <AdminProjects
                        searchString={this.state.searchString}
                        appVersion={this.props.appVersion}
                        teams={this.props.teams}
                        projects={this.props.projects}
                        users={this.props.users}
                        current_user={this.props.currentUser}
                        loadingHandler={this.loadToggle}
                      />
                    </ErrorBoundary>
                  ) : this.state.currentPage == 1 ? (
                    <ErrorBoundary>
                      <Teams
                        searchString={this.state.searchString}
                        projects={this.props.projects}
                        users={this.props.users}
                        loadingHandler={this.loadToggle}
                        teams={this.props.teams}
                        current_user={this.props.currentUser}
                      />
                    </ErrorBoundary>
                  ) : this.state.currentPage == 2 ? (
                    <ErrorBoundary>
                      <Users
                        searchString={this.state.searchString}
                        projects={this.props.projects}
                        users={this.props.users}
                        loadingHandler={this.loadToggle}
                        current_user={this.props.currentUser}
                      />
                    </ErrorBoundary>
                  ) : (
                    <ErrorBoundary>
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          willChange: "max-width",
                          transition: "max-width 0.666s ease-in-out 0s",
                          whiteSpace: "nowrap",
                          marginLeft: "auto",
                        }}
                      >
                        <TrialistDirectory
                          editMode
                          pages={this.props.trialist_pages}
                          showPage={this.props.current_page}
                        />
                      </div>
                    </ErrorBoundary>
                  )}
                </CSSTransition>
              </SwitchTransition>
            </div>
          </div>
          <div
            style={{
              transition: "margin-left .666s",
              transitionDelay: ".33s",
              marginLeft: `-${100}px`,
              alignSelf: "center",
              flexWrap: "wrap",
              marginTop: "100px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppVersion
              current_user={this.props.currentUser}
              appVersion={this.props.appVersion}
            />
            <button
              className="link-btn"
              type="button"
              onClick={this.clientSurveyModal}
              id="client-survey-button"
            >
              Send Client Survey
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPanel;
