import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import styled from "styled-components";
import moment from "moment";
import * as Sentry from "@sentry/react";
import CheckboxMenu from "../common/checkbox_menu.jsx";
import SkillsUserCard from "./skills_user_card.jsx";
import SkillsUserProfile from "./skills_user_profile.jsx";
import ReactModalV2 from "../modals/react_modal_v2";
import { badgeIcons } from "../common/icons";
import AddToProject from "../modals/add_to_project.jsx";
import EodPerformanceGraph from "./eod_performance_graph.jsx";

const modals = {
  "add-to-project": AddToProject,
};

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: "https://662459c60a3a405daa0c3aa17f38ee25@sentry.io/2023356",
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        if (event.exception.values.some((x) => x.handled === false)) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            title: "It looks like we dozed off and there was an error.",
            subtitle: "The Dev team has been notified.",
          });
        }
      }
      return event;
    },
  });
} else {
  Sentry.init({
    dsn: "https://3c2ed8a8f50148e582c6050933c50c31@sentry.io/5372657",
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: "It looks like we dozed off and there was an error.",
          subtitle: "The Dev team has been notified.",
        });
      }
      return event;
    },
  });
}

const SkillsPanel = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
`;

const SMALL_BADGE_PROPS = {
  style: { height: "37px", width: "37px" },
  className: "icons",
};

const LARGE_BADGE_PROPS = {
  style: {
    height: "127px",
    width: "127px",
    display: "block",
    marginRight: "10px",
  },
  className: "icons",
};

const SKILLS_MAP = {
  skill_a11y: "Accessibility",
  skill_automation: "Automation",
  skill_app: "App Testing",
  skill_web: "Web Testing",
  skill_test_cases: "Test Cases",
  skill_training: "Training",
};

class SkillsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: sessionStorage.getItem("expanded") !== false,
      users: this.props.users,
      baseUsers: this.props.users,
      selectedUser: this.props.users.find(
        (user) => user.id === this.props.selectedUser
      ),
      selectedPerformanceUsers: [],
      selectedTeams: [],
      selectedSkills: [],
      selectedProjects: [this.props.project?.id],
      selectedFilter: { label: "Project", value: "project" },
      modalOpen: false,
      currentModal: "",
      modalData: {},
      projectUsersWithData: [],
    };
    this.graphRef = React.createRef();
    this.searchInputRef = React.createRef();
    this.smallBadges = {
      bugs_bronze: (
        <badgeIcons.BugsBronzeSmall key="skill_0" {...SMALL_BADGE_PROPS} />
      ),
      bugs_silver: (
        <badgeIcons.BugsSilverSmall key="skill_1" {...SMALL_BADGE_PROPS} />
      ),
      bugs_gold: (
        <badgeIcons.BugsGoldSmall key="skill_2" {...SMALL_BADGE_PROPS} />
      ),
      test_pass_bronze: (
        <badgeIcons.TestPassBronzeSmall key="skill_3" {...SMALL_BADGE_PROPS} />
      ),
      test_pass_silver: (
        <badgeIcons.TestPassSilverSmall key="skill_4" {...SMALL_BADGE_PROPS} />
      ),
      test_pass_gold: (
        <badgeIcons.TestPassGoldSmall key="skill_5" {...SMALL_BADGE_PROPS} />
      ),
      test_cases_bronze: (
        <badgeIcons.TestCasesBronzeSmall key="skill_6" {...SMALL_BADGE_PROPS} />
      ),
      test_cases_silver: (
        <badgeIcons.TestCasesSilverSmall key="skill_7" {...SMALL_BADGE_PROPS} />
      ),
      test_cases_gold: (
        <badgeIcons.TestCasesGoldSmall key="skill_8" {...SMALL_BADGE_PROPS} />
      ),
      skill_a11y: (
        <badgeIcons.AccessibilityExpertSmall
          key="skill_9"
          {...SMALL_BADGE_PROPS}
        />
      ),
      skill_automation: (
        <badgeIcons.AutomationExpertSmall
          key="skill_10"
          {...SMALL_BADGE_PROPS}
        />
      ),
      skill_app: (
        <badgeIcons.AppTestingExpertSmall
          key="skill_11"
          {...SMALL_BADGE_PROPS}
        />
      ),
      skill_web: (
        <badgeIcons.WebTestingExpertSmall
          key="skill_12"
          {...SMALL_BADGE_PROPS}
        />
      ),
      skill_test_cases: (
        <badgeIcons.TestCasesExpertSmall
          key="skill_13"
          {...SMALL_BADGE_PROPS}
        />
      ),
      skill_training: (
        <badgeIcons.ExpertTrainerSmall key="skill_14" {...SMALL_BADGE_PROPS} />
      ),
      trialist: (
        <badgeIcons.TrialistSmall key="skill_15" {...SMALL_BADGE_PROPS} />
      ),
    };

    this.largeBadges = {
      bugs_bronze: (
        <badgeIcons.BugsBronze key="skill_15" {...LARGE_BADGE_PROPS} />
      ),
      bugs_silver: (
        <badgeIcons.BugsSilver key="skill_16" {...LARGE_BADGE_PROPS} />
      ),
      bugs_gold: <badgeIcons.BugsGold key="skill_17" {...LARGE_BADGE_PROPS} />,
      test_pass_bronze: (
        <badgeIcons.TestPassBronze key="skill_18" {...LARGE_BADGE_PROPS} />
      ),
      test_pass_silver: (
        <badgeIcons.TestPassSilver key="skill_19" {...LARGE_BADGE_PROPS} />
      ),
      test_pass_gold: (
        <badgeIcons.TestPassGold key="skill_20" {...LARGE_BADGE_PROPS} />
      ),
      test_cases_bronze: (
        <badgeIcons.TestCasesBronze key="skill_21" {...LARGE_BADGE_PROPS} />
      ),
      test_cases_silver: (
        <badgeIcons.TestCasesSilver key="skill_22" {...LARGE_BADGE_PROPS} />
      ),
      test_cases_gold: (
        <badgeIcons.TestCasesGold key="skill_23" {...LARGE_BADGE_PROPS} />
      ),
      skill_a11y: (
        <badgeIcons.AccessibilityExpert key="skill_24" {...LARGE_BADGE_PROPS} />
      ),
      skill_automation: (
        <badgeIcons.AutomationExpert key="skill_25" {...LARGE_BADGE_PROPS} />
      ),
      skill_app: (
        <badgeIcons.AppTestingExpert key="skill_26" {...LARGE_BADGE_PROPS} />
      ),
      skill_web: (
        <badgeIcons.WebTestingExpert key="skill_27" {...LARGE_BADGE_PROPS} />
      ),
      skill_test_cases: (
        <badgeIcons.TestCasesExpert key="skill_28" {...LARGE_BADGE_PROPS} />
      ),
      skill_training: (
        <badgeIcons.ExpertTrainer key="skill_29" {...LARGE_BADGE_PROPS} />
      ),
      trialist: <badgeIcons.Trialist key="skill_29" {...LARGE_BADGE_PROPS} />,
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.updateSearch();
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  updateUserProjects = (user) => {
    const baseUsers = [...this.state.baseUsers];
    const index = baseUsers.map((e) => e.id).indexOf(user.id);
    if (index != -1) {
      baseUsers[index] = user;
    }
    this.setState(
      {
        baseUsers,
        selectedUser: user,
      },
      () => {
        this.updateSearch();
      }
    );
  };

  updateUserLevel = (skill, level) => {
    axios
      .patch(`/skills/${this.state.selectedUser.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        skills_user: { [skill]: level },
      })
      .then((res) => {
        const baseUsers = [...this.state.baseUsers];
        const index = baseUsers.map((e) => e.id).indexOf(res.data.id);
        if (index != -1) {
          baseUsers[index] = res.data;
        }
        this.setState(
          {
            baseUsers,
            selectedUser: res.data,
          },
          () => {
            this.updateSearch();
          }
        );
      })
      .catch((err) => {
        Swal.fire(
          "Skills update error",
          "There was an error updating this user's skill"
        );
        console.error(err);
      });
  };

  setProjectUsersWithData = (data) => {
    this.setState({ projectUsersWithData: data }, () => {
      this.updateSearch();
    });
  };

  updateSearch = (e) => {
    let usersList = [...this.state.baseUsers];
    if ((e && e.target) || (this.searchInputRef && this.searchInputRef.value)) {
      const value = e
        ? e.target.value.toLowerCase()
        : this.searchInputRef.value.toLowerCase();
      usersList = usersList.filter(
        (u) =>
          u.name.toLowerCase().includes(value) ||
          u.languages.join(" ").toLowerCase().includes(value)
      );
    }
    if (this.state.selectedSkills.length > 0) {
      usersList = usersList.filter((u) => {
        for (const skill of this.state.selectedSkills) {
          if (u.skill_levels[skill] === 0) {
            return false;
          }
        }
        return true;
      });
    }
    if (this.state.selectedTeams.length > 0) {
      usersList = usersList.filter((u) => {
        for (let x = 0; x < this.state.selectedTeams.length; x++) {
          if (u.teams.indexOf(this.state.selectedTeams[x]) === -1) {
            return false;
          }
        }
        return true;
      });
    } else if (this.state.selectedProjects.length > 0) {
      usersList = usersList.filter((u) => {
        for (let x = 0; x < this.state.selectedProjects.length; x++) {
          if (
            u.projects
              .map((p) => p.id)
              .indexOf(this.state.selectedProjects[x]) === -1
          ) {
            return false;
          }
        }
        if (this.state.projectUsersWithData.length > 0) {
          if (this.state.projectUsersWithData.indexOf(u.name) === -1) {
            return false;
          }
          return true;
        }
        return false;
      });
    } else if (this.state.selectedPerformanceUsers.length > 0) {
      usersList = usersList.filter((u) => {
        return this.state.selectedPerformanceUsers.indexOf(u.id) !== -1;
      });
    }
    this.setState({ users: usersList }, () => {
      if (
        usersList.length > 0 &&
        (!this.state.selectedUser ||
          usersList.map((u) => u.id).indexOf(this.state.selectedUser.id) === -1)
      ) {
        this.setState({ selectedUser: usersList[0] });
      } else if (usersList.length === 0 && this.state.selectedUser) {
        this.setState({ selectedUser: null });
      }
    });
  };

  // setSidebarWidth = () => {
  //   const width = this.state.sideBarWidth == 100 ? 250 : 100;
  //   this.setState({ sideBarWidth: width, expanded: !this.state.expanded });
  //   sessionStorage.setItem("expanded", !this.state.expanded);
  // };

  setSelectedUser = (user, scrollToBadges) => {
    this.setState({ selectedUser: user }, () => {
      if (scrollToBadges) {
        const container = document.getElementById("selected-user-panel-scroll");
        if (container && container.childNodes[1]) {
          container.scrollTop = container.childNodes[1].offsetTop - 200;
        }
      }
    });
  };

  handleSkillChange = (e) => {
    const value = parseInt(e.target.value);
    let selectedSkills = [...this.state.selectedSkills];
    if (this.state.selectedSkills.indexOf(value) === -1) {
      selectedSkills.push(value);
    } else {
      selectedSkills = selectedSkills.filter((s) => s !== value);
    }
    this.setState({ selectedSkills }, () => {
      this.updateSearch();
    });
  };

  handleTeamChange = (value, promiseResolve) => {
    let selectedTeams = [...this.state.selectedTeams];
    if (this.state.selectedTeams.indexOf(value) === -1) {
      selectedTeams.push(value);
    } else {
      selectedTeams = selectedTeams.filter((s) => s !== value);
    }
    this.setState({ selectedTeams }, () => {
      if (promiseResolve) {
        promiseResolve("done");
        this.updateSearch();
      }
    });
  };

  handleProjectChange = (value, promiseResolve) => {
    let selectedProjects = [...this.state.selectedProjects];
    if (this.state.selectedProjects.indexOf(value) === -1) {
      selectedProjects.push(value);
    } else {
      selectedProjects = selectedProjects.filter((s) => s !== value);
    }
    this.setState({ selectedProjects }, () => {
      if (promiseResolve) {
        promiseResolve("done");
        this.updateSearch();
      }
    });
  };

  handleUserChange = (value, promiseResolve) => {
    let selectedPerformanceUsers = [...this.state.selectedPerformanceUsers];
    if (this.state.selectedPerformanceUsers.indexOf(value) === -1) {
      selectedPerformanceUsers.push(value);
    } else {
      selectedPerformanceUsers = selectedPerformanceUsers.filter(
        (s) => s !== value
      );
    }
    this.setState({ selectedPerformanceUsers }, () => {
      if (promiseResolve) {
        promiseResolve("done");
        this.updateSearch();
      }
    });
  };

  clearFilters = () => {
    this.setState(
      {
        selectedProjects: [],
        selectedPerformanceUsers: [],
        selectedTeams: [],
        projectUsersWithData: [],
      },
      () => {
        this.updateSearch();
      }
    );
  };

  handleFilterByChange = (e) => {
    this.setState(
      {
        selectedFilter: e,
        selectedProjects: [],
        selectedPerformanceUsers: [],
        selectedTeams: [],
        projectUsersWithData: [],
      },
      () => {
        this.updateSearch();
      }
    );
  };

  render() {
    const { selectedUser } = this.state;

    return (
      <div style={{ minWidth: "1298px" }}>
        <span
          className="large-title"
          role="heading"
          aria-level="1"
          style={{
            display: "block",
            backgroundColor: "white",
            position: "relative",
            maxWidth: `calc(100% - ${100}px)`,
            marginLeft: "auto",
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            padding: "20px 0px 20px 20px",
            zIndex: "10",
            marginTop: "-5px",
            borderBottom: "solid 1px lightgray",
            marginBottom: "27px",
          }}
        >
          <img
            style={{
              display: "inline-block",
              height: "50px",
              marginRight: "10px",
              verticalAlign: "-16px",
            }}
            alt="Plus QA Performance Badge icon"
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_Skills%20-%20Filled.svg"
          />
          Performance
        </span>
        <EodPerformanceGraph
          ref={this.graphRef}
          handleProjectChange={this.handleProjectChange}
          handleUserChange={this.handleUserChange}
          handleTeamChange={this.handleTeamChange}
          handleFilterByChange={this.handleFilterByChange}
          selectedFilter={this.state.selectedFilter}
          selectedProjects={this.state.selectedProjects}
          users={this.props.users}
          updateSearch={this.updateSearch}
          setProjectUsersWithData={this.setProjectUsersWithData}
          // sideBarWidth={this.state.sideBarWidth}
          projects={this.props.projects}
          teams={this.props.teams}
          clearFilters={this.clearFilters}
          selectedUsers={this.state.selectedPerformanceUsers}
          selectedTeams={this.state.selectedTeams}
          setExportData={this.setExportData}
        />
        <div
          id="main-skills-container"
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            whiteSpace: "nowrap",
            marginLeft: "auto",
          }}
        >
          <div
            id="skills-user-heading"
            style={{
              minHeight: "40px",
              width: "calc(50% + 15px)",
              marginBottom: "25px",
              display: "flex",
              paddingLeft: "30px",
            }}
          >
            <div
              id="user-search"
              style={{ position: "relative", marginRight: "20px", top: "3px" }}
            >
              <i
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  left: "6px",
                  top: "5px",
                  color: "#ababab",
                }}
                className="material-icons prefix"
              >
                {" "}
                search{" "}
              </i>
              <input
                ref={(node) => {
                  this.searchInputRef = node;
                }}
                autoComplete="off"
                id="bug-search-input"
                className="browser-default"
                type="text"
                onChange={this.updateSearch}
                placeholder="Search for Users"
              />
              <span style={{ fontSize: "10pt", paddingLeft: "15px" }}>
                Displaying {this.state.users.length} of{" "}
                {this.state.baseUsers.length} Testers
              </span>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <CheckboxMenu
                maxWidth="100%"
                key={2}
                count={this.state.selectedSkills.length}
                active={this.state.selectedSkills.length > 0}
                options={Object.keys(SKILLS_MAP).map((t, i) => ({
                  label: SKILLS_MAP[t],
                  value: i,
                  handler: this.handleSkillChange,
                  checked: this.state.selectedSkills.indexOf(i) !== -1,
                }))}
                title="Filter By Skill"
                id="2"
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />
            </div>
          </div>
          <SkillsPanel id="skills-user-panel">
            <div
              id="skills-user-list"
              className="new-scrollbar"
              style={{
                padding: "10px 30px",
                overflow: "auto",
                height: "calc(100vh - 150px)",
              }}
            >
              {this.state.users.length > 0 ? (
                this.state.users.map((user) => {
                  return (
                    <SkillsUserCard
                      setSelectedUser={this.setSelectedUser}
                      smallBadges={this.smallBadges}
                      active={selectedUser && selectedUser.id === user.id}
                      key={user.id}
                      user={user}
                    />
                  );
                })
              ) : (
                <div style={{ textAlign: "center", fontSize: "12pt" }}>
                  No Users Found
                </div>
              )}
            </div>
          </SkillsPanel>
          <SkillsPanel id="selected-user-panel">
            {selectedUser ? (
              <div
                id="selected-user-panel-scroll"
                className="new-scrollbar"
                style={{ overflow: "auto", height: "calc(100vh - 150px)" }}
              >
                <SkillsUserProfile
                  updateUserProjects={this.updateUserProjects}
                  projects={this.props.projects}
                  setModal={this.setModal}
                  key={selectedUser.id}
                  currentUser={this.props.currentUser}
                  updateUserLevel={this.updateUserLevel}
                  skills={SKILLS_MAP}
                  user={selectedUser}
                />
                {selectedUser.badges.length > 0 && (
                  <div
                    style={{
                      position: "relative",
                      padding: "0px 30px",
                      marginTop: "50px",
                    }}
                  >
                    <span
                      id="selected-badge-heading"
                      style={{
                        display: "block",
                        position: "absolute",
                        top: "-13px",
                        backgroundColor: "white",
                        fontSize: "9pt",
                        paddingRight: "10px",
                      }}
                    >
                      SKILLS BADGES
                    </span>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "lightgray",
                        marginTop: "7px",
                        marginBottom: "40px",
                      }}
                    />
                    {selectedUser.badges.map((badge) => {
                      return (
                        <div
                          key={badge.id}
                          style={{
                            height: "120px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "solid 1px lightgray",
                            marginTop: "25px",
                            paddingBottom: "25px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                              left: "-16px",
                            }}
                          >
                            {this.largeBadges[badge.identity]}
                            <span>
                              <span style={{ color: "#519acc" }}>
                                {badge.name}
                              </span>
                              <br />
                              <span
                                style={{
                                  display: "block",
                                  whiteSpace: "normal",
                                  marginRight: "20px",
                                  fontSize: "10pt",
                                }}
                              >
                                {badge.details}
                              </span>
                            </span>
                          </div>
                          <span style={{ fontSize: "10pt" }}>
                            Earned on{" "}
                            {moment(badge.created_at).format("MM/DD/YYYY")}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p
                  style={{
                    margin: "10px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  No User Selected
                </p>
              </div>
            )}
          </SkillsPanel>
          {/* {this.state.users.length > 0 &&
            <div style={{padding:"20px 40px 0px"}}>
              <p style={{fontSize:"13px", fontWeight:"600", lineHeight:"18px", textAlign:"right"}}>Export Performance Options</p>
              <div style={{textAlign:"right"}}>
                
                <button style={{width: "unset", background:"none", border:"none", color:"#519acc", fontSize:"12px", cursor:"pointer", padding:"0", fontWeight:"600"}} className="btn link-btn bc-blue pointer bc-hover" onClick={this.generateCsv}>
                  CSV
                </button>
                <CSVLink
                  id="csvLink"
                  className="bc-hover"
                  filename={``}
                  data={[]}
                  target="_blank"
                ></CSVLink>
                
                <a style={{background:"none", border:"none", color:"#519acc", fontSize:"12px", cursor:"pointer", fontWeight:"600", padding:"0 5px", margin:"0 5px", borderLeft:"solid 1px lightgray", borderRight:"solid 1px lightgray"}} href={`/projects/${this.props.project}/bugs_pdf_export.pdf`}>PDF</a>
                
                <a href={`/projects/${this.props.project}/ex_xlsx.xlsx`} style={{width: "unset", padding: "0", background:"none", border:"none", color:"#519acc", fontSize:"12px", cursor:"pointer", fontWeight:"600", padding:"0"}}>
                  XLSX
                </a>
              </div>
            </div>
          } */}
        </div>
        <ReactModalV2
          isShowing={this.state.modalOpen}
          id="react-modal"
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
      </div>
    );
  }
}

export default SkillsContainer;
