import React from "react";
import axios from "axios";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import MultiSelectImageStyles from "../common/multi_select_image_styles";

class GroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWatchers: this.props.data.selectedWatchers,
      submitted: false,
      menuIsOpen: false,
      groupName: "",
    };
    this.watcherSelect = React.createRef();
  }

  outsideClickHandler = (e) => {
    if (
      this.state.menuIsOpen &&
      this.watcherSelect.current &&
      !this.watcherSelect.current.contains(e.target)
    ) {
      this.toggleMenu();
    }
  };

  handleWatcherChange = (e) => {
    this.setState({ selectedWatchers: e });
  };

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.setState({ submitted: false });
  }

  componentWillUnmount() {}

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    var formData = new FormData();
    if (this.state.groupName != "") {
      formData.append("group[value]", this.state.groupName);
    } else {
      M.toast({
        html: `Group name cannot be blank`,
        displayLength: 3000,
        classes: "red",
      });
      return false;
    }
    if (this.state.selectedWatchers.length >= 1) {
      for (let index = 0; index < this.state.selectedWatchers.length; index++) {
        formData.append(
          "group[user_ids][]",
          this.state.selectedWatchers[index].value
        );
      }
    } else {
      M.toast({
        html: `Please select user(s) to create a group`,
        displayLength: 3000,
        classes: "red",
      });
    }
    formData.append("group[project_id]", this.props.data.projectId);
    var self = this;
    axios
      .post("/groups", formData)
      .then((res) => {
        if (res.status == 201) {
          M.toast({
            html: "Group Created",
            displayLength: 3000,
            classes: "green",
          });
          self.props.data.groupHandler(res.data, "create");
          self.closeModal();
        }
      })
      .catch((err, res) => {
        console.log(err, res);
        Swal.fire({
          title: "Group create error",
          text: err.response.data.error,
          confirmButtonText: "Ok",
        });
        self.setState({ submitted: false });
      });
  };

  updateValue = (e) => {
    var name = e.target.name;
    this.setState({ [name]: e.target.value });
  };

  toggleMenu = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
  };

  closeModal = () => {
    this.props.data.closeHandler();
  };

  render() {
    const MenuList = (props) => {
      return (
        <CSSTransition
          in={this.state.menuIsOpen}
          appear={true}
          exit={true}
          style={{ pointerEvents: "none" }}
          unmountOnExit
          timeout={{
            appear: 400,
            enter: 400,
            exit: 400,
          }}
          classNames="select-anim2"
        >
          <components.MenuList {...props}>
            {props ? props.children : ""}
          </components.MenuList>
        </CSSTransition>
      );
    };

    return (
      <div className="modal-content" onClick={this.outsideClickHandler}>
        <form onSubmit={this.handleSubmit} encType="multipart/form-data">
          <button
            style={{
              position: "relative",
              float: "right",
            }}
            className="icon-close-btn"
            onClick={this.closeModal}
          ></button>
          <div>
            <p style={{ fontSize: "16px" }} className="bold">
              Create a Watcher group
            </p>
            <label
              style={{
                fontSize: "13px",
                color: "#242424",
                marginBottom: "20px",
                display: "block",
              }}
            >
              Create a group to easily add multiple users at once.
            </label>
          </div>
          <div
            style={{
              width: "596px",
              marginLeft: "-30px",
              height: "1px",
              backgroundColor: "#e0e0e0",
            }}
          ></div>
          <div>
            <div>
              <p style={{ margin: "20px 0 15px" }} className="bold">
                Group Name
              </p>
              <input
                type="text"
                id="groupNameInput"
                name="groupName"
                style={{
                  borderRadius: "0px",
                  backgroundColor: "#f5f5f5",
                  padding: "0px 8px !important",
                }}
                value={this.state.groupName}
                onChange={this.updateValue}
              ></input>
            </div>
            <p style={{ margin: "20px 0 10px" }} className="bold">
              Watchers
            </p>
            <div
              ref={this.watcherSelect}
              style={{ marginBottom: "10px", width: "500px" }}
            >
              <Select
                isMulti
                onChange={this.handleWatcherChange}
                name="watchers"
                aria-label="Watcher selector for groups"
                menuIsOpen={this.state.menuIsOpen}
                placeholder={""}
                styles={MultiSelectImageStyles}
                getOptionValue={(option) =>
                  option.label.props
                    ? `${option.label.props.children[1]} ${option.value}`
                    : option.value
                }
                components={{
                  MenuList,
                }}
                value={this.state.selectedWatchers}
                closeMenuOnSelect={false}
                options={this.props.data.users.options}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <div
                onClick={this.toggleMenu}
                className="bug-form-add-button"
                style={{
                  color: "#83C9F7",
                  width: "fit-content",
                  cursor: "pointer",
                }}
              >
                + Add New Watcher
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{ alignSelf: "flex-end", marginRight: "3px" }}
              disabled={this.state.submitted}
              type="submit"
              className="btn"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default GroupModal;

GroupModal.propTypes = {
  data: PropTypes.object,
};
