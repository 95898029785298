import { useEffect } from "react";
import useSessionStorage from "./useSessionStorage";

const useSidebarState = (controlledState) => {
  const [isExpanded, setIsExpanded] = useSessionStorage("expanded", true);

  const expand = () => setIsExpanded(true);
  const collapse = () => setIsExpanded(false);
  const toggle = () => setIsExpanded((prevExpanded) => !prevExpanded);

  useEffect(() => {
    if (controlledState !== undefined) {
      setIsExpanded(!!controlledState);
    }
  }, [controlledState]);

  return { isExpanded, expand, collapse, toggle };
};

export default useSidebarState;
