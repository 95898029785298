import WikiCategoryIcons from "../icons/wiki_resources";

const {
  TrainingIcon,
  HRIcon,
  AccessibilityIcon,
  LeadIcon,
  DevsIcon,
  TeamsIcon,
  MiscIcon,
} = WikiCategoryIcons;

const categories = {
  training: {
    label: "Training",
    gradient: "linear-gradient(123.2deg, #D62F90 6.74%, #712A6B 94.1%)",
    image:
      "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/plus-qa-wiki/Wiki_V5.png",
    icon: TrainingIcon,
  },
  hr: {
    label: "HR",
    gradient:
      "linear-gradient(123.2deg, #E3A454 6.74%, #FF3655 94.1%), #FFFFFF",
    image:
      "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/plus-qa-wiki/Wiki_V1.png",
    icon: HRIcon,
  },
  accessibility: {
    label: "Accessibility",
    gradient:
      "linear-gradient(123.2deg, #AA43FB 0%, #705FDB 58.14%, #1C96B0 100%)",
    image:
      "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/plus-qa-wiki/Wiki_V3.png",
    icon: AccessibilityIcon,
  },
  lead: {
    label: "PM/Lead",
    gradient: "linear-gradient(123.2deg, #48BAFA 0%, #1B71AC 94.1%)",
    image:
      "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/plus-qa-wiki/Wiki_V4.png",
    icon: LeadIcon,
  },
  devs: {
    label: "Devs",
    gradient: "linear-gradient(123.2deg, #0BB991 6.74%, #1D8480 94.1%)",
    image:
      "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/plus-qa-wiki/Wiki_V2.png",
    icon: DevsIcon,
  },
  teams: {
    label: "Teams",
    gradient: "linear-gradient(123.2deg, #EA5858 7.54%, #6251CC 100%)",
    image:
      "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/plus-qa-wiki/Wiki_V6.png",
    icon: TeamsIcon,
  },
  misc: {
    label: "Miscellaneous",
    gradient: "linear-gradient(123.2deg, #FE8900 0%, #FFC700 100%);",
    image:
      "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/plus-qa-wiki/Wiki_V7.png",
    icon: MiscIcon,
  },
};

export default categories;
