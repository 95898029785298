import React from 'react'
import EditIcon from '../icons/edit_icon'
import MoveIcon from '../icons/move_icon'
import DeleteIcon from '../icons/delete_icon'
import Swal from "sweetalert2"

class TrialistPageLink extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      subpage: this.props.page.subpage,
      title: this.props.page.title
    }
    this.handlePageSelect = this.handlePageSelect.bind(this)
    this.handlePageEdit = this.handlePageEdit.bind(this)
    this.handlePageUpdate = this.handlePageUpdate.bind(this)
    this.handlePageDelete =  this.handlePageDelete.bind(this)
    this.handleCancelEdit = this.handleCancelEdit.bind(this)
    this.handleSubpage = this.handleSubpage.bind(this)
    this.handleDragStart = this.handleDragStart.bind(this)
    this.handleDragEnter = this.handleDragEnter.bind(this)
    this.handleDragDrop = this.handleDragDrop.bind(this)
    this.handleEnterKey = this.handleEnterKey.bind(this)
    this.pageLinkRef = React.createRef()
    this.pageInputRef = React.createRef()
    this.pageCheckboxRef = React.createRef()
  }

  handlePageSelect(){
    this.props.handlePageSelect(this.props.page, "node-forwards")
  }

  handlePageEdit(){
    this.props.handlePageEdit(this.props.page.id)
  }

  handleCancelEdit(){
    this.props.handlePageEdit(false)
  }

  handleSubpage(){
    this.setState({subpage: !this.state.subpage})
  }

  handleDragStart(e){
    var {id, position, day} = this.props.page
    var elem = document.querySelector(`#trial-page-link-${id} > span`)
    if(elem){
      e.dataTransfer.setData('text', 'anything');  
      e.dataTransfer.setDragImage(elem, elem.scrollWidth, 10)
    }
    this.props.handleDragStart(id, position, day)
  }

  handlePageUpdate(){
    var input = this.pageInputRef.current.value?this.pageInputRef.current.value:this.props.page.title
    var subpage = this.pageCheckboxRef.current.checked
    if(this.props.editingPage === "new"){
      this.props.handlePageCreate(input, subpage, this.props.page.day)
    } else {
      this.props.handlePageUpdate(this.props.page.id, input, subpage)
    } 
  }

  handlePageDelete(){
    Swal.fire({
      title: "Delete Page",
      text: "Are you sure that you want to delete this page?",
      reverseButtons: true,
      showCancelButton:true,
      confirmButtonAriaLabel: 'Yes',
      cancelButtonAriaLabel: 'cancel',
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.props.handlePageDelete(this.props.page.id)
      } else if (result.dismiss) {
        // Swal.fire("Cancelled", "Your file is safe");
      }
    })
  }

  handleDragEnter(e){
    var {day, position} = e.target.dataset
    if(day&&position&&(parseInt(position) !== this.props.dragging.position||parseInt(day)!==this.props.dragging.day)){
      if(parseInt(day)===this.props.dragging.day){
        if(parseInt(position) > this.props.dragging.position){
          e.target.classList.add("bottom-drag")
        } else if (parseInt(position) < this.props.dragging.position){
          e.target.classList.add("top-drag")
        }
      } else {
        e.target.classList.add("top-drag")
      }
    }
  }

  handleDragLeave(e){
    e.target.classList.remove("top-drag")
    e.target.classList.remove("bottom-drag")
  }

  handleDragDrop(e){
    e.target.classList.remove("top-drag")
    e.target.classList.remove("bottom-drag")
    var {day, position} = e.target.dataset
    if(day&&position&&(parseInt(position) !== this.props.dragging.position||parseInt(day)!==this.props.dragging.day)){
      var {id} = this.props.dragging
      if(parseInt(day)===this.props.dragging.day){
        this.props.handleDragDrop(id, parseInt(position))
      } else {
        this.props.handleDragDrop(id, parseInt(position), parseInt(day))
      }
      
    }
  }

  handleDragOver(e){
    e.preventDefault()
  }

  handleEnterKey(e) {
    if (e.which === 13) {
      this.handlePageUpdate()
    }
  }

  render(){
    var {subpage, title, day, position, id} = this.props.page
    var {editMode, editingPage} = this.props
    var dragging = this.props.dragging
    return(
      <>
        {editingPage !== id ?
          <div
            id={`trial-page-link-${id}`} 
            onDragEnter={this.handleDragEnter} 
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDragDrop}
            onDragOver={this.handleDragOver}
            ref={this.pageLinkRef} 
            data-position={position}
            data-day={day} 
            className={`trial-page-link ${subpage?"subpage":""} ${editMode?"edit":""}`}
          >
            {subpage&&<div/>}
            <span style={{pointerEvents:dragging||editingPage?"none":"all", display: "inline-block"}} onClick={this.handlePageSelect} role="link">{title}</span>
            {editMode&&!editingPage&&!(dragging&&dragging.id !== id)&&
              <div>
                <span onClick={this.handlePageEdit}><EditIcon svgStyles={{pointerEvents:"none"}} /></span>
                <span onClick={this.handlePageDelete} style={{margin:"0 5px"}}>
                  <DeleteIcon svgStyles={{pointerEvents:"none"}} />
                </span>
                <span onDragStart={this.handleDragStart} onDragEnd={this.props.handleDragEnd} draggable={true}>
                  <MoveIcon svgStyles={{pointerEvents:"none"}} />
                </span>
              </div>
            }
          </div> :
          <div>
            <div style={{display:"flex", height:"24px"}}>
              <input onKeyDown={this.handleEnterKey} ref={this.pageInputRef} maxLength={30} defaultValue={title} autoFocus={true} className="trial-page-input" />
              <i style={{fontSize:"19px", cursor:"pointer", color:"#444344", verticalAlign:"middle", margin:"0 5px"}} onClick={this.handlePageUpdate} className="material-icons">done</i>
              <i style={{fontSize:"19px", cursor:"pointer", color:"#444344", verticalAlign:"middle"}} onClick={this.handleCancelEdit} className="material-icons">close</i>
            </div>
            <label style={{display:"block"}}>
              <input ref={this.pageCheckboxRef} style={{width:"100px"}}  className="filled-in" type="checkbox" defaultChecked={subpage}/>
              <span style={{whiteSpace:"nowrap", paddingLeft:"28px", color:"#242424", fontSize:"13px", height:"22px", lineHeight:"22px"}}>Subpage</span>
            </label>
          </div>
        }
      </>
    )
  }
}

export default TrialistPageLink