import React from 'react'

class RoundDropdown extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .6s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        aria-hidden="true"
      >
        <path
          className="cls-1"
          d="M56.36,22.32A6.51,6.51,0,0,1,54.45,27L34.76,47a3.91,3.91,0,0,1-5.52,0L9.54,27a6.58,6.58,0,0,1,.28-9.52c2.64-2.36,6.79-1.91,9.3.6l12.8,13.12s0,0,.09,0a0,0,0,0,0,.06,0L44.88,18c2.51-2.51,6.65-3,9.29-.6A6.51,6.51,0,0,1,56.36,22.32Z"
        />
      </svg>
    );
  }
}
RoundDropdown.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default RoundDropdown
