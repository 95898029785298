import React from 'react'
import Build from './build.jsx'
import ApiKeyContainer from '../users/api_key_container.jsx'

class BuildsList extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      showBuilds: true, 
      filteredData: this.props.data, 
      search: "", 
      selector: "all", 
      column: "", 
      direction: ""
    };
  }

  componentDidMount() {
    this.setState({width: window.innerWidth})
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions);
  }

   componentDidUpdate = (prevProps) => {
      if (prevProps.data != this.props.data) {
        this.setState({filteredData: this.props.data, selector: "all"})
      }
   }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  updateSearch = (event) => {
    this.setState({search: event.target.value})
  }

  clearInput = () => {
    var elem = document.getElementById("bug-search-input") 
    if (elem) {
      elem.focus()
      elem.value = ""
    }
    this.setState({search: ""})
  }

  showHideBuilds = () => {
    this.setState({showBuilds: !this.state.showBuilds});
  }

  displayAllBuilds = () => {
    var data = this.props.data
    this.setState({filteredData: data, selector: "all"})
  }

  displayiOSBuilds = () => {
    let baseArray = this.props.data
    var results = baseArray.filter(build => build.build_name.includes(".ipa"))
    this.setState({filteredData: results, selector: "ios" });
  }

  displayAndroidBuilds = () => {
    let baseArray = this.props.data
    var results = baseArray.filter(build => build.build_name.includes(".apk"))
    this.setState({filteredData: results, selector: "android" });
  }

  sortBuilds = (column) => {
    var direction = this.state.column ? (this.state.direction === 'asc' ? 'desc' : 'asc') : 'desc';
    var sortedData = this.state.filteredData.sort((a, b) => {
        if (column === 'name') {
          var nameA = a.build_name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.build_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        } else if (column === 'url'){
          var nameA = a.url.toUpperCase(); // ignore upper and lowercase
          var nameB = b.url.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        } else if (column === 'copyURL'){
          var nameA = a.url.name; // ignore upper and lowercase
          var nameB = b.url.name; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        } else if (column === 'status'){
          var nameA = a.available_until; // ignore upper and lowercase
          var nameB = b.available_until; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        } else if (column === 'date'){
          var nameA = a.available_until; // ignore upper and lowercase
          var nameB = b.available_until; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        } else if (column === 'id'){
          return a.id - b.id;
        } else {
          return a.id - b.id;
        }
      });
      if (direction === 'desc') {
        sortedData.reverse();
      }

      this.setState({
        filteredData: sortedData,
        column: column,
        direction: direction
      });
  }

  render() {
    var table;
    let filteredData = this.state.filteredData

    var filteredProjects = filteredData.filter(
      (build) => {
      return build.build_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      }
      );
      if(this.state.search != ""){
        filteredData = filteredProjects;
      }

    if (this.state.showBuilds && this.props.data.length >= 1) {
      var nodes = [];
      for (var i = 0; i < filteredData.length; i++) {
        var build = filteredData[i];
        nodes.push(<Build build={build} key={build.created_at} width={this.state.width}/>);
      }

      table = (
        <table className="table striped highlight" id="builds-table">
          <thead>
          <tr className="sortTr" key="header">
            <th className={this.state.column == "name" && this.state.direction == "asc" ? "activeTrUp"
: this.state.column == "name" && this.state.direction == "desc" ? "activeTrDown" : " pointer"} onClick={() => this.sortBuilds("name")}>Name</th>
        <th>URL</th>
        {/* <th>Copy Url</th> */}
        <th className={this.state.column == "status" && this.state.direction == "asc" ? "activeTrUp"
: this.state.column == "status" && this.state.direction == "desc" ? "activeTrDown" : " pointer" } onClick={() => this.sortBuilds("status")}>Status</th>
        <th className={this.state.column == "date" && this.state.direction == "asc" ? "activeTrUp"
: this.state.column == "date" && this.state.direction == "desc" ? "activeTrDown" : " pointer" } onClick={() => this.sortBuilds("date")}>Date</th>
      </tr>
          </thead>
          <tbody style={{ display: this.props.data.length >= 1 ? 'initial' : 'none'}}>
            {nodes}
          </tbody>
        </table>
      );
    }

    return (
      <div className="col s12" id="buildsList">
        <div>
          <div
            className="col s12"
            style={{ padding: "0px", position: "relative" }}
          >
            {this.props.data.length >= 1 && (
              <ApiKeyContainer
                user={this.props.currentUser}
                buildsLength={this.props.data.length}
              />
            )}
            {this.props.data.length >= 1 ? (
              <div
                className="col s12 m6 l6"
                id="topNavDiv"
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <div
                  id="ticketSearch"
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    aria-hidden="true"
                    style={{
                      position: "absolute",
                      pointerEvents: "none",
                      left: "6px",
                      color: "#ababab",
                    }}
                    className="material-icons prefix"
                  >
                    search
                  </i>
                  <input
                    id="bug-search-input"
                    className="browser-default"
                    type="text"
                    onChange={this.updateSearch}
                    placeholder=""
                    autoComplete="false"
                    aria-live="polite"
                  />
                  {this.state.search != "" && (
                    <button onClick={this.clearInput} className="link-btn material-icons grey-text">
                      close
                    </button>
                  )}
                </div>
                <ul
                  id="topNavUl"
                  style={{
                    paddingLeft: "0px",
                    left: "0px",
                    zIndex: "0",
                    marginLeft: "10px",
                  }}
                >
                  <li
                    className={
                      this.state.selector == "all" ? "active topNav" : "topNav"
                    }
                    onClick={this.displayAllBuilds}
                  >
                    All
                  </li>
                  <li
                    className={
                      this.state.selector == "ios" ? "active topNav" : "topNav"
                    }
                    onClick={this.displayiOSBuilds}
                  >
                    iOS
                  </li>
                  <li
                    className={
                      this.state.selector == "android"
                        ? "active topNav"
                        : "topNav"
                    }
                    onClick={this.displayAndroidBuilds}
                  >
                    Android
                  </li>
                </ul>
              </div>
            ) : this.props.currentUser.build_flow_complete ? (
              //blurred fake table
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    left: "-10px",
                    right: "0px",
                    width: "calc(100% + 20px)",
                    height: "calc(100% + 6px)",
                    zIndex: "1000",
                    WebkitBackdropFilter: "blur(3px)",
                    backdropFilter: "blur(3px)",
                  }}
                >
                  <span
                    className="large-title"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "solid 1px #519acc",
                      width: "200px",
                      height: "75px",
                      backgroundColor: "white",
                    }}
                  >
                    {this.props.loaded ? (
                      "No builds found"
                    ) : (
                      <div
                        style={{ marginBottom: "15px" }}
                        id="build-modal-spinner"
                      />
                    )}
                  </span>
                </div>
                <div
                  className="col s12 m6 l6"
                  id="topNavDiv"
                  style={{
                    paddingLeft: "0px",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    id="ticketSearch"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      aria-hidden="true"
                      style={{
                        position: "absolute",
                        pointerEvents: "none",
                        left: "6px",
                        color: "#ababab",
                      }}
                      className="material-icons prefix"
                    >
                      search
                    </i>
                    <input
                      id="bug-search-input"
                      className="browser-default"
                      type="text"
                      onChange={this.updateSearch}
                      placeholder=""
                      autoComplete="false"
                      aria-live="polite"
                    />
                    {this.state.search != "" && (
                    <button onClick={this.clearInput} className="link-btn material-icons grey-text">
                      close
                    </button>
                  )}
                  </div>
                  <ul
                    id="topNavUl"
                    style={{
                      paddingLeft: "0px",
                      left: "0px",
                      zIndex: "0",
                      marginLeft: "10px",
                    }}
                  >
                    <li
                      className={
                        this.state.selector == "all"
                          ? "active topNav"
                          : "topNav"
                      }
                      onClick={this.displayAllBuilds}
                    >
                      All
                    </li>
                    <li
                      className={
                        this.state.selector == "ios"
                          ? "active topNav"
                          : "topNav"
                      }
                      onClick={this.displayiOSBuilds}
                    >
                      iOS
                    </li>
                    <li
                      className={
                        this.state.selector == "android"
                          ? "active topNav"
                          : "topNav"
                      }
                      onClick={this.displayAndroidBuilds}
                    >
                      Android
                    </li>
                  </ul>
                </div>
                <table
                  style={{ pointerEvents: "none" }}
                  className="table striped highlight"
                  id="builds-table"
                >
                  <thead>
                    <tr className="sortTr" key="header">
                      <th className={"pointer"}>Name</th>
                      <th>URL</th>
                      <th className={"pointer"}>Status</th>
                      <th className={"pointer"}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="truncate">
                        <a>new_ios_build_today.ipa</a>
                        <br />
                      </td>
                      <td
                        style={{
                          position: "relative",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>testplatform.plusqa.com/get/s2cdp5yk</span>
                      </td>
                      <td>
                        <div>
                          <span>Available</span>
                          <a className="expire-link"> - Expire now</a>
                        </div>
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>Uploaded 2 days ago</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="truncate">
                        <a>regressions_build_04_18_2020.ipa</a>
                        <br />
                      </td>
                      <td
                        style={{
                          position: "relative",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>testplatform.plusqa.com/get/hwkw6nm4</span>
                      </td>
                      <td>
                        <div>
                          <span>Available</span>
                          <a className="expire-link"> - Expire now</a>
                        </div>
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>Uploaded 7 days ago</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="truncate">
                        <a>the_dots_are_a_lie.ipa</a>
                        <br />
                      </td>
                      <td
                        style={{
                          position: "relative",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>testplatform.plusqa.com/get/sryewgq5</span>
                      </td>
                      <td>
                        <p>
                          <span className="label label-danger">Expired</span>
                        </p>
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>Uploaded 12 days ago</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="truncate">
                        <a>allThatISee_are_dots_when_i_sleep.ipa</a>
                        <br />
                      </td>
                      <td
                        style={{
                          position: "relative",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>testplatform.plusqa.com/get/sryewgqhkh5</span>
                      </td>
                      <td>
                        <p>
                          <span className="label label-danger">Expired</span>
                        </p>
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <span>Uploaded 18 days ago</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
          {this.state.width > 600 && table}
          {this.state.width < 600 && nodes}
        </div>
      </div>
    );
  }
};

export default BuildsList
