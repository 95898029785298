import * as React from "react";
import "trix/dist/trix.css";
import Trix from "trix";

export class TrixEditor extends React.Component {
  constructor(props) {
    super(props);
    this.id = this.generateId();
    this.state = {
      showMergeTags: false,
      tags: [],
      lastTagLocation: null,
    };
  }
  generateId() {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return "T" + uuid;
  }

  componentDidMount() {
    let props = this.props;

    this.container = document.getElementById(`editor-${this.id}`);
    //this.container = this.d && this.d.children && this.d.children.length >= 2 ? this.d.children[1] : null;
    //this.editor = this.d;
    if (this.container) {
      this.container.addEventListener(
        "trix-initialize",
        () => {
          this.editor = this.container.editor;
          const { toolbarElement } = event.target;
          const blockTools = toolbarElement.querySelector(
            "[data-trix-button-group=block-tools]"
          );
          blockTools.insertAdjacentHTML(
            "beforeend",
            `
            <button type="button" class="trix-button" style="width:2.6em; height: 2.1em;" data-trix-action="x-horizontal-rule" title="Horizontal Rule" tabindex="-1">━</button>
          `
          );
          if (!this.editor) {
            console.error("cannot find trix editor");
          }

          if (props.onEditorReady && typeof props.onEditorReady == "function") {
            props.onEditorReady(this.editor);
          }
        },
        false
      );
      addEventListener("trix-action-invoke", (event) => {
        if (event.actionName == "x-horizontal-rule") {
          const editor = event.target.editor;
          const range = editor.getSelectedRange();
          console.log(range);
          const text = `___________________________________________________________________________________________________________________________`;
          editor.insertHTML(text, { at: range.index });
          editor.setSelectedRange([range.index + 1, range.index + 1]);

          event.preventDefault();
        }
      });
      this.container.addEventListener(
        "trix-change",
        this.handleChange.bind(this),
        false
      );
      this.container.addEventListener(
        "keydown",
        this.keyListener.bind(this),
        false
      );
      if (props.uploadURL) {
        this.container.addEventListener(
          "trix-attachment-add",
          this.handleUpload.bind(this)
        );
      }
    } else {
      console.error("editor not found");
    }
  }

  keyListener(e) {
    const text = e.target.innerText;
    if (text.length >= 10000 && e.keyCode != 8) {
      e.preventDefault();
      e.stopPropagation();
    } else if (text.length >= 10000 && e.keyCode == 8) {
      // don't stop
    }
  }

  componentWillUnmount() {
    this.container.removeEventListener("trix-initialize", this.handleChange);
    this.container.removeEventListener("trix-change", this.handleChange);
    this.container.removeEventListener("keydown", this.keyListener);

    if (this.props.uploadURL) {
      this.container.removeEventListener(
        "trix-attachment-add",
        this.handleUpload
      );
    }
  }

  handleChange(e) {
    const props = this.props;
    let state = this.state;
    const text = e.target.innerText;

    if (text.length >= 10000) {
      M.toast({ html: "Max Character Limit Reached", classes: "red" });
      e.target.innerText = text.substring(0, 10000);
      return false;
    } else if (text.length < 10000 && e.target.contentEditable === "false") {
    }

    if (props.onChange) {
      props.onChange(e.target.innerHTML, text);
    }

    const range = this.editor.getSelectedRange();
    if (state.showMergeTags) {
      const lastChar = text[range[0] - 1];
      if (lastChar === " ") {
        state.showMergeTags = false;
        this.setState(state);
      }
      var searchText = text.split("@")[text.split("@").length - 1];
      if (searchText && searchText.length >= 1) {
        for (let i = 0; i < props.mergeTags.length; i++) {
          state.tags = props.mergeTags[i].tags.filter((x) =>
            x.name.toLowerCase().includes(searchText.toLowerCase())
          );
          this.setState(state);
          break;
        }
      }
    }

    // if we have a collapse selection
    if (text && range[0] == range[1]) {
      // if we have a merge tag mergeTagTrigger
      if (props.mergeTags) {
        // get the cursor position and compare the last character with our mergeTagTrigger
        const lastChar = range[0] - 1;
        if (lastChar >= 0 && lastChar < text.length) {
          const trigger = text[lastChar];
          for (let i = 0; i < props.mergeTags.length; i++) {
            if (trigger == props.mergeTags[i].trigger) {
              state.showMergeTags = true;
              state.tags = props.mergeTags[i].tags;
              this.setState(state);
              break;
            }
          }
        }
      }
    }
  }
  handleUpload() {
    var attachment = e.attachment;
    if (attachment.file) {
      return this.uploadAttachment(attachment);
    }
  }
  uploadAttachment() {
    var file, form, xhr;
    file = attachment.file;
    form = new FormData();
    // add any custom data that were passed
    if (this.props.uploadData) {
      for (var k in this.props.uploadData) {
        form.append(k, this.props.uploadData[k]);
      }
    }

    //form.append("Content-Type", "multipart/form-data");
    form.append(this.props.fileParamName || "file", file);
    xhr = new XMLHttpRequest();
    xhr.open("POST", this.props.uploadURL, true);
    xhr.upload.onprogress = (event) => {
      var progress = (event.loaded / event.total) * 100;
      return attachment.setUploadProgress(progress);
    };
    xhr.onload = () => {
      var href, url;
      if (xhr.status >= 200 && xhr.status < 300) {
        url = href = xhr.responseText;
        return attachment.setAttributes({
          url: url,
          href: href,
        });
      }
    };
    return xhr.send(form);
  }

  handleTagSelected(t, e) {
    e.preventDefault();

    let state = this.state;
    state.showMergeTags = false;
    this.setState(state);
    if (this.props.onTagClick) {
      this.props.onTagClick(t);
    }
    this.editor.expandSelectionInDirection("backward");
    this.editor.insertString(t.tag);
  }

  renderTagSelector(tags) {
    if (!tags || !this.editor) {
      return null;
    }

    const editorPosition = document
      .getElementById("trix-editor-top-level-" + this.id)
      .getBoundingClientRect();

    // current cursor position
    const rect = this.editor.getClientRectAtPosition(
      this.editor.getSelectedRange()[0]
    );
    const boxStyle = {
      position: "absolute",
      top: rect.top + 25 - editorPosition.top,
      left: rect.left + 25 - editorPosition.left,
      width: "250px",
      boxSizing: "border-box",
      padding: 0,
      height: "200px",
      margin: ".2em 0 0",
      backgroundColor: "hsla(0,0%,100%,.9)",
      borderRadius: ".3em",
      background: "linear-gradient(to bottom right, white, hsla(0,0%,100%,.8))",
      border: "1px solid rgba(0,0,0,.3)",
      boxShadow: ".05em .2em .6em rgba(0,0,0,.2)",
      textShadow: "none",
    };
    const tagStyle = {
      padding: ".2em .5em",
      cursor: "pointer",
      background: "transparent",
      border: "none",
      color: "#242424",
    };
    return (
      <div style={boxStyle} className="react-trix-suggestions">
        <p style={{ padding: "10px" }}>Tag Watchers</p>
        <div style={{ overflow: "auto", maxHeight: "150px" }}>
          {tags.length >= 1 ? (
            tags.map((t) => {
              return (
                <button
                  value={t.id}
                  onClick={this.handleTagSelected.bind(this, t)}
                  className="trix-mention-tag"
                  key={t.id}
                >
                  <img
                    alt="user"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    src={t.image}
                  />
                  <span style={tagStyle}>{t.name}</span>
                </button>
              );
            })
          ) : (
            <p style={{ padding: "10px" }}>No watchers match that query</p>
          )}
        </div>
      </div>
    );
  }
  render() {
    let state = this.state;
    let props = this.props;

    var attributes = {
      id: `editor-${this.id}`,
      input: `input-${this.id}`,
      maxlength: "10000",
    };

    if (props.className) {
      attributes["class"] = props.className;
    }

    if (props.autoFocus) {
      attributes["autofocus"] = props.autoFocus.toString();
    }

    if (props.placeholder) {
      attributes["placeholder"] = props.placeholder;
    }

    if (props.toolbar) {
      attributes["toolbar"] = props.toolbar;
    }

    let mergetags = null;
    if (state.showMergeTags) {
      mergetags = this.renderTagSelector(state.tags);
    }
    return (
      <div
        id={"trix-editor-top-level-" + this.id}
        ref={(d) => (this.d = d)}
        style={{ position: "relative" }}
      >
        {React.createElement("trix-editor", attributes)}
        <input type="hidden" id={`input-${this.id}`} value={this.props.value} />
        {mergetags}
      </div>
    );
  }
}
