import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import ErrorMessage from "../../../common/FormElements/ErrorMessage";
import styled from "styled-components";

const IntroEditor = () => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const current = watch("introduction");
  const questions = watch("questions");
  const isErrored = errors && errors.introduction;
  const innerRef = useRef(null);

  return (
    <IntroPanel>
      <ErrorMessage name="introduction" forwardRef={innerRef} />
      <IntroTag>
        <span>Intro</span>
      </IntroTag>
      <IntroContent
        $isErrored={isErrored}
        ref={innerRef}
        $hideBorder={!questions || questions.length === 0}
      >
        <QuestionHeadline>
          What should the participant know before taking this assessment?
        </QuestionHeadline>
        <TextEdit
          value={current}
          onChange={(e) => {
            setValue(
              "introduction",
              e.target.value.length > 240 ? current : e.target.value
            );
          }}
          rows="4"
          maxLength="240"
        />
        <CharacterLimit $isHidden={typeof current !== "string"}>
          {typeof current === "string" && current.length} / 240
        </CharacterLimit>
      </IntroContent>
    </IntroPanel>
  );
};

export const IntroContent = styled.div`
  position: relative;
  width: calc(100% - 58px);
  background-color: #ffffff;
  padding: 29px 25px;
  display: flex;
  flex-direction: column;
  border-width: ${(props) => (props.$isErrored ? "1px" : "0")};
  border-style: solid;
  transition: border-color 0.2s ease-in-out;
  border-color: ${(props) => (props.$isErrored ? "#ff0000" : "transparent")};

  &::after {
    content: "";
    position: absolute;
    top: 8px;
    left: -39px;
    width: 0;
    border-left: 1px solid #c4c4c4;
    z-index: 1;
    /* height: calc(100% + 8px); */
    height: 100%;
    display: ${(props) => (props.$hideBorder ? "none" : "block")};
  }
`;

export const IntroPanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const IntroTag = styled.div`
  width: 43px;
  white-space: nowrap;
  background-color: #519acc;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  span {
    text-transform: uppercase;
    writing-mode: vertical-lr;
    text-orientation: upright;
    line-height: 32px;
    letter-spacing: 6px;
  }
`;

export const QuestionHeadline = styled.div`
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  font-family: "Arial";
  color: #000000;
  margin-bottom: 9px;
`;

export const TextEdit = styled.textarea`
  width: 100%;
  min-height: 104px;
  display: flex;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-size: 13px;
  line-height: 20px;
  font-family: "Arial";
`;

export const CharacterLimit = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 24px;
  visibility: ${(props) => (props.$isHidden ? "hidden" : "visible")};
`;

export default IntroEditor;
