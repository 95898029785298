import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// import { Turbo } from "@hotwired/turbo-rails";

const SidebarContent = ({ links = [], bottomLinks = [], isExpanded }) => {
  const [setLinks, setSetLinks] = useState(links);
  const [currentBottomLinks, setCurrentBottomLinks] = useState(bottomLinks);

  useEffect(() => {
    setSetLinks(links);
    setCurrentBottomLinks(bottomLinks);
  }, [links, bottomLinks]);

  return (
    <Container>
      <LinksContainer>
        {setLinks.map((link) => (
          <Links key={link.id}>
            <LinkContainer
              // onClick={() => Turbo.visit(link.href)}
              key={`${link.id}_link`}
              href={link.href || "#"}
            >
              <IconContainer>{link.icon}</IconContainer>
              <TextContainer
                $dataActive={link.dataActive}
                $isExpanded={isExpanded}
              >
                {link.text}
              </TextContainer>
            </LinkContainer>
          </Links>
        ))}
      </LinksContainer>
      <BottomContainer>
        {currentBottomLinks.map((link) => (
          <BottomLinks key={link.id}>
            <BottomLinkContainer key={link.id} href={link.href || "#"}>
              <IconContainer>{link.icon}</IconContainer>
              <TextContainer
                $dataActive={link.dataActive}
                $isExpanded={isExpanded}
              >
                {link.text}
              </TextContainer>
            </BottomLinkContainer>
          </BottomLinks>
        ))}
      </BottomContainer>
    </Container>
  );
};

SidebarContent.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      href: PropTypes.string,
      icon: PropTypes.node.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  bottomLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      href: PropTypes.string,
      icon: PropTypes.node.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  isExpanded: PropTypes.bool.isRequired,
};

const Container = styled.div`
  width: 100%;
  max-height: calc(100% - 308px);
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const LinksContainer = styled.div`
  width: 100%;

  @media (max-height: 748px) {
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const LinkContainer = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
`;

const Links = styled.div`
  // position: relative;
  // left: 40px;
  padding-left: 40px;
  height: 50px;
`;

const BottomContainer = styled.div`
  margin-bottom: 30px;
  position: absolute;
  bottom: 0;
`;

const BottomLinkContainer = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 10px;
  overflow: hidden;
`;

const BottomLinks = styled.div`
  position: relative;
  left: 40px;
  height: 50px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const TextContainer = styled.span`
  font-family: "Manrope";
  font-weight: 800;
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  color: ${({ $dataActive }) => ($dataActive ? "#519acc" : "#444344")};
  transition: height 0.9s ease, opacity 0.6s ease;
  position: relative;
  bottom: 5px;
  white-space: nowrap;
`;

export default SidebarContent;
