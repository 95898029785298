import WikiCategoryAccessibility from "./Accessibility";
import WikiCategoryDevs from "./Devs";
import WikiCategoryHR from "./HR";
import WikiCategoryLead from "./Lead";
import WikiCategoryMisc from "./Misc";
import WikiCategoryTeams from "./Teams";
import WikiCategoryTraining from "./Training";

const Icons = {
  AccessibilityIcon: WikiCategoryAccessibility,
  DevsIcon: WikiCategoryDevs,
  HRIcon: WikiCategoryHR,
  LeadIcon: WikiCategoryLead,
  MiscIcon: WikiCategoryMisc,
  TeamsIcon: WikiCategoryTeams,
  TrainingIcon: WikiCategoryTraining,
};

export default Icons;
