import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Table from "rc-table";
import moment from "moment";
import styled from "styled-components";
import _ from "lodash";
import CreateAssessmentModal from "../AssessmentDetails/CreateAssessmentModal";
import DeleteAssessment from "./DeleteAssessmentModal";
import EditNotepadIcon from "../../../icons/edit_notepad_icon";
import ManageParticipants from "./ManageParticipantsModal";
import RoundDropdown from "../../../icons/round_dropdown";
import { wikiAssessmentTypes } from "../../../../types/wiki";

const [LEVEL, RESOURCE, CATEGORY, CREATED_BY, UPDATED_AT, TIME_LIMIT] = [
  "level",
  "wiki_resource",
  "performance_category",
  "created_by",
  "updated_at",
  "time_limit",
];
const [ASC, DESC] = ["asc", "desc"];

const AssessmentsTable = (props) => {
  const { assessments } = props;
  const [sortKey, setSortKey] = useState(LEVEL);
  const [sortOrder, setSortOrder] = useState(DESC);

  const sortedAssessments = useMemo(() => {
    const sorted = [...assessments].sort((first, second) => {
      const SORT_MAPS = {
        [LEVEL]: (a, b) => b[sortKey] - a[sortKey],
        [RESOURCE]: (a, b) => a[sortKey].title.localeCompare(b[sortKey].title),
        [CATEGORY]: (a, b) => {
          if (!a[sortKey] && !b[sortKey]) return 0;
          if (!a[sortKey]) return 1;
          if (!b[sortKey]) return -1;
          return a[sortKey].localeCompare(b[sortKey]);
        },
        [CREATED_BY]: (a, b) => a[sortKey].name.localeCompare(b[sortKey].name),
        [UPDATED_AT]: (a, b) => moment(a[sortKey]).diff(moment(b[sortKey])),
        [TIME_LIMIT]: (a, b) => a[sortKey] - b[sortKey],
      };
      if (SORT_MAPS[sortKey] === undefined) {
        return 0;
      }
      const value = SORT_MAPS[sortKey](first, second);
      return value * (sortOrder === ASC ? 1 : -1);
    });

    return sorted;
  }, [assessments, sortOrder, sortKey]);

  const setSorter = (key) => {
    if (key === sortKey) {
      setSortOrder(sortOrder === ASC ? DESC : ASC);
    } else {
      setSortKey(key);
      setSortOrder(DESC);
    }
  };

  const columns = [
    {
      // LEVEL column
      title: (
        <ColumnHeaderButton onClick={() => setSorter(LEVEL)} $align="center">
          Level
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === LEVEL}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [LEVEL],
      width: 140,
      className: "wiki-assessments-column wiki-assessments-level",
      render: (text, record) => {
        return (
          <LevelButton $isPublished={record.published}>
            Level {text}
          </LevelButton>
        );
      },
    },
    {
      // RESOURCE column
      title: (
        <ColumnHeaderButton onClick={() => setSorter(RESOURCE)}>
          Resource
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === RESOURCE}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [RESOURCE],
      // width: 200,
      className: "wiki-assessments-column wiki-assessments-resource",
      render: (text, record) => {
        return (
          <ResourceLink href={`/wiki/${text.id}`}>
            {text.title}
            {!record.published && " (Draft)"}
          </ResourceLink>
        );
      },
    },
    {
      // CATEGORY column
      title: (
        <ColumnHeaderButton onClick={() => setSorter(CATEGORY)}>
          Performance Category
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === CATEGORY}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [CATEGORY],
      // width: 200,
      className: "wiki-assessments-column wiki-assessments-category",
      render: (text) => {
        return <TableText>{text ? _.startCase(text) : "(none)"}</TableText>;
      },
    },
    {
      // CREATED_BY column
      title: (
        <ColumnHeaderButton onClick={() => setSorter(CREATED_BY)}>
          Created By
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === CREATED_BY}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [CREATED_BY, "name"],
      // width: 200,
      className: "wiki-assessments-column wiki-assessments-created-by",
      render: (text) => {
        return <TableText>{text}</TableText>;
      },
    },
    {
      // UPDATED_AT column
      title: (
        <ColumnHeaderButton onClick={() => setSorter(UPDATED_AT)}>
          Last Modified
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === UPDATED_AT}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [UPDATED_AT],
      // width: 200,
      className: "wiki-assessments-column wiki-assessments-modified",
      render: (text) => {
        const date = `${new Date(text).toLocaleDateString(
          "en-US"
        )} - ${Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(text))}`;
        return <TableText>{date}</TableText>;
      },
    },
    {
      // TIME_LIMIT column
      title: (
        <ColumnHeaderButton onClick={() => setSorter(TIME_LIMIT)}>
          Time Limit
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === TIME_LIMIT}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [TIME_LIMIT],
      // width: 100,
      className: "wiki-assessments-column wiki-assessments-time-limit",
      render: (text) => {
        return (
          <TableText>
            {(() => {
              if (text)
                return typeof text === "string"
                  ? parseFloat(text).toFixed(2)
                  : text;
              return "N/A";
            })()}
          </TableText>
        );
      },
    },
    {
      // ACTIONS column
      title: "",
      dataIndex: "id",
      // width: 200,
      className: "wiki-assessments-column wiki-assessments-actions",
      render: (text, record) => {
        return (
          <ActionsContainer>
            {record.published && (
              <ActionButton>
                <ManageParticipants assessment={record} />
              </ActionButton>
            )}
            <ActionButton>
              <ActionLink href={`/wiki-assessments/${text}/edit`}>
                <EditNotepadIcon color="#519acc" />
              </ActionLink>
            </ActionButton>
            <DeleteAssessment assessment={record} />
          </ActionsContainer>
        );
      },
    },
  ];

  return (
    <AssessmentsTableContainer>
      <SearchAndFilters>
        <SelectAssessments>
          <Header>All Assessments</Header>
          {assessments && assessments.length > 0 && (
            <FilterValue>{assessments.length} assessments</FilterValue>
          )}
        </SelectAssessments>
        <SearchAssessments>
          <CreateAssessmentModal />
        </SearchAssessments>
      </SearchAndFilters>
      <TableContainer>
        <Table
          className="wiki-assessments-table"
          data={sortedAssessments}
          columns={columns}
          rowKey="id"
        />
      </TableContainer>
    </AssessmentsTableContainer>
  );
};

const AssessmentsTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 132px);
`;

const SearchAndFilters = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const SelectAssessments = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h4`
  font-size: 18px;
  font-weight: 700;
  font-family: "Manrope";
  line-height: 25px;
`;

const ColumnHeaderButton = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  padding: 0;
  justify-content: ${({ $align }) => $align || "flex-start"};
  width: 100%;
`;

const IconContainer = styled.div`
  margin-left: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: transform, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  ${({ $rotate }) => $rotate && "transform: rotate(180deg);"}
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;

const FilterValue = styled.div`
  margin-top: 12px;
  font-size: 14px;
  font-family: "Arial";
  line-height: 16px;
  color: #000000;
  text-align: center;
  font-weight: 400;
  padding: 6px;
  background-color: #d7d7d7;
`;

const SearchAssessments = styled.div``;

const TableContainer = styled.div`
  width: 100%;
  padding: 8px 15px;
  margin-top: 8px;
  height: 100%;
  background-color: #ededed;
  height: 100%;
  flex-grow: 1;
`;

const LevelButton = styled.div`
  width: 100%;
  max-width: 100px;
  height: 28px;
  background-color: ${(props) => (props.$isPublished ? "#519acc" : "#D7D7D7")};
  color: ${(props) => (props.$isPublished ? "#ffffff" : "#000000")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResourceLink = styled.a`
  font-size: 14px;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`;

const TableText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Arial";
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;
`;

const ActionButton = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 2px;
  border: 1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ActionLink = styled.a`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

AssessmentsTable.propTypes = {
  assessments: PropTypes.arrayOf(wikiAssessmentTypes),
};

export default AssessmentsTable;
