import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Checkbox = (props) => {
  const {
    className,
    containerProps,
    labelProps,
    spanProps,
    children,
    checked,
    ...rest
  } = props;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <Container
      className={`${className ? `${className} ` : ""}common-radio radio-button`}
      {...containerProps}
    >
      <Label {...labelProps}>
        <Input
          type="checkbox"
          className="filled-in"
          checked={isChecked}
          {...rest}
        />
        <Span {...spanProps}>{children}</Span>
      </Label>
    </Container>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.objectOf(PropTypes.string),
  labelProps: PropTypes.objectOf(PropTypes.string),
  spanProps: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  checked: PropTypes.bool,
};

const Container = styled.div`
  display: block;
`;

const Label = styled.label``;

const Input = styled.input`
  &[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
    height: 20px;
    width: 20px;
    top: 2px;
  }

  &[type="checkbox"].filled-in:checked + span:not(.lever):after {
    height: 20px;
    width: 20px;
    top: 2px;
    background-color: #519acc;
    border-color: #519acc;
  }
`;

const Span = styled.span`
  white-space: nowrap;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
`;

export default Checkbox;
