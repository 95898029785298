import React from 'react'
import Swal from "sweetalert2";
import axios from 'axios'

class ApiKeyContainer extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
    };
    this.generateApiKey = this.generateApiKey.bind(this)
  }
    generateApiKey() {
    var object = this
    var user = this.props.user
      Swal.fire({
        title: 'Update API Key',
        text: 'Are you sure that you would like to update your API Key? This will impact any existing API integrations.',
        type: 'success',
        reverseButtons: true,
        showCancelButton:true,
        confirmButtonAriaLabel: 'Yes',
        cancelButtonAriaLabel: 'cancel',
        confirmButtonText: 'Yes'
      }).then((result)=>{
        if (result.value) {
           axios.get("/accounts/update_api_key.json", {
              headers: {
                  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                },
              }).then((res) => {
                if (res.status === 200) {
                    Swal.fire(
                        'Updated',
                        'Your API Key has been updated',
                        'success'
                        ).then(()=>{
                      user.api_key = res.data
                      object.setState({user: user})
                    })
                  }
                }).catch((err) => {
                  Swal.fire('Api Key Update Error', 'There was an error updating you API Key');
                  console.error(err)
                })
              } else if (result.dismiss) {
                
              }
        })
  }

  render() {
    return (
       <div style={{position: "absolute", textAlign:"right", right:"0px", bottom:this.props.user.api_key != null && this.props.buildsLength < 1 ? "-50px" : "2px", paddingTop:"14px"}}>
         {this.props.user.api_key != null && <p style={{wordBreak:"break-all"}}><span className="field-title">Current API key:</span> {this.props.user.api_key}</p>}
         <button style={{ display: "inline-block", border:"none", paddingRight:"0px", fontSize:"11pt"}} onClick={this.generateApiKey} className="light-button"><i className="material-icons" style={{alignSelf: "center", fontSize: "17px", marginTop:"-1px"}}>add</i>Generate {this.props.user.api_key != null ? "New API Key" : "API Key"}</button>
    </div>
      )
  }
}


export default ApiKeyContainer
