import React from "react";
import axios from "axios";
import { merge } from "lodash";
import Sleep from "../common/sleep";
import ShapeIcon from "../icons/shape_icon";
import TextBoxIcon from "../icons/text_box_icon";
import PointTextBoxIcon from "../icons/point_text_box_icon";
import ArrowIcon from "../icons/arrow_icon";
import UndoIcon from "../icons/undo_icon";
import RedoIcon from "../icons/redo_icon";
import RotateIcon from "../icons/rotate_icon";

class TicketAttachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: this.props.data.currentIndex,
      edit: this.props.edit ? this.props.edit : false,
      images: this.props.data.images,
      autoSelectMode: false,
      shapeMode: false,
      arrowMode: false,
      history: [],
      historyPosition: 0,
    };
    this.canvasObjects = {};
    this.toggleEdit = this.toggleEdit.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.addArrow = this.addArrow.bind(this);
    this.addText = this.addText.bind(this);
    this.addBox = this.addBox.bind(this);
    this.redo = this.redo.bind(this);
    this.undo = this.undo.bind(this);
    this.rotateImage = this.rotateImage.bind(this);
    this.autoTextBox = this.autoTextBox.bind(this);
    this.createShape = this.createShape.bind(this);
    this.textCtx = document.createElement("canvas").getContext("2d");
    this.resizeDirection = null;
    this.rotated = 0;
  }

  toggleEdit(e) {
    this.destroyMaterial();
    setTimeout(() => {
      this.setState(
        {
          edit: !this.state.edit,
        },
        () => {
          this.loadImage();
          this.props.setBypassClickCheck(false);
        }
      );
    }, 300);
  }

  cancelEdit = () => {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    this.setState(
      {
        edit: false,
        autoSelectMode: false,
        shapeMode: false,
        arrowMode: false,
      },
      () => {
        this.createMaterial();
        this.props.setBypassClickCheck(true);
      }
    );
  };

  addArrow() {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    this.setState({
      autoSelectMode: false,
      shapeMode: false,
      arrowMode: !this.state.arrowMode,
    });
  }

  undo() {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    const undoItem = this.state.history[this.state.historyPosition - 1].undo;
    const c = document.getElementById("object-canvas");
    const ctx = c.getContext("2d");
    if (undoItem) {
      if (undoItem.rotate) {
        undoItem.rotate();
      } else if (undoItem.delete) {
        delete this.canvasObjects[undoItem.delete];
      } else if (this.canvasObjects[undoItem.id]) {
        this.canvasObjects[undoItem.id] = merge(
          this.canvasObjects[undoItem.id],
          undoItem
        );
        if (undoItem.text) {
          this.calculateTextLines(
            this.canvasObjects[undoItem.id].rect.text,
            this.canvasObjects[undoItem.id],
            ctx,
            c
          );
        }
      } else if (undoItem.historyAction !== "createAutoAnnotation") {
        this.tempBox = undoItem.tempBoxHistory;
        this.tempBoxStartX = undoItem.tempBoxStartXHistory;
        this.tempBoxStartY = undoItem.tempBoxStartYHistory;
        this.historyEvent = true;
        this[undoItem.historyAction](ctx, c);
      } else {
        this.tempBox = undoItem.tempBoxHistory;
        this.tempBoxStartX = undoItem.tempBoxStartXHistory;
        this.tempBoxStartY = undoItem.tempBoxStartYHistory;
        this.historyEvent = true;
        const { x, y, o, v, arrow } = undoItem.paramHistory;
        this[undoItem.historyAction](x, y, o, v, ctx, c, arrow);
      }
    }
    this.setState(
      {
        autoSelectMode: false,
        shapeMode: false,
        arrowMode: false,
        historyPosition: this.state.historyPosition - 1,
      },
      () => {
        this.redrawCanvas(ctx, c);
      }
    );
  }

  redo() {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    const redoItem = this.state.history[this.state.historyPosition].redo;
    const c = document.getElementById("object-canvas");
    const ctx = c.getContext("2d");
    if (redoItem) {
      if (redoItem.rotate) {
        redoItem.rotate();
      } else if (redoItem.delete) {
        delete this.canvasObjects[redoItem.delete];
      } else if (this.canvasObjects[redoItem.id]) {
        this.canvasObjects[redoItem.id] = merge(
          this.canvasObjects[redoItem.id],
          redoItem
        );
        if (redoItem.text) {
          this.calculateTextLines(
            this.canvasObjects[redoItem.id].rect.text,
            this.canvasObjects[redoItem.id],
            ctx,
            c
          );
        }
      } else if (redoItem.historyAction !== "createAutoAnnotation") {
        this.tempBox = redoItem.tempBoxHistory;
        this.tempBoxStartX = redoItem.tempBoxStartXHistory;
        this.tempBoxStartY = redoItem.tempBoxStartYHistory;
        this.historyEvent = true;
        this[redoItem.historyAction](ctx, c);
      } else {
        this.tempBox = redoItem.tempBoxHistory;
        this.tempBoxStartX = redoItem.tempBoxStartXHistory;
        this.tempBoxStartY = redoItem.tempBoxStartYHistory;
        this.historyEvent = true;
        const { x, y, o, v, arrow } = redoItem.paramHistory;
        this[redoItem.historyAction](x, y, o, v, ctx, c, arrow);
      }
    }
    this.setState(
      {
        autoSelectMode: false,
        shapeMode: false,
        arrowMode: false,
        historyPosition: this.state.historyPosition + 1,
      },
      () => {
        this.redrawCanvas(ctx, c);
      }
    );
  }

  rotateImage() {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    this.setState({
      autoSelectMode: false,
      shapeMode: false,
      arrowMode: false,
    });
    const imageCanvas = document.getElementById("image-canvas");
    const ctx = imageCanvas.getContext("2d");
    ctx.clearRect(0, 0, imageCanvas.width, imageCanvas.height);
    ctx.save();
    ctx.translate(imageCanvas.width / 2, imageCanvas.height / 2);
    ctx.rotate(((90 + this.rotated) * Math.PI) / 180);
    this.rotated += 90;
    ctx.drawImage(
      this.baseImage,
      -this.baseImage.width / 2,
      -this.baseImage.height / 2
    );
    const newWidth = this.imageHeight;
    const newHeight = this.imageWidth;
    this.imageWidth = newWidth;
    this.imageHeight = newHeight;
    this.imageX = imageCanvas.width / 2 - this.imageWidth / 2;
    this.imageY = imageCanvas.height / 2 - this.imageHeight / 2;
    let { history } = this.state;
    if (!this.historyEvent) {
      if (history[this.state.historyPosition]) {
        history = history.slice(0, this.state.historyPosition);
      }
      history.push({
        redo: {
          rotate: () => {
            this.rotateImage();
          },
        },
        undo: {
          rotate: () => {
            this.rotated -= 180;
            this.rotateImage();
          },
        },
      });
      this.setState({
        history,
        historyPosition: this.state.historyPosition + 1,
      });
    } else {
      this.historyEvent = false;
    }

    ctx.restore();
  }

  addText() {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    this.setState({
      autoSelectMode: false,
      shapeMode: false,
      arrowMode: false,
    });
    const c = document.getElementById("object-canvas");
    const ctx = c.getContext("2d");
    this.createTextBox(ctx, c);
  }

  autoTextBox() {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    this.setState({
      autoSelectMode: !this.state.autoSelectMode,
      shapeMode: false,
      arrowMode: false,
    });
  }

  addBox() {
    if (this.textEditing) {
      document.querySelector("body").removeChild(this.openTextElem);
      this.textEditing = false;
    }
    this.setState({
      shapeMode: !this.state.shapeMode,
      autoSelectMode: false,
      arrowMode: false,
    });
  }

  loadImage = async () => {
    const image = this.state.images[this.state.currentIndex];
    const blob = await axios
      .get(`/ticket_attachments/${image.id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });

    const img = new Image();
    img.src = URL.createObjectURL(blob);
    img.setAttribute("crossorigin", "anonymous");
    img.onload = function () {
      const baseCanvas = document.getElementById("base-canvas");
      const imageCanvas = document.getElementById("image-canvas");
      const c = document.getElementById("object-canvas");
      const container = document.querySelector(".attachment-modal-image");
      const ratio = container.scrollHeight / container.scrollWidth;
      baseCanvas.height = img.height < 800 ? 800 : img.height;
      do {
        baseCanvas.width = (baseCanvas.height * 1.1) / ratio;
        baseCanvas.height *= 1.1;
        c.width = baseCanvas.width;
        c.height = baseCanvas.height;
        imageCanvas.width = baseCanvas.width;
        imageCanvas.height = baseCanvas.height;
        this.resizedMultiplier = baseCanvas.height / container.scrollHeight;
        this.imageWidth = img.width;
        this.imageHeight = img.height;
        this.imageX = baseCanvas.width / 2 - img.width / 2;
        this.imageY = baseCanvas.height / 2 - img.height / 2;
      } while (baseCanvas.width - img.width < 155 * this.resizedMultiplier * 2);
      let ctx = baseCanvas.getContext("2d");
      ctx.beginPath();
      ctx.rect(0, 0, baseCanvas.width, baseCanvas.height);
      ctx.fillStyle = "#282c34";
      ctx.fill();
      ctx = imageCanvas.getContext("2d");
      ctx.drawImage(img, this.imageX, this.imageY);
      this.baseImage = document.createElement("canvas");
      this.baseImage.width = img.width;
      this.baseImage.height = img.height;
      ctx = this.baseImage.getContext("2d");
      ctx.drawImage(img, 0, 0);
      ctx = c.getContext("2d");
      /// /////////////////////////////////////
      /// /////////MOUSE MOVE LISTENER/////////
      /// /////////////////////////////////////
      this.canvasMouseMove = (e) => {
        if (
          (this.movingRect || this.movingSelectRect || this.movingArrow) &&
          (this.canvasObjects[this.movingRect] ||
            this.canvasObjects[this.movingSelectRect] ||
            this.canvasObjects[this.movingArrow])
        ) {
          /// //////////MOVING TEXTBOX, ARROW, OR SELECT BOX//////////////
          const id = this.movingRect
            ? this.movingRect
            : this.movingSelectRect
            ? this.movingSelectRect
            : this.movingArrow;
          var previousX = this.currentMouseX;
          var previousY = this.currentMouseY;
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          var xModifier = this.currentMouseX - previousX;
          var yModifier = this.currentMouseY - previousY;
          if (this.movingRect) {
            this.canvasObjects[id].rect.x =
              this.canvasObjects[id].rect.x + xModifier;
            this.canvasObjects[id].rect.y =
              this.canvasObjects[id].rect.y + yModifier;
            if (this.canvasObjects[id].line) {
              this.canvasObjects[id].line.x2 =
                this.canvasObjects[id].line.x2 + xModifier;
              this.canvasObjects[id].line.y2 =
                this.canvasObjects[id].line.y2 + yModifier;
            }
          } else if (this.movingSelectRect) {
            this.canvasObjects[id].selectRect.x =
              this.canvasObjects[id].selectRect.x + xModifier;
            this.canvasObjects[id].selectRect.y =
              this.canvasObjects[id].selectRect.y + yModifier;
            if (this.canvasObjects[id].line) {
              this.canvasObjects[id].line.x1 =
                this.canvasObjects[id].line.x1 + xModifier;
              this.canvasObjects[id].line.y1 =
                this.canvasObjects[id].line.y1 + yModifier;
            }
          } else if (this.movingArrow) {
            if (this.movingArrowStart) {
              this.canvasObjects[id].arrowStart.x =
                this.canvasObjects[id].arrowStart.x + xModifier;
              this.canvasObjects[id].arrowStart.y =
                this.canvasObjects[id].arrowStart.y + yModifier;
              this.canvasObjects[id].line.x1 =
                this.canvasObjects[id].line.x1 + xModifier;
              this.canvasObjects[id].line.y1 =
                this.canvasObjects[id].line.y1 + yModifier;
            } else if (this.movingArrowEnd) {
              this.canvasObjects[id].arrowEnd.x =
                this.canvasObjects[id].arrowEnd.x + xModifier;
              this.canvasObjects[id].arrowEnd.y =
                this.canvasObjects[id].arrowEnd.y + yModifier;
              this.canvasObjects[id].line.x2 =
                this.canvasObjects[id].line.x2 + xModifier;
              this.canvasObjects[id].line.y2 =
                this.canvasObjects[id].line.y2 + yModifier;
            } else {
              this.canvasObjects[id].arrow.x =
                this.canvasObjects[id].arrow.x + xModifier;
              this.canvasObjects[id].arrow.y =
                this.canvasObjects[id].arrow.y + yModifier;
              this.canvasObjects[id].line.x1 =
                this.canvasObjects[id].line.x1 + xModifier;
              this.canvasObjects[id].line.y1 =
                this.canvasObjects[id].line.y1 + yModifier;
            }
          }
          if (this.canvasObjects[id].orientation) {
            if (
              this.canvasObjects[id].rect.x +
                this.canvasObjects[id].rect.width <
              (this.canvasObjects[id].selectRect
                ? this.canvasObjects[id].selectRect.x
                : this.canvasObjects[id].arrow.x)
            ) {
              if (this.canvasObjects[id].orientation !== "left") {
                this.canvasObjects[id].orientation = "left";
                if (this.canvasObjects[id].selectRect) {
                  this.canvasObjects[id].line.x1 =
                    this.canvasObjects[id].selectRect.x;
                  this.canvasObjects[id].line.y1 =
                    this.canvasObjects[id].selectRect.y +
                    this.canvasObjects[id].selectRect.height / 2;
                }
                this.canvasObjects[id].line.x2 =
                  this.canvasObjects[id].rect.x +
                  this.canvasObjects[id].rect.width;
                this.canvasObjects[id].line.y2 =
                  this.canvasObjects[id].rect.y +
                  this.canvasObjects[id].rect.height / 2;
              }
            } else if (
              this.canvasObjects[id].rect.x >
              (this.canvasObjects[id].selectRect
                ? this.canvasObjects[id].selectRect.x +
                  this.canvasObjects[id].selectRect.width
                : this.canvasObjects[id].arrow.x)
            ) {
              if (this.canvasObjects[id].orientation !== "right") {
                this.canvasObjects[id].orientation = "right";
                if (this.canvasObjects[id].selectRect) {
                  this.canvasObjects[id].line.x1 =
                    this.canvasObjects[id].selectRect.x +
                    this.canvasObjects[id].selectRect.width;
                  this.canvasObjects[id].line.y1 =
                    this.canvasObjects[id].selectRect.y +
                    this.canvasObjects[id].selectRect.height / 2;
                }
                this.canvasObjects[id].line.x2 = this.canvasObjects[id].rect.x;
                this.canvasObjects[id].line.y2 =
                  this.canvasObjects[id].rect.y +
                  this.canvasObjects[id].rect.height / 2;
              }
            } else if (
              this.canvasObjects[id].rect.y >=
              (this.canvasObjects[id].selectRect
                ? this.canvasObjects[id].selectRect.y +
                  this.canvasObjects[id].selectRect.height
                : this.canvasObjects[id].arrow.y)
            ) {
              if (this.canvasObjects[id].orientation !== "bottom") {
                this.canvasObjects[id].orientation = "bottom";
                if (this.canvasObjects[id].selectRect) {
                  this.canvasObjects[id].line.x1 =
                    this.canvasObjects[id].selectRect.x +
                    this.canvasObjects[id].selectRect.width / 2;
                  this.canvasObjects[id].line.y1 =
                    this.canvasObjects[id].selectRect.y +
                    this.canvasObjects[id].selectRect.height;
                }
                this.canvasObjects[id].line.x2 =
                  this.canvasObjects[id].rect.x +
                  this.canvasObjects[id].rect.width / 2;
                this.canvasObjects[id].line.y2 = this.canvasObjects[id].rect.y;
              }
            } else if (
              this.canvasObjects[id].rect.y +
                this.canvasObjects[id].rect.height <=
              (this.canvasObjects[id].selectRect
                ? this.canvasObjects[id].selectRect.y
                : this.canvasObjects[id].arrow.y)
            ) {
              if (this.canvasObjects[id].orientation !== "top") {
                this.canvasObjects[id].orientation = "top";
                if (this.canvasObjects[id].selectRect) {
                  this.canvasObjects[id].line.x1 =
                    this.canvasObjects[id].selectRect.x +
                    this.canvasObjects[id].selectRect.width / 2;
                  this.canvasObjects[id].line.y1 =
                    this.canvasObjects[id].selectRect.y;
                }
                this.canvasObjects[id].line.x2 =
                  this.canvasObjects[id].rect.x +
                  this.canvasObjects[id].rect.width / 2;
                this.canvasObjects[id].line.y2 =
                  this.canvasObjects[id].rect.y +
                  this.canvasObjects[id].rect.height;
              }
            }
          }
          this.redrawCanvas(ctx, c);
        } else if (
          ((this.state.autoSelectMode && this.autoSelectMouseDown) ||
            (this.state.shapeMode && this.shapeMouseDown) ||
            (this.state.arrowMode && this.arrowMouseDown)) &&
          !this.tempBoxStartX
        ) {
          /// ///////////DRAWING TEMP SELECT BOX
          /// //////////start drawing tempbox
          this.tempBoxStartX = this.currentMouseX;
          this.tempBoxStartY = this.currentMouseY;
          this.tempBox = {};
          if (this.arrowMouseDown) {
            this.tempBox.arrow = true;
          }
          this.tempBox.x = this.currentMouseX;
          this.tempBox.y = this.currentMouseY;
          this.tempBox.width = 0;
          this.tempBox.height = 0;
        } else if (
          ((this.state.autoSelectMode && this.autoSelectMouseDown) ||
            (this.state.shapeMode && this.shapeMouseDown) ||
            (this.state.arrowMode && this.arrowMouseDown)) &&
          this.tempBoxStartX
        ) {
          /// //////////continue drawing tempbox
          var previousX = this.currentMouseX;
          var previousY = this.currentMouseY;
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          var xModifier = this.currentMouseX - previousX;
          var yModifier = this.currentMouseY - previousY;
          this.tempBox.width += xModifier;
          this.tempBox.height += yModifier;
          this.redrawCanvas(ctx, c);
        } else if (
          this.resizingSelectRect &&
          this.canvasObjects[this.resizingSelectRect.id]
        ) {
          /// ///////////RESIZING AUTO SELECT RECT/////////////
          const object = this.canvasObjects[this.resizingSelectRect.id];
          var previousX = this.currentMouseX;
          var previousY = this.currentMouseY;
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          var xModifier = this.currentMouseX - previousX;
          var yModifier = this.currentMouseY - previousY;
          const leftWidthMin =
            object.selectRect.width - xModifier <= 10 * this.resizedMultiplier;
          const rightWidthMin =
            object.selectRect.width + xModifier <= 10 * this.resizedMultiplier;
          const bottomHeightMin =
            object.selectRect.height + yModifier <= 10 * this.resizedMultiplier;
          const topHeightMin =
            object.selectRect.height - yModifier <= 10 * this.resizedMultiplier;
          object.selectMoveIcon.fill = "transparent";
          switch (this.resizingSelectRect.direction) {
            case "left":
              if (!leftWidthMin) {
                object.selectRect.x += xModifier;
                object.selectRect.width -= xModifier;
              }
              break;
            case "left-top":
              if (!leftWidthMin) {
                object.selectRect.x += xModifier;
                object.selectRect.width -= xModifier;
              }
              if (!topHeightMin) {
                object.selectRect.y += yModifier;
                object.selectRect.height -= yModifier;
              }
              break;
            case "left-bottom":
              if (!leftWidthMin) {
                object.selectRect.x += xModifier;
                object.selectRect.width -= xModifier;
              }
              if (!bottomHeightMin) {
                object.selectRect.height += yModifier;
              }
              break;
            case "right":
              if (!rightWidthMin) {
                object.selectRect.width += xModifier;
              }
              break;
            case "right-top":
              if (!rightWidthMin) {
                object.selectRect.width += xModifier;
              }
              if (!topHeightMin) {
                object.selectRect.y += yModifier;
                object.selectRect.height -= yModifier;
              }
              break;
            case "right-bottom":
              if (!rightWidthMin) {
                object.selectRect.width += xModifier;
              }
              if (!bottomHeightMin) {
                object.selectRect.height += yModifier;
              }
              break;
            case "bottom":
              if (!bottomHeightMin) {
                object.selectRect.height += yModifier;
              }
              break;
            case "top":
              if (!topHeightMin) {
                object.selectRect.y += yModifier;
                object.selectRect.height -= yModifier;
              }
              break;
          }
          if (object.orientation) {
            switch (object.orientation) {
              case "left":
                object.line.x1 = object.selectRect.x;
                object.line.y1 =
                  object.selectRect.y + object.selectRect.height / 2;
                break;
              case "right":
                object.line.x1 = object.selectRect.x + object.selectRect.width;
                object.line.y1 =
                  object.selectRect.y + object.selectRect.height / 2;
                break;
              case "bottom":
                object.line.x1 =
                  object.selectRect.x + object.selectRect.width / 2;
                object.line.y1 = object.selectRect.y + object.selectRect.height;
                break;
              case "top":
                object.line.x1 =
                  object.selectRect.x + object.selectRect.width / 2;
                object.line.y1 = object.selectRect.y;
                break;
            }
          }
          this.redrawCanvas(ctx, c);
        } else if (
          !this.state.autoSelectMode &&
          Object.keys(this.canvasObjects).length > 0
        ) {
          /// ////////////////////////////////////////////////
          /// /////////VARIOUS HOVER STATES ON THE CANVAS/////
          /// ////////////////////////////////////////////////
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          Object.values(this.canvasObjects).forEach((o) => {
            o.hoverCheck(this.currentMouseX, this.currentMouseY);
          });
        }
      };
      /// /////////////////////////////////////
      /// /////////MOUSE DOWN LISTENER/////////
      /// /////////////////////////////////////
      this.canvasMouseDown = (e) => {
        if (
          this.state.autoSelectMode ||
          this.state.shapeMode ||
          this.state.arrowMode
        ) {
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          if (
            this.currentMouseX >= this.imageX &&
            this.currentMouseX < this.imageX + this.imageWidth &&
            this.currentMouseY >= this.imageY &&
            this.currentMouseY < this.imageY + this.imageHeight
          ) {
            if (this.state.autoSelectMode) {
              this.autoSelectMouseDown = true;
            } else if (this.state.shapeMode) {
              this.shapeMouseDown = true;
            } else if (this.state.arrowMode) {
              this.arrowMouseDown = true;
            }
          } else if (this.state.arrowMode) {
            this.arrowMouseDown = true;
          }
        } else {
          Object.values(this.canvasObjects).forEach((o) => {
            let renderHappened = false;
            if (o.rect) {
              if (
                this.currentMouseX >
                  o.rect.x + (o.rect.width - 20 * this.resizedMultiplier) &&
                this.currentMouseX <
                  o.rect.x + (o.rect.width - 4 * this.resizedMultiplier) &&
                this.currentMouseY > o.rect.y + 4 * this.resizedMultiplier &&
                this.currentMouseY < o.rect.y + 20 * this.resizedMultiplier
              ) {
                this.movingRect = o.id;
                this.historyStartTemp = JSON.parse(JSON.stringify(o));
                renderHappened = true;
                c.style.cursor = "grabbing";
              } else if (
                this.currentMouseX >
                  o.rect.x + (o.rect.width - 45 * this.resizedMultiplier) &&
                this.currentMouseX <
                  o.rect.x + (o.rect.width - 4 * this.resizedMultiplier) &&
                this.currentMouseY > o.rect.y + 4 * this.resizedMultiplier &&
                this.currentMouseY < o.rect.y + 20 * this.resizedMultiplier
              ) {
                this.objectDelete = {
                  x: o.rect.x + (o.rect.width - 35 * this.resizedMultiplier),
                  y: o.rect.y + 10 * this.resizedMultiplier,
                  currentPercent: 0,
                  id: o.id,
                };
                this.deleteAnimation(
                  this.objectDelete.currentPercent / 100,
                  ctx,
                  c
                );
              } else if (
                !this.textEditing &&
                this.currentMouseX > o.rect.x &&
                this.currentMouseX < o.rect.x + o.rect.width &&
                this.currentMouseY > o.rect.y &&
                this.currentMouseY < o.rect.y + o.rect.height
              ) {
                o.rect.textLines = [];
                this.redrawCanvas(ctx, c);
                this.showTextarea(c.getBoundingClientRect(), o);
                renderHappened = true;
              }
            }
            if (!renderHappened && o.arrow) {
              if (
                this.currentMouseX > o.arrow.x &&
                this.currentMouseX < o.arrow.x + o.arrow.width &&
                this.currentMouseY > o.arrow.y &&
                this.currentMouseY < o.arrow.y + o.arrow.height
              ) {
                this.movingArrow = o.id;
                this.historyStartTemp = JSON.parse(JSON.stringify(o));
                renderHappened = true;
                c.style.cursor = "grabbing";
              }
            }
            if (renderHappened === false && o.arrowStart) {
              if (
                this.currentMouseX > o.arrowStart.x &&
                this.currentMouseX < o.arrowStart.x + o.arrowStart.width &&
                this.currentMouseY > o.arrowStart.y &&
                this.currentMouseY < o.arrowStart.y + o.arrowStart.height
              ) {
                this.movingArrow = o.id;
                this.movingArrowStart = true;
                this.historyStartTemp = JSON.parse(JSON.stringify(o));
                renderHappened = true;
                c.style.cursor = "grabbing";
              }
            }
            if (renderHappened === false && o.arrowEnd) {
              if (
                this.currentMouseX > o.arrowEnd.x &&
                this.currentMouseX < o.arrowEnd.x + o.arrowEnd.width &&
                this.currentMouseY > o.arrowEnd.y &&
                this.currentMouseY < o.arrowEnd.y + o.arrowEnd.height
              ) {
                this.movingArrow = o.id;
                this.movingArrowEnd = true;
                this.historyStartTemp = JSON.parse(JSON.stringify(o));
                renderHappened = true;
                c.style.cursor = "grabbing";
              } else if (
                this.currentMouseX >
                  o.arrowEnd.x - 5 * this.resizedMultiplier &&
                this.currentMouseX <
                  o.arrowEnd.x + 14 * this.resizedMultiplier &&
                this.currentMouseY >
                  o.arrowEnd.y - 25 * this.resizedMultiplier &&
                this.currentMouseY < o.arrowEnd.y + 20 * this.resizedMultiplier
              ) {
                this.objectDelete = {
                  x: o.arrowEnd.x,
                  y: o.arrowEnd.y - 18 * this.resizedMultiplier,
                  currentPercent: 0,
                  id: o.id,
                };
                this.deleteAnimation(
                  this.objectDelete.currentPercent / 100,
                  ctx,
                  c
                );
              }
            }
            if (!renderHappened && o.selectRect) {
              if (
                this.resizeDirection &&
                this.currentMouseX >
                  o.selectRect.x - 4 * this.resizedMultiplier &&
                this.currentMouseX <
                  o.selectRect.x +
                    4 * this.resizedMultiplier +
                    o.selectRect.width &&
                this.currentMouseY >
                  o.selectRect.y - 4 * this.resizedMultiplier &&
                this.currentMouseY <
                  o.selectRect.y +
                    4 * this.resizedMultiplier +
                    o.selectRect.height
              ) {
                this.resizingSelectRect = {
                  id: o.id,
                  direction: this.resizeDirection,
                };
                this.historyStartTemp = JSON.parse(JSON.stringify(o));
              } else if (
                this.currentMouseX >
                  o.selectRect.x +
                    (o.selectRect.width - 20 * this.resizedMultiplier) &&
                this.currentMouseX <
                  o.selectRect.x +
                    (o.selectRect.width - 4 * this.resizedMultiplier) &&
                this.currentMouseY >
                  o.selectRect.y + 4 * this.resizedMultiplier &&
                this.currentMouseY <
                  o.selectRect.y + 20 * this.resizedMultiplier
              ) {
                this.movingSelectRect = o.id;
                this.historyStartTemp = JSON.parse(JSON.stringify(o));
                renderHappened = true;
                c.style.cursor = "grabbing";
              } else if (
                this.currentMouseX >
                  o.selectRect.x +
                    (o.selectRect.width - 45 * this.resizedMultiplier) &&
                this.currentMouseX <
                  o.selectRect.x +
                    (o.selectRect.width - 4 * this.resizedMultiplier) &&
                this.currentMouseY >
                  o.selectRect.y + 4 * this.resizedMultiplier &&
                this.currentMouseY <
                  o.selectRect.y + 20 * this.resizedMultiplier
              ) {
                this.objectDelete = {
                  x:
                    o.selectRect.x +
                    (o.selectRect.width - 35 * this.resizedMultiplier),
                  y: o.selectRect.y + 10 * this.resizedMultiplier,
                  currentPercent: 0,
                  id: o.id,
                };
                this.deleteAnimation(
                  this.objectDelete.currentPercent / 100,
                  ctx,
                  c
                );
              }
            }
          });
        }
      };
      /// /////////////////////////////////////
      /// /////////MOUSE UP LISTENER///////////
      /// /////////////////////////////////////
      this.canvasMouseUp = (e) => {
        if (this.state.autoSelectMode) {
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          if (
            this.currentMouseX >= this.imageX &&
            this.currentMouseX < this.imageX + this.imageWidth &&
            this.currentMouseY >= this.imageY &&
            this.currentMouseY < this.imageY + this.imageHeight
          ) {
            if (
              this.tempBox &&
              this.tempBox.width >= 10 * this.resizedMultiplier &&
              this.tempBox.height >= 10 * this.resizedMultiplier
            ) {
              var orientation =
                this.tempBoxStartX < this.imageX + this.imageWidth / 2
                  ? "left"
                  : "right";
              var verticalOrientation =
                this.tempBoxStartY < this.imageY + this.imageHeight / 2
                  ? "top"
                  : "bottom";
              this.createAutoAnnotation(
                this.currentMouseX,
                this.currentMouseY,
                orientation,
                verticalOrientation,
                ctx,
                c,
                false
              );
              this.setState({ autoSelectMode: false });
            } else {
              var orientation =
                this.currentMouseX < this.imageX + this.imageWidth / 2
                  ? "left"
                  : "right";
              var verticalOrientation =
                this.currentMouseY < this.imageY + this.imageHeight / 2
                  ? "top"
                  : "bottom";
              this.createAutoAnnotation(
                this.currentMouseX,
                this.currentMouseY,
                orientation,
                verticalOrientation,
                ctx,
                c,
                true
              );
              this.setState({ autoSelectMode: false });
              if (this.tempBox) {
                this.tempBox = null;
                this.tempBoxStartX = null;
                this.tempBoxStartY = null;
                this.redrawCanvas(ctx, c);
              }
            }
          } else if (this.tempBox) {
            this.tempBox = null;
            this.tempBoxStartX = null;
            this.tempBoxStartY = null;
            this.setState({ autoSelectMode: false });
            this.redrawCanvas(ctx, c);
          }
        } else if (
          this.state.shapeMode &&
          this.shapeMouseDown &&
          this.tempBox
        ) {
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          this.createShape(ctx, c);
          this.setState({ shapeMode: false });
        } else if (this.state.arrowMode && this.tempBox) {
          this.currentMouseX =
            e.offsetX * (e.target.width / e.target.scrollWidth);
          this.currentMouseY =
            e.offsetY * (e.target.height / e.target.scrollHeight);
          this.createArrow(ctx, c);
          this.setState({ arrowMode: false });
        }
        if (this.resizingSelectRect) {
          var { history } = this.state;
          const redoObject = JSON.parse(
            JSON.stringify(this.canvasObjects[this.resizingSelectRect.id])
          );
          const undoObject = JSON.parse(JSON.stringify(this.historyStartTemp));
          if (history[this.state.historyPosition]) {
            history = history.slice(0, this.state.historyPosition);
          }
          history.push({ redo: redoObject, undo: undoObject });
          this.setState({
            history,
            historyPosition: this.state.historyPosition + 1,
          });
          this.historyStartTemp = null;
          this.canvasObjects[this.resizingSelectRect.id].selectMoveIcon.fill =
            "gray";
          this.redrawCanvas(ctx, c);
        }
        if (this.historyStartTemp) {
          const id = this.movingRect
            ? this.movingRect
            : this.movingArrow
            ? this.movingArrow
            : this.movingSelectRect;
          if (this.canvasObjects[id]) {
            var { history } = this.state;

            if (history[this.state.historyPosition]) {
              history = history.slice(0, this.state.historyPosition);
            }
            history.push({
              redo: JSON.parse(JSON.stringify(this.canvasObjects[id])),
              undo: JSON.parse(JSON.stringify(this.historyStartTemp)),
            });
            this.setState({
              history,
              historyPosition: this.state.historyPosition + 1,
            });
            this.historyStartTemp = null;
          }
        }
        this.movingRect = false;
        this.movingArrow = false;
        this.movingArrowStart = false;
        this.movingArrowEnd = false;
        this.movingSelectRect = false;
        this.autoSelectMouseDown = false;
        this.shapeMouseDown = false;
        this.arrowMouseDown = false;
        this.resizeDirection = null;
        this.resizingSelectRect = null;
        this.objectDelete = false;
        if (c.style.cursor === "grabbing") {
          c.style.cursor = "grab";
        }
      };
      this.textAreaChange = (e) => {
        if (e.target.scrollHeight > e.target.clientHeight) {
          const id = parseInt(e.target.dataset.id);
          if (this.canvasObjects[id]) {
            this.canvasObjects[id].rect.height += 16 * this.resizedMultiplier;
            if (this.canvasObjects[id].line) {
              this.canvasObjects[id].line.y2 =
                this.canvasObjects[id].rect.y +
                this.canvasObjects[id].rect.height / 2;
            }
            e.target.style.height = `${
              this.canvasObjects[id].rect.height / this.resizedMultiplier
            }px`;
            this.redrawCanvas(ctx, c);
          }
        }
      };
      this.textAreaBlur = (e) => {
        const id = parseInt(e.target.dataset.id);
        if (this.canvasObjects[id]) {
          const historyObject = {};
          const currentText = this.canvasObjects[id].rect.text;
          historyObject.undo = JSON.parse(
            JSON.stringify(this.canvasObjects[id])
          );
          this.canvasObjects[id].rect.text = e.target.value;
          historyObject.redo = JSON.parse(
            JSON.stringify(this.canvasObjects[id])
          );
          historyObject.undo.text = true;
          historyObject.redo.text = true;
          if (!this.historyEvent && (currentText || e.target.value)) {
            let { history } = this.state;
            if (history[this.state.historyPosition]) {
              history = history.slice(0, this.state.historyPosition);
            }
            history.push(historyObject);
            this.setState({
              history,
              historyPosition: this.state.historyPosition + 1,
            });
          } else {
            this.historyEvent = false;
          }
          document.querySelector("body").removeChild(this.openTextElem);
          this.calculateTextLines(
            this.canvasObjects[id].rect.text,
            this.canvasObjects[id],
            ctx,
            c
          );
          this.textEditing = false;
        }
      };
      c.addEventListener("mousedown", this.canvasMouseDown);
      c.addEventListener("mousemove", this.canvasMouseMove);
      c.addEventListener("mouseup", this.canvasMouseUp);
    }.bind(this);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  calculateTextLines = (text, object, ctx, c) => {
    /// /Measure text in textboxes to determine linebreaks for text rendered to canvas
    this.textCtx.font = `${14 * this.resizedMultiplier}px Arial`;
    const textArray = text.split(" ");
    let currentLine = "";
    const textLines = [];
    textArray.forEach((word, i) => {
      if (
        this.textCtx.measureText(word).width >
        object.rect.width - 4 * this.resizedMultiplier
      ) {
        // do nothing
      } else if (i === 0) {
        currentLine += word;
      } else if (
        this.textCtx.measureText(`${currentLine} ${word}`).width >
        object.rect.width - 4 * this.resizedMultiplier
      ) {
        textLines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += ` ${word}`;
      }
    });
    if (currentLine) {
      textLines.push(currentLine);
    }
    if (textLines.length > 3) {
      object.rect.height =
        textLines.length * 16 * this.resizedMultiplier +
        5 * this.resizedMultiplier;
    } else {
      object.rect.height = 60 * this.resizedMultiplier;
    }
    if (object.line) {
      object.line.y2 = object.rect.y + object.rect.height / 2;
    }
    object.rect.textLines = textLines;
    this.redrawCanvas(ctx, c);
  };

  createShape(ctx, c) {
    /// /Create Rectangle selection box
    const shapeObject = {};
    shapeObject.id = Object.keys(this.canvasObjects).length + 1;
    shapeObject.tempBoxHistory = { ...this.tempBox };
    shapeObject.tempBoxStartXHistory = this.tempBoxStartX;
    shapeObject.tempBoxStartYHistory = this.tempBoxStartY;
    shapeObject.historyAction = "createShape";
    if (this.tempBox.width < 0) {
      this.tempBox.x += this.tempBox.width;
      this.tempBox.width = -this.tempBox.width;
    }
    if (this.tempBox.height < 0) {
      this.tempBox.y += this.tempBox.height;
      this.tempBox.height = -this.tempBox.height;
    }
    shapeObject.selectRect = {
      x: this.tempBox.x,
      y: this.tempBox.y,
      width: this.tempBox.width,
      height: this.tempBox.height,
      hovered: false,
    };
    shapeObject.selectMoveIcon = {
      fill: "transparent",
      hovered: false,
    };
    shapeObject.selectDeleteIcon = {
      fill: "transparent",
      hovered: false,
    };
    this.tempBox = null;
    this.tempBoxStartX = null;
    this.tempBoxStartY = null;
    shapeObject.render = () => {
      ctx.beginPath();
      ctx.lineWidth = 2 * this.resizedMultiplier;
      ctx.strokeStyle = "red";
      ctx.rect(
        shapeObject.selectRect.x,
        shapeObject.selectRect.y,
        shapeObject.selectRect.width,
        shapeObject.selectRect.height
      );
      ctx.stroke();
      ctx.font = `${20 * this.resizedMultiplier}px Material Icons`;
      ctx.fillStyle = shapeObject.selectMoveIcon.fill;
      ctx.fillText(
        "open_with",
        shapeObject.selectRect.x +
          (shapeObject.selectRect.width - 20 * this.resizedMultiplier),
        shapeObject.selectRect.y + 20 * this.resizedMultiplier
      );
      ctx.fillStyle = shapeObject.selectMoveIcon.fill;
      ctx.fillText(
        "delete",
        shapeObject.selectRect.x +
          (shapeObject.selectRect.width - 45 * this.resizedMultiplier),
        shapeObject.selectRect.y + 20 * this.resizedMultiplier
      );
    };
    shapeObject.hoverCheck = (x, y) => {
      this.checkSelectBoxHover(shapeObject, x, y, c, ctx);
    };
    this.canvasObjects[shapeObject.id] = shapeObject;
    if (!this.historyEvent) {
      let { history } = this.state;
      const historyObject = JSON.parse(JSON.stringify(shapeObject));
      if (history[this.state.historyPosition]) {
        history = history.slice(0, this.state.historyPosition);
      }
      history.push({ redo: historyObject, undo: { delete: shapeObject.id } });
      this.setState({
        history,
        historyPosition: this.state.historyPosition + 1,
      });
    } else {
      this.historyEvent = false;
    }
    shapeObject.render();
  }

  createArrow = (ctx, c) => {
    const arrowObject = {};
    arrowObject.id = Object.keys(this.canvasObjects).length + 1;
    const arrowOrientation = ["bottom", "right"];
    if (this.tempBox.width < 0) {
      this.tempBox.x += this.tempBox.width;
      this.tempBox.width = -this.tempBox.width;
      arrowOrientation[1] = "left";
    }
    if (this.tempBox.height < 0) {
      this.tempBox.y += this.tempBox.height;
      this.tempBox.height = -this.tempBox.height;
      arrowOrientation[0] = "top";
    }
    arrowObject.tempBoxHistory = { ...this.tempBox };
    arrowObject.tempBoxStartXHistory = this.tempBoxStartX;
    arrowObject.tempBoxStartYHistory = this.tempBoxStartY;
    arrowObject.historyAction = "createArrow";
    arrowObject.line = {
      x1:
        arrowOrientation[1] === "right"
          ? this.tempBox.x
          : this.tempBox.x + this.tempBox.width,
      y1:
        arrowOrientation[0] === "bottom"
          ? this.tempBox.y
          : this.tempBox.y + this.tempBox.height,
      x2:
        arrowOrientation[1] === "left"
          ? this.tempBox.x
          : this.tempBox.x + this.tempBox.width,
      y2:
        arrowOrientation[0] === "top"
          ? this.tempBox.y
          : this.tempBox.y + this.tempBox.height,
      hovered: false,
    };
    arrowObject.arrowStart = {
      x: arrowObject.line.x1 - 7 * this.resizedMultiplier,
      y: arrowObject.line.y1 - 7 * this.resizedMultiplier,
      width: 14 * this.resizedMultiplier,
      height: 14 * this.resizedMultiplier,
      hovered: false,
    };
    arrowObject.arrowEnd = {
      x: arrowObject.line.x2 - 7 * this.resizedMultiplier,
      y: arrowObject.line.y2 - 7 * this.resizedMultiplier,
      width: 14 * this.resizedMultiplier,
      height: 14 * this.resizedMultiplier,
      hovered: false,
    };
    arrowObject.arrowDeleteIcon = {
      fill: "transparent",
      hovered: false,
    };
    this.tempBox = null;
    this.tempBoxStartX = null;
    this.tempBoxStartY = null;
    arrowObject.render = () => {
      // line
      ctx.beginPath();
      ctx.moveTo(arrowObject.line.x1, arrowObject.line.y1);
      ctx.lineTo(arrowObject.line.x2, arrowObject.line.y2);
      ctx.lineWidth = 3 * this.resizedMultiplier;
      ctx.strokeStyle = "#FF0000";
      ctx.stroke();
      // arrowhead
      const headlen = 12 * this.resizedMultiplier;
      const dx = arrowObject.line.x2 - arrowObject.line.x1;
      const dy = arrowObject.line.y2 - arrowObject.line.y1;
      const angle = Math.atan2(dy, dx);
      ctx.beginPath();
      ctx.lineWidth = 3 * this.resizedMultiplier;
      ctx.strokeStyle = "#FF0000";
      ctx.moveTo(arrowObject.line.x2, arrowObject.line.y2);
      ctx.lineTo(
        arrowObject.line.x2 - headlen * Math.cos(angle - Math.PI / 5),
        arrowObject.line.y2 - headlen * Math.sin(angle - Math.PI / 5)
      );
      ctx.moveTo(arrowObject.line.x2, arrowObject.line.y2);
      ctx.lineTo(
        arrowObject.line.x2 - headlen * Math.cos(angle + Math.PI / 5),
        arrowObject.line.y2 - headlen * Math.sin(angle + Math.PI / 5)
      );
      ctx.lineCap = "round";
      ctx.stroke();
      // delete icon
      ctx.font = `${20 * this.resizedMultiplier}px Material Icons`;
      ctx.fillStyle = arrowObject.arrowDeleteIcon.fill;
      ctx.fillText(
        "delete",
        arrowObject.arrowEnd.x - 10 * this.resizedMultiplier,
        arrowObject.arrowEnd.y - 8 * this.resizedMultiplier
      );
    };
    arrowObject.hoverCheck = (x, y) => {
      ctx.beginPath();
      ctx.rect(
        arrowObject.arrowStart.x,
        arrowObject.arrowStart.y,
        arrowObject.arrowStart.width,
        arrowObject.arrowStart.height
      );
      if (ctx.isPointInPath(x, y)) {
        if (!arrowObject.arrowStart.hovered) {
          arrowObject.arrowStart.hovered = true;
          c.style.cursor = "grab";
          return;
        }
      } else if (arrowObject.arrowStart.hovered) {
        arrowObject.arrowStart.hovered = false;
        c.style.cursor = "default";
        return;
      }
      ctx.beginPath();
      ctx.rect(
        arrowObject.arrowEnd.x,
        arrowObject.arrowEnd.y,
        arrowObject.arrowEnd.width,
        arrowObject.arrowEnd.height
      );
      if (ctx.isPointInPath(x, y)) {
        if (!arrowObject.arrowEnd.hovered) {
          arrowObject.arrowEnd.hovered = true;
          arrowObject.arrowDeleteIcon.fill = "gray";
          arrowObject.render();
          c.style.cursor = "grab";
          return;
        }
      } else if (arrowObject.arrowEnd.hovered) {
        arrowObject.arrowEnd.hovered = false;
        c.style.cursor = "default";
        arrowObject.arrowDeleteIcon.fill = "transparent";
        this.redrawCanvas(ctx, c);
        return;
      }
      ctx.beginPath();
      ctx.rect(
        arrowObject.arrowEnd.x - 5 * this.resizedMultiplier,
        arrowObject.arrowEnd.y - 25 * this.resizedMultiplier,
        14 * this.resizedMultiplier,
        20 * this.resizedMultiplier
      );
      if (ctx.isPointInPath(x, y)) {
        if (!arrowObject.arrowDeleteIcon.hovered) {
          arrowObject.arrowDeleteIcon.hovered = true;
          arrowObject.arrowDeleteIcon.fill = "gray";
          c.style.cursor = "pointer";
          arrowObject.render();
        }
      } else if (arrowObject.arrowDeleteIcon.hovered) {
        arrowObject.arrowDeleteIcon.hovered = false;
        c.style.cursor = "default";
        arrowObject.arrowDeleteIcon.fill = "transparent";
        this.redrawCanvas(ctx, c);
      }
    };
    this.canvasObjects[arrowObject.id] = arrowObject;
    if (!this.historyEvent) {
      let { history } = this.state;
      if (history[this.state.historyPosition]) {
        history = history.slice(0, this.state.historyPosition);
      }
      history.push({
        redo: JSON.parse(JSON.stringify(arrowObject)),
        undo: { delete: arrowObject.id },
      });
      this.setState({
        history,
        historyPosition: this.state.historyPosition + 1,
      });
    } else {
      this.historyEvent = false;
    }
    arrowObject.render();
  };

  createTextBox = (ctx, c) => {
    const textObject = {};
    textObject.id = Object.keys(this.canvasObjects).length + 1;
    const rectHeight = 60 * this.resizedMultiplier;
    const rectX = this.imageX - 150 * this.resizedMultiplier;
    const rectY = this.imageY;
    textObject.historyAction = "createTextBox";
    textObject.rect = {
      x: rectX,
      y: rectY,
      width: 140 * this.resizedMultiplier,
      height: rectHeight,
      hovered: false,
      text: "",
      textLines: [],
    };
    textObject.rectMoveIcon = {
      fill: "transparent",
      hovered: false,
    };
    textObject.rectDeleteIcon = {
      fill: "transparent",
      hovered: false,
    };
    textObject.render = () => {
      ctx.beginPath();
      ctx.lineWidth = "3";
      ctx.strokeStyle = "red";
      ctx.fillStyle = "white";
      ctx.rect(
        textObject.rect.x,
        textObject.rect.y,
        textObject.rect.width,
        textObject.rect.height
      );
      ctx.stroke();
      ctx.fill();
      // text
      if (textObject.rect.textLines.length > 0) {
        ctx.font = `${14 * this.resizedMultiplier}px Arial`;
        ctx.fillStyle = "black";
        textObject.rect.textLines.forEach((l, i) => {
          ctx.fillText(
            l,
            textObject.rect.x + 2 * this.resizedMultiplier,
            textObject.rect.y + (i + 1) * (16 * this.resizedMultiplier)
          );
        });
      }
      // move icon
      ctx.font = `${20 * this.resizedMultiplier}px Material Icons`;
      ctx.fillStyle = textObject.rectMoveIcon.fill;
      ctx.fillText(
        "open_with",
        textObject.rect.x +
          (textObject.rect.width - 20 * this.resizedMultiplier),
        textObject.rect.y + 20 * this.resizedMultiplier
      );
      // delete icon
      ctx.font = `${20 * this.resizedMultiplier}px Material Icons`;
      ctx.fillStyle = textObject.rectDeleteIcon.fill;
      ctx.fillText(
        "delete",
        textObject.rect.x +
          (textObject.rect.width - 45 * this.resizedMultiplier),
        textObject.rect.y + 20 * this.resizedMultiplier
      );
    };
    textObject.hoverCheck = (x, y) => {
      ctx.beginPath();
      ctx.lineWidth = "3";
      ctx.rect(
        textObject.rect.x,
        textObject.rect.y,
        textObject.rect.width,
        textObject.rect.height
      );
      if (ctx.isPointInPath(x, y)) {
        if (!textObject.rect.hovered) {
          textObject.rect.hovered = true;
          textObject.rectMoveIcon.fill = "gray";
          textObject.rectDeleteIcon.fill = "gray";
          c.style.cursor = "text";
          textObject.render();
        } else {
          ctx.beginPath();
          ctx.lineWidth = "3";
          ctx.rect(
            textObject.rect.x +
              (textObject.rect.width - 20 * this.resizedMultiplier),
            textObject.rect.y + 5 * this.resizedMultiplier,
            15 * this.resizedMultiplier,
            15 * this.resizedMultiplier
          );
          /// ///////Check for move icon hover
          if (ctx.isPointInPath(x, y)) {
            if (!textObject.rectMoveIcon.hovered) {
              textObject.rectMoveIcon.hovered = true;
              c.style.cursor = "grab";
              textObject.render();
              return;
            }
          } else if (textObject.rectMoveIcon.hovered) {
            textObject.rectMoveIcon.hovered = false;
            c.style.cursor = "text";
            textObject.render();
            return;
          }
          ctx.beginPath();
          ctx.rect(
            textObject.rect.x +
              (textObject.rect.width - 45 * this.resizedMultiplier),
            textObject.rect.y + 5 * this.resizedMultiplier,
            15 * this.resizedMultiplier,
            15 * this.resizedMultiplier
          );
          /// ///////Check for delete icon hover
          if (ctx.isPointInPath(x, y)) {
            if (!textObject.rectDeleteIcon.hovered) {
              textObject.rectDeleteIcon.hovered = true;
              c.style.cursor = "pointer";
              textObject.render();
            }
          } else if (textObject.rectDeleteIcon.hovered) {
            textObject.rectDeleteIcon.hovered = false;
            c.style.cursor = "text";
            textObject.render();
          }
        }
      } else if (textObject.rect.hovered) {
        textObject.rect.hovered = false;
        textObject.rectMoveIcon.fill = "transparent";
        textObject.rectDeleteIcon.fill = "transparent";
        c.style.cursor = "default";
        textObject.render();
      }
    };
    this.canvasObjects[textObject.id] = textObject;
    if (!this.historyEvent) {
      let { history } = this.state;
      if (history[this.state.historyPosition]) {
        history = history.slice(0, this.state.historyPosition);
      }
      history.push({
        redo: JSON.parse(JSON.stringify(textObject)),
        undo: { delete: textObject.id },
      });
      this.setState({
        history,
        historyPosition: this.state.historyPosition + 1,
      });
    } else {
      this.historyEvent = false;
    }
    textObject.render();
    const canvasRect = c.getBoundingClientRect();
    this.showTextarea(canvasRect, textObject);
  };

  createAutoAnnotation = (x, y, o, v, ctx, c, arrow) => {
    /// /Create auto annotation object which consists of Textbox, Line, and arrow or selection box
    const autoObject = {};
    autoObject.paramHistory = { x, y, o, v, arrow };
    autoObject.historyAction = "createAutoAnnotation";
    autoObject.id = Object.keys(this.canvasObjects).length + 1;
    let lineX1 =
      x +
      (o === "left"
        ? -(5 * this.resizedMultiplier)
        : 5 * this.resizedMultiplier);
    let lineY1 =
      y +
      (v === "top"
        ? 5 * this.resizedMultiplier
        : -(5 * this.resizedMultiplier));
    const rectHeight = 60 * this.resizedMultiplier;
    autoObject.orientation = o;
    autoObject.verticalOrientation = v;
    if (arrow) {
      autoObject.arrow = {
        x: lineX1 - 7 * this.resizedMultiplier,
        y: lineY1 - 7 * this.resizedMultiplier,
        width: 14 * this.resizedMultiplier,
        height: 14 * this.resizedMultiplier,
        hovered: false,
      };
    } else {
      if (this.tempBox.width < 0) {
        this.tempBox.x += this.tempBox.width;
        this.tempBox.width = -this.tempBox.width;
      }
      if (this.tempBox.height < 0) {
        this.tempBox.y += this.tempBox.height;
        this.tempBox.height = -this.tempBox.height;
      }
      autoObject.tempBoxHistory = { ...this.tempBox };
      autoObject.tempBoxStartXHistory = this.tempBoxStartX;
      autoObject.tempBoxStartYHistory = this.tempBoxStartY;

      autoObject.selectRect = {
        x: this.tempBox.x,
        y: this.tempBox.y,
        width: this.tempBox.width,
        height: this.tempBox.height,
        hovered: false,
      };
      autoObject.selectMoveIcon = {
        fill: "transparent",
        hovered: false,
      };
      autoObject.selectDeleteIcon = {
        fill: "transparent",
        hovered: false,
      };
      lineX1 =
        o === "left" ? this.tempBox.x : this.tempBox.x + this.tempBox.width;
      lineY1 = this.tempBox.y + this.tempBox.height / 2;
      this.tempBox = null;
      this.tempBoxStartX = null;
      this.tempBoxStartY = null;
    }
    const rectX =
      o === "left"
        ? this.imageX - 150 * this.resizedMultiplier
        : this.imageX + this.imageWidth + 10 * this.resizedMultiplier;
    const rectY =
      v === "top"
        ? (arrow
            ? y
            : autoObject.selectRect.y + autoObject.selectRect.height / 2) +
          40 * this.resizedMultiplier
        : (arrow
            ? y
            : autoObject.selectRect.y + autoObject.selectRect.height / 2) -
          100 * this.resizedMultiplier;
    autoObject.rect = {
      x: rectX,
      y: rectY,
      width: 140 * this.resizedMultiplier,
      height: rectHeight,
      hovered: false,
      text: "",
      textLines: [],
    };
    autoObject.rectMoveIcon = {
      fill: "transparent",
      hovered: false,
    };
    autoObject.rectDeleteIcon = {
      fill: "transparent",
      hovered: false,
    };
    autoObject.line = {
      x1: lineX1,
      y1: lineY1,
      x2:
        rectX +
        (autoObject.orientation === "left" ? 140 * this.resizedMultiplier : 0),
      y2: rectY + 30 * this.resizedMultiplier,
    };
    autoObject.render = () => {
      // arrow
      if (autoObject.arrow) {
        const headlen = 12 * this.resizedMultiplier;
        const dx = autoObject.line.x1 - autoObject.line.x2;
        const dy = autoObject.line.y1 - autoObject.line.y2;
        const angle = Math.atan2(dy, dx);
        ctx.beginPath();
        ctx.lineWidth = 3 * this.resizedMultiplier;
        ctx.strokeStyle = "#FF0000";
        ctx.moveTo(autoObject.line.x1, autoObject.line.y1);
        ctx.lineTo(
          autoObject.line.x1 - headlen * Math.cos(angle - Math.PI / 5),
          autoObject.line.y1 - headlen * Math.sin(angle - Math.PI / 5)
        );
        ctx.moveTo(autoObject.line.x1, autoObject.line.y1);
        ctx.lineTo(
          autoObject.line.x1 - headlen * Math.cos(angle + Math.PI / 5),
          autoObject.line.y1 - headlen * Math.sin(angle + Math.PI / 5)
        );
        ctx.lineCap = "round";
        ctx.stroke();
      } else {
        // select box
        ctx.beginPath();
        ctx.lineWidth = 2 * this.resizedMultiplier;
        ctx.strokeStyle = "red";
        ctx.rect(
          autoObject.selectRect.x,
          autoObject.selectRect.y,
          autoObject.selectRect.width,
          autoObject.selectRect.height
        );
        ctx.stroke();
        // select box move icon
        ctx.font = `${20 * this.resizedMultiplier}px Material Icons`;
        ctx.fillStyle = autoObject.selectMoveIcon.fill;
        ctx.fillText(
          "open_with",
          autoObject.selectRect.x +
            (autoObject.selectRect.width - 20 * this.resizedMultiplier),
          autoObject.selectRect.y + 20 * this.resizedMultiplier
        );
        ctx.fillStyle = autoObject.selectMoveIcon.fill;
        ctx.fillText(
          "delete",
          autoObject.selectRect.x +
            (autoObject.selectRect.width - 45 * this.resizedMultiplier),
          autoObject.selectRect.y + 20 * this.resizedMultiplier
        );
      }
      // line
      ctx.beginPath();
      ctx.moveTo(autoObject.line.x1, autoObject.line.y1);
      ctx.lineTo(autoObject.line.x2, autoObject.line.y2);
      ctx.lineWidth = 3 * this.resizedMultiplier;
      ctx.strokeStyle = "#FF0000";
      ctx.stroke();
      // box
      ctx.beginPath();
      ctx.lineWidth = "3";
      ctx.strokeStyle = "red";
      ctx.fillStyle = "white";
      ctx.rect(
        autoObject.rect.x,
        autoObject.rect.y,
        autoObject.rect.width,
        autoObject.rect.height
      );
      ctx.stroke();
      ctx.fill();
      // text
      if (autoObject.rect.textLines.length > 0) {
        ctx.font = `${14 * this.resizedMultiplier}px Arial`;
        ctx.fillStyle = "black";
        autoObject.rect.textLines.forEach((l, i) => {
          ctx.fillText(
            l,
            autoObject.rect.x + 2 * this.resizedMultiplier,
            autoObject.rect.y + (i + 1) * (16 * this.resizedMultiplier)
          );
        });
      }
      // move icon
      ctx.font = `${20 * this.resizedMultiplier}px Material Icons`;
      ctx.fillStyle = autoObject.rectMoveIcon.fill;
      ctx.fillText(
        "open_with",
        autoObject.rect.x +
          (autoObject.rect.width - 20 * this.resizedMultiplier),
        autoObject.rect.y + 20 * this.resizedMultiplier
      );
      // delete icon
      ctx.font = `${20 * this.resizedMultiplier}px Material Icons`;
      ctx.fillStyle = autoObject.rectDeleteIcon.fill;
      ctx.fillText(
        "delete",
        autoObject.rect.x +
          (autoObject.rect.width - 45 * this.resizedMultiplier),
        autoObject.rect.y + 20 * this.resizedMultiplier
      );
    };
    autoObject.hoverCheck = (x, y) => {
      ctx.beginPath();
      ctx.lineWidth = "3";
      ctx.rect(
        autoObject.rect.x,
        autoObject.rect.y,
        autoObject.rect.width,
        autoObject.rect.height
      );
      if (ctx.isPointInPath(x, y)) {
        if (!autoObject.rect.hovered) {
          autoObject.rect.hovered = true;
          autoObject.rectMoveIcon.fill = "gray";
          autoObject.rectDeleteIcon.fill = "gray";
          c.style.cursor = "text";
          autoObject.render();
        } else {
          ctx.beginPath();
          ctx.lineWidth = "3";
          ctx.rect(
            autoObject.rect.x +
              (autoObject.rect.width - 20 * this.resizedMultiplier),
            autoObject.rect.y + 5 * this.resizedMultiplier,
            15 * this.resizedMultiplier,
            15 * this.resizedMultiplier
          );
          /// ///////Check for move icon hover
          if (ctx.isPointInPath(x, y)) {
            if (!autoObject.rectMoveIcon.hovered) {
              autoObject.rectMoveIcon.hovered = true;
              c.style.cursor = "grab";
              autoObject.render();
              return;
            }
          } else if (autoObject.rectMoveIcon.hovered) {
            autoObject.rectMoveIcon.hovered = false;
            c.style.cursor = "text";
            autoObject.render();
            return;
          }
          ctx.beginPath();
          ctx.rect(
            autoObject.rect.x +
              (autoObject.rect.width - 45 * this.resizedMultiplier),
            autoObject.rect.y + 5 * this.resizedMultiplier,
            15 * this.resizedMultiplier,
            15 * this.resizedMultiplier
          );
          /// ///////Check for delete icon hover
          if (ctx.isPointInPath(x, y)) {
            if (!autoObject.rectDeleteIcon.hovered) {
              autoObject.rectDeleteIcon.hovered = true;
              c.style.cursor = "pointer";
              autoObject.render();
              return;
            }
          } else if (autoObject.rectDeleteIcon.hovered) {
            autoObject.rectDeleteIcon.hovered = false;
            c.style.cursor = "text";
            autoObject.render();
            return;
          }
        }
        return;
      }
      if (autoObject.rect.hovered) {
        autoObject.rect.hovered = false;
        autoObject.rectMoveIcon.fill = "transparent";
        autoObject.rectDeleteIcon.fill = "transparent";
        c.style.cursor = "default";
        autoObject.render();
        return;
      }
      /// ///Check For Arrow Hover
      if (autoObject.arrow) {
        ctx.beginPath();
        ctx.rect(
          autoObject.arrow.x,
          autoObject.arrow.y,
          autoObject.arrow.width,
          autoObject.arrow.height
        );
        if (ctx.isPointInPath(x, y)) {
          if (!autoObject.arrow.hovered) {
            autoObject.arrow.hovered = true;
            c.style.cursor = "grab";
          }
        } else if (autoObject.arrow.hovered) {
          autoObject.arrow.hovered = false;
          c.style.cursor = "default";
        }
      } else {
        this.checkSelectBoxHover(autoObject, x, y, c, ctx);
      }
    };
    this.canvasObjects[autoObject.id] = autoObject;
    if (!this.historyEvent) {
      let { history } = this.state;
      if (history[this.state.historyPosition]) {
        history = history.slice(0, this.state.historyPosition);
      }
      history.push({
        redo: JSON.parse(JSON.stringify(autoObject)),
        undo: { delete: autoObject.id },
      });
      this.setState({
        history,
        historyPosition: this.state.historyPosition + 1,
      });
    } else {
      this.historyEvent = false;
    }
    autoObject.render();
    const canvasRect = c.getBoundingClientRect();
    this.showTextarea(canvasRect, autoObject);
    // this.setState({textModalX, textModalY, textEditing: autoObject.id})
  };

  checkSelectBoxHover = (object, x, y, c, ctx) => {
    // USED FOR REGUALR SELECT BOXES AND AUTOANNOTATE SELECT BOXES
    ctx.beginPath();
    ctx.lineWidth = 12 * this.resizedMultiplier;
    ctx.rect(
      object.selectRect.x - 4 * this.resizedMultiplier,
      object.selectRect.y - 4 * this.resizedMultiplier,
      object.selectRect.width + 8 * this.resizedMultiplier,
      object.selectRect.height + 8 * this.resizedMultiplier
    );
    /// /////CHECK FOR SELECT BOX HOVER
    if (ctx.isPointInPath(x, y)) {
      if (!object.selectRect.hovered) {
        object.selectRect.hovered = true;
        object.selectMoveIcon.fill = "gray";
        object.render();
        /// ///////CHECK FOR SELECT BOX BORDER OVER
      } else if (ctx.isPointInStroke(x, y)) {
        ctx.beginPath();
        ctx.moveTo(object.selectRect.x, object.selectRect.y);
        ctx.lineTo(
          object.selectRect.x + object.selectRect.width,
          object.selectRect.y
        );
        /// /////////CHECK TOP LEFT, TOP, AND TOP RIGHT BORDER
        if (ctx.isPointInStroke(x, y)) {
          if (x <= object.selectRect.x + 8 * this.resizedMultiplier) {
            if (c.style.cursor !== "nwse-resize") {
              c.style.cursor = "nwse-resize";
              this.resizeDirection = "left-top";
            }
          } else if (
            x >=
            object.selectRect.x +
              object.selectRect.width -
              8 * this.resizedMultiplier
          ) {
            if (c.style.cursor !== "nesw-resize") {
              c.style.cursor = "nesw-resize";
              this.resizeDirection = "right-top";
            }
          } else if (c.style.cursor !== "ns-resize") {
            c.style.cursor = "ns-resize";
            this.resizeDirection = "top";
          }
          return;
        }
        /// /////////CHECK RIGHT BORDER
        ctx.beginPath();
        ctx.moveTo(
          object.selectRect.x + object.selectRect.width,
          object.selectRect.y + 4 * this.resizedMultiplier
        );
        ctx.lineTo(
          object.selectRect.x + object.selectRect.width,
          object.selectRect.y +
            (object.selectRect.height - 8 * this.resizedMultiplier)
        );
        if (ctx.isPointInStroke(x, y)) {
          if (c.style.cursor !== "ew-resize") {
            c.style.cursor = "ew-resize";
            this.resizeDirection = "right";
          }
          return;
        }
        /// /////////CHECK BOTTOM LEFT, BOTTOM, AND BOTTOM RIGHT BORDER
        ctx.beginPath();
        ctx.moveTo(
          object.selectRect.x + object.selectRect.width,
          object.selectRect.y + object.selectRect.height
        );
        ctx.lineTo(
          object.selectRect.x,
          object.selectRect.y + object.selectRect.height
        );
        if (ctx.isPointInStroke(x, y)) {
          if (x <= object.selectRect.x + 8 * this.resizedMultiplier) {
            if (c.style.cursor !== "nesw-resize") {
              c.style.cursor = "nesw-resize";
              this.resizeDirection = "left-bottom";
            }
          } else if (
            x >=
            object.selectRect.x +
              object.selectRect.width -
              8 * this.resizedMultiplier
          ) {
            if (c.style.cursor !== "nwse-resize") {
              c.style.cursor = "nwse-resize";
              this.resizeDirection = "right-bottom";
            }
          } else if (c.style.cursor !== "ns-resize") {
            c.style.cursor = "ns-resize";
            this.resizeDirection = "bottom";
          }
          return;
        }
        /// /////////CHECK BOTTOM BORDER
        ctx.beginPath();
        ctx.moveTo(
          object.selectRect.x,
          object.selectRect.y + 4 * this.resizedMultiplier
        );
        ctx.lineTo(
          object.selectRect.x,
          object.selectRect.y +
            (object.selectRect.height - 8 * this.resizedMultiplier)
        );
        if (ctx.isPointInStroke(x, y)) {
          if (c.style.cursor !== "ew-resize") {
            c.style.cursor = "ew-resize";
            this.resizeDirection = "left";
          }
        }
        /// /////////CHECK SELECT BOX MOVE ICON HOVER
      } else {
        ctx.beginPath();
        ctx.rect(
          object.selectRect.x +
            (object.selectRect.width - 20 * this.resizedMultiplier),
          object.selectRect.y + 5 * this.resizedMultiplier,
          15 * this.resizedMultiplier,
          15 * this.resizedMultiplier
        );
        const moveHover = ctx.isPointInPath(x, y);
        ctx.beginPath();
        ctx.rect(
          object.selectRect.x +
            (object.selectRect.width - 45 * this.resizedMultiplier),
          object.selectRect.y + 5 * this.resizedMultiplier,
          15 * this.resizedMultiplier,
          15 * this.resizedMultiplier
        );
        const deleteHover = ctx.isPointInPath(x, y);
        if (moveHover) {
          if (!object.selectMoveIcon.hovered) {
            object.selectMoveIcon.hovered = true;
            c.style.cursor = "grab";
            this.resizeDirection = null;
            object.render();
          }
        } else if (object.selectMoveIcon.hovered) {
          object.selectMoveIcon.hovered = false;
          c.style.cursor = "default";
          object.render();
        } else if (deleteHover) {
          if (!object.selectDeleteIcon.hovered) {
            object.selectDeleteIcon.hovered = true;
            c.style.cursor = "pointer";
            this.resizeDirection = null;
            object.render();
          }
        } else if (object.selectDeleteIcon.hovered) {
          object.selectDeleteIcon.hovered = false;
          c.style.cursor = "default";
          object.render();
        } else if (c.style.cursor !== "default") {
          c.style.cursor = "default";
          this.resizeDirection = null;
        }
      }
    } else if (object.selectRect.hovered) {
      object.selectRect.hovered = false;
      object.selectMoveIcon.fill = "transparent";
      c.style.cursor = "default";
      this.resizeDirection = null;
      // this one needs whole canvas redraw since select box has no fill
      this.redrawCanvas(ctx, c);
    }
  };

  showTextarea = (canvasRect, object) => {
    /// /Create textarea element with same dimensions as text box and place on top
    this.textEditing = true;
    this.openTextElem = document.createElement("textarea");
    this.openTextElem.style.display = "block";
    this.openTextElem.style.position = "fixed";
    this.openTextElem.style.left = `${
      canvasRect.x + object.rect.x / this.resizedMultiplier
    }px`;
    this.openTextElem.style.top = `${
      canvasRect.y + object.rect.y / this.resizedMultiplier
    }px`;
    this.openTextElem.style.width = `${
      object.rect.width / this.resizedMultiplier
    }px`;
    this.openTextElem.style.height = `${
      object.rect.height / this.resizedMultiplier
    }px`;
    this.openTextElem.style.zIndex = "1000000";
    this.openTextElem.style.outline = "none";
    this.openTextElem.style.overflow = "hidden";
    this.openTextElem.style.padding = "2px";
    this.openTextElem.style.border = "none";
    this.openTextElem.style.fontFamily = "Arial";
    this.openTextElem.style.fontSize = "14px";
    this.openTextElem.setAttribute("id", "object-textarea");
    this.openTextElem.setAttribute("data-id", object.id);
    this.openTextElem.addEventListener("blur", this.textAreaBlur);
    this.openTextElem.addEventListener("input", this.textAreaChange);
    document.querySelector("body").appendChild(this.openTextElem);
    object.rectMoveIcon.fill = "transparent";
    if (object.rect.text) {
      this.openTextElem.value = object.rect.text;
    }
    this.editStartText = object.rect.text ? object.rect.text : "";
    setTimeout(() => {
      this.openTextElem.focus();
    }, 100);
  };

  redrawCanvas = (ctx, c) => {
    ctx.clearRect(0, 0, c.width, c.height);
    Object.values(this.canvasObjects).forEach((o) => {
      o.render();
    });
    if (this.tempBox) {
      ctx.beginPath();
      ctx.lineWidth = 3 * this.resizedMultiplier;
      ctx.strokeStyle = "red";
      if (!this.tempBox.arrow) {
        ctx.rect(
          this.tempBox.x,
          this.tempBox.y,
          this.tempBox.width,
          this.tempBox.height
        );
      } else {
        ctx.moveTo(this.tempBox.x, this.tempBox.y);
        ctx.lineTo(
          this.tempBox.x + this.tempBox.width,
          this.tempBox.y + this.tempBox.height
        );
      }
      ctx.stroke();
    }
  };

  deleteAnimation = (percent, ctx, c) => {
    if (this.objectDelete) {
      this.redrawCanvas(ctx, c);
      const circ = Math.PI * 2;
      const quart = Math.PI / 2;
      const radius = 12 * this.resizedMultiplier;
      ctx.beginPath();
      ctx.lineWidth = 3 * this.resizedMultiplier;
      ctx.strokeStyle = "#519acc";
      ctx.arc(
        this.objectDelete.x,
        this.objectDelete.y,
        radius,
        -quart,
        circ * percent - quart,
        false
      );
      ctx.stroke();
      this.objectDelete.currentPercent = this.objectDelete.currentPercent + 3.3;
      if (this.objectDelete.currentPercent <= 115) {
        requestAnimationFrame(() => {
          this.deleteAnimation(this.objectDelete.currentPercent / 100, ctx, c);
        });
      } else {
        let { history } = this.state;
        if (history[this.state.historyPosition]) {
          history = history.slice(0, this.state.historyPosition);
        }
        history.push({
          redo: { delete: this.objectDelete.id },
          undo: JSON.parse(
            JSON.stringify(this.canvasObjects[this.objectDelete.id])
          ),
        });
        this.setState({
          history,
          historyPosition: this.state.historyPosition + 1,
        });
        delete this.canvasObjects[this.objectDelete.id];
        this.objectDelete = false;
        c.style.cursor = "default";
        this.redrawCanvas(ctx, c);
      }
    } else {
      this.redrawCanvas(ctx, c);
    }
  };

  saveImage() {
    this.props.setBackdropLoader(true);
    const self = this;
    let currentHeight = this.imageHeight;
    let currentWidth = this.imageWidth;
    let currentX = this.imageX;
    let currentY = this.imageY;
    Object.values(this.canvasObjects).forEach((o) => {
      if (o.rect) {
        if (o.rect.x < currentX) {
          currentWidth += currentX - o.rect.x;
          currentX = o.rect.x;
        }
        if (o.rect.x + o.rect.width > currentX + currentWidth) {
          currentWidth += o.rect.x + o.rect.width - (currentX + currentWidth);
        }
        if (o.rect.y < currentY) {
          currentHeight += currentY - o.rect.y;
          currentY = o.rect.y;
        }
        if (o.rect.y + o.rect.height > currentY + currentHeight) {
          currentHeight +=
            o.rect.y + o.rect.height - (currentY + currentHeight);
        }
      }
    });
    currentHeight += 20 * this.resizedMultiplier;
    currentWidth += 20 * this.resizedMultiplier;
    currentX -= 10 * this.resizedMultiplier;
    currentY -= 10 * this.resizedMultiplier;
    const topLayer = document.getElementById("object-canvas");
    const imageLayer = document.getElementById("image-canvas");
    const baseLayer = document.getElementById("base-canvas");
    const ctx = baseLayer.getContext("2d");
    ctx.drawImage(imageLayer, 0, 0);
    ctx.drawImage(topLayer, 0, 0);
    const newCanvas = document.createElement("canvas");
    newCanvas.width = currentWidth;
    newCanvas.height = currentHeight;
    const newCtx = newCanvas.getContext("2d");
    newCtx.drawImage(
      baseLayer,
      currentX,
      currentY,
      currentWidth,
      currentHeight,
      0,
      0,
      currentWidth,
      currentHeight
    );
    const image = newCanvas.toDataURL();
    const attachment = this.state.images[this.state.currentIndex];
    let url;
    if (this.props.mediaItem) {
      url = `/media_items/${attachment.id}.json`;
    } else {
      url = `/ticket_attachments/${attachment.id}.json`;
    }
    axios
      .patch(
        url,
        {
          base_sixty_four_image: image,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          },
        }
      )
      .then((res) => {
        M.toast({
          html: "Attachment Updated",
          displayLength: 3000,
          classes: "green",
        });
        self.setState({
          edit: false,
          images: res.data,
          autoSelectMode: false,
          shapeMode: false,
          history: [],
          historyPosition: 0,
        });
        self.props.setBypassClickCheck(true);
        self.props.setBackdropLoader(false);
        self.props.data.handleNewImages(res.data);
        self.createMaterial();
      })
      .catch((err) => {
        // Swal.fire('There was an error editing the attachment', err);
        self.props.setBackdropLoader(false);
        console.log(err);
      });
    // }
  }

  destroyMaterial() {
    if (this.instances && this.instances.length >= 1) {
      this.instances[0].el.style.opacity = 0;
      setTimeout(() => {
        if (this.instances[0]) {
          this.instances[0].destroy();
        }
        this.instances = [];
      }, 200);
    }
  }

  createMaterial() {
    const elems = document.querySelectorAll(".materialboxed");
    const options = {};
    if (elems.length >= 1) {
      this.instances = M.Materialbox.init(elems, options);
      for (const item of elems) {
        item.style.maxHeight = "100%";
        item.style.maxWidth = "100%";
      }
    }
  }

  componentDidMount() {
    this.props.setBypassClickCheck(true);
    this.createMaterial();
    setTimeout(() => {
      const elem = document.querySelector(".ticket-attachments-modal");
      if (elem) {
        this.setState({
          modalWidth: elem.scrollWidth - 160,
          modalHeight: elem.scrollHeight - 170,
        });
      }
    }, 500);
  }

  componentWillUnmount() {
    this.props.setBypassClickCheck(false);
    this.destroyMaterial();
    const elem = document.querySelector(".new-modal");
    if (elem) {
      elem.style.height = "auto";
      if (elem.firstChild) {
        elem.firstChild.style.height = "auto";
      }
      elem.style.border = "none";
    }
  }

  changeImage = async (e) => {
    let newIndex = this.state.currentIndex + parseInt(e.target.dataset.number);
    newIndex =
      newIndex === -1
        ? this.state.images.length - 1
        : newIndex === this.state.images.length
        ? 0
        : newIndex;
    this.refs.img.style.opacity = "0";
    this.destroyMaterial();
    await Sleep(200);
    this.setState({ currentIndex: newIndex }, async () => {
      await Sleep(100);
      this.createMaterial();
      this.refs.img.style.opacity = "1";
    });
  };

  render() {
    const undoHidden =
      this.state.history.length === 0 || this.state.historyPosition === 0;
    const redoHidden =
      this.state.history.length === 0 ||
      this.state.historyPosition === this.state.history.length;
    const image = this.state.images[this.state.currentIndex].image_content_type
      ? this.state.images[this.state.currentIndex].image_content_type.includes(
          "image"
        )
      : false;

    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            height: "80px",
            padding: "0 55px",
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              padding: "30px 40px 0px 0px",
              height: "80px",
              lineHeight: "18px",
              textAlign: "left",
              display: "block",
              fontSize: "16px",
              position: "absolute",
              left: "54px",
            }}
          >
            <span
              style={{
                display: "block",
                fontWeight: "600",
                marginBottom: "8px",
              }}
            >
              {this.state.images[this.state.currentIndex].image_file_name}
            </span>
            {this.state.images[this.state.currentIndex].user_name && (
              <span style={{ display: "block", fontSize: "12pt" }}>
                Added by{" "}
                <span style={{ color: "#519acc" }}>
                  {this.state.images[this.state.currentIndex].user_name}
                </span>
              </span>
            )}
          </span>
          {this.state.edit && (
            <>
              <div
                id="editor-toolbar"
                style={{
                  margin: "auto",
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: "0",
                }}
              >
                <button
                  style={{
                    opacity: undoHidden ? "0.5" : "1",
                    pointerEvents: undoHidden ? "none" : "all",
                  }}
                  onClick={this.undo}
                  className="z-depth-1"
                >
                  <div
                    className="non-toggle-button"
                    style={{
                      width: "36px",
                      height: "35px",
                      position: "relative",
                      top: "1px",
                      left: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      backgroundColor: "#519acc",
                    }}
                  >
                    <UndoIcon
                      width={20}
                      height={20}
                      svgStyles={{ position: "relative", top: "-1px" }}
                    />
                  </div>
                </button>
                <div className="button-group z-depth-1">
                  <div
                    className="non-toggle-button"
                    style={{
                      width: "36px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      backgroundColor: "#519acc",
                    }}
                  >
                    <button className="link-btn" onClick={this.rotateImage}>
                      <RotateIcon
                        svgStyles={{ position: "relative", top: "1px" }}
                        width={20}
                        height={20}
                      />
                    </button>
                  </div>
                  <div
                    style={{
                      width: "36px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      backgroundColor: this.state.autoSelectMode
                        ? "#3b7ca9"
                        : "#519acc",
                    }}
                  >
                    <button className="link-btn" onClick={this.autoTextBox}>
                      <PointTextBoxIcon
                        svgStyles={{
                          position: "relative",
                          bottom: "2px",
                          left: "-3px",
                        }}
                        width={25}
                        height={25}
                      />
                    </button>
                  </div>
                  <div
                    className="non-toggle-button"
                    style={{
                      width: "36px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      backgroundColor: "#519acc",
                    }}
                  >
                    <button className="link-btn" onClick={this.addText}>
                      <TextBoxIcon
                        svgStyles={{ position: "relative", top: "1px" }}
                        width={19}
                        height={19}
                      />
                    </button>
                  </div>

                  <div
                    style={{
                      width: "36px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      backgroundColor: this.state.shapeMode
                        ? "#3b7ca9"
                        : "#519acc",
                    }}
                  >
                    <button className="link-btn" onClick={this.addBox}>
                      <ShapeIcon
                        svgStyles={{ position: "relative", top: "2px" }}
                        width={20}
                        height={20}
                      />
                    </button>
                  </div>
                  <div
                    style={{
                      width: "36px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      backgroundColor: this.state.arrowMode
                        ? "#3b7ca9"
                        : "#519acc",
                    }}
                  >
                    <button className="link-btn" onClick={this.addArrow}>
                      <ArrowIcon
                        svgStyles={{ position: "relative", top: "2px" }}
                        width={17}
                        height={17}
                      />
                    </button>
                  </div>
                </div>

                <button
                  style={{
                    opacity: redoHidden ? "0.5" : "1",
                    pointerEvents: redoHidden ? "none" : "all",
                  }}
                  className="z-depth-1"
                  onClick={this.redo}
                >
                  <div
                    className="non-toggle-button"
                    style={{
                      width: "36px",
                      height: "35px",
                      position: "relative",
                      top: "1px",
                      left: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      backgroundColor: "#519acc",
                    }}
                  >
                    <RedoIcon
                      width={20}
                      height={20}
                      svgStyles={{ position: "relative", top: "-1px" }}
                    />
                  </div>
                </button>
              </div>
              <button
                style={{
                  marginRight: "0px",
                  marginTop: "auto",
                  height: "52px",
                  width: "120px",
                  position: "absolute",
                  right: "225px",
                  bottom: "0px",
                }}
                value="cancel"
                className="common-button-cancel"
                onClick={this.cancelEdit}
              >
                Cancel
              </button>
            </>
          )}

          {image && this.props.data.hideEdit == undefined && (
            <button
              className="common-button-submit"
              style={{
                minWidth: "80px",
                marginTop: "auto",
                height: "52px",
                width: "160px",
                position: "absolute",
                right: "54px",
                bottom: "0px",
              }}
              onClick={this.state.edit ? this.saveImage : this.toggleEdit}
            >
              {this.state.edit ? "Save Changes" : "Edit"}
            </button>
          )}
        </div>
        <div
          style={{
            padding: "0px 42px 40px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100% - 95px)",
          }}
        >
          {(this.state.autoSelectMode ||
            this.state.shapeMode ||
            this.state.arrowMode) && (
            <p
              style={{
                position: "absolute",
                left: "0",
                right: "0",
                top: "-90px",
                textAlign: "center",
              }}
            >
              {this.state.autoSelectMode
                ? "Click on or drag over area you would like to annotate."
                : this.state.shapeMode
                ? "Click and drag on image to create selection box"
                : "Click and drag to create arrow"}
            </p>
          )}
          <button
            className="link-btn"
            style={{
              transition: "opacity .33s",
              opacity: `${this.state.edit ? "0" : "1"}`,
              pointerEvents: `${this.state.edit ? "none" : "all"}`,
            }}
            data-number={-1}
            onClick={this.changeImage}
          >
            <i
              data-number={-1}
              className="material-icons"
              style={{
                display: this.state.images.length > 1 ? "block" : "none",
                position: "absolute",
                left: "5px",
                fontSize: "40px",
                cursor: "pointer",
              }}
            >
              keyboard_arrow_left
            </i>
          </button>
          <div
            ref="img"
            className="attachment-modal-image"
            style={{
              transition: "opacity 0.2s",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.images[this.state.currentIndex] &&
            this.state.images[this.state.currentIndex].image_content_type &&
            this.state.images[
              this.state.currentIndex
            ].image_content_type.includes("video") ? (
              <video style={{ maxWidth: "100%", maxHeight: "100%" }} controls>
                <source
                  src={this.state.images[this.state.currentIndex].image_url}
                />
              </video>
            ) : (
              <>
                {this.state.edit ? (
                  <div
                    id="image-editor-container"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <canvas
                      style={{ height: "100%", width: "100%" }}
                      id="base-canvas"
                    />
                    <canvas
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        height: "100%",
                        width: "100%",
                      }}
                      id="image-canvas"
                    />
                    <canvas
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        height: "100%",
                        width: "100%",
                      }}
                      id="object-canvas"
                    />
                  </div>
                ) : (
                  <img
                    className="materialboxed"
                    alt="ticket attached"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    src={this.state.images[this.state.currentIndex].image_url}
                  />
                )}
              </>
            )}
          </div>
          <button
            className="link-btn"
            disabled={this.state.edit}
            data-number={1}
            onClick={this.changeImage}
          >
            <i
              data-number={1}
              className="material-icons"
              style={{
                transition: "opacity .33s",
                opacity: `${this.state.edit ? "0" : "1"}`,
                pointerEvents: `${this.state.edit ? "none" : "all"}`,
                display: this.state.images.length > 1 ? "block" : "none",
                position: "absolute",
                right: "5px",
                fontSize: "40px",
                cursor: "pointer",
              }}
            >
              keyboard_arrow_right
            </i>
          </button>
          {this.state.images.length > 1 && (
            <div
              id="dots-container"
              style={{
                position: "absolute",
                bottom: "6px",
                whiteSpace: "nowrap",
                display: this.state.edit ? "none" : "block",
              }}
            >
              {this.state.images.map((i, index) => {
                return (
                  <div
                    key={`${i.id}-dot`}
                    style={{
                      display: "inline-block",
                      height: "10px",
                      width: "10px",
                      transition: "background-color 0.3s",
                      backgroundColor:
                        index === this.state.currentIndex
                          ? "#519acc"
                          : "lightgray",
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default TicketAttachments;
