import React from 'react'

class QRCodeIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44 44"
      >
        <g id="Layer_3" data-name="Layer 3">
          <path d="M.23.23v12.7h12.7V.23ZM9.3,9.3H3.86V3.86H9.3Z"/>
          <path d="M.23,31.07v12.7h12.7V31.07ZM9.3,40.14H3.86V34.7H9.3Z"/>
          <path d="M31.07.23v12.7h12.7V.23ZM40.14,9.3H34.7V3.86h5.44Z"/>
          <polygon points="40.14 16.56 40.14 27.44 31.07 27.44 31.07 31.07 43.77 31.07 43.77 16.56 40.14 16.56"/>
          <polygon points="31.07 34.7 31.07 43.77 34.7 43.77 34.7 38.33 40.14 38.33 40.14 43.77 43.77 43.77 43.77 34.7 31.07 34.7"/>
          <polygon points="16.56 0.23 16.56 3.86 23.81 3.86 23.81 12.93 27.44 12.93 27.44 0.23 16.56 0.23"/>
          <polygon points="23.81 16.56 23.81 23.82 16.56 23.82 16.56 34.7 23.81 34.7 23.81 43.77 27.44 43.77 27.44 31.07 20.19 31.07 20.19 27.44 27.44 27.44 27.44 20.19 31.07 20.19 31.07 23.82 34.7 23.82 34.7 16.56 23.81 16.56"/>
          <rect x="16.56" y="38.33" width="3.63" height="5.44"/>
          <rect x="7.49" y="23.81" width="5.44" height="3.63"/>
          <polygon points="16.56 7.49 16.56 16.56 0.23 16.56 0.23 27.44 3.86 27.44 3.86 20.19 20.19 20.19 20.19 7.49 16.56 7.49"/>
        </g>
      </svg>
    );
  }
}
QRCodeIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default QRCodeIcon