import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import ApiClient from "../../../../utils/ApiClient";
import { wikiAssessmentTypes } from "../../../../types/wiki";
import { SubmitButton, CancelButton } from "../../../common/modal_buttons";
import {
  StyledModalBody,
  StyledModalContent,
  StyledModalHeader,
  ButtonsControl,
} from "../AssessmentDetails/form";
import LoadingSpinner from "../../../common/loading_spinner";
import UserInvites, { UserList, UserDisplay } from "./UserInvites";
import TeamInvite from "./TeamInvite";

const InviteUsersForm = ({
  assessment,
  toggleModal,
  publish,
  updateAssessmentData,
}) => {
  const [userInvite, setUserInvite] = useState(true);
  const [loading, setLoading] = useState(false);
  const [usersToInvite, setUsersToInvite] = useState([]);
  const [teamToInvite, setTeamToInvite] = useState({});

  const handleClose = () => {
    toggleModal();
  };

  const handleUsersUpdate = (user) => {
    const nextList = [...usersToInvite].filter((u) => u.id !== user.id);
    nextList.push(user);
    setUsersToInvite(nextList);
  };

  const removeUser = (user) => {
    const nextList = [...usersToInvite].filter((u) => u.id !== user.id);
    setUsersToInvite(nextList);
  };

  const handleTeamSelect = (team) => {
    setTeamToInvite(team);
  };

  const handleRequestInvites = async () => {
    try {
      setLoading(true);
      const api = new ApiClient();
      if (publish && !assessment.published) {
        // post request to publish assessment first, then handle invites
        const response = await api.post(
          `/wiki-assessments/${assessment.id}/publish`
        );
        updateAssessmentData(response.data);

        // if user has not invited any users or team, prevent request and close
        if (usersToInvite.length === 0 && !teamToInvite?.id) {
          handleClose();
          return null;
        }
      }

      const body = {};
      if (userInvite) {
        body.user_ids = usersToInvite.map((u) => u.id);
      } else {
        body.team_id = teamToInvite.id;
      }
      const response = await api.post(
        `/wiki-assessments/${assessment.id}/invite`,
        body
      );
      if (response.status === 200) {
        // eslint-disable-next-line no-undef
        M.toast({ html: "Assessment invitations sent.", classes: "green" });
        setUsersToInvite([]);
        updateAssessmentData(response.data);
      }
    } catch (error) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "There was an error sending invitations.",
        classes: "red darken-1 error-toast",
      });
      setUsersToInvite([]);
    } finally {
      setLoading(false);
    }

    return null;
  };

  const handleCancel = () => {
    handleClose();
  };

  const isSubmitDisabled = () => {
    // keep submit enabled if assessment is not published, allow for submission to publish w/o invites
    if (publish && !assessment.published) {
      return false;
    }

    if (userInvite) {
      return usersToInvite.length === 0;
    }
    return !teamToInvite?.id;
  };

  return (
    <StyledModalBody>
      <StyledModalHeader
        title={publish ? "Publish Assessment" : "Manage Participants"}
        subTitle={
          publish && "Select users to invite to participate in the assessment."
        }
        showHeaderImg={false}
        modalAction={handleClose}
        data={{ customClass: "manage-participants-modal-header" }}
      />
      <ManageParticipantsModalContent>
        <InviteToggles>
          <InviteToggle
            $active={userInvite}
            onClick={() => setUserInvite(true)}
            type="button"
          >
            Invite By Email
          </InviteToggle>
          <InviteToggle
            $active={!userInvite}
            onClick={() => setUserInvite(false)}
            type="button"
          >
            Invite By Team
          </InviteToggle>
        </InviteToggles>
        <InviteSection>
          {userInvite ? (
            <UserInvites
              handleUsersUpdate={handleUsersUpdate}
              handleRemoveUser={removeUser}
              assessment={assessment}
              currentList={usersToInvite}
            />
          ) : (
            <TeamInvite handleTeamSelect={handleTeamSelect} />
          )}
        </InviteSection>
        <ExistingInvitesSection>
          <ExistingTitle>Existing Participants</ExistingTitle>
          {assessment?.invitations && (
            <ExistingInvites>
              <UserList style={{ marginTop: 0 }}>
                {userInvite &&
                  assessment.invitations.map((invite) => {
                    if (invite && invite.user) {
                      return (
                        <UserDisplay key={invite.id}>
                          {invite.user.name}
                        </UserDisplay>
                      );
                    }

                    return null;
                  })}
                {!userInvite &&
                  assessment.invitations.map((invite) => {
                    if (invite && invite.team) {
                      return (
                        <UserDisplay key={invite.id}>
                          {invite.team.name}
                        </UserDisplay>
                      );
                    }

                    return null;
                  })}
              </UserList>
            </ExistingInvites>
          )}
        </ExistingInvitesSection>
        <ButtonsControl>
          <CancelButton
            type="button"
            className="common-button common-button-cancel"
            onClick={handleCancel}
          >
            Cancel
          </CancelButton>
          <SendButton
            type="button"
            className="common-button common-button-submit"
            onClick={handleRequestInvites}
            disabled={isSubmitDisabled()}
          >
            <ButtonContent>
              <ButtonSpinner loading={loading} size={13} />
              <ButtonText>{publish ? "Publish" : "Send Invites"}</ButtonText>
            </ButtonContent>
          </SendButton>
        </ButtonsControl>
      </ManageParticipantsModalContent>
    </StyledModalBody>
  );
};

const ManageParticipantsModalContent = styled(StyledModalContent)`
  overflow: auto;
`;

const InviteToggles = styled.div`
  display: flex;
`;

const toggleStyles = css`
  background-color: ${(props) => (props.$active ? "#519acc" : "#fff")};
  color: ${(props) => (props.$active ? "#fff" : "#519acc")};
  border-radius: 20px;
  padding: 4px 16px;
  border: 1px solid #519acc;
  margin-right: 8px;
  font-weight: 600;
  font-family: "Manrope";
  line-height: 17px;
  font-size: 12px;
  cursor: pointer;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: background-color, color;
`;

const InviteToggle = styled.button`
  ${toggleStyles}

  &:focus {
    ${toggleStyles}
  }
`;

const InviteSection = styled.div``;

const ExistingInvitesSection = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const ExistingTitle = styled.div`
  font-family: "Manrope";
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 8px;
  color: #333333;
`;

const ExistingInvites = styled.div`
  display: flex;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`;

const SendButton = styled(SubmitButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 24px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
`;

const ButtonSpinner = styled(LoadingSpinner)`
  position: static;
  margin-right: 6px;
`;

const ButtonText = styled.div`
  font-size: 14px;
`;

InviteUsersForm.propTypes = {
  assessment: wikiAssessmentTypes.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateAssessmentData: PropTypes.func.isRequired,
  publish: PropTypes.bool,
};

InviteUsersForm.defaultProps = {
  publish: false,
};

export default InviteUsersForm;
