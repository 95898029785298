import React from "react";

const TestCasesSilverSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Test_Cases_-_Silver_-_Small_svg__cls-1{fill:#939598}.Skills__Test_Cases_-_Silver_-_Small_svg__cls-2{fill:#bcbec0}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Test_Cases_-_Silver_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <rect
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          x={252.43}
          y={118.74}
          width={557.39}
          height={832.9}
          rx={254.7}
        />{" "}
        <rect
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-2"
          x={252.08}
          y={104.81}
          width={557.39}
          height={832.9}
          rx={254.7}
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          d="M605.18 341.22H457.72l70.52-157.48 76.94 157.48zM455.21 819.12h151.48v53.35c0 6.52-6 11.85-13.34 11.85h-124.8c-7.34 0-13.34-5.33-13.34-11.85z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          d="M581.48 322.07a25.21 25.21 0 0125.21 25.21v382h-50.42v-382a25.21 25.21 0 0125.21-25.21z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          d="M531.45 322.07a25.21 25.21 0 0125.21 25.21v382h-50.42v-382a25.21 25.21 0 0125.21-25.21z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          d="M481.28 322.07a25.21 25.21 0 0125.21 25.21v382h-50.42v-382a25.2 25.2 0 0125.21-25.21z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          d="M455.64 729.18h151.05v89.94H455.64z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          d="M561.93 729.22h11.62v89.89h-11.62zM528.24 183.74L511.69 220a40.45 40.45 0 0016.55 3.35c8.31 0 18.08-3.35 18.08-3.35z"
        />{" "}
        <path
          fill="#ffe68a"
          d="M605.18 329.61H457.72l70.52-157.48 76.94 157.48z"
        />{" "}
        <path
          d="M455.21 807.51h151.48v53.35c0 6.52-6 11.85-13.34 11.85h-124.8c-7.34 0-13.34-5.33-13.34-11.85z"
          fill="#ff74af"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-1"
          d="M556.27 310.45h50.42v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 581.48 514.04)"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Silver_-_Small_svg__cls-2"
          d="M506.24 310.45h50.42v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 531.45 514.04)"
        />{" "}
        <path
          d="M456.07 310.45h50.42v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 481.275 514.04)"
          fill="#d4d5d6"
        />{" "}
        <path
          transform="rotate(180 531.165 762.54)"
          fill="#5c5c5c"
          d="M455.64 717.57h151.05v89.94H455.64z"
        />{" "}
        <path
          transform="rotate(180 567.745 762.55)"
          fill="#e0e0e0"
          d="M561.93 717.6h11.62v89.89h-11.62z"
        />{" "}
        <path
          d="M528.24 172.13l-16.55 36.29a40.45 40.45 0 0016.55 3.35c8.31 0 18.08-3.35 18.08-3.35z"
          fill="#242424"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default TestCasesSilverSmall;
