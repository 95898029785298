import React from "react";
import { useFieldArray } from "react-hook-form";
import styled from "styled-components";
import QuestionEditor from "./QuestionEditor";
import { IntroContent } from "./IntroEditor";

const QuestionsController = () => {
  const { fields } = useFieldArray({ name: "questions" });

  return (
    <QuestionsControllerContent>
      {fields &&
        fields
          .sort((a, b) => a.position - b.position)
          .map((field) => {
            return (
              <QuestionItem key={field.id}>
                <QuestionEditor field={field} position={field.position} />
              </QuestionItem>
            );
          })}
    </QuestionsControllerContent>
  );
};

const QuestionsControllerContent = styled.div`
  position: relative;
`;

const QuestionItem = styled.div`
  transition: all 0.5s ease-in-out;

  &:last-child {
    ${IntroContent} {
      &::after {
        top: 2px;
        border-left-style: dotted;
      }
    }
  }
`;

export default QuestionsController;
