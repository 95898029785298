import React from 'react'
import moment from 'moment'
import {badgeIcons} from '../common/icons'
import styled from 'styled-components'

const BadgeContainer = styled.div`
    width: 250px;
    height: 300px;
    padding: 20px;
    text-align: center;
  `;
class AccountBadges extends React.PureComponent {
  constructor(props){
    super(props)
    this.badges = {
      "bugs_bronze":[<badgeIcons.BugsBronze className="icons badge" />,<badgeIcons.BugsBase className="icons badge" />],
      "bugs_silver":[<badgeIcons.BugsSilver className="icons badge" />, <badgeIcons.BugsBase className="icons badge" />],
      "bugs_gold":[<badgeIcons.BugsGold className="icons badge" />, <badgeIcons.BugsBase className="icons badge" />],
      "test_pass_bronze":[<badgeIcons.TestPassBronze className="icons badge" />, <badgeIcons.TestPassBase className="icons badge" />],
      "test_pass_silver":[<badgeIcons.TestPassSilver className="icons badge" />, <badgeIcons.TestPassBase className="icons badge" />],
      "test_pass_gold":[<badgeIcons.TestPassGold className="icons badge" />, <badgeIcons.TestPassBase className="icons badge" />],
      "test_cases_bronze":[<badgeIcons.TestCasesBronze className="icons badge" />, <badgeIcons.TestCasesBase className="icons badge" />],
      "test_cases_silver":[<badgeIcons.TestCasesSilver className="icons badge" />, <badgeIcons.TestCasesBase className="icons badge" />],
      "test_cases_gold":[<badgeIcons.TestCasesGold className="icons badge" />, <badgeIcons.TestCasesBase className="icons badge" />],
      "skill_a11y":[<badgeIcons.AccessibilityExpert className="icons badge" />, <badgeIcons.ExpertBase className="icons badge" />],
      "skill_automation":[<badgeIcons.AutomationExpert className="icons badge" />, <badgeIcons.ExpertBase className="icons badge" />],
      "skill_app": [<badgeIcons.AppTestingExpert className="icons badge" />, <badgeIcons.ExpertBase className="icons badge" />],
      "skill_web": [<badgeIcons.WebTestingExpert className="icons badge" />, <badgeIcons.ExpertBase className="icons badge" />],
      "skill_test_cases": [<badgeIcons.TestCasesExpert className="icons badge" />, <badgeIcons.ExpertBase className="icons badge" />],
      "skill_training": [<badgeIcons.ExpertTrainer className="icons badge" />, <badgeIcons.ExpertBase className="icons badge" />],
      "trialist": [<badgeIcons.Trialist className="icons badge" />, <badgeIcons.ExpertBase className="icons badge" />],
      "points_bronze":[<badgeIcons.BugsBronze className="icons badge" />,<badgeIcons.BugsBase className="icons badge" />],
      "points_silver":[<badgeIcons.BugsSilver className="icons badge" />, <badgeIcons.BugsBase className="icons badge" />],
      "points_gold":[<badgeIcons.BugsGold className="icons badge" />, <badgeIcons.BugsBase className="icons badge" />],
      "points_platinum":[<badgeIcons.BugsGold className="icons badge" />, <badgeIcons.BugsBase className="icons badge" />],
    }
    
  }

  render() {
    return(
      <div style={{position:"relative", marginTop:"50px"}}>
        <div style={{position:"absolute", top:"-8px", backgroundColor:"white", fontSize:"9pt", paddingRight:"10px"}}>SKILLS BADGES</div>
        <div style={{height:"1px", backgroundColor:"lightgray", marginTop:"7px"}} />
        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
          {this.props.achievements.map((a)=>{
            var badge = this.props.user.badges.find((b)=>b.identity === a.identity)
            if (badge) {
              return (
                <BadgeContainer key={a.identity}>
                  {this.badges[a.identity][0]}
                  <p style={{color:"#519acc", fontWeight:"600", fontSize:"13pt"}}>{badge.name}</p>
                  <p>Earned on {moment(badge.created_at).format("MM/DD/YYYY")}</p>
                </BadgeContainer>
              )
            } else {
              return (
                <BadgeContainer key={a.identity}>
                  {this.badges[a.identity][1]}
                  <p style={{ fontWeight:"600", fontSize:"13pt"}}>{a.name}</p>
                  <p>{a.name.includes("Expert") || a.name === "Tester Trial" ? "Not earned yet" : `${a.details} to earn`}</p>
                </BadgeContainer>
              )
            }
          })}
        </div>
      </div>
    )
  }
}

export default AccountBadges