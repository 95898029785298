import React from 'react'

class KeyboardShortcuts extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    document.querySelector("#keyboard-shortcuts > h2").focus()
  }

  componentWillUnmount() {
    var previousFocusElem = document.querySelector(this.props.data.currentResultSelector)
    if(previousFocusElem){previousFocusElem.focus()}
  }

  closeModal = () => {
		this.props.modalAction(false, "", {})
  }

  render() {
    
    return (
    <div tabIndex={-1} id="keyboard-shortcuts" role="dialog" aria-label="Keyboard Shortcuts" style={{position:"relative", padding:"20px"}}>
        <h2 tabIndex={-1} style={{padding:"10px", textAlign:"center", display:"block", fontSize:"20px", fontWeight:"600", outline:"none"}}>Test Case Result Shortcuts</h2>
        <ul>
          <li><b>T</b> - View and add bugs </li>
          <li><b>T+shift</b> - View and add device specific bugs.</li>
          <li><b>C</b> - View and add comments</li>
          <li><b>Shift+C</b> - View and add device specific comments</li>
          <li><b>A</b> - View and add WCAG violations</li>
          <li><b>Z</b> - Jump to top of device column.</li>
          <li>Test result options correspond to first letter, example: P for pass</li>
        </ul>
        <h3 style={{padding:"10px", textAlign:"center", display:"block", fontSize:"16px", fontWeight:"600"}}>Screen Reader specific shortcuts</h3>
        <ul>
          <li><b>S</b> - Test case text </li>
          <li><b>D</b> - Current column info</li>
        </ul>
        <button aria-label="close keyboard shortcuts modal" className="icon-close-btn" style={{ display:"block", position:"absolute", top:"10px", right:"15px" }} onClick={this.closeModal} ></button>
    </div>
    )
  }

}
export default KeyboardShortcuts