import React, { useState, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  useClick,
  useFloating,
  useInteractions,
  useDismiss,
  useTransitionStyles,
} from "@floating-ui/react";
import { deviceTypes } from "@types";
import UserImageCard from "@components/users/user_image_card";
import CheckoutHover from "@components/devices/Table/CheckoutHover";
import { DeviceLabContext } from "@components/devices/device_lab";
import popoverOffsetMiddleware from "./popoverOffsetMiddleware";

const DEFAULT_USER_AVATAR =
  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg";

const CheckoutCell = (props) => {
  const { original } = props;
  const { handleOpenCheckout } = useContext(DeviceLabContext);
  const [isHoverOpen, setIsHoverOpen] = useState(false);

  const isCheckedOut = useMemo(() => {
    return original.user.id !== 0;
  }, [original]);

  const isAvailable = useMemo(() => {
    return !isCheckedOut && original.checkout_active;
  }, [isCheckedOut, original]);

  const userAvatarUrl = useMemo(() => {
    return original.user?.avatar || DEFAULT_USER_AVATAR;
  }, [original]);

  const {
    refs,
    floatingStyles,
    context: floatingContext,
  } = useFloating({
    open: isHoverOpen,
    onOpenChange: setIsHoverOpen,
    placement: "left",
    middleware: [...popoverOffsetMiddleware],
  });

  const click = useClick(floatingContext, {
    enabled: isCheckedOut,
  });

  const dismiss = useDismiss(floatingContext, {
    enabled: isCheckedOut,
    outsidePress: true,
    escapeKey: true,
    ancestorScroll: false,
    bubbles: false,
  });

  const { isMounted: isHoverMounted, styles: hoverStyles } =
    useTransitionStyles(floatingContext, {
      duration: 150,
      enabled: isCheckedOut,
      initial: {
        opacity: 0,
        transform: "scale(0.9)",
      },
    });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  if (!isCheckedOut) {
    return (
      <TableCell aria-hidden="true" id={`device-${original.id}-available`}>
        <AvailableButton
          type="button"
          aria-label={`${original.device_name}, ${original.device_os}, Available`}
          data-index={original.id}
          value={original.id}
          name="checkOut"
          onClick={(e) => {
            if (isAvailable) {
              handleOpenCheckout(e);
            }
          }}
        >
          <AvailableCheckbox />
          {isAvailable ? "Available" : "Unavailable"}
        </AvailableButton>
      </TableCell>
    );
  }

  return (
    <TableCell
      ref={refs.setReference}
      aria-hidden="true"
      id={`device-${original.id}-user`}
      {...getReferenceProps()}
    >
      <UserImageCard
        user={original.user}
        divHeight="30px"
        name={original.user.name}
        avatarUrl={userAvatarUrl}
      />
      {isHoverMounted && (
        <HoverWrapper
          ref={refs.setFloating}
          style={{ ...hoverStyles, ...floatingStyles }}
          {...getFloatingProps()}
        >
          <CheckoutHover device={original} avatarUrl={userAvatarUrl} />
        </HoverWrapper>
      )}
    </TableCell>
  );
};

const TableCell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const AvailableButton = styled.button`
  border: none;
  background-color: transparent;
  color: #6b6b6b;
  font-size: 17px;
  cursor: pointer;
`;

const AvailableCheckbox = styled.span`
  display: inline-block;
  height: 20px;
  width: 20px;
  border: solid 2px #6b6b6b;
  border-radius: 2px;
  vertical-align: sub;
  margin-right: 15px;
  pointer-events: none;
`;

const HoverWrapper = styled.div`
  z-index: 1000;
  min-width: 300px;
`;

// The react-table will pass all available props, but for this component,
// currently we only make use of "original", which is the device data.
// If in the future need to access other props, document which are being used here.
// For more info on available props, see https://tanstack.com/table/latest/docs/guide/data

CheckoutCell.propTypes = {
  original: deviceTypes,
};

export default CheckoutCell;
