import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import TopTesterRow from "./top_tester_row.jsx";
import Switcher from "../common/switcher";

const TestersList = styled.div`
  display: flex;
  border-radius: 0px;
  box-shadow: 0px 0px 3px 1px lightgrey;
  padding: 10px 15px 20px;
  margin: 5px;
  &:first-child {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1;
  }
  .top-row {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const ReportsTable = styled.table`
  thead {
    background-color: #519acc;
    color: white;
    position: sticky;
    top: 0;
    height: 25px;
    
  }
  td, th {
    white-space: pre;
    padding: 0 10px !important;
    border-radius: 0px;
    transition: color .5s;
  }
  th {
    height: 25px;
  }
  tbody > tr > td:nth-child(1) {
    text-align: left;
  }
  thead > tr > th:nth-child(1) {
    text-align: left;
  }
  thead > tr > th {
    text-align: center;
    height: 25px;
  }
  tbody > tr > td {
    color: #242424;
    text-align: center;
  }
  tbody {
    max-height: 300px;
    overflow: hidden auto;
  }
  tr {
    height: 50px;
    white-space: pre;
    padding: 0 10px !important;
    text-align: center;
    transition: color .5s, background-color .5s;
    &:hover {
      cursor: pointer;
      background-color: #c8dbec;
      > td:nth-child(1) {
        color: white;
      }
    }
  }
  tr:nth-child(even) {
    background-color: lightgray;
    }
  }
`;

function TopTestersContainer(props) {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [mounted, setMounted] = useState(false);

  function switcherHandler(page) {
    setCurrentPage(page);
  }

  function getData() {
    setLoading(true);
    var scope = currentPage == 0 ? "bugs" : "testcases";
    var url = `/tester_rows.json?scope=${scope}`;
    axios
      .get(url)
      .then((res) => {
        setRows(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (mounted === false) {
      setMounted(true);
    }
    getData();
    return () => {};
  }, [props.projectId]);

  useEffect(() => {
    getData();
    return () => {};
  }, [currentPage]);

  return (
    <TestersList>
      <div style={{ height: "100%" }}>
        <div className="top-row">
          <p className="large-title">Top Testers</p>
          <Switcher
            key={1}
            classes="col s12"
            idProp="small-switcher"
            activeValue={currentPage}
            switcherOptions={["Bugs", "Test Cases"]}
            handlerFunction={switcherHandler}
          />
        </div>
        <div
          className="new-scrollbar"
          style={{
            maxHeight: "calc(100% - 65px)",
            height: "100%",
            overflow: "hidden auto",
          }}
        >
          {loading ? (
            <div
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <div id="test-step-bugs-loader" />
            </div>
          ) : (
            <ReportsTable style={{ width: "100%" }}>
              <>
                {rows.length == 0 ? (
                  <tbody>
                    <tr>
                      <td
                        style={{ color: "black", textAlign: "center" }}
                        colSpan={5}
                      >
                        No Testers Found
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <thead style={{ height: "25px", lineHeight: "25px" }}>
                    <tr style={{ height: "25px", backgroundColor: "#519acc" }}>
                      <th>Rank</th>
                      <th>Tester Name</th>
                      <th>This Month</th>
                      <th>Last Month</th>
                    </tr>
                  </thead>
                )}
              </>
              <tbody>
                {rows.map((row, index) => {
                  return <TopTesterRow index={index} key={index} row={row} />;
                })}
              </tbody>
            </ReportsTable>
          )}
        </div>
      </div>
    </TestersList>
  );
}

export default TopTestersContainer;
