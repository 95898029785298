import React from 'react'
import moment from 'moment'
import axios from 'axios'
import RoundDropdown from '../common/round_dropdown'
class TicketHistory extends React.Component{
    constructor(props) {
			super(props)
			this.state = {
				ticketVersions: [], 
				expanded: false, 
				loading: false,
				sort: "newest"
			}
		}

		expandHistory = () => {
			if(!this.state.loading){
				if(!this.state.expanded && this.state.ticketVersions.length ===0){
					this.setState({loading: true})
					axios.get(`/v1/bugs/${this.props.ticketId}/ticket_versions.json`, {
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}
					})
					.then((res) => {
						this.getTicketHistory(res.data.ticket_versions)
					})
					.catch((err) => {
						err.message = "Ticket History Expand Error: " + err.message
						console.error(err)
						this.setState({expanded: !this.state.expanded,loading: false})
					})
				} else {
					this.setState({expanded: !this.state.expanded})
					var elem = document.querySelector("#ticket-history-container")
					if(elem.style.overflow === "hidden"){setTimeout(() => {elem.style.overflow = "auto"}, 300)
					} else {elem.style.overflow = "hidden"}
				} 
			}
		}

		setSort = () => {
			this.setState({
				sort: this.state.sort === "newest" ? "oldest" : "newest",
				loading: true
			})
			axios.get(`/v1/bugs/${this.props.ticketId}/ticket_versions.json`, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}, params:{
					sort_order: this.state.sort === "newest" ? "oldest" : "newest"
				}
			})
			.then((res) => {
				this.getTicketHistory(res.data.ticket_versions)
			})
			.catch((err) => {
				err.message = "Ticket History Sort Error - backend: " + err.message
      	console.error(err)
				this.setState({expanded: !this.state.expanded,loading: false})
			})
		}
		
		getTicketHistory = (tv) => {
			try{
				var ticketVersions = []
				tv.forEach((version) => {
					if (version.change.updated_at) {
						//^^workaround for versions created before "object_changes" added to versions table
						var timeStamp = moment(version.change.updated_at[1]).format("MM/DD/YYYY [at] hh:mm a")
						switch(Object.keys(version.change)[0]) {
							case "id":
								ticketVersions.push({text: `${version.user?version.user:"A user"} created the ticket`, timeStamp, dateSort:version.change.updated_at[1]})
								break
							case "title":
								ticketVersions.push({text:<span>{version.user?version.user:"A user"} updated <span style={{fontWeight:"600"}}>Title</span> to "{version.change.title[1]}"</span>, timeStamp, dateSort:version.change.updated_at[1]})
								break
							case "ticket_status_id":
								var status = version.change.ticket_status_id[1]
								switch(status){
									case 1: status = <span style={{color:"green"}}>Open</span>; break
									case 2: status = <span style={{color:"black"}}>Closed</span>; break
									case 3: status = <span style={{color:"red"}}>Fixed</span>; break
									case 5: status = <span style={{color:"grey"}}>Wont Fix</span>; break
									case 6: status = <span style={{color:"#2E8B57"}}>Reopened</span>; break
									default: status = <span style={{color:"#cab200"}}>In Progress</span>
								}
								ticketVersions.push({text:<span>{version.user ? version.user: "A user"} updated <span style={{fontWeight:"600"}}>Status</span> to {status}</span>, timeStamp, dateSort:version.change.updated_at[1]})
								break
							case "assignee_id":
								var assignee = ""
								for(var x = 0; x < this.props.users.length; x++){
									if(this.props.users[x].value === version.change.assignee_id[1]){
										assignee = this.props.users[x].label.props ? this.props.users[x].label.props.children[1] :this.props.users[x].label
										break
									}
								}
									ticketVersions.push({text:<span>{version.user ? version.user : "A user"} updated <span style={{fontWeight:"600"}}>Assignee</span> {assignee? `to ${assignee}`:""}</span>, timeStamp, dateSort:version.change.updated_at[1]})
								break 
							case "description":
								ticketVersions.push({text:<span>{version.user ? version.user : "A user"} updated <span style={{fontWeight:"600"}}>Description</span></span>, timeStamp, dateSort:version.change.updated_at[1]})
								break
							case "ticket_priority_id":
								var priority = version.change.ticket_priority_id[1]
								switch(priority){
									case 1: priority = <span style={{color:"#ff0000"}}>Critical</span>;break
									case 2: priority = <span style={{color:"#ff9900"}}>High</span>;break
									case 3: priority = <span style={{color:"#0066cc"}}>Normal</span>;break
									default: priority = <span style={{color:"#009900"}}>Low</span>
								}
								ticketVersions.push({text:<span>{version.user ? version.user : "A user"} updated <span style={{fontWeight:"600"}}>Priority</span> to {priority}</span>, timeStamp, dateSort:version.change.updated_at[1]})
								break
							case "build_version":
								ticketVersions.push({text:<span>{version.user ? version.user : "A user"} updated <span style={{fontWeight:"600"}}>Build</span> {version.change.build_version[1] ? `to ${version.change.build_version[1]}` :""}</span>, timeStamp, dateSort:version.change.updated_at[1]})
								break
						}
					}	
				})
				if(this.props.comments.length > 0){
					this.props.comments.forEach((comment) => {
						ticketVersions.push({text:<span>{comment.user_name.name ? comment.user_name.name : "A user"} added a comment</span>, timeStamp: moment(comment.updated_at).format("MM/DD/YYYY [at] hh:mm a"), dateSort: comment.updated_at})
					})
					ticketVersions = ticketVersions.sort((a,b)=>{
						if(a.dateSort < b.dateSort){
							return this.state.sort === "newest" ? 1 : -1
						} else if(a.dateSort > b.dateSort){
							return this.state.sort === "newest" ? -1 : 1
						} else {
							return 0
						}
					})
				}
				this.setState({ticketVersions, expanded: true, loading: false})
				setTimeout(() => {
					var elem = document.querySelector("#ticket-history-container")
					elem.style.overflow = "auto"
				}, 300)
			} catch(err) {
				err.message = "Ticket History - frontend: " + err.message
      	console.error(err)
      	Sentry.captureException(err)
			}
		}

    render() {
			return (
				<div aria-hidden="true" className="col s12" style={{marginTop: "20px"}}>
					<div id="ticket-history-container" className="card col s12 " style={{padding: "0px 0px 10px 0px", margin:"0px", boxShadow:"none", transition:"max-height 0.3s", maxHeight:`${this.state.expanded?`${(this.state.ticketVersions.length * 56)+30}px`:"28px"}`, overflow:"hidden"}}>
						{this.state.loading&&<div id="ticket-history-loader"/>}
						<span style={{cursor:"pointer", fontWeight:"600", display: "flex", alignItems: "center", width: "65px", justifyContent:"space-between"}} onClick={this.expandHistory}>
							History<RoundDropdown rotate={this.state.expanded?"rotate(0deg)":"rotate(90deg)"} />
            </span>
						{this.state.ticketVersions.length > 1 && <div style={{position:"absolute", right: "10px", top:"32px"}}>
							Sort by: <span onClick={this.setSort} style={{display:"inline-block", textTransform: "capitalize", color: "#83c9f7", cursor: "pointer"}}>{this.state.sort}</span>
						</div>}
						{this.state.ticketVersions.length > 0 ?
							this.state.ticketVersions.map((version, index) => {
								return(
									<div key={`version-${index}`}>
										<p style={{fontStyle:"italic", fontWeight:"600", color:"#242424"}}>{version.timeStamp}</p>
										<p style={{paddingLeft:"5px", marginLeft:"10px", borderLeft:"solid 1px lightgray", color:"#7b7b7b"}}>{version.text}</p>
									</div>
								)
							})
						:
						<div style={{textAlign:"center"}}>
							<span>No history found</span>
						</div>
						}
					</div>
				</div>
			)
    }
}

export default TicketHistory