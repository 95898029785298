import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const LoadingSpinner = (props) => {
  const { loading, className, size } = props;

  return (
    loading && (
      <LoadingContainer $size={size} className={className}>
        <div
          style={{ height: `${size}px`, width: `${size}px` }}
          id="test-step-bugs-loader"
        />
      </LoadingContainer>
    )
  );
};

const LoadingContainer = styled.div`
  height: ${(props) => props.$size}px;
  width: ${(props) => props.$size}px;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  size: PropTypes.number,
  // if composed with styled-components, this will be passed in
  className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  size: 100,
  className: "",
};

export default LoadingSpinner;
