import React from 'react'
import TrialistSection from './trialist_section'
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from 'axios'
import Arrow from '../icons/round_dropdown'

class TrialistPage extends React.Component{
  constructor(props){
    super(props)
    var pages = [...this.props.pages]
    pages.sort((a,b)=> a.day - b.day || a.postion - b.position)
    this.state = {
      currentSlide: 1,
      pages: pages,
      currentIndex: pages.findIndex((p)=>p.id===this.props.showPage.id),
      slideOne: this.props.showPage,
      slideTwo: null,
      editingSection: false,
      dragging: false,
      direction: "node-forwards",
      loading: false
    }
    this.trialPageRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleNavButtons, false);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleNavButtons, false);
  }

  handleNavButtons = (event) => {
    var self = this
    var currentSlide = self.state.currentSlide
      var value = parseInt(event.target.location.pathname.split('/')[event.target.location.pathname.split('/').length - 1 ])
      if (isNaN(value)) {
        if(event.target.location.pathname === "/trialist/") {
          self.props.handlePageSelect(null, "node-backwards")
          return false
        } else {
          return false
        }
      }
      var index = self.state.pages.findIndex((x) => x.id === value)
      self.setState({loading: true})
        axios.get(`/trialist_sections/${value}`,{
          headers:{
              'Accept': 'application/json',
            }
        })
        .then((res)=>{
          var newSlide = currentSlide === 1 ? {slideTwo: res.data} : {slideOne: res.data}
          self.props.handleUpdateCache(res.data)
          self.setState({direction: value === "forwards" ? "node-forwards" : "node-backwards", loading: false, ...newSlide}, ()=>{
            self.setState({currentSlide: currentSlide === 1 ? 2 : 1, currentIndex: index})
            if (self.trialPageRef && self.trialPageRef.current) {
              setTimeout(()=>{self.trialPageRef.current.scrollTo({top: 0})},390)
            }
          })
        })
        .catch((err)=>{
          self.setState({loading: false})
          console.error(err)
        })
  }

  handleClearPage = () =>{
    this.props.handlePageSelect(null, "node-backwards")
  }

  handlePageNavigate = (e) => {
    if(!this.state.loading){
      var currentSlide = this.state.currentSlide
      var value = e.target.value
      var index = value === "forwards" ? this.state.currentIndex + 1 : this.state.currentIndex - 1
      if(!this.props.editMode){
        var stateObj = {}
        window.history.pushState(stateObj, "", `/trialist/${this.state.pages[index].id}` )
      }
      if(this.props.cachedPages[this.state.pages[index].id]){
        var newSlide = currentSlide === 1 ? {slideTwo: this.props.cachedPages[this.state.pages[index].id]} : {slideOne: this.props.cachedPages[this.state.pages[index].id]}
        this.setState({direction: value === "forwards" ? "node-forwards" : "node-backwards", loading: false, ...newSlide}, ()=>{
          this.setState({currentSlide: currentSlide === 1 ? 2 : 1, currentIndex: index})
          setTimeout(()=>{this.trialPageRef.current.scrollTo({top: 0})},390)
        })
      } else {
        this.setState({loading: true})
        axios.get(`/trialist_sections/${this.state.pages[index].id}`,{
          headers:{
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
          var newSlide = currentSlide === 1 ? {slideTwo: res.data} : {slideOne: res.data}
          this.props.handleUpdateCache(res.data)
          this.setState({direction: value === "forwards" ? "node-forwards" : "node-backwards", loading: false, ...newSlide}, ()=>{
            this.setState({currentSlide: currentSlide === 1 ? 2 : 1, currentIndex: index})
            setTimeout(()=>{this.trialPageRef.current.scrollTo({top: 0})},390)
          })
        })
        .catch((err)=>{
          this.setState({loading: false})
          console.error(err)
        })
      }
    }
  }

  handleAddSection = () => {
    var slide = this.state.currentSlide === 1 ? this.state.slideOne : this.state.slideTwo
    slide.trialist_sections.push({trialist_page_id: slide.id, id: "new", section_type:"text", style: {}, image_style: {}, position:slide.trialist_sections.length+1, content:""})
    slide = this.state.currentSlide === 1 ? {slideOne: slide} : {slideTwo: slide}
    this.setState({editingSection: "new", ...slide})
  }

  handleAddImage = () => {
    var slide = this.state.currentSlide === 1 ? this.state.slideOne : this.state.slideTwo
    slide.trialist_sections.push({trialist_page_id: slide.id, id: "new", section_type:"image", style: {}, image_style: {}, position:slide.trialist_sections.length+1, content:""})
    slide = this.state.currentSlide === 1 ? {slideOne: slide} : {slideTwo: slide}
    this.setState({editingSection: "new", ...slide})
  }

  handleCreateSection = (section) => {
    var slide = this.state.currentSlide === 1 ? this.state.slideOne : this.state.slideTwo
    slide.trialist_sections.splice(slide.trialist_sections.length-1, 1, section)
    slide = this.state.currentSlide === 1 ? {slideOne: slide} : {slideTwo: slide}
    this.setState({editingSection: false, ...slide})
    this.props.handleUpdateCache(slide)
  }

  handleUpdateSection = (section) => {
    var slide = this.state.currentSlide === 1 ? this.state.slideOne : this.state.slideTwo
    slide.trialist_sections.splice(section.position-1, 1, section)
    slide = this.state.currentSlide === 1 ? {slideOne: slide} : {slideTwo: slide}
    this.setState({editingSection: false, ...slide})
    this.props.handleUpdateCache(slide)
  }

  handleEditSection = (id) =>{
    if(this.state.editingSection === "new"){
      var slide = this.state.currentSlide === 1 ? this.state.slideOne : this.state.slideTwo
      slide.trialist_sections.splice(slide.trialist_sections.length-1, 1)
      slide = this.state.currentSlide === 1 ? {slideOne: slide} : {slideTwo: slide}
      this.setState({editingSection: id, ...slide})
    } else {
      this.setState({editingSection: id})
    }
    
  }

  handleDeleteSection = (data) =>{
    var slide = this.state.currentSlide === 1 ? this.state.slideOne : this.state.slideTwo
    slide.trialist_sections = slide.trialist_sections.filter((s)=>s.id!==data.delete_id).map((s)=> ({...s, ...data.sections[s.id.toString()]}))
    slide = this.state.currentSlide === 1 ? {slideOne: slide} : {slideTwo: slide}
    this.setState({...slide})
    this.props.handleUpdateCache(slide)
  }

  setLoading = (bool) => {
    this.setState({loading:bool})
  }

  handleDragStart = (id, position) => {
    this.setState({dragging: {id, position}})
  }

  handleDragEnd = (e) => {
    this.setState({dragging: false})
  }
  
  handleDragDrop = (id, position) => {
    if(!this.state.loading){
      this.setState({loading: true})
      axios.patch(`/trialist_sections/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        trialist_section:{position}
      })
      .then((res)=>{
        var slide = this.state.currentSlide === 1 ? this.state.slideOne : this.state.slideTwo
        slide.trialist_sections = slide.trialist_sections.map((s)=>({...s, ...res.data[s.id.toString()]}))
        slide.trialist_sections.sort((a,b)=> a.position - b.position)
        this.props.handleUpdateCache(slide)
        slide = this.state.currentSlide === 1 ? {slideOne: slide} : {slideTwo: slide}
        this.setState({...slide, loading: false})
      })
      .catch((err)=>{
        this.setState({loading: false})
        console.error(err)
      })
    }
  }

  render(){
    var {slideOne, slideTwo, currentSlide, currentIndex} = this.state
    var showLeftArrow = currentIndex > 0 && this.state.pages[currentIndex].day === this.state.pages[currentIndex-1].day
    var showRightArrow = currentIndex < this.state.pages.length-1 && this.state.pages[currentIndex].day === this.state.pages[currentIndex+1].day
    return(
      <div id="trial-page-container">
        <div ref={this.trialPageRef} className="trial-page new-scrollbar">
          <button onClick={this.handleClearPage} className="common-link">{"< Back to page directory"}</button>
          <SwitchTransition>
            <CSSTransition 
              key={currentSlide === 1}
              timeout={{
                appear: 300,
                enter: 390,
                exit: 390,
              }}
              classNames={this.state.direction} 
            >
              {currentSlide === 1 ?
                <div style={{whiteSpace:"normal"}}>
                  <h2>{slideOne.title}</h2>
                  {slideOne.trialist_sections.map((s)=>{
                    return(
                      <TrialistSection
                        editingSection={this.state.editingSection} 
                        handleEditSection={this.handleEditSection}
                        handleUpdateSection={this.handleUpdateSection}
                        handleCreateSection={this.handleCreateSection}
                        handleDeleteSection={this.handleDeleteSection}
                        handleDragStart={this.handleDragStart}
                        handleDragEnd={this.handleDragEnd}
                        handleDragDrop={this.handleDragDrop}
                        setLoading={this.setLoading} 
                        loading={this.state.loading} 
                        key={s.id} 
                        section={s}
                        editMode={this.props.editMode}
                        sectionsLength={slideOne.trialist_sections.length} 
                        dragging={this.state.dragging}
                      />
                    )
                  })}
                  {this.props.editMode&&
                    <div>
                      <button style={{marginTop:"30px"}} disabled={this.state.editingSection} onClick={this.handleAddSection} className="common-button-submit">Add Section</button>
                      <button style={{marginTop:"30px"}} disabled={this.state.editingSection} onClick={this.handleAddImage} className="common-button-submit">Add Image</button>
                    </div>
                  }
                </div> : 
                <div style={{whiteSpace:"normal"}}>
                  <h2>{slideTwo.title}</h2>
                  {slideTwo.trialist_sections.map((s)=>{
                    return(
                      <TrialistSection
                        editingSection={this.state.editingSection}
                        handleEditSection={this.handleEditSection}
                        handleUpdateSection={this.handleUpdateSection}
                        handleCreateSection={this.handleCreateSection} 
                        handleDeleteSection={this.handleDeleteSection}
                        handleDragStart={this.handleDragStart}
                        handleDragEnd={this.handleDragEnd}
                        handleDragDrop={this.handleDragDrop} 
                        setLoading={this.setLoading} 
                        loading={this.state.loading} 
                        key={s.id} 
                        section={s}
                        editMode={this.props.editMode}  
                        sectionsLength={slideTwo.trialist_sections.length}
                        dragging={this.state.dragging}
                      />
                    )
                  })}
                  {this.props.editMode&&
                  <div>
                    <button style={{marginTop:"30px"}} disabled={this.state.editingSection} onClick={this.handleAddSection} className="common-button-submit">Add Section</button>
                    <button style={{marginTop:"30px"}} disabled={this.state.editingSection} onClick={this.handleAddImage} className="common-button-submit">Add Image</button>
                  </div>}
                </div>
              }
              
            </CSSTransition>
          </SwitchTransition>
        
          
          
        </div>
        <div style={{position: "absolute", bottom:"0px", display:"flex", width:"100%", justifyContent:"space-between", left:"0", padding:"0 30px"}}>
              <div style={{display:"flex"}}>
                <button onClick={this.handlePageNavigate} value="backwards" className="trial-page-arrow" style={{ cursor:"pointer", pointerEvents: showLeftArrow ? "all":"none", opacity: showLeftArrow ? "1" : "0.4"}}>
                  <Arrow color={"white"} svgStyles={{transform:"rotate(90deg)" , pointerEvents:"none", position:"relative", left:"-1px"}} />
                </button>
                {showLeftArrow&&<p style={{marginLeft:"10px", fontWeight:"600"}}>{this.state.pages[currentIndex-1].title}</p>}
              </div>
              <CSSTransition 
                unmountOnExit 
                in={this.state.loading} 
                timeout={300}
                classNames="node-fade" 
              >
                <div id="trial-page-loader"></div>
              </CSSTransition>
              
              <div style={{display:"flex"}}>
              {showRightArrow&&<p style={{marginRight:"10px", fontWeight:"600"}}>{this.state.pages[currentIndex+1].title}</p>}
                <button onClick={this.handlePageNavigate} value="forwards" className="trial-page-arrow" style={{ cursor:"pointer", pointerEvents: showRightArrow ? "all":"none", opacity: showRightArrow ? "1" : "0.4"}}>
                  <Arrow color={"white"} svgStyles={{transform:"rotate(-90deg)", pointerEvents:"none"}} />
                </button>
              </div>
          </div>
      </div>
    )
  }
}

export default TrialistPage