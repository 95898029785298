import React from 'react'

class EarnIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
      >
        <path d="M48.16,88.69,64,72.85,79.84,88.69a5.3,5.3,0,0,0,3.73,1.4h0a5.29,5.29,0,0,0,3.72-1.4c1.87-1.86,1.87-5.12,0-7.45L67.73,61.67a5.67,5.67,0,0,0-7.46,0L40.71,81.24a5.65,5.65,0,0,0,0,7.45C43,90.55,46.3,90.55,48.16,88.69Z"/>
        <path d="M67.73,30a5.67,5.67,0,0,0-7.46,0L40.71,49.56a5.06,5.06,0,0,0,0,7c1.86,2.33,5.12,2.33,7.45.46L64,41.17,79.84,57a5.3,5.3,0,0,0,3.73,1.4h0A5.29,5.29,0,0,0,87.29,57c1.87-1.86,1.87-5.12,0-7.45Z"/>
        <path d="M64,3a61,61,0,1,0,61,61A61.24,61.24,0,0,0,64,3Zm0,111.81h0A50.78,50.78,0,1,1,114.78,64,50.9,50.9,0,0,1,64,114.78Z"/>
      </svg>
    );
  }
}
EarnIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default EarnIcon