import React from 'react'
import TestStep from './test_step.jsx'
import sleep from '../../common/sleep.js'
import Swal from 'sweetalert2'
import PropTypes from "prop-types";
import RoundDropdown from '../../icons/round_dropdown.jsx';

class TestCase extends React.Component {
   constructor(props) {
     super(props);
     this.state = {
      steps: this.props.testCase.test_steps,
      name: this.props.testCase.name,
      open: false,
      arrowOpen: false,
      active: false
     }
     this.inputCaseRef = React.createRef()
     this.caseRef = React.createRef()
     this.swalThirdButton = false
     this.swalThirdButtonListener = (e) => {
       if(e.target.id === "swal-third-button"){
        this.swalThirdButton = true
        var elem = document.querySelector(
          "body > div.swal2-container.swal2-center.swal2-backdrop-show > div > div.swal2-actions.swal2-3button > button.swal2-confirm.swal2-styled"
        );
        elem.click()
        window.removeEventListener("click", this.swalThirdButtonListener)
       }
     }
     this.handleOutsideClick = (e) => {
			if(!document.getElementById(`test-section-title-container-${this.props.testCase.id}`).contains(e.target)){
				this.editCaseToggle()
			}
    }
    this.editCaseToggle = this.editCaseToggle.bind(this);
    this.updateTestCase = this.updateTestCase.bind(this);
    this.handleCaseDelete = this.handleCaseDelete.bind(this);
    this.handleStepDelete = this.handleStepDelete.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.keydownHandler = this.keydownHandler.bind(this);
    this.onDragDropHandler = this.onDragDropHandler.bind(this);
    this.onDragStartHandler = this.onDragStartHandler.bind(this);
    this.onDragEndHandler = this.onDragEndHandler.bind(this);
    this.onDragLeaveHandler = this.onDragLeaveHandler.bind(this);
    this.onDragOverHandler = this.onDragOverHandler.bind(this);
    this.onDragEnterHandler = this.onDragEnterHandler.bind(this);
    this.createTestStep = this.createTestStep.bind(this);
    this.stepHandler = this.stepHandler.bind(this);
    this.updateSteps = this.updateSteps.bind(this);
    this.hideFocus = this.hideFocus.bind(this);
    this.showFocus = this.showFocus.bind(this);
    this.open = this.open.bind(this);
    this.parentOpen = this.parentOpen.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.scrollOpacity = this.scrollOpacity.bind(this);
    this.checkTemplateList = this.checkTemplateList.bind(this);
    this.cancelCase = this.cancelCase.bind(this);
    }

    updateSteps(data) {
      this.setState({steps: data.test_steps})
    }

    editCaseToggle() { 
      if(this.state.active){
        var id = this.props.testCase.id;
        var text = document.getElementById(`test-section-title-input-${id}`)
          .value
          ? document.getElementById(`test-section-title-input-${id}`).value
          : "";
        if (text != this.state.name) {
          this.updateTestCase()
        }
        window.removeEventListener("mousedown", this.handleOutsideClick)
        this.setState({active: false})
      } else {
        this.setState({active: true}, ()=>{
          window.addEventListener("mousedown", this.handleOutsideClick)
        })
      }
    }

    cancelCase() {
      window.removeEventListener("mousedown", this.handleOutsideClick)
      this.setState({active: false})
    }

    keydownHandler(e) {
      if(e.keyCode === 13 || e.keyCode === 9){this.updateTestCase(e)}
    }

    sendRequest(url, data, self) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: url,
          type: 'POST',
          data: data,
          beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
          dataType: 'json',
          cache: false,
          success: function(data) {
            var steps = self.state.steps
            var step = data.test_steps[(data.test_steps.length - 1)]
            steps.push(step)
            self.setState({steps: steps},()=>{
              var elem = document.getElementById(`tc-title-input-${step.id}`)
              if (elem) {
                elem.focus()
              }
              elem = document.getElementById(`tc-form-list`)
              var elem2 = document.getElementById(`${step.id}-${steps.length}-step`)
              if (elem&&elem2&&elem.getBoundingClientRect().bottom < elem2.getBoundingClientRect().bottom){
                elem.scrollBy(0, (elem2.getBoundingClientRect().bottom - elem.getBoundingClientRect().bottom) +34)
              }
            })
            resolve(data)
          },
          error: function(error) {
            reject(error)
          },
        })
      })
    }

    createTestStep() {
      var self = this
      var id = this.props.testCase.id
      var data = {"test_step": {"text": "" }}
      var url = '/test_cases/' + id + '/test_steps' + '.json'
      this.sendRequest(url, data, self)
      .then(data => {})
      .catch(error => {
        console.log(error)
      })
    }

    stepHandler(data) {
      var steps = this.state.steps
      var index = steps.map(e => e.id).indexOf(data.id)
      steps[index] = data
      this.setState({steps: steps})
    }

    updateTestCase() {
      var id = this.props.testCase.id
      var text = document.getElementById(`test-section-title-input-${id}`).value ? document.getElementById(`test-section-title-input-${id}`).value : ""
      if(text !== this.state.name){
        var data = {"test_case": {"name": text }}
        this.props.suggestionHandler('testCase', text)
        var url = '/test_suites/' + this.props.testSuite.id + '/test_cases/' + id + '.json'
        $.ajax({
            url: url,
            type: 'PUT',
            data: data,
            beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
            dataType: 'json',
            cache: false,
            success: function(data) {
              window.removeEventListener("mousedown", this.handleOutsideClick)
              this.setState({
                name: data.name,
                active: false
              })
            }.bind(this),
            error: function(status, err) {
            console.error(url, status, err);
          }.bind(this)
        })
      } else {
        this.editCaseToggle()
      } 
    }

    handleStepDelete(id) {
    var self = this
    var url = "/test_cases/" + this.props.testCase.id + "/test_steps/" + id + ".json"
    Swal.fire({
        title: "Delete Test Case",
        text: "Are you sure that you want to delete this Test Case?",
        reverseButtons: true,
        showCancelButton:true,
        confirmButtonAriaLabel: 'Yes',
        cancelButtonAriaLabel: 'cancel',
        confirmButtonText: "Yes",
        //ally stuff
        customClass: 'modal-button-outline'
      }).then((result)=>{
        if (result.value) {
        $.ajax({
            url: url,
            type: 'DELETE',
            beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
            dataType: 'json',
            cache: false,
            success: function(data) {
              self.setState({steps: data.test_steps})
            }.bind(this),
            error: function(status, err) {
            console.error(url, status, err);
          }.bind(this)
        })
        } else if (result.dismiss) {
          console.log('dismissed')
        }
      })
    }

    parentOpen(bool) {
      if(bool !== this.state.open){
        this.open()
      }
    }

    async open() {
      if(!this.transition){
        this.transition = true
        if(this.state.open){
          var elem = document.getElementById(`${this.props.testCase.id}-${this.props.testCase.position}`)
          if(elem){
            this.setState({arrowOpen:false})
            var height = 35
            elem.style.height = `${elem.clientHeight}px`
            await sleep(100)
            elem.style.height = `${height}px`
            await sleep(300)
            elem.style.height = "auto"
            this.setState({open:false}, ()=>{this.transition = false})
          }else{
            this.setState({open:false, arrowOpen:false}, ()=>{this.transition = false})
          }
        } else {
          var elem = document.getElementById(`${this.props.testCase.id}-${this.props.testCase.position}`)
          if(elem){
            elem.style.height = "35px"
            this.setState({open:true, arrowOpen:true}, async ()=>{
              await sleep(100)
              elem.style.height = `${elem.scrollHeight}px`
              await sleep(300)
              elem.style.height = "auto"
              this.transition = false
            })
          }else{
            this.setState({open:true, arrowOpen:true},()=>{
              this.transition = false
            })
          }
        }
      }
    }

    handleOutsideStepClick(e) {this.props.handleOutsideStepClick(e)}

    handleCaseDelete(e) {this.props.handleCaseDelete(e)}

    onDragStartHandler(e) {this.props.onDragStart(e)}

    onDragEndHandler(e) {this.props.onDragEnd(e)}

    onDragDropHandler(e) {this.props.onDragDrop(e)}

    onDragLeaveHandler(e) {this.props.onDragLeave(e)}

    onDragEnterHandler(e) {this.props.onDragEnter(e)}

    onDragOverHandler(e) {this.props.onDragOver(e)}

    scrollOpacity(elem) {
      if (elem) {
        if (elem.scrollHeight > elem.clientHeight) {
          elem.previousElementSibling.style.opacity = "0"
          elem.nextElementSibling.style.opacity = "1"
        } else if (elem.scrollHeight < elem.clientHeight) {
          elem.previousElementSibling.style.opacity = "1s"
          elem.nextElementSibling.style.opacity = "0"
        } else {
          elem.previousElementSibling.style.opacity = "0"
          elem.nextElementSibling.style.opacity = "0"
        }
      }
    }

    checkTemplateList() {
      var elemTwo = document.getElementById('section-template-list')
      this.scrollOpacity(elemTwo)
    }

    createTemplate() {
      var self = this
      var id = this.props.testCase.id
      var win = window
      var data = {"test_case_id" : id }
      if (this.props.testCase.test_section_template_id) {
        win.addEventListener("click", this.swalThirdButtonListener)
        Swal.fire({
          title: `Add To Section Templates?`,
          text: 'This is linked to an existing Section Template.',
          html: '<span>This is linked to an existing Section Template. Would you like to update it?</span>'+
          '<button id="swal-third-button" type="button" role="button" tabindex="0" class="swal-third-button">' + 'Create New' + '</button>',
          showCancelButton:true,
          confirmButtonAriaLabel: 'Yes',
          cancelButtonAriaLabel: 'cancel',
          customClass: {
            popup: 'modal-button-outline swal-third-button-spacing',
            actions: 'swal2-3button',
          },
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          //ally stuff
        }).then((result)=>{
          if (result.value) {
            self.props.setLoading(true);
            if (!self.swalThirdButton) {
              var url = `/test_section_templates/${self.props.testCase.test_section_template_id}.json`;
              self.swalThirdButton = false;
              $.ajax({
                url: url,
                type: "PATCH",
                data: data,
                beforeSend: function (xhr) {
                  xhr.setRequestHeader(
                    "X-CSRF-Token",
                    $('meta[name="csrf-token"]').attr("content")
                  );
                },
                dataType: "json",
                cache: false,
                success: function (data) {
                  // eslint-disable-next-line no-undef
                  M.toast({
                    html: `Section Template updated`,
                    displayLength: 3000,
                    classes: "green",
                  });
                  self.props.handleTestSectionTemplates(data.templates);
                  self.checkTemplateList()
                }.bind(this),
                error: function (status, err) {
                  self.props.setLoading(false);
                  console.error(url, status, err);
                }.bind(this),
              });
            } else {
              url = "/test_section_templates/";
              self.swalThirdButton = false;
              win.removeEventListener("click", self.swalThirdButtonListener);
              self.props.setLoading(true);
              $.ajax({
                url: url,
                type: "POST",
                data: data,
                beforeSend: function (xhr) {
                  xhr.setRequestHeader(
                    "X-CSRF-Token",
                    $('meta[name="csrf-token"]').attr("content")
                  );
                },
                dataType: "json",
                cache: false,
                success: function (data) {
                  // eslint-disable-next-line no-undef
                  M.toast({
                    html: `Section Template added`,
                    displayLength: 3000,
                    classes: "green",
                  });
                  self.props.handleTestSectionTemplates(data.templates);
                  self.props.caseHandler(data.test_case)
                  self.checkTemplateList()
                }.bind(this),
                error: function (status, err) {
                  self.props.setLoading(false);
                  console.error(url, status, err);
                }.bind(this),
              });
            }
          } else if (result.dismiss) {
            win.removeEventListener("click", self.swalThirdButtonListener);
          }
        })
      } else {
        var url = "/test_section_templates/"
        Swal.fire({
          title: "Save Test Section Template",
          text: "Once this Test Section is saved, it will be available to reuse on this and other Test Directories.",
          showCancelButton:true,
          confirmButtonAriaLabel: 'Yes',
          cancelButtonAriaLabel: 'cancel',
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          reverseButtons: true,
          //ally stuff
          customClass: "modal-button-outline",
        }).then((result) => {
          if (result.value) {
            self.props.setLoading(true);
            $.ajax({
              url: url,
              type: "POST",
              data: data,
              beforeSend: function (xhr) {
                xhr.setRequestHeader(
                  "X-CSRF-Token",
                  $('meta[name="csrf-token"]').attr("content")
                );
              },
              dataType: "json",
              cache: false,
              success: function (data) {
                // eslint-disable-next-line no-undef
                M.toast({
                  html: `Section Template added`,
                  displayLength: 3000,
                  classes: "green",
                });
                self.props.handleTestSectionTemplates(data.templates);
                self.props.caseHandler(data.test_case)
                self.checkTemplateList()
              }.bind(this),
              error: function (status, err) {
                self.props.setLoading(false);
                console.error(url, status, err);
              }.bind(this),
            });
          } else if (result.dismiss) {
            console.log("dismissed")
          }
        });
      }
    }
    
    hideFocus(e) {
      if(!e.target.classList.contains("hide-focus")){
        e.target.classList.add("hide-focus")
      }
    }

    showFocus(e) {
      if(e.target.classList.contains("hide-focus")){
        e.target.classList.remove("hide-focus")
      }
    }

    render() {
      var testCase = this.props.testCase
      var i = this.props.i
      return (
        <li 
          style={{position:"relative"}} 
          data-tcid={testCase.id} 
          data-tcposition={testCase.position} 
          key={testCase.name + testCase.id + i}  
          id={`${testCase.id}-${testCase.position}`} 
          className="test-case-drag-container"
        >
          <div 
            onDragOver={this.onDragOverHandler} 
            onDrop={this.onDragDropHandler} 
            onDragEnter={this.onDragEnterHandler} 
            onDragLeave={this.onDragLeaveHandler}
            data-tcid={testCase.id} 
            data-tcposition={testCase.position}
            data-type={'case'}  
            style={{position:"absolute", width:"100%", height:"100%", zIndex:`${this.props.caseDragging?"4":"-1"}`}} 
            id={`${testCase.id}-${testCase.position}-drag-area`}
          />
          <div 
            id={`${testCase.id}-${testCase.position}-drag-hover-top`} 
            className="test-case-drag-hover" 
            style={{height:"0px", backgroundColor: "rgba(211, 211, 211, 0.555)"}} 
          />

          {this.state.active ? (
            <div id={`test-section-title-container-${testCase.id}`} className="flex-row testSuiteTitle" style={{"width": "calc(100% - 28px)", alignItems: "center"}}>
              <button className="span-to-button" onClick={this.open} tabIndex={0} style={{height:"35px", width:"30px", display:"flex", justifyContent: "center", alignItems: "center", cursor:"pointer"}}>
                <span style={{transform: this.state.open ? "rotateX(180deg)" : "", display:"block",  pointerEvents:"none"}}><RoundDropdown color="white" /></span>
              </button>
              <span style={{fontSize:"12pt", fontWeight:"600", display:"inline-block", paddingRight:"2.5px", marginLeft:"-1px"}}>{`${(i+1)}:`}</span>
              <input 
                data-tc-id={testCase.id}
                maxLength={55} 
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus 
                name="test_case"
                onKeyDown={this.keydownHandler} 
                defaultValue={this.state.name == "New Test Case" ? "" : this.state.name}
                placeholder={""}
                list="testcase-flavors"
                className="activeCaseInput"
                id={`test-section-title-input-${testCase.id}`}
              />
              <datalist id="testcase-flavors">
                  {this.props.suggestions["testCases"].map((suggestion, key) => (
                    <option key={key} value={suggestion}/>
                    )
                  )}
              </datalist>
              <div style={{position:"absolute", right:"30px", top:"7px", lineHeight:"20px", fontSize:"9pt", paddingRight:"20px"}}>
                  <button className="span-to-button" style={{marginLeft: "5px", cursor:"pointer"}} onClick={this.updateTestCase}>Save</button>
                  <button style={{marginLeft: "20px", cursor:"pointer"}} onClick={this.cancelCase} className="section-input-cancel span-to-button">Cancel</button>
              </div> 
            </div>
          ) : (
            <div className="flex-row  testSuiteTitle" style={{"width": "calc(100% - 28px)", alignItems: "center"}}>
              <button aria-live={this.state.arrowOpen ? "assertive" : "polite"} aria-label={`${this.state.arrowOpen ? "Close" : "Expand"} Test Suite - ${this.state.name ? this.state.name : "Untitled Test Section"}`} className="test-section-expand-button span-to-button" id={`test-section-expand-button-${testCase.id}`} onMouseDown={this.hideFocus} onBlur={this.showFocus} tabIndex={0} onClick={this.open} style={{height:"35px", width:"30px", display:"flex", alignItems: "center", justifyContent: "center", cursor:"pointer"}}>
                <span style={{transform: this.state.arrowOpen ? "rotate(180deg)" : "", display:"flex", transition: "transform .6s", pointerEvents:"none"}} ><RoundDropdown color="white" /></span>
              </button>
              <h4 
                style={{width:"100%"}} 
                data-index={i} 
                onClick={this.open}
                id={`${testCase.id}-${testCase.position}-header`}
                data-tc-id={testCase.id}
                className=" tc-item test-section-title"
              >
                  {`${(i+1)}: ${this.state.name ? this.state.name : "Untitled Test Section"}`}
                  <img
                    src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move_White.svg" 
                    alt="White move icons"
                    id={`${testCase.id}-${testCase.position}-icon`} 
                    draggable={true} 
                    data-tcid={testCase.id} 
                    data-tcposition={testCase.position}
                    data-type={'case'} 
                    onDragStart={this.onDragStartHandler} 
                    onDragEnd={this.onDragEndHandler} 
                    style={{verticalAlign:"-5px", marginLeft: "10px"}} 
                    className="material-icons test-case-drag-icon"
                  />
              </h4>
              <div style={{position:"absolute", right:"30px", top:"7px", lineHeight:"20px", fontSize:"9pt", paddingRight:"20px"}}>
                <button aria-label={`Edit Test Section - ${testCase.name}`} className="span-to-button" id={`test-section-edit-button-${testCase.id}`} style={{marginLeft: "5px", cursor:"pointer"}}  onClick={this.editCaseToggle}>Edit</button>
                {!this.props.oneSection && 
                  <button aria-label={`Delete Test Section - ${testCase.name}`} className="span-to-button" style={{marginLeft: "20px", cursor:"pointer"}} data-index={i} onClick={this.handleCaseDelete} data-tc-id={testCase.id}>Delete</button>
                }
                <button aria-label={`Save Test Section Template - ${testCase.name}`} className="span-to-button" style={{marginLeft: "20px", cursor:"pointer"}} data-index={i} onClick={this.createTemplate} data-tc-id={testCase.id}>Save Template</button>
              </div>                 
            </div>
          )
          }
            {this.state.open && <ul style={{"padding": "0", width:"calc(100% + 20px)"}} id={`innerUL-${testCase.id}`} className="innerUL collapsibleUL">
            {this.state.steps && this.state.steps.map((testStep, y) => (
               <TestStep 
                  key={y}
                  suggestions={this.props.suggestions}
                  suggestionHandler={this.props.suggestionHandler}
                  stepHandler={this.stepHandler}
                  y={y} testStep={testStep} 
                  testCase={testCase} 
                  editStepToggle={this.editStepToggle} 
                  i={i}
                  position={testStep.position}
                  handleStepDelete={this.handleStepDelete}
                  onStepDragStart={this.onDragStartHandler}
                  onStepDragEnd={this.onDragEndHandler}
                  onStepDragEnter={this.onDragEnterHandler}
                  onStepDragLeave={this.onDragLeaveHandler}
                  onStepDragDrop={this.onDragDropHandler}
                  onStepDragOver={this.onDragOverHandler}
                  stepDragging={this.props.stepDragging} 
               />
            ))}
            <li 
              className="newStepInput"
              id={`${testCase.id}-new-step`}
              data-type={'step'} 
              data-tcid={testCase.id}
              onDragOver={this.onDragOverHandler} 
              onDrop={this.onDragDropHandler} 
              onDragEnter={this.onDragEnterHandler} 
              onDragLeave={this.onDragLeaveHandler}
            >
              <div id={`${testCase.id}-new-step-drag-hover-top`} className="test-case-drag-hover" style={{height:"0px", backgroundColor: "rgba(211, 211, 211, 0.555)"}} />
                <button tabIndex={0} onClick={this.createTestStep} onMouseDown={this.hideFocus} onBlur={this.showFocus} style={{margin:"-4px 0px 15px 75px", display:"block", fontSize:"11pt", width:"120px", padding:"0px", lineHeight:"20px", color: "#519acc"}} className="light-button span-to-button">
                  <span className="material-icons" style={{verticalAlign:"text-top", fontSize:"17px"}}>add</span>
                  New Test Case
                </button>
            </li>
            </ul>
            }
            <div id={`${testCase.id}-${testCase.position}-drag-hover-bottom`} className="test-case-drag-hover" style={{height:"0px", backgroundColor: "rgba(211, 211, 211, 0.555)"}} />
          </li>
      )
    }
}

export default TestCase

TestCase.propTypes = {
  position: PropTypes.number,
  testCase: PropTypes.object,
  testSuite: PropTypes.object,
  id: PropTypes.string,
  projectId: PropTypes.number,
  pathName: PropTypes.string,
  stepDragging: PropTypes.bool,
  i: PropTypes.number,
  handleCaseDelete: PropTypes.func,
  handleOutsideStepClick: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragDrop: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragOver: PropTypes.func,
  oneSection: PropTypes.bool,
  caseDragging: PropTypes.bool
};