import React from "react";
import moment from "moment";

class UserCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "name",
      cardLoaded: false,
    };
    this.buttonHandler = this.buttonHandler.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.refs[`user_card${this.props.user.name}`].contains(e.target)) {
      if (this.state.cardLoaded == true) {
        this.props.closeUser();
      } else {
        this.setState({ cardLoaded: true });
      }
    }
  };

  getPrettyTime() {
    if (this.props.user) {
      const created = moment(this.props.user.employee_started_at);
      const today = moment();
      let prettyTimeNum = Math.floor(
        moment(today.diff(created, "years", true))._i
      );
      let prettyTimeWord = prettyTimeNum >= 2 ? "Years" : "Year";
      if (prettyTimeNum < 1) {
        prettyTimeNum = Math.floor(
          moment(today.diff(created, "months", true))._i
        );
        prettyTimeWord = prettyTimeNum >= 2 ? "Months" : "Month";
      }
      if (prettyTimeNum == 0) {
        prettyTimeNum = 1;
      }
      return [prettyTimeNum, prettyTimeWord];
    }
    ["", ""];
  }

  buttonHandler() {
    if (this.state.active == "name") {
      this.setState({ active: "email" });
    } else {
      this.setState({ active: "name" });
    }
  }

  render() {
    const prettyTime = this.getPrettyTime();
    return (
      <div
        ref={`user_card${this.props.user.name}`}
        className={`${this.props.className} ${
          this.props.user.role == "client" ? "clientCard" : ""
        }`}
        id="userCard"
        style={{
          position: "absolute",
          top: this.props.top,
          left: this.props.left,
          zIndex: 1000,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            alt="user image"
            src={
              this.props.user.avatar_image == null
                ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
                : this.props.user.avatar_image
            }
          />
          <div>
            <p className="flip" style={{ fontWeight: 500, padding: "5px" }}>
              {this.props.user.name}
            </p>
            <p>
              <b>(</b>
              {this.props.user.email}
              <b>)</b>
            </p>
          </div>
        </div>
        {this.props.user.role != "client" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              textAlign: "center",
              margin: "auto 0",
            }}
          >
            <div className="innerCard">
              <img
                alt="location icon"
                src="https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/location-icon.svg"
              />
              <p>Testing In</p>
              <p
                style={{
                  display: "block",
                  whiteSpace: "pre-wrape",
                  width: "105px",
                  height: "40px",
                  padding: "0 5px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {this.props.user.fact}
              </p>
            </div>
            <div
              style={{
                width: "1px",
                height: "80%",
                backgroundColor: "#519acc",
                opacity: ".4",
              }}
            />
            <div className="innerCard">
              <img
                alt="medal icon"
                src="https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/medal-icon.svg"
              />
              <p style={{ fontWeight: 500 }}>{prettyTime[0]}</p>
              <p>{prettyTime[1]}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UserCard;
