import PropTypes, { arrayOf } from "prop-types";
import userTypes from "../user";
import assessementTypes from "./assessment";

const { string, number, shape, bool } = PropTypes;

const questionTypes = shape({
  answers: arrayOf(
    shape({
      id: number,
      answer: string,
    })
  ),
  id: number,
  description: string,
  previous_response: shape({
    id: number,
    question_id: number,
    trial_id: number,
    written_response: string,
    selected_options: arrayOf(number),
  }),
  question_type: string,
  question_number: number,
  question_string: string,
  short_question_string: string,
});

const trialTypes = shape({
  assessment: assessementTypes,
  completed_at: string,
  created_at: string,
  current_question: questionTypes,
  expired: bool,
  expires_at: string,
  id: number,
  in_progress: bool,
  started: bool,
  started_at: string,
  total_questions: number,
  trial_ended: bool,
  trial_path: string,
  trial_results: shape({
    attempt_number: number,
    correct_string: string,
    expired: bool,
    failed: bool,
    finished_at: string,
    passed: bool,
    percent: string,
    score: number,
    time_elapsed: number, // if complete, time elapsed in seconds
    time_elapsed_string: string,
    total_attempts: number,
    total_correct_responses: number,
    total_number_of_questions: number,
  }),
  updated_at: string,
  user: userTypes,
});

export default trialTypes;
export { questionTypes, trialTypes };
