import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Popover } from "@mui/material";
import { useFormContext } from "react-hook-form";
import Icons from "../../../icons";
import {
  AddNewResourceButton as AddQuestionButton,
  IconContainer,
} from "../../AddWikiResourceModal";
import { EditAssessmentContext } from "../EditAssessment";

const { PlusIcon, BulletpointsIcon, CheckboxSquircleIcon, PencilCircleIcon } =
  Icons;

const AddAssessmentQuestion = () => {
  const { QUESTION_TYPES } = useContext(EditAssessmentContext);
  const { getValues, setValue } = useFormContext();

  const [SINGLE, WRITTEN, MULTIPLE] = QUESTION_TYPES;

  // Add new question logic
  // get the position of the last question in the list
  const getLastPosition = () => {
    const questions = getValues("questions");
    const lastQuestion = questions[questions.length - 1];
    return lastQuestion ? lastQuestion.position + 1 : 1;
  };

  // construct new question and add to "questions" array in form
  const handleAddQuestion = (type) => {
    const newQuestion = {
      question_type: type,
      position: getLastPosition(),
      description: "",
    };

    if (type === SINGLE || type === MULTIPLE) {
      newQuestion.options = [];
      newQuestion.correct_options = [0];
    }

    if (type === WRITTEN) {
      newQuestion.correct_keywords = "";
    }

    setValue("questions", [...getValues("questions"), newQuestion]);
  };

  // Popover logic
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // open/close state of popover could be tricky. if needed, check out the
    // material-ui-popup-state package: https://github.com/jcoreio/material-ui-popup-state.
    // using this simple click event listener for now.
    const closePopover = (e) => {
      if (anchorEl && !anchorEl.contains(e.target)) {
        setAnchorEl(null);
      }
    };

    document.addEventListener("click", closePopover);
    return () => document.removeEventListener("click", closePopover);
  });

  return (
    <AddQuestionButton
      type="button"
      onClick={handlePopover}
      id="add-assessment-question-button"
    >
      <IconContainer>
        <PlusIcon height="16px" width="16px" />
      </IconContainer>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <PopoverContainer id="add-assessment-question-menu">
          <PopoverBody>
            <PopoverHeader>Select a Question Type</PopoverHeader>
            <PopoverContent>
              <PopoverContentItem
                onClick={() => {
                  handleAddQuestion(SINGLE);
                }}
              >
                <PopoverIconContainer>
                  <BulletpointsIcon width="45px" height="45px" color="#000" />
                </PopoverIconContainer>
                Single Answer
              </PopoverContentItem>
              <PopoverContentItem
                onClick={() => {
                  handleAddQuestion(WRITTEN);
                }}
              >
                <PopoverIconContainer>
                  <PencilCircleIcon width="45px" height="45px" color="#000" />
                </PopoverIconContainer>
                Written Answer
              </PopoverContentItem>
              <PopoverContentItem
                onClick={() => {
                  handleAddQuestion(MULTIPLE);
                }}
              >
                <PopoverIconContainer>
                  <CheckboxSquircleIcon
                    width="45px"
                    height="45px"
                    color="#000"
                  />
                </PopoverIconContainer>
                Multiple Answer
              </PopoverContentItem>
            </PopoverContent>
          </PopoverBody>
        </PopoverContainer>
      </StyledPopover>
    </AddQuestionButton>
  );
};

const StyledPopover = styled(Popover)`
  z-index: 1000;
  .MuiPopover-paper {
    background-color: transparent;
    box-shadow: none;
    transform: translateY(45px) !important;
  }
`;

const PopoverContainer = styled.div`
  width: 425px;
  padding-bottom: 20px;
  background-color: transparent;
`;

const PopoverBody = styled.div`
  position: relative;
  width: 425px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
`;

const PopoverHeader = styled.div`
  height: 50px;
  background-image: url(https://tp-assets.sfo2.cdn.digitaloceanspaces.com/eod-report/EOD_Modal_Header.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-family: "Manrope";
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  height: 165px;
  align-items: center;
`;

const PopoverContentItem = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  font-family: "Arial";
  line-height: 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  align-items: center;
  height: auto;
`;

const PopoverIconContainer = styled(IconContainer)`
  background-color: transparent;
  height: 64px;
  width: 64px;
`;

export default AddAssessmentQuestion;
