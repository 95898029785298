import React from "react";
import { getiPhoneModel, getiPadModel } from "./iphone.js";
import PropTypes from "prop-types";

class BuildInstall extends React.Component {
  constructor(props) {
    super(props);
    this.state = { build: [] };
  }

  downloadBuildInfo = (passcode) => {
    var params = {};
    if (passcode) {
      params["passcode"] = passcode;
    }
    $.ajax({
      url: this.props.url,
      dataType: "json",
      cache: false,
      data: params,
      success: function (data) {
        if (data.error) {
          this.setState({ error_message: data.error });
        } else {
          this.setState({ build: data });
        }
      }.bind(this),
      error: function (xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this),
    });
  };

  componentDidMount() {
    this.downloadBuildInfo();
  }

  resetForm = () => {
    this.setState({ error_message: null });
  };

  render() {
    var content;
    if (this.state.error_message) {
      content = (
        <BuildInstallError
          error={this.state.error_message}
          onPasscodeReset={this.resetForm}
        />
      );
    } else if (this.state.build.url) {
      content = <BuildInstallAuthorized build={this.state.build} />;
    } else {
      content = (
        <BuildInstallUnauthorized
          build={this.state.build}
          onPasscodeSubmit={this.downloadBuildInfo}
        />
      );
    }
    return (
      <div id="installForm" className="col s12 m6 offset-m3 l6 offset-l3">
        <div className="card-panel">
          <div className="card-content">{content}</div>
        </div>
      </div>
    );
  }
}

class BuildInstallAuthorized extends React.Component {
  constructor(props) {
    super(props);
    this.state = { installed: false };
  }

  componentDidMount() {}

  rawMarkup = () => {
    // eslint-disable-next-line no-undef
    var rawMarkup = marked(this.props.build.note ? this.props.build.note : "", {
      sanitize: true,
    });
    return { __html: rawMarkup };
  };

  onInstall = () => {
    this.setState({ installed: true });
    this.registerInstall();
  };

  registerInstall = () => {
    if (this.props.build) {
      var params = {};
      params["iphone"] = getiPhoneModel();
      params["ipad"] = getiPadModel();
      params["build_id"] = this.props.build.id;
      params["application_id"] = this.props.build.application_id;
      $.ajax({
        url: "/v1/events.json",
        dataType: "json",
        method: "POST",
        cache: false,
        data: params,
        success: function () {}.bind(this),
        error: function (xhr, status, err) {
          console.error(this.props.url, status, err.toString());
        }.bind(this),
      });
    }
  };

  render() {
    var content;
    if (this.state.installed === false) {
      var image_url = null;
      if (this.props.build.application) {
        image_url = this.props.build.application.logo_url;
        if (!image_url) {
          image_url = this.props.build.application.icon.url;
        }
      }
      var app_name = this.props.build.application
        ? this.props.build.application.name
        : this.props.build.build_name;
      content = (
        <div>
          <a
            className="install-area"
            href={this.props.build.url}
            onClick={this.onInstall}
          >
            <p>Tap to install</p>
            <p>{app_name}</p>
          </a>
          <p id="app-title">{this.props.build.name}</p>
          <p id="app-version">
            <span className="app-attr-name">Version:</span>{" "}
            {this.props.build.version}
          </p>
          <p id="app-build">
            <span className="app-attr-name">Build:</span>
            {this.props.build.build_number}
          </p>
          <div id="app-notes" className="container">
            <span dangerouslySetInnerHTML={this.rawMarkup()} />
          </div>
        </div>
      );
    } else {
      content = (
        <p>
          Check your device home screen (iOS) or downloads folder (Android) to
          find your build.
        </p>
      );
    }
    return <div>{content}</div>;
  }
}

class BuildInstallError extends React.Component {
  constructor(props) {
    super(props);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    var content;
    if (this.props.error == "Sorry, that password was incorrect.") {
      content = (
        <button
          type="button"
          className="btn white-text btn-default"
          onClick={this.props.onPasscodeReset}
        >
          Click here to Retry{" "}
        </button>
      );
    }
    return (
      <div>
        <p className="text-center" id="error_msg">
          {this.props.error}
        </p>
        <p>{content}</p>
      </div>
    );
  }
}

class BuildInstallUnauthorized extends React.Component {
  constructor(props) {
    super(props);
    this.passcode = React.createRef();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.onPasscodeSubmit && this.passcode && this.passcode.current) {
      this.props.onPasscodeSubmit(this.passcode.current.value.trim());
    }
  };

  render() {
    return (
      <div>
        <form className="" onSubmit={this.handleSubmit}>
          <div className="password">
            <div>
              <p>Enter Build Password</p>
            </div>
            <input
              className="form-control"
              type="password"
              id="build[passcode]"
              placeholder="Password"
              ref={this.passcode}
              required
            />
          </div>
          <div className="form-group actions">
            <button type="submit" className="btn btn_bc_blue white-text">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default BuildInstall;

BuildInstall.propTypes = {
  url: PropTypes.string,
  build: PropTypes.object,
  data: PropTypes.object,
  onPasscodeSubmit: PropTypes.func,
};

BuildInstallAuthorized.propTypes = {
  url: PropTypes.string,
  build: PropTypes.object,
  data: PropTypes.object,
  onPasscodeSubmit: PropTypes.func,
};

BuildInstallError.propTypes = {
  error: PropTypes.any,
  onPasscodeReset: PropTypes.bool,
};

BuildInstallUnauthorized.propTypes = {
  url: PropTypes.string,
  build: PropTypes.object,
  data: PropTypes.object,
  onPasscodeSubmit: PropTypes.func,
};
