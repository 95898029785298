import { detectOverflow, offset } from "@floating-ui/react";

const detectOffsetMiddleware = {
  name: "detectOffset",
  async fn(state) {
    /* create a custom placement for the check-in pop-up
     * problem: our Checkout cell is contained inside of the React-Table virtualized grid,
     * which has an overflow-y: scroll property, which will cause pop-ups on the top and
     * bottom rows to be cut off.
     * to fix this, use floating-ui middleware to determine if the pop-up element is overflowing
     * the reference element (tableContainer below), and if so, calculate by how much. this
     * value is then passed to the offset middleware, which will then reposition the pop-up, such
     * that if it is overflowing on top, it will be shifted down with the top of the pop-up no
     * higher than the top of the bounding rectangle of the reference element; similar shift for
     * bottom.
     * see:
     *   detectOverflow: https://floating-ui.com/docs/detectOverflow
     *   offset: https://floating-ui.com/docs/offset
     *   floating-ui and react: https://floating-ui.com/docs/react
     */

    const tableContainer = document.querySelector(
      "div.ReactVirtualized__Grid.ReactVirtualized__List"
    );

    if (!tableContainer) return {};

    const overflow = await detectOverflow(state, {
      boundary: tableContainer,
    });

    const calculateYOffset = () => {
      let yOffset = 0;
      if (overflow.top > 0) {
        yOffset = overflow.top;
      }
      if (overflow.bottom > 0) {
        yOffset = -overflow.bottom;
      }
      return yOffset;
    };
    return {
      data: {
        overflow,
        offsetValue: calculateYOffset(),
      },
    };
  },
};

export default [
  detectOffsetMiddleware,
  offset((state) => {
    return {
      crossAxis: state.middlewareData?.detectOffset?.offsetValue || 0,
    };
  }),
];
