import React, {useState, Suspense, useEffect} from 'react'
import MediaItem from './media_item'
import { AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import MediaTableHeader from "./media_table_header.jsx";

function MediaList(props) {
  const [SortColumn, setSortColumn] = useState("");
  const [SortDirection, setSortDirection] = useState("ASC");
  const [SortItems, setSortItems] = useState(props.media_items);


function sortMedia(e) {
  var media = props.media_items
  var column = null
  if (e) {
    column = e.target.attributes.value.value
  }
    var direction = SortColumn && column === SortColumn ? (SortDirection === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
    var sortedData = media.sort((a, b) => {
      if (column === 'name') {
        return a.file_name.localeCompare(b.file_name)
       } else if (column === 'created_at'){
        return a.id - b.id;
      } else if (column === 'owner') {
        return a.owner_name.localeCompare(b.owner_name)
      } else if (column === 'size'){
        return a.file_size - b.file_size;
      } else {
        return a.id - b.id;
      }
    });
    if (direction === 'DESC') {
      sortedData.reverse();
    }
    setSortColumn(column)
    setSortDirection(direction)
    setSortItems(sortedData)
  }

  function headerRowRenderer(props) {
    return ( <MediaTableHeader sortMedia={sortMedia} project={props.project} props={props} column={SortColumn} direction={SortDirection} sortItems={SortItems} /> )
   }

   useEffect(() => {
    sortMedia(null)
  }, [props.media_items]);

  function noRowsRenderer() {
    return (
      <div style={{display: "flex", flexDirection: "row", height: "100%", alignItems: "center", justifyContent: "center"}}>
        <p>No items found</p>
      </div>
    );
  }

  function customRowRenderer(innerProps) {
    var value = SortItems.find((x) => x.id == innerProps.rowData.id)
    if (value) {
    return (
      <Suspense
        style={innerProps.style}
        key={innerProps.key}
        fallback={
          <div id="ticketLoader" key={Math.random()}>
            <p>Loading…</p>
          </div>
        }
      >
        <MediaItem
          {...innerProps}
          handleSelected={props.handleSelected}
          mediaHandler={props.mediaHandler}
          style={innerProps.style}
          editorHandler={props.editorHandler}
          selected={props.selectedImages.includes(value.id)}
          key={innerProps.key}
          id={"media_item_" + value.id}
          dataId={value.id}
          item={value}
        />
      </Suspense>
    );
    }
  }

  return (
    <div style={{ display: "flex", flex: 1, width: "100%", minHeight: "70vh" }}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            id="media-table"
            width={width}
            height={height}
            headerHeight={25}
            className="striped highlight"
            rowHeight={50}
            sortBy={SortColumn}
            ref={props.listRef}
            noRowsRenderer={noRowsRenderer}
            sortDirection={SortDirection}
            headerRowRenderer={headerRowRenderer}
            overscanRowCount={2}
            rowRenderer={customRowRenderer}
            rowCount={props.media_items.length}
            rowGetter={({index}) => props.media_items[index]}
          >
            <Column label="Name" dataKey="name" width={60} />
            <Column
              headerClassName="flexBasisAuto"
              width={70}
              label="Owner"
              dataKey="owner_id"
            />
            <Column
              width={360}
              flexGrow={1}  
              label="Created At"
              dataKey="created_at"
            />
            <Column
              width={60}
              label="size"
              dataKey="file_size"
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
}

export default MediaList