const baseColor = "#519acc";
const darkGrey = "#6e6e6e";
const lightGrey = "#f0f0f0";
const medLightG = "#d1d1d1";

const MultiSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: state.isFocused
      ? `1px 1px 1px 0px transparent`
      : `1px 1px 3px 0px ${lightGrey}`,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: state.menuIsOpen
      ? baseColor
      : state.isFocused
      ? baseColor
      : "lightgray",
    minHeight: "42px",
    maxHeight: "42px",
    "&:hover": {
      color: `${baseColor}`,
      borderColor: `${baseColor}`,
    },
  }),
  container: (styles) => ({
    ...styles,
    width: "250px",
    fontFamily: "Arial",
    fontSize: "14px",
    margin: "5px 0",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: "100%",
    marginTop: "0",
    backgroundColor: "transparent",
  }),
  input: (styles) => ({
    ...styles,
    boxShadow: "none !important",
    margin: "0",
    padding: "0",
    top: "-9px",
    color: "transparent",
    position: "relative",
  }),
  menu: (styles, state) => ({
    ...styles,
    transform: "translateX(-25px)",
    width: "300px",
    top: "42px",
    borderRadius: "5px",
    boxShadow: "0 0 9px 2px hsla(0,0%,0%,0.3), 0 4px 11px hsla(0,0%,0%,0.1)",
    zIndex: 3,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    marginLeft: state.hasValue ? "30px" : "0",
    pointerEvents: "none",
    fontFamily: "Arial",
    display: "none",
  }),
  menuList: (styles) => ({ ...styles, borderRadius: "5px" }),
  valueContainer: (styles, state) => ({
    ...styles,
    maxHeight: "180px",
    overflow: "hidden auto",
    paddingTop: state.hasValue ? "10px" : "",
    order: 2,
    top: state.hasValue ? "30px" : "",
    left: state.hasValue ? "-10px" : "",
    position: state.hasValue ? "absolute" : "",
    width: "400px",
    display: "flex",
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    color: state.isFocused ? `${baseColor}` : `${medLightG}`,
    maxHeight: "42px",
    "&:hover": {
      color: `${baseColor}`,
    },
  }),
  dropdownIndicator: (styles, state) => {
    return {
      ...styles,
      color: state.isFocused
        ? `${baseColor} !important`
        : state.isHovered
        ? `${baseColor} !important`
        : "inherit",
      cursor: "pointer",
      "&:hover": {
        color: `${baseColor} !important`,
      },
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "400",
      textAlign: "center",
      borderRadius: "15px",
      fontSize: "13px",
      color: "white",
      backgroundColor: baseColor,
    };
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      color: "white",
      padding: "0",
      paddingLeft: "8px",
    };
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      borderRadius: "10px",
      color: "white",
      cursor: "pointer",
      paddingRight: "4px",
      backgroundColor: "transparent !important",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "white"
        : isSelected
        ? baseColor
        : isFocused
        ? baseColor
        : "white",
      color: isFocused ? "white" : isSelected ? "white" : "#242424",
      cursor: isDisabled ? "not-allowed" : "default",
      paddingLeft: "25px",
    };
  },
};

export default MultiSelectStyles;
