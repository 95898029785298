import React, { useState, useEffect } from "react";
import { navIcons } from "../components/common/icons";

const styles = {
  imgDecor: {
    padding: "5px",
    minHeight: "50px",
    height: "40px",
    width: "40px",
  },
  activeIcon: {
    color: "#519acc",
  },
};

const useSidebarLinks = (pageName, project, currentUser) => {
  const [links, setLinks] = useState([]);
  const [bottomLinks, setBottomLinks] = useState([]);
  const { id, has_bugs, media, has_test_cases } = project || {};

  const dashboardLink = {
    id: "dash",
    dataActive: pageName === "dashboard",
    pageName: "dashboard",
    href: `/projects/${id}/dashboard`,
    ariaLabel: "Dashboard",
    icon: (
      <navIcons.Dashboard
        style={{
          ...styles.imgDecor,
        }}
        active={pageName === "dashboard"}
      />
    ),
    text: "Dashboard",
  };

  const libraryLink = {
    id: "lib",
    dataActive: pageName === "library",
    pageName: "library",
    href: `/projects/${id}/library`,
    ariaLabel: "Library",
    icon: (
      <navIcons.LibraryIcon
        style={{
          pointerEvents: "none",
          padding: "5px 0px",
          height: "40px",
          minHeight: "50px",
          width: "40px",
        }}
        className="icons"
        active={pageName === "library"}
      />
    ),
    text: "Library",
  };

  const bugsLink = {
    id: "bugs",
    dataActive: pageName === "tickets",
    pageName: "tickets",
    href: id !== 0 ? `/projects/${id}/bugs` : "/bugs",
    ariaLabel: "Bugs",
    icon: (
      <navIcons.Bugs
        style={{
          ...styles.imgDecor,
        }}
        className="icons"
        active={pageName === "tickets"}
      />
    ),
    text: "Bugs",
  };

  const testPassesLink = {
    id: "testPass",
    dataActive: pageName === "test_passes",
    pageName: "test_passes",
    href: `/projects/${id}/test_passes/`,
    ariaLabel: "Test Passes",
    icon: (
      <navIcons.TestPasses
        style={{
          ...styles.imgDecor,
          height: "40px",
          width: "40px",
          padding: "0px",
        }}
        className="icons"
        active={pageName === "test_passes"}
      />
    ),
    text: "Test Passes",
  };

  const testCasesLink = {
    id: "testCases",
    dataActive: pageName === "test_suites",
    pageName: "test_suites",
    href: id !== 0 ? `/projects/${id}/test_suites/` : "test_suites/",
    ariaLabel: "Test Cases",
    icon: (
      <navIcons.TestDirectories
        style={{
          ...styles.imgDecor,
        }}
        className="icons"
        active={pageName === "test_suites"}
      />
    ),
    text: "Test Cases",
  };

  const deviceLabLink = {
    id: "deviceLab",
    dataActive: pageName === "device_lab",
    pageName: "device_lab",
    href: id !== 0 ? `/projects/${id}/device_lab/` : "device_lab",
    ariaLabel: "Device Lab",
    icon: (
      <navIcons.DeviceLab
        style={{
          ...styles.imgDecor,
          height: "40px",
          width: "40px",
          padding: "5px 0px",
        }}
        className="icons"
        active={pageName === "device_lab"}
      />
    ),
    text: "Device Lab",
  };

  const performanceLink = {
    id: "performance",
    dataActive: pageName === "performance",
    pageName: "performance",
    href:
      currentUser.role === "admin"
        ? "/performance"
        : `/performance/${currentUser.id}`,
    ariaLabel: "Performance",
    icon: (
      <navIcons.Skills
        style={{
          ...styles.imgDecor,
          padding: "0px",
          stroke: pageName === "performance" ? "#519acc" : "#444344",
        }}
        className="icons"
        active={pageName === "performance"}
      />
    ),
    text: "Performance",
  };

  const trialistLink = {
    id: "trialist",
    dataActive: pageName === "trialist",
    pageName: "trialist",
    key: "trialist",
    href: "/trialist",
    ariaLabel: "Trialist",
    icon: (
      <navIcons.AdminPanel
        style={{
          ...styles.imgDecor,
        }}
        className="icons"
        active={pageName === "trialist"}
      />
    ),
    text: "Trialist",
  };

  const adminLink = {
    id: "admin-link",
    dataActive: pageName === "admin_panel",
    key: "admin",
    href: id !== 0 ? `/projects/${id}/admin_panel/` : "/admin_panel",
    ariaLabel: "Admin Panel",
    icon: (
      <navIcons.AdminPanel
        style={{
          ...styles.imgDecor,
        }}
        className="icons"
        active={pageName === "admin_panel"}
      />
    ),
    text: "Admin Panel",
  };

  const accountLink = {
    id: "account-link",
    dataActive: pageName === "profile",
    key: "account",
    href: "/profile",
    icon: (
      <img
        alt="account icon"
        id="sideBarUserImg"
        style={{ borderRadius: "50%", padding: "3px" }}
        src={currentUser.avatar_url || currentUser.avatar_image}
        width="40px"
        height="40px"
      />
    ),
    text: "Account",
  };

  const wikiLink = {
    id: "wiki-link",
    dataActive: pageName === "wiki",
    key: "wiki",
    href: "/wiki",
    ariaLabel: "Wiki",
    icon: (
      <navIcons.BookIcon
        style={{
          position: "relative",
          top: "3px",
          ...styles.imgDecor,
        }}
        className="icons"
        active={pageName === "wiki"}
      />
    ),
    text: "Wiki",
  };

  const helpLink = {
    id: "help-link",
    dataActive: pageName === "help",
    href: "/help",
    ariaLabel: "Help",
    key: "help",
    icon: (
      <navIcons.HelpIcon
        style={{
          height: "36px",
          width: "43px",
        }}
        className="icons"
      />
    ),
    text: "Help Center",
  };

  useEffect(() => {
    const linkConfig = [
      {
        condition: currentUser.role === "trialist",
        link: trialistLink,
      },
      {
        condition: currentUser.role !== "trialist",
        link: dashboardLink,
      },
      {
        condition: has_bugs,
        link: bugsLink,
      },
      {
        condition:
          has_test_cases &&
          (currentUser.role === "admin" ||
            currentUser.role === "lead" ||
            (currentUser.additional_permissions &&
              currentUser.additional_permissions.includes("Test Cases"))),
        link: testCasesLink,
      },
      {
        condition:
          has_test_cases &&
          (currentUser.role === "admin" ||
            currentUser.role === "lead" ||
            currentUser.role === "tester" ||
            currentUser.role === "trialist" ||
            (currentUser.additional_permissions &&
              currentUser.additional_permissions.includes("Test Passes"))),
        link: testPassesLink,
      },
      {
        condition: currentUser.role !== "trialist",
        link: deviceLabLink,
      },
      {
        condition: media && currentUser.role !== "trialist",
        link: libraryLink,
      },
      {
        condition: currentUser.role === "admin",
        link: performanceLink,
      },
      {
        condition:
          currentUser.role !== "client" && currentUser.role !== "trialist",
        link: wikiLink,
      },
      {
        condition: currentUser.role === "admin",
        link: adminLink,
      },
    ];

    const bottomLinkConfig = [
      {
        condition: true,
        link: accountLink,
      },
      {
        condition: true,
        link: helpLink,
      },
    ];

    const filteredLinks = linkConfig
      .filter((config) => config.condition)
      .map((config) => config.link);

    const filteredBottomLinks = bottomLinkConfig
      .filter((config) => config.condition)
      .map((config) => config.link);

    setLinks(filteredLinks);
    setBottomLinks(filteredBottomLinks);
  }, [pageName, project, currentUser]);

  if (links.length === 0) {
    return <div>Loading...</div>;
  }

  return { links, bottomLinks };
};

export default useSidebarLinks;
