import React from "react";
import styled from "styled-components";
import useDeviceLab from "@hooks/data/useDeviceLab";
import DeviceFuzzySearch from "./DeviceFuzzySearch";
import PagingControls from "./PagingControls";
import FilterByTeam from "./FilterByTeam";
import FilterByPlatform from "./FilterByPlatform";
import FilterByStatus from "./FilterByStatus";
import ResetFiltersButton from "./ResetFiltersButton";

const DeviceLabFiltersAndSearch = () => {
  const { filters, list, paging } = useDeviceLab();

  if (!filters || !list || !paging) {
    return null;
  }

  return (
    <Container>
      <SearchContainer>
        <DeviceFuzzySearch />
        <PagingControls />
      </SearchContainer>
      <FiltersContainer>
        <FilterByTeam />
        <FilterByPlatform />
        <FilterByStatus />
        <ResetFiltersButton />
      </FiltersContainer>
    </Container>
  );
};

const MOVE_TO_COLUMN_BREAKPOINT = 1275;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  @media (max-width: ${MOVE_TO_COLUMN_BREAKPOINT}px) {
    flex-direction: column-reverse;
    gap: 10px;
    align-items: center;
    justify-content: center;

    > * {
      width: 100%;
    }
  }
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${MOVE_TO_COLUMN_BREAKPOINT}px) {
    width: 100%;
    justify-content: center;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${MOVE_TO_COLUMN_BREAKPOINT}px) {
    width: 100%;
    justify-content: center;
  }
`;

export default DeviceLabFiltersAndSearch;
