import React from "react";
import PropTypes from "prop-types";

const Dashboard = ({ active, ...props }) => {
  const pathClassName = active ? "active-path" : "Stats2_svg__cls-1";

  return (
    <svg
      id="Stats2_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      <path className={pathClassName} d="M28.91 10.78a24 24 0 1024 24h-24z" />
      <path className={pathClassName} d="M35.09 5.13v24h24a24 24 0 00-24-24z" />
    </svg>
  );
};

Dashboard.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Dashboard;
