import React from "react";
import PropTypes from "prop-types";

class SwitcherOption extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handlerClick = this.handlerClick.bind(this);
    this.optionMouseEnter = this.optionMouseEnter.bind(this);
    this.optionMouseLeave = this.optionMouseLeave.bind(this);
  }

  handlerClick() {
    this.props.handler(this.props.option.value);
  }

  optionMouseEnter(e) {
    var target = e.target;
    if (target.dataset.tooltip.length >= 15) {
      var rect = target.getBoundingClientRect();
      var popUpElem = document.createElement("div");
      popUpElem.setAttribute("class", `test-pass-pop-up`);
      popUpElem.style.position = "fixed";
      popUpElem.style.transition = "all .666s";
      popUpElem.style.top = `${rect.top}px`;
      popUpElem.style.zIndex = "1000";
      popUpElem.style.width = "fit-content";
      popUpElem.style.height = "28px";
      popUpElem.style.fontSize = "10pt";
      popUpElem.style.backgroundColor = "#000000b8";
      popUpElem.style.color = "white";
      popUpElem.style.textAlign = "center";
      popUpElem.style.paddingLeft = "5px";
      popUpElem.style.paddingRight = "5px";
      popUpElem.innerText = `${target.dataset.tooltip}`;
      var element = document.querySelector("body");
      element.appendChild(popUpElem);
      popUpElem.style.left = `${rect.right}px`;
    }
  }

  optionMouseLeave() {
    document.querySelectorAll(".test-pass-pop-up").forEach((n) => n.remove());
  }

  render() {
    var option = this.props.option;
    return (
      <div
        key={this.props.index}
        className={this.props.classList ? this.props.classList : ""}
        style={{
          height: "35px",
          marginTop: this.props.index === 0 ? "10px" : "0px",
          marginBottom:
            this.props.index === this.props.length - 1 ? "15px" : "0px",
          opacity: "0",
          transform: "translateX(-14px)",
          transition: `opacity 0.3s 0.05s, transform 0.3s 0.05s`,
        }}
      >
        <label style={{ cursor: "pointer" }}>
          <input
            onChange={
              this.props.handler
                ? this.handlerClick
                : console.log(`no handler attached to ${option.label}`)
            }
            className="filled-in dash-time-option"
            checked={this.props.checked ? this.props.checked : false}
            value={option.value ? option.value : ""}
            type="checkbox"
          />
          <span
            onMouseEnter={this.optionMouseEnter}
            onMouseLeave={this.optionMouseLeave}
            data-tooltip={option.label ? option.label : ""}
            style={{
              whiteSpace: "nowrap",
              fontSize: "11pt",
              color: option.checked ? "#519acc" : "black",
              paddingLeft: "0px",
              verticalAlign: "13px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {option.label ? option.label : ""}
          </span>
        </label>
      </div>
    );
  }
}

export default SwitcherOption;

SwitcherOption.propTypes = {
  index: PropTypes.number,
  checked: PropTypes.bool,
  classList: PropTypes.string,
  label: PropTypes.string,
  option: PropTypes.object,
  length: PropTypes.number,
  handler: PropTypes.any,
};
