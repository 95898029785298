import React from "react";
import ReduxProvider from "@rdx/utils/Provider";
import DeviceLab from "./device_lab";

const Devices = (props) => {
  return (
    <ReduxProvider {...props}>
      <DeviceLab {...props} />
    </ReduxProvider>
  );
};

export default Devices;
