import React from "react";
import { Pie, Doughnut } from "react-chartjs-2";
import _ from "lodash";
import moment from "moment";

moment.locale("en-US");

const { countBy } = _;

class DeviceLabStats extends React.Component {
  constructor(props) {
    var timeZone = new Date().getTimezoneOffset();
    function makeChart() {
      return {
        chartOptions: {
          legend: {
            display: false,
          },
          animation: {
            duration: 800,
            easing: "easeInOutSine",
          },
          labels: {
            display: true,
          },
          layout: {
            padding: {
              top: 30,
              left: 0,
              right: 0,
              bottom: 30,
            },
          },
          plugins: {
            datalabels: {
              anchor: "end",
              padding: 0,
              display: "auto",
              align: "end",
              offset: 10,
              formatter: function (value, context) {
                return context.chart.data.labels[context.dataIndex];
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                var label = data.labels[tooltipItem.index] || "";
                label +=
                  " : " +
                  Math.abs(
                    Math.round(
                      (data.datasets[tooltipItem.datasetIndex].data[
                        tooltipItem.index
                      ] /
                        total) *
                        100
                    )
                  );
                label += "%";
                return label;
              },
            },
          },
        },
        chartData: {
          labels: ["place"],
          datasets: [
            {
              label: "Common Devices",
              data: [0],
              backgroundColor: ["#ea6159", "#ebc34c", "#f09d3e", "#6eb782"],
            },
          ],
        },
      };
    }
    super(props);

    this.state = {
      data: this.props.data,
      permData: this.props.data,
      filtered: false,
      filteredData: [],
      search: "",
      pie1: makeChart(),
      pie2: makeChart(),
      pie3: makeChart(),
      pie4: makeChart(),
      pie5: makeChart(),
      pie6: makeChart(),
      pie7: makeChart(),
      ring1: {
        chartOptions: {
          legend: {
            display: false,
          },
          responsive: false,
          animation: {
            duration: 800,
            easing: "easeInOutSine",
          },
          cutoutPercentage: 15,
          labels: {
            display: true,
          },
          layout: {
            padding: {
              top: 0,
              left: -50,
              right: -50,
              bottom: 0,
            },
          },
          plugins: {
            datalabels: {
              anchor: "center",
              align: "center",
              font: {
                weight: "bold",
                size: 12,
              },
              formatter: function (value, context) {
                return context.dataset.labels[context.dataIndex];
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                var label = data.labels[tooltipItem.index] || "";
                label +=
                  data.datasets[tooltipItem.datasetIndex].labels[
                    tooltipItem.index
                  ];
                label +=
                  " : " +
                  Math.abs(
                    Math.round(
                      (data.datasets[tooltipItem.datasetIndex].data[
                        tooltipItem.index
                      ] /
                        total) *
                        100
                    )
                  );
                label += "%";
                label +=
                  " (" +
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ] +
                  ")";

                return label;
              },
            },
          },
        },
        chartData: {
          labels: ["place"],
          datasets: [
            {
              labels: ["Common Devices"],
              label: "moo",
              data: [0],
              borderWidth: 0,
              borderAlign: "inner",
              datalabels: {
                align: function (context) {
                  var indexes = [147, 117, 122];
                  if (indexes.includes(context.dataIndex)) {
                    return "start";
                  } else {
                    return "center";
                  }
                },
                display: function (context) {
                  var indexes = [
                    184, 174, 171, 169, 151, 141, 123, 122, 97, 66, 30,
                  ];
                  if (indexes.includes(context.dataIndex)) {
                    return true;
                  } else {
                    return false;
                  }
                },
                font: {
                  weight: "bold",
                  size: 12,
                },
                formatter: function (value, context) {
                  return context.dataset.labels[context.dataIndex];
                },
              },
              backgroundColor: function (context) {
                var word = context.dataset.labels[context.dataIndex];
                if (
                  word.includes("iPhone") ||
                  word.includes("Mac") ||
                  word.includes("iPad") ||
                  word.includes("iPod") ||
                  word.includes("Apple")
                ) {
                  return "#b4b5d7";
                } else if (
                  word.includes("Galaxy") ||
                  word.includes("Samsung")
                ) {
                  return "#a5cce3";
                } else if (word.includes("Nexus") || word.includes("Google")) {
                  return "#97d390";
                } else if (word.includes("Moo")) {
                  return "#b4b5d7";
                } else {
                  return "#fda460";
                }
              },
            },
          ],
        },
      },
      ring2: {
        chartOptions: {
          legend: {
            display: false,
          },
          animation: {
            duration: 800,
            easing: "easeInOutSine",
          },
          labels: {
            display: true,
          },
          layout: {
            padding: {
              top: 40,
              left: 40,
              right: 40,
              bottom: 40,
            },
          },
          plugins: {
            datalabels: {
              display: "auto",
              anchor: "center",
              align: "center",
              font: {
                weight: "bold",
                size: 12,
              },
              formatter: function (value, context) {
                return context.dataset.labels[context.dataIndex];
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                var label = data.labels[tooltipItem.index] || "";
                label +=
                  data.datasets[tooltipItem.datasetIndex].labels[
                    tooltipItem.index
                  ];
                label +=
                  " : " +
                  Math.abs(
                    Math.round(
                      (data.datasets[tooltipItem.datasetIndex].data[
                        tooltipItem.index
                      ] /
                        total) *
                        100
                    )
                  );
                label += "%";
                label +=
                  " (" +
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ] +
                  ")";

                return label;
              },
            },
          },
        },
        chartData: {
          labels: ["place"],
          borderWidth: 1,
          datasets: [
            {
              labels: ["Common Devices"],
              label: "moo1",
              data: [0],
              datalabels: {
                display: function (context) {
                  var indexes = [
                    123, 122, 38, 36, 32, 28, 24, 16, 12, 10, 9, 7, 6,
                  ];
                  if (indexes.includes(context.dataIndex)) {
                    return true;
                  } else {
                    return false;
                  }
                },
                anchor: "center",
                align: "center",
                font: {
                  weight: "bold",
                  size: 12,
                },
                formatter: function (value, context) {
                  return context.dataset.labels[context.dataIndex];
                },
              },
              backgroundColor: function (context) {
                if (context.dataset.labels[context.dataIndex]) {
                  if (
                    context.dataset.labels[context.dataIndex].includes("iOS")
                  ) {
                    return "#d5d5e8";
                  } else {
                    return "#bfd6ed";
                  }
                } else {
                  return "bfd6ed";
                }
              },
            },
          ],
        },
      },
      ring3: {
        chartOptions: {
          legend: {
            display: false,
          },
          animation: {
            duration: 800,
            easing: "easeInOutSine",
          },
          labels: {
            display: true,
          },
          layout: {
            padding: {
              top: 40,
              left: 40,
              right: 40,
              bottom: 40,
            },
          },
          plugins: {
            datalabels: {
              display: function (context) {
                var indexes = [184, 174, 171, 169, 151, 141, 132, 97, 66, 30];
                if (indexes.includes(context.dataIndex)) {
                  return true;
                } else {
                  return false;
                }
              },
              anchor: "center",
              align: "center",
              font: {
                weight: "bold",
                size: 12,
              },
              formatter: function (value, context) {
                return (
                  context.dataset.labels[context.dataIndex] + context.dataIndex
                );
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                var label = data.labels[tooltipItem.index] || "";
                label +=
                  data.datasets[tooltipItem.datasetIndex].labels[
                    tooltipItem.index
                  ];
                label +=
                  " : " +
                  Math.abs(
                    Math.round(
                      (data.datasets[tooltipItem.datasetIndex].data[
                        tooltipItem.index
                      ] /
                        total) *
                        100
                    )
                  );
                label += "%";
                label +=
                  " (" +
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ] +
                  ")";

                return label;
              },
            },
          },
        },
        chartData: {
          labels: ["place"],
          borderWidth: 1,
          datasets: [
            {
              labels: ["Common Devices"],
              label: "moo2",
              data: [0],
              datalabels: {
                display: "auto",
                anchor: "center",
                align: "center",
                font: {
                  weight: "bold",
                  size: 12,
                },
                formatter: function (value, context) {
                  return context.dataset.labels[context.dataIndex];
                },
              },
              backgroundColor: function (context) {
                if (context.dataset.labels[context.dataIndex] == "Apple") {
                  return "#7572b1";
                } else if (
                  context.dataset.labels[context.dataIndex] == "Google"
                ) {
                  return "#39a153";
                } else if (
                  context.dataset.labels[context.dataIndex] == "Samsung"
                ) {
                  return "#3a88bf";
                } else {
                  return "#ef5d14";
                }
              },
            },
          ],
        },
      },
    };
    this.chartInstance = React.createRef();
    this.pie1 = React.createRef();
    this.pie2 = React.createRef();
    this.pie3 = React.createRef();
    this.pie4 = React.createRef();
    this.pie6 = React.createRef();
    this.pie7 = React.createRef();
  }

  digestLineData(array) {
    [
      "TRUE",
      "Mobile",
      "Apple",
      "iOS",
      "iPhone 11 Pro Max",
      "iOS 13.1.2",
      '1242 x 2688, 458 ppi, 6.5"',
    ];
    var platforms = [];
    var androidVersions = [];
    var manufactorers = [];
    var iosVersions = [];
    var macVersions = [];
    var windowsVersions = [];
    var devices = [];
    var deviceObjects = [];
    for (var i = array.length - 1; i >= 0; i--) {
      var dataPoint = array[i];
      if (dataPoint.device_platform != "") {
        platforms.push(dataPoint.device_platform.trim());
      }
      if (dataPoint.device_make != "") {
        manufactorers.push(dataPoint.device_make.trim());
      }
      if (
        dataPoint.device_name != "" &&
        dataPoint.device_platform != "" &&
        dataPoint.device_make != "Oculus"
      ) {
        var mId = 0;
        if (
          dataPoint.device_name.includes("Mac") ||
          dataPoint.device_name.includes("iPad") ||
          dataPoint.device_name.includes("iPhone") ||
          dataPoint.device_name.includes("iPod") ||
          dataPoint.device_name.includes("Apple")
        ) {
          mId = 1;
        } else if (
          dataPoint.device_name.includes("Samsung") ||
          dataPoint.device_name.includes("Galaxy")
        ) {
          mId = 2;
        } else if (dataPoint.device_name.includes("Google")) {
          mId = 3;
        } else if (
          dataPoint.device_name.includes("Motorola") ||
          dataPoint.device_name.includes("Droid") ||
          dataPoint.device_name.includes("Moto") ||
          dataPoint.device_name[0] == "X"
        ) {
          mId = 4;
        } else if (
          dataPoint.device_name.includes("Kindle") ||
          dataPoint.device_name.includes("Amazon")
        ) {
          mId = 7;
        } else if (dataPoint.device_name.includes("HTC")) {
          mId = 6;
        } else if (
          dataPoint.device_name.includes("LG") ||
          dataPoint.device_name.includes("G")
        ) {
          mId = 5;
        } else {
          mId = 8;
        }
        devices.push([dataPoint.device_name.trim(), mId]);
      }

      if (dataPoint.device_os != "") {
        if (dataPoint.device_os.includes("iOS")) {
          iosVersions.push(dataPoint.device_os.trim());
        } else if (dataPoint.device_os.includes("Android")) {
          androidVersions.push(dataPoint.device_os.trim());
        } else if (dataPoint.device_os.includes("macOS")) {
          var version = dataPoint.device_os.replace("macOS", "");
          if (version.includes("(") && version.includes(")")) {
            version = version.replace(
              version.slice(version.indexOf("("), version.indexOf(")") + 1),
              ""
            );
          }
          macVersions.push(version.trim());
        } else if (
          dataPoint.device_os.includes("Windows") &&
          dataPoint[1] !== "Mobile"
        ) {
          var version = dataPoint.device_os
            .replace("Windows", "")
            .trim()
            .split(" ")[0];
          version = version.replace(",", "");
          windowsVersions.push(version);
        }
      }
    }
    return [
      platforms,
      androidVersions,
      manufactorers,
      iosVersions,
      devices,
      deviceObjects,
      macVersions,
      windowsVersions,
    ];
  }

  digestTicketData(array) {
    var data = [];
    var labels = [];
    for (var i = array.length - 1; i >= 0; i--) {
      var dataPoint = array[i];
      labels.push(moment(dataPoint[0]).format("MMM Do"));
      data.push(dataPoint[1]);
    }
    return [data.reverse(), labels.reverse()];
  }

  digestEnvData(array) {
    var newArray = array.sort((a, b) => {
      var firstNum;
      var secondNum;
      for (var x = 0; x < a.length; x++) {
        if (Number(a[x])) {
          firstNum = a.slice(x);
          if (firstNum.indexOf(" ") !== -1) {
            firstNum = firstNum.slice(0, firstNum.indexOf(" ") + 1);
          }
          if (
            firstNum.match(/\u002e/g) &&
            firstNum.match(/\u002e/g).length > 1
          ) {
            firstNum =
              firstNum.substring(0, firstNum.lastIndexOf(".")) +
              firstNum.substring(
                firstNum.lastIndexOf(".") + 1,
                firstNum.length
              );
          }
          break;
        }
      }
      for (var x = 0; x < b.length; x++) {
        if (Number(b[x])) {
          secondNum = b.slice(x);
          if (secondNum.indexOf(" ") !== -1) {
            secondNum = secondNum.slice(0, secondNum.indexOf(" ") + 1);
          }
          if (
            secondNum.match(/\u002e/g) &&
            secondNum.match(/\u002e/g).length > 1
          ) {
            secondNum =
              secondNum.substring(0, secondNum.lastIndexOf(".")) +
              secondNum.substring(
                secondNum.lastIndexOf(".") + 1,
                secondNum.length
              );
          }
          break;
        }
      }
      if (Number(firstNum)) {
        return parseFloat(firstNum) - parseFloat(secondNum);
      } else {
        if (a.toUpperCase() < b.toUpperCase()) {
          return -1;
        }
        if (a.toUpperCase() > b.toUpperCase()) {
          return 1;
        }
        return 0;
      }
    });
    var sortedData = countBy(newArray);
    var data = [];
    var labels = [];
    for (var key in sortedData) {
      if (sortedData.hasOwnProperty(key)) {
        var dataPoint = sortedData[key];
        labels.push(key);
        data.push(dataPoint);
      }
    }
    return [data, labels];
  }

  digestCountData(array) {
    var data = countBy(array);
    var sortedArray = array.sort(function (x, y) {
      return data[x] - data[y];
    });
    var sortedTally = countBy(sortedArray);
    var data = [];
    var labels = [];
    for (var key in sortedTally) {
      if (sortedTally.hasOwnProperty(key)) {
        var dataPoint = sortedTally[key];
        labels.push(key);
        data.push(dataPoint);
      }
    }
    return [data, labels];
  }

  digestDeviceData(array) {
    var data = countBy(array);
    var sortedArray = array.sort(function (x, y) {
      return y[1] - x[1];
    });
    var sortedTally = countBy(sortedArray);
    var data = [];
    var labels = [];
    for (var key in sortedTally) {
      if (sortedTally.hasOwnProperty(key)) {
        var dataPoint = sortedTally[key];
        var label = key.substring(0, key.length - 2);
        labels.push(label);
        data.push(dataPoint);
      }
    }
    return [data, labels];
  }

  getData = (data) => {
    var platformPie = this.state.pie1;
    var iosPie = this.state.pie2;
    var manufactorersPie = this.state.pie3;
    var androidPie = this.state.pie4;
    var macPie = this.state.pie6;
    var windowsPie = this.state.pie7;
    var comboPie = this.state.ring2;
    var devicePie = this.state.ring1;
    var manufactorersDough = this.state.ring3;
    var dataArrays = this.digestLineData(data);
    var platformCount = this.digestCountData(dataArrays[0]);
    var androidCount = this.digestEnvData(dataArrays[1]);
    var manufactorerCount = this.digestCountData(dataArrays[2]);
    var iosCount = this.digestEnvData(dataArrays[3]);
    var macCount = this.digestEnvData(dataArrays[6]);
    var windowsCount = this.digestEnvData(dataArrays[7]);
    var deviceCount = this.digestDeviceData(dataArrays[4]);
    var comboData = androidCount[0].concat(iosCount[0]);
    var comboLabels = androidCount[1].concat(iosCount[1]);
    devicePie.chartData.datasets[0].labels = deviceCount[1];
    devicePie.chartData.labels = deviceCount[1];
    devicePie.chartData.datasets[0].data = deviceCount[0];
    platformPie.chartData.labels = platformCount[1];
    platformPie.chartData.datasets[0].labels = platformCount[1];
    platformPie.chartData.datasets[0].data = platformCount[0];
    iosPie.chartData.datasets[0].labels = iosCount[1];
    iosPie.chartData.labels = iosCount[1];
    iosPie.chartData.datasets[0].data = iosCount[0];
    iosPie.chartData.datasets[0].backgroundColor = ["#7572b1"];
    macPie.chartData.datasets[0].labels = macCount[1];
    macPie.chartData.labels = macCount[1];
    macPie.chartData.datasets[0].data = macCount[0];
    macPie.chartData.datasets[0].backgroundColor = ["#7572b1"];
    windowsPie.chartData.datasets[0].labels = windowsCount[1];
    windowsPie.chartData.labels = windowsCount[1];
    windowsPie.chartData.datasets[0].data = windowsCount[0];
    windowsPie.chartData.datasets[0].backgroundColor = ["#7572b1"];
    manufactorersPie.chartData.datasets[0].labels = manufactorerCount[1];
    manufactorersPie.chartData.labels = manufactorerCount[1];
    manufactorersPie.chartData.datasets[0].data = manufactorerCount[0];
    manufactorersDough.chartData.datasets[0].labels = manufactorerCount[1];
    manufactorersDough.chartData.datasets[0].data = manufactorerCount[0];
    androidPie.chartData.datasets[0].labels = androidCount[1];
    androidPie.chartData.labels = androidCount[1];
    androidPie.chartData.datasets[0].data = androidCount[0];
    androidPie.chartData.datasets[0].backgroundColor = ["#39a153", "#3a88bf"];
    comboPie.chartData.datasets[0].labels = comboLabels;
    comboPie.chartData.datasets[0].data = comboData;
    platformPie.chartData.datasets[0].backgroundColor = ["#ef5d14", "#7572b1"];
    manufactorersPie.chartData.datasets[0].backgroundColor = function (
      context
    ) {
      if (context.chart.config.data.labels[context.dataIndex] == "Apple") {
        return "#7572b1";
      } else if (
        context.chart.config.data.labels[context.dataIndex] == "Google"
      ) {
        return "#39a153";
      } else if (
        context.chart.config.data.labels[context.dataIndex] == "Samsung"
      ) {
        return "#3a88bf";
      } else {
        return "#ef5d14";
      }
    };
    devicePie.chartData.datasets[0].backgroundColor = function (context) {
      var word = context.dataset.labels[context.dataIndex];
      if (
        word.includes("iPhone") ||
        word.includes("Mac") ||
        word.includes("iPad") ||
        word.includes("iPod") ||
        word.includes("Apple")
      ) {
        return "#b4b5d7";
      } else if (word.includes("Galaxy") || word.includes("Samsung")) {
        return "#a5cce3";
      } else if (word.includes("Nexus") || word.includes("Google")) {
        return "#97d390";
      } else if (word.includes("Moo")) {
        return "#b4b5d7";
      } else {
        return "#fda460";
      }
    };
    var iosColors = ["#ea6159", "#ebc34c", "#f09d3e", "#6eb782"];
    iosPie.chartData.datasets[0].backgroundColor = function (context) {
      return "#b4b5d7";
    };
    macPie.chartData.datasets[0].backgroundColor = function (context) {
      return "#b4b5d7";
    };
    windowsPie.chartData.datasets[0].backgroundColor = function (context) {
      return "#b4b5d7";
    };
    androidPie.chartData.datasets[0].backgroundColor = function (context) {
      return "#ef5d14";
    };
    this.setState({
      data: data,
      pie1: platformPie,
      pie2: iosPie,
      pie3: manufactorersPie,
      pie4: androidPie,
      pie5: comboPie,
      pie6: macPie,
      pie7: windowsPie,
      ring1: devicePie,
      ring2: comboPie,
      ring3: manufactorersDough,
    });
  };

  componentDidMount() {
    this.getData(this.props.data);
    if (this.chartInstance && this.chartInstance.current) {
      this.chartInstance.current.chartInstance.chart.update();
    }
    if (this.pie1 && this.pie1.current) {
      this.pie1.current.chartInstance.chart.update();
    }
    if (this.pie2 && this.pie2.current) {
      this.pie2.current.chartInstance.chart.update();
    }
    if (this.pie3 && this.pie3.current) {
      this.pie3.current.chartInstance.chart.update();
    }
    if (this.pie4 && this.pie4.current) {
      this.pie4.current.chartInstance.chart.update();
    }
    if (this.pie6 && this.pie6.current) {
      this.pie6.current.chartInstance.chart.update();
    }
    if (this.pie7 && this.pie7.current) {
      this.pie7.current.chartInstance.chart.update();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data.length != this.props.data.length) {
      this.getData(this.props.data);
    }
  }

  render() {
    var divisorArr = [
      this.state.pie1.chartData.datasets[0].data.length > 1 ? 1 : 0,
      this.state.pie2.chartData.datasets[0].data.length > 1 ? 1 : 0,
      this.state.pie3.chartData.datasets[0].data.length > 1 ? 1 : 0,
      this.state.pie4.chartData.datasets[0].data.length > 1 ? 1 : 0,
      this.state.pie6.chartData.datasets[0].data.length > 1 ? 1 : 0,
      this.state.pie7.chartData.datasets[0].data.length > 1 ? 1 : 0,
    ];
    var divisor = divisorArr.reduce(function (divisorArr, b) {
      return divisorArr + b;
    }, 0);
    var colWidth = 24 / divisor;
    if (colWidth == 24) {
      colWidth = 12;
    }
    var doughnut = {
      labels: [""],
      datasets: [
        this.state.ring2.chartData.datasets[0],
        this.state.ring1.chartData.datasets[0],
        this.state.ring3.chartData.datasets[0],
      ],
    };
    return (
      <div className="fade" id="pieContainer" ref="pieWrapper">
        {this.props.activeFilter == "reset" ? (
          <Doughnut
            ref={this.chartInstance}
            data={doughnut}
            options={this.state.ring1.chartOptions}
            height={540}
            width={540}
          />
        ) : (
          <>
            <div
              style={{
                display:
                  this.state.pie1.chartData.datasets[0].data.length > 1
                    ? "initial"
                    : "none",
                float: "none",
              }}
              className={"col pie-container s" + colWidth}
            >
              <div style={{ marginRight: divisor == 4 ? "" : "" }}>
                <div className="inner-chart">
                  <p className="center">Platforms</p>
                  <Pie
                    ref={this.pie1}
                    data={this.state.pie1.chartData}
                    options={this.state.pie1.chartOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.pie2.chartData.datasets[0].data.length > 1
                    ? "initial"
                    : "none",
                float: "none",
              }}
              className={"col pie-container s" + colWidth}
            >
              <div style={{ marginLeft: divisor == 4 ? "" : "" }}>
                <div className="inner-chart">
                  <p className="center">OS Versions</p>
                  <Pie
                    ref={this.pie2}
                    data={this.state.pie2.chartData}
                    options={this.state.pie2.chartOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.pie3.chartData.datasets[0].data.length > 1
                    ? "initial"
                    : "none",
                float: "none",
              }}
              className={"col pie-container s" + colWidth}
            >
              <div style={{ marginRight: divisor == 4 ? "" : "" }}>
                <div className="inner-chart">
                  <p className="center">Manufacturers</p>
                  <Pie
                    ref={this.pie3}
                    data={this.state.pie3.chartData}
                    options={this.state.pie3.chartOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.pie4.chartData.datasets[0].data.length > 1
                    ? "initial"
                    : "none",
                float: "none",
              }}
              className={"col pie-container s" + colWidth}
            >
              <div style={{ marginLeft: divisor == 4 ? "" : "" }}>
                <div className="inner-chart">
                  <p className="center">Android Versions</p>
                  <Pie
                    ref={this.pie4}
                    data={this.state.pie4.chartData}
                    options={this.state.pie4.chartOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.pie6.chartData.datasets[0].data.length > 1
                    ? "initial"
                    : "none",
                float: "none",
              }}
              className={"col pie-container s" + colWidth}
            >
              <div style={{ marginLeft: divisor == 4 ? "" : "" }}>
                <div className="inner-chart">
                  <p className="center">macOS Versions</p>
                  <Pie
                    ref={this.pie6}
                    data={this.state.pie6.chartData}
                    options={this.state.pie6.chartOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.pie7.chartData.datasets[0].data.length > 1
                    ? "initial"
                    : "none",
                float: "none",
              }}
              className={"col pie-container s" + colWidth}
            >
              <div style={{ marginLeft: divisor == 4 ? "" : "" }}>
                <div className="inner-chart">
                  <p className="center">Windows Versions</p>
                  <Pie
                    ref={this.pie7}
                    data={this.state.pie7.chartData}
                    options={this.state.pie7.chartOptions}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default DeviceLabStats;
