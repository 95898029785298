import React from 'react'
import PropType from 'prop-types'

class Switcher extends React.Component {
   constructor(props) {
   super(props);
  }

  setSelector = (e) => {
    var selector = parseInt(e.target.attributes.value.value)
    this.props.handlerFunction(selector)
  }

  makeOptions = () => {
    if (this.props.switcherOptions && this.props.switcherOptions.length >= 1) {
      var options = []
      for (var i = 0; i < this.props.switcherOptions.length; i++) {
        var option = this.props.switcherOptions[i]
        if (this.props.editState != undefined && this.props.editState && i != 0) {
          options.push(<li key={i} role="button" disabled={true} style={{color: "rgba(205,205,205,.6)", cursor: "default"}} className={this.props.activeValue == i ? "active" : ""} value={i}>{option}</li>)
        } else {
          options.push(<li key={i} role="button" aria-label={this.props.activeValue == i?`${option}, active`:option} onClick={this.setSelector} className={this.props.activeValue == i ? "active" : ""} value={i}>{option}</li>)
        }
        // { example: <li onClick={this.setSelector} className={this.props.activeSelector == "0" ? "active" : ""} value={0}>All</li>}
      }
      return options
    } else {
       return (
         <p>
           No options given please pass options to switcherOptions prop ex:
           [&quot;Taco&quot;, &quot;Pizza&quot;, &quot;Salad&quot;]
         </p>
       );
    }
  }

  render() {
    var options = this.makeOptions()
    return(
      <div id={this.props.idProp ? this.props.idProp : "switcher"}>
        <ul aria-label={this.props.ariaLabel?this.props.ariaLabel:""} className={this.props.classes}>
          {options}
        </ul>
      </div>
    )
  }

}

export default Switcher

Switcher.propTypes = {
  idProp: PropType.string,
  ariaLabel: PropType.string,
  classes: PropType.string,
  switcherOptions: PropType.array,
  editState: PropType.bool,
  handlerFunction: PropType.func,
  activeValue: PropType.number
}