import React, { useMemo } from "react";
import styled from "styled-components";
import useAvatarAttachmentUpload from "@hooks/useAvatarAttachmentUpload";
import LoadingSpinner from "@components/common/loading_spinner";

const AvatarUploader = ({ user }) => {
  const {
    uploadNewAvatar,
    loading,
    error,
    avatarInfo: updatedAvatar,
  } = useAvatarAttachmentUpload();

  const handleAvatarChange = (e) => {
    if (loading || error) {
      return null;
    }

    uploadNewAvatar(e.target.files[0]);

    return true;
  };

  const avatarData = useMemo(() => {
    if (updatedAvatar) {
      return updatedAvatar;
    }

    if (user?.avatar_info) {
      return user.avatar_info;
    }

    return null;
  }, [user?.avatar_info, updatedAvatar]);

  return (
    <Uploader className="upload-avatar-container">
      <LoadingWrapper>
        <LoadingSpinner size={25} loading={loading} />
      </LoadingWrapper>
      {!loading && !error && (
        <>
          <img
            alt={`Profile user ${user.name} avatar`}
            src={
              avatarData?.url ||
              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
            }
          />
          <UploaderBody>
            {avatarData?.metadata?.filename && (
              <p>{avatarData.metadata.filename}</p>
            )}
            <label role="button" htmlFor="avatar-upload">
              {avatarData?.url ? "Change" : "Upload"} Photo
              <input
                onChange={handleAvatarChange}
                accept="image/png, image/jpeg, image/webp, image/gif"
                type="file"
                id="avatar-upload"
              />
            </label>
          </UploaderBody>
        </>
      )}
    </Uploader>
  );
};

const Uploader = styled.div`
  min-height: 70px;
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const UploaderBody = styled.span`
  z-index: 2;
`;

export default AvatarUploader;
