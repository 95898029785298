import React from "react";
import { TrixEditor } from "../common/trix_container";
import axios from "axios";
import EditIcon from "../icons/edit_icon";
import MoveIcon from "../icons/move_icon";
import DeleteIcon from "../icons/delete_icon";
import Swal from "sweetalert2";
import BasicSelect from "../common/basic_select";

class TrialistSection extends React.Component {
  constructor(props) {
    super(props);
    var containerWidths = {
      "100%": { label: "Full", value: "100%" },
      "50%": { label: "Half", value: "50%" },
      "33%": { label: "Third", value: "33%" },
      "25%": { label: "Quarter", value: "25%" },
    };
    var imageWidths = {
      "100%": { label: "Fit Container", value: "100%" },
    };
    var imageAlignments = {
      "0 auto": { label: "Center", value: "0 auto" },
      "0 auto 0 0": { label: "Left", value: "0 auto 0 0" },
      "0 0 0 auto": { label: "Center", value: "0 0 0 auto" },
    };
    this.state = {
      content: this.props.section.content,
      contentValue: "",
      style: this.props.section.style,
      imageStyle: this.props.section.image_style,
      imageFile: null,
      containerWidth: this.props.section.style.width
        ? containerWidths[this.props.section.style.width]
          ? containerWidths[this.props.section.style.width]
          : { label: "Custom", value: "custom" }
        : { label: "Full", value: "100%" },
      containerCustomWidth: this.props.section.style.width
        ? this.props.section.style.width
        : "",
      imageWidth: this.props.section.style.width
        ? imageWidths[this.props.section.image_style.width]
          ? imageWidths[this.props.section.image_style.width]
          : { label: "Custom", value: "custom" }
        : { label: "Fit Container", value: "100%" },
      imageCustomWidth: this.props.section.image_style.width
        ? this.props.section.image_style.width
        : "",
      imageAlignment: this.props.section.image_style.margin
        ? imageAlignments[this.props.section.image_style.margin]
          ? imageAlignments[this.props.section.image_style.margin]
          : { label: "Center", value: "0 auto" }
        : { label: "Center", value: "0 auto" },
    };
    this.handleEditorReady = this.handleEditorReady.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleContainerWidthChange =
      this.handleContainerWidthChange.bind(this);
    this.handleImageWidthChange = this.handleImageWidthChange.bind(this);
    this.handleImageAlignmentChange =
      this.handleImageAlignmentChange.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragDrop = this.handleDragDrop.bind(this);
    this.customContainerWidthRef = React.createRef();
    this.customImageWidthRef = React.createRef();
    this.editor = React.createRef();
  }

  handleEditorReady(editor) {
    // this is a reference back to the editor if you want to
    // do editing programatically
    this.editor = editor;
    editor.insertHTML(this.state.content);
  }

  handleChange(html) {
    // html is the new html content
    // text is the new text content
    this.setState({ contentValue: html });
  }

  handleSave() {
    if (!this.props.loading) {
      var formData = new FormData();
      this.props.setLoading(true);
      var containerWidth =
        this.state.containerWidth.value === "custom"
          ? this.customContainerWidthRef.current.value
          : this.state.containerWidth.value;
      var imageWidth =
        this.props.section.section_type === "image" &&
        this.state.imageWidth.value === "custom"
          ? this.customImageWidthRef.current.value
          : this.state.imageWidth.value;
      formData.append("trialist_section[content]", this.state.contentValue);
      formData.append("trialist_section[style][width]", containerWidth);
      if (this.state.imageFile) {
        formData.append("trialist_section[image]", this.state.imageFile);
      }
      if (this.props.section.section_type === "image") {
        formData.append("trialist_section[image_style][width]", imageWidth);
        formData.append(
          "trialist_section[image_style][margin]",
          this.state.imageAlignment.value
        );
      }
      if (this.props.section.id === "new") {
        formData.append(
          "trialist_section[position]",
          this.props.section.position
        );
        formData.append(
          "trialist_section[trialist_page_id]",
          this.props.section.trialist_page_id
        );
        formData.append(
          "trialist_section[section_type]",
          this.props.section.section_type
        );
        axios
          .post(`/trialist_sections/`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.props.setLoading(false);
            this.props.handleCreateSection(res.data);
          })
          .catch((err) => {
            this.props.setLoading(false);
            console.error(err);
          });
      } else {
        axios
          .patch(`/trialist_sections/${this.props.section.id}`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.setState({
              contentValue: "",
              content: this.state.contentValue,
            });
            this.props.setLoading(false);
            this.props.handleUpdateSection(res.data);
          })
          .catch((err) => {
            this.props.setLoading(false);
            console.error(err);
          });
      }
    }
  }

  handleCancel() {
    this.setState({ contentValue: "" });
    this.props.handleEditSection(false);
  }

  handleEdit() {
    this.props.handleEditSection(this.props.section.id);
  }

  handleDelete() {
    if (!this.props.loading) {
      Swal.fire({
        title: "Delete Section",
        text: "Are you sure that you want to delete this section?",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        confirmButtonText: "Yes",
      }).then((result) => {
        this.props.setLoading(true);
        if (result.value) {
          axios
            .delete(`/trialist_sections/${this.props.section.id}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              this.props.setLoading(false);
              this.props.handleDeleteSection(res.data);
            })
            .catch((err) => {
              this.props.setLoading(false);
              console.error(err);
            });
        } else if (result.dismiss) {
          // Swal.fire("Cancelled", "Your file is safe");
        }
      });
    }
  }

  handleAddImage = (e) => {
    var file = e.target.files[0];
    var fileExtension = e.target.value.split(".").pop().toLowerCase();
    var allowedExtensions = ["jpg", "jpeg", "png", "mp4"];
    if (!allowedExtensions.includes(fileExtension)) {
      M.toast({
        html: "Invalid File Type",
        classes: "red darken-1 error-toast",
      });
      e.target.value = "";
      return;
    }
    // file size limit: 100MB
    if (file.size > 100 * 1024 * 1024) {
      M.toast({
        html: "File exceeds size limit",
        classes: "red darken-1 error-toast",
      });
      e.target.value = "";
      return;
    }
    this.setState({ imageFile: file });
  };

  handleContainerWidthChange(e) {
    this.setState({ containerWidth: e });
  }

  handleImageWidthChange(e) {
    this.setState({ imageWidth: e });
  }

  handleImageAlignmentChange(e) {
    this.setState({ imageAlignment: e });
  }

  handleDragStart() {
    var { id, position } = this.props.section;
    this.props.handleDragStart(id, position);
  }

  handleDragEnter(e) {
    var { position } = e.target.dataset;
    if (position) {
      if (parseInt(position) > this.props.dragging.position) {
        if (e.target.parentElement.style.width === "100%") {
          e.target.classList.add("bottom-drag");
        } else {
          e.target.classList.add("right-drag");
        }
      } else if (parseInt(position) < this.props.dragging.position) {
        if (e.target.parentElement.style.width === "100%") {
          e.target.classList.add("top-drag");
        } else {
          e.target.classList.add("left-drag");
        }
      }
    }
  }

  handleDragLeave(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    e.target.classList.remove("right-drag");
    e.target.classList.remove("left-drag");
  }

  handleDragDrop(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    e.target.classList.remove("right-drag");
    e.target.classList.remove("left-drag");
    var { position } = e.target.dataset;
    if (position) {
      var { id } = this.props.dragging;
      this.props.handleDragDrop(id, parseInt(position));
    }
  }

  handleDragOver(e) {
    e.preventDefault();
  }

  render() {
    var { editingSection, editMode, sectionsLength, dragging } = this.props;

    var { content, id, section_type, position } = this.props.section;
    var imageUrl = this.state.imageFile
      ? URL.createObjectURL(this.state.imageFile)
      : this.props.section.image_url
      ? this.props.section.image_url
      : "";
    return (
      <div
        style={{
          display: "inline-block",
          width: "100%",
          position: "relative",
          verticalAlign: "top",
          ...(editingSection !== id ? this.props.section.style : {}),
        }}
      >
        <div
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDragDrop}
          className={`trial-section-drag-overlay ${
            this.props.section.style.width &&
            this.props.section.style.width !== "100%"
              ? "side-drag"
              : ""
          }`}
          data-position={position}
          style={{ zIndex: dragging && dragging.id !== id ? "100" : "-1" }}
        />
        {editingSection !== id ? (
          section_type === "text" ? (
            <div className={`trial-section ${editMode ? "edit-mode" : ""}`}>
              {editMode && (
                <div className="trial-section-buttons-container">
                  <span onClick={this.handleEdit}>
                    <EditIcon svgStyles={{ pointerEvents: "none" }} />
                  </span>
                  <span onClick={this.handleDelete} style={{ margin: "0 8px" }}>
                    <DeleteIcon svgStyles={{ pointerEvents: "none" }} />
                  </span>
                  {sectionsLength > 1 && (
                    <span
                      onDragStart={this.handleDragStart}
                      onDragEnd={this.props.handleDragEnd}
                      draggable={true}
                    >
                      <MoveIcon svgStyles={{ pointerEvents: "none" }} />
                    </span>
                  )}
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          ) : (
            <div className={`trial-section ${editMode ? "edit-mode" : ""}`}>
              {editMode && (
                <div className="trial-section-buttons-container">
                  <span onClick={this.handleEdit}>
                    <EditIcon svgStyles={{ pointerEvents: "none" }} />
                  </span>
                  <span onClick={this.handleDelete} style={{ margin: "0 8px" }}>
                    <DeleteIcon svgStyles={{ pointerEvents: "none" }} />
                  </span>
                  {sectionsLength > 1 && (
                    <span
                      onDragStart={this.handleDragStart}
                      onDragEnd={this.props.handleDragEnd}
                      draggable={true}
                    >
                      <MoveIcon svgStyles={{ pointerEvents: "none" }} />
                    </span>
                  )}
                </div>
              )}
              <img
                style={{ display: "block", ...this.props.section.image_style }}
                src={imageUrl}
              />
            </div>
          )
        ) : section_type === "text" ? (
          <div style={{ margin: "20px 0", position: "relative" }}>
            <div
              style={{
                display: "flex",
                position: "absolute",
                left: "436px",
                zIndex: "100",
                top: "-2px",
              }}
            >
              <span>Width:</span>
              <BasicSelect
                options={[
                  { label: "Full", value: "100%" },
                  { label: "Half", value: "50%" },
                  { label: "Third", value: "33%" },
                  { label: "Quarter", value: "25%" },
                  { label: "Custom", value: "custom" },
                ]}
                top={"-4px"}
                onChange={this.handleContainerWidthChange}
                selectedOption={this.state.containerWidth}
                // arrowStyle={{position:"absolute", top:"5px", right:"-5px"}}
                width="100px"
                id="1"
              />
              {this.state.containerWidth.value === "custom" && (
                <input
                  ref={this.customContainerWidthRef}
                  maxLength={25}
                  defaultValue={this.props.section.style.width || ""}
                  className="trial-section-style-input"
                />
              )}
            </div>
            <TrixEditor
              value={this.state.contentValue}
              onChange={this.handleChange}
              className="trix-content"
              onEditorReady={this.handleEditorReady}
            />
            <div style={{ margin: "10px 0 0" }}>
              <button
                onClick={this.handleSave}
                className="common-button-submit"
              >
                Save
              </button>
              <button
                onClick={this.handleCancel}
                className="common-button-cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div
            className="trial-section-image-edit-container"
            style={{
              margin: "20px 0",
              padding: "20px",
              backgroundColor: "#efefef",
              position: "relative",
            }}
          >
            <div className="trial-section-edit-options">
              <span>Container</span>
              <div>
                <span>Width:</span>
                <BasicSelect
                  options={[
                    { label: "Full", value: "100%" },
                    { label: "Half", value: "50%" },
                    { label: "Third", value: "33%" },
                    { label: "Quarter", value: "25%" },
                    { label: "Custom", value: "custom" },
                  ]}
                  onChange={this.handleContainerWidthChange}
                  selectedOption={this.state.containerWidth}
                  // arrowStyle={{position:"absolute", top:"5px", right:"-5px"}}                  width="100px"
                  id="1"
                />
                {this.state.containerWidth.value === "custom" && (
                  <input
                    ref={this.customContainerWidthRef}
                    maxLength={25}
                    defaultValue={this.props.section.style.width || ""}
                    className="trial-section-style-input"
                  />
                )}
              </div>
              <span style={{ marginLeft: "20px" }}>Image</span>
              <div>
                <span>Width:</span>
                <BasicSelect
                  options={[
                    { label: "Fit Container", value: "100%" },
                    { label: "Custom", value: "custom" },
                  ]}
                  onChange={this.handleImageWidthChange}
                  selectedOption={this.state.imageWidth}
                  // arrowStyle={{position:"absolute", top:"5px", right:"-5px"}}                  width="140px"
                  id="2"
                />
                {this.state.imageWidth.value === "custom" && (
                  <input
                    ref={this.customImageWidthRef}
                    maxLength={25}
                    defaultValue={this.props.section.image_style.width || ""}
                    className="trial-section-style-input"
                  />
                )}
                <span>Alignment:</span>
                <BasicSelect
                  options={[
                    { label: "Center", value: "0 auto" },
                    { label: "Left", value: "0 auto 0 0" },
                    { label: "Right", value: "0 0 0 auto" },
                  ]}
                  onChange={this.handleImageAlignmentChange}
                  selectedOption={this.state.imageAlignment}
                  // arrowStyle={{position:"absolute", top:"5px", right:"-5px"}}                  width="100px"
                  id="3"
                />
              </div>
            </div>
            <div style={{ paddingTop: "20px" }}>
              {imageUrl ? (
                <div>
                  <img
                    style={{
                      maxWidth: "275px",
                      display: "block",
                      margin: "20px auto",
                    }}
                    src={imageUrl}
                  />
                  <label
                    htmlFor="image-file"
                    style={{
                      width: "100px",
                      display: "block",
                      height: "24px",
                      margin: "auto",
                      textAlign: "center",
                      color: "#519acc",
                      fontWeight: "600",
                      lineHeight: "20px",
                      cursor: "pointer",
                    }}
                  >
                    Change Image
                    <input
                      className="color"
                      style={{
                        height: "0",
                        zIndex: "1",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      id="image-file"
                      type="file"
                      accept=".jpg,.gif,.png,.mp4"
                      ref="file"
                      onChange={this.handleAddImage}
                    />
                  </label>
                </div>
              ) : (
                <div
                  onDragEnter={this.draggedOver}
                  onDragLeave={this.draggedOut}
                  style={{ pointerEvents: "all" }}
                  id="filepond-container"
                >
                  <div
                    style={{
                      position: "relative",
                      margin: "0 auto",
                      width: "139px",
                    }}
                  >
                    <div style={{ height: "0" }}>
                      <input
                        className="color"
                        style={{
                          height: "0",
                          zIndex: "1",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        id="image-file"
                        type="file"
                        accept=".jpg,.gif,.png,.mp4"
                        ref="file"
                        onChange={this.handleAddImage}
                      />
                    </div>
                    <label
                      htmlFor="image-file"
                      id="file-wrapper"
                      style={{
                        position: "relative",
                        backgroundColor: "#f5f5f5",
                        height: "134px",
                        width: "143px",
                        display: "block",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          transition: "color .3s",
                          position: "absolute",
                          top: "50px",
                          left: "16px",
                          lineHeight: "16px",
                          pointerEvents: "none",
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        Drop or select image <br /> to upload
                      </span>
                      <input
                        id="validator"
                        style={{
                          pointerEvents: "none",
                          zIndex: "0",
                          transition: "background-color .3s",
                          width: "100%",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          border: "2px solid #e0e0e0",
                          height: "131px",
                          borderRadius: "5px",
                          backgroundColor: "#f5f5f5",
                          marginBottom: 0,
                          textAlign: "center",
                        }}
                        className="file-path validate attachment-drop-area"
                        placeholder=""
                        type="text"
                      />
                    </label>
                  </div>
                </div>
              )}
              <button
                disabled={
                  section_type === "image" &&
                  !(imageUrl || this.state.imageFile)
                }
                onClick={this.handleSave}
                className="common-button-submit"
              >
                Save
              </button>
              <button
                onClick={this.handleCancel}
                className="common-button-cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TrialistSection;
