import React from "react";
import axios from "axios";
import WithCable from "@/channels/WithCable";
import TestPassLink from "./test_pass_link";
import RoundDropdown from "../../common/round_dropdown";

class TestPassMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openArray: [],
      currentPercent: this.props.currentPass
        ? parseInt(
            this.props.currentPass.percent_complete.substring(
              0,
              this.props.currentPass.percent_complete.length - 1
            )
          )
        : 0,
      testSuites: [],
    };
    this.handleOutsideClick = (e) => {
      // we should really get away from string refs, they are deprecated
      if (
        !this.refs[`nested_nav_menu_container_${this.props.id}`].contains(
          e.target
        )
      ) {
        this.setOpen();
      }
    };
  }

  componentDidMount() {
    if (this.props.currentPass) {
      this.testPassLinkChannel = this.props.cable.subscriptions.create(
        {
          channel: "TestPassLinkChannel",
          test_pass_id: this.props.currentPass.id,
        },
        {
          received: (data) => {
            this.setState({
              currentPercent: parseInt(
                data.percent.substring(0, data.percent.length - 1)
              ),
            });
          },
        }
      );
    }
    this.getPassMenuData(true);
  }

  getPassMenuData = (initialLoad, promiseResolve) => {
    axios
      .get(`/pass_menu_data/${this.props.project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.length !== this.state.openArray.length) {
          const openArray = Array(res.data.length).fill(false);
          if (initialLoad && this.props.suite) {
            for (let x = 0; x < res.data.length; x++) {
              if (res.data[x].id === this.props.suite.id) {
                openArray[x] = true;
              }
            }
          }
          this.setState({
            testSuites: res.data,
            openArray,
          });
        }
        this.setState({
          testSuites: res.data,
        });
        if (promiseResolve) {
          promiseResolve("done");
        }
      })
      .catch((err) => {
        if (promiseResolve) {
          promiseResolve("done");
        }
        console.error(err);
      });
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (
      !prevProps.currentPass ||
      !this.props.currentPass ||
      prevProps.currentPass.id != this.props.currentPass.id
    ) {
      const { currentPass } = this.props;
      return currentPass;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const currentPass = snapshot;
      window.removeEventListener("click", this.handleOutsideClick);
      const popUpElem = document.querySelector(".test-pass-pop-up");
      if (popUpElem) {
        document.querySelector("body").removeChild(popUpElem);
      }
      if (this.testPassLinkChannel) {
        this.testPassLinkChannel.unsubscribe();
      }
      if (currentPass) {
        this.testPassLinkChannel = this.props.cable.subscriptions.create(
          { channel: "TestPassLinkChannel", test_pass_id: currentPass.id },
          {
            received: (data) => {
              this.setState({
                currentPercent: parseInt(
                  data.percent.substring(0, data.percent.length - 1)
                ),
              });
            },
          }
        );
      }
      if (this.state.open) {
        this.setOpen();
      }
      this.setState({
        currentPercent: currentPass
          ? parseInt(
              currentPass.percent_complete.substring(
                0,
                currentPass.percent_complete.length - 1
              )
            )
          : 0,
        // open: false
      });
    }
  }

  setPass = (e) => {
    const id = e.target.attributes.value.value;
    this.props.setPass(id);
  };

  // setModal = (bool, page, data) => {
  //   this.setState({
  //     modalOpen: bool,
  //     currentModal: page,
  //     modalData: data
  //   })
  // }

  setOpenArray = (e) => {
    const { groupIndex } = e.target.dataset;
    const newOpenArray = this.state.openArray;
    newOpenArray[parseInt(groupIndex)] = !newOpenArray[parseInt(groupIndex)];
    this.setState({ openArray: newOpenArray });
  };

  setOpen = (e) => {
    try {
      const optionsCount = this.state.testSuites.length;
      if (!this.state.open === true) {
        const stickySectionHeader = document.getElementById(
          "sticky-section-header"
        );
        if (stickySectionHeader) {
          stickySectionHeader.style.display = "none";
        }
        if (
          e.target.id !== "pass-title-edit" &&
          e.target.id !== "test-pass-delete"
        ) {
          window.addEventListener("click", this.handleOutsideClick);
          this.getPassMenuData();
          this.setState(
            {
              open: true,
            },
            () => {
              const elem = document.querySelector(".checkbox-menu");
              const newPassButton = document.querySelector(
                ".new-test-pass-button-container"
              );
              const optionElems = document.querySelectorAll(
                ".checkbox-menu > div"
              );
              setTimeout(() => {
                elem.style.maxHeight = this.props.maxHeight
                  ? this.props.maxHeight
                  : "300px";
                // elem.style.padding = "10px 0px"
                if (newPassButton) {
                  newPassButton.style.height = "40px";
                }
                optionElems.forEach((elm, index) => {
                  elm.style.transitionDelay = `${
                    (0.4 / optionsCount) * index
                  }s`;
                  elm.style.transform = "translateX(0px)";
                  elm.style.opacity = "1";
                });
              }, 50);
              const timeout = optionElems.length * 150;
              setTimeout(() => {
                elem.style.overflow = "auto";
              }, timeout);
            }
          );
        }
      } else {
        window.removeEventListener("click", this.handleOutsideClick);
        const elem = document.querySelector(".checkbox-menu");
        elem.style.maxHeight = "0px";
        // elem.style.padding = "0px 0px"
        const newPassButton = document.querySelector(
          ".new-test-pass-button-container"
        );
        if (newPassButton) {
          newPassButton.style.height = "1px";
        }
        // newPassButton.style.opacity = "0"
        const optionElems = document.querySelectorAll(".checkbox-menu > div");
        elem.style.overflow = "hidden";
        optionElems.forEach((elm, index) => {
          elm.style.transitionDelay = `${
            (0.4 / optionsCount) * (optionsCount - (index + 1))
          }s`;
          elm.style.transform = "translateX(-14px)";
          elm.style.opacity = "0";
        });
        setTimeout(() => {
          this.setState({
            open: false,
          });
          const stickySectionHeader = document.getElementById(
            "sticky-section-header"
          );
          if (stickySectionHeader) {
            stickySectionHeader.style.display = "block";
          }
        }, 600);
      }
    } catch (err) {
      err.message = `Pass Menu Open Error: ${err.message}`;
      Sentry.captureException(err);
      console.error(err);
    }
  };

  newTestPass = () => {
    const elem = document.getElementById("test-pass-menu-selector");
    if (elem) {
      elem.click();
    }
    this.props.setModal(true, "new-test-pass", {
      newPass: true,
      testSuiteId: null,
      customClass: "new-test-pass-modal new-scrollbar",
      testSuiteName: null,
      projectId: this.props.project.id,
      suitesList: this.props.suites.map((suite) => {
        return { label: suite.name, value: suite.id };
      }),
    });
  };

  copyTestPass = (e) => {
    const elem = document.getElementById("test-pass-menu-selector");
    if (elem) {
      elem.click();
    }
    this.props.setModal(true, "new-test-pass", {
      newPass: false,
      testSuiteId: e.target.dataset.suiteId,
      testPassId: e.target.dataset.passId,
      testPassName: e.target.dataset.passName,
      customClass: "new-test-pass-modal new-scrollbar",
      testSuiteName: null,
    });
  };

  measureText = (txt) => {
    this.element = this.element || document.createElement("canvas");
    this.context = this.element.getContext("2d");
    this.context.font = "14px Arial";
    const { width } = this.context.measureText(txt);
    if (width > 265) {
      return `${((265 - (width - 265)) / 265) * 10 + 4}px`;
    }
    return "14px";
  };

  render() {
    const { openArray } = this.state;
    const percent = this.state.currentPercent;
    return (
      <div
        aria-hidden="true"
        key="pass-menu-container"
        ref={`nested_nav_menu_container_${this.props.id}`}
        className="checkbox-menu-container"
        style={{
          position: "relative",
          width: "375px",
          zIndex: "1000",
          display: "inline-block",
          maxWidth: "calc(100% - 70px)",
        }}
      >
        <div
          id="test-pass-menu-selector"
          className={`${
            this.state.open
              ? "checkbox-menu-selector-open"
              : "checkbox-menu-selector-closed"
          }`}
          onClick={this.setOpen}
          style={{
            height: "44px",
            transition: "border-color 0.2s",
            color: this.state.active ? "white" : "initial",
            paddingLeft: this.props.currentPass ? "10px" : "0px",
            paddingRight: "25px",
            cursor: "pointer",
          }}
        >
          <div
            id="test-pass-menu-hover-inner"
            style={{
              width: "100px",
              position: "absolute",
              pointerEvents:
                this.props.adminBool && this.props.currentPass ? "all" : "none",
              right: "-100px",
              top: "0px",
              height: "44px",
            }}
          >
            {this.props.adminBool && this.props.currentPass && (
              <span
                id="pass-title-edit"
                key="pass-title-edit"
                onClick={this.props.handleEditTestPassTitle}
                style={{
                  marginLeft: "7px",
                  cursor: "pointer",
                  fontWeight: "600",
                  fontSize: "10pt",
                }}
              >
                {this.state.editPassTitle ? "Done" : "Edit"}
              </span>
            )}
            {this.props.adminBool && this.props.currentPass && (
              <span
                id="test-pass-delete"
                key="test-pass-delete"
                onClick={this.props.handleTestPassDelete}
                style={{
                  marginLeft: "7px",
                  cursor: "pointer",
                  fontWeight: "600",
                  fontSize: "10pt",
                }}
              >
                Delete
              </span>
            )}
          </div>
          {this.props.currentPass ? (
            <span
              id="current-pass-title"
              style={{
                height: "44px",
                lineHeight: "44px",
                cursor: "default",
                display: "block",
                maxWidth: "fit-content",
                fontSize: "24px",
                paddingRight: "16px",
                position: "relative",
                whiteSpace: "nowrap",
                overflow: "hidden",
                paddingLeft: "5px",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginLeft: "4px",
                  verticalAlign: "19px",
                  background:
                    percent < 100
                      ? `linear-gradient(transparent ${
                          100 - percent
                        }%, rgb(0,210,0) ${100 - percent}%)`
                      : "rgb(0,210,0)",
                }}
                id="tiny-dot"
              />
              <span
                style={{
                  fontSize: "11pt",
                  marginRight: "10px",
                  color: "#242424",
                  verticalAlign: "12px",
                }}
              >{`${percent}%`}</span>
              <span
                style={{
                  fontSize: this.measureText(this.props.currentPass.name),
                  color: "#242424",
                  verticalAlign: "text-bottom",
                }}
              >
                {this.props.currentPass.name}
              </span>
            </span>
          ) : (
            <span
              id="current-pass-title"
              style={{
                height: "44px",
                lineHeight: "44px",
                cursor: "default",
                display: "block",
                maxWidth: "fit-content",
                fontSize: "24px",
                paddingRight: "16px",
                position: "relative",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingLeft: "5px",
              }}
            >
              <span style={{ fontSize: "12pt", verticalAlign: "text-bottom" }}>
                Select or create a test pass from menu
              </span>
            </span>
          )}

          <span
            style={{
              position: "absolute",
              transform: this.state.open ? "rotateX(180deg)" : "",
              display: "flex",
              alignItems: "center",
              right: "17px",
              top: "14px",
              transition: "transform .6s",
              pointerEvents: "none",
            }}
          >
            <RoundDropdown />
          </span>
        </div>
        {this.state.open && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "120%",
              left: "0px",
              backgroundColor: "#ffffffd1",
              WebkitBackdropFilter: "blur(4px)",
              backdropFilter: "blur(4px)",
            }}
          >
            <div
              className="checkbox-menu"
              style={{
                maxHeight: "0px",
                overflow: "hidden",
                borderBottomRightRadius:
                  this.props.currentUser.role === "admin" ||
                  this.props.currentUser.role === "lead"
                    ? "0px"
                    : "5px",
                borderBottomLeftRadius:
                  this.props.currentUser.role === "admin" ||
                  this.props.currentUser.role === "lead"
                    ? "0px"
                    : "5px",
                borderBottom:
                  this.props.currentUser.role === "admin" ||
                  this.props.currentUser.role === "lead"
                    ? "none"
                    : "solid 1px #519acc",
                zIndex: "1000",
                left: "0px",
                backdropFilter: "none",
                background: "transparent",
                padding: "0pxx",
                width: "100%",
                transition: "max-height 0.4s",
                minHeight: this.props.suites.length === 0 ? "30px" : "0px",
              }}
            >
              {this.state.testSuites.map((suite, index) => {
                const index1 = index;
                const passes = suite.test_passes.filter(
                  (p) => p.status === "active"
                );
                return passes.length > 0 ? (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      position: "relative",
                      transform: "translateX(-14px)",
                      opacity: "0",
                      transition: "transform 0.4s, opacity 0.4s",
                    }}
                    key={`${suite.id}-${index1}`}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        paddingRight: "17px",
                        paddingLeft: "15px",
                        fontSize: "11pt",
                        fontWeight: "600",
                        color:
                          this.props.suite && this.props.suite.id === suite.id
                            ? "#519acc"
                            : "#242424",
                      }}
                      onClick={this.setOpenArray}
                      data-group-index={index1}
                    >
                      {suite.name}
                    </span>
                    <span
                      onClick={this.setOpenArray}
                      data-group-index={index1}
                      style={{
                        cursor: "pointer",
                        verticalAlign: "middle",
                        height: "28px",
                        transition: "transform .6s",
                        transform: this.state.openArray[index1]
                          ? "rotateX(180deg)"
                          : "",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <RoundDropdown svgStyles={{ pointerEvents: "none" }} />
                    </span>
                    <div
                      style={{
                        height: openArray[index1]
                          ? `${passes.length * 28}px`
                          : "0px",
                        overflow: "hidden",
                        flex: "1 1 100%",
                        transition: "height 0.3s",
                      }}
                    >
                      {passes
                        .filter((p) => p.status === "active")
                        .map((pass, index) => {
                          const index2 = index;
                          const optionPercent = parseInt(
                            pass.percent_complete.substring(
                              0,
                              pass.percent_complete.length - 1
                            )
                          );
                          return (
                            <div
                              id={`pass-${pass.id}-link-container`}
                              className="pass-link-container"
                              style={{ paddingLeft: "30px" }}
                              key={`${index1}-${index2}`}
                            >
                              <TestPassLink
                                measureText={this.measureText}
                                currentUser={this.props.currentUser}
                                key={`${pass.id}-${index2}`}
                                percent={optionPercent}
                                testPass={pass}
                                activeTestPass={this.props.currentPass}
                                testSuiteId={suite.id}
                                copyTestPass={this.copyTestPass}
                                setPass={this.setPass}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
            {(this.props.currentUser.role === "admin" ||
              this.props.currentUser.role === "lead") && (
              <div
                className="new-test-pass-button-container"
                style={{
                  height: "0px",
                  transition: "height 0.3s, opacity 0.2s",
                  opacity: "1",
                  lineHeight: "35px",
                  borderBottomRightRadius: "5px",
                  overflow: "hidden",
                  borderBottomLeftRadius: "5px",
                  paddingLeft: "10px",
                  textAlign: "left",
                  position: "relative",
                  backgroundColor: "transparent",
                  borderLeft: "solid #519acc 1px",
                  borderRight: "solid #519acc 1px",
                  borderBottom: "solid #519acc 1px",
                }}
              >
                <span
                  className={
                    this.state.testSuites.length === 0
                      ? "new-test-pass-button-disabled"
                      : ""
                  }
                  onClick={this.newTestPass}
                  style={{
                    transition: "height 0.3s, opacity 0.2s",
                    opacity: "1",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                  id="new-test-cycle-button"
                >
                  <i
                    className="material-icons"
                    style={{ verticalAlign: "-3px", fontSize: "17px" }}
                  >
                    add
                  </i>
                  New Test Pass
                </span>
                {this.state.testSuites.length === 0 && (
                  <span className="new-test-pass-button-disabled-tooltip">
                    At least one Test Directory is required
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default WithCable(TestPassMenu);
