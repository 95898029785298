import React from "react";

const BugsSilver = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Bugs_-_Silver_svg__cls-1{fill:#939598}.Skills__Bugs_-_Silver_svg__cls-2{fill:#bcbec0}.Skills__Bugs_-_Silver_svg__cls-4{fill:#fff}.Skills__Bugs_-_Silver_svg__cls-5{fill:#4588ad}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g id="Skills__Bugs_-_Silver_svg__Layer_2" data-name="Layer 2">
        {" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-1"
          d="M488 897.46l-229.25-134.7A98.64 98.64 0 01210.08 677l2-265.86a98.65 98.65 0 0150-85.06l231.29-131.21a98.68 98.68 0 0198.66.76l229.22 134.7a98.66 98.66 0 0148.67 85.82l-2 265.86a98.66 98.66 0 01-50 85.06L586.63 898.22a98.66 98.66 0 01-98.63-.76z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-1"
          d="M178.06 542.45l-39.87 8.52L168 596.26l-17.81 77.26 58.07-8.52 9.29-.77-6.2-121.78h-33.29zM904.61 542.45l42.2 8.52-32.13 45.29 17.8 77.26-58.06-8.52-9.29-.77 6.19-121.78h33.29z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-2"
          d="M488 884.37l-229.25-134.7a98.66 98.66 0 01-48.67-85.82l2-265.86a98.66 98.66 0 0150-85.06l231.29-131.15a98.66 98.66 0 0198.66.76l229.22 134.7a98.64 98.64 0 0148.67 85.81l-2 265.86a98.65 98.65 0 01-50 85.06L586.63 885.13a98.68 98.68 0 01-98.63-.76z"
        />{" "}
        <path
          d="M493.38 847.89L288 727.19a88.4 88.4 0 01-43.61-76.9l1.83-238.21A88.42 88.42 0 01291 335.86l207.22-117.52a88.41 88.41 0 0188.4.68L792 339.71a88.41 88.41 0 0143.61 76.9l-1.83 238.22A88.41 88.41 0 01789 731L581.78 848.57a88.41 88.41 0 01-88.4-.68z"
          fill="#519acc"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-4"
          d="M538.17 867.53a95.88 95.88 0 01-48.59-13.18L284.19 733.66a96.19 96.19 0 01-47.31-83.42L238.71 412a96.17 96.17 0 0148.59-82.68l207.22-117.51a96.17 96.17 0 0195.9.74l205.39 120.7a96.17 96.17 0 0147.31 83.42l-1.83 238.22a96.18 96.18 0 01-48.59 82.68L585.48 855.09a95.85 95.85 0 01-47.31 12.44zm3.66-653.16a80.9 80.9 0 00-39.91 10.49L294.7 342.38a81.16 81.16 0 00-41 69.75l-1.83 238.22a81.12 81.12 0 0039.91 70.37l205.39 120.7a81.13 81.13 0 0080.9.62L785.3 724.52a81.14 81.14 0 0041-69.75l1.83-238.22a81.13 81.13 0 00-39.91-70.37l-205.4-120.7a81 81 0 00-40.99-11.11z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-5"
          d="M641 370.93c-8.54-11.42-18.28-18.35-29-20.61a34 34 0 00-20.79 2c-5.57-15.59-17.73-27.43-34.26-30.56a1.93 1.93 0 000-.34 29.49 29.49 0 013.51-13.32c5.13-9.71 15-16 29.24-18.67a4.88 4.88 0 10-1.8-9.6c-21.61 4.05-31.55 15.13-36.08 23.71a39.93 39.93 0 00-4.63 17.4l-12.17.11c-.76 0-1.52 0-2.28.08a39.75 39.75 0 00-4.94-17.37c-4.69-8.49-14.83-19.39-36.52-23.05a4.88 4.88 0 10-1.62 9.63C523 296 523 320.68 523 321.79a5 5 0 00.06.95c-15.07 4.14-28.57 15.6-33.61 30.26a34.07 34.07 0 00-20.15-1.38c-10.64 2.44-20.26 9.55-28.58 21.12a4.89 4.89 0 007.93 5.71c7.65-10.63 16.57-16.79 25.8-17.81a23.23 23.23 0 0112.79 2.07 48.6 48.6 0 00-.33 5.94l.18 19.61a34.84 34.84 0 00-14.28 1.56c-11 3.54-20.78 12-28.94 25.15a4.89 4.89 0 008.31 5.15c7-11.37 15.52-18.72 24.52-21.27a24 24 0 0110.48-.7l.2 22.2a45.35 45.35 0 00.4 5.63 31.68 31.68 0 00-13.12 5.19c-10.18 6.9-16.8 19.2-19.65 36.54a4.89 4.89 0 004 5.61 4.76 4.76 0 00.84.07 4.89 4.89 0 004.77-4.1c2.36-14.32 7.68-24.69 15.4-30a21.43 21.43 0 0110-3.75A47.32 47.32 0 00535 467.06h1.22v-88.62a4.7 4.7 0 014.7-4.7 4.71 4.71 0 014.7 4.7V467h2.13a47.35 47.35 0 0044.33-32.36 21.18 21.18 0 0110.82 3.61c7.81 5.15 13.32 15.42 15.94 29.7a4.89 4.89 0 009.61-1.77c-3.17-17.28-10-29.45-20.31-36.17a31.52 31.52 0 00-14-5 44.11 44.11 0 00.28-5.54l-.2-22a23.42 23.42 0 0111.25.34c9 2.39 17.66 9.59 24.91 20.83a4.89 4.89 0 008.21-5.3c-8.39-13-18.28-21.29-29.39-24.62a34.37 34.37 0 00-15.07-1.2l-.13-19.84c0-1.65-.12-3.28-.3-4.88a5 5 0 00.58-1 23.28 23.28 0 0112.79-2.33c9.25.86 18.28 6.86 26.12 17.35a4.89 4.89 0 007.83-5.85z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-4"
          d="M641 365.83c-8.54-11.42-18.28-18.35-29-20.6a34.06 34.06 0 00-20.79 2c-5.57-15.59-17.73-27.43-34.26-30.56a1.82 1.82 0 000-.33A29.47 29.47 0 01560.5 303c5.13-9.71 15-16 29.24-18.67a4.89 4.89 0 00-1.8-9.61c-21.61 4.06-31.55 15.14-36.08 23.71a40 40 0 00-4.63 17.4l-12.17.11c-.76 0-1.52 0-2.28.08a39.78 39.78 0 00-4.94-17.37c-4.69-8.49-14.83-19.39-36.52-23.05a4.88 4.88 0 10-1.62 9.63c33.3 5.67 33.3 30.35 33.3 31.46a5.11 5.11 0 00.06 1c-15.07 4.13-28.57 15.6-33.61 30.25a34.07 34.07 0 00-20.15-1.38c-10.64 2.45-20.26 9.55-28.58 21.12a4.89 4.89 0 007.93 5.71c7.65-10.62 16.57-16.79 25.8-17.81a23.32 23.32 0 0112.79 2.07 48.6 48.6 0 00-.33 5.94l.18 19.61a34.86 34.86 0 00-14.28 1.57c-11 3.53-20.78 12-28.94 25.14a4.89 4.89 0 008.31 5.16c7-11.37 15.52-18.73 24.52-21.28a24 24 0 0110.48-.7l.2 22.2a45.35 45.35 0 00.4 5.63 31.82 31.82 0 00-13.12 5.19c-10.18 6.91-16.8 19.2-19.65 36.54a4.9 4.9 0 004 5.62 5.93 5.93 0 00.84.06 4.88 4.88 0 004.77-4.09c2.36-14.33 7.68-24.69 15.4-30a21.47 21.47 0 0110-3.76A47.34 47.34 0 00535 462h1.22v-88.65a4.69 4.69 0 014.7-4.7 4.7 4.7 0 014.7 4.7v88.52h2.13a47.34 47.34 0 0044.33-32.36 21.18 21.18 0 0110.82 3.61c7.81 5.15 13.32 15.42 15.94 29.7a4.89 4.89 0 004.84 4 6.11 6.11 0 00.84-.08 4.9 4.9 0 003.93-5.69c-3.17-17.28-10-29.46-20.31-36.18a31.49 31.49 0 00-14-5 44.27 44.27 0 00.28-5.55l-.2-22a23.45 23.45 0 0111.25.35c9 2.38 17.66 9.58 24.91 20.82a4.86 4.86 0 004.15 2.23 4.76 4.76 0 002.6-.78 4.88 4.88 0 001.46-6.75c-8.39-13-18.28-21.29-29.39-24.62a34.35 34.35 0 00-15.07-1.19l-.13-19.8c0-1.65-.12-3.28-.3-4.88a5 5 0 00.58-1 23.21 23.21 0 0112.79-2.32c9.25.85 18.28 6.85 26.12 17.34a4.91 4.91 0 004 2 4.89 4.89 0 003.87-7.81z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-1"
          d="M902.5 659.79a2303.14 2303.14 0 00-720.56 1.15q-1-66.71-1-133.5a2434.54 2434.54 0 01721.9-.07q.37 66.26-.34 132.42z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-2"
          d="M902.5 654.37a2303.14 2303.14 0 00-720.56 1.15q-1-66.71-1-133.5a2434.54 2434.54 0 01721.9-.07q.37 66.25-.34 132.42z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-4"
          d="M413.19 361.32l-44.13-31.35M413.19 364.82a3.56 3.56 0 01-2-.64L367 332.82a3.5 3.5 0 114.06-5.7l44.13 31.35a3.5 3.5 0 01-2 6.35zM391.98 401.09l-92.2 1.37M299.78 406a3.5 3.5 0 010-7l92.2-1.37h.02a3.5 3.5 0 010 7L299.83 406zM414.7 445.84l-47.19 20M367.51 469.34a3.5 3.5 0 01-1.36-6.73l47.19-20a3.5 3.5 0 012.73 6.44l-47.19 20a3.5 3.5 0 01-1.37.29zM668.67 361.32l44.13-31.35M668.68 364.82a3.5 3.5 0 01-2-6.35l44.13-31.35a3.5 3.5 0 114.05 5.7l-44.16 31.36a3.51 3.51 0 01-2.02.64zM689.89 401.09l92.19 1.37M782.09 406H782l-92.19-1.37a3.5 3.5 0 010-7h.05l92.2 1.37a3.5 3.5 0 010 7zM667.16 445.84l47.19 20M714.35 469.34a3.42 3.42 0 01-1.36-.28l-47.19-20a3.5 3.5 0 112.73-6.44l47.19 20a3.51 3.51 0 01-1.37 6.73z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-1"
          d="M410.44 525.72c17-1.21 28.74 7.8 29.5 22.16.49 8.84-3.59 15.59-10.28 18 8.09 1.47 13.65 8.57 14.2 18.76.75 14.38-9.45 24.5-25.41 25.62q-19.5 1.37-39 3.19a1.77 1.77 0 01-1.88-1.56l-7.65-80.94a1.9 1.9 0 011.63-2q19.45-1.85 38.89-3.23zM407 559.4c3.8-.29 6.18-2.67 5.94-6.11s-2.95-5.46-6.78-5.18l-8 .62L399 560c2.66-.2 5.32-.41 8-.6zm4.45 29.27c3.61-.27 5.94-2.77 5.69-6.33s-2.92-5.72-6.56-5.45q-5.08.38-10.16.78l.93 11.77c3.35-.27 6.71-.53 10.08-.77zM526.08 521.23a2.13 2.13 0 012.21 2.08l.26 51.29c.06 21.79-11.59 33.19-33.82 33.72-22 .58-34.19-10.22-35.47-32q-1.15-25.62-2.32-51.24a2.2 2.2 0 012.1-2.18q11.13-.49 22.26-.84a2.11 2.11 0 012.26 2l1.62 53.48c.17 5.66 3.51 8.87 9 8.73s8.66-3.52 8.55-9q-.54-26.81-1.07-53.62a2.2 2.2 0 012.15-2.13q11.19-.22 22.27-.29zM621.17 556.05a1.91 1.91 0 011.82 1.81l-1.92 39.07a4.09 4.09 0 01-1.63 2.88c-7.74 5.9-19.19 9.1-31 8.75-26.48-.79-44.14-18.45-44.14-45S563 519.2 591.11 520a54 54 0 0126.89 8.2 1.86 1.86 0 011 2.26q-3.63 8.88-7.21 17.78a2 2 0 01-2.46.88 40.55 40.55 0 00-18.22-5c-11.78-.36-19.86 7.63-20.08 19.68-.21 12.3 7.37 20.54 18.83 20.89 4.62.14 7.77-.6 9.2-2 .09-2.38.17-4.75.26-7.13-3.85-.14-7.7-.26-11.55-.38-1.13 0-1.87-.54-2-1.41l-1.17-17a1.81 1.81 0 012.09-2q17.31.45 34.48 1.28zm-21.25 24.83v.5a4.17 4.17 0 010-.5zM646.29 583.69c.45-1.2 1.35-1.51 2.31-.84 5.55 3.56 12.23 6.38 16.93 7.11 4 .29 6.75-1.34 7-4.16.59-7.34-34.61-9.38-33.14-36 .91-16.44 14.62-26.68 34.32-25.17 9.19.7 20.53 5.25 28.13 11.28 1 .71 1.26 1.72.82 2.3q-4.86 8.25-9.63 16.53c-.49 1.19-1.41 1.48-2.37.78-5.93-4-13-7.15-17.43-7.86-4-.31-6.64 1.23-6.84 3.93-.51 7.12 35.47 9.66 32.83 36.7-1.59 16.29-15.25 25.93-34.13 24.5-10.32-.79-20.92-4.67-26.81-10-.94-.79-1.26-1.67-.86-2.27q4.38-8.41 8.87-16.83z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-4"
          d="M411.62 520.62c17-1.2 28.74 7.8 29.5 22.16.49 8.85-3.59 15.6-10.27 18 8.08 1.47 13.65 8.57 14.19 18.76.75 14.38-9.44 24.5-25.41 25.62q-19.5 1.36-39 3.19a1.77 1.77 0 01-1.89-1.55l-7.65-80.95a1.91 1.91 0 011.63-2q19.45-1.85 38.9-3.23zm-3.46 33.68c3.8-.29 6.18-2.67 5.94-6.11s-3-5.46-6.78-5.18l-8 .62.9 11.28zm4.45 29.27c3.61-.26 5.94-2.77 5.69-6.33s-2.92-5.72-6.56-5.45c-3.39.25-6.77.51-10.16.78l.93 11.77c3.37-.27 6.73-.52 10.1-.77zM527.26 516.13a2.14 2.14 0 012.21 2.08l.26 51.29c.06 21.8-11.59 33.19-33.82 33.73-22 .57-34.18-10.23-35.47-32q-1.15-25.62-2.32-51.23a2.21 2.21 0 012.11-2.19q11.11-.5 22.25-.84a2.12 2.12 0 012.26 2l1.62 53.48c.17 5.66 3.51 8.87 9 8.74s8.66-3.52 8.55-9.06q-.54-26.8-1.07-53.61a2.22 2.22 0 012.16-2.14q11.13-.18 22.26-.25zM622.35 551a1.91 1.91 0 011.82 1.82l-1.92 39.06a4 4 0 01-1.63 2.88c-7.73 5.9-19.19 9.11-31 8.75-26.49-.79-44.16-18.44-44.15-45s18.73-44.31 46.82-43.48a54 54 0 0126.88 8.16 1.87 1.87 0 011.05 2.27q-3.63 8.88-7.2 17.77a2 2 0 01-2.47.89 40.69 40.69 0 00-18.22-5c-11.78-.35-19.86 7.63-20.07 19.68-.22 12.3 7.36 20.55 18.83 20.9 4.62.14 7.76-.61 9.19-2 .09-2.37.17-4.75.26-7.13-3.85-.13-7.7-.26-11.55-.37-1.13 0-1.87-.55-2-1.41q-.57-8.51-1.17-17a1.82 1.82 0 012.09-2q17.24.35 34.44 1.21zm-21.25 24.79v.49a3.99 3.99 0 010-.49zM647.47 578.59c.45-1.2 1.35-1.51 2.31-.83 5.55 3.56 12.23 6.37 16.93 7.1 4 .3 6.75-1.33 7-4.15.59-7.34-34.61-9.39-33.14-36.05.91-16.45 14.62-26.68 34.32-25.18 9.19.7 20.53 5.25 28.13 11.28 1 .71 1.26 1.72.82 2.3q-4.84 8.25-9.63 16.54c-.49 1.19-1.41 1.48-2.37.77-5.93-4-13-7.14-17.43-7.85-4-.31-6.64 1.22-6.84 3.92-.51 7.13 35.47 9.66 32.83 36.7-1.59 16.3-15.25 25.94-34.13 24.5-10.31-.78-20.91-4.67-26.81-9.94-.94-.8-1.26-1.68-.86-2.27q4.4-8.43 8.87-16.84z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-5"
          d="M424.17 715.06a2.15 2.15 0 012.27-1 43.45 43.45 0 0021.48 5.56c6.31 0 10-2.4 10-6.57 0-3.29-3.41-5.81-10.11-5.81H422.4c-1.27 0-2-.76-1.9-1.9l3.5-49.28a1.94 1.94 0 011.89-1.9h51.43a1.83 1.83 0 011.76 1.9l-2.27 19.33a2 2 0 01-2 1.77H445.9l-1.14 10.61c3.16-1.64 8.46-2.65 12.63-2.78 16.93 0 26.91 10.24 26.91 27.54 0 19.34-13.13 30.83-35.5 30.83-9.22 0-20.21-2.9-29.94-8.72a1.72 1.72 0 01-.89-2zM574.26 698c0 27.29-16.43 45.49-40.94 45.49s-41.06-18.2-41.06-45.49 16.42-45.48 41.06-45.48 40.94 18.19 40.94 45.48zm-26.79 0c0-13.64-5.68-22.74-14.15-22.74s-14.27 9.1-14.27 22.74 5.68 22.75 14.27 22.75 14.15-9.1 14.15-22.75zM661.81 698c0 27.29-16.43 45.49-40.94 45.49s-41.06-18.2-41.06-45.49 16.43-45.48 41.06-45.48 40.94 18.19 40.94 45.48zM635 698c0-13.64-5.68-22.74-14.15-22.74s-14.25 9.1-14.25 22.74 5.68 22.75 14.27 22.75S635 711.65 635 698z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-4"
          d="M424.47 710a2.12 2.12 0 012.27-1 43.56 43.56 0 0021.48 5.56c6.32 0 10-2.4 10-6.57 0-3.29-3.41-5.82-10.11-5.82H422.7c-1.27 0-2-.75-1.9-1.89l3.54-49.28a1.94 1.94 0 011.89-1.9h51.43a1.83 1.83 0 011.76 1.9l-2.27 19.33a2 2 0 01-2 1.76H446.2l-1.14 10.62c3.16-1.64 8.46-2.66 12.63-2.78 16.93 0 26.92 10.23 26.92 27.54 0 19.33-13.14 30.83-35.51 30.83-9.22 0-20.21-2.91-29.94-8.72a1.71 1.71 0 01-.88-2zM574.56 692.94c0 27.29-16.43 45.48-40.94 45.48s-41.06-18.19-41.06-45.48 16.43-45.48 41.06-45.48 40.94 18.19 40.94 45.48zm-26.79 0c0-13.64-5.68-22.74-14.15-22.74s-14.27 9.1-14.27 22.74 5.68 22.74 14.27 22.74 14.15-9.09 14.15-22.74zM662.11 692.94c0 27.29-16.43 45.48-40.94 45.48s-41.06-18.19-41.06-45.48 16.43-45.48 41.06-45.48 40.94 18.19 40.94 45.48zm-26.79 0c0-13.64-5.68-22.74-14.15-22.74s-14.27 9.1-14.27 22.74 5.68 22.74 14.27 22.74 14.15-9.09 14.15-22.74zM388.02 684.98l4.35 8.81 9.71 1.41-7.03 6.86 1.66 9.68-8.69-4.57-8.7 4.57 1.67-9.68-7.04-6.86 9.72-1.41 4.35-8.81zM696.15 684.98l4.34 8.81 9.72 1.41-7.03 6.86 1.66 9.68-8.69-4.57-8.7 4.57 1.66-9.68-7.03-6.86 9.72-1.41 4.35-8.81z"
        />{" "}
        <circle
          className="Skills__Bugs_-_Silver_svg__cls-2"
          cx={538.08}
          cy={793.48}
          r={39.42}
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-4"
          d="M538.08 834.4A40.92 40.92 0 11579 793.48a41 41 0 01-40.92 40.92zm0-78.84A37.92 37.92 0 10576 793.48a38 38 0 00-37.92-37.92z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_svg__cls-4"
          d="M558 774.05a9.24 9.24 0 00-5.14.52v-2.71h-29.57v2.71a9.25 9.25 0 00-5.14-.52 5.69 5.69 0 00-4.49 4.53c-.71 3.15.86 6.75 4.42 10.15a29.52 29.52 0 0010.55 6.27 15.12 15.12 0 006.44 5.34c-.39 1.43-1.76 5.56-5.08 8.09h16.18c-3.31-2.53-4.68-6.66-5.07-8.09a15.12 15.12 0 006.44-5.34 29.48 29.48 0 0010.55-6.29c3.57-3.4 5.14-7 4.43-10.15a5.69 5.69 0 00-4.52-4.51zm-37.58 12.2c-2.58-2.46-3.87-5-3.45-6.91a2.32 2.32 0 011.88-1.94 6.65 6.65 0 014.59 1.06 41.31 41.31 0 002.56 11.71 24.39 24.39 0 01-5.55-3.92zm35.27 0a24.61 24.61 0 01-5.59 3.92 41.64 41.64 0 002.56-11.71 6.68 6.68 0 014.6-1.06 2.31 2.31 0 011.87 1.94c.46 1.87-.83 4.45-3.41 6.91zM527.7 810.61h20.77v4.49H527.7z"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default BugsSilver;
