import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useDeviceLab from "@hooks/data/useDeviceLab";
import LoadingSpinner from "../common/loading_spinner";

const LabSettings = (props) => {
  const {
    settings: {
      settings: { tier_1_limit, tier_2_limit },
    },
    hooks: {
      mutations: { useUpdateSettings },
    },
  } = useDeviceLab();

  const { close } = props;

  const [tierOneLimit, setTierOneLimit] = useState(tier_1_limit);
  const [tierTwoLimit, setTierTwoLimit] = useState(tier_2_limit);

  const [updateSettings, { isLoading }] = useUpdateSettings();

  const handleSubmit = async (data) => {
    const result = await updateSettings(data);

    if (result.error) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: result.error.data.message,
        displayLength: 5000,
        classes: "red",
      });
    }

    if (result.data) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "Settings updated successfully",
        displayLength: 3000,
        classes: "green",
      });
      close();
    }
  };

  function submit(e) {
    e.preventDefault();
    handleSubmit({
      app_version: {
        settings: {
          tier_1_limit: parseInt(tierOneLimit),
          tier_2_limit: parseInt(tierTwoLimit),
        },
      },
    });
  }

  function handleTierOne(e) {
    setTierOneLimit(e.target.value);
  }

  function handleTierTwo(e) {
    setTierTwoLimit(e.target.value);
  }

  return (
    <div>
      <div>
        <form
          style={{ marginTop: "20px", flexWrap: "wrap" }}
          className="flex-container flex-row flex-al-center"
          onSubmit={submit}
        >
          <div
            style={{ width: "100%" }}
            className="flex-column flex-al-center flex-space-between flex-container"
          >
            <p className="large-title">Device Lab Settings</p>
            <div
              style={{ marginBottom: "20px" }}
              className="flex-row flex-al-center flex-space-between flex-container"
            >
              <div style={{ marginRight: "20px" }}>
                <label className="common-input-label" htmlFor="tier1">
                  Tier 1 Limit:
                </label>
                <input
                  type="number"
                  min={0}
                  id="tier1"
                  style={{ width: 100 }}
                  className="browser-default common-input"
                  value={tierOneLimit}
                  onChange={handleTierOne}
                />
              </div>
              <div>
                <label className="common-input-label" htmlFor="tier2">
                  Tier 2 Limit:
                </label>
                <input
                  type="number"
                  min={0}
                  style={{ width: 100 }}
                  className="browser-default common-input"
                  id="tier2"
                  value={tierTwoLimit}
                  onChange={handleTierTwo}
                />
              </div>
            </div>
            <SubmitContainer>
              <button
                type="button"
                onClick={submit}
                className="common-button-submit"
              >
                Save Settings
              </button>
              <PositionedLoadingSpinner loading={isLoading} size={30} />
            </SubmitContainer>
          </div>
        </form>
      </div>
    </div>
  );
};

const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
`;

const PositionedLoadingSpinner = styled(LoadingSpinner)`
  top: 3px;
  right: calc(50% + 32px);
`;

LabSettings.propTypes = {
  close: PropTypes.func.isRequired,
};

export default LabSettings;
