import Axios from "axios";

class ApiClient {
  constructor(headers = {}) {
    this.requestHeaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-TOKEN": document.querySelector("meta[name='csrf-token']").content,
      ...headers,
    };
    this.axios = Axios.create({});
    this.axios.defaults.headers.common = {
      ...this.axios.defaults.headers.common,
      ...this.requestHeaders,
    };
  }

  get(path) {
    return this.makeRequest("GET", path);
  }

  post(path, body) {
    return this.makeRequest("POST", path, body);
  }

  patch(path, body) {
    return this.makeRequest("PATCH", path, body);
  }

  put(path, body) {
    return this.makeRequest("PUT", path, body);
  }

  delete(path) {
    return this.makeRequest("DELETE", path);
  }

  async makeRequest(method, path, body) {
    try {
      const response = await this.axios[method.toLowerCase()](path, body);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default ApiClient;
