import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import useDeviceLab from "@hooks/data/useDeviceLab";
import queryString from "query-string";
import _ from "lodash";
import RoundDropdown from "@icons/round_dropdown";
import LoadingSpinner from "@components/common/loading_spinner";

const PagingControls = () => {
  const {
    paging,
    isLoading,
    filters: { query },
    PARAM_KEYS: { PAGE },
  } = useDeviceLab();

  const decrementEnabled = useMemo(() => {
    return !!paging?.prev;
  }, [paging]);

  const incrementEnabled = useMemo(() => {
    return !!paging?.next;
  }, [paging]);

  const turnPage = useCallback(
    (page) => {
      const nextQuery = { ...query };
      if (!page || page <= 1) {
        delete nextQuery[PAGE];
      } else {
        nextQuery[PAGE] = page;
      }

      const nextQueryString = queryString.stringify(nextQuery, {
        arrayFormat: "bracket",
      });
      window.history.replaceState({}, "", `?${nextQueryString}`);
    },
    [query, PAGE]
  );

  const decrementPage = useCallback(() => {
    if (decrementEnabled) {
      turnPage(paging.prev);
    }
  }, [paging, turnPage, decrementEnabled]);

  const incrementPage = useCallback(() => {
    if (incrementEnabled) {
      turnPage(paging.next);
    }
  }, [paging, turnPage, incrementEnabled]);

  if ((!paging || _.isEmpty(paging)) && !isLoading) {
    return null;
  }

  return (
    <Container>
      <CSSTransition
        in={decrementEnabled}
        unmountOnExit
        appear
        timeout={{ enter: 150, exit: 300 }}
        classNames="scale-in"
      >
        <LeftButton type="button" onClick={decrementPage} disabled={false}>
          <RoundDropdown />
        </LeftButton>
      </CSSTransition>
      <PagingInfo>
        {!isLoading &&
          `${paging.from} - ${paging.to} of ${paging.count} Total Devices`}
        <StyledLoadingSpinner loading={isLoading} size={24} />
      </PagingInfo>
      <CSSTransition
        in={incrementEnabled}
        unmountOnExit
        appear
        timeout={{ enter: 150, exit: 300 }}
        classNames="scale-in"
      >
        <RightButton type="button" onClick={incrementPage} disabled={false}>
          <RoundDropdown />
        </RightButton>
      </CSSTransition>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 10px 0;
`;

const PagingInfo = styled.div`
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
`;

const PagerButtonStyles = css`
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  border-color: #d7d7d7;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: #519acc;
  }
`;

const LeftButton = styled.button`
  ${PagerButtonStyles}

  svg {
    transform: rotate(90deg);
  }
`;

const RightButton = styled.button`
  ${PagerButtonStyles}

  svg {
    transform: rotate(-90deg);
  }
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: static;
  display: inline-block;
  margin: 0 8px;
`;

export default PagingControls;
