import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import BasicSelect from "../common/basic_select.jsx";

class NewTestPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testSuiteId: this.props.data.testSuiteId,
      name: this.props.data.newPass ? "" : this.props.data.testPassName,
      templateOptions: [],
      templateName: "",
      deviceOptions: [[], [], [], [], []],
      macOsOptions: [],
      androidOsOptions: [],
      iosOsOptions: [],
      androidDeviceOptions: [],
      iosDeviceOptions: [],
      chromeOsOptions: [],
      wearableDeviceOptions: [],
      wearableOsOptions: [],
      selectedOptionwindows: [],
      selectedOptionmac: [],
      selectedOptionios: [],
      selectedOptionandroid: [],
      selectedOptionchrome: [],
      selectedOptionwearable: [],
      selectedTemplate: null,
      selectedOptionSuite: null,
      dragging: false,
      createAnother: false,
    };
    this.createTestPassButton = React.createRef();
    this.swalThirdButtonListener = (e) => {
      if (e.target.id === "swal-third-button") {
        this.swalThirdButton = true;
        var elem = document.querySelector(
          "body > div.swal2-container.swal2-center.swal2-backdrop-show > div > div.swal2-actions.swal2-3button > button.swal2-confirm.swal2-styled"
        );
        elem.click();
        window.removeEventListener("click", this.swalThirdButtonListener);
      }
    };
    this.windowsOsOptions = [
      { label: "Windows 11", value: "Windows 11" },
      { label: "Windows 10", value: "Windows 10" },
      { label: "Windows 8", value: "Windows 8" },
      { label: "Windows 7", value: "Windows 7" },
      { label: "Windows Vista", value: "Windows Vista" },
      { label: "Windows XP", value: "Windows XP" },
    ];
    this.browserOptions = {
      label: "Browser",
      options: [
        { label: "Chrome", value: "Chrome" },
        { label: "Firefox", value: "Firefox" },
        { label: "Safari 14", value: "Safari14" },
        { label: "Safari 15", value: "Safari15" },
        { label: "Safari 16", value: "Safari16" },
        { label: "Safari 17", value: "Safari17" },
        { label: "Safari", value: "Safari" },
        { label: "Opera", value: "Opera" },
        // {label: "Internet Explorer 11", value: "Internet Explorer 11"},
        { label: "Microsoft Edge", value: "Microsoft Edge" },
        { label: "Facebook", value: "Facebook" },
        { label: "Instagram", value: "Instagram" },
        { label: "Twitter", value: "Twitter" },
        { label: "Other", value: "Other" },
      ],
    };
    this.browserWindowsOptions = {
      label: "Browser",
      options: [
        { label: "Microsoft Edge", value: "Microsoft Edge" },
        { label: "Chrome", value: "Chrome" },
        { label: "Firefox", value: "Firefox" },
        { label: "Opera", value: "Opera" },
        { label: "Facebook", value: "Facebook" },
        { label: "Instagram", value: "Instagram" },
        { label: "Twitter", value: "Twitter" },
        { label: "Other", value: "Other" },
      ],
    };
    this.androidBrowserOptions = {
      label: "Browser",
      options: [
        { label: "Chrome", value: "Chrome" },
        { label: "Firefox", value: "Firefox" },
        { label: "Safari", value: "Safari" },
        { label: "Opera", value: "Opera" },
        { label: "Native", value: "Native" },
        { label: "Facebook", value: "Facebook" },
        { label: "Instagram", value: "Instagram" },
        { label: "Twitter", value: "Twitter" },
        { label: "Other", value: "Other" },
      ],
    };
    this.chromeOsBrowserOptions = {
      label: "Browser",
      options: [
        { label: "Chrome", value: "Chrome" },
        { label: "Firefox", value: "Firefox" },
        { label: "Microsoft Edge", value: "Microsoft Edge" },
        { label: "Facebook", value: "Facebook" },
        { label: "Instagram", value: "Instagram" },
        { label: "Twitter", value: "Twitter" },
      ],
    };
  }

  async componentDidMount() {
    this.props.setBackdropLoader(true);
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    if (this.props.data.newPass) {
      var name = document.querySelector("#test-pass-name-input");
      if (name) {
        name.blur();
      }
      axios
        .get("/tc_device_options", { headers: { Accept: "application/json" } })
        .then((res) => {
          var macOsOptions = res.data.macOsOptions.map((os) =>
            os.split(".").length > 2
              ? os.slice(0, os.lastIndexOf("."))
              : os.slice(0, os.indexOf(".") + 3)
          );
          macOsOptions.sort((a, b) => {
            var aNum = a.split(" ")[1].split(".");
            aNum = parseInt(aNum[0]) + parseInt(aNum[1]) / 100;
            var bNum = b.split(" ")[1].split(".");
            bNum = parseInt(bNum[0]) + parseInt(bNum[1]) / 100;
            return bNum - aNum;
          });
          macOsOptions = [...new Set(macOsOptions)].map((os) => {
            return { label: os, value: os };
          });
          var androidOsOptions = res.data.androidOsOptions
            .map((os) => os.slice(0, os.indexOf(".")))
            .sort((a, b) => {
              parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1]);
            });
          androidOsOptions = [...new Set(androidOsOptions)].map((os) => {
            return { label: os, value: os };
          });
          var iosOsOptions = res.data.iosOsOptions
            .map((os) => os.slice(0, os.indexOf(".")))
            .sort(
              (a, b) => parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1])
            );
          iosOsOptions = [...new Set(iosOsOptions)].map((os) => {
            return { label: os, value: os };
          });
          var androidDeviceOptions = Array.from(
            new Set(res.data.androidDeviceOptions.map(JSON.stringify)),
            JSON.parse
          );
          androidDeviceOptions = androidDeviceOptions.sort().map((device) => {
            return {
              label: device[0],
              value: device[0],
              deviceType: device[1],
            };
          });
          var iosDeviceOptions = Array.from(
            new Set(res.data.iosDeviceOptions.map(JSON.stringify)),
            JSON.parse
          );
          iosDeviceOptions = iosDeviceOptions.sort().map((device) => {
            return {
              label: device[0],
              value: device[0],
              deviceType: device[1],
            };
          });
          var chromeOsOptions = [...new Set(res.data.chromeOsOptions)]
            .sort()
            .map((os) => {
              return { label: os, value: os };
            });
          var wearableDeviceOptions = Array.from(
            new Set(res.data.wearableDeviceOptions.map(JSON.stringify)),
            JSON.parse
          );
          wearableDeviceOptions = wearableDeviceOptions.sort().map((device) => {
            return {
              label: device[0],
              value: device[0],
              deviceType: device[1],
            };
          });
          var wearableOsOptions = [...new Set(res.data.wearableOsOptions)]
            .sort()
            .map((os) => {
              return { label: os, value: os };
            });
          setTimeout(() => {
            this.setState(
              {
                macOsOptions,
                androidOsOptions,
                iosOsOptions,
                androidDeviceOptions,
                iosDeviceOptions,
                chromeOsOptions,
                wearableDeviceOptions,
                wearableOsOptions,
                deviceOptions: res.data.deviceOptions,
              },
              () => {
                axios
                  .get(`/test_pass_templates/${this.props.data.projectId}`, {
                    headers: { Accept: "application/json" },
                  })
                  .then((res) => {
                    var templateOptions = res.data.map((o) => ({
                      label: o.name,
                      value: o.id,
                      devices: o.devices,
                    }));
                    this.setState({ templateOptions: templateOptions }, () => {
                      this.props.setBackdropLoader(false);
                      document.querySelector("#test-pass-name-input").focus();
                    });
                  })
                  .catch((err) => {
                    this.props.setBackdropLoader(false);
                    console.log(err);
                  });
              }
            );
          }, 300);
        })
        .catch((err) => {
          this.props.setBackdropLoader(false);
          console.log(err);
        });
    } else {
      axios
        .get("/tc_device_options", { headers: { Accept: "application/json" } })
        .then((res) => {
          var deviceOptions = res.data.deviceOptions;
          var macOsOptions = res.data.macOsOptions.map((os) =>
            os.split(".").length > 2
              ? os.slice(0, os.lastIndexOf("."))
              : os.slice(0, os.indexOf(".") + 3)
          );
          macOsOptions.sort(
            (a, b) => parseInt(b.split(".")[1]) - parseInt(a.split(".")[1])
          );
          var androidOsOptions = res.data.androidOsOptions
            .map((os) => os.slice(0, os.indexOf(".")))
            .sort(
              (a, b) => parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1])
            );
          var iosOsOptions = res.data.iosOsOptions
            .map((os) => os.slice(0, os.indexOf(".")))
            .sort(
              (a, b) => parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1])
            );
          var androidDeviceOptions = res.data.androidDeviceOptions;
          var iosDeviceOptions = res.data.iosDeviceOptions;
          var chromeOsOptions = res.data.chromeOsOptions;
          var wearableOsOptions = res.data.wearableOsOptions;
          var wearableDeviceOptions = res.data.wearableDeviceOptions;
          axios
            .get(`/get_test_devices/${this.props.data.testPassId}`, {
              headers: { Accept: "application/json" },
            })
            .then((res) => {
              var selectedOption = [[], [], [], [], [], []];
              var selectedOptionwindows = [];
              var selectedOptionmac = [];
              var selectedOptionios = [];
              var selectedOptionandroid = [];
              var selectedOptionchrome = [];
              var selectedOptionwearable = [];
              var windowsOsOptions = this.windowsOsOptions.map((o) => o.label);
              res.data.test_devices.forEach((testDevice) => {
                if (windowsOsOptions.indexOf(testDevice.os_name) !== -1) {
                  selectedOptionwindows.push([
                    {},
                    { label: testDevice.os_name, value: testDevice.os_name },
                    testDevice.browser_name
                      ? {
                          label: testDevice.browser_name,
                          value: testDevice.browser_name,
                        }
                      : {},
                  ]);
                } else if (macOsOptions.indexOf(testDevice.os_name) !== -1) {
                  selectedOptionmac.push([
                    {},
                    { label: testDevice.os_name, value: testDevice.os_name },
                    testDevice.browser_name
                      ? {
                          label: testDevice.browser_name,
                          value: testDevice.browser_name,
                        }
                      : {},
                  ]);
                } else if (
                  iosDeviceOptions
                    .map((d) => d[0])
                    .indexOf(testDevice.device_name) !== -1 &&
                  iosOsOptions.indexOf(testDevice.os_name) !== -1
                ) {
                  selectedOptionios.push([
                    {
                      label: testDevice.device_name,
                      value: testDevice.device_name,
                      deviceType: testDevice.device_type_id,
                    },
                    { label: testDevice.os_name, value: testDevice.os_name },
                    testDevice.browser_name
                      ? {
                          label: testDevice.browser_name,
                          value: testDevice.browser_name,
                        }
                      : {},
                  ]);
                } else if (
                  androidDeviceOptions
                    .map((d) => d[0])
                    .indexOf(testDevice.device_name) !== -1 &&
                  androidOsOptions.indexOf(testDevice.os_name) !== -1
                ) {
                  selectedOptionandroid.push([
                    {
                      label: testDevice.device_name,
                      value: testDevice.device_name,
                      deviceType: testDevice.device_type_id,
                    },
                    { label: testDevice.os_name, value: testDevice.os_name },
                    testDevice.browser_name
                      ? {
                          label: testDevice.browser_name,
                          value: testDevice.browser_name,
                        }
                      : {},
                  ]);
                } else if (chromeOsOptions.indexOf(testDevice.os_name) !== -1) {
                  selectedOptionchrome.push([
                    {},
                    { label: testDevice.os_name, value: testDevice.os_name },
                    testDevice.browser_name
                      ? {
                          label: testDevice.browser_name,
                          value: testDevice.browser_name,
                        }
                      : {},
                  ]);
                } else if (
                  wearableDeviceOptions
                    .map((d) => d[0])
                    .indexOf(testDevice.device_name) !== -1 &&
                  wearableOsOptions.indexOf(testDevice.os_name) !== -1
                ) {
                  selectedOptionwearable.push([
                    {
                      label: testDevice.device_name,
                      value: testDevice.device_name,
                      deviceType: testDevice.device_type_id,
                    },
                    { label: testDevice.os_name, value: testDevice.os_name },
                    {},
                  ]);
                }
              });
              chromeOsOptions = [...new Set(chromeOsOptions)]
                .sort()
                .map((os) => {
                  return { label: os, value: os };
                });
              macOsOptions = [...new Set(macOsOptions)].map((os) => {
                return { label: os, value: os };
              });
              androidOsOptions = [...new Set(androidOsOptions)].map((os) => {
                return { label: os, value: os };
              });
              iosOsOptions = [...new Set(iosOsOptions)].map((os) => {
                return { label: os, value: os };
              });
              androidDeviceOptions = Array.from(
                new Set(androidDeviceOptions.map(JSON.stringify)),
                JSON.parse
              );
              androidDeviceOptions = androidDeviceOptions
                .sort()
                .map((device) => {
                  return {
                    label: device[0],
                    value: device[0],
                    deviceType: device[1],
                  };
                });
              iosDeviceOptions = Array.from(
                new Set(iosDeviceOptions.map(JSON.stringify)),
                JSON.parse
              );
              iosDeviceOptions = iosDeviceOptions.sort().map((device) => {
                return {
                  label: device[0],
                  value: device[0],
                  deviceType: device[1],
                };
              });
              wearableDeviceOptions = Array.from(
                new Set(wearableDeviceOptions.map(JSON.stringify)),
                JSON.parse
              );
              wearableDeviceOptions = wearableDeviceOptions
                .sort()
                .map((device) => {
                  return {
                    label: device[0],
                    value: device[0],
                    deviceType: device[1],
                  };
                });
              wearableOsOptions = [...new Set(wearableOsOptions)]
                .sort()
                .map((os) => {
                  return { label: os, value: os };
                });
              setTimeout(() => {
                this.setState(
                  {
                    macOsOptions,
                    androidOsOptions,
                    iosOsOptions,
                    androidDeviceOptions,
                    iosDeviceOptions,
                    chromeOsOptions,
                    wearableOsOptions,
                    wearableDeviceOptions,
                    deviceOptions: deviceOptions,
                    selectedOptionwindows,
                    selectedOptionmac,
                    selectedOptionios,
                    selectedOptionandroid,
                    selectedOptionchrome,
                    selectedOptionwearable,
                  },
                  () => {
                    this.props.setBackdropLoader(false);
                  }
                );
                document.querySelector("#test-pass-name-input").focus();
              }, 300);
            })
            .catch((err) => {
              this.props.setBackdropLoader(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  updateTemplate = () => {
    var devices = {
      selectedOptionandroid: this.state.selectedOptionandroid,
      selectedOptionchrome: this.state.selectedOptionchrome,
      selectedOptionios: this.state.selectedOptionios,
      selectedOptionwearable: this.state.selectedOptionwearable,
      selectedOptionwindows: this.state.selectedOptionwindows,
      selectedOptionmac: this.state.selectedOptionmac,
    };
    devices = JSON.stringify(devices);
    axios
      .patch(`/test_pass_template/${this.state.selectedTemplate.value}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        test_pass_template: {
          devices: devices,
          name: this.state.templateName,
        },
      })
      .then((res) => {
        var selectedTemplate = {
          label: res.data.name,
          value: res.data.id,
          devices: res.data.devices,
        };
        var templateOptions = this.state.templateOptions.map((o) => {
          if (o.value === res.data.id) {
            return {
              label: res.data.name,
              value: res.data.id,
              devices: res.data.devices,
            };
          } else {
            return o;
          }
        });
        this.setState({ selectedTemplate, templateOptions });
        M.toast({
          html: `Test Pass Template updated`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  };

  createTemplate = () => {
    var devices = {
      selectedOptionandroid: this.state.selectedOptionandroid,
      selectedOptionchrome: this.state.selectedOptionchrome,
      selectedOptionios: this.state.selectedOptionios,
      selectedOptionwearable: this.state.selectedOptionwearable,
      selectedOptionwindows: this.state.selectedOptionwindows,
      selectedOptionmac: this.state.selectedOptionmac,
    };
    devices = JSON.stringify(devices);
    axios
      .post(`/test_pass_template.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        test_pass_template: {
          devices: devices,
          name: this.state.templateName,
          project_id: this.props.data.projectId,
          owner_id: this.props.data.currentUserId,
        },
      })
      .then((res) => {
        var selectedTemplate = {
          label: res.data.name,
          value: res.data.id,
          devices: res.data.devices,
        };
        var templateOptions = [
          ...this.state.templateOptions,
          ...[selectedTemplate],
        ];
        templateOptions.sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase()
            ? -1
            : a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : 0
        );
        this.setState({ selectedTemplate, templateOptions });
        M.toast({
          html: `Test Pass Template created`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  };

  handleSubmitPass = () => {
    this.submitNewPass();
  };

  submitNewPass = () => {
    this.props.setBackdropLoader(true);
    if (this.createTestPassButtonn && this.createTestPassButton.current) {
      this.createTestPassButton.current.disabled = true;
    }
    var windowsDevices = [];
    var macDevices = [];
    var iosDevices = [];
    var androidDevices = [];
    var chromeDevices = [];
    var wearableDevices = [];
    for (var i = 0; i < this.state.selectedOptionwindows.length; i++) {
      if (this.state.selectedOptionwindows[i][1].label) {
        var selectedDevice = {
          device_name: "",
          os_name: this.state.selectedOptionwindows[i][1].label,
          browser_name: this.state.selectedOptionwindows[i][2].label
            ? this.state.selectedOptionwindows[i][2].label
            : "",
          value: 1,
          device_type_id: 4,
        };
        windowsDevices.push(selectedDevice);
      }
    }
    for (var i = 0; i < this.state.selectedOptionmac.length; i++) {
      if (this.state.selectedOptionmac[i][1].label) {
        var selectedDevice = {
          device_name: "",
          os_name: this.state.selectedOptionmac[i][1].label,
          browser_name: this.state.selectedOptionmac[i][2].label
            ? this.state.selectedOptionmac[i][2].label
            : "",
          value: 2,
          device_type_id: 4,
        };
        macDevices.push(selectedDevice);
      }
    }
    for (var i = 0; i < this.state.selectedOptionios.length; i++) {
      if (
        this.state.selectedOptionios[i][0].label &&
        this.state.selectedOptionios[i][1].label
      ) {
        var selectedDevice = {
          device_name: this.state.selectedOptionios[i][0].label,
          os_name: this.state.selectedOptionios[i][1].label,
          browser_name: this.state.selectedOptionios[i][2].label
            ? this.state.selectedOptionios[i][2].label
            : "",
          value: 3,
          device_type_id: this.state.selectedOptionios[i][0].deviceType,
        };
        iosDevices.push(selectedDevice);
      }
    }
    for (var i = 0; i < this.state.selectedOptionandroid.length; i++) {
      if (
        this.state.selectedOptionandroid[i][0].label &&
        this.state.selectedOptionandroid[i][1].label
      ) {
        var selectedDevice = {
          device_name: this.state.selectedOptionandroid[i][0].label,
          os_name: this.state.selectedOptionandroid[i][1].label,
          browser_name: this.state.selectedOptionandroid[i][2].label
            ? this.state.selectedOptionandroid[i][2].label
            : "",
          value: 4,
          device_type_id: this.state.selectedOptionandroid[i][0].deviceType,
        };
        androidDevices.push(selectedDevice);
      }
    }
    for (var i = 0; i < this.state.selectedOptionchrome.length; i++) {
      if (this.state.selectedOptionchrome[i][1].label) {
        var selectedDevice = {
          device_name: "",
          os_name: this.state.selectedOptionchrome[i][1].label,
          browser_name: this.state.selectedOptionchrome[i][2].label
            ? this.state.selectedOptionchrome[i][2].label
            : "",
          value: 5,
          device_type_id: 3,
        };
        chromeDevices.push(selectedDevice);
      }
    }
    for (var i = 0; i < this.state.selectedOptionwearable.length; i++) {
      if (this.state.selectedOptionwearable[i][1].label) {
        var selectedDevice = {
          device_name: this.state.selectedOptionwearable[i][0].label,
          os_name: this.state.selectedOptionwearable[i][1].label,
          value: 6,
          device_type_id: 5,
        };
        wearableDevices.push(selectedDevice);
      }
    }
    var testDevicesArray = [
      ...windowsDevices,
      ...macDevices,
      ...iosDevices,
      ...androidDevices,
      ...chromeDevices,
      ...wearableDevices,
    ];
    axios
      .post(`/test_passes.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        test_pass: {
          test_suite_id:
            this.state.testSuiteId === null
              ? this.state.selectedOptionSuite.value
              : this.state.testSuiteId,
          test_devices: testDevicesArray,
          name: this.state.name,
        },
      })
      .then((res) => {
        if (this.state.createAnother) {
          this.setState(
            {
              name: "",
              selectedOptionwindows: [],
              selectedOptionmac: [],
              selectedOptionios: [],
              selectedOptionandroid: [],
              selectedOptionchrome: [],
              selectedOptionSuite: [],
              selectedOptionwearable: [],
              dragging: false,
              createAnother: false,
            },
            () => {
              document.getElementById("react-modal").scrollTop = 0;
              document.querySelector("#test-pass-name-input").focus();
              M.toast({
                html: `Test Pass created`,
                displayLength: 3000,
                classes: "green",
              });
              this.props.setBackdropLoader(false);
            }
          );
        } else {
          window.location = `${window.location.origin}/projects/${res.data.test_suite.project_id}/test_passes/${res.data.id}`;
        }
      })
      .catch((err) => console.error(err));
  };

  handleSelectChange = (selectedOption) => {
    var nameArray = selectedOption.name.split("-");
    var name = "selectedOption" + nameArray[0];
    var options = this.state[name];
    options[parseInt(nameArray[1])][parseInt(nameArray[2])] = selectedOption;
    this.setState({
      [name]: options,
    });
  };

  handleSuiteChange = (selectedOption) => {
    this.setState({
      selectedOptionSuite: selectedOption,
    });
  };

  handleAddNewWindowsDevice = () => {
    var windowsOptions = this.state.selectedOptionwindows;
    windowsOptions.push([{}, {}, {}]);
    this.setState({
      selectedOptionwindows: windowsOptions,
    });
  };

  handleRemoveWindowsDevice = (e) => {
    if (this.state.selectedOptionwindows.length === 1) {
      this.setState({
        selectedOptionwindows: [],
      });
    } else {
      var windowsOptions = this.state.selectedOptionwindows;
      windowsOptions = [
        ...windowsOptions.slice(0, parseInt(e.target.dataset.index)),
        ...windowsOptions.slice(parseInt(e.target.dataset.index) + 1),
      ];
      this.setState({
        selectedOptionwindows: windowsOptions,
      });
    }
  };

  handleAddNewChromeDevice = () => {
    var chromeOptions = this.state.selectedOptionchrome;
    chromeOptions.push([{}, {}, {}]);
    this.setState({
      selectedOptionchrome: chromeOptions,
    });
  };

  handleRemoveChromeDevice = (e) => {
    if (this.state.selectedOptionchrome.length === 1) {
      this.setState({
        selectedOptionchrome: [],
      });
    } else {
      var chromeOptions = this.state.selectedOptionchrome;
      chromeOptions = [
        ...chromeOptions.slice(0, parseInt(e.target.dataset.index)),
        ...chromeOptions.slice(parseInt(e.target.dataset.index) + 1),
      ];
      this.setState({
        selectedOptionchrome: chromeOptions,
      });
    }
  };

  handleAddNewMacDevice = () => {
    var macOptions = this.state.selectedOptionmac;
    macOptions.push([{}, {}, {}]);
    this.setState({
      selectedOptionmac: macOptions,
    });
  };

  handleRemoveMacDevice = (e) => {
    if (this.state.selectedOptionmac.length === 1) {
      this.setState({
        selectedOptionmac: [],
      });
    } else {
      var macOptions = this.state.selectedOptionmac;
      macOptions = [
        ...macOptions.slice(0, parseInt(e.target.dataset.index)),
        ...macOptions.slice(parseInt(e.target.dataset.index) + 1),
      ];
      this.setState({
        selectedOptionmac: macOptions,
      });
    }
  };

  handleAddNewIOSDevice = () => {
    var iosOptions = this.state.selectedOptionios;
    iosOptions.push([{}, {}, {}]);
    this.setState({
      selectedOptionios: iosOptions,
    });
  };

  handleRemoveIOSDevice = (e) => {
    if (this.state.selectedOptionios.length === 1) {
      this.setState({
        selectedOptionios: [],
      });
    } else {
      var iosOptions = this.state.selectedOptionios;
      iosOptions = [
        ...iosOptions.slice(0, parseInt(e.target.dataset.index)),
        ...iosOptions.slice(parseInt(e.target.dataset.index) + 1),
      ];
      this.setState({
        selectedOptionios: iosOptions,
      });
    }
  };

  handleAddNewAndroidDevice = () => {
    var androidOptions = this.state.selectedOptionandroid;
    androidOptions.push([{}, {}, {}]);
    this.setState({
      selectedOptionandroid: androidOptions,
    });
  };

  handleRemoveAndroidDevice = (e) => {
    if (this.state.selectedOptionandroid.length === 1) {
      this.setState({
        selectedOptionandroid: [],
      });
    } else {
      var androidOptions = this.state.selectedOptionandroid;
      androidOptions = [
        ...androidOptions.slice(0, parseInt(e.target.dataset.index)),
        ...androidOptions.slice(parseInt(e.target.dataset.index) + 1),
      ];
      this.setState({
        selectedOptionandroid: androidOptions,
      });
    }
  };

  handleAddNewWearableDevice = () => {
    var wearableOptions = this.state.selectedOptionwearable;
    wearableOptions.push([{}, {}, {}]);
    this.setState({
      selectedOptionwearable: wearableOptions,
    });
  };

  handleRemoveWearableDevice = (e) => {
    if (this.state.selectedOptionwearable.length === 1) {
      this.setState({
        selectedOptionwearable: [],
      });
    } else {
      var wearableOptions = this.state.selectedOptionwearable;
      wearableOptions = [
        ...wearableOptions.slice(0, parseInt(e.target.dataset.index)),
        ...wearableOptions.slice(parseInt(e.target.dataset.index) + 1),
      ];
      this.setState({
        selectedOptionwearable: wearableOptions,
      });
    }
  };

  validateSelects = () => {
    for (var x = 0; x < this.state.selectedOptionwindows.length; x++) {
      if (this.state.selectedOptionwindows[x][1].label) {
        return false;
      }
    }
    for (var x = 0; x < this.state.selectedOptionmac.length; x++) {
      if (this.state.selectedOptionmac[x][1].label) {
        return false;
      }
    }
    for (var x = 0; x < this.state.selectedOptionios.length; x++) {
      if (
        this.state.selectedOptionios[x][0].label &&
        this.state.selectedOptionios[x][1].label
      ) {
        return false;
      }
    }
    for (var x = 0; x < this.state.selectedOptionandroid.length; x++) {
      if (
        this.state.selectedOptionandroid[x][0].label &&
        this.state.selectedOptionandroid[x][1].label
      ) {
        return false;
      }
    }
    for (var x = 0; x < this.state.selectedOptionchrome.length; x++) {
      if (this.state.selectedOptionchrome[x][1].label) {
        return false;
      }
    }
    for (var x = 0; x < this.state.selectedOptionwearable.length; x++) {
      if (
        this.state.selectedOptionwearable[x][0].label &&
        this.state.selectedOptionwearable[x][1].label
      ) {
        return false;
      }
    }
    return true;
  };

  onDragStartHandler = (e) => {
    var dragging = e.target.dataset.id.split("-");
    dragging[1] = parseInt(dragging[1]);
    var iconRect = e.target.getBoundingClientRect();
    var parentRect = e.target.parentElement.getBoundingClientRect();
    e.dataTransfer.setData("text", "anything");
    e.dataTransfer.setDragImage(
      e.target.parentElement,
      iconRect.left - parentRect.left + 12,
      15
    );
    this.setState({ dragging });
  };

  onDragEndHandler = (e) => {
    this.setState({ dragging: false });
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragEnter = (e) => {
    var index = parseInt(e.target.dataset.index);
    if (parseInt(index) > parseInt(this.state.dragging[1])) {
      e.target.parentElement.style.borderBottom = "solid 30px #e5e5e5";
      e.target.style.bottom = "-30px";
    } else if (parseInt(index) < parseInt(this.state.dragging[1])) {
      e.target.style.top = "-30px";
      e.target.parentElement.style.borderTop = "solid 30px #e5e5e5";
    }
  };

  onDragLeave = (e) => {
    var index = e.target.dataset.index;
    if (parseInt(index) > parseInt(this.state.dragging[1])) {
      e.target.style.bottom = "0px";
      e.target.parentElement.style.borderBottom = "solid 0px #e5e5e5";
    } else if (parseInt(index) < parseInt(this.state.dragging[1])) {
      e.target.style.top = "0px";
      e.target.parentElement.style.borderTop = "solid 0px #e5e5e5";
    }
  };

  onDragDrop = (e) => {
    var { platform, index } = e.target.dataset;
    index = parseInt(index);
    platform = "selectedOption" + platform;
    var devices = this.state[platform];
    var draggingDevice = devices.splice(this.state.dragging[1], 1)[0];
    devices.splice(index, 0, draggingDevice);
    this.setState({ [platform]: devices });
    if (parseInt(index) > parseInt(this.state.dragging[1])) {
      e.target.style.bottom = "0px";
      e.target.parentElement.style.borderBottom = "solid 0px #e5e5e5";
    } else if (parseInt(index) < parseInt(this.state.dragging[1])) {
      e.target.style.top = "0px";
      e.target.parentElement.style.borderTop = "solid 0px #e5e5e5";
    }
  };

  handleCreateAnother = () => {
    this.setState({ createAnother: !this.state.createAnother });
  };

  handleTemplateChange = (selectedTemplate) => {
    var selectedOption = this.state.templateOptions.find(
      (o) => o.value === selectedTemplate.value
    );
    this.setState({
      selectedTemplate: selectedOption,
      templateName: selectedOption.label,
      ...JSON.parse(selectedOption.devices),
    });
  };

  handleTemplateName = (e) => {
    this.setState({ templateName: e.target.value ? e.target.value : "" });
  };

  handleTemplateDelete = (id) => {
    Swal.fire({
      title: `Update Template`,
      text: "Are you sure you want to delete this Test Pass Template?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "Cancel",
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/test_pass_template/${id}.json`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            var deletedTemplate = {
              label: res.data.name,
              value: res.data.id,
              devices: res.data.devices,
            };
            var templateOptions = this.state.templateOptions.filter(
              (o) => o.value !== res.data.id
            );
            this.setState({
              selectedTemplate:
                this.state.selectedTemplate &&
                deletedTemplate.value === this.state.selectedTemplate.value
                  ? null
                  : this.state.selectedTemplate,
              templateOptions,
            });
            M.toast({
              html: `Test Pass Template deleted`,
              displayLength: 3000,
              classes: "green",
            });
          })
          .catch((err) => console.error(err));
      }
    });
  };

  render() {
    var {
      selectedOptionwindows: selectedWindows,
      selectedOptionmac: selectedMac,
      selectedOptionios: selectedIos,
      selectedOptionandroid: selectedAndroid,
      selectedOptionchrome: selectedChrome,
      selectedOptionwearable: selectedWearable,
    } = this.state;
    var deviceTotal =
      selectedChrome.length +
      selectedAndroid.length +
      selectedIos.length +
      selectedWindows.length +
      selectedMac.length +
      selectedWearable.length;
    return (
      <div>
        <button
          onClick={this.closeModal}
          className="icon-close-btn"
          style={{ zIndex: "10", position: "absolute", right: "19px" }}
        ></button>
        <div id="test-pass-create-form">
          <div
            style={{
              borderBottom: "solid 1px lightgray",
              paddingBottom: "16px",
              marginBottom: "20px",
              paddingLeft: "20px",
              left: "-20px",
              width: "calc(100% + 40px)",
              position: "relative",
            }}
          >
            <div>
              <span className="small-title">
                {this.props.data.newPass ? "Create New" : "Copy"} Test Pass{" "}
              </span>
              {this.props.data.testSuiteName && (
                <span style={{ fontSize: "12pt", color: "#519acc" }}>
                  - {this.props.data.testSuiteName}
                </span>
              )}
            </div>
            <span style={{ fontSize: "10pt" }}>
              All fields are required unless otherwise noted.
            </span>
          </div>
          {this.state.testSuiteId === null && (
            <div style={{ marginTop: "5px", marginBottom: "25px" }}>
              <BasicSelect
                name={`Suite`}
                options={this.props.data.suitesList}
                onChange={this.handleSuiteChange}
                selectedOption={this.state.selectedOptionSuite}
                placeholder="Select Test Directory"
                id={`directories`}
                maxHeight="200px"
                width="321px"
                display="inline-block"
                cascadeTransition={false}
                selectedTextStyle={{
                  color: "#242424",
                  fontWeight: "500",
                  display: "block",
                  width: "162px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                containerStyle={{
                  verticalAlign: "bottom",
                  marginRight: "10px",
                }}
                menuTransitionTime={0.2}
                scrollIntoView={"react-modal"}
                searchEnabled={true}
              />
            </div>
          )}
          <div style={{ display: "flex", width: "100%" }}>
            <div>
              <h4
                className="common-input-label"
                style={{
                  alignSelf: "flex-start",
                  fontSize: "11pt",
                  marginTop: "0px",
                }}
              >
                Test Pass Name
              </h4>
              <input
                id="test-pass-name-input"
                maxLength={50}
                style={{ width: "400px", marginTop: "0px", height: "37px" }}
                type="text"
                className="common-input browser-default"
                placeholder="eg. Website Round 1"
                autoFocus
                onChange={this.handleChange}
                value={this.state.name}
                required
              />
            </div>
            {this.props.data.newPass && (
              <div style={{ marginLeft: "30px" }}>
                <h4
                  className="common-input-label"
                  style={{
                    alignSelf: "flex-start",
                    fontSize: "11pt",
                    marginTop: "0px",
                  }}
                >
                  Test Pass Template (optional)
                </h4>
                <BasicSelect
                  name={`template`}
                  handleCreate={{
                    text: "Create Template",
                    handler: this.createTemplate,
                    name: this.state.templateName,
                    nameHandler: this.handleTemplateName,
                  }}
                  handleDelete={this.handleTemplateDelete}
                  handleUpdate={{
                    text: "Edit Template",
                    handler: this.updateTemplate,
                    name: this.state.templateName,
                    nameHandler: this.handleTemplateName,
                  }}
                  options={this.state.templateOptions}
                  onChange={this.handleTemplateChange}
                  selectedOption={this.state.selectedTemplate}
                  placeholder="Select Template"
                  id={`template`}
                  width="250px"
                  maxHeight="145px"
                  display="inline-block"
                  cascadeTransition={false}
                  selectedTextStyle={{
                    color: "#242424",
                    fontWeight: "500",
                    display: "block",
                    width: "162px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  containerStyle={{
                    verticalAlign: "bottom",
                    marginRight: "10px",
                  }}
                  menuTransitionTime={0.2}
                  // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                  scrollIntoView={"react-modal"}
                  searchEnabled={true}
                />
              </div>
            )}
          </div>

          <div className="platform" style={{ width: "100%" }}>
            <h4
              className="common-input-label"
              style={{
                alignSelf: "flex-start",
                fontSize: "11pt",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              Add Platform and Devices
            </h4>

            {/* //////////// */}
            {/* ///WINDOWS// */}
            {/* //////////// */}

            <span
              style={{
                fontSize: "11pt",
                display: "block",
                marginBottom: "15px",
              }}
            >
              Windows
            </span>
            {selectedWindows.map((device, index) => {
              var currentDrag =
                this.state.dragging &&
                this.state.dragging[0] === "windows" &&
                this.state.dragging[1] == index;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: "20px",
                    opacity: currentDrag ? "0.4" : "1",
                    transition: "border-width 0.3s",
                    borderTop: "solid 0px #e5e5e5",
                    borderBottom: "solid 0px #e5e5e5",
                  }}
                  key={`windows-device-${index}`}
                >
                  <div
                    //////DEVICE DRAG OVERLAY////////
                    onDragOver={this.onDragOver}
                    onDrop={this.onDragDrop}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    data-platform="windows"
                    data-index={index}
                    style={{
                      position: "absolute",
                      left: "0px",
                      right: "0px",
                      top: "0px",
                      bottom: "0px",
                      zIndex:
                        this.state.dragging &&
                        this.state.dragging[0] === "windows" &&
                        this.state.dragging[1] != index
                          ? "100"
                          : "-1",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      verticalAlign: "bottom",
                      borderRadius: "5px",
                      marginRight: "10px",
                      backgroundColor: "#519acc",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}
                  </div>
                  <BasicSelect
                    name={`windows-${index}-1`}
                    options={this.windowsOsOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionwindows[index][1].label
                        ? this.state.selectedOptionwindows[index][1]
                        : null
                    }
                    placeholder="Operating System"
                    id={`windows-${index}-1`}
                    width="292px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "10px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`windows-${index}-2`}
                    options={this.browserWindowsOptions.options}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionwindows[index][2].label
                        ? this.state.selectedOptionwindows[index][2]
                        : null
                    }
                    placeholder={
                      <span style={{ pointerEvents: "none" }}>
                        Browser{" "}
                        <span style={{ color: "#b7b7b7" }}>(Optional)</span>
                      </span>
                    }
                    id={`windows-${index}-2`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{ verticalAlign: "bottom" }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  {selectedWindows.length > 1 && (
                    <img
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move.svg"
                      // id={`windows-${index}`}
                      data-id={`windows-${index}`}
                      draggable={true}
                      alt="drag icon"
                      onDragStart={this.onDragStartHandler}
                      onDragEnd={this.onDragEndHandler}
                      style={{ verticalAlign: "-5px", marginRight: "10px" }}
                      className="material-icons test-case-drag-icon"
                    />
                  )}
                  <button
                    onClick={this.handleRemoveWindowsDevice}
                    data-index={index}
                    className="icon-close-btn"
                    style={{
                      display: "inline-block",
                      height: "20px",
                      width: "20px",
                      verticalAlign: "middle",
                    }}
                  ></button>
                </div>
              );
            })}
            <button
              className="span-to-button"
              onClick={this.handleAddNewWindowsDevice}
              style={{
                textAlign: "left",
                fontSize: "10pt",
                display: "block",
                width: "100%",
                fontWeight: "600",
                margin: "10px 0px 20px",
                paddingBottom: "15px",
                borderBottom: "solid 1px lightgray",
              }}
              id="new-test-cycle-button"
            >
              <i
                className="material-icons"
                style={{ verticalAlign: "-3px", fontSize: "14px" }}
              >
                add
              </i>
              Add Windows Device
            </button>

            {/* //////// */}
            {/* ///MAC// */}
            {/* //////// */}

            <span
              style={{
                fontSize: "11pt",
                display: "block",
                marginBottom: "15px",
              }}
            >
              Mac
            </span>
            {this.state.selectedOptionmac.map((device, index) => {
              var currentDrag =
                this.state.dragging &&
                this.state.dragging[0] === "mac" &&
                this.state.dragging[1] == index;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: "20px",
                    opacity: currentDrag ? "0.4" : "1",
                    transition: "border-width 0.3s",
                    borderTop: "solid 0px #e5e5e5",
                    borderBottom: "solid 0px #e5e5e5",
                  }}
                  key={`mac-device-${index}`}
                >
                  <div
                    //////DEVICE DRAG OVERLAY////////
                    onDragOver={this.onDragOver}
                    onDrop={this.onDragDrop}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    data-platform="mac"
                    data-index={index}
                    style={{
                      position: "absolute",
                      left: "0px",
                      right: "0px",
                      top: "0px",
                      bottom: "0px",
                      zIndex:
                        this.state.dragging &&
                        this.state.dragging[0] === "mac" &&
                        this.state.dragging[1] != index
                          ? "100"
                          : "-1",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      verticalAlign: "bottom",
                      borderRadius: "5px",
                      marginRight: "10px",
                      backgroundColor: "#519acc",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}
                  </div>
                  <BasicSelect
                    name={`mac-${index}-1`}
                    options={this.state.macOsOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionmac[index][1].label
                        ? this.state.selectedOptionmac[index][1]
                        : null
                    }
                    placeholder="Operating System"
                    id={`mac-${index}-1`}
                    width="292px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "10px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`mac-${index}-2`}
                    options={this.browserOptions.options}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionmac[index][2].label
                        ? this.state.selectedOptionmac[index][2]
                        : null
                    }
                    placeholder={
                      <span style={{ pointerEvents: "none" }}>
                        Browser{" "}
                        <span style={{ color: "#b7b7b7" }}>(Optional)</span>
                      </span>
                    }
                    id={`mac-${index}-2`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{ verticalAlign: "bottom" }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  {selectedMac.length > 1 && (
                    <img
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move.svg"
                      alt="drag icon"
                      data-id={`mac-${index}`}
                      draggable={true}
                      onDragStart={this.onDragStartHandler}
                      onDragEnd={this.onDragEndHandler}
                      style={{ verticalAlign: "-5px", marginRight: "10px" }}
                      className="material-icons test-case-drag-icon"
                    />
                  )}
                  <button
                    onClick={this.handleRemoveMacDevice}
                    data-index={index}
                    className="icon-close-btn"
                    style={{
                      display: "inline-block",
                      height: "20px",
                      width: "20px",
                      verticalAlign: "middle",
                    }}
                  ></button>
                </div>
              );
            })}
            <button
              className="span-to-button"
              onClick={this.handleAddNewMacDevice}
              style={{
                textAlign: "left",
                fontSize: "10pt",
                display: "block",
                width: "100%",
                fontWeight: "600",
                margin: "10px 0px 20px",
                paddingBottom: "15px",
                borderBottom: "solid 1px lightgray",
              }}
              id="new-test-cycle-button"
            >
              <i
                className="material-icons"
                style={{ verticalAlign: "-3px", fontSize: "14px" }}
              >
                add
              </i>
              Add macOS Device
            </button>

            {/* ///////// */}
            {/* ///IOS/// */}
            {/* ///////// */}

            <span
              style={{
                fontSize: "11pt",
                display: "block",
                marginBottom: "15px",
              }}
            >
              iOS
            </span>
            {selectedIos.map((device, index) => {
              var currentDrag =
                this.state.dragging &&
                this.state.dragging[0] === "ios" &&
                this.state.dragging[1] == index;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: "20px",
                    opacity: currentDrag ? "0.4" : "1",
                    transition: "border-width 0.3s",
                    borderTop: "solid 0px #e5e5e5",
                    borderBottom: "solid 0px #e5e5e5",
                  }}
                  key={`ios-device-${index}`}
                >
                  <div
                    //////DEVICE DRAG OVERLAY////////
                    onDragOver={this.onDragOver}
                    onDrop={this.onDragDrop}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    data-platform="ios"
                    data-index={index}
                    style={{
                      position: "absolute",
                      left: "0px",
                      right: "0px",
                      top: "0px",
                      bottom: "0px",
                      zIndex:
                        this.state.dragging &&
                        this.state.dragging[0] === "ios" &&
                        this.state.dragging[1] != index
                          ? "100"
                          : "-1",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      minWidth: "30px",
                      height: "30px",
                      verticalAlign: "bottom",
                      borderRadius: "5px",
                      marginRight: "10px",
                      backgroundColor: "#519acc",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}
                  </div>
                  <BasicSelect
                    name={`ios-${index}-0`}
                    options={this.state.iosDeviceOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionios[index][0].label
                        ? this.state.selectedOptionios[index][0]
                        : null
                    }
                    placeholder="Device"
                    id={`ios-${index}-0`}
                    width="292px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "10px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`ios-${index}-1`}
                    options={this.state.iosOsOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionios[index][1].label
                        ? this.state.selectedOptionios[index][1]
                        : null
                    }
                    placeholder="Operating System"
                    id={`ios-${index}-1`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "9px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`ios-${index}-2`}
                    options={this.browserOptions.options}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionios[index][2].label
                        ? this.state.selectedOptionios[index][2]
                        : null
                    }
                    placeholder={
                      <span style={{ pointerEvents: "none" }}>
                        Browser{" "}
                        <span style={{ color: "#b7b7b7" }}>(Optional)</span>
                      </span>
                    }
                    id={`ios-${index}-2`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{ verticalAlign: "bottom" }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  {selectedIos.length > 1 && (
                    <img
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move.svg"
                      alt="drag icon"
                      data-id={`ios-${index}`}
                      draggable={true}
                      onDragStart={this.onDragStartHandler}
                      onDragEnd={this.onDragEndHandler}
                      style={{ verticalAlign: "-5px", marginRight: "10px" }}
                      className="material-icons test-case-drag-icon"
                    />
                  )}
                  <button
                    onClick={this.handleRemoveIOSDevice}
                    data-index={index}
                    className="icon-close-btn"
                    style={{
                      display: "inline-block",
                      height: "20px",
                      width: "20px",
                      verticalAlign: "middle",
                    }}
                  ></button>
                </div>
              );
            })}
            <button
              className="span-to-button"
              onClick={this.handleAddNewIOSDevice}
              style={{
                textAlign: "left",
                fontSize: "10pt",
                display: "block",
                width: "100%",
                fontWeight: "600",
                margin: "10px 0px 20px",
                paddingBottom: "15px",
                borderBottom: "solid 1px lightgray",
              }}
              id="new-test-cycle-button"
            >
              <i
                className="material-icons"
                style={{ verticalAlign: "-3px", fontSize: "14px" }}
              >
                add
              </i>
              Add iOS Device
            </button>

            {/* //////////// */}
            {/* ///ANDROID// */}
            {/* //////////// */}

            <span
              style={{
                fontSize: "11pt",
                display: "block",
                marginBottom: "15px",
              }}
            >
              Android
            </span>
            {selectedAndroid.map((device, index) => {
              var currentDrag =
                this.state.dragging &&
                this.state.dragging[0] === "android" &&
                this.state.dragging[1] == index;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: "20px",
                    opacity: currentDrag ? "0.4" : "1",
                    transition: "border-width 0.3s",
                    borderTop: "solid 0px #e5e5e5",
                    borderBottom: "solid 0px #e5e5e5",
                  }}
                  key={`android-device-${index}`}
                >
                  <div
                    //////DEVICE DRAG OVERLAY////////
                    onDragOver={this.onDragOver}
                    onDrop={this.onDragDrop}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    data-platform="android"
                    data-index={index}
                    style={{
                      position: "absolute",
                      left: "0px",
                      right: "0px",
                      top: "0px",
                      bottom: "0px",
                      zIndex:
                        this.state.dragging &&
                        this.state.dragging[0] === "android" &&
                        this.state.dragging[1] != index
                          ? "100"
                          : "-1",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      minWidth: "30px",
                      height: "30px",
                      verticalAlign: "bottom",
                      borderRadius: "5px",
                      marginRight: "10px",
                      backgroundColor: "#519acc",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}
                  </div>
                  <BasicSelect
                    name={`android-${index}-0`}
                    options={this.state.androidDeviceOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionandroid[index][0].label
                        ? this.state.selectedOptionandroid[index][0]
                        : null
                    }
                    placeholder="Device"
                    id={`android-${index}-0`}
                    width="292px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "10px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`android-${index}-1`}
                    options={this.state.androidOsOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionandroid[index][1].label
                        ? this.state.selectedOptionandroid[index][1]
                        : null
                    }
                    placeholder="Operating System"
                    id={`android-${index}-1`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "9px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`android-${index}-2`}
                    options={this.androidBrowserOptions.options}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionandroid[index][2].label
                        ? this.state.selectedOptionandroid[index][2]
                        : null
                    }
                    placeholder={
                      <span style={{ pointerEvents: "none" }}>
                        Browser{" "}
                        <span style={{ color: "#b7b7b7" }}>(Optional)</span>
                      </span>
                    }
                    id={`android-${index}-2`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{ verticalAlign: "bottom" }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  {selectedAndroid.length > 1 && (
                    <img
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move.svg"
                      alt="drag icon"
                      data-id={`android-${index}`}
                      draggable={true}
                      onDragStart={this.onDragStartHandler}
                      onDragEnd={this.onDragEndHandler}
                      style={{ verticalAlign: "-5px", marginRight: "10px" }}
                      className="material-icons test-case-drag-icon"
                    />
                  )}
                  <button
                    onClick={this.handleRemoveAndroidDevice}
                    data-index={index}
                    className="icon-close-btn"
                    style={{
                      display: "inline-block",
                      height: "20px",
                      width: "20px",
                      verticalAlign: "middle",
                    }}
                  ></button>
                </div>
              );
            })}
            <button
              className="span-to-button"
              onClick={this.handleAddNewAndroidDevice}
              style={{
                textAlign: "left",
                fontSize: "10pt",
                display: "block",
                width: "100%",
                fontWeight: "600",
                margin: "10px 0px 20px",
                paddingBottom: "15px",
                borderBottom: "solid 1px lightgray",
              }}
              id="new-test-cycle-button"
            >
              <i
                className="material-icons"
                style={{ verticalAlign: "-3px", fontSize: "14px" }}
              >
                add
              </i>
              Add Android Device
            </button>
            {/* //////////// */}
            {/* ///CHROME/// */}
            {/* //////////// */}

            <span
              style={{
                fontSize: "11pt",
                display: "block",
                marginBottom: "15px",
              }}
            >
              ChromeOS
            </span>
            {selectedChrome.map((device, index) => {
              var currentDrag =
                this.state.dragging &&
                this.state.dragging[0] === "chrome" &&
                this.state.dragging[1] == index;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: "20px",
                    opacity: currentDrag ? "0.4" : "1",
                    transition: "border-width 0.3s",
                    borderTop: "solid 0px #e5e5e5",
                    borderBottom: "solid 0px #e5e5e5",
                  }}
                  key={`chrome-device-${index}`}
                >
                  <div
                    //////DEVICE DRAG OVERLAY////////
                    onDragOver={this.onDragOver}
                    onDrop={this.onDragDrop}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    data-platform="chrome"
                    data-index={index}
                    style={{
                      position: "absolute",
                      left: "0px",
                      right: "0px",
                      top: "0px",
                      bottom: "0px",
                      zIndex:
                        this.state.dragging &&
                        this.state.dragging[0] === "chrome" &&
                        this.state.dragging[1] != index
                          ? "100"
                          : "-1",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      verticalAlign: "bottom",
                      borderRadius: "5px",
                      marginRight: "10px",
                      backgroundColor: "#519acc",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}
                  </div>
                  <BasicSelect
                    name={`chrome-${index}-1`}
                    options={this.state.chromeOsOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionchrome[index][1].label
                        ? this.state.selectedOptionchrome[index][1]
                        : null
                    }
                    placeholder="Operating System"
                    id={`chrome-${index}-1`}
                    width="292px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "10px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`chrome-${index}-2`}
                    options={this.chromeOsBrowserOptions.options}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionchrome[index][2].label
                        ? this.state.selectedOptionchrome[index][2]
                        : null
                    }
                    placeholder={
                      <span style={{ pointerEvents: "none" }}>
                        Browser{" "}
                        <span style={{ color: "#b7b7b7" }}>(Optional)</span>
                      </span>
                    }
                    id={`chrome-${index}-2`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{ verticalAlign: "bottom" }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  {selectedChrome.length > 1 && (
                    <img
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move.svg"
                      alt="drag icon"
                      data-id={`chrome-${index}`}
                      draggable={true}
                      onDragStart={this.onDragStartHandler}
                      onDragEnd={this.onDragEndHandler}
                      style={{ verticalAlign: "-5px", marginRight: "10px" }}
                      className="material-icons test-case-drag-icon"
                    />
                  )}
                  <button
                    onClick={this.handleRemoveChromeDevice}
                    data-index={index}
                    className="icon-close-btn"
                    style={{
                      display: "inline-block",
                      height: "20px",
                      width: "20px",
                      verticalAlign: "middle",
                    }}
                  ></button>
                </div>
              );
            })}
            <button
              className="span-to-button"
              onClick={this.handleAddNewChromeDevice}
              style={{
                textAlign: "left",
                fontSize: "10pt",
                display: "block",
                width: "100%",
                fontWeight: "600",
                margin: "10px 0px 20px",
                paddingBottom: "15px",
              }}
              id="new-test-cycle-button"
            >
              <i
                className="material-icons"
                style={{ verticalAlign: "-3px", fontSize: "14px" }}
              >
                add
              </i>
              Add ChromeOS Device
            </button>

            {/* //////////// */}
            {/* ///WEARABLE// */}
            {/* //////////// */}

            <span
              style={{
                fontSize: "11pt",
                display: "block",
                marginBottom: "15px",
              }}
            >
              Wearable
            </span>
            {selectedWearable.map((device, index) => {
              var currentDrag =
                this.state.dragging &&
                this.state.dragging[0] === "wearable" &&
                this.state.dragging[1] == index;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: "20px",
                    opacity: currentDrag ? "0.4" : "1",
                    transition: "border-width 0.3s",
                    borderTop: "solid 0px #e5e5e5",
                    borderBottom: "solid 0px #e5e5e5",
                  }}
                  key={`wearable-device-${index}`}
                >
                  <div
                    //////DEVICE DRAG OVERLAY////////
                    onDragOver={this.onDragOver}
                    onDrop={this.onDragDrop}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    data-platform="wearable"
                    data-index={index}
                    style={{
                      position: "absolute",
                      left: "0px",
                      right: "0px",
                      top: "0px",
                      bottom: "0px",
                      zIndex:
                        this.state.dragging &&
                        this.state.dragging[0] === "wearable" &&
                        this.state.dragging[1] != index
                          ? "100"
                          : "-1",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      verticalAlign: "bottom",
                      borderRadius: "5px",
                      marginRight: "10px",
                      backgroundColor: "#519acc",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}
                  </div>
                  <BasicSelect
                    name={`wearable-${index}-0`}
                    options={this.state.wearableDeviceOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionwearable[index][0].label
                        ? this.state.selectedOptionwearable[index][0]
                        : null
                    }
                    placeholder="Device"
                    id={`wearable-${index}-0`}
                    width="292px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "10px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  <BasicSelect
                    name={`wearable-${index}-1`}
                    options={this.state.wearableOsOptions}
                    onChange={this.handleSelectChange}
                    selectedOption={
                      this.state.selectedOptionwearable[index][1].label
                        ? this.state.selectedOptionwearable[index][1]
                        : null
                    }
                    placeholder="Operating System"
                    id={`wearable-${index}-1`}
                    width="209px"
                    maxHeight="145px"
                    display="inline-block"
                    cascadeTransition={false}
                    selectedTextStyle={{
                      color: "#242424",
                      fontWeight: "500",
                      display: "block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    containerStyle={{
                      verticalAlign: "bottom",
                      marginRight: "9px",
                    }}
                    menuTransitionTime={0.2}
                    // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                    scrollIntoView={"react-modal"}
                    searchEnabled={true}
                  />
                  {selectedWearable.length > 1 && (
                    <img
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move.svg"
                      alt="drag icon"
                      data-id={`wearable-${index}`}
                      draggable={true}
                      onDragStart={this.onDragStartHandler}
                      onDragEnd={this.onDragEndHandler}
                      style={{ verticalAlign: "-5px", marginRight: "10px" }}
                      className="material-icons test-case-drag-icon"
                    />
                  )}
                  <button
                    onClick={this.handleRemoveWearableDevice}
                    data-index={index}
                    className="icon-close-btn"
                    style={{
                      display: "inline-block",
                      height: "20px",
                      width: "20px",
                      verticalAlign: "middle",
                    }}
                  ></button>
                </div>
              );
            })}
            <button
              className="span-to-button"
              onClick={this.handleAddNewWearableDevice}
              style={{
                textAlign: "left",
                fontSize: "10pt",
                display: "block",
                width: "100%",
                fontWeight: "600",
                margin: "10px 0px 20px",
                paddingBottom: "15px",
                borderBottom: "solid 1px lightgray",
              }}
              id="new-test-cycle-button"
            >
              <i
                className="material-icons"
                style={{ verticalAlign: "-3px", fontSize: "14px" }}
              >
                add
              </i>
              Add Wearable Device
            </button>
          </div>
          <div style={{ width: "100%", textAlign: "right" }}>
            {this.props.data.newPass && (
              <label
                id="create-another-check"
                style={{
                  paddingRight: "10px",
                  display: "inline-block",
                  marginRight: "20px",
                }}
              >
                <input
                  onChange={this.handleCreateAnother}
                  className="filled-in"
                  checked={this.state.createAnother}
                  type="checkbox"
                />
                <span
                  style={{
                    whiteSpace: "nowrap",
                    color: "black",
                    paddingLeft: "25px",
                    fontSize: "14px",
                  }}
                >
                  Create Another
                </span>
              </label>
            )}
            <button
              ref={this.createTestPassButton}
              disabled={
                this.state.name === "" ||
                (this.state.testSuiteId === null &&
                  !this.state.selectedOptionSuite) ||
                deviceTotal === 0 ||
                this.validateSelects()
              }
              style={{
                marginTop: 20 + "px",
                width: "145px",
                display: "inline-block",
              }}
              onClick={this.handleSubmitPass}
              className="btn btn-overflow"
            >
              {this.props.data.newPass ? "Create" : "Copy"} Test Pass
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default NewTestPass;
