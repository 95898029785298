import React from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";
import SingleSelectStyles from "../common/select_styles";
import { dropdownIndicator, customControl } from "../common/select_utils";
import MultiSelectWrapper from "../common/multi_select_wrapper";
import BugsIcon from "../icons/bugs_icon.jsx";
import TestPassIcon from "../icons/test_pass_icon.jsx";
import EODReportIcon from "../icons/eod_report_icon.jsx";
import LibraryFilledIcon from "../icons/library_filled_icon.jsx";
import ImageEmptyIcon from "../icons/image_empty_icon.jsx";
import CheckmarkIcon from "../icons/checkmark_icon.jsx";

const filterOption = (candidate, input) => {
  return (
    candidate.data.__isNew__ ||
    candidate.label.includes(input) ||
    (candidate.data.email && candidate.data.email.includes(input))
  );
};
class EditProjectModal extends React.Component {
  constructor(props) {
    super(props);
    const teamOptions = [];
    const selectedOptionTeam = [];
    this.props.teams.map((x) =>
      teamOptions.push({
        value: x.id,
        label: x.name,
        u_ids: x.user_ids,
        key: x.id,
        metaLabel: x.name,
      })
    );
    this.props.project.teams.map((x) =>
      selectedOptionTeam.push({
        value: x.id,
        label: x.name,
        u_ids: x.user_ids,
        key: x.id,
        metaLabel: x.name,
      })
    );
    this.state = {
      nameInput: this.props.project.name,
      a11yActive: this.props.project.a11y_active,
      automation: !!this.props.project.automation,
      media: this.props.project.media,
      hasBugs: this.props.project.has_bugs,
      hasTestCases: this.props.project.has_test_cases,
      identifierInput: this.props.project.identifier,
      projectId: this.props.project.id,
      owner: this.props.project.owner
        ? {
            value: this.props.project.owner.id,
            label: this.props.project.owner.name,
          }
        : {
            value: this.props.currentUser.id,
            label: this.props.currentUser.name,
          },
      users: this.props.users,
      selectedMembers: this.props.members,
      search: "",
      selectedOptionTeam,
      teamOptions,
      hookDeleted: false,
      step: this.props.action === "create" ? 1 : 2,
      success: false,
      subMenu: "general",
      projectImage:
        this.props.project.icon_url &&
        !this.props.project.icon_url.includes("Icons_User")
          ? this.props.project.icon_url
          : null,
      projectImageFile: null,
      settings: this.props.project.settings,
      jiraToTestPlatform:
        this.props.project.settings &&
        this.props.project.settings.jiraTestplatformConnection
          ? this.props.project.settings.jiraTestplatformConnection
          : false,
      testPlatformToJira:
        this.props.project.settings &&
        this.props.project.settings.testplatformJiraConnection
          ? this.props.project.settings.testplatformJiraConnection
          : false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitEditProject = this.submitEditProject.bind(this);
    this.closeEditProjectModal = this.closeEditProjectModal.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSelectChangeTeam = this.handleSelectChangeTeam.bind(this);
    this.deleteHook = this.deleteHook.bind(this);
    this.handleSelectChangeOwner = this.handleSelectChangeOwner.bind(this);
    this.slackUser = React.createRef();
    this.slackTicket = React.createRef();
    this.slackStatus = React.createRef();
    this.slackPriority = React.createRef();
    this.slackComment = React.createRef();
    this.modalRef = React.createRef();
    this.modalInnerRef = React.createRef();
    this.stepContainerRef = React.createRef();
    this.successRef = React.createRef();
    this.dotsContainerRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    M.Modal.init(this.modalRef.current, {
      dismissible: false,
      endingTop: "7%",
    });

    M.Modal.getInstance(this.modalRef.current).open();
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({ [name]: value });
  }

  deleteHook() {
    this.setState({ loading: true });
    const self = this;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
    };
    axios
      .delete(`/slack_delete/${self.props.project.id}.json`, {
        headers,
        data: {
          currentUser: self.props.currentUser.id,
        },
      })
      .then((res) => {
        self.setState({ hookDeleted: true, loading: false });
        M.toast({
          html: "Webhook Deleted",
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        Swal.fire("Webhook error", "There was an error deleting this webhook");
        console.error(err);
      });
  }

  closeEditProjectModal() {
    M.Modal.getInstance(this.modalRef.current).close();
    ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer"));
    $("body").css("overflow", "scroll");
  }

  handleChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  submitEditProject(formData) {
    this.props.loadingHandler();
    const component = this;
    const object = this.props;
    const { user } = object;
    const org = object.organization;
    const { project } = object;
    const xhr = new XMLHttpRequest();
    const url =
      this.props.action == "create"
        ? "/v1/projects.json"
        : `/v1/projects/${project.id}.json`;
    const action = this.props.action == "create" ? "POST" : "PATCH";
    xhr.open(action, url, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 200) {
        var resp = JSON.parse(xhr.response);
        component.props.handler(resp);
        Swal.fire({
          title: "Project Updated",
          text: "Successfully Updated",
          confirmButtonText: "OK",
        }).then(() => {
          component.props.loadingHandler();
          component.closeEditProjectModal();
        });
      } else if (xhr.status === 201) {
        var resp = JSON.parse(xhr.response);
        component.props.createHandler(resp);
        component.props.loadingHandler();
        component.successTransition(resp.id);
      } else {
        component.props.loadingHandler();
        Swal.fire({
          title: "Project Error",
          text: "There was an error updating this project, please make sure to not use the same Project Identifier as another project.",
          confirmButtonText: "OK",
        });
      }
    };
    xhr.send(formData);
  }

  handleCheckChange(e) {
    this.setState({ selectedMembers: e });
  }

  handleCheck(e) {
    const { name } = e.target;
    const { checked } = e.target;
    this.setState({ [name]: checked });
  }

  handleSubmit() {
    const formData = new FormData();
    const { selectedMembers } = this.state;
    if (this.state.a11yActive && this.state.automation) {
      return;
    }
    if (selectedMembers != undefined) {
      for (var i = 0; i < selectedMembers.length; i++) {
        const user = selectedMembers[i];
        if (user && user.id) {
          formData.append("project[user_ids][]", user.id);
        }
      }
    }
    if (this.state.projectImageFile) {
      formData.append("project[icon]", this.state.projectImageFile);
    }
    Object.entries(this.state.settings).forEach(([key, value]) => {
      formData.append(`project[settings][${key}]`, value);
    });
    formData.append("project[name]", this.state.nameInput);
    formData.append("project[owner_id]", this.state.owner.value);
    if (
      this.state.selectedOptionTeam != undefined &&
      this.state.selectedOptionTeam.length >= 1
    ) {
      // project.team_ids = this.state.selectedOptionTeam
      for (var i = 0; i < this.state.selectedOptionTeam.length; i++) {
        const team = this.state.selectedOptionTeam[i];
        formData.append("project[team_ids][]", team.value);
      }
    }
    if (this.props.project.jira_project_id) {
      formData.append(
        "project[settings][jiraTestplatformConnection]",
        this.state.jiraToTestPlatform
      );
      formData.append(
        "project[settings][testplatformJiraConnection]",
        this.state.testPlatformToJira
      );
    }
    formData.append("project[a11y_active]", this.state.a11yActive);
    formData.append("project[automation]", this.state.automation);
    formData.append("project[media]", this.state.media);
    formData.append("project[has_bugs]", this.state.hasBugs);
    formData.append("project[has_test_cases]", this.state.hasTestCases);
    formData.append("project[identifier]", this.state.identifierInput);
    this.submitEditProject(formData);
  }

  handleSelectChangeOwner(selectedOption) {
    this.setState({ owner: selectedOption });
  }

  handleSelectChangeTeam(selectedOption) {
    const users = [];
    const currentMembers = [];
    if (
      selectedOption != null &&
      this.state.selectedOptionTeam != null &&
      selectedOption.length > this.state.selectedOptionTeam.length
    ) {
      if (this.state.selectedMembers && this.state.selectedMembers.length > 0) {
        this.state.selectedMembers
          .filter((x) => x)
          .map((x) => currentMembers.push(x.id));
        users.push(currentMembers);
      }
      if (selectedOption && selectedOption.length > 0) {
        selectedOption.map((x) => users.push(x.u_ids));
        const flat_users = users.flat();
        const uniq_users = [...new Set(flat_users)];
        const members = [];
        for (var i = 0; i < uniq_users.length; i++) {
          const user = this.props.users.filter((x) => x.id == uniq_users[i])[0];
          members.push(user);
        }
        this.setState({
          selectedOptionTeam: selectedOption,
          selectedMembers: members,
        });
      } else {
        this.setState({ selectedOptionTeam: [] });
      }
    } else {
      this.setState({ selectedOptionTeam: selectedOption });
    }
  }

  renderSlackSettings() {
    return (
      <div className="col s4" style={{ padding: "0 25px" }}>
        <p style={{ textAlign: "center", fontWeight: 500 }}>
          Slack Notification Settings
        </p>
        <div>
          <div style={{ display: "inline-block", width: "100%" }}>
            <span>User Added</span>
            <div
              style={{ display: "inline-block", float: "right" }}
              className="switch"
            >
              <label>
                Off
                <input type="checkbox" key="last" ref={this.slackUser} />
                <span className="lever" />
                On
              </label>
            </div>
          </div>
          <div style={{ display: "inline-block", width: "100%" }}>
            <span>Ticket Creation</span>
            <div
              style={{ display: "inline-block", float: "right" }}
              className="switch"
            >
              <label>
                Off
                <input type="checkbox" key="last" ref={this.slackTicket} />
                <span className="lever" />
                On
              </label>
            </div>
          </div>
          <div style={{ display: "inline-block", width: "100%" }}>
            <span>Status Updates</span>
            <div
              style={{ display: "inline-block", float: "right" }}
              className="switch"
            >
              <label>
                Off
                <input type="checkbox" key="last" ref={this.slackStatus} />
                <span className="lever" />
                On
              </label>
            </div>
          </div>
          <div style={{ display: "inline-block", width: "100%" }}>
            <span>Priority Updates</span>
            <div
              style={{ display: "inline-block", float: "right" }}
              className="switch"
            >
              <label>
                Off
                <input type="checkbox" key="last" ref={this.slackPriority} />
                <span className="lever" />
                On
              </label>
            </div>
          </div>
          <div style={{ display: "inline-block", width: "100%" }}>
            <span>Ticket Comments</span>
            <div
              style={{ display: "inline-block", float: "right" }}
              className="switch"
            >
              <label>
                Off
                <input type="checkbox" key="last" ref={this.slackComment} />
                <span className="lever" />
                On
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSuccessScreen = () => {
    return (
      <div
        ref={this.successRef}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "center",
          padding: "35px 40px 30px 40px",
          opacity: "0",
          transition: "opacity 0.3s",
        }}
      >
        <CheckmarkIcon width={64} height={64} />
        <h2
          style={{
            fontFamily: "Manrope",
            fontSize: "16px",
            margin: "30px 0 20px 0",
          }}
        >
          Project Created!
        </h2>
        <p
          style={{
            textAlign: "center",
            lineHeight: "24px",
            marginBottom: "20px",
          }}
        >
          Your new project was created successfully and is now available for
          testing.
        </p>
        <a
          style={{
            textAlign: "center",
            padding: "0",
            display: "block",
            height: "45px",
            lineHeight: "45px",
            width: "130px",
          }}
          href={`/projects/${this.state.projectId}/dashboard`}
          className="common-button-submit"
        >
          Go to Project
        </a>
      </div>
    );
  };

  stepTransition = (step) => {
    if (step === 2) {
      this.stepContainerRef.current.style.opacity = "0";
      setTimeout(() => {
        this.modalRef.current.style.width = "730px";
        this.modalRef.current.style.height = "550px";
        setTimeout(() => {
          this.setState({ step: 2 }, () => {
            this.stepContainerRef.current.style.opacity = "1";
          });
        }, 300);
      }, 300);
    } else {
      this.stepContainerRef.current.style.opacity = "0";
      setTimeout(() => {
        this.modalRef.current.style.width = "635px";
        this.modalRef.current.style.height = "605px";
        setTimeout(() => {
          this.setState({ step: 1, subMenu: "general" }, () => {
            this.stepContainerRef.current.style.opacity = "1";
          });
        }, 300);
      }, 300);
    }
  };

  successTransition = (id) => {
    this.modalInnerRef.current.style.opacity = "0";
    this.dotsContainerRef.current.style.opacity = "0";
    setTimeout(() => {
      this.dotsContainerRef.current.style.display = "none";
      this.modalRef.current.style.width = "460px";
      this.modalRef.current.style.height = "330px";
      setTimeout(() => {
        this.setState({ success: true, projectId: id }, () => {
          this.successRef.current.style.opacity = "1";
        });
      }, 300);
    }, 300);
  };

  handleFeatureSelect = (e) => {
    const { value } = e.target.dataset;
    const { settings } = this.state;
    switch (value) {
      case "bugs":
        this.setState({ hasBugs: !this.state.hasBugs });
        break;
      case "test_pass":
        this.setState({ hasTestCases: !this.state.hasTestCases });
        break;
      case "media":
        this.setState({ media: !this.state.media });
        break;
    }
  };

  handleTypeSelect = (e) => {
    const type = e.target.dataset.projectType;
    const settings = {
      hasBugs: false,
      hasTestCases: false,
      media: false,
      settings: {
        ticket_add: "true",
        status: "true",
        priority: "true",
        ticket_comment: "true",
        user_add: "true",
      },
    };
    switch (type) {
      case "bugs_test":
        settings.hasBugs = true;
        settings.hasTestCases = true;
        break;
      case "bugs":
        settings.hasBugs = true;
        break;
      case "test":
        settings.hasTestCases = true;
        break;
      case "media":
        settings.media = true;
        break;
    }
    this.setState(settings, () => {
      this.stepTransition(2);
    });
  };

  handleSubMenu = (e) => {
    // var subMenu = e.target.dataset.subMenu;
    const { subMenu } = e.target.dataset;
    this.setState({ subMenu }, () => {
      if (this.state.subMenu === "features") {
        this.modalRef.current.style.width = "630px";
        this.modalRef.current.style.height = "505px";
      } else if (
        this.props.action === "create" &&
        this.state.subMenu === "general"
      ) {
        this.modalRef.current.style.width = "730px";
        this.modalRef.current.style.height = "550px";
      } else {
        this.modalRef.current.style.width = "730px";
        this.modalRef.current.style.height = "605px";
      }
    });
  };

  handleProjectImageChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 4000000) {
      M.toast({
        html: "File exceeds 4MB size limit",
        displayLength: 3000,
        classes: "red",
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        projectImage: e.target.result,
        projectImageFile: file,
      });
    };
    reader.readAsDataURL(file);
  };

  handleJToTChange = () => {
    this.setState({ jiraToTestPlatform: !this.state.jiraToTestPlatform });
  };

  handleTToJChange = () => {
    this.setState({ testPlatformToJira: !this.state.testPlatformToJira });
  };

  renderSubMenu = () => {
    switch (this.state.subMenu) {
      case "general":
        return (
          <div style={{ display: "flex", flex: "1" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "250px",
                padding: "20px 40px 40px",
              }}
            >
              <div style={{ width: "160px", height: "160px" }}>
                {this.state.projectImage ? (
                  <img
                    src={this.state.projectImage}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <ImageEmptyIcon width={160} height={160} />
                )}
              </div>

              <button
                style={{
                  width: "170px",
                  height: "45px",
                  marginTop: "20px",
                  position: "relative",
                }}
                className="common-button-cancel"
              >
                Upload a project photo
                <input
                  onChange={this.handleProjectImageChange}
                  accept=".jpg,.png"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    opacity: "0",
                  }}
                  type="file"
                />
              </button>
            </div>
            <div style={{ flex: "1", paddingLeft: "30px" }}>
              <p style={{ fontWeight: "600" }}>Project Name*</p>
              <input
                value={this.state.nameInput}
                onChange={this.handleChange}
                name="nameInput"
                maxLength={50}
                type="text"
                className="common-input browser-default"
                placeholder="Enter project name"
              />
              <p
                style={{
                  fontWeight: "600",
                  marginTop: "10px",
                  position: "relative",
                }}
              >
                Project Identifier*{" "}
                <i className="project-type-info identifier-info material-icons">
                  help
                </i>
              </p>
              <input
                onChange={this.handleChange}
                value={this.state.identifierInput}
                name="identifierInput"
                maxLength="4"
                type="text"
                className="common-input browser-default"
                style={{ width: "70px" }}
              />
              <div style={{ display: "flex" }}>
                <label
                  style={{
                    position: "relative",
                    marginRight: "20px",
                    display: "block",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    value={this.state.a11yActive}
                    checked={this.state.a11yActive}
                    name="a11yActive"
                    className="filled-in"
                    disabled={this.state.automation}
                    onChange={this.handleCheck}
                  />
                  <span
                    style={{
                      lineHeight: "20px",
                      color: "#4d4d4d",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Accessiblity
                  </span>
                </label>
                <label
                  style={{
                    position: "relative",
                    marginRight: "20px",
                    display: "block",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    value={this.state.automation}
                    checked={this.state.automation}
                    name="automation"
                    className="filled-in"
                    onChange={this.handleCheck}
                    disabled={this.state.a11yActive}
                  />
                  <span
                    style={{
                      lineHeight: "20px",
                      color: "#4d4d4d",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Automation
                  </span>
                </label>
              </div>
              {this.props.action === "edit" &&
                !this.props.project.slack_webhook && (
                  <div style={{ marginTop: "15px" }}>
                    <p
                      style={{
                        color: "#4d4d4d",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                    >
                      Enable Slack Notifications
                    </p>
                    <a
                      href={`https://slack.com/oauth/authorize?scope=incoming-webhook&client_id=3005130989.729960448755&state=${this.props.project.id}`}
                    >
                      <img
                        alt="Add to Slack"
                        height="40"
                        width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                      />
                    </a>
                  </div>
                )}
            </div>
          </div>
        );
      case "users":
        var { users } = this.state;
        var members =
          this.state.selectedMembers != null ? this.state.selectedMembers : [];
        var userList = [];

        var filteredUsers = users.filter((user) => {
          const string =
            user.name != null
              ? user.name
              : user.email != null
              ? user.email
              : "";
          return (
            string.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
          );
        });

        if (this.state.search != "") {
          users = filteredUsers;
        }
        var clean_team_members = members.filter(
          (x) => x != null && x.id != null
        );
        var team_member_ids = clean_team_members.map((x) =>
          x.member != null ? (x.member.id != null ? x.member.id : -1) : x.id
        );
        for (let i = 0; i < filteredUsers.length; i++) {
          const user = filteredUsers[i];
          const string = user.name != null ? user.name : user.email;
          const checked = team_member_ids.includes(user.id);
          userList.push(
            <li key={user.id + user.email}>
              <label>
                <input
                  type="checkbox"
                  checked={checked}
                  id={user.id}
                  name="teamMemberCheck"
                  onChange={this.handleCheckChange}
                />
                <span htmlFor={user.id}>{string}</span>
              </label>
            </li>
          );
        }
        if (this.props.action == "create" && this.props.currentUser) {
          users = users.filter((user) => user.id !== this.props.currentUser.id);
        }
        clean_team_members.map((member) => {
          member.value = member.id;
          member.label = member.name != "" ? member.name : member.email;
          member.metaLabel = member.label;
          return member;
        });
        users.map((user) => {
          user.value = user.id;
          user.label = user.name != "" ? user.name : user.email;
          user.key = `${user.id}-${user.email}`;
          user.metaLabel = user.label;
          return user;
        });
        return (
          <div
            className="project-users-menu"
            style={{ display: "flex", flex: "1", flexDirection: "column" }}
          >
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <div>
                <p style={{ fontWeight: "600" }}>Project Owner</p>
                <Select
                  aria-label="Owner dropdown for edit project modal"
                  key={this.props.project.id}
                  blurInputOnSelect
                  value={this.state.owner}
                  placeholder="Select a user"
                  styles={SingleSelectStyles}
                  onChange={this.handleSelectChangeOwner}
                  name="owner"
                  closeMenuOnSelect
                  options={users}
                  components={{
                    DropdownIndicator: dropdownIndicator,
                    Control: customControl,
                  }}
                  classNamePrefix="selectbox-menu"
                />
              </div>
            </div>
            <div style={{ flex: "1", display: "flex" }}>
              <div
                style={{ borderRight: "solid 1px lightgray", width: "250px" }}
              >
                <p style={{ fontWeight: "600" }}>Select Team(s) for Project</p>
                <MultiSelectWrapper
                  divider
                  aria-label="Users dropdown for edit team modal"
                  value={this.state.selectedOptionTeam}
                  // label={ <label className="select-labels" htmlFor="filter_filters">Select Teams for Project:</label>}
                  onChange={this.handleSelectChangeTeam}
                  name="colors"
                  isSearchable
                  tagStyle
                  isClearable={false}
                  placeholder="Search for Teams"
                  closeMenuOnSelect={false}
                  options={this.state.teamOptions}
                  width="500px"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              <div style={{ flex: "1", paddingLeft: "30px" }}>
                <p style={{ fontWeight: "600" }}>Select User(s) for Project</p>
                <MultiSelectWrapper
                  aria-label="Users dropdown for edit team modal"
                  value={clean_team_members}
                  defaultValue={clean_team_members}
                  onChange={this.handleCheckChange}
                  name="colors"
                  heightMath="200px"
                  isClearable={false}
                  closeMenuOnSelect={false}
                  filterOption={filterOption}
                  placeholder="Search for Users"
                  isSearchable
                  options={users}
                  tagStyle
                  width="500px"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>
        );
      case "features":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              paddingTop: "10px",
              flex: "1",
            }}
          >
            <button
              className="project-type-button"
              style={{
                margin: "0 4px 0 0",
                position: "relative",
                borderColor:
                  this.state.settings.bugs !== "false" ? "#519acc" : "#c7c7c7",
              }}
              onClick={this.handleFeatureSelect}
              data-value="bugs"
            >
              <label
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "-4px",
                  display: "block",
                  pointerEvents: "none",
                }}
              >
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={this.state.hasBugs}
                  name="a11yActive"
                  className="filled-in"
                />
                <span />
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    backgroundColor: "#519acc",
                    overflow: "hidden",
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BugsIcon width={36} height={36} />
                </div>
                <p
                  style={{
                    margin: "20px 0 0 0",
                    fontFamily: "Manrope",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  Bugs
                </p>
              </div>
            </button>
            <button
              className="project-type-button"
              style={{
                margin: "0 4px 0 0",
                position: "relative",
                borderColor:
                  this.state.settings.test_pass !== "false"
                    ? "#519acc"
                    : "#c7c7c7",
              }}
              onClick={this.handleFeatureSelect}
              data-value="test_pass"
            >
              <label
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "-4px",
                  display: "block",
                  pointerEvents: "none",
                }}
              >
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={this.state.hasTestCases}
                  name="a11yActive"
                  className="filled-in"
                />
                <span />
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    backgroundColor: "#519acc",
                    overflow: "hidden",
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TestPassIcon
                    width={42}
                    height={42}
                    stroke="#519acc"
                    fill="transparent"
                  />
                </div>
                <p
                  style={{
                    margin: "20px 0 0 0",
                    fontFamily: "Manrope",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  Test Cases
                </p>
              </div>
            </button>
            <button
              className="project-type-button"
              style={{
                margin: "0 0 0 0",
                position: "relative",
                borderColor:
                  this.state.settings.media !== "false" ? "#519acc" : "#c7c7c7",
              }}
              onClick={this.handleFeatureSelect}
              data-value="media"
            >
              <label
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "-4px",
                  display: "block",
                  pointerEvents: "none",
                }}
              >
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={this.state.media}
                  name="a11yActive"
                  className="filled-in"
                />
                <span />
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pointerEvents: "none",
                }}
              >
                <LibraryFilledIcon width={60} height={60} />
                <p
                  style={{
                    margin: "20px 0 0 0",
                    fontFamily: "Manrope",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  Media Library
                </p>
              </div>
            </button>
          </div>
        );
      case "integrations":
        return (
          <div>
            {this.props.project.slack_webhook && this.renderSlackSettings()}
            {this.props.project.slack_webhook && !this.state.hookDeleted && (
              <div style={{ backgroundColor: "#fafafa" }} className="col s6">
                <ul>
                  <li>
                    {" "}
                    Slack Team Name:{" "}
                    {this.props.project.slack_webhook.slack_team_name}{" "}
                  </li>
                  <li>
                    {" "}
                    Notifying Channel:{" "}
                    {this.props.project.slack_webhook.channel}{" "}
                  </li>
                  <li>
                    {" "}
                    Slack Configuration Url:{" "}
                    <a
                      className="pointer"
                      href={this.props.project.slack_webhook.config_url}
                    >
                      {" "}
                      here{" "}
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    Delete Webhook:{" "}
                    <a className="pointer" onClick={this.deleteHook}>
                      {" "}
                      Delete{" "}
                    </a>{" "}
                  </li>
                </ul>
              </div>
            )}
            {this.props.project.jira_project_id && (
              <div className="col s6 center">
                <p style={{ padding: "20px 10px" }}>Jira App Connection</p>
                <form>
                  <p style={{ minWidth: "120px" }}>
                    <label
                      style={{
                        position: "relative",
                        bottom: "8px",
                        flex: ".75",
                      }}
                    >
                      <input
                        name="visible"
                        checked={this.state.testPlatformToJira}
                        onChange={this.handleTToJChange}
                        className="filled-in"
                        type="checkbox"
                      />
                      <span>TestPlatform to Jira</span>
                    </label>
                  </p>
                  <p style={{ minWidth: "120px" }}>
                    <label
                      style={{
                        position: "relative",
                        bottom: "8px",
                        flex: ".75",
                      }}
                    >
                      <input
                        name="visible"
                        checked={this.state.jiraToTestPlatform}
                        onChange={this.handleJToTChange}
                        className="filled-in"
                        type="checkbox"
                      />
                      <span>Jira to TestPlatform</span>
                    </label>
                  </p>
                </form>
              </div>
            )}
          </div>
        );
    }
  };

  renderStepOne = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          paddingTop: "10px",
        }}
      >
        <button
          className="project-type-button"
          style={{ margin: "0 4px 0 0" }}
          onClick={this.handleTypeSelect}
          data-project-type="bugs_test"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                position: "relative",
                borderRadius: "50%",
                backgroundColor: "#519acc",
                overflow: "hidden",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BugsIcon
                svgStyles={{
                  position: "absolute",
                  left: "0",
                  height: "27px",
                  width: "27px",
                }}
              />
              <TestPassIcon
                svgStyles={{
                  position: "absolute",
                  right: "1px",
                  height: "34px",
                  width: "34px",
                }}
                stroke="#519acc"
                fill="transparent"
              />
            </div>
            <p
              style={{
                margin: "20px 0 0 0",
                fontFamily: "Manrope",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Bugs + Test Cases
            </p>
          </div>
          <i
            data-project-type="bugs_test"
            className="project-type-info bugs-test-info material-icons"
          >
            help
          </i>
        </button>
        <button
          className="project-type-button"
          style={{ margin: "0 4px" }}
          onClick={this.handleTypeSelect}
          data-project-type="bugs"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                backgroundColor: "#519acc",
                overflow: "hidden",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BugsIcon width={36} height={36} />
            </div>
            <p
              style={{
                margin: "20px 0 0 0",
                fontFamily: "Manrope",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Bugs Only
            </p>
          </div>
          <i
            data-project-type="bugs"
            className="project-type-info bugs-info material-icons"
          >
            help
          </i>
        </button>
        <button
          style={{ margin: "0 0 0 4px" }}
          className="project-type-button"
          onClick={this.handleTypeSelect}
          data-project-type="test"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                backgroundColor: "#519acc",
                overflow: "hidden",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TestPassIcon
                width={42}
                height={42}
                stroke="#519acc"
                fill="transparent"
              />
            </div>
            <p
              style={{
                margin: "20px 0 0 0",
                fontFamily: "Manrope",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Test Cases Only
            </p>
          </div>
          <i
            data-project-type="test"
            className="project-type-info  test-info material-icons"
          >
            help
          </i>
        </button>
        <button
          className="project-type-button"
          style={{ margin: "8px 4px 0 0" }}
          onClick={this.handleTypeSelect}
          data-project-type="media"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <LibraryFilledIcon width={60} height={60} />
            <p
              style={{
                margin: "20px 0 0 0",
                fontFamily: "Manrope",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Media Library
            </p>
          </div>
          <i
            data-project-type="media"
            className="project-type-info media-info material-icons"
          >
            help
          </i>
        </button>
        <button
          className="project-type-button"
          style={{ margin: "8px 0 0 4px" }}
          onClick={this.handleTypeSelect}
          data-project-type="eod_report"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                position: "relative",
                borderRadius: "50%",
                backgroundColor: "#519acc",
                overflow: "hidden",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EODReportIcon
                svgStyles={{ position: "absolute" }}
                width={60}
                height={60}
                stroke="#519acc"
                fill="transparent"
              />
            </div>
            <p
              style={{
                margin: "20px 0 0 0",
                fontFamily: "Manrope",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              EOD Report Only
            </p>
          </div>
          <i
            data-project-type="eod_report"
            className="project-type-info eod-info material-icons"
          >
            help
          </i>
        </button>
      </div>
    );
  };

  render() {
    const create = this.props.action === "create";
    return (
      <div
        style={{
          width: this.state.step === 1 ? "635px" : "730px",
          height: "605px",
          overflow: "visible",
          transition: "width 0.3s, height 0.3s",
        }}
        ref={(node) => {
          this.modalRef.current = node;
        }}
        className="modal gray lighten-3 row adminModal"
        id="projectsEditModal"
      >
        {this.state.success ? (
          this.renderSuccessScreen()
        ) : (
          <div
            ref={this.modalInnerRef}
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              transition: "opacity 0.3s",
            }}
          >
            <div
              style={{
                padding: "20px 30px",
                borderBottom: "solid 1px lightgray",
              }}
            >
              <h2 className="small-title" style={{ margin: "0 0 5px 0" }}>
                {create ? "Add a new project" : "Edit a project"}
              </h2>
              <p className="subtitle" style={{ margin: "0" }}>
                {this.state.step === 1
                  ? "Choose one of the options listed below."
                  : "Modify your project details below."}
              </p>
            </div>
            <div
              ref={this.stepContainerRef}
              style={{
                position: "relative",
                flex: "1",
                padding: `30px 30px ${create ? "50px" : "30px"}`,
                transition: "opacity 0.3s",
                opacity: "1",
              }}
            >
              {this.state.step === 1 ? (
                this.renderStepOne()
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        fontFamily: "Arial",
                        cursor: "pointer",
                        fontWeight: "600",
                        padding: "0 5px 10px 5px",
                        marginRight: "10px",
                        background: "none",
                        border: "none",
                        fontSize: "16px",
                        color:
                          this.state.subMenu === "general"
                            ? "#519acc"
                            : "#000000",
                        borderBottom:
                          this.state.subMenu === "general"
                            ? "solid 2px #519acc"
                            : "solid 2px transparent",
                      }}
                      data-sub-menu="general"
                      onClick={this.handleSubMenu}
                    >
                      General
                    </button>
                    <button
                      style={{
                        fontFamily: "Arial",
                        cursor: "pointer",
                        fontWeight: "600",
                        padding: "0 5px 10px 5px",
                        marginRight: !create ? "10px" : "0",
                        background: "none",
                        border: "none",
                        fontSize: "16px",
                        color:
                          this.state.subMenu === "users"
                            ? "#519acc"
                            : "#000000",
                        borderBottom:
                          this.state.subMenu === "users"
                            ? "solid 2px #519acc"
                            : "solid 2px transparent",
                      }}
                      data-sub-menu="users"
                      onClick={this.handleSubMenu}
                    >
                      Users
                    </button>
                    {!create && (
                      <button
                        style={{
                          fontFamily: "Arial",
                          cursor: "pointer",
                          fontWeight: "600",
                          padding: "0 5px 10px 5px",
                          marginRight: "10px",
                          background: "none",
                          border: "none",
                          fontSize: "16px",
                          color:
                            this.state.subMenu === "features"
                              ? "#519acc"
                              : "#000000",
                          borderBottom:
                            this.state.subMenu === "features"
                              ? "solid 2px #519acc"
                              : "solid 2px transparent",
                        }}
                        data-sub-menu="features"
                        onClick={this.handleSubMenu}
                      >
                        Features
                      </button>
                    )}
                    {!create &&
                      (this.props.project.slack_webhook ||
                        this.props.project.jira_project_id) && (
                        <button
                          style={{
                            fontFamily: "Arial",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "0 5px 10px 5px",
                            marginRight: "10px",
                            background: "none",
                            border: "none",
                            fontSize: "16px",
                            color:
                              this.state.subMenu === "integrations"
                                ? "#519acc"
                                : "#000000",
                            borderBottom:
                              this.state.subMenu === "integrations"
                                ? "solid 2px #519acc"
                                : "solid 2px transparent",
                          }}
                          data-sub-menu="integrations"
                          onClick={this.handleSubMenu}
                        >
                          Integrations
                        </button>
                      )}
                  </div>
                  {this.renderSubMenu()}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: create ? "space-between" : "flex-end",
                    }}
                  >
                    {create && (
                      <button
                        onClick={() => {
                          this.stepTransition(1);
                        }}
                        style={{ width: "130px", height: "45px" }}
                        className="common-button-cancel"
                      >
                        Previous Step
                      </button>
                    )}
                    <button
                      disabled={
                        !this.state.nameInput || !this.state.identifierInput
                      }
                      onClick={this.handleSubmit}
                      style={{ width: "130px", height: "45px" }}
                      className="common-button-submit"
                    >
                      {create ? "Create Project" : "Save & Exit"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <button
          type="button"
          style={{ position: "absolute", right: "20px", top: "20px" }}
          className="icon-close-btn"
          onClick={this.closeEditProjectModal}
        />
        {create && (
          <div
            ref={this.dotsContainerRef}
            style={{
              display: "inline-flex",
              opacity: "1",
              transition: "opacity 0.3s",
              height: "40px",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: "10px",
              right: "0",
              left: "0",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#519acc",
                borderRadius: "50%",
                transition: "background-color 0.4s",
                margin: "5px",
              }}
            />
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: this.state.step > 1 ? "#519acc" : "#dddddd",
                borderRadius: "50%",
                transition: "background-color 0.4s",
                margin: "5px",
              }}
            />
            <div
              id="build-flow-check"
              className={this.state.success ? "build-flow-check-active" : ""}
            />
          </div>
        )}
      </div>
    );
  }
}

export default EditProjectModal;
