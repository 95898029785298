import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import BasicSelect from "../common/basic_select.jsx";

class EditTestDevice extends React.Component {
  constructor(props) {
    super(props);
    var cp = this.props.data.currentPlatform;
    this.state = {
      deviceOptions: [[], [], [], [], [], []],
      loading: false,
      currentPlatform: {
        label:
          cp === 1
            ? "Windows"
            : cp === 2
            ? "Mac"
            : cp === 3
            ? "iOS"
            : cp === 4
            ? "Android"
            : cp === 5
            ? "ChromeOS"
            : "Wearable",
        value: cp,
      },
      selectedOS: [],
      selectedBrowser: [],
      selectedDevice: [],
      macOsOptions: [],
      androidOsOptions: [],
      iosOsOptions: [],
      androidDeviceOptions: [],
      iosDeviceOptions: [],
      chromeOsOptions: [],
      wearableDeviceOptions: [],
      wearableOsOptions: [],
    };
    this.chromeOsBrowserOptions = [
      { label: "Chrome", value: "Chrome" },
      { label: "Firefox", value: "Firefox" },
      { label: "Microsoft Edge", value: "Microsoft Edge" },
      { label: "Facebook", value: "Facebook" },
      { label: "Instagram", value: "Instagram" },
      { label: "Twitter", value: "Twitter" },
    ];
    this.browserOptions = [
      { label: "Chrome", value: "Chrome" },
      { label: "Firefox", value: "Firefox" },
      { label: "Safari 14", value: "Safari14" },
      { label: "Safari 15", value: "Safari15" },
      { label: "Safari 16", value: "Safari16" },
      { label: "Safari 17", value: "Safari17" },
      { label: "Safari", value: "Safari" },
      { label: "Opera", value: "Opera" },
      { label: "Internet Explorer 11", value: "Internet Explorer 11" },
      { label: "Microsoft Edge", value: "Microsoft Edge" },
      { label: "Facebook", value: "Facebook" },
      { label: "Instagram", value: "Instagram" },
      { label: "Twitter", value: "Twitter" },
      { label: "Other", value: "Other" },
    ];
    this.androidBrowserOptions = [
      { label: "Chrome", value: "Chrome" },
      { label: "Firefox", value: "Firefox" },
      { label: "Safari", value: "Safari" },
      { label: "Opera", value: "Opera" },
      { label: "Native", value: "Native" },
      { label: "Facebook", value: "Facebook" },
      { label: "Instagram", value: "Instagram" },
      { label: "Twitter", value: "Twitter" },
      { label: "Other", value: "Other" },
    ];
    this.windowsOsOptions = [
      { label: "Windows 11", value: "Windows 11" },
      { label: "Windows 10", value: "Windows 10" },
      { label: "Windows 8", value: "Windows 8" },
      { label: "Windows 7", value: "Windows 7" },
      { label: "Windows Vista", value: "Windows Vista" },
      { label: "Windows XP", value: "Windows XP" },
    ];
    this.platformOptions = {
      label: "Platform",
      options: [
        { label: "Windows", value: 1 },
        { label: "Mac", value: 2 },
        { label: "iOS", value: 3 },
        { label: "Android", value: 4 },
        { label: "ChromeOS", value: 5 },
        { label: "Wearable", value: 6 },
      ],
    };
  }

  componentDidMount() {
    this.props.setBackdropLoader(true);
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    axios
      .get("/tc_device_options", { headers: { Accept: "application/json" } })
      .then((res) => {
        var macOsOptions = res.data.macOsOptions.map((os) =>
          os.split(".").length > 2
            ? os.slice(0, os.lastIndexOf("."))
            : os.slice(0, os.indexOf(".") + 3)
        );
        macOsOptions = [...new Set(macOsOptions)].map((os) => {
          return { label: os, value: os };
        });
        var chromeOsOptions = [...new Set(res.data.chromeOsOptions)]
          .sort()
          .map((os) => {
            return { label: os, value: os };
          });
        var androidOsOptions = res.data.androidOsOptions
          .map((os) => os.slice(0, os.indexOf(".")))
          .sort(
            (a, b) => parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1])
          );
        androidOsOptions = [...new Set(androidOsOptions)].map((os) => {
          return { label: os, value: os };
        });
        var iosOsOptions = res.data.iosOsOptions
          .map((os) => os.slice(0, os.indexOf(".")))
          .sort(
            (a, b) => parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1])
          );
        iosOsOptions = [...new Set(iosOsOptions)].map((os) => {
          return { label: os, value: os };
        });
        var androidDeviceOptions = [...new Set(res.data.androidDeviceOptions)]
          .sort()
          .map((device) => {
            return {
              label: device[0],
              value: device[0],
              deviceType: device[1],
            };
          });
        var iosDeviceOptions = [...new Set(res.data.iosDeviceOptions)]
          .sort()
          .map((device) => {
            return {
              label: device[0],
              value: device[0],
              deviceType: device[1],
            };
          });
        var wearableOsOptions = [...new Set(res.data.wearableOsOptions)]
          .sort()
          .map((os) => {
            return { label: os, value: os };
          });
        var wearableDeviceOptions = Array.from(
          new Set(res.data.wearableDeviceOptions.map(JSON.stringify)),
          JSON.parse
        );
        wearableDeviceOptions = wearableDeviceOptions.sort().map((device) => {
          return { label: device[0], value: device[0], deviceType: device[1] };
        });
        var selectedDevice = [];
        var selectedOS = [];
        switch (this.state.currentPlatform.value) {
          case 1:
            selectedOS = this.windowsOsOptions.find(
              (option) => option.label === this.props.data.testDeviceOs
            );
            selectedOS = selectedOS ? selectedOS : [];
            break;
          case 2:
            selectedOS = macOsOptions.find(
              (option) => option.label === this.props.data.testDeviceOs
            );
            selectedOS = selectedOS ? selectedOS : [];
            break;
          case 3:
            selectedDevice = iosDeviceOptions.find(
              (option) => option.label === this.props.data.testDeviceName
            );
            selectedDevice = selectedDevice ? selectedDevice : [];
            selectedOS = iosOsOptions.find(
              (option) => option.label === this.props.data.testDeviceOs
            );
            selectedOS = selectedOS ? selectedOS : [];
            break;
          case 4:
            selectedDevice = androidDeviceOptions.find(
              (option) => option.label === this.props.data.testDeviceName
            );
            selectedDevice = selectedDevice ? selectedDevice : [];
            selectedOS = androidOsOptions.find(
              (option) => option.label === this.props.data.testDeviceOs
            );
            selectedOS = selectedOS ? selectedOS : [];
            break;
          case 5:
            selectedOS = chromeOsOptions.find(
              (option) => option.label === this.props.data.testDeviceOs
            );
            selectedOS = selectedOS ? selectedOS : [];
            break;
          case 6:
            selectedDevice = wearableDeviceOptions.find(
              (option) => option.label === this.props.data.testDeviceName
            );
            selectedDevice = selectedDevice ? selectedDevice : [];
            selectedOS = wearableOsOptions.find(
              (option) => option.label === this.props.data.testDeviceOs
            );
            selectedOS = selectedOS ? selectedOS : [];
            break;
        }
        var selectedBrowser = [];
        if (this.props.data.testDeviceBrowser) {
          if (this.state.currentPlatform.value === 4) {
            selectedBrowser = this.androidBrowserOptions.find(
              (option) => option.label === this.props.data.testDeviceBrowser
            );
          } else if (this.state.currentPlatform.value === 5) {
            selectedBrowser = this.chromeOsBrowserOptions.find(
              (option) => option.label === this.props.data.testDeviceBrowser
            );
          } else {
            selectedBrowser = this.browserOptions.find(
              (option) => option.label === this.props.data.testDeviceBrowser
            );
          }
        }
        setTimeout(() => {
          this.setState(
            {
              selectedDevice,
              selectedOS,
              selectedBrowser,
              macOsOptions,
              androidOsOptions,
              iosOsOptions,
              androidDeviceOptions,
              iosDeviceOptions,
              chromeOsOptions,
              wearableOsOptions,
              wearableDeviceOptions,
              loading: false,
            },
            () => {
              this.props.setBackdropLoader(false);
            }
          );
        }, 300);
      })
      .catch((err) => {
        this.props.setBackdropLoader(false);
        console.log(err);
      });
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handlePlatformChange = (selectedPlatform) => {
    var selectedPlatform = selectedPlatform;
    selectedPlatform.value = parseInt(selectedPlatform.value);
    this.setState({
      currentPlatform: selectedPlatform,
      selectedDevice: [],
      selectedOS: [],
      selectedBrowser: [],
    });
  };

  handleOSChange = (selectedOS) => {
    this.setState({
      selectedOS,
    });
  };

  handleDeviceChange = (selectedDevice) => {
    this.setState({
      selectedDevice,
    });
  };

  handleBrowserChange = (selectedBrowser) => {
    this.setState({
      selectedBrowser,
    });
  };

  handleEditDeviceClick = () => {
    this.props.setBackdropLoader(true);
    var device_name = this.state.selectedDevice.label
      ? this.state.selectedDevice.label
      : "";
    var os_name = this.state.selectedOS.label;
    var browser_name = this.state.selectedBrowser.label;
    var position = this.props.data.testDevicePosition;
    if (this.props.data.currentPlatform !== this.state.currentPlatform.value) {
      var testDevices = [...this.props.data.testDevices];
      testDevices = testDevices.map((td) => td.device_platform_id);
      var lastIndex = testDevices.lastIndexOf(this.state.currentPlatform.value);
      if (lastIndex !== -1) {
        if (lastIndex + 1 === testDevices.length) {
          position = lastIndex + 1;
        } else {
          if (
            this.props.data.currentPlatform < this.state.currentPlatform.value
          ) {
            position = lastIndex + 1;
          } else {
            position = lastIndex + 2;
          }
        }
      } else {
        switch (this.state.currentPlatform.value) {
          case 1:
            position = 1;
            break;
          case 2:
            if (testDevices.lastIndexOf(1) === -1) {
              position = 1;
            } else {
              if (
                this.props.data.currentPlatform <
                this.state.currentPlatform.value
              ) {
                position = testDevices.lastIndexOf(1) + 1;
              } else {
                position = testDevices.lastIndexOf(1) + 2;
              }
            }
            break;
          case 3:
            if (
              testDevices.lastIndexOf(4) === -1 &&
              testDevices.lastIndexOf(5) === -1 &&
              testDevices.lastIndexOf(6) === -1
            ) {
              position = testDevices.length;
            } else if (
              testDevices.lastIndexOf(4) === -1 &&
              testDevices.lastIndexOf(5) !== -1
            ) {
              if (testDevices.indexOf(5) === 0) {
                position = 1;
              } else {
                if (
                  this.props.data.currentPlatform <
                  this.state.currentPlatform.value
                ) {
                  position = testDevices.indexOf(5);
                } else {
                  position = testDevices.indexOf(5) + 1;
                }
              }
            } else if (testDevices.lastIndexOf(4) !== -1) {
              if (testDevices.indexOf(4) === 0) {
                position = 1;
              } else {
                if (
                  this.props.data.currentPlatform <
                  this.state.currentPlatform.value
                ) {
                  position = testDevices.indexOf(4);
                } else {
                  position = testDevices.indexOf(4) + 1;
                }
              }
            } else {
              if (testDevices.indexOf(6) === 0) {
                position = 1;
              } else {
                if (
                  this.props.data.currentPlatform <
                  this.state.currentPlatform.value
                ) {
                  position = testDevices.indexOf(6);
                } else {
                  position = testDevices.indexOf(6) + 1;
                }
              }
            }
            break;
          case 4:
            if (
              testDevices.lastIndexOf(5) === -1 &&
              testDevices.lastIndexOf(6) === -1
            ) {
              position = testDevices.length;
            } else if (testDevices.lastIndexOf(5) !== -1) {
              if (testDevices.indexOf(5) === 0) {
                position = 1;
              } else {
                if (
                  this.props.data.currentPlatform <
                  this.state.currentPlatform.value
                ) {
                  position = testDevices.indexOf(5);
                } else {
                  position = testDevices.indexOf(5) + 1;
                }
              }
            } else {
              if (testDevices.indexOf(6) === 0) {
                position = 1;
              } else {
                if (
                  this.props.data.currentPlatform <
                  this.state.currentPlatform.value
                ) {
                  position = testDevices.indexOf(6);
                } else {
                  position = testDevices.indexOf(6) + 1;
                }
              }
            }
            break;
          case 5:
            if (testDevices.lastIndexOf(6) === -1) {
              position = testDevices.length;
            } else {
              if (testDevices.indexOf(6) === 0) {
                position = 1;
              } else {
                if (
                  this.props.data.currentPlatform <
                  this.state.currentPlatform.value
                ) {
                  position = testDevices.indexOf(6);
                } else {
                  position = testDevices.indexOf(6) + 1;
                }
              }
            }
            break;
          case 6:
            position = testDevices.length;
            break;
        }
      }

      // var position = testDevices.indexOf("edit device")
    }

    axios
      .patch(`/test_devices/${this.props.data.testDeviceId}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        test_device: {
          device_name,
          os_name,
          browser_name,
          position,
          device_platform_id: this.state.currentPlatform.value,
          device_type_id: this.state.selectedDevice.deviceType
            ? this.state.selectedDevice.deviceType
            : this.state.currentPlatform.value === 5
            ? 3
            : 4,
        },
      })
      .then((res) => {
        this.props.setBackdropLoader(false);
        M.toast({
          html: `${this.state.currentPlatform.label} Device Updated`,
          displayLength: 2000,
          classes: "green darken-1 shift-toast",
        });
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        this.props.setBackdropLoader(false);
        console.error(err);
      });
  };

  handleTestDeviceDelete = () => {
    if (this.props.data.testDevices.length === 1) {
      Swal.fire({
        title: "Cannot delete.",
        text: "Test pass must have at least one test device.",
      });
    } else {
      Swal.fire({
        title: "Remove Test Device",
        text: "Are you sure that you want to remove this Test Device?",
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.props.setBackdropLoader(true);
          axios
            .delete(`/test_devices/${this.props.data.testDeviceId}.json`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.props.setBackdropLoader(false);
              M.toast({
                html: `Device removed`,
                displayLength: 3000,
                classes: "green shift-toast",
              });
              this.props.modalAction(false, "", {});
            })
            .catch((err) => {
              this.props.setBackdropLoader(false);
              console.error(err);
            });
        } else if (result.dismiss) {
          // Swal.fire('Device Saved', 'Your device is safe')
        }
      });
    }
  };

  render() {
    var cp = this.state.currentPlatform.value;
    return (
      <div>
        <div id="add-test-device-form">
          <h4 style={{ marginBottom: "15px" }}>Edit Test Device</h4>
          {this.props.data.testDevices &&
            this.props.data.testDevices.length > 1 && (
              <i
                className="material-icons bc-hover"
                id="test-device-delete"
                onClick={this.handleTestDeviceDelete}
                style={{
                  position: "absolute",
                  left: "137px",
                  fontSize: "20px",
                  top: "25px",
                  cursor: "pointer",
                }}
              >
                delete
              </i>
            )}
          <div
            style={{
              position: "relative",
              marginBottom: "20px",
              display: "block",
              justifyContent: "space-between",
            }}
          >
            <BasicSelect
              name={`device-platform`}
              options={this.platformOptions.options}
              onChange={this.handlePlatformChange}
              selectedOption={
                this.state.currentPlatform.label
                  ? this.state.currentPlatform
                  : null
              }
              placeholder="Platform"
              id={`device-platform`}
              width="122px"
              maxHeight="145px"
              display="inline-block"
              cascadeTransition={false}
              selectedTextStyle={{
                color: "#242424",
                fontWeight: "500",
                display: "block",
                width: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              containerStyle={{ verticalAlign: "bottom" }}
              menuTransitionTime={0.2}
              // arrowStyle={{right:"-2px"}}
              // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
              scrollIntoView={"react-modal"}
            />
            {(cp === 3 || cp === 4 || cp === 6) && (
              <BasicSelect
                name={`device-device`}
                options={
                  cp === 3
                    ? this.state.iosDeviceOptions
                    : cp === 4
                    ? this.state.androidDeviceOptions
                    : this.state.wearableDeviceOptions
                }
                onChange={this.handleDeviceChange}
                selectedOption={
                  this.state.selectedDevice.label
                    ? this.state.selectedDevice
                    : null
                }
                placeholder="Device"
                id={`device-device`}
                width="200px"
                maxHeight="145px"
                display="inline-block"
                cascadeTransition={false}
                selectedTextStyle={{
                  color: "#242424",
                  fontWeight: "500",
                  display: "block",
                  width: "162px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                containerStyle={{ verticalAlign: "bottom" }}
                menuTransitionTime={0.2}
                // arrowStyle={{right:"-2px"}}
                // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                scrollIntoView={"react-modal"}
                searchEnabled={true}
              />
            )}
            <BasicSelect
              name={`device-os`}
              options={
                cp === 1
                  ? this.windowsOsOptions
                  : cp === 2
                  ? this.state.macOsOptions
                  : cp === 3
                  ? this.state.iosOsOptions
                  : cp === 4
                  ? this.state.androidOsOptions
                  : cp === 5
                  ? this.state.chromeOsOptions
                  : this.state.wearableOsOptions
              }
              onChange={this.handleOSChange}
              selectedOption={
                this.state.selectedOS.label ? this.state.selectedOS : null
              }
              placeholder="Operating System"
              id={`device-os`}
              width="200px"
              maxHeight="145px"
              display="inline-block"
              cascadeTransition={false}
              selectedTextStyle={{
                color: "#242424",
                fontWeight: "500",
                display: "block",
                width: "162px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              containerStyle={{ verticalAlign: "bottom" }}
              menuTransitionTime={0.2}
              // arrowStyle={{right:"-2px"}}
              // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
              scrollIntoView={"react-modal"}
              searchEnabled={true}
            />
            {!(cp === 6) && (
              <BasicSelect
                name={`device-browser`}
                options={
                  this.state.currentPlatform.label &&
                  this.state.currentPlatform.value === 4
                    ? this.androidBrowserOptions
                    : this.state.currentPlatform.label &&
                      this.state.currentPlatform.value === 5
                    ? this.chromeOsBrowserOptions
                    : this.browserOptions
                }
                onChange={this.handleBrowserChange}
                selectedOption={
                  this.state.selectedBrowser.label
                    ? this.state.selectedBrowser
                    : null
                }
                placeholder={
                  <span>
                    Browser <span style={{ color: "#b7b7b7" }}>(Optional)</span>
                  </span>
                }
                id={`device-browser`}
                width="200px"
                maxHeight="145px"
                display="inline-block"
                cascadeTransition={false}
                selectedTextStyle={{
                  color: "#242424",
                  fontWeight: "500",
                  display: "block",
                  width: "162px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                containerStyle={{
                  verticalAlign: "bottom",
                  marginRight: "-15px",
                }}
                menuTransitionTime={0.2}
                // arrowStyle={{right:"-2px"}}
                // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
                scrollIntoView={"react-modal"}
              />
            )}
          </div>
          <button
            ref="add_test_device_button"
            disabled={
              !this.state.currentPlatform.label || !this.state.selectedOS.label
            }
            style={{ marginTop: "30px", display: "block", marginLeft: "auto" }}
            onClick={this.handleEditDeviceClick}
            className="btn"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}
export default EditTestDevice;
