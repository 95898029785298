import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Swal from "sweetalert2";

class UserPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      selectedUsers: []
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    $.ajax({
        url: '/users.json',
        dataType: 'json',
        cache: false,
        success: function(data) {
          var users = data.map(x => {x.label = x.name, x.value = x.id})
          this.setState({users: users})
        }.bind(this),
        error: function(data) {
          Swal.fire('User data error', 'There was an error retrieving users');
        }
      })
  }

  handleSelectUser = (selectedOption) => {
    this.setState({ selectedUsers: selectedOption})
  }

  render() {
      return (
        <div className="card col s12 commentCard" id="userPicker">
          <Select
            aria-label="User dropdown for user picker component"
            classNamePrefix={"select"}
            defaultValue={"Tag a user"}
            value={this.state.selectedOptionAssignee}
            isMulti
            onChange={this.handleSelectUser}
            options={this.state.users}
          />
        </div>
      );
  }
}

export default UserPicker