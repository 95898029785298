import React from 'react'
import axios from 'axios'
import ProgressRing from '../common/progress_ring.jsx'
import Swal from "sweetalert2";

class ImageEditorToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.image,
      percentCompleted: 0,
      loadingImage: false
    }
    this.handleDeleteImage = this.handleDeleteImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.addImage = this.addImage.bind(this)
    this.handleFileDragEnter = this.handleFileDragEnter.bind(this)
    this.handleFileDragLeaveOrDrop = this.handleFileDragLeaveOrDrop.bind(this)
    this.file = React.createRef()
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }

  handleFileDragEnter(){
      if($('.image-background').css('background-color') == "rgb(211, 211, 211)"){
        $('.image-background').css('background-color', "#519acc")
      }
    }

  handleFileDragLeaveOrDrop(e){
    if(e.type === "drop" || e.target.parentElement.classList.contains("image-background")){
      $('.image-background').css('background-color', "rgb(211, 211, 211)")
    }
  }

  handleDeleteImage() {
    var self = this
    Swal.fire({
      title: "Delete Attachment",
      text: "Are you sure that you want to delete this image?",
      width: "300px",
      reverseButtons: true,
      showCancelButton:true,
      confirmButtonAriaLabel: 'Yes',
      cancelButtonAriaLabel: 'cancel',
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        self.deleteImage();
      } else if (result.dismiss) {
        // Swal.fire("Cancelled", "Your file is safe");
      }
    });
  }

  deleteImage() {
    this.setState({loadingImage: true})
    var object = this.props
    var config = {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        self.setState({percentCompleted: percentCompleted})
      }
    }
      axios.patch(object.parent + 's/' + parentObject.id + '.json', formData, config).then((res) => {
      if (res.status === 200) {
           this.setState({loadingImage: false, image: ""})
            Swal.fire({
              text: 'User Image Deleted',
              type: 'success',
              confirmButtonText: 'Ok'
            })          
          }
        }).catch((err) => {
          console.log(err)
           this.setState({loadingImage: false })
          Swal.fire({
            title: "User Image Error",
            text: err.response.data.error,
            confirmButtonText: 'OK'
      })
     })
  }

  addImage(e) {
    e.preventDefault()
    var fileExtension
    if (document.getElementById("image-file")) {
      fileExtension = document.getElementById("image-file").value.split(".").pop().toLowerCase()
    } else {
      document.getElementById("image-btn-file") 
      fileExtension = document.getElementById("image-btn-file").value.split(".").pop().toLowerCase()
    }
    var allowedExtensions = ["jpg","jpeg","png","gif","svg"]
    if(!allowedExtensions.includes(fileExtension)){
      // eslint-disable-next-line no-undef
      M.toast({html: 'Invalid File Type', classes: "red darken-1 error-toast"})
      this.refs.file.value = ""
      return
    }
    this.setState({loadingImage: true})
    var self = this
    var object = this.props
    var formData = new FormData()
    var parentObject = this.props.parentObject
    var parentMap = {"project": "icon", "user": "avatar", "ticket_attachment": "image"}
    if (this.file.current) {
      formData.append(`${object.parent}[${parentMap[object.parent]}]`, this.file.current.files[0])
    }
    var config = {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        self.setState({percentCompleted: percentCompleted})
      }
    }
    var url = `/${object.parent}s/${parentObject.id}.json`
    if(this.props.action !== "create"){
      axios.patch(url, formData, config).then((res) => {
        var url = res.data.icon_url
        if (res.status === 200) {
           self.setState({loadingImage: false, image: url})
            Swal.fire({
              text: `${object.parent.replace(/^\w/, (c) => c.toUpperCase())} Updated`,
              confirmButtonText: 'OK'
            })          
          }
        }).catch((err) => {
          console.log(err)
           self.setState({loadingImage: false })
          Swal.fire({
            title: "Image add error",
            text: err.response.data.error,
            confirmButtonText: 'OK'
      })
    })
    } else {
      self.setState({loadingImage: false, image: URL.createObjectURL(this.file.current.files[0])})
    }
  }

    render() {
    return (
      <div>
        <div onDragEnter={this.handleFileDragEnter} onDragLeave={this.handleFileDragLeaveOrDrop} onDrop={this.handleFileDragLeaveOrDrop} className="image-background" id="image-upload-circle" style={{width: `${this.props.size}px`, height: `${this.props.size}px`, marginLeft: "12px", marginRight: "auto"}}>
          <img alt="user attached" style={{height: "100%", width: "100%", borderRadius: "50%", mouseEvents: "none"}} src={this.state.image} />
          <input onChange={this.addImage} id="image-btn-file" type="file" accept=".jpg,.gif,.png,.svg" ref={this.file} />
          { this.state.loadingImage && 
         <ProgressRing 
            radius={ ((this.props.size + 5)/2) }
            left={"22px"}
            top={"22px"}
            stroke={ 3 }
            progress={ this.state.percentCompleted.toString() } 
         />
          }
        </div>
      </div>
    );
  }
}

export default ImageEditorToolbar