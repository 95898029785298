import React from "react";

const TextHistory = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
    >
      <rect width="42" height="42" rx="5" fill="#DBF2FF" />
      <path
        d="M31.5 19.9615V12.8332C31.5 12.2143 31.2542 11.6208 30.8166 11.1833C30.379 10.7457 29.7855 10.4998 29.1667 10.4998H24.29C23.8 9.1465 22.5167 8.1665 21 8.1665C19.4833 8.1665 18.2 9.1465 17.71 10.4998H12.8333C11.55 10.4998 10.5 11.5498 10.5 12.8332V29.1665C10.5 29.7853 10.7458 30.3788 11.1834 30.8164C11.621 31.254 12.2145 31.4998 12.8333 31.4998H19.9617C21.4317 32.9465 23.4383 33.8332 25.6667 33.8332C30.1817 33.8332 33.8333 30.1815 33.8333 25.6665C33.8333 23.4382 32.9467 21.4315 31.5 19.9615ZM21 10.4998C21.6417 10.4998 22.1667 11.0248 22.1667 11.6665C22.1667 12.3082 21.6417 12.8332 21 12.8332C20.3583 12.8332 19.8333 12.3082 19.8333 11.6665C19.8333 11.0248 20.3583 10.4998 21 10.4998ZM12.8333 29.1665V12.8332H15.1667V15.1665H26.8333V12.8332H29.1667V18.2932C28.105 17.7915 26.9267 17.4998 25.6667 17.4998H15.1667V19.8332H19.95C19.25 20.4982 18.7133 21.2915 18.2933 22.1665H15.1667V24.4998H17.5933C17.535 24.8848 17.5 25.2698 17.5 25.6665C17.5 26.9265 17.7917 28.1048 18.2933 29.1665H12.8333ZM25.6667 31.4998C22.4467 31.4998 19.8333 28.8865 19.8333 25.6665C19.8333 22.4465 22.4467 19.8332 25.6667 19.8332C28.8867 19.8332 31.5 22.4465 31.5 25.6665C31.5 28.8865 28.8867 31.4998 25.6667 31.4998ZM26.25 25.9582L29.5867 27.9298L28.7117 29.3532L24.5 26.8332V20.9998H26.25V25.9582Z"
        fill="#62C6FF"
      />
    </svg>
  );
};

export default TextHistory;
