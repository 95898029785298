import React from 'react'

class UserSkillsIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
        aria-hidden="true"
      >
        <path d="M90.75,96A20.6,20.6,0,0,0,78.16,79.5a17.86,17.86,0,0,0,3.92-11.28A18.06,18.06,0,1,0,50,79.5,20.37,20.37,0,0,0,37.42,96l-1.9,14.73a6.24,6.24,0,0,0,4.75,6.77A111.3,111.3,0,0,0,64,120a112.15,112.15,0,0,0,23.76-2.49,6.09,6.09,0,0,0,4.75-6.77ZM64,54.91a13.31,13.31,0,1,1-13.3,13.31A13.29,13.29,0,0,1,64,54.91Zm22.81,58a112.77,112.77,0,0,1-45.49,0,1.44,1.44,0,0,1-1.07-1.54L42.05,96.6A15.88,15.88,0,0,1,53.93,83.18,18.1,18.1,0,0,0,64,86.27a18.41,18.41,0,0,0,10.1-3.09A15.6,15.6,0,0,1,86,96.6l1.78,14.73A1.36,1.36,0,0,1,86.83,112.87Z"/>
        <path d="M53.22,30.21l-1.79,7.6A4.05,4.05,0,0,0,53,42a4.3,4.3,0,0,0,2.37.71,4,4,0,0,0,2-.59l6.65-4,6.66,4A4.19,4.19,0,0,0,75.07,42a4,4,0,0,0,1.54-4.16l-1.78-7.6,5.94-5.11a3.92,3.92,0,0,0-2.26-6.89l-7.83-.59-3-7.24A3.94,3.94,0,0,0,64,8h0a4.19,4.19,0,0,0-3.68,2.38l-3,7.24-7.84.59A4,4,0,0,0,46.09,21a3.84,3.84,0,0,0,1.19,4.15Zm5-8a4.12,4.12,0,0,0,3.33-2.37L64,13.94l2.5,5.94a3.89,3.89,0,0,0,3.32,2.37l6.42.48-4.87,4.15a4.07,4.07,0,0,0-1.31,3.92l1.43,6.3L66,33.77a3.77,3.77,0,0,0-4.15,0L56.42,37.1l1.43-6.3a3.84,3.84,0,0,0-1.31-3.92l-4.87-4.15Z"/>
        <path d="M38.25,55.39l-1.78-7.6,5.94-5.11a3.92,3.92,0,0,0-2.26-6.89l-7.84-.59-3-7.25a3.93,3.93,0,0,0-3.68-2.37A4.19,4.19,0,0,0,22,28L19,35.2l-7.84.59a4,4,0,0,0-3.44,2.73,3.87,3.87,0,0,0,1.18,4.16l5.94,5.11-1.78,7.6a4.06,4.06,0,0,0,1.55,4.16,4.3,4.3,0,0,0,2.37.71,4,4,0,0,0,2-.6l6.65-4,6.65,4a4.18,4.18,0,0,0,4.4-.11A4.05,4.05,0,0,0,38.25,55.39ZM33,44.46a4.07,4.07,0,0,0-1.31,3.92l1.42,6.3-5.46-3.33a3.79,3.79,0,0,0-4.16,0l-5.46,3.33,1.43-6.3a3.84,3.84,0,0,0-1.31-3.92l-4.87-4.15,6.41-.48a4.12,4.12,0,0,0,3.33-2.38l2.49-5.93L28,37.45a3.88,3.88,0,0,0,3.32,2.38l6.42.48Z"/>
        <path d="M120.2,38.41a3.81,3.81,0,0,0-3.44-2.74l-7.84-.59-3-7.25a3.93,3.93,0,0,0-3.68-2.37h0a4.18,4.18,0,0,0-3.68,2.37l-3,7.25-7.84.59a4,4,0,0,0-3.45,2.74,3.86,3.86,0,0,0,1.19,4.15l5.94,5.11-1.78,7.6a4.05,4.05,0,0,0,1.54,4.16,4.33,4.33,0,0,0,2.38.71,4.09,4.09,0,0,0,2-.59l6.65-4,6.65,4a4.18,4.18,0,0,0,4.39-.12,4,4,0,0,0,1.55-4.16l-1.78-7.6L119,42.56A3.48,3.48,0,0,0,120.2,38.41Zm-10.57,6.05a4.06,4.06,0,0,0-1.3,3.92l1.42,6.3-5.34-3.33a3.79,3.79,0,0,0-4.16,0l-5.46,3.33,1.42-6.3a3.82,3.82,0,0,0-1.31-3.92l-4.75-4.15,6.42-.48a4.11,4.11,0,0,0,3.32-2.38l2.5-5.93,2.49,5.93a3.88,3.88,0,0,0,3.33,2.38l6.41.48Z"/>
      </svg>
    );
  }
}
UserSkillsIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default UserSkillsIcon