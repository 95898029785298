import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ToggleSwitch = (props) => {
  const { checked, handleChange, className, width, height } = props;

  return (
    <ToggleContainer className={className}>
      <Input
        $width={width}
        $height={height}
        checked={checked}
        type="checkbox"
        onChange={handleChange}
      />
      <Switch $width={width} $height={height}>
        <OnLabel $checked={checked}>On</OnLabel>
        <OffLabel $checked={checked}>Off</OffLabel>
      </Switch>
    </ToggleContainer>
  );
};

const ToggleContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  width: ${({ $width }) => `${$width}px` || "50px"};
  height: ${({ $height }) => `${$height}px` || "25px"};
  background: #818080;
  border-radius: 32px;
  border: 1px solid #717171;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    height: ${({ $height }) => ($height ? `${$height - 2}px` : "23px")};
    width: ${({ $height }) => ($height ? `${$height - 2}px` : "23px")};
    border-radius: 50%;
    top: 50%;
    left: 0;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: #519acc;

    &:before {
      /* transform: translate(34px, -50%); */
      transform: ${({ $width, $height }) =>
        `translate(${
          $width && $height ? parseInt($width, 10) - parseInt($height, 10) : 25
        }px, -50%)`};
    }
  }
`;

const Label = styled.span`
  position: absolute;
  font-family: Arial;
  font-size: 9px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  transition: 300ms opacity;
  transform: translate(0, -50%);
  top: 50%;
`;

const OnLabel = styled(Label)`
  left: 8px;
  opacity: ${({ $checked }) => ($checked ? "1" : "0")};
`;

const OffLabel = styled(Label)`
  right: 8px;
  opacity: ${({ $checked }) => ($checked ? "0" : "1")};
`;

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ToggleSwitch.defaultProps = {
  className: "",
  width: 50,
  height: 25,
};

export default ToggleSwitch;
