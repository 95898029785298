import React from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import Select from "react-select";
import SingleSelectStyles from "../common/select_styles";
import { dropdownIndicator, customControl } from "../common/select_utils";

class EditUserForm extends React.Component {
  constructor(props) {
    super(props);
    const role = { value: this.props.users.role, label: this.props.users.role };
    this.state = {
      user: this.props.users,
      selectedOptionRole: role,
      additionalPermissions: this.props.users.additional_permissions,
      nameInput: this.props.users.name != null ? this.props.users.name : "",
      emailInput: this.props.users.email != null ? this.props.users.email : "",
      loading: false,
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  resetUserPassword = () => {
    this.setState({ loading: true });
    const object = this.props;
    const self = this;
    Swal.fire({
      title: "Reset User's Password",
      text: "Are you sure that you want to reset their Password?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        // Send ajax call to delete application choosen
        $.ajax({
          url: `/users/${object.users.id}/password_reset.json`,
          dataType: "json",
          type: "GET",
          cache: false,
          beforeSend(xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          success() {
            self.setState({ loading: false });
            Swal.fire({
              text: "User Password Reset",
              type: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          },
          error(data) {
            // If error present error swal message
            self.setState({ loading: false });
            Swal.fire({
              text: "There was a problem with that request",
              type: "error",
              customClass: "swal2-error-modal",
            });
          },
        });
      } else if (result.dismiss) {
        self.setState({ loading: false });
      }
    });
  };

  resendUserEmail = () => {
    this.setState({ loading: true });
    const self = this;
    const formData = new FormData();
    if (this.state.user.email) {
      formData.append("user[email]", this.state.user.email);
      formData.append("commit", "Resend");
    } else {
      M.toast({
        html: "User Email is missing",
        classes: "red darken-1 error-toast",
      });
      return false;
    }
    Swal.fire({
      title: "Resend User Confirmation?",
      text: `Resend confirmation to ${this.state.user.email}?`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        // Send ajax call to delete application choosen
        $.ajax({
          url: "/users/confirmation",
          enctype: "multipart/form-data",
          type: "POST",
          data: formData,
          processData: false,
          contentType: false,
          cache: false,
          beforeSend(xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          success() {
            self.setState({ loading: false });
            Swal.fire({
              title: "User Confirmation Success",
              text: "Sign Up Email Sent",
              confirmButtonText: "Ok",
            });
          },
          error() {
            self.setState({ loading: false });
            // If error present error swal message
            Swal.fire({
              text: "There was a problem with that request",
              customClass: "swal2-error-modal",
            });
          },
        });
      } else if (result.dismiss) {
        self.setState({ loading: false });
      }
    });
  };

  handleCheckChange = (e) => {
    const { value } = e.target;
    const { additionalPermissions } = this.state;
    const selectedIndex = additionalPermissions.findIndex(
      (item) => item === value
    );
    if (selectedIndex !== -1) {
      additionalPermissions.splice(selectedIndex, 1);
    } else {
      additionalPermissions.push(value);
    }
    this.setState({ additionalPermissions });
  };

  submitEditUser = (formData) => {
    this.setState({ loading: true });
    const component = this;
    const object = this.props;
    const xhr = new XMLHttpRequest();
    xhr.open("PATCH", `/users/${this.props.users.id}/admin_update.json`, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 200) {
        const user = JSON.parse(xhr.response);
        object.handleEditUser(user);
        Swal.fire({
          title: "User Edit",
          text: "User Updated",
          confirmButtonText: "Ok",
        }).then(() => {
          component.setState({ loading: false });
          component.props.closeModal();
        });
      } else {
        component.setState({ loading: false });
        const error = JSON.parse(xhr.response);
        Swal.fire("Edit user error", error.error);
      }
    };
    xhr.send(formData);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const name = this.state.nameInput;
    const email = this.state.emailInput;
    const role = this.state.selectedOptionRole.value;
    const permissions = this.state.additionalPermissions.map((p) => p);

    if (!name || !email || !role) {
      return null;
    }

    const formData = new FormData();
    formData.append("user[name]", name);
    formData.append("user[email]", email);
    formData.append("user[role]", role);

    for (let x = 0; x < permissions.length; x++) {
      formData.append("user[additional_permissions][]", permissions[x]);
    }
    this.submitEditUser(formData);

    return null;
  };

  componentWillUnmount() {
    if (this.menuRef && this.menuRef.current) {
      const item = this.menuRef.current.children[1];
      if (item) {
        item.removeEventListener("wheel", this.indicatorHandler);
      }
    }
  }

  handlePermissionsSelect = (items) => {
    this.setState({ additionalPermissions: items || [] });
  };

  render() {
    const test_passes =
      this.state.additionalPermissions.findIndex((x) => x == "Test Passes") !=
      -1;
    const test_cases =
      this.state.additionalPermissions.findIndex((x) => x == "Test Cases") !=
      -1;
    const device_checkout =
      this.state.additionalPermissions.findIndex(
        (x) => x == "Device Checkout"
      ) != -1;
    const client_performance_viewable =
      this.state.additionalPermissions.findIndex(
        (x) => x == "Client Performance Viewable"
      ) != -1;

    const handleSelectChangeRole = (selectedOption) => {
      this.setState({
        selectedOptionRole: selectedOption,
        additionalPermissions:
          selectedOption.value === "admin" || selectedOption.value === "lead"
            ? []
            : this.state.additionalPermissions,
      });
    };

    return (
      <div
        id="userForm"
        style={{ flex: "1" }}
        className="flex-container flex-column"
      >
        {this.state.loading && (
          <div
            className={`modal-backdrop-loader ${
              this.state.loading
                ? "modal-backdrop-loader-open"
                : "modal-backdrop-loader-close"
            }`}
          >
            {this.state.loading && <div id="test-step-bugs-loader" />}
          </div>
        )}
        <div>
          <div>
            <h3 className="small-title">Edit an existing user</h3>
            <p className="subtitle">
              Modify the existing User&apos;s details below{" "}
            </p>
          </div>

          <form
            onSubmit={this.handleSubmit}
            style={{ paddingTop: "20px" }}
            encType="multipart/form-data"
          >
            <div
              style={{ flex: "1", flexDirection: "column" }}
              className="flex-container"
            >
              <div>
                <div style={{ marginBottom: "20px" }}>
                  <div style={{ flex: 1, display: "flex" }}>
                    <div style={{ width: "225px", marginRight: "20px" }}>
                      <p className="input-title">
                        User Name<span className="bc_blue">*</span>
                      </p>
                      <input
                        type="text"
                        className="browser-default common-input"
                        name="nameInput"
                        maxLength="35"
                        id="user[name]"
                        value={this.state.nameInput}
                        placeholder="User Name"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <div style={{ width: "225px" }}>
                        <p className="input-title">
                          Email Address<span className="bc_blue">*</span>
                        </p>
                        <input
                          type="email"
                          className="browser-default common-input"
                          name="emailInput"
                          maxLength="50"
                          id="user[email]"
                          value={this.state.emailInput}
                          placeholder="User Email"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <div style={{ flex: 1, display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "225px", marginRight: "20px" }}>
                      <p className="input-title">
                        User Role<span className="bc_blue">*</span>
                      </p>
                      <Select
                        styles={{
                          ...SingleSelectStyles,
                          container: (styles) => ({
                            ...styles,
                            fontFamily: "Arial",
                            width: "150px",
                          }),
                        }}
                        components={{
                          DropdownIndicator: dropdownIndicator,
                          Control: customControl,
                        }}
                        aria-label="Role dropdown for edit user form"
                        placeholder="User Role"
                        value={this.state.selectedOptionRole}
                        blurInputOnSelect
                        onChange={handleSelectChangeRole}
                        options={[
                          { value: "client", label: "client" },
                          { value: "tester", label: "tester" },
                          { value: "trialist", label: "trialist" },
                          { value: "lead", label: "lead" },
                          { value: "admin", label: "admin" },
                        ]}
                        required
                      />
                    </div>
                    {(this.state.selectedOptionRole.value === "client" ||
                      this.state.selectedOptionRole.value === "tester") && (
                      <div style={{ width: "225px" }}>
                        <div>
                          <p className="input-title">Additional Permissions</p>
                          <p>
                            <label htmlFor="checkbox_tp">
                              <input
                                onChange={this.handleCheckChange}
                                checked={test_cases === true}
                                name="visible"
                                value="Test Cases"
                                id="checkbox_tp"
                                className="filled-in"
                                type="checkbox"
                              />
                              <span>Test Cases</span>
                            </label>
                          </p>
                          <p>
                            <label htmlFor="checkbox_tc">
                              <input
                                onChange={this.handleCheckChange}
                                checked={test_passes === true}
                                name="visible"
                                id="checkbox_tc"
                                value="Test Passes"
                                className="filled-in"
                                type="checkbox"
                              />
                              <span>Test Passes</span>
                            </label>
                          </p>
                          <p>
                            <label htmlFor="checkbox_dev">
                              <input
                                onChange={this.handleCheckChange}
                                checked={device_checkout === true}
                                name="visible"
                                id="checkbox_dev"
                                value="Device Checkout"
                                className="filled-in"
                                type="checkbox"
                              />
                              <span>Device Checkout</span>
                            </label>
                          </p>
                          {this.state.selectedOptionRole.value === "client" && (
                            <p>
                              <label htmlFor="checkbox_cv">
                                <input
                                  onChange={this.handleCheckChange}
                                  checked={client_performance_viewable === true}
                                  name="visible"
                                  id="checkbox_cv"
                                  value="Client Performance Viewable"
                                  className="filled-in"
                                  type="checkbox"
                                />
                                <span>Extended Performance View</span>
                              </label>
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    <div style={{ flex: "1 0 100%" }}>
                      {this.state.user.confirmed ? (
                        <button
                          type="button"
                          className="new-design-button new-design-button-enabled center"
                          onClick={this.resetUserPassword}
                        >
                          Reset User Password
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="new-design-button new-design-button-enabled center"
                          onClick={this.resendUserEmail}
                        >
                          Resend Signup Email
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "15px",
                  }}
                >
                  <p style={{ fontSize: "16px", textTransform: "uppercase" }}>
                    Projects
                  </p>
                  <div
                    style={{
                      position: "unset",
                      marginLeft: "10px",
                      width: "100%",
                    }}
                    className="divider"
                  />
                </div>
                <ul className="pill-container">
                  {this.props.users.projects.map((project) => {
                    if (project.active === true) {
                      return (
                        <li className="pill" key={project.id}>
                          {project.name}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
            <div className="right form-group">
              <button
                type="button"
                style={{
                  marginRight: "11px",
                }}
                className="common-button-cancel"
                onClick={this.props.closeModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default EditUserForm;

EditUserForm.propTypes = {
  // openModal: PropTypes.func,
  closeModal: PropTypes.func,
  users: PropTypes.array,
  handleEditUser: PropTypes.func,
};
