import React from "react";
import PropTypes from "prop-types";
import WithCable from "@/channels/WithCable";
import TestPassLandingSuite from "./test_pass_landing_suite";

class TestPassLanding extends React.Component {
  constructor(props) {
    super(props);
    const suites = this.props.testSuites;
    this.recentSuiteId =
      suites.length > 0 ? suites[suites.length - 1].id : null;
    const urlBeginning =
      "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform";
    this.deviceUrls = {
      1: urlBeginning + "/Icons_Desktop.svg",
      2: urlBeginning + "/Icons_Desktop.svg",
      3: {
        Mobile: urlBeginning + "/Icons_Mobile.svg",
        Tablet: urlBeginning + "/Icons_Tablet.svg",
      },
      4: {
        Mobile: urlBeginning + "/Icons_Mobile.svg",
        Tablet: urlBeginning + "/Icons_Tablet.svg",
      },
      5: urlBeginning + "/Icons_Laptop.svg",
      6: urlBeginning + "/VR_AR.svg",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.project &&
      this.props.project &&
      prevProps.project.id !== this.props.project.id
    ) {
      this.testSuiteChannel?.unsubscribe();
      this.testSuiteChannel = this.props.cable.subscriptions.create(
        { channel: "TestSuiteChannel", project_id: this.props.project.id },
        {
          received: (data) => {
            if (data.retire_suite) {
              this.props.retireSuite(data.test_suite_id);
            } else if (this.refs[`suite-${data.test_suite_id}`]) {
              this.refs[`suite-${data.test_suite_id}`].handleCableUpdate(
                data.test_pass,
                data.bugs,
                data.delete_id
              );
            }
          },
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.project.id) {
      // eslint-disable-next-line no-undef
      this.testSuiteChannel = this.props.cable.subscriptions.create(
        { channel: "TestSuiteChannel", project_id: this.props.project.id },
        {
          received: (data) => {
            if (data.retire_suite) {
              this.props.retireSuite(data.test_suite_id);
            } else if (this.refs[`suite-${data.test_suite_id}`]) {
              this.refs[`suite-${data.test_suite_id}`].handleCableUpdate(
                data.test_pass,
                data.bugs,
                data.delete_id
              );
            }
          },
        }
      );
    }
  }

  componentWillUnmount() {
    this.testSuiteChannel.unsubscribe();
  }

  render() {
    const suites = this.props.testSuites;
    return (
      <div>
        {suites.length > 0 ? (
          suites
            .sort((a, b) => {
              return a.name.toLowerCase() - b.name.toLowerCase();
            })
            .map((suite) => {
              return (
                <TestPassLandingSuite
                  adminBool={this.props.adminBool}
                  showFocus={this.props.showFocus}
                  hideFocus={this.props.hideFocus}
                  setModal={this.props.setModal}
                  handleTestPassDelete={this.props.handleTestPassDelete}
                  deviceUrls={this.deviceUrls}
                  suite={suite}
                  setTestPass={this.props.setTestPass}
                  key={`suite-${suite.id}`}
                  ref={`suite-${suite.id}`}
                  project={this.props.project}
                />
              );
            })
        ) : (
          <div
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                display: "block",
                fontSize: "14pt",
                fontWeight: "600",
                marginBottom: "15px",
              }}
            >
              No test passes found.
            </span>
            <span>
              Looks like there are no test passes available. Please contact your
              project manager for more information.
            </span>
          </div>
        )}
      </div>
    );
  }
}

const { bool, func, number, string, shape, arrayOf } = PropTypes;

TestPassLanding.propTypes = {
  adminBool: bool,
  handleTestPassDelete: func,
  hideFocus: func,
  initialLoad: bool,
  project: shape({
    a11y_active: bool,
    active: bool,
    color: string,
    created_at: string,
    has_bugs: bool,
    has_test_cases: bool,
    icon_url: string,
    id: number,
    identifier: string,
    media: bool,
    name: string,
  }),
  retireSuite: func,
  setModal: func,
  setTestPass: func,
  showFocus: func,
  testSuites: arrayOf(shape({ id: number, name: string })),
};

export default WithCable(TestPassLanding);
