import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import _ from "lodash";
import styled from "styled-components";
import {
  PROJECT,
  TEAM,
  USER,
  USER_ID,
  TEAM_ID,
  PROJECT_ID,
} from "../constants";
import PerformanceUserDropdown from "./PerformanceUserDropdown";
import PerformanceProjectDropdown from "./PerformanceProjectDropdown";
import PerformanceTeamDropdown from "./PerformanceTeamDropdown";
import PerformanceDateRange from "./PerformanceDateRange";
import DashboardContext from "../DashboardContext";

const FiltersSelect = (props) => {
  const { selected, users } = props;
  const { queryParams, projects, teams } = useContext(DashboardContext);

  const handleFilterSelection = useCallback(
    (id) => {
      const nextParams = {
        ...queryParams,
        [USER_ID]: selected === USER ? id : null,
        [TEAM_ID]: selected === TEAM ? id : null,
        [PROJECT_ID]: selected === PROJECT ? id : null,
      };
      const nextParamsWithoutNulls = _.omitBy(nextParams, _.isNull);
      const search = queryString.stringify(nextParamsWithoutNulls);
      window.history.pushState({}, "", `?${search}`);
    },
    [queryParams, selected]
  );

  const handleDateRangeChange = useCallback(
    (start_date, end_date) => {
      const nextParams = {
        ...queryParams,
        start_date,
        end_date,
      };
      const search = queryString.stringify(nextParams);
      window.history.pushState({}, "", `?${search}`);
    },
    [queryParams]
  );

  return (
    <FiltersContainer>
      {selected === PROJECT && (
        <PerformanceProjectDropdown
          onProjectSelect={handleFilterSelection}
          queryParams={queryParams}
          projectsList={projects}
        />
      )}
      {selected === TEAM && (
        <PerformanceTeamDropdown
          onTeamSelect={handleFilterSelection}
          queryParams={queryParams}
          teamsList={teams}
        />
      )}
      {selected === USER && (
        <PerformanceUserDropdown
          onUserSelect={handleFilterSelection}
          queryParams={queryParams}
          usersList={users}
        />
      )}
      <DateRangeContainer>
        <PerformanceDateRange
          queryParams={queryParams}
          onDateRangeChange={handleDateRangeChange}
        />
      </DateRangeContainer>
    </FiltersContainer>
  );
};

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const DateRangeContainer = styled.div``;

FiltersSelect.propTypes = {
  selected: PropTypes.oneOf([PROJECT, TEAM, USER]).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.string,
      role: PropTypes.string,
      teams: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

export default FiltersSelect;
