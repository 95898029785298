import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";
import ReactTable from "pqa-react-table";
import moment from "moment";
import queryString from "query-string";
import useDeviceLab from "@hooks/data/useDeviceLab";
import { usePrevious, useLocation } from "react-use";
import { DeviceLabContext } from "@components/devices/device_lab";
import CheckoutCell from "./CheckoutCell";
import EditDeviceCell from "./EditDeviceCell";
import UserDeviceCountCell from "./UserDeviceCountCell";
import DeviceIdCell from "./DeviceIdCell";

const SCROLL_RESTORE_CLASSNAME = "ReactTable-scroll-restorable";
const SCROLL_ELEMENT_CLASS_IDENTIFIERS =
  ".ReactVirtualized__Grid.ReactVirtualized__List";

const DeviceLabTable = () => {
  const {
    currentUser,
    isClientUser,
    isAdminUser,
    isLeadUser,
    isTesterUser,
    isEditing,
    activeDevice,
    activeChartDevice,
    openHomeModal,
  } = useContext(DeviceLabContext);

  const { list, filters, SORT_KEYS } = useDeviceLab();

  const location = useLocation();
  const { search } = location;

  const q = filters.query;
  const previousList = usePrevious(list);

  const [a11yDetails, setA11yDetails] = useState(false);
  const [sorted, setSorted] = useState([]);

  const columns = useMemo(() => {
    const {
      ID,
      TYPE,
      PLATFORM,
      NAME,
      OS,
      SERIAL,
      USER,
      MAKE,
      RELEASE_DATE,
      DEVICE_COUNT,
      LAST_CHECKOUT,
      SIM,
    } = SORT_KEYS;

    const editCell = (cellInfo) => (
      <EditDeviceCell device={cellInfo.original} />
    );

    const standardCell = (cellInfo) => (
      <div>
        <p aria-hidden="true">{cellInfo.value}</p>
      </div>
    );

    const countCell = (cellInfo) => {
      return <UserDeviceCountCell device={cellInfo.original} />;
    };

    const idCell = (cellInfo) => {
      return <DeviceIdCell device={cellInfo.original} value={cellInfo.value} />;
    };

    const width =
      document.querySelector("div.ReactTable.-striped.-highlight")
        ?.clientWidth || 0;

    let divisor;
    if (isClientUser) {
      divisor = 2;
    } else if (isTesterUser) {
      divisor = 6.5;
    } else {
      divisor = 6.75;
    }
    const baseUnit = (width - 220) / divisor;

    const c = [
      {
        id: TYPE,
        Header: "Type",
        accessor: (d) => d.device_type,
        Cell: standardCell,
        resizable: true,
        width: baseUnit / 2,
      },
      {
        id: PLATFORM,
        Header: "Platform",
        accessor: (d) => d.device_platform,
        Cell: standardCell,
        resizable: true,
        width: baseUnit / 2,
      },
      {
        id: NAME,
        Header: "Name",
        accessor: (d) => d.device_name,
        Cell: standardCell,
        resizable: true,
        width: baseUnit,
      },
      {
        id: OS,
        Header: "Os",
        accessor: (d) => d.device_os,
        Cell: standardCell,
        resizable: true,
        width: baseUnit,
      },
    ];

    if (filters?.statuses?.sim) {
      c.push({
        id: SIM,
        Header: "Sim",
        accessor: (d) => d.sim,
        Cell: standardCell,
        width: baseUnit,
        resizable: true,
      });
    }

    if (!isClientUser) {
      c.unshift({
        id: ID,
        Header: "ID / Info",
        accessor: (d) => d.id,
        Cell: idCell,
        width: baseUnit * 0.75,
        resizable: true,
      });
    }

    if (!isClientUser) {
      c.push(
        {
          id: SERIAL,
          Header: "Serial",
          accessor: (d) => d.serial,
          Cell: standardCell,
          resizable: true,
          width: baseUnit,
        },
        {
          id: "office",
          Header: "Location",
          sortable: false,
          accessor: (d) => {
            if (d.user.id === 0) {
              if (d.office_only) {
                return "Office Available Only";
              }

              if (d.checkout_active) {
                return `Avaialble (Tier ${d.tier})`;
              }

              return "Unavailable";
            }
            return d.office ? "Office in Use" : "At Home";
          },
          Cell: standardCell,
          resizable: true,
          width: baseUnit,
        },
        {
          id: LAST_CHECKOUT,
          Header: "Last Checkout",
          Cell: standardCell,
          resizable: true,
          width: baseUnit,
          accessor: (d) => {
            if (!d.last_checkout) return "None";
            // return Date(d.last_checkout.created_at);
            // format date as "MON DD YYYY, hh:mm A"
            return moment(d.last_checkout.created_at).format("lll");
          },
        }
      );
    }

    if (isAdminUser || isLeadUser) {
      c.push({
        id: DEVICE_COUNT,
        Header: "Device Count",
        Cell: countCell,
        accessor: () => 0,
        resizable: true,
        width: baseUnit * 0.7,
      });
    }

    if (!isClientUser && !isEditing) {
      c.push({
        id: USER,
        Header: "Status",
        accessor: (d) => (d.user.id !== 0 ? d.user.name : "0"),
        Cell: CheckoutCell,
        width: 160,
        resizable: true,
      });
    }

    if (isEditing) {
      const item = {
        id: MAKE,
        Header: "Make",
        width: baseUnit,
        resizable: true,
        accessor: (d) => d.device_make,
        Cell: standardCell,
      };
      c.splice(4, 0, item);
      c.push({
        id: RELEASE_DATE,
        Header: "Released",
        Cell: standardCell,
        width: baseUnit,
        accessor: (d) => {
          return moment(d.release_date).format("l");
        },
        resizable: true,
      });
      c.unshift({
        id: "edit",
        Header: "",
        Cell: editCell,
        width: 40,
        accessor: (d) => d.id,
      });
    }

    return c;
  }, [
    isEditing,
    isClientUser,
    isAdminUser,
    isLeadUser,
    isTesterUser,
    filters,
    SORT_KEYS,
  ]);

  const tableProps = useMemo(() => {
    return { role: "" };
  }, []);

  const theadTrProps = useMemo(() => {
    return {
      "aria-hidden": "true",
      role: "list",
      tabIndex: "-1",
      "aria-label": "Device Sorting Options",
    };
  }, []);

  const a11yDeviceCheckout = useCallback(
    (e) => {
      if (
        e.keyCode === 13 &&
        (!e.target.dataset.username ||
          e.target.dataset.username === "Unassigned") &&
        e.target.classList.contains("rt-tr")
      ) {
        openHomeModal(e.target.dataset.id);
      } else if (e.keyCode === 68) {
        const elem = e.target;
        setA11yDetails(true);
        setTimeout(() => {
          if (elem?.firstChild) {
            elem.firstChild.focus();
          }
        }, 100);
      }
    },
    [openHomeModal]
  );

  const a11yDeviceFocus = useCallback((e) => {
    if (!e.target.classList.contains("rt-td")) {
      setA11yDetails(false);
    }
  }, []);

  const onSortedChange = useCallback(
    (newSorted) => {
      // update sorted controlled state
      setSorted(newSorted);

      // update url search params "sort" and "order"
      const nextQuery = { ...q };
      if (newSorted.length > 0) {
        nextQuery.sort = newSorted[0].id;

        if (newSorted[0].desc) {
          nextQuery.order = "desc";
        } else {
          delete nextQuery.order;
        }
      }

      const nextQueryString = queryString.stringify(nextQuery, {
        arrayFormat: "bracket",
      });
      window.history.replaceState({}, "", `?${nextQueryString}`);
    },
    [q]
  );

  const initializeSorted = useCallback(() => {
    setSorted([
      {
        id: SORT_KEYS.ID,
        desc: true,
      },
    ]);
  }, [SORT_KEYS.ID]);

  useEffect(() => {
    // scroll table to top on update
    const restoreScroll = () => {
      const scrollElement = document.querySelector(
        `.${SCROLL_RESTORE_CLASSNAME} ${SCROLL_ELEMENT_CLASS_IDENTIFIERS}`
      );
      if (scrollElement) {
        scrollElement.scrollTop = 0;
      }
    };

    const didUpdate = JSON.stringify(list) !== JSON.stringify(previousList);
    if (didUpdate) {
      restoreScroll();
    }
  }, [list, previousList]);

  useEffect(() => {
    // initialize sorted state on mount
    initializeSorted();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // account for Clear Filters button wiping out search params, update
    // controlled sorted state to default when that happens
    const { sort, order } = queryString.parse(search);

    if (!sort && !order) {
      initializeSorted();
    }
  }, [search, initializeSorted]);

  return (
    <StyledReactTable
      minRows={4}
      resizable={false}
      data={list}
      columns={columns}
      showPagination={false}
      manual
      sorted={sorted}
      onSortedChange={onSortedChange}
      expanded={{}} // useless prop but the table will break if it isn't defined
      activeDevice={
        activeDevice == null ? -1 : list.findIndex((x) => x.id === activeDevice)
      }
      className={`-striped -highlight ${SCROLL_RESTORE_CLASSNAME}`}
      style={{
        height: !isClientUser ? "50vh" : "70vh",
      }}
      getTableProps={() => tableProps}
      getTheadTrProps={() => theadTrProps}
      getTheadThProps={(state, row, column) => {
        return {
          role: "button",
          "aria-live": "polite",
          "aria-label":
            state.sorted.length > 0 && column.id === state.sorted[0].id
              ? `${column.Header}, active ${
                  state.sorted[0].desc ? "descending" : "ascending"
                }`
              : column.Header,
        };
      }}
      getTrProps={(state, rowInfo) => {
        if (
          rowInfo &&
          rowInfo.row &&
          activeChartDevice !== 0 &&
          rowInfo.original.id === activeChartDevice
        ) {
          return {
            style: { boxShadow: "0px 0px 0px 2px #519acc inset" },
          };
        }
        if (rowInfo && rowInfo.original) {
          return {
            role: a11yDetails ? "list" : "row",
            tabIndex: "-1",
            "data-username": rowInfo.original.user.name,
            "data-id": rowInfo.original.id,
            "aria-label": a11yDetails
              ? "1 of "
              : `${rowInfo.original.device_name}, 
             ${rowInfo.original.device_os},
             ${
               rowInfo.original.user.name &&
               rowInfo.original.user.name !== "Unassigned"
                 ? `checked out to ${rowInfo.original.user.name}`
                 : "available"
             },
             ${rowInfo.viewIndex + 1} of ${rowInfo.pageSize}. 
             ${
               rowInfo.original.user.name &&
               rowInfo.original.user.name !== "Unassigned"
                 ? "Press D for more device details"
                 : "Press enter to check out device or press D for more device details"
             }`,
            onKeyDown: a11yDeviceCheckout,
            onFocus: a11yDeviceFocus,
          };
        }
        return {};
      }}
      getTdProps={(state, rowInfo, column) => {
        let ariaLabel = "";
        if (column.Header === "Status") {
          ariaLabel = `${column.Header}, ${rowInfo.row[column.id]}`;
        } else if (rowInfo.original?.user.name !== "Unassigned") {
          if (currentUser.name === rowInfo.original.user.name) {
            ariaLabel = "Status, Checked out to you";
          } else {
            ariaLabel = `Status, Checked out to ${rowInfo.original.user.name}. Press M to ping ${rowInfo.original.user.name} on slack, or press N to recieve slack notification when device is available`;
          }
        } else {
          ariaLabel = "Status, Available";
        }
        return {
          role: "note",
          "data-header": column.Header,
          "data-id": rowInfo.original.id,
          "data-user-id": rowInfo.original.user.id,
          "data-user-name": rowInfo.original.user.name,
          "data-device-name": rowInfo.original.device_name,
          tabIndex: "-1",
          "aria-label": ariaLabel,
          "aria-hidden": a11yDetails ? "false" : "true",
        };
      }}
    />
  );
};

const StyledReactTable = styled(ReactTable)`
  min-height: 400px;

  .ReactVirtualized__Grid__innerScrollContainer {
    // giving inner scroll a min-height, otherwise its overflow: hidden property causes
    // the check in device popover to be cut off if there's only one row in the table.
    min-height: 143px;
  }

  ${SCROLL_ELEMENT_CLASS_IDENTIFIERS} {
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    color: #519bcc;
  }

  ::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #519bcc;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #519bcc;
  }
`;

export default DeviceLabTable;
