import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import styled from "styled-components";
import ProgressRing from "../common/progress_ring.jsx";
import ReactModalV2 from "../modals/react_modal_v2";
import AccountBadges from "./account_badges.jsx";

const AccountDiv = styled.div`
  p:not(.small-title) {
    line-height: 15px;
    padding-top: 10px;
    color: #9e9e9e;
    margin-bottom: 6px;
  }
`;

class AccountComponent extends React.Component {
  constructor(props) {
    const expanded = sessionStorage.getItem("expanded");
    super(props);
    this.state = {
      user: this.props.user,
      nameInput: this.props.user.name != null ? this.props.user.name : "",
      emailInput: this.props.user.email != null ? this.props.user.email : "",
      factInput: this.props.user.fact != null ? this.props.user.fact : "",
      titleInput: this.props.user.title != null ? this.props.user.title : "",
      languagesInput:
        this.props.user.languages != null
          ? this.props.user.languages.join(", ")
          : "",
      slackIdInput:
        this.props.user.slack_id != null ? this.props.user.slack_id : "",
      employee_started_at: moment(this.props.user.employee_started_at).format(
        "YYYY-MM-DD"
      ),
      selectAllState: false,
      loadingImage: false,
      modalOpen: false,
      submittable: false,
      currentModal: "",
      modalData: {},
      percentCompleted: 0,
      project: this.props.project,
    };
    this.file = React.createRef();
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  };

  slackIdInfoModal = () => {
    this.setModal(true, "information-modal", {
      videoLink:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/help/SlackID_Compressed.gif_converted.mp4",
      title: "How to find slack user ID",
      customClass: "new-ticket-modal",
    });
  };

  twoFAModal = () => {
    this.setModal(true, "two-fa", {
      title: "Add Multi Factor Authentication",
      currentUser: this.state.user,
      handleOtp: this.handleOtp,
      handleEmailOtp: this.handleEmailOtp,
    });
  };

  // setSidebarWidth = () => {
  //   var width = this.state.sideBarWidth == 250 ? 100 : 250;
  //   this.setState({ sideBarWidth: width, expanded: !this.state.expanded });
  //   sessionStorage.setItem("expanded", !this.state.expanded);
  // }

  handleFileDragEnter = () => {
    if (
      $(".avatar-background").css("background-color") == "rgb(211, 211, 211)"
    ) {
      $(".avatar-background").css("background-color", "#519acc");
    }
  };

  handleFileDragLeaveOrDrop = (e) => {
    if (
      e.type === "drop" ||
      e.target.parentElement.classList.contains("avatar-background")
    ) {
      $(".avatar-background").css("background-color", "rgb(211, 211, 211)");
    }
  };

  componentDidMount() {
    const url = new URL(location.href);
    const slackModal = url.searchParams.get("slack_modal");
    if (slackModal == "true") {
      const elem = document.getElementById("slack-info-icon");
      elem.click();
    }
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    const propName =
      name == "slackInput" ? "slack_id" : `${name.replace("Input", "")}`;
    if (propName == "employee_started_at") {
      const dateValue = moment(value).format("YYYY-MM-DD");
      if (
        (!moment(dateValue).isSame(this.props.user.employee_started_at), "day")
      ) {
        if (!target.previousSibling.classList.value.includes("changed-value")) {
          target.previousSibling.classList += "changed-value";
        } else {
          target.previousSibling.classList.remove("changed-value");
        }
      }
    } else if (value != this.props.user[propName]) {
      if (!target.previousSibling.classList.value.includes("changed-value")) {
        target.previousSibling.classList += "changed-value";
      }
    } else {
      target.previousSibling.classList.remove("changed-value");
    }
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.submittable != true &&
        this.props.user.email != prevProps.user.email) ||
      (prevProps.submittable != true &&
        this.props.user.slack_id != prevProps.user.slack_id) ||
      (prevProps.submittable != true &&
        this.props.user.name != prevProps.user.name) ||
      (prevProps.submittable != true &&
        this.props.user.fact != prevProps.user.fact) ||
      (prevProps.submittable != true &&
        this.props.user.title != prevProps.user.title) ||
      (prevProps.submittable != true &&
        this.props.user.languages != prevProps.user.languages) ||
      (prevProps.submittable != true &&
        moment(this.props.user.employee_started_at).format("YYYY-MM-DD") !=
          moment(prevProps.user.employee_started_at).format("YYYY-MM-DD"))
    ) {
      this.setState({
        user: this.props.user,
        nameInput: this.props.user.name != null ? this.props.user.name : "",
        emailInput: this.props.user.email != null ? this.props.user.email : "",
        factInput: this.props.user.fact != null ? this.props.user.fact : "",
        languagesInput:
          this.props.user.languages != null
            ? this.props.user.languages.join(", ")
            : "",
        slackInput:
          this.props.user.slack_id != null ? this.props.user.slack_id : "",
        employee_start: moment(this.props.user.employee_started_at).format(
          "YYYY-MM-DD"
        ),
        titleInput: this.props.user.title != null ? this.props.user.title : "",
      });
      const elems = document.querySelectorAll(".changed-value");
      for (let i = 0; i < elems.length; i++) {
        elems[i].classList.remove("changed-value");
      }
    }
    if (
      (this.state.submittable != true &&
        this.props.user.email != this.state.emailInput) ||
      (this.state.submittable != true &&
        this.props.user.slack_id != this.state.slackIdInput) ||
      (this.state.submittable != true &&
        this.props.user.name != this.state.nameInput) ||
      (this.state.submittable != true &&
        this.props.user.fact != this.state.factInput) ||
      (this.state.submittable != true &&
        this.props.user.title != this.state.titleInput) ||
      (this.state.submittable != true &&
        this.props.user.languages.join(", ") != this.state.languagesInput) ||
      (this.state.submittable != true &&
        moment(this.props.user.employee_started_at).format("YYYY-MM-DD") !=
          this.state.employee_started_at)
    ) {
      this.setState({ submittable: true });
    }
    if (
      prevState.submittable == true &&
      this.props.user.email == this.state.emailInput &&
      this.props.user.slack_id == this.state.slackIdInput &&
      this.props.user.name == this.state.nameInput &&
      this.props.user.fact == this.state.factInput &&
      this.props.user.title == this.state.titleInput &&
      this.props.user.languages.join(", ") == this.state.languagesInput &&
      moment(this.props.user.employee_started_at).format("YYYY-MM-DD") ==
        this.state.employee_started_at
    ) {
      this.setState({ submittable: false });
    }
  }

  formSubmittable = () => {
    if (
      this.props.user.email != this.state.emailInput ||
      this.props.user.slack_id != this.state.slackIdInput ||
      this.props.user.name != this.state.nameInput ||
      this.props.user.fact != this.state.factInput ||
      this.props.user.title != this.state.titleInput ||
      this.props.user.languages.join(", ") != this.state.languagesInput ||
      moment(this.props.user.employee_started_at).format("YYYY-MM-DD") !=
        this.state.employee_started_at
    ) {
      return true;
    }
    return false;
  };

  submitEditUser = (formData) => {
    const object = this.props;
    axios
      .patch(`/users/${object.user.id}.json`, formData, {})
      .then((res) => {
        if (res.status === 200) {
          const elem = document.querySelector(
            "#navbar > ul.right > li:nth-child(2) > a"
          );
          if (elem) {
            $("#navbar > ul.right > li:nth-child(2) > a").html(
              `${res.data.name}<i class='material-icons right'>arrow_drop_down</i>`
            );
          }
          object.userHandler(res.data);
          // eslint-disable-next-line no-undef
          Swal.fire({
            title: "Edit About Me",
            text: "Your About Me changes were saved.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then(() => {
            if (res.data.email != object.user.email) {
              // eslint-disable-next-line no-undef
              M.toast({
                html: "Please check your email inbox for a confirmation link",
                displayLength: 5000,
                classes: "green",
              });
            }
          });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: "Edit User Error",
          text: err.response.data.error,
          confirmButtonColor: "#519acc",
          confirmButtonText: "OK",
        });
      });
  };

  handleDeleteAvatar = () => {
    const self = this;
    // eslint-disable-next-line no-undef
    Swal.fire({
      title: "Device User Image",
      text: "Are you sure that you want to delete this Image?",
      reverseButtons: true,
      width: "300px",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        self.deleteAvatar();
      } else if (result.dismiss) {
      }
    });
  };

  deleteAvatar = () => {
    this.setState({ loadingImage: true });
    const object = this.props;
    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        self.setState({ percentCompleted });
      },
    };
    axios
      .patch(`/users/${object.user.id}/delete_avatar.json`, null, config)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ loadingImage: false, avatar_image: "" });
          this.changeSidebarImage(
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
          );
          // eslint-disable-next-line no-undef
          Swal.fire({
            title: "User",
            text: "User Image Deleted",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingImage: false });
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: "Avatar Delete Error",
          text: err.response.data.error,
          confirmButtonColor: "#519acc",
          confirmButtonText: "OK",
        });
      });
  };

  changeSidebarImage = (src) => {
    const elem = document.getElementById("sideBarUserImg");
    elem.src = src;
  };

  addAvatar = () => {
    let fileExtension;
    if (document.getElementById("avatar-file")) {
      fileExtension = document
        .getElementById("avatar-file")
        .value.split(".")
        .pop()
        .toLowerCase();
    } else {
      document.getElementById("avatar-btn-file");
      fileExtension = document
        .getElementById("avatar-btn-file")
        .value.split(".")
        .pop()
        .toLowerCase();
    }
    const allowedExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
    if (!allowedExtensions.includes(fileExtension)) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "Invalid File Type",
        classes: "red darken-1 error-toast",
      });
      this.refs.file.value = "";
      return;
    }
    this.setState({ loadingImage: true });
    const self = this;
    const object = this.props;
    const formData = new FormData();
    if (this.file.current) {
      formData.append("user[avatar]", this.file.current.files[0]);
    }
    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        self.setState({ percentCompleted });
      },
    };
    axios
      .patch(`/users/${object.user.id}.json`, formData, config)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            loadingImage: false,
            avatar_image: res.data.avatar_image,
          });
          this.changeSidebarImage(res.data.avatar_image);
          // eslint-disable-next-line no-undef
          Swal.fire({
            title: "User Image",
            text: "User Image Updated",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingImage: false });
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: "Add User Image Error",
          text: err.response.data.error,
          confirmButtonColor: "#519acc",
          confirmButtonText: "OK",
        });
      });
  };

  handleImageResize = (e) => {
    const image = e.target;
    if (image.clientHeight - image.clientWidth >= 0) {
      image.classList.add("tall-attachment-image");
    } else {
      image.classList.add("wide-attachment-image");
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const name = this.state.nameInput;
    const email = this.state.emailInput;
    const started_at = this.state.employee_started_at;
    const fact = this.state.factInput;
    const slackId = this.state.slackIdInput;
    const title = this.state.titleInput;
    const languages = this.state.languagesInput;
    const formData = new FormData();
    if (name != this.state.user.name) {
      formData.append("user[name]", name);
    }
    if (email != this.state.user.email) {
      formData.append("user[email]", email);
    }
    if (started_at != this.state.user.employee_started_at) {
      formData.append("user[employee_started_at]", started_at);
    }
    if (fact != this.state.user.fact) {
      formData.append("user[fact]", fact);
    }
    if (slackId != this.state.user.slack_id) {
      formData.append("user[slack_id]", slackId);
    }
    if (title != this.state.user.title) {
      formData.append("user[title]", title);
    }
    if (languages != this.state.user.languages.join(", ")) {
      const languagesArr = languages.split(",");
      for (let x = 0; x < languagesArr.length; x++) {
        formData.append("user[languages][]", languagesArr[x].trim());
      }
    }

    this.submitEditUser(formData);
  };

  updateAllMemberships = () => {
    const switchState = this.state.selectAllState;
    const { id } = this.props.user;
    const self = this;
    axios
      .patch(
        `/update_all_memberships/${id}.json`,
        { toggle: switchState },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          },
        }
      )
      .then((res) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Notification Preference Updated",
          displayLength: 3000,
          classes: "green",
        });
        const { user } = self.state;
        user.memberships = res.data.memberships;
        this.setState({ user, selectAllState: !switchState });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        Swal("There was an error editing your notification preferences");
        console.log(err);
      });
  };

  isDayBlocked = (day) => {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  };

  handleDateChange = (e) => {
    this.setState({ employee_started_at: e.target.value });
  };

  resetLocation = () => {
    location.href = "/logout";
  };

  resetUserPassword = () => {
    const object = this.props;
    // eslint-disable-next-line no-undef
    Swal.fire({
      title: "Reset Password",
      text: "This will send an email with a form to change your Password. Are you sure you want to change your Password?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        // Send ajax call to delete application choosen
        $.ajax({
          url: `/users/${object.user.id}/password_reset.json`,
          dataType: "json",
          type: "GET",
          cache: false,
          beforeSend(xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          success() {
            // eslint-disable-next-line no-undef
            Swal.fire({
              title: "Reset",
              text: "User Password Reset",
              confirmButtonText: "Ok",
            });
          },
          error(data) {
            // If error present error swal message
            console.log(data.responseText);
            // eslint-disable-next-line no-undef
            Swal.fire({
              title: "User Error",
              text: "There was a problem with that request",
              customClass: "swal2-error-modal",
            });
          },
        }).done(() => {
          // eslint-disable-next-line no-undef
          Swal("Reset!", "Password reset email sent!", "success");
          // Removes the application from the front end
        });
      } else if (result.dismiss) {
        console.log("dismissed");
      }
    });
  };

  toggle2fa = () => {
    let toggle;
    const self = this;
    if (self.state.user.otp_module_enabled) {
      toggle = "enable";
    } else {
      toggle = "disable";
    }
    axios
      .post(
        `users/${self.props.user.id}/${toggle}_multi_factor_authentication`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          },
        }
      )
      .then((res) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Two Factor Auth ${toggle}d`,
          displayLength: 3000,
          classes: "green",
        });
        const { user } = self.props;
        this.setState({ user });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "There was an error changing your 2fa settings",
          displayLength: 3000,
          classes: "red",
        });
        console.log(err);
      });
  };

  handleOtp = (otpStatus) => {
    const { user } = this.state;
    user.otp_enabled = otpStatus;
    this.setState({ user });
  };

  handleEmailOtp = (otpStatus) => {
    const { user } = this.state;
    user.email_otp_enabled = otpStatus;
    this.setState({ user });
  };

  updateMembership = () => {
    const id = event.target.parentElement.children[0].value;
    const { checked } = event.target;
    const self = this;
    axios
      .patch(`/memberships/${id}.json`, {
        membership: { email_notifications: checked },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Notification Preference Updated",
          displayLength: 3000,
          classes: "green",
        });
        const { user } = self.props;
        const { membership } = res.data;
        const { memberships } = user;
        for (let i = 0; i < memberships.length; i++) {
          if (memberships[i].id == membership.id) {
            memberships.splice(i, 1, membership);
            break;
          }
        }
        user.memberships = memberships;
        this.setState({ user });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        Swal("There was an error editing the watchers for this ticket");
        console.log(err);
      });
  };

  render() {
    const { submittable } = this.state;
    const projects = [];
    for (let i = 0; i < this.state.user.memberships.length; i++) {
      const membership = this.state.user.memberships[i];
      const checked = membership.email_notifications;
      projects.push(
        <div className="notification-project" key={membership.id}>
          <span>
            {membership.project_name != undefined
              ? membership.project_name
              : "moo"}
          </span>
          <div className="switch right">
            <label>
              Off
              <input type="hidden" value={membership.id} />
              <input
                type="checkbox"
                key={membership.id}
                checked={checked}
                onChange={this.updateMembership}
              />
              <span className="lever" />
              On
            </label>
          </div>
        </div>
      );
    }
    if (this.state.user.memberships.length >= 2) {
      projects.push(
        <div key="last">
          <span>Select All</span>
          <div className="switch right">
            <label>
              Off
              <input
                type="checkbox"
                key="last"
                checked={this.state.selectAllState}
                onChange={this.updateAllMemberships}
              />
              <span className="lever" />
              On
            </label>
          </div>
        </div>
      );
    }
    return (
      <div>
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
          customClass="new-ticket-modal"
        />
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100 + 35}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: "25px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form
              style={{ display: "grid", gridTemplateColumns: "33% 33% 33%" }}
              onSubmit={this.handleSubmit}
            >
              <AccountDiv style={{ maxHeight: "622px" }}>
                <div>
                  <p className="bold small-title">About Me</p>
                  <p
                    style={{
                      lineHeight: "15px",
                      paddingTop: "10px",
                      color: "#9e9e9e",
                      marginBottom: "6px",
                    }}
                  >
                    Name
                  </p>
                  <input
                    type="text"
                    className="form-control account-inputs"
                    name="nameInput"
                    id="user[name]"
                    value={this.state.nameInput}
                    placeholder="Edit User Name"
                    onChange={this.handleChange}
                  />
                  {this.props.user.role != "client" && (
                    <>
                      <p>When did you start at Plus QA?</p>
                      <input
                        type="date"
                        id="employee_start"
                        name="employee_started_at"
                        value={moment(this.state.employee_started_at).format(
                          "YYYY-MM-DD"
                        )}
                        min="2008-01-01"
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={this.handleChange}
                      />
                      <p>Location</p>
                      <input
                        type="text"
                        className="form-control account-inputs"
                        name="factInput"
                        id="user[fact]"
                        value={this.state.factInput}
                        placeholder="Edit Location"
                        onChange={this.handleChange}
                      />
                      <p>Languages Spoken (separated by commas)</p>
                      <input
                        type="text"
                        className="form-control account-inputs"
                        name="languagesInput"
                        id="user[languages]"
                        value={this.state.languagesInput}
                        placeholder=""
                        onChange={this.handleChange}
                      />
                    </>
                  )}
                  <p>Title</p>
                  <input
                    type="text"
                    className="form-control account-inputs"
                    name="titleInput"
                    id="user[title]"
                    value={this.state.titleInput}
                    placeholder="Edit Title"
                    onChange={this.handleChange}
                  />
                  <p>Email</p>
                  <input
                    type="email"
                    className="form-control account-inputs"
                    name="emailInput"
                    id="user[email]"
                    value={this.state.emailInput}
                    placeholder="User Email"
                    onChange={this.handleChange}
                  />
                  {this.props.user.role != "client" && (
                    <>
                      <p style={{ lineHeight: `${18}px` }}>
                        Slack ID
                        <img
                          id="slack-info-icon"
                          alt="slack info icon"
                          onClick={this.slackIdInfoModal}
                          style={{
                            cursor: "pointer",
                            opacity: "0.55",
                            height: "20px",
                            marginLeft: "3px",
                            width: "20px",
                            verticalAlign: "sub",
                          }}
                          src="https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/help.svg"
                        />
                      </p>
                      <input
                        maxLength={12}
                        type="text"
                        className="form-control account-inputs"
                        name="slackIdInput"
                        id="user[slackId]"
                        value={this.state.slackIdInput}
                        placeholder="User Slack Id"
                        onChange={this.handleChange}
                      />
                    </>
                  )}
                  <button
                    style={{ marginTop: "10px", marginBottom: "15px" }}
                    className="btn"
                    disabled={!submittable}
                  >
                    Submit
                  </button>
                </div>
              </AccountDiv>
              <div>
                <div
                  onDragEnter={this.handleFileDragEnter}
                  onDragLeave={this.handleFileDragLeaveOrDrop}
                  onDrop={this.handleFileDragLeaveOrDrop}
                  className="avatar-background hoverable"
                  id="avatar-img"
                  style={{
                    width: "250px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    onLoad={this.handleImageResize}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                      mouseEvents: "none",
                    }}
                    src={this.state.avatar_image}
                    alt="user avatar"
                  />
                  <input
                    onChange={this.addAvatar}
                    id="avatar-btn-file"
                    type="file"
                    accept=".jpg,.gif,.png,.svg"
                    ref={this.file}
                  />
                  {this.state.loadingImage && (
                    <ProgressRing
                      radius={125}
                      stroke={5}
                      progress={this.state.percentCompleted}
                    />
                  )}
                </div>
              </div>
              <div style={{}}>
                <div className="new-scrollbar" id="userProjectContainer">
                  <p className="small-title">Email Notifications?</p>
                  {projects}
                </div>
              </div>
            </form>
          </div>
          <div style={{ width: "100%", paddingLeft: "10px" }}>
            <div style={{ float: "left" }}>
              <button
                type="button"
                className="btn-overflow common-button-cancel"
                onClick={this.resetUserPassword}
              >
                Change Password
              </button>
            </div>
            <div style={{ float: "left", marginLeft: "10px" }}>
              <button
                type="button"
                className="btn-overflow common-button-cancel"
                onClick={this.twoFAModal}
              >
                {`${
                  this.state.user.otp_enabled ||
                  this.state.user.otp_email_enabled
                    ? "Disable"
                    : "Enable"
                } Two Factor Auth`}
              </button>
            </div>
            <div style={{ float: "right", marginRight: "calc(.75rem + 8px)" }}>
              <button
                onClick={this.resetLocation}
                type="button"
                className="btn"
                href="/logout"
              >
                Sign Out
              </button>
            </div>
          </div>
          {this.state.user.role != "client" && (
            <AccountBadges
              achievements={this.props.achievements}
              user={this.state.user}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AccountComponent;

AccountComponent.propTypes = {
  submittable: PropTypes.bool,
  userHandler: PropTypes.any,
  project: PropTypes.object,
  user: PropTypes.object,
  achievements: PropTypes.array,
};
