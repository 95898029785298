import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { dropdownIndicator, customControl } from "../common/select_utils";
import SingleSelectStyles from "../common/select_styles";

class InviteUserForm extends React.Component {
  constructor(props) {
    super(props);
    var options = this.props.projects.map((x) => ({
      value: x.application_id,
      label: x.name,
    }));
    this.state = {
      projectOptions: options,
      emailInput: "",
      loading: false,
      selectedOptionProject: [],
      invitedUsers: [],
    };
  }

  handleEmailChange = (e) => {
    var value = e.target.value;
    this.setState({ emailInput: value ? value : "" });
  };

  handleEmailKeydown = (e) => {
    var elem = e.target;
    if (e.keyCode === 13 && this.state.emailInput && !this.state.loading) {
      if (!this.state.selectedOptionProject.value) {
        M.toast({
          html: `Select a project`,
          displayLength: 3000,
          classes: "red",
        });
        return null;
      }
      if (
        this.state.invitedUsers
          .map((u) => u.email)
          .indexOf(this.state.emailInput) !== -1
      ) {
        M.toast({
          html: `You have already added this email address`,
          displayLength: 3000,
          classes: "red",
        });
        return null;
      }
      if (this.emailCheck(this.state.emailInput)) {
        this.setState({ loading: true });
        axios
          .get(`/v1/users/email_check?email=${this.state.emailInput}`)
          .then(() => {
            var invitedUsers = this.state.invitedUsers;
            invitedUsers.push({
              email: this.state.emailInput,
              role: {
                value: "trialist",
                label: "Trialist",
                index: invitedUsers.length,
              },
              project: this.state.selectedOptionProject,
            });
            this.setState({ emailInput: "", invitedUsers, loading: false });
            elem.value = "";
          })
          .catch((err) => {
            if (err.response.data === "duplicate") {
              M.toast({
                html: `This email is already registered with Test Platform`,
                displayLength: 3000,
                classes: "red",
              });
            } else {
              console.error(err);
            }
            this.setState({ loading: false });
          });
      } else {
        M.toast({
          html: `Invalid email address`,
          displayLength: 3000,
          classes: "red",
        });
      }
    }
  };

  emailCheck = (email) => {
    const regexp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  };

  sendInvites = () => {
    this.setState({ loading: true });
    axios
      .post("/v1/invite_new_users", {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        user: {
          user_array: this.state.invitedUsers.map((u) => ({
            project_id: u.project.value,
            role: u.role.value,
            email: u.email,
          })),
        },
      })
      .then((res) => {
        res.data.forEach((u) => {
          this.props.userHandler(u);
        });
        this.setState({ loading: false });
        Swal.fire({
          text: `Invitation${
            this.state.invitedUsers.length > 1 ? "s" : ""
          } sent`,
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(() => {
          this.props.closeModal();
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: "Error",
          text: `${xhr.responseText}`,
          confirmButtonColor: "#519acc",
          confirmButtonText: "OK",
        });
        console.error(err);
      });
  };

  handleSelectChangeProject = (selectedOption) => {
    this.setState({ selectedOptionProject: selectedOption });
  };

  handleRoleChange = (option) => {
    var invitedUsers = this.state.invitedUsers.map((u, i) =>
      option.index === i ? { ...u, role: option } : u
    );
    this.setState({ invitedUsers });
  };

  render() {
    var disabled = this.state.invitedUsers.length === 0;
    return (
      <section
        id="inviteUserForm"
        className="flex-container flex-column"
        style={{ alignItems: "center", padding: "10px 20px 0" }}
      >
        <div
          className={`modal-backdrop-loader ${
            this.state.loading
              ? "modal-backdrop-loader-open"
              : "modal-backdrop-loader-close"
          }`}
        >
          {this.state.loading && <div id="test-step-bugs-loader"></div>}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90px",
            width: "90px",
            borderRadius: "50%",
            padding: "4px",
            boxShadow: "0px 1px 3px #c3c3c3",
          }}
          aria-hidden="true"
        >
          <img
            style={{ position: "relative", bottom: "2px" }}
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/Tess_Head.svg"
          />
        </div>
        <h3
          style={{ fontFamily: "Manrope", fontSize: "21px", marginTop: "30px" }}
        >
          Invite a new user to Test Platform
        </h3>
        <p
          style={{
            fontFamily: "Arial",
            fontSize: "17px",
            textAlign: "center",
            lineHeight: "32px",
            marginTop: "20px",
            marginBottom: "18px",
          }}
        >
          Create a new invitation user by providing a unique email address and
          selecting a project and a role.
        </p>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Select
            aria-label="Project dropdown for invite user form"
            styles={{
              ...SingleSelectStyles,
              container: (styles) => {
                return { ...styles, width: "auto", flex: "1" };
              },
              menuList: (styles) => {
                return { ...styles, maxHeight: "275px" };
              },
            }}
            components={{
              DropdownIndicator: dropdownIndicator,
              Control: customControl,
            }}
            appendToBody
            blurInputOnSelect
            name="user-project"
            placeholder="Select project for user"
            value={this.state.selectedOptionProject}
            onChange={this.handleSelectChangeProject}
            options={this.state.projectOptions}
            menuPlacement="top"
          />
          <input
            onChange={this.handleEmailChange}
            onKeyDown={this.handleEmailKeydown}
            type="text"
            placeholder="Type an email address and press enter"
            className="common-input browser-default"
            style={{ marginLeft: "12px", width: "290px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            lineHeight: "3px",
          }}
        >
          <span style={{ paddingRight: "18px" }}>INVITATION USERS</span>
          <span
            style={{
              flex: "1",
              height: "0px",
              borderBottom: "solid 1px lightgray",
            }}
          />
        </div>
        <ul style={{ width: "100%" }}>
          {this.state.invitedUsers.length > 0 ? (
            this.state.invitedUsers.map((u, i) => {
              return (
                <li
                  key={i}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "20px 0",
                    borderBottom: "solid 1px lightgray",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "16px" }}>{u.email}</span>
                    <span style={{ color: "#a7a7a7" }}>{u.project.label}</span>
                  </div>
                  <Select
                    aria-label="Role dropdown for invite user form"
                    styles={{
                      ...SingleSelectStyles,
                      container: (styles) => {
                        return { ...styles, width: "150px" };
                      },
                      menuList: (styles) => {
                        return { ...styles, width: "150px" };
                      },
                      menu: (styles) => {
                        return { ...styles, width: "150px" };
                      },
                    }}
                    components={{
                      DropdownIndicator: dropdownIndicator,
                      Control: customControl,
                    }}
                    menuPlacement="top"
                    appendToBody={true}
                    blurInputOnSelect={true}
                    value={this.state.invitedUsers[i].role}
                    onChange={this.handleRoleChange}
                    options={[
                      { value: "trialist", label: "Trialist", index: i },
                      { value: "client", label: "Client", index: i },
                      { value: "tester", label: "Tester", index: i },
                      { value: "lead", label: "Project Lead", index: i },
                      { value: "admin", label: "Admin", index: i },
                    ]}
                  />
                </li>
              );
            })
          ) : (
            <p
              style={{
                textAlign: "center",
                margin: "20px 0 0",
                color: "#d4d4d4",
              }}
            >
              No invitation users added.
            </p>
          )}
        </ul>
        <button
          style={{ margin: "40px auto 35px", height: "44px", width: "200px" }}
          disabled={disabled}
          className="btn"
          id="build-submit-button"
          onClick={this.sendInvites}
        >
          Invite
        </button>
        <p style={{ marginBottom: "0" }}>
          Users will receive an email to confirm their Test Platform account.
        </p>
      </section>
    );
  }
}

export default InviteUserForm;
