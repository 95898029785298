import React from "react";
import ReactModalV2 from "../modals/react_modal_v2";
import axios from "axios";
import Swal from "sweetalert2";
import AddIntegration from "../modals/add_integration";

const modals = {
  "add-integration": AddIntegration,
};

class IntegrationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      application: this.props.application,
      currentProjectId: this.props.application.project_id,
      integrations: this.props.integrations,
      modalOpen: false,
      currentModal: "",
      modalData: {},
    };
  }

  dropdownHandler = (id) => {
    var projectId = id;
    window.history.pushState(
      "page2",
      "Test Platform",
      "/projects/" + projectId + "/integrations"
    );
    if (projectId !== this.state.currentProjectId) {
      axios
        .get(`/api/v1/applications/${projectId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.setState(
            {
              currentProjectId: projectId,
              application: res.data,
            },
            function () {
              this.refreshIntegrations();
            }
          );
        })
        .catch((err) => console.error(err));
    }
  };

  refreshIntegrations = () => {
    axios
      .get(`/api/v1/integrations/${this.state.currentProjectId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          integrations: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  handleSlackClick = () => {
    this.setModal(true, "add-integration", {
      type: "slack",
      application: this.state.application,
      refreshIntegrations: this.refreshIntegrations,
    });
  };

  handleEmailClick = () => {
    this.setModal(true, "add-integration", {
      type: "email",
      application: this.state.application,
      refreshIntegrations: this.refreshIntegrations,
    });
  };

  handleAndroidClick = () => {
    this.setModal(true, "add-integration", {
      type: "android",
      application: this.state.application,
      refreshIntegrations: this.refreshIntegrations,
    });
  };

  handleXcodeClick = () => {
    this.setModal(true, "add-integration", {
      type: "xcode",
      application: this.state.application,
      refreshIntegrations: this.refreshIntegrations,
    });
  };

  deleteIntegration = (e) => {
    var self = this;
    var id = e.target.id;
    Swal.fire({
      title: "Delete Integration",
      text: " Are you sure that you want to delete this Integration?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
      reverseButtons: true,
      //ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/api/v1/integrations/${id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            self.refreshIntegrations();
          })
          .catch((err) => console.error(err));
      } else if (result.dismiss) {
        Swal.fire("Canceled");
      }
    });
  };

  render() {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "-9px" }}
        id="integrationsContainer"
      >
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div
          style={{ display: "flex", flexDirection: "column", padding: 0 }}
          className=""
          id="edit-integrations-container"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                padding: "0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className="left">
                Integrate Test Platform with your preferred developer tool and
                quickly share builds with your team by clicking on the
                appropriate icon(s) below.
              </p>
              <div className="card-panel no-box-shadow valign-wrapper">
                <div className="left">
                  <button
                    className="btn-floating btn-large login-buttons transparent hoverable"
                    style={{
                      zIndex: "0",
                      position: "initial",
                      borderColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={this.handleSlackClick}
                  >
                    <img
                      alt="slack icon"
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/slack.png"
                      width="60"
                      height="60"
                    />
                  </button>
                </div>
                <span>Slack</span>
              </div>
              <div className="card-panel no-box-shadow valign-wrapper">
                <div className="left">
                  <button
                    className="btn-floating btn-large login-buttons transparent hoverable"
                    style={{
                      zIndex: "0",
                      borderColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={this.handleAndroidClick}
                  >
                    <img
                      alt="android studio icon"
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/android-studio-logo.png"
                      width="60"
                      height="60"
                    />
                  </button>
                </div>
                <span>Android Studio</span>
              </div>
              <div className="card-panel no-box-shadow valign-wrapper">
                <div className="left">
                  <button
                    className="btn-floating btn-large login-buttons transparent hoverable"
                    style={{
                      zIndex: "0",
                      borderColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={this.handleXcodeClick}
                  >
                    <img
                      alt="xcode icon"
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/xcode_icon.png"
                      width="50"
                      height="50"
                    />
                  </button>
                </div>
                <span>Xcode</span>
              </div>
              <div className="card-panel no-box-shadow valign-wrapper">
                <div className="left">
                  <button
                    className="btn-floating btn-large login-buttons transparent hoverable"
                    style={{
                      zIndex: "0",
                      borderColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={this.handleEmailClick}
                  >
                    <img
                      alt="mail icon"
                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/mail-icon.png"
                      width="50"
                      height="50"
                    />
                  </button>
                </div>
                <span>Email</span>
              </div>
            </div>
          </div>
        </div>
        {this.state.integrations.length > 0 ? (
          <div className="integrationList">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Info</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.integrations.map((i, index) => {
                  return (
                    <tr key={index}>
                      <td>{i.name}</td>
                      <td>
                        {i.name === "slack"
                          ? i.channel + " @ " + i.webhook
                          : i.webhook}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn white-text"
                          id={i.id}
                          onClick={this.deleteIntegration}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default IntegrationsContainer;
