import React from "react";
import Gallery from "react-image-gallery";
import moment from "moment";
import "react-image-gallery/styles/css/image-gallery.css";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

class AttachmentCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreen: false,
    };
    this.renderPdf = this.renderPdf.bind(this);
    this.screenChange = this.screenChange.bind(this);
    this.renderThumbInner = this.renderThumbInner.bind(this);
    this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
    this.handleImageResize = this.handleImageResize.bind(this);
    this.handleAttachmentDownload = this.handleAttachmentDownload.bind(this);
    this.galleryRef = React.createRef();
  }

  renderPdf = (item) => {
    return (
      <div
        className="image-gallery-image"
        style={{ height: `${125}px`, backgroundColor: "#e0e0e0" }}
      >
        <div style={{ backgroundColor: "#e0e0e0", paddingTop: `${5}em` }}>
          <img
            alt="pdf file icon"
            style={{
              width: "unset",
              marginBottom: `${1}em`,
              paddingTop: `${1}em`,
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/pdf-icon.png"
          />
          {!this.props.newTicket ? (
            <a href={item.original} rel="noreferrer" target="_blank">
              {item.description}{" "}
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderZip = (item) => {
    return (
      <div
        className="image-gallery-image"
        style={{ height: `${125}px`, backgroundColor: "#e0e0e0" }}
      >
        <div style={{ backgroundColor: "#e0e0e0", paddingTop: `${5}em` }}>
          <img
            alt="zip file icon"
            style={{
              width: "unset",
              height: `${128}px`,
              marginBottom: `${1}em`,
              paddingTop: `${1}em`,
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/zip-icon.png"
          />
          {!this.props.newTicket ? (
            <a href={item.original} rel="noreferrer" target="_blank">
              {item.description}
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderTextFile = (item) => {
    return (
      <div
        className="image-gallery-image"
        style={{ height: `${125}px`, backgroundColor: "#e0e0e0" }}
      >
        <div style={{ backgroundColor: "#e0e0e0", paddingTop: `${5}em` }}>
          <img
            alt="text file icon"
            style={{
              width: "unset",
              height: "128px",
              marginBottom: "1em",
              paddingTop: `${1}em`,
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/file-icon.png"
          />
          {!this.props.newTicket ? (
            <a href={item.original} rel="noreferrer" target="_blank">
              {item.description}
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderVideo = (item) => {
    return (
      <div
        className="image-gallery-image"
        style={{ height: `${125}px`, backgroundColor: "#e0e0e0" }}
      >
        <div style={{ backgroundColor: "#e0e0e0" }}>
          <video
            preload="metadata"
            style={{
              width: "unset",
              marginBottom: `${1}em`,
              paddingTop: `${1}em`,
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            controls
          >
            {!this.props.newTicket ? (
              <source src={`${item.original}#t=0.5`} />
            ) : (
              ""
            )}
          </video>
        </div>
      </div>
    );
  };

  renderVideoPlaceholder = (item) => {
    const url = `${item.original}#t=0.5`;
    return (
      <div
        className="image-gallery-image"
        style={{ height: `${125}px`, backgroundColor: "#e0e0e0" }}
      >
        <div style={{ backgroundColor: "#e0e0e0" }}>
          <video
            preload="metadata"
            style={{
              width: "100%",
              height: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            <source src={url} />
          </video>
        </div>
      </div>
    );
  };

  screenChange() {
    this.setState({
      fullScreen: !this.state.fullScreen,
    });
  }

  handleAttachmentDownload(e) {
    const { id } = e.target.dataset;
    const item = this.props.images.find((x) => x.id === parseInt(id));
    const dl = document.createElement("a");
    dl.setAttribute("href", `${item.download_url}`);
    dl.setAttribute("download", `${item.description}`);
    dl.click();
    dl.remove();
  }

  renderThumbInner(item) {
    try {
      if (this.props.newTicket) {
        return (
          <div className="image-gallery-thumbnail-inner">
            <div
              className="attachment-overlay"
              style={{
                display: `${this.state.fullScreen ? "none" : "block"}`,
                cursor: "default",
              }}
            >
              <span className="attachment-title">{item.description}</span>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.handleAttachmentDelete(item.id);
                }}
                className="attachment-delete-button"
              >
                <i style={{ pointerEvents: "none" }} className="material-icons">
                  delete
                </i>
              </div>
            </div>
            {item && item.type && item.type.includes("video/") ? (
              <video
                preload="metadata"
                style={{
                  width: "100%",
                  height: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <source src={`${item.original}#t=0.5`} />
              </video>
            ) : (
              <img
                alt="ticket attachment"
                onLoad={this.handleImageResize}
                src={item.thumbnail}
              />
            )}
          </div>
        );
      }
      if (item.originalTitle == "fileInput") {
        const items = this.props.fileInput;
        return <div className="image-gallery-thumbnail-inner">{items}</div>;
      }
      if (
        item.download_url.toLowerCase().includes(".mov") ||
        item.download_url.toLowerCase().includes(".mp4")
      ) {
        var thumbnail = (
          <video
            preload="metadata"
            style={{
              width: "auto",
              height: "155px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            <source src={`${item.original}#t=0.5`} />
          </video>
        );
      } else {
        thumbnail = (
          <img
            alt="attachment"
            onLoad={this.handleImageResize}
            src={item.thumbnail}
          />
        );
      }
      return (
        <div className="image-gallery-thumbnail-inner">
          <div
            className="attachment-overlay"
            style={{
              display: `${this.state.fullScreen ? "none" : "block"}`,
              cursor: item.isImage ? "pointer" : "default",
            }}
          >
            <span className="attachment-title">{item.description}</span>
            <span
              style={{
                display: "block",
                position: "absolute",
                left: "10px",
                fontSize: "9pt",
                marginBottom: item.user_name ? "0px" : "4px",
                bottom: "8px",
                color: "white",
                textAlign: "left",
                lineHeight: "18px",
              }}
            >
              {item.time_stamp}
              <br />
              {item.user_name
                ? `by ${
                    item.user_name.length >= 11
                      ? `${item.user_name.substring(0, 10)}...`
                      : item.user_name
                  }`
                : ""}
            </span>
            <div
              data-id={item.id}
              onClick={this.handleAttachmentDownload}
              style={{
                cursor: "pointer",
                right: this.props.deleteAttachmentHandler ? "30px" : "6px",
                bottom: item.user_name ? "5px" : "0px",
              }}
              className="attachment-download-button"
            >
              <i style={{ pointerEvents: "none" }} className="material-icons">
                cloud_download
              </i>
            </div>
            {this.props.deleteAttachmentHandler && (
              <div
                onClick={() => {
                  this.handleAttachmentDelete(item.id);
                }}
                style={{
                  cursor: "pointer",
                  bottom: item.user_name ? "5px" : "0px",
                }}
                className="attachment-delete-button"
              >
                <i style={{ pointerEvents: "none" }} className="material-icons">
                  delete
                </i>
              </div>
            )}
          </div>
          {thumbnail}
        </div>
      );
    } catch (err) {
      err.message = `Ticket Attachment Thumbnail Error Frontend: ${err.message}`;
      console.error(err);
      return <div />;
    }
  }

  handleAttachmentDelete(id) {
    const image = this.props.images.find((x) => x.id === parseInt(id));
    if (this.props.newTicket) {
      if (image) {
        this.props.deleteAttachmentHandler(image);
      } else {
        this.props.deleteAttachmentHandler(e.target.dataset.description);
      }
    } else {
      const self = this;
      Swal.fire({
        title: "Delete Attachment",
        text: "Are you sure that you want to delete this Attachment?",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          if (!self.props.draft) {
            self.props.setLoadingClass("form-update-start-loading");
            self.props.setLoadingText(true);
          }
          const params = {};
          if (this.props.comment) {
            params.comment = true;
          }
          $.ajax({
            url: `/ticket_attachments/${id}.json`,
            dataType: "json",
            cache: false,
            data: params,
            beforeSend(xhr) {
              xhr.setRequestHeader(
                "X-CSRF-Token",
                $('meta[name="csrf-token"]').attr("content")
              );
            },
            type: "DELETE",
            success(data) {
              self.props.deleteAttachmentHandler(
                data.ticket,
                self.props.draft ? image.id : image.image_file_name,
                self.props.comment
              );
              if (!self.props.draft) {
                self.props.setLoadingClass("form-update-end-loading");
                self.props.setLoadingText(false);
              }
            },
            error(xhr, status) {
              console.error(status, xhr.responseText);
              self.props.setLoadingClass("form-update-end-loading");
              Swal.fire({
                title: "Attachment delete error",
                text: "There was a problem deleting this attachment",
                customClass: "swal2-error-modal",
              });
            },
          });
        }
      });
    }
  }

  handleThumbnailClick(e, i) {
    const { target } = e;
    if (!this.props.newTicket) {
      if (
        e.target.classList[0] !== "attachment-delete-button" &&
        e.target.classList[0] !== "attachment-download-button"
      ) {
        if (target.id === "file-wrapper") {
          // do nothing so file form loads
        } else if (target.id === "bug-file") {
          // do nothing so file form loads
        } else if (
          this.props.images[i].image_content_type.includes("image") ||
          this.props.images[i].image_content_type.includes("video")
        ) {
          const { id } = this.props.images[i];
          const filteredImages = this.props.images.filter(
            (i) =>
              i.image_content_type.includes("image") ||
              i.image_content_type.includes("video")
          );
          const index = filteredImages.findIndex((i) => i.id === id);
          if (target.dataset.id === "editor-btn") {
            this.props.setModal(true, "ticket-attachments", {
              images: filteredImages,
              customClass: "ticket-attachments-modal",
              backdropClickCheck: {
                title: "Exit Image Edit",
                text: "Are you sure that you want to exit image edit? Any unsaved changes will not be preserved.",
              },
              setBypassClickCheck: true,
              currentIndex: index,
              edit: true,
              handleNewImages: this.props.handleNewImages,
            });
          } else {
            this.props.setModal(true, "ticket-attachments", {
              images: filteredImages,
              customClass: "ticket-attachments-modal",
              backdropClickCheck: {
                title: "Exit Image Edit",
                text: "Are you sure that you want to exit image edit? Any unsaved changes will not be preserved.",
              },
              setBypassClickCheck: true,
              currentIndex: index,
              handleNewImages: this.props.handleNewImages,
            });
          }
        }
      }
    }
  }

  handleImageResize(e) {
    const image = e.target;
    if (image.clientHeight - image.clientWidth >= 0) {
      image.classList.add("tall-attachment-image");
    } else {
      image.classList.add("wide-attachment-image");
    }
  }

  render() {
    const images = [];
    const attachments = this.props.images;
    if (this.props.newTicket) {
      for (var i = 0; i < attachments.length; i++) {
        try {
          var image = attachments[i];
          if (image.type.includes("image")) {
            images.push({
              id: attachments[i].id,
              original: URL.createObjectURL(attachments[i]),
              download_url: attachments[i].download_url,
              thumbnail: URL.createObjectURL(attachments[i]),
              isImage: true,
              description: attachments[i].name,
            });
          } else if (image.type.includes("application/pdf")) {
            images.push({
              id: attachments[i].id,
              original: URL.createObjectURL(attachments[i]),
              download_url: attachments[i].download_url,
              thumbnail:
                "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/pdf-icon.png",
              originalAlt: attachments[i].name,
              description: attachments[i].name,
              originalTitle: attachments[i].name,
              isImage: false,
              renderItem: this.renderPdf,
            });
          } else if (image.type.includes("application/zip")) {
            images.push({
              id: attachments[i].id,
              original: URL.createObjectURL(attachments[i]),
              download_url: attachments[i].download_url,
              thumbnail:
                "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/zip-icon.png",
              originalAlt: attachments[i].name,
              description: attachments[i].name,
              originalTitle: attachments[i].name,
              isImage: false,
              renderItem: this.renderZip,
            });
          } else if (
            image.type.includes("video/mp4") ||
            image.type.includes("video/quicktime")
          ) {
            images.push({
              id: attachments[i].id,
              original: URL.createObjectURL(attachments[i]),
              download_url: attachments[i].download_url,
              renderThumbInner: this.renderVideoPlaceholder,
              originalAlt: attachments[i].name,
              description: attachments[i].name,
              originalTitle: attachments[i].name,
              isImage: true,
              renderItem: this.renderVideo,
            });
          } else if (
            image.type.includes("application/chls") ||
            image.type.includes("text/rtf") ||
            image.type.includes("application/msword") ||
            image.type.includes(
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) ||
            image.type.includes("text/plain")
          ) {
            images.push({
              id: attachments[i].id,
              original: URL.createObjectURL(attachments[i]),
              download_url: attachments[i].download_url,
              thumbnail:
                "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/file-icon.png",
              originalAlt: attachments[i].name,
              description: attachments[i].name,
              originalTitle: attachments[i].name,
              isImage: false,
              renderItem: this.renderTextFile,
            });
          } else if (image.type.includes("application/json")) {
            images.push({
              id: attachments[i].id,
              original: attachments[i].image_url,
              download_url: attachments[i].download_url,
              thumbnail:
                "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/file-icon.png",
              originalAlt: attachments[i].image_url,
              description: attachments[i].image_file_name,
              originalTitle: attachments[i].image_file_name,
              isImage: false,
              renderItem: this.renderTextFile,
            });
          }
        } catch (err) {
          err.message = `New Ticket Attachment Error Frontend${err.message}`;
          console.error(err);
          Sentry.captureException(err);
          Swal.fire({
            title: "Attachment Error",
            text: "There was a problem with a ticket attachment",
            customClass: "Swal2-error-modal",
          });
        }
      }
    } else {
      for (var i = 0; i < attachments.length; i++) {
        var image = attachments[i];
        try {
          if (
            image &&
            image.image_content_type &&
            image.image_content_type.includes("image")
          ) {
            images.push({
              id: attachments[i].id,
              original: attachments[i].image_url,
              download_url: attachments[i].download_url,
              thumbnail: attachments[i].image_url,
              description: attachments[i].image_file_name,
              time_stamp: moment(
                attachments[i].image_attachment
                  ? attachments[i].image_attachment.created_at
                  : attachments[i].image_updated_at
              ).format("[Added] MM/DD/YY hh:mm a"),
              user_name: attachments[i].user_name,
              isImage: true,
            });
          } else if (
            image &&
            image.image_content_type &&
            image.image_content_type.includes("application/pdf")
          ) {
            images.push({
              id: attachments[i].id,
              original: attachments[i].image_url,
              download_url: attachments[i].download_url,
              thumbnail:
                "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/pdf-icon.png",
              originalAlt: attachments[i].image_url,
              description: attachments[i].image_file_name,
              originalTitle: attachments[i].image_file_name,
              renderItem: this.renderPdf,
              time_stamp: moment(
                attachments[i].image_attachment
                  ? attachments[i].image_attachment.created_at
                  : attachments[i].image_updated_at
              ).format("[Added] MM/DD/YY hh:mm a"),
              user_name: attachments[i].user_name,
              isImage: false,
            });
          } else if (
            image &&
            image.image_content_type &&
            image.image_content_type.includes("application/zip")
          ) {
            images.push({
              id: attachments[i].id,
              original: attachments[i].image_url,
              download_url: attachments[i].download_url,
              thumbnail:
                "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/zip-icon.png",
              originalAlt: attachments[i].image_url,
              description: attachments[i].image_file_name,
              originalTitle: attachments[i].image_file_name,
              renderItem: this.renderZip,
              time_stamp: moment(
                attachments[i].image_attachment
                  ? attachments[i].image_attachment.created_at
                  : attachments[i].image_updated_at
              ).format("[Added] MM/DD/YY hh:mm a"),
              user_name: attachments[i].user_name,
              isImage: false,
            });
          } else if (
            image &&
            image.image_content_type &&
            (image.image_content_type.includes("video/mp4") ||
              image.image_content_type.includes("video/quicktime"))
          ) {
            images.push({
              id: attachments[i].id,
              original: attachments[i].image_url,
              download_url: attachments[i].download_url,
              originalAlt: attachments[i].image_url,
              renderThumbInner: this.renderThumbInner,
              description: attachments[i].image_file_name,
              originalTitle: attachments[i].image_file_name,
              time_stamp: moment(
                attachments[i].image_attachment
                  ? attachments[i].image_attachment.created_at
                  : attachments[i].image_updated_at
              ).format("[Added] MM/DD/YY hh:mm a"),
              user_name: attachments[i].user_name,
              isImage: true,
            });
          } else if (
            image &&
            image.image_content_type &&
            (image.image_content_type.includes("application/chls") ||
              image.image_content_type.includes("text/rtf") ||
              image.image_content_type.includes("application/msword") ||
              image.image_content_type.includes(
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) ||
              image.image_content_type.includes("application/json") ||
              image.image_content_type.includes("text/plain"))
          ) {
            images.push({
              id: attachments[i].id,
              original: attachments[i].image_url,
              download_url: attachments[i].download_url,
              thumbnail:
                "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/file-icon.png",
              originalAlt: attachments[i].image_url,
              description: attachments[i].image_file_name,
              originalTitle: attachments[i].image_file_name,
              renderItem: this.renderTextFile,
              time_stamp: moment(
                attachments[i].image_attachment
                  ? attachments[i].image_attachment.created_at
                  : attachments[i].image_updated_at
              ).format("[Added] MM/DD/YY hh:mm a"),
              user_name: attachments[i].user_name,
              isImage: false,
            });
          }
        } catch (err) {
          err.message = `Ticket Attachment Error Frontend${err.message}`;
          console.error(err);
          Sentry.captureException(err);
          Swal.fire({
            title: "Attachment Error",
            text: "There was a problem with a ticket attachment",
            customClass: "Swal2-error-modal",
          });
        }
      }
    }

    if (this.props.action && this.props.action == "edit") {
      images.push({
        original: "",
        download_url: "",
        thumbnail: "",
        originalAlt: "",
        description: "",
        originalTitle: "fileInput",
        time_stamp: "",
        user_name: "",
      });
    }

    return (
      <Gallery
        ref={this.galleryRef}
        items={images}
        startIndex={0}
        showPlayButton={false}
        renderThumbInner={this.renderThumbInner}
        onScreenChange={this.screenChange}
        onThumbnailClick={this.handleThumbnailClick}
        additionalClass={`${
          this.state.fullScreen
            ? "attachment-gallery-fullscreen"
            : "attachment-gallery-regular"
        }`}
      />
    );
  }
}

export default AttachmentCarousel;

AttachmentCarousel.propTypes = {
  attachments: PropTypes.array,
  image: PropTypes.object,
  images: PropTypes.array,
  setModal: PropTypes.func,
  deleteAttachmentHandler: PropTypes.func,
  newTicket: PropTypes.bool,
  fileInput: PropTypes.object,
  action: PropTypes.string,
};
