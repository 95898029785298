import React, { useContext, useState, useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import ApiClient from "../../../utils/ApiClient";
import ProfileContext from "../ProfileContext";
import EditNotepadIcon from "../../icons/edit_notepad_icon";
import LoadingSpinner from "../../common/loading_spinner";

const InfoDisplay = ({ user }) => {
  const { current_user: sessionUser, setProfileData } =
    useContext(ProfileContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialUserInterests = user.user_interests.map(
    (interest) => interest.value
  );
  const [userInterests, setUserInterests] = useState(initialUserInterests);

  const [editedUserInterests, setEditedUserInterests] = useState(
    initialUserInterests.join(", ")
  );

  const api = new ApiClient();

  const isEditable = useMemo(() => {
    return sessionUser.role === "admin";
  }, [sessionUser]);

  const inputRef = useRef();

  const handleOpenEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setEditedUserInterests(userInterests);
    setIsEditing(false);
  };

  const handleSubmitInterests = () => {
    if (editedUserInterests === user.user_interests.join(", ")) {
      setIsEditing(false);
      return null;
    }

    setIsSubmitting(true);

    const updatedInterests = editedUserInterests
      .split(",")
      .map((item) => item.trim());
    api
      .patch(`/users/${user.id}/bio_details`, {
        user: { id: user.id, interests: updatedInterests },
      })
      .then((res) => {
        const newUserData = { ...user, user_interests: updatedInterests };
        setUserInterests(updatedInterests);

        setProfileData((prevData) => ({ ...prevData, user: newUserData }));
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Interests updated successfully.",
          classes: "green",
        });
      })
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Something went wrong while updating interests.",
          classes: "red",
        });
      })
      .finally(() => {
        setIsEditing(false);
        setIsSubmitting(false);
      });

    return null;
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [isEditing]);

  if (!user || !sessionUser) {
    return null;
  }

  return (
    <InfoContainer>
      <ControlsRow>
        <InfoTitle>
          Personal Interests
          <PositionedLoadingSpinner size={12} loading={isSubmitting} />
        </InfoTitle>
        {isEditable && (
          <Controls>
            {isEditing && (
              <>
                <SubmitButton type="button" onClick={handleSubmitInterests}>
                  <svg
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.76695 10.3226L2.28331 6.66703L2.21117 6.59133L2.13877 6.66678L0.927851 7.92852L0.861632 7.99752L0.927607 8.06675L5.69452 13.069L5.76691 13.145L5.83931 13.069L16.0724 2.33073L16.1381 2.26174L16.0724 2.19276L14.87 0.931013L14.7976 0.855082L14.7253 0.930978L5.76695 10.3226Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.2"
                    />
                  </svg>
                </SubmitButton>
                <CancelButton type="button" onClick={handleCancelEdit}>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.73488 6L0 1.26512L1.26512 0L6 4.73488L10.7349 0L12 1.26512L7.26512 6L12 10.7349L10.7349 12L6 7.26512L1.26512 12L0 10.7349L4.73488 6Z"
                      fill="#242424"
                    />
                  </svg>
                </CancelButton>
              </>
            )}
          </Controls>
        )}
      </ControlsRow>
      <InfoBodyContainer $isEditable={isEditable} $isEditing={isEditing}>
        {isEditable && !isEditing && (
          <OpenEditButton type="button" onClick={handleOpenEditClick}>
            <EditNotepadIcon height="16px" width="16px" color="#fff" />
          </OpenEditButton>
        )}
        {isEditing && (
          <InfoTextarea
            ref={(node) => {
              inputRef.current = node;
            }}
            value={editedUserInterests}
            onChange={(e) => setEditedUserInterests(e.target.value)}
          />
        )}
        {!isEditing && (
          <UserInterests>{userInterests.join(", ")}</UserInterests>
        )}
      </InfoBodyContainer>
    </InfoContainer>
  );
};

const InfoContainer = styled.div``;

const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: -8px;
  padding-bottom: 3px;
`;

const Controls = styled.div`
  display: flex;
  gap: 6px;
`;

const ControlButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }
`;

const CancelButton = styled(ControlButton)`
  background-color: #d3d3d3;

  &:focus,
  &:focus-visible,
  &:active {
    background-color: #d3d3d3;
  }
`;

const SubmitButton = styled(ControlButton)`
  background-color: #519acc;

  &:focus,
  &:focus-visible,
  &:active {
    background-color: #519acc;
  }
`;

const InfoTitle = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  color: #666;
  position: relative;
  line-height: normal;
`;

const PositionedLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  right: -50px;
`;

const OpenEditButton = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  opacity: 0;
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #519acc;
  border-radius: 4px;
  border: none;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    border: none;
    background-color: #519acc;
  }
`;

const IsEditingStyle = css`
  border-color: #d3d3d3;

  &:active,
  &:focus,
  &:focus-visible {
    border-color: #519acc;
  }
`;

const NotEditingStyle = css`
  border-color: transparent;

  &:hover {
    ${OpenEditButton} {
      opacity: 1;
    }
  }
`;

const InfoBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: opacity, border-color;
  min-height: 60px;
  margin: 0 -8px;
  padding: ${({ $isEditing, $isEditable }) =>
    !$isEditable || $isEditing ? "0px" : "4px"};

  ${(props) => (props.$isEditing ? IsEditingStyle : NotEditingStyle)}

  &:hover {
    border-color: #519acc;
  }
`;

const UserInterests = styled.p`
  color: #000;
  font-size: 14px;
  font-family: Arial;
  line-height: 20px;
  min-height: 60px;
  padding: 0px 4px;
`;

const InfoTextarea = styled.textarea`
  border: none;
  background-color: transparent;
  padding-right: 16px;
  min-height: 68px;
  padding: 8px;
  padding-top: 10px;

  &:focus,
  &:focus-visible {
    outline: none;
    border: none;
  }
`;

export default InfoDisplay;
