import React, { useContext, useMemo } from "react";
import queryString from "query-string";
import { DeviceLabContext } from "@components/devices/device_lab";
import CheckboxMenu from "@components/common/checkbox_menu";
import useDeviceLab from "@hooks/data/useDeviceLab";

const FilterByStatus = () => {
  const { isClientUser } = useContext(DeviceLabContext);
  const { filters, PARAM_KEYS } = useDeviceLab();

  const { query, statuses: currentStatuses } = filters;

  const statusOptions = useMemo(() => {
    const { AVAILABLE, OFFICE, HOME, SIM, OFFICE_ONLY, TIER1, TIER2 } =
      PARAM_KEYS;
    return [
      { label: "Available", value: AVAILABLE },
      { label: "Office In Use", value: OFFICE },
      { label: "At Home", value: HOME },
      { label: "Sim Devices", value: SIM },
      { label: "Office Only", value: OFFICE_ONLY },
      { label: "Tier 1 Devices", value: TIER1 },
      { label: "Tier 2 Devices", value: TIER2 },
    ];
  }, [PARAM_KEYS]);

  const handleStatusChange = (event) => {
    const { PAGE } = PARAM_KEYS;
    const nextQuery = { ...query };
    delete nextQuery[PAGE];

    const queryKey = event.target.value;
    if (nextQuery[queryKey]) {
      delete nextQuery[queryKey];
    } else {
      nextQuery[queryKey] = true;
    }

    const nextQueryString = queryString.stringify(nextQuery, {
      arrayFormat: "bracket",
    });
    window.history.replaceState({}, "", `?${nextQueryString}`);
  };

  if (isClientUser) {
    return null;
  }

  return (
    <CheckboxMenu
      key={1}
      maxWidth="100%"
      maxHeight="300px"
      searchEnabled
      count={Object.values(currentStatuses).length}
      active={Object.values(currentStatuses).length >= 1}
      options={statusOptions.map(({ label, value }) => ({
        label,
        value,
        handler: handleStatusChange,
        checked: !!currentStatuses[value],
        classList: "user-option",
      }))}
      title="Filter By Status"
      id="1"
      icons={[
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
      ]}
    />
  );
};

export default FilterByStatus;
