import React, { useCallback } from "react";
import styled from "styled-components";
import queryString from "query-string";
import {
  PieBreakdown,
  BugsStepsDualBreakdown,
  UserList,
} from "../performance/Widgets";
import usePerformanceDashboardData from "../../hooks/data/usePerformanceDashboardData";
import PerformanceDateRange from "../performance/Components/PerformanceDateRange";

const PerformanceRow = () => {
  const {
    data: { dashboardData, queryParams },
    dashboardLoading,
    error,
  } = usePerformanceDashboardData();

  const handleDateRangeChange = useCallback(
    (start_date, end_date) => {
      const nextParams = {
        ...queryParams,
        start_date,
        end_date,
      };
      const search = queryString.stringify(nextParams);
      window.history.pushState({}, "", `?${search}`);
    },
    [queryParams]
  );

  return (
    <>
      <RowWrapper>
        <FlexContainer>
          <WidgetTitle>Team Performance</WidgetTitle>
          <PerformanceDateRange
            queryParams={queryParams}
            onDateRangeChange={handleDateRangeChange}
          />
        </FlexContainer>
        <BugBarWrapper>
          {dashboardData && (
            <BugsStepsDualBreakdown
              bugs={dashboardData.bugs}
              users={dashboardData.users}
              loading={dashboardLoading}
              queryParams={queryParams}
              parentFilterValue="project"
              error={error}
            />
          )}
        </BugBarWrapper>
        <SplitWidgetRow>
          <SplitWidget>
            <PieBreakdown
              data={dashboardData?.platforms}
              title="Platforms Tested"
              parentFilterValue="project"
            />
          </SplitWidget>
          <SplitWidget>
            <PieBreakdown
              data={dashboardData?.tasks}
              title="Task Breakdown"
              parentFilterValue="project"
            />
          </SplitWidget>
        </SplitWidgetRow>
      </RowWrapper>
      <UserWrapper>
        <UserList users={dashboardData?.users || []} title="Your Team" />
      </UserWrapper>
    </>
  );
};

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const RowWrapper = styled.div`
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px lightgrey;
  padding: 20px 30px;
  margin: 5px;
  flex: 1;
  height: 100%;
`;

export const BugBarWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  padding: 5px 32px 32px 32px;
  margin-bottom: 10px;
`;

const UserWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px lightgrey;
  padding: 24px 30px;
  margin: 5px;
`;

export const WidgetTitle = styled.p`
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SplitWidgetRow = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
`;

export const SplitWidget = styled.div`
  flex-basis: calc(50% - 3px);
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  padding: 32px 0px 32px 32px;
`;

export default PerformanceRow;
