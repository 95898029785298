import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import LoadingSpinner from "../../common/loading_spinner";
import { CancelButton, SubmitButton } from "../../common/modal_buttons";
import { TrialsContext } from "./index";

const TrialFooter = () => {
  const {
    trial,
    isInProgress,
    isEnded,
    loading,
    activeQuestion,
    decrementQuestion,
  } = useContext(TrialsContext);

  const handleExitButton = () => {
    window.history.pushState({}, "", `/wiki/${trial.assessment.resource.id}`);
    window.location.reload();
  };

  const handleBackupButton = () => {
    decrementQuestion();
  };

  const progress = useMemo(() => {
    if (
      !trial ||
      !trial.total_questions ||
      !activeQuestion ||
      !activeQuestion.question_number
    )
      return null;
    return activeQuestion.question_number / trial.total_questions;
  }, [trial, activeQuestion]);

  const submitButtonLabel = useMemo(() => {
    if (!isInProgress) return "Begin Assessment";
    if (progress === 1) return "Complete";
    return "Next Question";
  }, [isInProgress, progress]);

  const showAdvanceButton = useMemo(() => {
    return !isEnded;
  }, [isEnded]);

  const showExitButton = useMemo(() => {
    return !isInProgress;
  }, [isInProgress]);

  const showBackupButton = useMemo(() => {
    if (showExitButton || !progress) return false;
    if (activeQuestion.question_number === 1) return false;
    return true;
  }, [showExitButton, progress, activeQuestion]);

  return (
    <Container>
      <ExitSection>
        {showExitButton && (
          <ExitButton
            type="button"
            onClick={handleExitButton}
            className="common-button common-button-cancel"
          >
            Exit
          </ExitButton>
        )}
        {showBackupButton && (
          <ExitButton
            type="button"
            onClick={handleBackupButton}
            className="common-button common-button-cancel"
            $isBackup
          >
            Previous Question
          </ExitButton>
        )}
      </ExitSection>
      <ProgressSection>
        {isInProgress && activeQuestion && (
          <>
            <TotalBar>
              <ProgressBar $progress={progress} />
            </TotalBar>
            <ProgressExplain>
              {activeQuestion.short_question_string}
            </ProgressExplain>
          </>
        )}
      </ProgressSection>
      <AdvanceSection>
        {showAdvanceButton && (
          <AdvanceButton
            type="submit"
            className="common-button common-button-submit"
          >
            <StyledLoadingSpinner loading={loading} size={30} />
            {submitButtonLabel}
          </AdvanceButton>
        )}
      </AdvanceSection>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExitSection = styled.div`
  flex-basis: 25%;
  display: flex;
  justify-content: flex-start;
  padding-right: 40px;
`;

const ExitButton = styled(CancelButton)`
  box-shadow: 0px 3px 0px #c4c4c4;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  font-family: "Manrope";
  border-width: 1px;
  height: 45px;
  width: ${(props) => (props.$isBackup ? "175px" : "96px")};
`;

const ProgressSection = styled.div`
  flex-basis: 75%;
  height: auto;
  margin: 0 12px;
  height: 30px;
  display: flex;
  align-items: center;
`;

const TotalBar = styled.div`
  position: relative;
  width: 100%;
  height: 14px;
  background-color: #dedede;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
`;

const ProgressBar = styled.span`
  display: block;
  height: 100%;
  width: ${(props) => (props.$progress * 100).toFixed(2) + "%"};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #519acc;
  position: relative;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
`;

const ProgressExplain = styled.div`
  margin-left: 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
`;

const AdvanceSection = styled.div`
  position: relative;
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  margin-left: unset;
  margin-right: unset;
`;

const AdvanceButton = styled(SubmitButton)`
  position: relative;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  min-width: 169px;
  height: 45px;
  font-family: "Manrope";
`;

export default TrialFooter;
