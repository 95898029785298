import React from "react";
import axios from "axios";
import xss from "xss";
import WithCable from "@/channels/WithCable";
import ReactGA, { set } from "react-ga";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import Linkify from "react-linkify";
import Sleep from "../../common/sleep";
import TestPassDevices from "./test_pass_devices";
import TestPassHoverDialog from "./test_pass_hover_dialog";
import TestPassResultRow from "./test_pass_result_row";

// for Linkify
const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

class TestPassForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      testPass: this.props.testPass,
      testCases: this.props.testPass.test_cases,
      testDevices: this.props.testPass.test_devices,
      loading: false,
      currentPlatform: 1,
      projectUsers: this.props.projectUsers,
      projectUsersList: Object.assign(
        {},
        ...this.props.projectUsers.map((u) => ({ [u.id]: u.name }))
      ),
      platformsPresent: [true, true, true, true, true, true],
      modalOpen: false,
      currentModal: "",
      modalData: {},
      editPassTitle: false,
      showProgress: true,
      gridRowSettings: "",
      dragging: false,
      draggingId: null,
      draggingPosition: null,
      resultOutlineActive: false,
      resultOutlineLeft: 0,
      resultOutlineTop: 0,
      draggingResult: false,
      resultDragType: "outline",
      resultOutlineRow: 0,
      resultOutlineColumn: 0,
      resultOutlineHeight: 0,
      copiedResultsRowRange: [0, 0],
      copiedResultsColumnRange: [0, 0],
      currentColumn: 0,
      showBuild: false,
    };
    this.stickySectionPreviousScroll = window.scrollY;
    this.outlineKeyDown = false;
    this.emptyCounter = 1;
    this.copiedResultsRowRange = [0, 0];
    this.copiedResultsColumnRange = [0, 0];
    this.pasteResultRow = 0;
    this.pasteResultColumn = 0;
    this.hoverDialogRef = React.createRef();
    this.testDevicesRef = React.createRef();
    this.scrollTimer = null;
    this.dragTimer = null;
    this.deltaDivider = typeof InstallTrigger !== "undefined" ? 0.5 : 4;
    this.validCopy = false;
    this.measureText = document.createElement("canvas");
    this.badgeUrls = {
      test_pass_bronze:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Test%20Pass%20-%20Bronze.svg",
      test_pass_silver:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Test%20Pass%20-%20Silver.svg",
      test_pass_gold:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Test%20Pass%20-%20Gold.svg",
    };

    this.stickySliderWheel = (e) => {
      e.preventDefault();
      const elem = document.getElementById("left-test-pass-container");
      const stickyElem = document.getElementById("sticky-device-container");
      // var sliderElem = document.getElementById("test-pass-slider")
      if (elem) {
        const { scrollWidth } = elem;
        const { clientWidth } = elem;
        if (scrollWidth > clientWidth) {
          let newScrollLeft = 0;
          if (e.deltaY > 0) {
            newScrollLeft =
              elem.scrollLeft + Math.floor(e.deltaY / this.deltaDivider) * 4;
            var percent =
              (newScrollLeft / (elem.scrollWidth - elem.clientWidth)) * 100;
            elem.scrollLeft = newScrollLeft;
            stickyElem.scrollLeft = newScrollLeft;
            // sliderElem.value = percent
          } else if (e.deltaY < 0) {
            newScrollLeft =
              elem.scrollLeft - Math.floor(-e.deltaY / this.deltaDivider) * 4;
            var percent =
              (newScrollLeft / (elem.scrollWidth - elem.clientWidth)) * 100;
            elem.scrollLeft -= Math.floor(-e.deltaY / this.deltaDivider) * 4;
            stickyElem.scrollLeft -=
              Math.floor(-e.deltaY / this.deltaDivider) * 4;
            // sliderElem.value = percent
          }
          if (e.deltaX > 0) {
            newScrollLeft =
              elem.scrollLeft + Math.floor(e.deltaX / this.deltaDivider) * 4;
            var percent =
              (newScrollLeft / (elem.scrollWidth - elem.clientWidth)) * 100;
            elem.scrollLeft = newScrollLeft;
            stickyElem.scrollLeft = newScrollLeft;
            // sliderElem.value = percent
          } else if (e.deltaX < 0) {
            newScrollLeft =
              elem.scrollLeft - Math.floor(-e.deltaX / this.deltaDivider) * 4;
            var percent =
              (newScrollLeft / (elem.scrollWidth - elem.clientWidth)) * 100;
            elem.scrollLeft -= Math.floor(-e.deltaX / this.deltaDivider) * 4;
            stickyElem.scrollLeft -=
              Math.floor(-e.deltaX / this.deltaDivider) * 4;
            // sliderElem.value = percent
          }
        }
      }
    };

    this.passXWheel = (e) => {
      const x = e.deltaX;
      if (x !== 0) {
        e.preventDefault();
        setTimeout(() => {
          const elem = document.getElementById("left-test-pass-container");
          if (elem) {
            const { scrollWidth } = elem;
            const { clientWidth } = elem;
            if (scrollWidth > clientWidth) {
              const stickyElem = document.getElementById(
                "sticky-device-container"
              );
              stickyElem.scrollLeft += x;
              elem.scrollLeft += x;
            }
          }
        }, 10);
      }
    };

    this.passArrowKeys = async (e) => {
      if (e.keyCode === 37 || e.keyCode === 39) {
        await Sleep(70);
        const elem = document.getElementById("left-test-pass-container");
        const stickyElem = document.getElementById("sticky-device-container");
        stickyElem.scrollLeft = elem.scrollLeft;
      }
    };

    this.passHoverWheel = (e) => {
      const elem = document.getElementById("test-pass-hover-dialog-inner");
      scrollHeight = elem.scrollHeight;
      clientHeight = elem.clientHeight;
      if (scrollHeight > clientHeight) {
        e.preventDefault();
        var e = e;
        let scrollTop;
        var scrollHeight;
        var clientHeight;
        if (e.deltaY > 0) {
          elem.scrollTop += Math.floor(e.deltaY / this.deltaDivider) * 4;
          scrollTop = elem.scrollTop;
        } else {
          elem.scrollTop -= Math.floor(-e.deltaY / this.deltaDivider) * 4;
          scrollTop = elem.scrollTop;
        }
      }
    };
  }

  componentDidMount() {
    try {
      if (this.props.new_badge) {
        this.props.setModal(true, "new-badge", {
          badgeDetails: this.props.new_badge.details,
          badgeName: this.props.new_badge.name,
          imageLink: this.badgeUrls[this.props.new_badge.identity],
          customClass: "new-badge-modal",
        });
      }
      const stickyElem = document.getElementById("sticky-device-container");
      stickyElem.addEventListener("wheel", this.stickySliderWheel, {
        passive: false,
      });
      window.addEventListener("keydown", this.passArrowKeys);
      var elem = document.getElementById("left-test-pass-container");
      elem.addEventListener("wheel", this.passXWheel, { passive: false });
      this.testPassDeviceContainer = document
        .getElementById("test-pass-device-container")
        .getBoundingClientRect();
      this.testPassContainer = document
        .getElementById("left-test-pass-container")
        .getBoundingClientRect();
      const deviceTypes = this.state.testPass.test_devices
        .map((testDevice) => testDevice.device_platform_id)
        .sort();
      const platformsPresent = [];
      for (let x = 1; x <= 6; x++) {
        platformsPresent.push(!!deviceTypes.includes(x));
      }
      this.setState(
        {
          currentPlatform: deviceTypes[0],
          shortDevice: [1, 2, 5, 6].indexOf(deviceTypes[0]) !== -1,
          platformsPresent,
          // gridRowSettings
        },
        () => {
          const { shortDevice } = this.state;
          const height =
            (this.state.shortDevice ? 50 : 70) +
            (this.state.showBuild ? 38 : 0);
          document.querySelector(
            ".pass-page-heading"
          ).style.paddingBottom = `${height}px`;
          const menu = document.querySelector(".test-pass-menu-hover");
          if (menu) {
            menu.style.marginBottom = `${-(height + 70)}px`;
            menu.style.top = `${height + 20}px`;
          }
          if (this.stickySectionHeader) {
            this.stickySectionHeader.style.top = `${height + 136}px`;
          }
          if (deviceTypes[0] != 1) {
            window.dispatchEvent(new Event("resize"));
          }
        }
      );
      let resizeTimer;
      this.resize = (e) => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          const elem = document.getElementById("left-test-pass-container");
          const total = elem.scrollWidth - elem.offsetWidth;
          const deviceContainer = document.getElementById(
            "sticky-device-container"
          );
          if (this.stickySectionHeader && deviceContainer) {
            const { expanded } = this.props;
            if (deviceContainer.scrollWidth > deviceContainer.clientWidth) {
              this.stickySectionHeader.style.width = `calc(100% - ${
                expanded ? 288 : 138
              }px)`;
              this.stickySectionHeader.style.left = expanded
                ? "288px"
                : "138px";
            } else {
              const addDeviceButtonContainer = document.getElementById(
                "add-device-button-container"
              );
              if (addDeviceButtonContainer) {
                this.stickySectionHeader.style.width = `${
                  addDeviceButtonContainer.getBoundingClientRect().right -
                  (expanded ? 288 : 138)
                }px`;
                this.stickySectionHeader.style.left = expanded
                  ? "288px"
                  : "138px";
              }
            }
          }
          this.testPassDeviceContainer = document
            .getElementById("test-pass-device-container")
            .getBoundingClientRect();
          this.testPassContainer = document
            .getElementById("left-test-pass-container")
            .getBoundingClientRect();
        }, 100);
      };
      window.addEventListener("resize", this.resize);

      var elem = document.getElementById("left-test-pass-container");
      const total = elem.scrollWidth - elem.offsetWidth;
      // var container = document.getElementById("test-pass-slide-container")

      this.updateDeviceProgressPercentDisplays(
        this.state.testPass.device_percents
      );

      this.setPassProgress(this.state.testPass.progress);
      this.testPassChannel = this.props.cable.subscriptions.create(
        { channel: "TestPassChannel", test_pass_id: this.state.testPass.id },
        {
          received: (data) => {
            if (data.test_devices) {
              this.setState({ testDevices: data.test_devices });
            } else if (data.test_cases) {
              if (
                this.props.currentUser.role === "trialist" &&
                data.user_id &&
                data.user_id !== this.props.currentUser.id
              ) {
                return;
              }
              this.setState({ testCases: data.test_cases });
            } else if (data.test_pass) {
              const deviceTypes = data.test_pass.test_devices
                .map((testDevice) => testDevice.device_platform_id)
                .sort();
              const platformsPresent = [];
              for (let x = 1; x <= 6; x++) {
                platformsPresent.push(!!deviceTypes.includes(x));
              }
              this.setState(
                {
                  testDevices: data.test_pass.test_devices,
                  testPass: data.test_pass,
                  currentPlatform: platformsPresent[
                    this.state.currentPlatform - 1
                  ]
                    ? this.state.currentPlatform
                    : deviceTypes[0],
                  shortDevice:
                    [1, 2, 5, 6].indexOf(
                      platformsPresent[this.state.currentPlatform - 1]
                        ? this.state.currentPlatform
                        : deviceTypes[0]
                    ) !== -1,
                  platformsPresent,
                },
                () => {
                  const { shortDevice } = this.state;
                  const height =
                    (this.state.shortDevice ? 50 : 70) +
                    (this.state.showBuild ? 38 : 0);
                  document.querySelector(
                    ".pass-page-heading"
                  ).style.paddingBottom = `${height}px`;
                  const menu = document.querySelector(".test-pass-menu-hover");
                  menu.style.marginBottom = `${-(height + 70)}px`;
                  menu.style.top = `${height + 20}px`;
                  window.dispatchEvent(new Event("resize"));
                  if (this.stickySectionHeader) {
                    this.stickySectionHeader.style.top = `${height + 136}px`;
                  }
                }
              );
              this.updateDeviceProgressPercentDisplays(data.device_percents);
              if (data.progress) {
                this.setPassProgress(data.progress);
              }
            } else if (data.test_step) {
              if (this.refs[`step_${data.test_step.id}`]) {
                this.refs[`step_${data.test_step.id}`].handleCableUpdate(
                  data.test_step.test_pass_results
                );
                if (
                  data.test_device &&
                  document.getElementById(`device_${data.test_device.id}_text`)
                ) {
                  const percentText = document.getElementById(
                    `device_${data.test_device.id}_text`
                  );
                  percentText.innerText = `${data.test_device.percent}%`;
                  percentText.style.background = `linear-gradient(to right, white ${data.test_device.percent}%, black 0%)`;
                  percentText.style.webkitBackgroundClip = "text";
                  document.getElementById(
                    `device_${data.test_device.id}_bar`
                  ).style.width = `${data.test_device.percent}%`;
                }
              }
              if (data.progress) {
                this.setPassProgress(data.progress);
              }
            } else if (data.copy_progress) {
              this.setPassProgress(data.copy_progress);
              this.updateDeviceProgressPercentDisplays(data.device_percents);
            } else if (data.test_device_build) {
              const elem = document.getElementById(
                `test-device-${data.test_device_build.id}-build-input`
              );
              if (elem) {
                elem.value = data.test_device_build.build;
              }
              const { testDevices } = this.state;
              const index = testDevices
                .map((t) => t.id)
                .indexOf(data.test_device_build.id);
              if (index !== -1) {
                testDevices.splice(index, 1, data.test_device_build);
                this.setState({ testDevices });
              }
            }
          },
        }
      );
      // a11y
      setTimeout(() => {
        const linkElems = document.querySelectorAll("#test-pass-form a");
        linkElems.forEach((a) => {
          a.setAttribute("role", "link");
          a.setAttribute("aria-label", a.innerText);
          a.setAttribute("tabindex", "0");
        });
      }, 3000);
      // sticky test section header
      this.stickySectionHeader = document.createElement("div");
      this.stickySectionHeader.id = "sticky-section-header";
      this.stickySectionHeader.style.left = this.props.expanded
        ? "288px"
        : "138px";
      this.stickySectionHeader.setAttribute("data-index1", "none");
      const deviceContainer = document.getElementById(
        "sticky-device-container"
      );
      if (deviceContainer) {
        const { expanded } = this.props;
        if (deviceContainer.scrollWidth > deviceContainer.clientWidth) {
          this.stickySectionHeader.style.width = `calc(100% - ${
            expanded ? 310 : 161
          }px)`;
          this.stickySectionHeader.style.left = expanded ? "288px" : "138px";
        } else {
          const addDeviceButtonContainer = document.getElementById(
            "add-device-button-container"
          );
          if (addDeviceButtonContainer) {
            this.stickySectionHeader.style.width = `${
              addDeviceButtonContainer.getBoundingClientRect().right -
              (expanded ? 288 : 138)
            }px`;
            this.stickySectionHeader.style.left = expanded ? "288px" : "138px";
          }
        }
      }
      document.body.appendChild(this.stickySectionHeader);
      document.addEventListener("scroll", this.stickeySectionScrollListener);
      if (
        localStorage.getItem(
          `test_pass_${this.props.testPass.id}_show_build`
        ) === "true"
      ) {
        this.setShowBuild();
      }
    } catch (err) {
      err.message = `Pass Execution Mount Error: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.testPass.id != this.props.testPass.id) {
      const { testPass } = this.props;
      return testPass;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If we have a snapshot value, we've just added new items.
    // Adjust scroll so these new items don't push the old ones out of view.
    // (snapshot here is the value returned from getSnapshotBeforeUpdate)
    if (snapshot !== null) {
      const testPass = snapshot;
      const platformsPresent = [];

      const deviceTypes = testPass.test_devices
        .map((testDevice) => testDevice.device_platform_id)
        .sort();

      for (let x = 1; x <= 6; x++) {
        platformsPresent.push(!!deviceTypes.includes(x));
      }
      this.setState(
        {
          testPass,
          testCases: testPass.test_cases,
          testDevices: testPass.test_devices,
          platformsPresent,
          currentPlatform: deviceTypes[0],
          shortDevice: [1, 2, 5, 6].indexOf(deviceTypes[0]) !== -1,
        },
        () => {
          const { shortDevice } = this.state;
          const height =
            (this.state.shortDevice ? 50 : 70) +
            (this.state.showBuild ? 38 : 0);
          document.querySelector(
            ".pass-page-heading"
          ).style.paddingBottom = `${height}px`;
          const menu = document.querySelector(".test-pass-menu-hover");
          menu.style.marginBottom = `${-(height + 70)}px`;
          menu.style.top = `${height + 20}px`;
          this.updateDeviceProgressPercentDisplays(
            this.state.testPass.device_percents
          );
          if (this.stickySectionHeader) {
            this.stickySectionHeader.style.top = `${height + 136}px`;
          }
          this.setPassProgress(this.state.testPass.progress);
        }
      );
      // connect to new test pass channel
      this.testPassChannel?.unsubscribe();
      this.testPassChannel = this.props.cable.subscriptions.create(
        { channel: "TestPassChannel", test_pass_id: testPass.id },
        {
          received: (data) => {
            if (data.test_devices) {
              this.setState({ testDevices: data.test_devices });
            } else if (data.test_cases) {
              this.setState({ testCases: data.test_cases });
            } else if (data.test_pass) {
              const deviceTypes = data.test_pass.test_devices
                .map((testDevice) => testDevice.device_platform_id)
                .sort();
              const platformsPresent = [];
              for (let x = 1; x <= 6; x++) {
                platformsPresent.push(!!deviceTypes.includes(x));
              }
              this.setState(
                {
                  testDevices: data.test_pass.test_devices,
                  testPass: data.test_pass,
                  platformsPresent,
                },
                () => {
                  window.dispatchEvent(new Event("resize"));
                }
              );
              if (data.device_percents) {
                this.updateDeviceProgressPercentDisplays(data.device_percents);
              }
              if (data.progress) {
                this.setPassProgress(data.progress);
              }
            } else if (data.test_step) {
              if (this.refs[`step_${data.test_step.id}`]) {
                this.refs[`step_${data.test_step.id}`].handleCableUpdate(
                  data.test_step.test_pass_results
                );
                if (
                  data.test_device &&
                  document.getElementById(`device_${data.test_device.id}_text`)
                ) {
                  const percentText = document.getElementById(
                    `device_${data.test_device.id}_text`
                  );
                  percentText.innerText = `${data.test_device.percent}%`;
                  percentText.style.background = `linear-gradient(to right, white ${data.test_device.percent}%, black 0%)`;
                  percentText.style.webkitBackgroundClip = "text";
                  document.getElementById(
                    `device_${data.test_device.id}_bar`
                  ).style.width = `${data.test_device.percent}%`;
                }
              }
              if (data.progress) {
                this.setPassProgress(data.progress);
              }
            } else if (data.copy_progress) {
              this.setPassProgress(data.copyProgress);
              if (data.device_percents) {
                this.updateDeviceProgressPercentDisplays(data.device_percents);
              }
            } else if (data.test_device_build) {
              const elem = document.getElementById(
                `test-device-${data.test_device_build.id}-build-input`
              );
              if (elem) {
                elem.value = data.test_device_build.build;
              }
              const { testDevices } = this.state;
              const index = testDevices
                .map((t) => t.id)
                .indexOf(data.test_device_build.id);
              if (index !== -1) {
                testDevices.splice(index, 1, data.test_device_build);
                this.setState({ testDevices });
              }
            }
          },
        }
      );
      ///
      if (testPass.name !== this.state.testPass.name) {
        this.setState({ editPassTitle: false });
      }
      if (
        localStorage.getItem(`test_pass_${testPass.id}_show_build`) == "true" &&
        !this.state.showBuild
      ) {
        this.setShowBuild();
      }
    }
  }

  componentWillUnmount() {
    this.testPassChannel.unsubscribe();
    removeEventListener("resize", this.resize);
    removeEventListener("keydown", this.passArrowKeys);
    removeEventListener("scroll", this.stickeySectionScrollListener);
    document.body.removeChild(this.stickySectionHeader);
  }

  updateDeviceProgressPercentDisplays = (device_percents) => {
    /* instead of passing data down to TestPassDevices and letting it update itself,
     * this component is manually updating the DOM here. this is a incredibly bad anti-pattern,
     * and there's no reason it should be doing it like this. currently DRYing up the six-ish
     * places where this code is repeated and putting it in this one component method for now,
     * but a big teardown and rebuild of the entire TestPass view is required.
     */
    for (const device_percent_info of device_percents) {
      const {
        assigned_user,
        device_id,
        device_platform,
        device_progress,
        device_type,
        percent,
        title,
      } = device_percent_info;
      const textId = `device_${device_id}_text`;
      const progressId = `device_${device_id}_progress_elem`;
      const progressBarId = `device_${device_id}_bar`;
      const text = document.getElementById(textId);
      const progress = document.getElementById(progressId);
      const progressBar = document.getElementById(progressBarId);
      if (text && progress && progressBar) {
        text.innerText = `${percent}%`;
        text.style.background = `linear-gradient(to right, white ${percent}%, black 0%)`;
        text.style.webkitBackgroundClip = "text";
        progress.value = percent;
        progressBar.style.width = `${percent}%`;
        progress.innerText = `${percent}%`;
        progress.value = percent;
      }
    }
  };

  setCurrentColumn = (currentColumn) => {
    this.setState({ currentColumn });
  };

  setPassProgress = (progress) => {
    try {
      var progress = Object.values(progress);
      const totalSteps = progress.reduce((a, b) => a + b, 0);
      const totalStepsElem = document.getElementById("total-steps");
      const totalStepsCompletedElem = document.getElementById(
        "total-steps-completed"
      );
      const untestedProgress = document.getElementById("all-untested-progress");
      const passProgress = document.getElementById("all-pass-progress");
      const failProgress = document.getElementById("all-fail-progress");
      const blockedProgress = document.getElementById("all-blocked-progress");
      const retestProgress = document.getElementById("all-retest-progress");
      const naProgress = document.getElementById("all-na-progress");
      let ariaText = `${
        totalSteps - progress[0]
      } steps completed out of ${totalSteps}. `;
      if (totalStepsElem) {
        totalStepsElem.innerText = totalSteps;
      }
      if (totalStepsCompletedElem) {
        totalStepsCompletedElem.innerText = totalSteps - progress[0];
      }
      if (untestedProgress) {
        untestedProgress.style.width = `${(progress[0] / totalSteps) * 100}%`;
        untestedProgress.setAttribute(
          "data-message",
          `Untested - ${progress[0]}`
        );
        if (progress[0] > 0) {
          ariaText = ariaText.concat(`Untested: ${progress[0]}. `);
        }
      }
      if (passProgress) {
        passProgress.style.width = `${(progress[1] / totalSteps) * 100}%`;
        passProgress.setAttribute("data-message", `Pass - ${progress[1]}`);
        if (progress[1] > 0) {
          ariaText = ariaText.concat(`Pass: ${progress[1]}. `);
        }
      }
      if (failProgress) {
        failProgress.style.width = `${(progress[2] / totalSteps) * 100}%`;
        failProgress.setAttribute("data-message", `Fail - ${progress[2]}`);
        if (progress[2] > 0) {
          ariaText = ariaText.concat(`Fail: ${progress[2]}. `);
        }
      }
      if (blockedProgress) {
        blockedProgress.style.width = `${(progress[3] / totalSteps) * 100}%`;
        blockedProgress.setAttribute(
          "data-message",
          `Blocked - ${progress[3]}`
        );
        if (progress[3] > 0) {
          ariaText = ariaText.concat(`Blocked: ${progress[3]}. `);
        }
      }
      if (retestProgress) {
        retestProgress.style.width = `${(progress[4] / totalSteps) * 100}%`;
        retestProgress.setAttribute("data-message", `Retest - ${progress[4]}`);
        if (progress[4] > 0) {
          ariaText = ariaText.concat(`Retest: ${progress[4]}. `);
        }
      }
      if (naProgress) {
        naProgress.style.width = `${(progress[5] / totalSteps) * 100}%`;
        naProgress.setAttribute("data-message", `N/A - ${progress[5]}`);
        if (progress[5] > 0) {
          ariaText = ariaText.concat(`N/A: ${progress[5]}.`);
        }
      }
      document
        .getElementById("all-progress-bar")
        .setAttribute("aria-label", ariaText);
    } catch (err) {
      err.message = `Pass Progress Error Frontend: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
  };

  handleResultChange = (e) => {
    const v = e.target.value;
    const x = document.getElementById(e.target.id);
    x.style.backgroundColor =
      v === "0"
        ? "white"
        : v === "1"
        ? "#9DDEA8"
        : v === "2"
        ? "#DC918D"
        : v === "3"
        ? "#F5EE89"
        : "#519acc";
    x.style.color = v === "0" ? "transparent" : "black";
    const elementIds = e.target.id.split("-");
    axios
      .patch("/update_test_result", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        id: parseInt(elementIds[4]),
        index: parseInt(elementIds[5]),
        value: parseInt(v),
      })
      .then((res) => {})
      .catch((err) => {
        err.message = `Test Result Update Error: ${err.message}`;
        console.log(err);
        Swal.fire({
          title: "Test Case Update Error",
          text: "There was a problem updating this Test Case Result",
          customClass: "swal2-error-modal",
        });
      });
  };

  handleExpandSteps = (e) => {
    try {
      const { index } = e.target.dataset;
      const leftRowArrow = document.getElementById(
        `test-case-title-arrow-${index}`
      );
      const elems = document.querySelectorAll(`.test-case-group-${index}`);
      const resultElems = document.querySelectorAll(
        `.test-case-result-group-${index}`
      );
      if (elems.length > 0 && elems[0].style.display === "none") {
        leftRowArrow.style.transform = "rotate(0deg)";
        e.target.setAttribute("aria-expanded", "true");
        elems.forEach((elem, i) => {
          resultElems[i].style.display = "block";
          elem.style.display = "block";
        });
      } else {
        leftRowArrow.style.transform = "rotate(-90deg)";
        e.target.setAttribute("aria-expanded", "false");
        elems.forEach((elem, i) => {
          resultElems[i].style.display = "none";
          elem.style.display = "none";
        });
      }
    } catch (err) {
      err.message = `Test Pass Expand Error: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
  };

  handlePlatformChange = (e) => {
    try {
      this.setState(
        {
          currentPlatform: parseInt(e.target.attributes.value.value),
          shortDevice:
            [1, 2, 5, 6].indexOf(parseInt(e.target.attributes.value.value)) !==
            -1,
        },
        () => {
          const item = document.querySelector(".test-pass-menu-hover");
          if (item) {
            const { shortDevice } = this.state;
            var height =
              (this.state.shortDevice ? 50 : 70) +
              (this.state.showBuild ? 38 : 0);
            document.querySelector(
              ".pass-page-heading"
            ).style.paddingBottom = `${height}px`;
            item.style.marginBottom = `${-(height + 70)}px`;
            item.style.top = `${height + 20}px`;
          }
          if (this.stickySectionHeader) {
            this.stickySectionHeader.style.top = `${height + 136}px`;
          }
        }
      );
      window.dispatchEvent(new Event("resize"));
    } catch (err) {
      err.message = `Pass Platform Change Error${err.message}`;
      console.error(err);
    }
  };

  deviceLabelMouseEnter = (e) => {
    if (e.target.scrollWidth > e.target.clientWidth) {
      const rect = e.target.getBoundingClientRect();
      const popUpElem = document.createElement("div");
      popUpElem.setAttribute("id", `${e.target.id}-pop-up`);
      popUpElem.style.position = "fixed";
      popUpElem.style.top = `${rect.top - 34}px`;
      popUpElem.style.zIndex = "10";
      popUpElem.style.width = `${e.target.scrollWidth + 10}px`;
      // popUpElem.style.height = "30px"
      popUpElem.style.padding = "0px 6px";
      popUpElem.style.backgroundColor = "#519acc";
      popUpElem.style.color = "white";
      popUpElem.style.fontWeight = "600";
      popUpElem.style.fontSize = "13px";
      popUpElem.style.fontFamily = "Arial";
      popUpElem.style.borderRadius = "5px";
      popUpElem.style.textAlign = "center";
      popUpElem.innerHTML = `${e.target.innerHTML}`;
      const element = document.querySelector("body");
      element.appendChild(popUpElem);
      // need to set left after element is appended to parent element
      popUpElem.style.left = `${
        rect.left - (popUpElem.clientWidth - e.target.clientWidth)
      }px`;
    }
  };

  deviceLabelMouseLeave = (e) => {
    if (e.target.scrollWidth > e.target.clientWidth) {
      const popUpElem = document.getElementById(`${e.target.id}-pop-up`);
      if (popUpElem) {
        const element = document.querySelector("body");
        element.removeChild(popUpElem);
      }
    }
  };

  handleTestStepCommentsClick = (e) => {
    const existingComments = !!e.target.dataset.comments;
    this.props.setModal(true, "test-step-comments", {
      currentUserId: this.props.currentUser.id,
      modalRef: this.hoverDialogRef,
      device: e.target.dataset.device,
      testPassId: this.state.testPass.id,
      testStepId: e.target.dataset.stepId,
      testCaseNumber: e.target.dataset.testCaseNumber,
      existingComments,
      onMouseDown: this.props.hideFocus,
      onBlur: this.props.showFocus,
      customClass: "test-step-comments-modal",
      currentResultSelector: `.test-case-result-${e.target.dataset.index1}-${e.target.dataset.index2}-${this.state.currentColumn}`,
    });
  };

  commentMouseEnter = (e) => {
    if (e.target.dataset.comments) {
      e.target.addEventListener("wheel", this.passHoverWheel, {
        passive: false,
      });
      const id = e.target.dataset.stepId;
      const { device } = e.target.dataset;
      const count = parseInt(e.target.dataset.count);
      const rect = e.target.getBoundingClientRect();
      const elem = document.getElementById("test-pass-hover-dialog");
      elem.style.height = "104px";
      const elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "1";
      elemContainer.style.transform = `translate(${rect.x - 400}px, ${
        rect.y - 157
      }px)`;
      const innerElem = document.getElementById("test-pass-hover-dialog-inner");
      innerElem.style.transitionDelay = "0s";
      innerElem.style.transition = "opacity 0s";
      innerElem.style.opacity = "0";
      this.hoverDialogRef.current.getTestStepComments(id, device, count);
    }
  };

  commentMouseLeave = (e) => {
    if (e.target.dataset.comments) {
      e.target.removeEventListener("wheel", this.passHoverWheel, {
        passive: false,
      });
      if (this.hoverDialogRef.current.cancelRequest !== null) {
        this.hoverDialogRef.current.cancelRequest();
        this.hoverDialogRef.current.cancelRequest = null;
      }
      const elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "0";
    }
  };

  bugsMouseEnter = (e) => {
    if (e.target.dataset.bugs) {
      e.target.addEventListener("wheel", this.passHoverWheel, {
        passive: false,
      });
      const id = e.target.dataset.stepId;
      const { device } = e.target.dataset;
      const rect = e.target.getBoundingClientRect();
      const elem = document.getElementById("test-pass-hover-dialog");
      elem.style.height = "68px";
      const elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "1";
      elemContainer.style.transform = `translate(${rect.x - 400}px, ${
        rect.y - 157
      }px)`;
      const innerElem = document.getElementById("test-pass-hover-dialog-inner");
      innerElem.style.transitionDelay = "0s";
      innerElem.style.transition = "opacity 0s";
      innerElem.style.opacity = "0";
      this.hoverDialogRef.current.getTestStepBugs(id, device);
    }
  };

  bugsMouseLeave = (e) => {
    if (e.target.dataset.bugs) {
      e.target.removeEventListener("wheel", this.passHoverWheel, {
        passive: false,
      });
      if (this.hoverDialogRef.current.cancelRequest !== null) {
        this.hoverDialogRef.current.cancelRequest();
        this.hoverDialogRef.current.cancelRequest = null;
      }
      const elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "0";
    }
  };

  a11yMouseEnter = (e) => {
    if (e.target.dataset.wcags) {
      e.target.addEventListener("wheel", this.passHoverWheel, {
        passive: false,
      });
      const id = e.target.dataset.stepId;
      const rect = e.target.getBoundingClientRect();
      const elem = document.getElementById("test-pass-hover-dialog");
      elem.style.height = "68px";
      const elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "1";
      elemContainer.style.transform = `translate(${rect.x - 400}px, ${
        rect.y - 157
      }px)`;
      const innerElem = document.getElementById("test-pass-hover-dialog-inner");
      innerElem.style.transitionDelay = "0s";
      innerElem.style.transition = "opacity 0s";
      innerElem.style.opacity = "0";
      this.hoverDialogRef.current.getTestStepA11y(id);
    }
  };

  a11yMouseLeave = (e) => {
    if (e.target.dataset.wcags) {
      e.target.removeEventListener("wheel", this.passHoverWheel, {
        passive: false,
      });
      if (this.hoverDialogRef.current.cancelRequest !== null) {
        this.hoverDialogRef.current.cancelRequest();
        this.hoverDialogRef.current.cancelRequest = null;
      }
      const elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "0";
    }
  };

  handleTestStepBugsClick = (e) => {
    const existingBugs = !!e.target.dataset.bugs;
    this.props.setModal(true, "test-step-bugs", {
      customClass: "ts-bugs-modal",
      currentResultSelector: `.test-case-result-${e.target.dataset.index1}-${e.target.dataset.index2}-${this.state.currentColumn}`,
      testPassId: this.state.testPass.id,
      device: e.target.dataset.device,
      projectId: this.state.project.id,
      projectIdentifier: this.state.project.identifier,
      testStepId: e.target.dataset.stepId,
      testCaseNumber: e.target.dataset.testCaseNumber,
      existingBugs,
    });
  };

  handleTestStepA11yClick = (e) => {
    const existingWcags = !!e.target.dataset.wcags;
    this.props.setModal(true, "test-step-a11y", {
      customClass: "ts-bugs-modal",
      currentResultSelector: `.test-case-result-${e.target.dataset.index1}-${e.target.dataset.index2}-${this.state.currentColumn}`,
      testPassId: this.state.testPass.id,
      projectId: this.state.project.id,
      a11yOptions: this.props.a11yOptions,
      testStepId: e.target.dataset.stepId,
      testCaseNumber: e.target.dataset.testCaseNumber,
      existingWcags,
    });
  };

  handleTestPassDelete = () => {
    this.props.handleTestPassDelete();
  };

  setResultOutline = (left, top, height, row, column) => {
    var top = top;
    var left = left;
    var height = height;
    this.copiedResultsRowRange = [row, row];
    this.copiedResultsColumnRange = [column, column];
    this.setState(
      {
        resultOutlineActive: true,
        resultOutlineLeft: left,
        resultOutlineTop: top,
        resultOutlineHeight: height,
        resultOutlineRow: row,
        resultOutlineColumn: column,
        copiedResultsRowRange: [row, row],
        copiedResultsColumnRange: [column, column],
      },
      () => {
        const elem = document.getElementById("result-outline");
        elem.style.width = "150px";
        elem.style.height = `${height}px`;
        elem.style.left = `${left}px`;
        elem.style.top = `${top}px`;
        window.addEventListener("click", this.handleCancelResultOutline);
      }
    );
  };

  handleCancelResultOutline = (e) => {
    if (
      e.target.id !== "result-outline" &&
      e.target.id !== "result-copy-button" &&
      !e.target.id.includes("result-outline-button")
    ) {
      this.setState({
        resultOutlineActive: false,
        resultOutlineLeft: 0,
        resultOutlineTop: 0,
        resultDragType: "outline",
      });
      window.removeEventListener("click", this.handleCancelResultOutline);
    }
  };

  handleDragScroll = (e) => {
    if (
      e.clientX - this.testPassDeviceContainer.left < 50 &&
      e.clientX - this.testPassDeviceContainer.left > 0
    ) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          const elem = document.getElementById("left-test-pass-container");
          if (elem.scrollWidth > elem.clientWidth) {
            const stickyElem = document.getElementById(
              "sticky-device-container"
            );
            elem.scrollLeft -= 5;
            stickyElem.scrollLeft -= 5;
          }
        }, 10);
      }
    } else if (
      this.testPassContainer.right - e.clientX < 50 &&
      this.testPassContainer.right - e.clientX > 0
    ) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          const elem = document.getElementById("left-test-pass-container");
          if (elem.scrollWidth > elem.clientWidth) {
            const stickyElem = document.getElementById(
              "sticky-device-container"
            );
            elem.scrollLeft += 5;
            stickyElem.scrollLeft += 5;
          }
        }, 10);
      }
    } else if (window.innerHeight - e.clientY < 120) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          window.scrollBy(0, 5);
        }, 10);
      }
    } else if (window.innerHeight - (window.innerHeight - e.clientY) < 80) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          window.scrollBy(0, -5);
        }, 10);
      }
    } else if (this.dragTimer) {
      clearInterval(this.dragTimer);
      this.dragTimer = null;
    }
  };

  onResultDragStart = (e) => {
    window.addEventListener("dragover", this.handleDragScroll);
    var elem = e.target;
    elem.style.opacity = "0";
    e.dataTransfer.setDragImage(elem, 0, 0);
    if (
      elem.id === "result-copy-button" &&
      this.state.resultDragType != "copy"
    ) {
      this.validCopy = false;
      setTimeout(() => {
        this.setState({
          draggingResult: true,
          resultDragType: "copy",
        });
      }, 100);
    } else {
      setTimeout(() => {
        this.setState({
          draggingResult: true,
        });
      }, 100);
    }
  };

  onResultDragEnd = (e) => {
    if (this.dragTimer) {
      clearInterval(this.dragTimer);
      this.dragTimer = null;
    }
    window.removeEventListener("dragover", this.handleDragScroll);
    if (this.state.resultDragType === "outline") {
      this.setState({
        draggingResult: false,
        resultDragType: "copy",
      });
      e.target.style.opacity = "1";
    } else if (this.state.resultDragType === "copy" && this.validCopy) {
      var testStepsArray = [];
      var testResultsArray = [];
      this.state.testPass.test_cases.forEach((tc) => {
        testStepsArray = testStepsArray.concat(tc.test_steps);
      });
      testStepsArray.forEach((ts) => {
        testResultsArray = testResultsArray.concat(ts.test_pass_results);
      });
      testResultsArray = testResultsArray.filter(
        (x) => x.test_pass_id === this.state.testPass.id
      );
      if (
        this.copiedResultsRowRange[0] === this.copiedResultsRowRange[1] &&
        this.copiedResultsColumnRange[0] === this.copiedResultsColumnRange[1] &&
        (this.pasteResultColumn != this.copiedResultsColumnRange[0] ||
          this.pasteResultRow != this.copiedResultsRowRange[0])
      ) {
        var copiedResultId = testResultsArray[this.copiedResultsRowRange[0]].id;
        var copiedIndex = this.copiedResultsColumnRange[0];
        var pasteIndexRange =
          this.pasteResultColumn > this.copiedResultsColumnRange[0]
            ? [this.copiedResultsColumnRange[0], this.pasteResultColumn]
            : [this.pasteResultColumn, this.copiedResultsColumnRange[0]];
        var pasteRowRange =
          this.pasteResultRow > this.copiedResultsRowRange[0]
            ? [this.copiedResultsRowRange[0], this.pasteResultRow]
            : [this.pasteResultRow, this.copiedResultsRowRange[0]];
        var pasteRowIds = [];
        for (var x = pasteRowRange[0]; x <= pasteRowRange[1]; x++) {
          pasteRowIds.push(testResultsArray[x].id);
        }
        axios
          .patch(`/copy_test_results`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
            copied_row_ids: [copiedResultId],
            copied_indexes: [copiedIndex],
            paste_index_range: pasteIndexRange,
            paste_row_ids: pasteRowIds,
          })
          .then((res) => {
            M.toast({
              html: `Results copied`,
              displayLength: 2000,
              classes: "green darken-1 shift-toast",
            });
          })
          .catch((err) => console.error(err));
      } else if (
        this.copiedResultsRowRange[0] != this.copiedResultsRowRange[1] &&
        this.copiedResultsColumnRange[0] === this.copiedResultsColumnRange[1] &&
        this.pasteResultColumn != this.copiedResultsColumnRange[0]
      ) {
        var copiedRowRange = this.copiedResultsRowRange.sort((a, b) => a - b);
        var copiedResultIds = [];
        for (var x = copiedRowRange[0]; x <= copiedRowRange[1]; x++) {
          copiedResultIds.push(testResultsArray[x].id);
        }
        var copiedIndex = this.copiedResultsColumnRange[0];
        var pasteIndexRange =
          this.pasteResultColumn > this.copiedResultsColumnRange[0]
            ? [this.copiedResultsColumnRange[0], this.pasteResultColumn]
            : [this.pasteResultColumn, this.copiedResultsColumnRange[0]];
        axios
          .patch(`/copy_test_results`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
            copied_row_ids: copiedResultIds,
            copied_indexes: [copiedIndex],
            paste_index_range: pasteIndexRange,
            paste_row_ids: [],
          })
          .then((res) => {
            M.toast({
              html: `Results copied`,
              displayLength: 2000,
              classes: "green darken-1 shift-toast",
            });
          })
          .catch((err) => console.error(err));
      } else if (
        this.copiedResultsRowRange[0] === this.copiedResultsRowRange[1] &&
        this.copiedResultsColumnRange[0] != this.copiedResultsColumnRange[1] &&
        this.pasteResultRow != this.copiedResultsRowRange[0]
      ) {
        var copiedResultIndexes = this.copiedResultsColumnRange.sort(
          (a, b) => a - b
        );
        var copiedResultId = testResultsArray[this.copiedResultsRowRange[0]].id;
        var pasteRowRange =
          this.pasteResultRow > this.copiedResultsRowRange[0]
            ? [this.copiedResultsRowRange[0], this.pasteResultRow]
            : [this.pasteResultRow, this.copiedResultsRowRange[0]];
        var pasteRowIds = [];
        for (var x = pasteRowRange[0]; x <= pasteRowRange[1]; x++) {
          pasteRowIds.push(testResultsArray[x].id);
        }
        axios
          .patch(`/copy_test_results`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
            copied_row_ids: [copiedResultId],
            copied_indexes: copiedResultIndexes,
            paste_index_range: [],
            paste_row_ids: pasteRowIds,
          })
          .then((res) => {
            M.toast({
              html: `Results copied`,
              displayLength: 2000,
              classes: "green darken-1 shift-toast",
            });
          })
          .catch((err) => console.error(err));
      }
      this.setState(
        {
          draggingResult: false,
          resultDragType: "outline",
          resultOutlineActive: false,
        },
        () => {
          window.removeEventListener("click", this.handleCancelResultOutline);
        }
      );
      e.target.style.opacity = "1";
    } else if (!this.validCopy) {
      this.setState(
        {
          draggingResult: false,
          resultDragType: "outline",
          resultOutlineActive: false,
        },
        () => {
          window.removeEventListener("click", this.handleCancelResultOutline);
        }
      );
      e.target.style.opacity = "1";
    }
  };

  onResultDragEnter = (e) => {
    var elem = e.target;
    var columnRange = this.copiedResultsColumnRange;
    var rowRange = this.copiedResultsRowRange;
    if (this.state.resultDragType === "outline") {
      if (
        elem.dataset.row == this.state.resultOutlineRow &&
        elem.dataset.column != this.state.resultOutlineColumn
      ) {
        var outline = document.getElementById("result-outline");
        if (e.target.offsetParent.offsetLeft > this.state.resultOutlineLeft) {
          this.copiedResultsRowRange = [
            this.state.resultOutlineRow,
            this.state.resultOutlineRow,
          ];
          this.copiedResultsColumnRange = [
            this.state.resultOutlineColumn,
            parseInt(elem.dataset.column),
          ];
          var newWidth =
            e.target.offsetParent.offsetLeft +
            150 -
            this.state.resultOutlineLeft;
          outline.style.width = `${newWidth}px`;
          outline.style.height = `${this.state.resultOutlineHeight}px`;
          outline.style.top = `${this.state.resultOutlineTop}px`;
        } else {
          this.copiedResultsRowRange = [
            this.state.resultOutlineRow,
            this.state.resultOutlineRow,
          ];
          this.copiedResultsColumnRange = [
            parseInt(elem.dataset.column),
            this.state.resultOutlineColumn,
          ];
          var newWidth =
            this.state.resultOutlineLeft +
            150 -
            e.target.offsetParent.offsetLeft;
          outline.style.left = `${e.target.offsetParent.offsetLeft}px`;
          outline.style.width = `${newWidth}px`;
          outline.style.height = `${this.state.resultOutlineHeight}px`;
          outline.style.top = `${this.state.resultOutlineTop}px`;
        }
      } else if (
        elem.dataset.row != this.state.resultOutlineRow &&
        elem.dataset.column == this.state.resultOutlineColumn
      ) {
        var outline = document.getElementById("result-outline");
        if (e.target.offsetParent.offsetTop > this.state.resultOutlineTop) {
          this.copiedResultsRowRange = [
            this.state.resultOutlineRow,
            parseInt(elem.dataset.row),
          ];
          this.copiedResultsColumnRange = [
            this.state.resultOutlineColumn,
            this.state.resultOutlineColumn,
          ];
          var newHeight =
            e.target.offsetParent.offsetTop +
            e.target.offsetParent.clientHeight -
            this.state.resultOutlineTop;
          outline.style.height = `${newHeight}px`;
          outline.style.width = `150px`;
          outline.style.left = `${this.state.resultOutlineLeft}px`;
        } else {
          this.copiedResultsRowRange = [
            parseInt(elem.dataset.row),
            this.state.resultOutlineRow,
          ];
          this.copiedResultsColumnRange = [
            this.state.resultOutlineColumn,
            this.state.resultOutlineColumn,
          ];
          var newHeight =
            this.state.resultOutlineTop +
            this.state.resultOutlineHeight -
            e.target.offsetParent.offsetTop;
          outline.style.top = `${e.target.offsetParent.offsetTop}px`;
          outline.style.height = `${newHeight}px`;
          outline.style.width = `150px`;
          outline.style.left = `${this.state.resultOutlineLeft}px`;
        }
      } else if (
        elem.dataset.row == this.state.resultOutlineRow &&
        elem.dataset.column == this.state.resultOutlineColumn
      ) {
        this.copiedResultsRowRange = [
          this.state.resultOutlineRow,
          this.state.resultOutlineRow,
        ];
        this.copiedResultsColumnRange = [
          this.state.resultOutlineColumn,
          this.state.resultOutlineColumn,
        ];
        var outline = document.getElementById("result-outline");
        var newHeight =
          e.target.offsetParent.offsetTop +
          e.target.offsetParent.clientHeight -
          this.state.resultOutlineTop;
        var newWidth =
          e.target.offsetParent.offsetLeft + 150 - this.state.resultOutlineLeft;
        outline.style.left = `${this.state.resultOutlineLeft}px`;
        outline.style.top = `${this.state.resultOutlineTop}px`;
        outline.style.width = `${newWidth}px`;
        outline.style.height = `${newHeight}px`;
      }
    } else if (this.state.resultDragType === "copy") {
      if (columnRange[0] === columnRange[1] && rowRange[0] === rowRange[1]) {
        if (
          elem.dataset.row == this.state.resultOutlineRow &&
          elem.dataset.column != this.state.resultOutlineColumn
        ) {
          this.validCopy = true;
          var outline = document.getElementById("result-outline");
          this.pasteResultRow = this.state.resultOutlineRow;
          this.pasteResultColumn = parseInt(elem.dataset.column);
          if (e.target.offsetParent.offsetLeft > this.state.resultOutlineLeft) {
            var newWidth =
              e.target.offsetParent.offsetLeft +
              150 -
              this.state.resultOutlineLeft;
            outline.style.width = `${newWidth}px`;
            outline.style.height = `${this.state.resultOutlineHeight}px`;
            outline.style.top = `${this.state.resultOutlineTop}px`;
          } else {
            var newWidth =
              this.state.resultOutlineLeft +
              150 -
              e.target.offsetParent.offsetLeft;
            outline.style.left = `${e.target.offsetParent.offsetLeft}px`;
            outline.style.width = `${newWidth}px`;
            outline.style.height = `${this.state.resultOutlineHeight}px`;
            outline.style.top = `${this.state.resultOutlineTop}px`;
          }
        } else if (
          elem.dataset.row != this.state.resultOutlineRow &&
          elem.dataset.column == this.state.resultOutlineColumn
        ) {
          this.validCopy = true;
          var outline = document.getElementById("result-outline");
          this.pasteResultRow = parseInt(elem.dataset.row);
          this.pasteResultColumn = this.state.resultOutlineColumn;
          if (e.target.offsetParent.offsetTop > this.state.resultOutlineTop) {
            var newHeight =
              e.target.offsetParent.offsetTop +
              e.target.offsetParent.clientHeight -
              this.state.resultOutlineTop;
            outline.style.height = `${newHeight}px`;
            outline.style.width = `150px`;
            outline.style.left = `${this.state.resultOutlineLeft}px`;
          } else {
            var newHeight =
              this.state.resultOutlineTop +
              this.state.resultOutlineHeight -
              e.target.offsetParent.offsetTop;
            outline.style.top = `${e.target.offsetParent.offsetTop}px`;
            outline.style.height = `${newHeight}px`;
            outline.style.width = `150px`;
            outline.style.left = `${this.state.resultOutlineLeft}px`;
          }
        } else if (
          elem.dataset.row == this.state.resultOutlineRow &&
          elem.dataset.column == this.state.resultOutlineColumn
        ) {
          this.validCopy = true;
          var outline = document.getElementById("result-outline");
          this.pasteResultRow = this.state.resultOutlineRow;
          this.pasteResultColumn = this.state.resultOutlineColumn;
          var newHeight =
            e.target.offsetParent.offsetTop +
            e.target.offsetParent.clientHeight -
            this.state.resultOutlineTop;
          var newWidth =
            e.target.offsetParent.offsetLeft +
            150 -
            this.state.resultOutlineLeft;
          outline.style.left = `${this.state.resultOutlineLeft}px`;
          outline.style.top = `${this.state.resultOutlineTop}px`;
          outline.style.width = `${newWidth}px`;
          outline.style.height = `${newHeight}px`;
        }
      } else if (columnRange[0] === columnRange[1]) {
        var outline = document.getElementById("result-outline");
        this.validCopy = true;
        this.pasteResultColumn = parseInt(elem.dataset.column);
        if (elem.dataset.column != this.state.resultOutlineColumn) {
          if (e.target.offsetParent.offsetLeft > this.state.resultOutlineLeft) {
            var newWidth =
              e.target.offsetParent.offsetLeft +
              150 -
              this.state.resultOutlineLeft;
            outline.style.width = `${newWidth}px`;
          } else {
            var newWidth =
              this.state.resultOutlineLeft +
              150 -
              e.target.offsetParent.offsetLeft;
            outline.style.left = `${e.target.offsetParent.offsetLeft}px`;
            outline.style.width = `${newWidth}px`;
          }
        } else {
          outline.style.left = `${this.state.resultOutlineLeft}px`;
          outline.style.width = `150px`;
        }
      } else if (rowRange[0] === rowRange[1] && elem.dataset.row) {
        var outline = document.getElementById("result-outline");
        this.validCopy = true;
        this.pasteResultRow = parseInt(elem.dataset.row);
        if (elem.dataset.row != this.state.resultOutlineRow) {
          if (e.target.offsetParent.offsetTop > this.state.resultOutlineTop) {
            var newHeight =
              e.target.offsetParent.offsetTop +
              e.target.offsetParent.clientHeight -
              this.state.resultOutlineTop;
            outline.style.height = `${newHeight}px`;
          } else {
            var newHeight =
              this.state.resultOutlineTop +
              this.state.resultOutlineHeight -
              e.target.offsetParent.offsetTop;
            outline.style.top = `${e.target.offsetParent.offsetTop}px`;
            outline.style.height = `${newHeight}px`;
          }
        } else {
          outline.style.top = `${this.state.resultOutlineTop}px`;
          outline.style.height = `${this.state.resultOutlineHeight}px`;
        }
      }
    }
  };

  processText(text) {
    if (text) {
      text = text.replace(/\n/g, "<br>");
      text = text.replace(/<div>/g, "<p>");
      text = text.replace(/<\/div>/g, "</p>");
    }
    return text;
  }

  sectionHeadingOnKeyDown = (e) => {
    const data = e.target.dataset;
    if (e.keyCode === 40) {
      // down arrow
      e.preventDefault();
      var expanded = e.target.getAttribute("aria-expanded");
      if (expanded == "true") {
        document
          .querySelector(
            `.test-case-result-${data.index}-0-${this.state.currentColumn}`
          )
          .focus();
      } else if (data.lastSection == "false") {
        document
          .querySelector(`#test-case-${parseInt(data.index) + 1} > button`)
          .focus();
      } else {
      }
    } else if (e.keyCode === 38) {
      e.preventDefault();
      if (data.index == "0") {
        document
          .querySelector(`h3.device-index-${this.state.currentColumn}`)
          .focus();
      } else {
        const previousSection = document.querySelector(
          `#test-case-${parseInt(data.index) - 1} > button`
        );
        var expanded = previousSection.getAttribute("aria-expanded");
        if (expanded == "true") {
          const previousStepsLength = parseInt(data.previousStepsLength);
          document
            .querySelector(
              `.test-case-result-${data.index - 1}-${previousStepsLength - 1}-${
                this.state.currentColumn
              }`
            )
            .focus();
        } else {
          previousSection.focus();
        }
      }
    }
  };

  sectionHeadingFocus = (e) => {
    e.target.setAttribute("aria-live", "polite");
  };

  sectionHeadingBlur = (e) => {
    e.target.setAttribute("aria-live", "off");
  };

  keyboardShortcuts = (index1, index2) => {
    this.props.setModal(true, "keyboard-shortcuts", {
      customClass: "keyboard-shortcuts-modal",
      currentResultSelector: `.test-case-result-${index1}-${index2}-${this.state.currentColumn}`,
    });
  };

  setShowBuild = () => {
    this.setState({ showBuild: !this.state.showBuild }, () => {
      const height =
        (this.state.shortDevice ? 50 : 70) + (this.state.showBuild ? 38 : 0);
      document.querySelector(
        ".pass-page-heading"
      ).style.paddingBottom = `${height}px`;
      const menu = document.querySelector(".test-pass-menu-hover");
      const menuInput = document.getElementById("pass-title-input-container");
      if (menu) {
        menu.style.marginBottom = `${-(height + 70)}px`;
        menu.style.top = `${height + 20}px`;
      } else if (menuInput) {
        menuInput.style.marginBottom = `${-(height + 61)}px`;
        menuInput.style.top = `${height + 20}px`;
      }
      if (this.stickySectionHeader) {
        this.stickySectionHeader.style.top = `${height + 136}px`;
      }
      localStorage.setItem(
        `test_pass_${this.state.testPass.id}_show_build`,
        this.state.showBuild ? "true" : "false"
      );
    });
  };

  render() {
    const adminBool =
      this.props.currentUser.role === "admin" ||
      this.props.currentUser.role === "lead";
    const titleEdit = this.state.editPassTitle;
    let row = 0;
    const { shortDevice } = this.state;

    return (
      <div
        id="test-pass-form"
        style={{
          borderBottom: "1px solid lightgray",
          position: "relative",
          whiteSpace: "nowrap",
        }}
      >
        <TestPassHoverDialog ref={this.hoverDialogRef} />
        <div
          id="platform-buttons-container"
          style={{
            position: "sticky",
            top: "25px",
            right: "0px",
            width: "100%",
            zIndex: "100",
            height: "50px",
          }}
        >
          <div
            role="group"
            aria-label="Device Filter Options"
            style={{
              width: "100%",
              marginLeft: "auto",
              backgroundColor: "white",
              position: "absolute",
              right: "0px",
              textAlign: "right",
              top: "-25px",
            }}
          >
            <li
              role="button"
              aria-live="assertive"
              aria-label={`Windows${
                this.state.currentPlatform === 1 ? ", active" : ""
              }`}
              id="platform-1"
              value={1}
              onClick={this.handlePlatformChange}
              style={{
                display: this.state.platformsPresent[0]
                  ? "inline-block"
                  : "none",
              }}
              className={`test-pass-platform-button ${
                this.state.platformsPresent[0] && !this.props.titleEdit
                  ? ""
                  : "test-pass-platform-button-disabled"
              } ${
                this.state.currentPlatform === 1 ? "platform-button-active" : ""
              }`}
            >
              Windows
            </li>
            <li
              role="button"
              aria-live="assertive"
              aria-label={`MacOS${
                this.state.currentPlatform === 2 ? ", active" : ""
              }`}
              id="platform-2"
              value={2}
              onClick={this.handlePlatformChange}
              style={{
                display: this.state.platformsPresent[1]
                  ? "inline-block"
                  : "none",
              }}
              className={`test-pass-platform-button ${
                this.state.platformsPresent[1] && !this.props.titleEdit
                  ? ""
                  : "test-pass-platform-button-disabled"
              } ${
                this.state.currentPlatform === 2 ? "platform-button-active" : ""
              }`}
            >
              MacOS
            </li>
            <li
              role="button"
              aria-live="assertive"
              aria-label={`iOS${
                this.state.currentPlatform === 3 ? ", active" : ""
              }`}
              id="platform-3"
              value={3}
              onClick={this.handlePlatformChange}
              style={{
                display: this.state.platformsPresent[2]
                  ? "inline-block"
                  : "none",
              }}
              className={`test-pass-platform-button ${
                this.state.platformsPresent[2] && !this.props.titleEdit
                  ? ""
                  : "test-pass-platform-button-disabled"
              } ${
                this.state.currentPlatform === 3 ? "platform-button-active" : ""
              }`}
            >
              iOS
            </li>
            <li
              role="button"
              aria-live="assertive"
              aria-label={`Android${
                this.state.currentPlatform === 4 ? ", active" : ""
              }`}
              id="platform-4"
              value={4}
              onClick={this.handlePlatformChange}
              style={{
                display: this.state.platformsPresent[3]
                  ? "inline-block"
                  : "none",
              }}
              className={`test-pass-platform-button ${
                this.state.platformsPresent[3] && !this.props.titleEdit
                  ? ""
                  : "test-pass-platform-button-disabled"
              } ${
                this.state.currentPlatform === 4 ? "platform-button-active" : ""
              }`}
            >
              Android
            </li>
            <li
              role="button"
              aria-live="assertive"
              aria-label={`ChromeOS${
                this.state.currentPlatform === 5 ? ", active" : ""
              }`}
              id="platform-5"
              value={5}
              onClick={this.handlePlatformChange}
              style={{
                display: this.state.platformsPresent[4]
                  ? "inline-block"
                  : "none",
              }}
              className={`test-pass-platform-button ${
                this.state.platformsPresent[4] && !this.props.titleEdit
                  ? ""
                  : "test-pass-platform-button-disabled"
              } ${
                this.state.currentPlatform === 5 ? "platform-button-active" : ""
              }`}
            >
              ChromeOS
            </li>
            <li
              role="button"
              aria-live="assertive"
              aria-label={`ChromeOS${
                this.state.currentPlatform === 6 ? ", active" : ""
              }`}
              id="platform-6"
              value={6}
              onClick={this.handlePlatformChange}
              style={{
                display: this.state.platformsPresent[5]
                  ? "inline-block"
                  : "none",
              }}
              className={`test-pass-platform-button ${
                this.state.platformsPresent[5] && !this.props.titleEdit
                  ? ""
                  : "test-pass-platform-button-disabled"
              } ${
                this.state.currentPlatform === 6 ? "platform-button-active" : ""
              }`}
            >
              Wearable
            </li>
          </div>
        </div>
        <div
          style={{
            width: "calc(100% + 24px)",
            marginLeft: "-24px",
            position: "sticky",
            right: "0px",
            borderBottom: "solid 1px lightgray",
            top: "45px",
            zIndex: "100",
            marginBottom: "0px",
          }}
        />
        <TestPassDevices
          ref={this.testDevicesRef}
          testPass={this.props.testPass}
          clearActivePass={this.props.clearActivePass}
          deviceLabelMouseEnter={this.deviceLabelMouseEnter}
          deviceLabelMouseLeave={this.deviceLabelMouseLeave}
          shortDevice={shortDevice}
          titleEdit={titleEdit}
          hoverDialogRef={this.hoverDialogRef}
          testDevices={this.state.testDevices}
          currentPlatform={this.state.currentPlatform}
          testPassContainer={this.testPassContainer}
          testPassDeviceContainer={this.testPassDeviceContainer}
          setModal={this.props.setModal}
          projectUsersList={this.state.projectUsersList}
          projectUsers={this.state.projectUsers}
          adminBool={adminBool}
          project={this.state.project}
          currentColumn={this.state.currentColumn}
          setCurrentColumn={this.setCurrentColumn}
          setShowBuild={this.setShowBuild}
          showBuild={this.state.showBuild}
        />
        <div
          id="left-test-pass-container"
          style={{
            width: "100%",
            overflow: "auto",
            display: "grid",
            gridTemplateColumns: "50% minmax(max-content, 50%)",
          }}
        >
          {this.state.resultOutlineActive && (
            <div
              id="result-outline"
              style={{
                position: "absolute",
                backgroundColor: "#87d3ff38",
                border: "solid 3px #4D90FE",
                zIndex: "1",
              }}
            >
              <div
                onDragStart={this.onResultDragStart}
                onDragEnd={this.onResultDragEnd}
                id="result-copy-button"
                draggable
                style={{
                  position: "absolute",
                  cursor: "crosshair",
                  borderTop: "solid white 1px",
                  zIndex: "3",
                  borderLeft: "solid white 1px",
                  right: "-6px",
                  bottom: "-7px",
                  backgroundColor: "rgb(77, 144, 254)",
                  width: "9px",
                  height: "9px",
                }}
              />
            </div>
          )}
          {this.state.testPass.test_cases.map((testCase, index) => {
            const index1 = index;
            const empty = !testCase.test_steps.length > 0;
            return [
              <div
                key={`test-case-${testCase.id}-${index}`}
                data-index1={index1}
                id={`test-case-${index1}`}
                style={{
                  position: "sticky",
                  left: "0",
                  zIndex: "10",
                  backgroundColor: "#519acc",
                  color: "white",
                }}
              >
                <button
                  className="section-heading-button"
                  tabIndex={0}
                  data-index1={index1}
                  data-index={index1}
                  data-previous-steps-length={
                    index > 0
                      ? this.state.testPass.test_cases[index - 1].test_steps
                          .length
                      : -1
                  }
                  data-last-section={
                    this.state.testPass.test_cases.length - 1 === index1
                  }
                  onKeyDown={this.sectionHeadingOnKeyDown}
                  onMouseDown={this.props.hideFocus}
                  role="rowheading"
                  onFocus={this.sectionHeadingFocus}
                  onBlur={this.sectionHeadingBlur}
                  aria-label={`Section ${index + 1}: ${testCase.name}`}
                  aria-expanded="true"
                  onClick={this.handleExpandSteps}
                  id={`test-case-title-${index}`}
                  style={{
                    fontSize: "18px",
                    cursor: `${empty ? "default" : "pointer"}`,
                    pointerEvents: `${empty ? "none" : "all"}`,
                    background: "none",
                    border: "none",
                    color: "white",
                  }}
                >
                  <i
                    aria-hidden="true"
                    id={`test-case-title-arrow-${index}`}
                    style={{ opacity: `${empty ? "0" : "1"}` }}
                    className="material-icons test-case-title-arrow"
                  >
                    expand_more
                  </i>
                  {index + 1}: {testCase.name}
                </button>
              </div>,
              <div
                style={{ backgroundColor: "#f2f2f2" }}
                key={`filler-${index}`}
              >
                {this.state.testDevices.map((assignment, index) => (
                  <div
                    key={`filler-${testCase.id}-${index}`}
                    style={{
                      width: "150px",
                      height: "100%",
                      backgroundColor: "#519acc",
                      display: `${
                        assignment.device_platform_id ===
                        this.state.currentPlatform
                          ? "inline-block"
                          : "none"
                      }`,
                    }}
                  />
                ))}
                {adminBool ? (
                  <div
                    style={{
                      width: "71px",
                      height: "100%",
                      backgroundColor: "#519acc",
                      display: "inline-block",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "1px",
                      height: "100%",
                      backgroundColor: "#519acc",
                      display: "inline-block",
                    }}
                  />
                )}
              </div>,
              testCase.test_steps.map((step, index) => {
                const textCaseStep =
                  this.state.testCases[index1].test_steps[index];
                const index2 = index;
                const testCaseNumber = `${index1 + 1}.${index + 1}`;
                const violationCount =
                  this.state.project.a11y_active &&
                  textCaseStep &&
                  textCaseStep.test_step_a11y_ids &&
                  textCaseStep.test_step_a11y_ids.length > 0
                    ? textCaseStep.test_step_a11y_ids.length
                    : "";
                const bugsCount =
                  textCaseStep &&
                  textCaseStep.ticket_ids &&
                  textCaseStep.ticket_ids.length > 0
                    ? textCaseStep.ticket_ids.length
                    : "";
                row = index === 0 && index1 === 0 && row === 0 ? 0 : row + 1;
                return [
                  <div
                    data-index1={index1}
                    data-index2={index2}
                    aria-hidden="true"
                    role="dialog"
                    tabIndex={-1}
                    aria-label="Test Case text."
                    key={`test-step-${step.id}-${index1}-${index}`}
                    id={`test-step-${index1}-${index}`}
                    className={`test-step-text test-case-group-${index1}`}
                    style={{
                      paddingRight: this.state.project.a11y_active
                        ? "85px"
                        : "54px",
                      position: "sticky",
                      left: "1px",
                      zIndex: "10",
                      backgroundColor: `${
                        index % 2 === 0 ? "#e6e6e6" : "#f7f7f7"
                      }`,
                      borderRight: "solid 1px gray",
                    }}
                  >
                    <h4
                      data-index1={index1}
                      data-index2={index2}
                      tabIndex={-1}
                      aria-label={`Test Case ${index1 + 1}.${
                        index + 1
                      } text. Press R to return to test result column.`}
                    >
                      TC {index1 + 1}.{index + 1}
                    </h4>
                    <Linkify componentDecorator={componentDecorator}>
                      <div
                        data-index1={index1}
                        data-index2={index2}
                        role="group"
                      >
                        {parse(this.processText(step.text))}
                      </div>
                    </Linkify>
                    {this.state.project.a11y_active && (
                      <i
                        aria-hidden="true"
                        data-index2={index2}
                        data-index1={index1}
                        id={`test-step-violations-button-${step.id}`}
                        className="test-step-a11y-button material-icons"
                        onMouseEnter={this.a11yMouseEnter}
                        onMouseLeave={this.a11yMouseLeave}
                        onClick={this.handleTestStepA11yClick}
                        data-test-case-number={`${index1 + 1}.${index + 1}`}
                        data-step-id={step.id}
                        data-wcags={
                          textCaseStep.test_step_a11y_ids
                            ? textCaseStep.test_step_a11y_ids
                            : []
                        }
                      >
                        accessibility_new
                      </i>
                    )}
                    {violationCount ? (
                      <span
                        aria-hidden="true"
                        className="counter test-step-a11y-button-counter"
                      >
                        {violationCount}
                      </span>
                    ) : (
                      ""
                    )}
                    <i
                      aria-hidden="true"
                      data-index2={index2}
                      data-index1={index1}
                      className="test-step-bugs-button material-icons"
                      id={`test-step-bugs-button-${step.id}`}
                      onMouseEnter={this.bugsMouseEnter}
                      onMouseLeave={this.bugsMouseLeave}
                      onClick={this.handleTestStepBugsClick}
                      data-test-case-number={`${index1 + 1}.${index + 1}`}
                      data-step-id={step.id}
                      data-bugs={
                        textCaseStep && textCaseStep.ticket_ids
                          ? textCaseStep.ticket_ids
                          : []
                      }
                    >
                      bug_report
                    </i>
                    {bugsCount ? (
                      <span
                        aria-hidden="true"
                        className="counter test-step-bugs-button-counter"
                      >
                        {bugsCount}
                      </span>
                    ) : (
                      ""
                    )}
                    <i
                      aria-hidden="true"
                      className="test-step-comments-button material-icons"
                      id={`test-step-comments-button-${step.id}`}
                      onMouseEnter={this.commentMouseEnter}
                      onMouseLeave={this.commentMouseLeave}
                      onClick={this.handleTestStepCommentsClick}
                      data-test-case-number={`${index1 + 1}.${index + 1}`}
                      data-device=""
                      data-step-id={step.id}
                      data-index2={index2}
                      data-index1={index1}
                      data-count={
                        textCaseStep.comment_counts.no_device
                          ? textCaseStep.comment_counts.no_device
                          : 0
                      }
                      data-comments={
                        textCaseStep.test_step_comment_ids
                          ? textCaseStep.test_step_comment_ids
                          : []
                      }
                    >
                      comment
                    </i>
                    {textCaseStep.comment_counts.no_device ? (
                      <span
                        aria-hidden="true"
                        className="counter test-step-comments-button-counter"
                      >
                        {textCaseStep.comment_counts.no_device}
                      </span>
                    ) : (
                      ""
                    )}
                    {step.details !== "" && (
                      <div style={{ position: "relative" }}>
                        <h4
                          data-index1={index1}
                          data-index2={index2}
                          className="test-pass-step-details-label"
                          style={{
                            fontWeight: "400",
                            backgroundColor: `${
                              index % 2 === 0 ? "#e6e6e6" : "#f7f7f7"
                            }`,
                          }}
                        >
                          Details/Expected Results
                        </h4>
                        <p
                          data-index1={index1}
                          data-index2={index2}
                          className="test-pass-step-details-text"
                          dangerouslySetInnerHTML={{
                            __html: xss(step.details),
                          }}
                        />
                      </div>
                    )}
                  </div>,
                  <div
                    key={`result-group-${step.id}-${index1}`}
                    className={`test-case-result-group-${index1} test-result-group`}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "#f2f2f2",
                      // transition:"height 0.3s",
                      marginLeft: "1px",
                    }}
                  >
                    {step.test_pass_results
                      .filter((x) => x.test_pass_id === this.state.testPass.id)
                      .map((filterResult, index) => {
                        const index3 = filterResult.id;
                        return (
                          <TestPassResultRow
                            showFocus={this.props.showFocus}
                            hideFocus={this.props.hideFocus}
                            setResultOutline={this.setResultOutline}
                            key={`testPassResultRow-${filterResult.id}-${filterResult.results.length}`}
                            testPassResults={filterResult.results}
                            testPassId={this.state.testPass.id}
                            testPassPercent={
                              this.state.testPass.percent_complete
                            }
                            index={index}
                            index1={index1}
                            index2={index2}
                            index3={index3}
                            stepId={step.id}
                            commentCounts={textCaseStep.comment_counts}
                            ticketCounts={textCaseStep.ticket_counts}
                            violationCount={violationCount}
                            bugsCount={bugsCount}
                            row={row}
                            step={step}
                            ref={`step_${step.id}`}
                            testCaseNumber={testCaseNumber}
                            currentUserId={this.props.currentUser.id}
                            testDevices={this.state.testPass.test_devices}
                            currentPlatform={this.state.currentPlatform}
                            keyboardShortcuts={this.keyboardShortcuts}
                            onResultDragStart={this.onResultDragStart}
                            onResultDragEnd={this.onResultDragEnd}
                            onResultDragEnter={this.onResultDragEnter}
                            draggingResult={this.state.draggingResult}
                            onCommentMouseEnter={this.commentMouseEnter}
                            onCommentMouseLeave={this.commentMouseLeave}
                            onCommentClick={this.handleTestStepCommentsClick}
                            onBugMouseEnter={this.bugsMouseEnter}
                            onBugMouseLeave={this.bugsMouseLeave}
                            onBugClick={this.handleTestStepBugsClick}
                            projectUsersList={this.state.projectUsersList}
                            currentColumn={this.state.currentColumn}
                            setCurrentColumn={this.setCurrentColumn}
                            onResultBlur={this.sectionHeadingBlur}
                          />
                        );
                      })}
                    {adminBool && (
                      <div
                        style={{
                          display: "inline-block",
                          width: "70px",
                          height: "100%",
                          backgroundColor: "white",
                          borderRight: "solid 1px lightgray",
                          verticalAlign: "bottom",
                          minHeight: "29px",
                        }}
                      />
                    )}
                  </div>,
                ];
              }),
            ];
          })}
        </div>
      </div>
    );
  }
}

export default WithCable(TestPassForm);
