import React from "react";
import ApiClient from "../../utils/ApiClient";

class TwoFactorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svg: null,
      codeSent: false,
      loaded: false,
      otpModuleEnabled: null,
      otpEmailModuleEnabled: null,
      code: "",
      currentView: "",
    };
    this.getModalView = this.getModalView.bind(this);
    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.enableEmail = this.enableEmail.bind(this);
    this.disableEmail = this.disableEmail.bind(this);
    this.handleCodeUpdate = this.handleCodeUpdate.bind(this);
    this.setCurrentView = this.setCurrentView.bind(this);
    this.api = new ApiClient({
      Accept: "*/*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  componentDidMount() {
    this.getModalView();
  }

  handleCodeUpdate(e) {
    this.setState({ code: e.target.value });
  }

  getModalView() {
    if (this.props.data.secureContent) {
      this.setState({ loaded: true });
    } else {
      this.api
        .get(`/users/${this.props.data.currentUser.id}/enable_two_factor.json`)
        .then((res) => {
          if (res.data.otpEmailModuleEnabled === true) {
            this.sendInitialEmail();
          }
          this.setState(
            {
              svg: res.data.svg,
              loaded: true,
              otpModuleEnabled: res.data.otpModuleEnabled,
              otpEmailModuleEnabled: res.data.otpEmailModuleEnabled,
            },
            () => {
              setTimeout(() => {
                const elem = document.getElementById("2fa-header");
                if (elem) {
                  elem.focus();
                }
              }, 200);
            }
          );
        });
    }
  }

  enable(e) {
    e.preventDefault();
    const self = this;
    this.api
      .post(
        `/users/${this.props.data.currentUser.id}/enable_multi_factor_authentication.json`,
        {
          multi_factor_authentication: {
            otp_code_token: this.state.code,
          },
        }
      )
      .then((res) => {
        self.closeModal();
        self.props.data.handleOtp(true);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role="alert">${res.data.msg}</span>`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch(() => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: '<span role="alert">Two Factor Authentication could not be enabled</span>',
          classes: "red darken-3 error-toast",
        });
      });
  }

  sendInitialEmail() {
    this.api
      .post(
        `/users/${this.props.data.currentUser.id}/send_multi_factor_email.json`
      )
      .then(() => {
        this.setState({ codeSent: true });
      })
      .catch(() => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: '<span role="alert">Two Factor Authentication could not be enabled</span>',
          classes: "red darken-3 error-toast",
        });
      });
  }

  enableEmail() {
    const self = this;
    this.api
      .post(
        `/users/${this.props.data.currentUser.id}/enable_email_multi_factor_authentication.json`,
        {
          multi_factor_authentication: {
            email_code_token: this.state.code,
          },
        }
      )
      .then((res) => {
        self.closeModal();
        self.props.data.handleEmailOtp(true);
        // eslint-disable-next-line no-undef
        M.toast({ html: res.data.msg, displayLength: 3000, classes: "green" });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: '<span role="alert">Two Factor Authentication could not be disabled</span>',
          classes: "red darken-3 error-toast",
        });
      });
  }

  disableEmail(e) {
    e.preventDefault();
    const self = this;
    this.api
      .post(
        `/users/${this.props.data.currentUser.id}/disable_email_multi_factor_authentication.json`,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          multi_factor_authentication: {
            email_code_token: this.state.code,
          },
        }
      )
      .then((res) => {
        self.closeModal();
        self.props.data.handleEmailOtp(true);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role="alert">${res.data.msg}</span>`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: '<span role="alert">Two Factor Authentication could not be disabled</span>',
          classes: "red darken-3 error-toast",
        });
      });
  }

  disable(e) {
    e.preventDefault();
    const self = this;
    this.api
      .post(
        `/users/${this.props.data.currentUser.id}/disable_multi_factor_authentication.json`,
        {
          multi_factor_authentication: {
            otp_code_token: this.state.code,
          },
        }
      )
      .then((res) => {
        self.closeModal();
        self.props.data.handleOtp(false);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role="alert">${res.data.msg}</span>`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: '<span role="alert">Two Factor Authentication could not be disabled</span>',
          classes: "red darken-3 error-toast",
        });
      });
  }

  renderEmail() {
    return (
      <form
        id="2fa-form"
        tabIndex={0}
        onSubmit={this.state.otpEmailModuleEnabled ? this.disable : this.enable}
        style={{ flex: 1, outline: "none" }}
      >
        <div className="modal-body">
          <div className="form-group">
            <div className="text-center">
              <p
                role="header"
                id="2fa-header"
                tabIndex={0}
                style={{ outline: "none" }}
                className="small-title"
              >
                Email Token
              </p>
              {this.state.codeSent && (
                <p style={{ color: "green" }}>
                  Token sent to {this.props.data.currentUser.email}, please
                  retrieve the code and enter here to{" "}
                  {this.state.otpEmailModuleEnabled ? "deactivate" : "activate"}
                </p>
              )}
              <input
                type="text"
                name="email"
                placeholder={`Verify token to ${
                  this.props.data.currentUser.otp_enabled ? "disable" : "enable"
                }`}
                className="form-control account-inputs"
                onChange={this.handleCodeUpdate}
                value={this.state.code}
              />
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="modal-footer"
        >
          {this.state.otpEmailModuleEnabled === false && (
            <button
              style={{ marginRight: "auto" }}
              value=""
              onClick={this.setCurrentView}
              type="button"
              className="common-button-submit"
            >
              Back
            </button>
          )}
          <button
            style={{ width: "auto" }}
            onClick={
              this.state.otpEmailModuleEnabled
                ? this.disableEmail
                : this.enableEmail
            }
            type="button"
            className="common-button-submit"
          >
            {this.state.otpEmailModuleEnabled ? "Disable" : "Enable"} Two Factor
            Auth
          </button>
        </div>
      </form>
    );
  }

  verifySecureToken = () => {
    const api = new ApiClient();
    api
      .post("/verify_secure_wiki_content.json", {
        content_verify: {
          email_code_token: this.state.code,
          id: this.props.data.secureContent,
          user_id: this.props.data.currentUser.id,
        },
      })
      .then((res) => {
        this.closeModal();
        this.props.data.handleSecureContent(res.data, this.props.data.edit);
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: '<span role="alert">Error or incorrect code</span>',
          classes: "red darken-3 error-toast",
        });
      });
  };

  renderSecureContent() {
    return (
      <form
        id="2fa-form"
        tabIndex={0}
        onSubmit={this.verifySecureToken}
        style={{ flex: 1, outline: "none" }}
      >
        <div className="modal-body">
          <div className="form-group">
            <div className="text-center">
              <p
                role="header"
                id="2fa-header"
                tabIndex={0}
                style={{ outline: "none" }}
                className="small-title"
              >
                Email Token
              </p>
              <p style={{ margin: "20px 0" }}>
                Two-Factor Authorization is not enabled for this account. To
                view this secure content enter the code that was just emailed to
                you. Otherwise navigate to the <a href="/profile">here</a> to
                enable Two-Factor Authorization
              </p>
              <input
                type="text"
                name="email"
                placeholder="Enter Authentication Code"
                className="form-control account-inputs"
                onChange={this.handleCodeUpdate}
                value={this.state.code}
              />
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="modal-footer"
        >
          <button
            style={{ width: "auto" }}
            onClick={this.verifySecureToken}
            type="button"
            className="common-button-submit"
          >
            Submit Code
          </button>
        </div>
      </form>
    );
  }

  setCurrentView(e) {
    const { value } = e.target;
    if (value === "email") {
      this.sendInitialEmail();
      this.setState({ currentView: value }, () => {
        setTimeout(() => {
          const elem = document.getElementById("2fa-header");
          if (elem) {
            elem.focus();
          }
        }, 200);
      });
    } else {
      this.setState({ currentView: value }, () => {
        setTimeout(() => {
          const elem = document.getElementById("2fa-header");
          if (elem) {
            elem.focus();
          }
        }, 200);
      });
    }
  }

  renderOTP() {
    return (
      <form
        id="2fa-form"
        tabIndex={0}
        onSubmit={this.state.otpModuleEnabled ? this.disable : this.enable}
        style={{ flex: 1, outline: "none" }}
      >
        <div className="modal-body">
          {!this.state.otpModuleEnabled && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <p
                role="header"
                id="2fa-header"
                tabIndex={0}
                className="small-title"
                style={{
                  flex: "1 1 100%",
                  textAlign: "center",
                  paddingBottom: "20px",
                  outline: "none",
                }}
              >
                Scan code with google authenticator to receive token
              </p>
              <div dangerouslySetInnerHTML={{ __html: this.state.svg }} />
            </div>
          )}
          <div className="form-group">
            <div className="text-center">
              <p>Google Authenticator Token</p>
              <input
                type="text"
                name="otp_code_token"
                placeholder={`Verify token to ${
                  this.props.data.currentUser.otp_enabled ? "disable" : "enable"
                }`}
                className="form-control account-inputs"
                onChange={this.handleCodeUpdate}
                value={this.state.code}
              />
            </div>
            <p style={{ textAlign: "center" }}>
              Need to download the google authenticator app?
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
              >
                Android
              </a>
              <a
                style={{ marginLeft: "10px" }}
                rel="noreferrer"
                target="_blank"
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              >
                iOS
              </a>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="modal-footer"
        >
          {this.state.otp_enabled === false && (
            <button
              style={{ marginRight: "auto" }}
              value=""
              onClick={this.setCurrentView}
              type="button"
              className="common-button-submit"
            >
              Back
            </button>
          )}
          <button
            style={{ width: "auto" }}
            onClick={this.state.otpModuleEnabled ? this.disable : this.enable}
            type="button"
            className="common-button-submit"
          >
            {this.state.otpModuleEnabled ? "Disable" : "Enable"} Two Factor Auth
          </button>
        </div>
      </form>
    );
  }

  render() {
    if (this.state.loaded === false) {
      return (
        <div
          role="dialog"
          aria-label="Two Factor Modal"
          id="test-step-bugs-loader"
        ></div>
      );
    } else if (this.state.loaded && this.props.data.secureContent) {
      return (
        <div
          role="dialog"
          aria-label="Secure Content Authentication Modal"
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {this.renderSecureContent()}
        </div>
      );
    } else if (
      (this.state.loaded && this.state.currentView === "otp") ||
      this.props.data.currentUser.otp_enabled
    ) {
      return (
        <div
          role="dialog"
          aria-label="Two Factor Modal"
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {this.renderOTP()}
        </div>
      );
    } else if (this.props.data.currentUser.otp_enabled) {
      return (
        <div
          role="dialog"
          aria-label="Two Factor Modal"
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {this.renderOTP()}
        </div>
      );
    } else if (
      (this.state.loaded && this.state.currentView === "email") ||
      this.props.data.currentUser.otp_email_enabled
    ) {
      return (
        <div
          role="dialog"
          aria-label="Two Factor Modal"
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {this.renderEmail()}
        </div>
      );
    } else if (
      this.state.loaded &&
      this.state.currentView === "" &&
      this.state.otp_enabled != false
    ) {
      return (
        <div
          role="dialog"
          aria-label="Two Factor Modal"
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "180px",
            flexDirection: "column",
          }}
        >
          <p
            role="header"
            id="2fa-header"
            tabIndex={0}
            className="small-title"
            style={{ padding: "30px", outline: "none" }}
          >
            Two Factor Authentication Options:
          </p>
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            className="modal-footer"
          >
            <button
              style={{ width: "180px" }}
              value="otp"
              onClick={this.setCurrentView}
              type="button"
              className="common-button-submit"
            >
              Google Authenticator
            </button>
            <button
              style={{ width: "180px" }}
              value="email"
              onClick={this.setCurrentView}
              type="button"
              className="common-button-submit"
            >
              Email
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          role="dialog"
          aria-label="Two Factor Modal"
          id="generate-code"
          className="small-title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "190px",
          }}
        >
          <p>
            {this.props.data.currentUser.otp_enabled
              ? "Loading"
              : "Generating QR Code"}
          </p>
          <p className="dot">.</p>
          <p className="dot">.</p>
          <p className="dot">.</p>
        </div>
      );
    }
  }
}
export default TwoFactorModal;
