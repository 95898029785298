import React, { useMemo, useContext } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  AssessmentExpired,
  AssessmentFailed,
  AssessmentPassed,
  Confetti,
} from "./icons";
import { Content } from "./TrialBody";
import { TrialsContext } from ".";
import EmailResultsModal from "./EmailResultsModal";

const Results = () => {
  const { trial } = useContext(TrialsContext);
  const { trial_results, assessment } = trial;
  const { resource } = assessment;

  const STATUS = useMemo(() => {
    if (!trial_results) return null;

    const strings = _.keys(_.pickBy(trial_results, (value) => value === true));
    const keys = ["passed", "failed", "expired"];

    return (
      Array.isArray(strings) &&
      strings.length === 1 &&
      keys.includes(strings[0]) &&
      strings[0]
    );
  }, [trial_results]);

  const timeElapsed = useMemo(() => {
    if (!trial_results) return null;

    const { time_elapsed } = trial_results;
    if (!time_elapsed) return null;

    const seconds = time_elapsed % 60;
    const minutes = Math.floor(time_elapsed / 60);
    return `${minutes} minutes and ${seconds} seconds`;
  }, [trial_results]);

  const ICON = {
    passed: <AssessmentPassed />,
    failed: <AssessmentFailed />,
    expired: <AssessmentExpired />,
  };

  const HEADLINE = {
    passed: "Assessment Passed",
    failed: "Assessment Failed",
    expired: "Time Expired",
  };

  const MESSAGE = {
    passed: (
      <>
        Congratulations, you passed the assessment. Your score was{" "}
        <Bold>{trial_results.correct_string}</Bold> or{" "}
        <Bold>{trial_results.percent}</Bold>. The assessment was completed in{" "}
        <Bold>{timeElapsed}</Bold>.
      </>
    ),
    failed: (
      <>
        Sorry, but you did not receive a passing score for this assessment. Your
        score was <Bold>{trial_results.correct_string}</Bold> or{" "}
        <Bold>{trial_results.percent}</Bold>. The assessment was completed in{" "}
        <Bold>{timeElapsed}</Bold>. You can retake this assessment by returning
        to the resource page and attempting again.
      </>
    ),
    expired: (
      <>
        Sorry, but you ran out of time for this assessment session. Your score
        was <Bold>{trial_results.correct_string}</Bold> or{" "}
        <Bold>{trial_results.percent}</Bold>. You can retake this assessment by
        returning to the resource page and attempting again.
      </>
    ),
  };

  return (
    STATUS && (
      <Content>
        <BigIconContainer>
          {STATUS === "passed" && <Confetti />}
          {ICON[STATUS]}
          {STATUS === "passed" && <ReversedConfetti />}
        </BigIconContainer>
        <Headline>
          {HEADLINE[STATUS]} - {resource.title} (Level {assessment.level})
        </Headline>
        <ResultExplain>{MESSAGE[STATUS]}</ResultExplain>
        <EmailResultsModal />
      </Content>
    )
  );
};

const BigIconContainer = styled.div``;

const Headline = styled.h2`
  text-align: center;
  font-size: 25px;
  line-height: 34px;
  margin-top: 12px;
  font-family: "Manrope";
  font-weight: 700;
`;

const ResultExplain = styled.p`
  text-align: center;
  max-width: 1024px;
  margin-top: 24px;
  font-size: 16px;
  line-height: 33px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const ReversedConfetti = styled(Confetti)`
  transform: scaleX(-1);
`;

export default Results;
