import React from "react";

const BugsGoldSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Bugs_-_Gold_-_Small_svg__cls-1{fill:#d1b542}.Skills__Bugs_-_Gold_-_Small_svg__cls-2{fill:#edcc43}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g id="Skills__Bugs_-_Gold_-_Small_svg__Layer_2" data-name="Layer 2">
        {" "}
        <path
          className="Skills__Bugs_-_Gold_-_Small_svg__cls-1"
          d="M471.2 900.85L242 766.15a98.66 98.66 0 01-48.67-85.82l2-265.85a98.68 98.68 0 0150-85.06L476.6 198.26a98.66 98.66 0 0198.66.76l229.21 134.7a98.66 98.66 0 0148.67 85.82l-2 265.85a98.66 98.66 0 01-50 85.06L569.85 901.61a98.64 98.64 0 01-98.65-.76z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_-_Small_svg__cls-2"
          d="M471.2 888.46L242 753.76A98.64 98.64 0 01193.31 668l2-265.86a98.68 98.68 0 0150-85.06L476.6 185.87a98.68 98.68 0 0198.66.76l229.21 134.7a98.66 98.66 0 0148.67 85.82L851.1 673a98.67 98.67 0 01-50 85.06L569.85 889.22a98.64 98.64 0 01-98.65-.76z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_-_Small_svg__cls-2"
          d="M476.61 852L271.22 731.29a88.39 88.39 0 01-43.61-76.9l1.83-238.22A88.39 88.39 0 01274.23 340l207.22-117.57a88.41 88.41 0 0188.4.68l205.38 120.7a88.38 88.38 0 0143.61 76.9L817 658.92a88.38 88.38 0 01-44.79 76.22L565 852.66a88.39 88.39 0 01-88.39-.66z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_-_Small_svg__cls-1"
          d="M748.72 514.77c-19.15-25.62-41-41.18-65-46.23a76.35 76.35 0 00-46.64 4.54c-12.49-35-39.79-61.54-76.86-68.57v-.75a66.19 66.19 0 017.86-29.89c11.52-21.78 33.6-35.87 65.63-41.87a11 11 0 10-4-21.54c-48.5 9.09-70.8 33.95-81 53.19a89.6 89.6 0 00-10.39 39.05l-27.3.24c-1.71 0-3.42.08-5.11.18a89.52 89.52 0 00-11.1-39c-10.52-19.05-33.27-43.51-81.93-51.72a11 11 0 10-3.64 21.6c74.76 12.65 74.81 68 74.76 70.51a10.85 10.85 0 00.15 2.14c-33.82 9.28-64.12 35-75.42 67.89a76.49 76.49 0 00-45.22-3.1c-23.87 5.49-45.45 21.44-64.14 47.4a11 11 0 0017.8 12.81c17.16-23.85 37.18-37.67 57.89-40a52.26 52.26 0 0128.7 4.65 103.1 103.1 0 00-.72 13.33l.39 44a78.17 78.17 0 00-32 3.5c-24.79 7.93-46.64 26.92-64.94 56.43a11 11 0 0018.64 11.56c15.81-25.51 34.84-42 55-47.73a53.71 53.71 0 0123.51-1.58l.45 49.82a103.14 103.14 0 00.91 12.67 71.25 71.25 0 00-29.44 11.64c-22.84 15.5-37.68 43.09-44.08 82a11 11 0 009 12.6 12.13 12.13 0 001.89.15 11 11 0 0010.7-9.19c5.3-32.14 17.23-55.4 34.54-67.27a48.15 48.15 0 0122.48-8.42c14.67 41.41 54.5 71.11 100.72 70.69h2.73V531.64a10.55 10.55 0 0110.55-10.55 10.54 10.54 0 0110.54 10.55v198.63h4.79c46.26-.42 85.56-30.89 99.46-72.61a47.46 47.46 0 0124.29 8.1c17.51 11.55 29.88 34.59 35.76 66.64a11 11 0 0010.86 9 11.21 11.21 0 001.89-.19 11 11 0 008.81-12.76c-7.11-38.77-22.44-66.09-45.56-81.18a71 71 0 00-31.33-11.29 104.36 104.36 0 00.64-12.44l-.42-49.54c4.94-1.2 14-2.19 25.23.77 20.29 5.35 39.62 21.51 55.89 46.73a11 11 0 0018.42-11.9c-18.82-29.18-41-47.76-66-55.24a77.07 77.07 0 00-33.79-2.68l-.41-44.24a106.74 106.74 0 00-.66-10.94 10.72 10.72 0 001.31-2.34 52.07 52.07 0 0128.7-5.21c20.74 1.91 41 15.38 58.6 38.91a11 11 0 008.89 4.39 11 11 0 008.67-17.52z"
        />{" "}
        <path
          d="M748.72 503.33c-19.15-25.62-41-41.17-65-46.23a76.33 76.33 0 00-46.64 4.55c-12.49-35-39.79-61.55-76.86-68.58v-.75a66.14 66.14 0 017.86-29.88c11.51-21.79 33.59-35.89 65.62-41.89a11 11 0 10-4-21.55c-48.5 9.09-70.8 34-81 53.2a89.57 89.57 0 00-10.39 39l-27.3.25c-1.71 0-3.42.07-5.11.18a89.46 89.46 0 00-11.1-39c-10.52-19.06-33.27-43.52-81.93-51.72a11 11 0 10-3.64 21.61c74.79 12.63 74.81 68 74.78 70.49a10.9 10.9 0 00.15 2.15c-33.82 9.27-64.12 35-75.42 67.88a76.43 76.43 0 00-45.21-3.04c-23.87 5.49-45.45 21.43-64.14 47.4a11 11 0 0017.8 12.8c17.16-23.84 37.18-37.67 57.89-40a52.17 52.17 0 0128.7 4.65 103 103 0 00-.72 13.32l.39 44a78.17 78.17 0 00-32 3.5c-24.79 7.94-46.64 26.92-64.94 56.43a11 11 0 1018.64 11.56c15.81-25.51 34.84-42 55-47.73a53.68 53.68 0 0123.51-1.57l.45 49.82a103.28 103.28 0 00.88 12.63 71.21 71.21 0 00-29.44 11.63c-22.84 15.5-37.68 43.1-44.08 82a11 11 0 009 12.6 10.1 10.1 0 001.89.15 11 11 0 0010.7-9.18c5.3-32.15 17.23-55.41 34.54-67.27a48.08 48.08 0 0122.48-8.43c14.67 41.41 54.5 71.11 100.72 70.69h2.73V520.2a10.55 10.55 0 0110.55-10.55 10.54 10.54 0 0110.54 10.55v198.64l4.79-.05c46.26-.42 85.56-30.89 99.46-72.6a47.26 47.26 0 0124.29 8.1c17.51 11.55 29.88 34.59 35.76 66.63a11 11 0 0010.86 9 12.32 12.32 0 001.89-.18 11 11 0 008.85-12.74c-7.11-38.77-22.44-66.09-45.56-81.17a71 71 0 00-31.33-11.3 104.19 104.19 0 00.64-12.43l-.45-49.46a52.53 52.53 0 0125.23.77c20.29 5.35 39.62 21.51 55.89 46.73a11 11 0 0018.42-11.9c-18.82-29.17-41-47.76-66-55.24a77.22 77.22 0 00-33.79-2.68l-.41-44.23a106.76 106.76 0 00-.66-10.95 10.72 10.72 0 001.31-2.34 52.16 52.16 0 0128.7-5.21c20.74 1.92 41 15.38 58.6 38.91a11 11 0 0017.56-13.13z"
          fill="#fff"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default BugsGoldSmall;
