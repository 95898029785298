import React from 'react'

class BugDraftIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44 44"
      >
        <g id="Layer_5" data-name="Layer 5">
          <path d="M15.22,39.7H6.67A5.86,5.86,0,0,1,.74,33.78V7.45A5.86,5.86,0,0,1,6.67,1.53H33a5.86,5.86,0,0,1,5.92,5.92v7.24a2,2,0,1,1-3.95,0V7.45a1.91,1.91,0,0,0-2-2H6.67a1.91,1.91,0,0,0-2,2V33.78a1.91,1.91,0,0,0,2,2h8.55a2,2,0,1,1,0,4Z"/>
          <path d="M27.73,17.06H11.93a1.71,1.71,0,1,1,0-3.42h15.8a1.71,1.71,0,1,1,0,3.42Z"/>
          <path d="M19.83,25h-7.9a1.71,1.71,0,0,1,0-3.42h7.9a1.71,1.71,0,0,1,0,3.42Z"/>
          <path d="M23.6,42.47a3.35,3.35,0,0,1-2.54-1.12,3,3,0,0,1-.66-2.73l1.05-5.08a1.77,1.77,0,0,1,.5-.93l12-12a3.12,3.12,0,0,1,4.45,0l4,3.95a3.11,3.11,0,0,1,0,4.45L30.2,40.89a1.87,1.87,0,0,1-.92.49l-5.13,1A3.48,3.48,0,0,1,23.6,42.47ZM25,34.82l-.79,3.85L28,37.88,39.33,26.8l-3.18-3.18Z"/>
        </g>
      </svg>
    );
  }
}
BugDraftIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default BugDraftIcon