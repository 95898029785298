import React from "react";

class TrashIcon extends React.Component {
  render() {
    return (
      <svg
        height={this.props.height}
        width={this.props.width}
        stroke={this.props.color}
        fill="none"
        style={this.props.svgStyles}
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 64 64"
      >
        <path d="M50.52,19.08,46.7,56.69a4.44,4.44,0,0,1-4.44,4.44H21.05a4.44,4.44,0,0,1-4.44-4.44L12.79,19"/>
        <line x1="19.57" y1="22.59" x2="22.12" y2="52.12"/>
        <line x1="31.65" y1="22.59" x2="31.65" y2="52.12"/>
        <line x1="43.73" y1="22.38" x2="41.19" y2="51.91"/>
        <polyline points="9.48 13.89 9.48 6.91 53.83 6.91 53.83 13.89"/>
        <polyline points="26.81 5.47 26.81 2.38 36.49 2.38 36.49 5.47"/>
        <line x1="9.91" y1="13.89" x2="53.69" y2="13.89"/>
      </svg>
    );
  }
}

TrashIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc"
}

export default TrashIcon