import React from "react";
import Sleep from "../common/sleep.js";
import axios from "axios";

class OnboardingWelcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1,
    };
    this.transitioning = false;
  }

  componentWillUnmount() {
    axios
      .get(`/v1/onboard_complete`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        //nothing is happening here with the response, lets find out if we can update this component to only be used for onboarding.
        //console.log(res.data)
      })
      .catch((err) => {
        console.error(err);
      });
    var backdrop = document.getElementById("container");
    var footer = document.querySelector(".page-footer");
    if (backdrop && footer) {
      backdrop.style.filter = "none";
      footer.style.filter = "none";
    }
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    var backdrop = document.getElementById("container");
    var footer = document.querySelector(".page-footer");
    if (backdrop && footer) {
      backdrop.style.filter = "blur(4px)";
      footer.style.filter = "blur(4px)";
    }
    var headings = document.querySelectorAll("#heading-container > h2");
    if (headings[0]) {
      setTimeout(() => {
        headings[0].focus();
      }, 1000);
    }
  }

  handleCancelButton = () => {
    this.props.modalAction(false, "", {});
  };

  nextSlide = () => {
    if (!this.transitioning) {
      this.transitioning = true;
      this.setState({ currentSlide: this.state.currentSlide + 1 }, async () => {
        // var headings = document.querySelectorAll("#heading-container h2")
        // if(headings[this.state.currentSlide-1]){
        //   headings[this.state.currentSlide-1].focus()
        // }
        var vid = document.getElementById(`video-${this.state.currentSlide}`);
        vid.pause();
        vid.currentTime = "0";
        await Sleep(800);
        vid.play();
        await Sleep(200);
        var headings = document.querySelectorAll("#heading-container > h2");
        if (headings[this.state.currentSlide - 1]) {
          headings[this.state.currentSlide - 1].focus();
        }
        // vid.play()
        this.transitioning = false;
      });
    }
  };

  previousSlide = () => {
    if (!this.transitioning) {
      this.transitioning = true;
      this.setState({ currentSlide: this.state.currentSlide - 1 }, async () => {
        var vid = document.getElementById(`video-${this.state.currentSlide}`);
        vid.pause();
        vid.currentTime = "0";
        await Sleep(800);
        vid.play();
        await Sleep(200);
        var headings = document.querySelectorAll("#heading-container > h2");
        if (headings[this.state.currentSlide - 1]) {
          headings[this.state.currentSlide - 1].focus();
        }
        this.transitioning = false;
      });
    }
  };

  render() {
    var currentSlide = this.state.currentSlide;
    return (
      <div
        role="dialog"
        aria-label="Test Platform Welcome Modal"
        style={{ paddingTop: "30px", position: "relative" }}
      >
        <div id="slides-container" style={{ overflow: "hidden" }}>
          <div
            id="heading-container"
            style={{
              whiteSpace: "nowrap",
              transform:
                currentSlide === 1
                  ? "translateX(0%)"
                  : currentSlide === 2
                  ? "translateX(-100%)"
                  : "translateX(-200%)",
              transition: "transform 1s ease-in-out",
              transitionDelay: "0.4s",
            }}
          >
            <h2
              tabIndex={-1}
              aria-hidden={currentSlide === 1 ? "false" : "true"}
              style={{
                textAlign: "center",
                outlineColor: "white",
                width: "100%",
                display: "inline-block",
                outline: "none",
              }}
            >
              <img
                style={{
                  width: "40px",
                  verticalAlign: "-13px",
                  marginRight: "10px",
                }}
                aria-hidden="true"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Piechart.svg"
              />
              Dashboard
            </h2>
            <h2
              tabIndex={-1}
              aria-hidden={currentSlide === 2 ? "false" : "true"}
              style={{
                textAlign: "center",
                outlineColor: "white",
                width: "100%",
                display: "inline-block",
                outline: "none",
              }}
            >
              <img
                style={{
                  width: "40px",
                  verticalAlign: "-13px",
                  marginRight: "10px",
                }}
                aria-hidden="true"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20-%20Filled.svg"
              />
              Bugs
            </h2>
            <h2
              tabIndex={-1}
              aria-hidden={currentSlide === 3 ? "false" : "true"}
              style={{
                textAlign: "center",
                outlineColor: "white",
                width: "100%",
                display: "inline-block",
                outline: "none",
              }}
            >
              <img
                style={{
                  width: "40px",
                  verticalAlign: "-13px",
                  marginRight: "10px",
                }}
                aria-hidden="true"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Passes%20-%20Filled.svg"
              />
              Test Passes
            </h2>
          </div>
          <div
            id="text-container"
            style={{
              whiteSpace: "nowrap",
              transform:
                currentSlide === 1
                  ? "translateX(0%)"
                  : currentSlide === 2
                  ? "translateX(-100%)"
                  : "translateX(-200%)",
              transition: "transform 1s ease-in-out",
              transitionDelay: "0.2s",
            }}
          >
            <p
              role="text"
              aria-hidden={currentSlide === 1 ? "false" : "true"}
              style={{
                padding: "20px 50px",
                fontSize: "16px",
                textAlign: "center",
                width: "100%",
                display: "inline-block",
                whiteSpace: "normal",
              }}
            >
              Your one-stop shop for robust statistics on the activity of your
              project.
              <br /> As Bugs are filed and Test Passes are in progress, you’ll
              see it all update here in real-time.
            </p>
            <p
              aria-hidden={currentSlide === 2 ? "false" : "true"}
              style={{
                padding: "20px 50px",
                fontSize: "16px",
                textAlign: "center",
                width: "100%",
                display: "inline-block",
                whiteSpace: "normal",
              }}
            >
              As new issues are identified for the project, testers can file new
              Bug tickets which can easily be browsed, viewed, and modified as
              needed.
            </p>
            <p
              aria-hidden={currentSlide === 3 ? "false" : "true"}
              style={{
                padding: "20px 50px",
                fontSize: "16px",
                textAlign: "center",
                width: "100%",
                display: "inline-block",
                whiteSpace: "normal",
              }}
            >
              Test Passes makes test case execution more efficient by combining
              an intuitive interface with a flexible and dynamic testing
              workspace.
            </p>
          </div>
          <div
            id="video-container"
            style={{
              whiteSpace: "nowrap",
              transform:
                currentSlide === 1
                  ? "translateX(0%)"
                  : currentSlide === 2
                  ? "translateX(-100%)"
                  : "translateX(-200%)",
              transition: "transform 1s ease-in-out",
              height: "300px",
            }}
          >
            <video
              id="video-1"
              role="img"
              aria-hidden={currentSlide === 1 ? "false" : "true"}
              aria-label="animation of dashboard page"
              style={{
                width: "100%",
                display: "inline-block",
                padding: "0px 14%",
                height: "auto",
              }}
              preload="auto"
              autoPlay="autoplay"
              muted="muted"
              playsInline={true}
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/DashboardGraphic.mp4"
            ></video>
            <video
              id="video-2"
              role="img"
              aria-hidden={currentSlide === 2 ? "false" : "true"}
              aria-label="animation of bugs page"
              style={{
                width: "100%",
                display: "inline-block",
                padding: "0px 14%",
                height: "auto",
              }}
              preload="auto"
              autoPlay="autoplay"
              muted="muted"
              playsInline={true}
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/BugsGraphic.mp4"
            ></video>
            <video
              id="video-3"
              role="img"
              aria-hidden={currentSlide === 3 ? "false" : "true"}
              aria-label="animation of test passes page"
              style={{
                width: "100%",
                display: "inline-block",
                padding: "0px 14%",
                height: "auto",
              }}
              preload="auto"
              autoPlay="autoplay"
              muted="muted"
              playsInline={true}
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/TestPassGraphic.mp4"
            ></video>
          </div>
        </div>
        <div
          id="buttons-container"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100px",
          }}
        >
          <div style={{ position: "relative" }}>
            <button
              aria-hidden={currentSlide === 1 ? "true" : "false"}
              aria-label="previous slide"
              style={{
                opacity: currentSlide !== 1 ? "1" : "0",
                pointerEvents: currentSlide !== 1 ? "all" : "none",
                transition: "opacity 1s",
                position: "absolute",
                bottom: "30px",
                left: "30px",
              }}
              onClick={this.previousSlide}
              className="common-button-cancel"
            >
              Previous
            </button>
          </div>
          <div
            role="progressbar"
            aria-valuenow={currentSlide}
            aria-valuemin={0}
            aria-valuemax={3}
            aria-label={`Slide ${currentSlide} of 3`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              aria-hidden={true}
              style={{
                height: "12px",
                width: "12px",
                borderRadius: "50%",
                backgroundColor: currentSlide === 1 ? "#519acc" : "lightgray",
                transition: "background-color 0.4s",
                transitionDelay: "0.4s",
              }}
            ></div>
            <div
              aria-hidden={true}
              style={{
                margin: "0px 12px",
                height: "12px",
                width: "12px",
                borderRadius: "50%",
                backgroundColor: currentSlide === 2 ? "#519acc" : "lightgray",
                transition: "background-color 0.4s",
                transitionDelay: "0.4s",
              }}
            ></div>
            <div
              aria-hidden={true}
              style={{
                height: "12px",
                width: "12px",
                borderRadius: "50%",
                backgroundColor: currentSlide === 3 ? "#519acc" : "lightgray",
                transition: "background-color 0.4s",
                transitionDelay: "0.4s",
              }}
            ></div>
          </div>
          <div style={{ position: "relative" }}>
            <button
              onClick={this.nextSlide}
              aria-hidden={currentSlide === 3 ? "true" : "false"}
              aria-label="next slide"
              style={{
                opacity: currentSlide !== 3 ? "1" : "0",
                pointerEvents: currentSlide !== 3 ? "all" : "none",
                transition: "opacity 1s",
                position: "absolute",
                bottom: "30px",
                right: "30px",
              }}
              className="common-button-submit"
            >
              <span>Next</span>
            </button>
            <button
              aria-hidden={currentSlide === 3 ? "false" : "true"}
              aria-label="Done"
              onClick={this.handleCancelButton}
              style={{
                opacity: currentSlide == 3 ? "1" : "0",
                pointerEvents: currentSlide === 3 ? "all" : "none",
                transition: "opacity 1s",
                position: "absolute",
                bottom: "30px",
                right: "30px",
              }}
              className="common-button-submit"
            >
              Done
            </button>
          </div>
        </div>
        <button
          style={{ position: "absolute", top: "0px", right: "25px" }}
          onClick={this.handleCancelButton}
          aria-label="close-modal"
          className="icon-close-btn"
        ></button>
      </div>
    );
  }
}
export default OnboardingWelcome;
