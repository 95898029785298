import React from "react";

const WebTestingExpertSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Web_Testing_Expert_-_Small_svg__cls-2{fill:#519acc}.Skills__Web_Testing_Expert_-_Small_svg__cls-5{fill:#fff}.Skills__Web_Testing_Expert_-_Small_svg__cls-6{opacity:.2;isolation:isolate}.Skills__Web_Testing_Expert_-_Small_svg__cls-7{fill:#00100f}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Web_Testing_Expert_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <circle cx={540} cy={540} r={418.69} fill="#edcc43" />{" "}
        <circle
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          cx={540}
          cy={540}
          r={361.06}
        />{" "}
        <rect
          x={245.92}
          y={346.8}
          width={588.15}
          height={386.4}
          rx={24.32}
          fill="#f7d64b"
        />{" "}
        <path fill="#002856" d="M266.19 369.32h547.62v338.66H266.19z" />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M279.4 406.44h519.51V697.9H279.4z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-5"
          d="M376.58 419.53h330.48a19.18 19.18 0 0119.18 19.18v257.92a.9.9 0 01-.9.9h-367a.9.9 0 01-.9-.9V438.72a19.18 19.18 0 0119.18-19.18z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M521.2 505.47H399.34a4.22 4.22 0 010-8.43H521.2a4.22 4.22 0 110 8.43zM469 485.88h-69.66a4.22 4.22 0 010-8.43H469a4.22 4.22 0 110 8.43zM469 525.05h-69.66a4.22 4.22 0 010-8.43H469a4.22 4.22 0 110 8.43zM576.64 657.41H507a4.22 4.22 0 010-8.43h69.64a4.22 4.22 0 010 8.43zM602.46 678.07H481.18a4.22 4.22 0 010-8.43h121.28a4.22 4.22 0 110 8.43z"
        />{" "}
        <g className="Skills__Web_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M678.66 476.77a4.79 4.79 0 011.18 3.12v65.77a5.5 5.5 0 01-5.56 5.23h-73.36a5.2 5.2 0 01-5.23-5.23v-65.77a5.19 5.19 0 014.47-5.15v59.87l17.2-17.28a5.28 5.28 0 017.42 0l6.74 6.74 17.29-17.2a5.3 5.3 0 017.42 0l17.2 17.2v-49.41h.85a5.76 5.76 0 014.38 2.11zm-4.13 59.87v-7.85l-19.06-19a2.5 2.5 0 00-3.71 0s-12.65 12.56-15.43 15.34-4.64 2.79-7.42 0l-4.91-4.94a2.65 2.65 0 00-3.71 0L602 538.49a2.35 2.35 0 001.86.76h68a2.63 2.63 0 002.67-2.61z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M673.43 474.66v49.41l-17.2-17.2a5.3 5.3 0 00-7.42 0l-17.29 17.2-6.74-6.74a5.28 5.28 0 00-7.42 0l-17.2 17.28v-59.87a2.43 2.43 0 01.76-.08zM631.19 490a10.26 10.26 0 10-20.49 0 10.26 10.26 0 1020.49 0z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M673.43 471.29v3.37h-72.51a2.43 2.43 0 00-.76.08v-3.45a2.63 2.63 0 012.61-2.62h68.05a2.63 2.63 0 012.61 2.62zM621 479.3a10.72 10.72 0 010 21.42 10.72 10.72 0 010-21.42zm6.31 10.7a6.33 6.33 0 10-12.65 0 6.33 6.33 0 1012.65 0z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M679.75 539.23v3.88a5.26 5.26 0 01-5.23 5.23h-73.28a5.2 5.2 0 01-5.22-5.23v-3.88a5.21 5.21 0 005.22 5.31h73.28a5.27 5.27 0 005.23-5.31z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M602 538.47a2.36 2.36 0 001.86.76h68a2.58 2.58 0 002.61-2.62v-7.84l-19.06-19a2.63 2.63 0 00-3.71 0l-15.43 15.34c-2.78 2.79-4.63 2.79-7.42 0l-4.85-4.94a2.65 2.65 0 00-3.71 0zm77.75-68.72v69.48a5.27 5.27 0 01-5.23 5.31h-73.28a5.21 5.21 0 01-5.22-5.31v-69.48a5.19 5.19 0 015.22-5.23h73.28a5.25 5.25 0 015.23 5.23zm-5.23 51.6v-52.78a2.63 2.63 0 00-2.61-2.62h-68a2.64 2.64 0 00-2.62 2.62v63.32l17.21-17.28a5.28 5.28 0 017.42 0l6.74 6.74 17.29-17.2a5.3 5.3 0 017.42 0z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M679.75 466v3.8a5.25 5.25 0 00-5.23-5.23h-73.28a5.19 5.19 0 00-5.22 5.23V466a5.2 5.2 0 015.22-5.23h73.28a5.26 5.26 0 015.23 5.23z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M674.52 468.57v52.78l-17.2-17.2a5.3 5.3 0 00-7.42 0l-17.29 17.2-6.74-6.74a5.28 5.28 0 00-7.42 0l-17.21 17.28v-63.32a2.64 2.64 0 012.62-2.62h68a2.63 2.63 0 012.66 2.62zm-42.24 18.72a10.26 10.26 0 10-20.49 0 10.26 10.26 0 1020.49 0z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M622.07 476.58a10.72 10.72 0 010 21.42 10.72 10.72 0 010-21.42zm6.33 10.71a6.33 6.33 0 10-12.65 0 6.33 6.33 0 1012.65 0z"
        />{" "}
        <circle
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-5"
          cx={580.74}
          cy={534.51}
          r={6.53}
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M646.5 627.8h-18.64a2.11 2.11 0 010-4.22h18.64a2.11 2.11 0 110 4.22z"
        />{" "}
        <g className="Skills__Web_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M652.94 585.35a1.87 1.87 0 01.42 1.16v25.09a2.12 2.12 0 01-2.12 2h-27.93a2 2 0 01-2-2v-25.09a1.94 1.94 0 011.67-2v22.81l6.59-6.55a2 2 0 012.83 0l2.57 2.57 6.55-6.59a2 2 0 012.83 0l6.56 6.59v-18.83h.32a2.17 2.17 0 011.71.84zm-1.61 22.78v-3l-7.26-7.26a1 1 0 00-1.41 0l-5.85 5.85c-1.06 1.06-1.8 1.06-2.83 0s-1.89-1.86-1.89-1.86a1 1 0 00-1.39 0l-7 7a1 1 0 00.71.32h25.93a1 1 0 00.99-1.05z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M650.92 584.51v18.87l-6.56-6.59a2 2 0 00-2.83 0l-6.53 6.59-2.57-2.57a2 2 0 00-2.83 0l-6.6 6.55v-22.81a.91.91 0 01.32 0zm-16.07 5.85a3.91 3.91 0 10-3.92 4.08 4 4 0 003.92-4.08zM650.92 583.23v1.28h-27.61a.91.91 0 00-.32 0v-1.32a1 1 0 011-1h25.93a1 1 0 011 1.04z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M630.93 586.28a4.08 4.08 0 11-3.89 4.08 4 4 0 013.89-4.08zm2.41 4.08a2.41 2.41 0 10-4.82 0 2.41 2.41 0 104.82 0z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M653.35 609.15v1.45a2 2 0 01-2 2h-27.94a2 2 0 01-2-2v-1.45a2 2 0 002 2h27.92a2 2 0 002.02-2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M623.7 608.86a1.15 1.15 0 00.7.29h25.93a1 1 0 001-1v-3l-7.26-7.3a1 1 0 00-1.42 0l-5.84 5.88c-1.06 1.06-1.8 1-2.83 0s-1.87-1.89-1.87-1.89a1 1 0 00-1.41 0zm29.65-26.19v26.48a2 2 0 01-2 2h-27.94a2 2 0 01-2-2v-26.48a2 2 0 012-2h27.92a2 2 0 012.02 2zm-2 19.67v-20.15a1 1 0 00-1-1H624.4a1 1 0 00-1 1v24.13l6.58-6.55a2 2 0 012.83 0l2.57 2.57 6.56-6.59a2 2 0 012.83 0z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M653.35 581.2v1.47a2 2 0 00-2-2h-27.94a2 2 0 00-2 2v-1.47a2 2 0 012-2h27.92a2 2 0 012.02 2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M651.33 582.19v20.15l-6.55-6.59a2 2 0 00-2.83 0l-6.56 6.59-2.57-2.57a2 2 0 00-2.83 0l-6.58 6.55v-24.13a1 1 0 011-1h25.93a1 1 0 01.99 1zm-16.07 7.14a3.9 3.9 0 10-3.92 4.08 4 4 0 003.92-4.08z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M631.34 585.24a4.09 4.09 0 11-3.88 4.09 4 4 0 013.88-4.09zm2.41 4.09a2.45 2.45 0 00-2.41-2.51 2.51 2.51 0 000 5 2.44 2.44 0 002.41-2.49z"
        />{" "}
        <circle
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-5"
          cx={631.2}
          cy={589.3}
          r={2.49}
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M579.4 627.8h-18.63a2.11 2.11 0 010-4.22h18.63a2.11 2.11 0 010 4.22z"
        />{" "}
        <g className="Skills__Web_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M585.84 585.35a1.87 1.87 0 01.42 1.16v25.09a2.12 2.12 0 01-2.12 2h-27.93a2 2 0 01-2-2v-25.09a1.94 1.94 0 011.67-2v22.81l6.59-6.55a2 2 0 012.83 0l2.57 2.57 6.55-6.59a2 2 0 012.83 0l6.56 6.59v-18.83h.32a2.16 2.16 0 011.71.84zm-1.61 22.78v-3l-7.23-7.25a1 1 0 00-1.41 0l-5.85 5.85c-1.06 1.06-1.8 1.06-2.83 0s-1.89-1.86-1.89-1.86a1 1 0 00-1.39 0l-7 7a1 1 0 00.71.32h25.93a1 1 0 00.96-1.06z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M583.82 584.51v18.87l-6.56-6.59a2 2 0 00-2.83 0l-6.55 6.59-2.57-2.57a2 2 0 00-2.83 0l-6.59 6.55v-22.81a.91.91 0 01.32 0zm-16.07 5.85a3.91 3.91 0 10-3.92 4.08 4 4 0 003.92-4.08zM583.82 583.23v1.28h-27.61a.91.91 0 00-.32 0v-1.32a1 1 0 011-1h25.93a1 1 0 011 1.04z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M563.83 586.28a4.08 4.08 0 11-3.89 4.08 4 4 0 013.89-4.08zm2.41 4.08a2.41 2.41 0 10-4.82 0 2.41 2.41 0 104.82 0z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M586.26 609.15v1.45a2 2 0 01-2 2h-27.95a2 2 0 01-2-2v-1.45a2 2 0 002 2h27.92a2 2 0 002.03-2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M556.6 608.86a1.15 1.15 0 00.7.29h25.93a1 1 0 001-1v-3l-7.26-7.3a1 1 0 00-1.42 0l-5.84 5.88c-1.06 1.06-1.8 1-2.83 0s-1.86-1.89-1.86-1.89a1 1 0 00-1.42 0zm29.66-26.19v26.48a2 2 0 01-2 2h-27.95a2 2 0 01-2-2v-26.48a2 2 0 012-2h27.92a2 2 0 012.03 2zm-2 19.67v-20.15a1 1 0 00-1-1H557.3a1 1 0 00-1 1v24.13l6.58-6.55a2 2 0 012.83 0l2.57 2.57 6.56-6.59a2 2 0 012.83 0z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M586.26 581.2v1.47a2 2 0 00-2-2h-27.95a2 2 0 00-2 2v-1.47a2 2 0 012-2h27.92a2 2 0 012.03 2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M584.23 582.19v20.15l-6.55-6.59a2 2 0 00-2.83 0l-6.56 6.59-2.57-2.57a2 2 0 00-2.83 0l-6.58 6.55v-24.13a1 1 0 011-1h25.93a1 1 0 01.99 1zm-16.07 7.14a3.9 3.9 0 10-3.92 4.08 4 4 0 003.92-4.08z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M564.24 585.24a4.09 4.09 0 11-3.88 4.09 4 4 0 013.88-4.09zm2.41 4.09a2.45 2.45 0 00-2.41-2.51 2.51 2.51 0 000 5 2.44 2.44 0 002.41-2.49z"
        />{" "}
        <circle
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-5"
          cx={564.1}
          cy={589.3}
          r={2.49}
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M445.2 627.8h-18.63a2.11 2.11 0 010-4.22h18.63a2.11 2.11 0 010 4.22z"
        />{" "}
        <g className="Skills__Web_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M451.64 585.35a1.87 1.87 0 01.42 1.16v25.09a2.12 2.12 0 01-2.12 2H422a2 2 0 01-2-2v-25.09a1.94 1.94 0 011.67-2v22.81l6.59-6.55a2 2 0 012.83 0l2.57 2.57 6.55-6.59a2 2 0 012.83 0l6.56 6.59v-18.83h.32a2.16 2.16 0 011.72.84zM450 608.13v-3l-7.26-7.26a1 1 0 00-1.41 0l-5.85 5.85c-1.06 1.06-1.8 1.06-2.83 0s-1.89-1.86-1.89-1.86a1 1 0 00-1.38 0l-7 7a1 1 0 00.71.32H449a1 1 0 001-1.05z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M449.62 584.51v18.87l-6.56-6.59a2 2 0 00-2.83 0l-6.55 6.59-2.57-2.57a2 2 0 00-2.83 0l-6.59 6.55v-22.81a.91.91 0 01.32 0zm-16.07 5.85a3.91 3.91 0 10-3.92 4.08 4 4 0 003.92-4.08zM449.62 583.23v1.28H422a.91.91 0 00-.32 0v-1.32a1 1 0 011-1h25.93a1 1 0 011.01 1.04z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M429.63 586.28a4.08 4.08 0 11-3.89 4.08 4 4 0 013.89-4.08zm2.41 4.08a2.41 2.41 0 10-4.82 0 2.41 2.41 0 104.82 0z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M452.06 609.15v1.45a2 2 0 01-2 2h-27.95a2 2 0 01-2-2v-1.45a2 2 0 002 2H450a2 2 0 002.06-2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M422.4 608.86a1.15 1.15 0 00.7.29H449a1 1 0 001-1v-3l-7.26-7.3a1 1 0 00-1.41 0l-5.85 5.88c-1.06 1.06-1.8 1-2.83 0s-1.86-1.89-1.86-1.89a1 1 0 00-1.42 0zm29.66-26.19v26.48a2 2 0 01-2 2h-27.95a2 2 0 01-2-2v-26.48a2 2 0 012-2H450a2 2 0 012.06 2zm-2 19.67v-20.15a1 1 0 00-1-1H423.1a1 1 0 00-1 1v24.13l6.59-6.55a2 2 0 012.82 0l2.57 2.57 6.56-6.59a2 2 0 012.83 0z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M452.06 581.2v1.47a2 2 0 00-2-2h-27.95a2 2 0 00-2 2v-1.47a2 2 0 012-2H450a2 2 0 012.06 2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M450 582.19v20.15l-6.55-6.59a2 2 0 00-2.83 0l-6.56 6.59-2.57-2.57a2 2 0 00-2.82 0l-6.59 6.55v-24.13a1 1 0 011-1H449a1 1 0 011 1zm-16 7.14a3.9 3.9 0 10-3.92 4.08 4 4 0 003.92-4.08z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M430 585.24a4.09 4.09 0 11-3.88 4.09 4 4 0 013.88-4.09zm2.41 4.09a2.45 2.45 0 00-2.41-2.51 2.51 2.51 0 000 5 2.44 2.44 0 002.45-2.49z"
        />{" "}
        <circle
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-5"
          cx={429.9}
          cy={589.3}
          r={2.49}
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M512.3 627.8h-18.63a2.11 2.11 0 010-4.22h18.63a2.11 2.11 0 010 4.22z"
        />{" "}
        <g className="Skills__Web_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M518.74 585.35a1.87 1.87 0 01.42 1.16v25.09a2.12 2.12 0 01-2.12 2h-27.93a2 2 0 01-2-2v-25.09a1.94 1.94 0 011.67-2v22.81l6.59-6.55a2 2 0 012.83 0l2.57 2.57 6.55-6.59a2 2 0 012.83 0l6.56 6.59v-18.83h.29a2.16 2.16 0 011.74.84zm-1.61 22.78v-3l-7.26-7.26a1 1 0 00-1.41 0l-5.85 5.85c-1.06 1.06-1.8 1.06-2.83 0s-1.89-1.86-1.89-1.86a1 1 0 00-1.39 0l-7 7a1 1 0 00.71.32h25.93a1 1 0 00.99-1.05z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M516.72 584.51v18.87l-6.56-6.59a2 2 0 00-2.83 0l-6.55 6.59-2.57-2.57a2 2 0 00-2.83 0l-6.59 6.55v-22.81a.91.91 0 01.32 0zm-16.07 5.85a3.91 3.91 0 10-3.92 4.08 4 4 0 003.92-4.08zM516.72 583.23v1.28h-27.61a.91.91 0 00-.32 0v-1.32a1 1 0 011-1h25.93a1 1 0 011 1.04z"
          />{" "}
          <path
            className="Skills__Web_Testing_Expert_-_Small_svg__cls-7"
            d="M496.73 586.28a4.08 4.08 0 11-3.89 4.08 4 4 0 013.89-4.08zm2.41 4.08a2.41 2.41 0 10-4.82 0 2.41 2.41 0 104.82 0z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M519.16 609.15v1.45a2 2 0 01-2 2h-27.95a2 2 0 01-2-2v-1.45a2 2 0 002 2h27.92a2 2 0 002.03-2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M489.5 608.86a1.15 1.15 0 00.7.29h25.94a1 1 0 001-1v-3l-7.26-7.3a1 1 0 00-1.41 0l-5.85 5.88c-1.06 1.06-1.8 1-2.83 0s-1.86-1.89-1.86-1.89a1 1 0 00-1.42 0zm29.66-26.19v26.48a2 2 0 01-2 2h-27.95a2 2 0 01-2-2v-26.48a2 2 0 012-2h27.92a2 2 0 012.03 2zm-2 19.67v-20.15a1 1 0 00-1-1H490.2a1 1 0 00-1 1v24.13l6.58-6.55a2 2 0 012.83 0l2.57 2.57 6.56-6.59a2 2 0 012.83 0z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M519.16 581.2v1.47a2 2 0 00-2-2h-27.95a2 2 0 00-2 2v-1.47a2 2 0 012-2h27.92a2 2 0 012.03 2z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M517.13 582.19v20.15l-6.55-6.59a2 2 0 00-2.83 0l-6.56 6.59-2.57-2.57a2 2 0 00-2.83 0l-6.58 6.55v-24.13a1 1 0 011-1h25.94a1 1 0 01.98 1zm-16.07 7.14a3.9 3.9 0 10-3.92 4.08 4 4 0 003.92-4.08z"
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          d="M497.14 585.24a4.09 4.09 0 11-3.88 4.09 4 4 0 013.88-4.09zm2.41 4.09a2.45 2.45 0 00-2.41-2.51 2.51 2.51 0 000 5 2.44 2.44 0 002.41-2.49z"
        />{" "}
        <circle
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-5"
          cx={497}
          cy={589.3}
          r={2.49}
        />{" "}
        <rect
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-2"
          x={399.34}
          y={540.83}
          width={32.23}
          height={13.06}
          rx={2.55}
        />{" "}
        <path
          className="Skills__Web_Testing_Expert_-_Small_svg__cls-5"
          d="M279.55 378.97h519.43v27.84H279.55z"
        />{" "}
        <circle cx={300.33} cy={392.44} r={7.48} fill="#b71d15" />{" "}
        <circle cx={322.56} cy={392.44} r={7.48} fill="#f4c404" />{" "}
        <circle cx={344.79} cy={392.44} r={7.48} fill="#2ecc71" />{" "}
      </g>{" "}
    </svg>
  );
};

export default WebTestingExpertSmall;
