import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import ReactModalV2 from "../../../modals/react_modal_v2";
import { SubmitButton } from "../../../common/modal_buttons";
import InviteUsersForm from "../InviteUsersForm";
import { wikiAssessmentTypes } from "../../../../types/wiki";
// import styled from "styled-components";

const PublishAssessmentModal = ({
  assessment,
  postEditAssessment,
  updateAssessmentData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const methods = useFormContext();
  const { trigger, getValues } = methods;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openModal = async (e) => {
    e.preventDefault();
    try {
      const isValid = await trigger();
      if (isValid) {
        const data = getValues();
        const response = await postEditAssessment(data);
        if (response) {
          toggleModal();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SubmitButton type="button" onClick={openModal}>
        Publish
      </SubmitButton>
      {isModalOpen && (
        <ReactModalV2
          id="publish-assessment-modal"
          isShowing={isModalOpen}
          data={{ customClass: "publish-assessment-modal" }}
          modalAction={toggleModal}
        >
          <InviteUsersForm
            assessment={assessment}
            toggleModal={toggleModal}
            updateAssessmentData={updateAssessmentData}
            publish
          />
        </ReactModalV2>
      )}
    </>
  );
};

PublishAssessmentModal.propTypes = {
  assessment: wikiAssessmentTypes.isRequired,
  postEditAssessment: PropTypes.func.isRequired,
  updateAssessmentData: PropTypes.func.isRequired,
};

export default PublishAssessmentModal;
