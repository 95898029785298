import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { WidgetTitle } from "../styles";
import PropTypes from "prop-types";
import Table from "rc-table";
import moment from "moment";
import RoundDropdown from "../../icons/round_dropdown";
import TextHistoryIcon from "../../icons/performance_bio/TextHistory";

const [
  DATE,
  PROJECT_NAME,
  TOTAL_BUGS,
  HOURS_SPENT,
  TEST_STEPS,
  PLATFORMS_TESTED,
] = [
  "date",
  "project_name",
  "total_bugs",
  "hours",
  "test_steps",
  "platforms_tested",
];

const [ASC, DESC] = ["asc", "desc"];

const EODHistory = (props) => {
  const [reports, setReports] = useState([]);
  const [sortKey, setSortKey] = useState(DATE);
  const [sortOrder, setSortOrder] = useState(DESC);

  useEffect(() => {
    if (props.reports) {
      const formattedReports = props.reports.data.map((report) => ({
        ...report,
        date: moment(report.date, "YYYY-MM-DD HH:mm:ss Z").format("MM/DD/YYYY"),
      }));
      setReports(formattedReports);
    }
  }, [props.reports]);

  const sortedData = useMemo(() => {
    const sorted = [...reports].sort((a, b) => {
      const SORT_MAPS = {
        [DATE]: (a, b) =>
          moment(b.date, "MM/DD/YYYY").diff(moment(a.date, "MM/DD/YYYY")),
        [PROJECT_NAME]: (a, b) => a.project_name.localeCompare(b.project_name),
        [TOTAL_BUGS]: (a, b) => a.total_bugs - b.total_bugs,
        [HOURS_SPENT]: (a, b) => a.hours_spent - b.hours_spent,
        [TEST_STEPS]: (a, b) => a.test_steps - b.test_steps,
        [PLATFORMS_TESTED]: (a, b) =>
          a.eod_platforms.length - b.eod_platforms.length,
      };
      if (SORT_MAPS[sortKey] === undefined) {
        return 0;
      }
      let value = SORT_MAPS[sortKey](a, b);
      return value * (sortOrder === ASC ? 1 : -1);
    });

    return sorted;
  }, [reports, sortOrder, sortKey]);

  const setSorter = (key) => {
    if (key === sortKey) {
      setSortOrder((prevSortOrder) => (prevSortOrder === ASC ? DESC : ASC));
    } else {
      setSortKey(key);
      setSortOrder(DESC);
    }
  };
  const columns = [
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(DATE)} $align="center">
          Date
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === DATE}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [DATE],
      width: 180,
      className: "eod-history-date",
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(PROJECT_NAME)}>
          Project Name
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === PROJECT_NAME}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [PROJECT_NAME],
      className: "eod-history-project-name",
      render: (text, project) => (
        <ResourceLink href={`/projects/${project.project_id}/dashboard`}>
          {text}
        </ResourceLink>
      ),
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(TOTAL_BUGS)}>
          Total Bugs
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === TOTAL_BUGS}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [TOTAL_BUGS],
      className: "eod-history-total-bugs",
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(HOURS_SPENT)}>
          Hours Spent
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === HOURS_SPENT}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [HOURS_SPENT],
      className: "eod-history-hours-spent",
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(TEST_STEPS)}>
          Test Steps
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === TEST_STEPS}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [TEST_STEPS],
      className: "eod-history-test-steps",
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(PLATFORMS_TESTED)}>
          Platforms Tested
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === PLATFORMS_TESTED}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [PLATFORMS_TESTED],
      className: "eod-history-platforms-tested",
      render: (text, record) => (
        <TableText>{record.eod_platforms.length}</TableText>
      ),
    },
  ];

  return (
    <EODContainer>
      <WidgetContainer>
        <IconWrapper>
          <TextHistoryIcon />
          <WidgetTitle style={{ marginLeft: "15px" }}>EOD History</WidgetTitle>
        </IconWrapper>
      </WidgetContainer>
      <TableContainer>
        <Table
          className="performance-assessments-table "
          data={sortedData}
          columns={columns}
          rowKey="id"
          scroll={{ y: 400 }}
        />
      </TableContainer>
    </EODContainer>
  );
};

const EODContainer = styled.div`
  height: 100%;
`;

const WidgetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;
const TableContainer = styled.div`
  width: 100%;
  padding: 8px 15px;
  margin-top: 23px;
  height: 100%;
  background-color: #ededed;
  height: 90%;
  flex-grow: 1;
  overflow-y: auto;
`;
const TableText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

const ColumnHeaderButton = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding: 0;

  width: 100%;
`;
const ResourceLink = styled.a`
  font-size: 14px;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
`;

const IconContainer = styled.div`
  margin-left: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: transform, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  ${({ $rotate }) => $rotate && `transform: rotate(180deg);`}
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;
const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

EODHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      project_name: PropTypes.string,
      project_id: PropTypes.number,
      total_bugs: PropTypes.number,
      hours_spent: PropTypes.number,
      test_steps: PropTypes.number,
      platforms_tested: PropTypes.number,
    })
  ),
};

export default EODHistory;
