import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import DownloadIcon from "../icons/download_icon";
import ReactModalV2 from "../modals/react_modal_v2";

const MediaList = styled.div`
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px lightgrey;
  padding: 20px;
  margin: 5px;

  > div > a {
    margin-bottom: 10px;
    line-height: 12px;
  }

  &:first-child {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  > div:nth-child(1) {
    height: 45px;
    margin: 15px 0 25px;
  }

  > .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow: auto hidden;
    transition: max-width 0.5s;
    padding: 3px 3px 3px 0;
    min-height: 175px;
  }
`;

const MediaSquare = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
  border: 1px solid lightgrey;
  margin-right: 10px;
  cursor: pointer;
  transition: border-color 0.5s;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  z-index: 1;
  border-color: lightgray;
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > p {
      flex: 1;
      padding: 3px 8px;
      word-break: break-all;
      height: 100px;
      display: inline-block;
      width: 130px;
      pointer-events: none;
      padding: 3px 8px;
      white-space: pre-wrap;
    }
    > button {
      padding: 3px 8px;
      &:hover {
        > svg {
          fill: #519acc !important;
        }
      }
    }
  }
`;

function MediaRecents(props) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState("");
  const [modalData, setModalData] = useState({});
  const containerRef = useRef();

  function getData() {
    setLoading(true);
    var url = `/projects/${props.projectId}/media_items.json`;
    axios
      .get(url)
      .then((res) => {
        setItems(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function displayLoader() {
    return (
      <div style={{ height: props.height + "px", width: "100%" }}>
        <div id="test-step-bugs-loader" />
      </div>
    );
  }

  function editModal(e) {
    var image = items.find((x) => x.id == e.target.dataset.itemid);
    if (image) {
      setModal(true, "ticket-attachments", {
        images: [image],
        customClass: "ticket-attachments-modal",
        backdropClickCheck: {
          title: "Exit Image Edit",
          setBypassClickCheck: true,
          text: "Are you sure that you want to exit image edit? Any unsaved changes will not be preserved.",
        },
        currentIndex: 0,
        setBypassClickCheck: true,
        hideEdit: true,
        edit: true,
        handleNewImages: null,
      });
    }
  }

  function setModal(bool, page, data) {
    setModalOpen(bool);
    setCurrentModal({ page: page });
    setModalData(data);
  }

  function displayFile(item) {
    var element =
      "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/file-icon.png";
    if (
      item &&
      item.file_type &&
      item.file_type.includes("image") &&
      item.file_type.includes("svg")
    ) {
      // svgs download from digital ocean so gotta find a way around this maybe. People likely don't take screenshots with svg
    } else if (item && item.file_type && item.file_type.includes("image")) {
      element = item.url;
    } else if (item && item.file_type && item.file_type.includes("video")) {
      // element = <video style={{width: "30px", height: "30px", borderRadius: "3px"}} alt="media item" src={props.item.url}/>
    }
    return element;
  }

  function handleMediaDownload(e) {
    var id = e.target.dataset.itemid;
    var item = items.find((x) => x.id == id);
    if (item) {
      e.stopPropagation();
      setLoading(true);
      var dl = document.createElement("a");
      dl.setAttribute("href", `${item.download_url}`);
      dl.setAttribute("download", `${item.file_name}`);
      dl.click();
      dl.remove();
      setLoading(false);
    }
  }

  function displayItems() {
    if (items.length >= 1) {
      var width = "175px";
      if (props.height) {
        width = props.height + "px";
      }
      return items.map((item, index) => (
        <MediaSquare
          data-itemid={item.id}
          onClick={editModal}
          className="media-square"
          key={item.id}
          style={{
            width: width,
            height: width,
            display: "inline-block",
            backgroundImage: `url(${displayFile(item)}`,
            marginLeft: index >= 1 ? "" : "0px",
          }}
        >
          <div
            data-itemid={item.id}
            onClick={editModal}
            className="media-recent-overlay"
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "row",
              display: "flex",
              overflow: "visible",
              flex: 1,
              margin: 0,
            }}
          >
            <p>{item.file_name}</p>
            <button
              data-itemid={item.id}
              onClick={handleMediaDownload}
              style={{ cursor: "pointer", right: "6px", bottom: "0px" }}
              className="link-btn"
            >
              <DownloadIcon
                svgStyles={{
                  fill: "white",
                  transition: "fill .5s",
                  pointerEvents: "none",
                  strokeLinecap: "round",
                  strokeWidth: "3px",
                }}
              />
            </button>
          </div>
        </MediaSquare>
      ));
    } else {
      return (
        <p style={{ padding: "10px", textAlign: "center" }}>No Recent Media</p>
      );
    }
  }

  function mediaHandler() {
    return null;
  }

  useEffect(() => {
    getData();
  }, []);

  function getExpanded() {
    const expanded =
      sessionStorage.getItem("expanded") === "true"
        ? true
        : sessionStorage.getItem("expanded") === "false"
        ? false
        : true;
    return expanded;
  }

  return (
    <MediaList>
      <ReactModalV2
        isShowing={modalOpen}
        page={currentModal}
        data={modalData}
        modalAction={setModal}
        handler={mediaHandler}
      />
      <div>
        <p className="performance-large-title">Recent Uploads</p>
        <a href={`/projects/${props.projectId}/library`}>
          Navigate to Media Library
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          minWidth: 0,
          width: loading ? "100%" : "",
          maxWidth: `calc(100vw - ${getExpanded() ? "350px" : "210px"}`,
        }}
      >
        <div
          ref={containerRef}
          className="flex hide-scrollbar"
          style={{
            whiteSpace: "nowrap",
            overflow: "scroll",
            width: loading ? "100%" : "",
          }}
        >
          {loading ? displayLoader() : displayItems()}
        </div>
      </div>
    </MediaList>
  );
}

export default MediaRecents;
