import React from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import { MentionsInput, Mention } from "react-mentions";
import ReactGA from "react-ga";
import Swal from "sweetalert2";
import ApiClient from "@utils/ApiClient";
import AttachmentCarousel from "./attachment_carousel";

ReactGA.initialize(`${process.env.G_ANALYTICS_TAG}`, {
  debug: !process.env.NODE_ENV === "production",
  titleCase: false,
});

const CommentFormWrapper = styled.div`
  /*
     * mentions alignment is off, this is a fix.
     * see: https://stackoverflow.com/questions/69439961/material-uis-cssbaseline-breaking-react-mentions
     */

  .commentMention {
    * {
      line-height: 1;
      box-sizing: border-box;
    }

    .commentMention__highlighter {
      strong {
        line-height: 1;
      }
    }
  }
`;

class CommentForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const users = [];
    for (let i = 0; i < this.props.users.length; i++) {
      const user = this.props.users[i];
      if (user.value > 0) {
        users.push({
          id: user.value,
          display: user.label.props ? user.label.props.children[1] : user.label,
        });
      }
    }
    this.state = {
      commentValue: "",
      value: "",
      data: [],
      tags: [],
      attachments: [],
      userBox: false,
      userOptions: users,
    };

    this.autoComplete = React.createRef();

    this.inputFocus = (e) => {
      e.target.parentElement.parentElement.style.marginTop = "30px";
      const elem = document.querySelector("#new-comment-icons");
      if (elem) {
        elem.style.transitionDelay = "0.2s";
        elem.style.opacity = "1";
      }
    };

    this.inputBlur = (e) => {
      if (
        !this.refs.commentForm.contains(e.relatedTarget) &&
        !(this.state.value || this.state.attachments.length > 0)
      ) {
        const elem = document.querySelector("#new-comment-icons");
        e.target.parentElement.parentElement.style.marginTop = "10px";
        if (elem) {
          elem.style.transitionDelay = "0s";
          elem.style.opacity = "0";
        }
      }
    };
  }

  componentDidMount() {
    const elem = document.querySelector("#commentForm textarea");
    if (elem) {
      elem.addEventListener("focus", this.inputFocus);
      elem.addEventListener("blur", this.inputBlur);
    }
  }

  onCommentSubmit = (formData) => {
    const { data } = this.state;
    const { setLoadingClass, setLoadingText, handler } = this.props;
    const api = new ApiClient({
      "Content-Type": "multipart/form-data",
    });
    const commentMention = document.querySelector(
      "#commentForm > .commentMention"
    );
    const newCommentIcons = document.querySelector("#new-comment-icons");
    api
      .post("/comments", formData)
      .then((res) => {
        const nextData = [res.data, ...data];
        this.setState({
          data: nextData,
          commentValue: "",
          value: "",
          tags: [],
          attachments: [],
        });
        if (commentMention) {
          commentMention.style.marginTop = "10px";
          if (newCommentIcons) {
            newCommentIcons.style.transitionDelay = "0s";
            newCommentIcons.style.opacity = "0";
          }
        }
        handler(res.data, "create");
        ReactGA.event({
          category: "Ticket Comment",
          action: "Ticket Comment Created",
        });
      })
      .catch((err) => {
        console.error(err);
        ReactGA.event({
          category: "Ticket Comment",
          action: "Ticket Comment error",
        });
        Swal.fire(
          "Comment submit error",
          "There was an error submitting this ticket"
        );
      })
      .finally(() => {
        setLoadingClass("form-update-end-loading");
        setLoadingText(false);
      });
  };

  handleChange = (e, newValue, newPlainTextValue, mentions) => {
    let filteredTags;
    if (this.state.value.length - newValue.length > 1) {
      const userString = this.state.value.slice(newValue.length);
      const userIds = [];
      this.state.userOptions.forEach((u) => {
        if (userString.includes(u.display)) {
          userIds.push(u.id);
        }
      });
      filteredTags = this.state.tags.filter((t) => userIds.indexOf(t) === -1);
    }
    this.setState({
      value: newValue,
      tags: filteredTags || this.state.tags,
    });
  };

  addMentionees = (id, display) => {
    const { tags } = this.state;
    tags.push(id);
    this.setState({ tags });
  };

  handleAddFile = () => {
    try {
      const fileExtension = document
        .getElementById("comment-file")
        .value.split(".")
        .pop()
        .toLowerCase();
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "chls",
        "rtf",
        "txt",
        "doc",
        "docx",
        "zip",
        "pdf",
        "mp4",
        "mov",
        "json",
      ];
      if (!allowedExtensions.includes(fileExtension)) {
        M.toast({
          html: "Invalid File Type",
          classes: "red darken-1 error-toast",
        });
        this.refs.file.value = "";
        return;
      }
      const { files } = document.getElementById("comment-file");
      const checkedFiles = [];
      for (const key in files) {
        if (files.hasOwnProperty(key)) {
          const file = files[key];
          // file size limit: 100MB
          if (file.size > 100 * 1024 * 1024) {
            M.toast({
              html: "File exceeds size limit",
              classes: "red darken-1 error-toast",
            });
            this.refs.file.value = "";
            return;
          }
          if (files.length > 3) {
            M.toast({
              html: "Please limit selection to 3 files at a time",
              classes: "red darken-1 error-toast",
            });
            this.refs.file.value = "";
            return;
          }
          file.id = key;
          checkedFiles.push(file);
        }
      }
      this.setState({
        attachments: this.state.attachments.concat(checkedFiles),
      });
      this.refs.file.value = "";
    } catch (err) {
      err.message = `Comment Attachment Add Error - frontend: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
  };

  handleSubmit = (e) => {
    try {
      e.preventDefault();
      const { value } = this.state;

      if (!value) {
        return null;
      }
      if (value === "") {
        return null;
      }

      this.props.setLoadingClass("form-update-start-loading");
      this.props.setLoadingText(true);
      const formData = new FormData();

      if (this.state.tags.length > 0) {
        formData.append("comment[tag_ids]", this.state.tags);
      }

      if (this.state.attachments.length > 0) {
        for (let x = 0; x < this.state.attachments.length; x++) {
          formData.append("attachments[]", this.state.attachments[x]);
          formData.append("filenames[]", this.state.attachments[x].name);
        }
      }

      formData.append("comment[value]", value);
      formData.append("comment[ticket_id]", this.props.ticketId);
      this.onCommentSubmit(formData);
    } catch (err) {
      err.message = `Comment Submit Error - frontend: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
  };

  attachmentClick = () => {
    if (this.refs.file) {
      this.refs.file.click();
    }
  };

  cancelClick = (e) => {
    e.preventDefault();
    this.setState({ attachments: [], tags: [], value: "", commentValue: "" });
    var elem = document.querySelector("#commentForm > .commentMention");
    if (elem) {
      elem.style.marginTop = "10px";
      var elem = document.querySelector("#new-comment-icons");
      if (elem) {
        elem.style.transitionDelay = "0s";
        elem.style.opacity = "0";
      }
    }
  };

  deleteAttachmentHandler = (file, e) => {
    if (file) {
      if (file.description) {
        var attachments = this.state.attachments.filter(
          (attachment) => attachment.name !== file.description
        );
      } else {
        var attachments = this.state.attachments.filter(
          (attachment) => attachment.name !== file.name
        );
      }
      this.setState({ attachments });
    }
  };

  mentionClick = () => {
    const elem = document.querySelector("#commentForm > div > div > textarea");
    if (elem) {
      elem.focus();
      this.setState({ value: `${this.state.value} @` });
    }
  };

  render() {
    return (
      <CommentFormWrapper className="col s12">
        <form
          id="commentForm"
          style={{
            height: "unset",
            paddingBottom: "1px",
            textAlign: "right",
            position: "relative",
          }}
          onSubmit={this.handleSubmit}
          ref="commentForm"
          encType="multipart/form-data"
        >
          <MentionsInput
            key="new-comment-mention"
            placeholder="Enter a comment here"
            maxLength="4000"
            className="commentMention browser-default common-input"
            value={this.state.value}
            onChange={this.handleChange}
            allowSpaceInQuery
            style={{
              input: {
                lineHeight: "1",
                // paddingLeft: "12px",
                // paddingTop: "6px",
                padding: "12px",
                outline: "none",
                overflow: "auto",
                height: "84px",
              },
              highlighter: {
                lineHeight: "1",
                overflow: "hidden",
                // paddingLeft: "12px",
                // paddingTop: "6px",
                padding: "12px",
                height: "84px",
              },
            }}
          >
            <Mention
              markup="@[[__display__]]"
              onAdd={this.addMentionees}
              trigger="@"
              style={{ color: "#519acc", backgroundColor: "#f5f5f5" }}
              displayTransform={(id, display) => `@${display}`}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => {
                const user = this.props.users.find(
                  (u) => u.value === suggestion.id
                );
                return (
                  <div className={`user ${focused ? "focused" : ""}`}>
                    {user ? user.label : highlightedDisplay}
                  </div>
                );
              }}
              data={this.state.userOptions.filter(
                (u) => !this.state.value.includes(u.display)
              )}
            />
          </MentionsInput>
          {(this.state.value || this.state.attachments.length > 0) && (
            <button onClick={this.cancelClick} className="common-button-cancel">
              Cancel
            </button>
          )}
          {(this.state.value || this.state.attachments.length > 0) && (
            <button
              type="submit"
              aria-label="submit new comment"
              className="common-button-submit"
            >
              Submit
            </button>
          )}
          <span
            className="comment-icon"
            id="new-comment-icons"
            style={{
              position: "absolute",
              opacity: "0",
              transition: "opacity 0.2s",
              transitionDelay: "0.2s",
              height: "30px",
              left: "0px",
              top: "-28px",
            }}
          >
            <input
              style={{
                height: "0px",
                width: "0px",
                opacity: "0",
                pointerEvents: "none",
              }}
              id="comment-file"
              type="file"
              accept=".jpg,.gif,.png,.chls,.rtf,.txt,.doc,.docx,.pdf,.mp4,.mov,.json"
              ref="file"
              onChange={this.handleAddFile}
              aria-hidden="true"
              multiple
            />
            <img
              aria-hidden="true"
              alt="mention icon"
              tabIndex={0}
              id="new-comment-mention-icon"
              onClick={this.mentionClick}
              style={{
                outline: "none",
                height: "24px",
                width: "24px",
                verticalAlign: "top",
                opacity: "0.7",
                cursor: "pointer",
              }}
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Mention.svg"
            />
            <img
              role="button"
              aria-label="attach file to new comment"
              alt="icon"
              tabIndex={0}
              onClick={this.attachmentClick}
              style={{
                outline: "none",
                height: "27px",
                width: "27px",
                opacity: this.state.attachments.length > 2 ? "0.5" : "0.7",
                pointerEvents:
                  this.state.attachments.length > 2 ? "none" : "all",
                cursor: "pointer",
              }}
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Image.svg"
            />
          </span>
          {this.state.attachments.length > 0 && (
            <AttachmentCarousel
              deleteAttachmentHandler={this.deleteAttachmentHandler}
              setLoadingClass={this.props.setLoadingClass}
              setLoadingText={this.props.setLoadingText}
              images={this.state.attachments}
              newTicket
            />
          )}
        </form>
      </CommentFormWrapper>
    );
  }
}

export default CommentForm;
