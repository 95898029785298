import React from "react";

const PencilCircleIcon = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1667 2C10.5809 2 2 10.5602 2 21.1205C2 31.6807 10.5809 40.2409 21.1667 40.2409C31.7524 40.2409 40.3333 31.6807 40.3333 21.1205C40.3333 10.5602 31.7524 2 21.1667 2Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 38.6504V24.9449L21.1667 11.5605L28.8333 24.9449V38.6504"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 24.9443C13.5 24.9443 15.6601 26.8564 17.3333 26.8564C19.0066 26.8564 21.1667 24.9443 21.1667 24.9443C21.1667 24.9443 23.3267 26.8564 25 26.8564C26.6732 26.8564 28.8333 24.9443 28.8333 24.9443"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PencilCircleIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default PencilCircleIcon;
