import React, { useContext } from "react";
import queryString from "query-string";
import { DeviceLabContext } from "@components/devices/device_lab";
import CheckboxMenu from "@components/common/checkbox_menu";
import useDeviceLab from "@hooks/data/useDeviceLab";

const FilterByTeam = () => {
  const { isClientUser } = useContext(DeviceLabContext);
  const { settings, filters, PARAM_KEYS } = useDeviceLab();
  const { TEAMS, PAGE } = PARAM_KEYS;
  const { query, teams: currentTeams } = filters;

  const handleTeamChange = (event) => {
    const teamId = event.target.value;
    const nextTeams = query[TEAMS] || [];
    delete query[PAGE];
    if (nextTeams.includes(teamId)) {
      // remove
      nextTeams.splice(nextTeams.indexOf(teamId), 1);
    } else {
      // add
      nextTeams.push(teamId);
    }
    const nextQueryString = queryString.stringify(
      { ...query, [TEAMS]: nextTeams },
      { arrayFormat: "bracket" } // represent the team array in query string as ?teams[]=id1&teams[]=id2
    );
    window.history.replaceState({}, "", `?${nextQueryString}`);
  };

  if (!settings?.[TEAMS] || isClientUser) {
    return null;
  }

  return (
    <CheckboxMenu
      key={1}
      maxWidth="100%"
      searchEnabled
      count={currentTeams.length}
      active={currentTeams.length >= 1}
      options={settings.teams.map((team) => ({
        label: team.name,
        value: team.id,
        handler: handleTeamChange,
        checked: currentTeams.indexOf(team.id) !== -1,
        classList: "user-option",
      }))}
      title="Filter By Team"
      id="1"
      icons={[
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
      ]}
    />
  );
};

export default FilterByTeam;
