import wikiResourceTypes from "./resource";
import {
  topicTypes as wikiTopicTypes,
  featuredTopicTypes as wikiFeaturedTopicTypes,
} from "./topic";
import wikiAssessmentTypes from "./assessment";
import { trialTypes } from "./trial";

export { wikiResourceTypes };
export { wikiTopicTypes };
export { wikiFeaturedTopicTypes };
export { wikiAssessmentTypes };
export { trialTypes };

export default {
  wikiResourceTypes,
  wikiTopicTypes,
  wikiFeaturedTopicTypes,
  wikiAssessmentTypes,
  trialTypes,
};
