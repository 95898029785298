import React from "react";
import PropTypes from "prop-types";

const CheckboxSquircleIcon = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
    >
      <path
        d="M8.54167 0C6.27628 0 4.10367 0.899923 2.5018 2.5018C0.899923 4.10367 0 6.27628 0 8.54167V32.4583C0 34.7237 0.899923 36.8963 2.5018 38.4982C4.10367 40.1001 6.27628 41 8.54167 41H32.4583C34.7237 41 36.8963 40.1001 38.4982 38.4982C40.1001 36.8963 41 34.7237 41 32.4583V8.54167C41 6.27628 40.1001 4.10367 38.4982 2.5018C36.8963 0.899923 34.7237 0 32.4583 0H8.54167ZM3.41667 8.54167C3.41667 7.18243 3.95662 5.87887 4.91774 4.91774C5.87887 3.95662 7.18243 3.41667 8.54167 3.41667H32.4583C33.8176 3.41667 35.1211 3.95662 36.0823 4.91774C37.0434 5.87887 37.5833 7.18243 37.5833 8.54167V32.4583C37.5833 33.8176 37.0434 35.1211 36.0823 36.0823C35.1211 37.0434 33.8176 37.5833 32.4583 37.5833H8.54167C7.18243 37.5833 5.87887 37.0434 4.91774 36.0823C3.95662 35.1211 3.41667 33.8176 3.41667 32.4583V8.54167ZM30.2512 16.5845C30.5719 16.2637 30.7522 15.8287 30.7522 15.375C30.7522 14.9213 30.5719 14.4863 30.2512 14.1655C29.9304 13.8447 29.4953 13.6645 29.0417 13.6645C28.588 13.6645 28.1529 13.8447 27.8322 14.1655L17.0833 24.9178L13.1678 20.9988C12.8471 20.6781 12.412 20.4978 11.9583 20.4978C11.5047 20.4978 11.0696 20.6781 10.7488 20.9988C10.4281 21.3196 10.2478 21.7547 10.2478 22.2083C10.2478 22.662 10.4281 23.0971 10.7488 23.4178L15.8738 28.5428C16.0325 28.7019 16.221 28.8281 16.4286 28.9143C16.6361 29.0004 16.8586 29.0447 17.0833 29.0447C17.308 29.0447 17.5305 29.0004 17.7381 28.9143C17.9456 28.8281 18.1341 28.7019 18.2928 28.5428L30.2512 16.5845Z"
        fill={color}
      />
    </svg>
  );
};

CheckboxSquircleIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

CheckboxSquircleIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default CheckboxSquircleIcon;
