import React from "react";

const PerformanceScoreIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4335_7792)">
        <rect width="35" height="35" rx="5" fill="#FFD4BC" />
        <path
          d="M29.6139 14.2702L18.0485 6.17291C17.8892 6.06057 17.6969 6 17.4994 6C17.3019 6 17.1096 6.06057 16.9503 6.17291L5.38489 14.2702C5.22518 14.382 5.10636 14.5396 5.04549 14.7205C4.98463 14.9013 4.98484 15.096 5.04611 15.2768L9.46492 28.3786C9.52592 28.5591 9.64464 28.7164 9.80411 28.828C9.96358 28.9396 10.1556 28.9998 10.3528 29H24.6489C25.0549 29 25.4112 28.7498 25.5368 28.3786L29.9556 15.2768C30.0783 14.9056 29.941 14.5008 29.6139 14.2702ZM27.038 14.8297L24.1612 15.7294L18.3201 11.6611V8.72581L27.038 14.8297ZM16.1559 19.0527L13.5537 22.4912L11.9416 17.7341L16.1559 19.0527ZM12.7593 16.3311L16.6816 13.6011V17.5597L12.7593 16.3311ZM17.4994 19.9524L20.2418 23.5765H14.757L17.4994 19.9524ZM18.3201 13.6011L22.2395 16.3311L18.3201 17.5569V13.6011ZM18.8429 19.0527L23.0572 17.7341L21.4451 22.4912L18.8429 19.0527ZM16.6816 8.72581V11.6611L10.8376 15.7294L7.96083 14.8297L16.6816 8.72581ZM7.49062 16.3423L10.0169 17.1324L12.365 24.0601L10.7938 26.1378L7.49062 16.3423ZM12.0993 27.0881L13.5683 25.1482H21.4334L22.9024 27.0881H12.0993ZM24.205 26.1378L22.6338 24.0601L24.9819 17.1324L27.5082 16.3423L24.205 26.1378Z"
          fill="#FD5E05"
        />
      </g>
      <defs>
        <clipPath id="clip0_4335_7792">
          <rect width="35" height="35" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PerformanceScoreIcon;
