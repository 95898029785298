import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const StatsBox = styled.div`
  display: flex;
  border-radius: 5px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
  align-items: flex-start;

  > .flex {
    display: flex;
    box-shadow: 0px 0px 3px 1px lightgrey;
    border-radius: 5px;
    padding: 32px 27px;
    height: ${(props) =>
      props.loading === "true" ? "100%" : props.big ? "50%" : "100%"};
    margin: 5px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    transition: max-width 0.5s;
    flex-direction: column;

    > p.large-title {
      align-self: flex-start;
      margin-bottom: 30px;
    }
  }

  .inner-flex {
    width: 100%;
    flex-direction: ${(props) => (props.big ? "" : "column")};
    display: inline-flex;
    margin-bottom: 10px;
    height: 100%;
  }

  .bottom-flex {
    width: 100%;
    display: flex;
    height: 50%;
    justify-content: space-between;
  }

  .stat-item:nth-child(1) {
    margin: ${(props) => (props.big ? "" : "0 0 35px 0")};
  }

  .stat-item:nth-child(3) {
    margin: ${(props) => (props.big ? "" : "35px 0 0 0")};
  }
`;

const StatRect = styled.div`
  box-shadow: 0px 0px 3px 1px lightgrey;
  border-radius: 5px;
  padding: 25px;
  display: flex;
  flex: 1;
  margin: 5px;
  flex-direction: column;
  > img {
    width: 55px;
    height: 55px;
    border-radius: 3px;
    border: 1px solid transparent;
  }
  > div {
    width: 100%;
    margin-left: 2px;
  }
  > div > p:nth-child(1) {
    line-height: 20px;
    margin: 15px 0;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }
  > div > p:nth-child(2) {
    font-size: 22px;
    font-weight: 600;
    line-height: 23px;
  }
`;

const StatItem = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin: ${(props) => (props.big ? "" : "10px 0")};
  > img {
    width: 55px;
    height: 55px;
    border-radius: 3px;
    border: 1px solid transparent;
  }
  > div {
    width: 100%;
    height: 55px;
    margin-left: 10px;
  }
  > div > p:nth-child(1) {
    line-height: 20px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  > div > p:nth-child(2) {
    font-size: 22px;
    font-weight: 600;
    line-height: 45px;
  }
`;

function EodStats(props) {
  const [items, setStats] = useState([]);
  const [loading, setLoading] = useState(false);

  function getData() {
    setLoading(true);
    var url = `/projects/${props.projectId}/eod_stats.json`;
    axios
      .get(url)
      .then((res) => {
        setStats(res.data ? res.data : []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function displayLoader() {
    return (
      <div className="display-loader">
        <div id="test-step-bugs-loader" />
      </div>
    );
  }

  function displayTopItems() {
    if (items.length >= 1) {
      var maxWidths = ["165px", "200px", "250px"];
      return items.map((item, index) => {
        if (index < 3) {
          return (
            <StatItem
              className="stat-item"
              style={{ maxWidth: maxWidths[index] }}
              key={`stat_${item[1].length}`}
            >
              <img alt="bug" src={item[0]} />
              <div>
                <p>{item[1]}</p>
                <p>{item[2]}</p>
              </div>
            </StatItem>
          );
        }
      });
    } else {
      return (
        <p style={{ padding: "10px", textAlign: "center" }}>No Recent Media</p>
      );
    }
  }

  function displayBottomItems() {
    if (items.length >= 1) {
      return (
        <div className="bottom-flex">
          <StatRect big={props.big} key="item3">
            <img alt="bug" src={items[3][0]} />
            <div>
              <p>{items[3][1]}</p>
              <p>{items[3][2]}</p>
            </div>
          </StatRect>
          <StatRect big={props.big} key="item4">
            <img alt="bug" src={items[4][0]} />
            <div>
              <p>{items[4][1]}</p>
              <p>{items[4][2]}</p>
            </div>
          </StatRect>
        </div>
      );
    }
  }

  useEffect(() => {
    getData();
  }, [props.projectId]);

  return (
    <StatsBox big={props.big} loading={loading.toString()}>
      <div className="flex" style={{ alignItems: "normal" }}>
        <p className="performance-large-title" style={{ marginBottom: "20px" }}>
          EOD Report Averages
        </p>
        <div className="inner-flex">
          {loading ? displayLoader() : displayTopItems()}
        </div>
      </div>
      {loading === false && props.big == true && displayBottomItems()}
    </StatsBox>
  );
}

export default EodStats;
