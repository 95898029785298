import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ErrorMessage as FormErrorMessage } from "@hookform/error-message";

// For use with the react-hook-form error interface, will display a toast with
// error message, and if a DOM ref is provided via forwardRef, will scroll the
// relevant errored input into view. Must be called as a descendant of a
// react-hook-form <FormProvider> component in order to consume error messages
// from form context, or provide error object directly via an `errors` prop.

const ErrorMessage = (props) => {
  const { name, forwardRef, ...rest } = props;

  return (
    <FormErrorMessage
      name={name}
      render={({ message }) => (
        <ErrorToast message={message} forwardRef={forwardRef} />
      )}
      {...rest}
    />
  );
};

// for use with react-hook-form error messages
const ErrorToast = ({ message, forwardRef }) => {
  useEffect(() => {
    if (message) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: message,
        classes: "red darken-1 error-toast",
      });

      if (forwardRef && forwardRef.current) {
        forwardRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [message, forwardRef]);

  return null;
};

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  forwardRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

ErrorToast.propTypes = {
  message: PropTypes.string,
  forwardRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default ErrorToast;

export { ErrorMessage, ErrorToast };
