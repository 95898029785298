import React, { useEffect } from "react";
import CancelIcon from "@icons/cancel_icon";
import styled from "styled-components";
import { func, string, bool, objectOf } from "prop-types";

const ModalHeader = ({
  className,
  modalAction,
  title,
  subTitle,
  showHeaderImg,
  containerStyle,
  imageStyle,
  imageUrl,
}) => {
  const handleCancelButton = () => modalAction(false, "", {});

  // useEffect(() => {
  //   const el = document.getElementById("react-modal-heading");
  //   if (el) {
  //     el.focus();
  //   }
  // }, []);

  return (
    <HeaderContainer
      containerStyle={containerStyle}
      className={`modal-header${className ? ` ${className}` : ""}`}
    >
      {showHeaderImg && (
        <ImageContainer aria-hidden="true">
          <Image
            alt={`${title} modal header`}
            src={imageUrl}
            imageStyle={imageStyle}
          />
        </ImageContainer>
      )}
      <TitleRow>
        <div>
          <Title id="react-modal-heading">{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </div>
        <ButtonContainer>
          <CloseButton
            aria-label="Close Modal"
            type="button"
            tabIndex="0"
            onClick={handleCancelButton}
          >
            <svg
              fill="#1C6BA9"
              height="12"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
            >
              <path d="M60.44,48.52c3.17,3.17,3.75,8.45.75,11.8a8.3,8.3,0,0,1-12.06.34L32,43.5l-.2.2-17,17A8.34,8.34,0,0,1,3,60.66,8.38,8.38,0,0,1,3,48.92L19.93,32a.1.1,0,0,0,0-.17L3.57,15.48C.37,12.31-.21,7.06,2.79,3.68a8.34,8.34,0,0,1,12.09-.34L32.05,20.5,49.21,3.34A8.37,8.37,0,0,1,61,3.34a8.32,8.32,0,0,1,0,11.74L44.05,32a.17.17,0,0,0,0,.17Z" />
            </svg>
          </CloseButton>
        </ButtonContainer>
      </TitleRow>
    </HeaderContainer>
  );
};

ModalHeader.propTypes = {
  className: string,
  modalAction: func,
  title: string,
  subTitle: string,
  showHeaderImg: bool,
  containerStyle: objectOf(string),
  imageStyle: objectOf(string),
  imageUrl: string,
};

ModalHeader.defaultProps = {
  showHeaderImg: true,
  imageUrl:
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/Tess_Head.svg",
  imageStyle: {},
  containerStyle: {},
};

const HeaderContainer = styled.div`
  height: 95px;
  width: 100%;
  background-image: url("https://tp-assets.sfo2.cdn.digitaloceanspaces.com/eod-report/EOD_Modal_Header.jpg");
  background-size: auto 100%;
  background-position: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  ${(props) => props.containerStyle}
`;

const ImageContainer = styled.div`
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background-color: white;
`;

const Image = styled.img`
  width: 36px;
  ${(props) => props.imageStyle}
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-family: Manrope;
  color: white;
  font-size: 16px;
  text-shadow: 0px 1px #b1b1b166;
  outline: none;
`;

const SubTitle = styled.p`
  font-family: Manrope;
  color: white;
  font-size: 13px;
  text-shadow: 0px 1px #b1b1b166;
`;

const ButtonContainer = styled.div`
  height: 28px;
  width: 28px;
`;

const CloseButton = styled.button`
  border-radius: 50%;
  border: none;
  padding: 5px;
  height: 28px;
  width: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: white;
  transform: scale(1);

  &:focus {
    background: white;
  }

  svg {
    transform: scale(1);
  }
`;

export default ModalHeader;
