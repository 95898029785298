import React from 'react'

class TextBoxIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31.18 31.18"
        className="icons"
      >
        <g id="Layer_3" data-name="Layer 3">
        <path d="M31.23,31.18V22.52H28V8.65h3.22V0H22.58V3.22H8.71V0H.05V8.65H3.29V22.52H.05v8.66H8.71V28H22.58v3.22ZM29,24.74V29H24.8V24.74ZM24.8,2.22H29V6.44H24.8ZM2.27,6.44V2.22H6.49V6.44ZM6.49,29H2.27V24.74H6.49Zm2.22-3.22V22.52H5.51V8.65h3.2V5.44H22.58V8.65H25.8V22.52H22.58v3.22Z"/>
        <path d="M20.91,13.22a6.25,6.25,0,0,0-.86-2c-.36-.42-.52-.58-1.71-.58H17.06v9.44c0,1.68.2,1.8,2,1.95v.74H12.38v-.74c1.71-.15,1.89-.27,1.89-1.95V10.67H13c-1.12,0-1.44.18-1.83.66a7.71,7.71,0,0,0-.82,1.91H9.63c.14-1.47.28-3,.32-4h.6c.3.47.54.51,1.15.51h8a1.31,1.31,0,0,0,1.16-.51h.59c0,.85.1,2.61.2,3.92Z"/>
        </g>
      </svg>
    );
  }
}
TextBoxIcon.defaultProps = {
  color: "white",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default TextBoxIcon