import React from "react";

const TestCasesBase = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <rect
        x={261.31}
        y={123.55}
        width={557.39}
        height={832.9}
        rx={254.7}
        fill="#e8e8e8"
        data-name="Layer 2"
      />{" "}
    </svg>
  );
};

export default TestCasesBase;
