export const backgroundColorPlugin = {
  id: "customCanvasBackgroundColor",
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    const { chartArea } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = options.color || "#F0F0F0";
    ctx.fillRect(
      chartArea.left,
      chartArea.top,
      chartArea.right - chartArea.left,
      chartArea.bottom - chartArea.top
    );
    ctx.restore();
  },
};

export const radarBackgroundPlugin = {
  id: "radarBackgroundColor",
  beforeDraw: (chart) => {
    const { ctx, config, scales } = chart;
    const { r } = scales;
    const { xCenter, yCenter, drawingArea: radius } = r;

    ctx.save();
    ctx.arc(xCenter, yCenter, radius, 0, Math.PI * 2);
    ctx.fillStyle = config.options.chartArea.backgroundColor || "#F0F0F0";
    ctx.fill();
    ctx.restore();
  },
};

export const radarTextAlignPlugin = {
  id: "radarTextAlign",
  afterLayout: (chart) => {
    /* eslint-disable */
    chart.getDatasetMeta(0).rScale._pointLabelItems.forEach(function (o) {
      o.textAlign = "center";
    });
    /* eslint-enable */
  },
};

export const radarPlugins = [radarBackgroundPlugin, radarTextAlignPlugin];

export function labelFormatter(value) {
  // For use in chart.options.scales.xAxes[0].ticks.callback
  // For formatting x-axis labels; if the value is a full name, abbreviate last name, otherwise return label
  // e.g. "John Smith" => "John S.", "2023/08/17" => "2023/08/17"
  // very crude! not foolproof! literally only works for names with 2 parts!
  const label = this.getLabelForValue(value);
  const splitLabel = label.split(" ");
  if (splitLabel.length === 2 && /\d/.test(splitLabel[1]) === false) {
    return `${splitLabel[0]} ${splitLabel[1].charAt(0)}.`;
  }
  return label;
}

export const xAxisClickEvent = {
  id: "xAxisClickEvent",
  afterEvent: (chart, e) => {
    const evt = e.event;
    if (evt.type !== "click") return null;

    const xAxis = chart.scales.x;
    if (!xAxis) return null;

    if (evt.y > chart.chartArea.bottom && evt.y < chart.legend.top) {
      // unfortunately chartjs won't let me pass a cb function through chart.options?!!?
      // so this doesn't work.
      // const value = xAxis.getValueForPixel(evt.x);
      // const label = xAxis.getLabelForValue(value);
      // if (
      //   chart.options.xAxisClickOptions?.xAxisClickCb &&
      //   typeof chart.options.xAxisClickOptions.xAxisClickCb === "function"
      // ) {
      //   chart.options.xAxisClickOptions.xAxisClickCb(value, label);
      // }
    }

    return null;
  },
};

export default [backgroundColorPlugin];
