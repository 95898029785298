import React from "react";
import PropTypes from "prop-types";

const AssessmentFailed = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 73 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.375 2H6.29167C3.92144 2 2 3.92144 2 6.29167V83.5417C2 85.912 3.92144 87.8333 6.29167 87.8333H66.375C68.7453 87.8333 70.6667 85.912 70.6667 83.5417V6.29167C70.6667 3.92144 68.7453 2 66.375 2Z"
        fill="#519ACC"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21.3125 57.792H51.3542H21.3125Z" fill="#519ACC" />
      <path
        d="M21.3125 57.792H51.3542"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21.3125 70.667H36.3333H21.3125Z" fill="#519ACC" />
      <path
        d="M21.3125 70.667H36.3333"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27.75 38.4792L44.9167 21.3125L27.75 38.4792Z" fill="#519ACC" />
      <path
        d="M27.75 38.4792L44.9167 21.3125"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.9167 38.4792L27.75 21.3125L44.9167 38.4792Z"
        fill="#519ACC"
      />
      <path
        d="M44.9167 38.4792L27.75 21.3125"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

AssessmentFailed.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

AssessmentFailed.defaultProps = {
  height: "90",
  width: "73",
};

export default AssessmentFailed;
