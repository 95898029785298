import React from "react";

const BugsFilled = (props) => {
  return (
    <svg
      id="Bugs-new_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Bugs-new_svg__cls-1,.Bugs-new_svg__cls-2{fill:none;stroke:#519acc;stroke-miterlimit:10;stroke-width:3px}.Bugs-new_svg__cls-2{stroke-linecap:round}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <path
        className="Bugs-new_svg__cls-1"
        d="M19.06 22.21c0-7.14 5.8-17.28 12.94-17.28s12.94 10.14 12.94 17.28"
      />{" "}
      <path
        className="Bugs-new_svg__cls-2"
        d="M22.95 10.76l-5.08-8.18M41.05 10.76l5.5-8.18"
      />{" "}
      <circle className="Bugs-new_svg__cls-1" cx={32} cy={40.06} r={21.9} />{" "}
    </svg>
  );
};

export default BugsFilled;
