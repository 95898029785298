import React from "react";
import PropTypes from "prop-types";
import BugDraftIcon from "../icons/bug_draft_icon";

class Ticket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      a11yDetails: false,
    };
    this.ticketClick = this.ticketClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.a11yTicketFocus = this.a11yTicketFocus.bind(this);
    this.draftClick = this.draftClick.bind(this);
  }

  handleKeyDown(e) {
    var code = e.which;
    if (code === 13) {
      this.ticketClick();
    } else if (code === 68) {
      var elem = e.target;
      this.setState({ a11yDetails: true }, () => {
        setTimeout(() => {
          elem.firstChild.focus();
        }, 100);
      });
    }
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return <p aria-label="Open">Open</p>;
      case 2:
        return <p aria-label="Closed">Closed</p>;
      case 3:
        return <p aria-label="Fixed">Fixed</p>;
      case 4:
        return <p aria-label="In Progress">In Progress</p>;
      case 5:
        return <p aria-label="Won't Fix">Won&apos;t Fix</p>;
      case 6:
        return <p aria-label="Reopened">Reopened</p>;
      default:
        "";
    }
  }

  getStatusAria(status) {
    switch (status) {
      case 1:
        return "Open";
      case 2:
        return "Closed";
      case 3:
        return "Fixed";
      case 4:
        return "In Progress";
      case 5:
        return "Won't Fix";
      case 6:
        return "Reopened";
      default:
        "";
    }
  }

  priorityIcon(priority) {
    switch (priority) {
      case 3:
        return (
          <div className="priorityIcon">
            <div style={{ backgroundColor: "#0066cc" }} />
          </div>
        );
      case 2:
        return (
          <div className="priorityIcon">
            <div style={{ backgroundColor: "#ff9900" }} />
          </div>
        );
      case 1:
        return (
          <div className="priorityIcon">
            <div style={{ backgroundColor: "#ff0000" }} />
          </div>
        );
      case 4:
        return (
          <div className="priorityIcon">
            <div style={{ backgroundColor: "#009900" }} />
          </div>
        );
      default:
        "";
    }
  }

  ticketClick() {
    this.props.handler(this.props.ticket.id);
  }

  draftClick() {
    this.props.handleDraft({ id: this.props.ticket.id });
  }

  a11yTicketFocus(e) {
    if (!e.target.classList.contains("ReactVirtualized__Table__rowColumn")) {
      this.setState({ a11yDetails: false });
    }
  }

  render() {
    let divClass = "ReactVirtualized__Table__row";
    if (this.props.currentTicketId === this.props.ticket.id) {
      divClass += " selected-ticket";
    }
    if (this.props.index % 2 != 0) {
      divClass += " odd";
    }
    var priorityId = this.props.ticket.ticket_priority_id;
    var draft = this.props.ticket.draft;
    if (draft) {
      return (
        <div
          className={divClass}
          id={this.props.id}
          tabIndex="0"
          onKeyDown={this.handleKeyDown}
          onClick={this.draftClick}
          onFocus={this.a11yTicketFocus}
          style={this.props.style}
          role={this.state.a11yDetails ? "list" : "button"}
          aria-expanded={
            this.props.dataId === this.props.currentTicketId ? "true" : "false"
          }
          aria-label={
            this.state.a11yDetails
              ? "1 of "
              : `${this.props.ticket.project.identifier}-${this.props.ticket.app_key}, ${this.props.ticket.title}. Press enter to edit draft or D for more details`
          }
        >
          <div
            tabIndex={-1}
            style={{
              overflow: "visible",
              flex: "0 1 80px",
              fontWeight: "600",
              fontStyle: "italic",
              color: "#519acc",
            }}
            role="text"
            aria-label={`ID: ${this.props.ticket.project.identifier}-${this.props.ticket.app_key}`}
            className="ReactVirtualized__Table__rowColumn"
          >
            {`${this.props.ticket.project.identifier}-${this.props.ticket.app_key}`}
            <BugDraftIcon
              svgStyles={{ verticalAlign: "middle", marginLeft: "4px" }}
            />
          </div>
          <div
            role="text"
            aria-hidden="true"
            className="ReactVirtualized__Table__rowColumn"
            style={{
              overflow: "hidden",
              flex: "0 1 60px",
              verticalAlign: "initial",
            }}
          ></div>
          <div
            role="text"
            style={{
              overflow: "hidden",
              flex: "1 1 400px",
              fontWeight: "600",
              fontStyle: "italic",
              color: "#519acc",
            }}
            className="ReactVirtualized__Table__rowColumn"
            aria-label={`Title: ${
              this.props.ticket.title == null ? "" : this.props.ticket.title
            }`}
          >
            {`[DRAFT] ${
              this.props.ticket.title == null ? "" : this.props.ticket.title
            }`}
          </div>
          <div
            style={{ overflow: "hidden", flex: "0 1 80px" }}
            className="ReactVirtualized__Table__rowColumn"
            aria-hidden="true"
          ></div>
          <div
            style={{ overflow: "hidden", flex: "0 1 100px" }}
            className="ReactVirtualized__Table__rowColumn"
            aria-hidden="true"
          ></div>
          <div
            role="text"
            style={{
              overflow: "hidden",
              flex: "0 1 100px",
              fontWeight: "600",
              fontStyle: "italic",
              color: "#519acc",
            }}
            className="ReactVirtualized__Table__rowColumn"
            aria-label={`Reporter: ${this.props.ticket.tester_name}`}
          >
            {this.props.ticket.tester_name}
          </div>
          {this.props.a11y_active ? (
            <div
              style={{ overflow: "hidden", flex: "0 1 100px" }}
              className="ReactVirtualized__Table__rowColumn"
              aria-hidden="true"
            ></div>
          ) : (
            <div
              style={{ overflow: "hidden", flex: "0 1 100px" }}
              className="ReactVirtualized__Table__rowColumn"
              aria-hidden="true"
            ></div>
          )}
          <div
            role="text"
            style={{
              overflow: "hidden",
              flex: "0 1 100px",
              fontWeight: "600",
              fontStyle: "italic",
              color: "#519acc",
            }}
            className="ReactVirtualized__Table__rowColumn"
            aria-label={`Created: ${this.props.ticket.created_at}`}
          >
            {this.props.ticket.created_at}
          </div>
        </div>
      );
    }

    return (
      <div
        className={divClass}
        id={this.props.id}
        tabIndex="0"
        onKeyDown={this.handleKeyDown}
        onClick={this.ticketClick}
        onFocus={this.a11yTicketFocus}
        style={this.props.style}
        role={this.state.a11yDetails ? "list" : "button"}
        aria-expanded={
          this.props.dataId === this.props.currentTicketId ? "true" : "false"
        }
        aria-label={
          this.state.a11yDetails
            ? "1 of "
            : `${this.props.ticket.project.identifier}-${this.props.ticket.app_key}, ${this.props.ticket.title}. Press enter to open ticket or D for more details`
        }
      >
        <div
          tabIndex={-1}
          style={{ overflow: "hidden", flex: "0 1 80px" }}
          role="text"
          aria-label={`ID: ${this.props.ticket.project.identifier}-${this.props.ticket.app_key}`}
          className="ReactVirtualized__Table__rowColumn"
        >{`${this.props.ticket.project.identifier}-${this.props.ticket.app_key}`}</div>
        <div
          role="text"
          aria-label={`Priority: ${
            priorityId === 4
              ? "Low"
              : priorityId === 3
              ? "Normal"
              : priorityId === 2
              ? "High"
              : "Critical"
          }`}
          className="ReactVirtualized__Table__rowColumn"
          style={{
            overflow: "hidden",
            flex: "0 1 60px",
            verticalAlign: "initial",
          }}
        >
          {this.priorityIcon(priorityId)}
        </div>
        <div
          role="text"
          style={{ overflow: "hidden", flex: "1 1 400px" }}
          className="ReactVirtualized__Table__rowColumn"
          aria-label={`Title: ${
            this.props.ticket.title == null ? "" : this.props.ticket.title
          }`}
        >
          {this.props.ticket.title == null ? "" : this.props.ticket.title}
        </div>
        <div
          role="text"
          style={{ overflow: "hidden", flex: "0 1 80px" }}
          className="ReactVirtualized__Table__rowColumn"
          aria-label={`Status: ${this.getStatusAria(
            this.props.ticket.ticket_status_id
          )}`}
        >
          {this.getStatus(this.props.ticket.ticket_status_id)}
        </div>
        <div
          role="text"
          style={{ overflow: "hidden", flex: "0 1 100px" }}
          className="ReactVirtualized__Table__rowColumn"
          aria-label={`Assignee: ${this.props.ticket.assignee_name}`}
        >
          {this.props.ticket.assignee_name}
        </div>
        <div
          role="text"
          style={{ overflow: "hidden", flex: "0 1 100px" }}
          className="ReactVirtualized__Table__rowColumn"
          aria-label={`Reporter: ${this.props.ticket.tester_name}`}
        >
          {this.props.ticket.tester_name}
        </div>
        {this.props.a11y_active ? (
          <div
            role="text"
            style={{ overflow: "hidden", flex: "0 1 100px" }}
            className="ReactVirtualized__Table__rowColumn"
            aria-label={`WCAG Violation: ${
              this.props.ticket.a11y.violation.split(" ")[0]
            }`}
          >
            {this.props.ticket.a11y.violation.split(" ")[0]}
          </div>
        ) : (
          <div
            style={{ overflow: "hidden", flex: "0 1 100px" }}
            className="ReactVirtualized__Table__rowColumn"
          >
            {this.props.ticket.ticket_labels
              .map((label) => label.name)
              .sort()
              .join(", ")}
          </div>
        )}
        <div
          role="text"
          style={{ overflow: "hidden", flex: "0 1 100px" }}
          className="ReactVirtualized__Table__rowColumn"
          aria-label={`Created: ${this.props.ticket.created_at}`}
        >
          {this.props.ticket.created_at}
        </div>
      </div>
    );
  }
}

export default Ticket;
