import React from 'react'
import moment from 'moment'

class BuildHistory extends React.Component{

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    if (this.props.width > 600) {
    return (
      <>
      <tr>
        <td>{this.props.buildHistory.build_name}</td>
        <td>{ moment(this.props.buildHistory.created_at).format("MM/DD/YY") }</td>
        <td><a href="/dashboard">{ this.props.buildHistory.downloads }</a></td>
        <td>{ moment(this.props.buildHistory.available_until).format("MM/DD/YY") }</td>
      </tr>
      </>
    );
  } else {
    return (
       <div className="col s12 m6 card">
          <div className="card-content">
          <ul>
            <li className="truncate"><span>Build Name: </span><span>{this.props.buildHistory.build_name}</span></li>
            <li><span>Created At: </span><span>{ moment(this.props.buildHistory.created_at).format("MM/DD/YY") }</span></li>
            <li><a href="/dashboard"><span>Downloads: </span><span>{ this.props.buildHistory.downloads }</span></a></li>
            <li><span>Downloads: </span><span>{ moment(this.props.buildHistory.available_until).format("MM/DD/YY")}</span></li>
          </ul>
          </div>
        </div>
      )
    }
  }
}

export default BuildHistory
