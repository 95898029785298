import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import moment from "moment";
import { wikiFeaturedTopicTypes } from "../../types/wiki";
import wikiCategoryValues from "./wikiCategoryValues";

const FeaturedTopicCard = ({ featuredTopic, handleClick }) => {
  const { id, created_at, title, resource, featured_text } = featuredTopic;
  return (
    <Card
      key={`${id}-featured`}
      $backgroundImage={
        Object.prototype.hasOwnProperty.call(
          wikiCategoryValues,
          resource.category
        )
          ? wikiCategoryValues[resource.category].image
          : wikiCategoryValues.misc.image
      }
      className={`${
        moment().subtract(30, "days").isAfter(moment(created_at))
          ? ""
          : "new-feature"
      }`}
    >
      <h3 style={{ whiteSpace: "normal" }}>{title}</h3>
      <FeaturedText>{featured_text}</FeaturedText>
      <ViewTopicButton
        type="button"
        onClick={() =>
          handleClick({
            resourceId: resource.id,
            topicId: id,
          })
        }
        data-resource-id={resource.id}
        data-topic-id={id}
      >
        View Topic
      </ViewTopicButton>
    </Card>
  );
};

const clamp = css`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Card = styled.div`
  background-image: url(${({ $backgroundImage }) => $backgroundImage});
  width: 258px;
  height: 250px;
  mix-blend-mode: normal;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 20;
    margin-bottom: 12px;
    color: #ffffff;
    font-weight: 800;
    line-height: 27px;
    ${clamp}
    -webkit-line-clamp: 2;
  }
`;

const FeaturedText = styled.p`
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  ${clamp}
`;

const ViewTopicButton = styled.button`
  font-size: 14px;
  font-weight: 800;
  font-family: "Manrope";
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 40px;
  width: 138px;
  background: #ffffff !important;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
`;

FeaturedTopicCard.propTypes = {
  featuredTopic: wikiFeaturedTopicTypes.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default FeaturedTopicCard;
