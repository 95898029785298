import React from 'react'
import PropTypes from "prop-types";

class ProgressRing extends React.PureComponent {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - (stroke/Math.PI) * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
    this.state = {
      strokeDashoffset: this.circumference,
    }
  }

  componentDidMount() {
    const strokeDashoffset = this.circumference - this.props.progress / 100 * this.circumference;
    this.setState({strokeDashoffset: strokeDashoffset, text: this.props.text})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.progress != this.props.progress) {
      const strokeDashoffset = this.circumference - this.props.progress / 100 * this.circumference;
      this.setState({strokeDashoffset: strokeDashoffset})
    }
  }

  render() {
    const { radius, stroke } = this.props;
    return (
      <svg
        style={{
          position: `${this.props.relative ? "relative" : "absolute"}`,
          left: `${this.props.left ? this.props.left : ""}`,
          top: `${this.props.top ? this.props.top : ""}`,
          verticalAlign: this.props.verticalAlign
            ? this.props.verticalAlign
            : "",
          ...this.props.containerStyle
        }}
        height={radius * 2}
        width={radius * 2}
        className="icons"
      >
        {this.props.phantomRing && (
          <circle
            stroke={this.props.backgroundCircleColor?this.props.backgroundCircleColor:"#e6e6e6"}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + " " + this.circumference}
            style={{
              strokeDashoffset: 0,
              position: `${this.props.relative ? "relative" : "absolute"}`,
            }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        )}
        <circle
          stroke={this.props.color ? this.props.color : "#519acc"}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + " " + this.circumference}
          style={{
            strokeDashoffset: this.state.strokeDashoffset,
            position: `${this.props.relative ? "relative" : "absolute"}`,
          }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        {this.props.text && this.props.text != "" && (
          <text
            x="50%"
            y="50%"
            alignmentBaseline="central"
            textAnchor="middle"
            // dominant baseline is a firefox center fix
            dominantBaseline="middle"
            style={this.props.textStyles}
          >
            {this.props.text != this.state.text
              ? this.props.text
              : this.state.text}
          </text>
        )}
      </svg>
    );
  }
}

ProgressRing.defaultProps = {
  containerStyle: {}
}

export default ProgressRing

ProgressRing.propTypes = {
  radius: PropTypes.number,
  text: PropTypes.string,
  stroke: PropTypes.number,
  progress: PropTypes.any,
  color: PropTypes.string,
  relative: PropTypes.bool,
  phantomRing: PropTypes.bool,
  textStyles: PropTypes.object,
  containerStyle: PropTypes.object,
  top: PropTypes.string,
  left: PropTypes.string
};
