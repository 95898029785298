import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const PillboxToggles = (props) => {
  const { options, onSelect, defaultSelected, controlledSelected } = props;

  const [selected, setSelected] = useState(defaultSelected || options[0]?.key);

  const onToggleChange = useCallback(
    (newKey) => {
      setSelected(newKey);
      if (onSelect) {
        onSelect(newKey);
      }
    },
    [onSelect]
  );

  useEffect(() => {
    const isControlled = controlledSelected !== undefined;
    const isOptionExists = !!options.find(
      ({ key }) => key === controlledSelected
    );
    if (isControlled && isOptionExists) {
      setSelected(controlledSelected);
    }
  }, [controlledSelected, options]);

  if (!options || options.length < 2) {
    console.error("Must provide at least 2 options to PillboxToggles");
    return null;
  }

  return (
    <TogglesContainer>
      {options.map(({ key, icon, label }) => (
        <ToggleButton
          key={key}
          onClick={() => onToggleChange(key)}
          $isActive={selected === key}
        >
          {icon && (
            <ButtonIcon $isActive={selected === key}>{icon || null}</ButtonIcon>
          )}
          <ButtonLabel $isActive={selected === key}>
            {label || null}
          </ButtonLabel>
        </ToggleButton>
      ))}
    </TogglesContainer>
  );
};

const TogglesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleButton = styled.button`
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  border: 1px solid #d7d7d7;
  margin: 0;
  background-color: ${({ $isActive }) => ($isActive ? "#519ACC" : "#ffffff")};
  transition-property: background-color color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:not(:first-child) {
    border-left: none;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:focus,
  &:active {
    background-color: ${({ $isActive }) => ($isActive ? "#519ACC" : "#ffffff")};
  }
`;

const ButtonIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const ButtonLabel = styled.div`
  color: ${({ $isActive }) => ($isActive ? "#ffffff" : "#000000")};
  font-size: 13px;
  font-family: "Arial";
  font-weight: 400;
  line-height: 15px;
`;

PillboxToggles.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      icon: PropTypes.node,
    })
  ).isRequired,
  onSelect: PropTypes.func,
  defaultSelected: PropTypes.string,
  controlledSelected: PropTypes.string,
};

export default PillboxToggles;
