import { string, number, bool, shape, oneOfType } from "prop-types";

export default shape({
  a11y_active: bool,
  active: bool,
  automation: bool,
  bug_count: number,
  build_count: number,
  cases_completed: number,
  color: string,
  created_at: string,
  has_bugs: bool,
  has_test_cases: bool,
  id: number,
  identifier: string,
  jira_project_id: number,
  jira_url: string,
  media: bool,
  name: string,
  owner_id: number,
  settings: shape({
    bugs: oneOfType([string, bool]),
    eod_report: oneOfType([string, bool]),
    media: oneOfType([string, bool]),
    priority: oneOfType([string, bool]),
    status: oneOfType([string, bool]),
    test_pass: oneOfType([string, bool]),
    ticket_add: oneOfType([string, bool]),
    ticket_comment: oneOfType([string, bool]),
    user_add: oneOfType([string, bool]),
  }),
  trello_board: string,
  trello_list: string,
  trello_token: string,
  updated_at: string,
});
