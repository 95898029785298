import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import Select, { components } from "react-select";
import { dropdownIndicator } from "../common/select_utils";
import SingleSelectStyles from "../common/select_styles";
import axios from "axios";
import Swal from "sweetalert2";

function RemoteDeviceLabContainer(props) {
  const [device, setDevice] = useState(null);
  const [loadingDevice, setLoadingDevice] = useState(false);
  const [disableCheckout, setDisableCheckout] = useState(false);
  const [currentDeviceAndroid, setCurrentDeviceAndroid] = useState(false);
  const [devices, setDevices] = useState(
    props.remote_devices
      .map((d) => ({ ...d, label: d.device_label, value: d.id }))
      .sort((a, b) =>
        a.label.toLowerCase() < b.label.toLowerCase()
          ? -1
          : a.label.toLowerCase() > b.label.toLowerCase()
          ? 1
          : 0
      )
  );
  function deviceSelect(e) {
    setDevice(e);
  }
  function launchDevice(e) {
    setLoadingDevice(true);
    // axios.defaults.headers.common = {
    //   'X-Requested-With': 'XMLHttpRequest',
    //   'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
    var environment = location.href.includes("stagingtestplatform.plusqa.com")
      ? "staging"
      : location.href.includes("testplatform.plusqa.com")
      ? "production"
      : "development";
    if (device.user_id == null) {
      axios
        .patch(`/remote_devices/${device.value}.json`, {
          remote_device: {
            user_id: props.currentUser.id,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
        })
        .then((res) => {
          if (device.device_platform.id === 3) {
            axios
              .get(
                `https://launch-device.plusqa.com:9000/hooks/launch-device?token=${process.env.REMOTE_WEBHOOK_TOKEN}&udid=${device.udid}&device=${device.id}&environ=${environment}`,
                {
                  transformRequest: [
                    function (data, headers) {
                      delete headers.common["X-CSRF-TOKEN"];
                      delete headers.common["X-Requested-With"];
                      return data;
                    },
                  ],
                }
              )
              .then((res) => {
                setTimeout(() => {
                  window.location.href = `${
                    device.device_url
                  }?device=${encodeURIComponent(device.label)}&serial=${
                    device.serial
                  }&info=none&env=${environment}&remote_device_limit=${
                    props.remote_device_limit
                  }&device_size=${device.device_size}&sim=${encodeURIComponent(
                    device.sim
                  )}`;
                }, 10000);
              })
              .catch((err) => {
                M.toast({
                  html: "Error connecting to Remote Device. Make sure you are connected to Office VPN.",
                  classes: "red",
                });
                axios
                  .get(
                    `/check_in_remote_device/${device.id}.json?token=${process.env.REMOTE_WEBHOOK_TOKEN}`
                  )
                  .then((res) => {})
                  .catch((err) => {});
                setLoadingDevice(false);
              });
          } else {
            setTimeout(() => {
              window.location.href = `${device.device_url}?user_name=${
                props.currentUser.name
              }&user_email=${
                props.currentUser.email
              }&env=${environment}&device_id=${
                device.id
              }&device_label=${encodeURIComponent(
                device.label
              )}&device_serial=${device.serial}&remote_device_limit=${
                props.remote_device_limit
              }&device_size=${device.device_size}&sim=${encodeURIComponent(
                device.sim
              )}`;
            }, 1000);
          }
        })
        .catch((err) => {
          M.toast({ html: "Error Updating Remote Device", classes: "red" });
          setLoadingDevice(false);
        });
    } else if (device.user_id === props.currentUser.id) {
      if (device.device_platform.id === 3) {
      } else {
        setTimeout(() => {
          window.location.href = `${device.device_url}?user_name=${
            props.currentUser.name
          }&user_email=${
            props.currentUser.email
          }&env=${environment}&device_id=${
            device.id
          }&device_label=${encodeURIComponent(device.label)}&device_serial=${
            device.serial
          }`;
        }, 1000);
      }
    }
  }

  useEffect(() => {
    var url = new URL(location.href);
    var timeExpired = url.searchParams.get("time_expired");
    var endSession = url.searchParams.get("end_session");
    if (timeExpired || endSession) {
      url.searchParams.delete("time_expired");
      url.searchParams.delete("end_session");
      window.history.replaceState({}, "", url.href);
      setDevices(
        devices.map((d) => ({
          ...d,
          user_id: d.user_id === props.currentUser.id ? null : d.user_id,
        }))
      );
      if (timeExpired) {
        Swal.fire({
          title: "Time Expired",
          text: "Your time to use that remote device has expired",
          customClass: "Swal2-error-modal",
        });
      }
    } else if (
      devices
        .filter((d) => d.user_id)
        .map((d) => d.user_id)
        .indexOf(props.currentUser.id) !== -1
    ) {
      var androidDevice = devices.some(
        (d) => d.user_id === props.currentUser.id && d.device_platform.id !== 3
      );
      setDisableCheckout(true);
      setCurrentDeviceAndroid(androidDevice);
      Swal.fire({
        title: "Device Already In Use",
        text: `You already have a Remote Device checked out. Close your current active Remote Device session to check out a new device. ${
          androidDevice
            ? "Select your device from the dropdown to resume your current session."
            : ""
        }`,
        customClass: "Swal2-error-modal",
      });
    }
  }, []);

  var option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {props.data.label}
            {props.data.user_id && (
              <img
                src={props.data.avatar_image}
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
            )}
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
      <div
        style={{
          height: "100px",
          width: "100%",
          paddingLeft: "30px",
          boxShadow: "0px 1px 3px 2px lightgray",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <a
          style={{
            fontSize: "14px",
            width: "155px",
            lineHeight: "45px",
            height: "45px",
          }}
          href="/device_lab/"
          className="common-button-submit"
        >
          Back to Test Platform
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <img
          style={{ margin: "40px 0 20px", height: "140px" }}
          src={
            "https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/scientist_tess.png"
          }
        />
        <h1
          style={{
            fontFamily: "Manrope",
            fontWeight: "700",
            fontSize: "23px",
            textAlign: "center",
            margin: "0 0 25px 0",
          }}
        >
          Welcome to the Remote Device Lab
        </h1>
        <p
          style={{
            fontFamily: "Manrope",
            maxWidth: "700px",
            fontWeight: "600",
            marginBottom: "30px",
          }}
        >
          Establish a VPN connection and select an available device to get
          started. Please note that you may experience some latency while using
          a remote device. If a device is unavailable for testing but is not
          in-use, please contact an Administrator.
          <br />
          Remote devices are available for a maximum use of 1 hour.
        </p>
        <Select
          isDisabled={loadingDevice}
          name="Device"
          options={[
            {
              label: "Available",
              options: devices
                .filter((d) => !d.user_id)
                .map((d) => ({ ...d, isDisabled: disableCheckout })),
            },
            {
              label: "Currently In Use",
              options: devices
                .filter((d) => d.user_id)
                .map((d) => ({
                  ...d,
                  isDisabled:
                    d.user_id !== props.currentUser.id || !currentDeviceAndroid,
                })),
            },
          ]}
          onChange={deviceSelect}
          styles={{
            ...SingleSelectStyles,
            container: (styles) => ({
              ...styles,
              width: "350px",
            }),
            control: (styles, state) => ({
              ...SingleSelectStyles.control(styles, state),
              height: "50px",
            }),
            singleValue: (styles, state) => ({
              ...SingleSelectStyles.singleValue(styles, state),
              paddingLeft: "15px",
            }),
            indicatorsContainer: (styles, state) => ({
              ...SingleSelectStyles.indicatorsContainer(styles, state),
              height: "50px",
              width: "40px",
            }),
            menu: (styles) => ({
              ...styles,
              width: "350px",
            }),
            placeholder: (styles) => ({
              ...styles,
              color: "inherit",
              top: "55%",
              paddingLeft: "15px",
            }),
            valueContainer: (styles) => ({
              ...SingleSelectStyles.valueContainer(styles),
              maxHeight: "50px",
            }),
            groupHeading: (styles) => ({
              ...styles,
              textAlign: "left",
              paddingLeft: "25px",
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
              ...SingleSelectStyles.option(styles, {
                data,
                isDisabled,
                isFocused,
                isSelected,
              }),
              paddingLeft: "25px",
              textAlign: "left",
              color: isDisabled
                ? "#242424"
                : isFocused
                ? isSelected
                  ? "#519acc"
                  : "white"
                : isSelected
                ? "#519acc"
                : "#242424",
            }),
          }}
          // defaultValue={{value: true, label: "Active"}}
          value={device}
          placeholder="Select a device from the list"
          components={{ DropdownIndicator: dropdownIndicator, Option: option }}
          classNamePrefix={"select"}
          blurInputOnSelect={true}
          isSearchable={false}
          className="short"
        />
        <button
          disabled={!device || loadingDevice}
          onClick={launchDevice}
          style={{ width: "185px", marginTop: "40px", height: "50px" }}
          className="common-button-submit"
        >
          {device && device.user_id === props.currentUser.id
            ? "Resume Device Session"
            : "Test With This Device"}
        </button>
        <div
          aria-hidden={true}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CSSTransition
            unmountOnExit
            in={loadingDevice}
            timeout={500}
            classNames="node-fade"
          >
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "75px",
                  top: "-58px",
                  height: "30px",
                  width: "30px",
                }}
                id="first-build-spinner"
              />
              <p style={{ margin: "5px 0 0", color: "#9b9b9b" }}>
                Loading device connection. You will be redirected in a few
                moments.
              </p>
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
}

export default RemoteDeviceLabContainer;
