import React from 'react'
import {components} from 'react-select'
import axios from 'axios'
import MultiSelectWrapper from '../common/multi_select_wrapper';

class AddToProject extends React.Component {

  constructor(props) {
    super(props);
    var arr1 = this.props.data.selectedUser.projects.map((p)=>p.id)
    this.state = {
      selectedProjects:[],
      projectOptions:this.props.data.projectOptions.filter((p)=>arr1.indexOf(p.id)===-1).map((p)=>({label:p.name, value:p.id}))
    }
    this.heightMath = this.heightMath.bind(this)
  }

  handleCancelButton = () => {
    this.props.modalAction(false, "", {})
  }

  handleProjectChange = (e) => {
    this.setState({
      selectedProjects: e ? e : []
    })
  }

  handleSubmit = () => {
    axios.post(`/add_multiple_projects/${this.props.data.selectedUser.id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, 
        project: {project_ids:this.state.selectedProjects.map((p)=>p.value)}
    }).then((res)=>{
      M.toast({html: `User added to projects`, displayLength: 3000, classes: 'green' })
      this.props.data.updateUserProjects(res.data)
      this.props.modalAction(false, "", {})
    }).catch((err)=>{
      console.error(err)
      M.toast({html: `Error adding user to projects`, displayLength: 3000, classes: 'red' })
      this.props.modalAction(false, "", {})
    })
  }

  heightMath() {
    return (`${(this.state.selectedProjects.length/2) * 50 + 100 >= 250 ? "250" : (this.state.selectedProjects.length/2) * 50 + 100 < 175 ? 175 : (this.state.selectedProjects.length/2) * 50 + 100}px`)
  }

  render() {
    return (
    <div id="addToProject" style={{position:"relative"}}>
      <MultiSelectWrapper
        aria-label="Project Selector"
        heightMath={this.heightMath()}
        value={this.state.selectedProjects}
        label={<p className="small-title">Add user to one or more projects</p>}
        onChange={this.handleProjectChange}
        name="projects"
        tagStyle={true}
        isClearable={false}
        defaultValue="Select a Project"
        isSearchable={true}
        // onSelectKeyDown={this.onSelectDevicesKeyDown}
        closeMenuOnSelect={false}
        options={this.state.projectOptions}
        placeholder={"Select a Project"}
        width="500px"
        height="200px"
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <button disabled={this.state.selectedProjects.length < 1} onClick={this.handleSubmit} style={{marginLeft: "auto"}} className="common-button-submit">Submit</button>
      <button style={{position:"absolute", top:"-30px", right:"0px"}} onClick={this.handleCancelButton} aria-label="close-modal" className="icon-close-btn" ></button>
    </div>
    )
  }
}
export default AddToProject