import React from "react";

const BulletpointsIcon = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 43"
    >
      <path
        d="M0.9 5.9779C0.9 8.74259 3.13814 10.99 5.90206 11.0431V11.0558H6.00206C8.8121 11.0558 11.1041 8.77555 11.1041 5.9779C11.1041 3.18025 8.8121 0.9 6.00206 0.9C3.19202 0.9 0.9 3.18025 0.9 5.9779ZM3.46504 5.9779C3.46504 4.58611 4.60233 3.45362 6.00206 3.45362C7.40178 3.45362 8.53907 4.58611 8.53907 5.9779C8.53907 7.36969 7.40178 8.50218 6.00206 8.50218C4.60233 8.50218 3.46504 7.36969 3.46504 5.9779Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M17.8983 7.2544H45.8176C46.5228 7.2544 47.1001 6.68052 47.1001 5.97759C47.1001 5.27466 46.5228 4.70078 45.8176 4.70078H17.8983C17.1932 4.70078 16.6158 5.27466 16.6158 5.97759C16.6158 6.68052 17.1932 7.2544 17.8983 7.2544Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M0.9 21.4886C0.9 24.2863 3.19202 26.5665 6.00206 26.5665C8.8121 26.5665 11.1041 24.2863 11.1041 21.4886C11.1041 18.691 8.8121 16.4107 6.00206 16.4107C3.19202 16.4107 0.9 18.691 0.9 21.4886ZM3.46504 21.5004C3.46504 20.1086 4.60233 18.9761 6.00206 18.9761C7.40178 18.9761 8.53907 20.1086 8.53907 21.5004C8.53907 22.8922 7.40178 24.0247 6.00206 24.0247C4.60233 24.0247 3.46504 22.8922 3.46504 21.5004Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M17.8983 22.7769H45.8176C46.5228 22.7769 47.1001 22.203 47.1001 21.5001C47.1001 20.7971 46.5228 20.2232 45.8176 20.2232H17.8983C17.1932 20.2232 16.6158 20.7971 16.6158 21.5001C16.6158 22.203 17.1932 22.7769 17.8983 22.7769Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M0.9 37.0218C0.9 39.8195 3.19202 42.0997 6.00206 42.0997C8.8121 42.0997 11.1041 39.8195 11.1041 37.0218C11.1041 34.2242 8.8121 31.9439 6.00206 31.9439C3.19202 31.9439 0.9 34.2242 0.9 37.0218ZM3.46504 37.0336C3.46504 35.6418 4.60233 34.5093 6.00206 34.5093C7.40178 34.5093 8.53907 35.6418 8.53907 37.0336C8.53907 38.4254 7.40178 39.5579 6.00206 39.5579C4.60233 39.5579 3.46504 38.4254 3.46504 37.0336Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M17.8983 38.2983H45.8176C46.5228 38.2983 47.1001 37.7245 47.1001 37.0215C47.1001 36.3186 46.5228 35.7447 45.8176 35.7447H17.8983C17.1932 35.7447 16.6158 36.3186 16.6158 37.0215C16.6158 37.7245 17.1932 38.2983 17.8983 38.2983Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

BulletpointsIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default BulletpointsIcon;
