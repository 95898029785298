import React from "react";
import { components } from "react-select";
import PropTypes from "prop-types";

const SingleValue = ({ project, projectImage, ...props }) => {
  return (
    <div
      style={{ width: "100%", position: "absolute", top: "4px" }}
      className="flex-container flex-al-center"
    >
      {projectImage}
      <components.SingleValue {...props}>{project.name}</components.SingleValue>
    </div>
  );
};

const { bool, string, shape, number } = PropTypes;
// set up prop types
SingleValue.propTypes = {
  projectImage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  project: shape({
    a11y_active: bool,
    active: bool,
    color: string,
    created_at: string,
    has_bugs: bool,
    has_test_cases: bool,
    icon_url: string,
    id: number,
    identifier: string,
    media: bool,
    name: string,
  }).isRequired,
};
export default SingleValue;
