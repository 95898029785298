import React from "react";
import axios from "axios";
import Select, { components } from "react-select";
import { CSSTransition } from "react-transition-group";
import Swal from 'sweetalert2'
import SingleSelectStyles from '../common/select_styles'
import {dropdownIndicator} from '../common/select_utils'

class DebugPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bugCount: 0,
      bugStart: "",
      bugEnd: "",
      bugRandom: true,
      selectedProject: {},
      submitted: false,
    };
    this.menuRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this)

    this.menuList = (props) => {
        if (this.menuRef && this.menuRef.current) {
          var item = this.menuRef.current.children[1];
          if (item) {
            if (item.scrollTop == 57) {
              item.scrollTop = 0
            }
          }
        }
      return (
        <div ref={this.menuRef}>
          <CSSTransition
            in={props.selectProps.menuIsOpen}
            appear={true}
            timeout={{
              appear: 800,
              enter: 800,
            }}
            classNames="scrollFade"
          >
            <div
              className="scrollFade"
              style={{
                transform:
                  "rotate(90deg) translateY(-80px)",
                position: "absolute",
                right: "48px",
                bottom: "260px",
                float: "right",
              }}
            >
            </div>
          </CSSTransition>
          <CSSTransition
            in={props.selectProps.menuIsOpen}
            appear={true}
            exit={true}
            unmountOnExit
            timeout={{
              appear: 400,
              enter: 400,
              exit: 400,
            }}
            classNames="select-anim"
          >
            <components.MenuList {...props}>
              {props ? props.children : ""}
            </components.MenuList>
          </CSSTransition>
        </div>
      );
    }
  }

  handleProjectChange = (e) => {
    this.setState({ selectedProject: e})  
  }

  componentDidMount() {
     axios.defaults.headers.common = {
       "X-Requested-With": "XMLHttpRequest",
       "X-CSRF-TOKEN": document
         .querySelector('meta[name="csrf-token"]')
         .getAttribute("content"),
     };
     var timer = Swal.mixin({
       showConfirmButton: false,
       timer: 3000,
       timerProgressBar: true,
       allowOutsideClick: false
     });
    timer.fire({
      title: "Warning!",
      text: "Please limit how many bugs you file through here."
    });
    this.setState({ submitted: false});
  }

  handleSubmit(e) {
     e.preventDefault();
     this.setState({submitted: true})
     var formData = new FormData()
     formData.append('bug_count', this.state.bugCount)
     formData.append('bug_start', this.state.bugStart)
     if (this.state.bugEnd != "") {
       formData.append("bug_end", this.state.bugEnd);
     }
     formData.append("project_id", this.state.selectedProject.value)
     formData.append('bug_random', this.state.bugRandom)
     var self = this
      axios
        .post("/admin_panel/debug", formData
        )
        .then((res) => {
          if (res.status == 200) {
            M.toast({
              html: `Bugs Created, data can be found in browser console`,
              displayLength: 3000,
              classes: "green",
            });
            console.table(res.data.tickets);
          }
        })
        .catch((err) => {
          M.toast({
            html: "Error creating tickets",
            classes: "red darken-3 error-toast",
          });
        });
  }

  updateValue = (e) => {
    var name = e.target.name
    var value;
    if (e.target.type != "checkbox") {
      value = e.target.value
    } else {
      value = e.target.checked
    }
    this.setState({ [name]: value}) 
  }

  render() {    
    return (
      <div className="modal-content">
        <form onSubmit={this.handleSubmit} encType="multipart/form-data">
            <p>Which Project would you like to create bugs for?</p>
            <Select
              aria-label="Project selector for debug panel"
              value={this.state.selectedProject}
              styles={SingleSelectStyles}
              onChange={this.handleProjectChange}
              name="projectDUCK"
              closeMenuOnSelect={true}
              options={this.props.data.projects}
              components={{
                DropdownIndicator: dropdownIndicator,
                MenuList: this.menuList,
              }}
              classNamePrefix="selectbox-menu"
            />
          <p>How Many Bugs would you like to create?</p>
          <label htmlFor="bugCount">
            If bug count is less than date range, bugs will be made in order of
            days. (3 bugs for 5 days = bugs on days 1,2 & 3)
          </label>
          <input
            type="number"
            max="10"
            name="bugCount"
            value={this.state.bugCount}
            onChange={this.updateValue}
          ></input>
          <p>Date or date range for bugs</p>
          <input
            type="date"
            name="bugStart"
            value={this.state.bugStart}
            onChange={this.updateValue}
          ></input>
          <input
            type="date"
            name="bugEnd"
            value={this.state.bugEnd}
            onChange={this.updateValue}
          ></input>
          <div className="col s12">
            <p>
              <label>
                <input
                  type="checkbox"
                  name="bugRandom"
                  checked={this.state.bugRandom}
                  className="filled-in"
                  value={this.state.bugRandom}
                  onChange={this.updateValue}
                ></input>
                <span>Spread bugs randomly across date range?</span>
              </label>
            </p>
          </div>
          {/* On 6/25/2020 Ryan Submitted 550 bugs because there was no button disabled state and wasn't fired*/}
          <button disabled={this.state.submitted} type="submit" className="btn">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default DebugPanel;
