import { combineReducers } from "@reduxjs/toolkit";
import testPlatformApi from "./api";
import deviceLabReducer from "./deviceLab";

const rootReducer = combineReducers({
  [testPlatformApi.reducerPath]: testPlatformApi.reducer,
  deviceLab: deviceLabReducer,
});

export default rootReducer;
