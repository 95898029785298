import React, { useContext, useMemo, useEffect } from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { IconContainer, Content } from "./TrialBody";
import MultipleChoiceOption from "./MultipleChoiceOption";
import Icons from "../../icons";
import { TrialsContext } from "./index";
import { questionTypes } from "../../../types/wiki/trial";

const SingleAnswer = (props) => {
  const { question } = props;
  const { assessment } = useContext(TrialsContext);

  const { register, setValue, getValues, reset } = useFormContext();

  const ID = useMemo(() => `${question.id}`, [question.id]);

  const optionsWithLabels = useMemo(() => {
    const LABELS = ["A", "B", "C", "D"];
    return question.answers.map((answer, idx) => {
      return {
        optionLabel: LABELS[idx],
        optionId: answer.id,
        optionText: answer.answer,
        answer,
      };
    });
  }, [question.answers]);

  const handleOptionSelect = (answer) => {
    setValue(ID, [answer.id]);
  };

  useEffect(() => {
    reset();
    register(ID, {
      value: [],
      shouldUnregister: true,
      validate: {
        mutatedAnswer: (value) => {
          if (Array.isArray(value)) return true;
          console.error(value);
          return "Value is in an invalid format";
        },
        required: (value) =>
          value.length != 0 || "Please select a response before continuing.",
        atMostOneAnswer: (value) =>
          value.length === 1 || "Please select only one response.",
      },
    });

    if (question.previous_response) {
      setValue(ID, question.previous_response.selected_options);
    }
  }, [question]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Headline>
        <IconContainer>
          <Icons.BookIcon height="21px" width="29px" color="#ffffff" />
        </IconContainer>
        <h3>
          Test Your Knowledge on {assessment.resource.title} (Level{" "}
          {assessment.level})
        </h3>
      </Headline>
      <QuestionDescription>
        <h4>
          <span>Question {question.question_number}</span> - Single Answer
        </h4>
        <p>{question.description}</p>
      </QuestionDescription>
      <ResponsesContainer>
        {optionsWithLabels.map((o) => (
          <MultipleChoiceOption
            {...o}
            questionId={ID}
            isSelected={getValues(ID)?.includes(o.answer.id) || false}
            key={o.optionId}
            onSelect={handleOptionSelect}
          />
        ))}
      </ResponsesContainer>
    </Content>
  );
};

export const Headline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  margin-bottom: 49px;

  h3 {
    font-family: "Manrope";
    margin-left: 29px;
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
  }
`;

export const QuestionDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  h4 {
    font-family: "Arial";
    font-size: 18px;
    font-weight: 700;

    span {
      text-transform: uppercase;
    }
  }

  p {
    font-family: "Arial";
    font-size: 16px;
    line-height: 33px;
  }
`;

export const ResponsesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

SingleAnswer.propTypes = {
  question: questionTypes.isRequired,
};

export default SingleAnswer;
