import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import axios from "axios";
import Team from "./team";
import NewTeamModal from "./team_modal";
import ErrorBoundary from "../common/error_boundary";

class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      base_teams: [],
      users: [],
      projects: [],
      searchString: "",
      modalOpen: false,
      active: true,
      sortBy: "",
      sortAsc: true,
      firstRender: true,
    };

    this.NewTeamModal = React.createRef();
  }

  componentDidMount() {
    const newTeamModalOptions = {
      startingTop: "4%",
      endingTop: "15%",
      dismissible: false,
      complete: function () {
        this.close();
      }.bind(this),
    };
    M.Modal.init(this.NewTeamModal, newTeamModalOptions);
    this.getTeams();
  }

  clearInput = () => {
    var elem = document.getElementById("bug-search-input");
    if (elem) {
      elem.focus();
      elem.value = "";
    }
    this.setState({ searchString: "" });
  };

  handleEditTeam = (team) => {
    var teams = this.state.teams;
    //change build histories link if lastteam
    for (var i = 0; i < teams.length; i++) {
      if (teams[i].id == team.id) {
        teams.splice(i, 1, team);
        break;
      }
    }
    this.setState({ teams: teams });
  };

  newTeamHandler = (team) => {
    var teams = this.state.teams.filter((team) => {
      if (this.state.active == team.active) {
        return true;
      }
    });
    teams.push(team);
    this.setState({ teams: teams });
  };

  open = () => {
    ReactDOM.render(
      <NewTeamModal
        users={this.state.users}
        handler={this.newTeamHandler}
        projects={this.state.projects}
        current_user={this.props.current_user}
      />,
      document.getElementById("modalContainer")
    );
  };

  updateSearch = (event) => {
    this.setState({ searchString: event.target.value });
  };

  close = () => {
    M.Modal.getInstance(this.NewTeamModal).close();
    ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer"));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.firstRender == true) {
      this.setState({ firstRender: false });
    }
  }

  handleClick = () => {
    if (this.state.modalOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  getTeams = () => {
    this.props.loadingHandler();
    axios
      .get("/admin_panel/teams", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.props.loadingHandler();
        this.setState({
          teams: res.data.teams,
          base_teams: res.data.teams,
          projects: res.data.projects,
          users: res.data.users,
          loaded: true,
        });
      })
      .catch((err) => console.error(err));
  };

  handleDeleteTeam = (teamID) => {
    var teams = this.state.teams;
    //change build histories link if lastteam
    for (var i = 0; i < teams.length; i++) {
      if (teams[i].id == teamID) {
        teams.splice(i, 1);
        break;
      }
    }
    this.setState({ teams: teams });
  };

  sortTeams = (a, b) => {
    var team1 = a;
    var team2 = b;
    if (this.state.sortBy === "created_at") {
      var date1 = new Date(team1[this.state.sortBy]);
      var date2 = new Date(team2[this.state.sortBy]);
      if (this.state.sortAsc) {
        return date1 - date2;
      } else {
        return date2 - date1;
      }
    } else if (this.state.sortBy === "owner") {
      team1 = JSON.parse(JSON.stringify(a));
      team2 = JSON.parse(JSON.stringify(b));
      team1.owner = team1.owner ? team1.owner.name : "";
      team2.owner = team2.owner ? team2.owner.name : "";
    }
    if (
      team1[this.state.sortBy] &&
      team2[this.state.sortBy] &&
      this.state.sortBy != "created_at"
    ) {
      if (this.state.sortAsc) {
        return team1[this.state.sortBy].localeCompare(team2[this.state.sortBy]);
      } else {
        return team2[this.state.sortBy].localeCompare(team1[this.state.sortBy]);
      }
    }
  };

  setSortTeams = (e) => {
    if (this.state.sortBy === e.target.id) {
      this.setState({
        sortAsc: !this.state.sortAsc,
      });
    } else {
      this.setState({
        sortAsc: true,
        sortBy: e.target.id,
      });
    }
  };

  toggleTableState() {
    this.setState({ deactivateOpen: !this.state.deactivateOpen });
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    var teams = this.state.teams.filter((team) => {
      if (this.state.active == team.active) {
        return true;
      }
    });
    var base_teams = this.state.base_teams;
    if (this.state.sortBy) {
      teams.sort(this.sortTeams);
    }
    var filteredteams = teams.filter((team) => {
      if (
        team.name &&
        team.name
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) !== -1
      ) {
        return true;
      }
      if (
        team.owner != null &&
        team.owner.name
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) !== -1
      ) {
        return true;
      }
    });

    if (this.state.searchString != "") {
      teams = filteredteams;
    }

    var delay = 666 / teams.length < 100 ? 666 / teams.length : 100;

    return (
      <div className="col s12" id="teams-main" style={{ paddingRight: "0px" }}>
        <div className="col s12">
          {base_teams.length < 1 && this.state.loaded && (
            <div className="white" id="noteamsContainer">
              <div className="no-box-shadow card col s4">
                <div className="card-content">
                  <p>No teams currently associated with these projects</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <span
          className="modal"
          id="newTeamModal"
          ref={(node) => {
            this.NewTeamModal = node;
          }}
        />
        <div style={{ display: "flex", margin: "10px 0" }}>
          <button
            style={{ marginRight: "20px" }}
            className="btn btn-overflow"
            onClick={this.handleClick}
          >
            Add New Team
          </button>
          <div
            id="ticketSearch"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              style={{
                position: "absolute",
                pointerEvents: "none",
                left: "6px",
                color: "#ababab",
              }}
              className="material-icons prefix"
            >
              search
            </i>
            <input
              id="bug-search-input"
              className="browser-default"
              type="text"
              onChange={this.updateSearch}
              placeholder=" "
            />
            {this.state.searchString != "" && (
              <button
                onClick={this.clearInput}
                className="link-btn material-icons grey-text"
              >
                close
              </button>
            )}
          </div>
          <button
            style={{ padding: "0 5px 0 0", margin: "0 10px 0 20px" }}
            disabled={this.state.active}
            className="arial bold elPt link-btn pointer admin-add-button"
            onClick={this.toggleActive}
          >
            Active
          </button>
          <button
            style={{ padding: "0 5px" }}
            disabled={!this.state.active}
            className="arial bold elPt link-btn pointer admin-add-button"
            onClick={this.toggleActive}
          >
            Retired
          </button>
        </div>
        <table className="striped highlight">
          <thead>
            <tr
              style={{
                fontWeight: 600,
                backgroundColor: "white",
                lineHeight: "25px",
              }}
              key="header"
            >
              <th
                style={{ position: "relative", whiteSpace: "nowrap" }}
                className={`heading-hover ${
                  this.state.sortBy === "name" ? "heading-selected" : ""
                }`}
                id="name"
                onClick={this.setSortTeams}
              >
                Team{" "}
                {this.state.sortBy === "name" ? (
                  this.state.sortAsc ? (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_down
                    </i>
                  ) : (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_up
                    </i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th
                width="100px"
                style={{ position: "relative", whiteSpace: "nowrap" }}
                className={`heading-hover ${
                  this.state.sortBy === "created_at" ? "heading-selected" : ""
                }`}
                id="created_at"
                onClick={this.setSortTeams}
              >
                Created{" "}
                {this.state.sortBy === "created_at" ? (
                  this.state.sortAsc ? (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_down
                    </i>
                  ) : (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_up
                    </i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th
                style={{ position: "relative", whiteSpace: "nowrap" }}
                className={`heading-hover ${
                  this.state.sortBy === "owner" ? "heading-selected" : ""
                }`}
                id="owner"
                onClick={this.setSortTeams}
              >
                Team Owner{" "}
                {this.state.sortBy === "owner" ? (
                  this.state.sortAsc ? (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_down
                    </i>
                  ) : (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_up
                    </i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th></th>
              <th>Active</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <ErrorBoundary key={index}>
                <Team
                  i={index}
                  delay={delay}
                  teams={this.props.teams}
                  editHandler={this.handleEditTeam}
                  team={team}
                  loadingHandler={this.props.loadingHandler}
                  projects={this.props.projects}
                  users={this.props.users}
                  current_user={this.props.current_user}
                  handleDeleteApplication={this.handleDeleteTeam}
                />
              </ErrorBoundary>
            ))}
          </tbody>
        </table>
        {teams.length == 0 && this.state.searchString != "" && (
          <div className="white center" id="noProjectsContainer">
            <div className="no-box-shadow card col s6 offset-s3">
              <div className="card-content">
                <p style={{ paddingTop: "15px" }}>No results for that query</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Teams;

Teams.propTypes = {
  current_user: PropTypes.object,
  testPass: PropTypes.object,
  projects: PropTypes.array,
  teams: PropTypes.array,
  users: PropTypes.array,
};
