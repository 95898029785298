import React from "react";
import PropTypes from "prop-types";

const Bugs = ({ active, ...props }) => {
  const pathClassName = active ? "active-path" : "Stats2_svg__cls-1";

  return (
    <svg
      id="Bugs_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        className={pathClassName}
        d="M19.06 22.21c0-7.14 5.8-17.28 12.94-17.28s12.94 10.14 12.94 17.28"
      />
      <path
        d="M22.95 10.76l-5.08-8.18m23.18 8.18l5.5-8.18"
        className={pathClassName}
      />
      <circle className={pathClassName} cx={32} cy={40.06} r={21.9} />
    </svg>
  );
};

Bugs.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Bugs;
