import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import ApiClient from "../../utils/ApiClient";
import { userTypes } from "../../types";
import { TrixEditor } from "../common/trix_container";
import BasicSelect from "../common/basic_select";
import EditIcon from "../icons/edit_icon";
import MoveIcon from "../icons/move_icon";

const containerWidths = {
  "100%": { label: "Full", value: "100%" },
  "50%": { label: "Half", value: "50%" },
  "33%": { label: "Third", value: "33%" },
  "25%": { label: "Quarter", value: "25%" },
};
const imageWidths = {
  "100%": { label: "Fit Container", value: "100%" },
};
const imageAlignments = {
  "0 auto": { label: "Center", value: "0 auto" },
  "0 auto 0 0": { label: "Left", value: "0 auto 0 0" },
  "0 0 0 auto": { label: "Center", value: "0 0 0 auto" },
};

const WikiSection = (props) => {
  const {
    currentUser,
    dragging,
    editMode,
    editingSection,
    handleCreateSection,
    handleDeleteSection,
    handleDragDrop,
    handleDragStart,
    handleDragEnd,
    handleEditSection,
    handleSetLoading,
    handleUpdateSection,
    loading,
    section,
    sectionsLength,
    setModal,
  } = props;
  const [content, setContent] = useState(section.content);
  const [altText, setAltText] = useState(section.image_alt_text);
  const [contentValue, setContentValue] = useState("");
  const [secureContent, setSecureContent] = useState("");
  const [secureContentValue, setSecureContentValue] = useState("");
  const [videoBlob, setVideoBlob] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [autoplay, setAutoplay] = useState(section.autoplay);
  const [videoEmbed, setVideoEmbed] = useState(section.video_embed);
  const [containerWidth, setContainerWidth] = useState(
    section.style.width
      ? containerWidths[section.style.width]
        ? containerWidths[section.style.width]
        : { label: "Custom", value: "custom" }
      : { label: "Full", value: "100%" }
  );

  const [imageWidth, setImageWidth] = useState(
    section.style.width
      ? imageWidths[section.image_style.width]
        ? imageWidths[section.image_style.width]
        : { label: "Custom", value: "custom" }
      : { label: "Fit Container", value: "100%" }
  );

  const [imageAlignment, setImageAlignment] = useState(
    section.image_style.margin
      ? imageAlignments[section.image_style.margin]
        ? imageAlignments[section.image_style.margin]
        : { label: "Center", value: "0 auto" }
      : { label: "Center", value: "0 auto" }
  );

  const customContainerWidthRef = useRef();
  const customImageWidthRef = useRef();
  const videoEmbedInputRef = useRef();
  const imageInputRef = useRef();

  function handleEditorReady(editor) {
    if (section.section_type === "secure") {
      editor.insertHTML(secureContent);
    } else {
      editor.insertHTML(content);
    }
  }

  function handleSecureContent(c, edit) {
    setSecureContent(c);
    if (edit) handleEditSection(section.id);
  }

  function handleShowSecureContent(edit) {
    const api = new ApiClient();
    const isEditing = edit === true;
    api
      .get(`/get_secure_wiki_content/${section.id}`)
      .then((res) => {
        if (res.data.msg && res.data.msg === "Email Sent") {
          setModal(true, "two-fa", {
            title: "Secure Content Authentication",
            currentUser,
            handleSecureContent,
            secureContent: section.id,
            edit: isEditing,
          });
        } else {
          setSecureContent(res.data);
          if (edit === true) handleEditSection(section.id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleChange(html) {
    if (section.section_type === "secure") {
      setSecureContentValue(html);
    } else {
      setContentValue(html);
    }
  }

  function handleSave() {
    const api = new ApiClient({ "Content-Type": "multipart/form-data" });

    if (!loading) {
      let embedLink = "";
      if (
        videoEmbed &&
        !(
          videoEmbedInputRef.current &&
          videoEmbedInputRef.current.value.includes(
            "https://www.youtube.com" // eslint-disable-line quotes
          )
        )
      ) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Invalid embed link, URL should begin with 'https://www.youtube.com'",
          classes: "red darken-1 error-toast",
        });
        return null;
      }

      handleSetLoading(true);

      const formData = new FormData();
      const formContainerWidth =
        containerWidth.value === "custom"
          ? customContainerWidthRef.current.value
          : containerWidth.value;
      const formImageWidth =
        section.section_type === "image" && imageWidth.value === "custom"
          ? customImageWidthRef.current.value
          : imageWidth.value;

      if (section.section_type === "secure") {
        formData.append("wiki_section[secure_content]", secureContentValue);
      }

      if (section.section_type !== "image") {
        formData.append("wiki_section[content]", contentValue);
      }

      formData.append("wiki_section[style][width]", formContainerWidth);

      if (imageFile) {
        formData.append("wiki_section[image]", imageFile);
        formData.append("wiki_section[image_file_name]", imageFile.name);
        formData.append("wiki_section[image_content_type]", imageFile.type);
        formData.append("wiki_section[image_file_size]", imageFile.size);
      }

      if (section.section_type === "image") {
        formData.append("wiki_section[image_style][width]", formImageWidth);
        formData.append(
          "wiki_section[image_style][margin]",
          imageAlignment.value
        );

        formData.append("wiki_section[image_alt_text]", altText);
        formData.append("wiki_section[autoplay]", !!autoplay);

        if (videoEmbed) {
          formData.append("wiki_section[video_embed]", true);
          formData.append(
            "wiki_section[content]",
            videoEmbedInputRef.current.value
          );
          embedLink = videoEmbedInputRef.current.value;
        }
      }

      if (section.id === "new") {
        formData.append("wiki_section[position]", section.position);
        formData.append("wiki_section[wiki_topic_id]", section.wiki_topic_id);
        formData.append("wiki_section[section_type]", section.section_type);
        api
          .post("/wiki_sections/", formData)
          .then((res) => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "Section created successfully",
              classes: "green darken-1 success-toast",
            });
            handleCreateSection(res.data);
          })
          .catch((err) => {
            console.error(err);
            // eslint-disable-next-line no-undef
            M.toast({
              html: "There was an error creating the section",
              classes: "red darken-1 error-toast",
            });
          })
          .finally(() => {
            handleSetLoading(false);
          });
      } else {
        api
          .patch(`/wiki_sections/${section.id}`, formData)
          .then((res) => {
            if (section.section_type === "secure") {
              setSecureContentValue("");
              setSecureContent(secureContentValue);
            } else {
              setContentValue("");
              setContent(embedLink || contentValue);
            }
            // eslint-disable-next-line no-undef
            M.toast({
              html: "Section updated successfully",
              classes: "green darken-1 success-toast",
            });
            handleUpdateSection(res.data);
          })
          .catch((err) => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "There was an error updating the section",
              classes: "red darken-1 error-toast",
            });
            console.error(err);
          })
          .finally(() => {
            handleSetLoading(false);
          });
      }
    }
  }

  function handleCancel() {
    if (section.section_type === "secure") {
      setSecureContentValue("");
    } else {
      setContentValue("");
    }
    handleEditSection(false);
  }

  function handleEdit() {
    if (section.section_type === "secure" && secureContent === "") {
      handleShowSecureContent(true);
    } else {
      handleEditSection(section.id);
    }
  }

  function handleDelete() {
    const api = new ApiClient({ "Content-Type": "multipart/form-data" });
    if (!loading) {
      Swal.fire({
        title: "Delete Section",
        text: "Are you sure that you want to delete this section?",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        confirmButtonText: "Yes",
      }).then((result) => {
        handleSetLoading(true);
        if (result.value) {
          api
            .delete(`/wiki_sections/${section.id}`)
            .then((res) => {
              // eslint-disable-next-line no-undef
              M.toast({
                html: "Section deleted successfully",
                classes: "green darken-1 success-toast",
              });
              handleDeleteSection(section.id);
            })
            .catch((err) => {
              // eslint-disable-next-line no-undef
              M.toast({
                html: "There was an error deleting the section",
                classes: "red darken-1 error-toast",
              });
              console.error(err);
            })
            .finally(() => {
              handleSetLoading(false);
            });
        }
      });
    }
  }

  function handleAddImage(e) {
    e.preventDefault();
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const fileExtension = e.dataTransfer
      ? e.dataTransfer.files[0].name.split(".").pop().toLowerCase()
      : e.target.value.split(".").pop().toLowerCase();
    const allowedExtensions = ["jpg", "jpeg", "png", "mp4"];
    if (!allowedExtensions.includes(fileExtension)) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "Invalid File Type",
        classes: "red darken-1 error-toast",
      });
      e.target.value = "";
      return null;
    }
    // file size limit: 100MB
    if (file.size > 100 * 1024 * 1024) {
      M.toast({
        html: "File exceeds size limit",
        classes: "red darken-1 error-toast",
      });
      e.target.value = "";
      return null;
    }
    setImageFile(file);
    setVideoBlob(fileExtension === "mp4");
    return null;
  }

  function handleContainerWidthChange(e) {
    setContainerWidth(e);
  }

  function handleImageWidthChange(e) {
    setImageWidth(e);
  }

  function handleImageAlignmentChange(e) {
    setImageAlignment(e);
  }

  function onDragStart() {
    const { id, position } = section;
    handleDragStart(id, position);
  }

  function onDragEnter(e) {
    const { position } = e.target.dataset;
    if (position) {
      if (parseInt(position) > dragging.position) {
        if (e.target.parentElement.style.width === "100%") {
          e.target.classList.add("bottom-drag");
        } else {
          e.target.classList.add("right-drag");
        }
      } else if (parseInt(position) < dragging.position) {
        if (e.target.parentElement.style.width === "100%") {
          e.target.classList.add("top-drag");
        } else {
          e.target.classList.add("left-drag");
        }
      }
    }
  }

  function onDragLeave(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    e.target.classList.remove("right-drag");
    e.target.classList.remove("left-drag");
  }

  function onDragDrop(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    e.target.classList.remove("right-drag");
    e.target.classList.remove("left-drag");
    const { position } = e.target.dataset;
    if (position) {
      const { id } = dragging;
      handleDragDrop(id, parseInt(position));
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  const { id, section_type, position } = section;
  const imageUrl = imageFile
    ? URL.createObjectURL(imageFile)
    : section.image_url
    ? section.image_url
    : "";

  return (
    <div
      style={{
        display: "inline-block",
        width: "100%",
        position: "relative",
        verticalAlign: "top",
        ...(editingSection !== id ? section.style : {}),
      }}
    >
      <div
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={handleDragOver}
        onDrop={onDragDrop}
        className={`trial-section-drag-overlay ${
          section.style.width && section.style.width !== "100%"
            ? "side-drag"
            : ""
        }`}
        data-position={position}
        style={{ zIndex: dragging && dragging.id !== id ? "100" : "-1" }}
      />
      {editingSection !== id ? (
        section_type === "text" || section_type === "secure" ? (
          <div className={`trial-section ${editMode ? "edit-mode" : ""}`}>
            {editMode && (
              <div
                style={{
                  backgroundColor: "#519acc",
                  padding: "5px 10px 0",
                  top: "4px",
                  zIndex: "10",
                  borderRadius: "3px",
                }}
                className="trial-section-buttons-container"
              >
                <span onClick={handleEdit}>
                  <EditIcon
                    color="white"
                    svgStyles={{ pointerEvents: "none" }}
                  />
                </span>
                {sectionsLength > 1 && (
                  <span
                    onDragStart={onDragStart}
                    className="move-icon"
                    onDragEnd={handleDragEnd}
                    draggable
                  >
                    <MoveIcon
                      color="white"
                      svgStyles={{ pointerEvents: "none", marginLeft: "8px" }}
                    />
                  </span>
                )}
                <span onClick={handleDelete} style={{ margin: "0 0 0 8px" }}>
                  <img
                    src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/Icon_TP_Trash_white.svg"
                    style={{
                      pointerEvents: "none",
                      width: "13px",
                      transform: "translateY(0px)",
                    }}
                  />
                </span>
              </div>
            )}
            {section_type === "text" ? (
              <div dangerouslySetInnerHTML={{ __html: section.content }} />
            ) : (
              <div style={{ width: "100%" }}>
                {secureContent ? (
                  <div dangerouslySetInnerHTML={{ __html: secureContent }} />
                ) : (
                  <button
                    onClick={handleShowSecureContent}
                    style={{
                      width: "250px",
                      display: "block",
                      height: "60px",
                      fontSize: "16px",
                      fontFamily: "manrope",
                      fontWeight: "600",
                      margin: "0 auto 20px",
                    }}
                    className="common-button-cancel"
                  >
                    Show Secure Content
                  </button>
                )}
              </div>
            )}
          </div>
        ) : (
          <div
            style={{ margin: "10px 0" }}
            className={`trial-section ${editMode ? "edit-mode" : ""}`}
          >
            {editMode && (
              <div
                style={{
                  backgroundColor: "#519acc",
                  padding: "5px 10px 0",
                  top: "4px",
                  zIndex: "10",
                  borderRadius: "3px",
                }}
                className="trial-section-buttons-container"
              >
                <span onClick={handleEdit}>
                  <EditIcon
                    color="white"
                    svgStyles={{ pointerEvents: "none" }}
                  />
                </span>
                {sectionsLength > 1 && (
                  <span
                    onDragStart={onDragStart}
                    className="move-icon"
                    onDragEnd={handleDragEnd}
                    dr
                  >
                    <MoveIcon
                      color="white"
                      svgStyles={{ pointerEvents: "none", marginLeft: "8px" }}
                    />
                  </span>
                )}
                <span onClick={handleDelete} style={{ margin: "0 0 0 8px" }}>
                  <img
                    src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/Icon_TP_Trash_white.svg"
                    style={{
                      pointerEvents: "none",
                      width: "13px",
                      transform: "translateY(0px)",
                    }}
                  />
                </span>
              </div>
            )}
            {videoEmbed ? (
              <div dangerouslySetInnerHTML={{ __html: section.content }} />
            ) : imageUrl.includes(".mp4") ? (
              <video
                autoPlay={autoplay}
                loop={autoplay}
                controls={!autoplay}
                style={{
                  display: "block",
                  boxShadow: "0px 0px 3px 2px #cfcfcf",
                  ...section.image_style,
                }}
                src={imageUrl}
              />
            ) : (
              <img
                alt={altText}
                style={{
                  display: "block",
                  boxShadow: "0px 0px 3px 2px #cfcfcf",
                  ...section.image_style,
                }}
                src={imageUrl}
              />
            )}
          </div>
        )
      ) : section_type === "text" || section_type === "secure" ? (
        <div style={{ margin: "20px 0", position: "relative" }}>
          <div
            style={{
              display: "flex",
              position: "absolute",
              left: "465px",
              zIndex: "100",
              top: "-2px",
            }}
          >
            <span>Width:</span>
            <BasicSelect
              options={[
                { label: "Full", value: "100%" },
                { label: "Half", value: "50%" },
                { label: "Third", value: "33%" },
                { label: "Quarter", value: "25%" },
                { label: "Custom", value: "custom" },
              ]}
              top="-4px"
              onChange={handleContainerWidthChange}
              selectedOption={containerWidth}
              width="100px"
              id="1"
            />
            {containerWidth.value === "custom" && (
              <input
                ref={customContainerWidthRef}
                maxLength={25}
                defaultValue={section.style.width || ""}
                className="trial-section-style-input"
              />
            )}
          </div>
          <TrixEditor
            value={contentValue}
            onChange={handleChange}
            className="trix-content"
            onEditorReady={handleEditorReady}
          />
          <div style={{ margin: "10px 0 0" }}>
            <button onClick={handleSave} className="common-button-submit">
              Save
            </button>
            <button onClick={handleCancel} className="common-button-cancel">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div
          className="trial-section-image-edit-container"
          style={{
            margin: "20px 0",
            padding: "20px",
            backgroundColor: "#efefef",
            position: "relative",
          }}
        >
          <div className="trial-section-edit-options">
            <span>Container</span>
            <div>
              <span>Width:</span>
              <BasicSelect
                options={[
                  { label: "Full", value: "100%" },
                  { label: "Half", value: "50%" },
                  { label: "Third", value: "33%" },
                  { label: "Quarter", value: "25%" },
                  { label: "Custom", value: "custom" },
                ]}
                onChange={handleContainerWidthChange}
                selectedOption={containerWidth}
                width="100px"
                id="1"
              />
              {containerWidth.value === "custom" && (
                <input
                  ref={customContainerWidthRef}
                  maxLength={25}
                  defaultValue={section.style.width || ""}
                  className="trial-section-style-input"
                />
              )}
            </div>
            <span style={{ marginLeft: "20px" }}>Image</span>
            <div>
              <span>Width:</span>
              <BasicSelect
                options={[
                  { label: "Fit Container", value: "100%" },
                  { label: "Custom", value: "custom" },
                ]}
                onChange={handleImageWidthChange}
                selectedOption={imageWidth}
                width="140px"
                id="2"
              />
              {imageWidth.value === "custom" && (
                <input
                  ref={customImageWidthRef}
                  maxLength={25}
                  defaultValue={section.image_style.width || ""}
                  className="trial-section-style-input"
                />
              )}
              <span>Alignment:</span>
              <BasicSelect
                options={[
                  { label: "Center", value: "0 auto" },
                  { label: "Left", value: "0 auto 0 0" },
                  { label: "Right", value: "0 0 0 auto" },
                ]}
                onChange={handleImageAlignmentChange}
                selectedOption={imageAlignment}
                width="100px"
                id="3"
              />
            </div>
            {imageUrl.includes(".mp4") || videoBlob ? (
              <div
                style={{
                  background: "none",
                  border: "none",
                  marginLeft: "20px",
                  paddingTop: "9px",
                }}
              >
                <label style={{ height: "25px" }}>
                  <input
                    onChange={() => {
                      setAutoplay(!autoplay);
                    }}
                    className="filled-in"
                    checked={autoplay}
                    value={1}
                    type="checkbox"
                  />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      height: "100%",
                      width: "100%",
                      paddingLeft: "30px",
                      textOverflow: "ellipsis",
                      lineHeight: "22px",
                      overflow: "hidden",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Autoplay Video
                  </span>
                </label>
              </div>
            ) : imageUrl ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "none",
                  border: "none",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    margin: "0 10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Alt Text
                </p>
                <input
                  maxLength={300}
                  onChange={(e) => {
                    setAltText(e.target.value ? e.target.value : "");
                  }}
                  value={altText}
                  style={{
                    maxWidth: "200px",
                    marginTop: "0",
                    marginBottom: "0",
                  }}
                  type="text"
                  className="common-input browser-default"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ paddingTop: "20px" }}>
            {imageUrl ? (
              <div>
                {imageUrl.includes(".mp4") || videoBlob ? (
                  <video
                    autoPlay={false}
                    loop={false}
                    controls={true}
                    style={{
                      maxWidth: "325px",
                      display: "block",
                      margin: "20px auto",
                    }}
                    src={imageUrl}
                  />
                ) : (
                  <img
                    style={{
                      maxWidth: "275px",
                      display: "block",
                      margin: "20px auto",
                    }}
                    src={imageUrl}
                  />
                )}
                <label
                  htmlFor="image-file"
                  style={{
                    width: "100px",
                    display: "block",
                    height: "24px",
                    margin: "auto",
                    textAlign: "center",
                    color: "#519acc",
                    fontWeight: "600",
                    lineHeight: "20px",
                    cursor: "pointer",
                  }}
                >
                  Change Media
                  <input
                    className="color"
                    style={{
                      height: "0",
                      zIndex: "1",
                      width: "100%",
                      cursor: "pointer",
                    }}
                    id="image-file"
                    type="file"
                    ref={imageInputRef}
                    accept=".jpg,.gif,.png,.mp4"
                    onChange={handleAddImage}
                  />
                </label>
              </div>
            ) : videoEmbed ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <p style={{ fontWeight: "700" }}>Video Embed Link</p>
                  <input
                    maxLength={300}
                    autoFocus={true}
                    ref={videoEmbedInputRef}
                    defaultValue={content}
                    style={{ width: "400px", marginTop: "0" }}
                    type="text"
                    className="common-input browser-default"
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  pointerEvents: "all",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                id="filepond-container"
              >
                <div style={{ position: "relative", width: "139px" }}>
                  <div style={{ height: "0" }}>
                    <input
                      className="color"
                      style={{
                        height: "0",
                        zIndex: "1",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      id="image-file"
                      type="file"
                      accept=".jpg,.gif,.png,.mp4"
                      ref={imageInputRef}
                      onChange={handleAddImage}
                    />
                  </div>
                  <label
                    htmlFor="image-file"
                    id="file-wrapper"
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={handleAddImage}
                    style={{
                      position: "relative",
                      backgroundColor: "#f5f5f5",
                      height: "134px",
                      width: "143px",
                      display: "block",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        transition: "color .3s",
                        position: "absolute",
                        top: "50px",
                        left: "16px",
                        lineHeight: "16px",
                        pointerEvents: "none",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      Drop or select image <br /> to upload
                    </span>
                    <input
                      id="validator"
                      style={{
                        pointerEvents: "none",
                        zIndex: "0",
                        transition: "background-color .3s",
                        width: "100%",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        border: "2px solid #e0e0e0",
                        height: "131px",
                        borderRadius: "5px",
                        backgroundColor: "#f5f5f5",
                        marginBottom: 0,
                        textAlign: "center",
                      }}
                      className="file-path validate attachment-drop-area"
                      placeholder=""
                      type="text"
                    />
                  </label>
                </div>
                <p style={{ margin: "0 20px 0 24px", fontWeight: "700" }}>OR</p>
                <button
                  style={{ width: "130px", height: "50px" }}
                  onClick={() => {
                    setVideoEmbed(!videoEmbed);
                  }}
                  className="common-button-submit"
                >
                  Embed Youtube Video
                </button>
              </div>
            )}
            <button
              disabled={
                section_type === "image" &&
                !(imageUrl || imageFile) &&
                !videoEmbed
              }
              onClick={handleSave}
              className="common-button-submit"
            >
              Save
            </button>
            <button onClick={handleCancel} className="common-button-cancel">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const { string, number, bool, func, shape, objectOf, oneOfType } = PropTypes;

WikiSection.propTypes = {
  currentUser: userTypes,
  dragging: shape({ position: number, id: number }),
  editMode: bool,
  editingSection: bool,
  handleCreateSection: func,
  handleDeleteSection: func,
  handleDragDrop: func,
  handleDragStart: func,
  handleDragEnd: func,
  handleEditSection: func,
  handleSetLoading: func,
  handleUpdateSection: func,
  loading: bool,
  section: shape({
    autoplay: bool,
    content: string,
    id: number,
    image_alt_text: string,
    image_style: objectOf(oneOfType([string, number])),
    image_url: string,
    position: number,
    section_type: string,
    style: objectOf(oneOfType([string, number])),
    video_embed: bool,
    wiki_topic_id: number,
  }),
  sectionsLength: number,
  setModal: func,
};

export default WikiSection;
