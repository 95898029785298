import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


const Report = styled.tr` 

`

function TopTester(props) {

  return (
      <Report key={props.index}>
          <td>{props.index + 1}</td>
          <td>{props.row && props.row[0] ? props.row[0] : props.row[3] ? props.row[3] : "" }</td>
          <td>{props.row && props.row[1] ? props.row[1] : "0"}</td>
          <td>{props.row && props.row[2] ? props.row[2] : "0"}</td>
      </Report>
    );
}

export default TopTester
TopTester.propTypes = {
  handler: PropTypes.func,
  report: PropTypes.shape({
    id: PropTypes.number,
    tester_id: PropTypes.number,
    user_name: PropTypes.string,
    submitted: PropTypes.string,
    hours: PropTypes.number,
    test_steps: PropTypes.number,
    total_bugs: PropTypes.number
  })
};

