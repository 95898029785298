import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import _ from "lodash";
import styled from "styled-components";

const PerformanceDataCSVExport = (props) => {
  const { data, queryParams } = props;

  const isExportable = useMemo(() => {
    return (
      !!data &&
      !_.isEmpty(data) &&
      Array.isArray(data.bugs) &&
      data.bugs.length > 0 &&
      !!queryParams
    );
  }, [data, queryParams]);

  const csvData = useMemo(() => {
    if (!isExportable) return null;

    const filterHeaders = Object.keys(queryParams)
      .map((key) => _.startCase(key))
      .join(",");
    const filterValues = Object.values(queryParams).join(",");

    const filtersCsv = `${filterHeaders}
${filterValues}`;

    const bugsAndHoursData = data.bugs.map((bug) => ({
      ...bug,
      ...data.hours.find((h) => h.label === bug.label),
    }));

    const bugsHeaders = Object.keys(bugsAndHoursData[0])
      .map((key) => _.startCase(key))
      .join(",");
    const bugsValues = bugsAndHoursData
      .map((bug) => Object.values(bug).join(","))
      .join("\n");

    const bugsCsv = `${bugsHeaders}
${bugsValues}`;

    const platformsHeaders = Object.keys(data.platforms[0] || {}).join(",");
    const platformsValues = data.platforms
      .map((p) => Object.values(p).join(","))
      .join("\n");
    const platformsCsv = `${platformsHeaders}
${platformsValues}`;

    const tasksHeaders = Object.keys(data.tasks[0] || {}).join(",");
    const tasksValues = data.tasks
      .map((t) => Object.values(t).join(","))
      .join("\n");
    const tasksCsv = `${tasksHeaders}
${tasksValues}`;

    return `
Filters:
${filtersCsv}

Bugs and Hours:
${bugsCsv}

Platforms:
${platformsCsv}

Tasks:
${tasksCsv}
`;
  }, [isExportable, data, queryParams]);

  const filename = useMemo(() => {
    if (!isExportable) return null;

    return `performance_dashboard_data${
      queryParams.start_date && queryParams.end_date
        ? `-${queryParams.start_date}-${queryParams.end_date}`
        : ""
    }.csv`;
  }, [isExportable, queryParams]);

  if (!isExportable) return null;

  return (
    <CSVExportContainer>
      Export Data:{" "}
      <CSVLink data={csvData} filename={filename} style={{ fontWeight: "700" }}>
        CSV
      </CSVLink>
    </CSVExportContainer>
  );
};

const CSVExportContainer = styled.div`
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

PerformanceDataCSVExport.propTypes = {
  data: PropTypes.shape({
    bugs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
        new_bugs: PropTypes.number,
        updated_bugs: PropTypes.number,
        test_steps: PropTypes.number,
        test_runs: PropTypes.number,
      })
    ),
    hours: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
        hours: PropTypes.number,
      })
    ),
    platforms: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
        id: PropTypes.number,
      })
    ),
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
        id: PropTypes.number,
      })
    ),
  }),
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
  }),
};

export default PerformanceDataCSVExport;
