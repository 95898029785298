import React from "react";
import ReactDOM from "react-dom";
import PropType from "prop-types";
import { timeRangeIcons } from "../common/icons";
import moment from "moment";
import RoundDropdown from "../icons/round_dropdown";
const today = moment(new Date()).format("YYYY-MM-DD");
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

class AvgTimeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "last30",
      custom: false,
      customSubmited: false,
      open: false,
      startDate: "",
      endDate: today,
    };

    this.checkboxMenuContainerTime = React.createRef();
    this.handleOutsideClick = (e) => {
      if (
        this.checkboxMenuContainerTime &&
        this.checkboxMenuContainerTime.current &&
        !this.checkboxMenuContainerTime.current.contains(e.target) &&
        this.state.open
      ) {
        this.setOpen();
      }
    };
    this.selectedIcons = {
      week: (
        <timeRangeIcons.WeekSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      last30: (
        <timeRangeIcons.Last30Selected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      month: (
        <timeRangeIcons.MonthSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      allTime: (
        <timeRangeIcons.AllTimeSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
      custom: (
        <timeRangeIcons.CustomDateSelected
          style={{ width: "38px", height: "38px", verticalAlign: "bottom" }}
          className="icons"
        />
      ),
    };
  }

  setOpen = () => {
    if (!this.state.open === true) {
      window.addEventListener("click", this.handleOutsideClick);
      this.setState(
        {
          open: true,
          selectOnOpen: this.state.selected,
        },
        () => {
          var elem = document.getElementById("time-checkbox-menu");
          setTimeout(() => {
            elem.style.maxHeight = this.props.maxHeight
              ? this.props.maxHeight
              : "270px";
            var optionElems = document.querySelectorAll(
              `#time-checkbox-menu > div > div`
            );
            optionElems.forEach((elm, index) => {
              elm.style.transitionDelay = `${
                (0.3 / optionElems.length) * index
              }s`;
              elm.style.transform = "translateX(0px)";
              elm.style.opacity = "1";
            });
          }, 50);
          setTimeout(() => {
            elem.style.transitionDelay = "0.2s";
            elem.style.overflow = "visible";
          }, 450);
        }
      );
    } else {
      window.removeEventListener("click", this.handleOutsideClick);
      var elem = document.getElementById("time-checkbox-menu");
      elem.style.overflow = "hidden";
      elem.style.maxHeight = "0px";
      var optionElems = document.querySelectorAll(
        `#time-checkbox-menu > div > div`
      );
      optionElems.forEach((elm, index) => {
        elm.style.transitionDelay = `${
          (0.4 / optionElems.length) * (optionElems.length - (index + 1))
        }s`;
        elm.style.transform = "translateX(-14px)";
        elm.style.opacity = "0";
      });
      setTimeout(() => {
        this.setState({
          open: false,
          custom: false,
          selected:
            !this.state.customSubmited && this.state.selected === "custom"
              ? this.state.selectOnOpen
              : this.state.selected,
        });
      }, 600);
    }
  };

  handleSelect = (e) => {
    var event = e;
    if (event.target.value == "custom") {
      this.setState({ custom: true, selected: "last30" });
    } else {
      this.props.handler(e.target.value);
      this.setState({
        selected: e.target.value,
        custom: false,
        customSubmited: false,
        open: false,
      });
    }
  };

  handleCustom = () => {
    if (this.state.custom) {
      this.setState({ custom: false, selected: "custom" });
    } else {
      this.setState({ custom: true, selected: "custom" });
    }
  };

  submitData = (e) => {
    e.preventDefault();
    var start = this.state.startDate;
    var end = this.state.endDate;
    if (!this.validateDate(start)) {
      M.toast({
        html: `Invalid Start Date`,
        displayLength: 5000,
        classes: "red",
      });
      return;
    }
    if (!this.validateDate(end)) {
      M.toast({
        html: `Invalid End Date`,
        displayLength: 5000,
        classes: "red",
      });
      return;
    }
    this.props.customHandler(start, end);
    this.setState({ custom: false, customSubmited: true, open: false });
  };

  validateDate = (input) => {
    return moment(input, "YYYY-MM-DD").isValid();
  };

  makeOptions = () => {
    var options = this.props.options
      ? this.props.options
      : [
          {
            label: "This Week",
            value: "week",
            handler: this.handleSelect,
            checked: this.state.selected == "week",
            icon:
              this.state.selected == "week" ? (
                <timeRangeIcons.WeekFilled
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ) : (
                <timeRangeIcons.Week
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ),
          },
          {
            label: "Last 30 Days",
            value: "last30",
            handler: this.handleSelect,
            checked: this.state.selected == "last30",
            icon:
              this.state.selected == "last30" ? (
                <timeRangeIcons.Last30Filled
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ) : (
                <timeRangeIcons.Last30
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ),
          },
          {
            label: "This Month",
            value: "month",
            handler: this.handleSelect,
            checked: this.state.selected == "month",
            icon:
              this.state.selected == "month" ? (
                <timeRangeIcons.MonthFilled
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ) : (
                <timeRangeIcons.Month
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ),
          },
          {
            label: "All",
            value: "allTime",
            handler: this.handleSelect,
            checked: this.state.selected == "allTime",
            icon:
              this.state.selected == "allTime" ? (
                <timeRangeIcons.AllTimeFilled
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ) : (
                <timeRangeIcons.AllTime
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ),
          },
          {
            label: "Custom",
            value: "custom",
            handler: this.handleCustom,
            checked: this.state.selected == "custom",
            icon:
              this.state.selected == "custom" ? (
                <timeRangeIcons.CustomDateFilled
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ) : (
                <timeRangeIcons.CustomDate
                  style={{ width: "38px", height: "38px", paddingRight: "6px" }}
                  className="icons"
                />
              ),
          },
        ];
    var returnOptions = [];
    options.map((option, index) => {
      returnOptions.push(
        <div
          key={index}
          className={option.classList}
          style={{
            height: "34px",
            marginTop: index === 0 ? "5px" : "0px",
            marginBottom: index === options.length - 1 ? "0px" : "0px",
            opacity: "0",
            transform: "translateX(-14px)",
            transition: " opacity 0.3s, transform 0.3s",
          }}
        >
          <label style={{ cursor: "pointer" }}>
            <input
              onChange={option.handler ? option.handler : this.handleSelect}
              className="filled-in dash-time-option"
              checked={option.value === this.state.selected}
              value={option.value}
              type="checkbox"
            />
            {/* {option.icon} */}
            {/* <img style={{width:"38px", height:"38px", paddingRight:"6px"}} src={option.icon}/> */}
            <span
              style={{
                whiteSpace: "nowrap",
                fontSize: "11pt",
                color:
                  option.value === this.state.selected ? "#519acc" : "#242424",
                paddingLeft: "0px",
                verticalAlign: "13px",
              }}
            >
              {option.label}
            </span>
          </label>
        </div>
      );
    });
    return returnOptions;
  };

  changeStart = (e) => {
    this.setState({ startDate: e.target.value });
  };

  changeEnd = (e) => {
    this.setState({ startDate: e.target.value });
  };

  render() {
    var options = {
      week: "This Week",
      month: "This Month",
      last30: "Last 30 days",
      allTime: "All",
      custom: "Custom",
    };
    var text = options[this.state.selected];
    return (
      <div
        ref={this.checkboxMenuContainerTime}
        className="checkbox-menu-container"
        style={{ width: "fit-content", position: "relative" }}
      >
        <div
          className={`${
            this.state.open
              ? "checkbox-menu-selector-open"
              : "checkbox-menu-selector-closed"
          }`}
          onClick={this.setOpen}
          style={{
            height: "34px",
            transition: "border-color 0.2s",
            color: this.state.active ? "white" : "initial",
            width: "130px",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "34px",
              whiteSpace: "nowrap",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* {this.selectedIcons[this.state.selected]} */}
            {/* <img style={{width:"38px", height:"38px", verticalAlign:"bottom"}} src={this.selectedIcons[this.state.selected]}/> */}
            <span
              style={{
                display: "inline-block",
                width: "76px",
                textAlign: "center",
                margin: "0 10px",
                color: "#242424",
              }}
            >
              {text}
            </span>
            <RoundDropdown svgStyles={{ position: "relative", left: "10px" }} />
          </span>
        </div>
        {this.state.open && (
          <div
            className="checkbox-menu"
            id="time-checkbox-menu"
            style={{
              position: "absolute",
              overflow: "hidden",
              transition: "max-height 0.4s",
              zIndex: "13",
              backgroundColor: "#ffffffdb",
              left: "0px",
              top: "100%",
              padding: "0px 10px 0px 10px",
              maxWidth: "400px",
              maxHeight: "0px",
              width: "130px",
            }}
          >
            <div id="dashTimeSwitcher">
              {this.makeOptions()}
              {this.state.custom == true && (
                <div
                  className="checkbox-menu"
                  style={{
                    fontSize: "11pt",
                    position: "absolute",
                    borderTop: "1px solid #519acc",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    zIndex: "1",
                  }}
                >
                  <form
                    style={{ display: "flex", flexDirection: "column" }}
                    onSubmit={this.submitData}
                  >
                    <div
                      style={{
                        height: "26px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        style={{
                          paddingLeft: "10px",
                          border: "none",
                          height: "26px",
                          width: "153px",
                          background: "#ffffffb7",
                        }}
                        className="browser-default"
                        type="text"
                        id="date-start-text"
                        name={"address " + Math.random()}
                        placeholder="Start: YYYY-MM-DD"
                        onChange={this.changeStart}
                        value={this.state.startDate}
                        min="2019-01-01"
                        max={today}
                        autoFocus
                      />
                      {!isSafari && (
                        <i
                          style={{ cursor: "pointer", fontSize: "26px" }}
                          className="material-icons"
                        >
                          arrow_drop_down
                        </i>
                      )}
                      <input
                        style={{ background: "#ffffffb7" }}
                        className="browser-default"
                        type="date"
                        id="date-start-date"
                        name="date-start"
                        placeholder="yyyy-mm-dd"
                        onChange={this.changeStart}
                        min="2019-01-01"
                        max={today}
                      />
                    </div>
                    <div
                      style={{
                        height: "26px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        style={{
                          paddingLeft: "10px",
                          border: "none",
                          height: "26px",
                          width: "153px",
                          background: "#ffffffb7",
                        }}
                        className="browser-default"
                        type="text"
                        id="date-end-text"
                        name={"address " + Math.random()}
                        placeholder="End: YYYY-MM-DD"
                        onChange={this.changeEnd}
                        value={this.state.endDate}
                        min="2019-01-01"
                        max={today}
                      />
                      {!isSafari && (
                        <i
                          style={{ cursor: "pointer", fontSize: "26px" }}
                          className="material-icons"
                        >
                          arrow_drop_down
                        </i>
                      )}
                      <input
                        style={{ paddingLeft: "10px", background: "#ffffffb7" }}
                        required
                        className="browser-default"
                        type="date"
                        id="date-end-date"
                        name="date-end"
                        defaultValue={moment(today).format("YYYY-MM-DD")}
                        onChange={this.changeEnd}
                        placeholder="yyyy-mm-dd"
                        min="2019-01-01"
                        max={today}
                      />
                    </div>
                    <button
                      disabled={this.state.startDate == ""}
                      type="submit"
                      style={{
                        cursor: `${
                          this.state.startDate == "" ? "intial" : "pointer"
                        }`,
                        paddingLeft: "10px",
                        background: "#ffffffb7",
                      }}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AvgTimeSelector;
