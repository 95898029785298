import React from "react";

function TicketTableHeader(props) {
  const upClass =
    "activeTrUp ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn";
  const downClass =
    "activeTrDown ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn";
  const neutralClass =
    "ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn";

  function keydown(e) {
    if (e.which == 13) {
      e.preventDefault();
      props.sortTickets;
    }
  }
  const seventh = props.project.a11y_active ? "wcag" : "labels";
  const headerArray = [
    "id",
    "priority",
    "title",
    "status",
    "assignee",
    "reporter",
    seventh,
    "created",
  ];
  const headerWidthArray = [
    "80",
    "60",
    "400",
    "80",
    "100",
    "100",
    "100",
    "100",
    "100",
  ];

  function makeHeader(name, width, index) {
    return (
      <div
        key={name}
        tabIndex={-1}
        onKeyDown={keydown}
        role="button"
        onClick={props.sortTickets}
        value={name}
        style={{ flex: `${index == 2 ? "1" : "0"} 1 ${width}px` }}
        className={
          props.column == name && props.direction == "asc"
            ? upClass
            : props.column == name && props.direction == "desc"
            ? downClass
            : neutralClass
        }
      >
        <p value={name}>{name}</p>
      </div>
    );
  }

  return (
    <div
      id="ticketTableHeader"
      style={props.props.style}
      className="ReactVirtualized__Table__headerRow sortTr"
      key="header"
    >
      {headerArray.map((name, index) => {
        var width = headerWidthArray[index];
        return makeHeader(name, width, index);
      })}
      {/* <div
        tabIndex={-1}
        onKeyDown={keydown}
        role="button"
        onClick={props.sortTickets}
        value="id"
        style={{flex: "0 1 80px"}}
        className={
          props.column == "id" && props.direction == "asc"
            ? upClass
            : props.column == "id" && props.direction == "desc"
            ? downClass
            : neutralClass
        }
      >
        <p value="id">ID</p>
      </div>
      <div
        style={{flex: "0 1 60px"}}
        className={
          props.column == "priority" && props.direction == "asc"
            ? upClass
            : props.column == "priority" && props.direction == "desc"
            ? downClass
            : neutralClass
        }
        onClick={props.sortTickets}
        value="priority"
      >
        <p value="priority">Priority</p>
      </div>
      <div
        style={{flex: "1 1 400px"}}
        className={
          props.column == "title" && props.direction == "asc"
            ? upClass
            : props.column == "title" && props.direction == "desc"
            ? downClass
            : neutralClass
        }
        onClick={props.sortTickets}
        value="title"
      >
        <p value="title">Title</p>
      </div>
      <div
        style={{
          flex: "0 1 80px",
        }}
        className={
          props.column == "status" && props.direction == "asc"
            ? upClass
            : props.column == "status" && props.direction == "desc"
            ? downClass
            : neutralClass
        }
        onClick={props.sortTickets}
        value="status"
      >
        <p value="status">Status</p>
      </div>
      <div
        style={{flex: "0 1 100px"}}
        className={
          props.column == "assignee" && props.direction == "asc"
            ? upClass
            : props.column == "assignee" && props.direction == "desc"
            ? downClass
            : neutralClass
        }
        onClick={props.sortTickets}
        value="assignee"
      >
        <p value="assignee">Assignee</p>
      </div>
      <div
        style={{flex: "0 1 100px"}}
        className={
          props.column == "reporter" && props.direction == "asc"
            ? upClass
            : props.column == "reporter" && props.direction == "desc"
            ? downClass
            : neutralClass
        }
        onClick={props.sortTickets}
        value="reporter"
      >
        <p value="reporter">Reporter</p>
      </div>
      {props.project.a11y_active ? (
        <div
          style={{flex: "0 1 100px"}}
          className={
            props.column == "wcag" && props.direction == "asc"
              ? upClass
              : props.column == "wcag" && props.direction == "desc"
              ? downClass
              : neutralClass
          }
          onClick={props.sortTickets}
          value="wcag"
        >
          <p value="wcag">WCAG</p>
        </div>
      ) : (
        <div
          style={{flex: "0 1 100px"}}
          className={
            props.column == "label" && props.direction == "asc"
              ? upClass
              : props.column == "label" && props.direction == "desc"
              ? downClass
              : neutralClass
          }
          onClick={props.sortTickets}
          value="label"
        >
          <p value="label">Label(s)</p>
        </div>
      )}
      <div
        style={{
          flex: "0 1 100px",
        }}
        className={
          props.column == "created_at" && props.direction == "asc"
            ? upClass
            : props.column == "created_at" && props.direction == "desc"
            ? downClass
            : neutralClass
        }
        onClick={props.sortTickets}
        value="created_at"
      >
        <p value="created_at">Created</p>
      </div> */}
    </div>
  );
}

export default TicketTableHeader;
