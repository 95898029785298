import React, { useEffect} from "react";
import "react-virtualized/styles.css";

function MediaTableHeader(props) {

  useEffect(() => {
    setTimeout(() => {
      var elems = document.querySelectorAll(".ReactVirtualized__Table__sortableHeaderColumn")
      if (elems.length >= 1) {
        elems.forEach((elm)=>{
          elm.style.opacity = "1"
        }) 
      }
    }, 1000);
  }, []);

  return (
    <div
      style={props.props.style}
      className="ReactVirtualized__Table__headerRow sortTr"
      key="header"
    >
      <button
        onClick={props.sortMedia}
        value="name"
        style={{
          overflow: "hidden",
          fontSize: "12px",
          fontWeight: "700",
          flex: "1 1 500px",
          transition: "opacity .5s",
          opacity: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: "flex-start",
          margin: 0,
          padding: "0 0 0 10px",
        }}
        className={
          props.column == "name" && props.direction == "ASC"
            ? "activeTrUp ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : props.column == "name" && props.direction == "DESC"
            ? "activeTrDown ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : "ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
        }
      >
        <p value="Name">Name</p>
      </button>
      <button
        type="button"
        style={{
          overflow: "hidden",
          fontSize: "12px",
          fontWeight: "700",
          display: 'flex',
          transition: "opacity .4s",
          opacity: 0,
          alignItems: 'center',
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
          flex: "0 1 200px",
        }}
        className={
          props.column == "owner" && props.direction == "ASC"
            ? "activeTrUp ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : props.column == "owner" && props.direction == "DESC"
            ? "activeTrDown ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : "ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
        }
        onClick={props.sortMedia}
        value="owner"
      >
        <p value="owner">Owner</p>
      </button>
      <button
      type="button"
        style={{
          overflow: "hidden",
          fontSize: "12px",
          fontWeight: "700",
          transition: "opacity .4s",
          opacity: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
          flex: "0 1 200px",
        }}
        className={
          props.column == "created_at" && props.direction == "ASC"
            ? "activeTrUp ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : props.column == "created_at" && props.direction == "DESC"
            ? "activeTrDown ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : "ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
        }
        onClick={props.sortMedia}
        value="created_at"
      >
        <p value="created_at">Uploaded Date</p>
      </button>
      <button
      type="button"
        style={{
          overflow: "hidden",
          fontSize: "12px",
          fontWeight: "700",
          transition: "opacity .4s",
          opacity: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
          flex: "0 1 220px",
        }}
        className={
          props.column == "size" && props.direction == "ASC"
            ? "activeTrUp ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : props.column == "size" && props.direction == "DESC"
            ? "activeTrDown ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
            : "ReactVirtualized__Table__headerColumn ReactVirtualized__Table__sortableHeaderColumn"
        }
        onClick={props.sortMedia}
        value="size"
      >
        <p value="size">File Size</p>
      </button>
    </div>
  );
}
 
export default MediaTableHeader