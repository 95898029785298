import React, { useContext } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useDeviceLab from "@hooks/data/useDeviceLab";
import UserDevice from "./user_device";
import ErrorBoundary from "../common/error_boundary";
import { DeviceLabContext } from "./device_lab";

const UserDevices = (props) => {
  const { fullWidth } = props;

  const { currentUser } = useContext(DeviceLabContext);
  const {
    hooks: {
      queries: { useGetUserDevices },
    },
  } = useDeviceLab();

  const { data: userDevices } = useGetUserDevices(currentUser.id);

  if (!userDevices || userDevices.length === 0) return null;

  return (
    <div
      id="userDeviceContainer"
      className={`${userDevices.length >= 1 ? "hasDevices" : "noDevices"}`}
      style={{
        transition: "flex .55s, transform .5 ease .55s",
        width: `${fullWidth ? "100%" : ""}`,
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "2px",
        }}
      >
        <p
          role="heading"
          aria-level={1}
          style={{
            paddingRight: "15px",
            fontWeight: "400",
            fontSize: "12px",
          }}
        >
          MY DEVICES
        </p>
        <div
          style={{
            flex: 1,
            height: "1px",
            backgroundColor: "#e0e0e0",
            width: "calc(100% + 25px)",
          }}
        />
      </div>
      <ul
        aria-label="Your checked out devices"
        key="user-devices-container"
        className="new-scrollbar"
        id="user-devices-container"
      >
        <TransitionGroup
          style={{
            display: `${fullWidth ? "grid" : "block"}`,
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          {userDevices.map((device) => (
            <CSSTransition
              key={device.id}
              unmountOnExit
              timeout={{
                enter: 700,
                exit: 900,
              }}
              classNames="card-slide"
            >
              <ErrorBoundary>
                <UserDevice
                  fullWidth={fullWidth}
                  active={false}
                  key={device.id}
                  device={device}
                />
              </ErrorBoundary>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ul>
      {userDevices.length >= 3 && (
        <div
          style={{ marginTop: `${fullWidth ? "-5px" : ""}` }}
          id="user-devices-container-blurbox"
        />
      )}
    </div>
  );
};

UserDevices.propTypes = {
  fullWidth: PropTypes.bool,
};

UserDevices.defaultProps = {
  fullWidth: false,
};

export default UserDevices;
