import axios from "axios";
import React from "react";
import WithCable from "@/channels/WithCable";
import Swal from "sweetalert2";
import PersonalInfoBox from "./personal_info_box";
import AboutMeIcon from "../icons/profile/about_me_icon";
import UserSkillsIcon from "../icons/profile/user_skills_icon";
import LanguagesIcon from "../icons/profile/languages_icon";
import InterestsIcon from "../icons/profile/interests_icon";
import AccountDetails from "./account_details";
import ReactModalV2 from "../modals/react_modal_v2";
import UserBadges from "./components/UserBadges";
import Performance from "./performance";
import KudosModal from "../modals/kudos_modal";
import TwoFactorModal from "../modals/two_factor_modal";

const modals = {
  "kudos-modal": KudosModal,
  "two-fa": TwoFactorModal,
};

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    const currentUser = this.props.current_user.id === user.id;
    this.state = {
      expanded:
        sessionStorage.getItem("expanded") === "true"
          ? true
          : sessionStorage.getItem("expanded") !== "false",
      user,
      name: user.name,
      pronouns: user.pronouns,
      about: user.about,
      languages: user.languages.join(", "),
      skills: user.user_skills,
      interests: user.user_interests,
      currentTab:
        currentUser && (user.role == "trialist" || user.role == "client")
          ? "account"
          : "account",
      currentUser,
      startDate: user.employee_started_at,
      title: user.title,
      email: user.email,
      slackId: user.slack_id,
      location: user.fact,
      muteAllProjects: user.mute_all_email_notifications,
      earnedBadges: this.props.earned_badges,
      notEarnedBadges: this.props.not_earned_badges,
      points: user.points,
      tessCoins: user.tess_coins,
      kudos: user.kudos,
      rewardHistories: this.props.reward_histories,
      userSkillOptions: this.props.user_skill_options,
      userInterestOptions: this.props.user_interest_options,
      memberships: this.props.memberships,
      workEvents: this.props.work_events,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      otpEmailEnabled: user.otp_email_enabled,
      otpEnabled: user.otp_enabled,
      role: user.role,
      scopeBugs: this.props.ticket_count,
      scopeEvents: this.props.work_event_count,
      scopeTestCases: this.props.test_case_count,
      bugsThisMonth: this.props.ticket_count_this_month,
      bugsLastMonth: this.props.ticket_count_last_month,
      testCasesThisMonth: this.props.test_case_count_this_month,
      testCasesLastMonth: this.props.test_case_count_last_month,
      workEventsThisMonth: this.props.work_event_count_this_month,
      workEventsLastMonth: this.props.work_event_count_last_month,
    };
    this.bloops = [
      new Audio(
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bloop1.mp3"
      ),
      new Audio(
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bloop2.mp3"
      ),
      new Audio(
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bloop3.mp3"
      ),
    ];
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  handleUserUpdate = (form, stateData, updateText, resolve, reject) => {
    axios
      .patch(`/users/${this.props.current_user.id}`, form, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        this.setState(stateData);

        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role="alert">${updateText} Updated</span>`,
          classes: "green",
        });
        if (resolve) {
          resolve("done");
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role="alert">Error Updating ${updateText}</span>`,
          classes: "red",
        });
        console.error(err);
        if (reject) {
          reject("error");
        }
      });
  };

  handleAboutUpdate = (about, resolve, reject) => {
    const user_form = new FormData();
    user_form.append("user[about]", about);
    this.handleUserUpdate(user_form, { about }, "About Me", resolve, reject);
  };

  handleLanguageUpdate = (languages, resolve, reject) => {
    if (languages) {
      const user_form = new FormData();
      const splitLanguages = languages.split(",").map((l) => l.trim());
      splitLanguages.forEach((l) => {
        if (l) {
          user_form.append("user[languages][]", l);
        }
      });
      this.handleUserUpdate(
        user_form,
        { languages },
        "Languages",
        resolve,
        reject
      );
    } else {
      M.toast({
        html: '<span role="alert">Please enter at least one language</span>',
        classes: "red",
      });
    }
  };

  handleSelectCreate = (item, value, resolve, reject) => {
    axios
      .post(`/create_user_${item}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        value,
      })
      .then((res) => {
        const options =
          this.state[
            `user${item.charAt(0).toUpperCase() + item.slice(1)}Options`
          ];
        options.push(res.data);
        this.setState({
          [`user${item.charAt(0).toUpperCase() + item.slice(1)}Options`]:
            options,
        });
        M.toast({
          html: `<span role="alert">${
            item.charAt(0).toUpperCase() + item.slice(1)
          } Created</span>`,
          classes: "green",
        });
        if (resolve) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        M.toast({
          html: `<span role="alert">Error Creating ${
            item.charAt(0).toUpperCase() + item.slice(1)
          }</span>`,
          classes: "red",
        });
        console.error(err);
        if (reject) {
          reject("error");
        }
      });
  };

  handleSelectUpdate = (dataIds, item, stateData, resolve, reject) => {
    const user_form = new FormData();
    dataIds.forEach((id) => {
      user_form.append(`user[user_${item}_ids][]`, id);
    });
    this.handleUserUpdate(
      user_form,
      stateData,
      `User ${item.charAt(0).toUpperCase() + item.slice(1)}s`,
      resolve,
      reject
    );
  };

  handleWorkEvent = (event, action) => {
    const work_event = {};
    if (action === "redeem") {
      work_event.attended_ids = event.attended_ids;
      work_event.attended_ids.push(this.props.current_user.id);
    } else if (action === "remove") {
      work_event.attendee_ids = event.attendee_ids;
      work_event.attendee_ids.splice(
        work_event.attendee_ids.indexOf(this.props.current_user.id),
        1
      );
    }
    axios
      .patch(`/work_events/${event.id}`, {
        work_event,
        headers: {
          // Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const { workEvents } = this.state;
        const index = workEvents.findIndex(
          (e) =>
            e.id ===
            (action === "redeem" ? res.data.work_event.id : res.data.id)
        );
        if (index !== -1) {
          if (action === "redeem") {
            workEvents.splice(index, 1, res.data.work_event);
            const { rewardHistories } = this.state;
            rewardHistories.unshift(res.data.reward_history);
            this.setState({
              workEvents,
              points: this.state.points + res.data.work_event.points,
              rewardHistories,
            });
          } else if (action === "remove") {
            workEvents.splice(index, 1);
            this.setState({ workEvents });
          }
        }
        M.toast({
          html:
            action === "redeem"
              ? `<span role="alert">${res.data.work_event.points} Points Redeemed!</span>`
              : '<span role="alert">Event removed from history</span>',
          classes: "green",
        });
      })
      .catch((err) => {
        M.toast({
          html: '<span role="alert">Error updating Event</span>',
          classes: "red",
        });
      });
  };

  handlePerformanceScope = (scope, start, end) => {
    const url = `profile/performance_scope.json?scope=${scope}${
      start ? `&start_date=${start}&end_date=${end}` : ""
    }`;
    axios
      .get(url)
      .then((res) => {
        this.setState({
          scopeBugs: res.data.ticket_count,
          scopeEvents: res.data.work_event_count,
          scopeTestCases: res.data.test_case_count,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  renderCurrentTab = () => {
    switch (this.state.currentTab) {
      case "info":
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <PersonalInfoBox
              currentUser={this.state.currentUser}
              data={this.state.about}
              image={AboutMeIcon}
              handler={this.handleAboutUpdate}
              title="ABOUT ME"
              type="text"
              placeHolder="Tell us about yourself."
            />
            <PersonalInfoBox
              currentUser={this.state.currentUser}
              data={this.state.skills}
              createHandler={this.handleSelectCreate}
              handler={this.handleSelectUpdate}
              options={this.state.userSkillOptions}
              image={UserSkillsIcon}
              title="PROFESSIONAL SKILLS"
              type="select"
              placeHolder="e.g. Test Case Authoring"
              item="skill"
            />
            <PersonalInfoBox
              currentUser={this.state.currentUser}
              data={this.state.languages}
              image={LanguagesIcon}
              handler={this.handleLanguageUpdate}
              title="LANGUAGES SPOKEN"
              placeHolder="Enter the languages you speak fluently, separated by commas"
              type="text"
            />
            <PersonalInfoBox
              currentUser={this.state.currentUser}
              data={this.state.interests}
              createHandler={this.handleSelectCreate}
              handler={this.handleSelectUpdate}
              options={this.state.userInterestOptions}
              image={InterestsIcon}
              title="PERSONAL INTERESTS"
              type="select"
              placeHolder="e.g. Music"
              item="interest"
            />
          </div>
        );
      case "performance":
        return (
          <div style={{ height: "100%", width: "100%" }}>
            <Performance
              scopeBugs={this.state.scopeBugs}
              scopeEvents={this.state.scopeEvents}
              scopeTestCases={this.state.scopeTestCases}
              handlePerformanceScope={this.handlePerformanceScope}
              handleWorkEvent={this.handleWorkEvent}
              userId={this.props.current_user.id}
              workEvents={this.state.workEvents}
              workEventsThisMonth={this.state.workEventsThisMonth}
              workEventsLastMonth={this.state.workEventsLastMonth}
              bugsThisMonth={this.state.bugsThisMonth}
              bugsLastMonth={this.state.bugsLastMonth}
              testCasesThisMonth={this.state.testCasesThisMonth}
              testCasesLastMonth={this.state.testCasesLastMonth}
            />
          </div>
        );
      case "badges":
        return (
          <div style={{ height: "100%", width: "100%" }}>
            <UserBadges
              isCurrentUser={this.state.currentUser}
              earnedBadges={this.state.earnedBadges}
              notEarnedBadges={this.state.notEarnedBadges}
              trials={this.props.trials || []}
            />
          </div>
        );
      case "account":
        return (
          <div style={{ height: "100%", width: "100%" }}>
            <AccountDetails
              user={this.props.user}
              memberships={this.state.memberships}
              name={this.state.name}
              location={this.state.location}
              startDate={this.state.startDate}
              pronouns={this.state.pronouns}
              slackId={this.state.slackId}
              email={this.state.email}
              title={this.state.title}
              userId={this.props.current_user.id}
              otpEmailEnabled={this.state.otpEmailEnabled}
              otpEnabled={this.state.otpEnabled}
              handleUpdateMembership={this.handleUpdateMembership}
              handleTwoFAModal={this.handleTwoFAModal}
              submitEditUser={this.submitEditUser}
              role={this.state.role}
              muteAllProjects={this.state.muteAllProjects}
            />
          </div>
        );
    }
  };

  handleUpdateMembership = (checked, membershipId) => {
    const id = membershipId;
    axios
      .patch(`/memberships/${id}.json`, {
        membership: { email_notifications: checked },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Notification Preference Updated",
          displayLength: 3000,
          classes: "green",
        });
        const { membership } = res.data;
        const { memberships } = this.state;
        const index = memberships.map((m) => m.id).indexOf(membership.id);
        if (index !== -1) {
          memberships.splice(index, 1, {
            ...memberships[index],
            ...membership,
          });
          this.setState({ memberships });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        Swal("There was an error editing the watchers for this ticket");
        console.log(err);
      });
  };

  handleTwoFAModal = () => {
    this.setModal(true, "two-fa", {
      title: "Add Multi Factor Authentication",
      currentUser: this.state.user,
      handleOtp: this.handleOtp,
      handleEmailOtp: this.handleEmailOtp,
    });
  };

  handleOtp = (otpStatus) => {
    const { user } = this.state;
    const otpEnabled = otpStatus;
    this.setState({ otpEnabled });
  };

  handleEmailOtp = (otpStatus) => {
    const otpEmailEnabled = otpStatus;
    this.setState({ otpEmailEnabled });
  };

  submitEditUser = (formData, stateData, emailUpdate) => {
    axios
      .patch(`/users/${this.props.current_user.id}.json`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (stateData) {
            this.setState(stateData);
          }
          if (emailUpdate) {
            // eslint-disable-next-line no-undef
            M.toast({
              html: '<span role="alert">Please check your inbox for a confirmation link to confirm email change</span>',
              displayLength: 5000,
              classes: "green",
            });
          } else if (
            stateData &&
            (stateData.muteAllProjects === true ||
              stateData.muteAllProjects === false)
          ) {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "Notification Preference Updated",
              displayLength: 3000,
              classes: "green",
            });
          } else {
            // eslint-disable-next-line no-undef
            M.toast({
              html: '<span role="alert">Personal Info Updated</span>',
              displayLength: 3000,
              classes: "green",
            });
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Edit User Error",
          text: err.response.data.error,
          confirmButtonColor: "#519acc",
          confirmButtonText: "OK",
        });
      });
  };

  resetLocation = () => {
    location.href = "/logout";
  };

  handleKudosModal = (name, message) => {
    this.setModal(true, "kudos-modal", {
      customClass: "kudos-modal",
      view: true,
      userName: name,
      message,
    });
  };

  render() {
    const { currentUser, role } = this.state;

    const employee = role !== "trialist" && role !== "client";
    return (
      <div
        className={employee ? "employee" : ""}
        style={{
          maxWidth: `calc(100% - ${100}px)`,
          marginLeft: "auto",
          willChange: "max-width",
          transition: "max-width 0.666s ease-in-out 0s",
        }}
        id="profile-container"
      >
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div
          className={
            role !== "trialist" && role !== "client" ? "" : "client-container"
          }
          style={{
            minWidth: "700px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="profile-header">
            <div className="left-section">
              <h2
                id="main-content"
                tabIndex={0}
                style={{ fontWeight: "700", outline: "none" }}
                className="large-title"
              >
                Manage Account
              </h2>
            </div>
            <div className="right-section">
              {currentUser && (
                <button
                  onClick={this.resetLocation}
                  className="common-button-cancel"
                >
                  Sign Out
                </button>
              )}
            </div>
          </div>

          <div className="profile-current-tab-container">
            {this.renderCurrentTab()}
          </div>
        </div>
      </div>
    );
  }
}

export default WithCable(ProfileContainer);
