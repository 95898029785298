import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DropdownSelectCss } from "../styles";
import useDateRangeHandler from "../../../hooks/useDateRangeHandler";
import useModalControl from "../../../hooks/useModalControl";
import ReactModalV2 from "../../modals/react_modal_v2";
import { ModalBody, ModalContent } from "../../modals/common/modal_styles";
import { CancelButton, SubmitButton } from "../../common/modal_buttons";
import ModalHeader from "../../modals/common/modal_header";

const PerformanceDateRange = (props) => {
  const {
    queryParams: { start_date, end_date },
    onDateRangeChange,
  } = props;

  const [customStartDate, setCustomStartDate] = useState(new Date(start_date));
  const [customEndDate, setCustomEndDate] = useState(new Date(end_date));

  const { PRESET_OPTIONS, PRESET_DATES, CUSTOM, formatDate, currentOption } =
    useDateRangeHandler(start_date, end_date);

  const { isOpen: isCustomDateRangeModalOpen, toggle } = useModalControl();

  const handleDateRangeOptionSelect = (option) => {
    if (option.value !== CUSTOM) {
      // call onDateRangeChange immediately with start and end dates as given by PRESET_DATES as arguments
      const { start_date: nextStartDate, end_date: nextEndDate } =
        PRESET_DATES[option.value];
      onDateRangeChange(nextStartDate, nextEndDate);
      return null;
    }

    // if option.value === CUSTOM, then open the date range picker dialog
    toggle();
    return null;
  };

  const closeCustomDateRangeModal = () => {
    // reset custom date ranges to current values from query params, then close modal
    setCustomStartDate(new Date(start_date));
    setCustomEndDate(new Date(end_date));
    toggle();
  };

  const handleCustomDateRangeSubmit = () => {
    // parse date objects to YYYY/MM/DD format, call onDateRangeChange cb with new values,
    // then close modal
    const nextStartDate = formatDate(customStartDate);
    const nextEndDate = formatDate(customEndDate);
    onDateRangeChange(nextStartDate, nextEndDate);
    toggle();
  };

  useEffect(() => {
    // re-initialize custom date range values when query params change
    setCustomStartDate(new Date(start_date));
    setCustomEndDate(new Date(end_date));
  }, [start_date, end_date]);

  return (
    <DateRangeContainer>
      <StyledSelect
        aria-label="Date range filter, dropdown to select preset date range or open dialog to select custom date range"
        placeholder="Timeframe"
        classNamePrefix="Select"
        onChange={(option) => {
          handleDateRangeOptionSelect(option);
        }}
        value={currentOption}
        isClearable={false}
        isMulti={false}
        options={Object.values(PRESET_OPTIONS)}
        isSearchable={false}
        menuPortalTarget={document.querySelector("body")}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#519acc",
          },
        })}
      />
      <ReactModalV2
        isShowing={isCustomDateRangeModalOpen}
        modalAction={toggle}
        data={{ customClass: "add-wiki-resource-modal" }}
        // eslint-disable-next-line
        page={() => {
          return (
            <ModalBody>
              <ModalHeader
                title="Select Custom Date Range"
                modalAction={toggle}
                showHeaderImg={false}
                containerStyle={{
                  borderTopLeftRadius: "6px",
                  borderTopRightRadius: "6px",
                  maxHeight: "75px",
                }}
              />
              <ModalContent>
                <DatePickers>
                  <DatePickerContainer>
                    <DatePickerLabel>Start Date</DatePickerLabel>
                    <StyledDatePicker
                      selected={customStartDate}
                      onChange={(date) => setCustomStartDate(date)}
                      selectsStart
                      startDate={customStartDate}
                      endDate={customEndDate}
                      wrapperClassName="dp-wrapper"
                      popperClassName="dp-popper"
                    />
                  </DatePickerContainer>
                  <DatePickerContainer>
                    <DatePickerLabel>End Date</DatePickerLabel>
                    <StyledDatePicker
                      selected={customEndDate}
                      onChange={(date) => setCustomEndDate(date)}
                      selectsEnd
                      startDate={customStartDate}
                      endDate={customEndDate}
                      wrapperClassName="dp-wrapper"
                      popperClassName="dp-popper"
                    />
                  </DatePickerContainer>
                </DatePickers>
                <ModalFooter>
                  <CancelButton
                    type="button"
                    onClick={closeCustomDateRangeModal}
                  >
                    Cancel
                  </CancelButton>
                  <SubmitButton
                    type="button"
                    onClick={handleCustomDateRangeSubmit}
                  >
                    Select
                  </SubmitButton>
                </ModalFooter>
              </ModalContent>
            </ModalBody>
          );
        }}
      />
    </DateRangeContainer>
  );
};

const DateRangeContainer = styled.div``;

const StyledSelect = styled(Select)`
  ${DropdownSelectCss}
  width: 160px;
`;

const DatePickers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .dp-wrapper {
    input {
      width: 100%;
      padding: 0 16px;
      border: 1px solid #d7d7d7;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  .dp-popper {
    font-family: "Manrope";

    .react-datepicker__header {
      border-bottom: 1px solid #d7d7d7;
      background-color: transparent;
    }

    .react-datepicker__navigation {
      top: 12px;

      .react-datepicker__navigation-icon::before {
        border-color: #519acc;
        transition: border-color 0.1s ease-in-out;
      }
    }
  }

  .react-datepicker__day--in-range {
    background-color: #519acc;
    border-radius: 0;
    transition-property: background-color border-radius;
  }

  .react-datepicker {
    font-family: "Manrope";
  }
`;

const DatePickerLabel = styled.label`
  color: #000;
  font-size: 13px;
  font-family: Manrope;
  font-weight: 700;
  margin-bottom: 4px;
`;

const StyledDatePicker = styled(DatePicker)``;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
`;

PerformanceDateRange.propTypes = {
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
  }).isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
};

export default PerformanceDateRange;
