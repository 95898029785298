import React from "react";
import PropTypes from "prop-types";

const WikiCategoryLead = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 35"
    >
      <path
        d="M21.1922 10.247C21.5941 10.247 21.9229 9.91919 21.9229 9.51511C21.9229 9.11104 21.5941 8.7832 21.1922 8.7832H18.2691C17.8656 8.7832 17.5383 9.11104 17.5383 9.51511V19.0299H15.346C14.9425 19.0299 14.6152 19.3578 14.6152 19.7618C14.6152 20.1644 14.9426 20.4938 15.346 20.4938H17.5383V30.0086C17.5383 30.4111 17.8656 30.7405 18.2691 30.7405H21.1922C21.5941 30.7405 21.9229 30.4111 21.9229 30.0086C21.9229 29.606 21.5941 29.2767 21.1922 29.2767H18.9998V10.247H21.1922Z"
        fill="#F4F4F4"
      />
      <path
        d="M11.2974 14.3048C11.2974 16.5103 9.51233 18.2981 7.31032 18.2981C5.10842 18.2981 3.32324 16.5103 3.32324 14.3048C3.32324 12.0995 5.10846 10.3115 7.31032 10.3115C9.51237 10.3115 11.2974 12.0995 11.2974 14.3048Z"
        fill="#F4F4F4"
      />
      <path
        d="M11.3824 17.8955C10.3827 19.0299 8.93436 19.7619 7.30765 19.7619C5.68094 19.7619 4.23265 19.0299 3.23288 17.8955C1.27883 19.3505 0 21.6604 0 24.2852V24.8195C0 25.6261 0.653288 26.2833 1.46154 26.2833H13.1538C13.9621 26.2833 14.6154 25.6261 14.6154 24.8195V24.2852C14.6154 21.6605 13.3366 19.3507 11.3825 17.8955H11.3824Z"
        fill="#F4F4F4"
      />
      <path
        d="M34.6822 3.9933C34.6822 6.19863 32.8971 7.9866 30.6951 7.9866C28.4932 7.9866 26.708 6.19859 26.708 3.9933C26.708 1.78781 28.4932 0 30.6951 0C32.8971 0 34.6822 1.78785 34.6822 3.9933Z"
        fill="#F4F4F4"
      />
      <path
        d="M34.7672 7.58301C33.7674 8.71745 32.3191 9.44936 30.6924 9.44936C29.0657 9.44936 27.6174 8.71745 26.6177 7.58301C24.6636 9.03797 23.3848 11.3479 23.3848 13.9727V14.507C23.3848 15.3136 24.0381 15.9708 24.8463 15.9708H36.5386C37.3469 15.9708 38.0001 15.3136 38.0001 14.507V13.9727C38.0001 11.348 36.7213 9.03824 34.7673 7.58301H34.7672Z"
        fill="#F4F4F4"
      />
      <path
        d="M34.6812 23.0242C34.6812 20.8196 32.8966 19.0293 30.6957 19.0293C28.4945 19.0293 26.707 20.8196 26.707 23.0242C26.707 25.2257 28.4945 27.0175 30.6957 27.0175C32.8968 27.0175 34.6812 25.2243 34.6812 23.0242Z"
        fill="#F4F4F4"
      />
      <path
        d="M34.7672 26.6123C33.766 27.7467 32.3191 28.4787 30.6924 28.4787C29.0657 28.4787 27.6174 27.7467 26.6177 26.6123C24.6636 28.0673 23.3848 30.3772 23.3848 33.002V33.5363C23.3848 34.3429 24.0381 35.0001 24.8463 35.0001H36.5386C37.3469 35.0001 38.0001 34.3429 38.0001 33.5363V33.002C38.0001 30.3773 36.7213 28.0675 34.7673 26.6123H34.7672Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};

WikiCategoryLead.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

WikiCategoryLead.defaultProps = {
  height: "35px",
  width: "38px",
  svgStyles: {},
  color: "#f4f4f4",
};

export default WikiCategoryLead;
