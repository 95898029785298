import React, { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import WithCable from "../../channels/WithCable";
import MediaList from "./media_list";
import MediaFlex from "./media_flex";
import QRCodeIcon from "../icons/qr_code_icon";
import CheckboxMenu from "../common/checkbox_menu";
import SubNavbar from "../common/sub_navbar";
import ReactModalV2 from "../modals/react_modal_v2";
import QRCode from "../modals/qr_code";
import AddMedia from "../modals/add_media";
import TicketAttachments from "../modals/ticket_attachments";

const modals = {
  "qr-code": QRCode,
  "new-media": AddMedia,
  "ticket-attachments": TicketAttachments,
};

function selectedReducer(state, action) {
  switch (action.type) {
    case "add":
      var state = [...state];
      state.unshift(action.id);
      return state;
    case "remove":
      var selectedIndex = state.findIndex((item) => item === action.id);
      if (selectedIndex < 0) {
        return state;
      }
      var update = [...state];
      update.splice(selectedIndex, 1);
      return update;
    default:
      return state;
  }
}

function filterReducer(state, action) {
  switch (action.type) {
    case "digest":
      var update = [...state];
      if (action.search != "") {
        update = update.filter((x) =>
          x.file_name.toLowerCase().includes(action.search.toLowerCase())
        );
      }
      if (action.SelectedOwners.length >= 1) {
        update = update.filter(
          (x) => action.SelectedOwners.includes(x.owner_id) != false
        );
      }
      if (action.itemFilterType.length >= 1) {
        update = update.filter(
          (x) =>
            action.itemFilterType.includes(x.file_type.split("/")[0]) != false
        );
      }
      return update;
    case "reset":
      return action.mediaItems;
  }
}

function mediaReducer(state, action) {
  switch (action.type) {
    case "add":
      return [action.id, ...state];
    case "media":
      var update = [...state];
      for (let index = 0; index < action.id.length; index++) {
        const element = action.id[index];
        update.unshift(element);
      }
      return update;
    case "remove":
      var selectedIndex = state.findIndex((item) => item.id === action.id);
      if (selectedIndex < 0) {
        return state;
      }
      var update = [...state];
      update.splice(selectedIndex, 1);
      return update;
    case "multi_remove":
      var update = [...state];
      for (let index = 0; index < action.id.length; index++) {
        const item = action.id[index];
        var selectedIndex = state.findIndex((item) => item.id === item);
        if (selectedIndex < 0) {
          return state;
        }
        update.splice(selectedIndex, 1);
      }
      return update;
    case "update":
      var selectedIndex = state.findIndex((item) => item.id === action.id);
      if (selectedIndex < 0) {
        return state;
      }
      var update = [...state];
      update.splice(selectedIndex, 1, action);
      return update;
    default:
      return state;
  }
}

function userReducer(state, action) {
  switch (action.type) {
    case "add":
      return [...state, action.value];
    case "remove":
      var selectedIndex = state.findIndex((item) => item === action.value);
      if (selectedIndex < 0) {
        return state;
      }
      var update = [...state];
      update.splice(selectedIndex, 1);
      return update;
    case "reset":
      return [];
    default:
      return state;
  }
}

function typeReducer(state, action) {
  switch (action.type) {
    case "add":
      return [...state, action.value];
    case "remove":
      var selectedIndex = state.findIndex((item) => item === action.value);
      if (selectedIndex < 0) {
        return state;
      }
      var update = [...state];
      update.splice(selectedIndex, 1);
      return update;
    case "reset":
      return [];
    default:
      return state;
  }
}

const url = new URL(location.href);
const grid = url.searchParams.get("grid");

const MediaContainer = (props) => {
  const [Project] = useState(props.project);
  const [GalleryView, setGalleryView] = useState(grid === "true");
  const [search, setSearch] = useState("");
  const [itemCount] = useState(props.media_items.length);
  const [itemFilterType, setItemFilterType] = useReducer(typeReducer, []);
  const [SelectedOwners, setSelectedOwners] = useReducer(userReducer, []);
  const [selectedImages, setSelectedImages] = useReducer(selectedReducer, []);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState({ page: "" });
  const [modalData, setModalData] = useState({});
  const [mediaItems, setMediaItems] = useReducer(
    mediaReducer,
    props.media_items
  );
  const [filteredMediaItems, setFilteredMediaItems] = useReducer(
    filterReducer,
    []
  );
  const listRef = useRef(null);
  const [mounted, setMounted] = useState(false);

  function dropdownHandler(id) {
    setLoaded(false);
    const url = new URL(location.href);
    const taskBoard = url.searchParams.get("grid");
    const newUrl = `/projects/${id}/library${
      taskBoard === "true" ? "?grid=true" : ""
    }`;
    window.location = newUrl;
  }

  function updateCurrentUserDevices(devices) {
    setCurrentUserDevices(devices);
  }

  function handleSelected(id) {
    if (selectedImages.includes(id)) {
      setSelectedImages({ id, type: "remove" });
    } else {
      setSelectedImages({ id, type: "add" });
    }
  }

  useEffect(() => {
    if (mounted === false) {
      props.cable.subscriptions.create(
        { channel: "MediaChannel", project_id: Project.id },
        {
          received(data) {
            const id = JSON.parse(data.media_item);
            if (id.owner_id != props.current_user.id) {
              setMediaItems({ id, type: data.action });
            }
          },
        }
      );
      setMounted(true);
    }
    return () => {
      props.cable.subscriptions.subscriptions[0].unsubscribe();
    };
  }, []);

  function mediaHandler(id, action) {
    if (selectedImages.includes(id)) {
      setSelectedImages({ id, type: "remove" });
    }
    setMediaItems({ id, type: action });
  }

  function handleCreatedByUserChange(e) {
    const value = isNaN(e.target.value)
      ? e.target.value
      : parseInt(e.target.value);
    const newSelectedOwners = SelectedOwners;
    if (newSelectedOwners.indexOf(value) !== -1) {
      setSelectedOwners({ value, type: "remove" });
    } else {
      setSelectedOwners({ value, type: "add" });
    }
  }

  function handleTypeChange(e) {
    const value = isNaN(e.target.value)
      ? e.target.value
      : parseInt(e.target.value);
    const newTypes = itemFilterType;
    if (newTypes.indexOf(value) !== -1) {
      setItemFilterType({ value, type: "remove" });
    } else {
      setItemFilterType({ value, type: "add" });
    }
  }

  useEffect(() => {
    if (
      search != "" ||
      SelectedOwners.length >= 1 ||
      itemFilterType.length >= 1
    ) {
      setFilteredMediaItems({
        type: "digest",
        search,
        itemFilterType,
        SelectedOwners,
      });
    } else {
      setFilteredMediaItems({ mediaItems, type: "reset" });
    }
  }, [search, itemFilterType, SelectedOwners]);

  useEffect(() => {
    if (listRef && listRef.current) {
      if (mediaItems != props.media_items) {
        listRef.current.recomputeRowHeights();
        listRef.current.forceUpdateGrid();
      }
    }
  }, [mediaItems]);

  function updateSearch(e) {
    const input = e.target.value.toLowerCase();
    setSearch(input);
  }

  function clearInput() {
    setSearch("");
  }

  function downloadSelected() {
    for (let index = 0; index < selectedImages.length; index++) {
      const id = selectedImages[index];
      const item = mediaItems.find((x) => x.id == id);
      if (item) {
        const dl = document.createElement("a");
        dl.setAttribute("href", `${item.download_url}`);
        dl.setAttribute("download", `${item.file_name}`);
        dl.click();
        dl.remove();
      }
    }
  }

  function deleteSelected() {
    axios
      .delete(`/media_items/${selectedImages}/bulk_delete.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          M.toast({
            html: `${selectedImages.length} Item${
              selectedImages.length >= 2 ? "s" : ""
            } Deleted`,
            classes: "amber",
          });
          for (let index = 0; index < selectedImages.length; index++) {
            const item = selectedImages[index];
            mediaHandler(item, "remove");
          }
        }
      })
      .catch((err) => {
        err.message = `Delete Media Item Backend Error: ${err.message}`;
        console.error(err);
        Swal.fire({
          title: "Media Item delete error",
          text: "There was a problem deleting this media",
          customClass: "swal2-error-modal",
        });
      });
  }

  function resetFilters() {
    setItemFilterType({ type: "reset" });
    setSelectedOwners({ type: "reset" });
    setSearch("");
  }

  function showQRCode() {
    setModal(true, "qr-code", {
      userId: props.current_user.id,
      projectId: Project.id,
      customClass: "qr-modal",
      media: true,
    });
  }

  function togglTaskBoardView() {
    const url = new URL(location.href);
    const grid = url.searchParams.get("grid");
    if (grid === "true") {
      url.searchParams.delete("grid");
    } else {
      url.searchParams.append("grid", "true");
    }
    window.history.pushState("page2", "Test Platform", url);
    setGalleryView(!GalleryView);
  }

  function handleNewMedia() {
    setModal(true, "new-media", {
      currentUser: props.current_user,
      project: Project,
      customClass: "new-media",
      mediaHandler,
      backdropClickCheck: {
        title: "Discard New Media",
        text: "Are you sure that you want to discard this media?",
      },
    });
  }

  function setModal(bool, page, data) {
    setModalOpen(bool);
    setCurrentModal({ page: modals[page] });
    setModalData(data);
  }

  function handleNewImage(image) {
    setMediaItems({ image, type: "update" });
  }

  function editModal(image) {
    if (image) {
      setModal(true, "ticket-attachments", {
        images: [image],
        customClass: "ticket-attachments-modal",
        backdropClickCheck: {
          title: "Exit Image Edit",
          text: "Are you sure that you want to exit image edit? Any unsaved changes will not be preserved.",
        },
        setBypassClickCheck: true,
        currentIndex: 0,
        edit: true,
        handleNewImages: handleNewImage,
      });
    }
  }

  let data;
  if (
    itemFilterType.length >= 1 ||
    SelectedOwners.length >= 1 ||
    search != ""
  ) {
    data = filteredMediaItems;
  } else {
    data = mediaItems;
  }
  return (
    <div>
      <ReactModalV2
        isShowing={modalOpen}
        page={currentModal}
        data={modalData}
        modalAction={setModal}
        handler={mediaHandler}
      />
      <div
        id="sidebar-child"
        style={{
          width: "100%",
          maxWidth: `calc(100% - ${100}px)`,
          willChange: "max-width",
          transition: "max-width 0.666s ease-in-out 0s",
          padding: "0px 25px",
          marginLeft: "auto",
          display: "flex",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "1px",
            backgroundColor: "rgb(224, 224, 224)",
            width: "calc(100% + 50px)",
            top: "90px",
            marginLeft: "-25px",
            position: "relative",
          }}
        />
        <div style={{ marginBottom: "30px" }} id="tickets-sub-nav-container">
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: "13px",
            }}
          >
            <div
              style={{ flex: 1, alignItems: "center", display: "inline-flex" }}
            >
              <img
                alt="media library"
                style={{
                  pointerEvents: "none",
                  height: "50px",
                  marginRight: "10px",
                  width: "50px",
                }}
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/icon_medialibrary_filled.svg"
              />
              <h1 tabIndex={-1} className="large-title">
                {" "}
                Media Library{" "}
              </h1>
            </div>
            <div
              style={{ maxWidth: selectedImages.length >= 1 ? "400px" : "0px" }}
              id="selectedButtons"
            >
              <button
                style={{ whiteSpace: "pre", width: "auto" }}
                className="common-button-cancel"
                type="button"
                onClick={downloadSelected}
              >
                Download Selected
              </button>
              <button
                style={{ whiteSpace: "pre", width: "auto" }}
                className="common-button-cancel"
                type="button"
                onClick={deleteSelected}
              >
                Delete Selected
              </button>
            </div>
            <button
              message="Get my QR Code"
              className="link-btn qr-button-bottom"
              style={{
                marginRight: "8px",
                color: "#519acc",
                border: "solid 1px lightgray",
                height: "32px",
                borderRadius: "6px",
              }}
              onClick={showQRCode}
            >
              <QRCodeIcon
                width={20}
                height={20}
                color="#242424"
                svgStyles={{ position: "relative", top: "2px" }}
              />
            </button>
            <span
              style={{
                display: "inline-block",
                border: "solid 1px lightgray",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
                height: "32px",
                padding: "0 2px",
              }}
              aria-hidden="true"
              aria-label="List View"
              message="List View"
              className="qr-button-bottom list-view"
            >
              <img
                onClick={togglTaskBoardView}
                src={
                  GalleryView
                    ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_List_View.svg"
                    : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_List_View_Blue.svg"
                }
                width="36"
                height="36"
                alt="list view icon"
                data-id="list"
                style={{
                  display: "inline-block",
                  fontSize: "44px",
                  // marginRight: "15px",
                  cursor: "pointer",
                  verticalAlign: "bottom",
                  position: "relative",
                  bottom: "3px",
                }}
                className="material-icons"
              />
            </span>
            <span
              style={{
                display: "inline-block",
                borderTop: "solid 1px lightgray",
                borderRight: "solid 1px lightgray",
                borderBottom: "solid 1px lightgray",
                borderTopRightRadius: "6px",
                borderBottomRightRadius: "6px",
                height: "32px",
                padding: "0 2px",
                marginRight: "10px",
              }}
              aria-hidden="true"
              aria-label="Gallery View"
              message="Gallery View"
              className="qr-button-bottom taskboard-view"
            >
              <img
                onClick={togglTaskBoardView}
                src={
                  GalleryView
                    ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/icon_medialibrary_galleryview_blue.svg"
                    : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/icon_medialibrary_galleryview.svg"
                }
                width="38"
                height="38"
                data-id="column"
                style={{
                  display: "inline-block",
                  fontSize: "44px",
                  // marginRight: "15px",
                  padding: "4px",
                  cursor: "pointer",
                  verticalAlign: "bottom",
                  position: "relative",
                  bottom: "4px",
                }}
                className="material-icons"
              />
            </span>
          </div>
        </div>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <div style={{ flex: ".5", display: "flex", flexDirection: "row" }}>
            <button
              id="new-bug"
              type="button"
              style={{ marginRight: "20px" }}
              onClick={handleNewMedia}
              className="btn btn-overflow"
            >
              Add New Media
            </button>
            <div
              id="ticketSearch"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i
                aria-hidden="true"
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  left: "6px",
                  color: "#ababab",
                }}
                className="material-icons prefix"
              >
                search
              </i>
              <input
                id="bug-search-input"
                disabled={props.media_items.length === 0}
                className="browser-default"
                type="text"
                onChange={updateSearch}
                placeholder=" "
                aria-label="Bugs search input field"
              />
              {search != "" && (
                <button
                  onClick={clearInput}
                  className="link-btn material-icons grey-text"
                >
                  close
                </button>
              )}
            </div>
            <p
              aria-label={`Showing ${
                search
                  ? `${mediaItems.length} out of ${itemCount} items`
                  : `all ${itemCount} items`
              }`}
              role="text"
              style={{
                whiteSpace: "pre",
                alignSelf: "center",
                padding: "0 20px",
              }}
            >{`Displaying ${mediaItems.length >= 1 ? "1" : "0"}-${
              filteredMediaItems.length
            } out of ${itemCount == null ? "0" : itemCount}`}</p>
          </div>

          <SubNavbar
            flex={[0, 0, 0]}
            pageName=""
            aria-hidden
            containerStyle={{
              height: "70px",
              flex: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "20px",
            }}
            height="70px"
            maxWidth="100%"
            center
            sectionTwoChildren={[
              <CheckboxMenu
                maxWidth="100%"
                key={3}
                count={itemFilterType.filter((x) => x).length}
                active={itemFilterType.filter((x) => x).length >= 1}
                options={[
                  {
                    label: "Image",
                    value: "image",
                    handler: handleTypeChange,
                    checked: itemFilterType.includes("image"),
                  },
                  {
                    label: "Video",
                    value: "video",
                    handler: handleTypeChange,
                    checked: itemFilterType.includes("video"),
                  },
                  {
                    label: "File",
                    value: "application",
                    handler: handleTypeChange,
                    checked: itemFilterType.includes("application"),
                  },
                  {
                    label: "Audio",
                    value: "audio",
                    handler: handleTypeChange,
                    checked: itemFilterType.includes("audio"),
                  },
                  // {
                  //   label: "Won't Fix",
                  //   value: 4,
                  //   handler: handleTypeChange,
                  //   checked: itemFilterType[4],
                  // },
                  // {
                  //   label: "Reopened",
                  //   value: 5,
                  //   handler: handleTypeChange,
                  //   checked: itemFilterType[5],
                  // },
                ]}
                title="Filter By Type"
                id="1"
                narrow
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />,
            ]}
            sectionOneChildren={[
              <CheckboxMenu
                key={2}
                maxWidth="100%"
                searchEnabled
                containerMinWidth="160px"
                count={SelectedOwners.length}
                active={SelectedOwners.length >= 1}
                options={[
                  {
                    label: "Me",
                    value: props.current_user.id,
                    handler: handleCreatedByUserChange,
                    checked:
                      SelectedOwners.indexOf(props.current_user.id) !== -1,
                  },

                  ...props.userOptions.map((user) => ({
                    label: user.label,
                    value: user.value,
                    handler: handleCreatedByUserChange,
                    checked: SelectedOwners.indexOf(user.value) !== -1,
                    classList: "user-option",
                  })),
                ]}
                title="Filter By User"
                id="2"
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />,
            ]}
          />
          {SelectedOwners.length + itemFilterType.filter((x) => x).length >=
            1 && (
            <button
              style={{
                flex: 0.2,
                whiteSpace: "pre",
                alignSelf: "center",
                color: "#519acc",
                cursor: "pointer",
              }}
              onClick={resetFilters}
              className="btn link-btn"
            >
              Clear All Filters
            </button>
          )}
        </div>
        {GalleryView ? (
          <MediaFlex
            selectedImages={selectedImages}
            handleSelected={handleSelected}
            mediaHandler={mediaHandler}
            editorHandler={editModal}
            project={Project}
            media_items={data}
          />
        ) : (
          <MediaList
            listRef={listRef}
            selectedImages={selectedImages}
            handleSelected={handleSelected}
            mediaHandler={mediaHandler}
            editorHandler={editModal}
            project={Project}
            media_items={data}
          />
        )}
      </div>
    </div>
  );
};

export default WithCable(MediaContainer);
