import { useCallback, useMemo, useState, useEffect } from "react";
import _ from "lodash";

const [THIS_MONTH, LAST_MONTH, THIS_YEAR, ALL_TIME, CUSTOM] = [
  "this_month",
  "last_month",
  "this_year",
  "all_time",
  "custom",
];

const DATERANGE_KEYS = [THIS_MONTH, LAST_MONTH, THIS_YEAR, ALL_TIME, CUSTOM];

// const DEFAULT_DATERANGE = THIS_MONTH;

const useDateRangeHandler = (start_date, end_date) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // update start and end date state Date objects when start_date and end_date string props change
  useEffect(() => {
    setStartDate(new Date(start_date));
    setEndDate(new Date(end_date));
  }, [start_date, end_date]);

  // all preset options, pass this list to a select dropdown
  const PRESET_OPTIONS = useMemo(() => {
    const options = {};
    for (const key of DATERANGE_KEYS) {
      options[key] = { label: _.startCase(key), value: key };
    }
    return options;
  }, []);

  const formatDate = (date) => {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  };

  // give a preset, get correct start and end date strings
  const PRESET_DATES = useMemo(() => {
    const today = new Date();
    return {
      [THIS_MONTH]: {
        start_date: formatDate(
          new Date(today.getFullYear(), today.getMonth(), 1)
        ),
        end_date: formatDate(today),
      },
      [LAST_MONTH]: {
        start_date: formatDate(
          new Date(today.getFullYear(), today.getMonth() - 1, 1)
        ),
        end_date: formatDate(
          new Date(today.getFullYear(), today.getMonth(), 0)
        ),
      },
      [THIS_YEAR]: {
        start_date: formatDate(new Date(today.getFullYear(), 0, 1)),
        end_date: formatDate(today),
      },
      [ALL_TIME]: {
        start_date: "2020/09/01",
        end_date: formatDate(today),
      },
    };
  }, []);

  // check end dates for each preset option
  const isThisMonth = useCallback(() => {
    const today = new Date();
    return (
      startDate.getMonth() === today.getMonth() &&
      startDate.getDate() === 1 &&
      startDate.getFullYear() === today.getFullYear() &&
      endDate.getMonth() === today.getMonth() &&
      endDate.getFullYear() === today.getFullYear()
    );
  }, [startDate, endDate]);

  const isLastMonth = useCallback(() => {
    const lastDayOfPrevMonth = new Date();
    lastDayOfPrevMonth.setDate(0);

    const startDateIsFirstDayOfPrevMonth =
      startDate.getMonth() === lastDayOfPrevMonth.getMonth() &&
      startDate.getDate() === 1 &&
      startDate.getFullYear() === lastDayOfPrevMonth.getFullYear();
    const endDateIsLastDayOfLastMonth =
      endDate.getMonth() === lastDayOfPrevMonth.getMonth() &&
      endDate.getDate() === lastDayOfPrevMonth.getDate() &&
      endDate.getFullYear() === lastDayOfPrevMonth.getFullYear();

    return startDateIsFirstDayOfPrevMonth && endDateIsLastDayOfLastMonth;
  }, [startDate, endDate]);

  const isThisYearToDate = useCallback(() => {
    const today = new Date();

    return (
      startDate.getMonth() === 0 &&
      startDate.getDate() === 1 &&
      startDate.getFullYear() === today.getFullYear() &&
      endDate.getMonth() === today.getMonth() &&
      endDate.getDate() === today.getDate() &&
      endDate.getFullYear() === today.getFullYear()
    );
  }, [startDate, endDate]);

  const isAllTime = useCallback(() => {
    const firstReportDate = new Date("2020/09/01");
    const today = new Date();

    return (
      startDate.getTime() <= firstReportDate.getTime() &&
      endDate.getMonth() === today.getMonth() &&
      endDate.getDate() === today.getDate() &&
      endDate.getFullYear() === today.getFullYear()
    );
  }, [startDate, endDate]);

  // check if start and end dates are defined. return defaults if not
  const datesAreDefined = useCallback(() => {
    return !(start_date === undefined || end_date === undefined);
  }, [start_date, end_date]);

  // check if start and end dates are valid, should be valid dates and start date before end date
  const datesAreValid = useCallback(() => {
    if (!datesAreDefined()) return false;

    if (startDate.toString() === "Invalid Date") {
      return false;
    }

    if (endDate.toString() === "Invalid Date") {
      return false;
    }

    return startDate.getTime() <= endDate.getTime();
  }, [datesAreDefined, startDate, endDate]);

  // select the valid preset key value
  const getSelectedPreset = useCallback(() => {
    return (
      datesAreValid &&
      ((isThisMonth() && THIS_MONTH) ||
        (isLastMonth() && LAST_MONTH) ||
        (isThisYearToDate() && THIS_YEAR) ||
        (isAllTime() && ALL_TIME) ||
        CUSTOM)
    );
  }, [datesAreValid, isThisMonth, isLastMonth, isThisYearToDate, isAllTime]);

  // select the correct preset option object; return default if dates are invalid,
  // to prevent breaking select dropdown component
  const currentOption = useMemo(() => {
    // if (!datesAreValid()) return PRESET_OPTIONS[DEFAULT_DATERANGE];
    if (!datesAreValid()) return null;
    return PRESET_OPTIONS[getSelectedPreset()];
  }, [datesAreValid, getSelectedPreset, PRESET_OPTIONS]);

  return {
    PRESET_OPTIONS,
    PRESET_DATES,
    THIS_MONTH,
    LAST_MONTH,
    THIS_YEAR,
    ALL_TIME,
    CUSTOM,
    DATERANGE_KEYS,
    formatDate,
    getSelectedPreset,
    currentOption,
  };
};

export default useDateRangeHandler;
