import React from 'react'
import ReactDOM from 'react-dom'
import Swal from "sweetalert2";
import NewTeamModal from './team_modal.jsx'
import axios from 'axios'

class Team extends React.Component{

   constructor(props) {
    super(props);
    this.submitDeleteTeam = this.submitDeleteTeam.bind(this)
    this.deactivateTeam = this.deactivateTeam.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
  }

  async openEditModal() {
    this.props.loadingHandler()
    var resp = await axios.get(`/admin_panel/teams/${this.props.team.id}.json`)
    ReactDOM.render(
      <NewTeamModal 
        team={resp.data.team} 
        edit={true}
        editHandler={this.props.editHandler} 
        users={resp.data.users} 
        current_user={this.props.current_user} 
        projects={resp.data.projects} 
        // teams={resp.data.team}
        project={this.props.project} 
      />,
    document.getElementById('modalContainer'),
    )
    this.props.loadingHandler()
  }

  deactivateTeam() {
    var formData = new FormData();
    formData.append('team[active]', !this.props.team.active);
    var component = this
    var object = this.props
    var team = object.team
    var xhr = new XMLHttpRequest();
    xhr.open('PATCH', '/teams/' + team.id, true);
    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    xhr.onload = function () {
      if (xhr.status === 200) {
        var resp = JSON.parse(xhr.response)
        component.props.editHandler(resp)
        component.setState({activeState: resp.active})
        component.closeEditProjectModal
          Swal.fire({
              title: resp.active ? "Activated" : "Deactivated",
              text: resp.active ? "Your Team was successfully activated" : "Your Team was successfully deactivated",
          })
        } else {
        Swal.fire('Team deactivation error','There was an issue');
      };
    }
  Swal.fire({
          title: !team.active ? `Reactivate Team: ${object.team ? object.team.name : object.team.name == ""}` : `Deactivate Team: ${object.team ? object.team.name : object.team.name == ""}`,
          text: !team.active ?  "Are you sure you want to reactivate this Team?" :  "Are you sure you want to deactivate this Team?",
          reverseButtons: true,
          showCancelButton:true,
          confirmButtonAriaLabel: 'Yes',
          cancelButtonAriaLabel: 'cancel',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            xhr.send(formData);
            
        } else if (result.dismiss) {
            // Swal.fire(
            //   'Cancelled',
            //   'Your Team is safe'
            // )
          }
        })
}


  submitDeleteTeam(e) {
    var object = this.props
    var user = object.user
    var org = object.organization
    var team = object.team
  // Sweet alert that presents when user clicks trashcan icon
      Swal.fire({
        title: `Delete Team: ${object.team ? object.team.name : object.team.name == ""}`,
        text: "Are you sure that you want to delete this team?",
        showCancelButton:true,
        confirmButtonAriaLabel: 'Yes',
        cancelButtonAriaLabel: 'cancel',
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
        // Send ajax call to delete application choosen
          $.ajax(
          {
            url: "/teams/"+object.team.id + ".json",
            dataType: 'json',
            type: "DELETE",
            cache: false,
            beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
            success: function(data){
              Swal.fire("Deleted", "Your Team was successfully deleted");
              // Removes the application from the front end
              object.handleDeleteApplication(object.team.id)
            },
            error: function(data) {
            // If error present error swal message
            Swal.fire({title: "Delete Team Error", text:"There was a problem with that request", customClass: "swal2-error-modal"});
          }
          })
        } else if (result.dismiss) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your Team is safe'
          // )
      }
    })
  }

  render() {
    const { team } = this.props.team;
    return (
      <tr style={{color: this.props.team.active ? "" : "#a5a5a5"}}>
      <td>{this.props.team.name}</td>
      <td>{this.props.team.created_at}</td>
      <td>{this.props.team.owner != null ? this.props.team.owner.name != null ? this.props.team.owner.name : this.props.team.owner.email : ""}</td>
      <td></td>
      <td><button className="link-btn" onClick={this.deactivateTeam}><i className="material-icons deactivate-icon" style={{color:`${this.props.team.active?"#2bde2b":"#ff5353"}`, fontSize: "28px", lineHeight: "30px"}}>power_settings_new</i></button></td>
      <td><button className="link-btn" onClick={this.openEditModal}><img src='https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/noun_edit.png' id="team-edit" className="hoverable responsive-img circle pointer" width="30" height="30" /></button></td>
      <td><button className="link-btn" onClick={this.submitDeleteTeam}><img src='https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/noun_Delete.png' id="team-delete" className="hoverable responsive-img circle pointer" width="30" height="30" /></button></td>
    </tr>
    );
  }
}

export default Team