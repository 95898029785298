import React, { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";
import RoundDropdown from "../icons/round_dropdown";
import moment from "moment";
import { CSSTransition } from "react-transition-group";

var searchTimer = null;

var backgroundUrls = [
  "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/help-center/Help_Center_V1.png",
  "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/help-center/Help_Center_V2.png",
  "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/help-center/Help_Center_V3.png",
  "https://tp-assets.sfo2.cdn.digitaloceanspaces.com/help-center/Help_Center_V4.png",
];

function HelpDirectory(props) {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const searchInput = useRef();

  function updateSearch(e) {
    setSearch(e.target.value ? e.target.value : "");
    setSearchLoading(true);
    if (!e.target.value) {
      setSearchResults(null);
      clearTimeout(searchTimer);
      setSearchLoading(false);
    } else {
      var value = e.target.value;
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        axios
          .get(`/help_search.json?query=${value}`)
          .then((res) => {
            setSearchResults(res.data);
            setSearchLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setSearchLoading(false);
          });
      }, 400);
    }
  }

  function clearInput() {
    var elem = document.getElementById("bug-search-input");
    if (searchInput.current) {
      searchInput.current.focus();
      searchInput.current.value = "";
    }
    setSearch("");
    setSearchResults(null);
  }

  function handleTopicClick(e) {
    var resourceId = e.target.dataset.resourceId;
    var topicId = e.target.dataset.topicId;
    props.handleTopicClick(resourceId, topicId);
  }

  return (
    <div>
      <div id="help-search-container">
        <img
          style={{ height: "100%", right: "0", zIndex: "1" }}
          src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/help-center/Help_Center_Banner.png"
        />
        <span
          style={{
            display: "block",
            position: "absolute",
            width: "100%",
            height: "100%",
            maxWidth: "1200px",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "0 auto",
          }}
        >
          <img
            style={{ height: "265px", right: "30px", zIndex: "2" }}
            src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/help-center/Help_Center_Banner_Tess.png"
          />
        </span>
        <h1 style={{ zIndex: "3" }}>Welcome to Test Platform Help</h1>
        <div style={{ zIndex: "3", position: "relative" }}>
          <CSSTransition
            unmountOnExit
            in={searchLoading}
            timeout={200}
            classNames="node-fade"
          >
            <div
              style={{ position: "absolute", left: "460px", top: "8px" }}
              id="first-build-spinner"
            />
          </CSSTransition>
          <i aria-hidden="true" className="material-icons prefix">
            search
          </i>
          <input
            ref={searchInput}
            className="browser-default"
            type="text"
            onChange={updateSearch}
            placeholder="Search to locate a help article"
            autoComplete="false"
            aria-live="polite"
          />
          {search != "" && (
            <button
              onClick={clearInput}
              className="link-btn material-icons grey-text"
            >
              close
            </button>
          )}
        </div>
      </div>
      {!Array.isArray(searchResults) ? (
        <div>
          <div id="help-featured-container">
            <h2>Featured Help Topics</h2>
            <div className="help-divider">
              <div />
              <div />
              <div />
            </div>
            <div>
              {props.featuredTopics.map((t) => {
                return (
                  <div
                    key={`${t.id}-featured`}
                    className={`featured-topic-card ${
                      moment()
                        .subtract(30, "days")
                        .isAfter(moment(t.created_at))
                        ? ""
                        : "new-feature"
                    }`}
                  >
                    <h3 style={{ whiteSpace: "normal" }}>{t.title}</h3>
                    <p>{t.featured_text}</p>
                    <button
                      onClick={handleTopicClick}
                      data-resource-id={t.help_resource_id}
                      data-topic-id={t.id}
                      className="common-button-submit"
                    >
                      View Article
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div id="help-resources-container">
            <h2>Help Resources</h2>
            <div className="help-divider">
              <div />
              <div />
              <div />
            </div>
            <div>
              {props.helpResources.map((r, i) => {
                return (
                  <div
                    key={r.id}
                    style={{
                      margin:
                        i === 1 || (i - 1) % 3 === 0
                          ? "10px 10px 0px 10px"
                          : "10px 0px 0px",
                      backgroundImage: `url(${backgroundUrls[i % 4]})`,
                      backgroundSize: "cover",
                    }}
                    className="help-resource-card"
                  >
                    <h3>{r.title}</h3>
                    {r.help_topics.slice(0, 5).map((t) => {
                      return (
                        <button
                          onClick={handleTopicClick}
                          data-resource-id={r.id}
                          data-topic-id={t.id}
                          className="light-button"
                          key={t.id}
                        >
                          {t.title}
                        </button>
                      );
                    })}
                    {r.help_topics.length > 5 && (
                      <button
                        onClick={handleTopicClick}
                        data-resource-id={r.id}
                        style={{
                          color: "#519acc",
                          backgroundColor: "white",
                          padding: "0 10px",
                          width: "auto",
                          borderRadius: "14px",
                          height: "27px",
                          marginTop: "14px",
                          marginBottom: "10px",
                        }}
                        className="common-button-submit show-more"
                      >
                        View All Topics{" "}
                        <RoundDropdown
                          svgStyles={{
                            transform: "rotate(-90deg) translate(-2px, 5px)",
                          }}
                          color="#519acc"
                        />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div id="help-search-results">
          <h2 style={{ marginTop: "20px" }}>Search Results</h2>
          <div className="help-divider">
            <div />
            <div />
            <div />
          </div>
          {searchResults.length > 0 ? (
            <div>
              {searchResults.map((t) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={t.id}
                  >
                    <button
                      onClick={handleTopicClick}
                      style={{
                        display: "block",
                        color: "#519acc",
                        fontSize: "16px",
                        paddingRight: "4px",
                      }}
                      data-resource-id={t.help_resource_id}
                      data-topic-id={t.id}
                      className="light-button"
                    >
                      {t.title}
                    </button>
                    <span style={{ color: "#b3b3b3", fontStyle: "italic" }}>
                      {" "}
                      - {t.help_resource_title}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              style={{
                textAlign: "center",
                color: "#bfbfbf",
                marginTop: "30px",
                fontSize: "15px",
              }}
            >
              No Search Results Found
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default HelpDirectory;
