import React from "react";
import PropTypes from "prop-types";

const Confetti = ({ height, width, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 137 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1961_5515)">
        <path
          d="M32.5705 99.0283C34.0278 97.9942 34.3692 95.9722 33.3331 94.5121C32.297 93.052 30.2757 92.7067 28.8184 93.7408C27.3611 94.775 27.0196 96.7969 28.0557 98.257C29.0918 99.7171 31.1132 100.062 32.5705 99.0283Z"
          fill="#519ACC"
        />
        <path
          d="M57.3057 42.7971C58.3597 44.2823 60.3677 44.5993 61.8205 43.5684C63.2732 42.5374 63.616 40.5077 62.5831 39.0522C61.5502 37.5966 59.5211 37.25 58.0684 38.2809C56.6156 39.3118 56.2729 41.3415 57.3057 42.7971Z"
          fill="#519ACC"
        />
        <path
          d="M61.5699 110.116C60.1172 111.147 59.4779 113.387 60.5108 114.843C61.5437 116.299 63.5728 116.645 65.0255 115.614C66.4783 114.583 67.1176 112.343 66.0847 110.888C65.0518 109.432 63.0227 109.085 61.5699 110.116Z"
          fill="#519ACC"
        />
        <path
          d="M67.4933 79.8305C66.4604 78.3749 64.4313 78.0283 62.9785 79.0592C61.5258 80.0901 61.183 82.1198 62.2159 83.5754C63.2488 85.0309 65.2779 85.3776 66.7306 84.3467C68.1834 83.3158 68.5261 81.286 67.4933 79.8305Z"
          fill="#519ACC"
        />
        <path
          d="M104.549 45.9873C106.006 44.9532 106.348 42.9312 105.312 41.4711C104.276 40.011 102.254 39.6657 100.797 40.6998C99.3396 41.7339 98.9981 43.7559 100.034 45.216C101.07 46.6761 103.092 47.0214 104.549 45.9873Z"
          fill="#519ACC"
        />
        <path
          d="M75.8732 24.7988C77.3305 23.7647 77.672 21.7427 76.6358 20.2826C75.5997 18.8225 73.5784 18.4772 72.1211 19.5113C70.6638 20.5455 70.3224 22.5674 71.3585 24.0275C72.3946 25.4876 74.4159 25.833 75.8732 24.7988Z"
          fill="#519ACC"
        />
        <path
          d="M97.2267 25.9932C98.684 24.959 99.0255 22.9371 97.9894 21.477C96.9533 20.0169 94.9319 19.6715 93.4746 20.7057C92.0173 21.7398 91.6759 23.7618 92.712 25.2219C93.7481 26.682 95.7694 27.0273 97.2267 25.9932Z"
          fill="#519ACC"
        />
        <path
          d="M27.8029 82.7607C29.2602 81.7266 29.6016 79.7046 28.5655 78.2445C27.5294 76.7844 25.5081 76.4391 24.0508 77.4732C22.5935 78.5074 22.252 80.5293 23.2881 81.9894C24.3243 83.4495 26.3456 83.7949 27.8029 82.7607Z"
          fill="#519ACC"
        />
        <path
          d="M35.0616 41.7902C36.1155 43.2755 38.1236 43.5924 39.5763 42.5615C41.0291 41.5306 41.3719 39.5009 40.339 38.0453C39.3061 36.5898 37.277 36.2431 35.8242 37.274C34.3715 38.3049 34.0287 40.3347 35.0616 41.7902Z"
          fill="#519ACC"
        />
        <path
          d="M8.92385 52.3873C7.47109 53.4182 7.12832 55.448 8.1612 56.9035C9.19407 58.3591 11.2232 58.7057 12.6759 57.6748C14.1287 56.6439 14.4715 54.6141 13.4386 53.1586C12.4057 51.7031 10.3766 51.3564 8.92385 52.3873Z"
          fill="#519ACC"
        />
        <path
          d="M85.316 43.8239C86.1461 43.2349 86.2628 42.0801 85.6937 41.2781C85.1035 40.4463 84 40.3361 83.1488 40.8955C82.3779 41.4425 81.5221 42.1838 80.6576 42.9759C80.41 42.1244 80.1834 41.3026 79.8851 40.4424C79.5531 39.472 78.5096 39.0066 77.5405 39.337C76.601 39.6464 76.1081 40.7108 76.4401 41.6811C76.7173 42.5116 76.9439 43.3334 77.1915 44.1849L73.6045 42.7107C72.6749 42.3431 71.6083 42.7873 71.2425 43.7168C70.8768 44.6463 71.3227 45.7144 72.2523 46.082L75.8394 47.5563C75.3986 48.003 74.996 48.378 74.6527 48.711C74.1527 49.1998 73.8094 49.5327 73.6822 49.6676C72.945 50.3248 72.879 51.4882 73.537 52.2271C74.174 52.9363 75.1972 53.0589 75.968 52.5119C76.0273 52.4698 76.0866 52.4277 76.1163 52.4067C76.2645 52.3015 76.6756 51.8758 77.2436 51.2941C77.6844 50.8473 78.2437 50.3164 78.871 49.6926C79.3287 51.1543 79.8668 52.6037 80.5657 54.0283C81.0162 54.9144 82.098 55.3081 83.0124 54.8378C83.1014 54.7747 83.1607 54.7326 83.2496 54.6695C83.9612 54.1646 84.2044 53.1881 83.8171 52.391C82.9371 50.6483 82.366 48.7757 81.8036 46.8523C83.0409 45.7063 84.2994 44.59 85.316 43.8239Z"
          fill="#519ACC"
        />
        <path
          d="M38.5755 55.286C38.992 54.3652 38.5461 53.297 37.6251 52.8787C36.7041 52.4603 35.6375 52.9046 35.221 53.8254C34.881 54.6026 34.5285 55.2994 34.1759 55.9962L34.0995 56.1397C33.8051 55.4107 33.4514 54.7238 32.9666 54.0406C32.3763 53.2089 31.1378 52.9712 30.4302 53.6073C29.6 54.1964 29.4326 55.3424 30.0017 56.1445C30.5709 56.9465 30.941 57.8451 31.2223 58.8068C30.5542 58.7448 29.8776 58.7337 29.2393 58.6507C28.2372 58.5578 27.3517 59.3202 27.2904 60.3017C27.1993 61.3042 27.9628 62.1916 28.9438 62.2548C29.7429 62.313 30.5928 62.3799 31.4215 62.417C31.0472 63.3973 30.6222 64.3688 30.2394 65.3998C29.8947 66.3589 30.3999 67.385 31.3295 67.7526C31.9211 67.9581 32.5344 67.8801 33.0088 67.5435C33.3053 67.3331 33.5385 67.0336 33.6492 66.6871C34.0875 65.4829 34.5296 64.4098 34.9803 63.2861C35.0653 63.0917 35.1207 62.9185 35.2057 62.7242C36.6518 62.8146 38.1189 62.9347 39.4846 63.0375C39.9201 63.0858 40.3602 62.9522 40.6863 62.7207C41.1014 62.4262 41.39 61.9534 41.4335 61.3866C41.5245 60.3841 40.7611 59.4966 39.759 59.4037C38.757 59.3109 37.6746 59.2304 36.6132 59.1796C36.8597 58.6474 37.1061 58.1152 37.3736 57.6127C37.7644 56.8441 38.1551 56.0756 38.5123 55.1969L38.5755 55.286Z"
          fill="#519ACC"
        />
        <path
          d="M53.9755 93.8881C54.7017 92.5242 55.3982 91.1814 56.2172 89.8856C56.7772 89.0415 56.5006 87.8979 55.6475 87.3867C54.7944 86.8755 53.6606 87.0995 53.1513 87.9522C52.6801 88.7332 52.2512 89.5735 51.8011 90.3842C51.6036 89.8544 51.427 89.3544 51.2552 88.6724C51.0036 87.6897 50.0365 87.0808 49.0549 87.3307C48.1029 87.5596 47.4664 88.5473 47.7179 89.53C48.0406 90.8642 48.4569 91.9534 48.941 92.9497C48.2605 92.8073 47.6518 92.7033 46.9501 92.5313C45.9738 92.2862 44.9829 92.9 44.7397 93.8765C44.4965 94.853 45.1118 95.8457 46.0881 96.0908C46.9926 96.2975 47.8253 96.4659 48.7088 96.6429C48.2666 97.7159 47.7527 98.7506 47.3612 99.8323C47.0166 100.791 47.5218 101.818 48.481 102.164C49.0726 102.37 49.6649 102.262 50.1392 101.925C50.4357 101.715 50.669 101.415 50.8093 101.048C51.2561 99.7929 51.7958 98.606 52.3144 97.3894C53.8065 97.6704 55.2776 97.9217 56.7487 98.173L57.2516 96.4317L57.3486 94.621C56.2326 94.4303 55.0658 94.231 53.9288 94.0106L53.9755 93.8881Z"
          fill="#519ACC"
        />
        <path
          d="M46.5785 60.2812C46.386 61.2664 46.9802 62.2293 47.9862 62.4534C82.7982 70.1757 98.0062 83.1892 104.591 92.7196C105.181 93.5513 106.297 93.7419 107.119 93.2036C107.919 92.6356 108.137 91.4982 107.568 90.6961C100.583 80.6014 84.7083 66.8996 48.7382 58.9273C47.7533 58.7329 46.792 59.3257 46.5699 60.332L46.5785 60.2812Z"
          fill="#519ACC"
        />
        <path
          d="M63.1603 98.2238C62.1661 98.3933 61.4664 99.2919 61.6376 100.287C61.8088 101.282 62.7377 101.963 63.7022 101.814C78.1909 99.6169 86.6378 102.6 91.1649 105.462C91.8026 105.858 92.6188 105.815 93.1821 105.415C93.36 105.289 93.5465 105.112 93.6611 104.896C94.2212 104.052 93.9446 102.909 93.0915 102.397C88.0282 99.157 78.7235 95.8446 63.1392 98.1941L63.1603 98.2238Z"
          fill="#519ACC"
        />
        <path
          d="M90.7124 51.7415C90.2031 52.5942 90.5093 53.7168 91.3624 54.228C101.689 60.2992 114.279 75.2149 117.323 83.1485C117.685 84.0978 118.728 84.5632 119.677 84.2031C119.816 84.1487 119.956 84.0942 120.074 84.0101C120.727 83.5472 121.033 82.6598 120.697 81.8714C117.03 72.3697 103.4 57.1197 93.1962 51.0955C92.3431 50.5843 91.2007 50.859 90.7124 51.7415Z"
          fill="#519ACC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1961_5515">
          <rect
            width="101.466"
            height="92.0437"
            fill="white"
            transform="translate(0 58.7197) rotate(-35.3601)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Confetti.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

Confetti.defaultProps = {
  height: "134",
  width: "137",
  className: "",
};

export default Confetti;
