import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

class TestPassStatsWidget extends React.PureComponent {
   constructor(props) {
   super(props);
   this.state = {labels: []}
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeWidget != 0 && prevProps.activeWidget != this.props.activeWidget) {
      this.makeLabels()
    } else {
      return false
    }
  }

  makeLabels = () => {
    var resultMap = { 1: ["Pass","#6eb782"], 0: ["Untested","#636466"], 2: ["Fail", "#ea6159"], 3: ["Blocked", "#ebc34c"], 4: ["Retest", "#519acc"], 5: ["N/A", "lavender"]}
    var stats = []
    var id = parseInt(this.props.activeWidget)
    var url = `/v1/projects/${this.props.projectId}/tally_tp/${id}.json`
   axios.get(url)
    .then((res) => {
      for (var i = 0; i < res.data.length; i++) {
         var dataPoint = res.data[i]
         var result = resultMap[dataPoint[0]]
        result.push(res.data[i][1])
        stats.push(result)
      }
      this.setState({labels: stats})
    })
    .catch((err) => {
      console.log(err)
    })
  }


  render() {
    return (
    <div className="chartText" style={{marginTop: "30px", height: "145px"}}>
      <p style={{textAlign: "left", fontWeight: 600, fontSize: "15pt", fontFamily: "Arial", padding: "15px 0px 25px"}}>Test Pass Stats</p>
      <ul style={{display: "inline-flex", alignContent: "flex-start", justifyContent: "space-between", width: "95%"}}>
      {this.state.labels.map((label, i) => (
        <li style={{flex: "0 0 auto", margin: "0 10px", fontSize: "17pt", minWidth: "88px", fontWeight: 500, padding: "10px 15px 10px 15px", textAlign: "left", boxShadow: "0px 2px 4px #00000033", color: `${label[1]}`}} key={i}>{label[2]}<span style={{display: "block", fontWeight: 500, fontSize:"11pt", color: "#b1b1b1"}}>{label[0]}</span></li>
        ))}
      </ul>
    </div>
    )
  }
}


export default TestPassStatsWidget

TestPassStatsWidget.propTypes = {
  activeWidget: PropTypes.number,
  projectId: PropTypes.number
};
