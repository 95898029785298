import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TestPassProgressWidget from "./test_pass_progress_widget.jsx";
import ErrorBoundary from "../../common/error_boundary.jsx";
import TestPassIcon from "../../icons/test_pass_icon";

class TestPassWidgetContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      testPasses: [],
      activeWidget: 0,
      loading: false,
      randomNum: 0,
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.getData();
  }

  onScroll(e) {
    const elem = e.target;
    if (elem && elem.clientHeight < elem.scrollHeight) {
      if (
        elem.clientHeight + elem.scrollTop === elem.scrollHeight &&
        elem.scrollTop != 0
      ) {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "0";
      } else if (elem.scrollHeight > 1) {
        elem.nextElementSibling.style.opacity = "1";
      }
      if (elem.scrollTop === 0 && elem.scrollHeight > 1) {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "1";
      }
      if (elem.scrollTop != 0) {
        elem.previousElementSibling.style.opacity = "1";
      }
    }
  }

  widgetHandler = (testPassId) => {
    this.setState({ activeWidget: testPassId });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading == false && prevState.loading == true) {
      this.randomNumber();
    }
    if (this.props.projectId != prevProps.projectId) {
      this.getData();
    }
  }

  randomNumber = () => {
    this.setState({ randomNum: Math.random() });
  };

  getData = () => {
    this.setState({ loading: true });
    const url = `/v1/projects/${this.props.projectId}/test_passes.json`;
    axios
      .get(url)
      .then((res) => {
        this.setState(
          {
            testPasses: res.data,
            activeWidget: res.data.length >= 1 ? res.data[0].id : null,
            loading: false,
          },
          () => {
            const elem = document.getElementById("tpw-scroll");
            if (elem) {
              elem.addEventListener("scroll", this.onScroll, { passive: true });
            }
          }
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentWillUnmount() {
    const elem = document.getElementById("tpw-scroll");
    if (elem) {
      elem.removeEventListener("scroll", this.onScroll, { passive: true });
    }
  }

  displayLoader() {
    return (
      <div style={{ height: "100px" }}>
        <div id="test-step-bugs-loader" />
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          borderRadius: "5px",
          boxShadow: " 0px 0px 3px 1px lightgrey",
          padding: "10px 30px 20px",
          flex: 1,
          height: "100%",
          margin: "5px",
        }}
      >
        <ErrorBoundary>
          {this.props.testPassCount > 0 && (
            <div
              className="base-flex-row"
              style={{ height: "70px", alignItems: "center" }}
            >
              <p className="performance-large-title">Test Pass Progress</p>
              <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          )}
          <div
            style={{
              height:
                this.state.testPasses.length === 0
                  ? "100%"
                  : "calc(100% - 60px)",
            }}
            id="tpWidgetContainer"
          >
            <div className="top-team-blur" />
            <div
              id="tpw-scroll"
              className="new-scrollbar"
              style={{
                height: "100%",
                width: this.props.width ? this.props.width : "",
                flexDirection: "column",
                padding: "0 1px",
                overflowY: "auto",
                transition: "width 0.5s ease 0s",
                willChange: "width",
                overflowX: "hidden",
                margin: "-30px 0 -30px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {this.state.testPasses
                .filter((p) => p.status === "active")
                .map((testPass) => (
                  <TestPassProgressWidget
                    key={testPass.id}
                    testPass={testPass}
                    currentUser={this.props.currentUser}
                  />
                ))}
              {this.state.loading &&
                this.props.testPassCount > 0 &&
                this.displayLoader()}
              {!this.state.loading && this.state.testPasses.length == 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      boxShadow: "0px 1px 2px 1px lightgray",
                      backgroundColor: "#519acc",
                      overflow: "hidden",
                      display: "flex",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TestPassIcon
                      stroke="#519acc"
                      fill="transparent"
                      width={44}
                      height={44}
                    />
                  </div>
                  <h2 style={{ margin: "18px 0px" }}>No Test Passes Found</h2>
                  <p
                    style={{
                      maxWidth: "420px",
                      textAlign: "center",
                      lineHeight: "22px",
                      marginBottom: "20px",
                    }}
                  >
                    Start completing test cases by creating your first Test Pass
                    using the button below.
                  </p>
                  <a
                    style={{
                      height: "48px",
                      lineHeight: "48px",
                      textAlign: "center",
                      width: "180px",
                    }}
                    href={`/projects/${this.props.projectId}/test_suites/`}
                    className="common-button-submit"
                  >
                    Navigate to Test Cases
                  </a>
                </div>
              )}
            </div>
            <div style={{ bottom: "0px" }} className="bottom-team-blur" />
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

export default TestPassWidgetContainer;

TestPassWidgetContainer.propTypes = {
  projectId: PropTypes.number,
  width: PropTypes.string,
  currentUser: PropTypes.object,
};
