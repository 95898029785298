import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CancelButton, SubmitButton } from "../../../common/modal_buttons";
import PublishAssessmentModal from "./PublishAssessmentModal";
import EditAssessmentDetailsModal from "../AssessmentDetails/EditAssessmentDetailsModal";
import { EditAssessmentContext } from "../EditAssessment";

const HeaderControls = ({ postEditAssessment }) => {
  const { assessment, setAssessment } = useContext(EditAssessmentContext);

  if (!assessment || !assessment.wiki_resource) return null;

  const isPublished = assessment.published;

  return (
    <Header>
      <InfoPanel>
        <ResourceTitle>{assessment.wiki_resource.title || ""}</ResourceTitle>
        <InfoButtons>
          <div>Level {assessment.level}</div>
          <div>
            {assessment.time_limit
              ? `Time Limit: ${
                  typeof assessment.time_limit === "string"
                    ? parseFloat(assessment.time_limit).toFixed(2)
                    : assessment.time_limit
                }`
              : "Untimed Assessment"}
          </div>
        </InfoButtons>
      </InfoPanel>
      <Controls>
        <EditAssessmentDetailsModal assessment={assessment}>
          Edit Assessment Details
        </EditAssessmentDetailsModal>
        {!isPublished && (
          <CancelButton type="submit">Save as Draft</CancelButton>
        )}
        {!isPublished && (
          <PublishAssessmentModal
            assessment={assessment}
            postEditAssessment={postEditAssessment}
            updateAssessmentData={setAssessment}
          />
        )}
        {isPublished && <SubmitButton type="submit">Save Changes</SubmitButton>}
      </Controls>
    </Header>
  );
};

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #c4c4c4;
`;

const InfoPanel = styled.div``;

const ResourceTitle = styled.h4`
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #242424;
`;

const InfoButtons = styled.div`
  display: flex;

  div {
    background-color: #d7d7d7;
    font-family: "Arial";
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 18px;
    border: none;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const Controls = styled.div`
  display: flex;
  gap: 15px;

  button {
    width: auto;
    min-width: 128px;
    padding: 0 24px;
    margin: 0;
  }
`;

HeaderControls.propTypes = {
  postEditAssessment: PropTypes.func.isRequired,
};

export default HeaderControls;
