import Email from "./Email";
import Pronoun from "./Pronoun";
import Calender from "./Calender";
import Team from "./Team";
import Location from "./Location";
import TextHistory from "./TextHistory";
import BackArrow from "./BackArrow";
import EmptyEOD from "./EmptyEOD";
import EmptyHours from "./EmptyHours";
import EmptyPie from "./EmptyPie";

const Icons = {
  EmailIcon: Email,
  PronounIcon: Pronoun,
  CalenderIcon: Calender,
  TeamIcon: Team,
  LocationIcon: Location,
  TextHistoryIcon: TextHistory,
  BackArrowIcon: BackArrow,
  EmptyEODIcon: EmptyEOD,
  EmptyHoursIcon: EmptyHours,
  EmptyPieIcon: EmptyPie,
};

export default Icons;
