import React, { useContext, useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IconContainer, Content } from "./TrialBody";
import MultipleChoiceOption from "./MultipleChoiceOption";
import {
  Headline,
  QuestionDescription,
  ResponsesContainer,
} from "./SingleAnswer";
import Icons from "../../icons";
import { TrialsContext } from "./index";
import { questionTypes } from "../../../types/wiki/trial";

const MultipleAnswer = (props) => {
  const { question } = props;
  const { assessment } = useContext(TrialsContext);

  const { register, setValue, getValues, reset } = useFormContext();

  const ID = useMemo(() => `${question.id}`, [question.id]);

  const optionsWithLabels = useMemo(() => {
    const LABELS = ["A", "B", "C", "D"];
    return question.answers.map((answer, idx) => {
      return {
        optionLabel: LABELS[idx],
        optionId: answer.id,
        optionText: answer.answer,
        answer,
      };
    });
  }, [question.answers]);

  const handleOptionSelect = (answer) => {
    const nextAnswers = getValues(ID);
    if (nextAnswers.includes(answer.id)) {
      setValue(
        ID,
        nextAnswers.filter((id) => id !== answer.id)
      );
    } else {
      setValue(ID, [...nextAnswers, answer.id]);
    }
  };

  useEffect(() => {
    reset();
    register(ID, {
      value: [],
      shouldUnregister: true,
      validate: {
        mutatedAnswer: (value) => {
          if (Array.isArray(value)) return true;
          console.error(value);
          return "Value is in an invalid format";
        },
        required: (value) =>
          value.length != 0 || "Please select a response before continuing.",
      },
    });

    if (question.previous_response) {
      setValue(ID, question.previous_response.selected_options);
    }
  }, [question]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Headline>
        <IconContainer>
          <Icons.BookIcon height="21px" width="29px" color="#ffffff" />
        </IconContainer>
        <h3>
          Test Your Knowledge on {assessment.resource.title} (Level{" "}
          {assessment.level})
        </h3>
      </Headline>
      <QuestionDescription>
        <h4>Question {question.question_number} - Multiple Answer</h4>
        <p>{question.description}</p>
      </QuestionDescription>
      <ResponsesContainer>
        {optionsWithLabels.map((o) => (
          <MultipleChoiceOption
            {...o}
            questionId={ID}
            isSelected={getValues(ID)?.includes(o.answer.id) || false}
            key={o.optionId}
            onSelect={handleOptionSelect}
          />
        ))}
      </ResponsesContainer>
    </Content>
  );
};

MultipleAnswer.propTypes = {
  question: questionTypes.isRequired,
};

export default MultipleAnswer;
