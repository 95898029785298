import React from "react";

class ImageEmptyIcon extends React.Component {
  render() {
    return (
      <svg
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        stroke={this.props.stroke}
        style={this.props.svgStyles}
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 64 64"
        className="icons"
      >
        <circle fill="#00100f" isolation="isolate" opacity="0.2" cx="32" cy="33.01" r="28.35"/>
        <circle fill="#a7a9ac" cx="32" cy="30.99" r="28.35"/>
        <path fill="#fff" isolation="isolate" opacity="0.2" d="M32,2.64A28.35,28.35,0,0,0,3.65,31c0,.39,0,.79.05,1.18a28.32,28.32,0,0,1,56.6-.34c0-.28,0-.56.05-.84A28.35,28.35,0,0,0,32,2.64Z"/>
        <path fill="#fdfeff" d="M46.74,14.77H18a2.06,2.06,0,0,0-2,2.05V45.6a2.06,2.06,0,0,0,2,2H46.74a2.06,2.06,0,0,0,2-2V16.82A2.06,2.06,0,0,0,46.74,14.77ZM26.12,21.09a2.6,2.6,0,1,1-2.48,2.61A2.55,2.55,0,0,1,26.12,21.09Zm20.62,22a1,1,0,0,1-1,1H19a1.21,1.21,0,0,1-.73-.29l7.21-7.22a1.06,1.06,0,0,1,1.46,0s.86.86,1.92,2,1.82,1.09,2.91,0,6-6.06,6-6.06a1,1,0,0,1,1.45,0L46.74,40Z"/>
      </svg>
    );
  }
}

ImageEmptyIcon.defaultProps = {
  height: "50px",
  width: "50px",
  svgStyles: {},
}

export default ImageEmptyIcon