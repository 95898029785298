import React from "react";
import TestCase from "./test_case.jsx";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
class TestSuiteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.loading,
      testCases: this.props.testSuite.test_cases,
      name: this.props.testSuite.name,
      collapsed: true,
      stepDragging: false,
      draggingId: "",
      draggingHeight: "0px",
      testCaseSuggestions:
        this.props.testSuite.test_cases != undefined
          ? this.props.testSuite.test_cases.map((testCase) => testCase.name)
          : [],
      testStepSuggestions: [],
      testStepDetailSuggestions: [],
    };
    this.dragTimer = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.testSuite.id != this.props.testSuite.id) {
      var suggestions =
        this.props.testSuite &&
        this.props.testSuite.test_cases &&
        this.props.testSuite.test_cases.length >= 1
          ? this.props.testSuite.test_cases.map((testCase) => testCase.name)
          : [];
      this.setState({
        testStepSuggestions: suggestions,
      });
      var elem = document.getElementById("tc-form-list");
      if (elem && elem.scrollHeight > elem.clientHeight) {
        elem.nextElementSibling.style.opacity = "1";
      } else if (elem) {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "0";
      }
    }
  }

  getData() {
    this.props.setLoading(true);
    var projectId = this.props.testSuite.project.id;
    $.ajax({
      url: "/test_suites/" + this.props.testSuite.id,
      dataType: "json",
      cache: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      success: function (data) {
        var testSuite = data.testSuite;
        window.history.pushState(
          "page2",
          "Test Platform",
          "/projects/" + projectId + "/test_suites/" + data.testSuite.id
        );
        this.props.setActiveSuite(testSuite);
      }.bind(this),
      error: function (status, err) {
        console.error("/test_suites/" + this.props.testSuite.id, status, err);
      }.bind(this),
    });
  }

  handleResize() {
    console.log("resized");
  }

  checkHeight() {
    var elem = document.getElementById("tc-form-list");
    if (elem) {
      if (elem.clientHeight == elem.scrollHeight) {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "0";
      } else if (elem.scrollTop === elem.scrollHeight - elem.clientHeight) {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "0";
      } else if (elem.scrollTop === 0) {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "1";
      } else {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "1";
      }
    }
  }

  componentDidMount() {
    if (this.props.testSuite && this.props.testSuite.test_cases === undefined) {
      this.getData(this.props.testSuite);
    } else {
      // console.log("gotcha");
    }
    var elem = document.getElementById("tc-form-list");
    if (elem) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (elem.clientHeight == elem.scrollHeight) {
          elem.previousElementSibling.style.opacity = "0";
          elem.nextElementSibling.style.opacity = "0";
        } else if (elem.scrollTop === elem.scrollHeight - elem.clientHeight) {
          elem.previousElementSibling.style.opacity = "1";
          elem.nextElementSibling.style.opacity = "0";
        } else if (elem.scrollTop === 0) {
          elem.previousElementSibling.style.opacity = "0";
          elem.nextElementSibling.style.opacity = "1";
        } else {
          elem.previousElementSibling.style.opacity = "1";
          elem.nextElementSibling.style.opacity = "1";
        }
      });
      var elems = document.getElementsByClassName("test-case-drag-container");
      if (elems && Array.isArray(elems)) {
        elems.forEach((element) => {
          resizeObserver.observe(element);
        });
      }
    }
    if (elem && elem.scrollHeight > elem.clientHeight) {
      elem.nextElementSibling.style.opacity = "1";
    }
    var main = document.getElementById("main");
    if (main) {
      main.addEventListener("dragover", this.handleAddDragOver);
    }
  }

  expandAll = () => {
    this.props.testSuite.test_cases.forEach((tc) => {
      if (this.refs[`case-${tc.id}`]) {
        this.refs[`case-${tc.id}`].parentOpen(true);
      }
    });
    this.checkHeight();
  };

  collapseAll = () => {
    this.props.testSuite.test_cases.forEach((tc) => {
      if (this.refs[`case-${tc.id}`]) {
        this.refs[`case-${tc.id}`].parentOpen(false);
      }
    });
    this.checkHeight();
  };

  handleCaseDelete = (e) => {
    this.props.setLoading(true);
    // this.setState({loading: true})
    var id = e.target.dataset.tcId;
    var self = this;
    var url = "/test_cases/" + id + ".json";
    Swal.fire({
      title: "Delete Test Section",
      text: "Are you sure that you want to delete this Test Section?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
      //ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: url,
          type: "DELETE",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          dataType: "json",
          cache: false,
          success: function (data) {
            var casePromise = new Promise((resolve, reject) => {
              self.props.casePositionHandler(data, resolve);
            });
            casePromise.then((message) => {
              self.props.setLoading(false);
            });
          }.bind(this),
          error: function (status, err) {
            M.toast({
              html: `There was an error`,
              displayLength: 3000,
              classes: "red",
            });
            self.props.setLoading(false);
            console.error(url, status, err);
          }.bind(this),
        });
      } else if (result.dismiss) {
        self.props.setLoading(false);
      }
    });
  };

  handleCaseAdd = (position, templateDrop) => {
    var templateDrop = templateDrop;
    var self = this;
    self.props.setLoading(true);
    var data;
    if (self.props.template) {
      data = {
        test_case: position
          ? {
              position: position,
              test_section_template_id: self.props.template,
            }
          : { test_section_template_id: self.props.template },
      };
    } else {
      data = { test_case: { name: "" } };
    }
    var id = self.props.testSuite.id;
    var url = "/test_suites/" + id + "/test_cases.json";
    $.ajax({
      url: url,
      type: "POST",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        if (self.props.template) {
          var casePromise = new Promise((resolve, reject) => {
            self.props.casePositionHandler(data, resolve);
          });
          casePromise.then((message) => {
            if (templateDrop) {
              var elem = document.getElementById("tc-form-list");
              if (elem) {
                elem.scrollTop = elem.scrollHeight;
              }
            }
            self.props.setLoading(false);
          });
        } else {
          var casePromise = new Promise((resolve, reject) => {
            self.props.caseHandler(data, resolve);
          });
          casePromise.then((message) => {
            self.props.setLoading(false);
            var edit = document.getElementById(
              `test-section-edit-button-${data.id}`
            );
            var expand = document.getElementById(
              `test-section-expand-button-${data.id}`
            );
            if (edit && expand) {
              expand.click();
              edit.click();
            }
          });
        }
      }.bind(this),
      error: function (err) {
        M.toast({
          html: `There was an error`,
          displayLength: 3000,
          classes: "red",
        });
        self.props.setLoading(false);
        console.error(url, err);
      }.bind(this),
    });
  };

  handleChangeCasePosition = (id, position) => {
    this.props.setLoading(true);
    var data = { test_case: { id: id, position: position } };
    var self = this;
    var url = "/update_test_case_position";
    $.ajax({
      url: url,
      type: "PATCH",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        self.props.casePositionHandler(data);
        self.props.setLoading(false);
      }.bind(this),
      error: function (status, err) {
        M.toast({
          html: `There was an error`,
          displayLength: 3000,
          classes: "red",
        });
        self.props.setLoading(false);
        console.error(url, status, err);
      }.bind(this),
    });
  };

  handleChangeStepPosition = (stepId, position) => {
    this.props.setLoading(true);
    var data = { test_step: { id: stepId, position: position } };
    var self = this;
    var url = "/test_steps/" + stepId + ".json";
    $.ajax({
      url: url,
      type: "PATCH",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        this.refs[`case-${data.id}`].updateSteps(data);
        self.props.setLoading(false);
      }.bind(this),
      error: function (status, err) {
        M.toast({
          html: `There was an error`,
          displayLength: 3000,
          classes: "red",
        });
        self.props.setLoading(false);
        console.error(url, status, err);
      }.bind(this),
    });
  };

  handleCopyStep = (caseId, position, text, details) => {
    this.props.setLoading(true);
    var data = {
      test_step: { text: text, details: details, position: position },
    };
    var self = this;
    var url = "/test_cases/" + caseId + "/test_steps" + ".json";
    $.ajax({
      url: url,
      type: "POST",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        self.refs[`case-${data.id}`].updateSteps(data);
        self.props.setLoading(false);
      },
      error: function (error) {
        M.toast({
          html: `There was an error`,
          displayLength: 3000,
          classes: "red",
        });
        self.props.setLoading(false);
        reject(error);
      },
    });
  };

  handleCopyCase = (id, position) => {
    this.props.setLoading(true);
    var testCase = this.props.testSuite.test_cases.find(
      (tc) => tc.id === parseInt(id)
    );
    var testSteps = testCase.test_steps.map((ts) => {
      return { text: ts.text, details: ts.details };
    });
    var data = {
      test_case: {
        name: testCase.name,
        position: position,
        test_steps:
          testSteps.length > 0 ? testSteps : [{ text: false, details: false }],
      },
    };
    var self = this;
    var id = this.props.testSuite.id;
    var url = "/test_suites/" + id + "/test_cases.json";
    $.ajax({
      url: url,
      type: "POST",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        self.props.casePositionHandler(data);
        self.props.setLoading(false);
      }.bind(this),
      error: function (status, err) {
        M.toast({
          html: `There was an error`,
          displayLength: 3000,
          classes: "red",
        });
        self.props.setLoading(false);
        console.error(url, status, err);
      }.bind(this),
    });
  };

  handleMoveStepToCase = (
    stepId,
    position,
    newCaseId,
    previousCaseId,
    text,
    details
  ) => {
    this.props.setLoading(true);
    var data = {};
    if (position) {
      data = {
        test_step: { text: text, details: details, position: position },
      };
    } else {
      data = { test_step: { text: text, details: details } };
    }
    var self = this;
    var url = "/test_cases/" + newCaseId + "/test_steps" + ".json";
    $.ajax({
      url: url,
      type: "POST",
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        self.refs[`case-${data.id}`].updateSteps(data);
        var url =
          "/test_cases/" + previousCaseId + "/test_steps/" + stepId + ".json";
        $.ajax({
          url: url,
          type: "DELETE",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          dataType: "json",
          cache: false,
          success: function (data) {
            self.refs[`case-${data.id}`].updateSteps(data);
            self.props.setLoading(false);
          },
          error: function (status, err) {
            M.toast({
              html: `There was an error`,
              displayLength: 3000,
              classes: "red",
            });
            self.props.setLoading(false);
            console.error(url, status, err);
          },
        });
      },
      error: function (error) {
        reject(error);
      },
    });
  };

  onDragStart = (e) => {
    window.addEventListener("dragover", this.handleDragScroll);
    if (e.target.dataset.type === "case") {
      var id = e.target.dataset.tcid;
      var position = e.target.dataset.tcposition;
      setTimeout(() => {
        this.props.setCaseDragging(true);
        this.setState({
          draggingId: `${id}-${position}-drag-area`,
          draggingHeight: `${
            document.getElementById(`${id}-${position}-header`).clientHeight
          }px`,
        });
      }, 200);
      var dragCopy = document.getElementById(`${id}-${position}-header`);
      dragCopy.style.maxHeight = dragCopy.clientHeight;
      var headingRect = document
        .getElementById(`${id}-${position}-header`)
        .getBoundingClientRect();
      var iconRect = document
        .getElementById(`${id}-${position}-icon`)
        .getBoundingClientRect();
      e.dataTransfer.setData("text", "anything");
      e.dataTransfer.setDragImage(
        dragCopy,
        iconRect.left - headingRect.left + 12,
        12
      );
    } else if (e.target.dataset.type === "step") {
      var id = e.target.dataset.tsid;
      var position = e.target.dataset.tsposition;
      setTimeout(() => {
        this.setState({
          stepDragging: true,
          draggingId: `${id}-${position}-step-drag-area`,
          draggingHeight: `60px`,
        });
      }, 200);
      // var dragCopy = document.getElementById(`${id}-${position}-step-header`)
      var dragCopy = document.getElementById(`tc-title-input-${id}`);
      dragCopy.style.maxHeight = dragCopy.clientHeight;
      dragCopy.style.backgroundColor = "white";
      var headingRect = document
        .getElementById(`${id}-${position}-step-heading`)
        .getBoundingClientRect();
      var iconRect = document
        .getElementById(`${id}-${position}-step-grab`)
        .getBoundingClientRect();
      e.dataTransfer.setData("text", "anything");
      e.dataTransfer.setDragImage(
        dragCopy,
        iconRect.left - headingRect.left + 12,
        12
      );
    }
  };

  handleDragScroll = (e) => {
    if (window.innerHeight - e.clientY < 120) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          window.scrollBy(0, 5);
        }, 10);
      }
    } else if (window.innerHeight - (window.innerHeight - e.clientY) < 140) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          window.scrollBy(0, -5);
        }, 10);
      }
    } else {
      if (this.dragTimer) {
        clearInterval(this.dragTimer);
        this.dragTimer = null;
      }
    }
  };

  onDragEnd = (e) => {
    if (this.dragTimer) {
      clearInterval(this.dragTimer);
      this.dragTimer = null;
    }
    window.removeEventListener("dragover", this.handleDragScroll);
    if (e.target.dataset.type === "case") {
      var id = e.target.dataset.tcid;
      var position = e.target.dataset.tcposition;
      var elem = document.getElementById(`${id}-${position}`);
      this.props.setCaseDragging(false);
      this.setState({
        draggingId: "",
      });
    } else if (e.target.dataset.type === "step") {
      var id = e.target.dataset.tsid;
      var position = e.target.dataset.tsposition;
      var elem = document.getElementById(`${id}-${position}-step`);
      elem.style.opacity = "1";
      this.setState({
        stepDragging: false,
        draggingId: "",
      });
    }
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragEnter = (e) => {
    if (
      this.state.draggingId != e.target.id &&
      e.target.dataset.type === "case"
    ) {
      var dragginIdAndPosition = this.state.draggingId.split("-");
      var id = e.target.dataset.tcid;
      var position = e.target.dataset.tcposition;
      var dragElem = document.getElementById(
        `${dragginIdAndPosition[0]}-${dragginIdAndPosition[1]}`
      );
      if (dragElem && !(e.target.id === "template-drag-hover")) {
        dragElem.style.opacity = "0.3";
        if (position > parseInt(dragginIdAndPosition[1])) {
          var elem = document.getElementById(
            `${id}-${position}-drag-hover-bottom`
          );
          elem.style.height = this.state.draggingHeight;
        } else {
          var elem = document.getElementById(
            `${id}-${position}-drag-hover-top`
          );
          elem.style.height = this.state.draggingHeight;
        }
      } else if (!dragElem && !this.state.stepDragging) {
        if (e.target.id === "template-drag-hover") {
          var elem = document.getElementById("template-drag-hover-top");
          elem.style.height = "30px";
        } else {
          var elem = document.getElementById(
            `${id}-${position}-drag-hover-top`
          );
          elem.style.height = "30px";
        }
      }
    } else if (e.target.dataset.type === "case") {
      var id = e.target.dataset.tcid;
      var position = e.target.dataset.tcposition;
      var elem = document.getElementById(`${id}-${position}`);
      elem.style.opacity = "1";
    } else if (
      this.state.draggingId != e.target.id &&
      e.target.dataset.type === "step"
    ) {
      var dragginIdAndPosition = this.state.draggingId.split("-");
      var id = e.target.dataset.tsid; //undefined if new test step
      var caseId = e.target.dataset.tcid;
      var position = e.target.dataset.tsposition; //undefined if new test step
      var dragElem = document.getElementById(
        `${dragginIdAndPosition[0]}-${dragginIdAndPosition[1]}-step`
      );
      if (dragElem) {
        dragElem.style.opacity = "0.3";
      }
      if (
        dragElem &&
        dragElem.dataset &&
        dragElem.dataset.tcid === caseId &&
        !e.target.id.includes("new-step")
      ) {
        if (position > parseInt(dragginIdAndPosition[1])) {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-bottom`
          );
          if (elem) {
            elem.style.height = this.state.draggingHeight;
          }
        } else {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-top`
          );
          if (elem) {
            elem.style.height = this.state.draggingHeight;
          }
        }
      } else if (
        dragElem &&
        dragElem.dataset &&
        dragElem.dataset.tcid !== caseId
      ) {
        if (e.target.id.includes("new-step")) {
          var elem = document.getElementById(
            `${caseId}-new-step-drag-hover-top`
          );
          if (elem) {
            elem.style.height = this.state.draggingHeight;
          }
        } else {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-top`
          );
          if (elem) {
            elem.style.height = this.state.draggingHeight;
          }
        }
      }
    } else if (e.target.dataset.type === "step") {
      var id = e.target.dataset.tsid;
      var position = e.target.dataset.tsposition;
      var elem = document.getElementById(`${id}-${position}-step`);
      elem.style.opacity = "1";
    }
  };

  onDragLeave = (e) => {
    if (
      this.state.draggingId !== e.target.id &&
      e.target.dataset.type === "case"
    ) {
      var id = e.target.dataset.tcid;
      var position = e.target.dataset.tcposition;
      var dragginIdAndPosition = this.state.draggingId.split("-");
      if (e.target.id === "template-drag-hover") {
        var elem = document.getElementById("template-drag-hover-top");
        elem.style.height = "0px";
      } else if (parseInt(position) > parseInt(dragginIdAndPosition[1])) {
        var elem = document.getElementById(
          `${id}-${position}-drag-hover-bottom`
        );
        elem.style.height = "0px";
      } else {
        var elem = document.getElementById(`${id}-${position}-drag-hover-top`);
        elem.style.height = "0px";
      }
    } else if (
      this.state.draggingId !== e.target.id &&
      e.target.dataset.type === "step"
    ) {
      var id = e.target.dataset.tsid; //undefined if new test step
      var caseId = e.target.dataset.tcid;
      var position = e.target.dataset.tsposition; //undefined if new test step
      var dragginIdAndPosition = this.state.draggingId.split("-");
      var dragElem = document.getElementById(this.state.draggingId);
      if (
        dragElem &&
        dragElem.dataset &&
        dragElem.dataset.tcid === caseId &&
        !e.target.id.includes("new-step")
      ) {
        if (parseInt(position) > parseInt(dragginIdAndPosition[1])) {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-bottom`
          );
          elem.style.height = "0px";
        } else {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-top`
          );
          elem.style.height = "0px";
        }
      } else if (
        dragElem &&
        dragElem.dataset &&
        dragElem.dataset.tcid !== caseId
      ) {
        if (e.target.id.includes("new-step")) {
          var elem = document.getElementById(
            `${caseId}-new-step-drag-hover-top`
          );
          elem.style.height = "0px";
        } else {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-top`
          );
          elem.style.height = "0px";
        }
      }
    }
  };

  onDragDrop = (e) => {
    e.preventDefault();
    if (
      this.state.draggingId !== e.target.id &&
      e.target.dataset.type === "case"
    ) {
      var id = e.target.dataset.tcid;
      var position = e.target.dataset.tcposition;
      var dragginIdAndPosition = this.state.draggingId.split("-");
      var copyModifier = 0;
      if (this.props.template) {
        if (e.target.id === "template-drag-hover") {
          var elem = document.getElementById("template-drag-hover-top");
          elem.style.height = "0px";
          this.handleCaseAdd(false, true);

          return;
        } else {
          var elem = document.getElementById(
            `${id}-${position}-drag-hover-top`
          );
          elem.style.height = "0px";
          this.handleCaseAdd(parseInt(position));
          return;
        }
      } else if (e.target.id === "template-drag-hover") {
        var elem = document.getElementById("template-drag-hover-top");
        elem.style.height = "0px";
        return;
      } else if (parseInt(position) > parseInt(dragginIdAndPosition[1])) {
        var elem = document.getElementById(
          `${id}-${position}-drag-hover-bottom`
        );
        elem.style.height = "0px";
        copyModifier = 1;
      } else {
        var elem = document.getElementById(`${id}-${position}-drag-hover-top`);
        elem.style.height = "0px";
      }
      if (e.altKey) {
        this.handleCopyCase(
          dragginIdAndPosition[0],
          parseInt(position) + copyModifier
        );
        var dragElem = document.getElementById(
          `${dragginIdAndPosition[0]}-${dragginIdAndPosition[1]}`
        );
        dragElem.style.opacity = "1";
      } else {
        this.handleChangeCasePosition(
          dragginIdAndPosition[0],
          parseInt(position)
        );
      }
    } else if (
      this.state.draggingId !== e.target.id &&
      e.target.dataset.type === "step"
    ) {
      var id = e.target.dataset.tsid; //undefined if new test step
      var caseId = e.target.dataset.tcid;
      var position = e.target.dataset.tsposition; //undefined if new test step
      var dragginIdAndPosition = this.state.draggingId.split("-");
      var dragElem = document.getElementById(this.state.draggingId);
      var copyModifier = 0;
      if (
        dragElem &&
        dragElem.dataset &&
        dragElem.dataset.tcid === caseId &&
        !e.target.id.includes("new-step")
      ) {
        if (parseInt(position) > parseInt(dragginIdAndPosition[1])) {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-bottom`
          );
          elem.style.height = "0px";
          copyModifier = 1;
        } else {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-top`
          );
          elem.style.height = "0px";
        }
        if (e.altKey) {
          this.handleCopyStep(
            caseId,
            parseInt(position) + copyModifier,
            dragElem.dataset.text,
            dragElem.dataset.details
          );
        } else {
          this.handleChangeStepPosition(
            dragginIdAndPosition[0],
            parseInt(position)
          );
        }
      } else if (
        dragElem &&
        dragElem.dataset &&
        dragElem.dataset.tcid !== caseId
      ) {
        //move step to different test case
        if (e.target.id.includes("new-step")) {
          var elem = document.getElementById(
            `${caseId}-new-step-drag-hover-top`
          );
          elem.style.height = "0px";
        } else {
          var elem = document.getElementById(
            `${id}-${position}-step-drag-hover-top`
          );
          elem.style.height = "0px";
        }
        if (e.altKey) {
          this.handleCopyStep(
            caseId,
            parseInt(position),
            dragElem.dataset.text,
            dragElem.dataset.details
          );
        } else {
          this.handleMoveStepToCase(
            dragginIdAndPosition[0],
            parseInt(position),
            caseId,
            dragElem.dataset.tcid,
            dragElem.dataset.text,
            dragElem.dataset.details
          );
        }
      }
    }
  };

  handleAddDragOver = (e) => {
    if (
      e.target.id == "template-drag-hover" ||
      e.target.id.includes("drag-area")
    ) {
      e.dataTransfer.dropEffect = "copy";
    } else {
      e.dataTransfer.dropEffect = "none";
    }
  };

  handleSuiteInputBlur = (e) => {
    var text = e.target.value;
    if (text !== this.props.testSuite.name) {
      var data = { test_suite: { name: text ? text.trim() : "" } };
      var self = this;
      $.ajax({
        url: "/test_suites/" + this.props.testSuite.id,
        type: "PATCH",
        data: data,
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        dataType: "json",
        cache: false,
        success: function (data) {
          this.props.suiteHandler(data);
        }.bind(this),
        error: function (status, err) {
          console.error("/test_suites/" + this.props.testSuite.id, status, err);
        }.bind(this),
      });
    }
  };

  handleSuiteInput = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  hideFocus = (e) => {
    if (!e.target.classList.contains("hide-focus")) {
      e.target.classList.add("hide-focus");
    }
  };

  showFocus = (e) => {
    if (e.target.classList.contains("hide-focus")) {
      e.target.classList.remove("hide-focus");
    }
    e.target.style.boxShadow = "none";
  };

  newSectionFocus = (e) => {
    if (!e.target.classList.contains("hide-focus")) {
      e.target.style.boxShadow = "0px 0px 3px blue";
    }
  };

  expandKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.expandAll();
    }
  };

  collapseKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.collapseAll();
    }
  };

  suggestLogic(oldSuggestions, newSuggestion) {
    const limit = 10;
    if (oldSuggestions.length < limit) {
      oldSuggestions.push(newSuggestion);
    } else {
      oldSuggestions[0] = newSuggestion;
    }
    return oldSuggestions;
  }

  suggestionHandler = (field, suggestion) => {
    switch (field) {
      case "testCase":
        var suggestions = this.suggestLogic(
          this.state.testCaseSuggestions,
          suggestion
        );
        this.setState({ testCaseSuggestions: suggestions });
        break;
      case "testStep":
        var suggestions = this.suggestLogic(
          this.state.testStepSuggestions,
          suggestion
        );
        this.setState({ testStepSuggestions: suggestions });
        break;
      case "testStepDetail":
        var suggestions = this.suggestLogic(
          this.state.testStepDetailSuggestions,
          suggestion
        );
        this.setState({ testStepDetailSuggestions: suggestions });
        break;
    }
  };

  onScroll(e) {
    var elem = e.target;
    if (elem && elem.previousElementSibling && elem.nextElementSibling) {
      if (elem.scrollTop === elem.scrollHeight - elem.clientHeight) {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "0";
      } else if (elem.scrollTop === 0) {
        elem.previousElementSibling.style.opacity = "0";
        elem.nextElementSibling.style.opacity = "1";
      } else {
        elem.previousElementSibling.style.opacity = "1";
        elem.nextElementSibling.style.opacity = "1";
      }
    }
  }

  render() {
    return (
      <div id="test-suite-form">
        <h2 className="small-title" style={{ marginBottom: "14px" }}>
          Edit Test Directory
        </h2>
        <span style={{ fontWeight: "600", display: "block", fontSize: "9pt" }}>
          Directory Name
        </span>
        <input
          aria-label="Suite Title Name Input"
          id="active-suite-title-input"
          maxLength={40}
          onKeyDown={this.handleSuiteInput}
          onBlur={this.handleSuiteInputBlur}
          defaultValue={this.props.testSuite.name}
          style={{ width: "370px", marginRight: "20px", marginTop: "0px" }}
          type="text"
          className="common-input browser-default"
        />
        <button
          tabIndex={0}
          onMouseDown={this.hideFocus}
          onFocus={this.newSectionFocus}
          onBlur={this.showFocus}
          className="common-button-delete"
          style={{
            display: "block",
            position: "absolute",
            right: "180px",
            top: "72px",
            fontWeight: "500",
            width: "133px",
          }}
          data-tsid={this.props.testSuite.id}
          onClick={this.props.deleteHandler}
        >
          Delete Directory
        </button>
        <button
          tabIndex={0}
          onMouseDown={this.hideFocus}
          onFocus={this.newSectionFocus}
          onBlur={this.showFocus}
          className="common-button-submit"
          style={{
            display: "block",
            position: "absolute",
            right: "27px",
            top: "72px",
            fontWeight: "500",
            width: "133px",
          }}
          onClick={this.props.createTestPass}
        >
          Create Test Pass
        </button>
        <div
          style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "end",
            margin: "4px 310px 0px 0px",
          }}
        >
          <button
            tabIndex={0}
            onMouseDown={this.hideFocus}
            onFocus={this.newSectionFocus}
            onBlur={this.showFocus}
            onKeyDown={this.expandKeyDown}
            className="calm-button span-to-button"
            style={{ fontWeight: "600" }}
            onClick={this.expandAll}
          >
            Expand All
          </button>
          <button
            tabIndex={0}
            onMouseDown={this.hideFocus}
            onFocus={this.newSectionFocus}
            onBlur={this.showFocus}
            onKeyDown={this.collapseKeyDown}
            className="calm-button span-to-button"
            style={{ fontWeight: "600", paddingLeft: "10px" }}
            onClick={this.collapseAll}
          >
            Collapse All
          </button>
        </div>
        <div
          style={{ left: 0, marginBottom: "-30px", width: "calc(100% - 18px)" }}
          className="top-tb-blur"
        ></div>
        <ul
          id="tc-form-list"
          onScroll={this.onScroll}
          className="new-scrollbar"
          style={{ padding: "15px 0px 0px" }}
        >
          {this.props.testSuite.test_cases != undefined &&
            this.props.testSuite.test_cases.length >= 1 &&
            this.props.testSuite.test_cases.map((testCase, i) => {
              return (
                <TestCase
                  suggestionHandler={this.suggestionHandler}
                  suggestions={{
                    testCases: this.state.testCaseSuggestions,
                    testSteps: this.state.testStepSuggestions,
                    testStepDetails: this.state.testStepDetailSuggestions,
                  }}
                  setLoading={this.props.setLoading}
                  handleTestSectionTemplates={
                    this.props.handleTestSectionTemplates
                  }
                  key={testCase.id}
                  ref={`case-${testCase.id}`}
                  testCase={testCase}
                  position={testCase.position}
                  caseHandler={this.props.caseHandler}
                  oneSection={this.props.testSuite.test_cases.length === 1}
                  handleCaseDelete={this.handleCaseDelete}
                  caseDragging={this.props.caseDragging}
                  stepDragging={this.state.stepDragging}
                  testSuite={this.props.testSuite}
                  draggingId={this.state.draggingId}
                  onDragStart={this.onDragStart}
                  onDragDrop={this.onDragDrop}
                  onDragEnter={this.onDragEnter}
                  onDragOver={this.onDragOver}
                  onDragEnd={this.onDragEnd}
                  onDragLeave={this.onDragLeave}
                  i={i}
                />
              );
            })}
        </ul>
        <div
          style={{ left: 0, width: "calc(100% - 18px)" }}
          className="bottom-tb-blur"
        ></div>
        <div
          id={`template-drag-hover`}
          data-type="case"
          onDrop={this.onDragDrop}
          onDragOver={this.onDragOver}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          <div
            id={`template-drag-hover-top`}
            className="test-case-drag-hover"
            style={{
              height: "0px",
              backgroundColor: "rgba(211, 211, 211, 0.555)",
              pointerEvents: "none",
            }}
          />
          <button
            tabIndex={0}
            onDragOver={this.handleAddDragOver}
            onMouseDown={this.hideFocus}
            onFocus={this.newSectionFocus}
            onBlur={this.showFocus}
            className="common-button-submit"
            style={{
              padding: "0px 3px",
              zIndex: "0",
              fontWeight: "500",
              width: "150px",
              marginTop: "20px",
            }}
            onClick={this.handleCaseAdd}
          >
            <span
              onDragOver={this.handleAddDragOver}
              className="material-icons"
              style={{
                verticalAlign: "top",
                fontSize: "17px",
                marginRight: 5 + "px",
              }}
            >
              add
            </span>
            New Test Section
          </button>
        </div>
      </div>
    );
  }
}

export default TestSuiteForm;

TestSuiteForm.propTypes = {
  position: PropTypes.number,
  testStep: PropTypes.object,
  testCase: PropTypes.object,
  testSuite: PropTypes.object,
  id: PropTypes.string,
  projectId: PropTypes.number,
  pathName: PropTypes.string,
  stepDragging: PropTypes.bool,
  y: PropTypes.number,
  i: PropTypes.number,
  handleCaseDelete: PropTypes.func,
  handleOutsideStepClick: PropTypes.func,
  onStepDragStart: PropTypes.func,
  onStepDragEnd: PropTypes.func,
  onStepDragDrop: PropTypes.func,
  onStepDragLeave: PropTypes.func,
  onStepDragEnter: PropTypes.func,
  setCaseDragging: PropTypes.func,
  onStepDragOver: PropTypes.func,
  setLoading: PropTypes.func,
  createTestPass: PropTypes.func,
  template: PropTypes.bool,
  handleTestSectionTemplates: PropTypes.func,
  caseHandler: PropTypes.func,
  oneSection: PropTypes.bool,
  handleStepDelete: PropTypes.func,
  caseDragging: PropTypes.bool,
};
