import React from "react";
import PropTypes from "prop-types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import PriorityCircle from "./PriorityCircle";

const TaskColumn = ({ droppableId, tickets, columnId, currentTicket }) => {
  const sortTitle = (a, b) =>
    a.value.title.toLowerCase().localeCompare(b.value.title.toLowerCase());

  const sortPriority = (a, b) =>
    a.value.ticket_priority_id - b.value.ticket_priority_id;

  return (
    <ColumnContainer
      key={columnId}
      id={columnId}
      className="task-column center"
    >
      <Droppable droppableId={String(droppableId)}>
        {(provided, snapshot) => (
          <div
            style={{ minHeight: "calc(100% - 20px)" }}
            data-dragging-from-this={snapshot.draggingFromThisWith}
            data-dragging={snapshot.isDraggingOver}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tickets
              .sort(sortTitle)
              .sort(sortPriority)
              .map((ticket, index) => (
                <Draggable
                  draggableId={String(ticket.value.id)}
                  index={index}
                  key={`drag${ticket.value.id}`}
                >
                  {(provided) => (
                    <DraggableTaskCard
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`column-card item ${
                        currentTicket === ticket.value.id
                          ? "active-task-card"
                          : ""
                      }`}
                    >
                      <TaskCard>
                        <CardHeader>
                          <CardTitle>{ticket.value.title}</CardTitle>
                          <CardMeta>
                            <PriorityCircle
                              priority={ticket.value.ticket_priority_id}
                            />
                          </CardMeta>
                        </CardHeader>
                        <CardDetails>
                          <ProjectInfo>
                            {ticket.value.project.identifier}-
                            {ticket.value.app_key}
                          </ProjectInfo>
                          <CardAssignee>
                            {ticket.value.tester_name}
                          </CardAssignee>
                        </CardDetails>
                      </TaskCard>
                    </DraggableTaskCard>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </ColumnContainer>
  );
};

TaskColumn.propTypes = {
  droppableId: PropTypes.number.isRequired,
  tickets: PropTypes.array.isRequired,
  columnId: PropTypes.string.isRequired,
  currentTicket: PropTypes.number,
};

const ColumnContainer = styled.div`
  max-height: 73vh;
`;

const TaskCard = styled.div`
  position: relative;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: grab; /* Enable drag cursor */
  max-height: 220px;
  min-height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.active-task-card {
    border: 2px solid #4caf50; /* Green border for active task */
  }
`;

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  max-width: calc(100% - 35px);
`;

const CardMeta = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  height: 30px;
`;

const CardDetails = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ProjectInfo = styled.div`
  // font-size: 12px;
  max-width: 33%;
  max-height: 14px;
  font-size: 14px;
  line-height: 14px;
`;

const CardAssignee = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  color: #9e9e9e;
  max-height: 14px;
  line-height: 14px;
  max-width: 66%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DraggableTaskCard = styled.div`
  user-select: none;
  margin: 8px;
`;

export default TaskColumn;
