import React from 'react'
import EarnIcon from '../icons/profile/earn_icon'
import RedeemIcon from '../icons/profile/redeem_icon'

class ProfileProgress extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      badges:this.props.badges.map((b)=>b.indentity)
    }
    this.pointBadges =[
      ["Bronze", "points_bronze", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/TP-Badges/UP_Bronze_Badge_2022.svg"],
      ["Silver", "points_silver", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/TP-Badges/UP_Silver_Badge_2022.svg"],
      ["Gold", "points_gold", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/TP-Badges/UP_Gold_Badge_2022.svg"],
      ["Platinum", "points_platinum", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/TP-Badges/UP_Plat_Badge_2022.svg"],
    ]
  }

  render(){
    var {points, lastQuarterPoints, kudos, tessCoins, currentUser, name} = this.props
    var {badges} = this.state
    var currentLevel = points > 500 ? Math.floor((points-250)/250) : 0
    
    return(
      <section>
        <div className="progress-badges">
          {this.pointBadges.map((b,i)=>{
            return (
              <div key={b[1]}>
                <div aria-hidden="true">
                  <img src={currentLevel>=i+1?b[2]:"https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/TP-Badges/Points_Base.svg"}/>
                </div>
                <p aria-label={`${b[0]} level, ${500+(250*i)} points. ${currentLevel>=i+1?"Level reached":"Level not reached yet"}`}><span aria-hidden="true">{b[0]}</span></p>
                <p aria-hidden="true">{(500+(250*i)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              </div>
            )
          })}
        </div>
        <div className="user-experience-content">
          <div>
            <div style={{padding:"4px 4px 6px"}}>
              <p style={{fontSize:"13px", lineHeight:"22px"}} className="small-title">POINTS THIS QUARTER</p>
              <p style={{fontSize:"24px", letterSpacing:"1px"}} className="large-title">{points}</p>
            </div>
            <div style={{padding:"4px 4px 6px"}}>
              <p style={{fontSize:"13px", lineHeight:"22px"}} className="small-title">POINTS LAST QUARTER</p>
              <p style={{fontSize:"24px", letterSpacing:"1px"}} className="large-title">{lastQuarterPoints}</p>
            </div>
          </div>
        </div>
        <div className="progress-kudos-coins">
          <div>
            <span aria-label={`${currentUser?"You have":`${name} has`} ${kudos} Kudos`}>
              <span aria-hidden="true">{kudos}</span>
            </span>
            <img aria-hidden="true" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/Icon_Handshake.svg"/>
            <span aria-label={`${currentUser?"You have":`${name} has`} ${tessCoins} Tess Coins`} >
              <span aria-hidden="true">{tessCoins}</span>
            </span>
            <img aria-hidden="true" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/Icon_Tess_Coin.svg" />
          </div>
          <p>{currentLevel < 4 ? 
            `${currentUser?"You are":`${name} is`} ${(500+(currentLevel*250))-points} pts away from reaching ${this.pointBadges[currentLevel][0]} level!` :
            `${currentUser?"You have":`${name} has`} reached the top points level for this quarter!`}
          </p>
        </div>
        <div className="how-it-works-container">
          <h3>How it works</h3>
          <div>
            <div aria-hidden="true"><EarnIcon svgStyles={{width:"40px", height:"25px"}} /></div>
            <p>Earn points by using Test Platform and participating in activities at PLUS QA.</p>
          </div>
          <div>
            <div aria-hidden="true"><img style={{width:"40px", height:"30px"}} src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/TP_Profile/Icon_Badge_Outline.svg" /></div>
            <p>Every time you earn, your user lever increases, badges can be obtained, and Tess Coins are awarded.</p>
          </div>
          <div>
            <div aria-hidden="true"><RedeemIcon svgStyles={{width:"40px", height:"26px"}} /></div>
            <p>Tess Coins can be redeemed at PLUS QA for unique prizes and giveaways.</p>
          </div>
        </div>
      </section>
    )
  }
}

export default ProfileProgress