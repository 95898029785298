import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import EarnedBadges from "../EarnedBadges";
import UnearnedBadges from "./UnearnedBadges";
import AssessmentResults from "../AssessmentResults";
import { trialTypes } from "../../../types/wiki";

const [EARNED, NOT_EARNED, ASSESSMENTS] = [
  "earned",
  "notEarned",
  "assessments",
];

const UserBadges = (props) => {
  const { earnedBadges, notEarnedBadges, trials, isCurrentUser } = props;
  const [currentTab, setCurrentTab] = useState(EARNED);

  const handleTabClick = (e) => {
    setCurrentTab(e.target.dataset.tab);
  };

  return (
    <UserBadgesContainer id="user-badges-container">
      <ProfileNav className="profile-nav" role="navigation">
        <button
          aria-label="Earned Badges"
          aria-pressed={currentTab === EARNED}
          onClick={handleTabClick}
          data-tab={EARNED}
          className={currentTab === EARNED ? "active" : ""}
        >
          Earned
        </button>
        <button
          aria-label="Not Earned Badges"
          aria-pressed={currentTab === NOT_EARNED}
          onClick={handleTabClick}
          data-tab={NOT_EARNED}
          className={currentTab === NOT_EARNED ? "active" : ""}
        >
          Not Earned
        </button>
        <button
          aria-label="Assessment Results"
          aria-pressed={currentTab === ASSESSMENTS}
          onClick={handleTabClick}
          data-tab={ASSESSMENTS}
          className={currentTab === ASSESSMENTS ? "active" : ""}
        >
          Assessments
        </button>
      </ProfileNav>
      <Scrollbar className="new-scrollbar">
        {currentTab === EARNED && (
          <EarnedBadges
            earnedBadges={earnedBadges}
            currentUser={isCurrentUser}
          />
        )}
        {currentTab === NOT_EARNED && (
          <UnearnedBadges unearnedBadges={notEarnedBadges} />
        )}
        {currentTab === ASSESSMENTS && <AssessmentResults trials={trials} />}
      </Scrollbar>
    </UserBadgesContainer>
  );
};

const UserBadgesContainer = styled.div`
  padding-top: 2px;
`;

const ProfileNav = styled.div`
  box-shadow: none;
  margin-bottom: 24px;
  display: flex;
  gap: 18px;

  button {
    text-transform: uppercase;
    font-size: 15px;
  }
`;

const Scrollbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2px 0px 10px 10px;
  height: calc(100% - 86px);
  overflow-y: auto;
`;

const BadgeTypes = PropTypes.shape({
  created_at: PropTypes.string,
  details: PropTypes.string,
  id: PropTypes.number,
  identity: PropTypes.string,
  name: PropTypes.string,
  time_dependent: PropTypes.bool,
  url: PropTypes.string,
});

UserBadges.propTypes = {
  earnedBadges: PropTypes.arrayOf(BadgeTypes),
  notEarnedBadges: PropTypes.arrayOf(BadgeTypes),
  isCurrentUser: PropTypes.bool,
  trials: PropTypes.arrayOf(trialTypes),
};

export default UserBadges;
