import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

class TestPassLink extends React.Component {

  constructor(props) {
		super(props);
		this.passLabelMouseEnter=this.passLabelMouseEnter.bind(this)
		this.passLabelMouseLeave=this.passLabelMouseLeave.bind(this)
  }

	passLabelMouseEnter(e){
    // if(e.target.scrollWidth > e.target.clientWidth){
      // var rect = e.target.getBoundingClientRect()
      var rect = document.getElementById(`pass-${e.target.dataset.passId}-link-container`).getBoundingClientRect()
      // var popUpElem = <div style={{position:"fixed", top:"0", zIndex:"10", left:`${rect.left}px`}}>e.target.id</div>
			var popUpElem = document.createElement('div')
      popUpElem.setAttribute("id", `${e.target.dataset.passId}-pop-up`)
      popUpElem.setAttribute("class", `test-pass-pop-up`)
      popUpElem.style.position = "fixed"
      popUpElem.style.top = `${rect.top}px`
      popUpElem.style.zIndex = "1000"
      popUpElem.style.width = "130px"
      popUpElem.style.height = "28px"
      popUpElem.style.fontSize = "10pt"
      popUpElem.style.backgroundColor = "#000000b8"
      popUpElem.style.color = "white"
			popUpElem.style.textAlign = "center"
			popUpElem.style.paddingLeft = "5px"
			popUpElem.style.paddingRight = "5px"
      popUpElem.innerHTML = `${e.target.dataset.tooltip}`
			var element = document.querySelector("body")
      element.appendChild(popUpElem)
      //need to set left after element is appended to parent element
      popUpElem.style.left = `${rect.right}px`
    // }
  }

  passLabelMouseLeave(e){
    // if(e.target.scrollWidth > e.target.clientWidth){
      var popUpElem = document.getElementById(`${e.target.dataset.passId}-pop-up`)
      if(popUpElem){
        var element = document.querySelector("body")
        element.removeChild(popUpElem)
      }
    // }
  }

  render() {
    var color = this.props.activeTestPass && this.props.activeTestPass.id === this.props.testPass.id ? "#519ACC":"initial"
    return (
      <div style={{display: "flex"}}>
				<div style={{ background: this.props.percent < 100 ? `linear-gradient(transparent ${100-this.props.percent}%, rgb(0,210,0) ${100-this.props.percent}%)` : 'rgb(0,210,0)'}} id="tiny-dot"></div>
				<p style={{color: color}}>{this.props.percent}%</p>
				<div className="test-pass-link-tooltipz" style={{display:"inline-block", height:"28px"}}>
          <a 
            data-tooltip={`Created ${moment(this.props.testPass.created_at).format("l")}`} 
            data-pass-id={this.props.testPass.id} 
            onMouseEnter={this.passLabelMouseEnter} 
            onMouseLeave={this.passLabelMouseLeave} 
            style={{flex: 4, fontSize:this.props.measureText(this.props.testPass.name), cursor:"pointer", paddingLeft:"4px", whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis", color: color}} 
            onClick={this.props.setPass} 
            value={this.props.testPass.id}>{this.props.testPass.name}</a>
				</div>
				{(this.props.currentUser.role === "admin" || this.props.currentUser.role === "lead") && 
          <i style={{fontSize:"19px", cursor:"pointer", lineHeight:"28px", marginLeft:"10px", color:"white", display:"none"}} data-pass-id={this.props.testPass.id} data-suite-id={this.props.testSuiteId} data-pass-name={this.props.testPass.name} onClick={this.props.copyTestPass} className="material-icons">content_copy</i>
        }
      </div>
    )
  }
};

export default TestPassLink
