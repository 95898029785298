import React from "react";

const BuildsFilled = (props) => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 64 64" {...props}>
      {" "}
      <path
        d="M32.46 27l25.25-11.6a.52.52 0 00-.06-1L32.4 3.78a.51.51 0 00-.3 0L6.35 14.91a.53.53 0 000 1L32.05 27a.49.49 0 00.41 0zm2.45 4.3L58 20.81l-.07 25.76a3 3 0 01-1.55 2.59l-21.5 11.4zm-5.32.03l-24-10.46v25.35a3.55 3.55 0 001.89 3.15l22.11 11.19z"
        fill="none"
        stroke="#519acc"
        strokeMiterlimit={10}
        strokeWidth={3}
      />{" "}
    </svg>
  );
};

export default BuildsFilled;
