import React from "react";

const MonthSelectedFilled = (props) => {
  return (
    <svg
      id="Icons_This_Month_-_Outline_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {".Icons_This_Month_-_Outline_svg__cls-1{fill:#519acc}"}
        </style>{" "}
      </defs>{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={21.94}
        y={28.23}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={30.09}
        y={28.23}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={38.27}
        y={28.23}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={46.45}
        y={28.23}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={21.94}
        y={35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={14.27}
        y={35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={30.09}
        y={35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={21.94}
        y={41.78}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={14.27}
        y={41.78}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={30.09}
        y={41.78}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={38.27}
        y={35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_This_Month_-_Outline_svg__cls-1"
        x={46.45}
        y={35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <path
        className="Icons_This_Month_-_Outline_svg__cls-1"
        d="M13.05 50.74H51A3.76 3.76 0 0054.71 47V17A3.76 3.76 0 0051 13.26H13.05A3.77 3.77 0 009.29 17v30a3.77 3.77 0 003.76 3.74zm37.9-2h-37.9A1.76 1.76 0 0111.29 47V25h41.42v22A1.76 1.76 0 0151 48.74zm-37.9-33.48H51A1.76 1.76 0 0152.71 17v6H11.29v-6a1.76 1.76 0 011.76-1.74z"
      />{" "}
    </svg>
  );
};

export default MonthSelectedFilled;
