import React from "react";

const ExpertBase = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <circle
        cx={540}
        cy={540}
        r={418.69}
        fill="#e8e8e8"
        data-name="Layer 2"
      />{" "}
    </svg>
  );
};

export default ExpertBase;
