import React, { useState, useReducer, useEffect } from "react";
import axios from "axios";
import HelpSection from "./help_section";
import CheckboxMenu from "../common/checkbox_menu.jsx";

const HelpTopic = (props) => {
  const [dragging, setDragging] = useState(false);
  const [editingSection, setEditingSection] = useState(false);
  const [sections, setSections] = useState(props.topic.help_sections);
  const [selectedRoles, setSelectedRoles] = useState(
    props.topic.user_permissions
  );

  function handleDragStart(id, position) {
    setDragging({ id, position });
  }

  function handleDragEnd(e) {
    setDragging(false);
  }

  function handleDragDrop(id, position) {
    if (!props.loading && dragging) {
      axios
        .patch(`/help_sections/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          help_section: { position },
        })
        .then((res) => {
          setSections(
            sections
              .map((s) => ({ ...s, ...res.data[s.id.toString()] }))
              .sort((a, b) => a.position - b.position)
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
        });
    }
  }

  function handleUpdateSection(section) {
    var section = section;
    setSections(sections.map((s) => (s.id !== section.id ? s : section)));
    setEditingSection(false);
  }

  function handleEditSection(id) {
    if (editingSection === "new") {
      setSections(sections.slice(0, sections.length - 1));
      setEditingSection(id);
    } else {
      setEditingSection(id);
    }
  }

  function handleCreateSection(section) {
    setSections([...sections.slice(0, sections.length - 1), section]);
    setEditingSection(false);
  }

  function handleAddSection() {
    setSections([
      ...sections,
      {
        help_topic_id: props.topic.id,
        id: "new",
        section_type: "text",
        style: {},
        image_style: {},
        position: sections.length + 1,
        content: "",
      },
    ]);
    setEditingSection("new");
  }

  function handleAddImage() {
    setSections([
      ...sections,
      {
        help_topic_id: props.topic.id,
        id: "new",
        section_type: "image",
        style: {},
        image_style: {},
        position: sections.length + 1,
        content: "",
      },
    ]);
    setEditingSection("new");
  }

  function handleDeleteSection(id) {
    setSections([...sections].filter((s) => s.id !== id));
  }

  function handleUsePagePermissionsCheck() {
    setSelectedRoles(props.selectedRoles);
  }

  function handleFeaturedTopicModal() {
    props.setModal(true, "featured-topic", {
      topic: props.topic,
      edit: props.topic.featured,
      handler: props.handleUpdateTopic,
      permanentFeaturedTopicsCount: props.permanentFeaturedTopicsCount,
      customClass: "kudos-modal",
    });
  }

  function handleRoleChange(e) {
    const index = selectedRoles.indexOf(e.target.value);
    const newRoles =
      index === -1
        ? [...selectedRoles, e.target.value]
        : [...selectedRoles.slice(0, index), ...selectedRoles.slice(index + 1)];
    setSelectedRoles(newRoles);
    axios
      .patch(`/help_topics/${props.topic.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        help_topic: { user_permissions: newRoles },
      })
      .then((res) => {})
      .catch((err) => {});
  }
  const roleOptions = [...props.selectedRoles, "admin"];
  return (
    <div className="help-topic">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3
          id={`topic-${props.topic.id}`}
          style={{ textAlign: "left", fontSize: "18px", marginBottom: "10px" }}
        >
          {props.topic.title}
        </h3>
        {props.editing && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {selectedRoles.length === 0 ? (
              <label style={{ height: "25px" }}>
                <input
                  onChange={handleUsePagePermissionsCheck}
                  disabled={props.selectedRoles.length === 0}
                  className="filled-in"
                  checked
                  value={1}
                  type="checkbox"
                />
                <span
                  style={{
                    whiteSpace: "nowrap",
                    height: "100%",
                    width: "100%",
                    paddingLeft: "30px",
                    textOverflow: "ellipsis",
                    lineHeight: "22px",
                    overflow: "hidden",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Use Page Permission Settings
                </span>
              </label>
            ) : (
              <CheckboxMenu
                maxWidth="100%"
                key={1}
                count={selectedRoles.length + 1}
                active
                options={roleOptions.map((r, i) => {
                  if (r === "admin") {
                    return {
                      label: "Admin",
                      value: "admin",
                      handler: false,
                      checked: true,
                    };
                  }
                  if (r.split(" ").length > 1) {
                    return {
                      label: `${r} Permission`,
                      value: r,
                      handler: handleRoleChange,
                      checked: selectedRoles.indexOf(r) !== -1,
                    };
                  }
                  return {
                    label: r[0].toUpperCase() + r.slice(1),
                    value: r,
                    handler: handleRoleChange,
                    checked: selectedRoles.indexOf(r) !== -1,
                  };
                })}
                name="filters"
                title="Visible To"
                // clearFunction={this.clearFilters}
                id="1"
                containerMinWidth="160px"
                menuStyles={{
                  maxWidth: "255px",
                  width: "255px",
                  right: "-44px",
                }}
                narrow
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />
            )}
            <button
              onClick={handleFeaturedTopicModal}
              style={{
                height: "48px",
                marginLeft: "20px",
                width: props.topic.featured ? "150px" : "130px",
              }}
              className="common-button-submit"
            >
              {props.topic.featured ? "Edit Featured Topic" : "Set As Featured"}
            </button>
          </div>
        )}
      </div>
      {sections.map((s) => {
        return (
          <HelpSection
            dragging={dragging}
            editingSection={editingSection}
            editMode={props.editing}
            handleCreateSection={handleCreateSection}
            handleDeleteSection={handleDeleteSection}
            handleDragDrop={handleDragDrop}
            handleDragEnd={handleDragEnd}
            handleDragStart={handleDragStart}
            handleEditSection={handleEditSection}
            handleSetLoading={props.handleSetLoading}
            handleUpdateSection={handleUpdateSection}
            key={s.id}
            loading={props.loading}
            section={s}
            sectionsLength={sections.length}
          />
        );
      })}
      {props.editing && (
        <div>
          <button
            style={{ marginTop: "30px" }}
            disabled={editingSection}
            onClick={handleAddSection}
            className="common-button-submit"
          >
            Add Text
          </button>
          <button
            style={{ marginTop: "30px" }}
            disabled={editingSection}
            onClick={handleAddImage}
            className="common-button-submit"
          >
            Add Image
          </button>
        </div>
      )}
    </div>
  );
};

export default HelpTopic;
