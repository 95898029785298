import React from "react";

const TestPassesFilled = (props) => {
  return (
    <svg
      id="IconsTestPassBlue_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".IconsTestPassBlue_svg__cls-1{fill:none;stroke:#519acc;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <path
        className="IconsTestPassBlue_svg__cls-1"
        d="M12.87 52.51a3 3 0 002.84 3.17h32.58a3 3 0 002.84-3.17v-41a3 3 0 00-2.84-3.17H12.87z"
      />{" "}
      <path
        className="IconsTestPassBlue_svg__cls-1"
        d="M18.61 17.21l2.6 2.8 4.85-4.95m3.56 2.98H45.4M18.61 31.33l2.6 2.8 4.85-4.95m3.56 3.26H45.4M28.99 45.77h15.78m-25.54 2.47l4.94-4.93m-4.94 0l4.94 4.93"
      />{" "}
    </svg>
  );
};

export default TestPassesFilled;
