import React from 'react'
import axios from 'axios'
import ReactGA from 'react-ga'
import Swal from "sweetalert2";
import Select, {components} from 'react-select'
import customStyles from '../common/custom_styles'


ReactGA.initialize(`${process.env.G_ANALYTICS_TAG}`, {
  debug: !process.env.NODE_ENV == "production",
  titleCase: false
}); 
class TestStepA11y extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        wcags: [],
        loading: true,
        selectedOptionA11y: [],
        wcagOptions: this.props.data.a11yOptions
    }
    this.labelOption = (props) => {
      return (
        <div role="text" aria-label={`Selected violation ${props.data.label}`} className="ticket-label-option" style={{ position: "relative" }}>
          <components.MultiValueLabel {...props} />
        </div>
      );
    };
  }

  componentDidMount(){ 
    document.getElementById("test-step-bugs-container-title").focus()
		var elem = document.querySelector(".new-modal-open")
		elem.style.top = "5%"
    axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
    if(this.props.data.existingWcags){
        axios.get(`/get_test_step_a11ies/${this.props.data.testStepId}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            var options = this.state.wcagOptions
            options.sort((a,b) =>{
              if(a.label.toLowerCase() < b.label.toLowerCase()){
               return -1
              } else if(a.label.toLowerCase() > b.label.toLowerCase()){
               return 1
              } else {
                return 0
              }
            } )
            if(res.data.length > 0){
              var wcagIds = res.data.map((wcag)=>wcag.a11y_id )
              
              options = options.filter((option)=>!wcagIds.includes(option.value))
              this.setState({
                wcags: res.data,
                loading: false,
                wcagOptions: options,
                selectedOptionA11y: []
             })
            } else {
              this.setState({
                wcags: res.data,
                loading: false,
                selectedOptionA11y: []
             })
            }
        })
        .catch((err) => console.error(err))
    }
    
	}
	
	componentWillUnmount(){
		var elem = document.querySelector(".new-modal-open")
    elem.style.top = "12%"
    var previousFocusElem = document.querySelector(this.props.data.currentResultSelector)
    if(previousFocusElem){previousFocusElem.focus()}
	}

  handleAddWcag = (e) => {
		this.refs.bug_add_button.disabled = true
    axios.post(`/add_test_step_a11y`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        a11y_id: this.state.selectedOptionA11y.map(x=>x.value),
				test_step_id: this.props.data.testStepId,
				test_pass_id: this.props.data.testPassId
      })
    .then((res) => {
				M.toast({html: `<p role="alert">WCAG added</p>`, displayLength: 3000, classes: 'green' });
        this.props.modalAction(false, "", {})
        ReactGA.event({
          category: 'Test Step',
          action: 'Test Step WCAG Link Created'
        });
    })
    .catch((err) => { 
      ReactGA.event({
        category: 'Test Step',
        action: 'Test Step WCAG Link Error'
      });
      console.error(err)
    })
	}

	handleDeleteWcag = (e) => {
		var id = e.target.dataset.id
		Swal.fire({
			title: "Delete WCAG",
      text: "Are you sure that you want to remove this WCAG?",
			reverseButtons: true,
			showCancelButton:true,
      confirmButtonAriaLabel: 'Yes',
      cancelButtonAriaLabel: 'cancel',
			confirmButtonText: "Yes",
		}).then((result) => {
      if (result.value) {
			axios.delete(`/delete_test_step_a11y/${id}`, {
				headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
				},
				data: {
					test_step_id: this.props.data.testStepId,
					test_pass_id: this.props.data.testPassId
				}
			})
			.then((res) => {
          M.toast({html: `WCAG removed`, displayLength: 3000, classes: 'green' });
          var newOptions = JSON.parse(JSON.stringify(this.state.wcagOptions))
          newOptions.push({value: res.data.a11y_id, label: res.data.violation})
          newOptions.sort((a,b) =>{
            if(a.label.toLowerCase() < b.label.toLowerCase()){
             return -1
            } else if(a.label.toLowerCase() > b.label.toLowerCase()){
             return 1
            } else {
              return 0
            }
          } )
					var updatedWcags = this.state.wcags
					updatedWcags = updatedWcags.filter((wcag) => wcag.id !== res.data.id)
					this.setState({wcags: updatedWcags, wcagOptions: newOptions})
          ReactGA.event({
            category: 'Test Step',
            action: 'Test Step WCAG Link Deleted'
          });
			}, )
      .catch((err) => console.error(err))
      } else if (result.dismiss) {

      }
		})
	}
	
	closeModal = () => {
		this.props.modalAction(false, "", {})
  }
  
  handleWcagChange = (e) => {
    var e = e ? e : []
    this.setState({
      selectedOptionA11y: e
    })
  }

  render() {
    return (

    <div role="dialog" aria-label="Test Case A11y Violations Modal" className="modal-content">
			<div id="test-step-bugs-container">
			{this.props.data.existingWcags &&
				<div id="test-step-existing-bugs-container" style={{minHeight:"80px"}}>
				<h2 style={{outline:"none"}} tabIndex={0} id="test-step-bugs-container-title"><span role="text">TC {this.props.data.testCaseNumber} Linked WCAGs</span></h2>
				{this.state.loading ?
				<div id="test-step-bugs-loader" style={{width:"30px", height:"30px", border:"3px solid #f3f3f3", borderTop:"3px solid #0097ff"}}></div> :
				<div>
					{this.state.wcags.map((wcag, index) => {
					return (
						<div key={index} className="test-step-single-bug-container" style={{marginBottom:"4px"}}>
							<span className="test-step-bug-title">
								{wcag.violation}
								<i role="button" className="material-icons test-step-comment-delete-icon" aria-label={`remove WCAG ${wcag.violation.split(" ")[0]}`} style={{opacity:"0"}} onClick={this.handleDeleteWcag} data-id={wcag.id}>delete</i>
							</span>
						</div>
					)
					})}
				</div>
				}
				</div>
			}
			<div id="test-step-new-bug-container">
				<h2 aria-label="Link WCAG violations. Select one or more violations from dropdown then select Add button below to submit." style={{outline:"none"}} tabIndex={0} id="test-step-bugs-container-title">Link WCAG</h2>
				<div id="a11y-container" className="ticket-form-select-container" style={{width:"50%"}}>
          <Select
          aria-label="Type to filter list. Use arrow keys to navigate and enter to select"
          name="A11y"
          classNamePrefix={"select"}
          value={this.state.selectedOptionA11y}
          styles={customStyles}
          onChange={this.handleWcagChange}
          options={this.state.wcagOptions}
          required
          isMulti={true}
          closeMenuOnSelect={false}
          components={{ MultiValueLabel: this.labelOption}}
          />
        </div>
        <button
          ref="bug_add_button"
          aria-label={`Add ${this.state.selectedOptionA11y.length} selected WCAG`}
          disabled={this.state.selectedOptionA11y.length === 0}
          style={{ marginTop: "20px" }}
          className="add-test-step-bug-button btn"
          onClick={this.handleAddWcag}
        >
          Add WCAG
        </button>
      </div>
    </div>
    <button aria-label="close violation modal" className="icon-close-btn" style={{ display:"block", position:"absolute", top:"10px", right:"15px" }} onClick={this.closeModal} ></button>
  </div>
  );
}

}
export default TestStepA11y