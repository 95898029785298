import React, { useMemo, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import useDeviceLab from "@hooks/data/useDeviceLab";

const ResetFiltersButton = () => {
  const { filters, PARAM_KEYS } = useDeviceLab();

  const { query } = filters;

  const reset = useCallback(() => {
    window.history.replaceState({}, "", "?");
  }, []);

  const isFiltersActive = useMemo(() => {
    return Object.keys(query).some((key) => {
      return Object.values(PARAM_KEYS).includes(key);
    });
  }, [query, PARAM_KEYS]);

  return (
    <CSSTransition
      in={isFiltersActive}
      unmountOnExit
      appear
      timeout={{ enter: 200, exit: 500 }}
      classNames="scale-in"
    >
      <button
        type="button"
        style={{
          flex: 0.2,
          whiteSpace: "pre",
          alignSelf: "center",
          color: "#519acc",
          cursor: "pointer",
        }}
        onClick={reset}
        className="btn link-btn scale-in"
      >
        Clear All Filters
      </button>
    </CSSTransition>
  );
};

export default ResetFiltersButton;
