import React from "react";
import TrialistDirectory from "./trialist_directory";
import ReactModalV2 from "../modals/react_modal_v2";
import EodReport from "../modals/eod_report.jsx";

const modals = {
  "eod-report": EodReport,
};

class TrialistContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentModal: "",
      modalData: {},
    };
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  handleEodModal = () => {
    this.setModal(true, "eod-report", {
      currentUserEmail: this.props.current_user.email,
      currentUserId: this.props.current_user.id,
      currentProject: {
        value: this.props.project.id,
        label: this.props.project.name,
      },
      customClass: "eod-report-modal",
      backdropClickCheck: {
        title: "Close",
        text: "Close Report Modal?",
      },
    });
  };

  render() {
    return (
      <div style={{ overflow: "hidden" }}>
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <span
          role="heading"
          style={{
            display: "block",
            backgroundColor: "white",
            position: "relative",
            maxWidth: `calc(100% - ${100}px)`,
            marginLeft: "auto",
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            padding: "20px 0px 20px 20px",
            zIndex: "10",
            marginTop: "-5px",
            fontSize: "16pt",
            borderBottom: "solid 1px lightgray",
            marginBottom: "27px",
            fontWeight: "600",
            color: "#242424",
          }}
        >
          <img
            style={{
              display: "inline-block",
              height: "50px",
              marginRight: "10px",
              verticalAlign: "-16px",
            }}
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Admin_Panel_Filled.svg"
          />
          Tester Trial
          <button
            className="btn btn-overflow"
            style={{
              fontSize: "16px",
              display: "block",
              position: "absolute",
              right: "62px",
              top: "26px",
              color: "white",
              cursor: "pointer",
              width: "145px",
            }}
            onClick={this.handleEodModal}
          >
            <img
              alt="report icon"
              style={{
                width: "15px",
                height: "15px",
                marginRight: "5px",
                verticalAlign: "text-top",
              }}
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Generate_Report.svg"
            />
            EOD Report
          </button>
        </span>
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            whiteSpace: "nowrap",
            marginLeft: "auto",
          }}
        >
          <TrialistDirectory
            editMode={false}
            pages={this.props.trialist_pages}
            showPage={this.props.current_page}
          />
        </div>
      </div>
    );
  }
}

export default TrialistContainer;
