import React, { lazy, Suspense } from "react";
import Swal from "sweetalert2";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import Sidebar from "react-sidebar";
import { DragDropContext } from "react-beautiful-dnd";
import { AutoSizer, Column, Table } from "react-virtualized";
import * as Sentry from "@sentry/react";
import WithCable from "@/channels/WithCable";
import { userTypes, projectTypes } from "../../types";
import { ticketTypes, ticketIndexTypes } from "../../types/ticket";
import ExportLinks from "./export_links";
import TicketForm from "./ticket_form";
import TaskColumn from "./task_column";
import ErrorBoundary from "../common/error_boundary";
import SubNavbar from "../common/sub_navbar";
import CheckboxMenu from "../common/checkbox_menu";
import ReactModalV2 from "../modals/react_modal_v2";
import TicketTableHeader from "./ticket_table_header";
import QRCodeIcon from "../icons/qr_code_icon";
import QRCode from "../modals/qr_code";
import InformationModal from "../modals/information_modal";
import NewBadge from "../modals/new_badge";
import NewTicket from "../modals/new_ticket";
import GroupModal from "../modals/group_modal";
import CloneModal from "../modals/clone_modal";
import TicketAttachments from "../modals/ticket_attachments";
import "react-virtualized/styles.css";

const Ticket = lazy(() => import("./ticket"));

const modals = {
  "new-badge": NewBadge,
  "information-modal": InformationModal,
  "new-ticket": NewTicket,
  "qr-code": QRCode,
  "group-modal": GroupModal,
  "clone-modal": CloneModal,
  "ticket-attachments": TicketAttachments,
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://662459c60a3a405daa0c3aa17f38ee25@sentry.io/2023356",
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        if (event.exception.values.some((x) => x.handled === false)) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            title: "It looks like we dozed off and there was an error.",
            subtitle: "The Dev team has been notified.",
          });
        }
      }
      return event;
    },
  });
} else {
  Sentry.init({
    dsn: "https://3c2ed8a8f50148e582c6050933c50c31@sentry.io/5372657",
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: "It looks like we dozed off and there was an error.",
          subtitle: "The Dev team has been notified.",
        });
      }
      return event;
    },
  });
}

ReactGA.initialize(`${process.env.G_ANALYTICS_TAG}`, {
  debug: !process.env.NODE_ENV === "production",
  titleCase: false,
});

class TicketsList extends React.Component {
  constructor(props) {
    super(props);
    const watcherOptions = [];
    if (this.props.watcherOptions) {
      this.props.watcherOptions.forEach((x) => {
        watcherOptions.push({
          label:
            x.label != null ? (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    display: "inline-block",
                    verticalAlign: "bottom",
                    marginRight: "10px",
                  }}
                >
                  <img
                    alt="user icon"
                    className="select-avatar-image"
                    src={x.image}
                  />
                </div>
                <p
                  style={{
                    whiteSpace: "pre",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  }}
                >
                  {x.label}
                </p>
              </div>
            ) : (
              x.email
            ),
          value: x.value,
          emailNotifications: x.email_notifications,
          metaLabel: x.label != null ? x.label : x.email,
        });
      });
    }

    this.state = {
      tickets: this.props.tickets || [
        {
          title: "Test Ticket",
          description: "",
          build_version: "",
          ticket_priority: { id: 0, name: "Normal" },
          ticket_status: { id: 0, name: "Open" },
          assignee: { id: 0, name: "" },
          tester: { id: 3, name: "" },
          application: { id: 0, name: "" },
        },
      ],
      search: "",
      sidebarOpen: false,
      searchActive: false,
      ticket_data: "",
      user_options: "",
      a11yOptions: this.props.a11yOptions,
      ticketLabelOptions: this.props.ticketLabels
        ? this.props.ticketLabels.map((label) => {
            return { label: label.name, value: label.id };
          })
        : [],
      projectId: this.props.project != undefined ? this.props.project.id : null,
      project: this.props.project,
      currentUserDevices: this.props.current_user.devices,
      topDevices: this.props.topDevices,
      column: "",
      direction: "",
      debugKey: false,
      loaded: false,
      loading: false,
      ticketCount: this.props.ticket_count,
      noMoreRows: false,
      searchTickets: [],
      watcherOptions,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      trelloConnected: false,
      trelloListId: "",
      ticketFilterStatus: [false, false, false, false, false, false],
      selectedCreatedByUsers: [],
      filterCreatedByUsers: [],
      selectedAssignedToUsers: [],
      filterAssignedToUsers: [],
      selectedTicketLabels: [],
      ticket:
        this.props.ticket != null
          ? this.props.ticket
          : {
              title: "",
              description: "",
              build_version: "",
              tester_name:
                this.props.current_user.name != undefined
                  ? this.props.current_user.name
                  : this.props.current_user.email,
              project: {
                id: this.props.project.id,
                name: this.props.project.name,
                a11y_active: this.props.project.a11y_active,
              },
              ticket_priority: { id: 0, name: "" },
              ticket_status: { id: 1, name: "Open", value: 0, label: "Open" },
              ticket_status_name: "Open",
              ticket_status_id: 1,
              assignee: {
                id: 0,
                name: "Unassigned",
                value: 0,
                label: "Unassigned",
              },
              tester: {
                id: this.props.current_user.id,
                name:
                  this.props.current_user.name != undefined
                    ? this.props.current_user.name
                    : this.props.current_user.email,
              },
              ticket_attachments: [],
              watchers: [],
              comments: [],
              a11y: { violation: null, id: null },
            },
      currentTicketId: null,
      taskBoardView: false,
      loadingClass: "form-update-message-hidden",
      loadingText: <div id="form-update-loader" />,
      sidebarClosed: true,
    };
    this.Table = React.createRef();
    this.badgeUrls = {
      bugs_bronze:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Bugs%20-%20Bronze.svg",
      bugs_silver:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Bugs%20-%20Silver.svg",
      bugs_gold:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Skills__Bugs%20-%20Gold.svg",
      trialist:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/Skills_Trialist.svg",
    };
    this.columnSortKeys = {
      reporter: "lower(testers.name)",
      status: "ticket_status_id",
      priority: "ticket_priority_id",
      assignee: "lower(assignees.name)",
      wcag: "a11y.violation",
      created: "created_at",
      labels: "lower(ticket_labels.name)",
      title: "lower(title)",
    };
    this.ticketSortKeys = {
      reporter: "tester_name",
      status: "ticket_status_id",
      priority: "ticket_priority_id",
      assignee: "assignee_name",
      wcag: "a11y",
      created: "created_at",
      labels: "ticket_labels",
      title: "title",
      id: "id",
    };
    this.typingTimeout = null;
  }

  handleCreateNewBug = (draftData) => {
    this.setModal(true, "new-ticket", {
      currentUserDevices: this.state.currentUserDevices,
      topDevices: this.state.topDevices,
      currentUser: this.props.current_user,
      userOptions: this.state.user_options,
      watcherOptions: this.state.watcherOptions,
      deviceOptions: this.props.device_options,
      ticketLabelOptions: this.state.ticketLabelOptions,
      violationOptions: this.state.a11yOptions,
      browserOptions: this.props.browser_options,
      updateTicketLabels: this.updateTicketLabels,
      handler: this.handler,
      project: this.state.project,
      addToTrello: this.state.trelloConnected,
      listId: this.state.trelloListId,
      customClass: "new-ticket-modal",
      draftData: draftData.id ? draftData : null,
      backdropClickCheck: {
        title: draftData.id ? "Close Bug Draft" : "Discard New Bug",
        text: draftData.id
          ? "Are you sure that you want to close this bug draft before submitting?"
          : "Are you sure that you want to discard this bug?",
      },
    });
  };

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  updateTicketLabels = (labels, promiseResolve) => {
    this.setState({ ticketLabelOptions: labels }, () => {
      if (promiseResolve) {
        promiseResolve("done");
      }
    });
  };

  localTicketSort = (tickets, ticket) => {
    tickets.sort((a, b) => {
      let item1 = a[this.ticketSortKeys[this.state.column]];
      let item2 = b[this.ticketSortKeys[this.state.column]];
      if (this.state.column === "wcag") {
        item1 = item1.violation;
        item2 = item2.violation;
      }
      if (Array.isArray(item1)) {
        item1 = item1[0].name;
        item2 = item2[0].name;
      }
      if (Number.isInteger(item1)) {
        item1 = item1.toString();
        item2 = item2.toString();
      }
      item1 = item1.toLowerCase();
      item2 = item2.toLowerCase();
      if (item1 === item2) {
        return b.id - a.id;
      }
      if (this.state.direction === "asc") {
        return item1 > item2 ? 1 : -1;
      }
      return item1 < item2 ? 1 : -1;
    });
    if (
      (ticket,
      tickets.length < this.state.ticketCount &&
        tickets.slice(-1)[0].id === ticket.id)
    ) {
      tickets.pop();
    }
  };

  handler = (ticketString, action) => {
    const {
      selectedAssignedToUsers,
      selectedCreatedByUsers,
      ticketFilterStatus,
      tickets: currentTickets,
    } = this.state;
    const filtered =
      selectedAssignedToUsers.length +
        selectedCreatedByUsers.length +
        ticketFilterStatus.filter((x) => x).length >=
      1;

    const ticketData = JSON.parse(ticketString);
    const ticketsList = Array.isArray(currentTickets)
      ? [...currentTickets]
      : [];
    let updateTickets = true;

    if (filtered) {
      if (
        selectedAssignedToUsers.length > 0 &&
        selectedAssignedToUsers.indexOf(ticketData.assignee_id) === -1
      ) {
        updateTickets = false;
      } else if (
        selectedCreatedByUsers.length > 0 &&
        selectedCreatedByUsers.indexOf("pqa") === -1 &&
        selectedCreatedByUsers.indexOf(ticketData.tester_id) === -1
      ) {
        updateTickets = false;
      } else if (
        ticketFilterStatus.indexOf(true) !== -1 &&
        !ticketFilterStatus[ticketData.ticket_status_id - 1]
      ) {
        updateTickets = false;
      }
    }
    if (
      (action === "create" || action === "draft") &&
      ticketData.project.id === this.state.projectId
    ) {
      if (updateTickets) {
        ticketsList.unshift(ticketData);
        if (this.state.column && this.state.direction) {
          this.localTicketSort(ticketsList, ticketData);
        }
        this.setState({
          tickets: ticketsList,
          ticketCount: this.state.ticketCount + 1,
        });
      } else {
        this.setState({
          ticketCount: this.state.ticketCount + 1,
        });
      }
    } else if (action === "update") {
      const index = ticketsList.map((e) => e.id).indexOf(ticketData.id);
      if (
        index !== -1 &&
        ticketsList[index].project.id === this.state.projectId
      ) {
        if (updateTickets) {
          ticketsList[index] = ticketData;
          if (this.state.column && this.state.direction) {
            this.localTicketSort(ticketsList, ticketData);
          }
        } else {
          ticketsList.splice(index, 1);
        }
        this.setState({
          tickets: ticketsList,
        });
      }
    } else if (
      this.state.ticket != null &&
      this.state.ticket.id == ticketData.id &&
      action === "ticket_attachment"
    ) {
      const newTicket = this.state.ticket;
      newTicket.ticket_attachments = ticketData.ticket_attachments;
      this.setState({
        ticket: newTicket,
      });
    }
  };

  updateSearch = (event) => {
    const { value } = event.target;
    if (value) {
      this.filterUrlChange("search", value, true);
    } else {
      this.filterUrlChange("search", value, false);
    }
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.typingTimeout = setTimeout(() => {
      this.setState({ search: value || "" }, () => {
        this.filterTickets();
      });
    }, 600);
  };

  dbSearch = (query, ticketSearch) => {
    const self = this;
    let url;
    if (ticketSearch) {
      url = `/projects/${self.state.projectId}/bugs/id_search.json?id=${query}`;
    } else {
      url = `/projects/${self.state.projectId}/bugs/search.json?query=${query}`;
    }
    $.ajax({
      url,
      dataType: "json",
      cache: false,
      success(data) {
        var { tickets } = data;
        if (data.tickets.length < 1 && self.state.search != "") {
          var tickets = "No bugs found";
        } else {
          var { tickets } = data;
        }
        $("#ticketSideBar > div").css("position", "");
        $("#ticketSideBar > div").css("height", "");
        self.setState(
          {
            // searchTickets: tickets,
            tickets,
            loadingClass: "form-update-message-hidden",
          },
          () => {
            const elem = document.getElementById("aria-custom-status");
            if (elem) {
              self.ariaModifier = self.ariaModifier ? "" : ".";
              elem.innerText = `${
                tickets === "No bugs found" ? 0 : tickets.length
              } search results found${self.ariaModifier}`;
            }
          }
        );
      },
      error(xhr, status, err) {
        console.error(self.props.url, status, err.toString());
      },
    });
  };

  getData = (projectId) => {
    if (projectId) {
      var id = projectId;
    } else if (this.state.projectId == undefined) {
      var id = this.props.projectId;
    } else {
      var id = parseInt(this.state.projectId);
    }
    if (id == 0) {
      var url = "/bugs.json";
    } else if (isNaN(id)) {
      const proj_id = window.location.pathname.split("/")[2];
      var url = `/projects/${proj_id}/bugs`;
    } else {
      var url = `/projects/${id}/bugs`;
    }
    $.ajax({
      url,
      dataType: "json",
      cache: false,
      success: function (data) {
        const options = data.user_options;
        options.unshift({ label: "Unassigned", value: 0 });
        const filterAssignedToUsers = options.filter(
          (option) => option.value !== this.props.current_user.id
        );
        const filterCreatedByUsers = options.filter(
          (option) =>
            option.value !== 0 && option.value !== this.props.current_user.id
        );
        const watcherOptions = [];
        data.watcher_options.forEach((x) => {
          watcherOptions.push({
            label:
              x.label != null ? (
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "30px",
                      width: "30px",
                      display: "inline-block",
                      verticalAlign: "bottom",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      alt="user icon"
                      className="select-avatar-image"
                      src={x.image}
                    />
                  </div>
                  <p
                    style={{
                      whiteSpace: "pre",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px",
                    }}
                  >
                    {x.label}
                  </p>
                </div>
              ) : (
                x.email
              ),
            value: x.value,
            emailNotifications: x.email_notifications,
            metaLabel: x.label != null ? x.label : x.email,
          });
        });
        this.setState(
          {
            tickets: data.tickets,
            project: data.project,
            projectId: data.project.id,
            topDevices: data.top_devices,
            currentPage: 1,
            currentUserAssignee: false,
            currentUserFiled: false,
            trelloConnected: false,
            trelloBoardId: "",
            trelloListId: "",
            ticketLabelOptions: data.ticket_labels.map((label) => {
              return { label: label.name, value: label.id };
            }),
            ticketCount: data.ticket_count,
            watcherOptions: data.watcher_options,
            search: "",
            searchTickets: [],
            loaded: true,
            a11yOptions: data.a11yOptions,
            user_options: options,
            filterAssignedToUsers,
            filterCreatedByUsers,
            selectedCreatedByUsers: [],
            selectedAssignedToUsers: [],
            noMoreRows: false,
          },
          () => {
            if (this.state.project.trello_token) {
              if (typeof Trello !== "undefined") {
                Trello.get(
                  "members/me/boards",
                  { token: this.state.project.trello_token },
                  (res) => {
                    this.setState({ trelloConnected: true });
                    const { trello_token, trello_board, trello_list } =
                      this.state.project;
                    this.setTrelloInfo(
                      trello_token,
                      trello_board || "Test Platform",
                      trello_list || this.state.project.name
                    );
                  },
                  (res) => {
                    Swal.fire({
                      title: "Invalid Trello Token",
                      text: "Bugs will not be added/updated in linked Trello account",
                      confirmButtonText: "OK",
                    }).then(
                      () => {},
                      (dismiss) => {
                        // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
                      }
                    );
                  }
                );
              }
            }
          }
        );
      }.bind(this),
      error(status, err) {
        console.error(url, status, err);
      },
    });
  };

  setTrelloInfo = async (token, board, list) => {
    var token = token;
    var board = board;
    var list = list;
    let boards = [];
    await Trello.get(
      "members/me/boards",
      { token },
      (res) => {
        boards = res;
      },
      (res) => {
        console.error(res);
      }
    );
    var index = boards.map((board) => board.name).indexOf(board);
    let boardId = "";
    if (index === -1) {
      await Trello.post(
        "boards",
        { token, name: board },
        (res) => {
          boardId = res.id;
        },
        (res) => {
          console.error(res);
        }
      );
    } else {
      boardId = boards[index].id;
    }
    let lists = [];
    await Trello.get(
      `boards/${boardId}/lists`,
      { token },
      (res) => {
        lists = res;
      },
      (res) => {
        console.error(res);
      }
    );
    let listId = "";
    if (index === -1) {
      await Trello.post(
        "lists",
        { token, idBoard: boardId, name: list },
        (res) => {
          listId = res.id;
        },
        (res) => {
          console.error(res);
        }
      );
    } else {
      listId = lists[index].id;
    }
    this.setState({
      trelloBoardId: boardId,
      trelloListId: listId,
    });
  };

  componentDidMount() {
    Sentry.setUser({
      username: this.props.current_user.name,
      id: this.props.current_user.id,
    });
    Sentry.setTag("Page", "Bugs");
    if (this.state.project.trello_token) {
      if (typeof Trello !== "undefined") {
        Trello.get(
          "members/me/boards",
          { token: this.state.project.trello_token },
          (res) => {
            this.setState({ trelloConnected: true });
            const { trello_token, trello_board, trello_list } =
              this.state.project;
            this.setTrelloInfo(
              trello_token,
              trello_board || "Test Platform",
              trello_list || this.state.project.name
            );
          },
          () => {
            Swal.fire({
              title: "Invalid Trello Token",
              text: "Bugs will not be added/updated in linked Trello account",
              confirmButtonText: "OK",
            });
          }
        );
      }
    }
    if (
      this.props.action == "show" &&
      this.props.tickets.length >= 1 &&
      !this.props.blocked
    ) {
      var options = this.props.userOptions;
      options.unshift({ value: 0, label: "Unassigned" });
      var filterAssignedToUsers = options.filter(
        (option) => option.value !== this.props.current_user.id
      );
      var filterCreatedByUsers = options.filter(
        (option) =>
          option.value !== 0 && option.value !== this.props.current_user.id
      );
      this.setState({
        loaded: true,
        user_options: options,
        filterCreatedByUsers,
        filterAssignedToUsers,
      });
      setTimeout(() => {
        this.openTicket(this.props.ticket);
      }, 1000);
    } else if (this.props.action == "index" && !this.props.blocked) {
      var options = this.props.userOptions;
      options.unshift({ value: 0, label: "Unassigned" });
      var filterAssignedToUsers = options.filter(
        (option) => option.value !== this.props.current_user.id
      );
      var filterCreatedByUsers = options.filter(
        (option) =>
          option.value !== 0 && option.value !== this.props.current_user.id
      );
      this.setState({
        loaded: true,
        user_options: options,
        filterCreatedByUsers,
        filterAssignedToUsers,
      });
    } else if (!this.props.blocked) {
      let uri = window.location.href;
      uri = uri.substring(0, uri.lastIndexOf("/"));
      window.history.pushState("page2", "Test Platform", uri);
      this.setState({ loaded: true });
      M.toast({
        html: "Ticket not found",
        displayLength: 5000,
        classes: "red",
      });
    }
    document.addEventListener("keydown", this.onKeyPressed);
    $("#ticket-overlay").hide();
    window.addEventListener("keydown", this.handleFocusTab);
    window.addEventListener("popstate", (e) => this.handlePopState(e));

    const url = new URL(location.href);
    const taskBoard = url.searchParams.get("taskboard");
    const sessionTaskBoard = sessionStorage.getItem("taskBoardView");
    if (taskBoard === "true" || sessionTaskBoard === "true") {
      this.setState({
        taskBoardView: true,
      });
    }
    const searchParam = url.searchParams.get("search");
    if (searchParam) {
      this.setState({ search: searchParam }, () => {
        document.getElementById("bug-search-input").value = searchParam;
      });
    }
    const statusParams = url.searchParams.get("ticket_status_id");
    if (statusParams) {
      const filters = [false, false, false, false, false, false];
      statusParams.split(",").map((x) => (filters[parseInt(x) - 1] = true));
      this.setState({ ticketFilterStatus: filters });
    }
    const assigneeParams = url.searchParams.get("assignee_ids");
    if (assigneeParams) {
      var ids = assigneeParams.split(",").map((x) => parseInt(x));
      this.setState({ selectedAssignedToUsers: ids });
    }
    const testerParams = url.searchParams.get("tester_ids");
    if (testerParams) {
      var ids = testerParams
        .split(",")
        .map((x) => (x != "client" ? parseInt(x) : "client"));
      this.setState({ selectedCreatedByUsers: ids });
    }
    const self = this;
    const ticketParams = url.searchParams.has("search");
    if (ticketParams) {
      var ids = url.searchParams.get("search").trim();
      self.setState({ search: ids }, () => {
        this.filterTickets();
      });
    }
    if (this.props.current_user && this.props.current_user.role != "trialist") {
      this.props.cable.subscriptions.create(
        { channel: "TicketChannel", project_id: self.state.projectId },
        {
          received(data) {
            if (data.action == "create") {
              const parsed = JSON.parse(data.ticket);
              if (parsed.tester_id != self.props.current_user.id) {
                self.handler(data.ticket, data.action);
              }
            } else {
              self.handler(data.ticket, data.action);
            }
          },
        }
      );
    }
    if (this.props.new_badge) {
      this.setModal(true, "new-badge", {
        badgeDetails: this.props.new_badge.details,
        badgeName: this.props.new_badge.name,
        imageLink: this.badgeUrls[this.props.new_badge.identity],
        customClass: "new-badge-modal",
      });
    }
    setTimeout(() => {
      const tableOuterContainer = document.querySelector(
        ".ReactVirtualized__Grid.ReactVirtualized__Table__Grid"
      );
      const tableContainer = document.querySelector(
        ".ReactVirtualized__Grid__innerScrollContainer"
      );
      const tableHeader = document.querySelector(
        ".ReactVirtualized__Table__headerRow"
      );
      const table = document.querySelector(".ReactVirtualized__Table");
      if ((tableOuterContainer && tableContainer && tableHeader, table)) {
        table.removeAttribute("role");
        tableHeader.setAttribute("aria-hidden", "true");
        tableOuterContainer.removeAttribute("aria-label");
        tableOuterContainer.removeAttribute("role");
        tableOuterContainer.removeAttribute("tabindex");
        tableOuterContainer.removeAttribute("aria-readonly");
        if (tableContainer) {
          // somehow with the check about this is still getting caught as an error event, trying another if
          tableContainer.setAttribute("role", "group");
          tableContainer.setAttribute("aria-label", "Ticket List");
          // tableContainer.setAttribute("aria-live", "polite")
          tableContainer.setAttribute("tabindex", "-1");
          // tableContainer.addEventListener("focus", this.a11yTableListener)
          tableContainer.style.listStyle = "none";
        }
      }
    }, 1000);
  }

  filterTickets = () => {
    const self = this;
    // self.setLoadingClass("form-update-start-loading");
    self.setState({ loaded: false });
    const url = `/projects/${self.state.projectId}/bugs/filter.json`;
    const currentUrl = new URL(location.href);
    let scopeString = currentUrl.search;
    if (this.state.column && this.state.direction) {
      const column = this.columnSortKeys[this.state.column]
        ? this.columnSortKeys[this.state.column]
        : this.state.column;
      scopeString += `${scopeString ? "&" : "?"}direction=${
        this.state.direction
      }&column=${column}`;
    }
    if (this.state.search != "") {
      scopeString += `${scopeString ? "&" : "?"}query=${self.state.search}`;
    }
    $.ajax({
      url: url + scopeString,
      dataType: "json",
      cache: false,
      success(data) {
        let tickets;
        if (data.tickets.length < 1) {
          tickets = "No bugs found";
        } else {
          tickets = data.tickets;
        }
        self.setState({
          tickets,
          noMoreRows: false,
          // loadingClass: "form-update-message-hidden",
          loaded: true,
        });
        self.Table.current.recomputeRowHeights();
        self.Table.current.forceUpdate();
      },
      error(xhr, status, err) {
        console.error(self.props.url, status, err.toString());
      },
    });
  };

  handlePopState = (e) => {
    const projectId = e.target.location.pathname.split("/")[2];
    this.getData(projectId);
  };

  componentDidUpdate(prevProps, prevState) {
    const self = this;
    if (prevState.loaded == true && self.state.loaded == false) {
      $("#container").css("marginTop", "0px");
      $("header > div").show();
    }
    if (prevState.projectId != this.state.projectId) {
      if (
        this.props.current_user &&
        this.props.current_user.role != "trialist"
      ) {
        this.props.cable.subscriptions.subscriptions[0].unsubscribe();
        this.props.cable.subscriptions.create(
          { channel: "TicketChannel", project_id: self.state.projectId },
          {
            received(data) {
              if (data.action == "create") {
                const parsed = JSON.parse(data.ticket);
                if (parsed.tester_id != self.props.current_user.id) {
                  self.handler(data.ticket, data.action);
                }
              } else {
                self.handler(data.ticket, data.action);
              }
            },
          }
        );
      }
      self.setState({
        ticketFilterStatus: [false, false, false, false, false, false],
        selectedAssignedToUsers: [],
        selectedCreatedByUsers: [],
        noMoreRows: false,
      });
    }
  }

  closeSidebar = () => {
    const { sidebarOpen, project } = this.state;

    if (sidebarOpen) {
      this.setState({
        currentTicketId: null,
      });

      const stateObj = {
        bug: "bugs",
      };

      this.setState({
        sidebarOpen: false,
        loadingText: <div id="form-update-loader" />,
        loadingClass: "form-update-message-hidden",
      });

      const { search } = window.location;
      const projectId = project?.id;
      const pathname = `${projectId && `/projects/${projectId}`}/bugs${search}`;
      window.history.replaceState(stateObj, "", pathname);

      setTimeout(() => {
        this.setState({
          ticket_data: "",
          sidebarClosed: true,
          loadingText: <div id="form-update-loader" />,
          loadingClass: "form-update-message-hidden",
        });
        $("#ticketSideBar > div").css("position", "static");
        $("#ticketSideBar > div").css("height", "0px");
      }, 500);
    }
  };

  dropdownHandler = (id) => {
    this.setState({ loaded: false });
    const url = new URL(location.href);
    const taskBoard = url.searchParams.get("taskboard");
    window.history.pushState(
      "page2",
      "Test Platform",
      `/projects/${id}/bugs${taskBoard === "true" ? "?taskboard=true" : ""}`
    );
    this.getData(id);
  };

  ticketIdentifier = (input, id) => {
    if (input === null) {
      return (
        <p
          id="projectKey"
          data-balloon-pos="left"
          aria-label="Go to project page to add a project key"
        >
          ?
        </p>
      );
    }
    if (id === null) {
      return `${input}-??`;
    }
    return `${input}-${id}`;
  };

  ticketDeleteHandler = (id) => {
    const new_array = this.state.tickets.filter((i) => i.id !== id);
    const filteredTickets = this.state.searchTickets.filter((i) => i.id !== id);
    this.setState({ tickets: new_array, searchTickets: filteredTickets });
  };

  retrieveTicketData = (id) => {
    this.setState({ loading: true });
    $.ajax({
      url: `/v1/bugs/${id}/ticket_data.json`,
      dataType: "json",
      cache: false,
      success: function (data) {
        const { ticket } = data;
        const identifier = `${ticket.project.identifier}-${ticket.app_key}`;
        const stateObj = {
          bug: identifier,
        };
        if (!window.location.href.includes("bugs/")) {
          const path = `${window.location.pathname}/${identifier}${window.location.search}`;
          window.history.replaceState(stateObj, "", path);
        }
        this.setState({
          sidebarOpen: true,
          sidebarClosed: false,
          // user_options: options,
          loading: false,
          ticket: data.ticket,
          ticket_data: data,
          ticket_versions: data.ticket_versions,
          // filterAssignedToUsers,
          // filterCreatedByUsers
        });
      }.bind(this),
      error: function (xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this),
    });
  };

  openTicket = (ticket) => {
    let ticketId = Number(ticket);
    if (!Number(ticketId)) {
      ticketId = Number(ticket.id);
    }

    if (!Number(ticketId)) {
      return null;
    }

    const { sidebarOpen, currentTicketId, taskBoardView } = this.state;

    if (sidebarOpen === false) {
      this.setState({
        currentTicketId: ticketId,
      });

      $("#ticketSideBar > div").css("position", "fixed");
      $("#ticketSideBar > div").css("height", "40px");
      $("#ticketSideBar > div > div:nth-child(3) > ").css("position", "static");

      setTimeout(() => {
        this.retrieveTicketData(ticketId);
      }, 600);
    } else if (currentTicketId !== ticketId) {
      this.closeSidebar();

      setTimeout(() => {
        this.setState({
          currentTicketId: ticketId,
        });
        this.retrieveTicketData(ticketId);
        $("#ticketSideBar > div").css("position", "fixed");
        $("#ticketSideBar > div").css("height", "40px");
        $("#ticketSideBar > div > div:nth-child(3)").css("position", "static");
      }, 600);
    }

    if (taskBoardView) {
      ReactGA.event({
        category: "Tickets Taskboard",
        action: "Card clicks",
      });
    }

    return null;
  };

  sortTickets = (e) => {
    const column = e.target.attributes.value.value;
    const direction =
      this.state.column && column === this.state.column
        ? this.state.direction === "asc"
          ? "desc"
          : "asc"
        : "desc";
    this.setState({ column, direction }, () => {
      this.filterTickets();
    });
  };

  togglTaskBoardView = (e) => {
    // to make the balloon css pop up go away after clicking
    e.target.blur();
    /// ////////////
    const url = new URL(location.href);
    if (e.target.dataset.id === "list") {
      url.searchParams.set("taskboard", false);
      sessionStorage.setItem("taskBoardView", "false");
      window.history.replaceState({}, "", url.href);
      this.setState({
        taskBoardView: false,
      });
    } else if (e.target.dataset.id === "column") {
      url.searchParams.set("taskboard", true);
      sessionStorage.setItem("taskBoardView", "true");
      window.history.replaceState({}, "", url.href);
      this.setState({
        taskBoardView: true,
      });
    }
    ReactGA.event({
      category: "Tickets Page",
      action: "Toggled Taskboard",
      // recorded before user clicks the button, track state prior to update, 0 = taskboard off 1 = taskboard on.
      value: this.state.taskBoardView ? 0 : 1,
    });
  };

  onKeyPressed = (e) => {
    if (e.keyCode === 18) {
      this.setState({ debugKey: !this.state.debugKey });
    }
  };

  componentWillUnmount() {
    if (this.props.current_user && this.props.current_user.role != "trialist") {
      this.props.cable.subscriptions.subscriptions[0].unsubscribe();
    }
    document.removeEventListener("keydown", this.onKeyPressed);
    $("header > div").show();
    $("#container").css("marginTop", "auto");
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const { tickets } = this.state;

    const draggedTicket = this.findDraggedTicket(tickets, draggableId);

    if (!draggedTicket) {
      console.error("Dragged ticket not found in the tickets array.");
      return;
    }

    const index = tickets.indexOf(draggedTicket);

    this.updateTicketStatus(tickets, index, destination.droppableId);

    this.setState({ tickets });

    this.sendUpdateRequest(draggableId, tickets[index]);
  };

  findDraggedTicket = (tickets, draggableId) => {
    return tickets.find((ticket) => String(ticket.id) === draggableId);
  };

  updateTicketStatus = (tickets, index, destinationDroppableId) => {
    const { current_user } = this.props;
    const isClient = current_user.role === "client";

    const statusOptions = {
      1: "Open",
      2: "Closed",
      3: "Fixed",
      4: "In Progress",
      6: "Reopened",
    };

    tickets[index].ticket_status_name =
      isClient && destinationDroppableId === 2
        ? "Won't Fix"
        : statusOptions[destinationDroppableId];
    tickets[index].ticket_status_id =
      isClient && destinationDroppableId === 2 ? 5 : destinationDroppableId;
  };

  sendUpdateRequest = (draggableId, ticket) => {
    const formData = new FormData();
    formData.append("ticket[project_id]", ticket.project.id);
    formData.append(
      "ticket[ticket_status_id]",
      this.props.current_user.role === "client" && ticket.ticket_status_id === 2
        ? 5
        : ticket.ticket_status_id
    );

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", `/v1/bugs/${draggableId}`, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = () => {
      if (xhr.status === 201) {
        this.handler(xhr.response, "create");
        this.setState({ tickets: [...this.state.tickets] });
      } else if (xhr.status === 200) {
        this.handler(xhr.response, "update");
        this.setState({ tickets: [...this.state.tickets] });
      } else {
        this.handleUpdateError();
      }
    };

    ReactGA.event({
      category: "Tickets Taskboard",
      action: "Dragged Card",
    });

    xhr.send(formData);
  };

  handleUpdateError = () => {
    Swal.fire(
      "On Drag ticket error",
      "There was an error updating this ticket"
    );
  };
  setLoadingClass = (loadingClass) => {
    const x = this.state.loadingClass;
    if (
      loadingClass === "form-update-start-loading" &&
      (x === "form-update-start-loading" ||
        x === "form-update-end-loading" ||
        x === "form-update-start-loading-no-fade")
    ) {
      this.setState({
        loadingClass: "form-update-start-loading-no-fade",
      });
    } else {
      this.setState({
        loadingClass,
      });
    }
  };

  setLoadingText = (loading) => {
    if (loading) {
      this.setState({ loadingText: <div id="form-update-loader" /> });
    } else {
      this.setState({ loadingText: "Ticket Updated" });
    }
  };

  filterUrlChange = (key, value, append) => {
    const url = new URL(location.href);
    if (append) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
    const stateObj = {};
    window.history.replaceState(stateObj, "", url.href);
  };

  handleTypeChange = (e) => {
    if (e.target.value) {
      const url = new URL(location.href);
      const paramPresent = url.searchParams.has("ticket_status_id");
      const newStatusFilter = this.state.ticketFilterStatus;
      newStatusFilter[e.target.value] = !newStatusFilter[e.target.value];
      let append;
      let number = parseInt(e.target.value) + 1;
      if (newStatusFilter[e.target.value]) {
        if (paramPresent) {
          var ids = url.searchParams.get("ticket_status_id");
          number = `${ids},${number.toString()}`;
        }
        append = true;
      } else if (paramPresent) {
        var ids = url.searchParams.get("ticket_status_id").split(",");
        ids = ids.filter((x) => x != number.toString());
        number = ids.join(",");
        if (ids.length >= 1) {
          append = true;
        } else {
          append = false;
        }
      } else {
        append = false;
      }
      this.filterUrlChange("ticket_status_id", number, append);
      this.setState({ ticketFilterStatus: newStatusFilter }, () => {
        if (this.state.taskBoardView) {
          const elems = document.querySelectorAll(".task-column");
          for (let x = 0; x < elems.length; x++) {
            if (elems[x].scrollHeight > elems[x].clientHeight) {
              document.querySelector(
                `#${elems[x].id}-dot-container`
              ).style.display = "block";
            } else {
              document.querySelector(
                `#${elems[x].id}-dot-container`
              ).style.display = "none";
            }
          }
        }
        this.filterTickets();
      });
    }
  };

  handleCreatedByUserChange = (e) => {
    if (e.target.value) {
      const value = Number.isNaN(e.target.value)
        ? e.target.value
        : parseInt(e.target.value);
      let newSelectedCreatedByUsers = this.state.selectedCreatedByUsers;
      if (newSelectedCreatedByUsers.indexOf(value) !== -1) {
        newSelectedCreatedByUsers = newSelectedCreatedByUsers.filter(
          (id) => id !== value
        );
        if (newSelectedCreatedByUsers.length >= 1) {
          this.filterUrlChange("tester_ids", newSelectedCreatedByUsers, true);
        } else {
          this.filterUrlChange("tester_ids", newSelectedCreatedByUsers, false);
        }
      } else {
        newSelectedCreatedByUsers.push(value);
        this.filterUrlChange("tester_ids", newSelectedCreatedByUsers, true);
      }
      this.setState(
        { selectedCreatedByUsers: newSelectedCreatedByUsers },
        () => {
          if (this.state.taskBoardView) {
            const elems = document.querySelectorAll(".task-column");
            for (let x = 0; x < elems.length; x++) {
              if (elems[x].scrollHeight > elems[x].clientHeight) {
                document.querySelector(
                  `#${elems[x].id}-dot-container`
                ).style.display = "block";
              } else {
                document.querySelector(
                  `#${elems[x].id}-dot-container`
                ).style.display = "none";
              }
            }
          }
          this.filterTickets();
        }
      );
    }
  };

  resetFilters = () => {
    window.history.replaceState(
      {},
      "",
      `/projects/${this.state.projectId}/bugs`
    );
    this.setState(
      {
        ticketFilterStatus: [false, false, false, false, false, false],
        selectedAssignedToUsers: [],
        selectedCreatedByUsers: [],
        selectedTicketLabels: [],
        search: "",
        searchTickets: [],
      },
      () => {
        this.filterTickets();
      }
    );
  };

  handleAssignedToUserChange = (e) => {
    if (e.target.value) {
      const value = Number.isNaN(e.target.value)
        ? e.target.value
        : parseInt(e.target.value);
      let newSelectedAssignedToUsers = this.state.selectedAssignedToUsers;
      if (newSelectedAssignedToUsers.indexOf(value) !== -1) {
        newSelectedAssignedToUsers = newSelectedAssignedToUsers.filter(
          (id) => id !== value
        );
        if (newSelectedAssignedToUsers.length >= 1) {
          this.filterUrlChange(
            "assignee_ids",
            newSelectedAssignedToUsers,
            true
          );
        } else {
          this.filterUrlChange(
            "assignee_ids",
            newSelectedAssignedToUsers,
            false
          );
        }
      } else {
        newSelectedAssignedToUsers.push(value);
        this.filterUrlChange("assignee_ids", newSelectedAssignedToUsers, true);
      }
      this.setState(
        { selectedAssignedToUsers: newSelectedAssignedToUsers },
        () => {
          if (this.state.taskBoardView) {
            const elems = document.querySelectorAll(".task-column");
            for (let x = 0; x < elems.length; x++) {
              if (elems[x].scrollHeight > elems[x].clientHeight) {
                document.querySelector(
                  `#${elems[x].id}-dot-container`
                ).style.display = "block";
              } else {
                document.querySelector(
                  `#${elems[x].id}-dot-container`
                ).style.display = "none";
              }
            }
          }
          this.filterTickets();
        }
      );
    }
  };

  handleTicketLabelChange = (e) => {
    if (e.target.value) {
      const value = isNaN(e.target.value)
        ? e.target.value
        : parseInt(e.target.value);
      let newSelectedTicketLabels = this.state.selectedTicketLabels;
      if (newSelectedTicketLabels.indexOf(value) !== -1) {
        newSelectedTicketLabels = newSelectedTicketLabels.filter(
          (id) => id !== value
        );
        if (newSelectedTicketLabels.length >= 1) {
          this.filterUrlChange("label_ids", newSelectedTicketLabels, true);
        } else {
          this.filterUrlChange("label_ids", newSelectedTicketLabels, false);
        }
      } else {
        newSelectedTicketLabels.push(value);
        this.filterUrlChange("label_ids", newSelectedTicketLabels, true);
      }
      this.setState({ selectedTicketLabels: newSelectedTicketLabels }, () => {
        if (this.state.taskBoardView) {
          const elems = document.querySelectorAll(".task-column");
          for (let x = 0; x < elems.length; x++) {
            if (elems[x].scrollHeight > elems[x].clientHeight) {
              document.querySelector(
                `#${elems[x].id}-dot-container`
              ).style.display = "block";
            } else {
              document.querySelector(
                `#${elems[x].id}-dot-container`
              ).style.display = "none";
            }
          }
        }
        this.filterTickets();
      });
    }
  };

  handleFocusTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", this.handleFocusTab);
      window.addEventListener("mousedown", this.handleFocusMouse);
    }
  };

  tableScroll = (height) => {
    if (
      height.clientHeight + height.scrollTop >= height.scrollHeight - 10 &&
      !this.state.noMoreRows &&
      this.state.tickets.length <= this.state.ticketCount &&
      !this.state.loading
    ) {
      this.setLoadingClass("form-update-start-loading");
      this.loadMoreRows();
    }
  };

  loadMoreRows = () => {
    this.setState({ loading: true });
    const self = this;
    const url = `/projects/${self.state.projectId}/bugs/load_more.json`;
    const currentUrl = new URL(location.href);
    let scopeString = currentUrl.search;
    if (this.state.column && this.state.direction) {
      const column = this.columnSortKeys[this.state.column]
        ? this.columnSortKeys[this.state.column]
        : this.state.column;
      scopeString += `${scopeString ? "&" : "?"}direction=${
        this.state.direction
      }&column=${column}`;
    }
    if (this.state.search != "") {
      scopeString += `${scopeString ? "&" : "?"}query=${self.state.search}`;
    }
    scopeString += `${scopeString ? "&" : "?"}startIndex=${
      self.state.tickets.length
    }`;
    $.ajax({
      url: url + scopeString,
      dataType: "json",
      cache: false,
      success(data) {
        const tickets = self.state.tickets.concat(data.tickets);
        self.setState({
          tickets,
          loadingClass: "form-update-message-hidden",
          loading: false,
          noMoreRows: data.tickets.length === 0,
        });
        self.Table.current.recomputeRowHeights();
        self.Table.current.forceUpdate();
      },
      error(xhr, status, err) {
        console.error(self.props.url, status, err.toString());
      },
    });
  };

  handleFocusMouse = () => {
    document.body.classList.remove("user-is-tabbing");
    window.removeEventListener("mousedown", this.handleFocusMouse);
    window.addEventListener("keydown", this.handleFocusTab);
  };

  clearInput = () => {
    window.history.replaceState(
      {},
      "",
      `/projects/${this.state.projectId}/bugs`
    );
    const elem = document.getElementById("bug-search-input");
    if (elem) {
      elem.value = "";
      elem.focus();
    }
    this.setState(
      {
        search: "",
        searchTickets: [],
      },
      () => {
        this.filterTickets();
      }
    );
  };

  toggleSearchActive = () => {
    this.setState({ searchActive: !this.state.searchActive });
  };

  showQRCode = () => {
    this.setModal(true, "qr-code", {
      userId: this.props.current_user.id,
      projectId: this.state.project.id,
      customClass: "qr-modal",
    });
  };

  render() {
    const content = (
      <div className="col s12 card" id="sidebarCard">
        <div className="card-content">
          <div className="col s12">
            <ErrorBoundary>
              <TicketForm
                ticketDeleteHandler={this.ticketDeleteHandler}
                setLoadingText={this.setLoadingText}
                setLoadingClass={this.setLoadingClass}
                handler={this.handler}
                violationOptions={this.state.a11yOptions}
                currentUser={this.props.current_user}
                roleLevel={1}
                trelloConnected={this.state.trelloConnected}
                userOptions={this.state.user_options}
                watcherOptions={this.state.watcherOptions}
                closeButtonHandler={this.closeSidebar}
                deviceOptions={this.props.device_options}
                browserOptions={this.props.browser_options}
                closeMethod={this.closeSidebar}
                projectOptions={this.props.projectOptions}
                ticket={this.state.ticket}
                ticketData={this.state.ticket_data}
                ticketLabelOptions={this.state.ticketLabelOptions}
                updateTicketLabels={this.updateTicketLabels}
                currentUserDevices={this.state.currentUserDevices}
                topDevices={this.state.topDevices}
                project={this.state.project}
                setModal={this.setModal}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );

    let tickets;
    if (this.state.tickets == "No bugs found") {
      tickets = [];
    } else {
      tickets = this.state.tickets;
    }

    const toDoTickets = [];
    const inProgressTickets = [];
    const fixedTickets = [];
    const closedTickets = [];
    const reopenedTickets = [];

    Object.keys(tickets).forEach((ticket) => {
      const value = tickets[ticket];
      if (value.ticket_status_id === 1) {
        toDoTickets.push({ value, index: ticket });
      } else if (value.ticket_status_id === 4) {
        inProgressTickets.push({ value, index: ticket });
      } else if (value.ticket_status_id === 3) {
        fixedTickets.push({ value, index: ticket });
      } else if (value.ticket_status_id === 6) {
        reopenedTickets.push({ value, index: ticket });
      } else {
        closedTickets.push({ value, index: ticket });
      }
    });

    const self = this;

    function headerRowRenderer(props) {
      return (
        <TicketTableHeader
          project={self.state.project}
          props={props}
          column={self.state.column}
          direction={self.state.direction}
          sortTickets={self.sortTickets}
        />
      );
    }

    function noRowsRenderer() {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>No bugs found</p>
        </div>
      );
    }

    function rowRenderer(props) {
      const value = tickets[props.index];
      return (
        <Suspense
          style={props.style}
          key={props.key}
          fallback={
            <div id="ticketLoader" key={Math.random()}>
              {" "}
              <p>Loading…</p>{" "}
            </div>
          }
        >
          <Ticket
            {...props}
            style={props.style}
            a11y_active={self.state.project.a11y_active}
            currentTicketId={self.state.currentTicketId}
            handler={self.openTicket}
            handleDraft={self.handleCreateNewBug}
            key={props.key}
            id={`ticket_${value.id}`}
            dataId={value.id}
            ticket={value}
          />
        </Suspense>
      );
    }
    return (
      <div style={{ display: "flex", flex: 1, height: "100%" }}>
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
          updateTicketLabels={this.updateTicketLabels}
          ticketLabelOptions={this.state.ticketLabelOptions}
          handler={this.handler}
        />
        <div
          style={{
            position: "fixed",
            top: "-200px",
            left: "-200px",
            pointerEvents: "none",
            height: "0px",
            width: "0px",
            overflow: "hidden",
          }}
          data-number="0"
          role="status"
          aria-live="assertive"
          id="aria-custom-status"
        />

        {!this.props.blocked ? (
          <div
            id="sidebar-child"
            style={{
              width: "100%",
              maxWidth: `calc(100% - ${100}px)`,
              willChange: "max-width",
              transition: "max-width 0.666s ease-in-out 0s",
              marginLeft: "auto",
              display: "flex",
              height: "100%",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "100%", padding: "0 25px" }}>
              <div
                style={{
                  flex: "1 1 0%",
                  height: "1px",
                  backgroundColor: "rgb(224, 224, 224)",
                  width: "calc(100% + 50px)",
                  top: "90px",
                  marginLeft: "-25px",
                  position: "relative",
                }}
              />
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                id="tickets-list-main"
              >
                <div id="tickets-sub-nav-container">
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "13px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                    >
                      <img
                        aria-hidden="true"
                        style={{ marginRight: "10px" }}
                        height="40"
                        width="40"
                        src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20-%20Filled.svg"
                      />
                      <h1 tabIndex={-1} className="large-title">
                        {" "}
                        Bugs{" "}
                      </h1>
                    </div>
                    {this.props.current_user.role !== "client" && (
                      <button
                        message="Get my QR Code"
                        className="link-btn qr-button-bottom"
                        style={{
                          marginRight: "8px",
                          color: "#519acc",
                          border: "solid 1px lightgray",
                          height: "32px",
                          borderRadius: "6px",
                        }}
                        onClick={this.showQRCode}
                      >
                        <QRCodeIcon
                          width={20}
                          height={20}
                          color="#242424"
                          svgStyles={{ position: "relative", top: "2px" }}
                        />
                      </button>
                    )}
                    <span
                      style={{
                        display: "inline-block",
                        border: "solid 1px lightgray",
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: "6px",
                        height: "32px",
                        padding: "0 2px",
                      }}
                      aria-hidden="true"
                      aria-label="List View"
                      message="List View"
                      className="qr-button-bottom list-view"
                    >
                      <img
                        onClick={this.togglTaskBoardView}
                        src={
                          this.state.taskBoardView
                            ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_List_View.svg"
                            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_List_View_Blue.svg"
                        }
                        width="36"
                        height="36"
                        alt="list view icon"
                        data-id="list"
                        style={{
                          display: "inline-block",
                          fontSize: "44px",
                          cursor: "pointer",
                          verticalAlign: "bottom",
                          position: "relative",
                          bottom: "3px",
                        }}
                        className="material-icons"
                      />
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        borderTop: "solid 1px lightgray",
                        borderRight: "solid 1px lightgray",
                        borderBottom: "solid 1px lightgray",
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                        height: "32px",
                        padding: "0 2px",
                        marginRight: "10px",
                      }}
                      aria-hidden="true"
                      aria-label="Taskboard View"
                      message="Task Board View"
                      className="qr-button-bottom taskboard-view"
                    >
                      <img
                        onClick={this.togglTaskBoardView}
                        src={
                          this.state.taskBoardView
                            ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Taskboard_View_Blue.svg"
                            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Taskboard_View.svg"
                        }
                        width="38"
                        height="38"
                        data-id="column"
                        style={{
                          display: "inline-block",
                          fontSize: "44px",
                          cursor: "pointer",
                          verticalAlign: "bottom",
                          position: "relative",
                          bottom: "4px",
                        }}
                        className="material-icons"
                      />
                    </span>
                  </div>
                </div>
                {!this.state.taskBoardView ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        marginTop: "40px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          flex: ".5",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <button
                          id="new-bug"
                          type="button"
                          style={{
                            marginRight: "20px",
                          }}
                          onClick={this.handleCreateNewBug}
                          className="btn btn-overflow"
                        >
                          Create New Bug
                        </button>
                        <div
                          id="ticketSearch"
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            aria-hidden="true"
                            style={{
                              position: "absolute",
                              pointerEvents: "none",
                              left: "6px",
                              color: "#ababab",
                            }}
                            className="material-icons prefix"
                          >
                            search
                          </i>
                          <input
                            id="bug-search-input"
                            disabled={this.state.tickets.length === 0}
                            className="browser-default"
                            type="text"
                            onChange={this.updateSearch}
                            placeholder=" "
                            aria-label="Bugs search input field"
                          />
                          {this.state.search != "" && (
                            <button
                              onClick={this.clearInput}
                              className="link-btn material-icons grey-text"
                            >
                              close
                            </button>
                          )}
                        </div>
                        <p
                          aria-label={`Showing ${
                            this.state.search
                              ? `${tickets.length} out of ${this.state.ticketCount} bugs`
                              : `all ${this.state.ticketCount} bugs`
                          }`}
                          role="text"
                          style={{
                            whiteSpace: "pre",
                            alignSelf: "center",
                            padding: "0 20px",
                          }}
                        >
                          {`Displaying ${tickets.length >= 1 ? "1" : "0"}-${
                            tickets.length
                          } out of ${
                            this.state.ticketCount == null
                              ? "0"
                              : this.state.ticketCount
                          }`}
                        </p>
                      </div>
                      <SubNavbar
                        flex={[0, 0, 0]}
                        pageName=""
                        aria-hidden={false}
                        containerStyle={{
                          height: "70px",
                          flex: 3,
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                        height="70px"
                        maxWidth="100%"
                        center
                        sectionOneChildren={[
                          <CheckboxMenu
                            maxWidth="100%"
                            key={3}
                            count={
                              this.state.ticketFilterStatus.filter((x) => x)
                                .length
                            }
                            active={
                              this.state.ticketFilterStatus.filter((x) => x)
                                .length >= 1
                            }
                            options={[
                              {
                                label: "Open",
                                value: 0,
                                handler: this.handleTypeChange,
                                checked: this.state.ticketFilterStatus[0],
                              },
                              {
                                label: "Closed",
                                value: 1,
                                handler: this.handleTypeChange,
                                checked: this.state.ticketFilterStatus[1],
                              },
                              {
                                label: "Fixed",
                                value: 2,
                                handler: this.handleTypeChange,
                                checked: this.state.ticketFilterStatus[2],
                              },
                              {
                                label: "In Progress",
                                value: 3,
                                handler: this.handleTypeChange,
                                checked: this.state.ticketFilterStatus[3],
                              },
                              {
                                label: "Won't Fix",
                                value: 4,
                                handler: this.handleTypeChange,
                                checked: this.state.ticketFilterStatus[4],
                              },
                              {
                                label: "Reopened",
                                value: 5,
                                handler: this.handleTypeChange,
                                checked: this.state.ticketFilterStatus[5],
                              },
                            ]}
                            title="Filter By Status"
                            id="1"
                            containerMinWidth="160px"
                            narrow
                            icons={[
                              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                            ]}
                          />,
                        ]}
                        sectionTwoChildren={[
                          <CheckboxMenu
                            key={2}
                            searchEnabled
                            maxWidth="100%"
                            count={this.state.selectedCreatedByUsers.length}
                            active={
                              this.state.selectedCreatedByUsers.length >= 1
                            }
                            options={[
                              {
                                label: "Me",
                                value: this.props.current_user.id,
                                handler: this.handleCreatedByUserChange,
                                checked:
                                  this.state.selectedCreatedByUsers.indexOf(
                                    this.props.current_user.id
                                  ) !== -1,
                              },
                              {
                                label: "PlusQA Team",
                                value: "pqa",
                                handler: this.handleCreatedByUserChange,
                                checked:
                                  this.state.selectedCreatedByUsers.indexOf(
                                    "pqa"
                                  ) !== -1,
                              },
                              {
                                label: "Clients",
                                value: "client",
                                handler: this.handleCreatedByUserChange,
                                checked:
                                  this.state.selectedCreatedByUsers.indexOf(
                                    "client"
                                  ) !== -1,
                              },
                              ...this.state.filterCreatedByUsers.map(
                                (user) => ({
                                  label: user.label,
                                  value: user.value,
                                  handler: this.handleCreatedByUserChange,
                                  checked:
                                    this.state.selectedCreatedByUsers.indexOf(
                                      user.value
                                    ) !== -1,
                                  classList: "user-option",
                                })
                              ),
                            ]}
                            title="Filter By Tester"
                            id="2"
                            icons={[
                              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                            ]}
                          />,
                        ]}
                        sectionThreeChildren={[
                          <CheckboxMenu
                            key={3}
                            searchEnabled
                            maxWidth="100%"
                            containerMinWidth="175px"
                            count={this.state.selectedAssignedToUsers.length}
                            last
                            active={
                              this.state.selectedAssignedToUsers.length >= 1
                            }
                            options={[
                              {
                                label: "Me",
                                value: this.props.current_user.id,
                                handler: this.handleAssignedToUserChange,
                                checked:
                                  this.state.selectedAssignedToUsers.indexOf(
                                    this.props.current_user.id
                                  ) !== -1,
                              },
                              ...this.state.filterAssignedToUsers.map(
                                (user) => ({
                                  label: user.label,
                                  value: user.value,
                                  handler: this.handleAssignedToUserChange,
                                  checked:
                                    this.state.selectedAssignedToUsers.indexOf(
                                      user.value
                                    ) !== -1,
                                  classList: "user-option",
                                })
                              ),
                            ]}
                            title="Filter By Assignee"
                            id="3"
                            icons={[
                              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                            ]}
                          />,
                        ]}
                      />
                      {this.state.selectedAssignedToUsers.length +
                        this.state.selectedCreatedByUsers.length +
                        this.state.ticketFilterStatus.filter((x) => x).length >=
                        1 && (
                        <button
                          style={{
                            flex: 0.2,
                            whiteSpace: "pre",
                            alignSelf: "center",
                            color: "#519acc",
                            cursor: "pointer",
                          }}
                          onClick={this.resetFilters}
                          className="btn link-btn"
                        >
                          Clear All Filters
                        </button>
                      )}
                    </div>
                    {this.state.loaded === false ? (
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          flex: "1",
                        }}
                      >
                        <div id="test-step-bugs-loader" />
                        <div />
                      </div>
                    ) : (
                      <div style={{ display: "flex", flex: 1 }}>
                        <AutoSizer>
                          {({ width, height }) => (
                            <Table
                              ref={this.Table}
                              id="ticket-container-table"
                              width={width}
                              height={height}
                              headerHeight={25}
                              onScroll={(height) => this.tableScroll(height)}
                              className="striped highlight"
                              rowHeight={32}
                              sortBy={this.state.column}
                              noRowsRenderer={noRowsRenderer}
                              sortDirection={this.state.sortDirection}
                              headerRowRenderer={headerRowRenderer}
                              overscanRowCount={2}
                              rowRenderer={rowRenderer}
                              rowCount={tickets.length}
                              rowGetter={({ index }) => tickets[index]}
                            >
                              <Column label="Id" dataKey="id" width={60} />
                              <Column
                                headerClassName="flexBasisAuto"
                                width={70}
                                label="Priority"
                                dataKey="ticket_priority_id"
                              />
                              <Column
                                width={360}
                                flexGrow={1}
                                label="Title"
                                dataKey="title"
                              />
                              <Column
                                width={60}
                                label="Status"
                                dataKey="ticket_status_id"
                              />
                              <Column
                                width={100}
                                label="Assignee"
                                dataKey="assignee_id"
                              />
                              <Column
                                width={100}
                                label="Reporter"
                                dataKey="tester_id"
                              />
                              <Column
                                width={100}
                                label="WCAG"
                                dataKey="a11y_id"
                              />
                              <Column
                                width={100}
                                label="Created"
                                dataKey="created_at"
                              />
                            </Table>
                          )}
                        </AutoSizer>
                      </div>
                    )}
                    <div
                      className={tickets.length >= 1 ? "" : "noTickets"}
                      style={{
                        alignSelf: "center",
                        alignContent: "center",
                        marginLeft: "-29px",
                      }}
                    />
                  </div>
                ) : tickets.length >= 1 ? (
                  [
                    <div
                      id="column-headings-container"
                      key="column-headings-container"
                    >
                      <span className="task-column-heading">
                        Open
                        <span>{`(${toDoTickets.length})`}</span>
                      </span>
                      <span className="task-column-heading">
                        Reopened
                        <span>{`(${reopenedTickets.length})`}</span>
                      </span>
                      <span className="task-column-heading">
                        In Progress
                        <span>{`(${inProgressTickets.length})`}</span>
                      </span>
                      <span className="task-column-heading">
                        Fixed
                        <span>{` (${fixedTickets.length})`}</span>
                      </span>
                      <span className="task-column-heading">
                        <span
                          style={{ opacity: "1", transition: "opacity 0.3s" }}
                          id="column-closed-text"
                        >
                          Closed
                        </span>
                        <span
                          style={{ opacity: "1", transition: "opacity 0.3s" }}
                          id="column-text-slash"
                        >
                          /
                        </span>
                        <span
                          style={{ opacity: "1", transition: "opacity 0.3s" }}
                          id="column-wont-fix-text"
                        >
                          Won&apos;t Fix
                        </span>
                        <span>{`(${closedTickets.length})`}</span>
                      </span>
                    </div>,
                    <DragDropContext
                      onDragEnd={this.onDragEnd}
                      key="column-container"
                    >
                      <div id="column-container">
                        <TaskColumn
                          droppableId={1}
                          currentTicket={this.state.currentTicketId}
                          tickets={toDoTickets}
                          columnId="to-do-column"
                          headingText="To Do"
                        />
                        <TaskColumn
                          droppableId={6}
                          currentTicket={this.state.currentTicketId}
                          tickets={reopenedTickets}
                          columnId="reopened-column"
                          headingText="Reopened"
                        />
                        <TaskColumn
                          droppableId={4}
                          currentTicket={this.state.currentTicketId}
                          tickets={inProgressTickets}
                          columnId="in-progress"
                          headingText="In Progress"
                        />
                        <TaskColumn
                          droppableId={3}
                          currentTicket={this.state.currentTicketId}
                          tickets={fixedTickets}
                          columnId="fixed-column"
                          headingText="Fixed"
                        />
                        <TaskColumn
                          droppableId={2}
                          currentTicket={this.state.currentTicketId}
                          tickets={closedTickets}
                          columnId="closed-column"
                          headingText="Closed"
                          currentUser={this.props.current_user}
                        />
                      </div>
                    </DragDropContext>,
                  ]
                ) : (
                  <div
                    style={{
                      pointerEvents: "none",
                      fontSize: "20px",
                      textAlign: "center",
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No bugs found
                  </div>
                )}
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "54px",
                  }}
                >
                  {tickets.length >= 1 && (
                    <ExportLinks
                      bugs={tickets}
                      project={this.state.project}
                      ticketCount={tickets.length}
                      taskBoardViewOpen={this.state.taskBoardView}
                    />
                  )}
                </div>
              </div>
              <div id="ticketSideBar">
                <div
                  id="form-update-message"
                  className={this.state.loadingClass}
                >
                  {this.state.loadingText}
                </div>
                <Sidebar
                  ref={this.listNode}
                  sidebar={this.state.sidebarClosed ? "" : content}
                  children=""
                  open={this.state.sidebarOpen}
                  pullRight
                  overlayId="ticket-overlay"
                  shadow={false}
                  rootId="root-sidebar"
                  styles={{
                    sidebar: {
                      zIndex: 1,
                      background: "white",
                      width: "50%",
                      height: "100%",
                      position: "fixed",
                      bottom: 0,
                      transition: "transform .5s ease-out",
                      WebkitTransition: "-webkit-transform .5s ease-out",
                      overflowY: "auto",
                      overflowX: "hidden",
                    },
                    root: {
                      height: "100%",
                      left: "50%",
                      width: "100%",
                      position: "static",
                      transition: "transform .5s ease-out",
                      zIndex: "4",
                      WebkitTransition: "-webkit-transform .5s ease-out",
                    },
                    overlay: {
                      zIndex: 0,
                      display: "none",
                    },
                    content: {
                      position: "static",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              maxWidth: `calc(100% - ${this.state.sideBarWidth + 18}px)`,
              willChange: "max-width",
              transition: "max-width 0.666s ease-in-out 0s",
              marginLeft: "auto",
              height: "100%",
            }}
          >
            <div
              style={{
                flex: 1,
                alignItems: "center",
                display: "inline-flex",
                height: "90px",
                width: "100%",
                paddingLeft: "20px",
                borderBottom: "solid 1px lightgray",
              }}
            >
              <img
                aria-hidden="true"
                style={{ marginRight: "10px" }}
                height="40"
                width="40"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20-%20Filled.svg"
              />
              <h1 tabIndex={-1} className="large-title">
                {" "}
                Bugs{" "}
              </h1>
            </div>
            <div
              style={{
                width: "100%",
                height: "calc(100% - 100px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "90px", marginBottom: "20px" }}
                src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/Icon_Bugs_AccessDenied.svg"
              />
              <h2
                style={{
                  width: "500px",
                  marginBottom: "20px",
                  textAlign: "center",
                  fontFamily: "manrope",
                }}
              >
                You don't have permission to view this page.
              </h2>
              <p
                style={{
                  width: "500px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Before you can view Bugs for this project, you must first be
                added as a team member. Please contact your project manager if
                this is unexpected.
              </p>
              <a
                style={{
                  width: "175px",
                  textAlign: "center",
                  height: "48px",
                  lineHeight: "48px",
                }}
                className="common-button-submit"
                href="/dashboard"
              >
                Navigate to Dashboard
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const { arrayOf, string, bool, number, shape, any } = PropTypes;

TicketsList.propTypes = {
  a11yOptions: arrayOf(shape({ label: string, value: number })),
  action: string,
  blocked: bool,
  browser_options: arrayOf(shape({ label: string, value: number })),
  current_user: userTypes,
  device_options: arrayOf(shape({ label: string, value: number })),
  new_badge: any,
  project: projectTypes,
  projectOptions: arrayOf(shape({ label: string, value: number })),
  ticket: ticketTypes,
  ticketLabels: arrayOf(shape({ label: string, value: number })),
  ticket_count: number,
  tickets: arrayOf(ticketIndexTypes),
  topDevices: arrayOf(arrayOf(number)),
  userOptions: arrayOf(
    shape({
      email_notifications: bool,
      id: number,
      image: string,
      label: string,
      user_name: string,
      value: number,
    })
  ),
  watcherOptions: arrayOf(
    shape({
      email_notifications: bool,
      id: number,
      image: string,
      label: string,
      user_name: string,
      value: number,
    })
  ),
};

export default WithCable(TicketsList);
