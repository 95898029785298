import React from "react";
import PropTypes from "prop-types";

const WikiCategoryTeams = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      style={svgStyles}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 36C28.3888 36 36 28.3888 36 19C36 9.61116 28.3888 2 19 2C9.61116 2 2 9.61116 2 19C2 28.3888 9.61116 36 19 36ZM19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z"
        fill="#F4F4F4"
      />
      <path
        d="M17 10.5C17 12.433 15.433 14 13.5 14C11.5671 14 10 12.433 10 10.5C10 8.5671 11.5671 7 13.5 7C15.433 7 17 8.56713 17 10.5Z"
        fill="#F4F4F4"
      />
      <path
        d="M17.1244 14C16.2351 14.9467 14.9469 15.5576 13.5 15.5576C12.053 15.5576 10.7648 14.9467 9.87557 14C8.13748 15.2142 7 17.142 7 19.3325V19.7784C7 20.4515 7.58108 21 8.3 21H18.7C19.4189 21 20 20.4515 20 19.7784V19.3325C20 17.1421 18.8625 15.2145 17.1244 14H17.1244Z"
        fill="#F4F4F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1729 14.9177C16.1952 15.7458 14.9126 16.2576 13.5 16.2576C12.0873 16.2576 10.8048 15.7457 9.82703 14.9177C8.5154 16.0152 7.7 17.5873 7.7 19.3325V19.7784C7.7 20.0254 7.92696 20.3 8.3 20.3H18.7C19.073 20.3 19.3 20.0254 19.3 19.7784V19.3325C19.3 17.5874 18.4846 16.0154 17.1729 14.9177ZM17.1244 14C18.8625 15.2145 20 17.1421 20 19.3325V19.7784C20 20.4515 19.4189 21 18.7 21H8.3C7.58108 21 7 20.4515 7 19.7784V19.3325C7 17.142 8.13748 15.2142 9.87557 14C10.7648 14.9467 12.053 15.5576 13.5 15.5576C14.9469 15.5576 16.2351 14.9467 17.1244 14H17.1244Z"
        fill="#5340B9"
      />
      <path
        d="M28 10.5C28 12.433 26.433 14 24.5 14C22.5671 14 21 12.433 21 10.5C21 8.5671 22.5671 7 24.5 7C26.433 7 28 8.56713 28 10.5Z"
        fill="#F4F4F4"
      />
      <path
        d="M28.1244 14C27.2351 14.9467 25.9469 15.5576 24.5 15.5576C23.053 15.5576 21.7648 14.9467 20.8756 14C19.1375 15.2142 18 17.142 18 19.3325V19.7784C18 20.4515 18.5811 21 19.3 21H29.7C30.4189 21 31 20.4515 31 19.7784V19.3325C31 17.1421 29.8625 15.2145 28.1244 14H28.1244Z"
        fill="#F4F4F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1729 14.9177C27.1952 15.7458 25.9126 16.2576 24.5 16.2576C23.0873 16.2576 21.8048 15.7457 20.827 14.9177C19.5154 16.0152 18.7 17.5873 18.7 19.3325V19.7784C18.7 20.0254 18.927 20.3 19.3 20.3H29.7C30.073 20.3 30.3 20.0254 30.3 19.7784V19.3325C30.3 17.5874 29.4846 16.0154 28.1729 14.9177ZM28.1244 14C29.8625 15.2145 31 17.1421 31 19.3325V19.7784C31 20.4515 30.4189 21 29.7 21H19.3C18.5811 21 18 20.4515 18 19.7784V19.3325C18 17.142 19.1375 15.2142 20.8756 14C21.7648 14.9467 23.053 15.5576 24.5 15.5576C25.9469 15.5576 27.2351 14.9467 28.1244 14H28.1244Z"
        fill="#503EB9"
      />
      <path
        d="M23.3077 18.3077C23.3077 20.6868 21.3791 22.6154 19 22.6154C16.621 22.6154 14.6923 20.6868 14.6923 18.3077C14.6923 15.9287 16.6211 14 19 14C21.3791 14 23.3077 15.9288 23.3077 18.3077Z"
        fill="#F4F4F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21.9154C20.9925 21.9154 22.6077 20.3002 22.6077 18.3077C22.6077 16.3154 20.9925 14.7 19 14.7C17.0077 14.7 15.3923 16.3153 15.3923 18.3077C15.3923 20.3002 17.0076 21.9154 19 21.9154ZM19 22.6154C21.3791 22.6154 23.3077 20.6868 23.3077 18.3077C23.3077 15.9288 21.3791 14 19 14C16.6211 14 14.6923 15.9287 14.6923 18.3077C14.6923 20.6868 16.621 22.6154 19 22.6154Z"
        fill="#523FB9"
      />
      <path
        d="M23.4608 22.6154C22.3663 23.7806 20.7808 24.5323 19 24.5323C17.2191 24.5323 15.6336 23.7806 14.5392 22.6154C12.4 24.1098 11 26.4824 11 29.1784V29.7272C11 30.5557 11.7152 31.2307 12.6 31.2307H25.4C26.2848 31.2307 27 30.5557 27 29.7272V29.1784C27 26.4825 25.6 24.1101 23.4608 22.6154H23.4608Z"
        fill="#F4F4F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5179 23.5336C22.3306 24.5805 20.7484 25.2323 19 25.2323C17.2515 25.2323 15.6693 24.5805 14.482 23.5336C12.7729 24.9151 11.7 26.9308 11.7 29.1784V29.7272C11.7 30.1296 12.0611 30.5307 12.6 30.5307H25.4C25.9389 30.5307 26.3 30.1296 26.3 29.7272V29.1784C26.3 26.9309 25.2271 24.9153 23.5179 23.5336ZM23.4608 22.6154C25.6 24.1101 27 26.4825 27 29.1784V29.7272C27 30.5557 26.2848 31.2307 25.4 31.2307H12.6C11.7152 31.2307 11 30.5557 11 29.7272V29.1784C11 26.4824 12.4 24.1098 14.5392 22.6154C15.6336 23.7806 17.2191 24.5323 19 24.5323C20.7808 24.5323 22.3663 23.7806 23.4608 22.6154H23.4608Z"
        fill="#523FB9"
      />
    </svg>
  );
};

WikiCategoryTeams.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

WikiCategoryTeams.defaultProps = {
  height: "38px",
  width: "38px",
  svgStyles: {},
  color: "#f4f4f4",
};

export default WikiCategoryTeams;
