import React from 'react'

class ArrowIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25.76 27.1"
        className="icons"
      >
        <g id="Layer_5" data-name="Layer 5">
          <polygon points="17.84 0.31 25.49 0.31 25.49 2.8 25.49 7.64 23 7.64 23 2.8 17.84 2.8 17.84 0.31"/>
          <path d="M2.42,26.81.16,24.68,7.6,16.76l2.26,2.13ZM13.05,15.49l-2.27-2.12,7.44-7.92,2.26,2.13Z"/>
        </g>
      </svg>
    );
  }
}
ArrowIcon.defaultProps = {
  color: "white",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default ArrowIcon