import React from 'react'

class Shake extends React.PureComponent {
   constructor(props) {
   super(props);
  }

  render() {
    return(
    <div style={{display: "flex", maxWidth:"920px", borderBottom:"solid 1px lightgray", paddingBottom:"40px", justifyContent: "center", marginTop:"10px", marginLeft:"10px", fontSize: "11pt", color: "rgba(0,0,0,0.55)", fontFamily: "arial"}}>
      <div style={{width:"70%"}}>
        <img alt="shake icon" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Shake%20Colored.svg" style={{display:"block", width:"110px", height:"110px", marginLeft:"-10px"}}/>
        <div style={{margin:"22px 0px"}}><span style={{fontWeight:"600", color:"#519acc"}}>Shake Report</span> is a small library which makes it easy for testers to report bugs.</div>
        <div>Simply shake your iOS or Android device and retrieve:<br/>
          - A <span style={{fontWeight:"600", color:"#519acc"}}>screenshot</span> of the current view<br/>
          - <span style={{fontWeight:"600", color:"#519acc"}}>Log files</span> for the current session<br/>
          - A <span style={{fontWeight:"600", color:"#519acc"}}>detailed crash report</span> (if a crash has been reported)<br/>
          - The dumped hierarchy view
        </div>
        <div style={{margin:"22px 0px 24px 0px"}}>To use <span style={{fontWeight:"600", color:"#519acc"}}>Shake Report</span>, simply download the library from one of the links below:</div>  
        <div>
          <a href="https://github.com/PQALAB/ShakeReportiOS" className="btn btn-overflow" rel="noreferrer" target="_blank">Download iOS</a>
          <a style={{marginLeft: "20px"}} href="http://shake.betacrash.com/" id="android-btn" className="btn" rel="noreferrer" target="_blank">Download Android</a>
        </div>
      </div>
      <div style={{width:"30%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <img alt="iphone 11" style={{height: "450px"}} src='https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/iPhone11.png' className="hide-on-med-and-down responsive-img" />
      </div>
    </div>
    )
  }

}

export default Shake
