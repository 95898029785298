import React from 'react'
import ProgressRing from '../common/progress_ring'

class PerformanceMonthCompare extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      
    }
    this.eventEngagementRef = React.createRef()
    this.containerRef = React.createRef()
    this.resize = null
  }

  componentDidMount(){
    if(this.containerRef.current && this.eventEngagementRef.current){
      if(this.containerRef.current.clientWidth > 535){
        this.eventEngagementRef.current.style.display = "block"
      } else {
        this.eventEngagementRef.current.style.display = "none"
      }
    }
    var resizeTimer
    this.resize = (e) => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        if(this.containerRef.current && this.eventEngagementRef.current){
          if(this.containerRef.current.clientWidth > 535){
            this.eventEngagementRef.current.style.display = "block"
          } else {
            this.eventEngagementRef.current.style.display = "none"
          }
        }
      },100)
    }
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount(){
    removeEventListener('resize', this.resize)
  }

  render(){
    var total = this.props.workEventsLastMonth+this.props.workEventsThisMonth
    var progress = total > 0 ? (this.props.workEventsThisMonth/(this.props.workEventsLastMonth+this.props.workEventsThisMonth))*100 : 0
    return(
      <div ref={this.containerRef} id="performance-month-compare-container">
        <div>
          <h3 style={{fontFamily:"manrope", fontWeight:"700"}}>Bugs Filed</h3>
          <ProgressRing 
            radius={ 42 }
            relative={true}
            stroke={14}
            text={""}
            textStyles={{fontWeight:"600", fontSize: "13pt", fill: "black"}}
            progress={(this.props.bugsThisMonth/(this.props.bugsLastMonth+this.props.bugsThisMonth))*100} 
            phantomRing={true}
            color="#519acc"
            backgroundCircleColor="#f1563c"
            containerStyle={{display:"block", margin:"0 auto", transition:"none"}}
          />
          <div style={{marginTop:"10px"}}>
            <div style={{backgroundColor:"#519acc"}} />
            <span>{this.props.bugsThisMonth} This Month</span>
          </div>
          <div>
            <div style={{backgroundColor:"#f1563c"}} />
            <span>{this.props.bugsLastMonth} Last Month</span>
          </div>
        </div>
        <div ref={this.eventEngagementRef}>
          <h3 style={{fontFamily:"manrope", fontWeight:"700"}}>Event Engagement</h3>
          <ProgressRing 
            radius={ 42 }
            relative={true}
            stroke={14}
            text={""}
            textStyles={{fontWeight:"600", fontSize: "13pt", fill: "black"}}
            progress={progress} 
            phantomRing={true}
            color="#13b740"
            backgroundCircleColor="#ddd933"
            containerStyle={{display:"block", margin:"0 auto"}}
          />
          <div style={{marginTop:"10px"}}>
            <div style={{backgroundColor:"#13b740"}} />
            <span>{this.props.workEventsThisMonth} This Month</span>
          </div>
          <div>
            <div style={{backgroundColor:"#ddd933"}} />
            <span>{this.props.workEventsLastMonth} Last Month</span>
          </div>
        </div>
        <div>
          <h3 style={{fontFamily:"manrope", fontWeight:"700"}}>Test Cases Completed</h3>
          <ProgressRing 
            radius={ 42 }
            relative={true}
            stroke={14}
            text={""}
            textStyles={{fontWeight:"600", fontSize: "13pt", fill: "black"}}
            progress={(this.props.testCasesThisMonth/(this.props.testCasesLastMonth+this.props.testCasesThisMonth))*100} 
            phantomRing={true}
            color="#544aaf"
            backgroundCircleColor="#33cccc"
            containerStyle={{display:"block", margin:"0 auto"}}
          />
          <div style={{marginTop:"10px"}}>
            <div style={{backgroundColor:"#544aaf"}} />
            <span>{this.props.testCasesThisMonth} This Month</span>
          </div>
          <div>
            <div style={{backgroundColor:"#33cccc"}} />
            <span>{this.props.testCasesLastMonth} Last Month</span>
          </div>
        </div>
      </div>
    )
  }
}

export default PerformanceMonthCompare