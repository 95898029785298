import React from "react";

const TestPassBronzeSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2{fill:#b85a24}.Skills__Test_Pass_-_Bronze_-_Small_svg__cls-3{opacity:.25}.Skills__Test_Pass_-_Bronze_-_Small_svg__cls-4{fill:#231f20}.Skills__Test_Pass_-_Bronze_-_Small_svg__cls-6{fill:#e6e7e8}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Test_Pass_-_Bronze_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <rect
          x={215.23}
          y={168.39}
          width={649.55}
          height={768}
          rx={66.43}
          fill="#a34c21"
        />{" "}
        <rect
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2"
          x={215.23}
          y={156}
          width={649.55}
          height={768}
          rx={66.43}
        />{" "}
        <g className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-3">
          {" "}
          <rect
            className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-4"
            x={316.02}
            y={296.15}
            width={447.61}
            height={551.82}
            rx={15.49}
          />{" "}
        </g>{" "}
        <g className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-3">
          {" "}
          <path
            className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-4"
            d="M531.6 296.15h216.63a15.35 15.35 0 0115.35 15.35v509.66a15.35 15.35 0 01-15.35 15.35H531.6V296.15z"
          />{" "}
        </g>{" "}
        <rect
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2"
          x={315.78}
          y={284.52}
          width={448.44}
          height={551.82}
          rx={15.51}
        />{" "}
        <path fill="#fff" d="M345.18 305.52h386.73v498.19H345.18z" />{" "}
        <path
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-6"
          d="M517.12 265.46a15.26 15.26 0 0114.7-15.21V232c-41.67 0-36.82 46.55-36.82 46.55l-74.63 28.1v27.05l111.4.09v-53a15.32 15.32 0 01-14.65-15.33z"
        />{" "}
        <path
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-6"
          d="M568.59 278.55S573.44 232 531.76 232v18.13h.16a15.35 15.35 0 110 30.7h-.16v53h111.46v-27.18z"
        />{" "}
        <path
          fill="#bcbec0"
          d="M420.26 327.98h222.96v5.82H420.26z"
          opacity={0.88}
        />{" "}
        <path
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2"
          d="M417.6 423.68a2.45 2.45 0 001.71-.71L437 404.9a2.39 2.39 0 10-3.42-3.35l-16 16.28-7.81-8.38a2.37 2.37 0 00-1.67-.76h-.08a2.41 2.41 0 00-1.63.64 2.37 2.37 0 00-.76 1.67 2.34 2.34 0 00.64 1.71l9.52 10.21a2.33 2.33 0 001.72.76"
        />{" "}
        <path
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2"
          d="M421.43 439.51a27.78 27.78 0 1127.78-27.78 27.81 27.81 0 01-27.78 27.78zm0-51.78a24 24 0 1024 24 24 24 0 00-24-24zM417.6 530a2.42 2.42 0 001.71-.72L437 511.19a2.39 2.39 0 00-3.42-3.35l-16 16.28-7.81-8.37a2.37 2.37 0 00-1.67-.76h-.08a2.37 2.37 0 00-1.63.65 2.33 2.33 0 00-.76 1.66 2.38 2.38 0 00.64 1.72l9.52 10.21a2.41 2.41 0 001.72.76"
        />{" "}
        <path
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2"
          d="M421.43 545.8a27.78 27.78 0 1127.78-27.8 27.81 27.81 0 01-27.78 27.8zm0-51.77a24 24 0 1024 24 24 24 0 00-24-24.03zM417.6 634.33a2.42 2.42 0 001.71-.72L437 615.55a2.39 2.39 0 10-3.42-3.35l-16 16.28-7.81-8.38a2.37 2.37 0 00-1.67-.76h-.08a2.41 2.41 0 00-1.63.64 2.37 2.37 0 00-.76 1.67 2.34 2.34 0 00.64 1.71l9.52 10.21a2.37 2.37 0 001.72.76"
        />{" "}
        <path
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2"
          d="M421.43 650.16a27.78 27.78 0 1127.78-27.78 27.81 27.81 0 01-27.78 27.78zm0-51.78a24 24 0 1024 24 24 24 0 00-24-24zM675.06 416.74H486a5 5 0 010-10h189.06a5 5 0 010 10zM675.06 523H486a5 5 0 010-10h189.06a5 5 0 010 10zM675.06 627.39H486a5 5 0 010-10h189.06a5 5 0 010 10zM675.06 733.2H486a5 5 0 010-10h189.06a5 5 0 010 10zM417.6 740.14a2.42 2.42 0 001.71-.72L437 721.35a2.39 2.39 0 10-3.4-3.35l-16 16.28-7.81-8.38a2.37 2.37 0 00-1.67-.76h-.08a2.39 2.39 0 00-1.75 4l9.52 10.21a2.37 2.37 0 001.72.76"
        />{" "}
        <path
          className="Skills__Test_Pass_-_Bronze_-_Small_svg__cls-2"
          d="M421.43 756a27.78 27.78 0 1127.78-27.78A27.82 27.82 0 01421.43 756zm0-51.78a24 24 0 1024 24 24 24 0 00-24-24.03z"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default TestPassBronzeSmall;
