import React from 'react'
import moment from 'moment'
import Swal from "sweetalert2"
import DashboardTimeSelector from '../devices/dashboard_time_selector.jsx'
import PerformanceMonthCompare from './performance_month_compare.jsx'

class Performance extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      scope: "allTime",
      startDate: moment(new Date()).startOf('week'),
      endDate: moment(new Date()).endOf('week'),

    }
  }

  handleRedeemPoints = (e) =>{
    var index = parseInt(e.target.dataset.index)
    var self = this
    Swal.fire({
      title: "Redeem Points",
      text: "Did you attent this event? Please be honest!",
      reverseButtons: true,
      showCancelButton:true,
      confirmButtonAriaLabel: 'Yes',
      cancelButtonAriaLabel: 'No',
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.value) {
        this.props.handleWorkEvent(this.props.workEvents[index], "redeem")
      } else if (result.dismiss && result.dismiss==="cancel") {
        this.props.handleWorkEvent(this.props.workEvents[index], "remove")
      }
    })
  }

  handleScope = (scope) => {
    this.setState({scope})
    this.props.handlePerformanceScope(scope)
  }

  handleDate = (startDate, endDate) => {
    this.setState({startDate, endDate, scope:"custom"})
    this.props.handlePerformanceScope(scope, startDate. endDate)
  }

  render(){
    var workEvents = this.props.workEvents
    return(
      <div style={{height:"100%"}}>
        <div style={{ display:"flex", boxShadow:"lightgrey 0px 0px 3px 1px", borderRadius:"6px", height:"209px", width:"calc(100% - 10px)", marginBottom:"10px"}}>
          <div id="performance-scope-container" style={{display:"flex", flexDirection:"column", alignItems:"center", height:"100%", padding:"20px 40px", borderRight:"solid 2px lightgray"}}>
            <img style={{width:"40px", marginBottom:"20px"}} src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/TPLogo_2021_Color.png" />
            <DashboardTimeSelector 
              key={2}
              handler={this.handleScope}
              customHandler={this.handleDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              scope={this.state.scope}
              closeOnSelect={true}
              containerStyle={{position:"relative", left:"0px", top:"0", marginRight: "0px"}}
            />
            <div className="performance-scope-values" >
              <div>
                <span>{this.props.scopeBugs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                <span>BUGS</span>
              </div>
              <div>
                <span>{this.props.scopeEvents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                <span>EVENTS</span>
              </div>
              <div>
                <span>{this.props.scopeTestCases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                <span>TEST CASES</span>
              </div>
            </div>
          </div>
          <PerformanceMonthCompare 
            workEventsThisMonth={this.props.workEventsThisMonth}
            workEventsLastMonth={this.props.workEventsLastMonth}
            bugsThisMonth={this.props.bugsThisMonth}
            bugsLastMonth={this.props.bugsLastMonth}
            testCasesThisMonth={this.props.testCasesThisMonth}
            testCasesLastMonth={this.props.testCasesLastMonth}
          />
        </div>
        <div style={{boxShadow:"lightgrey 0px 0px 3px 1px", borderRadius:"6px", height:"calc(100% - 219px)", width:"calc(100% - 10px)", padding:"20px"}}>
          <h2 style={{fontSize:"18px", marginBottom:"20px", fontFamily:"manrope", fontWeight:"700"}}>Event Attendance History</h2>
          <div style={{height:"calc(100% - 48px)", overflowY:"auto", width:"calc(100% - 0px)", display:"flex", flexWrap:"wrap", justifyContent:"space-around"}} className="event-group new-scrollbar">
            {workEvents.map((e, i)=>{
              var month = moment(e.start_time).format("MMMM")
              var lastQuarter = moment(e.start_time).isBefore(moment().startOf('quarter'))
              return(
                <div key={e.id} aria-label={`Event ${(i+1)} of ${workEvents.length}`} style={{marginRight:"10px", display:"inline-block", verticalAlign:"top", width:"209px", marginBottom:"10px"}} className={`event-card`}>
                    <p aria-label={`${month} ${moment(e.start_time).format("Do")}`}><span aria-hidden="true">{moment(e.start_time).format("DD")}</span></p>
                    <p aria-hidden="true" style={{fontWeight:"700"}} className="small-title">{month}</p>
                    <p className="small-title">{e.title}</p>
                    <p aria-label={`Time: ${moment(e.start_time).format("h:mmA")} to ${moment(e.end_time).format("h:mmA")}`}><span aria-hidden="true">{moment(e.start_time).format("h:mmA - ")}{moment(e.end_time).format("h:mmA")}</span></p>
                    <p aria-label={`Location: ${e.location}`}><span aria-hidden="true">{e.location}</span></p>
                    {e.attended_ids.indexOf(this.props.userId)===-1?
                    <button id={`event-${e.id}-button`} aria-label="View event" onClick={this.handleRedeemPoints} data-index={i} className="small-title dream-button">
                      <div aria-hidden="true" className="dream-border" />
                      <span aria-hidden="true" >REDEEM POINTS</span>
                      <div aria-hidden="true" >
                        <span>REDEEM POINTS</span>
                      </div>
                    </button>:
                    <div style={{borderRadius:"6px", backgroundColor:lastQuarter?"#cf74d0":"#57ba5b", textAlign:"center", height:"35px", marginTop:"18px", padding:"4px"}}>
                      <span style={{color:"white", fontFamily:"manrope", fontWeight:"600"}}>{e.points} POINTS EARNED</span>
                    </div>
                    }
                  </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Performance