import React from "react";
import PropTypes from "prop-types";

const CancelIcon = ({
  height = "20px",
  width = "20px",
  color = "#519acc",
  svgStyles = {},
}) => (
  <svg
    aria-hidden="true"
    height={height}
    width={width}
    fill={color}
    style={svgStyles}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <path d="M60.44,48.52c3.17,3.17,3.75,8.45.75,11.8a8.3,8.3,0,0,1-12.06.34L32,43.5l-.2.2-17,17A8.34,8.34,0,0,1,3,60.66,8.38,8.38,0,0,1,3,48.92L19.93,32a.1.1,0,0,0,0-.17L3.57,15.48C.37,12.31-.21,7.06,2.79,3.68a8.34,8.34,0,0,1,12.09-.34L32.05,20.5,49.21,3.34A8.37,8.37,0,0,1,61,3.34a8.32,8.32,0,0,1,0,11.74L44.05,32a.17.17,0,0,0,0,.17Z" />
  </svg>
);

CancelIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
};

export default CancelIcon;
