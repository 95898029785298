import React from "react";
import axios from "axios";
import moment from "moment";
import BasicSelect from "../common/basic_select";
import CheckboxMenu from "../common/checkbox_menu.jsx";
import RoundDropdown from "../icons/round_dropdown";
import { CSSTransition } from "react-transition-group";
import { CSVLink, CSVDownload } from "react-csv";

class EodPerformanceGraph extends React.Component {
  constructor(props) {
    super(props);
    // var arr1 = this.props.currentUser.projects.map((p)=>p.)
    this.state = {
      selectedTimeOption: { label: "Last 30 Days", value: "last30" },
      selectedMetric: "bugs",
      selectedUsers: [],
      graphData: [],
      csvData: [],
      columnWidth: 100,
      selectedScope: "month",
      loading: true,
      chartTransition: true,
      pickerTransition: false,
      labelTransition: false,
      graphLineIncrement: 5,
      hoursLineIncrement: 5,
      floatyArrowRight: false,
      floatyHighlight: null,
      lineCount: 5,
      hoverData: null,
      smallCircle: true,
      singleUser: false,
      singleUserIndex: 0,
      newColumnRampUp: false,
    };
    this.filterByOptions = [
      { label: "Project", value: "project" },
      { label: "Team", value: "team" },
      { label: "User", value: "user" },
    ];
    this.groupTimeOptions = [
      { label: "Last 30 Days", value: "last30" },
      { label: "Last 90 Days", value: "last90" },
      { label: "Last 180 Days", value: "last180" },
      { label: "Last 360 Days", value: "last360" },
    ];
    this.singleUserTimeOptions = [
      { label: "Last 6 Weeks", value: "last6weeks" },
      { label: "Last 6 Months", value: "last6months" },
    ];
    this.timeStartEndDates = {
      last30: { start: moment().subtract(30, "days"), end: moment() },
      last90: { start: moment().subtract(90, "days"), end: moment() },
      last180: { start: moment().subtract(180, "days"), end: moment() },
      last360: { start: moment().subtract(360, "days"), end: moment() },
      last6weeks: {
        start: moment().subtract(5, "weeks").startOf("week"),
        end: moment(),
      },
      last6months: {
        start: moment().subtract(5, "months").startOf("month"),
        end: moment(),
      },
    };
    this.containerRef = React.createRef();
    this.graphContainerRef = React.createRef();
    this.scrollboxRef = React.createRef();
    this.floatyRef = React.createRef();
    this.innerScrollboxRef = React.createRef();
  }

  componentDidMount() {
    this.getPerformanceData(
      this.timeStartEndDates[this.state.selectedTimeOption.value].start,
      this.timeStartEndDates[this.state.selectedTimeOption.value].end,
      this.state.selectedScope,
      this.props.selectedProjects
    );
  }

  handleTeamChange = (e) => {
    this.setState({ chartTransition: true });
    var value = parseInt(e.target.value);
    var teamPromise = new Promise((resolve, reject) => {
      this.props.handleTeamChange(value, resolve);
    });
    teamPromise.then((message) => {
      if (this.props.selectedTeams.length > 0) {
        this.getPerformanceData(
          this.timeStartEndDates[this.state.selectedTimeOption.value].start,
          this.timeStartEndDates[this.state.selectedTimeOption.value].end,
          this.state.selectedScope,
          this.props.selectedTeams
        );
      } else {
        this.setState({ labelTransition: true });
        setTimeout(() => {
          this.setState({
            graphData: [],
            labelTransition: false,
            chartTransition: false,
          });
        }, 300);
      }
    });
  };

  handleProjectChange = (e) => {
    this.setState({ chartTransition: true });
    var value = parseInt(e.target.value);
    var projectPromise = new Promise((resolve, reject) => {
      this.props.handleProjectChange(value, resolve);
    });
    projectPromise.then((message) => {
      if (this.props.selectedProjects.length > 0) {
        this.getPerformanceData(
          this.timeStartEndDates[this.state.selectedTimeOption.value].start,
          this.timeStartEndDates[this.state.selectedTimeOption.value].end,
          this.state.selectedScope,
          this.props.selectedProjects
        );
      } else {
        this.setState({ labelTransition: true });
        setTimeout(() => {
          this.setState({
            graphData: [],
            labelTransition: false,
            chartTransition: false,
          });
        }, 300);
      }
    });
  };

  handleUserChange = (e) => {
    this.setState({ chartTransition: true });
    var value = parseInt(e.target.value);
    var projectPromise = new Promise((resolve, reject) => {
      this.props.handleUserChange(value, resolve);
    });
    projectPromise.then((message) => {
      if (this.props.selectedUsers.length > 0) {
        if (
          (this.props.selectedUsers.length === 1 &&
            this.state.selectedTimeOption.value !== "last6weeks" &&
            this.state.selectedTimeOption.value !== "last6months") ||
          (this.props.selectedUsers.length > 1 &&
            (this.state.selectedTimeOption.value === "last6weeks" ||
              this.state.selectedTimeOption.value === "last6months"))
        ) {
          this.setState(
            {
              selectedTimeOption:
                this.props.selectedUsers.length === 1
                  ? { label: "Last 6 Weeks", value: "last6weeks" }
                  : { label: "Last 30 Days", value: "last30" },
              singleUserIndex: 0,
            },
            () => {
              this.getPerformanceData(
                this.timeStartEndDates[this.state.selectedTimeOption.value]
                  .start,
                this.timeStartEndDates[this.state.selectedTimeOption.value].end,
                this.state.selectedScope,
                this.props.selectedUsers
              );
            }
          );
        } else {
          this.getPerformanceData(
            this.timeStartEndDates[this.state.selectedTimeOption.value].start,
            this.timeStartEndDates[this.state.selectedTimeOption.value].end,
            this.state.selectedScope,
            this.props.selectedUsers
          );
          this.setState({ singleUserIndex: 0 });
        }
      } else {
        this.setState({ labelTransition: true });
        setTimeout(() => {
          this.setState({
            graphData: [],
            selectedTimeOption: { label: "Last 30 Days", value: "last30" },
            chartTransition: false,
            labelTransition: false,
          });
        }, 300);
      }
    });
  };

  clearFilters = () => {
    this.setState({ chartTransition: true }, () => {
      setTimeout(() => {
        this.props.clearFilters();
        this.setState({
          graphData: [],
          chartTransition: false,
          singleUserIndex: 0,
          selectedTimeOption: { label: "Last 30 Days", value: "last30" },
        });
      }, 300);
    });
  };

  getPerformanceData = (startDate, endDate, scope, ids) => {
    var ids = ids;
    var params = {
      filter_by: this.props.selectedFilter.value,
      start_date: startDate,
      end_date: endDate,
      ids,
      scope:
        this.state.selectedTimeOption.value === "last6weeks" ? "week" : "month",
    };
    this.setState({ loading: true, chartTransition: true }, () => {
      setTimeout(() => {
        axios
          .get("/eod_performance.json", {
            params,
          })
          .then((res) => {
            var graphData = [];
            var graphLineIncrement = 1;
            var hoursLineIncrement = 1;
            var lineCount = 5;
            var singleUser = false;
            if (
              this.props.selectedFilter.value === "project" ||
              this.props.selectedFilter.value === "team"
            ) {
              graphData = Object.entries(res.data);
              graphData.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase()
                  ? 1
                  : a[0].toLowerCase() < b[0].toLowerCase()
                  ? -1
                  : 0
              );
            } else if (this.props.selectedFilter.value === "user") {
              graphData = Object.entries(res.data);
              if (ids.length === 1) {
                singleUser = true;
                graphData.sort((a, b) =>
                  moment(a[0]).isAfter(moment(b[0]))
                    ? 1
                    : moment(a[0]).isBefore(moment(b[0]))
                    ? -1
                    : 0
                );
              } else {
                graphData.sort((a, b) =>
                  a[0].toLowerCase() > b[0].toLowerCase()
                    ? 1
                    : a[0].toLowerCase() < b[0].toLowerCase()
                    ? -1
                    : 0
                );
              }
            }
            var maxMetric = Math.max(
              ...graphData.map((d) =>
                this.state.selectedMetric === "bugs"
                  ? d[1].bugs
                  : d[1].test_steps
              )
            );
            var maxHours = Math.max(...graphData.map((d) => d[1].hours));
            var { graphLineIncrement, hoursLineIncrement, lineCount } =
              this.getResizedGraph(maxMetric, maxHours);
            this.setState(
              {
                lineCount,
                graphLineIncrement,
                hoursLineIncrement,
                graphData: graphData,
                singleUser,
                loading: false,
              },
              () => {
                if (this.props.selectedFilter.value === "project") {
                  this.props.setProjectUsersWithData(
                    graphData.map((u) => u[0])
                  );
                }
                var graphWidth = this.scrollboxRef.current.clientWidth;
                if (
                  graphData.length > 0 &&
                  graphWidth / graphData.length < 140
                ) {
                  this.setState({ smallCircle: true });
                } else {
                  this.setState({ smallCircle: false });
                }
                setTimeout(() => {
                  if (
                    this.scrollboxRef.current &&
                    this.scrollboxRef.current.scrollWidth >
                      this.scrollboxRef.current.clientWidth
                  ) {
                    this.scrollboxRef.current.style.paddingBottom = "0px";
                    setTimeout(() => {
                      this.setState({ chartTransition: false });
                    }, 100);
                  } else {
                    this.scrollboxRef.current.style.paddingBottom = "12px";
                    setTimeout(() => {
                      this.setState({ chartTransition: false });
                    }, 100);
                  }
                }, 50);
              }
            );
          })
          .catch((err) => {
            console.error(err);
          });
      }, 300);
    });
  };

  handleDataMetric = (e) => {
    var selectedMetric = e.target.dataset.metric;
    this.setState({ labelTransition: true });
    setTimeout(() => {
      var graphLineIncrement = 1;
      var hoursLineIncrement = 1;
      var lineCount = 5;
      var maxMetric = Math.max(
        ...this.state.graphData.map((d) =>
          selectedMetric === "bugs" ? d[1].bugs : d[1].test_steps
        )
      );
      while (maxMetric / 2.5 > graphLineIncrement * 2) {
        graphLineIncrement *= 2;
        if (graphLineIncrement === 4) {
          graphLineIncrement = 5;
        }
      }

      if (maxMetric / 2.5 > graphLineIncrement * 1.5) {
        lineCount = 6;
      }

      var maxHours = Math.max(...this.state.graphData.map((d) => d[1].hours));
      while (maxHours / (lineCount === 6 ? 2.5 : 2) > hoursLineIncrement * 2) {
        hoursLineIncrement *= 2;
        if (hoursLineIncrement === 4) {
          hoursLineIncrement = 5;
        }
      }

      this.setState({
        lineCount,
        graphLineIncrement,
        hoursLineIncrement,
        selectedMetric: selectedMetric,
        loading: false,
        labelTransition: false,
        chartTransition: false,
      });
    }, 300);
  };

  handleColumnMouseEnter = (e) => {
    if (
      this.floatyRef.current &&
      this.floatyRef.current.style.opacity !== "1" &&
      e.target.id &&
      e.target.id.includes("floaty")
    ) {
      var name = e.target.dataset.name;
      var metric = e.target.dataset.metricValue;
      var hours = e.target.dataset.hoursValue;
      var imageUrl = e.target.dataset.imageUrl;
      var id = e.target.id;
      if (id.includes("metric-column")) {
        this.setState({ floatyHighlight: "metric" });
      } else if (id.includes("hours-column")) {
        this.setState({ floatyHighlight: "hours" });
      }
      this.setState({
        hoverData: {
          name,
          metric,
          hours: parseFloat(hours).toFixed(2),
          imageUrl,
        },
      });
      this.floatyRef.current.style.opacity = "1";
      var elem = e.target;
      var rect = elem.getBoundingClientRect();
      var containerRect = this.containerRef.current.getBoundingClientRect();
      var floatyArrowRight = rect.right + 300 > containerRect.right;
      if (floatyArrowRight && !this.state.floatyArrowRight) {
        this.setState({ floatyArrowRight: true });
      } else if (!floatyArrowRight && this.state.floatyArrowRight) {
        this.setState({ floatyArrowRight: false });
      }

      var x =
        rect.x +
        (!floatyArrowRight
          ? elem.clientWidth +
            (!this.state.smallCircle && id.includes("image-circle") ? 16 : 10)
          : -210);
      var y =
        rect.y +
        elem.clientHeight / 2 -
        (this.state.smallCircle || this.props.selectedUsers.length === 1
          ? 108
          : 164) /
          2;
      this.floatyRef.current.style.transform = `translate(${x}px, ${y}px)`;
    }
  };

  handleColumnMouseLeave = () => {
    if (this.floatyRef.current) {
      this.floatyRef.current.style.opacity = "0";
      this.setState({ floatyHighlight: null });
    }
  };

  handleTimeOption = (e) => {
    this.setState({ selectedTimeOption: e, singleUserIndex: 0 }, () => {
      this.getPerformanceData(
        this.timeStartEndDates[this.state.selectedTimeOption.value].start,
        this.timeStartEndDates[this.state.selectedTimeOption.value].end,
        this.state.selectedScope,
        this.props.selectedFilter.value === "project"
          ? this.props.selectedProjects
          : this.props.selectedFilter.value === "user"
          ? this.props.selectedUsers
          : this.props.selectedTeams
      );
    });
  };

  handleFilterByChange = (e) => {
    var option = e;
    this.setState({ chartTransition: true }, () => {
      setTimeout(() => {
        this.props.handleFilterByChange(option);
        this.setState({
          graphData: [],
          chartTransition: false,
          singleUserIndex: 0,
        });
      }, 300);
    });
  };

  getResizedGraph = (maxMetric, maxHours) => {
    var graphLineIncrement = 1;
    var hoursLineIncrement = 1;
    var lineCount = 5;
    while (maxMetric / 2.5 > graphLineIncrement * 2) {
      graphLineIncrement *= 2;
      if (graphLineIncrement === 4) {
        graphLineIncrement = 5;
      }
    }
    if (maxMetric / 2.5 > graphLineIncrement * 1.5) {
      lineCount = 6;
    }
    while (maxHours / (lineCount === 6 ? 2.5 : 2) > hoursLineIncrement * 2) {
      hoursLineIncrement *= 2;
      if (hoursLineIncrement === 4) {
        hoursLineIncrement = 5;
      }
    }

    return { graphLineIncrement, hoursLineIncrement, lineCount };
  };

  shiftSingleUserData = (e) => {
    var direction = e.target.dataset.direction;
    if (direction === "left" && this.state.singleUserIndex === 0) {
      this.setState({ loading: true });
      var params = {
        user_id: this.props.selectedUsers[0],
        end_date: moment(this.state.graphData[0][0]).format("YYYY/MM/DD"),
        scope:
          this.state.selectedTimeOption.value === "last6weeks"
            ? "week"
            : "month",
      };
      axios
        .get("/more_single_user_performance.json", {
          params,
        })
        .then((res) => {
          var newGraphData = [
            ...Object.entries(res.data),
            ...this.state.graphData,
          ];
          var maxMetric = Math.max(
            ...newGraphData
              .slice(0, 6)
              .map((d) =>
                this.state.selectedMetric === "bugs"
                  ? d[1].bugs
                  : d[1].test_steps
              )
          );
          var maxHours = Math.max(
            ...newGraphData.slice(0, 6).map((d) => d[1].hours)
          );
          var { graphLineIncrement, hoursLineIncrement, lineCount } =
            this.getResizedGraph(maxMetric, maxHours);
          this.innerScrollboxRef.current.style.transition = "none";
          setTimeout(() => {
            this.setState(
              {
                graphData: newGraphData,
                loading: false,
                singleUserIndex: 1,
                newColumnRampUp: true,
              },
              () => {
                // this.innerScrollboxRef.current.style.transition = "transform 0.4s"
                setTimeout(() => {
                  this.innerScrollboxRef.current.style.transition =
                    "transform 0.4s";
                  if (
                    this.state.hoursLineIncrement !== hoursLineIncrement ||
                    this.state.graphLineIncrement !== graphLineIncrement ||
                    this.state.lineCount !== lineCount
                  ) {
                    this.setState({ labelTransition: true }, () => {
                      setTimeout(() => {
                        this.setState({
                          singleUserIndex: 0,
                          lineCount,
                          graphLineIncrement,
                          hoursLineIncrement,
                          newColumnRampUp: false,
                          labelTransition: false,
                        });
                      }, 300);
                    });
                  } else {
                    this.setState({
                      singleUserIndex: 0,
                      newColumnRampUp: false,
                    });
                  }
                }, 100);
              }
            );
          }, 100);
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
        });
    } else {
      var singleUserIndex =
        this.state.singleUserIndex + (direction === "left" ? -1 : 1);
      var newGraphData = this.state.graphData.slice(
        singleUserIndex,
        singleUserIndex + 6
      );
      var maxMetric = Math.max(
        ...newGraphData
          .slice(0, 6)
          .map((d) =>
            this.state.selectedMetric === "bugs" ? d[1].bugs : d[1].test_steps
          )
      );
      var maxHours = Math.max(
        ...newGraphData.slice(0, 6).map((d) => d[1].hours)
      );
      var { graphLineIncrement, hoursLineIncrement, lineCount } =
        this.getResizedGraph(maxMetric, maxHours);
      if (
        this.state.hoursLineIncrement !== hoursLineIncrement ||
        this.state.graphLineIncrement !== graphLineIncrement ||
        this.state.lineCount !== lineCount
      ) {
        this.setState({ labelTransition: true }, () => {
          setTimeout(() => {
            this.setState({
              singleUserIndex,
              lineCount,
              graphLineIncrement,
              hoursLineIncrement,
              labelTransition: false,
            });
          }, 300);
        });
      } else {
        this.setState({ singleUserIndex });
      }
    }
  };

  generateCsv = () => {
    var csvData = [];
    csvData.push([
      this.props.selectedUsers.length === 1 ? "Date Start" : "User",
      "Bugs",
      "Test Steps",
      "Hours",
    ]);
    this.state.graphData.forEach((d) => {
      csvData.push([
        d[0],
        d[1].bugs.toString(),
        d[1].test_steps.toString(),
        d[1].hours.toString(),
      ]);
    });
    this.setState({ csvData }, () => {
      setTimeout(() => {
        var elem = document.getElementById("csvLink");
        if (elem) {
          elem.click();
        }
      }, 500);
    });
  };

  generatePdf = () => {
    var selectedItems = [
      ...this.props.selectedUsers,
      ...this.props.selectedProjects,
      ...this.props.selectedTeams,
    ];
    var heading = "";
    if (selectedItems.length > 1) {
      heading = `Multiple ${this.props.selectedFilter.label}s`;
    } else if (selectedItems.length === 1) {
      var selectedItem = this.props[`${this.props.selectedFilter.value}s`].find(
        (o) => o.id === selectedItems[0]
      );
      if (selectedItem) {
        heading = selectedItem.name;
      }
    }
    var params = {
      heading: `${heading} - ${this.state.selectedTimeOption.label}`,
      graph_data: JSON.stringify(this.state.graphData),
    };
    axios
      .get(`/generate_skills_pdf.pdf`, {
        params,
      })
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  renderColumns = () => {
    var topNumber = this.state.graphLineIncrement * (this.state.lineCount - 1);
    var topHoursNumber =
      this.state.hoursLineIncrement * (this.state.lineCount - 1);
    return (
      <div
        ref={this.scrollboxRef}
        className="new-scrollbar horizontal"
        style={{
          width: "calc(100% - 36px)",
          transition: "padding 0.1s",
          paddingBottom: "0px",
          height: "100%",
          position: "absolute",
          left: "0",
          top: "0",
          display: "flex",
          flexWrap: "nowrap",
          overflowX: this.props.selectedUsers.length === 1 ? "hidden" : "auto",
          alignItems: "flex-end",
          justifyContent:
            this.state.graphData.length < 3 ? "space-around" : "space-between",
        }}
      >
        <div
          ref={this.innerScrollboxRef}
          style={{
            transition: "transform 0.4s",
            transform: `translateX(-${16.66 * this.state.singleUserIndex}%)`,
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "flex-end",
            justifyContent:
              this.state.graphData.length < 3
                ? "space-around"
                : "space-between",
          }}
        >
          {this.state.graphData.map((d, i) => {
            var metric =
              this.state.selectedMetric === "bugs"
                ? d[1].bugs
                : d[1].test_steps;
            var hours = d[1].hours;
            var height1 =
              this.state.newColumnRampUp && i === 0
                ? 0
                : (metric / topNumber) * 100;
            var height2 =
              this.state.newColumnRampUp && i === 0
                ? 0
                : (hours / topHoursNumber) * 100;
            var imageUrl = d[1].image_url
              ? d[1].image_url
              : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg";
            return (
              <div
                key={`${d[0]}-${i}}`}
                style={{
                  minWidth:
                    this.props.selectedUsers.length === 1 ? "16.66%" : `80px`,
                  maxWidth:
                    this.props.selectedUsers.length === 1 ? "16.66%" : "300px",
                  width:
                    this.props.selectedUsers.length === 1 ? "16.66%" : "100%",
                  height: "100%",
                  padding: metric + hours > 0 ? "0 20px" : "",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                {metric + hours > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      width: "100%",
                      height: "83.1%",
                    }}
                  >
                    <div
                      className="moving-gradient"
                      data-image-url={imageUrl}
                      data-hours-value={hours}
                      data-metric-value={metric}
                      data-name={d[0]}
                      id={`${d[0]}-${i}-floaty-metric-column`}
                      onMouseEnter={this.handleColumnMouseEnter}
                      onMouseLeave={this.handleColumnMouseLeave}
                      style={{
                        left: "-1px",
                        position: "relative",
                        backgroundColor: "#519acce6",
                        flex: "1",
                        transition: "max-height 0.3s",
                        width: "calc(50%)",
                        height: "100%",
                        minHeight: "0.01%",
                        maxHeight: `${
                          metric > 0 &&
                          !this.state.chartTransition &&
                          !this.state.pickerTransition
                            ? height1
                            : 0.01
                        }%`,
                      }}
                    />
                    <div
                      className="moving-gradient"
                      data-image-url={imageUrl}
                      data-hours-value={hours}
                      data-metric-value={metric}
                      data-name={d[0]}
                      id={`${d[0]}-${i}-floaty-hours-column`}
                      onMouseEnter={this.handleColumnMouseEnter}
                      onMouseLeave={this.handleColumnMouseLeave}
                      style={{
                        right: "-1px",
                        position: "relative",
                        backgroundColor: "#43962ae6",
                        flex: "1",
                        transition: "max-height 0.3s",
                        width: "calc(50%)",
                        height: "100%",
                        minHeight: "0.01%",
                        maxHeight: `${
                          hours > 0 &&
                          !this.state.chartTransition &&
                          !this.state.pickerTransition
                            ? height2
                            : 0.01
                        }%`,
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      width: "100%",
                      height: "83.1%",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        transition: "opacity 0.3s",
                        opacity: !this.state.chartTransition ? "1" : "0",
                        borderRadius: "6px",
                        display: "block",
                        width: "90%",
                        maxWidth: "100px",
                        bottom: "50px",
                        textAlign: "center",
                        padding: "5px",
                        fontWeight: "700",
                        fontFamily: "manrope",
                        zIndex: "10",
                        backgroundColor: "#ffffffb8",
                        left: "0",
                        right: "0",
                        margin: "0 auto",
                        fontSize: "13px",
                        border: "solid 2px lightgray",
                      }}
                    >
                      No Data
                    </span>
                    {/* <div style={{ left:"-1px", position:"relative", backgroundColor:"#f9f9f9", border:"dashed 2px lightgray", flex:"1", transition:"max-height 0.3s", width:"100%", height:"100%", minHeight:"0.01%", maxHeight: `60%`, color:"lightgray", fontFamily:"manrope", display:"flex", justifyContent:"center", alignItems:"center"}} />
                    <div style={{ right:"-1px", position:"relative", backgroundColor:"#f9f9f9", border:"dashed 2px lightgray", flex:"1", transition:"max-height 0.3s", width:"calc(50%)", height:"100%", minHeight:"0.01%", maxHeight: `40%`}} /> */}
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    height: "42px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <div
                    data-image-url={imageUrl}
                    data-hours-value={hours}
                    data-name={d[0]}
                    data-metric-value={metric}
                    id={`${d[0]}-${i}-floaty-image-circle`}
                    onMouseEnter={this.handleColumnMouseEnter}
                    onMouseLeave={this.handleColumnMouseLeave}
                    style={{
                      border:
                        this.state.smallCircle &&
                        this.props.selectedUsers.length !== 1
                          ? "solid 2px transparent"
                          : "solid 2px transparent",
                      opacity: this.state.chartTransition ? "0" : "1",
                      transition: "opacity 0.3s, border 0.3s",
                      width:
                        this.state.smallCircle &&
                        this.props.selectedUsers.length !== 1
                          ? "36px"
                          : "100%",
                      height: "36px",
                      borderRadius: "36px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.smallCircle &&
                    this.props.selectedUsers.length !== 1 ? (
                      <img
                        style={{
                          pointerEvents: "none",
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                        src={imageUrl}
                      />
                    ) : (
                      <span style={{ pointerEvents: "none" }}>
                        {this.props.selectedUsers.length === 1
                          ? this.state.selectedTimeOption.value === "last6weeks"
                            ? `${moment(d[0])
                                .startOf("week")
                                .add(1, "day")
                                .format("MM/DD")} - ${moment(d[0])
                                .endOf("week")
                                .subtract(1, "day")
                                .format("MM/DD")}`
                            : moment(d[0]).format("MMMM YYYY")
                          : d[0]}
                      </span>
                    )}
                  </div>

                  {/* <span>{d[1].bugs}</span> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    var {
      lineCount,
      loading,
      graphData,
      hoursLineIncrement,
      graphLineIncrement,
      hoverData,
      labelTransition,
      chartTransition,
      pickerTransition,
      floatyArrowRight,
      selectedMetric,
      floatyHighlight,
      singleUserIndex,
    } = this.state;
    var noData = graphData.length === 0;
    var selectedItems = [
      ...this.props.selectedUsers,
      ...this.props.selectedProjects,
      ...this.props.selectedTeams,
    ];
    var heading = "";
    if (selectedItems.length > 1) {
      heading = `Multiple ${this.props.selectedFilter.label}s`;
    } else if (selectedItems.length === 1) {
      var selectedItem = this.props[`${this.props.selectedFilter.value}s`].find(
        (o) => o.id === selectedItems[0]
      );
      if (selectedItem) {
        heading = selectedItem.name;
      }
    }
    var userIds =
      this.props.selectedUsers.length === 1
        ? this.props.selectedUsers
        : graphData.map((u) => u[1].id);

    return (
      <div
        ref={this.containerRef}
        // className={
        //   this.props.sideBarWidth === 100
        //     ? "performance-graph-expanded"
        //     : "performance-graph-collapsed"
        // }
        style={{
          width: "100%",
          minWidth: "1000px",
          maxWidth: `calc(100% - ${100 + 73}px)`,
          willChange: "max-width",
          marginLeft: "auto",
          marginRight: "25px",
          marginBottom: "20px",
          transition: "max-width 0.666s ease-in-out 0s",
          height: "498px",
          borderRadius: "6px",
          border: "solid 1px lightgray",
          padding: "20px 20px 30px",
        }}
      >
        <div
          className={floatyArrowRight ? "arrow-right" : ""}
          ref={this.floatyRef}
          id="bug-chart-floaty"
        >
          {hoverData ? (
            <div>
              {this.state.smallCircle ||
              this.props.selectedUsers.length === 1 ? (
                <h3
                  style={{
                    textAlign: "center",
                    padding: "0 5px",
                    borderRadius: "4px",
                    color: floatyHighlight === "name" ? "white" : "#4d4d4d",
                    transition: "color 0.3s, background-color 0.3s",
                    backgroundColor:
                      floatyHighlight === "name" ? "#519acc" : "transparent",
                  }}
                >
                  {this.props.selectedUsers.length === 1
                    ? this.state.selectedTimeOption.value === "last6weeks"
                      ? `${moment(hoverData.name)
                          .startOf("week")
                          .add(1, "day")
                          .format("MM/DD/YY")} - ${moment(hoverData.name)
                          .endOf("week")
                          .subtract(1, "day")
                          .format("MM/DD/YY")}`
                      : `${moment(hoverData.name)
                          .startOf("month")
                          .format("MM/DD/YY")} - ${moment(hoverData.name)
                          .endOf("month")
                          .format("MM/DD/YY")}`
                    : hoverData.name}
                </h3>
              ) : (
                <div
                  style={{
                    display: "flex",
                    padding: "5px 0",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    width: "90px",
                    height: "90px",
                    margin: "0 auto",
                    backgroundColor:
                      floatyHighlight === "name" ? "#519acc" : "transparent",
                    transition: "background-color 0.3s",
                  }}
                >
                  <img
                    style={{
                      display: "block",
                      width: "80px",
                      height: "80px",
                      margin: "0 auto",
                      borderRadius: "50%",
                    }}
                    src={hoverData.imageUrl}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  padding: "0 5px",
                  borderRadius: "4px",
                  justifyContent: "space-between",
                  transition: "background-color 0.3s",
                  alignItems: "center",
                  backgroundColor:
                    floatyHighlight === "metric" ? "#519acc" : "transparent",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "13px",
                      height: "13px",
                      borderRadius: "50%",
                      boxShadow: "#8e8e8e 0px 1px 4px 0px",
                      border: "solid 2px white",
                      backgroundColor: "#519acc",
                    }}
                  />
                  <span
                    style={{
                      color: floatyHighlight === "metric" ? "white" : "#4d4d4d",
                      paddingLeft: "10px",
                      transition: "color 0.3s",
                    }}
                  >
                    {selectedMetric === "bugs" ? "Bugs" : "Test Steps"}
                  </span>
                </div>
                <span
                  style={{
                    color: floatyHighlight === "metric" ? "white" : "#4d4d4d",
                    transition: "color 0.3s",
                  }}
                >
                  {hoverData.metric}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "0 5px",
                  borderRadius: "4px",
                  justifyContent: "space-between",
                  transition: "background-color 0.3s",
                  alignItems: "center",
                  backgroundColor:
                    floatyHighlight === "hours" ? "#519acc" : "transparent",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "13px",
                      height: "13px",
                      borderRadius: "50%",
                      boxShadow: "#8e8e8e 0px 1px 4px 0px",
                      border: "solid 2px white",
                      backgroundColor: "#43962a",
                    }}
                  />
                  <span
                    style={{
                      color: floatyHighlight === "hours" ? "white" : "#4d4d4d",
                      paddingLeft: "10px",
                      transition: "color 0.3s",
                    }}
                  >
                    Hours
                  </span>
                </div>
                <span
                  style={{
                    color: floatyHighlight === "hours" ? "white" : "#4d4d4d",
                    transition: "color 0.3s",
                  }}
                >
                  {hoverData.hours}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
            alignItems: "flex-start",
          }}
        >
          <div>
            {/* <h2 style={{fontFamily:"manrope", fontSize:"18px", marginRight:"20px"}}>Performance</h2> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                marginLeft: "20px",
              }}
            >
              {selectedItems.length > 0 && (
                <h2 style={{ fontFamily: "manrope", marginRight: "20px" }}>
                  {heading}
                </h2>
              )}
              <BasicSelect
                options={
                  this.props.selectedUsers.length === 1
                    ? this.singleUserTimeOptions
                    : this.groupTimeOptions
                }
                onChange={this.handleTimeOption}
                selectedOption={this.state.selectedTimeOption}
                selectedTextStyle={{ color: "#242424", fontWeight: "500" }}
                listItemStyle={{
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  color: "#242424",
                }}
                placeholder="Select Project"
                id="1"
                width="170px"
                maxHeight="155px"
                disabled={noData && !(this.props.selectedProjects.length > 0)}
                hideDisabled={true}
                arrowStyle={{ position: "absolute", top: "10px", right: "0px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              <button
                disabled={noData}
                onClick={this.handleDataMetric}
                data-metric="bugs"
                style={{
                  marginRight: "12px",
                  transition: "opacity 0.3s",
                  opacity: noData ? "0" : "1",
                  pointerEvents: noData ? "none" : "all",
                }}
                className={`common-circle-toggle ${
                  this.state.selectedMetric === "bugs" ? "toggled" : ""
                }`}
              >
                Bugs
              </button>
              <button
                disabled={noData}
                onClick={this.handleDataMetric}
                data-metric="test_steps"
                style={{
                  transition: "opacity 0.3s",
                  opacity: noData ? "0" : "1",
                  pointerEvents: noData ? "none" : "all",
                }}
                className={`common-circle-toggle ${
                  this.state.selectedMetric === "test_steps" ? "toggled" : ""
                }`}
              >
                Test Steps
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <BasicSelect
              arrowStyle={{ position: "relative", top: "2px" }}
              options={this.filterByOptions}
              onChange={this.handleFilterByChange}
              selectedOption={this.props.selectedFilter}
              selectedTextStyle={{ color: "#242424", fontWeight: "500" }}
              listItemStyle={{
                paddingTop: "4px",
                paddingBottom: "4px",
                color: "#242424",
              }}
              placeholder="Project"
              id="2"
              width="120px"
              maxHeight="155px"
              disabled={!this.props.selectedFilter}
              // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
            />

            {this.props.selectedFilter.value === "project" ? (
              <CheckboxMenu
                maxWidth={"100%"}
                key={1}
                count={this.props.selectedProjects.length}
                active={this.props.selectedProjects.length >= 1}
                options={this.props.projects.map((p) => ({
                  label: p.name,
                  value: p.id,
                  handler: this.handleProjectChange,
                  checked: this.props.selectedProjects.indexOf(p.id) !== -1,
                }))}
                name="filters"
                title={
                  this.props.selectedProjects.length === 0
                    ? "Select Project"
                    : this.props.selectedProjects.length === 1
                    ? "Selected Project"
                    : "Selected Projects"
                }
                clearFunction={this.clearFilters}
                id="1"
                containerMinWidth="160px"
                narrow={true}
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />
            ) : this.props.selectedFilter.value === "team" ? (
              <CheckboxMenu
                maxWidth={"100%"}
                key={2}
                count={this.props.selectedTeams.length}
                active={this.props.selectedTeams.length >= 1}
                options={this.props.teams.map((t) => ({
                  label: t.name,
                  value: t.id,
                  handler: this.handleTeamChange,
                  checked: this.props.selectedTeams.indexOf(t.id) !== -1,
                }))}
                title={
                  this.props.selectedTeams.length === 0
                    ? "Select Team"
                    : this.props.selectedTeams.length === 1
                    ? "Selected Team"
                    : "Selected Teams"
                }
                name="filters"
                id="2"
                containerMinWidth="160px"
                clearFunction={this.clearFilters}
                narrow={true}
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />
            ) : (
              <CheckboxMenu
                maxWidth={"100%"}
                key={3}
                count={this.props.selectedUsers.length}
                active={this.props.selectedUsers.length >= 1}
                options={this.props.users.map((u) => ({
                  label: u.name,
                  value: u.id,
                  handler: this.handleUserChange,
                  checked: this.props.selectedUsers.indexOf(u.id) !== -1,
                }))}
                title={
                  this.props.selectedUsers.length === 0
                    ? "Select Users"
                    : this.props.selectedUsers.length === 1
                    ? "Selected User"
                    : "Selected Users"
                }
                name="filters"
                id="3"
                containerMinWidth="160px"
                clearFunction={this.clearFilters}
                narrow={true}
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />
            )}
          </div>
        </div>
        <div
          ref={this.graphContainerRef}
          style={{
            height: "calc(100% - 100px)",
            position: "relative",
            width: "100%",
            paddingLeft: "48px",
            paddingRight: "48px",
            display: "flex",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",
            overflow: "hidden",
          }}
        >
          {/* <div style={{position:"absolute", transition:"opacity 0.3s", opacity:graphData.length===0&&!loading?"1":"0", pointerEvents:graphData.length===0?"all":"none", width:"520px", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", margin:"0 auto", left:"0", right:"0"}}>
              <img style={{display:"block", width:"145px"}} src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/empty_graph_icon.png" />
              <h3 style={{fontSize:"18px", fontFamily:"manrope", fontWeight:"600", marginTop:"10px", marginBottom:"20px"}}>
                {selectedItems.length > 0 ? 
                  `No ${this.props.selectedFilter.label} EOD Report Data Found` : 
                  `No ${this.props.selectedFilter.label}s selected`
                }
              </h3>
              <p style={{textAlign:"center", marginBottom:"20px"}}>
                {selectedItems.length > 0 ? 
                  `Please verify that there are valid EOD Report submissions for the selected ${this.props.selectedFilter.value}, select a different timerange, or choose another filter` : 
                  `Select one or more ${this.props.selectedFilter.value}s from the filter above to retreive EOD report data.`
                }
              </p>
          </div> */}
          <CSSTransition
            unmountOnExit
            in={
              graphData.length === 0 &&
              selectedItems.length > 0 &&
              !loading &&
              !chartTransition
            }
            timeout={500}
            classNames="node-fade"
          >
            <div
              style={{
                position: "absolute",
                width: "520px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "0 auto",
                left: "0",
                right: "0",
              }}
            >
              <img
                style={{ display: "block", width: "145px" }}
                src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/empty_graph_icon.png"
              />
              <h3
                style={{
                  fontSize: "18px",
                  fontFamily: "manrope",
                  fontWeight: "600",
                  marginTop: "5px",
                  marginBottom: "15px",
                }}
              >
                No {this.props.selectedFilter.label} EOD Report Data Found
              </h3>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "100px",
                  lineHeight: "26px",
                }}
              >
                Please verify that there are valid EOD Report submissions for
                the selected {this.props.selectedFilter.value}, select a
                different timerange, or choose another filter.
              </p>
            </div>
          </CSSTransition>
          <CSSTransition
            unmountOnExit
            in={selectedItems.length === 0 && !loading && !chartTransition}
            timeout={500}
            classNames="node-fade"
          >
            <div
              style={{
                position: "absolute",
                width: "520px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "0 auto",
                left: "0",
                right: "0",
              }}
            >
              <img
                style={{ display: "block", width: "145px" }}
                src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/empty_graph_icon.png"
              />
              <h3
                style={{
                  fontSize: "18px",
                  fontFamily: "manrope",
                  fontWeight: "600",
                  marginTop: "5px",
                  marginBottom: "15px",
                }}
              >
                No {this.props.selectedFilter.label}s selected
              </h3>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "100px",
                  lineHeight: "26px",
                }}
              >
                Select one or more {this.props.selectedFilter.value}s from the
                filter above to retreive EOD report data.
              </p>
            </div>
          </CSSTransition>
          <div
            style={{
              height: "82%",
              width: "3px",
              opacity: graphData.length > 0 ? "1" : "0",
              transition: "opacity 0.3s",
              backgroundColor: "#519acc",
              position: "absolute",
              left: "42px",
              bottom: "51px",
            }}
          />
          <div
            style={{
              height: "82%",
              width: "3px",
              opacity: graphData.length > 0 ? "1" : "0",
              transition: "opacity 0.3s",
              backgroundColor: "#43962a",
              position: "absolute",
              right: "42px",
              bottom: "51px",
            }}
          />
          <button
            data-direction="left"
            onClick={this.shiftSingleUserData}
            style={{
              opacity: this.props.selectedUsers.length === 1 ? "1" : "0",
              pointerEvents:
                this.props.selectedUsers.length === 1 ? "all" : "none",
              cursor: "pointer",
              transition: "opacity 0.3s",
              display: "block",
              border: "none",
              background: "none",
              position: "absolute",
              bottom: "10px",
              left: "18px",
            }}
          >
            <RoundDropdown
              svgStyles={{ transform: "rotate(88deg)", pointerEvents: "none" }}
              width={26}
              height={26}
            />
          </button>
          <button
            data-direction="right"
            onClick={this.shiftSingleUserData}
            style={{
              opacity:
                this.props.selectedUsers.length === 1 &&
                graphData.length - singleUserIndex > 6
                  ? "1"
                  : "0",
              pointerEvents:
                this.props.selectedUsers.length === 1 &&
                graphData.length - singleUserIndex > 6
                  ? "all"
                  : "none",
              cursor: "pointer",
              zIndex: "10",
              transition: "opacity 0.3s",
              display: "block",
              border: "none",
              background: "none",
              position: "absolute",
              bottom: "10px",
              right: "18px",
            }}
          >
            <RoundDropdown
              svgStyles={{ transform: "rotate(-88deg)", pointerEvents: "none" }}
              width={26}
              height={26}
            />
          </button>
          {Array(6)
            .fill(0)
            .map((_, i) => {
              return (
                <div
                  style={{
                    position: "relative",
                    opacity: graphData.length > 0 ? "1" : "0",
                    borderTop: "solid 1px #c1c1c1",
                    height: "16%",
                    width: "100%",
                    transition: "transform 0.3s, opacity 0.3s",
                    transform: `translateY(${
                      lineCount === 6 ? 0 : i * -24.5
                    }%)`,
                  }}
                  key={i}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "-15px",
                      opacity:
                        graphData.length > 0 &&
                        !chartTransition &&
                        !pickerTransition &&
                        !labelTransition
                          ? "1"
                          : "0",
                      transition: "opacity 0.4s",
                      color: "#519acc",
                      left: "-52px",
                      textAlign: "right",
                      display: "block",
                      width: "40px",
                      fontFamily: "arial",
                      fontSize: "16px",
                    }}
                  >
                    {graphLineIncrement * i}
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "-15px",
                      opacity:
                        graphData.length > 0 &&
                        !chartTransition &&
                        !pickerTransition &&
                        !labelTransition
                          ? "1"
                          : "0",
                      transition: "opacity 0.4s",
                      color: "#43962a",
                      right: "-52px",
                      textAlign: "left",
                      display: "block",
                      width: "40px",
                      fontFamily: "arial",
                      fontSize: "16px",
                    }}
                  >
                    {hoursLineIncrement * i}
                  </span>
                </div>
              );
            })}
          <div
            id="bug-column-container"
            style={{
              width: "calc(100% - 60px)",
              height: "100%",
              position: "absolute",
              left: "48px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {loading && (
                <div
                  style={{
                    height: "100px",
                    width: "100px",
                    position: "absolute",
                    top: "30%",
                    left: "-55px",
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div id="test-step-bugs-loader" />
                </div>
              )}
              {this.renderColumns()}
            </div>
          </div>
        </div>
        {this.state.graphData.length > 0 && (
          <div
            style={{
              position: "absolute",
              top: "575px",
              right: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <p style={{fontSize:"13px", fontWeight:"600", lineHeight:"18px", textAlign:"right", marginRight:"4px"}}>Export Data -</p> */}
            <div style={{ textAlign: "right" }}>
              <button
                style={{
                  width: "unset",
                  background: "none",
                  border: "none",
                  color: "#519acc",
                  fontSize: "12px",
                  cursor: "pointer",
                  padding: "0",
                  fontWeight: "600",
                }}
                className="btn link-btn bc-blue pointer bc-hover"
                onClick={this.generateCsv}
              >
                CSV
              </button>
              <CSVLink
                id="csvLink"
                className="bc-hover"
                filename={`${heading} - ${this.state.selectedTimeOption.label}`}
                data={this.state.csvData}
                target="_blank"
              ></CSVLink>

              <a
                href={`/generate_skills_pdf.pdf?heading=${`${heading} - ${this.state.selectedTimeOption.label}`}&user_ids=${userIds}&start_date=${
                  this.timeStartEndDates[this.state.selectedTimeOption.value]
                    .start
                }&end_date=${
                  this.timeStartEndDates[this.state.selectedTimeOption.value]
                    .end
                }&scope=${
                  this.state.selectedTimeOption.value === "last6weeks"
                    ? "week"
                    : "month"
                }&export_type=pdf&project_ids=${
                  this.props.selectedProjects
                }&selected_filter=${this.props.selectedFilter.value}`}
                style={{
                  background: "none",
                  border: "none",
                  color: "#519acc",
                  fontSize: "12px",
                  cursor: "pointer",
                  fontWeight: "600",
                  padding: "0 5px",
                  margin: "0 5px",
                  borderLeft: "solid 1px lightgray",
                  borderRight: "solid 1px lightgray",
                }}
              >
                PDF
              </a>

              <a
                href={`/generate_skills_pdf.xlsx?heading=${`${heading} - ${this.state.selectedTimeOption.label}`}&user_ids=${userIds}&start_date=${
                  this.timeStartEndDates[this.state.selectedTimeOption.value]
                    .start
                }&end_date=${
                  this.timeStartEndDates[this.state.selectedTimeOption.value]
                    .end
                }&scope=${
                  this.state.selectedTimeOption.value === "last6weeks"
                    ? "week"
                    : "month"
                }&export_type=xlsx&project_ids=${
                  this.props.selectedProjects
                }&selected_filter=${this.props.selectedFilter.value}`}
                style={{
                  width: "unset",
                  padding: "0",
                  background: "none",
                  border: "none",
                  color: "#519acc",
                  fontSize: "12px",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                XLSX
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EodPerformanceGraph;
