import React from "react";
import PropTypes from "prop-types";

const UserIcon = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyles}
    >
      <path
        d="M2.07512 8.64951L2.07495 8.64936L2.07205 8.65323C1.39327 9.55943 0.95 10.7316 0.95 12.142V13.5941H0.94994L0.95006 13.5965L1.00178 14.6526L1.00287 14.6749L1.02017 14.689C3.51325 16.7172 5.97073 17.223 8.07285 16.9672C10.1732 16.7116 11.9156 15.6962 12.9826 14.6865L12.9972 14.6727L12.9982 14.6526L13.0499 13.5965L13.05 13.5965V13.5941V12.142C13.05 10.7323 12.633 9.55961 11.9274 8.65251L11.9275 8.65241L11.9248 8.64947L11.2007 7.85739L11.201 7.85712L11.1962 7.85304C10.8991 7.60027 10.5998 7.36973 10.2541 7.18632C10.6801 6.55229 10.9293 5.7684 10.9293 4.93403C10.9293 2.71554 9.14884 0.923633 6.97414 0.923633C4.799 0.923633 3.04483 2.71598 3.04483 4.93403C3.04483 5.77032 3.29517 6.52986 3.72176 7.18716C3.39789 7.36995 3.07517 7.60122 2.77618 7.88102L2.77607 7.88091L2.7734 7.88384L2.07512 8.64951ZM7 1.84211C8.70425 1.84211 10.0786 3.21789 10.0793 4.95903C9.97662 6.75579 8.65183 8.0526 6.99961 8.07875C5.29535 8.07854 3.92069 6.67597 3.92069 4.96043C3.92069 3.21863 5.29529 1.84211 7 1.84211ZM1.90345 12.142C1.90345 9.98484 2.96381 8.63244 4.43245 7.92362C5.13447 8.49915 6.01535 8.86522 6.97414 8.86522C7.93169 8.86522 8.83805 8.52577 9.54167 7.95001C11.0102 8.6591 12.0707 10.0373 12.0707 12.1684V14.2017C10.3645 15.3887 8.69383 15.9797 7.01626 15.9764C5.33853 15.9732 3.64835 15.3755 1.90345 14.1751V12.142Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
    </svg>
  );
};

UserIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

UserIcon.defaultProps = {
  height: "18",
  width: "14",
  svgStyles: {},
  color: "#519acc",
};

export default UserIcon;
