import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  useFloating,
  useInteractions,
  useHover,
  useTransitionStyles,
} from "@floating-ui/react";
import styled from "styled-components";
import { deviceTypes } from "@types";
import popoverOffsetMiddleware from "./popoverOffsetMiddleware";

const DeviceIdCell = ({ device, value }) => {
  const [isHoverOpen, setIsHoverOpen] = useState(false);

  const deviceHasNotes = useMemo(() => {
    return device.visible_notes && device.visible_notes.length > 0;
  }, [device.visible_notes]);

  const copyUdid = async (e) => {
    const { udid } = e.target.dataset;
    await navigator.clipboard.writeText(udid);
    // eslint-disable-next-line no-undef
    M.toast({
      html: "<span aria-live='assertive'>UDID Copied</span>",
      displayLength: 3000,
      classes: "loading-toast",
    });
  };

  const {
    refs,
    floatingStyles,
    context: floatingContext,
  } = useFloating({
    open: isHoverOpen,
    onOpenChange: setIsHoverOpen,
    placement: "right",
    transform: true,
    middleware: [...popoverOffsetMiddleware],
  });

  const hover = useHover(floatingContext, {
    enabled: deviceHasNotes,
    delay: { open: 25, close: 150 },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const { isMounted: isHoverMounted, styles: hoverStyles } =
    useTransitionStyles(floatingContext, {
      duration: 150,
      enabled: deviceHasNotes,
      initial: {
        opacity: 0,
        transform: "scale(0.9) translateX(-25%)",
      },
    });

  if (device.visible_notes && device.visible_notes !== "") {
    return (
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-around",
        }}
      >
        {isHoverMounted && (
          <DeviceInfoPopup
            ref={refs.setFloating}
            style={{ ...hoverStyles, ...floatingStyles }}
            {...getFloatingProps()}
          >
            <Header>Device Info:</Header>
            <Notes>{device.visible_notes}</Notes>
          </DeviceInfoPopup>
        )}
        <p aria-hidden="true">{value}</p>
        <div style={{ display: "flex" }}>
          <i className="material-icons">library_books</i>
        </div>
        {device.udid && device.udid !== "" && (
          <button
            type="button"
            id="visible-notes-icon"
            className="udid-icon transparent-button"
            style={{ display: "flex" }}
            data-udid={device.udid}
            onClick={copyUdid}
          >
            <i data-udid={device.udid} className="material-icons">
              phone_android
            </i>
          </button>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
      }}
    >
      <p aria-hidden="true">{value}</p>
      {device.udid && device.udid !== "" ? (
        <button
          type="button"
          id="visible-notes-icon"
          className="udid-icon"
          style={{ display: "flex" }}
          data-udid={device.udid}
          onClick={copyUdid}
        >
          <i data-udid={device.udid} className="material-icons">
            phone_android
          </i>
        </button>
      ) : (
        // /* this p is nothing just used to even the flex out for the other condition */
        <p
          style={{
            width: "24px",
            visibility: "hidden",
            pointerEvents: "none",
            userSelect: "none",
          }}
          aria-hidden="true"
        >
          {value}
        </p>
      )}
    </div>
  );
};

const DeviceInfoPopup = styled.div`
  z-index: 1000;
  height: fit-content;
  width: fit-content;
  font-size: 12px;
  background-color: white;
  color: #242424;
  border: 2px solid #519acc;
  border-radius: 6px;
  padding: 0 5px;
`;

const Header = styled.p`
  font-weight: 600;
`;

const Notes = styled.p``;

DeviceIdCell.propTypes = {
  device: deviceTypes,
  value: PropTypes.number,
};

export default DeviceIdCell;
