import React from "react";

const AllTime = (props) => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 64 64" {...props}>
      {" "}
      <path
        d="M39.57 25a7 7 0 00-5.4 2.54l-.27.35-.76 1 1.15 1.54L35.36 29l.24-.31a5.15 5.15 0 11.43 7l-.58-.66-.24-.31h0l-5.25-7A7 7 0 1024.43 39a6.93 6.93 0 005-2.12l.41-.46 1-1.33-1.14-1.55-1.27 1.71-.31.36a5.16 5.16 0 11.5-6.61h0l2.22 3h0L32 33.55h0l2 2.68h0l.68.76h0a7 7 0 104.84-12z"
        fill="#414042"
        stroke="#414042"
        strokeMiterlimit={10}
      />{" "}
    </svg>
  );
};

export default AllTime;
