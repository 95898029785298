import React from "react";
import PropTypes from "prop-types";

const WikiCategoryTraining = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 38"
    >
      <path
        d="M30.05 3.80022V4.15022H30.4H36.1C37.0243 4.15022 37.65 4.92039 37.65 5.70022V28.5002C37.65 28.9907 37.488 29.3722 37.23 29.6302C36.972 29.8883 36.5905 30.0502 36.1 30.0502H20.9H20.55V30.4002V34.2002V34.5502H20.9H24.7C25.1905 34.5502 25.572 34.7122 25.83 34.9702C26.088 35.2282 26.25 35.6098 26.25 36.1002C26.25 36.5907 26.088 36.9722 25.83 37.2302C25.572 37.4883 25.1905 37.6502 24.7 37.6502H13.3C12.8095 37.6502 12.428 37.4883 12.17 37.2302C11.912 36.9722 11.75 36.5907 11.75 36.1002C11.75 35.6098 11.912 35.2282 12.17 34.9702C12.428 34.7122 12.8095 34.5502 13.3 34.5502H17.1H17.45V34.2002V30.4002V30.0502H17.1H1.9C1.40954 30.0502 1.02801 29.8883 0.769987 29.6302C0.51196 29.3722 0.35 28.9907 0.35 28.5002V5.70022C0.35 4.92039 0.975652 4.15022 1.9 4.15022H7.6H7.95V3.80022C7.95 3.64646 8.00945 3.43821 8.15773 3.18614C8.30412 2.93727 8.52585 2.66516 8.81915 2.39002C9.40566 1.83982 10.2549 1.29953 11.283 0.92258C13.3329 0.170941 16.067 0.0769297 18.8127 1.81591L19.0049 1.93761L19.1941 1.81144C20.849 0.708178 22.5789 0.212681 24.297 0.325718C26.016 0.438809 27.7526 1.16302 29.4159 2.5491L29.4303 2.56107L29.4459 2.57144C29.8917 2.86867 30.05 3.31091 30.05 3.80022ZM11.1993 4.46349L11.05 4.56799V4.75022V15.7702V16.2872L11.53 16.0952C13.3465 15.3686 15.1535 15.3686 16.97 16.0952L17.45 16.2872V15.7702V4.75022V4.56799L17.3007 4.46349C16.3051 3.76653 15.2864 3.40272 14.25 3.40272C13.2136 3.40272 12.1949 3.76653 11.1993 4.46349ZM34.2 26.9502H34.55V26.6002V7.60022V7.25022H34.2H30.4H30.05V7.60022V19.0002C30.05 20.2069 28.4984 21.0334 27.3782 20.2388C26.1092 19.2638 24.8973 18.7452 23.655 18.7452C22.4116 18.7452 21.1986 19.2647 19.9284 20.2414C19.2937 20.7165 18.5163 20.7165 17.8816 20.2414C16.6114 19.2647 15.3984 18.7452 14.155 18.7452C12.911 18.7452 11.6974 19.2652 10.4266 20.2428L10.4264 20.2426L10.4159 20.2513C9.97501 20.6188 9.3703 20.6341 8.84902 20.3734C8.32781 20.1128 7.95 19.6044 7.95 19.0002V7.60022V7.25022H7.6H3.8H3.45V7.60022V26.6002V26.9502H3.8H34.2Z"
        fill="#F4F4F4"
        stroke="#742A6C"
        strokeWidth="0.7"
      />
    </svg>
  );
};

WikiCategoryTraining.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

WikiCategoryTraining.defaultProps = {
  height: "38px",
  width: "38px",
  svgStyles: {},
  color: "#f4f4f4",
};

export default WikiCategoryTraining;
