import React from "react";
import ActivityWrapper from "../activity/activity_wrapper";
import styled from "styled-components";

function ActivityRow(props) {
  return (
    <ActivityParentContainer>
      <ActivityWrapper
        currentUser={props.currentUser}
        project={props.project}
        activityCount={props.activityCount}
      />
    </ActivityParentContainer>
  );
}

const ActivityParentContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 350px;
  overflow: auto;
  margin: 5px 0px 0px 0px;
`;

export default ActivityRow;
