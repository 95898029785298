import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useForm, FormProvider } from "react-hook-form";
import styled from "styled-components";
import _ from "lodash";
import { trialTypes } from "@types/wiki/trial";
import ApiClient from "@utils/ApiClient";
import TrialHeader from "./TrialHeader";
import TrialBody from "./TrialBody";
import TrialFooter from "./TrialFooter";

const TrialsContext = React.createContext({});

const TrialsContainer = (props) => {
  const { trial: initialTrialData, users } = props;

  const [trial, setTrial] = useState(initialTrialData);
  const [loading, setLoading] = useState(false);
  const { assessment, current_question } = trial;
  const { questions } = assessment;

  const STATUS = useMemo(() => {
    return {
      isStarted: trial.started,
      isInProgress: trial.in_progress,
      isEnded: trial.trial_ended,
      isExpired: trial.expired,
    };
  }, [trial]);

  const activeQuestion = useMemo(() => {
    const q = questions.find(
      (question) => question.id === current_question?.id
    );
    return q ? _.merge(q, current_question) : {};
  }, [questions, current_question]);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { handleSubmit } = methods;

  const startTrial = async () => {
    try {
      setLoading(true);
      const api = new ApiClient();
      const response = await api.patch(
        `/wiki-assessments/${assessment.id}/trials/${trial.id}/start`
      );
      setTrial(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onError = (errors) => {
    const formErrorMessage = Object.values(errors)[0]?.message;
    // eslint-disable-next-line no-undef
    M.toast({
      html: formErrorMessage || "Assessment Error",
      classes: "red",
    });
  };

  const recordResponse = async (data) => {
    try {
      setLoading(true);
      const api = new ApiClient();
      const questionId = activeQuestion.id;
      const response = { question_id: questionId };
      if (activeQuestion.question_type !== "written_answer") {
        response.answer_ids = data[questionId];
      } else {
        response.user_written_response = data[questionId];
      }

      api
        .put(
          `/wiki-assessments/${assessment.id}/trials/${trial.id}/record_response`,
          { response }
        )
        .then((res) => {
          setTrial(res.data);
        });
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    if (!STATUS.isStarted) {
      startTrial();
    }

    if (STATUS.isInProgress && activeQuestion) {
      recordResponse(data);
    }
  };

  const decrementQuestion = useCallback(async () => {
    try {
      setLoading(true);
      const api = new ApiClient();
      const response = await api.patch(
        `/wiki-assessments/${assessment.id}/trials/${trial.id}/decrement_question`
      );
      setTrial(response.data);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  }, [assessment.id, trial.id]);

  const refreshTrial = useCallback(async () => {
    try {
      setLoading(true);
      const api = new ApiClient();
      const response = await api.get(
        `/wiki-assessments/${assessment.id}/trials/${trial.id}`
      );
      setTrial(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [assessment.id, trial.id]);

  const trialsContextValue = useMemo(() => {
    return {
      trial,
      assessment,
      loading,
      questions,
      activeQuestion,
      refreshTrial,
      decrementQuestion,
      users,
      ...STATUS,
    };
  }, [
    trial,
    assessment,
    loading,
    questions,
    activeQuestion,
    users,
    STATUS,
    refreshTrial,
    decrementQuestion,
  ]);

  return (
    <TrialsContext.Provider value={trialsContextValue}>
      <Helmet>
        <title>
          Wiki Assessment | {assessment.resource.title} - Test Platform
        </title>
      </Helmet>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <TrialWindow>
            <HeaderContainer>
              <TrialHeader />
            </HeaderContainer>
            <BodyContainer>
              <TrialBody />
            </BodyContainer>
            <FooterContainer>
              <TrialFooter />
            </FooterContainer>
          </TrialWindow>
        </form>
      </FormProvider>
    </TrialsContext.Provider>
  );
};

const TrialWindow = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 140px;
  padding: 0 40px;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: calc(100% - 140px - 140px);
  overflow: auto;
`;

const FooterContainer = styled.div`
  width: 100%;
  height: 140px;
  border-top: 1px solid #d3d3d3;
  padding: 0 40px;
`;

TrialsContainer.propTypes = {
  trial: trialTypes,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

export default TrialsContainer;

export { TrialsContext };
