import React, {useState} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import DownloadIcon from '../icons/download_icon'
import TrashIcon from '../icons/trash_icon'

function MediaSquare(props) {
  const [loading, setLoading] = useState(false);

  function handleCheckChange(e) {
    e.stopPropagation()
    props.handleSelected(props.item.id)
  }

  function stopProp(e) {
    e.stopPropagation()
  }

  function handleEditorClick() {
    props.editorHandler(props.item)
  }

  function handleMediaDelete(e) {
    e.stopPropagation()
    Swal.fire({
      title: "Delete File",
      text: `Are you sure that you want to delete: "${props.item.file_name}"?`,
      reverseButtons: true,
      showCancelButton:true,
      confirmButtonAriaLabel: 'Yes',
      cancelButtonAriaLabel: 'cancel',
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios.delete(`/media_items/${props.item.id}.json`, {
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        }).then((res) => {
          if (res.status === 200) {
            M.toast({html: `Deleted ${props.item.file_name}`, classes: "green"})
            props.mediaHandler(props.item.id, "remove")
          }
        })
        .catch((err) => {
          err.message = "Delete Media Item Backend Error: " + err.message
          console.error(err)
          Swal.fire({title: "Media Item delete error", text:"There was a problem deleting this media", customClass: "swal2-error-modal"});
        })
      } else if (result.dismiss) {
          // Cancelled Delete
      }
    });
  }

  function handleMediaDownload(e) {
    e.preventDefault()
    e.stopPropagation()
    setLoading(true)
    var dl = document.createElement('a')
    dl.setAttribute('href', `${props.item.download_url}`)
    dl.setAttribute('download', `${props.item.file_name}`)
    dl.click()
    dl.remove()
    setLoading(false)
  }

  function getSelected() {
    return props.selected
  }

  function displayFile() {
    var element = "//plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/file-icon.png"
    if(props.item.file_type && props.item.file_type.includes("image") && props.item.file_type.includes("svg")) {
      // svgs download from digital ocean so gotta find a way around this maybe. People likely don't take screenshots with svg
    } else if (props.item.file_type && props.item.file_type.includes("image")) {
      element = props.item.url
    } else if (props.item.file_type && props.item.file_type.includes("video")) {
      // element = <video style={{width: "30px", height: "30px", borderRadius: "3px"}} alt="media item" src={props.item.url}/>
    }
    return element
  }

  return (
    <div onClick={handleEditorClick} style={{backgroundImage: `url(${displayFile()}`}} className="media-square">
       <div className={`media-overlay ${getSelected() ? "selected" : ""}`} style={{ flexDirection: "column", display: "flex", overflow: "visible", flex: "0 1 200px", margin: 0}}>
        <div style={{ display: "flex", alignItems: "center", overflow: "visible", margin: "0", padding: "5px", justifyContent: "space-between"}}>
          <p style={{width: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <label style={{pointerEvents: "none"}} htmlFor={`checkbox_${props.item.id}`}>
              <input
                onClick={stopProp}
                style={{pointerEvents: "auto"}}
                id={`checkbox_${props.item.id}`}
                onChange={handleCheckChange}
                checked={getSelected()}
                name="visible"
                className="filled-in media-check"
                type="checkbox"
              />
            </label>
          </p>
          <div className="flex-container">
            <button onClick={handleMediaDownload} style={{cursor:"pointer", right: "6px", bottom:props.item.owner_name?"5px":"0px"}} className="link-btn">
              <DownloadIcon svgStyles={{fill: "white", pointerEvents:"none", strokeLinecap: "round", strokeWidth: "3px"}} />
            </button>
            <button onClick={handleMediaDelete} style={{cursor:"pointer", bottom:props.item.owner_name?"5px":"0px"}} className="link-btn">
              <TrashIcon svgStyles={{stroke: "white", pointerEvents:"none", strokeLinecap: "round", strokeWidth: "3px"}} />
            </button>
          </div>
        </div>
            <p style={{fontSize: "10px", lineHeight: "13px", flex: "1 1 100px", padding: "0 10px"}}>{props.item.file_name}</p>
            {loading && (
              <div id="test-step-bugs-loader"></div>
            )}
            <div style={{display: "flex",flex: "0 1 50px", flexDirection: "column"}}>
              <p style={{ height: "12px", paddingLeft: "10px", margin: 0, fontSize: "11px"}}>Added {props.item.created_at}</p>
              <p style={{ height: "12px", paddingLeft: "10px", margin: 0, fontSize: "11px"}}>by {props.item.owner_name}</p>
            </div>
      </div>
    </div>
  );
}

export default MediaSquare