import React from 'react'

class RedeemIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
      >
        <path d="M6,48.94V109.2a9.93,9.93,0,0,0,9.77,9.74h96.56A9.76,9.76,0,0,0,122,109.2V48.91a9.87,9.87,0,0,0-9.88-9.74H92.81l1.1-1.57A18.09,18.09,0,0,0,64.73,16.23l-.8,1.06-.8-1.06a18,18,0,0,0-25.32-3.48,18.19,18.19,0,0,0-4,24.86l1,1.56H15.71A9.93,9.93,0,0,0,6,48.94ZM116,109.06a4,4,0,0,1-3.8,3.88H15.71a4,4,0,0,1-3.88-3.8V93.91H116ZM66.87,26.37A12.21,12.21,0,1,1,82.28,39l-.13,0H66.87Zm-30.28.9A12.32,12.32,0,0,1,49,15.15,12.09,12.09,0,0,1,61,26.28V39L45.46,39a12,12,0,0,1-8.87-11.71Zm20.3,18.05L44.72,57.48a2.92,2.92,0,0,0-.95,2.06,2.71,2.71,0,0,0,.82,2,2.9,2.9,0,0,0,2.06,1,2.8,2.8,0,0,0,2-.77L63.94,46.59,78.4,61.05a3.07,3.07,0,0,0,2.16.89,2.64,2.64,0,0,0,1.9-.86,3,3,0,0,0,.92-2.19,2.64,2.64,0,0,0-.86-1.9L71,45.45,112.15,45a4,4,0,0,1,3.89,3.8V88.18H11.83V48.78a3.88,3.88,0,0,1,3.88-3.88Z"/>
      </svg>
    );
  }
}
RedeemIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default RedeemIcon