import React from "react";

const AccessibilityExpertSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Accessibility_Expert_-_Small_svg__cls-1{fill:#edcc43}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Accessibility_Expert_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <circle
          className="Skills__Accessibility_Expert_-_Small_svg__cls-1"
          cx={540}
          cy={540}
          r={418.69}
        />{" "}
        <circle cx={540} cy={540} r={361.19} fill="#519acc" />{" "}
        <path
          className="Skills__Accessibility_Expert_-_Small_svg__cls-1"
          d="M626.44 800.72a27.43 27.43 0 0012.39-3.1c15.49-7.23 21.69-24.78 14.46-40.27 0 0-56.79-130.1-67.12-177.6-4.13-16.52-6.19-60.92-7.23-81.57 0-7.23 4.13-13.42 10.33-15.49l127-38.2c15.49-4.13 24.78-21.68 20.65-37.17s-21.68-24.78-37.17-20.65c0 0-117.71 38.2-160 38.2-41.31 0-158-37.17-158-37.17-15.49-4.13-33.05 3.1-38.21 18.58-5.16 16.53 4.13 34.08 20.65 38.21l127 38.2c6.19 2.07 11.35 8.26 10.32 15.49-1 20.65-3.1 65.05-7.23 81.57-10.32 47.5-67.11 177.6-67.11 177.6-7.23 15.49 0 33 14.45 40.27a27.43 27.43 0 0012.39 3.1c11.36 0 22.72-6.2 26.85-17.55l60.92-123.91 57.81 124.94a29.79 29.79 0 0026.85 16.52z"
        />{" "}
        <circle
          className="Skills__Accessibility_Expert_-_Small_svg__cls-1"
          cx={541.77}
          cy={337.1}
          r={57.82}
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default AccessibilityExpertSmall;
