import React from "react";
import PropTypes from "prop-types";

const CommunityIcon = ({ active, ...props }) => {
  const pathClassName = active
    ? "active-path-fill"
    : "CommunityIcon_svg__cls-1";
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      {...props}
    >
      <path
        d="M64,24A19.85,19.85,0,0,0,44.09,43.73,19.52,19.52,0,0,0,47.64,55a28.77,28.77,0,0,0-5,3.51c-.6-.45-1.22-.81-1.86-1.2A14.42,14.42,0,0,0,43,49.63a14.9,14.9,0,0,0-29.79,0,14.42,14.42,0,0,0,2.23,7.71A20.28,20.28,0,0,0,5.64,75.13v8.08a3.58,3.58,0,0,0,1.65,2.52c10,7,17.45,8.18,26.32,6.6l0,0c25.38,20.28,49.92,10,60.78,0,8.83,1.54,17.78-.74,26.37-6.68a2.87,2.87,0,0,0,1.56-2.85V75.13a20.26,20.26,0,0,0-9.82-17.83,14.4,14.4,0,0,0,2.23-7.67,14.88,14.88,0,0,0-29.75,0,14.48,14.48,0,0,0,2.19,7.67,21.77,21.77,0,0,0-1.86,1.2,28.55,28.55,0,0,0-5-3.47A19.55,19.55,0,0,0,84,43.73,19.89,19.89,0,0,0,64,24Zm0,5.28A14.51,14.51,0,0,1,78.67,43.73c-.51,8.36-6.79,14.4-14.69,14.52a14.51,14.51,0,1,1,0-29ZM28.12,40.1a9.49,9.49,0,1,1,0,19c-5.39-.33-9.52-3.66-9.61-9.45A9.51,9.51,0,0,1,28.12,40.1Zm71.76,0a9.49,9.49,0,1,1,0,19C94.27,59,90.38,55,90.3,49.63A9.48,9.48,0,0,1,99.88,40.1ZM51.39,59A19.81,19.81,0,0,0,64,63.53,20,20,0,0,0,76.65,59C84,62.54,89.4,69.2,89.4,79.75v10c-17.13,11.68-33.47,11.7-50.8-.08V79.75C38.6,69.16,44,62.52,51.39,59ZM37.16,61.3A16,16,0,0,1,39,62.46a27.9,27.9,0,0,0-5.65,17.29V87c-7.45,1.55-14.73-.11-22.4-5.2V75.13c0-7,3.4-11.35,8.21-13.78a14.91,14.91,0,0,0,18-.05Zm53.72,0a14.75,14.75,0,0,0,18,0c4.8,2.41,8.21,6.78,8.21,13.83v6.64c-7.69,5.13-15,6.79-22.4,5.28v-7.3A27.94,27.94,0,0,0,89,62.42,18.93,18.93,0,0,1,90.88,61.3Z"
        className={pathClassName}
      />
    </svg>
  );
};

CommunityIcon.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default CommunityIcon;
