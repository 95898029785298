import React from "react";
import PropTypes from "prop-types";
import DeleteIcon from "../icons/delete_icon";
import RoundDropdown from "./round_dropdown";
import ReactDOMServer from "react-dom/server";

class BasicSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: "",
      createEdit: false,
    };
    this.checkBoxContainer = React.createRef();
    this.createContainer = React.createRef();
    this.inputRef = React.createRef();
    this.handleOutsideClick = (e) => {
      if (
        e.target &&
        this.checkBoxContainer &&
        this.checkBoxContainer.current &&
        !this.checkBoxContainer.current.contains(e.target) &&
        e.target.id !== "template-create" &&
        e.target.id !== "template-cancel"
      ) {
        this.setOpen();
      }
    };
  }

  setOpen = () => {
    var optionsCount = this.props.options.length;
    if (!this.state.open === true) {
      window.addEventListener("click", this.handleOutsideClick);
      var container = this.props.scrollIntoView
        ? document.getElementById(this.props.scrollIntoView)
        : null;
      this.setState(
        {
          open: true,
        },
        () => {
          // document.getElementById(`drop-arrow-${this.props.id}`).style.transform = "rotateX(180deg)"
          var elem = document.getElementById(`checkbox-menu-${this.props.id}`);
          // elem.focus()
          if (this.inputRef.current) {
            this.inputRef.current.focus();
          } else {
            elem.focus();
          }
          setTimeout(() => {
            elem.style.zIndex = "100";
            elem.style.maxHeight = this.props.maxHeight
              ? this.props.maxHeight
              : "200px";
            elem.style.overflowY =
              this.props.options.length <= 4 ? "hidden" : "";
            if (this.props.cascadeTransition) {
              var optionElems = document.querySelectorAll(
                `#checkbox-menu-${this.props.id} .basic-select-option`
              );
              optionElems.forEach((elm, index) => {
                elm.style.transitionDelay = `${
                  (this.props.menuTransitionTime / optionsCount) * index
                }s`;
                elm.style.paddingLeft = this.props.listItemStyle.paddingLeft
                  ? this.props.listItemStyle.paddingLeft
                  : "10px";
                elm.style.opacity = "1";
              });
            }
          }, 50);
          setTimeout(() => {
            if (
              container &&
              container.getBoundingClientRect().bottom <
                elem.getBoundingClientRect().bottom
            ) {
              elem.scrollIntoView({ behavior: "smooth", block: "center" });
            }

            elem.style.transitionDelay = "0.2s";
          }, this.props.menuTransitionTime * 1000 + 50);
        }
      );
    } else {
      if (this.props.searchEnabled && this.state.search) {
        optionsCount = this.optionsCount;
      }
      window.removeEventListener("click", this.handleOutsideClick);
      // document.getElementById(`drop-arrow-${this.props.id}`).style.transform = "rotateX(0deg)"
      var elem = document.getElementById(`checkbox-menu-${this.props.id}`);
      elem.style.zIndex = "1";
      elem.style.maxHeight = "0px";
      if (this.props.cascadeTransition) {
        var optionElems = document.querySelectorAll(
          `#checkbox-menu-${this.props.id} .basic-select-option`
        );
        if (optionElems) {
          optionElems.forEach((elm, index) => {
            elm.style.transitionDelay = `${
              (this.props.menuTransitionTime / optionsCount) *
              (optionsCount - (index + 1))
            }s`;
            elm.style.paddingLeft = "0px";
            elm.style.opacity = "0";
          });
        }
      }
      setTimeout(() => {
        this.setState({
          open: false,
          search: "",
          createEdit: false,
        });
      }, this.props.menuTransitionTime * 1000 + 200);
    }
  };

  onChange = (e) => {
    if (e.target.id !== "delete-button") {
      var value = isNaN(e.target.dataset.value)
        ? e.target.dataset.value
        : parseInt(e.target.dataset.value);
      var label = e.target.dataset.label;
      var icons = e.target.dataset.icons;
      if (e.target.dataset.svg && e.target.dataset.index) {
        this.props.onChange({
          value,
          label,
          icons: this.props.options[parseInt(e.target.dataset.index)].icons,
          name: this.props.name,
        });
      } else if (icons) {
        this.props.onChange({
          value,
          label,
          icons: icons.split(","),
          name: this.props.name,
        });
      } else {
        this.props.onChange({ value, label, name: this.props.name });
      }
      this.setOpen();
    }
  };

  handleInput = (e) => {
    this.setState({ search: e.target.value });
  };

  handleCreateEdit = () => {
    this.setState({ createEdit: !this.state.createEdit }, () => {
      if (this.state.createEdit) {
        var elem = document.querySelector(".checkbox-menu");
        if (elem) {
          elem.scrollTop = elem.scrollHeight;
        }
      }
    });
  };

  handleCreate = () => {
    this.props.handleCreate.handler();
    this.setOpen();
  };

  handleUpdate = () => {
    this.props.handleUpdate.handler();
    this.setOpen();
  };

  handleDelete = (e) => {
    var value = isNaN(e.target.dataset.value)
      ? e.target.dataset.value
      : parseInt(e.target.dataset.value);
    this.props.handleDelete(value);
  };

  render() {
    var options = this.props.searchEnabled
      ? this.props.options.filter((option) =>
          this.state.search
            ? option.label
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
            : true
        )
      : this.props.options;
    this.optionsCount = options.length;
    return (
      <div
        style={{
          pointerEvents: this.props.disabled ? "none" : "all",
          opacity: this.props.disabled
            ? this.props.hideDisabled
              ? "0"
              : "0.5"
            : "1",
          display: this.props.display ? this.props.display : "block",
          position: this.props.position ? this.props.position : "relative",
          left: this.props.left ? this.props.left : "",
          top: this.props.top ? this.props.top : "",
          margin: this.props.margin ? this.props.margin : "initial",
          ...this.props.containerStyle,
        }}
      >
        <div
          ref={this.checkBoxContainer}
          key={`checkbox_menu_container_${this.props.id}`}
          className="checkbox-menu-container"
          style={{
            position: "relative",
            width: this.props.width ? this.props.width : "fit-content",
            marginRight: "15px",
          }}
        >
          <div
            role="button"
            className={`checkbox-menu-selector${
              this.state.open ? "-open" : "-closed"
            }`}
            onClick={this.setOpen}
            tabIndex={0}
            aria-label={
              this.props.selectedOption
                ? `${this.props.selectedOption.label} currently selected`
                : this.props.placeholder
            }
            style={{
              height: "36px",
              color: "initial",
              paddingLeft: "10px",
              paddingRight: "10px",
              cursor: "pointer",
              ...this.props.containerButtonStyle,
            }}
          >
            <span
              style={{
                fontSize: "15px",
                lineHeight: "33px",
                width: "100%",
                color: "#519acc",
                justifyContent: "space-between",
                fontWeight: "600",
                whiteSpace: "nowrap",
                display: "flex",
                flex: "1 1 155px",
                overflow: "hidden",
                position: "relative",
                textOverflow: "ellipsis",
                // maxWidth: "235px",
                ...this.props.selectedItemStyle,
              }}
            >
              {this.props.icons && (
                <img
                  alt="icon"
                  style={{
                    width: "36px",
                    height: "36px",
                    verticalAlign: "bottom",
                  }}
                  src={this.props.icons[0]}
                />
              )}
              {this.props.selectedOption &&
                this.props.selectedOption.icons &&
                (!this.props.selectedOption.icons[0].props ? (
                  <img
                    alt="icon"
                    style={
                      this.props.selectedIconStyle
                        ? this.props.selectedIconStyle
                        : {
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                            verticalAlign: "middle",
                          }
                    }
                    src={
                      this.props.selectedOption.icons[2]
                        ? this.props.selectedOption.icons[2]
                        : this.props.selectedOption.icons[1]
                    }
                  />
                ) : this.props.selectedOption.icons[2] ? (
                  this.props.selectedOption.icons[2]
                ) : (
                  this.props.selectedOption.icons[1]
                ))}
              <span style={{ ...this.props.selectedTextStyle }}>
                {this.state.open && this.props.searchEnabled ? (
                  <input
                    onChange={this.handleInput}
                    placeholder={
                      this.props.selectedOption
                        ? this.props.selectedOption.label
                        : typeof this.props.placeholder === "object"
                        ? this.props.placeholder.props.children[0]
                        : this.props.placeholder
                    }
                    ref={this.inputRef}
                    autoComplete={false}
                    maxLength={50}
                    aria-label={`${
                      this.props.selectedOption
                        ? this.props.selectedOption.label
                        : typeof this.props.placeholder === "object"
                        ? this.props.placeholder.props.children[0]
                        : this.props.placeholder
                    }, start typing to filter options`}
                    style={{
                      height: "33px",
                      marginBottom: "0",
                      border: "none",
                      fontSize: "15px",
                      outline: "none",
                      ...this.props.inputStyle,
                    }}
                    value={this.state.search ? this.state.search : ""}
                  />
                ) : this.props.selectedOption ? (
                  this.props.selectedOption.label
                ) : (
                  this.props.placeholder
                )}
              </span>
              <i
                className="flex-container flex-al-center flex-j-center"
                id={`drop-arrow-${this.props.id}`}
                style={{
                  color: "black",
                  transition: `transform ${this.props.menuTransitionTime}s`,
                  ...this.props.arrowStyle,
                }}
              >
                <RoundDropdown svgStyles={this.props.roundDropdownStyle} />
              </i>
            </span>
          </div>
          {this.state.open && (
            <div
              className="checkbox-menu delayed-overflow-container"
              id={`checkbox-menu-${this.props.id}`}
              style={{
                position: "absolute",
                zIndex: "10",
                backgroundColor: "#ffffffd8",
                maxHeight: "0px",
                right: "0px",
                top: "120%",
                padding: "0px",
                transition: `max-height ${this.props.menuTransitionTime}s`,
                width: this.props.width ? this.props.width : "auto",
                maxWidth: "400px",
              }}
            >
              {options.length === 0 ? (
                <div
                  style={{
                    transition: "opacity 0.3s, padding-left 0.3s",
                    opacity:
                      this.props.cascadeTransition && this.state.search === ""
                        ? "0"
                        : "1",
                    paddingRight: "10px",
                    ...this.props.listItemStyle,
                    paddingLeft:
                      this.props.cascadeTransition && this.state.search === ""
                        ? "0px"
                        : "10px",
                  }}
                  key={"no-options"}
                >
                  <span style={{ whiteSpace: "nowrap", color: "lightgray" }}>
                    no options found
                  </span>
                </div>
              ) : (
                options.map((option, index) => {
                  if (
                    option.icons &&
                    option.icons[0] &&
                    !Object.keys(this.props).includes("hideIconsInMenu")
                  ) {
                    return (
                      <div
                        style={{
                          cursor: "pointer",
                          transition:
                            "opacity 0.3s, padding-left 0.3s, overflow 1s",
                          opacity:
                            this.props.cascadeTransition &&
                            this.state.search === ""
                              ? "0"
                              : "1",
                          paddingRight: "10px",
                          ...this.props.listItemStyle,
                          paddingLeft:
                            this.props.cascadeTransition &&
                            this.state.search === ""
                              ? "0px"
                              : "10px",
                        }}
                        data-value={option.value}
                        data-index={index}
                        data-svg={this.props.svg}
                        data-label={option.label}
                        data-icons={option.icons}
                        onClick={this.onChange}
                        key={index}
                        className="basic-select-option"
                      >
                        <span
                          data-value={option.value}
                          data-label={option.label}
                          data-icons={option.icons}
                          data-index={index}
                          data-svg={this.props.svg}
                          style={{
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            color:
                              this.props.selectedOption &&
                              this.props.selectedOption.value === option.value
                                ? "#519acc"
                                : "black",
                            ...this.props.listItemTextStyle,
                          }}
                        >
                          {!option.icons[0].props ? (
                            <img
                              alt="icon"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                verticalAlign: "middle",
                                pointerEvents: "none",
                                ...this.props.listItemImgStyle,
                              }}
                              src={
                                this.props.selectedOption &&
                                this.props.selectedOption.value === option.value
                                  ? option.icons[1]
                                  : option.icons[0]
                              }
                            />
                          ) : this.props.selectedOption &&
                            this.props.selectedOption.value === option.value ? (
                            option.icons[1]
                          ) : (
                            option.icons[0]
                          )}
                          {option.label}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        role="button"
                        aria-label={`${option.label}, Option ${index + 1} of ${
                          options.length
                        }`}
                        style={{
                          cursor: "pointer",
                          transition: "opacity 0.3s, padding-left 0.3s",
                          opacity:
                            this.props.cascadeTransition &&
                            this.state.search === ""
                              ? "0"
                              : "1",
                          paddingRight: "10px",
                          ...this.props.listItemStyle,
                          paddingLeft:
                            this.props.cascadeTransition &&
                            this.state.search === ""
                              ? "0px"
                              : "10px",
                        }}
                        data-value={option.value}
                        data-icons={option.icons}
                        data-label={option.label}
                        onClick={this.onChange}
                        key={index}
                        className="basic-select-option"
                      >
                        <span
                          data-icons={option.icons}
                          data-value={option.value}
                          data-label={option.label}
                          role="option"
                          style={{
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            color:
                              this.props.selectedOption &&
                              this.props.selectedOption.value === option.value
                                ? "#519acc"
                                : "black",
                            ...this.props.listItemTextStyle,
                          }}
                        >
                          {option.label}
                        </span>
                        {this.props.handleDelete && (
                          <button
                            id="delete-button"
                            data-value={option.value}
                            onClick={this.handleDelete}
                          >
                            <DeleteIcon
                              svgStyles={{ pointerEvents: "none" }}
                              color="white"
                            />
                          </button>
                        )}
                      </div>
                    );
                  }
                })
              )}
              {this.props.handleCreate && (
                <div
                  style={{
                    borderTop: "solid 1px lightgray",
                    padding: "6px 4px",
                  }}
                >
                  {this.state.createEdit ? (
                    <div>
                      <input
                        autoFocus={true}
                        value={this.props.handleCreate.name}
                        onChange={this.props.handleCreate.nameHandler}
                        autoComplete="off"
                        maxLength={30}
                        style={{
                          height: "28px",
                          outline: "none",
                          fontSize: "14px",
                          padding: "0 4px",
                          boxSizing: "border-box",
                        }}
                      />
                      {this.props.selectedOption && this.props.handleUpdate ? (
                        <div>
                          <button
                            onClick={this.handleUpdate}
                            className="calm-button span-to-button"
                          >
                            Save
                          </button>
                          <button
                            onClick={this.handleCreate}
                            className="calm-button span-to-button"
                          >
                            Create New
                          </button>
                          <button
                            id="template-cancel"
                            onClick={this.handleCreateEdit}
                            className="calm-button span-to-button"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={this.handleCreate}
                            className="calm-button span-to-button"
                          >
                            Submit
                          </button>
                          <button
                            id="template-cancel"
                            onClick={this.handleCreateEdit}
                            className="calm-button span-to-button"
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      id="template-create"
                      onClick={this.handleCreateEdit}
                      className="calm-button span-to-button"
                    >
                      {this.props.selectedOption && this.props.handleUpdate
                        ? this.props.handleUpdate.text
                        : this.props.handleCreate.text}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

BasicSelect.defaultProps = {
  containerStyle: {},
  listItemStyle: {},
  selectedItemStyle: {},
  selectedTextStyle: {},
  inputStyle: {},
  arrowStyle: {},
  listItemTextStyle: {},
  listItemImgStyle: {},
  containerButtonStyle: {},
  roundDropdownStyle: {},
  cascadeTransition: true,
  menuTransitionTime: 0.4,
  placeholder: "Select an option",
};

export default BasicSelect;

BasicSelect.propTypes = {
  options: PropTypes.array,
  scrollIntoView: PropTypes.string,
  id: PropTypes.string,
  maxHeight: PropTypes.string,
  cascadeTransition: PropTypes.bool,
  menuTransitionTime: PropTypes.number,
  listItemImgStyle: PropTypes.object,
  listItemStyle: PropTypes.object,
  searchEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  display: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  margin: PropTypes.string,
  position: PropTypes.string,
  containerStyle: PropTypes.object,
  width: PropTypes.string,
  icons: PropTypes.array,
  selectedOption: PropTypes.object,
  selectedTextStyle: PropTypes.object,
  selectedItemStyle: PropTypes.object,
  selectedIconStyle: PropTypes.object,
  svg: PropTypes.string,
  listItemTextStyle: PropTypes.object,
  arrowStyle: PropTypes.object,
  placeholder: PropTypes.any,
};
