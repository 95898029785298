import styled from "styled-components";

export const ControlsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;

export const ModalBody = styled.div`
  background-color: white;
  border-radius: 6px;
`;

export const ModalContent = styled.div`
  padding: 23px;
  display: flex;
  flex-direction: column;
`;
