import React from 'react'

class Device extends React.PureComponent{

  constructor(props) {
    super(props);
  }

  render() {
     return (
      <tr>
        {this.props.device.map((x, i) => {
          return (
            <td key={x}>
              {this.props.device[i]}
            </td>
          )
        })}
      </tr>
    );
   }
}

export default Device