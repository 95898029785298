/*
Previously, we had all of these icons defined and exported in this one giant awful file,
living outside of our icons directory. Should still get rid of this file and have all
components import them directly from the icons directory, but this at least gets rid of
the huge unreadable file.
*/

// ================ NAV ==================
import AdminPanel from "@icons/nav/AdminPanel";
import AdminPanelFilled from "@icons/nav/AdminPanelFilled";
import BookIcon from "@icons/nav/BookIcon";
import Bugs from "@icons/nav/Bugs";
import BugsFilled from "@icons/nav/BugsFilled";
import Builds from "@icons/nav/Builds";
import BuildsFilled from "@icons/nav/BuildsFilled";
import CommunityIcon from "@icons/nav/CommunityIcon";
import Dashboard from "@icons/nav/Dashboard";
import DashboardFilled from "@icons/nav/DashboardFilled";
import DeviceLab from "@icons/nav/DeviceLab";
import DeviceLabFilled from "@icons/nav/DeviceLabFilled";
import HelpIcon from "@icons/nav/HelpIcon";
import LibraryIcon from "@icons/nav/LibraryIcon";
import Skills from "@icons/nav/Skills";
import SkillsFilled from "@icons/nav/SkillsFilled";
import TestDirectories from "@icons/nav/TestDirectories";
import TestDirectoriesFilled from "@icons/nav/TestDirectoriesFilled";
import TestPasses from "@icons/nav/TestPasses";
import TestPassesFilled from "@icons/nav/TestPassesFilled";

// ================ TIME RANGE ==================
import AllTime from "@icons/time_range/AllTime";
import AllTimeFilled from "@icons/time_range/AllTimeFilled";
import AllTimeSelected from "@icons/time_range/AllTimeSelected";
import AllTimeSelectedFilled from "@icons/time_range/AllTimeSelectedFilled";
import CustomDate from "@icons/time_range/CustomDate";
import CustomDateFilled from "@icons/time_range/CustomDateFilled";
import CustomDateSelected from "@icons/time_range/CustomDateSelected";
import CustomDateSelectedFilled from "@icons/time_range/CustomDateSelectedFilled";
import Last30 from "@icons/time_range/Last30";
import Last30Filled from "@icons/time_range/Last30Filled";
import Last30Selected from "@icons/time_range/Last30Selected";
import Last30SelectedFilled from "@icons/time_range/Last30SelectedFilled";
import Month from "@icons/time_range/Month";
import MonthFilled from "@icons/time_range/MonthFilled";
import MonthSelected from "@icons/time_range/MonthSelected";
import MonthSelectedFilled from "@icons/time_range/MonthSelectedFilled";
import Today from "@icons/time_range/Today";
import TodayFilled from "@icons/time_range/TodayFilled";
import TodaySelected from "@icons/time_range/TodaySelected";
import TodaySelectedFilled from "@icons/time_range/TodaySelectedFilled";
import Week from "@icons/time_range/Week";
import WeekFilled from "@icons/time_range/WeekFilled";
import WeekSelected from "@icons/time_range/WeekSelected";
import WeekSelectedFilled from "@icons/time_range/WeekSelectedFilled";
import Yesterday from "@icons/time_range/Yesterday";
import YesterdayFilled from "@icons/time_range/YesterdayFilled";
import YesterdaySelected from "@icons/time_range/YesterdaySelected";
import YesterdaySelectedFilled from "@icons/time_range/YesterdaySelectedFilled";

// ================ BADGE ==================
import AccessibilityExpert from "@icons/badge/AccessibilityExpert";
import AccessibilityExpertSmall from "@icons/badge/AccessibilityExpertSmall";
import AppTestingExpert from "@icons/badge/AppTestingExpert";
import AppTestingExpertSmall from "@icons/badge/AppTestingExpertSmall";
import AutomationExpert from "@icons/badge/AutomationExpert";
import AutomationExpertSmall from "@icons/badge/AutomationExpertSmall";
import BugsBase from "@icons/badge/BugsBase";
import BugsBronze from "@icons/badge/BugsBronze";
import BugsBronzeSmall from "@icons/badge/BugsBronzeSmall";
import BugsGold from "@icons/badge/BugsGold";
import BugsGoldSmall from "@icons/badge/BugsGoldSmall";
import BugsSilver from "@icons/badge/BugsSilver";
import BugsSilverSmall from "@icons/badge/BugsSilverSmall";
import ExpertBase from "@icons/badge/ExpertBase";
import ExpertTrainer from "@icons/badge/ExpertTrainer";
import ExpertTrainerSmall from "@icons/badge/ExpertTrainerSmall";
import TestCasesBase from "@icons/badge/TestCasesBase";
import TestCasesBronze from "@icons/badge/TestCasesBronze";
import TestCasesBronzeSmall from "@icons/badge/TestCasesBronzeSmall";
import TestCasesExpert from "@icons/badge/TestCasesExpert";
import TestCasesExpertSmall from "@icons/badge/TestCasesExpertSmall";
import TestCasesGold from "@icons/badge/TestCasesGold";
import TestCasesGoldSmall from "@icons/badge/TestCasesGoldSmall";
import TestCasesSilver from "@icons/badge/TestCasesSilver";
import TestCasesSilverSmall from "@icons/badge/TestCasesSilverSmall";
import TestPassBase from "@icons/badge/TestPassBase";
import TestPassBronze from "@icons/badge/TestPassBronze";
import TestPassBronzeSmall from "@icons/badge/TestPassBronzeSmall";
import TestPassGold from "@icons/badge/TestPassGold";
import TestPassGoldSmall from "@icons/badge/TestPassGoldSmall";
import TestPassSilver from "@icons/badge/TestPassSilver";
import TestPassSilverSmall from "@icons/badge/TestPassSilverSmall";
import TrialistIcon from "@icons/badge/TrialistIcon";
import TrialistIconSmall from "@icons/badge/TrialistIconSmall";
import WebTestingExpert from "@icons/badge/WebTestingExpert";
import WebTestingExpertSmall from "@icons/badge/WebTestingExpertSmall";

export const timeRangeIcons = {
  AllTime,
  AllTimeFilled,
  AllTimeSelected,
  AllTimeSelectedFilled,
  CustomDate,
  CustomDateFilled,
  CustomDateSelected,
  CustomDateSelectedFilled,
  Last30,
  Last30Filled,
  Last30Selected,
  Last30SelectedFilled,
  Month,
  MonthFilled,
  MonthSelected,
  MonthSelectedFilled,
  Today,
  TodayFilled,
  TodaySelected,
  TodaySelectedFilled,
  Week,
  WeekFilled,
  WeekSelected,
  WeekSelectedFilled,
  Yesterday,
  YesterdayFilled,
  YesterdaySelected,
  YesterdaySelectedFilled,
};

export const navIcons = {
  AdminPanel,
  AdminPanelFilled,
  BookIcon,
  Bugs,
  BugsFilled,
  Builds,
  BuildsFilled,
  CommunityIcon,
  Dashboard,
  DashboardFilled,
  DeviceLab,
  DeviceLabFilled,
  HelpIcon,
  LibraryIcon,
  Skills,
  SkillsFilled,
  TestDirectories,
  TestDirectoriesFilled,
  TestPasses,
  TestPassesFilled,
};

export const badgeIcons = {
  AccessibilityExpert,
  AccessibilityExpertSmall,
  AppTestingExpert,
  AppTestingExpertSmall,
  AutomationExpert,
  AutomationExpertSmall,
  BugsBase,
  BugsBronze,
  BugsBronzeSmall,
  BugsGold,
  BugsGoldSmall,
  BugsSilver,
  BugsSilverSmall,
  ExpertBase,
  ExpertTrainer,
  ExpertTrainerSmall,
  TestCasesBase,
  TestCasesBronze,
  TestCasesBronzeSmall,
  TestCasesExpert,
  TestCasesExpertSmall,
  TestCasesGold,
  TestCasesGoldSmall,
  TestCasesSilver,
  TestCasesSilverSmall,
  TestPassBase,
  TestPassBronze,
  TestPassBronzeSmall,
  TestPassGold,
  TestPassGoldSmall,
  TestPassSilver,
  TestPassSilverSmall,
  TrialistIcon,
  TrialistIconSmall,
  WebTestingExpert,
  WebTestingExpertSmall,
};

export const CalendarIcon = Last30Selected;
