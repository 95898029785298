import React from "react";
import PropTypes from "prop-types";
import ProgressRing from "@components/common/progress_ring";
import { userTypes } from "@types";
import styled from "styled-components";

const TestPassProgressWidget = (props) => {
  const { testPass, currentUser } = props;
  const { progress } = testPass;
  const totalSteps = Object.values(progress).reduce((a, b) => a + b, 0);

  return (
    <TestPassProgressContainer>
      <InfoPanel>
        <RingContainer>
          <ProgressRing
            phantomRing
            radius={28}
            text={testPass.percent_complete}
            textStyles={{
              stroke: "#000000",
              fill: "#000000",
              fontSize: "12px",
            }}
            relative
            stroke={6}
            progress={testPass.percent_complete.slice(0, -1)}
          />
        </RingContainer>
        <TestPassInfo>
          <TestPassTitleContainer>
            <img
              style={{ margin: "0 2px 0 -5px" }}
              alt="testpass icon"
              width="30"
              height="30"
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/IconsTestPassBlack.svg"
            />
            <TestPassTitle
              data-message={testPass.name.substring(14, testPass.name.length)}
            >
              {currentUser.role !== "client" ||
              (currentUser.additional_permissions &&
                currentUser.additional_permissions.indexOf("Test Passes") !==
                  -1) ? (
                <a href={`/test_passes/${testPass.id}`}>{testPass.name}</a>
              ) : (
                <span>{testPass.name}</span>
              )}
            </TestPassTitle>
          </TestPassTitleContainer>
          <label style={{ color: "#7d7d7d" }}>
            {testPass.steps_remaining} steps remaining
          </label>
        </TestPassInfo>
      </InfoPanel>
      <ProgressChartPanel>
        <div
          style={{
            display: "block",
            backgroundColor: "transparent",
            minWidth: "275px",
            borderRadius: "2px",
            height: "12px",
            border: "solid 1px lightgray",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              position: "relative",
              display: "inline-flex",
              width: `${(progress[1] / totalSteps) * 100}%`,
              justifyContent: "center",
              verticalAlign: "top",
              backgroundColor: "#9ddea8",
            }}
            data-color="#7a9e80"
          />
          <div
            style={{
              height: "100%",
              display: "inline-flex",
              width: `${(progress[2] / totalSteps) * 100}%`,
              justifyContent: "center",
              verticalAlign: "top",
              backgroundColor: "#c3552b",
            }}
            data-color="#c3552b"
          />
          <div
            style={{
              height: "100%",
              display: "inline-flex",
              width: `${(progress[3] / totalSteps) * 100}%`,
              justifyContent: "center",
              verticalAlign: "top",
              backgroundColor: "#f5ee89",
            }}
            data-color="#c5bc3e"
          />
          <div
            style={{
              height: "100%",
              display: "inline-flex",
              width: `${(progress[4] / totalSteps) * 100}%`,
              justifyContent: "center",
              verticalAlign: "top",
              backgroundColor: "#519bcc",
            }}
            data-color="#519bcc"
          />
          <div
            style={{
              height: "100%",
              display: "inline-flex",
              width: `${(progress[5] / totalSteps) * 100}%`,
              justifyContent: "center",
              verticalAlign: "top",
              backgroundColor: "lavender",
            }}
            data-color="#9c9cda"
          />
          <div
            style={{
              height: "100%",
              display: "inline-flex",
              width: `${(progress[0] / totalSteps) * 100}%`,
              justifyContent: "center",
              verticalAlign: "top",
              backgroundColor: "#f6f2f2",
            }}
            data-color="#afafaf"
          />
        </div>
        <div
          style={{
            minWidth: "275px",
            display: "flex",
            textAlign: "center",
            justifyContent: "space-between",
            fontSize: "10pt",
            lineHeight: "20px",
            marginTop: "15px",
          }}
        >
          <div style={{ fontWeight: "600" }}>
            <span>{progress[1]}</span>
            <br />
            <span style={{ color: "#b3b3b3" }}>Pass</span>
          </div>
          <div style={{ fontWeight: "600" }}>
            <span>{progress[2]}</span>
            <br />
            <span style={{ color: "#b3b3b3" }}>Fail</span>
          </div>
          <div style={{ fontWeight: "600" }}>
            <span>{progress[3]}</span>
            <br />
            <span style={{ color: "#b3b3b3" }}>Blocked</span>
          </div>
          <div style={{ fontWeight: "600" }}>
            <span>{progress[4]}</span>
            <br />
            <span style={{ color: "#b3b3b3" }}>Retest</span>
          </div>
          <div style={{ fontWeight: "600" }}>
            <span>{progress[5]}</span>
            <br />
            <span style={{ color: "#b3b3b3" }}>N/A</span>
          </div>
          <div style={{ fontWeight: "600" }}>
            <span>{progress[0]}</span>
            <br />
            <span style={{ color: "#b3b3b3" }}>Untested</span>
          </div>
          <div style={{ lineHeight: "33px" }}>
            <span style={{ fontWeight: "600" }}>
              {progress[1] +
                progress[2] +
                progress[3] +
                progress[4] +
                progress[5]}{" "}
              / {totalSteps}
            </span>
          </div>
        </div>
      </ProgressChartPanel>
    </TestPassProgressContainer>
  );
};

const TestPassProgressContainer = styled.div`
  position: relative;
  height: 100px;
  width: 99%;
  overflow: hidden;
  flex: 0 0 auto;
  box-shadow: 0px 0px 4px #00000033;
  padding: 5px;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
`;

const INFO_PANEL_WIDTH = 400;

const InfoPanel = styled.div`
  display: flex;
  width: ${INFO_PANEL_WIDTH}px;
`;

const ProgressChartPanel = styled.div`
  width: calc(100% - ${INFO_PANEL_WIDTH}px);
  flex: 1;
  position: relative;
  margin-right: 15px;
  padding-top: 15px;
`;

const RingContainer = styled.div`
  padding: 15px 10px;
  position: relative;
  display: flex;
  align-items: center;
`;

const TestPassInfo = styled.div`
  margin-left: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TestPassTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(
    ${INFO_PANEL_WIDTH}px - 76px
  ); // width of left panel minus width of progress ring
  margin-bottom: 5px;
`;

const TestPassTitle = styled.p`
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  font-family: Arial;
  max-width: calc(${INFO_PANEL_WIDTH}px - 76px - 30px);

  a {
    display: inline-block;
    color: #242424;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(${INFO_PANEL_WIDTH}px - 76px - 30px - 8px);
  }
`;

TestPassProgressWidget.propTypes = {
  testPass: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    percent_complete: PropTypes.string,
    progress: PropTypes.objectOf(PropTypes.number),
    status: PropTypes.string,
    steps_remaining: PropTypes.number,
  }),
  currentUser: userTypes,
};

export default TestPassProgressWidget;
