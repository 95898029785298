import React from "react";

const EmptyHours = () => {
  return (
    <svg
      width="61"
      height="63"
      viewBox="0 0 61 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M30.5 63.0001C47.3447 63.0001 61 49.4279 61 32.6858C61 15.9437 47.3447 2.37158 30.5 2.37158C13.6553 2.37158 0 15.9437 0 32.6858C0 49.4279 13.6553 63.0001 30.5 63.0001Z"
            fill="#00100F"
          />
        </g>
      </g>
      <path
        d="M30.5 60.6285C47.3447 60.6285 61 47.0563 61 30.3142C61 13.5721 47.3447 0 30.5 0C13.6553 0 0 13.5721 0 30.3142C0 47.0563 13.6553 60.6285 30.5 60.6285Z"
        fill="#519ACC"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M30.5 0.206055C13.6939 0.206055 0 13.6103 0 30.1079C0 30.5203 0 30.9327 0 31.3452C0.518708 15.157 14.0051 2.26825 30.5 2.26825C46.7874 2.26825 60.2738 14.9507 60.8963 30.9327C60.8963 30.6234 60.8963 30.3141 60.8963 30.0047C61 13.6103 47.3061 0.206055 30.5 0.206055Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 38.2539H6.74316V38.4601H54.3605V38.2539Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 44.7495H6.74316V44.9557H54.3605V44.7495Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 30.4175H6.74316V30.6237H54.3605V30.4175Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 23.1997H6.74316V23.4059H54.3605V23.1997Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 14.4355H6.74316V14.6418H54.3605V14.4355Z"
          fill="white"
        />
      </g>
      <path
        d="M48.1818 20C45.3182 20 43.7909 22.6353 44.5545 24.7059L37.6818 31.4824C37.1091 31.2941 36.3455 31.2941 35.7727 31.4824L30.8091 26.5882C31.5727 24.5176 30.0455 21.8824 27.1818 21.8824C24.5091 21.8824 22.7909 24.5176 23.5545 26.5882L14.7727 35.0588C12.6727 34.4941 10 36 10 38.8235C10 40.8941 11.7182 42.5882 13.8182 42.5882C16.4909 42.5882 18.2091 39.9529 17.4455 37.8824L26.0364 29.2235C26.6091 29.4118 27.3727 29.4118 27.9455 29.2235L32.9091 34.1176C32.3364 36 33.8636 38.8235 36.7273 38.8235C39.5909 38.8235 41.1182 36.1882 40.3545 34.1176L47.2273 27.3412C49.3273 27.9059 52 26.4 52 23.7647C52 21.6941 50.2818 20 48.1818 20Z"
        fill="white"
      />
    </svg>
  );
};

export default EmptyHours;
