import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swal from "sweetalert2";
import useDeviceLab from "@hooks/data/useDeviceLab";
import { deviceTypes } from "@types";
import { DeviceLabContext } from "@components/devices/device_lab";

const CheckoutHover = (props) => {
  const { device, avatarUrl } = props;
  const { currentUser } = useContext(DeviceLabContext);

  const {
    hooks: {
      queries: { useGetDeviceCheckoutDate },
      mutations: { useCheckInDevice },
    },
  } = useDeviceLab();

  const { data: checkoutDate, isLoading: checkoutLoading } =
    useGetDeviceCheckoutDate(device.id);

  const [checkInDevice] = useCheckInDevice();

  const handleCheckInButton = useCallback(async () => {
    const result = await checkInDevice({ device_id: device.id });

    if (result.data) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "<span role='alert'>Device Checked In Successfully</span>",
        displayLength: 3000,
        classes: "green",
      });
    }

    if (result.error) {
      console.error(result.error);
      Swal.fire("Checkin Error", "There was an error checking in this device");
    }
  }, [device, checkInDevice]);

  const loading = checkoutLoading || checkoutDate === null;

  return (
    <CheckoutHoverContainer
      data-cellid={device.id}
      className="nav-checkout-item checked-out"
      key={device.id}
    >
      <CheckedOut className="checked-out">
        <Avatar
          alt="user icon"
          src={
            avatarUrl ||
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
          }
        />
        <DeviceDetails>
          <UserDeviceHeadline>
            <span style={{ textTransform: "capitalize" }}>
              {device.user.name}
            </span>
            &apos;s Device
          </UserDeviceHeadline>
          {device.tier && <p>Tier {device.tier} Device</p>}
          <CheckoutDateHeadline>
            Checkout Date:
            {loading ? (
              <LoadingSpinner id="pass-loader" />
            ) : (
              <CheckoutDate>{checkoutDate}</CheckoutDate>
            )}
          </CheckoutDateHeadline>
        </DeviceDetails>
      </CheckedOut>
      <CheckoutControls>
        {device.user.id !== currentUser.id && (
          <button
            type="button"
            className="btn btn-grey"
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Request.svg"
            data-id={device.id}
            data-cellid={device.id}
            data-text="Check In Device"
            data-checked-in
            data-user-name={device.user.name}
            data-user-id={device.user.id}
            data-device-name={device.device_name}
            data-device-id={device.id}
            onClick={handleCheckInButton}
          >
            Check In Device
          </button>
        )}
      </CheckoutControls>
    </CheckoutHoverContainer>
  );
};

const CheckoutControls = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    margin: 2.5px 0 5;
    width: 100%;
    border: none;
    background-color: #dbdbdb;
  }
`;

const CheckoutDate = styled.span`
  color: #519acc;
  padding-left: 5px;
`;

const LoadingSpinner = styled.span`
  display: inline-block;
  vertical-align: -5px;
  margin-left: 10px;
`;

const CheckoutDateHeadline = styled.p`
  color: rgb(156, 156, 156);
  font-size: 12px;
`;

const UserDeviceHeadline = styled.p`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DeviceDetails = styled.div`
  text-align: left;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
`;

const CheckedOut = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: 10px;
`;

const CheckoutHoverContainer = styled.div`
  cursor: default;
  background-color: white;
  max-width: 300px;
  border-radius: 10px;
  flex: 1;
  border: solid 1px #519acc;
  height: 100%;
  flex-direction: column;
  display: flex;
  white-space: nowrap;
  border: solid 2px gray;
  padding: 8px 8px 8px 8px;
  display: flex;
  overflow: hidden;
  align-items: center;
  position: relative;
`;

CheckoutHover.propTypes = {
  device: deviceTypes,
  avatarUrl: PropTypes.string,
};

export default CheckoutHover;
