import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { deviceLabEndpoints, DEVICE_LAB_TAGS } from "./deviceLab";

const testPlatformApi = createApi({
  reducerPath: "testPlatformApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers) => {
      const csrfToken = document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content");
      headers.set("Accept", "application/json");
      headers.set("X-CSRF-TOKEN", csrfToken);
      return headers;
    },
  }),
  tagTypes: [...DEVICE_LAB_TAGS],
  endpoints: (builder) => {
    return {
      ...deviceLabEndpoints(builder),
    };
  },
});

export default testPlatformApi;

export const {
  // device lab
  useGetDeviceLabIndexQuery,
  useGetDeviceLabSettingsQuery,
  useGetUserDeviceTierQuery,
  useGetUserDevicesQuery,
  useGetDeviceCheckoutDateQuery,
  useCheckOutDeviceMutation,
  useCheckInDeviceMutation,
  useEditDeviceMutation,
  useCreateDeviceMutation,
  useUpdateDeviceLabSettingsMutation,
  //
} = testPlatformApi;
