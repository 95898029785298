import React from "react";
import PropTypes from "prop-types";

const TestPasses = ({ active, ...props }) => {
  const pathClassName = active
    ? "active-path"
    : "IconsTestPassBlack_svg__cls-1";

  return (
    <svg
      id="IconsTestPassBlack_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        className={pathClassName}
        d="M12.87 52.51a3 3 0 002.84 3.17h32.58a3 3 0 002.84-3.17v-41a3 3 0 00-2.84-3.17H12.87z"
      />
      <path
        className={pathClassName}
        d="M18.61 17.21l2.6 2.8 4.85-4.95m3.56 2.98H45.4M18.61 31.33l2.6 2.8 4.85-4.95m3.56 3.26H45.4M28.99 45.77h15.78m-25.54 2.47l4.94-4.93m-4.94 0l4.94 4.93"
      />
    </svg>
  );
};

TestPasses.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default TestPasses;
