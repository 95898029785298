import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { wikiAssessmentTypes } from "../../../../types/wiki";
import ApiClient from "../../../../utils/ApiClient";
import ModalHeader from "../../../modals/common/modal_header";
import { ModalBody, ModalContent } from "../../../modals/common/modal_styles";
import { SubmitButton, CancelButton } from "../../../common/modal_buttons";
import { RadioButton } from "../../../common/FormElements";
import {
  AddResourceForm as CreateAssessmentForm,
  FormItem,
} from "../../AddWikiResourceForm";

const CreateNewAssessmentForm = (props) => {
  const { toggleModalVisible, assessment, wiki_resources, onSubmitSuccessCb } =
    props;

  const resourceOptions = useMemo(() => {
    return wiki_resources.map((resource) => ({
      value: resource.id,
      label: resource.title,
    }));
  }, [wiki_resources]);

  const performanceCategoryOptions = useMemo(() => {
    return [
      { value: "web_testing", label: "Web Testing" },
      { value: "mobile_app_testing", label: "Mobile App Testing" },
      { value: "test_automation", label: "Test Automation" },
      { value: "accessibility", label: "Accessibility" },
      { value: "project_lead", label: "Project Lead" },
      { value: "team_manager", label: "Team Manager" },
    ];
  }, []);

  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      level: assessment ? assessment.level.toString() : "1",
      time_limit: assessment ? assessment.time_limit : undefined,
      linked_resource: assessment
        ? resourceOptions.find((r) => r.value === assessment.wiki_resource.id)
        : undefined,
      performance_category: assessment
        ? performanceCategoryOptions.find(
            (r) => r.value === assessment.performance_category
          )
        : undefined,
    },
  });

  const [currentLinkedResource, level, timeLimit, currentPerformanceCategory] =
    watch(["linked_resource", "level", "time_limit", "performance_category"]);

  const postNewAssessment = ({
    wiki_resource,
    level: new_level,
    time_limit,
    performance_category,
  }) => {
    const api = new ApiClient();
    return api.post("/wiki-assessments", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      wiki_assessment: {
        wiki_resource,
        level: new_level,
        time_limit,
        performance_category,
      },
    });
  };

  const editAssessmentDetails = ({
    wiki_resource,
    level: new_level,
    time_limit,
    performance_category,
  }) => {
    const api = new ApiClient();
    return api.patch(
      `/wiki-assessments/${assessment.id}/update_specifications`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        wiki_assessment: {
          wiki_resource,
          level: new_level,
          time_limit,
          performance_category,
        },
      }
    );
  };

  const onCancel = () => {
    reset();
    toggleModalVisible();
  };

  const onSubmit = async (data) => {
    try {
      const {
        linked_resource,
        level: new_level,
        time_limit,
        performance_category,
      } = data;
      const body = {
        wiki_resource: linked_resource.value,
        level: new_level,
        time_limit: time_limit || null,
        performance_category: performance_category.value,
      };
      const response = assessment
        ? await editAssessmentDetails(body)
        : await postNewAssessment(body);
      const newAssessment = response.data;
      reset();
      toggleModalVisible();
      if (onSubmitSuccessCb) {
        onSubmitSuccessCb(newAssessment);
      }
    } catch (e) {
      console.error(e);
      // eslint-disable-next-line no-undef
      M.toast({
        html:
          e.response?.status === 500
            ? "An internal server error has occurred."
            : e.message,
        classes: "red darken-1 error-toast",
      });
    }
  };

  const preventEditAssessmentContentSubmit = (cb) => {
    // with this component/form structure, with the edit assessment form here nested underneath the
    // assessment builder form, submitting this form will also submit the assessment builder form.
    // wrap the submit callback here to prevent the event from propagating to the assessment builder form.
    return (e) => {
      e.stopPropagation();
      cb();
      e.preventDefault();
    };
  };

  const onError = (errors) => {
    for (const error of Object.values(errors)) {
      if (error.message) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: error.message,
          classes: "red darken-1 error-toast",
        });
      }
    }
  };

  return (
    <StyledModalBody>
      <StyledModalHeader
        title={assessment ? "Edit Assessment Details" : "Create an Assessment"}
        subTitle={
          !assessment
            ? "Create a new assessment for a Test Platform Wiki resource."
            : ""
        }
        showHeaderImg={false}
        modalAction={onCancel}
        data={{ customClass: "create-new-assessment-modal-header" }}
      />
      <StyledModalContent>
        <CreateAssessmentForm
          onSubmit={preventEditAssessmentContentSubmit(
            handleSubmit(onSubmit, onError)
          )}
        >
          <FormItem>
            <label htmlFor="linked_resource">
              Linked Resource<span style={{ color: "#519ACC" }}>*</span>
            </label>
            <StyledSelect
              {...register("linked_resource", {
                required: {
                  value: true,
                  message:
                    "Please select an associated Wiki resource for this assessment.",
                },
              })}
              menuPortalTarget={document.querySelector("body")}
              options={resourceOptions}
              value={currentLinkedResource}
              classNamePrefix="Select"
              isSearchable={false}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              onChange={(selectedOption) => {
                setValue("linked_resource", selectedOption);
              }}
            />
          </FormItem>
          <FormItem>
            <label htmlFor="performance_category">
              Performance Category<span style={{ color: "#519ACC" }}>*</span>
            </label>
            <StyledSelect
              {...register("performance_category", {
                required: {
                  value: true,
                  message:
                    "Please select a performance category for this assessment.",
                },
              })}
              menuPortalTarget={document.querySelector("body")}
              options={performanceCategoryOptions}
              value={currentPerformanceCategory}
              classNamePrefix="Select"
              isSearchable={false}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              onChange={(selectedOption) => {
                setValue("performance_category", selectedOption);
              }}
            />
          </FormItem>
          <FormItem>
            <label htmlFor="level">Assessment Level</label>
            <LevelContainer {...register("level")} className="common-radio">
              <StyledRadioButton
                type="radio"
                onChange={() => setValue("level", "1")}
                checked={level === "1"}
                value="1"
              >
                1
              </StyledRadioButton>
              <StyledRadioButton
                type="radio"
                onChange={() => setValue("level", "2")}
                checked={level === "2"}
                value="2"
              >
                2
              </StyledRadioButton>

              <StyledRadioButton
                type="radio"
                onChange={() => setValue("level", "3")}
                checked={level === "3"}
                value="3"
              >
                3
              </StyledRadioButton>
            </LevelContainer>
          </FormItem>
          <div>
            <FormLabel htmlFor="time_limit">Time Limit (in hours)</FormLabel>
            <div style={{ display: "flex", marginTop: "6px", height: "39px" }}>
              <TimeLimitInput
                {...register("time_limit", {
                  pattern: /[0-9]{4}/,
                  maxLength: 4,
                  min: 0,
                  max: 9999,
                  valueAsNumber: true,
                })}
                value={timeLimit}
                className="browser-default"
                type="number"
                min="0"
                max="9999"
                step="0.25"
              />
            </div>
          </div>
          <ButtonsControl>
            <CancelButton onClick={onCancel} htmlType="button">
              Cancel
            </CancelButton>
            <SubmitButton htmlType="submit">
              {assessment ? "Update" : "Create"}
            </SubmitButton>
          </ButtonsControl>
        </CreateAssessmentForm>
      </StyledModalContent>
    </StyledModalBody>
  );
};

export const StyledModalBody = styled(ModalBody)`
  background-color: transparent;
  border-radius: 10px;
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: #519acc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
`;

export const StyledModalContent = styled(ModalContent)`
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
`;

const StyledSelect = styled(Select)`
  margin-top: 6px;
  max-width: 275px;

  .Select__control {
    height: 38px;
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    cursor: pointer;
  }

  .Select__control:hover {
    border: 1px solid #d3d3d3;
  }

  .Select__control--is-focused {
    outline: none;
  }

  .Select__value-container {
    height: 38px;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__indicator {
    color: #519acc;
  }
`;

const FormLabel = styled.label`
  color: #242424;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  font-family: "Manrope", sans-serif;
`;

export const StyledCheckbox = styled.input`
  display: inline-block;
  margin-right: 20px;

  &[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
    height: 20px;
    width: 20px;
    top: 2px;
  }

  &[type="checkbox"].filled-in:checked + span:not(.lever):after {
    height: 20px;
    width: 20px;
    top: 2px;
    background-color: #519acc;
    border-color: #519acc;
  }
`;

const LevelContainer = styled.div`
  display: flex;
  margin-top: 6px;
  height: 39px;
`;

export const StyledRadioButton = styled(RadioButton)`
  margin-right: 24px;

  > label > span.radio-button-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family: Inter, sans-serif;
    padding-left: 30px;
  }

  &.common-radio.radio-button > label > span.radio-button-label::before {
    height: 20px;
    width: 20px;
    top: 0;
  }

  &.common-radio.radio-button > label > span.radio-button-label::after {
    height: 20px;
    width: 20px;
    top: 0;
  }
`;

const TimeLimitInput = styled.input`
  width: 70px;
  height: 40px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 12px 16px;
`;

export const ButtonsControl = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;

CreateNewAssessmentForm.propTypes = {
  toggleModalVisible: PropTypes.func.isRequired,
  assessment: wikiAssessmentTypes,
  onSubmitSuccessCb: PropTypes.func,
  wiki_resources: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })
  ),
};

CreateNewAssessmentForm.defaultProps = {
  assessment: null,
};

export default CreateNewAssessmentForm;
