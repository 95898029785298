import React, { useMemo } from "react";
import queryString from "query-string";
import CheckboxMenu from "@components/common/checkbox_menu";
import useDeviceLab from "@hooks/data/useDeviceLab";

const FilterByPlatform = () => {
  const {
    settings,
    filters,
    PARAM_KEYS: { PLATFORMS, PAGE },
  } = useDeviceLab();

  const { query, platforms: currentPlatforms } = filters;

  const platformOptions = useMemo(() => {
    if (!settings || !settings.options) {
      return [];
    }

    // TODO: the platform options are sent as response to /device_lab/settings.json as options.Platform,
    // would be nice to be consistent and rename that options array from "Platform" to "platforms"
    // as the string constant PLATFORMS="platforms" is the query key we send to filter by platform,
    // but changing the json response will break a few different other things, mainly the Add Device
    // modal. doing it the lazy way here for now, will get the correct options in either case.
    if (settings.options[PLATFORMS]) {
      return settings.options[PLATFORMS];
    }

    if (settings.options.Platform) {
      return settings.options.Platform;
    }

    return [];
  }, [settings, PLATFORMS]);

  const handlePlatformChange = (event) => {
    const platformId = event.target.value;
    const nextPlatforms = query[PLATFORMS] || [];
    delete query[PAGE];

    if (nextPlatforms.includes(platformId)) {
      // remove
      nextPlatforms.splice(nextPlatforms.indexOf(platformId), 1);
    } else {
      // add
      nextPlatforms.push(platformId);
    }

    const nextQueryString = queryString.stringify(
      { ...query, [PLATFORMS]: nextPlatforms },
      { arrayFormat: "bracket" } // represent the platform array in query string as ?platforms[]=id1&platforms[]=id2
    );
    window.history.replaceState({}, "", `?${nextQueryString}`);
  };

  if (!platformOptions || platformOptions.length === 0) {
    return null;
  }

  return (
    <CheckboxMenu
      key={1}
      maxWidth="100%"
      searchEnabled
      count={currentPlatforms.length}
      active={currentPlatforms.length >= 1}
      options={platformOptions.map((platform) => ({
        label: platform.label,
        value: platform.value,
        handler: handlePlatformChange,
        checked: currentPlatforms.indexOf(platform.value) !== -1,
        classList: "user-option",
      }))}
      title="Filter By Platform"
      id="1"
      icons={[
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
      ]}
    />
  );
};

export default FilterByPlatform;
