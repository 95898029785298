import React from "react";

const BugsBronze = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Bugs_-_Bronze_svg__cls-2{fill:#a34c21}.Skills__Bugs_-_Bronze_svg__cls-3{fill:#b85a24}.Skills__Bugs_-_Bronze_svg__cls-5{fill:#fff}.Skills__Bugs_-_Bronze_svg__cls-6{fill:#4588ad}.Skills__Bugs_-_Bronze_svg__cls-7{fill:#aa4f22}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g id="Skills__Bugs_-_Bronze_svg__Layer_2" data-name="Layer 2">
        {" "}
        <path
          d="M376.49 530.74a190.34 190.34 0 01-27.88-99.48c0-105.61 85.62-191.26 191.23-191.26s191.22 85.62 191.22 191.23a190.35 190.35 0 01-32.15 106.17z"
          fill="#4281a5"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-2"
          d="M488 897.11l-229.25-134.7a98.66 98.66 0 01-48.67-85.82l2-265.86a98.63 98.63 0 0150-85.05l231.29-131.16a98.66 98.66 0 0198.66.76L821.25 330a98.65 98.65 0 0148.67 85.81l-2 265.86a98.65 98.65 0 01-50 85.06L586.63 897.87a98.66 98.66 0 01-98.63-.76z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-2"
          d="M178.06 541.81l-39.87 8.51L168 595.61l-17.81 76.26 58.07-8.51 9.29-.78-6.2-120.77h-33.29zM903.61 541.81l42.2 8.51-32.13 45.29 17.8 76.26-58.06-8.51-9.29-.78 6.19-120.77h33.29z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-3"
          d="M488 884.72L258.75 750a98.65 98.65 0 01-48.67-85.81l2-265.86a98.65 98.65 0 0150-85.06l231.29-131.14a98.66 98.66 0 0198.66.76l229.22 134.7a98.66 98.66 0 0148.67 85.82l-2 265.86a98.63 98.63 0 01-50 85.05L586.63 885.48a98.66 98.66 0 01-98.63-.76z"
        />{" "}
        <path
          d="M493.38 848.24L288 727.54a88.4 88.4 0 01-43.61-76.89l1.83-238.22A88.44 88.44 0 01291 336.21l207.22-117.52a88.41 88.41 0 0188.4.68L792 340.07A88.4 88.4 0 01835.62 417l-1.83 238.22A88.43 88.43 0 01789 731.4L581.78 848.92a88.41 88.41 0 01-88.4-.68z"
          fill="#519acc"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-5"
          d="M538.17 867.89a95.88 95.88 0 01-48.59-13.18L284.19 734a96.18 96.18 0 01-47.31-83.42l1.83-238.22a96.18 96.18 0 0148.59-82.68l207.22-117.51a96.15 96.15 0 0195.9.74L795.81 333.6a96.19 96.19 0 0147.31 83.4l-1.83 238.22a96.18 96.18 0 01-48.59 82.68L585.48 855.44a95.86 95.86 0 01-47.31 12.45zm-41-26.11a81.15 81.15 0 0080.9.62L785.3 724.87a81.13 81.13 0 0041-69.75l1.83-238.21a81.14 81.14 0 00-39.91-70.38l-205.4-120.69a81.1 81.1 0 00-80.9-.62L294.7 342.74a81.14 81.14 0 00-41 69.75l-1.83 238.22a81.13 81.13 0 0039.91 70.37z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-6"
          d="M641 371.28c-8.54-11.42-18.28-18.35-29-20.6a34.06 34.06 0 00-20.79 2c-5.57-15.59-17.73-27.43-34.26-30.56a1.82 1.82 0 000-.33 29.47 29.47 0 013.51-13.32c5.13-9.71 15-16 29.24-18.67a4.88 4.88 0 10-1.8-9.6c-21.61 4-31.55 15.13-36.08 23.71a39.9 39.9 0 00-4.63 17.4l-12.17.11c-.76 0-1.52 0-2.28.07a39.78 39.78 0 00-4.94-17.37c-4.69-8.49-14.83-19.39-36.52-23.05a4.89 4.89 0 10-1.62 9.64C523 296.36 523 321 523 322.14a5.06 5.06 0 00.06 1c-15.07 4.13-28.57 15.6-33.61 30.25a34.07 34.07 0 00-20.12-1.39c-10.64 2.45-20.26 9.56-28.58 21.13a4.88 4.88 0 007.93 5.7c7.65-10.62 16.57-16.79 25.8-17.81a23.32 23.32 0 0112.79 2.07 48.71 48.71 0 00-.33 5.94l.18 19.62a34.67 34.67 0 00-14.28 1.56c-11 3.53-20.78 12-28.94 25.15a4.88 4.88 0 001.58 6.72 4.83 4.83 0 002.62.74 4.92 4.92 0 004.11-2.31c7-11.37 15.52-18.73 24.52-21.28a24 24 0 0110.48-.7l.2 22.21a45.62 45.62 0 00.4 5.63 31.64 31.64 0 00-13.12 5.18c-10.18 6.91-16.8 19.21-19.65 36.54a4.9 4.9 0 004 5.62 5.93 5.93 0 00.84.06 4.88 4.88 0 004.77-4.09c2.36-14.33 7.68-24.69 15.4-30a21.47 21.47 0 0110-3.76 47.33 47.33 0 0044.95 31.5h1.22V378.8a4.69 4.69 0 014.7-4.7 4.7 4.7 0 014.7 4.7v88.52h2.13A47.33 47.33 0 00592.06 435a21 21 0 0110.82 3.61c7.81 5.14 13.32 15.41 15.94 29.69a4.88 4.88 0 109.61-1.76c-3.17-17.28-10-29.46-20.31-36.18a31.61 31.61 0 00-14-5 44.21 44.21 0 00.28-5.54l-.2-22.05a23.45 23.45 0 0111.25.35c9 2.38 17.66 9.58 24.91 20.82a4.89 4.89 0 108.21-5.3c-8.39-13-18.28-21.29-29.39-24.62a34.5 34.5 0 00-15.07-1.19L594 368c0-1.64-.12-3.27-.3-4.88a4.57 4.57 0 00.58-1 23.21 23.21 0 0112.79-2.32c9.25.85 18.28 6.85 26.12 17.34a4.89 4.89 0 007.83-5.85z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-5"
          d="M641 366.18c-8.54-11.41-18.28-18.35-29-20.6a34 34 0 00-20.79 2C585.68 332 573.52 320.18 557 317a1.82 1.82 0 000-.33 29.49 29.49 0 013.51-13.32c5.13-9.71 15-16 29.24-18.67a4.88 4.88 0 10-1.8-9.6c-21.61 4.05-31.55 15.13-36.08 23.71a39.9 39.9 0 00-4.63 17.4l-12.17.11c-.76 0-1.52 0-2.28.08a39.75 39.75 0 00-4.94-17.37c-4.69-8.5-14.83-19.4-36.52-23.05a4.88 4.88 0 10-1.62 9.63C523 291.26 523 315.93 523 317a5.06 5.06 0 00.06 1c-15.07 4.13-28.57 15.6-33.61 30.25a34.07 34.07 0 00-20.15-1.38c-10.64 2.45-20.26 9.56-28.58 21.13a4.89 4.89 0 004 7.74 4.92 4.92 0 003.93-2c7.65-10.63 16.57-16.79 25.8-17.82a23.21 23.21 0 0112.82 2.08 48.49 48.49 0 00-.33 5.94l.18 19.61a34.84 34.84 0 00-14.28 1.56c-11 3.54-20.78 12-28.94 25.15a4.89 4.89 0 008.31 5.15c7-11.37 15.52-18.72 24.52-21.27a24 24 0 0110.48-.7l.2 22.2a45.62 45.62 0 00.4 5.63 31.78 31.78 0 00-13.12 5.18c-10.18 6.91-16.8 19.21-19.65 36.54a4.89 4.89 0 004 5.62 4.76 4.76 0 00.84.07 4.9 4.9 0 004.77-4.1c2.36-14.32 7.68-24.69 15.4-30a21.43 21.43 0 0110-3.75A47.33 47.33 0 00535 462.32h1.22V373.7a4.69 4.69 0 014.7-4.7 4.7 4.7 0 014.7 4.7v88.53h2.13a47.35 47.35 0 0044.33-32.36 21 21 0 0110.82 3.61c7.81 5.15 13.32 15.41 15.94 29.69a4.88 4.88 0 109.61-1.76c-3.17-17.28-10-29.45-20.31-36.18a31.61 31.61 0 00-14-5 44.21 44.21 0 00.28-5.54l-.2-22a23.29 23.29 0 0111.25.34c9 2.39 17.66 9.59 24.91 20.83a4.9 4.9 0 004.15 2.23 4.89 4.89 0 004.06-7.54c-8.39-13-18.28-21.28-29.39-24.62a34.5 34.5 0 00-15.07-1.19l-.13-19.8c0-1.65-.12-3.28-.3-4.88a5 5 0 00.58-1 23.19 23.19 0 0112.79-2.33c9.25.86 18.28 6.86 26.12 17.34a4.89 4.89 0 007.83-5.85z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-6"
          d="M431.26 679c-.88 0-1.77-.5-1.64-1.52V661.9a2 2 0 012-1.89l32.6-5c1.14-.13 1.77.37 1.77 1.26v85a1.83 1.83 0 01-1.77 1.89h-22.34a1.86 1.86 0 01-1.9-1.89V679zM558.49 699.55c0 27.29-16.43 45.49-40.94 45.49s-41.06-18.2-41.06-45.49 16.42-45.48 41.06-45.48 40.94 18.19 40.94 45.48zm-26.79 0c0-13.64-5.68-22.74-14.15-22.74s-14.28 9.1-14.28 22.74 5.73 22.75 14.28 22.75 14.15-9.1 14.15-22.75zM646 699.55c0 27.29-16.39 45.45-40.9 45.45S564 726.84 564 699.55s16.43-45.48 41.06-45.48S646 672.26 646 699.55zm-26.79 0c0-13.64-5.68-22.74-14.15-22.74s-14.27 9.1-14.27 22.74 5.68 22.75 14.27 22.75 14.19-9.1 14.19-22.75z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-5"
          d="M431.26 671.31c-.88 0-1.77-.5-1.64-1.51v-15.54a2 2 0 012-1.9l32.6-5.05c1.14-.13 1.77.38 1.77 1.26v85a1.84 1.84 0 01-1.77 1.9h-22.34a1.87 1.87 0 01-1.9-1.9v-62.26zM558.49 691.91c0 27.29-16.43 45.48-40.94 45.48s-41.06-18.19-41.06-45.48 16.42-45.49 41.06-45.49 40.94 18.2 40.94 45.49zm-26.79 0c0-13.65-5.68-22.74-14.15-22.74s-14.28 9.09-14.28 22.74 5.69 22.74 14.28 22.74 14.15-9.1 14.15-22.74zM646 691.91c0 27.29-16.43 45.48-40.94 45.48S564 719.2 564 691.91s16.43-45.49 41.06-45.49S646 664.62 646 691.91zm-26.79 0c0-13.65-5.68-22.74-14.15-22.74s-14.27 9.09-14.27 22.74 5.68 22.74 14.27 22.74 14.19-9.1 14.19-22.74z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-2"
          d="M902.5 658.47a2302.84 2302.84 0 00-720.56 1.14q-1-66.21-.87-132.5a2433.87 2433.87 0 01721.6-.08q.47 65.76-.17 131.44z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-3"
          d="M902.5 652.72a2303.14 2303.14 0 00-720.56 1.15q-1-66.21-.87-132.5a2433.54 2433.54 0 01721.6-.08q.47 65.76-.17 131.43z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-5"
          d="M413.19 361.68l-44.13-31.36M413.19 365.18a3.5 3.5 0 01-2-.65L367 333.18a3.5 3.5 0 014.06-5.71l44.13 31.35a3.51 3.51 0 01-2 6.36zM391.98 401.45l-92.2 1.36M299.78 406.31a3.5 3.5 0 010-7l92.2-1.36a3.5 3.5 0 11.1 7l-92.2 1.36zM414.7 446.2l-47.19 19.99M367.51 469.69a3.5 3.5 0 01-1.36-6.72l47.19-20a3.5 3.5 0 012.73 6.45l-47.19 20a3.5 3.5 0 01-1.37.27zM668.67 361.68l44.13-31.36M668.68 365.18a3.5 3.5 0 01-2-6.36l44.13-31.35a3.5 3.5 0 114.05 5.71l-44.16 31.35a3.44 3.44 0 01-2.02.65zM689.89 401.45l92.19 1.36M782.09 406.31H782L689.83 405a3.5 3.5 0 01.06-7h.05l92.2 1.36a3.5 3.5 0 010 7zM667.16 446.2l47.19 19.99M714.35 469.69a3.42 3.42 0 01-1.36-.28l-47.19-20a3.5 3.5 0 012.73-6.45l47.19 20a3.5 3.5 0 01-1.37 6.72z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-7"
          d="M410.43 525.07c17-1.2 28.75 7.8 29.51 22.16.48 8.85-3.59 15.6-10.28 18 8.09 1.47 13.65 8.57 14.2 18.77.75 14.38-9.45 24.49-25.41 25.61q-19.5 1.36-39 3.19a1.76 1.76 0 01-1.88-1.55l-7.65-80.94a1.92 1.92 0 011.63-2q19.45-1.84 38.88-3.24zM407 558.75c3.8-.28 6.18-2.67 5.93-6.11s-3-5.46-6.78-5.17c-2.68.2-5.35.4-8 .62q.45 5.64.89 11.28c2.61-.22 5.28-.37 7.96-.62zm4.42 29.25c3.61-.26 5.95-2.77 5.7-6.33s-2.92-5.71-6.56-5.44q-5.08.36-10.16.77l.93 11.77zM526.07 520.58a2.14 2.14 0 012.22 2.08q.12 25.65.26 51.29c.05 21.8-11.59 33.19-33.82 33.73-22 .57-34.19-10.23-35.48-32l-2.31-51.24a2.21 2.21 0 012.1-2.19q11.13-.49 22.25-.84a2.14 2.14 0 012.27 2q.81 26.74 1.61 53.48c.18 5.66 3.52 8.87 9 8.74s8.67-3.52 8.56-9L501.65 523a2.23 2.23 0 012.16-2.14q11.13-.21 22.26-.28zM621.17 555.41a1.91 1.91 0 011.82 1.81l-1.92 39.07a4.07 4.07 0 01-1.63 2.87c-7.74 5.91-19.19 9.11-31 8.76-26.48-.79-44.15-18.45-44.15-45s18.71-44.36 46.82-43.52a53.88 53.88 0 0126.89 8.15 1.88 1.88 0 011.06 2.27q-3.63 8.88-7.21 17.78a2 2 0 01-2.46.88 40.55 40.55 0 00-18.22-5c-11.78-.36-19.86 7.62-20.08 19.67-.22 12.31 7.37 20.55 18.83 20.9 4.62.14 7.77-.61 9.2-2q.12-3.57.26-7.13-5.77-.21-11.55-.38c-1.13 0-1.87-.55-2-1.41l-1.17-17a1.81 1.81 0 012.08-2q17.26.45 34.43 1.28zm-21.25 24.83v.49c-.02-.16-.01-.33 0-.49zM646.28 583.05c.46-1.21 1.36-1.52 2.32-.84 5.54 3.56 12.23 6.38 16.93 7.1 4 .3 6.74-1.33 7-4.15.59-7.34-34.61-9.39-33.14-36 .91-16.45 14.62-26.68 34.32-25.18 9.19.7 20.53 5.25 28.13 11.28 1 .71 1.26 1.73.82 2.3q-4.86 8.26-9.63 16.54c-.49 1.19-1.41 1.48-2.38.78-5.92-4-13-7.15-17.42-7.86-4-.31-6.64 1.22-6.84 3.92-.51 7.13 35.47 9.66 32.83 36.7-1.59 16.3-15.26 25.94-34.13 24.5-10.32-.78-20.92-4.67-26.81-9.94-1-.8-1.27-1.68-.86-2.27q4.38-8.48 8.86-16.88z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-5"
          d="M410.43 520c17-1.21 28.75 7.8 29.51 22.16.48 8.84-3.59 15.59-10.28 18 8.09 1.48 13.65 8.58 14.2 18.77.75 14.38-9.45 24.49-25.41 25.61q-19.5 1.38-39 3.2a1.77 1.77 0 01-1.88-1.56l-7.65-80.94a1.92 1.92 0 011.63-2q19.45-1.87 38.88-3.24zM407 553.65c3.8-.28 6.18-2.67 5.93-6.1s-3-5.47-6.78-5.18l-8 .62q.45 5.64.89 11.28zm4.44 29.27c3.61-.26 5.95-2.76 5.7-6.32s-2.92-5.72-6.56-5.45l-10.16.77.93 11.78c3.34-.27 6.71-.53 10.07-.78zM526.07 515.49a2.13 2.13 0 012.22 2.08q.12 25.63.26 51.29c.05 21.79-11.59 33.18-33.82 33.72-22 .57-34.19-10.22-35.48-32l-2.31-51.24a2.21 2.21 0 012.1-2.19q11.13-.48 22.25-.84a2.14 2.14 0 012.27 2q.81 26.73 1.61 53.48c.18 5.65 3.52 8.87 9 8.73s8.67-3.52 8.56-9.05l-1.08-53.62a2.22 2.22 0 012.16-2.13q11.13-.16 22.26-.23zM621.17 550.31a1.91 1.91 0 011.82 1.81l-1.92 39.07a4 4 0 01-1.63 2.87c-7.74 5.91-19.19 9.11-31 8.76-26.48-.79-44.15-18.45-44.15-45s18.71-44.36 46.82-43.52a53.79 53.79 0 0126.89 8.16 1.88 1.88 0 011.06 2.26q-3.63 8.88-7.21 17.78a2 2 0 01-2.46.88 40.55 40.55 0 00-18.22-5c-11.78-.36-19.86 7.63-20.08 19.67-.22 12.31 7.37 20.55 18.83 20.9 4.62.14 7.77-.61 9.2-2 .08-2.38.17-4.75.26-7.13-3.85-.14-7.7-.26-11.55-.38-1.13 0-1.87-.54-2-1.41l-1.17-17a1.81 1.81 0 012.08-2q17.26.45 34.43 1.28zm-21.25 24.83v.49c-.02-.16-.01-.32 0-.49zM646.28 578c.46-1.2 1.36-1.51 2.32-.84 5.54 3.56 12.23 6.38 16.93 7.1 4 .3 6.74-1.33 7-4.15.59-7.34-34.61-9.38-33.14-36 .91-16.45 14.62-26.68 34.32-25.17 9.18.69 20.53 5.25 28.13 11.27 1 .71 1.26 1.73.82 2.31q-4.89 8.15-9.66 16.48c-.49 1.19-1.41 1.48-2.38.78-5.92-4-13-7.15-17.42-7.86-4-.31-6.64 1.23-6.84 3.92-.51 7.13 35.47 9.67 32.83 36.71-1.59 16.29-15.26 25.93-34.13 24.5-10.32-.79-20.92-4.67-26.81-10-1-.8-1.27-1.68-.86-2.27q4.41-8.43 8.89-16.78zM399.25 686.53l4.35 8.81 9.72 1.41-7.03 6.86 1.66 9.68-8.7-4.57-8.69 4.57 1.66-9.68-7.03-6.86 9.72-1.41 4.34-8.81zM683.38 686.53l4.35 8.81 9.72 1.41-7.03 6.86 1.66 9.68-8.7-4.57-8.69 4.57 1.66-9.68-7.03-6.86 9.72-1.41 4.34-8.81z"
        />{" "}
        <circle
          className="Skills__Bugs_-_Bronze_svg__cls-3"
          cx={536.66}
          cy={793.48}
          r={39.42}
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-5"
          d="M536.66 834.4a40.92 40.92 0 1140.92-40.92 41 41 0 01-40.92 40.92zm0-78.84a37.92 37.92 0 1037.92 37.92 38 38 0 00-37.92-37.92z"
        />{" "}
        <path
          className="Skills__Bugs_-_Bronze_svg__cls-5"
          d="M556.61 774.05a9.28 9.28 0 00-5.15.52v-2.71h-29.59v2.71a9.28 9.28 0 00-5.15-.52 5.68 5.68 0 00-4.48 4.53c-.72 3.15.86 6.75 4.42 10.15a29.52 29.52 0 0010.55 6.27 15.12 15.12 0 006.44 5.34c-.4 1.43-1.77 5.56-5.08 8.09h16.18c-3.31-2.53-4.68-6.66-5.07-8.09a15.09 15.09 0 006.43-5.34 29.52 29.52 0 0010.56-6.29c3.57-3.4 5.14-7 4.42-10.15a5.68 5.68 0 00-4.48-4.51zM519 786.25c-2.57-2.46-3.86-5-3.44-6.91a2.31 2.31 0 011.87-1.94 6.67 6.67 0 014.6 1.06 41.31 41.31 0 002.57 11.71 24.45 24.45 0 01-5.6-3.92zm35.28 0a24.61 24.61 0 01-5.59 3.92 41.64 41.64 0 002.56-11.71 6.67 6.67 0 014.6-1.06 2.31 2.31 0 011.87 1.94c.44 1.87-.84 4.45-3.42 6.91zM526.27 810.61h20.77v4.49h-20.77z"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default BugsBronze;
