import React, { useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { TrixEditor } from "../common/trix_container";
import BasicSelect from "../common/basic_select";
import EditIcon from "../icons/edit_icon";
import MoveIcon from "../icons/move_icon";

const containerWidths = {
  "100%": { label: "Full", value: "100%" },
  "50%": { label: "Half", value: "50%" },
  "33%": { label: "Third", value: "33%" },
  "25%": { label: "Quarter", value: "25%" },
};
const imageWidths = {
  "100%": { label: "Fit Container", value: "100%" },
};
const imageAlignments = {
  "0 auto": { label: "Center", value: "0 auto" },
  "0 auto 0 0": { label: "Left", value: "0 auto 0 0" },
  "0 0 0 auto": { label: "Center", value: "0 0 0 auto" },
};

const HelpSection = (props) => {
  const {
    dragging,
    editingSection,
    editMode,
    handleCreateSection,
    handleDeleteSection,
    handleEditSection,
    handleSetLoading,
    handleUpdateSection,
    loading,
    section,
    sectionsLength,
  } = props;

  const [content, setContent] = useState(section.content);
  const [contentValue, setContentValue] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const [containerWidth, setContainerWidth] = useState(
    section.style.width
      ? containerWidths[section.style.width]
        ? containerWidths[section.style.width]
        : { label: "Custom", value: "custom" }
      : { label: "Full", value: "100%" }
  );

  const [imageWidth, setImageWidth] = useState(
    section.style.width
      ? imageWidths[section.image_style.width]
        ? imageWidths[section.image_style.width]
        : { label: "Custom", value: "custom" }
      : { label: "Fit Container", value: "100%" }
  );

  const [imageAlignment, setImageAlignment] = useState(
    section.image_style.margin
      ? imageAlignments[section.image_style.margin]
        ? imageAlignments[section.image_style.margin]
        : { label: "Center", value: "0 auto" }
      : { label: "Center", value: "0 auto" }
  );

  const customContainerWidthRef = useRef();
  const customImageWidthRef = useRef();

  function handleEditorReady(editor) {
    editor.insertHTML(content);
  }

  function handleChange(html) {
    setContentValue(html);
  }

  function handleSave() {
    if (!loading) {
      const formData = new FormData();
      handleSetLoading(true);
      const formContainerWidth =
        containerWidth.value === "custom"
          ? customContainerWidthRef.current.value
          : containerWidth.value;
      const formImageWidth =
        section.section_type === "image" && imageWidth.value === "custom"
          ? customImageWidthRef.current.value
          : imageWidth.value;
      formData.append("help_section[content]", contentValue);
      formData.append("help_section[style][width]", formContainerWidth);
      if (imageFile) {
        formData.append("help_section[image]", imageFile);
        formData.append("help_section[section_type]", "image");
        formData.append("help_section[image_content_type]", imageFile.type);
        formData.append("help_section[image_file_name]", imageFile.name);
        formData.append("help_section[image_file_size]", imageFile.size);
      }
      if (section.section_type === "image") {
        formData.append("help_section[image_style][width]", formImageWidth);
        formData.append(
          "help_section[image_style][margin]",
          imageAlignment.value
        );
      }
      if (section.id === "new") {
        formData.append("help_section[position]", section.position);
        formData.append("help_section[help_topic_id]", section.help_topic_id);
        formData.append("help_section[section_type]", section.section_type);
        axios
          .post("/help_sections/", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "Section created successfully",
              classes: "green darken-1 success-toast",
            });
            handleCreateSection(res.data);
          })
          .catch((err) => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "An unexpected error occured, please try again.",
              classes: "red darken-1 error-toast",
            });
            console.error(err);
          })
          .finally(() => {
            handleSetLoading(false);
          });
      } else {
        axios
          .patch(`/help_sections/${section.id}`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "Section updated successfully",
              classes: "green darken-1 success-toast",
            });
            setContentValue("");
            setContent(contentValue);
            handleUpdateSection(res.data);
          })
          .catch((err) => {
            // eslint-disable-next-line no-undef
            M.toast({
              html: "An unexpected error occured, please try again.",
              classes: "red darken-1 error-toast",
            });
            console.error(err);
          })
          .finally(() => {
            handleSetLoading(false);
          });
      }
    }
  }

  function handleCancel() {
    setContentValue("");
    handleEditSection(false);
  }

  function handleEdit() {
    handleEditSection(section.id);
  }

  function handleDelete() {
    if (!loading) {
      Swal.fire({
        title: "Delete Section",
        text: "Are you sure that you want to delete this section?",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        confirmButtonText: "Yes",
      }).then((result) => {
        handleSetLoading(true);
        if (result.value) {
          axios
            .delete(`/help_sections/${section.id}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              // eslint-disable-next-line no-undef
              M.toast({
                html: "Section deleted successfully",
                classes: "green darken-1 success-toast",
              });
              handleDeleteSection(res.data.id);
            })
            .catch((err) => {
              // eslint-disable-next-line no-undef
              M.toast({
                html: "An unexpected error occured, please try again.",
                classes: "red darken-1 error-toast",
              });
              console.error(err);
            })
            .finally(() => {
              handleSetLoading(false);
            });
        }
      });
    }
  }

  function handleAddImage(e) {
    e.preventDefault();

    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const fileExtension = e.dataTransfer
      ? e.dataTransfer.files[0].name.split(".").pop().toLowerCase()
      : e.target.value.split(".").pop().toLowerCase();
    const allowedExtensions = ["jpg", "jpeg", "png", "mp4"];
    if (!allowedExtensions.includes(fileExtension)) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "Invalid File Type",
        classes: "red darken-1 error-toast",
      });
      e.target.value = "";
      return null;
    }
    // file size limit: 100MB
    if (file.size > 100 * 1024 * 1024) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "File exceeds size limit",
        classes: "red darken-1 error-toast",
      });
      e.target.value = "";
      return null;
    }
    setImageFile(file);
    return null;
  }

  function handleContainerWidthChange(e) {
    setContainerWidth(e);
  }

  function handleImageWidthChange(e) {
    setImageWidth(e);
  }

  function handleImageAlignmentChange(e) {
    setImageAlignment(e);
  }

  function handleDragStart() {
    const { id, position } = section;
    handleDragStart(id, position);
  }

  function handleDragEnter(e) {
    const { position } = e.target.dataset;
    if (position) {
      if (parseInt(position) > dragging.position) {
        if (e.target.parentElement.style.width === "100%") {
          e.target.classList.add("bottom-drag");
        } else {
          e.target.classList.add("right-drag");
        }
      } else if (parseInt(position) < dragging.position) {
        if (e.target.parentElement.style.width === "100%") {
          e.target.classList.add("top-drag");
        } else {
          e.target.classList.add("left-drag");
        }
      }
    }
  }

  function handleDragLeave(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    e.target.classList.remove("right-drag");
    e.target.classList.remove("left-drag");
  }

  function handleDragDrop(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    e.target.classList.remove("right-drag");
    e.target.classList.remove("left-drag");
    const { position } = e.target.dataset;
    if (position) {
      const { id } = dragging;
      props.handleDragDrop(id, parseInt(position));
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  const { id, section_type, position } = props.section;
  const imageUrl = imageFile
    ? URL.createObjectURL(imageFile)
    : section.image_url
    ? section.image_url
    : "";

  return (
    <div
      style={{
        display: "inline-block",
        width: "100%",
        position: "relative",
        verticalAlign: "top",
        ...(editingSection !== id ? section.style : {}),
      }}
    >
      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDragDrop}
        className={`trial-section-drag-overlay ${
          section.style.width && section.style.width !== "100%"
            ? "side-drag"
            : ""
        }`}
        data-position={position}
        style={{ zIndex: dragging && dragging.id !== id ? "100" : "-1" }}
      />
      {editingSection !== id ? (
        section_type === "text" ? (
          <div className={`trial-section ${editMode ? "edit-mode" : ""}`}>
            {editMode && (
              <div
                style={{
                  backgroundColor: "#519acc",
                  padding: "5px 10px 0",
                  top: "4px",
                  zIndex: "10",
                  borderRadius: "3px",
                }}
                className="trial-section-buttons-container"
              >
                <span onClick={handleEdit}>
                  <EditIcon
                    color="white"
                    svgStyles={{ pointerEvents: "none" }}
                  />
                </span>
                {sectionsLength > 1 && (
                  <span
                    onDragStart={handleDragStart}
                    className="move-icon"
                    onDragEnd={props.handleDragEnd}
                    draggable
                  >
                    <MoveIcon
                      color="white"
                      svgStyles={{ pointerEvents: "none", marginLeft: "8px" }}
                    />
                  </span>
                )}
                <span onClick={handleDelete} style={{ margin: "0 0 0 8px" }}>
                  <img
                    src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/Icon_TP_Trash_white.svg"
                    style={{
                      pointerEvents: "none",
                      width: "13px",
                      transform: "translateY(0px)",
                    }}
                  />
                </span>
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: props.section.content }} />
          </div>
        ) : (
          <div
            style={{ margin: "10px 0" }}
            className={`trial-section ${editMode ? "edit-mode" : ""}`}
          >
            {editMode && (
              <div
                style={{
                  backgroundColor: "#519acc",
                  padding: "5px 10px 0",
                  top: "4px",
                  zIndex: "10",
                  borderRadius: "3px",
                }}
                className="trial-section-buttons-container"
              >
                <span onClick={handleEdit}>
                  <EditIcon
                    color="white"
                    svgStyles={{ pointerEvents: "none" }}
                  />
                </span>
                {sectionsLength > 1 && (
                  <span
                    onDragStart={handleDragStart}
                    className="move-icon"
                    onDragEnd={props.handleDragEnd}
                    draggable
                  >
                    <MoveIcon
                      color="white"
                      svgStyles={{ pointerEvents: "none", marginLeft: "8px" }}
                    />
                  </span>
                )}
                <span onClick={handleDelete} style={{ margin: "0 0 0 8px" }}>
                  <img
                    src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/Icon_TP_Trash_white.svg"
                    style={{
                      pointerEvents: "none",
                      width: "13px",
                      transform: "translateY(0px)",
                    }}
                  />
                </span>
              </div>
            )}
            {imageUrl.includes(".mp4") ? (
              <video
                autoPlay
                loop
                style={{
                  display: "block",
                  boxShadow: "0px 0px 3px 2px #cfcfcf",
                  ...props.section.image_style,
                }}
                src={imageUrl}
              />
            ) : (
              <img
                style={{
                  display: "block",
                  boxShadow: "0px 0px 3px 2px #cfcfcf",
                  ...props.section.image_style,
                }}
                src={imageUrl}
              />
            )}
          </div>
        )
      ) : section_type === "text" ? (
        <div style={{ margin: "20px 0", position: "relative" }}>
          <div
            style={{
              display: "flex",
              position: "absolute",
              left: "436px",
              zIndex: "100",
              top: "-2px",
            }}
          >
            <span>Width:</span>
            <BasicSelect
              options={[
                { label: "Full", value: "100%" },
                { label: "Half", value: "50%" },
                { label: "Third", value: "33%" },
                { label: "Quarter", value: "25%" },
                { label: "Custom", value: "custom" },
              ]}
              top="-4px"
              onChange={handleContainerWidthChange}
              selectedOption={containerWidth}
              width="100px"
              id="1"
            />
            {containerWidth.value === "custom" && (
              <input
                ref={customContainerWidthRef}
                maxLength={25}
                defaultValue={props.section.style.width || ""}
                className="trial-section-style-input"
              />
            )}
          </div>
          <TrixEditor
            value={contentValue}
            onChange={handleChange}
            className="trix-content"
            onEditorReady={handleEditorReady}
          />
          <div style={{ margin: "10px 0 0" }}>
            <button onClick={handleSave} className="common-button-submit">
              Save
            </button>
            <button onClick={handleCancel} className="common-button-cancel">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div
          className="trial-section-image-edit-container"
          style={{
            margin: "20px 0",
            padding: "20px",
            backgroundColor: "#efefef",
            position: "relative",
          }}
        >
          <div className="trial-section-edit-options">
            <span>Container</span>
            <div>
              <span>Width:</span>
              <BasicSelect
                options={[
                  { label: "Full", value: "100%" },
                  { label: "Half", value: "50%" },
                  { label: "Third", value: "33%" },
                  { label: "Quarter", value: "25%" },
                  { label: "Custom", value: "custom" },
                ]}
                onChange={handleContainerWidthChange}
                selectedOption={containerWidth}
                width="100px"
                id="1"
              />
              {containerWidth.value === "custom" && (
                <input
                  ref={customContainerWidthRef}
                  maxLength={25}
                  defaultValue={props.section.style.width || ""}
                  className="trial-section-style-input"
                />
              )}
            </div>
            <span style={{ marginLeft: "20px" }}>Image</span>
            <div>
              <span>Width:</span>
              <BasicSelect
                options={[
                  { label: "Fit Container", value: "100%" },
                  { label: "Custom", value: "custom" },
                ]}
                onChange={handleImageWidthChange}
                selectedOption={imageWidth}
                width="140px"
                id="2"
              />
              {imageWidth.value === "custom" && (
                <input
                  ref={customImageWidthRef}
                  maxLength={25}
                  defaultValue={props.section.image_style.width || ""}
                  className="trial-section-style-input"
                />
              )}
              <span>Alignment:</span>
              <BasicSelect
                options={[
                  { label: "Center", value: "0 auto" },
                  { label: "Left", value: "0 auto 0 0" },
                  { label: "Right", value: "0 0 0 auto" },
                ]}
                onChange={handleImageAlignmentChange}
                selectedOption={imageAlignment}
                width="100px"
                id="3"
              />
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            {imageUrl ? (
              <div>
                <img
                  style={{
                    maxWidth: "275px",
                    display: "block",
                    margin: "20px auto",
                  }}
                  src={imageUrl}
                />
                <label
                  htmlFor="image-file"
                  style={{
                    width: "100px",
                    display: "block",
                    height: "24px",
                    margin: "auto",
                    textAlign: "center",
                    color: "#519acc",
                    fontWeight: "600",
                    lineHeight: "20px",
                    cursor: "pointer",
                  }}
                >
                  Change Image
                  <input
                    className="color"
                    style={{
                      height: "0",
                      zIndex: "1",
                      width: "100%",
                      cursor: "pointer",
                    }}
                    id="image-file"
                    type="file"
                    accept=".jpg,.gif,.png,.mp4"
                    onChange={handleAddImage}
                  />
                </label>
              </div>
            ) : (
              <div style={{ pointerEvents: "all" }} id="filepond-container">
                <div
                  style={{
                    position: "relative",
                    margin: "0 auto",
                    width: "139px",
                  }}
                >
                  <div style={{ height: "0" }}>
                    <input
                      className="color"
                      style={{
                        height: "0",
                        zIndex: "1",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      id="image-file"
                      type="file"
                      accept=".jpg,.gif,.png,.mp4"
                      onChange={handleAddImage}
                    />
                  </div>
                  <label
                    htmlFor="image-file"
                    id="file-wrapper"
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={handleAddImage}
                    style={{
                      position: "relative",
                      backgroundColor: "#f5f5f5",
                      height: "134px",
                      width: "143px",
                      display: "block",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        transition: "color .3s",
                        position: "absolute",
                        top: "50px",
                        left: "16px",
                        lineHeight: "16px",
                        pointerEvents: "none",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      Drop or select image <br /> to upload
                    </span>
                    <input
                      id="validator"
                      style={{
                        pointerEvents: "none",
                        zIndex: "0",
                        transition: "background-color .3s",
                        width: "100%",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        border: "2px solid #e0e0e0",
                        height: "131px",
                        borderRadius: "5px",
                        backgroundColor: "#f5f5f5",
                        marginBottom: 0,
                        textAlign: "center",
                      }}
                      className="file-path validate attachment-drop-area"
                      placeholder=""
                      type="text"
                    />
                  </label>
                </div>
              </div>
            )}
            <button
              disabled={section_type === "image" && !(imageUrl || imageFile)}
              onClick={handleSave}
              className="common-button-submit"
            >
              Save
            </button>
            <button onClick={handleCancel} className="common-button-cancel">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpSection;
