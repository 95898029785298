import React from "react";
import TimedInput from "../common/timed_input.jsx";
import axios from "axios";
import Swal from "sweetalert2";

class AppVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { input: this.props.appVersion.version, active: false };
  }

  activeHandler = () => {
    this.setState({ active: !this.state.active });
  };

  inputHandler = (e) => {
    this.sendRequest(e);
  };

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  sendRequest = (input) => {
    axios
      .patch("/admin_panel/app_version.json", {
        app_version: { version: input },
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Application Version Updated",
          displayLength: 3000,
          classes: "green",
        });
        this.setState({ input: res.data.version.version, active: false });
      })
      .catch((err) => {
        Swal.fire("There was an error editing the Application Version", err);
        console.log(err);
      });
  };

  render() {
    return (
      <div className="large-title" id="appVersionInput">
        {this.state.active ? (
          <TimedInput
            version={true}
            onChange={this.inputHandler}
            value={this.state.input}
          />
        ) : (
          <span>App Version: {this.state.input}</span>
        )}
        <a onClick={this.activeHandler} className="left pointer">
          <i className="tiny material-icons">edit</i>
        </a>
      </div>
    );
  }
}

export default AppVersion;
