import React from 'react'
import MediaRecents from '../media/media_recents'

function MediaRow(props) {

  return (
    <div style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "center",
      maxHeight: "315px",
      margin: "5px 0",
      height: "100%",
      flex: 1
    }}  
    className="dash-row-2"
    >
      <div style={{display: 'flex', flex: '1', height: "325px"}}>
        <MediaRecents projectId={props.project.id} />
      </div>
    </div>
    );
}

export default MediaRow