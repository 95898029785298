import React from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";
import ReactModalV2 from "@components/modals/react_modal_v2";
import NewTestPass from "@components/modals/new_test_pass";
import NewBadge from "@components/modals/new_badge";
import TestStepComments from "@components/modals/test_step_comments";
import TestStepBugs from "@components/modals/test_step_bugs";
import TestStepA11y from "@components/modals/test_step_a11y";
import KeyboardShortcuts from "@components/modals/keyboard_shortcuts";
import AddTestDevice from "@components/modals/add_test_device";
import EditTestDevice from "@components/modals/edit_test_device";
import TestPassForm from "./test_pass_form";
import TestPassMenu from "./test_pass_menu";
import TestPassLanding from "./test_pass_landing";

const modals = {
  "new-test-pass": NewTestPass,
  "new-badge": NewBadge,
  "test-step-comments": TestStepComments,
  "test-step-bugs": TestStepBugs,
  "test-step-a11y": TestStepA11y,
  "keyboard-shortcuts": KeyboardShortcuts,
  "add-test-device": AddTestDevice,
  "edit-test-device": EditTestDevice,
};

// Error Tracking service for React Front End
// Needs to be placed at the parent level component. The components that get served to Rails through the view

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: "https://662459c60a3a405daa0c3aa17f38ee25@sentry.io/2023356",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        if (event.exception.values.some((x) => x.handled === false)) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            title: "It looks like we dozed off and there was an error.",
            subtitle: "The Dev team has been notified.",
          });
        }
      }
      return event;
    },
  });
} else {
  Sentry.init({
    dsn: "https://3c2ed8a8f50148e582c6050933c50c31@sentry.io/5372657",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: "It looks like we dozed off and there was an error.",
          subtitle: "The Dev team has been notified.",
        });
      }
      return event;
    },
  });
}

class TestPassContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      activeSuite: this.props.activeSuite,
      activePass: this.props.activePass,
      testSuites: this.props.testSuites,
      action: this.props.action,
      loading: false,
      editPassTitle: false,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      projectUsers: this.props.projectUsers,
    };
    this.testPassMenu = React.createRef();
    this.adminBool =
      this.props.currentUser.role === "admin" ||
      this.props.currentUser.role === "lead";
  }

  componentDidMount() {
    Sentry.setUser({
      username: this.props.currentUser.name,
      id: this.props.currentUser.id,
    });
    Sentry.setTag("page", "test_passes");
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    // Add event listener for popstate event
    window.addEventListener("popstate", (e) => this.handlePopState(e));
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.updateWidth);
    // Remove event listener when component is unmounted
    window.removeEventListener("popstate", this.handlePopState);
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  handleTestPassDelete = (e) => {
    const { id } = e.target.dataset;
    const self = this;
    Swal.fire({
      title: "Delete Test Pass",
      text: "Are you sure that you want to delete this Test Pass?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
      width: "300px",
      // ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(
            `/test_passes/${
              self.state.activePass ? self.state.activePass.id : id
            }.json`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            window.history.pushState(
              "page2",
              "Test Platform",
              `/projects/${self.state.project.id}/test_passes`
            );
            self.getData(self.state.project.id);
          })
          .catch((err) => {
            err.message = `Delete Pass Backend Error: ${err.message}`;
            console.error(err);
            Swal.fire({
              title: "Test Pass delete error",
              text: "There was a problem deleting this Test Pass",
              customClass: "swal2-error-modal",
            });
          });
      }
    });
  };

  retireSuite = (id) => {
    this.setState({
      testSuites: this.state.testSuites.filter((t) => t.id !== id),
    });
  };

  getData = (projectId) => {
    this.setState({ loading: true });
    $.ajax({
      url: `/projects/${projectId}/test_passes`,
      dataType: "json",
      beforeSend(xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      cache: false,
      success: function (data) {
        this.setState(
          {
            project: data.project,
            projectUsers: data.projectUsers,
            activePass: null,
            action: "new",
            testSuites: data.testSuites,
            loading: false,
          },
          () => {
            if (this.testPassMenu.current) {
              const updateMenuPromise = new Promise((resolve, reject) => {
                this.testPassMenu.current.getPassMenuData(false, resolve);
              });
              updateMenuPromise.then((message) => {
                this.setState({ loading: false });
              });
            } else {
              this.setState({ loading: false });
            }
          }
        );
      }.bind(this),
      error(status, err) {
        console.error(`/projects/${projectId}/test_passes`, status, err);
      },
    });
  };

  handlePopState = (e) => {
    const projectId = e.target.location.pathname.split("/")[2];

    this.setState({ loading: true });

    window.history.pushState(
      "page2",
      "Test Platform",
      `/projects/${projectId}/test_passes`
    );

    $.ajax({
      url: `/projects/${projectId}/test_passes`,
      dataType: "json",
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      cache: false,
      success: (data) => {
        this.setState(
          {
            project: data.project,
            projectUsers: data.projectUsers,
            activePass: null,
            action: "new",
            testSuites: data.testSuites,
            loading: false,
          },
          () => {
            if (this.testPassMenu.current) {
              const updateMenuPromise = new Promise((resolve, reject) => {
                this.testPassMenu.current.getPassMenuData(false, resolve);
              });
              updateMenuPromise.then((message) => {
                this.setState({ loading: false });
              });
            } else {
              this.setState({ loading: false });
            }
          }
        );
      },
      error: (status, err) => {
        console.error(`/projects/${projectId}/test_passes`, status, err);
      },
    });
  };

  testPassHandler = (pass) => {
    try {
      this.setState({ activePass: pass });
    } catch (err) {
      err.message = `Pass Handler Error Frontend${err.message}`;
      Sentry.captureException(err);
      console.error(err);
    }
  };

  setTestPass = (passId) => {
    this.setState({ loading: true });
    const projectId = this.state.project.id;
    $.ajax({
      url: `/test_passes/${passId}`,
      dataType: "json",
      beforeSend(xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      cache: false,
      success: function (data) {
        const recentSuite = localStorage.getItem(
          `project_${projectId}_recent_suite`
        );
        if (recentSuite !== data.testPass.test_suite.id) {
          localStorage.setItem(
            `project_${projectId}_recent_suite`,
            data.testPass.test_suite.id
          );
        }

        window.history.pushState(
          "page2",
          "Test Platform",
          `/projects/${projectId}/test_passes/${data.testPass.id}`
        );
        this.setState(
          {
            activePass: data.testPass,
            activeSuite: data.testPass.test_suite,
            action: "edit",
            loading: false,
          },
          () => {
            const event = new Event("resize");
            setTimeout(() => {
              window.dispatchEvent(event);
              const elem = document.getElementById("pass-back-button");
              if (elem) {
                elem.focus();
              }
            }, 200);
          }
        );
      }.bind(this),
      error(status, err) {
        console.error(`/test_suites/${passId}`, status, err);
      },
    });
  };

  clearPass = () => {
    this.setState({ loading: true });
    axios
      .get(`/project_landing_suites/${this.state.project.id}`)
      .then((res) => {
        this.setState({
          activePass: null,
          testSuites: res.data.testSuites,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ activePass: null, loading: false });
        console.error(err);
      });
  };

  handleEditTestPassTitle = (e) => {
    const { paddingBottom } =
      document.querySelector(".pass-page-heading").style;
    if (e.target.id === "pass-title-edit") {
      var { top } = document.getElementById("test-pass-menu-hover").style;
      this.setState(
        {
          editPassTitle: true,
        },
        () => {
          document.querySelector("#pass-title-input").focus();
          const { paddingBottom } =
            document.querySelector(".pass-page-heading").style;
          document.getElementById("pass-title-input-container").style.top = top;
          document.getElementById(
            "pass-title-input-container"
          ).style.marginBottom =
            paddingBottom === "50px"
              ? "-105px"
              : paddingBottom === "70px"
              ? "-125px"
              : paddingBottom === "88px"
              ? "-143px"
              : "-163px";
        }
      );
    } else if (e.target.id === "pass-title-cancel") {
      var { top } = document.getElementById("pass-title-input-container").style;
      this.setState(
        {
          editPassTitle: false,
        },
        () => {
          document.getElementById("test-pass-menu-hover").style.top = top;
          document.getElementById("test-pass-menu-hover").style.marginBottom =
            paddingBottom === "50px"
              ? "-115px"
              : paddingBottom === "70px"
              ? "-151px"
              : paddingBottom === "88px"
              ? "-169px"
              : "-189px";
        }
      );
    } else if (
      e.target.id === "pass-title-save" ||
      (e.target.id === "pass-title-input" && e.keyCode === 13)
    ) {
      var { top } = document.getElementById("pass-title-input-container").style;
      const input = document.querySelector("#pass-title-input");
      input.disabled = true;
      document.querySelector("#pass-title-save").style.pointerEvents = "none";
      document.querySelector("#pass-title-cancel").style.pointerEvents = "none";
      axios
        .patch(`/test_passes/${this.state.activePass.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          test_pass: { name: input.value },
        })
        .then((res) => {
          this.testPassHandler(res.data);
          this.setState(
            {
              editPassTitle: false,
            },
            () => {
              document.getElementById("test-pass-menu-hover").style.top = top;
              document.getElementById(
                "test-pass-menu-hover"
              ).style.marginBottom =
                paddingBottom === "50px"
                  ? "-115px"
                  : paddingBottom === "70px"
                  ? "-151px"
                  : paddingBottom === "88px"
                  ? "-169px"
                  : "-189px";
            }
          );
        })
        .catch((err) => {
          err.message = `Pass Title Edit Error${err.message}`;
          console.error(err);
          Swal.fire({
            title: "Pass Title Error",
            text: "There was a problem updating the Test Pass Title",
            customClass: "swal2-error-modal",
          });
        });
    }
  };

  hideFocus = (e) => {
    if (!e.target.classList.contains("hide-focus")) {
      e.target.classList.add("hide-focus");
    }
  };

  showFocus = (e) => {
    if (e.target.classList.contains("hide-focus")) {
      e.target.classList.remove("hide-focus");
    }
  };

  render() {
    const titleEdit = this.state.editPassTitle;

    return (
      <div style={{ overflowX: "visible" }}>
        <div
          style={{
            position: "fixed",
            top: "-200px",
            left: "-200px",
            pointerEvents: "none",
            height: "0px",
            width: "0px",
            overflow: "hidden",
          }}
          data-number="0"
          role="status"
          aria-live="assertive"
          id="aria-custom-status"
        />
        <div
          className={`modal-backdrop-loader ${
            this.state.loading
              ? "modal-backdrop-loader-open"
              : "modal-backdrop-loader-close"
          }`}
        >
          {this.state.loading && <div id="test-step-bugs-loader" />}
        </div>
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            id="testSuiteContainer"
            style={{
              paddingBottom: "20px",
              display: "flex",
              width: "auto",
              minHeight: "calc(100vh - 223px)",
              margin: "17px 0 -40px 0px",
              paddingLeft: `${20}px`,
              maxWidth: "100%",
              position: "relative",
            }}
          >
            <div
              id="leftSuite"
              style={
                this.state.action == "edit"
                  ? {
                      flex: 1,
                      flexBasis: "40%",
                      padding: "5px 0px 20px",
                      maxWidth: "100%",
                    }
                  : {
                      flex: 1,
                      flexBasis: "40%",
                      padding: `${10}px`,
                      maxWidth: "100%",
                    }
              }
            >
              <div
                style={{
                  position: "relative",
                  display: `${
                    this.state.action == "edit" &&
                    this.state.activePass != undefined
                      ? "block"
                      : "flex"
                  }`,
                  flexDirection: "column",
                  minHeight: "40%",
                }}
              >
                <h1
                  role="heading"
                  aria-label="Test Passes. If you are using NVDA switch to focus mode to use this page."
                  tabIndex={0}
                  className="pass-page-heading large-title"
                  id="main-content"
                  style={{
                    display: "flex",
                    pointerEvents: "none",
                    outline: "none",
                    borderBottom: this.state.activePass
                      ? "none"
                      : "solid 1px lightgray",
                    alignItems: "center",
                    paddingLeft: this.state.activePass ? "20px" : "19px",
                    marginLeft: this.state.activePass ? "-24px" : "-28px",
                    zIndex: "101",
                    paddingBottom: this.state.activePass ? "50px" : "17px",
                    position: "relative",
                    marginBottom: this.state.activePass ? "4px" : "26px",
                    marginTop: this.state.activePass ? "3px" : "-2px",
                    width: "calc(100% + 24px)",
                  }}
                >
                  <img
                    aria-hidden="true"
                    style={{
                      display: "inline-block",
                      height: "48px",
                      marginRight: "6px",
                    }}
                    src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Passes%20-%20Filled.svg"
                  />
                  Test Passes
                </h1>
                {this.state.activePass ? (
                  [
                    titleEdit ? (
                      <div
                        id="pass-title-input-container"
                        key={`${this.state.activePass.id}-input`}
                        style={{
                          height: "28px",
                          position: "sticky",
                          width: "50%",
                          zIndex: "1000",
                          top: "78px",
                          marginBottom: "-105px",
                          paddingTop: "6px",
                        }}
                      >
                        <input
                          id="pass-title-input"
                          maxLength={50}
                          onKeyDown={this.handleEditTestPassTitle}
                          style={{
                            fontFamily: "Arial",
                            verticalAlign: "bottom",
                            paddingLeft: "5px",
                            paddingBottom: "5px",
                            color: "#242424",
                            boxShadow: "none",
                            borderBottom: "solid black 1px",
                            fontSize: `${
                              this.state.activePass.name.length > 30
                                ? "10pt"
                                : "12pt"
                            }`,
                            marginBottom: "-2px",
                            height: "24px",
                            width: "360px",
                          }}
                          defaultValue={this.state.activePass.name}
                        />
                        <span
                          id="pass-title-save"
                          onClick={this.handleEditTestPassTitle}
                          style={{
                            marginLeft: "7px",
                            cursor: "pointer",
                            fontWeight: "600",
                            fontSize: "10pt",
                          }}
                        >
                          Save
                        </span>
                        <span
                          id="pass-title-cancel"
                          onClick={this.handleEditTestPassTitle}
                          style={{
                            marginLeft: "7px",
                            cursor: "pointer",
                            fontWeight: "600",
                            fontSize: "10pt",
                          }}
                        >
                          Cancel
                        </span>
                      </div>
                    ) : (
                      <div
                        key="test-pass-menu-container"
                        id="test-pass-menu-hover"
                        className="test-pass-menu-hover"
                        style={{
                          top: "72px",
                          marginLeft: this.state.activePass ? "0px" : "-5px",
                        }}
                      >
                        {this.state.activePass.status === "active" ? (
                          <TestPassMenu
                            setModal={this.setModal}
                            ref={this.testPassMenu}
                            id="test-pass-menu"
                            key="test-pass-menu"
                            handleEditTestPassTitle={
                              this.handleEditTestPassTitle
                            }
                            handleTestPassDelete={this.handleTestPassDelete}
                            loading={this.state.loading}
                            setPass={this.setTestPass}
                            adminBool={this.adminBool}
                            suites={this.state.testSuites}
                            currentPass={this.state.activePass}
                            suite={this.state.activeSuite}
                            currentUser={this.props.currentUser}
                            project={this.state.project}
                          />
                        ) : (
                          <div
                            id="retired-pass-title"
                            style={{ height: "44px", cursor: "default" }}
                          >
                            <span style={{ fontWeight: "600" }}>
                              {this.state.activePass.name}
                            </span>{" "}
                            (retired){" "}
                            <span
                              onClick={this.handleTestPassDelete}
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                                color: "#83c9f7",
                                marginLeft: "5px",
                                opacity: "0",
                              }}
                            >
                              Delete
                            </span>
                          </div>
                        )}
                      </div>
                    ),
                    this.state.activePass && (
                      <TestPassForm
                        trialist={this.props.trailist}
                        expanded={this.state.expanded}
                        showFocus={this.showFocus}
                        hideFocus={this.hideFocus}
                        setModal={this.setModal}
                        new_badge={this.props.new_badge}
                        clearActivePass={this.clearPass}
                        a11yOptions={this.props.a11yOptions}
                        titleEdit={titleEdit}
                        key={`${this.state.activePass.id}-pass`}
                        testPassHandler={this.testPassHandler}
                        handleTestPassDelete={this.handleTestPassDelete}
                        testPass={this.state.activePass}
                        currentUser={this.props.currentUser}
                        project={this.state.project}
                        suite={this.state.activeSuite}
                        projectUsers={this.state.projectUsers}
                      />
                    ),
                  ]
                ) : (
                  <div>
                    <TestPassLanding
                      adminBool={this.adminBool}
                      retireSuite={this.retireSuite}
                      showFocus={this.showFocus}
                      hideFocus={this.hideFocus}
                      setModal={this.setModal}
                      handleTestPassDelete={this.handleTestPassDelete}
                      project={this.state.project}
                      setTestPass={this.setTestPass}
                      testSuites={this.state.testSuites}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestPassContainer;
