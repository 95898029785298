import React from "react";

const TestCasesExpertSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Test_Cases_Expert_-_Small_svg__cls-1{fill:#edcc43}.Skills__Test_Cases_Expert_-_Small_svg__cls-3{fill:#366e87}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Test_Cases_Expert_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <circle
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-1"
          cx={540}
          cy={540}
          r={418.69}
        />{" "}
        <circle cx={540} cy={540} r={361.19} fill="#519acc" />{" "}
        <path
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-3"
          d="M601.14 385.98H479.69l58.08-129.69 63.37 129.69zM477.62 779.58h124.76v43.93c0 5.37-4.94 9.76-11 9.76H488.61c-6.05 0-11-4.39-11-9.76z"
        />{" "}
        <path
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-3"
          d="M581.62 370.21A20.77 20.77 0 01602.38 391v314.55h-41.52V391a20.76 20.76 0 0120.76-20.79z"
        />{" "}
        <path
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-3"
          d="M540.41 370.21A20.77 20.77 0 01561.18 391v314.55h-41.53V391a20.77 20.77 0 0120.76-20.79z"
        />{" "}
        <path
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-3"
          d="M499.09 370.21A20.76 20.76 0 01519.85 391v314.55h-41.52V391a20.76 20.76 0 0120.76-20.79z"
        />{" "}
        <path
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-3"
          d="M477.97 705.5h124.4v74.08h-124.4z"
        />{" "}
        <path
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-3"
          d="M565.52 705.53h9.57v74.04h-9.57zM537.77 256.29l-13.63 29.89a33.3 33.3 0 0013.63 2.76c6.84 0 14.89-2.76 14.89-2.76z"
        />{" "}
        <path
          fill="#ffe68a"
          d="M601.14 376.42H479.69l58.08-129.69 63.37 129.69z"
        />{" "}
        <path
          d="M477.62 770h124.76v44c0 5.37-4.94 9.76-11 9.76H488.61c-6.05 0-11-4.39-11-9.76z"
          fill="#ff74af"
        />{" "}
        <path
          d="M560.86 360.65h41.52v314.57A20.76 20.76 0 01581.62 696a20.76 20.76 0 01-20.76-20.76V360.65z"
          transform="rotate(180 581.62 528.315)"
          fill="#d1b542"
        />{" "}
        <path
          className="Skills__Test_Cases_Expert_-_Small_svg__cls-1"
          d="M519.65 360.65h41.52v314.57A20.76 20.76 0 01540.41 696a20.76 20.76 0 01-20.76-20.76V360.65z"
          transform="rotate(180 540.415 528.315)"
        />{" "}
        <path
          d="M478.33 360.65h41.52v314.57A20.76 20.76 0 01499.09 696a20.76 20.76 0 01-20.76-20.76V360.65z"
          transform="rotate(180 499.09 528.315)"
          fill="#f7d64b"
        />{" "}
        <path
          transform="rotate(180 540.175 732.975)"
          fill="#5c5c5c"
          d="M477.97 695.94h124.4v74.08h-124.4z"
        />{" "}
        <path
          transform="rotate(180 570.305 732.985)"
          fill="#e0e0e0"
          d="M565.52 695.97h9.57v74.04h-9.57z"
        />{" "}
        <path
          d="M537.77 246.73l-13.63 29.88a33.3 33.3 0 0013.63 2.76c6.84 0 14.89-2.76 14.89-2.76z"
          fill="#242424"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default TestCasesExpertSmall;
