import React from "react";
import axios from "axios";
import BasicSelect from "../common/basic_select.jsx";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import CheckboxMenu from "../common/checkbox_menu";

class CloneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.data.projectId,
      submitted: false,
      menuIsOpen: false,
      ticketTitle: this.props.data.ticketTitle,
      selectedDuplicateOptions: [],
      projectOptions: [],
    };
    this.watcherSelect = React.createRef();
  }

  outsideClickHandler = (e) => {
    if (
      this.state.menuIsOpen &&
      this.watcherSelect.current &&
      !this.watcherSelect.current.contains(e.target)
    ) {
      this.toggleMenu();
    }
  };

  downloadProjects = () => {
    var self = this;
    axios
      .get("/api/v1/dropdown_projects.json?icons=true", {})
      .then((res) => {
        var data = res.data;
        var project = data.projectOptions.find(
          (x) => x.id == self.props.data.projectId
        );
        this.setState({
          project: project,
          projectOptions: data.projectOptions,
          submitted: false,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("axios was canceled");
        } else {
          console.error(err);
        }
      });
  };

  handleProjectChange = (e) => {
    this.setState({ project: e });
  };

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.downloadProjects();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    var formData = new FormData();
    if (this.props.data.projectId != this.state.project.value) {
      formData.append("ticket[project_id]", this.state.project.value);
    }
    formData.append("ticket[title]", this.state.ticketTitle);
    formData.append("duplicate_options", this.state.selectedDuplicateOptions);
    var self = this;
    axios
      .post(`/bugs/${this.props.data.ticketId}/deep_copy`, formData)
      .then((res) => {
        if (res.status == 201) {
          M.toast({
            html: "Ticket Copied",
            displayLength: 3000,
            classes: "green",
          });
          self.props.data.ticketHandler(res.data.ticket, "create");
          self.closeModal();
        }
      })
      .catch((err, res) => {
        console.log(err, res);
        Swal.fire({
          title: "Ticket Copy error",
          text: err.response.data.error,
          confirmButtonText: "Ok",
        });
        self.setState({ submitted: false });
      });
  };

  updateValue = (e) => {
    var name = e.target.name;
    this.setState({ [name]: e.target.value });
  };

  toggleMenu = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
  };

  closeModal = () => {
    this.props.data.closeHandler();
  };

  dupeOptionHandler = (e) => {
    if (e.target.value) {
      var value = e.target.value;
      var newSelectedDuplicateOptions = this.state.selectedDuplicateOptions;
      if (newSelectedDuplicateOptions.indexOf(value) !== -1) {
        newSelectedDuplicateOptions = newSelectedDuplicateOptions.filter(
          (id) => id !== value
        );
      } else {
        newSelectedDuplicateOptions.push(value);
      }
      this.setState({ selectedDuplicateOptions: newSelectedDuplicateOptions });
    }
  };

  render() {
    return (
      <div className="modal-content" onClick={this.outsideClickHandler}>
        <form onSubmit={this.handleSubmit} encType="multipart/form-data">
          <button
            style={{
              position: "relative",
              float: "right",
            }}
            className="icon-close-btn"
            onClick={this.closeModal}
          />
          <div style={{ marginBottom: "10px" }}>
            <p className="small-title">Duplicate a Bug</p>
            <p
              className="subtitle"
              style={{ fontSize: "13px", margin: "0 0 10px" }}
            >
              Create a copy of an issue with the same or different project.
            </p>
          </div>
          <div
            style={{
              width: "596px",
              marginLeft: "-30px",
              height: "1px",
              backgroundColor: "#e0e0e0",
            }}
          />
          <div>
            <div>
              <p className="p-input-title" style={{ margin: "17px 0 10px" }}>
                Bug Title
              </p>
              <CheckboxMenu
                key={2}
                maxWidth={"100%"}
                count={this.state.selectedDuplicateOptions.length}
                active={this.state.selectedDuplicateOptions.length >= 1}
                // [comments ticket_labels devices browsers]
                options={[
                  {
                    value: "comments",
                    label: "Comments",
                    handler: this.dupeOptionHandler,
                    checked:
                      this.state.selectedDuplicateOptions.indexOf(
                        "comments"
                      ) !== -1,
                  },
                  {
                    value: "ticket_labels",
                    label: "Ticket Labels",
                    handler: this.dupeOptionHandler,
                    checked:
                      this.state.selectedDuplicateOptions.indexOf(
                        "ticket_labels"
                      ) !== -1,
                  },
                  {
                    value: "devices",
                    label: "Devices",
                    handler: this.dupeOptionHandler,
                    checked:
                      this.state.selectedDuplicateOptions.indexOf("devices") !==
                      -1,
                  },
                  {
                    value: "browsers",
                    label: "Browsers",
                    handler: this.dupeOptionHandler,
                    checked:
                      this.state.selectedDuplicateOptions.indexOf(
                        "browsers"
                      ) !== -1,
                  },
                ]}
                title="&nbsp;Duplicate Options"
                id="2"
                icons={[
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                  "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                ]}
              />
              <input
                type="text"
                id="ticketTitle"
                name="ticketTitle"
                style={{
                  marginTop: "15px",
                  borderRadius: "0px",
                  backgroundColor: "#f5f5f5",
                  padding: "0px 8px !important",
                }}
                value={this.state.ticketTitle}
                onChange={this.updateValue}
              />
            </div>
            <p style={{ margin: "20px 0 10px" }} className="bold">
              Select Project Destination
            </p>
            <div ref={this.watcherSelect} style={{ marginBottom: "10px" }}>
              <BasicSelect
                options={this.state.projectOptions}
                onChange={this.handleProjectChange}
                selectedOption={this.state.project}
                selectedTextStyle={{
                  width: "200px",
                  display: "block",
                  overflow: "hidden",
                  whiteSpace: "pre",
                  textOverflow: "ellipsis",
                }}
                id="1"
                width="250px"
                hideIconsInMenu={true}
                maxHeight="175px"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <button
              style={{ alignSelf: "flex-end", marginRight: "3px" }}
              disabled={this.state.submitted}
              type="submit"
              className="common-button-submit"
            >
              Duplicate
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default CloneModal;

CloneModal.propTypes = {
  data: PropTypes.object,
};
