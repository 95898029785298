import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CSVLink } from "react-csv";
import { CSSTransition } from "react-transition-group";
import ReactGA from "react-ga";
import useDeviceLab from "@hooks/data/useDeviceLab";
import { userTypes } from "@types";
import DeviceLabTable from "./Table";
import DeviceLabFiltersAndSearch from "./Filters";
import WithCable from "../../channels/WithCable";
import DeviceLabStats from "./device_lab_stats";
import SubNavbar from "../common/sub_navbar";
import UserDevices from "./user_devices";
import ReactModalV2 from "../modals/react_modal_v2";
import CheckoutStats from "./checkout_stats";
import MobileDeviceStats from "./mobile_device_stats";
import LabSettings from "../modals/lab_settings";
import AddDevice from "./add_device";
import DeviceCheckin from "./DeviceCheckin";
import NewBadge from "../modals/new_badge";

const [LAB_SETTINGS, ADD_DEVICE, NEW_BADGE, DEVICE_CHECKIN] = [
  "lab-settings",
  "add-device",
  "new-badge",
  "device-checkin",
];

const DeviceLabContext = React.createContext();

const DeviceLab = (props) => {
  const deviceLabData = useDeviceLab();
  const { settings, list, paging, isLoading, isErrored, PARAM_KEYS, refetch } =
    deviceLabData;
  const { currentUser, cable } = props;

  // user roles
  const isClientUser = useMemo(() => {
    return currentUser.role === "client";
  }, [currentUser]);

  const isAdminUser = useMemo(() => {
    return currentUser.role === "admin";
  }, [currentUser]);

  const isTesterUser = useMemo(() => {
    return currentUser.role === "tester";
  }, [currentUser]);

  const isLeadUser = useMemo(() => {
    return currentUser.role === "lead";
  }, [currentUser]);

  // state fields
  const [activeChartDevice, setActiveChartDevice] = useState(0); // what is this?
  const [activeFilter] = useState("reset");
  const [activeValue] = useState(0);
  const [currentModal, setCurrentModal] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  // derived constants from props
  const badgeUrls = useMemo(
    () => ({
      trialist:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/Skills_Trialist_3.svg",
    }),
    []
  );

  const date = moment().format("l");

  // state update functions
  const resetActiveChartDevice = useCallback(() => {
    setActiveChartDevice(0);
  }, []);

  const setModal = useCallback((openState, page, modalProps) => {
    setModalData(modalProps);
    setCurrentModal(page);
    setModalOpen(openState);
  }, []);

  const closeModal = () => {
    setModal(false, "", {});
  };

  const setEditState = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  // modal controls
  const closeHomeModal = useCallback(() => {
    ReactGA.event({
      category: "Device Lab - Device Checkout",
      action: "Canceled at home/work",
    });

    setModal(false, "", {});
  }, [setModal]);

  const openSettingsModal = useCallback(() => {
    setModal(true, LAB_SETTINGS, {
      customClass: "device-settings",
    });
  }, [setModal]);

  const openDeviceModal = useCallback((device) => {
    setModal(true, ADD_DEVICE, {
      closeModal,
      backdropClickCheck: {
        title: "Close",
        text: "Are you sure you want to close this modal?",
      },
      customClass: "device-add",
      device,
    });
  }, []);

  const openDeviceCheckinModal = useCallback(
    (deviceId) =>
      setModal(true, DEVICE_CHECKIN, {
        deviceId,
        closeModal: closeHomeModal,
        customClass: "checkin-modal",
      }),
    [closeHomeModal, setModal]
  );

  const handleOpenCheckout = useCallback(
    (e) => {
      openDeviceCheckinModal(e.target.value);
    },
    [openDeviceCheckinModal]
  );

  // component mount side effects and unmount cleanup
  useEffect(() => {
    // add event listeners
    const skipElem = document.querySelector(".skip-link");
    const skipClickListener = () => {
      const heading = document.getElementById("main-heading");
      if (heading) {
        heading.focus();
      }
    };

    const escKeyHandler = (e) => {
      if (e.keyCode === 27 && isClientUser) {
        resetActiveChartDevice();
      }
    };

    const a11yTableListener = (e) => {
      e.target.setAttribute(
        "aria-label",
        `Device List, ${list.filter((d) => d.checkout_active).length} results`
      );
    };

    if (skipElem) {
      skipElem.addEventListener("click", skipClickListener);
    }
    window.addEventListener("keydown", escKeyHandler);

    const tableInnerContainerSelector =
      ".ReactVirtualized__Grid__innerScrollContainer";
    const tableOuterContainerSelector =
      ".ReactVirtualized__Grid.ReactVirtualized__List";

    (async () => {
      const Sleep = (ms) =>
        new Promise((r) => {
          setTimeout(r, ms);
        });
      await Sleep(1000);
      const tableOuterContainer = document.querySelector(
        tableOuterContainerSelector
      );
      const tableContainer = document.querySelector(
        tableInnerContainerSelector
      );
      if (tableOuterContainer && tableContainer) {
        tableOuterContainer.removeAttribute("aria-label");
        tableOuterContainer.removeAttribute("role");
        tableOuterContainer.removeAttribute("tabindex");
        tableOuterContainer.removeAttribute("aria-readonly");
        tableContainer.removeAttribute("role");
        tableContainer.setAttribute("aria-label", "Devices List");
        tableContainer.setAttribute("tabindex", "-1");
        tableContainer.addEventListener("focus", a11yTableListener);
        tableContainer.style.listStyle = "none";
      }
    })();

    // establish cable subscription
    if (!isClientUser) {
      // eslint-disable-next-line no-undef
      cable.subscriptions.create(
        { channel: "DeviceLabChannel" },
        {
          received: () => {
            // the DeviceLabChannel is sending device json back to the client.
            // this method is not useful for our purose, as rtk-query is caching results
            // based on the query params. it may be possible to update those cached
            // pages with each page containing the updated device in place in the rtk-query
            // api, but this seems more trouble than it's worth, instead will do the
            // blunt instrument method here of invalidating the cache entirely and refetching
            // the current page.
            // rtk-query and streaming updates, see: https://redux-toolkit.js.org/rtk-query/usage/streaming-updates
            refetch();
          },
        }
      );
    }

    // open new badge modal if prompted by server
    if (settings.badge) {
      const { badge } = settings;
      setModal(true, NEW_BADGE, {
        badgeDetails: badge.details,
        badgeName: badge.name,
        imageLink: badgeUrls[badge.identity],
        customClass: "new-badge-modal",
      });
    }

    // conditionally hide footer for in-office tablet checkout env
    if (currentUser.name === "Tablet Checkout") {
      const elem = document.querySelector("footer");
      if (elem) {
        elem.style.display = "none";
      }
    }

    // cleanup
    return () => {
      // remove event listeners
      if (skipElem) {
        skipElem.removeEventListener("click", skipClickListener);
      }

      window.removeEventListener("keydown", escKeyHandler);

      // unsubscribe from cable websocket
      if (!isClientUser) {
        cable.subscriptions.subscriptions[0].unsubscribe();
      }

      const tableContainer = document.querySelector(
        tableInnerContainerSelector
      );
      if (tableContainer) {
        tableContainer.removeEventListener("focus", a11yTableListener);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isErrored) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "<span role='alert'>An error occured while loading devices.</span>",
        displayLength: 5000,
        classes: "red",
      });
    }
  }, [isErrored]);

  const contextValue = useMemo(() => {
    return {
      currentUser,
      isClientUser,
      isTesterUser,
      isAdminUser,
      isLeadUser,
      openDeviceModal,
      handleOpenCheckout,
      activeChartDevice,
      settings,
      list,
      paging,
      isLoading,
      isEditing,
      isErrored,
      PARAM_KEYS,
    };
  }, [
    currentUser,
    isClientUser,
    isTesterUser,
    isAdminUser,
    isLeadUser,
    openDeviceModal,
    handleOpenCheckout,
    activeChartDevice,
    settings,
    list,
    paging,
    isLoading,
    isEditing,
    isErrored,
    PARAM_KEYS,
  ]);

  return (
    <DeviceLabContext.Provider value={contextValue}>
      <div
        className={
          currentUser && currentUser.name === "Tablet Checkout"
            ? "tablet-checkout-view"
            : ""
        }
      >
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            className="deviceLabMain"
            style={{
              padding: "10px 25px 0 25px",
              flex: "1",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <SubNavbar
              center
              containerStyle={{
                height: "68px",
                width: "98%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
              flex={[2, 1, 0]}
              pageName="Device Lab"
              icon="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Device_Lab_Filled.svg"
              iconStyle={{ width: "50px", height: "50px" }}
              pageNameId="device-lab-heading"
              sectionThreeChildren={[
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                  key={1}
                >
                  <div
                    style={{
                      maxWidth: isEditing ? "400px" : 0,
                      transition: "max-width 1s ease .55s",
                      overflow: "hidden",
                      display: "inline-flex",
                    }}
                  >
                    <button
                      type="button"
                      style={{ fontSize: "14px", marginRight: "15px" }}
                      className="btn btn-overflow scale-in"
                      onClick={() => openDeviceModal()}
                    >
                      Add New Device
                    </button>
                    <button
                      type="button"
                      style={{ fontSize: "14px", marginRight: "15px" }}
                      className="btn btn-overflow scale-in"
                      onClick={openSettingsModal}
                    >
                      {" "}
                      Device Lab Settings{" "}
                    </button>
                  </div>
                  {isAdminUser && (
                    <button
                      type="button"
                      style={{
                        transition: "all .77s",
                        fontSize: "14px",
                        willChange: "width, max-width, color, background-color",
                        maxWidth: isEditing ? "56px" : "125px",
                        overflow: "hidden",
                        width: "125px",
                        backgroundColor: isEditing ? "#e0e0e0" : "",
                        color: isEditing ? "black" : "white",
                      }}
                      className="btn btn-overflow"
                      onClick={setEditState}
                    >
                      {isEditing ? "Done" : "Manage Devices"}
                    </button>
                  )}
                </div>,
              ]}
            />
            <div
              style={{
                width: "calc(100% + 70px)",
                marginLeft: "-25px",
                transition: "all .4s",
                // margin:
                //   userDevices.length >= 1
                //     ? "10px 0 30px -25px"
                //     : "10px 0 0 -25px",
                height: "1px",
                backgroundColor: "#e0e0e0",
              }}
            />
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                // transition: `${
                //   userDevices.length === 0
                //     ? "transform .5s linear .3s"
                //     : "transform .5s ease .25s"
                // }`,
                // transform: `${
                //   userDevices.length === 0 ? "translateX(-10px)" : ""
                // }`,
              }}
            >
              {!isClientUser && isTesterUser && <UserDevices fullWidth />}
              {!isClientUser && !isTesterUser && (
                <>
                  <UserDevices fullWidth={false} />
                  <MobileDeviceStats />
                </>
              )}
            </div>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: isClientUser ? "none" : "flex",
              }}
            >
              <p
                style={{
                  paddingRight: "15px",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                CHECKOUT A DEVICE
              </p>
              <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "#e0e0e0",
                  width: "calc(100% + 25px)",
                }}
              />
            </div>
            <div
              id="device-table"
              style={{
                maxHeight: !isClientUser ? "72vh" : "75v",
              }}
            >
              <div>
                <div>
                  <DeviceLabFiltersAndSearch />
                  <div id="formPortal" />
                  <DeviceLabTable />
                  <div id="checkoutHoverPortal" />
                  <div id="exportLink" className="left">
                    <div className="left m-font-sm">
                      <p style={{ paddingTop: 3 }}>
                        Export Device List Options
                      </p>
                      <p>
                        <CSVLink
                          className="bc-hover"
                          filename={`deviceLabList_${date}.csv`}
                          data={list}
                          target="_blank"
                        >
                          CSV
                        </CSVLink>{" "}
                        |{" "}
                        <a
                          className="pointer bc-hover"
                          href={`/devices/ex_xlsx?ids=${list.map((d) => d.id)}
                          `}
                        >
                          XLSX
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <CSSTransition
                in={isClientUser}
                appear
                exit
                unmountOnExit
                timeout={{
                  appear: 200,
                  enter: 300,
                  exit: 600,
                }}
                classNames="slide-right"
              >
                {isAdminUser ? (
                  <CheckoutStats
                    resetActiveChartDevice={resetActiveChartDevice}
                    activeFilter={activeValue}
                    activeChartDevice={activeChartDevice}
                  />
                ) : (
                  <DeviceLabStats
                    search="search why is this here"
                    activeFilter={activeFilter}
                    data={list}
                    dataLength={list}
                  />
                )}
              </CSSTransition>
            </div>
            <div className="col s12" />
          </div>
        </div>
        <ReactModalV2
          isShowing={modalOpen}
          modalAction={setModal}
          data={modalData}
        >
          {currentModal === NEW_BADGE && (
            <NewBadge data={modalData} setModal={setModal} />
          )}
          {currentModal === ADD_DEVICE && (
            <AddDevice data={modalData} close={closeModal} />
          )}
          {currentModal === LAB_SETTINGS && (
            <LabSettings data={modalData} close={closeModal} />
          )}
          {currentModal === DEVICE_CHECKIN && (
            <DeviceCheckin data={modalData} setModal={setModal} />
          )}
        </ReactModalV2>
      </div>
    </DeviceLabContext.Provider>
  );
};

DeviceLab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cable: PropTypes.objectOf(PropTypes.any), // actioncable
  currentUser: userTypes,
};

export default WithCable(DeviceLab);

export { DeviceLabContext };
