import React from 'react'

class EODReportIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        stroke={this.props.stroke}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        className="icons"
      >
        <circle fill="#519acc" cx="32" cy="32" r="29.09"/>
        <path fill="#fff" d="M42.09,16.86H40.83v2.53a3.78,3.78,0,0,1-3.78,3.78H27a3.78,3.78,0,0,1-3.78-3.78V16.86H21.91a3.79,3.79,0,0,0-3.79,3.79v24a3.79,3.79,0,0,0,3.79,3.79H42.09a3.79,3.79,0,0,0,3.79-3.79v-24A3.79,3.79,0,0,0,42.09,16.86ZM24.43,28.22H32a1.26,1.26,0,0,1,0,2.52H24.43a1.26,1.26,0,1,1,0-2.52Zm0,6.3H32a1.27,1.27,0,0,1,0,2.53H24.43a1.27,1.27,0,0,1,0-2.53Zm11.35,8.83H24.43a1.26,1.26,0,0,1,0-2.52H35.78a1.26,1.26,0,1,1,0,2.52Z"/>
        <rect fill="#fff" x="25.69" y="15.6" width="12.61" height="5.05" rx="1.26"/>
      </svg>
    );
  }
}
EODReportIcon.defaultProps = {
  color: "#fff",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default EODReportIcon