import React from "react";
import PropTypes from "prop-types";

const WikiCategoryHR = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
    >
      <path
        d="M26.0598 16.6056C26.0598 19.9389 23.3326 25.3329 19.9991 25.3329C16.6657 25.3329 13.9385 19.9389 13.9385 16.6056C13.9385 13.2723 16.6657 10.5449 19.9991 10.5449C23.3325 10.5448 26.0598 13.2721 26.0598 16.6056Z"
        fill="#F4F4F4"
      />
      <path
        d="M20 6C12.2423 6 6 12.2423 6 20C6 27.697 12.2423 34 20 34C27.7577 34 34 27.697 34 20C34 12.2423 27.7577 6 20 6ZM24.6667 23.8183C23.3939 25.4547 21.8182 26.6062 20 26.6062C18.1818 26.6062 16.5453 25.4547 15.2727 23.8183C13.0909 24.485 10.6667 25.8183 9.818 27.8183C8.121 25.6366 7.15133 22.97 7.15133 20C7.15133 12.909 12.909 7.212 19.9393 7.212C27.0303 7.212 32.7273 12.9697 32.7273 20C32.7273 22.9091 31.7577 25.6363 30.0607 27.8183C29.3333 25.8183 26.9091 24.485 24.6667 23.8183Z"
        fill="#F4F4F4"
      />
      <path
        d="M4.84796 9.93962L5.0904 11.3335C5.15095 11.6972 5.45407 11.8789 5.81761 11.8184C6.1206 11.7578 6.36304 11.4547 6.3025 11.0912L5.81761 8.30328C5.75706 8.00029 5.39337 7.69715 5.02971 7.81838L2.2418 8.30328C1.93881 8.36383 1.69637 8.66695 1.7569 9.03048C1.81745 9.39415 2.12057 9.57592 2.4841 9.51538L3.81744 9.27294C-0.788562 16.1819 -0.485562 25.2729 4.72654 31.9396C4.90831 32.1821 5.33267 32.2426 5.57497 32.0608C5.81742 31.8791 5.87796 31.4547 5.69619 31.2124C0.787194 24.9697 0.544861 16.4244 4.8479 9.93973L4.84796 9.93962Z"
        fill="#F4F4F4"
      />
      <path
        d="M31.2117 34.303C25.03 39.1513 16.4844 39.4546 9.9997 35.1514L11.3936 34.9089C11.6966 34.8484 11.939 34.5453 11.8785 34.2423C11.8179 33.9393 11.5148 33.6968 11.2118 33.7574L8.36337 34.1816C8.06038 34.2422 7.81794 34.5453 7.87847 34.9088L8.3027 37.6967C8.36325 38.0604 8.66637 38.2421 8.96937 38.1816C9.27236 38.1211 9.5148 37.8179 9.45427 37.5149L9.21182 36.1211C16.1815 40.7877 25.2725 40.4847 31.8785 35.2726C32.1209 35.0909 32.1815 34.6665 31.9997 34.4242C31.8179 34.1818 31.4543 34.1212 31.2118 34.303L31.2117 34.303Z"
        fill="#F4F4F4"
      />
      <path
        d="M8.78738 5.69697C15.0297 0.787973 23.5147 0.545306 29.9994 4.84854L28.6055 5.09099C28.3025 5.15153 28.0601 5.45465 28.1206 5.75765C28.1812 6.06065 28.4843 6.30309 28.7873 6.24255L31.6357 5.81832C31.9387 5.75777 32.2418 5.45465 32.1206 5.09112L31.6964 2.30322C31.6358 2.00023 31.3327 1.75779 31.0297 1.81832C30.7267 1.87887 30.4843 2.18199 30.5448 2.48499L30.7873 3.87885C23.8176 -0.787814 14.6659 -0.484814 8.05993 4.72715C7.81748 4.90892 7.75694 5.33329 7.93871 5.57559C8.18115 5.81816 8.54484 5.87871 8.78727 5.69694L8.78738 5.69697Z"
        fill="#F4F4F4"
      />
      <path
        d="M37.5159 30.5457L36.122 30.7881C40.728 23.8791 40.4857 14.7275 35.2736 8.12145C35.0918 7.87901 34.6675 7.81846 34.4252 8.00023C34.1827 8.182 34.1222 8.60636 34.3039 8.84866C39.2129 15.091 39.4556 23.576 35.1524 30.0607L34.9099 28.6668C34.8494 28.3638 34.5463 28.1214 34.2433 28.1819C33.9403 28.2424 33.6978 28.5456 33.7584 28.8486L34.1826 31.697C34.2431 31.9394 34.4856 32.2424 34.8492 32.1819L37.6977 31.7577C38.0007 31.6971 38.2431 31.394 38.1826 31.091C38.1826 30.6668 37.8796 30.485 37.5159 30.5456L37.5159 30.5457Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};

WikiCategoryHR.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

WikiCategoryHR.defaultProps = {
  height: "40px",
  width: "40px",
  svgStyles: {},
  color: "#f4f4f4",
};

export default WikiCategoryHR;
