import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const StatsBox = styled.div`
  display: flex;
  padding: 33px 27px;
  margin: 5px;
  display: flex;
  height: 100%;
  align-items: flex-start;

  > .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: max-width 0.5s;
    flex-direction: column;
  }
`;

const StatItem = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;

  > img {
    width: 55px;
    height: 55px;
    position: relative;
    border-radius: 3px;
    border: 1px solid transparent;
  }

  > div {
    width: 100%;
    margin-left: 10px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  > div > p:nth-child(1) {
    line-height: 20px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  > div > p:nth-child(2) {
    font-size: 22px;
    line-height: 25px;
    font-weight: 600;
  }
`;

function GeneralStats(props) {
  const [items, setStats] = useState([]);
  const [loading, setLoading] = useState(false);

  function getData() {
    setLoading(true);
    if (props.scope == "custom") {
      var url = `/projects/${props.projectId}/general_stats.json?startDate=${props.startDate}&endDate=${props.endDate}&scope=${props.scope}`;
    } else {
      var url = `/projects/${props.projectId}/general_stats.json?scope=${props.scope}`;
    }
    axios
      .get(url)
      .then((res) => {
        setStats(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function displayLoader() {
    return (
      <div className="display-loader">
        <div id="test-step-bugs-loader" />
      </div>
    );
  }

  function displayItems() {
    if (items.length >= 1) {
      return items.map((item) => (
        <StatItem key={item[1]}>
          <img alt="bug" src={item[0]} />
          <div>
            <p>{item[1]}</p>
            <p>{item[2]}</p>
          </div>
        </StatItem>
      ));
    } else {
      return (
        <p style={{ padding: "10px", textAlign: "center" }}>No Recent Media</p>
      );
    }
  }

  useEffect(() => {
    getData();
  }, [props.projectId, props.scope, props.endDate, props.startDate]);

  return (
    <StatsBox>
      <div className="flex">{loading ? displayLoader() : displayItems()}</div>
    </StatsBox>
  );
}

export default GeneralStats;
