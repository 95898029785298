import React from 'react'

class InformationModal extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    
    return (
    <div>
        <span style={{padding:"10px", textAlign:"center", display:"block", fontSize:"20px", fontWeight:"600"}}>{this.props.data.title}</span>
        <video 
          style={{padding:"37.5px", boxShadow:"0px 0px 11px 6px lightgrey inset", maxWidth: "75%", margin:"20px auto", display:"block", height:"auto"}}
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
          playsInline={true}
          src={this.props.data.videoLink}>
        </video>
    </div>
    )
  }

}
export default InformationModal