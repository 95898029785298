import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useProjects = (projectId) => {
  const [projectOptions, setProjectOptions] = useState([]);

  const getProject = useCallback(
    (id, projectsList) => {
      try {
        if (Number.isNaN(projectId) && id !== undefined) {
          const project = projectsList.find((e) => e.identifier === id);
          return { value: project.id, label: project.name, project };
        }
        if (id === undefined) {
          const urlId = window.location.href.split("/").indexOf("projects") + 1;
          const urlProjectId = Number.isNaN(parseInt(urlId))
            ? urlId
            : parseInt(urlId);
          const project = projectsList.find(
            (e) => e.identifier === urlProjectId || e.id === urlProjectId
          );
          return { value: project.id, label: project.name, project };
        }
        const project = projectsList.find((e) => e.id === projectId);
        return { value: project.id, label: project.name, project };
      } catch (err) {
        return null;
      }
    },
    [projectId]
  );

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    axios
      .get("/api/v1/dropdown_projects.json", {
        cancelToken: axiosCancelSource.token,
      })
      .then((res) => {
        const { data } = res;
        setProjectOptions(data.projectOptions);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      axiosCancelSource.cancel("axios get canceled");
    };
  }, [projectId, getProject]);

  return { projectOptions };
};

export default useProjects;
