import React from "react";

class DeleteIcon extends React.Component {
  render() {
    return (
      <svg
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        style={this.props.svgStyles}
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 64 64"
      >
        <rect x="8.03" y="14.69" width="47.93" height="5.77"/>
        <rect x="24.01" y="4.59" width="15.98" height="5.77"/>
        <path d="M13.36,24.79V53.64a5.56,5.56,0,0,0,5.33,5.77H45.31a5.56,5.56,0,0,0,5.33-5.77V24.79Zm11,26a2.74,2.74,0,0,1-2.56,2.88,2.73,2.73,0,0,1-2.56-2.88V33.44a2.73,2.73,0,0,1,2.56-2.89,2.74,2.74,0,0,1,2.56,2.89Zm10.23,0A2.73,2.73,0,0,1,32,53.63a2.73,2.73,0,0,1-2.56-2.88V33.44A2.73,2.73,0,0,1,32,30.55a2.73,2.73,0,0,1,2.55,2.89Zm10.24,0a2.58,2.58,0,1,1-5.12,0V33.44a2.58,2.58,0,1,1,5.12,0Z"/>
      </svg>
    );
  }
}

DeleteIcon.defaultProps = {
  height: "16px",
  width: "16px",
  svgStyles: {},
  color: "#444344"
}

export default DeleteIcon