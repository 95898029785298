const MultiSelectImageStyles = {
  container: (styles, state) => {
    return {
    ...styles,
    display: state.hasValue ? "inline-block" : "block",
    borderBottom: "none",
    width: "100%",
    top: "-5px",
    transition: "all .3s",
    height: state.hasValue ? "100%" : state.selectProps.menuIsOpen ? "100%" : "0",
    background: state.hasValue ? "" : "transparent",
    }
   },
  multiValue: (provided, state) => {
    return {
      ...provided,
      margin: "2px 5px",
    };
  },
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "transparent",
      borderColor: "transparent !important",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      borderStyle: "solid",
      transition: "border .3s, max-height .3s",
      borderWidth: "1px",
      maxHeight: state.hasValue ? "500px" : state.selectProps.menuIsOpen ? "500px" : "0",
      height: "100%",
      cursor: "pointer",
      outline: "unset",
      display: "",
      pointerEvents: state.hasValue ? "auto" : state.selectProps.menuIsOpen ? "auto" : "none",
      boxShadow: state.isFocused ? "unset" : "unset",
    };
  },
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    display: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderBottom: "solid rgb(224, 224, 224) 1px",
    borderLeft: "solid rgb(224, 224, 224) 1px",
    borderRight: "solid rgb(224, 224, 224) 1px",
    borderTop: "solid rgb(224,224,224) 1px",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    paddingTop: "0px",
    transition: "max-height 0.3s",
    maxHeight: state.hasValue ? "150px" : state.selectProps.menuIsOpen ? "150px" : "0",
    paddingBottom: "0px",
    marginTop: "5px",
    overflow: "hidden scroll"
  }),
  valueLabel: (styles) => ({ ...styles, whiteSpace: "pre-wrap" }),
  input: (styles, state) => {
    return {
    ...styles,
   //  height: state.hasValue ? "40px" : state.selectProps.menuIsOpen ? "40px" : "0",
    boxShadow: "none !important",
  }},
  menu: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "rgba(255,255,255,0.6)",
      transition: "max-height .5s",
      borderTopColor: "transparent",
      filterBackdrop: "blur(3px)",
      boxShadow: "unset",
      cursor: "pointer",
      marginTop: "5px"
    };
  },
  singleValue: (styles, {}) => ({
    ...styles,
    fontWeight: "600",
    fontSize: "15px",
    whiteSpace: "normal",
    lineHeight: "15px",
    marginTop: "4px",
    top: "45%",
    wordBreak: "break-word",
    cursor: "pointer",
    height: "20px",
  }),
  valueContainer: (styles) => ({
    ...styles,
    maxHeight: "500px",
    overflow: "visible",
    alignSelf: "flex-start",
    padding: "0"
  }),
  indicatorsContainer: (styles, state) => {
    return {
      ...styles,
      bottom: state.hasValue ? "" : "18px",
      maxHeight: "100px",
      alignItems: "flex-start",
    };
  },
  dropdownIndicator: (styles) => ({ ...styles, display: "none" }),
  option: (styles, state) => {
    return {
      ...styles,
      fontSize: "11pt",
      transition: "opacity 0.3s, transform 0.3s",
      fontFamily: "arial",
      whiteSpace: "pre-wrap",
      backgroundColor: state.isFocused
        ? "rgba(81,155,204,0.6)"
        : "rgba(255,255,255,0.6)",
      color: state.isFocused ? "white" : "black",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default MultiSelectImageStyles