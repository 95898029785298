import React from 'react'

class BuildIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#6eb0db" stroke="#6eb0db" d="M32.56,27.79,54,18a.45.45,0,0,0-.06-.84l-21.39-9a.36.36,0,0,0-.25,0L10.44,17.54a.45.45,0,0,0,0,.84l21.81,9.41A.43.43,0,0,0,32.56,27.79Z"/>
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#519acc" stroke="#519acc" d="M34.64,31.43l19.52-8.89L54.1,44.37a2.47,2.47,0,0,1-1.31,2.19L34.58,56.23Z"/>
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#8abbdb" stroke="#8abbdb" d="M30.13,31.46,9.77,22.6V44.07a3,3,0,0,0,1.6,2.67l18.76,9.49Z"/>
      </svg>
    );
  }
}
BuildIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default BuildIcon