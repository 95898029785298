import PropTypes from "prop-types";
import projectTypes from "./project";

const { shape, string, number, bool, arrayOf, array } = PropTypes;

export const attachmentTypes = shape({
  id: number,
  ticket_id: number,
  comment_id: number,
  file_url: string,
  file_info: shape({
    url: string,
    metadata: shape({
      filename: string,
      mime_type: string,
      size: number,
    }),
  }),
});

export const commentTypes = shape({
  ticket_attachments: arrayOf(attachmentTypes),
  avatar_image: string,
  created_at: string,
  id: number,
  ticket_id: number,
  updated_at: PropTypes.string,
  user_id: number,
  user_name: string,
  value: string,
  likes: shape({
    count: number,
    unicode: string,
    current_user_liked: bool,
    list: arrayOf(
      shape({
        id: number,
        user_id: number,
        created_at: string,
        updated_at: string,
        name: string,
        email: string,
      })
    ),
  }),
});

const ticketTypes = shape({
  a11y: shape({ id: number, violation: string }),
  app_key: number,
  assignee_avatar_image: string,
  assignee_email_notification: bool,
  assignee_id: number,
  assignee_name: string,
  browsers: arrayOf(shape({ value: number, label: string })),
  build_version: string,
  comments: arrayOf(commentTypes),
  content: string,
  created_at: string,
  description: string,
  devices: arrayOf(shape({ value: number, label: string })),
  draft: bool,
  id: number,
  jira_ticket_key: string,
  project: projectTypes,
  project_id: number,
  tester_avatar_image: string,
  tester_email_notifications: bool,
  tester_id: number,
  tester_name: string,
  ticket_attachments: arrayOf(attachmentTypes),
  ticket_labels: arrayOf(string),
  ticket_priority_id: number,
  ticket_priority_name: string,
  ticket_status_id: number,
  ticket_status_name: string,
  title: string,
  trello_card_id: string,
  updated_at: string,
  watchers: array, // fill this out
});

const ticketIndexTypes = shape({
  a11y: shape({ id: number, violation: string }),
  app_key: number,
  assignee_name: string,
  browsers: arrayOf(string),
  created_at: string,
  description: string,
  devices: arrayOf(string),
  draft: bool,
  id: number,
  project: shape({ id: number, identifier: string, name: string }),
  reporter_role: string,
  tester_id: number,
  tester_name: string,
  tester_labels: string,
  ticket_priority_id: number,
  ticket_priority_name: string,
  ticket_status_id: number,
  ticket_status_name: string,
  title: string,
  updated_at: string,
});

export default ticketTypes;

export { ticketTypes, ticketIndexTypes };
