import React from "react";
import PropTypes from "prop-types";

const ChecklistIcon = ({ height, width, color, fillColor, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={fillColor}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 23"
    >
      <path
        d="M17.028 23H1.77765C0.795265 23 0 22.1154 0 21.0445V0.465587C0 0.186235 0.187121 0 0.467803 0H17.028C18.0104 0 18.8057 0.884615 18.8057 1.95547V21.0445C18.8525 22.1154 18.0104 23 17.028 23ZM0.935606 0.931174V21.0445C0.935606 21.6032 1.30985 22.0688 1.77765 22.0688H17.028C17.4958 22.0688 17.8701 21.6032 17.8701 21.0445V1.95547C17.9169 1.39676 17.4958 0.931174 17.028 0.931174H0.935606Z"
        fill={color}
      />
      <path
        d="M4.35011 6.37849C4.20977 6.37849 4.11621 6.33194 4.02264 6.23882L2.80636 4.93517C2.61924 4.74894 2.61924 4.46959 2.80636 4.28335C2.99348 4.09712 3.27416 4.09712 3.46128 4.28335L4.35011 5.21453L6.31488 3.25906C6.502 3.07283 6.78268 3.07283 6.9698 3.25906C7.15692 3.4453 7.15692 3.72465 6.9698 3.91088L4.72435 6.19226C4.63079 6.33194 4.49045 6.37849 4.35011 6.37849Z"
        fill={color}
      />
      <path
        d="M15.6722 5.44729H8.32767C8.04698 5.44729 7.85986 5.26105 7.85986 4.9817C7.85986 4.70235 8.04698 4.51611 8.32767 4.51611H15.719C15.9996 4.51611 16.1868 4.70235 16.1868 4.9817C16.1868 5.26105 15.9529 5.44729 15.6722 5.44729Z"
        fill={color}
      />
      <path
        d="M4.35011 12.9434C4.20977 12.9434 4.1162 12.8969 4.02264 12.8038L2.80636 11.5001C2.61924 11.3139 2.61924 11.0345 2.80636 10.8483C2.99348 10.6621 3.27416 10.6621 3.46128 10.8483L4.35011 11.7795L6.2681 9.824C6.45522 9.63777 6.7359 9.63777 6.92302 9.824C7.11014 10.0102 7.11014 10.2896 6.92302 10.4758L4.67757 12.7572C4.63079 12.8969 4.49045 12.9434 4.35011 12.9434Z"
        fill={color}
      />
      <path
        d="M15.6722 12.1519H8.32767C8.04698 12.1519 7.85986 11.9656 7.85986 11.6863C7.85986 11.4069 8.04698 11.2207 8.32767 11.2207H15.719C15.9996 11.2207 16.1868 11.4069 16.1868 11.6863C16.1868 11.9656 15.9529 12.1519 15.6722 12.1519Z"
        fill={color}
      />
      <path
        d="M15.3903 18.3906H7.99905C7.71837 18.3906 7.53125 18.2044 7.53125 17.9251C7.53125 17.6457 7.71837 17.4595 7.99905 17.4595H15.3903C15.671 17.4595 15.8581 17.6457 15.8581 17.9251C15.8581 18.2044 15.671 18.3906 15.3903 18.3906Z"
        fill={color}
      />
      <path
        d="M3.46048 19.508C3.32014 19.508 3.22658 19.4614 3.13302 19.3683C2.9459 19.1821 2.9459 18.9027 3.13302 18.7165L5.42525 16.4351C5.61237 16.2489 5.89305 16.2489 6.08018 16.4351C6.2673 16.6213 6.2673 16.9007 6.08018 17.0869L3.78794 19.3683C3.69438 19.4614 3.55404 19.508 3.46048 19.508Z"
        fill={color}
      />
      <path
        d="M5.75271 19.508C5.61237 19.508 5.51881 19.4614 5.42525 19.3683L3.13302 17.0869C2.9459 16.9007 2.9459 16.6213 3.13302 16.4351C3.32014 16.2489 3.60082 16.2489 3.78794 16.4351L6.08018 18.7165C6.2673 18.9027 6.2673 19.1821 6.08018 19.3683C5.98662 19.4614 5.89305 19.508 5.75271 19.508Z"
        fill={color}
      />
    </svg>
  );
};

ChecklistIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
  fillColor: PropTypes.string,
};

ChecklistIcon.defaultProps = {
  height: 23,
  width: 19,
  svgStyles: {},
  color: "#519acc",
  fillColor: "none",
};

export default ChecklistIcon;
