import React from "react";
import styled from "styled-components";
import TestPassResultRow from "./test_pass_result_row";

// Styled Components
const TestCaseContainer = styled.div`
  max-height: 40vh;
`;

const TestCaseHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #519acc;
  color: white;
`;

const SectionHeadingButton = styled.button`
  font-size: 18px;
  cursor: ${(props) => (props.empty ? "default" : "pointer")};
  pointer-events: ${(props) => (props.empty ? "none" : "all")};
  background: none;
  border: none;
  color: white;
`;

const TestCaseTitleArrow = styled.i`
  opacity: ${(props) => (props.empty ? "0" : "1")};
`;

const TestStepContainer = styled.div`
  display: flex;
`;

const TestStepText = styled.div`
  padding-right: ${(props) => (props.a11yActive ? "85px" : "54px")};
  position: sticky;
  left: 1px;
  z-index: 10;
  background-color: ${(props) =>
    props.index % 2 === 0 ? "#e6e6e6" : "#f7f7f7"};
  border-right: solid 1px gray;
  min-width: 700px;
`;

const TestStepResultGroup = styled.div`
  background-color: #f2f2f2;
  margin-left: 1px;
`;

const StepDetailsContainer = styled.div`
  position: relative;
`;

const StepDetailsLabel = styled.h4`
  font-weight: 400;
  background-color: ${(props) =>
    props.index % 2 === 0 ? "#e6e6e6" : "#f7f7f7"};
`;

const StepDetailsText = styled.p``;

// Functional Component
const TestCaseComponent = ({
  testCase,
  index1,
  handleExpandSteps,
  sectionHeadingOnKeyDown,
  hideFocus,
  sectionHeadingFocus,
  processText,
  handleTestStepA11yClick,
  a11yMouseEnter,
  a11yMouseLeave,
  bugsMouseEnter,
  bugsMouseLeave,
  handleTestStepBugsClick,
  handleTestStepCommentsClick,
  currentPlatform,
  testPassId,
  testPassPercent,
  commentMouseEnter,
  commentMouseLeave,
  textCaseStep,
  a11yActive,
  currentUserId,
  draggingResult,
  projectUsersList,
  currentColumn,
  setCurrentColumn,
  sectionHeadingBlur,
  row,
}) => {
  const empty = !testCase.test_steps.length > 0;

  return (
    <TestCaseContainer key={`test-case-${testCase.id}-${index1}`}>
      <TestCaseHeader id={`test-case-${index1}`}>
        <SectionHeadingButton
          tabIndex={0}
          aria-expanded="true"
          aria-label={`Section ${index1 + 1}: ${testCase.name}`}
          empty={empty}
          onClick={handleExpandSteps}
        >
          <TestCaseTitleArrow
            empty={empty}
            aria-hidden="true"
            className="material-icons"
          >
            expand_more
          </TestCaseTitleArrow>
          {index1 + 1}: {testCase.name}
        </SectionHeadingButton>
      </TestCaseHeader>

      <div>
        {testCase.test_steps.map((step, index2) => {
          const violationCount =
            a11yActive && textCaseStep?.test_step_a11y_ids?.length > 0
              ? textCaseStep.test_step_a11y_ids.length
              : "";
          const bugsCount =
            textCaseStep?.ticket_ids?.length > 0
              ? textCaseStep.ticket_ids.length
              : "";

          return (
            <TestStepContainer key={`test-step-${step.id}-${index1}-${index2}`}>
              <TestStepText a11yActive={a11yActive} index={index2}>
                <h4>
                  TC {index1 + 1}.{index2 + 1}
                </h4>
                <div>{processText(step.text)}</div>

                {/* Accessibility Icon */}
                {a11yActive && (
                  <i
                    className="material-icons"
                    onClick={handleTestStepA11yClick}
                    onMouseEnter={a11yMouseEnter}
                    onMouseLeave={a11yMouseLeave}
                  >
                    accessibility_new
                  </i>
                )}

                {/* Bugs Count Icon */}
                <i
                  className="material-icons"
                  onClick={handleTestStepBugsClick}
                  onMouseEnter={bugsMouseEnter}
                  onMouseLeave={bugsMouseLeave}
                >
                  bug_report
                </i>

                {/* Comments Icon */}
                <i
                  className="material-icons"
                  onClick={handleTestStepCommentsClick}
                  onMouseEnter={commentMouseEnter}
                  onMouseLeave={commentMouseLeave}
                >
                  comment
                </i>

                {/* Step Details */}
                {step.details !== "" && (
                  <StepDetailsContainer>
                    <StepDetailsLabel index={index2}>
                      Details/Expected Results
                    </StepDetailsLabel>
                    <StepDetailsText
                      dangerouslySetInnerHTML={{ __html: step.details }}
                    />
                  </StepDetailsContainer>
                )}
              </TestStepText>

              <TestStepResultGroup>
                {/* Test Pass Result Rows */}
                {step.test_pass_results
                  .filter((x) => x.test_pass_id === testPassId)
                  .map((filterResult, index3) => (
                    <TestPassResultRow
                      key={index3}
                      testPassResults={filterResult.results}
                      testPassPercent={testPassPercent}
                      currentUserId={currentUserId}
                      index1={index1}
                      index2={index2}
                      index3={index3}
                      stepId={step.id}
                      draggingResult={draggingResult}
                      projectUsersList={projectUsersList}
                      currentColumn={currentColumn}
                      setCurrentColumn={setCurrentColumn}
                    />
                  ))}
              </TestStepResultGroup>
            </TestStepContainer>
          );
        })}
      </div>
    </TestCaseContainer>
  );
};

export default TestCaseComponent;
