import React, { useEffect, useContext } from "react";
import { func } from "prop-types";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Select from "react-select";
import ApiClient from "../../utils/ApiClient";
import ModalHeader from "../modals/common/modal_header";
import { ModalBody, ModalContent } from "../modals/common/modal_styles";
import { CancelButton, SubmitButton } from "../common/modal_buttons";
import wikiCategoryValues from "./wikiCategoryValues";
import { ColorExample } from "./EditWikiResourceForm";
import VisibleRolesDropdown from "./VisibleRolesDropdown";
import VisibleTeamsDropdown from "./VisibleTeamsDropdown";
import { WikiContext } from "./wiki_container";

const AddWikiResourceForm = ({ toggleModalVisible }) => {
  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      resource_name: "",
      user_permissions: [],
      team_permissions: [],
      category: "misc",
    },
  });

  const { setCurrentResource, setDirection } = useContext(WikiContext);

  const [currentResourceName, currentUserPermissions, currentTeamPermissions] =
    watch(["resource_name", "user_permissions", "team_permissions"]);

  // post new wiki resource request fn
  const postNewResource = ({
    resource_name,
    user_permissions,
    team_permissions,
    category,
  }) => {
    const api = new ApiClient();
    return api.post("/wiki", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      wiki_resource: {
        title: resource_name,
        user_permissions,
        team_permissions,
        category,
      },
    });
  };

  // form callback fns
  const onCancel = () => {
    reset();
    toggleModalVisible();
  };

  const onSubmit = async (data) => {
    try {
      const response = await postNewResource(data);
      reset();
      toggleModalVisible();
      setCurrentResource({ wiki_resource: response.data, wiki_topics: [] });
      window.history.pushState(
        "page2",
        "Test Platform",
        `/wiki/${response.data.id}`
      );
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 400);
      setTimeout(() => {
        setDirection("node-backwards");
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  const onError = (errors) => {
    for (const error of Object.values(errors)) {
      if (error.message) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: error.message,
          classes: "red darken-1 error-toast",
        });
      }
    }
  };

  // custom update form values. for playing nicely with existing checkbox components
  const handleRoleSelect = (e) => {
    const role = e.target.value;
    const idx = currentUserPermissions.indexOf(e.target.value);
    const newRoles =
      idx === -1
        ? [...currentUserPermissions, role]
        : [
            ...currentUserPermissions.slice(0, idx),
            ...currentUserPermissions.slice(idx + 1),
          ];

    setValue("user_permissions", newRoles);
  };

  const handleTeamSelect = (e) => {
    const value = parseInt(e.target.value);
    const idx = currentTeamPermissions.indexOf(value);
    const newTeams =
      idx === -1
        ? [...currentTeamPermissions, value]
        : [
            ...currentTeamPermissions.slice(0, idx),
            ...currentTeamPermissions.slice(idx + 1),
          ];

    setValue("team_permissions", newTeams);
  };
  const options = Object.entries(wikiCategoryValues).map(
    ([value, { gradient, label }]) => ({
      value,
      label,
      colorGradient: gradient,
    })
  );

  const CategoryOption = ({ innerProps, label, data }) => {
    return (
      <div
        {...innerProps}
        style={{ display: "flex", alignItems: "center", padding: "5px 10px" }}
      >
        <ColorExample $colorGradient={data.colorGradient} />
        <span style={{ paddingLeft: "10px" }}>{label}</span>
      </div>
    );
  };

  const handleCategorySelect = (category) => {
    setValue("category", category);
  };

  useEffect(() => {
    // registering in component causes console to complain about functional components being
    // given refs, register them here to get rid of that.
    register("user_permissions", {
      required: {
        value: true,
        message: "Please select at least one user role.",
      },
    });
    register("team_permissions");
    register("category");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalBody>
      <ModalHeader
        title="Create New Wiki Resource"
        modalAction={toggleModalVisible}
        showHeaderImg={false}
        containerStyle={{
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          maxHeight: "75px",
        }}
      />
      <ModalContent>
        <AddResourceForm onSubmit={handleSubmit(onSubmit, onError)}>
          <FormItem>
            <label htmlFor="resource_name">
              Resource Name<span style={{ color: "#519ACC" }}>*</span>
            </label>
            <input
              {...register("resource_name", {
                required: {
                  value: true,
                  message: "Resource Name is required.",
                },
                maxLength: {
                  value: 30,
                  message: "Resource name cannot be longer than 30 characters.",
                },
              })}
              type="text"
              className="browser-default"
              maxLength={30}
              placeholder="e.g. Test Accounts"
            />
            <h4>{currentResourceName?.length || 0} / 30</h4>
          </FormItem>
          <FormItem>
            <label htmlFor="user_permissions">User Permissions</label>
            <div style={{ display: "flex", marginTop: "8px" }}>
              <div style={{ marginRight: "3px" }}>
                <VisibleRolesDropdown
                  name="user_permissions"
                  onRoleSelect={handleRoleSelect}
                  selectedRoles={currentUserPermissions}
                />
              </div>
              <div>
                <VisibleTeamsDropdown
                  name="team_permissions"
                  onTeamSelect={handleTeamSelect}
                  selectedTeams={currentTeamPermissions}
                />
              </div>
            </div>
          </FormItem>
          <FormItem>
            <label htmlFor="card_color">Resource Card Color</label>
            <Select
              options={options}
              onChange={(selectedOption) =>
                handleCategorySelect(selectedOption ? selectedOption.value : "")
              }
              getOptionValue={(option) => option.value}
              components={{
                Option: CategoryOption,

                SingleValue: ({ children, ...props }) => (
                  <div
                    {...props}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ColorExample $colorGradient={props.data.colorGradient} />
                    <span style={{ paddingLeft: "10px" }}>{children}</span>
                  </div>
                ),
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  height: "36px",
                  width: "178px",
                  marginTop: "8px",
                }),

                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: "8px",
                  height: "100%",
                  color: "rgb(81, 154, 204) !important",
                }),

                placeholder: (provided) => ({
                  ...provided,
                  lineHeight: "39px",
                  height: "100%",
                }),
                indicatorSeparator: () => ({
                  height: "100%",
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  height: "100%",
                  overflow: "none",
                  padding: "0px 5px",
                }),
                input: (provided) => ({
                  ...provided,

                  height: "100%",
                  margin: "0",
                  padding: "0",
                }),
              }}
            />
          </FormItem>
          <ControlsRow>
            <CancelButton onClick={onCancel} htmlType="button">
              Cancel
            </CancelButton>
            <SubmitButton htmlType="submit">Create</SubmitButton>
          </ControlsRow>
        </AddResourceForm>
      </ModalContent>
    </ModalBody>
  );
};

export const AddResourceForm = styled.form``;

export const FormItem = styled.div`
  margin: 8px 0 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    color: #242424;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    font-family: "Manrope", sans-serif;
  }

  input {
    max-width: 100%;
    width: auto;
    height: 39px;
    margin: 8px 0;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0 16px;
    font-size: 13px;
    line-height: 15px;
  }

  h4 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #242424;
    margin: 0;
    padding: 0;
  }
`;

export const ControlsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;

AddWikiResourceForm.propTypes = {
  toggleModalVisible: func.isRequired,
};

export default AddWikiResourceForm;
