import React from "react";

const Pronoun = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.91169 13.5507L4.91164 13.5507L4.41103 13.2824L2.09611 13.7969L2.0194 13.8139L2.03645 13.7372L2.55097 11.4223L2.28343 10.9217L4.91169 13.5507ZM4.91169 13.5507C5.83613 14.0448 6.86852 14.3022 7.91668 14.3L4.91169 13.5507ZM4.21229 14.8582L4.19595 14.8495L4.17787 14.8535L0.0658669 15.7675L0.979818 11.6555L0.983837 11.6374L0.975103 11.621C0.365872 10.4815 0.0480634 9.20892 0.0500089 7.91674V7.91667C0.0500089 3.57191 3.57191 0.05 7.91668 0.05C12.2614 0.05 15.7833 3.57191 15.7833 7.91667C15.7833 12.2614 12.2614 15.7833 7.91668 15.7833H7.9166C6.62442 15.7853 5.35184 15.4675 4.21229 14.8582ZM7.91678 14.3C9.17925 14.3 10.4134 13.9256 11.4631 13.2242C12.5128 12.5228 13.331 11.5259 13.8141 10.3595C14.2972 9.19306 14.4237 7.90959 14.1774 6.67134C13.9311 5.43309 13.3231 4.29569 12.4304 3.40297C11.5376 2.51024 10.4002 1.90229 9.162 1.65599C7.92376 1.40969 6.64028 1.5361 5.47388 2.01924C4.30748 2.50238 3.31054 3.32054 2.60913 4.37028C1.90772 5.42001 1.53334 6.65416 1.53334 7.91667C1.53334 8.98094 1.79265 10.0055 2.28341 10.9217L7.91678 14.3ZM11.8247 7.96667C11.8117 8.98514 11.4014 9.95911 10.6803 10.6803C9.94733 11.4132 8.95323 11.825 7.91668 11.825C6.88012 11.825 5.88602 11.4132 5.15307 10.6803C4.4319 9.95911 4.02168 8.98514 4.00866 7.96667H5.49219C5.50507 8.59171 5.75896 9.18842 6.20194 9.6314C6.65672 10.0862 7.27353 10.3417 7.91668 10.3417C8.55982 10.3417 9.17663 10.0862 9.63141 9.6314C10.0744 9.18842 10.3283 8.59171 10.3412 7.96667H11.8247Z"
        fill="#519ACC"
        stroke="white"
        strokeWidth="0.1"
      />
    </svg>
  );
};

export default Pronoun;
