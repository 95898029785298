import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useFormContext, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import Icons from "../../../icons";

const { TrashSimpleIcon, RoundDropdownIcon } = Icons;

const QuestionEditorControls = ({ question }) => {
  const methods = useFormContext();
  const { getValues, setValue } = methods;
  const allQuestions = methods.watch("questions");
  const fieldArray = useFieldArray({ name: "questions" });

  const isFirst = useMemo(() => {
    if (!allQuestions) return false;
    const { position } = question;
    return position === 1;
  }, [allQuestions, question]);

  const isLast = useMemo(() => {
    if (!allQuestions) return false;
    const { position } = question;
    return position === allQuestions.length;
  }, [allQuestions, question]);

  const scrollQuestionIntoView = useCallback((nextPosition) => {
    const controls = document.querySelector(
      `#question-editor-controls-${nextPosition}`
    );
    if (controls) {
      controls.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  const updatePositions = useCallback((movedQuestions) => {
    // for use after useFieldArray's move method
    // update the position of each question after the move, return new array
    return [...movedQuestions].map((q, index) => ({
      ...q,
      position: index + 1,
    }));
  }, []);

  const handleDelete = useCallback(() => {
    const { position, id } = question;
    const nextQuestions = [...allQuestions]
      .filter((q) => q.position !== position)
      .map((q) => {
        // Decrement position of questions after delete.
        if (q.position > position) {
          return {
            ...q,
            position: q.position - 1,
          };
        }
        return q;
      });
    setValue("questions", nextQuestions);

    if (id) {
      setValue("delete_questions", [
        ...getValues("delete_questions"),
        question,
      ]);
    }
  }, [allQuestions, getValues, setValue, question]);

  const openConfirmDelete = () => {
    Swal.fire({
      title: "Delete Question",
      text: "Are you sure you want to delete this question?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "common-button-submit",
        cancelButton: "common-button-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  const handleMoveUp = useCallback(() => {
    // decrement question index by 1
    const { move } = fieldArray;
    const prevIndex = allQuestions.findIndex((q) => {
      return JSON.stringify(q) === JSON.stringify(question);
    });

    // if question is first or not found, return
    if (prevIndex < 1) return null;

    const nextIndex = prevIndex - 1;
    move(prevIndex, nextIndex);

    // update postition values of each question
    const nextQuestions = updatePositions(getValues("questions"));
    const nextPosition =
      nextQuestions[nextQuestions.findIndex((q) => q.id === question.id)]
        .position;
    setValue("questions", nextQuestions);

    // scroll to new position of question after move
    scrollQuestionIntoView(nextPosition);

    return null;
  }, [
    fieldArray,
    question,
    allQuestions,
    getValues,
    setValue,
    updatePositions,
    scrollQuestionIntoView,
  ]);

  const handleMoveDown = useCallback(() => {
    // increment question index by 1
    const { move } = fieldArray;
    const prevIndex = allQuestions.findIndex((q) => {
      return JSON.stringify(q) === JSON.stringify(question);
    });

    // if question is last or not found, return
    if (prevIndex === allQuestions.length - 1 || prevIndex === -1) return null;

    const nextIndex = prevIndex + 1;
    move(prevIndex, nextIndex);

    // update postition values of each question
    const nextQuestions = updatePositions(getValues("questions"));
    const nextPosition =
      nextQuestions[nextQuestions.findIndex((q) => q.id === question.id)]
        .position;
    setValue("questions", nextQuestions);

    // scroll to new position of question after move
    scrollQuestionIntoView(nextPosition);

    return null;
  }, [
    fieldArray,
    question,
    allQuestions,
    getValues,
    setValue,
    updatePositions,
    scrollQuestionIntoView,
  ]);

  return (
    <EditorControls id={`question-editor-controls-${question.position}`}>
      {!isFirst && (
        <Control type="button" onClick={handleMoveUp}>
          <IconContainer $reverse>
            <RoundDropdownIcon height="20px" width="20px" color="#519acc" />
          </IconContainer>
        </Control>
      )}
      {!isLast && (
        <Control type="button" onClick={handleMoveDown}>
          <IconContainer>
            <RoundDropdownIcon height="20px" width="20px" color="#519acc" />
          </IconContainer>
        </Control>
      )}
      <DeleteQuestion onClick={openConfirmDelete} type="button">
        <IconContainer>
          <TrashSimpleIcon height="19px" width="20px" color="#519acc" />
        </IconContainer>
      </DeleteQuestion>
    </EditorControls>
  );
};

const EditorControls = styled.div`
  display: flex;
  gap: 5px;
`;

const Control = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
`;

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ $reverse }) => ($reverse ? "rotate(180deg)" : "none")};
`;

const DeleteQuestion = styled(Control)`
  cursor: pointer;
`;

QuestionEditorControls.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    position: PropTypes.number,
    question_type: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        answer: PropTypes.string,
        correct: PropTypes.bool,
      })
    ),
  }).isRequired,
};

export default QuestionEditorControls;
