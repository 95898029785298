import React from 'react'
import axios from 'axios'
class SendClientSurvey extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      validEmail: null
    }
    this.timer = null
  }

  componentDidMount(){ 
    axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
				'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
	}

  emailValidation = (e) => {
      if(this.timer){
        clearTimeout(this.timer)
      }
      var email = e.target.value
      this.timer = setTimeout(() =>  {
        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regexp.test(email)){
        this.setState({validEmail: true})
      } else {
        this.setState({validEmail: false})
      }
      },500)
  }

  sendEmail = () => {
    document.querySelector("#client-survey-form button").disabled = true
    var email = document.querySelector("#client-survey-form input").value
    var message = document.querySelector("#client-survey-form textarea")
    message = message.value ? message.value : ""
    axios.post("/send_client_survey", {
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    },
       email,
       message
    }).then((res) => {
      M.toast({html: 'Email Sent', classes: "green", displayLength: 5000})
      this.props.modalAction(false, "", {})
    })
    .catch((err) => {
      M.toast({html: 'Error Sending Email', classes: "red", displayLength: 5000})
      console.error(err)
      this.props.modalAction(false, "", {})
      })
  }

  render() {
    return (
    <div id="client-survey-form">
        <span style={{padding:"10px", textAlign:"center", display:"block", fontSize:"20px", fontWeight:"600"}}>Send Client Survey</span>
        <span style={{fontWeight:"600"}}>Email</span> {this.state.validEmail === false && <span style={{color:"red"}}> - invalid email</span>}
        <input maxLength={50} onChange={this.emailValidation} type="text" className={this.state.validEmail === false ? "invalid-email" : ""}/>
        <span style={{fontWeight:"600"}}>Default Message</span>
        <span style={{fontStyle:"italic", display:"block", marginBottom:"5px"}}>"Thank you for your recent project with PLUS QA. We are always trying to improve our services for clients. Please take a brief moment to provide some feedback on your experience."</span>
        <span style={{fontWeight:"600"}}>Additional Message (optional)</span>
        <textarea placeholder="message" maxLength={1000}></textarea>
        <button onClick={this.sendEmail} className={this.state.validEmail ? "button-enabled":""} disabled={!this.state.validEmail}>Send</button>
    </div>
    )
  }

}
export default SendClientSurvey