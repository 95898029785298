import React, { useContext } from "react";
import { func } from "prop-types";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import ApiClient from "../../utils/ApiClient";
import ModalHeader from "../modals/common/modal_header";
import { ModalBody, ModalContent } from "../modals/common/modal_styles";
import { WikiContext } from "./wiki_container";
import {
  AddResourceForm as FeedbackForm,
  FormItem,
} from "./AddWikiResourceForm";
import { SubmitButton, CancelButton } from "../common/modal_buttons";

const WikiFeedbackForm = ({ toggleModalVisible }) => {
  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      resource_name: "",
      user_permissions: [],
      team_permissions: [],
    },
  });

  const {
    currentResource: { wiki_resource },
  } = useContext(WikiContext);

  // post new wiki resource request fn
  const postFeedback = ({ feedback_content }) => {
    const api = new ApiClient();
    return api.post(`/wiki/${wiki_resource.id}/feedback`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      feedback: { content: feedback_content },
    });
  };

  // form callback fns
  const onCancel = () => {
    reset();
    toggleModalVisible();
  };

  const onSubmit = async (data) => {
    try {
      await postFeedback(data);
      // eslint-disable-next-line no-undef
      M.toast({
        html: "Feedback submitted for review, thank you!",
        classes: "green",
      });
      reset();
      toggleModalVisible();
    } catch (e) {
      console.error(e);
    }
  };

  const onError = (errors) => {
    for (const error of Object.values(errors)) {
      if (error.message) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: error.message,
          classes: "red darken-1 error-toast",
        });
      }
    }
  };

  return (
    <ModalBody>
      <ModalHeader
        title="Give us your feedback"
        modalAction={toggleModalVisible}
        showHeaderImg={false}
        containerStyle={{
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          maxHeight: "75px",
        }}
      />
      <ModalContent>
        <FeedbackForm onSubmit={handleSubmit(onSubmit, onError)}>
          <FormItem>
            <label htmlFor="feedback_content">
              How can we improve this feature?
            </label>
            <TextInput
              autoFocus
              {...register("feedback_content", {
                required: {
                  value: true,
                  message: "Please enter your feedback before submitting.",
                },
              })}
              placeholder="We would love to hear your feedback"
              rows="6"
              max-rows="8"
            />
          </FormItem>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "32px",
            }}
          >
            <CancelButton onClick={onCancel} htmlType="button">
              Cancel
            </CancelButton>
            <SubmitButton htmlType="submit">Submit</SubmitButton>
          </div>
        </FeedbackForm>
      </ModalContent>
    </ModalBody>
  );
};

const TextInput = styled.textarea`
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d3d3d3;
  padding: 12px 16px;
  gap: 20px;
  height: auto;
  margin-top: 8px;
`;

WikiFeedbackForm.propTypes = {
  toggleModalVisible: func.isRequired,
};

export default WikiFeedbackForm;
