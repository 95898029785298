import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { wikiResourceTypes } from "@types/wiki";
import ReactModalV2 from "@components/modals/react_modal_v2";
import EditNotepadIcon from "@icons/edit_notepad_icon";
import useModalControl from "@hooks/useModalControl";
import EditWikiResourceForm from "./EditWikiResourceForm";

const EditWikiResourceModal = ({ resource, children, className }) => {
  const { isOpen, toggle } = useModalControl(false);

  const renderEditWikiResourceForm = () => (
    <EditWikiResourceForm toggleModalVisible={toggle} resource={resource} />
  );

  return (
    <>
      <EditResourceButton className={className} onClick={toggle}>
        {children || (
          <IconContainer>
            <EditNotepadIcon color="#000000" />
          </IconContainer>
        )}
      </EditResourceButton>
      <ReactModalV2
        isShowing={isOpen}
        data={{ customClass: "add-wiki-resource-modal" }}
        page={renderEditWikiResourceForm}
        modalAction={toggle}
      />
    </>
  );
};

const EditResourceButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const IconContainer = styled.div``;

EditWikiResourceModal.propTypes = {
  resource: wikiResourceTypes.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

EditWikiResourceModal.defaultProps = {
  className: "",
  children: null,
};

export default EditWikiResourceModal;
