import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { WidgetTitle } from "../styles";

const UserList = ({ users, title }) => {
  const [search, setSearch] = useState("");

  const userSearchResults = useMemo(() => {
    if (!search || search === "") {
      return users;
    }

    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.role.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, users]);

  const updateSearch = (e) => {
    setSearch(e.target.value);
  };

  const clearSearch = () => {
    setSearch("");
  };

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  return (
    <UserListWrapper>
      <WidgetContainer>
        <WidgetTitle>{title}</WidgetTitle>
        <SearchContainer>
          <SearchIcon className="material-icons prefix">search</SearchIcon>
          <SearchInput
            id="bug-search-input"
            disabled={users.length === 0}
            className="browser-default"
            type="text"
            value={search}
            onChange={updateSearch}
            placeholder=" "
            aria-label="Bugs search input field"
          />
          {search !== "" && (
            <CloseButton
              onClick={clearSearch}
              className="link-btn material-icons grey-text"
            >
              close
            </CloseButton>
          )}
          <SearchResults
            aria-label={`Showing ${
              search
                ? `${users.length} out of ${users.length} bugs`
                : `all ${users.length} bugs`
            }`}
          >
            {`Displaying ${users.length >= 1 ? "1" : "0"}-${
              users.length
            } out of ${users.length}`}
          </SearchResults>
        </SearchContainer>
      </WidgetContainer>

      <UserListContainer>
        {userSearchResults.length === 0 ? (
          <NoResultsMessage>No users found.</NoResultsMessage>
        ) : (
          userSearchResults.map((user) => (
            <UserCard key={user.id}>
              <ColorBar role={user.role} />
              <UserProfileImage
                src={
                  user.avatar
                    ? user.avatar
                    : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
                }
                alt="Profile"
              />
              <UserName>{capitalize(user.name)}</UserName>
              <JobTitle>{capitalize(user.title)}</JobTitle>

              <ViewProfileButton href={`/performance/${user.id}`}>
                View Profile
              </ViewProfileButton>
            </UserCard>
          ))
        )}
        {}
      </UserListContainer>
    </UserListWrapper>
  );
};

const UserListWrapper = styled.div``;

const UserListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f0f0f0;
  max-height: 638px;
  overflow: auto;
  justify-content: center;
  align-items: flex-start;
`;

const UserCard = styled.div`
  background-color: #fff;
  max-width: 265px;
  min-width: 265px;
  height: 299px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  border-radius: 3px;
`;

const IMG = {
  tester:
    "url('https://tp-assets.sfo2.cdn.digitaloceanspaces.com/performance/USER_TESTER_BG.png')",
  lead: "url('https://tp-assets.sfo2.cdn.digitaloceanspaces.com/performance/USER_MANAGER_BG.png')",
  admin:
    "url('https://tp-assets.sfo2.cdn.digitaloceanspaces.com/performance/USER_ADMIN_BG.png')",
};

const ColorBar = styled.div`
  background-image: ${(props) => {
    return IMG[props.role] || IMG.tester;
  }};
  background-size: cover;
  min-height: 75px;
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`;

const UserProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  top: -50px;
`;

const UserName = styled.h3`
  text-align: center;
  position: relative;
  top: -50px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
`;

const JobTitle = styled.p`
  text-align: center;
  position: relative;
  top: -60px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #656565;
`;

const ViewProfileButton = styled.a`
  display: block;
  margin: 0 auto;
  background-color: #519acc;
  color: #fff;
  width: 226px;
  height: 40px;
  border: none;
  border-radius: 5px;
  position: relative;
  bottom: 28px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  font-family: Manrope;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
const NoResultsMessage = styled.p`
  text-align: center;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  height: 319px;
`;

const SearchIcon = styled.i`
  position: absolute;
  pointer-events: none;
  left: 6px;
  color: #ababab;
`;

const SearchInput = styled.input``;

const CloseButton = styled.button`
  color: grey;
  background: none;
  border: none;
  cursor: pointer;
`;

const SearchResults = styled.p`
  margin-left: 10px;
`;

const WidgetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-bottom: 10px;
`;

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      avatar: PropTypes.string,
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      teams: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default UserList;
