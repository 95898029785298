import jQuery from "jquery";
import Rails from "@rails/ujs";
import M from "materialize-css/dist/js/materialize.min";

Object.assign(window, {
  jQuery,
  $: jQuery,
  Rails,
  M,
});
