import React, { useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { startCase } from "lodash";
import Icons from "@icons";
import {
  RadioButton,
  ErrorMessage,
  Checkbox,
} from "@components/common/FormElements";
import {
  IntroPanel,
  IntroTag,
  IntroContent,
  QuestionHeadline,
  TextEdit,
  CharacterLimit,
} from "./IntroEditor";
import { EditAssessmentContext } from "../EditAssessment";
import QuestionEditorControls from "./QuestionEditorControls";

const QuestionEditor = ({ position }) => {
  const { QUESTION_TYPES } = useContext(EditAssessmentContext);
  const [SINGLE, WRITTEN, MULTIPLE] = QUESTION_TYPES;
  const ICONS = {
    [SINGLE]: (
      <Icons.BulletpointsIcon height="26px" width="26px" color="#000" />
    ),
    [WRITTEN]: (
      <Icons.PencilCircleIcon height="26px" width="26px" color="#000" />
    ),
    [MULTIPLE]: (
      <Icons.CheckboxSquircleIcon height="26px" width="26px" color="#000" />
    ),
  };
  const EXPLAIN = {
    [SINGLE]:
      "Please provide up to four choices and select the correct answer.",
    [WRITTEN]:
      "Please provide a series of keywords that you would expect to see in a good answer to this question.",
    [MULTIPLE]:
      "Please provide up to four choices, and select the correct answers.",
  };

  const innerRef = useRef(null);

  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const getIndex = useCallback(() => {
    return getValues("questions").findIndex((q) => q.position === position);
  }, [position, getValues]);

  const question = watch("questions")[getIndex()];

  if (!question) return null;

  const handleUpdateDescription = (value) => {
    if (value.length <= 240) {
      setValue(`questions.${getIndex()}.description`, value);
    }
  };

  const handleUpdateKeywords = (value) => {
    if (value.length <= 240) {
      setValue(`questions.${getIndex()}.keywords`, value);
    }
  };

  const handleSelectOption = (index) => {
    // if single choice, set option.correct to true all else to false
    if (question.question_type === SINGLE) {
      const nextOptions = question.options.map((o, i) => {
        return { ...o, correct: i === index };
      });
      setValue(`questions.${getIndex()}.options`, nextOptions);
      return null;
    }

    // if multiple choice, toggle option.correct, unless this would make
    // all options correct, then do nothing
    const isCorrect = question.options[index].correct;
    const correctCount = question.options.reduce((acc, { correct }) => {
      return correct ? acc + 1 : acc;
    }, 0);
    const total = question.options.length;
    if (!isCorrect && correctCount === total - 1) return null;
    setValue(`questions.${getIndex()}.options[${index}].correct`, !isCorrect);

    return null;
  };

  const handleAddOption = () => {
    const focusInput = () => {
      const inputId = `question-${getIndex()}-option-input-${
        question.options.length - 1
      }`;
      const input = document.querySelector(`#${inputId}`);
      if (input) {
        input.focus();
      }
    };

    if (question.options.length >= 4) {
      focusInput();
      return null;
    }

    const options = [...question.options, { answer: "", correct: false }];
    setValue(`questions.${getIndex()}.options`, options);
    setTimeout(() => {
      focusInput();
    }, [50]);

    return null;
  };

  const handleRemoveOption = (index) => {
    const currentRemovedOptions = getValues("delete_answers");
    if (question.options.length === 1) return null;

    // remove the option
    // const nextOptions = question.options.filter((_, i) => i !== index);
    const nextOptions = [...question.options];
    const removedOption = {
      ...nextOptions.splice(index, 1)[0],
      question_id: question.id,
    };

    // if no correct options remain, set the first option to correct
    const totalCorrect = nextOptions.reduce((acc, { correct }) => {
      return correct ? acc + 1 : acc;
    }, 0);
    if (totalCorrect === 0) {
      nextOptions[0].correct = true;
    }

    setValue(`questions.${getIndex()}.options`, nextOptions);

    // add option to delete_answers if it has an id (an existing option that should be
    // removed from the db). if not, was created locally and can be ignored
    if (removedOption.id) {
      setValue("delete_answers", [...currentRemovedOptions, removedOption]);
    }

    return null;
  };

  const handleUpdateOptionText = (value, index) => {
    if (value.length > 140) return null;
    setValue(`questions.${getIndex()}.options[${index}].answer`, value);

    return null;
  };

  return (
    <QuestionPanel>
      <QuestionTag>{getIndex() + 1}</QuestionTag>
      <QuestionContent
        $isErrored={errors && errors.questions && errors.questions[getIndex()]}
        ref={(node) => {
          innerRef.current = node;
        }}
      >
        <QuestionBody>
          <QuestionHeaderRow>
            <TitleColumn>
              <IconContainer>{ICONS[question.question_type]}</IconContainer>
              <TitleHeadline>{startCase(question.question_type)}</TitleHeadline>
            </TitleColumn>
            <ControlColumn>
              <QuestionEditorControls question={question} />
            </ControlColumn>
          </QuestionHeaderRow>
          <QuestionHeadline>Question</QuestionHeadline>
          <ErrorMessage
            name={`questions.${getIndex()}.description`}
            forwardRef={innerRef}
          />
          <TextEdit
            onChange={(e) => {
              handleUpdateDescription(e.target.value);
            }}
            value={question.description}
            rows="4"
            maxLength="240"
          />
          <CharacterLimit $isHidden={typeof question.description !== "string"}>
            {typeof question.description === "string" &&
              question.description.length}{" "}
            / 240
          </CharacterLimit>

          <AnswerSection>
            <AnswerHeadline>Answer</AnswerHeadline>
            <AnswerExplainer>{EXPLAIN[question.question_type]}</AnswerExplainer>
            <ErrorMessage
              name={`questions.${getIndex()}`}
              forwardRef={innerRef}
            />
            {question.question_type === WRITTEN && (
              <>
                <AnswerInput
                  type="text"
                  className="browser-default"
                  value={question.keywords}
                  onChange={(e) => {
                    handleUpdateKeywords(e.target.value);
                  }}
                />
                <CharacterLimit
                  $isHidden={typeof question.keywords !== "string"}
                >
                  {typeof question.keywords === "string" &&
                    question.keywords.length}{" "}
                  / 140
                </CharacterLimit>
              </>
            )}
            {question.question_type !== WRITTEN && (
              <AnswerOptions>
                {question.options.map((option, optionIndex) => {
                  if (question.question_type === SINGLE)
                    return (
                      <AnswerOption
                        className="common-radio"
                        key={option?.id || optionIndex}
                      >
                        <RadioButton
                          onChange={() => null}
                          onClick={() => handleSelectOption(optionIndex)}
                          checked={!!option.correct}
                        />
                        <OptionInput
                          type="text"
                          value={option.answer}
                          className="browser-default"
                          id={`question-${getIndex()}-option-input-${optionIndex}`}
                          onChange={(e) =>
                            handleUpdateOptionText(e.target.value, optionIndex)
                          }
                        />
                        <DeleteOption
                          type="button"
                          disabled={question.options.length === 1}
                          onClick={() => handleRemoveOption(optionIndex)}
                        >
                          <Icons.TrashSimpleIcon
                            height="19px"
                            width="20px"
                            color="#519acc"
                          />
                        </DeleteOption>
                      </AnswerOption>
                    );

                  return (
                    <AnswerOption key={option?.id || optionIndex}>
                      <Checkbox
                        checked={!!option.correct}
                        id={`question-${getIndex()}-option-input-${optionIndex}`}
                        onChange={() => null}
                        onClick={() => {
                          handleSelectOption(optionIndex);
                        }}
                      />
                      <OptionInput
                        type="text"
                        value={option.answer}
                        className="browser-default"
                        id={`question-${getIndex()}-option-input-${optionIndex}`}
                        onChange={(e) =>
                          handleUpdateOptionText(e.target.value, optionIndex)
                        }
                      />
                      <DeleteOption
                        type="button"
                        disabled={question.options.length === 1}
                        onClick={() => handleRemoveOption(optionIndex)}
                      >
                        <Icons.TrashSimpleIcon
                          height="16px"
                          width="16px"
                          color="#519acc"
                        />
                      </DeleteOption>
                    </AnswerOption>
                  );
                })}
                <AnswerOption>
                  <AddOptionButton
                    type="button"
                    onClick={() => handleAddOption()}
                    disabled={question.options.length >= 4}
                    $isDisabled={question.options.length >= 4}
                  >
                    <AddIconContainer>
                      <Icons.PlusIcon height="16px" width="16px" />
                    </AddIconContainer>
                  </AddOptionButton>
                </AnswerOption>
              </AnswerOptions>
            )}
          </AnswerSection>
        </QuestionBody>
      </QuestionContent>
    </QuestionPanel>
  );
};

const QuestionPanel = styled(IntroPanel)`
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
`;

const QuestionTag = styled(IntroTag)`
  width: 43px;
  height: auto;
  max-height: 43px;
`;

const QuestionContent = styled(IntroContent)`
  padding: 0;
  background-color: transparent;
`;

const QuestionBody = styled.div`
  padding: 29px 25px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
`;

const QuestionHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const ControlColumn = styled.div``;

const TitleHeadline = styled.div`
  margin-bottom: 0;
  color: #242424;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 600;
`;

const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;

const AnswerSection = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AnswerHeadline = styled(QuestionHeadline)`
  margin-bottom: 0;
`;

const AnswerExplainer = styled.div`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
`;

const AnswerInput = styled.input`
  width: 100%;
  height: 43px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 13px;
  line-height: 20px;
  font-family: "Arial";
`;

const AnswerOptions = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

const AnswerOption = styled.div`
  height: 43px;
  width: 100%;
  margin-bottom: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  label {
    height: 100%;
    width: 43px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
`;

const OptionInput = styled(AnswerInput)`
  width: calc(100% - 43px);
`;

const DeleteOption = styled.button`
  height: 43px;
  width: 43px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  background-color: transparent;
`;

const AddOptionButton = styled.button`
  opacity: 1;
  width: calc(100% - 86px);
  height: 43px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  transition: opacity 0.2s ease-in-out;

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const AddIconContainer = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #519acc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

QuestionEditor.propTypes = {
  position: PropTypes.number.isRequired,
};

export default QuestionEditor;
