import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AssessmentEditor from "./AssessmentEditor";
import { wikiAssessmentTypes } from "../../../types/wiki";
import { userTypes, projectTypes } from "../../../types";

export const EditAssessmentContext = React.createContext({});

export const QUESTION_TYPES = [
  "single_answer",
  "written_answer",
  "multiple_answer",
];

const EditAssessmentContainer = ({
  current_user,
  project,
  assessment: assessment_data,
  wiki_resources,
}) => {
  const [assessment, setAssessment] = useState(assessment_data || {});

  return (
    <EditAssessmentContext.Provider
      value={{
        current_user,
        project,
        assessment,
        setAssessment,
        wiki_resources,
        QUESTION_TYPES,
      }}
    >
      <div id="help-center-container">
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            whiteSpace: "nowrap",
            marginLeft: "auto",
          }}
        >
          <Content>
            <Header>Build an Assessment</Header>
            <AssessmentEditor />
          </Content>
        </div>
      </div>
    </EditAssessmentContext.Provider>
  );
};

const Content = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 10px;
`;

EditAssessmentContainer.propTypes = {
  assessment: wikiAssessmentTypes.isRequired,
  current_user: userTypes.isRequired,
  project: projectTypes.isRequired,
  wiki_resources: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })
  ).isRequired,
};

export default EditAssessmentContainer;
