import React from 'react'
import BasicSelect from '../common/basic_select.jsx'
import axios from 'axios'
import Swal from "sweetalert2";

class UploadBuild extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      availability: {value:"24_hours", label:"24 hours"},
      buildFile: null, 
      loading: false 
    }
    this.uploadProgressTimer = null
    this.uploadProgress = 0
    this.uploadProgressCount = 0
    this.waitForProgressTimer = null
  }

  onAvailabilityChange = (option) => {
    this.setState({availability: option})
  }

  componentDidMount(){
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
  }

  handleCancelButton = () => {
    var self = this
    if (this.state.loading || this.state.buildFile){
      Swal.fire({
        title: "Cancel Upload",
        text: "Are you sure that you want to cancel uploading this Build?",
        reverseButtons: true,
        showCancelButton:true,
confirmButtonAriaLabel: 'Yes',
cancelButtonAriaLabel: 'cancel',
        confirmButtonText: "Yes",
      }).then((result)=>{
        if (result.value) {
         self.props.modalAction(false, "", {})
        } else if (result.dismiss) {
        }
      })
    } else {
      self.props.modalAction(false, "", {})
    }
  }
  
  onFileChange = () => {
    var file = document.getElementById("build-modal-file").files[0];
    var fileExtension = file.name.split(".").pop().toLowerCase()
    var allowedExtensions = ["ipa","apk","app"]
    if(!allowedExtensions.includes(fileExtension)){
      M.toast({html: 'Invalid File Type', classes: "red darken-1 error-toast"})
      this.setState({buildFile: null});
    }
    if (file) {
      if (file.size/1024/1024 >= 200) {
        M.toast({html: 'Build exceeds file limit!', classes: "red darken-3 error-toast"})
        this.setState({buildFile: null});
      } else {
        this.setState({buildFile: file});
      }
    } else {
      this.setState({buildFile: null});
    }
  }

  removeFile = () => {
    this.setState({buildFile: null})
  }

  uploadBuild = () => {
    document.getElementById("build-upload-file-container").style.opacity = "0"
    setTimeout(()=>{
      this.props.disableBackdropClick(true)
      this.setState({loading: true}, ()=>{
        document.getElementById("build-upload-progress-container").style.opacity = "1"
        setTimeout(()=>{
          var formData = new FormData();
          formData.append('file', this.state.buildFile, this.state.buildFile.name);
          formData.append('passcode', this.refs.build_password.value.trim());
          formData.append('availability', this.state.availability.value);
          formData.append('note', this.refs.build_new_text.value.trim());
          if (this.props.data.application_id) {
            formData.append('application_id', this.props.data.application_id);
            formData.append('app_id', this.props.data.application_id);
          }
          if (this.props.data.project_id) {
            formData.append('project_id', this.props.data.project_id);
          }
          var self = this;
          axios.post('/v1/builds', formData, 
            {onUploadProgress:(progressEvent) => {
              this.uploadProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              
              if(this.uploadProgressTimer === null){
                this.uploadProgressTimer = setInterval(()=>{
                  if(this.uploadProgressCount === 95){
                    clearInterval(this.uploadProgressTimer)
                    document.getElementById("build-upload-percentage").innerHTML = "95"
                    this.uploadProgressTimer = null
                  } else if(this.uploadProgressCount < this.uploadProgress){
                    document.getElementById("build-upload-percentage").innerHTML = `${this.uploadProgressCount + 1}`
                    this.uploadProgressCount += 1
                  }
                }, 20)
              }
            }
          })
          .then((res) => {
            this.waitForProgressTimer = setInterval(()=>{
              if(this.uploadProgressTimer === null){
                clearInterval(this.waitForProgressTimer)
                this.waitForProgressTimer = null
                this.uploadProgressTimer = setInterval(()=>{
                  if(this.uploadProgressCount < 100){
                    document.getElementById("build-upload-percentage").innerHTML = `${this.uploadProgressCount +1}`
                    this.uploadProgressCount += 1
                  } else {
                    clearInterval(this.uploadProgressTimer)
                    this.uploadProgressTimer = null
                    this.uploadProgressCount = 0
                    this.uploadProgress = 0
                    document.getElementById("build-modal-spinner").style.opacity = "0"
                    
                    setTimeout(()=>{
                      document.getElementById("upload-modal-complete").style.opacity = "1"
                      setTimeout(()=>{
                        this.props.disableBackdropClick(false)
                        M.toast({html: 'Build Upload Successful', classes: "green"})
                        this.props.data.downloadBuilds()
                        if(!this.props.data.flowCompleted){
                          this.props.data.skipFlow()
                        }
                        this.props.modalAction(false, "", {})
                      }, 1000)
                    }, 300)
                  }
                }, 60)
              }
            }, 100)
          })
          .catch((err) => {
            clearInterval(this.uploadProgressTimer)
            this.uploadProgress = 0
            this.uploadProgressCount = 0
            this.uploadProgressTimer = null
            this.setState({loading: false})
            M.toast({html: 'Error uploading build', classes: "red darken-3 error-toast"})
          })
        }, 300)
      })
      
    }, 300)  
  }

  render() {
    return (
    <div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
        <button onClick={this.handleCancelButton} className="icon-close-btn right" style={{pointerEvents: this.state.loading ? "none" : "all"}}></button>
      </div>
      {this.state.buildFile ? 
      <div style={{borderBottom:"solid 1px lightgray", marginBottom:"20px"}}>
        {this.state.loading ? 
        <div id="build-upload-progress-container">
          <img id="upload-modal-complete" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Checkmark.svg"></img>
          <div id="build-modal-spinner"/>
          <div style={{display:"inline-block", verticalAlign:"19px", marginLeft:"8px"}}>
            <p id="upload-progress-title">{this.state.buildFile.name}</p>
            <span style={{display:"block", maxWidth:"300px", fontSize:"10pt"}}>
              Uploading <span id="build-upload-percentage">0</span>% 
              <span style={{whiteSpace:"pre"}}>{"   - "}</span>
              <span style={{fontStyle:"italic"}}>{(this.state.buildFile.size / 1000000).toFixed(1)} MB</span>
            </span>
          </div>
        </div> : 
        <span id="build-upload-file-container">
          <span style={{display:"inline-block", maxWidth:"247px", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>{this.state.buildFile.name}</span>
          <span style={{whiteSpace:"pre"}}>{"  - "}</span><span style={{fontStyle:"italic"}}>{(this.state.buildFile.size / 1000000).toFixed(1)} MB</span>
          <p onClick={this.removeFile} id="upload-build-remove">x</p>
        </span>
      }  
      </div>
      : 
      <div id="build-drop-container">
        <input title="" className="color first-upload-input" style={{ opacity:"0"}} id="build-modal-file" type="file" accept=".ipa,.apk,.app" ref="file" onChange={this.onFileChange} multiple />
        <div id="file-wrapper" style={{paddingRight:"4px", marginTop:"-22px"}}>
          <img src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Upload_gray.svg" alt=""/>
          <p>
            Drop files to upload <br/> or <span style={{color:"#519acc", cursor:"pointer"}}>browse</span>
          </p>
        </div>
      </div>}
      <BasicSelect
        options={[
          {label: "10 minutes", value: "10_minutes"},
          {label: "1 hour", value: "1_hour"},
          {label: "3 hours", value: "3_hours"},
          {label: "6 hours", value: "6_hours"},
          {label: "12 hours", value: "12_hours"},
          {label: "24 hours", value: "24_hours"},
          {label: "1 week", value: "1_week"},
          {label: "2 weeks", value: "2_weeks"}
        ]}
        onChange={this.onAvailabilityChange} 
        selectedOption={this.state.availability}
        id="2"
        width="150px"
        margin="0px 0px 13px 0px"
        // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
        
      />
      <span className="common-input-label">Password</span>
      <input ref="build_password" type="password" placeholder="Optional" autoComplete="new-password" className="common-input" maxLength={25} style={{width:"100%"}} />
      <span className="common-input-label">What's new with this build?</span>
      <textarea placeholder="Describe what changes are present in this build." className="common-textarea" maxLength={2000} ref="build_new_text" style={{width:"100%"}}/>
      <button 
        className="btn right" 
        disabled={this.state.loading || this.state.buildFile === null} 
        onClick={this.uploadBuild}
      > 
        Submit
      </button>
    </div>
    )
  }

}
export default UploadBuild