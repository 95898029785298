import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import axios from "axios";
import BuildsList from "./builds_list.jsx";
import ErrorBoundary from "../common/error_boundary.jsx";
import Switcher from "../common/switcher.jsx";
import SubNavbar from "../common/sub_navbar.jsx";
import Shake from "../common/shake.jsx";
import IntegrationsContainer from "./integrations_container.jsx";
import FirstBuildFlow from "./first_build_flow.jsx";
import ReactModalV2 from "../modals/react_modal_v2";
import UploadBuild from "../modals/upload_build.jsx";

const modals = {
  "upload-build": UploadBuild,
};

class BuildsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProjectId: this.props.project_id,
      currentProject:
        this.props.currentProject != undefined ? this.props.currentProject : "",
      currentApplicationId: this.props.application_id,
      currentPage: 0,
      expanded:
        sessionStorage.getItem("expanded") === "true"
          ? true
          : sessionStorage.getItem("expanded") !== "false",
      flowCompleted: false,
      skipFlow: false,
      data: [],
      modalOpen: false,
      loaded: false,
      currentModal: "",
      modalData: {},
      userBuildFlowComplete: this.props.currentUser.build_flow_complete,
    };
  }

  openUploadModal = () => {
    this.setModal(true, "upload-build", {
      downloadBuilds: this.downloadBuilds,
      flowCompleted: this.state.userBuildFlowComplete,
      skipFlow: this.skipFlow,
      application_id: this.state.currentApplicationId,
      project_id: this.state.currentProjectId,
      customClass: "upload-build-modal",
    });
  };

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  componentDidMount() {
    this.downloadBuilds();
  }

  switcherHandler = (value) => {
    if (
      !this.state.userBuildFlowComplete &&
      this.state.flowCompleted &&
      value !== 0
    ) {
      setTimeout(() => {
        this.setState({
          userBuildFlowComplete: true,
        });
      }, 500);
    }
    this.setState({ currentPage: value });
  };

  dropdownHandler = (id) => {
    var id = id;
    const url = `/v1/projects/${id}/builds_project.json`;
    axios
      .get(url)
      .then((res) => {
        window.history.pushState(
          "page2",
          "Test Platform",
          `/projects/${id}/builds`
        );
        this.setState(
          {
            currentProjectId: id,
            currentProject: res.data,
            currentApplicationId: res.data.application.id,
          },
          () => {
            this.downloadBuilds();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  flowCompleted = () => {
    this.setState({ flowCompleted: true });
  };

  skipFlow = () => {
    this.setState({ skipFlow: true });
  };

  downloadBuilds = () => {
    this.setState({ loaded: false });
    const params = {};
    if (this.state.currentProjectId) {
      params.project_id = this.state.currentProjectId;
    }
    $.ajax({
      url: "/v1/builds",
      dataType: "json",
      cache: false,
      data: params,
      success: function (data) {
        this.setState({ data, filteredData: data });
        setTimeout(() => {
          this.setState({ loaded: true });
        }, 700);
      }.bind(this),
      error(xhr, status, err) {
        console.error(status, err.toString());
      },
    });
  };

  hideXScrollBar = () => {
    document.querySelector("body").style.overflowX = "hidden";
  };

  showXScrollBar = () => {
    document.querySelector("body").style.overflowX = "auto";
  };

  render() {
    return (
      <div>
        <ReactModalV2
          customClass="upload-build-modal"
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ padding: "10px 25px" }}>
            <SubNavbar
              center
              iconStyle={{ width: "45px", height: "45px" }}
              icon="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Builds_Filled.svg"
              containerStyle={{
                height: "68px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              pageName={
                <span className="large-title" style={{ marginLeft: "5px" }}>
                  Builds
                </span>
              }
              sectionThreeChildren={[
                <Switcher
                  key={1}
                  classes="col s12 builds-switcher"
                  activeValue={this.state.currentPage}
                  switcherOptions={["Builds", "Integrations", "Shake"]}
                  handlerFunction={this.switcherHandler}
                />,
              ]}
            />
            <div
              style={{
                marginLeft: "-25px",
                marginTop: "12px",
                width: "calc(100% + 50px)",
                height: "1px",
                backgroundColor: "#e0e0e0",
              }}
            />
            <div style={{ paddingTop: "20px" }} id="buildsContainer">
              <SwitchTransition>
                <CSSTransition
                  key={this.state.currentPage}
                  timeout={{
                    appear: 300,
                    enter: 390,
                    exit: 390,
                  }}
                  onExit={this.hideXScrollBar}
                  onEntered={this.showXScrollBar}
                  classNames="transition"
                >
                  <ErrorBoundary>
                    {this.state.currentPage == 0 ? (
                      <div>
                        {!this.state.userBuildFlowComplete &&
                          !this.state.skipFlow && (
                            <FirstBuildFlow
                              flowCompleted={this.flowCompleted}
                              downloadBuilds={this.downloadBuilds}
                              project_id={this.state.currentProjectId}
                              application_id={this.state.currentApplicationId}
                            />
                          )}
                        <div
                          style={{ paddingLeft: "0px" }}
                          id="buildsContainerUpload"
                          className="col s12"
                        >
                          {!this.state.flowCompleted &&
                            !this.state.userBuildFlowComplete &&
                            !this.state.skipFlow && (
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "10pt",
                                  marginTop: "15px",
                                  marginLeft: "10px",
                                }}
                              >
                                Know what to do? Skip the walkthrough and upload
                                your first build here.
                              </span>
                            )}
                          <div
                            onClick={this.openUploadModal}
                            id="build-icon-box"
                            style={{
                              marginLeft: "9px",
                              cursor: "pointer",
                              height: "34px",
                              width: "140px",
                              marginBottom: "10px",
                              marginTop: this.state.flowCompleted
                                ? "10px"
                                : this.state.userBuildFlowComplete ||
                                  this.state.skipFlow
                                ? "-10px"
                                : "0px",
                            }}
                          >
                            <img
                              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Upload.svg"
                              height="40"
                              width="40"
                            />
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#519acc",
                                verticalAlign: "14px",
                              }}
                            >
                              Upload a Build
                            </span>
                          </div>
                        </div>
                        <div
                          id="buildsContainerList"
                          style={{ paddingLeft: "0px" }}
                          className="col s12"
                        >
                          <BuildsList
                            loaded={this.state.loaded}
                            data={this.state.data}
                            currentUser={this.props.currentUser}
                            project_id={this.state.currentProjectId}
                            application_id={this.state.currentApplicationId}
                          />
                        </div>
                      </div>
                    ) : this.state.currentPage == 1 ? (
                      <IntegrationsContainer
                        currentUser={this.props.currentUser}
                        integrations={this.props.integrations}
                        application={this.props.application}
                      />
                    ) : (
                      <Shake />
                    )}
                  </ErrorBoundary>
                </CSSTransition>
              </SwitchTransition>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuildsContainer;
