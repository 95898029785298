import React from "react";
import Select, { createFilter } from "react-select";
import TaskSelectStyles from "./task_select_styles";
import MultiSelectStyles from "./multi_select_styles";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import {
  labelInput,
  CustomValueContainer,
  DropdownIndicator,
  customMultiControl,
} from "./select_utils";

const baseFilterOption = (candidate, input) => {
  return candidate.data.__isNew__ || candidate.label.includes(input);
};

class MultiSelectWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.onSelectKeyDown = this.onSelectKeyDown.bind(this);
    this.heightMath = this.heightMath.bind(this);
  }

  onSelectKeyDown(e) {
    //simplify readout of options for screen readers
    if (e.keyCode === 38 || e.keyCode === 40) {
      setTimeout(() => {
        var elem = document.querySelector(
          ".react-select__menu .react-select__option--is-focused"
        );
        if (elem) {
          var list =
            this.selectRef.current.select.state.menuOptions.focusable.map(
              (i) => i.label
            );
          var index = list.indexOf(elem.innerText);
          CustomAriaAlert(
            `option ${elem.innerText}. ${index + 1} of ${list.length} options`
          );
        }
      }, 50);
    } else if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  }

  formatCreateLabel(inputValue) {
    return (
      <div className="no-option-msg">
        <p>No tags found for "{inputValue}"</p>
        <p className="base-color">Create New Tag</p>
      </div>
    );
  }

  heightMath() {
    if (this.props.tagStyle) {
      return `${
        this.props.count && this.props.count == false
          ? "unset"
          : (this.props.value.length / 2.2) * 30 + 42 >= 250
          ? "250"
          : (this.props.value.length / 2.2) * 30 + 42 < 120
          ? 120
          : (this.props.value.length / 2) * 30 + 42
      }px`;
    } else {
      return `${
        this.props.count && this.props.count == false
          ? "unset"
          : (this.props.value.length / 2) * 50 + 100 >= 250
          ? "250"
          : (this.props.value.length / 2) * 50 + 110
      }px`;
    }
  }

  render() {
    return (
      <div
        style={{
          minHeight: this.props.heightMath
            ? this.props.heightMath
            : this.heightMath(),
          maxHeight: "250px",
        }}
        className="select-aria-class col s12"
      >
        <AutoSizer>
          {({ width, height }) => (
            <div
              style={{
                borderRight: this.props.divider ? "1px solid lightgrey" : "",
                width: `${width}px`,
                transition: "min-height .5s",
              }}
            >
              {this.props.label}
              <Select
                classNamePrefix="react-multi-select"
                isMulti
                menuShouldScrollIntoView
                required={this.props.required}
                name={this.props.name}
                ref={this.selectRef}
                options={this.props.options}
                onChange={this.props.onChange}
                aria-label={this.props.ariaLabel}
                placeholder={this.props.placeholder}
                blurInputOnSelect={
                  this.props.blurInputOnSelect
                    ? this.props.blurInputOnSelect
                    : false
                }
                onKeyDown={this.onSelectKeyDown}
                isSearchable={
                  this.props.isSearchable ? this.props.isSearchable : false
                }
                defaultValue={
                  this.props.defaultValue ? this.props.defaultValue : undefined
                }
                value={this.props.value}
                className={this.props.className ? this.props.className : ""}
                isDisabled={this.props.isDisabled}
                filterOption={createFilter({ ingnoreCase: true })}
                isOptionDisabled={this.props.isOptionDisabled}
                isClearable={false}
                components={{
                  valueContainer: CustomValueContainer,
                  Input: labelInput,
                  DropdownIndicator: DropdownIndicator,
                  Control: customMultiControl,
                }}
                closeMenuOnSelect={false}
                styles={
                  this.props.tagStyle
                    ? {
                        ...MultiSelectStyles,
                        valueContainer: (styles, state) => ({
                          ...styles,
                          paddingTop: state.hasValue ? "10px" : "",
                          order: 2,
                          top: state.hasValue ? "30px" : "",
                          left: state.hasValue ? "-10px" : "",
                          position: state.hasValue ? "absolute" : "",
                          width: width,
                          padding: "0 0 0 10px",
                          overflow: state.hasValue ? "auto" : "",
                          display: "flex",
                          maxHeight: this.props.maxHeight
                            ? this.props.maxHeight
                            : "150px",
                          marginTop: "15px",
                        }),
                      }
                    : {
                        ...TaskSelectStyles,
                        valueContainer: (styles, state) => ({
                          ...styles,
                          paddingTop: state.hasValue ? "10px" : "",
                          order: 2,
                          top: state.hasValue ? "30px" : "",
                          left: state.hasValue ? "-10px" : "",
                          position: state.hasValue ? "absolute" : "",
                          width,
                          padding: "0 0 0 10px",
                          overflow: state.hasValue ? "auto" : "",
                          display: "flex",
                          maxHeight: this.props.maxHeight
                            ? this.props.maxHeight
                            : "200px",
                          marginTop: "15px",
                        }),
                      }
                }
              />
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
}

MultiSelectWrapper.defaultProps = {
  customStyles: {},
  menuPlacement: "auto",
};

export default MultiSelectWrapper;
