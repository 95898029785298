import React from "react";
import PropTypes from "prop-types";

const WikiCategoryDevs = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 30"
    >
      <path
        d="M37.4795 0.240234H2.55952C1.39949 0.240234 0.439453 1.20027 0.439453 2.3603V27.6003C0.439453 28.7603 1.39949 29.7204 2.55952 29.7204H37.4795C38.6396 29.7204 39.5996 28.7603 39.5996 27.6003V2.3603C39.5996 1.20027 38.6396 0.240234 37.4795 0.240234ZM12.9595 2.44023H27.0795C27.3996 2.44023 27.6396 2.68021 27.6396 3.00027C27.6396 3.32033 27.3996 3.5603 27.0795 3.5603H12.9595C12.6395 3.5603 12.3995 3.32033 12.3995 3.00027C12.3995 2.68021 12.6395 2.44023 12.9595 2.44023ZM6.83952 2.44023C7.15957 2.44023 7.39955 2.68021 7.39955 3.00027C7.39955 3.32033 7.15958 3.5603 6.83952 3.5603C6.51947 3.5603 6.27949 3.32033 6.27949 3.00027C6.27949 2.68021 6.51959 2.44023 6.83952 2.44023ZM4.95959 2.44023C5.27964 2.44023 5.51962 2.68021 5.51962 3.00027C5.51962 3.32033 5.27965 3.5603 4.95959 3.5603C4.63953 3.5603 4.39955 3.32033 4.39955 3.00027C4.39955 2.68021 4.6795 2.44023 4.95959 2.44023ZM3.11962 2.44023C3.43967 2.44023 3.67965 2.68021 3.67965 3.00027C3.67965 3.32033 3.43968 3.5603 3.11962 3.5603C2.79957 3.5603 2.55959 3.32033 2.55959 3.00027C2.55959 2.68021 2.79956 2.44023 3.11962 2.44023ZM38.4796 27.6402C38.4796 28.2003 38.0397 28.6402 37.4796 28.6402H2.55962C1.99959 28.6402 1.55962 28.2003 1.55962 27.6402V5.76023H38.5196L38.5198 27.6402H38.4796Z"
        fill="#F4F4F4"
      />
      <path
        d="M15.2393 19.5201L12.3194 17.48L15.2393 15.44L14.2393 14.04L9.31934 17.48L14.2393 20.92L15.2393 19.5201Z"
        fill="#F4F4F4"
      />
      <path
        d="M23.3597 11H21.3996L16.6396 23.4H18.5597L23.3597 11Z"
        fill="#F4F4F4"
      />
      <path
        d="M25.7598 20.92L30.6798 17.48L25.7598 14.04L24.7598 15.44L27.6797 17.48L24.7598 19.5201L25.7598 20.92Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};

WikiCategoryDevs.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

WikiCategoryDevs.defaultProps = {
  height: "30px",
  width: "40px",
  svgStyles: {},
  color: "#f4f4f4",
};

export default WikiCategoryDevs;
