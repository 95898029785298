import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { components } from "react-select";
import {
  SearchableSelect,
  DropdownLabel,
  DropdownIconContainer,
  DropdownIconPlaceholder,
  DropdownName,
} from "../styles";

const SingleValue = (props) => {
  /* eslint-disable react/prop-types */
  const { data } = props;
  const { project } = data;
  const { name } = project;
  /* eslint-enable react/prop-types */

  return (
    <components.SingleValue {...props}>
      <Label>
        <ProjectName>{name}</ProjectName>
      </Label>
    </components.SingleValue>
  );
};

const PerformanceProjectDropdown = ({
  onProjectSelect,
  projectsList,
  queryParams,
}) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectSearchValue, setProjectSearchValue] = useState("");

  const handleInputChange = (value) => {
    setProjectSearchValue(value);
  };

  const options = useMemo(() => {
    if (!projectsList || projectsList.length === 0) {
      return [];
    }
    return projectsList.map((project) => ({
      project,
      value: project.id,
      label: (
        <Label>
          <IconContainer>
            <IconPlaceholder $color={project.color || "#519acc"}>
              {project.identifier || project.name[0]}
            </IconPlaceholder>
          </IconContainer>
          <ProjectName>{project.name}</ProjectName>
        </Label>
      ),
    }));
  }, [projectsList]);

  const filterSearch = (option, inputValue) => {
    if (!inputValue || inputValue.length === 0) {
      return true;
    }

    const { project } = option.data;
    const { identifier, name } = project;
    const checkValue = `${identifier} ${name}`.toLowerCase();
    return checkValue.includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    // on fetch status 200, set selected project based on id returned in queryParams
    const projectId = parseInt(queryParams.project_id, 10);
    if (projectId !== selectedProject?.value) {
      setSelectedProject(options.find((option) => option.value === projectId));
    }
  }, [queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!projectsList || projectsList.length === 0) {
    return null;
  }

  return (
    <ProjectSelectContainer>
      <SearchableSelect
        aria-label="Project lookup field, filter performance dashboard view by project"
        placeholder="All projects"
        classNamePrefix="Select"
        onChange={(option) => {
          setSelectedProject(option || null);
          onProjectSelect(option?.value || null);
        }}
        value={selectedProject}
        isSearchable
        isClearable
        isMulti={false}
        options={options}
        components={{ SingleValue }}
        inputValue={projectSearchValue}
        filterOption={filterSearch}
        onInputChange={handleInputChange}
        menuPortalTarget={document.querySelector("body")}
        menuPlacement="auto"
        menuPosition="fixed"
        menuShouldScrollIntoView={false}
        styles={{
          placeholder: (base, state) => ({
            ...base,
            display: state.isFocused || state.hasValue ? "none" : "block",
          }),
          menu: (base) => ({
            ...base,
            width: "328px",
            right: 0,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#519acc",
          },
        })}
      />
    </ProjectSelectContainer>
  );
};

const ProjectSelectContainer = styled.div``;

const Label = styled(DropdownLabel)``;

const IconContainer = styled(DropdownIconContainer)``;

const IconPlaceholder = styled(DropdownIconPlaceholder)``;

const ProjectName = styled(DropdownName)``;

PerformanceProjectDropdown.propTypes = {
  onProjectSelect: PropTypes.func.isRequired,
  projectsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
      identifier: PropTypes.string,
    })
  ).isRequired,
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
  }),
};

export default PerformanceProjectDropdown;
