import React, { useContext } from "react";
import { DeviceLabContext } from "@components/devices/device_lab";
import { deviceTypes } from "@types";

const EditDeviceCell = ({ device }) => {
  const { openDeviceModal } = useContext(DeviceLabContext);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        type="button"
        className="transparent-button"
        onClick={() => openDeviceModal(device)}
      >
        <i
          style={{ fontSize: "18px", cursor: "pointer", marginTop: "5px" }}
          className="material-icons"
        >
          create
        </i>
      </button>
    </div>
  );
};

EditDeviceCell.propTypes = {
  device: deviceTypes,
};

export default EditDeviceCell;
