import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import UserCard from '../users/user_card.jsx'
import { CSSTransition } from 'react-transition-group';

class UserImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: 0
    };
  }

  setActiveCard = (e) => {
    if (this.props.activity) {
      this.props.activeCardHandler(this.props.activity.id);
    } else {
      var rect = e.target.getBoundingClientRect()
      var scrollOffset = e.target.parentElement.parentElement.scrollTop;
      var width = rect.left - (100)
      this.setState({ scrollOffset: scrollOffset, left: width, top: rect.top + 40});
      this.props.activeCardHandler(this.props.user.id);
    }
  }

  renderCard = () => {
    var elem = document.querySelector("#activityOuter");
    return ReactDOM.createPortal(
      <CSSTransition
        in={this.props.active}
        appear={true}
        exit={true}
        unmountOnExit
        timeout={{
          appear: 300,
          enter: 300,
          exit: 300,
        }}
        classNames="trans"
      >
        <UserCard
          closeUser={this.props.closeUser}
          className="trans rightArrow"
          user={this.props.user}
          top={0}
          left={-250}
        />
      </CSSTransition>,
      elem
    );
  }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(
      ([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(
        ([key, val]) =>
          prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }
  }

  render() {
    var elem = document.querySelector("#activityOuter");

    return (
      <>
        <img
          alt="User avatar"
          className="circle userImg"
          onClick={this.setActiveCard}
          src={
            this.props.url != null
              ? this.props.url
              : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
          }
          style={{ cursor: `${this.props.showCard ? "pointer" : ""}` }}
        />
        {this.props.showName && this.props.name && (
          <span>{this.props.name}</span>
        )}
        {this.props.showCard &&
          this.props.active &&
          elem != undefined &&
          this.props.portal != true && (
            <CSSTransition
              in={this.props.active}
              appear={true}
              exit={true}
              unmountOnExit
              timeout={{
                appear: 300,
                enter: 300,
                exit: 300,
              }}
              classNames="trans"
            >
              <UserCard
                closeUser={this.props.closeUser}
                className="trans downArrow"
                user={this.props.user}
                top={this.state.top}
                left={this.state.left}
              />
            </CSSTransition>
          )}
        {this.props.showCard &&
          this.props.active &&
          this.props.activity != undefined &&
          elem != undefined &&
          this.renderCard()}
      </>
    );
  }
}

export default UserImage

UserImage.propTypes = {
  active: PropTypes.bool,
  activity: PropTypes.object,
  showCard: PropTypes.bool,
  user: PropTypes.object,
  closeUser: PropTypes.func,
  portal: PropTypes.bool,
  url: PropTypes.string,
  activeCardHandler: PropTypes.func
};