import * as yup from "yup";

const [SINGLE, MULTIPLE] = [
  "single_answer",
  "multiple_answer",
  "written_answer",
];

yup.addMethod(yup.object, "questionHasValidResponses", function (message) {
  return this.test("questionHasValidResponses", message, function (value) {
    const { path, createError } = this;
    const { question_type, options } = value;

    if (question_type === SINGLE || question_type === MULTIPLE) {
      // question must have between 2 and 4 options
      if (options.length < 2 || options.length > 4)
        return createError({
          path,
          message: message || "Question must have between 2 and 4 options.",
        });

      // question cannot have keywords, reserved for written answer questions
      if (value.keywords) {
        return createError({
          path,
          message: message || "Question cannot have keywords.",
        });
      }
    }

    if (question_type === SINGLE) {
      // single answer question must have exactly one correct response
      const hasCorrectAnswer = options.some((option) => option.correct);
      if (!hasCorrectAnswer)
        return createError({
          path,
          message:
            message || "Question must have exactly one correct response.",
        });

      // single answer question must not have empty options
      const hasEmptyOptions = options.some(
        (option) => !option.answer || option.answer === ""
      );
      if (hasEmptyOptions)
        return createError({
          path,
          message: message || "Question cannot have empty options.",
        });

      return true;
    }

    if (question_type === MULTIPLE) {
      // multiple answer question must have at least one correct response and one incorrect response
      const hasCorrectAnswer = options.some((option) => option.correct);
      const hasIncorrectAnswer = options.some((option) => !option.correct);
      if (!hasCorrectAnswer || !hasIncorrectAnswer) {
        return createError({
          path,
          message:
            message ||
            "Question must have at least one correct response and one incorrect response.",
        });
      }

      return true;
    }

    // written answer must not have options
    const hasOptions = options && options.length > 0;
    if (hasOptions)
      return createError({
        path,
        message: message || "Question cannot have options.",
      });

    // written answer must have keywords
    const hasKeywords = value.keywords && value.keywords.length > 0;
    if (!hasKeywords)
      return createError({
        path,
        message: message || "Question must have correct keywords.",
      });

    return true;
  });
});

const schema = yup.object().shape({
  introduction: yup
    .string()
    .required("An assessment introduction is required.")
    .max(240, "The introduction must be less than 240 characters."),
  questions: yup.array().of(
    yup
      .object()
      .shape({
        id: yup.number(),
        question_type: yup
          .string()
          .oneOf(["single_answer", "written_answer", "multiple_answer"])
          .required(),
        description: yup
          .string()
          .required("Question text is required.")
          .max(240, "The question text must be less than 240 characters."),
        options: yup.array().of(
          yup.object().shape({
            id: yup.number(),
            answer: yup
              .string()
              .required("Option text cannot be empty.")
              .max(140, "The option text must be less than 140 characters."),
            correct: yup.boolean().required(),
          })
        ),
        keywords: yup
          .string()
          .max(240, "The keywords must be less than 240 characters."),
      })
      .questionHasValidResponses()
  ),
  delete_questions: yup
    .array()
    .of(yup.object().shape({ id: yup.number(), _destroy: yup.boolean() })),
  delete_answers: yup
    .array()
    .of(yup.object().shape({ id: yup.number(), _destroy: yup.boolean() })),
});

export default schema;
