import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import CustomValueContainer from "./CustomValueContainer";
import SingleValue from "./SingleValue";
import SingleSelectStyles from "../common/select_styles";
import { dropdownIndicator, customControl } from "../common/select_utils";

const ProjectDropdown = ({
  project,
  projectOptions,
  projectImage,
  handleProjectChange,
  expanded,
}) => {
  const [selectedProject, setSelectedProject] = useState(project);
  const [openMenu, setOpenMenu] = useState(false);

  const sideProjectRef = useRef(null);

  useEffect(() => {
    setSelectedProject(project);
  }, [project]);

  const handleMenuCloseDiff = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    const handleMouseLeave = () => {
      handleMenuCloseDiff();
    };

    const container = document.getElementById("sideProjectDropComponent");
    if (container) {
      container.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (container) {
        container.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const handleProjectClick = (p) => {
    setSelectedProject(p);
    handleProjectChange(p);
    const newPath = `/projects/${p.value}/dashboard`;
    window.history.replaceState({ path: newPath }, "", newPath);
    window.dispatchEvent(new PopStateEvent("popstate"));
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
    const menuElem = document.querySelector(".menuListContainer");
    if (menuElem) {
      menuElem.style.transitionDelay = "0.2s";
      menuElem.classList.remove("expand-project-menu");
      menuElem.classList.add("collapse-project-menu");
      const optionElems = document.querySelectorAll(
        ".menuListContainer .__option"
      );
      optionElems.forEach((elm, index) => {
        // eslint-disable-next-line no-param-reassign
        elm.style.transitionDelay = `${
          (0.3 / optionElems.length) * (optionElems.length - (index + 1))
        }s`;
        elm.classList.add("hide-menu-option");
      });
      setOpenMenu(false);
    }
  };

  const handleMenuOpen = () => {
    if (projectOptions.length >= 2) {
      setOpenMenu(true);
    }
  };

  const menuList = (props) => {
    if (openMenu) {
      const elem = document.querySelector(".menuListContainer");
      if (elem && elem.scrollTop === 56.3636360168457) {
        elem.scrollTop = 0;
      }
    }
    return (
      <components.MenuList
        className="new-scrollbar menuListContainer"
        {...props}
      >
        {
          // eslint-disable-next-line react/prop-types
          props ? props.children : ""
        }
      </components.MenuList>
    );
  };

  const onInputChange = () => {
    requestAnimationFrame(() => {
      const item = document.querySelector(".menuListContainer");
      if (item && openMenu) {
        item.classList.add("expand-project-menu");
        const optionElems = document.querySelectorAll(
          ".menuListContainer .__option"
        );
        optionElems.forEach((elm, index) => {
          const delay = (0.3 / optionElems.length) * index;
          const transformValue = "translateX(0px)";
          const opacityValue = "1";
          // eslint-disable-next-line no-param-reassign
          elm.style.transitionDelay = `${delay}s`;
          // eslint-disable-next-line no-param-reassign
          elm.style.transform = transformValue;
          // eslint-disable-next-line no-param-reassign
          elm.style.opacity = opacityValue;
        });
      }
    });
  };

  const options = projectOptions.filter((i) => i.value !== project.id);

  return (
    <div id="sideProjectDropComponent">
      <Select
        ref={sideProjectRef}
        classNamePrefix="select browser-default"
        aria-label="project dropdown for main sidebar"
        aria-haspopup="listbox"
        menuPosition="fixed"
        value={selectedProject}
        blurInputOnSelect
        styles={{
          ...SingleSelectStyles,
          menuPortal: (styles) => ({
            ...styles,
            left: "30px",
          }),
          container: (styles) => ({
            ...styles,
            fontFamily: "Arial",
            width: "215px",
            transition: "max-width .8s ease .1s",
            overflow: "hidden",
            maxWidth: expanded ? "215px" : " 0px",
            marginLeft: "30px",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "transparent",
          }),
          singleValue: (styles, state) => {
            return {
              ...styles,
              color: state.selectProps.isSearchable ? "inherit" : "#242424",
              maxWidth: "120px",
              left: "40px",
            };
          },
        }}
        onChange={handleProjectClick}
        onMenuClose={handleMenuClose}
        onMenuOpen={handleMenuOpen}
        options={options}
        hideSelectedOptions
        components={{
          DropdownIndicator: dropdownIndicator,
          // eslint-disable-next-line react/no-unstable-nested-components
          SingleValue: (props) => (
            <SingleValue
              {...props}
              project={selectedProject}
              projectImage={projectImage}
            />
          ),
          ValueContainer: CustomValueContainer,
          MenuList: menuList,
          Control: customControl,
        }}
        menuIsOpen={openMenu}
        onInputChange={onInputChange}
      />
    </div>
  );
};

const { bool, string, shape, number } = PropTypes;
ProjectDropdown.propTypes = {
  handleProjectChange: PropTypes.func.isRequired,
  projectImage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  project: shape({
    a11y_active: bool,
    active: bool,
    color: string,
    created_at: string,
    has_bugs: bool,
    has_test_cases: bool,
    icon_url: string,
    id: number,
    identifier: string,
    media: bool,
    name: string,
  }).isRequired,
  projectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  expanded: bool.isRequired,
};

export default ProjectDropdown;
