import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";

class ExportLinks extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      csvData: [],
      loading: false,
    };
  }

  generateCsv = async () => {
    var self = this;
    self.setState({ loading: true });
    var csvData = await axios
      .get(`/v1/projects/${self.props.project.id}/bugs`)
      .then((res) => {
        var bugs = res.data.tickets;
        var csvData = [];
        csvData.push([
          "Ticket ID",
          "Title",
          "Description",
          "Devices",
          "Browsers",
          "Status",
        ]);
        Object.keys(bugs).forEach(function (bug) {
          var value = bugs[bug];
          var devices = [];
          if (value.devices.length >= 1) {
            value.devices.map((x) => devices.push(x.label));
          }
          var browsers = [];
          if (value.browsers.length >= 1) {
            value.browsers.map((x) => browsers.push(x.label));
          }
          csvData.push([
            `${value.project.identifier}-${value.app_key}`,
            value.title == undefined ? "" : value.title,
            value.description == undefined ? "" : value.description,
            devices.join(", "),
            browsers.join(", "),
            value.ticket_status_name,
          ]);
        });
        self.setState({ csvData: csvData, loading: false });
      })
      .catch((err) => console.error(err));
    var elem = document.getElementById("csvLink");
    if (elem) {
      elem.click();
    }
  };

  render() {
    var date = moment().format("l");
    if (this.props.bugs.length >= 1) {
      return (
        <div id="exportLink" className="right">
          <div className="center m-font-sm">
            <p style={{ fontWeight: "600" }}>Export Bug Options</p>
            {this.state.loading ? (
              <div style={{ margin: "0 auto" }} id="form-update-loader"></div>
            ) : (
              <p style={{ textAlign: "right" }}>
                <button
                  style={{
                    width: "unset",
                    padding: "0",
                    fontWeight: 700,
                    color: "#039be5",
                  }}
                  className="btn link-btn bc-blue pointer bc-hover"
                  onClick={this.generateCsv}
                >
                  CSV
                </button>
                <CSVLink
                  id="csvLink"
                  className="bc-hover"
                  filename={`${
                    this.props.project != undefined
                      ? this.props.project.name
                      : ""
                  }_bugList_${date}.csv`}
                  data={this.state.csvData}
                  target="_blank"
                ></CSVLink>{" "}
                {/* |{" "}
              <a style={{paddingRight: "4px"}} href={`/projects/${this.props.project.id}/bugs_pdf_export.pdf`}>PDF</a> */}
                |{" "}
                <a
                  href={`/projects/${this.props.project.id}/ex_xlsx.xlsx`}
                  style={{ width: "unset", padding: "0" }}
                >
                  XLSX
                </a>
              </p>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ExportLinks;
