import React from "react";
import { string, number, arrayOf, func, oneOfType } from "prop-types";
import CheckboxMenu from "../common/checkbox_menu";

const ROLE_OPTIONS = ["tester", "lead", "admin"];

const VisibleRolesDropdown = ({ key, selectedRoles, onRoleSelect }) => {
  return (
    <CheckboxMenu
      name="filters"
      title="Roles Visible To"
      id="1"
      maxWidth="100%"
      key={key}
      count={selectedRoles.length}
      active
      options={ROLE_OPTIONS.map((r, index) => {
        if (r.split(" ").length > 1) {
          return {
            label: `${r} Permission`,
            value: r,
            handler: onRoleSelect,
            checked: selectedRoles.indexOf(r) !== -1,
            tabIndex: index + 1,
          };
        }
        return {
          label: r[0].toUpperCase() + r.slice(1),
          value: r,
          handler: onRoleSelect,
          checked: selectedRoles.indexOf(r) !== -1,
          tabIndex: index + 1,
        };
      })}
      containerMinWidth="160px"
      menuStyles={{
        maxWidth: "255px",
        width: "255px",
        right: "-44px",
      }}
      narrow
      icons={[
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
      ]}
    />
  );
};

VisibleRolesDropdown.propTypes = {
  selectedRoles: arrayOf(string),
  onRoleSelect: func,
  key: oneOfType([string, number]),
};

VisibleRolesDropdown.defaultProps = {
  selectedRoles: [],
  onRoleSelect: () => null,
  key: "user-permissions-dropdown",
};

export default VisibleRolesDropdown;
