import React from "react";

const CustomDate = (props) => {
  return (
    <svg
      id="Icons_Custom_date_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Icons_Custom_date_svg__cls-1,.Icons_Custom_date_svg__cls-2{fill:#414042}.Icons_Custom_date_svg__cls-2{opacity:.33}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={21.04}
        y={24.57}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={13.37}
        y={24.57}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={29.19}
        y={24.57}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={37.37}
        y={24.57}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={45.55}
        y={24.57}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={21.04}
        y={31.35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={13.37}
        y={31.35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={29.19}
        y={31.35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-2"
        x={21.04}
        y={38.12}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-2"
        x={13.37}
        y={38.12}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-2"
        x={29.19}
        y={38.12}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-2"
        x={37.37}
        y={38.12}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-2"
        x={45.55}
        y={38.12}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={37.37}
        y={31.35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Custom_date_svg__cls-1"
        x={45.55}
        y={31.35}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
    </svg>
  );
};

export default CustomDate;
