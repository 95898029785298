import { string, number, bool, shape, arrayOf } from "prop-types";

export default shape({
  active: bool,
  additional_permissions: arrayOf(string),
  avatar_image: string,
  build_flow_complete: bool,
  email: string,
  id: number,
  name: string,
  onboarded: bool,
  project_count: number,
  role: string,
  slack_id: string,
  teams: arrayOf(
    shape({
      active: bool,
      application_id: number,
      created_at: string,
      id: number,
      name: string,
      owner_id: number,
      updated_at: string,
    })
  ),
});
