import React from "react";
import styled from "styled-components";

const AssessmentsSectionToggle = () => {
  const PATHS = {
    wiki: {
      path: "/wiki/",
      isActive: window.location.pathname.match(/^\/wiki((\/\w+)+|\/?)$/g),
      // regexp
    },
    assessments: {
      path: "/wiki-assessments/",
      isActive: window.location.pathname.match(
        /^\/wiki-assessments((\/\w+)+|\/?)$/g
      ),
    },
  };

  return (
    <AssessmentsSectionToggleContainer>
      <ToggleButton href="/wiki" $isActive={PATHS.wiki.isActive}>
        Wiki Content
      </ToggleButton>
      <ToggleButton
        href="/wiki-assessments"
        $isActive={PATHS.assessments.isActive}
      >
        Wiki Assessments
      </ToggleButton>
    </AssessmentsSectionToggleContainer>
  );
};

const AssessmentsSectionToggleContainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleButton = styled.a`
  color: ${({ $isActive }) => ($isActive ? "#519acc" : "#242424")};
  margin-left: 26px;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--primary-blue, #519acc);
    opacity: ${({ $isActive }) => ($isActive ? "1" : "0")};
    transition: opacity 0.3s ease;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`;

export default AssessmentsSectionToggle;
