import React from 'react'
import TestPassWidgetContainer from '../test_cases/dashboard/test_pass_widget_container'

function TestPassRow(props) {
  
  return (
    <div style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "center",
      maxHeight: "400px",
      margin: "5px 0",
      height: "100%",
      flex: 1
    }}  
    className="dash-row-2"
    >
      <div style={{display: "flex", flex: 1, height: `100%`, maxHeight: "400px"}}>
        <div style={{flex: 1}}>
        <TestPassWidgetContainer
          currentUser={props.currentUser}
          projectId={props.project.id}
          testPassCount={props.testPassCount}
        />
        </div>
      </div>
    </div>
    );
}

export default TestPassRow