import React from 'react'

class AboutMeIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
        aria-hidden="true"
      >
        <path d="M63.64,5.8a41.65,41.65,0,1,0,41.75,41.54A41.84,41.84,0,0,0,63.64,5.8Zm0,75a33.65,33.65,0,0,1-19.29-6.15c7.42-2.75,11-5.93,12.93-8.69a16.11,16.11,0,0,0,5.72.85h.64a18.49,18.49,0,0,0,6.14-1.06c1.7,2.76,5.51,5.94,12.93,8.9A31.55,31.55,0,0,1,63.64,80.83Zm-11-40.69h0C53,29.75,60,28.69,63,28.69h.85c3,0,10,1.06,10.38,11.24v.21c.22,3,.22,11.44-3.6,15.89-1.7,1.91-4,2.76-7,3h-.22a9,9,0,0,1-7-3C52.19,51.58,52.19,43.1,52.62,40.14Zm36.87,28.4c-8.69-2.76-11.87-5.73-12.93-7.21C83.35,53.7,82.29,41,82.08,39.5c-.64-14-10.39-19.07-18.66-19.07h-.84c-8.06,0-17.81,4.87-18.65,19.07-.22,1.48-1.06,14.41,5.72,22,0,.21.21.21.21.43-1.06,1.27-4.24,4-12.72,6.78A33.47,33.47,0,0,1,63.21,14.28c18.44,0,33.91,14.62,33.91,33.06A32.45,32.45,0,0,1,89.49,68.54Zm3.18,38.15H34.6a4,4,0,1,1,0-8.06H92.88a4,4,0,0,1,4,4A4.08,4.08,0,0,1,92.67,106.69Zm-10.17,8a4,4,0,0,1,4,4,4.16,4.16,0,0,1-4,4H44.77a4,4,0,0,1-4-4,4.16,4.16,0,0,1,4-4Z"/>
      </svg>
    );
  }
}
AboutMeIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default AboutMeIcon