import React from "react";

const SkillsFilled = (props) => {
  return (
    <svg
      id="Icons_Skills_-_Side_Nav_filled_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Icons_Skills_-_Side_Nav_filled_svg__cls-1{fill:#519acc;stroke:#519acc;stroke-miterlimit:10;stroke-width:.5px}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <path
        className="Icons_Skills_-_Side_Nav_filled_svg__cls-1"
        d="M35.82 50.2a.78.78 0 01-.46-.14l-3.75-2.73-3.74 2.73a.8.8 0 01-.91 0 .77.77 0 01-.28-.87l1.43-4.4-3.75-2.73a.77.77 0 01.45-1.39h4.64l1.43-4.41a.76.76 0 01.73-.53.77.77 0 01.74.53l1.43 4.41h4.63a.77.77 0 01.46 1.39l-3.75 2.73 1.43 4.4a.79.79 0 01-.28.87.73.73 0 01-.45.14zm-4.21-4.59a.79.79 0 01.46.15l2.28 1.65-.87-2.68a.75.75 0 01.28-.86L36 42.21h-2.78a.75.75 0 01-.73-.53L31.61 39l-.87 2.68a.76.76 0 01-.73.53h-2.82l2.28 1.66a.77.77 0 01.28.86l-.87 2.68 2.28-1.65a.76.76 0 01.45-.15z"
      />{" "}
      <path
        className="Icons_Skills_-_Side_Nav_filled_svg__cls-1"
        d="M31.61 55.36a11.46 11.46 0 1111.46-11.45 11.47 11.47 0 01-11.46 11.45zm0-21.36a9.91 9.91 0 109.91 9.91A9.92 9.92 0 0031.61 34z"
      />{" "}
      <path
        className="Icons_Skills_-_Side_Nav_filled_svg__cls-1"
        d="M38.57 35.2H37v-4.38H26.2v4h-1.54v-4.77a.77.77 0 01.77-.78H37.8a.77.77 0 01.77.78z"
      />{" "}
      <path
        className="Icons_Skills_-_Side_Nav_filled_svg__cls-1"
        d="M37.8 30.82H25.43a.77.77 0 01-.77-.77v-.8a.76.76 0 01.13-.44L38.63 9a.77.77 0 01.63-.33h11.45a.76.76 0 01.67.39.78.78 0 010 .78l-12.92 20.6a.77.77 0 01-.66.38zm-11.45-1.55h11l12-19.09h-9.68z"
      />{" "}
      <path
        className="Icons_Skills_-_Side_Nav_filled_svg__cls-1"
        d="M25.43 30h-.07a.78.78 0 01-.64-.45L12.58 9.73a.81.81 0 01.06-.73.76.76 0 01.65-.35h11.53a.76.76 0 01.71.47l7 10.62a.75.75 0 01-.08.75l-6.37 9.21a.77.77 0 01-.65.3zM14.49 10.18L25.57 27.7l5.35-7.76-6.61-9.76z"
      />{" "}
    </svg>
  );
};

export default SkillsFilled;
