import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


const Report = styled.tr` 
transition: background-color .5s;
&:hover {
  cursor: pointer;
  background-color: #519acc;
}
`

function EodReport(props) {
  
  function clickHandler() {
    props.handler(props.report.id)
  }

  const {id, user_name, submitted, total_bugs, hours, test_steps} = props.report
  return (
      <Report onClick={clickHandler} key={id}>
          <td>{user_name}</td>
          <td>{submitted}</td>
          <td>{total_bugs}</td>
          <td>{hours}</td>
          <td>{test_steps}</td>
      </Report>
    );
}

export default EodReport
EodReport.propTypes = {
  handler: PropTypes.func,
  report: PropTypes.shape({
    id: PropTypes.number,
    tester_id: PropTypes.number,
    user_name: PropTypes.string,
    submitted: PropTypes.string,
    hours: PropTypes.number,
    test_steps: PropTypes.number,
    total_bugs: PropTypes.number
  })
};

