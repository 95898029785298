import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import ReactGA from "react-ga";
import Select, { components, createFilter } from "react-select";
import Swal from "sweetalert2";
import AttachmentCarousel from "../tickets/attachment_carousel.jsx";
import TicketSelectStyles from "../common/ticket_select_styles";
import ErrorBoundary from "../common/error_boundary";
import GroupModal from "./group_modal";
import TicketAttachments from "./ticket_attachments.jsx";
import ReactModalV2 from "./react_modal_v2";
import TaskSelectStyles from "../common/task_select_styles.js";
import { TrixEditor } from "../common/trix_container.jsx";
import MultiSelectWrapper from "../common/multi_select_wrapper.jsx";
import MultiSelectStyles from "../common/multi_select_styles";
import {
  assigneeOption,
  dropdownIndicator,
  singleValue,
  assigneeSingleValue,
  groupHeading,
  customMultiControl,
  customMultiValueRemove,
  customControl,
} from "../common/select_utils";

const modals = {
  "group-modal": GroupModal,
  "ticket-attachments": TicketAttachments,
};

// import TrelloClient, { Trello } from 'react-trello-client'

ReactGA.initialize(`${process.env.G_ANALYTICS_TAG}`, {
  debug: !process.env.NODE_ENV === "production",
  titleCase: false,
});

const filterConfig = {
  stringify: (option) => (option.data.metaLabel ? option.data.metaLabel : ""),
};

class NewTicket extends React.Component {
  constructor(props) {
    super(props);
    this.priorityColors = ["#ff0000", "#ff9900", "#0066cc", "#009900"];
    this.priorityDotOptions = this.priorityColors.map((x) => (
      <span key={x} className="priority-span">
        <svg viewBox="-60 -50 200 255" preserveAspectRatio="xMinYMin meet">
          <circle cx="100" cy="100" r="60" fill={x} />
        </svg>
      </span>
    ));
    this.priorityOptions = [
      { value: 1, label: "Critical" },
      { value: 2, label: "High" },
      { value: 3, label: "Normal" },
      { value: 4, label: "Low" },
    ];
    const groups =
      this.props.data.project.groups != undefined
        ? this.props.data.project.groups
        : [];

    this.state = {
      selectedOptionPriority: this.priorityOptions[2],
      selectedOptionAssignee: { label: "Unassigned", value: 0 },
      selectedOptionWCAG: { label: "none", value: 0 },
      selectedOptionDevices: [],
      selectedOptionBrowsers: [],
      selectedOptionWatchers: [],
      selectedOptionLabels: [],
      modalOpen: false,
      watcherOptions: [
        { label: "Groups", options: groups },
        {
          label: "Watchers",
          options: this.props.data.watcherOptions.filter(
            (option) => option.value !== 0
          ),
        },
      ],
      userOptions: this.props.data.userOptions,
      content: "",
      title: "",
      watcherMenuOpen: false,
      groups: this.props.data.project.groups,
      files: [],
      draftAttachments: [],
      createAnother: false,
      addToTrello: false,
      deviceOptions: this.props.data.deviceOptions,
      browserOptions: this.props.data.browserOptions,
      labelOptions: this.props.data.ticketLabelOptions,
    };
    this.titleTimer = null;
    this.descriptionTimer = null;
    this.violationOptions = [
      { label: "none", value: 0 },
      ...this.props.data.violationOptions,
    ];
    this.handleEditorReady = this.handleEditorReady.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteable =
      this.props.data.currentUser.role === "admin" ||
      this.props.data.currentUser.role === "lead";
    this.labelOption = (props) => {
      return (
        <div className="ticket-label-option" style={{ position: "relative" }}>
          <components.Option {...props} />
          {this.deleteable && (
            <i
              onClick={this.deleteTicketLabel}
              style={{ position: "absolute", right: "5px", top: "10px" }}
              data-label-id={props.value}
              className="material-icons"
            >
              delete
            </i>
          )}
        </div>
      );
    };
    this.priorityOption = (props) => {
      return (
        <div>
          <components.Option {...props}>
            {props.data.label}
            {this.priorityDotOptions[props.data.value - 1]}
          </components.Option>
        </div>
      );
    };

    this.prioritySingleValue = (props) => {
      return (
        <div aria-hidden="true">
          <components.SingleValue {...props}>
            {props.data.label}
            {this.priorityDotOptions[props.data.value - 1]}
          </components.SingleValue>
        </div>
      );
    };
    this.deviceSelectRef = React.createRef();
    this.browserSelectRef = React.createRef();
    this.labelSelectRef = React.createRef();
    this.assigneeSelectRef = React.createRef();
  }

  componentDidMount() {
    document.querySelector("#ticketForm > h2").focus();
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    document.querySelector("body").style.overflow = "hidden";
    const devices = this.props.data.deviceOptions;
    this.ariaDeviceList = [
      ...devices[0].options.map((o) => o.label),
      ...devices[1].options.map((o) => o.label),
      ...devices[2].options.map((o) => o.label),
      ...devices[3].options.map((o) => o.label),
    ];
    try {
      if (this.props.data.currentUserDevices || this.props.data.topDevices) {
        const modifiedDeviceOptions = JSON.parse(
          JSON.stringify(this.props.data.deviceOptions)
        );
        modifiedDeviceOptions.unshift({ label: "Top Devices", options: [] });
        this.props.data.topDevices.forEach((device) => {
          let deviceFound = false;
          for (let x = 1; x < 5; x++) {
            for (let y = 0; y < modifiedDeviceOptions[x].options.length; y++) {
              if (modifiedDeviceOptions[x].options[y].value == device[0]) {
                modifiedDeviceOptions[0].options.push(
                  modifiedDeviceOptions[x].options[y]
                );
                modifiedDeviceOptions[x].options.splice(y, 1);
                deviceFound = true;
                break;
              }
            }
            if (deviceFound) {
              break;
            }
          }
        });
        modifiedDeviceOptions.unshift({ label: "Your Devices", options: [] });
        this.props.data.currentUserDevices.forEach((device) => {
          let deviceFound = false;
          for (let x = 2; x < 6; x++) {
            for (let y = 0; y < modifiedDeviceOptions[x].options.length; y++) {
              if (modifiedDeviceOptions[x].options[y].value === device.id) {
                modifiedDeviceOptions[0].options.push(
                  modifiedDeviceOptions[x].options[y]
                );
                modifiedDeviceOptions[x].options.splice(y, 1);
                deviceFound = true;
                break;
              }
            }
            if (deviceFound) {
              break;
            }
          }
        });
        this.setState({ deviceOptions: modifiedDeviceOptions });
      }
      if (this.props.data.draftData) {
        this.props.setBackdropLoader(true);
        axios
          .get(`/v1/bugs/${this.props.data.draftData.id}/ticket_data.json`)
          .then((res) => {
            this.props.setBackdropLoader(false);
            this.setState(
              {
                title: res.data.ticket.title,
                draftAttachments: res.data.ticket.ticket_attachments,
                selectedOptionDevices: res.data.ticket.devices,
              },
              () => {
                // this.titleRef.current.value = this.state.title
              }
            );
          })
          .catch((err) => {
            this.props.setBackdropLoader(false);
            console.error(err);
          });
      }
    } catch (err) {
      err.message = "New Ticket Modal Mount Error - suggested devices";
      console.error(err.message);
    }
    const state = {};
    if (localStorage.title && localStorage.title != "") {
      state.title = localStorage.title;
    }
    if (
      localStorage.selectedOptionLabels &&
      localStorage.selectedOptionLabels != ""
    ) {
      state.selectedOptionLabels = JSON.parse(
        localStorage.selectedOptionLabels
      );
    }

    if (
      localStorage.selectedOptionBrowsers &&
      localStorage.selectedOptionBrowsers != ""
    ) {
      state.selectedOptionBrowsers = JSON.parse(
        localStorage.selectedOptionBrowsers
      );
    }
    if (
      localStorage.selectedOptionDevices &&
      localStorage.selectedOptionDevices != ""
    ) {
      state.selectedOptionDevices = JSON.parse(
        localStorage.selectedOptionDevices
      );
    }
    if (
      localStorage.selectedOptionWCAG &&
      localStorage.selectedOptionWCAG != ""
    ) {
      state.selectedOptionWCAG = JSON.parse(localStorage.selectedOptionWCAG);
    }
    if (
      localStorage.selectedOptionAssignee &&
      localStorage.selectedOptionAssignee != ""
    ) {
      state.selectedOptionAssignee = JSON.parse(
        localStorage.selectedOptionAssignee
      );
    }
    if (Object.keys(state).length >= 1) {
      const self = this;
      Swal.fire({
        title: "Load Lost Bug?",
        text: "It looks like you experienced an error, would you like to load the bug you were working on?",
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          self.setState(state);
        } else if (result.dismiss) {
          self.cleanUpLocal();
          if (self.editor) {
            self.editor.loadHTML("");
          }
        }
      });
    }
    window.addEventListener("beforeunload", this.cleanUpLocal, false);
  }

  MenuList = (props) => {
    return (
      <components.MenuList id="watchersMenuList" {...props}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #e0e0e0",
            height: "40px",
          }}
        >
          <p style={{ marginLeft: "30px" }}>Add A Watcher</p>
          <button
            type="button"
            className="link-btn"
            style={{ cursor: "pointer", color: "#519acc" }}
            onClick={this.groupModal}
          >
            <i style={{ fontSize: "12px", marginRight: "5px" }}>+</i>Create
            Group
          </button>
        </div>
        {props ? props.children : ""}
      </components.MenuList>
    );
  };

  componentWillUnmount() {
    document.querySelector("body").style.overflow = "auto";
    document.querySelector("h1").focus();
    this.cleanUpLocal();
    window.removeEventListener("beforeunload", this.cleanUpLocal, false);
  }

  handleSubmit = (e) => {
    try {
      if (e && e !== "file-upload") {
        e.preventDefault();
      }
      this.setState({ loading: true });
      this.props.setBackdropLoader(true);
      if (timeout) {
        clearTimeout(timeout);
        var timeout = null;
      }

      const assignee = this.state.selectedOptionAssignee;
      const priority = this.state.selectedOptionPriority.value;
      // var content = this.state.content.slice(0, this.state.content.length-6) + "<span></span></div>"
      const { content } = this.state;
      const title = this.state.title.trim();
      const { files } = this.state;
      const formData = new FormData();
      formData.append("ticket[project_id]", this.props.data.project.id);
      formData.append("ticket[ticket_status_id]", 1);
      formData.append("ticket[assignee_id]", assignee.value);
      formData.append("ticket[title]", title === "" ? "untitled" : title);
      formData.append("ticket[ticket_priority_id]", priority);
      formData.append("ticket[content]", content);
      if (this.props.data.draftData) {
        formData.append("ticket[draft]", false);
      }
      if (this.props.data.project.a11y_active) {
        if (this.state.selectedOptionWCAG.value === 0) {
          formData.append("ticket[a11y_id]", null);
        } else {
          formData.append(
            "ticket[a11y_id]",
            this.state.selectedOptionWCAG.value
          );
        }
      }
      for (let i = 0; i < files.length; i++) {
        formData.append("attachments[]", files[i]);
        formData.append("filenames[]", files[i].name);
      }
      for (var x = 0; x < this.state.selectedOptionWatchers.length; x++) {
        formData.append(
          "ticket[watchers_attributes][]",
          JSON.stringify({
            user_id: this.state.selectedOptionWatchers[x].value,
          })
        );
      }
      for (var x = 0; x < this.state.selectedOptionDevices.length; x++) {
        formData.append(
          "ticket[device_ids][]",
          this.state.selectedOptionDevices[x].value
        );
      }
      for (var x = 0; x < this.state.selectedOptionBrowsers.length; x++) {
        formData.append(
          "ticket[browser_ids][]",
          this.state.selectedOptionBrowsers[x].value
        );
      }
      for (var x = 0; x < this.state.selectedOptionLabels.length; x++) {
        formData.append(
          "ticket[ticket_label_ids][]",
          this.state.selectedOptionLabels[x].value
        );
      }
      this.onApplicationSubmitNew(formData);
    } catch (err) {
      err.message = `Ticket Create Backend Error: ${err.message}`;
      console.error(err);
      this.setState({ loading: false });
    }
  };

  toggleMenu = (e) => {
    const menuName = e.target.dataset.menu;
    this.setState({ [menuName]: !this.state[menuName] });
  };

  submitToTrello = async (ticket) => {
    // needs to all be on one line to not mess up trello card formatting
    const descriptionText = `**Priority**\n${
      ticket.priority
    }\n\n**Assignee**\n${ticket.assignee}\n\n**Description**\n${
      ticket.content
    }\n\n${
      ticket.affectedDevices
        ? `**Affected Devices**\n${ticket.affectedDevices}`
        : ""
    }\n\n${
      ticket.affectedBrowsers
        ? `**Affected Browsers**\n${ticket.affectedBrowsers}`
        : ""
    }`;
    if (this.props.data.listId) {
      Trello.post(
        "cards",
        {
          token: ticket.token,
          pos: "top",
          idList: this.props.data.listId,
          name: `${ticket.identifier} ${ticket.title}`,
          desc: descriptionText,
        },
        (res) => {
          const cardId = res.id;
          if (ticket.files.length > 0) {
            ticket.files.forEach((file) => {
              const formData = new FormData();
              formData.append("token", ticket.token);
              formData.append("key", "0897db51a0c97cd37a2191a9bfabb4ea");
              formData.append("file", file);
              const request = new XMLHttpRequest();
              request.open(
                "POST",
                `https://api.trello.com/1/cards/${cardId}/attachments`
              );
              request.send(formData);
            });
          }
          axios.patch(`/v1/bugs/${ticket.ticketId}`, {
            ticket: {
              trello_card_id: cardId,
              project_id: ticket.projectId,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
        },
        () => {
          console.error("Error adding ticket to trello");
          Sentry.captureException(new Error("Ticket Create Trello Error"));
        }
      );
    } else {
      console.error("invalid trello list id");
    }
  };

  onApplicationSubmitNew = (formData) => {
    const self = this;
    const xhr = new XMLHttpRequest();
    const action = this.props.data.draftData ? "PUT" : "POST";
    const url = this.props.data.draftData
      ? `/bugs/${this.props.data.draftData.id}`
      : "/bugs.json";
    xhr.open(action, url, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 201 || xhr.status === 200) {
        if (this.props.data.addToTrello) {
          const { files } = this.state;
          const { app_key } = JSON.parse(xhr.response);
          const ticketId = JSON.parse(xhr.response).id;
          const assignee = this.state.selectedOptionAssignee.label;
          const priority = this.state.selectedOptionPriority.label;
          const description = this.state.content;
          const affectedDevices = this.state.selectedOptionDevices
            .map((device) => device.label)
            .join(", ");
          const affectedBrowsers = this.state.selectedOptionBrowsers
            .map((browser) => browser.label)
            .join(", ");
          const title = this.state.title.trim();
          const projectId = this.props.data.project.id;
          const token = this.props.data.project.trello_token;
          const board = this.props.data.project.trello_board
            ? this.props.data.project.trello_board
            : "Test Platform";
          const list = this.props.data.project.trello_list
            ? this.props.data.project.trello_list
            : this.props.data.project.name;
          this.submitToTrello({
            assignee,
            projectId,
            priority,
            ticketId,
            affectedBrowsers,
            affectedDevices,
            files,
            description,
            title,
            token,
            board,
            list,
            identifier: `${this.props.data.project.identifier}-${app_key}`,
          });
        }
        M.toast({
          html: "<span aria-live='asserive' >Ticket Created</span>",
          classes: "green darken-1 error-toast",
        });
        this.props.data.handler(
          xhr.response,
          this.props.data.draftData ? "update" : "create"
        );
        this.props.setBackdropLoader(false);
        if (this.state.createAnother) {
          this.refs.description.value = "";
          // this.titleRef.current.value = ""
          this.setState(
            {
              selectedOptionPriority: this.priorityOptions[2],
              selectedOptionAssignee: { label: "Unassigned", value: 0 },
              selectedOptionWCAG: { label: "none", value: 0 },
              selectedOptionDevices: [],
              selectedOptionBrowsers: [],
              selectedOptionWatchers: [],
              selectedOptionLabels: [],
              title: "",
              files: [],
              createAnother: false,
            },
            () => {
              $(".new-ticket-modal")[0].scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }
          );
        } else {
          this.cleanUpLocal();
          this.props.modalAction(false, "", {});
        }
        if (formData.get("attachments[]")) {
          ReactGA.event({
            category: "Ticket",
            action: "Ticket Created w/ attachment/s",
          });
        } else {
          ReactGA.event({
            category: "Ticket",
            action: "Ticket Created w/o attachment/s",
          });
        }
      } else {
        ReactGA.event({
          category: "Ticket",
          action: "Ticket Creation Error",
        });
        Swal.fire(
          "Ticket create error",
          "There was an error submitting this ticket"
        );
        this.setState({ loading: false });
      }
    }.bind(this);
    xhr.send(formData);
  };

  handleFileDragEnter = (e) => {
    if (document.querySelector("#build-file").clientHeight === 80) {
      const form = document.querySelector("#sidebarCard");
      var dropElem = document.querySelector("#build-file");
      if (form) {
        var dropElem = document.querySelector("#build-file");
        dropElem.style.position = "fixed";
        dropElem.style.height = `${form.scrollHeight}px`;
      } else {
        var dropElem = document.querySelector("#build-file");
        const modal = document.querySelector("#ticketForm");
        dropElem.style.position = "fixed";
        dropElem.style.height = `${modal.scrollHeight}px`;
      }
    }
  };

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  closeGroupModal = () => {
    this.setModal(false, "", {});
  };

  groupHandler = (group, action) => {
    const options = this.state.watcherOptions;
    const optionGroup = {
      value: group.id,
      label: group.value,
      user_ids: group.user_ids,
      id: group.id,
    };
    if (action == "create") {
      if (options.length >= 2) {
        options[0].options.push(optionGroup);
      } else {
        options.unshift({ label: "Groups", options: [optionGroup] });
      }
    } else if (action == "delete") {
      const index = options[0].options.findIndex((x) => x.id == group.id);
      if (index != -1) {
        options[0].options.splice(index, 1);
      }
    }
    this.setState({ watcherOptions: options });
  };

  deleteGroup = (e) => {
    const id = e.target.dataset.groupid;
    axios
      .delete(`/groups/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.groupHandler(res.data, "delete");
        }
      })
      .catch((err) => {
        err.message = `Ticket Group Delete Error - backend: ${err.message}`;
        console.error(err);
      });
  };

  groupModal = () => {
    const groups =
      this.props.data.project.groups != undefined
        ? this.props.data.project.groups
        : [];
    this.setModal(true, "group-modal", {
      users: this.state.watcherOptions[1],
      customClass: "group-modal",
      selectedWatchers: this.state.selectedOptionWatchers,
      projectId: this.props.data.project.id,
      closeHandler: this.closeGroupModal,
      groupHandler: this.groupHandler,
    });
  };

  handleFileDragLeaveOrDrop = (e) => {
    if (e.type === "drop" || e.target.id === "build-file") {
      const dropElem = document.querySelector("#build-file");
      dropElem.style.position = "absolute";
      dropElem.style.height = "80px";
    }
  };

  cleanUpLocal = () => {
    const cleanUpKeys = [
      "editorState",
      "title",
      "selectedOptionPriority",
      "selectedOptionLabels",
      "selectedOptionWatchers",
      "selectedOptionBrowsers",
      "selectedOptionDevices",
      "selectedOptionWCAG",
      "selectedOptionAssignee",
    ];
    cleanUpKeys.map((x) => localStorage.removeItem(x));
  };

  handleCancelButton = () => {
    const self = this;
    Swal.fire({
      title: "Discard New Bug",
      text: "Are you sure you would like to discard this Bug?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        self.cleanUpLocal();
        self.props.modalAction(false, "", {});
      }
    });
  };

  handleSelectChange = (selectedOption, e) => {
    let name = `selectedOption${e.name}`;
    localStorage[name] = selectedOption ? JSON.stringify(selectedOption) : [];
    this.setState({
      [name]: selectedOption || [],
    });
    const elem = document.getElementById("aria-custom-status");
    if (elem) {
      name = e.name;
      this.ariaModifier = this.ariaModifier ? " " : ".";
      setTimeout(() => {
        elem.innerText = `${selectedOption.label} ${name} selected${this.ariaModifier}`;
      }, 200);
    }
  };

  handleMultiSelectChange = (selectedOption, e) => {
    let name = `selectedOption${e.name}`;
    const oldLength = this.state[name].length;
    localStorage[name] = selectedOption ? JSON.stringify(selectedOption) : [];
    this.setState({
      [name]: selectedOption || [],
    });
    const elem = document.getElementById("aria-custom-status");
    if (elem) {
      name = e.name;
      this.ariaModifier = this.ariaModifier ? " " : ".";
      if (selectedOption && selectedOption.length < oldLength) {
        setTimeout(() => {
          elem.innerText = `${name.slice(0, -1)} removed`;
        }, 200);
      } else if (selectedOption && selectedOption.length > oldLength) {
        setTimeout(() => {
          elem.innerText = `${name.slice(0, -1)} ${
            selectedOption[selectedOption.length - 1].label
          } added`;
        }, 200);
      } else {
        setTimeout(() => {
          elem.innerText = `No ${e.name} selected`;
        }, 200);
      }
    }
  };

  onSelectKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          ariaElem.innerText = elem.classList.contains(
            "select__option--is-selected"
          )
            ? `option ${elem.innerText} ${id} currently selected`
            : `option ${elem.innerText} ${id}`;
        }
      }, 50);
    }
  };

  onSelectA11yKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          const parentElem = elem.parentElement;
          const index = Array.prototype.indexOf.call(parentElem.children, elem);
          ariaElem.innerText = elem.classList.contains(
            "select__option--is-selected"
          )
            ? `option ${elem.innerText} currently selected. ${index + 1} of ${
                parentElem.children.length
              }${
                parentElem.children.length < this.violationOptions.length
                  ? " search"
                  : ""
              } options`
            : `option ${elem.innerText}. ${index + 1} of ${
                parentElem.children.length
              }${
                parentElem.children.length < this.violationOptions.length
                  ? " search"
                  : ""
              } options`;
        }
      }, 50);
    }
  };

  onSelectAssigneeKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          const list = this[
            `${id.toLowerCase()}SelectRef`
          ].current.select.state.menuOptions.focusable.map((i) => i.label);
          const index = list.indexOf(elem.innerText);
          ariaElem.innerText = elem.classList.contains(
            "select__option--is-selected"
          )
            ? `option ${elem.innerText} currently selected. ${index + 1} of ${
                list.length
              }${
                list.length < this.state.userOptions.length ? " search" : ""
              } options`
            : `option ${elem.innerText}. ${index + 1} of ${list.length}${
                list.length < this.state.userOptions.length ? " search" : ""
              } options`;
        }
      }, 50);
    }
  };

  onSelectDevicesKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          const list = this[
            `${id.toLowerCase()}SelectRef`
          ].current.select.state.menuOptions.focusable.map((i) => i.label);
          const index = list.indexOf(elem.innerText);
          ariaElem.innerText = `option ${elem.innerText}. ${index + 1} of ${
            list.length
          } options`;
        }
      }, 50);
    }
  };

  removeGroup = (e) => {
    const group = this.state.groups.find(
      (x) => x.id == e.target.dataset.groupid
    );
    const selectedWatchers = this.state.selectedOptionWatchers;
    const filteredWatchers = selectedWatchers.filter(
      (watcher) => !group.user_ids.includes(watcher.value)
    );
    localStorage.selectedOptionWatchers = filteredWatchers;
    this.setState({ selectedOptionWatchers: filteredWatchers });
  };

  handleWatcherChange = (e) => {
    var e = e || [];
    const self = this;
    const values = this.state.selectedOptionWatchers.flatMap((x) =>
      typeof x.value === "number" ? x.value : []
    );
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (element && element.user_ids) {
        element.user_ids.forEach((user) => {
          if (!values.includes(user)) {
            const newUser = self.state.watcherOptions[1].options.find(
              (x) => x.value == user
            );
            e.push(newUser);
          }
        });
        e.splice(index, 1);
      }
    }
    e = e.sort((a, b) => {
      var a = a.label.props.children[1].props.children;
      var b = b.label.props.children[1].props.children;
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    localStorage.selectedOptionWatchers = e;
    this.setState({
      selectedOptionWatchers: e,
    });
  };

  handleTitleInput = (e) => {
    let title = e.target.value;
    if (title.includes("<") || title.includes(">")) {
      title = title.replace(/</g, "");
      title = title.replace(/>/g, "");
      e.target.value = title;
    }
    localStorage.title = title;
    this.setState({
      title,
    });
  };

  deleteNewTicketAttachmentHandler = (description) => {
    if (description) {
      const files = this.state.files.filter(
        (file) => file.name !== description
      );
      this.setState({
        files,
      });
    }
  };

  deleteAttachmentHandler = (ticket, id, comment) => {
    const draftAttachments = this.state.draftAttachments.filter(
      (file) => file.id !== id
    );
    this.setState({
      draftAttachments,
    });
  };

  outsideClickHandler = (e) => {
    const self = this;
    const elem = document.getElementById("watchersSelect");
    if (self.state.watcherMenuOpen && elem && !elem.contains(e.target)) {
      self.setState({ watcherMenuOpen: !self.state.watcherMenuOpen });
    }
  };

  handleEditorReady(editor) {
    // this is a reference back to the editor if you want to
    // do editing programatically
    if (localStorage.editorState && localStorage.editorState != "") {
      var content = JSON.parse(localStorage.editorState);
    }
    editor.insertHTML(content);
    this.editor = editor;
  }

  handleChange(html, text) {
    // html is the new html content
    // text is the new text content
    localStorage.editorState = JSON.stringify(html);
    this.setState({ content: html });
  }

  handleAddFile = () => {
    const fileExtension = document
      .getElementById("build-file")
      .value.split(".")
      .pop()
      .toLowerCase();
    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "chls",
      "rtf",
      "txt",
      "doc",
      "docx",
      "zip",
      "pdf",
      "mp4",
      "mov",
      "json",
    ];
    if (!allowedExtensions.includes(fileExtension)) {
      M.toast({
        html: "Invalid File Type",
        classes: "red darken-1 error-toast",
      });
      this.refs.file.value = "";
      document.getElementById("validator").value =
        "Drop or select file to upload";
      return;
    }
    const { files } = document.getElementById("build-file");
    const checkedFiles = [];
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        // file size limit: 100MB
        if (file.size > 100 * 1024 * 1024) {
          M.toast({
            html: "File exceeds size limit",
            classes: "red darken-1 error-toast",
          });
          this.refs.file.value = "";
          document.getElementById("validator").value =
            "Drop or select file to upload";
          return;
        }
        if (files.length > 3) {
          M.toast({
            html: "Please limit selection to 3 files at a time",
            classes: "red darken-1 error-toast",
          });
          this.refs.file.value = "";
          document.getElementById("validator").value =
            "Drop or select file to upload";
          return;
        }
        checkedFiles.push(file);
      }
    }
    this.setState({
      files: this.state.files.concat(checkedFiles),
    });
    this.refs.file.value = "";
    document.getElementById("validator").value =
      "Drop or select file to upload";
  };

  createNewLabel = (e) => {
    const { data } = this.props;
    const { selectedOptionLabels, labelOptions } = this.state;
    const button = e.target;
    button.style.pointerEvents = "none";
    button.style.color = "lightgrey";
    const labelName = document.querySelector("#label_select_input input").value;
    if (labelName) {
      axios
        .post("/ticket_labels.json", {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          ticket_label: {
            project_id: data.project.id,
            name: labelName,
          },
        })
        .then((res) => {
          const newLabels = [...selectedOptionLabels];
          const newTotalLabels = [...labelOptions];
          const newLabelData = { label: res.data.name, value: res.data.id };
          newLabels.push(newLabelData);
          newTotalLabels.push(newLabelData);
          newTotalLabels.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
          );
          this.setState({
            labelOptions: newTotalLabels,
          });
          this.handleMultiSelectChange(newLabels, {
            action: "select-option",
            name: "Labels",
            option: newLabelData,
          });
          document.activeElement.blur();
        })
        .catch((err) => {
          console.error(err);
          button.style.pointerEvents = "all";
          button.style.color = "#519acc";
          // eslint-disable-next-line no-undef
          M.toast({
            html: "There was an error creating the label",
            displayLength: 3000,
            classes: "red",
          });
        });
    }
  };

  deleteTicketLabel = (e) => {
    const { data } = this.props;
    const { labelOptions } = this.state;
    const { labelId } = e.target.dataset;
    Swal.fire({
      title: "Delete Label",
      text: "Are you sure you would like to delete this Label?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/ticket_labels/${labelId}.json`)
          .then(() => {
            const newTotalLabels = [...labelOptions].filter(
              (label) => label.value !== parseInt(labelId)
            );
            this.setState({ labelOptions: newTotalLabels });
            document.activeElement.blur();
          })
          .catch((err) => {
            console.error(err);
            // eslint-disable-next-line no-undef
            M.toast({
              html: "There was an error deleting the label",
              displayLength: 3000,
              classes: "red",
            });
          });
      }
    });
  };

  checkScrolling = () => {
    setTimeout(() => {
      const elem = document.querySelector(".select__menu");
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  handleCreateAnother = () => {
    this.setState({
      createAnother: !this.state.createAnother,
    });
  };

  handleNewImages = (images) => {
    this.setState({ draftAttachments: images });
  };

  render() {
    const watcherIds = [];
    for (const key in this.state.selectedOptionWatchers) {
      if (
        this.state.selectedOptionWatchers.hasOwnProperty(key) &&
        this.state.selectedOptionWatchers[key]
      ) {
        watcherIds.push(this.state.selectedOptionWatchers[key].value);
      }
    }

    const Option = (props) => {
      if (props.selectProps.options && props.data.user_ids) {
        if (props.data.user_ids.every((item) => watcherIds.includes(item))) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "47px",
              }}
            >
              <div style={{ display: "inline-flex" }}>
                <img
                  alt="select icon"
                  style={{ marginLeft: "-3px", marginRight: "5px" }}
                  height="30"
                  width="30"
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Select.svg"
                />
                <div
                  data-groupid={props.data.id}
                  onClick={this.removeGroup}
                  style={{
                    height: "30px",
                    width: "30px",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px",
                  }}
                >
                  {props ? props.children : ""}
                </div>
              </div>
              <i
                data-groupid={props.data.id}
                onClick={this.deleteGroup}
                style={{
                  verticalAlign: "-7px",
                  color: "#519acc",
                  marginLeft: "auto",
                  marginRight: "12px",
                  cursor: "pointer",
                }}
                className="material-icons hover-red"
              >
                delete
              </i>
            </div>
          );
        }
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "47px",
            }}
          >
            <components.Option {...props}>
              <div
                style={{
                  height: "22px",
                  width: "22px",
                  marginLeft: "-21px",
                  marginRight: "10px",
                  borderRadius: "50%",
                  border: "1px solid black",
                }}
              />
              {props ? props.children : ""}
              <i
                data-groupid={props.data.id}
                onClick={this.deleteGroup}
                style={{
                  verticalAlign: "-7px",
                  color: "#519acc",
                  marginLeft: "auto",
                  cursor: "pointer",
                }}
                className="material-icons hover-red"
              >
                delete
              </i>
            </components.Option>
          </div>
        );
      }
      if (props.isSelected) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "25px",
            }}
          >
            <components.Option {...props}>
              <img
                alt="Select Icon"
                style={{ marginLeft: "-3px", marginRight: "3px" }}
                height="30"
                width="30"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Select.svg"
              />
              {props ? props.children : ""}
            </components.Option>
          </div>
        );
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "25px",
          }}
        >
          <components.Option {...props}>
            <div
              style={{
                height: "22px",
                width: "22px",
                position: "relative",
                top: "3px",
                marginRight: "8px",
                borderRadius: "50%",
                border: "1px solid black",
              }}
            />
            {props ? props.children : ""}
          </components.Option>
        </div>
      );
    };

    const disabled =
      this.state.title === "" ||
      this.state.description === "" ||
      (this.state.selectedOptionDevices.length === 0 &&
        this.state.selectedOptionBrowsers.length === 0);
    return (
      <div className="modal-content">
        <ErrorBoundary>
          <ReactModalV2
            isShowing={this.state.modalOpen}
            page={this.state.currentModal}
            data={this.state.modalData}
            modalAction={this.setModal}
          />
          <div
            onDragEnter={this.handleFileDragEnter}
            onDragLeave={this.handleFileDragLeaveOrDrop}
            onDrop={this.handleFileDragLeaveOrDrop}
          >
            <form
              id="ticketForm"
              tabIndex={-1}
              onSubmit={this.handleSubmit}
              onClick={this.outsideClickHandler}
              ref="ticketForm"
              role="dialog"
              aria-label="New Bug Modal"
              encType="multipart/form-data"
              style={{ position: "relative", outline: "none" }}
            >
              <h2
                tabIndex={-1}
                className="small-title"
                style={{ paddingLeft: "10px", outline: "none" }}
              >
                Create New Bug
              </h2>
              <div
                className="col s12"
                style={{ width: "100%", padding: "10px 11px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    className="ticket-form-select-container new-form-priority-select select-aria-class"
                    style={{ marginRight: "40px" }}
                  >
                    <span style={{ fontWeight: "600" }}>Priority</span>
                    <Select
                      aria-label={`Priority dropdown, ${this.state.selectedOptionPriority.label} selected. Press down to open menu`}
                      id="Priority-select"
                      name="Priority"
                      classNamePrefix="select"
                      defaultValue="Select a priority"
                      value={this.state.selectedOptionPriority}
                      blurInputOnSelect
                      styles={TicketSelectStyles}
                      onChange={this.handleSelectChange}
                      onKeyDown={this.onSelectKeyDown}
                      options={this.priorityOptions}
                      isSearchable={false}
                      instanceId="Priority"
                      components={{
                        DropdownIndicator: dropdownIndicator,
                        Option: this.priorityOption,
                        SingleValue: this.prioritySingleValue,
                      }}
                      required
                    />
                  </div>
                  {this.props.data.project.a11y_active && (
                    <div>
                      <div
                        className="ticket-form-select-container select-aria-class"
                        style={{ width: "50%" }}
                      >
                        <span style={{ fontWeight: "600" }}>Accessibility</span>
                        <Select
                          name="WCAG"
                          id="WCAG-select"
                          aria-label={`WCAG Violation Dropdown, ${this.state.selectedOptionWCAG.label} selected. Type to refine list or press down to open menu`}
                          classNamePrefix="select"
                          defaultValue="Accessibility Violations"
                          value={this.state.selectedOptionWCAG}
                          blurInputOnSelect
                          // onMenuOpen={this.checkScrolling}
                          styles={TicketSelectStyles}
                          onChange={this.handleSelectChange}
                          onKeyDown={this.onSelectA11yKeyDown}
                          components={{
                            SingleValue: singleValue,
                            DropdownIndicator: dropdownIndicator,
                            Control: customControl,
                          }}
                          options={this.violationOptions}
                          instanceId="WCAG"
                          required
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className="ticket-form-input-container">
                    <p className="field-title">
                      Title<span className="bc-blue">*</span>
                    </p>
                    <textarea
                      aria-label="Title input field"
                      className="common-textarea browser-default"
                      // ref={this.titleRef}
                      id="ticket[title]"
                      name="title"
                      placeholder="Enter new title"
                      type="text"
                      maxLength={200}
                      value={this.state.title}
                      onChange={this.handleTitleInput}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "30px",
                        overflow: "hidden",
                      }}
                      required
                    />
                  </div>
                </div>
                <div>
                  <div
                    className="ticket-form-textarea-container"
                    id="descContainer"
                  >
                    <p className="field-title">
                      Description<span className="bc-blue">*</span>
                    </p>
                    <TrixEditor
                      onChange={this.handleChange}
                      className="trix-content"
                      value={this.state.commentValue}
                      placeholder="Enter new description"
                      onEditorReady={this.handleEditorReady}
                    />
                  </div>
                </div>
                <div
                  style={{
                    minHeight: `${
                      (this.state.selectedOptionDevices.length / 2) * 50 + 50 >=
                      250
                        ? "250"
                        : (this.state.selectedOptionDevices.length / 2) * 50 +
                          100
                    }px`,
                    maxHeight: "250px",
                    transition: "max-height .5s",
                    flex: 1,
                  }}
                >
                  <p className="field-title">
                    Environment<span className="bc-blue">*</span>
                  </p>
                  <div
                    id="new-ticket-flex-container"
                    style={{ display: "flex" }}
                  >
                    <MultiSelectWrapper
                      aria-label={`Device dropdown, ${
                        this.state.selectedOptionDevices
                          ? this.state.selectedOptionDevices.length
                          : 0
                      } device${
                        this.state.selectedOptionDevices &&
                        this.state.selectedOptionDevices.length !== 1
                          ? "s"
                          : ""
                      } selected. Type to refine list or press down to open menu`}
                      value={this.state.selectedOptionDevices}
                      label={<p>Affected Devices</p>}
                      onChange={this.handleMultiSelectChange}
                      name="Devices"
                      tagStyle
                      isClearable={false}
                      isSearchable
                      // onSelectKeyDown={this.onSelectDevicesKeyDown}
                      closeMenuOnSelect={false}
                      options={this.state.deviceOptions}
                      placeholder="Search for devices"
                      width="500px"
                      height="200px"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    <MultiSelectWrapper
                      aria-label={`Browser dropdown, ${
                        this.state.selectedOptionBrowsers
                          ? this.state.selectedOptionBrowsers.length
                          : 0
                      } browser${
                        this.state.selectedOptionBrowsers &&
                        this.state.selectedOptionBrowsers.length !== 1
                          ? "s"
                          : ""
                      } selected. Type to refine list or press down to open menu`}
                      value={this.state.selectedOptionBrowsers}
                      label={<p>Affected Browsers</p>}
                      onChange={this.handleMultiSelectChange}
                      isSearchable
                      count={false}
                      // onKeyDown={this.onSelectDevicesKeyDown}
                      name="Browsers"
                      tagStyle
                      isClearable={false}
                      defaultValue="Select a Browser"
                      closeMenuOnSelect={false}
                      options={this.state.browserOptions}
                      placeholder="Search for browsers"
                      width="500px"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", margin: "10px 0 30px" }}>
                  <div
                    className="ticket-form-select-container new-ticket-select select-aria-class"
                    style={{ flex: "1", minWidth: "230px" }}
                  >
                    <p className="field-title" style={{ marginBottom: "5px" }}>
                      Assignee
                    </p>
                    <Select
                      name="Assignee"
                      ref={this.assigneeSelectRef}
                      aria-label={`Assignee Dropdown, ${this.state.selectedOptionAssignee.label} selected. Type to refine list or press down to open menu`}
                      classNamePrefix="select"
                      defaultValue="Select a user"
                      blurInputOnSelect
                      value={this.state.selectedOptionAssignee}
                      styles={{
                        ...TicketSelectStyles,
                        valueContainer: (styles) => ({
                          ...styles,
                          maxHeight: "unset",
                        }),
                        container: (styles) => ({
                          ...styles,
                          width: "250px",
                        }),
                        singleValue: (styles) => ({
                          color: "inherit",
                          marginLeft: "2px",
                          marginRight: "2px",
                          flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          boxSizing: "border-box",
                          height: "42px",
                          lineHeight: "38px",
                        }),
                        menu: (styles) => ({
                          ...styles,
                          width: "300px",
                          zIndex: 2,
                          backgroundColor: "white",
                          right: "-30px",
                        }),
                      }}
                      getOptionValue={(option) =>
                        option.label.props
                          ? `${option.label.props.children[1]} ${option.value}`
                          : option.value
                      }
                      onMenuOpen={this.checkScrolling}
                      onChange={this.handleSelectChange}
                      onKeyDown={this.onSelectAssigneeKeyDown}
                      instanceId="Assignee"
                      options={this.state.userOptions}
                      components={{
                        Option: assigneeOption,
                        SingleValue: assigneeSingleValue,
                        DropdownIndicator: dropdownIndicator,
                        Control: customControl,
                      }}
                      required
                    />
                  </div>
                  <div
                    aria-hidden="true"
                    style={{
                      minHeight: `${
                        (this.state.selectedOptionWatchers.length / 2) * 50 +
                          50 >=
                        250
                          ? "250"
                          : (this.state.selectedOptionWatchers.length / 2) *
                              50 +
                            100
                      }px`,
                      maxHeight: "250px",
                      flex: 1,
                    }}
                    className="col edit-ticket-select"
                    id="watchersSelect"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="field-title">Watchers</p>
                    </div>
                    <Select
                      key={JSON.stringify(this.state.watcherOptions[0])}
                      isMulti
                      aria-label="Watcher dropdown for edit bug form"
                      hideSelectedOptions={false}
                      onChange={this.handleMultiSelectChange}
                      onKeyDown={this.handleSelectKeyDown}
                      isClearable={false}
                      name="Watchers"
                      placeholder="Search for watchers"
                      styles={{
                        ...TaskSelectStyles,
                        indicatorsContainer: (styles) => {
                          return {
                            marginLeft: "auto",
                          };
                        },
                        option: (styles) => ({
                          ...styles,
                          marginLeft: "-20px",
                          display: "flex",
                        }),
                        valueContainer: (styles, state) => ({
                          ...styles,
                          paddingTop: state.hasValue ? "10px" : "",
                          order: 2,
                          width: "500px",
                          top: state.hasValue ? "40px" : "",
                          left: state.hasValue ? "-10px" : "",
                          position: "absolute",
                          padding: "0 0 0 10px",
                          overflow: state.hasValue ? "auto" : "",
                          display: "flex",
                          maxHeight: "150px",
                          marginTop: "0",
                        }),
                        menu: (styles) => ({
                          ...styles,
                          width: "300px",
                          right: "-30px",
                          zIndex: 3,
                        }),
                        groupHeading: (styles, state) => {
                          return {
                            ...styles,
                            color: "black",
                            fontSize: "12px",
                            paddingLeft:
                              state.children == "Users" ? "25px" : "20px",
                            marginBottom: 0,
                          };
                        },
                      }}
                      getOptionValue={(option) =>
                        option.label.props
                          ? `${option.label.props.children[1]} ${option.value}`
                          : option.value
                      }
                      filterOption={createFilter(filterConfig)}
                      value={this.state.selectedOptionWatchers}
                      components={{
                        MenuList: this.MenuList,
                        Option,
                        Control: customMultiControl,
                        GroupHeading: groupHeading,
                      }}
                      closeMenuOnSelect={false}
                      options={this.state.watcherOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div
                  style={{
                    minHeight: "120px",
                    maxHeight: "250px",
                    transition: "max-height .5s",
                  }}
                  className="col s12 edit-ticket-select"
                  id="labelsSelect"
                >
                  <p className="field-title" style={{ marginBottom: "10px" }}>
                    Labels
                  </p>
                  <Select
                    id="label_select_input"
                    isMulti
                    aria-label={`Label dropdown, ${
                      this.state.selectedOptionLabels
                        ? this.state.selectedOptionLabels.length
                        : 0
                    } label${
                      this.state.selectedOptionLabels &&
                      this.state.selectedOptionLabels.length !== 1
                        ? "s"
                        : ""
                    } selected. Type to refine list or press down to open menu`}
                    onChange={this.handleMultiSelectChange}
                    onKeyDown={this.onSelectDevicesKeyDown}
                    ref={this.labelSelectRef}
                    name="Labels"
                    placeholder="Search for or create labels"
                    isClearable={false}
                    styles={{
                      ...MultiSelectStyles,
                      indicatorsContainer: (styles) => ({
                        ...styles,
                        marginLeft: "auto",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        paddingTop: state.hasValue ? "10px" : "",
                        order: 2,
                        top: state.hasValue ? "40px" : "",
                        left: state.hasValue ? "-10px" : "",
                        position: "absolute",
                        padding: "0 0 0 10px",
                        overflow: state.hasValue ? "auto" : "",
                        display: "flex",
                        maxHeight: "150px",
                        width: "800px",
                      }),
                    }}
                    // defaultValue={this.state.selectedOptionLabels}
                    value={this.state.selectedOptionLabels}
                    closeMenuOnSelect={false}
                    options={this.state.labelOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    components={{
                      Option: this.labelOption,
                      MultiValueRemove: customMultiValueRemove,
                      DropdownIndicator: dropdownIndicator,
                      Control: customMultiControl,
                    }}
                    noOptionsMessage={() =>
                      this.deleteable &&
                      document.querySelector("#label_select_input input")
                        .value ? (
                        <span>
                          Label not found. Click{" "}
                          <span
                            onClick={this.createNewLabel}
                            style={{ color: "#519acc", cursor: "pointer" }}
                          >
                            here
                          </span>{" "}
                          to create new &quot;
                          {
                            document.querySelector("#label_select_input input")
                              .value
                          }
                          &quot; label.
                        </span>
                      ) : this.deleteable ? (
                        <span>
                          No label options. Start typing to create new label.
                        </span>
                      ) : document.querySelector("#label_select_input input")
                          .value ? (
                        <span>Label not found.</span>
                      ) : (
                        <span>No label options.</span>
                      )
                    }
                  />
                </div>
                {this.state.draftAttachments.length > 0 && (
                  <div id="attachmentsContainer" style={{ marginTop: "10px" }}>
                    <p className="field-title" style={{ marginTop: "4px" }}>
                      Draft Attachments
                    </p>
                    {this.state.draftAttachments.length > 0 ? (
                      <div id="attachmentCarousel">
                        <AttachmentCarousel
                          setModal={this.setModal}
                          handleNewImages={this.handleNewImages}
                          deleteAttachmentHandler={this.deleteAttachmentHandler}
                          images={[...this.state.draftAttachments]}
                          draft={this.props.data.draftData}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div id="attachmentsContainer" style={{ marginTop: "10px" }}>
                  <p className="field-title" style={{ margin: "4px 0" }}>
                    {`${this.props.data.draftData ? "New " : ""}Attachments`}
                  </p>
                  {this.state.files.length > 0 ? (
                    <div id="attachmentCarousel">
                      <AttachmentCarousel
                        deleteAttachmentHandler={
                          this.deleteNewTicketAttachmentHandler
                        }
                        images={[...this.state.files]}
                        newTicket
                        draft={this.props.data.draftData}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className=" truncate" id="filepond-container">
                    <div className="file-field input-field">
                      <div className="">
                        <input
                          className="color"
                          style={{ height: "80px" }}
                          id="build-file"
                          type="file"
                          accept=".jpg,.gif,.png,.chls,.rtf,.txt,.doc,.docx,.zip,.pdf,.mp4,.mov,.json"
                          ref="file"
                          onChange={this.handleAddFile}
                          multiple
                        />
                      </div>
                      <div id="file-wrapper" style={{ paddingRight: "4px" }}>
                        <input
                          aria-hidden="true"
                          id="validator"
                          style={{
                            pointerEvents: "none",
                            fontFamily: "Arial",
                            fontSize: "15px",
                            border: "1px dashed #e0e0e0",
                            height: "80px",
                            textAlign: "center",
                          }}
                          className="file-path validate attachment-drop-area"
                          placeholder="Drop or select file to upload"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "right", marginTop: "10px" }}>
                  {!this.props.data.draftData && (
                    <label
                      aria-hidden="true"
                      id="create-another-check"
                      style={{ paddingRight: "10px" }}
                    >
                      <input
                        aria-hidden="true"
                        onChange={this.handleCreateAnother}
                        className="filled-in"
                        checked={this.state.createAnother}
                        type="checkbox"
                      />
                      <span
                        aria-hidden="true"
                        style={{
                          whiteSpace: "nowrap",
                          color: "black",
                          paddingLeft: "25px",
                          fontSize: "14px",
                        }}
                      >
                        Create Another
                      </span>
                    </label>
                  )}
                  <button
                    aria-label="submit new bug"
                    type="submit"
                    className={
                      disabled ? "btn" : "btn ticket-modal-submit-enabled"
                    }
                    disabled={disabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <button
                aria-label="Close New Bug Modal"
                type="button"
                tabIndex="0"
                className="icon-close-btn"
                style={{
                  display: "block",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
                onClick={this.handleCancelButton}
              />
            </form>
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}
export default NewTicket;

NewTicket.propTypes = {
  data: PropTypes.object,
};
