import React from "react";
import PropTypes from "prop-types";

class SubNavbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        aria-hidden={
          this.props["aria-hidden"] ? this.props["aria-hidden"] : false
        }
        style={this.props.containerStyle}
      >
        <div
          style={{
            flex: this.props.flex ? this.props.flex[0] : 1,
            padding: this.props.sectionOneChildren ? "10px 0px" : "0px 0px",
            fontSize: "21px",
            fontFamily: "arial",
            maxWidth: this.props.maxWidth ? this.props.maxWidth : "",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            flexWrap: "wrap",
            alignItems: this.props.center ? "center" : "",
          }}
        >
          {this.props.pageName && this.props.icon && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: this.props.wrap ? "wrap" : "",
                alignItems: "center",
              }}
            >
              <img
                aria-hidden="true"
                alt="page icon"
                width="40"
                height="40"
                style={
                  this.props.iconStyle
                    ? this.props.iconStyle
                    : { marginRight: "10px" }
                }
                src={this.props.icon}
              />
              <h2
                tabIndex={-1}
                aria-label={this.props.pageName}
                id="main-heading"
                className="large-title"
              >
                {this.props.pageName}
              </h2>
            </div>
          )}
          {this.props.sectionOneChildren &&
            this.props.sectionOneChildren.length >= 1 &&
            this.props.sectionOneChildren}
        </div>
        <div
          style={{
            flex: this.props.flex ? this.props.flex[1] : 1,
            padding: this.props.sectionTwoChildren ? "10px 0px" : "0px 0px",

            fontSize: "21px",
            fontFamily: "arial",
            display: "flex",
            maxWidth: this.props.maxWidth ? this.props.maxWidth : "",
            justifyContent: "space-between",
            flexWrap: "wrap",
            position: "relative",
            alignItems: this.props.center ? "center" : "",
          }}
        >
          {this.props.sectionTwoChildren &&
            this.props.sectionTwoChildren.length >= 1 &&
            this.props.sectionTwoChildren}
        </div>
        <div
          style={{
            flex: this.props.flex ? this.props.flex[2] : 1,
            padding: this.props.sectionThreeChildren ? "10px 0px" : "0px 0px",
            fontSize: "21px",
            fontFamily: "arial",
            maxWidth: this.props.maxWidth ? this.props.maxWidth : "",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: this.props.center ? "center" : "",
          }}
        >
          {this.props.sectionThreeChildren &&
            this.props.sectionThreeChildren.length >= 1 &&
            this.props.sectionThreeChildren}
        </div>
        <div
          style={{
            flex: this.props.flex ? this.props.flex[2] : 1,
            padding: this.props.sectionFourChildren ? "10px 0px" : "0px 0px",
            fontSize: "21px",
            fontFamily: "arial",
            maxWidth: this.props.maxWidth ? this.props.maxWidth : "",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            position: "relative",
            alignItems: this.props.center ? "center" : "",
          }}
        >
          {this.props.sectionFourChildren &&
            this.props.sectionFourChildren.length >= 1 &&
            this.props.sectionFourChildren}
        </div>
      </div>
    );
  }
}

export default SubNavbar;

SubNavbar.propTypes = {
  user: PropTypes.object,
  currentURL: PropTypes.string,
  projectId: PropTypes.number,
  center: PropTypes.bool,
  maxWidth: PropTypes.string,
  flex: PropTypes.array,
  icon: PropTypes.string,
  iconStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  wrap: PropTypes.bool,
  pathName: PropTypes.string,
  pageName: PropTypes.any,
  dropDown: PropTypes.bool,
  sectionOneChildren: PropTypes.any,
  sectionTwoChildren: PropTypes.any,
  sectionThreeChildren: PropTypes.any,
  sectionFourChildren: PropTypes.any,
  ["aria-hidden"]: PropTypes.bool,
};
