import React from 'react'

class NotificationsIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
      >
        <path d="M99.36,87.26l-6.68-6.68a.86.86,0,0,1,0-.22V54.61A28.49,28.49,0,0,0,67.18,26.14V20.42a3.18,3.18,0,1,0-6.36,0v5.72A28.88,28.88,0,0,0,35.34,54.61V80.36c0,.06,0,.06-.12.18l-6.71,6.72a6.56,6.56,0,0,0-2,4.77v2a6.67,6.67,0,0,0,6.67,6.67H51.52a12.69,12.69,0,0,0,12.35,10.06,12.49,12.49,0,0,0,12.35-10.06H94.58A6.68,6.68,0,0,0,101.25,94V92A7.3,7.3,0,0,0,99.36,87.26ZM64,104.41a6.13,6.13,0,0,1-5.59-3.7H69.69A6.45,6.45,0,0,1,64,104.41ZM95.15,93.54H95V94a.38.38,0,0,1-.31.31H33.29A.38.38,0,0,1,33,94V92s0-.06.12-.18l6.71-6.7a6.6,6.6,0,0,0,2-4.79V54.61a22.3,22.3,0,0,1,44.59,0V80.36a7.34,7.34,0,0,0,1.89,4.79L95,91.85l.12.18Z"/>
        <path d="M125.11,62a36.44,36.44,0,0,1-10.74,26,3.11,3.11,0,0,1-2.23,1,3.14,3.14,0,0,1-2.23-1,3.23,3.23,0,0,1-1-2.38,2.85,2.85,0,0,1,1-2.1A30.22,30.22,0,0,0,118.75,62a30.68,30.68,0,0,0-8.84-21.51,3.08,3.08,0,0,1,0-4.45,3.23,3.23,0,0,1,2.39-1,2.88,2.88,0,0,1,2.1,1A36.5,36.5,0,0,1,125.11,62Z"/>
        <path d="M111.64,62a23,23,0,0,1-6.79,16.44,3.07,3.07,0,0,1-4.46,0,3.11,3.11,0,0,1-1-2.22,3.16,3.16,0,0,1,1-2.23,17,17,0,0,0,0-24,3.16,3.16,0,0,1-1-2.23,3.2,3.2,0,0,1,1-2.24,3.17,3.17,0,0,1,2.39-.94,2.86,2.86,0,0,1,2.1,1A23.09,23.09,0,0,1,111.64,62Z"/>
        <path d="M19,85.89a2.85,2.85,0,0,1-1,2.09,3.09,3.09,0,0,1-4.44,0,36.76,36.76,0,0,1,0-51.93,3.2,3.2,0,0,1,2.38-1,2.87,2.87,0,0,1,2.1,1,3.21,3.21,0,0,1,0,4.44A30.21,30.21,0,0,0,9.25,62a30.6,30.6,0,0,0,8.84,21.5A3.28,3.28,0,0,1,19,85.89Z"/>
        <path d="M27.62,74a3.15,3.15,0,0,1,.94,2.23,3.07,3.07,0,0,1-.95,2.22,3.07,3.07,0,0,1-4.46,0,23.17,23.17,0,0,1,0-32.89,3.18,3.18,0,0,1,5.41,2.24A3.12,3.12,0,0,1,27.61,50a16.58,16.58,0,0,0-4.95,12A17.09,17.09,0,0,0,27.62,74Z"/>
      </svg>
    );
  }
}
NotificationsIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default NotificationsIcon