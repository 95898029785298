import { useState, useCallback } from "react";
import ApiClient from "@utils/ApiClient";

const useAttachmentUpload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [avatarInfo, setAvatarInfo] = useState(null);

  const uploadNewAvatar = useCallback((file) => {
    setLoading(true);

    const api = new ApiClient({ "Content-Type": "multipart/form-data" });
    const formData = new FormData();
    formData.append("avatar[file]", file);

    api
      .put("profile/avatar", formData)
      .then((res) => {
        setAvatarInfo(res.data.avatar);
      })
      .catch((err) => {
        setError(err);
        // eslint-disable-next-line no-undef
        M.toast({
          classes: "red",
          html: "There was an error uploading your avatar",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { uploadNewAvatar, loading, error, avatarInfo };
};

export default useAttachmentUpload;
