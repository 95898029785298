
const baseColor = "#519acc"
const lightGrey = "#f0f0f0";
const medLightG = "#d1d1d1"
const TicketSelectStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "white",
      borderRadius: "5px",
      outlineColor: baseColor,
      boxShadow: state.isFocused
        ? `1px 1px 1px 0px transparent`
        : `1px 1px 3px 0px ${lightGrey}`,
      borderStyle: "solid",
      borderWidth: "1px",
      color: state.isFocused
      ? `#d1d1d1`
      : "#242424",
      borderColor: state.menuIsOpen
        ? baseColor
        : state.isFocused
        ? baseColor
        : "lightgray",
      minHeight:"35px",
      height: "42px",
      "&:hover": {
        borderColor: baseColor,
      },
    };
  },
  placeholder: (styles) => {
    return {
    ...styles,
    color: "inherit",
    top: "47%",
    }
  },
  singleValue: (styles, state) => {
    return {
    ...styles,
      color: state.selectProps.isSearchable ? "inherit" : "#242424"
    }
  },
  container: (styles) => ({
    ...styles,
    fontFamily: 'Arial',
    width: "200px"
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: "100%",
    marginTop: "0",
    backgroundColor: "transparent",
  }),
  input: (styles) => {
    return {
    ...styles,
    boxShadow: "none !important",
    margin: "0",
    padding: "0",
    height: "42px",
    position: "relative",
    userSelect: "none",
    color: "transparent"
    }
  },
  menu: (styles) => ({
    ...styles,
    transform: "translateX(-12.5px)",
    width: "225px",
    borderRadius: "5px",
    boxShadow: "0 0 9px 2px hsla(0,0%,0%,0.3), 0 4px 11px hsla(0,0%,0%,0.1)",
    zIndex: 2,
  }),
  menuList: (styles) => ({ ...styles, borderRadius: "5px" }),
  valueContainer: (styles) => {
    return {
    ...styles, 
      maxHeight: "35px",
      bottom: "3px",
      color: "inherit"
     }
    },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    color: state.isFocused ? baseColor : baseColor,
    alignItems: "center",
    height: "42px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: `${baseColor} !important`,
    cursor: "pointer",
    alignItems: "center",
    height: "42px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "white"
        : isSelected
        ? "white"
        : isFocused
        ? baseColor
        : "white",
      color: isFocused ? isSelected ? baseColor : "white" : isSelected ? baseColor : "#242424",
      cursor: isDisabled ? "not-allowed" : "default",
      paddingLeft: "25px",
    };
  },
};

export default TicketSelectStyles;
