import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Table from "rc-table";
import moment from "moment";
import { trialTypes } from "../../types/wiki";

const AssessmentResults = ({ trials }) => {
  const columns = [
    {
      title: "Resource",
      dataIndex: ["assessment", "resource", "title"],
      className:
        "wiki-assessment-results-table wiki-assessment-results-table-cell wiki-assessment-results-table-cell-resource",
      render: (text) => {
        return text;
      },
    },
    {
      title: "Attempt Date",
      dataIndex: ["started_at"],
      className:
        "wiki-assessment-results-table wiki-assessment-results-table-cell wiki-assessment-results-table-cell-attempt-date",
      render: (text) => {
        return moment(text).format("MM/DD/YYYY");
      },
    },
    {
      title: "Attempt Result",
      dataIndex: ["trial_results"],
      align: "center",
      className:
        "wiki-assessment-results-table wiki-assessment-results-table-cell wiki-assessment-results-table-cell-attempt-result",
      render: (text) => {
        const { passed, failed, expired } = text;
        if (passed) return <span style={{ color: "#15a942" }}>Pass</span>;
        if (failed) return <span style={{ color: "#D10800" }}>Fail</span>;
        if (expired) return <span>Expired</span>;
      },
    },
    {
      title: "Score",
      dataIndex: ["trial_results", "correct_string"],
      align: "center",
      className:
        "wiki-assessment-results-table wiki-assessment-results-table-cell wiki-assessment-results-table-cell-attempt-score",
      render: (text) => {
        return text;
      },
    },
  ];
  return (
    <AssessmentResultsContainer>
      <ResultsTable
        columns={columns}
        data={trials}
        rowKey={(record) => record.id}
        onRow={(record, index) => {
          return {
            className: `wiki-assessment-results-table-row wiki-assessment-results-table-row-${
              index % 2 === 0 ? "even" : "odd"
            }`,
          };
        }}
        className="wiki-assessment-results-table"
      />
    </AssessmentResultsContainer>
  );
};

const AssessmentResultsContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

const ResultsTable = styled(Table)`
  td,
  th {
    padding: 7px 15px;
    font-family: "Arial";
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    border: none;
    border-width: 0;
    border-radius: 0;
  }

  thead {
    border: 1px solid #1b71ac;
    border-radius: 2px;
  }

  thead tr {
    background-color: #519acc;
    color: white;
    border: none;
  }

  tbody tr {
    color: #242424;
    border: none;
    border-width: 0;
  }

  .wiki-assessment-results-table-row {
  }

  .wiki-assessment-results-table-row-odd {
    background-color: white;
  }

  .wiki-assessment-results-table-row-even {
    background-color: #d2d2d2;
  }
`;

AssessmentResults.propTypes = {
  trials: PropTypes.arrayOf(trialTypes),
};

export default AssessmentResults;
