import { string, number, bool, shape } from "prop-types";

export default shape({
  active: bool,
  application_id: number,
  created_at: string,
  id: number,
  name: string,
  owner_id: number,
  updated_at: string,
});
