import React, { useContext } from "react";
import styled from "styled-components";
import { TrialsContext } from "./index";
import TimeRemaining from "./TimeRemaining";

const TrialHeader = () => {
  const { trial, isInProgress } = useContext(TrialsContext);
  const { expires_at } = trial;

  return (
    <Container>
      <Logo />
      {isInProgress && expires_at && <TimeRemaining />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  height: 75px;
  width: 75px;
  background-image: url(https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/TPLogo_2021_Color.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export default TrialHeader;
