import React from "react";

const DashboardFilled = (props) => {
  return (
    <svg
      id="Stats2-blue_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Stats2-blue_svg__cls-1{fill:none;stroke:#519acc;stroke-miterlimit:10;stroke-width:3px}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <path
        className="Stats2-blue_svg__cls-1"
        d="M28.91 10.78a24 24 0 1024 24h-24z"
      />{" "}
      <path
        className="Stats2-blue_svg__cls-1"
        d="M35.09 5.13v24h24a24 24 0 00-24-24z"
      />{" "}
    </svg>
  );
};

export default DashboardFilled;
