import React, { useContext } from "react";
import styled from "styled-components";
import Icons from "../../icons";
import { IconContainer, Content } from "./TrialBody";
import { TrialsContext } from "./index";

const TrialIntro = () => {
  const { assessment } = useContext(TrialsContext);
  return (
    <Content>
      <IconRow>
        <IconContainer $size="60px">
          <Icons.BookIcon height="33px" width="46px" color="#ffffff" />
        </IconContainer>
      </IconRow>
      <HeadlineRow>
        <Headline>
          Test Your Knowledge on {assessment.resource.title} (Level{" "}
          {assessment.level})
        </Headline>
      </HeadlineRow>
      <DescriptionRow>
        <Description>{assessment.introduction}</Description>
      </DescriptionRow>
    </Content>
  );
};

const IconRow = styled.div`
  margin-bottom: 29px;
`;

const HeadlineRow = styled.div`
  margin-bottom: 21px;
`;

const DescriptionRow = styled.div``;

const Headline = styled.h3`
  font-size: 27px;
  font-weight: 700;
  line-height: 37px;
  font-family: "Manrope";
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Arial";
`;

export default TrialIntro;
