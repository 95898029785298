import React from "react";
import styled from "styled-components";

// eslint-disable-next-line react/prop-types
export const CancelButton = ({ children, className, ...rest }) => {
  return (
    <Cancel
      {...rest}
      className={`${className ? `${className} ` : ""}common-button-cancel`}
    >
      {children}
    </Cancel>
  );
};

// eslint-disable-next-line react/prop-types
export const SubmitButton = ({ children, className, ...rest }) => {
  return (
    <Submit {...rest} className={`${className ? `${className} ` : ""}`}>
      {children}
    </Submit>
  );
};

export const buttonStyle = `
  width: 128px;
  height: 41px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  `;

export const submitStyle = `
  ${buttonStyle}
  background-color: #519acc;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  height: 38px;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
    background-color: #519acc;
  }
`;

export const Cancel = styled.button`
  ${buttonStyle}
  background: #e0e0e0;
  box-shadow: 0px 3px 0px #c4c4c4;
  color: #242424;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
`;

export const Submit = styled.button`
  ${submitStyle}
  background: #519ACC;
  box-shadow: 0px 3px 0px #437fa9;
  color: #ffffff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
`;
