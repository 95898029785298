import React from "react";

const PerformanceHeaderIcon = () => {
  return (
    <svg
      width="34"
      height="41"
      viewBox="0 0 34 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3697_5643)">
        <path
          d="M0.863835 0.150635H10.7576C10.9932 0.150635 11.2287 0.30137 11.3858 0.527473L17.3534 9.57159C17.432 9.79769 17.432 10.0238 17.2749 10.1745L11.7784 18.0881C11.6213 18.2389 11.4643 18.3896 11.2287 18.3896C11.2287 18.3896 11.2287 18.3896 11.1502 18.3896C10.9146 18.3896 10.6791 18.2389 10.6006 18.0128L0.23566 1.13041C0.157138 0.904311 0.157138 0.678208 0.314181 0.527473C0.392703 0.30137 0.628269 0.150635 0.863835 0.150635Z"
          fill="#8ABBDB"
        />
        <path
          d="M23.3995 0.150635H33.2148C33.4503 0.150635 33.6859 0.30137 33.7644 0.452105C33.9215 0.678208 33.9215 0.904311 33.7644 1.13041L22.6928 18.6911C22.5358 18.9172 22.3788 18.9925 22.1432 18.9925H11.5427C11.1501 18.9925 10.9146 18.6911 10.9146 18.3142V17.6359C10.9146 17.4852 10.9146 17.4098 11.0716 17.2591L22.8499 0.452105C22.9284 0.30137 23.164 0.150635 23.3995 0.150635Z"
          fill="#ACD2EB"
        />
        <path
          d="M22.4572 23.6653H11.1501C10.7574 23.6653 10.4434 23.3638 10.4434 22.987V18.691C10.4434 18.3142 10.7574 18.0127 11.1501 18.0127H22.4572C22.8498 18.0127 23.1639 18.3142 23.1639 18.691V22.987C23.1639 23.3638 22.8498 23.6653 22.4572 23.6653Z"
          fill="#6EB0DB"
        />
        <path
          d="M27.2472 30.8254C27.2472 36.3272 22.5359 40.8493 16.8038 40.8493C11.0717 40.8493 6.36035 36.3272 6.36035 30.8254C6.36035 25.3235 11.0717 20.8015 16.8038 20.8015C22.5359 20.7261 27.2472 25.2482 27.2472 30.8254Z"
          fill="#519ACC"
        />
        <path
          d="M20.6513 36.6286C20.4943 36.6286 20.3372 36.5533 20.2587 36.4779L16.8037 34.0661L13.3488 36.5533C13.1132 36.704 12.7991 36.704 12.485 36.5533C12.2495 36.4025 12.1709 36.1011 12.2495 35.7996L13.5843 31.9558L10.2079 29.5441C9.97232 29.3933 9.89379 29.0919 9.97232 28.7904C10.0508 28.4889 10.3649 28.3382 10.679 28.3382H14.9192L16.2541 24.4944C16.3326 24.193 16.6467 24.0422 16.9608 24.0422C17.2749 24.0422 17.5104 24.2683 17.6675 24.4944L19.0023 28.3382H23.2425C23.5566 28.3382 23.7922 28.5643 23.9492 28.7904C24.0277 29.0919 23.9492 29.3933 23.7137 29.5441L20.2587 31.9558L21.5936 35.7996C21.6721 36.1011 21.5936 36.4025 21.358 36.5533C20.8869 36.6286 20.8083 36.6286 20.6513 36.6286Z"
          fill="white"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_3697_5643">
          <rect width="34" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PerformanceHeaderIcon;
