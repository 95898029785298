import React from "react";
import ErrorBoundary from "../common/error_boundary.jsx";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { TrixEditor } from "./trix_container.jsx";

const WAIT_INTERVAL = 3000;
class EditableTextArea extends React.Component {
  constructor(props) {
    super(props);
    var users = [
      {
        trigger: "@",
        tags: [],
      },
    ];
    this.props.users.map((x) =>
      users[0].tags.push(
        x
        // {
        //   id: x.value,
        //   name: x.label.props?x.label.props.children[1]:x.label,
        //   tag: x.label.props? `@${x.label.props.children[1]}` : `@${x.label} `,
        //   image: x.image ? x.image : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
        // }
      )
    );
    this.state = {
      tags: [],
      value: this.props.value,
      editable: false,
      users: users,
      loaded: false,
    };
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addMentionee = this.addMentionee.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.handleEditorReady = this.handleEditorReady.bind(this);
  }

  timer = null;

  toggleEdit(e) {
    var e = e;
    var self = this;
    if (e.target.tagName !== "A") {
      if (this.props.value != this.state.value) {
        Swal.fire({
          title: "Are you sure?",
          reverseButtons: true,
          text: "If you confirm any description changes will be lost",
          showCancelButton: true,
          confirmButtonAriaLabel: "Yes",
          cancelButtonAriaLabel: "cancel",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            if (self.state.editable) {
              document.getElementById("descContainer").style.marginBottom =
                "10px";
            } else {
              document.getElementById("descContainer").style.marginBottom =
                "50px";
            }
            self.props.updatedHandler(false);
            self.setState({
              editable: !self.state.editable,
              value: self.props.value,
            });
            var ariaElem = document.getElementById(`aria-custom-status`);
            if (ariaElem) {
              ariaElem.innerText = "Description edit canceled";
            }
          } else if (result.dismiss) {
            console.log("dismissed");
          }
        });
      } else {
        if (this.state.editable) {
          document.getElementById("descContainer").style.marginBottom = "10px";
        } else {
          document.getElementById("descContainer").style.marginBottom = "50px";
          setTimeout(() => {
            var node = ReactDOM.findDOMNode(this);
            // var textArea = node.querySelector("textarea")
            // textArea.focus()
          }, 50);
        }
        this.props.updatedHandler(false);
        this.setState({ editable: !this.state.editable });
      }
    }
  }

  triggerChange() {
    const value = this.state.value;
    const mentions = this.state.tags;
    this.props.onChange(value, mentions);
    this.props.updatedHandler(false);
    var descContainer = document.getElementById("descContainer");
    if (descContainer) {
      descContainer.style.marginBottom = "10px";
    }
    var ariaElem = document.getElementById(`aria-custom-status`);
    if (ariaElem) {
      ariaElem.innerText = "Description updated";
    }
  }

  addMentionee(tag) {
    var id = tag.id;
    var tags = this.state.tags;
    tags.push(id);
    var users = this.state.users;
    var filteredUsers = users.filter((x) => x.id !== id);
    this.setState({ tags: tags, users: filteredUsers });
  }

  linkClick(e) {
    var target = e.target;
    if (target && target.nodeName === "A" && e.metaKey) {
      var url = target.href;
      if (url) {
        window.open(url, "_blank");
      }
    }
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.element.removeEventListener("click", this.linkClick);
    }
  }

  handleEditorReady(editor) {
    // this is a reference back to the editor if you want to
    // do editing programatically
    editor.insertHTML(this.state.value);
    this.editor = editor;
    editor.element.addEventListener("click", this.linkClick);

    this.setState({ loaded: true });
  }

  handleChange(html, text) {
    var self = this;
    if (this.state.loaded) {
      if (!this.props.localFormUpdated) {
        this.props.updatedHandler(true);
      }
      clearTimeout(this.timer);
      // html is the new html content
      // text is the new text content

      this.setState({ value: html }, () => {
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
      });
    }
  }

  render() {
    if (this.props.editable != undefined) {
      return (
        <div>
          <ErrorBoundary>
            {this.props.editable === true ? (
              <div onClick={this.dblClick}>
                <TrixEditor
                  disabled={!this.state.editable}
                  onChange={this.handleChange}
                  onTagClick={this.addMentionee}
                  onBlur={this.onBlur}
                  className="trix-content"
                  placeholder={this.props.placeholder}
                  onEditorReady={this.handleEditorReady}
                  mergeTags={this.state.users}
                  autoFocus={false}
                />
              </div>
            ) : (
              <div
                id="trix-placeholder"
                className="trix-content"
                dangerouslySetInnerHTML={{ __html: this.state.value }}
              ></div>
            )}
          </ErrorBoundary>
        </div>
      );
    } else {
      return (
        <div>
          <ErrorBoundary>
            {/* <MentionsInput 
        // ref={this.props.refProp} 
        maxLength="10000" placeholder="" className="mentionInput" style={mentionStyle} value={this.state.value} onChange={this.handleChange}>
          <Mention
            markup={'@[__display__]'}
            onAdd={this.addMentionee}
            trigger="@"
            displayTransform={(id, display)=>`@${display}`}
            style={{color:"#519acc", backgroundColor:"#f5f5f5"}}
            renderSuggestion={(suggestion,search,highlightedDisplay,index,focused) => {
              var user = this.props.users.find((u)=> u.value === suggestion.id)
              return (<div className={`user ${focused ? 'focused' : ''}`}>{user?user.label:highlightedDisplay}</div>)
            }}

            data={this.state.users}
          />
      </MentionsInput> */}
            <TrixEditor
              disabled={!this.state.editable}
              onChange={this.handleChange}
              onTagClick={this.addMentionee}
              className="trix-content"
              placeholder={this.props.placeholder}
              onEditorReady={this.handleEditorReady}
              mergeTags={this.state.users}
              autoFocus={false}
            />
          </ErrorBoundary>
        </div>
      );
    }
  }
}

export default EditableTextArea;
