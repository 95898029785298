import React, { useState, useEffect, useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import axios from "axios";
import ReactModalV2 from "../modals/react_modal_v2";
import HelpDirectory from "./help_directory";
import HelpResource from "./help_resource";
import FeaturedTopic from "../modals/featured_topic";

const modals = {
  "featured-topic": FeaturedTopic,
};

const HelpContainer = (props) => {
  const [modalObject, setModalObject] = useState({
    modalOpen: false,
    currentModal: "",
    modalData: {},
  });
  const [direction, setDirection] = useState("node-forwards");
  const [currentResource, setCurrentResource] = useState(
    props.current_resource ? props.current_resource : null
  );
  const [featuredTopics, setFeaturedTopcs] = useState(props.featured_topics);
  const [helpResources, setHelpResources] = useState(props.help_resources);
  const ariaCustomStatusRef = useRef(null);

  useEffect(() => {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    setTimeout(() => {
      const url = new URL(location.href);
      const topic = url.searchParams.get("topic");
      if (topic) {
        const elem = document.getElementById(`topic-${topic}`);
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 500);
  }, []);

  function setModal(bool, page, data) {
    setModalObject({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  }

  function refreshFeaturedTopics() {
    axios
      .get("/refresh_featured_topics")
      .then((res) => {
        setFeaturedTopcs(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function backToLandingPage(updated) {
    if (updated) {
      axios
        .get("/refresh_landing_page")
        .then((res) => {
          setHelpResources(res.data);
          setCurrentResource(null);
          window.history.pushState("page2", "Test Platform", "/help/");
          setTimeout(() => {
            setDirection("node-forwards");
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          setCurrentResource(null);
          window.history.pushState("page2", "Test Platform", "/help/");
          setTimeout(() => {
            setDirection("node-forwards");
          }, 1000);
        });
    } else {
      setCurrentResource(null);
      window.history.pushState("page2", "Test Platform", "/help/");
      setTimeout(() => {
        setDirection("node-forwards");
      }, 1000);
    }
  }

  function handleDragDropUpdate(resource) {
    setCurrentResource(resource);
  }

  function handleTopicClick(id, topicId) {
    var topicId = topicId;
    var id = id;
    axios
      .get(`/get_help_resource/${id}`)
      .then((res) => {
        setCurrentResource(res.data);
        if (topicId) {
          window.history.pushState(
            "page2",
            "Test Platform",
            `/help/${id}?topic=${topicId}`
          );
          setTimeout(() => {
            const elem = document.getElementById(`topic-${topicId}`);
            if (elem) {
              elem.scrollIntoView({ behavior: "smooth" });
            }
          }, 1000);
        } else {
          window.history.pushState("page2", "Test Platform", `/help/${id}`);
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 400);
        setTimeout(() => {
          setDirection("node-backwards");
        }, 1000);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div id="help-center-container">
      <div
        ref={ariaCustomStatusRef}
        style={{
          position: "fixed",
          top: "-200px",
          left: "-200px",
          pointerEvents: "none",
          height: "0px",
          width: "0px",
          overflow: "hidden",
        }}
        data-number="0"
        role="status"
        aria-live="assertive"
        id="aria-custom-status"
      />
      <ReactModalV2
        isShowing={modalObject.modalOpen}
        page={modalObject.currentModal}
        data={modalObject.modalData}
        modalAction={setModal}
      />
      <div
        style={{
          width: "100%",
          maxWidth: `calc(100% - ${100}px)`,
          willChange: "max-width",
          transition: "max-width 0.666s ease-in-out 0s",
          whiteSpace: "nowrap",
          marginLeft: "auto",
        }}
      >
        <SwitchTransition>
          <CSSTransition
            key={!currentResource}
            timeout={{ appear: 300, enter: 390, exit: 390 }}
            classNames={direction}
          >
            {!currentResource ? (
              <HelpDirectory
                handleTopicClick={handleTopicClick}
                helpResources={helpResources}
                featuredTopics={featuredTopics}
              />
            ) : (
              <HelpResource
                currentUser={props.current_user}
                helpResources={helpResources}
                permanentFeaturedTopicsCount={
                  featuredTopics.filter((f) => f.permanent_feature).length
                }
                refreshFeaturedTopics={refreshFeaturedTopics}
                handleDragDropUpdate={handleDragDropUpdate}
                setModal={setModal}
                backToLandingPage={backToLandingPage}
                currentResource={currentResource}
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default HelpContainer;
