import React, { useMemo } from "react";
import { createConsumer } from "@rails/actioncable";

const WithCable = (Component) => {
  const WithCableComponent = (props) => {
    const context = useMemo(() => ({ App: { cable: createConsumer() } }), []);

    return <Component {...props} cable={context.App.cable} />;
  };

  return WithCableComponent;
};

export default WithCable;
