import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const LastFiveDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
  > ul {
    width: 100%;
    display: inline-flex;
    > li {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }
  }
`;

function LastFive(props) {
  const [loading, setLoading] = useState(false);
  const [lastFive, setLastFive] = useState([]);
  const [mounted, setMounted] = useState(false);

  function getData() {
    setLoading(true);
    var url = `/devices/${props.device.id}/last_five.json`;
    axios
      .get(url)
      .then((res) => {
        setLastFive(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (mounted === false) {
      setMounted(true);
    }
    getData();
    return () => {};
  }, [props.device.id]);

  function displayLastFive() {
    var users = lastFive.map((x) => (
      <li key={x[0]}>
        <p>{x[0]}</p>
        <p className="pill">{x[1]}</p>
      </li>
    ));
    return <ul>{users}</ul>;
  }

  return (
    <LastFiveDiv>
      <p className="common-input-label">Last Users:</p>
      {lastFive.length >= 1 ? (
        displayLastFive()
      ) : loading ? null : (
        <p>Device has not been used yet</p>
      )}
    </LastFiveDiv>
  );
}

export default LastFive;
