import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";

class ReactModalV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disableBackdropClick: false,
      bypassClickCheck: false,
    };

    this.backdropClick = this.backdropClick.bind(this);
    this.setBackdropLoader = this.setBackdropLoader.bind(this);
    this.setDisableBackdropClick = this.setDisableBackdropClick.bind(this);
    this.setBypassClickCheck = this.setBypassClickCheck.bind(this);

    this.handleEsc = (e) => {
      const key = e.keyCode;
      if (key === 27) {
        this.backdropClick();
      }
    };
    this.listenerPresent = false;
  }

  componentDidUpdate(prevProps) {
    const { isShowing } = this.props;
    if (prevProps.isShowing === false && isShowing === true) {
      const html = document.querySelector("html");
      if (html) {
        html.style.overflow = "hidden";
      }
    }

    if (prevProps.isShowing === true && isShowing === false) {
      const html = document.querySelector("html");

      if (html) {
        html.style.removeProperty("overflow");
      }
    }
  }

  get modalClassName() {
    const { customClass, data } = this.props;
    return customClass || data?.customClass || "";
  }

  setBackdropLoader(bool) {
    this.setState({
      loading: bool,
    });
  }

  setBypassClickCheck(bool) {
    this.setState({
      bypassClickCheck: bool,
    });
  }

  setDisableBackdropClick(bool) {
    this.setState({
      disableBackdropClick: bool,
    });
  }

  backdropClick() {
    const { disableBackdropClick, bypassClickCheck } = this.state;
    const { data, modalAction } = this.props;
    if (!disableBackdropClick) {
      if (data?.backdropClickCheck && !bypassClickCheck) {
        Swal.fire({
          title: data.backdropClickCheck.title
            ? data.backdropClickCheck.title
            : "",
          text: data.backdropClickCheck.text,
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonAriaLabel: "Yes",
          cancelButtonAriaLabel: "cancel",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            modalAction(false, "", {});
          }
        });
      } else {
        modalAction(false, "", {});
      }
    }
  }

  render() {
    const { isShowing, page, modalAction, data, id, children } = this.props;
    const { loading } = this.state;
    if (isShowing && !this.listenerPresent) {
      this.listenerPresent = true;
      window.addEventListener("keydown", this.handleEsc);
    } else if (!isShowing && this.listenerPresent) {
      this.listenerPresent = false;
      window.removeEventListener("keydown", this.handleEsc);
    }

    const additionalProps = {};

    if (data?.disableBackdropClick) {
      additionalProps.disableBackdropClick = this.setDisableBackdropClick;
    }

    if (data?.setBypassClickCheck) {
      additionalProps.setBypassClickCheck = this.setBypassClickCheck;
    }

    const Page = page && page.page ? page.page : page;

    return ReactDOM.createPortal(
      <div>
        <div
          id={id || ""}
          className={`new-modal ${this.modalClassName} ${
            isShowing ? "new-modal-open" : "new-modal-close"
          }`}
          tabIndex={-1}
        >
          {isShowing && (
            <div>
              {Page && (
                <Page
                  {...additionalProps}
                  data={data || {}}
                  modalAction={modalAction}
                  setBackdropLoader={this.setBackdropLoader}
                />
              )}
              {children}
            </div>
          )}
        </div>
        <div
          className={`modal-backdrop ${
            isShowing ? "modal-backdrop-open" : "modal-backdrop-close"
          }`}
          onClick={this.backdropClick}
        />
        <div
          className={`modal-backdrop-loader ${
            loading
              ? "modal-backdrop-loader-open"
              : "modal-backdrop-loader-close"
          }`}
        >
          {loading && <div id="test-step-bugs-loader" />}
        </div>
      </div>,
      document.querySelector("body")
    );
  }
}

ReactModalV2.defaultProps = {
  data: {},
  page: null,
  customClass: "",
  isShowing: false,
  id: null,
  children: null,
};

ReactModalV2.propTypes = {
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /* rendering modal content:
   *
   * When this component was initially set up, it expected the modal body to be contained
   * in a Page prop, with the props to be passed to that component diverted to the data
   * prop passed to this component. This is a pretty convoluted and unperformant anti-pattern,
   * when we could easily just pass children as a prop. Have left the Page prop to be
   * backwards-comaptible, recommend when using this in the future to simply give your
   * modal content as children, and pass any props that child needs directly rather than
   * as a data prop of this component.
   */
  page: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  customClass: PropTypes.string,
  isShowing: PropTypes.bool,
  id: PropTypes.string,
  modalAction: PropTypes.func.isRequired,
};

export default ReactModalV2;
