import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import queryString from "query-string";
import { WidgetTitle, TitleRow, TitleSection } from "../styles";
import PerformanceBreakdownIcon from "../Components/PerformanceBreakdownIcon";
import MetricIndicator from "../Components/MetricIndicator";
import { BugsBreakdown, TestStepBreakdown } from ".";
import BugsOutline from "../../icons/bugs_outline";
import ChecklistIcon from "../../icons/checklist_icon";
import PerformanceProjectDropdown from "../Components/PerformanceProjectDropdown";
import PerformanceDateRange from "../Components/PerformanceDateRange";
import { PROJECT_ID } from "../constants";

const ProfileBreakdown = (props) => {
  const { bugs, metrics, loading, projects, queryParams } = props;

  const handleProjectSelect = useCallback(
    (id) => {
      const nextParams = {
        ...queryParams,
        [PROJECT_ID]: id || null,
      };
      const nextParamsWithoutNulls = _.omitBy(nextParams, _.isNull);
      const search = queryString.stringify(nextParamsWithoutNulls);
      window.history.pushState({}, "", `?${search}`);
    },
    [queryParams]
  );

  const handleDateRangeSelect = useCallback(
    (start_date, end_date) => {
      const nextParams = {
        ...queryParams,
        start_date,
        end_date,
      };
      const search = queryString.stringify(nextParams);
      window.history.pushState({}, "", `?${search}`);
    },
    [queryParams]
  );

  return (
    <ProfileBreakdownContainer>
      <TitleRow>
        <TitleSection>
          <PerformanceBreakdownIcon />
          <WidgetTitle>Performance Breakdown</WidgetTitle>
        </TitleSection>
        <FiltersSection>
          <PerformanceProjectDropdown
            projectsList={projects}
            queryParams={queryParams}
            onProjectSelect={handleProjectSelect}
          />
          <PerformanceDateRange
            queryParams={queryParams}
            onDateRangeChange={handleDateRangeSelect}
          />
        </FiltersSection>
      </TitleRow>
      <MetricRow>
        <MetricIndicator
          label="New Bugs"
          value={metrics?.new_bugs}
          icon={<BugsOutline height="33px" width="43px" color="#4574EC" />}
        />
        <MetricIndicator label="Updated Bugs" value={metrics?.updated_bugs} />
        <MetricIndicator
          label="Hourly Bug Average"
          value={metrics?.hourly_bugs}
        />
      </MetricRow>
      <ChartWrapper>
        <BugsBreakdown data={bugs} loading={loading} />
      </ChartWrapper>
      <MetricRow>
        <MetricIndicator
          label="Test Steps"
          value={metrics?.test_steps}
          icon={<ChecklistIcon height="33px" width="43px" color="#FD772B" />}
        />
        <MetricIndicator
          label="Hourly Test Step Average"
          value={metrics?.hourly_test_steps}
        />
        <MetricIndicator label="Test Runs" value={metrics?.test_runs} />
      </MetricRow>
      <ChartWrapper>
        <TestStepBreakdown data={bugs} loading={loading} />
      </ChartWrapper>
    </ProfileBreakdownContainer>
  );
};

const ProfileBreakdownContainer = styled.div``;

const FiltersSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MetricRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  margin-top: 12px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 24px 36px;
  margin-top: 12px;
`;

ProfileBreakdown.propTypes = {
  bugs: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      label: PropTypes.string,
      new_bugs: PropTypes.number,
      updated_bugs: PropTypes.number,
      test_steps: PropTypes.number,
      test_runs: PropTypes.number,
    })
  ),
  metrics: PropTypes.shape({
    new_bugs: PropTypes.number,
    updated_bugs: PropTypes.number,
    hourly_bugs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hours: PropTypes.number,
    test_steps: PropTypes.number,
    hourly_test_steps: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    test_runs: PropTypes.number,
  }),
  loading: PropTypes.bool,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      identifier: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
  }),
};

export default ProfileBreakdown;
