import React, { useContext, useState, useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import ApiClient from "../../../utils/ApiClient";
import ProfileContext from "../ProfileContext";
import EditNotepadIcon from "../../icons/edit_notepad_icon";
import LoadingSpinner from "../../common/loading_spinner";

const TitleDisplay = ({ user }) => {
  const { current_user: sessionUser, setProfileData } =
    useContext(ProfileContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userTitle, setUserTitle] = useState(user.title);
  const originalUserTitle = user.title;
  const api = new ApiClient();

  const isEditable = useMemo(() => {
    return sessionUser.role === "admin";
  }, [sessionUser]);

  const inputRef = useRef();

  const handleOpenEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setUserTitle(originalUserTitle);
    if (userTitle === "") setUserTitle(originalUserTitle);
    setIsEditing(false);
  };

  const handleSubmitTitle = () => {
    if (userTitle === user.title) {
      setIsEditing(false);
      return null;
    }

    setIsSubmitting(true);
    api
      .patch(`/users/${user.id}/admin_update`, {
        user: { id: user.id, title: userTitle },
      })
      .then((res) => {
        const updatedUser = { ...user, title: res.data.title };

        setIsEditing(false);

        setProfileData((prevData) => ({
          ...prevData,
          user: updatedUser,
        }));
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Title updated successfully.",
          classes: "green",
        });
      })
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Something went wrong while updating title.",
          classes: "red",
        });
      })
      .finally(() => {
        setIsEditing(false);
        setIsSubmitting(false);
      });

    return null;
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [isEditing]);

  if (!user || !sessionUser) {
    return null;
  }

  return (
    <TitleContainer>
      <ControlsRow>
        {!isEditing && <TitleText>{userTitle}</TitleText>}

        {isEditable && (
          <Controls>
            {isEditing && (
              <>
                <SubmitButton type="button" onClick={handleSubmitTitle}>
                  <svg
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.76695 10.3226L2.28331 6.66703L2.21117 6.59133L2.13877 6.66678L0.927851 7.92852L0.861632 7.99752L0.927607 8.06675L5.69452 13.069L5.76691 13.145L5.83931 13.069L16.0724 2.33073L16.1381 2.26174L16.0724 2.19276L14.87 0.931013L14.7976 0.855082L14.7253 0.930978L5.76695 10.3226Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.2"
                    />
                  </svg>
                </SubmitButton>
                <CancelButton type="button" onClick={handleCancelEdit}>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.73488 6L0 1.26512L1.26512 0L6 4.73488L10.7349 0L12 1.26512L7.26512 6L12 10.7349L10.7349 12L6 7.26512L1.26512 12L0 10.7349L4.73488 6Z"
                      fill="#242424"
                    />
                  </svg>
                </CancelButton>
              </>
            )}
          </Controls>
        )}
      </ControlsRow>

      <TitleBodyContainer $isEditable={isEditable} $isEditing={isEditing}>
        {isEditable && !isEditing && (
          <OpenEditButton type="button" onClick={handleOpenEditClick}>
            <EditNotepadIcon height="16px" width="16px" color="#fff" />
          </OpenEditButton>
        )}
        {isEditing && (
          <TitleTextarea
            ref={(node) => {
              inputRef.current = node;
            }}
            value={userTitle}
            maxLength={35}
            onChange={(e) => setUserTitle(e.target.value)}
          />
        )}
      </TitleBodyContainer>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  position: relative;
  bottom: 71px;
`;

const TitleText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  color: #656565;
  font-family: manrope;
  width: 100%;
  position: relative;
  top: 2px;
`;

const PositionedLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  right: -50px;
`;

const Controls = styled.div`
  display: flex;
  gap: 6px;
  position: absolute;
  right: -7px;
  top: 38px;
`;
const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: -8px;
  padding-bottom: 3px;
  text-align: center;
`;
const ControlButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }
`;

const CancelButton = styled(ControlButton)`
  background-color: #d3d3d3;
  height: 17px !important;
  width: 17px !important;
  padding: 3px;

  &:focus,
  &:focus-visible,
  &:active {
    background-color: #d3d3d3;
  }
`;

const SubmitButton = styled(ControlButton)`
  background-color: #519acc;
  height: 17px !important;
  width: 17px !important;
  padding: 3px;

  &:focus,
  &:focus-visible,
  &:active {
    background-color: #519acc;
  }
`;

const OpenEditButton = styled.button`
  position: absolute;
  top: 5px;
  right: 2px;
  opacity: 0;
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #519acc;
  border-radius: 4px;
  border: none;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    border: none;
    background-color: #519acc;
  }
`;

const NotEditingStyle = css`
  border-color: transparent;

  &:hover {
    ${OpenEditButton} {
      opacity: 1;
    }
  }
`;

const IsEditingStyle = css`
  border-color: #d3d3d3;

  &:active,
  &:focus,
  &:focus-visible {
    border-color: #519acc;
  }
`;
const TitleBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  bottom: ${({ $isEditing }) => ($isEditing ? "5px" : "33px")};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: opacity, border-color;
  margin: 0 -8px;
  min-width: 246px;
  height: 36px;
  padding: ${({ $isEditing, $isEditable }) =>
    !$isEditable || $isEditing ? "0px" : "4px"};

  ${(props) => (props.$isEditing ? IsEditingStyle : NotEditingStyle)}

  &:hover {
    border-color: #519acc;
  }
`;

const TitleTextarea = styled.textarea`
  border: none;
  background-color: transparent;
  padding-right: 16px;
  height: 36px;
  padding: 8px;
  padding-top: 10px;
  overflow: hidden;
  resize: none;

  &:focus,
  &:focus-visible {
    outline: none;
    border: none;
  }
`;

TitleDisplay.propTypes = {
  userTitle: PropTypes.string,
};

export default TitleDisplay;
