import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import TrialistPage from './trialist_page'
import TrialistPageLink from './trialist_page_link'
import axios from 'axios'

class TrialistDirectory extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      pages: this.props.pages ? this.props.pages : [],
      showPage: this.props.showPage,
      editingPage: false,
      dragging: false,
      direction: "node-forwards",
      loading: false,
      cachedPages: {}
    }
  }
  componentDidMount(){
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}

    var elem = document.getElementById("appVersionInput")
    if(elem && elem.parentElement){
      elem.parentElement.style.display = "none"
    }
    elem = document.getElementById("main")
    if(elem){
      elem.style.overflowX = "hidden"
    }
    elem = document.querySelector("footer.page-footer")
    if(elem){
      elem.style.height = "40px"
    }
    if(!this.props.pages){
      this.setState({loading: true})
      axios.get(`/trialist`,{
        headers:{'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'}
      })
      .then((res)=>{
        this.setState({pages: res.data.trialist_pages, loading:false})
      })
      .catch((err)=>{
        this.setState({loading: false})
        console.error(err)
      })
    }
  }

  componentWillUnmount(){
    var elem = document.getElementById("appVersionInput")
    if(elem && elem.parentElement){
      elem.parentElement.style.display = "block"
    }
    elem = document.getElementById("main")
    if(elem){
      elem.style.overflowX = "auto"
    }
    elem = document.querySelector("footer.page-footer")
    if(elem){
      elem.style.height = "auto"
    }
    window.removeEventListener('popstate', this.handleNavButtons, false);
  }

  handleAddPage = (e) => {
    var pages = this.state.pages
    pages.unshift({id:"new", subpage:false, title:"", position: 0, day: parseInt(e.target.value)})
    this.setState({pages, editingPage: "new"})
  }

  handlePageEdit = (id) => {
    if(this.state.editingPage === "new"){
      var pages = this.state.pages
      pages.splice(0,1)
      this.setState({editingPage:id, pages})
    } else {
      this.setState({editingPage:id})
    }
    
  }

  handleDragStart = (id, position, day) => {
    this.setState({dragging: {id, position, day}})
  }

  handleDragEnd = (e) => {
    this.setState({dragging: false})
  }
  
  handleDragDrop = (id, position, day) => {
    if(!this.state.loading){
      this.setState({loading: true})
      var trialist_page = day ? {id, position, day} : {id, position}
      axios.patch(`/trialist/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        trialist_page
      })
      .then((res)=>{
        var pages = this.state.pages.map((p)=> {
          return({...p, ...res.data[p.id.toString()]})
        })
        pages.sort((a,b)=> a.position - b.position)
        this.setState({pages, loading: false})
      })
      .catch((err)=>{
        this.setState({loading: false})
        console.error(err)
      })
    }
    
  }

  handlePageSelect = (page, direction) => {
    var page = page
    var direction = direction
    var cachedPages = this.state.cachedPages
    if(!this.state.loading){
      if(page){
        if(!this.props.editMode){
          var stateObj = {}
          window.history.pushState(stateObj, "", `/trialist/${page.id}` )
        }
        if(cachedPages[page.id]){
          cachedPages[page.id] = {...page, trialist_sections: cachedPages[page.id].trialist_sections}
          this.setState({direction},()=>{
            this.setState({showPage: cachedPages[page.id], cachedPages})
          })
        } else {
          this.setState({loading: true})
          axios.get(`/trialist_sections/${page.id}`,{
            headers:{'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'}
          })
          .then((res)=>{
            cachedPages[page.id] = res.data
            this.setState({direction, loading: false},()=>{
              this.setState({showPage: res.data, cachedPages})
            })
          })
          .catch((err)=>{
            this.setState({loading: false})
            console.error(err)
          })
        }  
      } else {
        if(!this.props.editMode){
          var stateObj = {}
          window.history.pushState(stateObj, "", '/trialist/' )
        }
        if (page === null) {
          window.addEventListener('popstate', this.handleNavButtons, false);
        }
        this.setState({direction},()=>{
          this.setState({showPage: false})
        })
      }
    }
  }

  handleNavButtons = (event) => {
    var self = this
      var value = parseInt(event.target.location.pathname.split('/')[event.target.location.pathname.split('/').length - 1 ])
      if (isNaN(value)) {
        // if(event.target.location.pathname === "/trialist/") {
        //   // self.handlePageSelect(null, "node-backwards")
        // } else {
          return false
        // }
      }
      var index = self.state.pages.findIndex((x) => x.id === value)
      self.setState({loading: true})
        axios.get(`/trialist_sections/${value}`,{
          headers:{
              'Accept': 'application/json',
            }
        })
        .then((res)=>{
          self.handleUpdateCache(res.data)
          self.setState({showPage: res.data})
          window.removeEventListener('popstate', self.handleNavButtons, false);
        })
        .catch((err)=>{
          self.setState({loading: false})
          console.error(err)
        })
  }

  handlePageUpdate = (id, title, subpage) => {
    if(!this.state.loading){
      this.setState({loading:true})
      var trialist_page = {title, subpage}
      axios.patch(`/trialist/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        trialist_page
      })
      .then((res)=>{
        var pages = this.state.pages.map((p)=> p.id === res.data.id ? {...p, ...res.data} : p)
        this.setState({pages, editingPage:false, loading:false})
      })
      .catch((err)=>{
        this.setState({loading:false})
        console.error(err)
      })
    }
    
  }

  handlePageCreate = (title, subpage, day) => {
    if(!this.state.loading){
      var trialist_page = {title, subpage, day, position: 1}
      this.setState({loading:true})
      axios.post(`/trialist`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        trialist_page
      })
      .then((res)=>{
        var pages = this.state.pages.map((p)=> p.id==="new" ? res.data.new_page : ({...p, ...res.data.pages[p.id.toString()]}))
        this.setState({pages, editingPage:false, loading:false})
      })
      .catch((err)=>{
        this.setState({loading:false})
        console.error(err)
      })
    }
  }

  handlePageDelete = (id) => {
    if(!this.state.loading){
      this.setState({loading:true})
      axios.delete(`/trialist/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res)=>{
        var pages = this.state.pages.filter((p)=>p.id!==res.data.delete_id).map((p)=> ({...p, ...res.data.pages[p.id.toString()]}))
        this.setState({pages, loading: false})
      })
      .catch((err)=>{
        this.setState({loading:false})
        console.error(err)
      })
    }
    
  }

  handleUpdateCache = (page) => {
    var cachedPages = this.state.cachedPages
    cachedPages[page.id] = page
    this.setState({cachedPages})
  }

  render(){
    var editMode = this.props.editMode
    var dayOnePages = []
    var dayTwoPages = []
    this.state.pages.forEach((p)=>{p.day===1?dayOnePages.push(p):dayTwoPages.push(p)})
    return(
      <div style={{paddingRight:!this.state.showPage?"10px":"0px", height: "calc(100vh - 182px)", width: "100%", marginTop:this.props.editMode?"5px":"0"}}>
        <SwitchTransition>
          <CSSTransition 
            key={!this.state.showPage}
            timeout={{
              appear: 300,
              enter: 390,
              exit: 390,
            }}
            classNames={this.state.direction} 
          >
            {!this.state.showPage ? 
              <div id="trial-directory" className="new-scrollbar">
                <div style={{marginRight:"200px"}} className={`trial-day-container  ${editMode?"edit":""}`}>
                  <div>
                    <h2 style={{marginBottom:"20px"}}>Day 1</h2>
                    {editMode &&
                      <button
                        disabled={this.state.editingPage}
                        onClick={this.handleAddPage}
                        value={1}
                        style={{position:"absolute", right: "30px", top:"20px"}} 
                        className="common-button-submit">
                          Add Page
                      </button>
                    }
                    {dayOnePages.map((page)=>{
                      return(
                        <TrialistPageLink 
                          dragging={this.state.dragging} 
                          handleDragEnd={this.handleDragEnd} 
                          handleDragStart={this.handleDragStart} 
                          handleDragDrop={this.handleDragDrop}
                          handlePageSelect={this.handlePageSelect}
                          handlePageEdit={this.handlePageEdit}
                          handlePageUpdate={this.handlePageUpdate}
                          handlePageCreate={this.handlePageCreate}
                          handlePageDelete={this.handlePageDelete}
                          handleUpdateCache={this.handleUpdateCache}
                          editingPage={this.state.editingPage} 
                          page={page} key={page.id} 
                          editMode={editMode} 
                        />
                      )
                    })}
                  </div>
                </div>
                <div className={`trial-day-container ${editMode?"edit":""}`}>
                  <h2 style={{marginBottom:"20px"}}>Day 2</h2>
                  {editMode &&
                    <button
                      disabled={this.state.editingPage}
                      onClick={this.handleAddPage}
                      value={2}
                      style={{position:"absolute", right: "30px", top:"20px"}} 
                      className="common-button-submit">
                        Add Page
                    </button>
                  }
                  {dayTwoPages.map((page)=>{
                    return(
                      <TrialistPageLink 
                        dragging={this.state.dragging} 
                        handleDragEnd={this.handleDragEnd} 
                        handleDragStart={this.handleDragStart} 
                        handleDragDrop={this.handleDragDrop}
                        handlePageSelect={this.handlePageSelect}
                        handlePageEdit={this.handlePageEdit}
                        handlePageUpdate={this.handlePageUpdate}
                        handlePageCreate={this.handlePageCreate}
                        handlePageDelete={this.handlePageDelete}
                        editingPage={this.state.editingPage}  
                        page={page} key={page.id} 
                        editMode={editMode}
                      />
                    )
                  })}
                  <img style={{width:"256px", marginTop:"30px"}} src="https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/scientist_tess.png"/>
                </div>
              </div> :
              <div>
                <TrialistPage 
                  pages={this.state.pages} 
                  showPage={this.state.showPage}
                  handlePageSelect={this.handlePageSelect}
                  handleUpdateCache={this.handleUpdateCache}
                  cachedPages={this.state.cachedPages}
                  editMode={editMode}
                />
              </div>
              
            }
          </CSSTransition>    
        </SwitchTransition>
      </div>
    )
  }
}

export default TrialistDirectory