import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import User from "./user";
import UserModal from "./user_modal";
import ErrorBoundary from "../common/error_boundary";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      base_users: [],
      loaded: false,
      searchString: "",
      modalOpen: false,
      active: true,
      sortBy: "",
      sortAsc: true,
      firstRender: true,
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  newUserHandler = (user) => {
    const sortedUsers = this.state.users;
    // change build histories link if last project
    const array = sortedUsers;
    array.push(user);
    const newUsers = array.sort((a, b) => {
      const aString = a.name != undefined ? a.name : a.email;
      const bString = b.name != undefined ? b.name : b.email;
      if (aString.toLowerCase() < bString.toLowerCase()) {
        return -1;
      }
      if (aString.toLowerCase() > bString.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    this.setState({ users: newUsers });
  };

  handleDeleteUser = (userID) => {
    const { users } = this.state;
    // change build histories link if lastteam
    for (let i = 0; i < users.length; i++) {
      if (users[i].id == userID) {
        users.splice(i, 1);
        break;
      }
    }
    this.setState({ users });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.firstRender == true) {
      this.setState({ firstRender: false });
    }
  }

  handleEditUser = (user) => {
    const { users } = this.state;
    // change build histories link if lastteam
    for (let i = 0; i < users.length; i++) {
      if (users[i].id == user.id) {
        users.splice(i, 1, user);
        break;
      }
    }
    this.setState({ users });
  };

  getUsers = () => {
    this.props.loadingHandler();
    axios
      .get("/admin_panel/users", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.props.loadingHandler();
        this.setState({
          users: res.data.users,
          base_users: res.data.users,
          projects: res.data.projects,
          loaded: true,
        });
      })
      .catch((err) => console.error(err));
  };

  clearInput = () => {
    const elem = document.getElementById("bug-search-input");
    if (elem) {
      elem.focus();
      elem.value = "";
    }
    this.setState({ searchString: "" });
  };

  handleClick = () => {
    this.open();
  };

  open = () => {
    ReactDOM.render(
      <UserModal
        action="invite"
        currentUser={this.props.current_user}
        handler={this.handleEditUser}
        createHandler={this.newUserHandler}
        projects={this.state.projects}
        users={this.state.users}
      />,
      document.getElementById("modalContainer")
    );
  };

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  onInviteSubmit = (formData) => {
    const self = this;
    const { id } = this.props.organization;
    const xhr = new XMLHttpRequest();
    const action = "POST";
    const url = `/organizations/${id}/invites.json`;

    xhr.open(action, url, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 201) {
        var data = xhr.response;
        self.setState({ data, submitted: true });
        this.close();
        M.toast({ html: "Invite Sent", classes: "green darken-1 error-toast" });
        $("#invite_email").removeClass("valid");
      } else if (xhr.status === 200) {
        var data = xhr.response;
        M.toast({ html: "Invite Sent", classes: "green darken-1 error-toast" });
        $("#invite_email").removeClass("valid");
      }
    }.bind(this);
    xhr.send(formData);
  };

  onRoleSubmit = (formData) => {
    const { id } = this.state.userId;
    const xhr = new XMLHttpRequest();
    const action = "PATCH";
    const url = `/memberships/${id}.json`;
    xhr.open(action, url, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 201) {
        const { data } = self.state;
        M.toast({
          html: "Role Updated",
          classes: "green darken-1 error-toast",
        });
      } else if (xhr.status === 200) {
        const resp = JSON.parse(xhr.responseText);
        let { users } = this.state;
        const index = users
          .map((e) => {
            return e.id;
          })
          .indexOf(resp.membership.id);
        const new_array = users.filter((_, i) => i !== index);
        users = new_array;
        users.push(resp.membership);
        this.setState({ users: new_array });
        this.closeEdit();
        M.toast({
          html: "Role Updated",
          classes: "green darken-1 error-toast",
        });
      } else {
        M.toast({
          html: "There was an issue updating this user",
          classes: "red darken-1 error-toast",
        });
      }
    }.bind(this);
    xhr.send(formData);
  };

  handleRoleSubmit = (e) => {
    e.preventDefault();
    const roleId = $("#role-select").val();
    if (!roleId) {
      return;
    }
    const formData = new FormData();
    formData.append("membership[organization_role_id]", roleId);
    this.onRoleSubmit(formData);
  };

  sortUsers = (a, b) => {
    const user1 = a;
    const user2 = b;
    if (this.state.sortBy === "created_at") {
      const date1 = new Date(user1[this.state.sortBy]);
      const date2 = new Date(user2[this.state.sortBy]);
      if (this.state.sortAsc) {
        return date1 - date2;
      }
      return date2 - date1;
    }
    if (user1[this.state.sortBy] && user2[this.state.sortBy]) {
      if (this.state.sortAsc) {
        return user1[this.state.sortBy].localeCompare(user2[this.state.sortBy]);
      }
      return user2[this.state.sortBy].localeCompare(user1[this.state.sortBy]);
    }
  };

  updateSearch = (event) => {
    this.setState({ searchString: event.target.value });
  };

  setSortUsers = (e) => {
    if (this.state.sortBy === e.target.id) {
      this.setState({
        sortAsc: !this.state.sortAsc,
      });
    } else {
      this.setState({
        sortAsc: true,
        sortBy: e.target.id,
      });
    }
  };

  render() {
    let users = this.state.users.filter((user) => {
      if (this.state.active == user.active) {
        return true;
      }
    });
    if (this.state.sortBy) {
      users.sort(this.sortUsers);
    }
    const filteredUsers = users.filter((user) => {
      var string =
        user.name != null ? user.name : user.email != null ? user.email : "";
      if (
        string.toLowerCase().indexOf(this.state.searchString.toLowerCase()) !==
        -1
      ) {
        return true;
      }
      for (let x = 0; x < user.teams.length; x++) {
        if (
          user.teams[x].name &&
          user.teams[x].name
            .toLowerCase()
            .indexOf(this.state.searchString.toLowerCase()) !== -1
        ) {
          return true;
        }
      }
      var string = user.email ? user.email : "";
      return (
        string.toLowerCase().indexOf(this.state.searchString.toLowerCase()) !==
        -1
      );
    });

    if (this.state.searchString != "") {
      users = filteredUsers;
    }

    const delay = 666 / users.length < 100 ? 666 / users.length : 100;

    return (
      <div className="col s12" id="users-main" style={{ paddingRight: "0px" }}>
        <div style={{ display: "flex", margin: "10px 0" }}>
          <button
            style={{ marginRight: "20px" }}
            className="btn btn-overflow"
            onClick={this.handleClick}
          >
            Add New User
          </button>
          <div
            id="ticketSearch"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              style={{
                position: "absolute",
                pointerEvents: "none",
                left: "6px",
                color: "#ababab",
              }}
              className="material-icons prefix"
            >
              search
            </i>
            <input
              id="bug-search-input"
              className="browser-default"
              type="text"
              onChange={this.updateSearch}
              placeholder=" "
            />
            {this.state.searchString != "" && (
              <button
                onClick={this.clearInput}
                className="link-btn material-icons grey-text"
              >
                close
              </button>
            )}
          </div>
          <button
            style={{ padding: "0 5px 0 0", margin: "0 10px 0 20px" }}
            disabled={this.state.active}
            className="arial bold elPt link-btn pointer admin-add-button"
            onClick={this.toggleActive}
          >
            Active
          </button>
          <button
            style={{ padding: "0 5px" }}
            disabled={!this.state.active}
            className="arial bold elPt link-btn pointer admin-add-button"
            onClick={this.toggleActive}
          >
            Retired
          </button>
        </div>
        <table className="striped highlight" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr
              style={{
                fontWeight: 600,
                backgroundColor: "white",
                lineHeight: "25px",
              }}
              key="header"
            >
              <th
                style={{ position: "relative", whiteSpace: "nowrap" }}
                className={`heading-hover ${
                  this.state.sortBy === "name" ? "heading-selected" : ""
                }`}
                id="name"
                onClick={this.setSortUsers}
              >
                User{" "}
                {this.state.sortBy === "name" ? (
                  this.state.sortAsc ? (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_down
                    </i>
                  ) : (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_up
                    </i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th
                style={{ position: "relative", whiteSpace: "nowrap" }}
                className={`heading-hover ${
                  this.state.sortBy === "email" ? "heading-selected" : ""
                }`}
                id="email"
                onClick={this.setSortUsers}
              >
                Email{" "}
                {this.state.sortBy === "email" ? (
                  this.state.sortAsc ? (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_down
                    </i>
                  ) : (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_up
                    </i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th
                width="80px"
                style={{ position: "relative", whiteSpace: "nowrap" }}
                className={`heading-hover ${
                  this.state.sortBy === "created_at" ? "heading-selected" : ""
                }`}
                id="created_at"
                onClick={this.setSortUsers}
              >
                Created{" "}
                {this.state.sortBy === "created_at" ? (
                  this.state.sortAsc ? (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_down
                    </i>
                  ) : (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_up
                    </i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th width="100px">Last Login</th>
              <th
                style={{
                  width: "75px",
                  position: "relative",
                  whiteSpace: "nowrap",
                }}
                className={`heading-hover ${
                  this.state.sortBy === "role" ? "heading-selected" : ""
                }`}
                id="role"
                onClick={this.setSortUsers}
              >
                Role{" "}
                {this.state.sortBy === "role" ? (
                  this.state.sortAsc ? (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_down
                    </i>
                  ) : (
                    <i className="material-icons sort-arrows">
                      keyboard_arrow_up
                    </i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th>Team</th>
              <th style={{ width: "50px" }}>Active</th>
              <th style={{ width: "50px" }}>Edit</th>
              <th style={{ width: "50px" }}>Delete</th>
              <th style={{ width: "75px" }}>Impersonate</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <ErrorBoundary key={index}>
                <User
                  i={index}
                  delay={delay}
                  loadingHandler={this.props.loadingHandler}
                  editClick={this.handleEditClick}
                  user={user}
                  handleDeleteUser={this.handleDeleteUser}
                  handler={this.newUserHandler}
                  handleEditUser={this.handleEditUser}
                />
              </ErrorBoundary>
            ))}
          </tbody>
        </table>
        <div className="col s12">
          {this.state.base_users.length < 1 && this.state.loaded && (
            <div className="white center" id="noUsersContainer">
              <div className="no-box-shadow card col s6 offset-s3">
                <div className="card-content">
                  <p>No Users currently associated with this organization</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Users;
