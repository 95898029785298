import React, { useState, useRef, useMemo, useEffect } from "react";
import { arrayOf, func } from "prop-types";
import axios from "axios";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import { wikiResourceTypes, wikiFeaturedTopicTypes } from "../../types/wiki";
import { userTypes } from "../../types";
import useDebounce from "../../hooks/useDebounce";
import RoundDropdown from "../icons/round_dropdown";
import EditNotepadIcon from "../icons/edit_notepad_icon";
import AddWikiResource from "./AddWikiResourceModal";
import EditWikiResourceModal from "./EditWikiResourceModal";
import FeaturedTopicCard from "./FeaturedTopicCard";
import ResourceMultiSelect from "./ResourceMultiSelect";
import wikiCategoryValues from "./wikiCategoryValues";

const WikiDirectory = ({
  featuredTopics,
  wikiResources,
  setCurrentResource,
  setDirection,
  user,
}) => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [categoryFilters, setCategoryFilters] = useState([]);

  const debouncedSearch = useDebounce(search, 400);

  const searchInput = useRef();

  const updateSearch = (e) => {
    setSearch(e.target?.value || "");
    setSearchLoading(true);
  };

  const clearInput = () => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
    setSearch("");
    setSearchResults(null);
  };

  const getTopic = (id, topicId) => {
    axios
      .get(`/get_wiki_resource/${id}.json`)
      .then((res) => {
        setCurrentResource(res.data);
        if (topicId) {
          window.history.pushState(
            "page2",
            "Test Platform",
            `/wiki/${id}?topic=${topicId}`
          );
          setTimeout(() => {
            const elem = document.getElementById(`topic-${topicId}`);
            if (elem) {
              elem.scrollIntoView({ behavior: "smooth" });
            }
          }, 1000);
        } else {
          window.history.pushState("page2", "Test Platform", `/wiki/${id}`);
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 400);
        setTimeout(() => {
          setDirection("node-backwards");
        }, 1000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleTopicClick = ({ resourceId, topicId }) => {
    getTopic(resourceId, topicId);
  };

  const filteredWikiResourcesByCategory = useMemo(() => {
    if (categoryFilters.length === 0) {
      return wikiResources;
    }

    return wikiResources.filter((resource) =>
      categoryFilters.includes(resource.category)
    );
  }, [wikiResources, categoryFilters]);

  useEffect(() => {
    if (debouncedSearch === "") {
      setSearchResults(null);
    } else {
      setSearchLoading(true);
      axios
        .get(`/wiki_search.json?query=${debouncedSearch}`)
        .then((res) => {
          setSearchResults(res.data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setSearchLoading(false));
    }
  }, [debouncedSearch]);

  return (
    <div style={{ marginTop: "30px" }}>
      <h2>Featured Topics</h2>
      <div className="help-divider">
        <div />
        <div />
        <div />
      </div>
      <div style={{ marginTop: "7px" }} id="help-featured-container">
        <FeaturedTopics>
          {featuredTopics.map((t) => {
            return (
              <FeaturedTopicCard
                key={t.id}
                featuredTopic={t}
                handleClick={handleTopicClick}
              />
            );
          })}
        </FeaturedTopics>
      </div>

      <WikiResourcesGridHeader>
        <FlexItem>
          <span>
            <SearchContainer>
              <CSSTransition
                unmountOnExit
                in={searchLoading}
                timeout={200}
                classNames="node-fade"
              >
                <div
                  style={{
                    position: "absolute",
                    right: "-32px",
                  }}
                  id="first-build-spinner"
                />
              </CSSTransition>
              <i aria-hidden="true" className="material-icons prefix">
                search
              </i>
              <input
                ref={searchInput}
                value={search}
                className="browser-default"
                type="text"
                onChange={updateSearch}
                placeholder="Search Wiki Resources"
                autoComplete="false"
                aria-live="polite"
              />
              {search !== "" && (
                <button
                  type="button"
                  onClick={clearInput}
                  className="link-btn material-icons grey-text"
                >
                  close
                </button>
              )}
            </SearchContainer>
          </span>
        </FlexItem>
        <FlexItem>
          <span>
            <h2>
              {Array.isArray(searchResults)
                ? "Search Results"
                : "Wiki Resources"}
            </h2>
          </span>
        </FlexItem>
        <FlexItem>
          <span>
            <ResourceMultiSelect
              categoryFilters={categoryFilters}
              setCategoryFilters={setCategoryFilters}
            />
          </span>
        </FlexItem>
      </WikiResourcesGridHeader>
      <div className="help-divider">
        <div />
        <div />
        <div />
      </div>

      {!Array.isArray(searchResults) ? (
        <ResourcesGridContainer>
          <ResourcesGrid>
            {filteredWikiResourcesByCategory.map((r) => {
              const image = Object.prototype.hasOwnProperty.call(
                wikiCategoryValues,
                r.category
              )
                ? wikiCategoryValues[r.category].image
                : wikiCategoryValues.misc.image;

              const Icon = Object.prototype.hasOwnProperty.call(
                wikiCategoryValues,
                r.category
              )
                ? wikiCategoryValues[r.category].icon
                : () => null;
              return (
                <ResourceCard
                  key={r.id}
                  className="help-resource-card"
                  $cardBg={image}
                >
                  <TopicButton
                    type="button"
                    onClick={() => handleTopicClick({ resourceId: r.id })}
                  >
                    <h3 data-resource-id={r.id}>{r.title}</h3>
                  </TopicButton>
                  {r &&
                    r.wiki_topics &&
                    r.wiki_topics.slice(0, 5).map((t) => {
                      return (
                        <TopicButton
                          type="button"
                          onClick={() =>
                            handleTopicClick({
                              resourceId: r.id,
                              topicId: t.id,
                            })
                          }
                          data-resource-id={r.id}
                          data-topic-id={t.id}
                          className="light-button"
                          key={t.id}
                        >
                          <span>{t.title}</span>
                        </TopicButton>
                      );
                    })}
                  <ControlsRow>
                    {r && r.wiki_topics && r.wiki_topics.length > 5 && (
                      <ViewTopicsButton
                        onClick={() => handleTopicClick({ resourceId: r.id })}
                        data-resource-id={r.id}
                        style={{ color: "#242424", padding: "0 10px" }}
                      >
                        View All Topics{" "}
                        <RoundDropdown
                          svgStyles={{
                            transform: "rotate(-90deg) translate(-2px, 5px)",
                          }}
                          color="#242424"
                        />
                      </ViewTopicsButton>
                    )}
                    {user && user.role === "admin" && (
                      <EditResourceButton resource={r}>
                        <EditResourceButtonLabel>
                          <div>
                            <EditNotepadIcon
                              height="14"
                              width="14"
                              color="#ffffff"
                            />
                          </div>
                          <EditResourceText>Edit</EditResourceText>
                        </EditResourceButtonLabel>
                      </EditResourceButton>
                    )}
                  </ControlsRow>
                  <CategoryIconContainer>
                    <Icon />
                  </CategoryIconContainer>
                </ResourceCard>
              );
            })}
            {filteredWikiResourcesByCategory.length === 0 && (
              <NoResourcesFoundMessage>
                No Resource Found
              </NoResourcesFoundMessage>
            )}
            {user && user.role === "admin" && <AddWikiResource />}
          </ResourcesGrid>
        </ResourcesGridContainer>
      ) : (
        <div id="help-search-results" style={{ minHeight: "250px" }}>
          {searchResults.length > 0 ? (
            <div>
              {searchResults.map((t) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={t.id}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleTopicClick({
                          resourceId: t.wiki_resource_id,
                          topicId: t.id,
                        })
                      }
                      style={{
                        display: "block",
                        color: "#519acc",
                        fontSize: "16px",
                        paddingRight: "4px",
                      }}
                      data-resource-id={t.wiki_resource_id}
                      data-topic-id={t.id}
                      className="light-button"
                    >
                      {t.title}
                    </button>
                    <span style={{ color: "#b3b3b3", fontStyle: "italic" }}>
                      {" "}
                      - {t.wiki_resource_title}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              style={{
                textAlign: "center",
                color: "#bfbfbf",
                marginTop: "30px",
                fontSize: "15px",
              }}
            >
              No Search Results Found
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const commonButtonStyles = css`
  border-radius: 14px;
  height: 27px;
  width: auto;
  font-family: "Manrope";
`;

const EditResourceButton = styled(EditWikiResourceModal)`
  ${commonButtonStyles}
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:enabled:hover {
    background-color: transparent;
  }
`;

const EditResourceButtonLabel = styled.div`
  height: 23px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 10px;

  div {
    height: 23px;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
  }
`;

const FeaturedTopics = styled.div``;

const WikiResourcesGridHeader = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  padding: 14px;
  padding-bottom: 7px;
`;

const FlexItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  &:first-child > span {
    margin-right: auto;
  }

  &:last-child > span {
    margin-left: auto;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  background-color: #ededed;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 24px;

  input {
    background-color: transparent;
    height: 32px;
    border: none;
    padding: 0 16px;

    &:focus {
      outline: none;
    }
  }
`;

const ResourcesGridContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 14px 0;
  display: flex;
  justify-content: center;
  background-color: #ededed;
`;

const ResourceCard = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;
  flex-basis: calc(100% / 3 - 20px);
  background-image: url("${(props) => props.$cardBg}");
  background-size: cover;

  &:hover {
    ${EditResourceButton} {
      opacity: 1;
    }
  }
`;

const ResourcesGrid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 1156px;
  margin-top: 0;
  margin-bottom: 0;

  & > ${ResourceCard} {
    margin: 0;
    background-repeat: no-repeat;
  }
`;

const EditResourceText = styled.span`
  font-size: 14px;
  font-weight: 800;
  font-family: "Manrope";
`;

const NoResourcesFoundMessage = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopicButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  max-width: 100%;

  h3,
  span {
    /* cut off overflowed text with ellipsis */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
    margin: 0;
  }
`;

const CategoryIconContainer = styled.div`
  position: absolute;
  bottom: 19px;
  right: 17px;
  height: 38px;
  width: 38px;
`;

const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  z-index: 3;
  position: relative;
  height: 27px;
  margin-top: 20px;
  font-family: "Manrope";
`;

const ViewTopicsButton = styled.button`
  ${commonButtonStyles}
  color: #242424;
  text-shadow: 0px 1px #b1b1b166;
  background-color: #ffffff;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  border: none;
  display: inline-block;

  &:enabled:hover {
    background-color: #ffffff;
  }
`;

WikiDirectory.propTypes = {
  featuredTopics: arrayOf(wikiFeaturedTopicTypes),
  wikiResources: arrayOf(wikiResourceTypes),
  setCurrentResource: func.isRequired,
  setDirection: func.isRequired,
  user: userTypes.isRequired,
};

export default WikiDirectory;
