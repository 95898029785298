import React from "react";

const TestCasesBronzeSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1{fill:#a34c21}.Skills__Test_Cases_-_Bronze_-_Small_svg__cls-2{fill:#b85a24}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__Test_Cases_-_Bronze_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <rect
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          x={260.81}
          y={118.74}
          width={557.39}
          height={832.9}
          rx={254.7}
        />{" "}
        <rect
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-2"
          x={260.47}
          y={104.81}
          width={557.39}
          height={832.9}
          rx={254.7}
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          d="M613.57 341.22H466.1l70.53-157.48 76.94 157.48zM463.59 819.12h151.49v53.35c0 6.52-6 11.85-13.34 11.85H476.93c-7.33 0-13.34-5.33-13.34-11.85z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          d="M564.66 322.07h50.42V704a25.21 25.21 0 01-25.21 25.21A25.21 25.21 0 01564.66 704V322v.07z"
          transform="rotate(180 589.865 525.65)"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          d="M514.63 322.07H565V704a25.21 25.21 0 01-25.21 25.21A25.21 25.21 0 01514.63 704V322v.07z"
          transform="rotate(180 539.835 525.65)"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          d="M464.45 322.07h50.42V704a25.21 25.21 0 01-25.21 25.21A25.21 25.21 0 01464.45 704V322z"
          transform="rotate(180 489.665 525.65)"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          transform="rotate(180 539.55 774.15)"
          d="M464.02 729.18h151.05v89.94H464.02z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          transform="rotate(180 576.13 774.165)"
          d="M570.32 729.22h11.62v89.89h-11.62z"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          d="M536.63 183.74L520.08 220a40.45 40.45 0 0016.55 3.35c8.3 0 18.08-3.35 18.08-3.35z"
        />{" "}
        <path
          fill="#ffe68a"
          d="M613.57 329.61H466.1l70.53-157.48 76.94 157.48z"
        />{" "}
        <path
          d="M463.59 807.51h151.49v53.35c0 6.52-6 11.85-13.34 11.85H476.93c-7.33 0-13.34-5.33-13.34-11.85z"
          fill="#ff74af"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-1"
          d="M564.66 310.45h50.42v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 589.865 514.04)"
        />{" "}
        <path
          className="Skills__Test_Cases_-_Bronze_-_Small_svg__cls-2"
          d="M514.63 310.45H565v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 539.835 514.04)"
        />{" "}
        <path
          d="M464.45 310.45h50.42v382a25.21 25.21 0 01-25.21 25.21 25.21 25.21 0 01-25.21-25.21v-382z"
          transform="rotate(-180 489.665 514.04)"
          fill="#ce6029"
        />{" "}
        <path
          transform="rotate(180 539.55 762.54)"
          fill="#5c5c5c"
          d="M464.02 717.57h151.05v89.94H464.02z"
        />{" "}
        <path
          transform="rotate(180 576.13 762.55)"
          fill="#e0e0e0"
          d="M570.32 717.6h11.62v89.89h-11.62z"
        />{" "}
        <path
          d="M536.63 172.13l-16.55 36.29a40.45 40.45 0 0016.55 3.35c8.3 0 18.08-3.35 18.08-3.35z"
          fill="#242424"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default TestCasesBronzeSmall;
