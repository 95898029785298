import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { deviceTypes } from "@types";
import Swal from "sweetalert2";
import styled from "styled-components";
import useDeviceLab from "@hooks/data/useDeviceLab";

const UserDevice = (props) => {
  const { device, fullWidth, active } = props;

  const {
    hooks: {
      mutations: { useCheckInDevice },
    },
  } = useDeviceLab();

  const [checkInDevice] = useCheckInDevice();

  const handleCheckInButton = useCallback(async () => {
    const result = await checkInDevice({ device_id: device.id });

    if (result.data) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "<span role='alert'>Device Checked In Successfully</span>",
        displayLength: 3000,
        classes: "green",
      });
    }

    if (result.error) {
      console.error(result.error);
      Swal.fire("Checkin Error", "There was an error checking in this device");
    }
  }, [device, checkInDevice]);

  const deviceIcon = useMemo(() => {
    const SRC = {
      Mobile: "Icons_Mobile_white.svg",
      Tablet: "Icons_Tablet_white.svg",
      Desktop: "Icons_Desktop_white.svg",
      Laptop: "Icons_Laptop_white.svg",
      Wearable: "Icons_AR_white.svg",
    };

    return SRC[device.device_type] || "Icons_Request_white.svg";
  }, [device]);

  const deviceBackground = useMemo(() => {
    if (device.device_type.includes("Mobile", "Tablet")) {
      return device.device_platform === "iOS" ? "#519acc" : "#43962a";
    }

    return "#414042";
  }, [device]);

  return (
    <UserDeviceListItem
      aria-label={`ID ${device.id}, ${device.device_name}, ${device.device_os}`}
      className="user-device-card card-slide"
      key={`device-card-${device.id}`}
      $fullWidth={fullWidth}
      $active={active}
    >
      <div aria-hidden="true">
        <DeviceBackground $background={deviceBackground} />
        <img
          style={{ zIndex: 1, height: "35px", width: "35px" }}
          alt="device icon"
          src={`https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/${deviceIcon}`}
        />
      </div>
      <div aria-hidden="true" style={{ position: "relative" }}>
        <span>{device.id}</span>
        <span style={{ fontSize: "13px" }}>{device.device_name}</span>
        <span style={{ fontSize: "12px" }}>{device.device_os}</span>
      </div>
      <button
        type="button"
        className="btn btn-overflow"
        aria-label="Check in device"
        onClick={handleCheckInButton}
        data-element="666"
        style={{ marginRight: "15px" }}
      >
        Check In
      </button>
    </UserDeviceListItem>
  );
};

const UserDeviceListItem = styled.li`
  position: relative;
  vertical-align: bottom;
  border: 1px solid transparent;
  ${({ $fullWidth }) => ($fullWidth ? "margin-right: 10px;" : "")}
  ${({ $active }) => ($active ? "border: 1px solid #519acc;" : "")}
`;

const DeviceBackground = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  background-color: ${({ $background }) => $background};
`;

UserDevice.propTypes = {
  device: deviceTypes,
  fullWidth: PropTypes.bool,
  active: PropTypes.bool,
};

export default UserDevice;
