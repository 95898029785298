import React from "react";
import PropTypes from "prop-types";

const AssessmentExpired = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 103 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M81.541 8.58301H21.4577C19.0875 8.58301 17.166 10.5045 17.166 12.8747V90.1247C17.166 92.495 19.0875 94.4164 21.4577 94.4164H81.541C83.9113 94.4164 85.8327 92.495 85.8327 90.1247V12.8747C85.8327 10.5045 83.9113 8.58301 81.541 8.58301Z"
        fill="#519ACC"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M36.4785 64.375H66.5202H36.4785Z" fill="#519ACC" />
      <path
        d="M36.4785 64.375H66.5202"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M36.4785 77.25H51.4993H36.4785Z" fill="#519ACC" />
      <path
        d="M36.4785 77.25H51.4993"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1667 41.8333C52.61 41.8333 57.8333 36.61 57.8333 30.1667C57.8333 23.7233 52.61 18.5 46.1667 18.5C39.7233 18.5 34.5 23.7233 34.5 30.1667C34.5 36.61 39.7233 41.8333 46.1667 41.8333Z"
        fill="white"
      />
      <path
        d="M46.1667 39.3333C51.2293 39.3333 55.3333 35.2293 55.3333 30.1667C55.3333 25.1041 51.2293 21 46.1667 21C41.1041 21 37 25.1041 37 30.1667C37 35.2293 41.1041 39.3333 46.1667 39.3333Z"
        fill="#519ACC"
      />
      <path d="M45.334 22.667H47.0007V30.167H45.334V22.667Z" fill="white" />
      <path
        d="M50.8798 33.4619L49.4657 34.8761L45.459 30.8702L46.8732 29.4561L50.8798 33.4619Z"
        fill="white"
      />
      <path
        d="M46.1667 31.8333C47.0871 31.8333 47.8333 31.0871 47.8333 30.1667C47.8333 29.2462 47.0871 28.5 46.1667 28.5C45.2462 28.5 44.5 29.2462 44.5 30.1667C44.5 31.0871 45.2462 31.8333 46.1667 31.8333Z"
        fill="white"
      />
      <path
        d="M46.1673 30.9997C46.6276 30.9997 47.0007 30.6266 47.0007 30.1663C47.0007 29.7061 46.6276 29.333 46.1673 29.333C45.7071 29.333 45.334 29.7061 45.334 30.1663C45.334 30.6266 45.7071 30.9997 46.1673 30.9997Z"
        fill="#519ACC"
      />
      <path
        d="M41.9175 51.0003L53.9175 30.917C54.5841 29.8337 56.1675 29.8337 56.7508 30.917L68.7508 51.0003C69.4175 52.0837 68.5841 53.5003 67.3341 53.5003H43.3341C42.0841 53.5003 41.2508 52.0837 41.9175 51.0003Z"
        fill="white"
      />
      <path d="M52 47.111L58.6667 40.4443L52 47.111Z" fill="#519ACC" />
      <path
        d="M52 47.111L58.6667 40.4443"
        stroke="#519ACC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M58.6667 47.111L52 40.4443L58.6667 47.111Z" fill="#519ACC" />
      <path
        d="M58.6667 47.111L52 40.4443"
        stroke="#519ACC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

AssessmentExpired.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

AssessmentExpired.defaultProps = {
  height: "103",
  width: "103",
};

export default AssessmentExpired;
