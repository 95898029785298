import React from 'react'

class LinkIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M9.88 13.7a4.3 4.3 0 0 1 0-6.07l3.37-3.37a4.26 4.26 0 0 1 6.07 0 4.3 4.3 0 0 1 0 6.06l-1.96 1.72a.91.91 0 1 1-1.3-1.3l1.97-1.71a2.46 2.46 0 0 0-3.48-3.48l-3.38 3.37a2.46 2.46 0 0 0 0 3.48.91.91 0 1 1-1.3 1.3z"/>
        <path d="M4.25 19.46a4.3 4.3 0 0 1 0-6.07l1.93-1.9a.91.91 0 1 1 1.3 1.3l-1.93 1.9a2.46 2.46 0 0 0 3.48 3.48l3.37-3.38c.96-.96.96-2.52 0-3.48a.91.91 0 1 1 1.3-1.3 4.3 4.3 0 0 1 0 6.07l-3.38 3.38a4.26 4.26 0 0 1-6.07 0z"/>
      </svg>
    );
  }
}
LinkIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default LinkIcon