import React, { useState, useReducer, useEffect, useRef } from "react";
import HelpTopic from "./help_topic";
import PlusIcon from "../icons/plus_icon";
import CheckboxMenu from "../common/checkbox_menu.jsx";
import RoundDropdown from "../icons/round_dropdown";
import EditIcon from "../icons/edit_icon";
import MoveIcon from "../icons/move_icon";
import Swal from "sweetalert2";
import DeleteIcon from "../icons/delete_icon";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import CheckmarkIcon from "../icons/checkmark_icon";
import CancelIcon from "../icons/cancel_icon";
import TrashIcon from "../icons/trash_icon";
import Arrow from "../icons/round_dropdown";
import axios from "axios";

const resourceIcons = {
  Dashboard:
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Piechart.svg",
  Bugs: "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20-%20Filled.svg",
  "Test Cases":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Cases%20-%20Filled2.svg",
  "Test Passes":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Passes%20-%20Filled.svg",
  "Device Lab":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Device_Lab_Filled.svg",
  Performance:
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_Skills%20-%20Filled.svg",
  "Admin Panel":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Admin_Panel_Filled.svg",
};

const roleOptions = [
  "trialist",
  "client",
  "tester",
  "lead",
  "Device Checkout",
  "Test Passes",
  "Test Cases",
  "admin",
];

function HelpResource(props) {
  const [topics, setTopics] = useState(props.currentResource.help_topics);
  const [resourceSwitch, setResourceSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [editingTopic, setEditingTopic] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState(
    props.currentResource.help_resource.user_permissions
  );
  const [direction, setDirection] = useState("node-forwards");
  const [currentResourceIndex, setCurrentResourceIndex] = useState(
    props.helpResources.findIndex(
      (r) => r.id === props.currentResource.help_resource.id
    )
  );
  const topicEditInput = useRef();
  const [resource, setResource] = useState(props.currentResource.help_resource);

  function backToLandingPage() {
    props.backToLandingPage(updated);
  }

  function handleSetLoading(bool) {
    setLoading(bool);
  }

  function handleEnableEditing() {
    setEditing(!editing);
  }

  function handleRoleChange(e) {
    var index = selectedRoles.indexOf(e.target.value);
    // if(index === -1){
    //   setSelectedRoles([...selectedRoles, e.target.value])
    // } else {
    //   setSelectedRoles([...selectedRoles.slice(0,index), ...selectedRoles.slice(index+1)])
    // }
    var newRoles =
      index === -1
        ? [...selectedRoles, e.target.value]
        : [...selectedRoles.slice(0, index), ...selectedRoles.slice(index + 1)];
    setSelectedRoles(newRoles);
    axios
      .patch(`/help/${resource.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        help_resource: { user_permissions: newRoles },
      })
      .then((res) => {})
      .catch((err) => {});
  }

  function handleEditTopic(e) {
    if (editingTopic === "new") {
      setTopics(topics.slice(0, topics.length - 1));
      setEditingTopic(id);
    } else {
      var id = parseInt(e.target.dataset.id);
      setEditingTopic(id);
    }
  }

  function handleScrollToTopic(e) {
    var id = e.target.dataset.id;
    var elem = document.getElementById(`topic-${id}`);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
      window.history.pushState(
        "page2",
        "Test Platform",
        "/help/" + resource.id + "?topic=" + id
      );
    }
  }

  function handleAddTopic(e) {
    var bottomButton = e.target.dataset.bottomButton;
    setTopics([
      ...topics,
      {
        help_resource_id: resource.id,
        id: "new",
        position: topics.length + 1,
        title: "",
        bottomButton,
      },
    ]);
    setEditingTopic("new");
  }

  function handleCancelTopic() {
    if (editingTopic === "new") {
      setTopics(topics.slice(0, topics.length - 1));
    }
    setEditingTopic(false);
  }

  function handleUpdateTopic(data) {
    if (data && data.id) {
      axios
        .patch(`/help_topics/${data.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          help_topic: data,
        })
        .then((res) => {
          setTopics(topics.map((t) => (t.id === res.data.id ? res.data : t)));
          props.refreshFeaturedTopics();
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (topicEditInput.current) {
      if (!topicEditInput.current.value) {
        M.toast({
          html: "Please enter a topic title",
          classes: "red darken-1 error-toast",
        });
        return;
      }
      if (editingTopic === "new") {
        axios
          .post(`/help_topics.json`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            help_topic: {
              title: topicEditInput.current.value,
              help_resource_id: resource.id,
            },
          })
          .then((res) => {
            if (
              topics[topics.length - 1] &&
              topics[topics.length - 1].bottomButton
            ) {
              var data = res.data;
              setTimeout(() => {
                var elem = document.getElementById(`topic-${data.id}`);
                elem.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }
            setTopics([...topics.slice(0, topics.length - 1), res.data]);
            setEditingTopic(false);
            setUpdated(true);
          });
      } else {
        axios
          .patch(`/help_topics/${editingTopic}.json`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            help_topic: {
              title: topicEditInput.current.value,
            },
          })
          .then((res) => {
            setTopics(topics.map((t) => (t.id === res.data.id ? res.data : t)));
            setEditingTopic(false);
            setUpdated(true);
          });
      }
    }
  }

  function handleDragStart(e) {
    var id = parseInt(e.target.dataset.id);
    var position = parseInt(e.target.dataset.position);
    setDragging({ id, position });
  }

  function handleDragEnd(e) {
    setDragging(false);
  }

  function handleDragOver(e) {
    e.preventDefault();
  }
  function handleDragEnter(e) {
    var { position } = e.target.dataset;
    if (position) {
      if (parseInt(position) > dragging.position) {
        e.target.classList.add("bottom-drag");
      } else if (parseInt(position) < dragging.position) {
        e.target.classList.add("top-drag");
      }
    }
  }

  function handleDragLeave(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
  }

  function handleDragDrop(e) {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    var { position } = e.target.dataset;
    if (position) {
      var { id } = dragging;
      axios
        .patch(`/help_topics/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          help_topic: { position },
        })
        .then((res) => {
          setTopics(res.data.help_topics);
          props.handleDragDropUpdate(res.data);
          // setSections(sections.map((s)=>({...s, ...res.data[s.id.toString()]})).sort((a,b)=> a.position - b.position))
        })
        .catch((err) => {
          // this.setState({loading: false})
          console.error(err);
        });
    }
  }

  function handleDeleteTopic(e) {
    var { id } = e.target.dataset;
    if (!props.loading) {
      Swal.fire({
        title: "Delete Topic",
        text: "Are you sure that you want to delete this topic?",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "cancel",
        confirmButtonText: "Yes",
      }).then((result) => {
        // props.handleSetLoading(true)
        if (result.value) {
          axios
            .delete(`/help_topics/${id}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              setTopics(res.data.help_topics);
              props.handleDragDropUpdate(res.data);
              props.refreshFeaturedTopics();
            })
            .catch((err) => {
              // props.handleSetLoading(false)
              console.error(err);
            });
        } else if (result.dismiss) {
          // Swal.fire("Cancelled", "Your file is safe");
        }
      });
    }
  }

  function handleResourceSwitch(e) {
    var id = e.target.dataset.id;
    var direction = e.target.dataset.direction;
    setDirection(direction);
    axios
      .get(`/get_help_resource/${id}`)
      .then((res) => {
        var data = res.data;
        setResourceSwitch(!resourceSwitch);
        setTimeout(() => {
          setCurrentResourceIndex(
            props.helpResources.findIndex((r) => r.id === data.help_resource.id)
          );
          setResource(data.help_resource);
          setTopics(data.help_topics);
        }, 300);
        window.history.pushState("page2", "Test Platform", "/help/" + id);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  var column1 =
    topics.length > 0 ? topics.slice(0, Math.ceil(topics.length / 2)) : [];
  var column2 =
    topics.length > 1 ? topics.slice(Math.ceil(topics.length / 2)) : [];

  return (
    <SwitchTransition>
      <CSSTransition
        key={resourceSwitch}
        timeout={{ appear: 300, enter: 390, exit: 390 }}
        classNames={direction}
      >
        <div
          style={{
            width: "100%",
            minHeight: "calc(100vh - 70px)",
            backgroundColor: "#efefef",
          }}
        >
          <div id="help-show-index-container">
            <div>
              <div>
                {resourceIcons[resource.title] && (
                  <img
                    style={{ marginRight: "10px", width: "50px" }}
                    src={resourceIcons[resource.title]}
                  />
                )}
                <h2>{resource.title} Help Topics</h2>
              </div>
              <div>
                {editing && (
                  <CheckboxMenu
                    maxWidth={"100%"}
                    key={1}
                    count={selectedRoles.length + 1}
                    active={true}
                    options={roleOptions.map((r, i) => {
                      if (r === "admin") {
                        return {
                          label: "Admin",
                          value: "admin",
                          handler: false,
                          checked: true,
                        };
                      } else if (r.split(" ").length > 1) {
                        return {
                          label: `${r} Permission`,
                          value: r,
                          handler: handleRoleChange,
                          checked: selectedRoles.indexOf(r) !== -1,
                        };
                      } else {
                        return {
                          label: r[0].toUpperCase() + r.slice(1),
                          value: r,
                          handler: handleRoleChange,
                          checked: selectedRoles.indexOf(r) !== -1,
                        };
                      }
                    })}
                    name="filters"
                    title={"Visible To"}
                    // clearFunction={this.clearFilters}
                    id="1"
                    containerMinWidth="160px"
                    menuStyles={{
                      maxWidth: "255px",
                      width: "255px",
                      right: "-44px",
                    }}
                    narrow={true}
                    icons={[
                      "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                      "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                    ]}
                  />
                )}
                {props.currentUser.role === "admin" && (
                  <button
                    onClick={handleEnableEditing}
                    style={{
                      margin: "0 10px 0 20px",
                      height: "48px",
                      width: "140px",
                    }}
                    className="common-button-cancel"
                  >
                    {!editing ? "Enable" : "Disable"} Editing
                  </button>
                )}
                <button
                  onClick={backToLandingPage}
                  style={{ height: "48px", width: "180px" }}
                  className="common-button-submit"
                >
                  Back to Landing Page
                </button>
              </div>
            </div>
            {!editing ? (
              <div
                className="topic-viewing"
                style={{
                  height: `${22 + column1.length * 30.1}px`,
                  display: "flex",
                }}
              >
                <div>
                  {column1.map((t, i) => {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className={`${editing ? "editing" : ""}`}
                        key={t.id}
                      >
                        <button
                          onClick={handleScrollToTopic}
                          data-id={t.id}
                          style={{
                            padding: "7px 20px",
                            transition: "transform 0.3s",
                          }}
                          className="light-button"
                        >
                          {i + 1}. {t.title}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {column2.map((t, i) => {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className={`${editing ? "editing" : ""}`}
                        key={t.id}
                      >
                        <button
                          onClick={handleScrollToTopic}
                          data-id={t.id}
                          style={{
                            padding: "7px 20px",
                            transition: "transform 0.3s",
                          }}
                          className="light-button"
                        >
                          {i + 1 + column1.length}. {t.title}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="topic-editing">
                {topics.map((t, i) => {
                  if (editingTopic === t.id) {
                    return (
                      <div>
                        <input
                          ref={topicEditInput}
                          autoFocus={true}
                          placeholder="Enter a topic title"
                          defaultValue={t.title}
                          style={{
                            margin: "10px 5px 20px 20px",
                            width: "300px",
                            height: "35px",
                            fontSize: "14px",
                          }}
                          type="text"
                          className="common-input browser-default"
                        />
                        <button
                          onClick={handleUpdateTopic}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{ color: "#519acc", fontSize: "21px" }}
                            className="material-icons"
                          >
                            done
                          </i>
                        </button>
                        <button
                          onClick={handleCancelTopic}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{ color: "#519acc", fontSize: "21px" }}
                            className="material-icons"
                          >
                            close
                          </i>
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className={`${editing ? "editing" : ""}`}
                        key={t.id}
                      >
                        <div
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDragOver={handleDragOver}
                          onDrop={handleDragDrop}
                          className={`trial-section-drag-overlay`}
                          data-position={t.position}
                          style={{
                            zIndex:
                              dragging && dragging.id !== t.id ? "100" : "-1",
                          }}
                        />
                        <button
                          onClick={handleScrollToTopic}
                          data-id={t.id}
                          style={{
                            padding: "7px 20px",
                            transition: "transform 0.3s",
                          }}
                          className="light-button"
                        >
                          {i + 1}. {t.title}
                        </button>
                        <span
                          style={{ pointerEvents: editing ? "all" : "none" }}
                          data-id={t.id}
                          onClick={handleEditTopic}
                        >
                          <EditIcon
                            color={"#519acc"}
                            svgStyles={{ pointerEvents: "none" }}
                          />
                        </span>
                        <span
                          style={{
                            pointerEvents: editing ? "all" : "none",
                            margin: "0 8px",
                          }}
                          data-id={t.id}
                          onClick={handleDeleteTopic}
                        >
                          <DeleteIcon
                            color={"#519acc"}
                            svgStyles={{ pointerEvents: "none" }}
                          />
                        </span>
                        {topics.length > 1 && (
                          <span
                            style={{ pointerEvents: editing ? "all" : "none" }}
                            data-id={t.id}
                            data-position={t.position}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                            draggable={true}
                          >
                            <MoveIcon
                              color={"#519acc"}
                              svgStyles={{ pointerEvents: "none" }}
                            />
                          </span>
                        )}
                      </div>
                    );
                  }
                })}
                {editing && !editingTopic && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={handleAddTopic}
                      disabled={loading || editingTopic}
                      style={{
                        border: "none",
                        margin: "5px 0",
                        backgroundColor: "#519acc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        padding: "4px",
                      }}
                    >
                      <PlusIcon
                        svgStyles={{ pointerEvents: "none" }}
                        width={14}
                        height={14}
                      />
                    </button>
                  </div>
                )}
              </div>
            )}
            {/* <div className='topic-viewing'>
              <div>
              {topics.map((t, i)=>{
                if(editingTopic===t.id){
                  return(
                    <div>
                      <input ref={topicEditInput} autoFocus={true} placeholder='Enter a topic title' defaultValue={t.title} style={{margin:"10px 5px 20px 20px", width:"300px", height:"35px", fontSize:"14px"}} type="text" className='common-input browser-default' />
                      <button onClick={handleUpdateTopic} style={{border:"none", background:"none", cursor:"pointer"}}><i style={{color:"#519acc", fontSize:"21px"}} className='material-icons'>done</i></button>
                      <button onClick={handleCancelTopic} style={{border:"none", background:"none", cursor:"pointer"}}><i style={{color:"#519acc", fontSize:"21px"}} className='material-icons'>close</i></button>
                    </div>
                  )
                } else {
                  return(
                    <div style={{position:"relative"}} className={`${editing?"editing":""}`} key={t.id}>
                      <div
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={handleDragDrop}
                        className={`trial-section-drag-overlay`} 
                        data-position={t.position} 
                        style={{zIndex:dragging&&dragging.id!==t.id?"100":"-1"}} 
                      />
                      <button onClick={handleScrollToTopic} data-id={t.id} style={{padding:"7px 20px", transition:"transform 0.3s"}} className='light-button'>{i+1}. {t.title}</button>
                      <span style={{pointerEvents:editing?"all":"none"}} data-id={t.id} onClick={handleEditTopic}><EditIcon color={"#519acc"} svgStyles={{pointerEvents:"none"}} /></span>
                      <span style={{pointerEvents:editing?"all":"none"}} data-id={t.id} onClick={handleDeleteTopic}><DeleteIcon color={"#519acc"}  svgStyles={{pointerEvents:"none"}} /></span>
                      {topics.length>1&&<span style={{pointerEvents:editing?"all":"none"}} data-id={t.id} data-position={t.position} onDragStart={handleDragStart} onDragEnd={handleDragEnd} draggable={true}><MoveIcon color={"#519acc"} svgStyles={{pointerEvents:"none"}} /></span>}
                    </div>
                  )
                }
                
              })}
              </div>
              {editing&&!editingTopic&&
                <div style={{width: "100%", display:"flex", justifyContent:"center"}}>
                  <button onClick={handleAddTopic} disabled={loading||editingTopic} style={{border:"none", margin:"5px 0", backgroundColor:"#519acc", display:"flex", alignItems:"center", justifyContent:"center", borderRadius:"50%", height:"20px", width:"20px", cursor:"pointer", padding:"4px"}}>
                    <PlusIcon svgStyles={{pointerEvents:"none"}} width={14} height={14}/>
                  </button>
                </div>
              }
            </div> */}
            {!editing && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "20px 0 0",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    onClick={handleResourceSwitch}
                    data-direction="node-backwards"
                    data-id={
                      currentResourceIndex > 0
                        ? props.helpResources[currentResourceIndex - 1].id
                        : props.helpResources[props.helpResources.length - 1].id
                    }
                    className="trial-page-arrow"
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  >
                    <Arrow
                      color={"white"}
                      svgStyles={{
                        transform: "rotate(90deg)",
                        pointerEvents: "none",
                      }}
                    />
                  </button>
                  <span>
                    {currentResourceIndex > 0
                      ? props.helpResources[currentResourceIndex - 1].title
                      : props.helpResources[props.helpResources.length - 1]
                          .title}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    {currentResourceIndex < props.helpResources.length - 1
                      ? props.helpResources[currentResourceIndex + 1].title
                      : props.helpResources[0].title}
                  </span>
                  <button
                    data-id={
                      currentResourceIndex < props.helpResources.length - 1
                        ? props.helpResources[currentResourceIndex + 1].id
                        : props.helpResources[0].id
                    }
                    onClick={handleResourceSwitch}
                    data-direction="node-forwards"
                    className="trial-page-arrow"
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      height: "25px",
                      marginLeft: "10px",
                    }}
                  >
                    <Arrow
                      color={"white"}
                      svgStyles={{
                        transform: "rotate(-90deg)",
                        pointerEvents: "none",
                      }}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div id="help-section-container">
            {topics
              .filter((t) => t.id !== "new")
              .map((t) => {
                return (
                  <HelpTopic
                    permanentFeaturedTopicsCount={
                      props.permanentFeaturedTopicsCount
                    }
                    key={t.id}
                    topic={t}
                    loading={loading}
                    handleSetLoading={handleSetLoading}
                    editing={editing}
                    setModal={props.setModal}
                    handleUpdateTopic={handleUpdateTopic}
                    selectedRoles={selectedRoles}
                  />
                );
              })}
            {editing && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <button
                  data-bottom-button="true"
                  onClick={handleAddTopic}
                  disabled={loading || editingTopic}
                  style={{
                    border: "none",
                    backgroundColor: "#519acc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    padding: "4px",
                  }}
                >
                  <PlusIcon
                    svgStyles={{ pointerEvents: "none" }}
                    width={14}
                    height={14}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default HelpResource;
