import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Switcher from "../common/switcher";

const StatsBox = styled.div`
  display: flex;
  border-radius: 0px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
  align-items: flex-start;

  > .flex {
    display: flex;
    box-shadow: 0px 0px 3px 1px lightgrey;
    border-radius: 0px;
    padding: 32px 27px;
    height: ${(props) => (props.loading === "true" ? "100%" : "50%")};
    margin: 5px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    transition: max-width 0.5s;
    flex-direction: column;

    > div:nth-child(1) {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
    }

    > div > p.large-title {
      flex: 1;
      align-self: flex-start;
      margin-bottom: 30px;
    }
  }

  .inner-flex {
    width: 100%;
    display: inline-flex;
    margin-bottom: 10px;
    height: 100%;
  }

  .bottom-flex {
    width: 100%;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const StatRect = styled.div`
  box-shadow: 0px 0px 3px 1px lightgrey;
  border-radius: 0px;
  padding: 7.5px 10px 7.5px 27px;
  display: flex;
  width: calc(50% - 10px);
  margin: 5px;
  flex-direction: column;

  > div {
    width: 100%;
    margin-left: 2px;
    position: relative;
  }

  > div > p:nth-child(1) {
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0 0;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  > div > p:nth-child(2) {
    font-size: 22px;
    font-weight: 600;
  }

  > div > p:nth-child(3) {
    position: absolute;
    top: 1px;
    right: 10px;
    color: lightgray;
  }
`;

const StatItem = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;

  > img {
    width: 50px;
    height: 50px;
    border-radius: 3px;
  }

  > div {
    width: 100%;
    margin-left: 10px;
  }

  > div > p:nth-child(1) {
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  > div > p:nth-child(2) {
    font-size: 22px;
    font-weight: 600;
  }
`;

const scopeMap = ["week", "month", "year"];

function ProjectStats(props) {
  const [items, setStats] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);

  function switcherHandler(page) {
    setCurrentPage(page);
  }

  function getData() {
    setLoading(true);
    var scope = scopeMap[currentPage];
    var url = `/project_stats.json?scope=${scope}`;
    axios
      .get(url)
      .then((res) => {
        setStats(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
    return () => {};
  }, [currentPage]);

  function displayLoader() {
    return (
      <div className="display-loader">
        <div id="test-step-bugs-loader" />
      </div>
    );
  }

  function displayTopItems() {
    if (items.length >= 1) {
      var maxWidths = ["165px", "250px", "250px"];
      return items.map((item, index) => {
        if (index < 3) {
          return (
            <StatItem
              style={{
                maxWidth: maxWidths[index],
                minWidth: index == 1 ? "250px" : "",
              }}
              key={`stat_${item[1].length}`}
            >
              <img alt="bug" src={item[0]} />
              <div>
                <p>{item[1]}</p>
                <p>{item[2]}</p>
              </div>
            </StatItem>
          );
        }
      });
    } else {
      return (
        <p style={{ padding: "10px", textAlign: "center" }}>No Recent Media</p>
      );
    }
  }

  function displayBottomItems() {
    if (items.length >= 1) {
      return (
        <div className="bottom-flex">
          <StatRect key="item3">
            <div>
              <p>{items[3][1]}</p>
              <p>{items[3][2]}</p>
              <p style={{ color: items[3][0].includes("+") ? "green" : "red" }}>
                {items[3][0]}
              </p>
            </div>
          </StatRect>
          <StatRect key="item4">
            <div>
              <p>{items[4][1]}</p>
              <p>{items[4][2]}</p>
              <p style={{ color: items[4][0].includes("+") ? "green" : "red" }}>
                {items[4][0]}
              </p>
            </div>
          </StatRect>
          <StatRect key="item5">
            <div>
              <p>{items[5][1]}</p>
              <p>{items[5][2]}</p>
            </div>
          </StatRect>
          <StatRect key="item6">
            <div>
              <p>{items[6][1]}</p>
              <p>{items[6][2]}</p>
            </div>
          </StatRect>
        </div>
      );
    }
  }

  useEffect(() => {
    getData();
  }, [props.projectId]);

  return (
    <StatsBox loading={loading.toString()}>
      <div className="flex">
        <div>
          <p className="large-title">Testing Stats</p>
          <Switcher
            key={1}
            classes="col s12"
            idProp="small-switcher"
            activeValue={currentPage}
            switcherOptions={["Weekly", "Monthly", "Yearly"]}
            handlerFunction={switcherHandler}
          />
        </div>
        <div className="inner-flex">
          {loading ? displayLoader() : displayTopItems()}
        </div>
      </div>
      {loading === false && displayBottomItems()}
    </StatsBox>
  );
}

export default ProjectStats;
