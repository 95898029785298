import React, { useContext } from "react";
import styled from "styled-components";
import TrialIntro from "./Intro";
import SingleAnswer from "./SingleAnswer";
import MultipleAnswer from "./MultipleAnswer";
import WrittenAnswer from "./WrittenAnswer";
import Results from "./Results";
import { TrialsContext } from "./index";

const TrialBody = () => {
  const {
    trial,
    assessment,
    activeQuestion,
    isStarted,
    isInProgress,
    isEnded,
  } = useContext(TrialsContext);
  if (!trial || !assessment) return null;

  const QUESTION_COMPONENTS = {
    single_answer: SingleAnswer,
    multiple_answer: MultipleAnswer,
    written_answer: WrittenAnswer,
  };

  const QuestionComponent = QUESTION_COMPONENTS[activeQuestion?.question_type];

  return (
    <Container>
      {(() => {
        if (!isStarted) return <TrialIntro />;

        if (isInProgress && activeQuestion && QuestionComponent)
          return <QuestionComponent question={activeQuestion} />;

        if (isEnded) return <Results />;

        return null;
      })()}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

export const Content = styled.div`
  width: 100%;
  padding: 32px 0;
  max-width: 1096px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: ${({ $size }) => $size || "50px"};
  height: ${({ $size }) => $size || "50px"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #519acc;
`;

export default TrialBody;
