import React from "react";
import PropTypes from "prop-types";

const WikiCategoryAccessibility = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 38"
    >
      <path
        d="M22.8758 30.7682C23.0649 30.7682 23.2539 30.721 23.4429 30.6263C24.1518 30.2956 24.4354 29.492 24.1046 28.783C24.1046 28.783 21.5051 22.8278 21.0325 20.6537C20.8434 19.8975 20.7489 17.8651 20.7017 16.9198C20.7017 16.589 20.8908 16.3053 21.1743 16.2108L26.9877 14.4622C27.6967 14.2731 28.1221 13.4697 27.933 12.7607C27.7439 12.0518 26.9405 11.6263 26.2316 11.8154C26.2316 11.8154 20.8435 13.5641 18.9057 13.5641C17.0151 13.5641 11.6743 11.8627 11.6743 11.8627C10.9654 11.6736 10.162 12.0045 9.92567 12.7135C9.68935 13.4697 10.1148 14.2733 10.8709 14.4622L16.6844 16.2108C16.9679 16.3053 17.2042 16.589 17.157 16.9198C17.1098 17.8651 17.0152 19.8975 16.8262 20.6537C16.3536 22.8278 13.7541 28.783 13.7541 28.783C13.4233 29.492 13.7541 30.2954 14.4158 30.6263C14.6049 30.7208 14.794 30.7682 14.9829 30.7682C15.5028 30.7682 16.0226 30.4846 16.2117 29.9648L19.0002 24.2931L21.6469 30.0121C21.8832 30.4848 22.3558 30.7684 22.8757 30.7684L22.8758 30.7682Z"
        fill="#F4F4F4"
      />
      <path
        d="M21.647 9.54712C21.647 11.0089 20.462 12.1938 19.0002 12.1938C17.5385 12.1938 16.3535 11.0089 16.3535 9.54712C16.3535 8.08537 17.5385 6.90039 19.0002 6.90039C20.462 6.90039 21.647 8.08537 21.647 9.54712Z"
        fill="#F4F4F4"
      />
      <path
        d="M38 19C38 8.50746 29.4925 0 19 0C8.50746 0 0 8.50746 0 19C0 29.4925 8.50746 38 19 38C29.4925 38 38 29.4925 38 19ZM1.5597 19C1.5597 9.40547 9.35821 1.5597 19 1.5597C28.6418 1.5597 36.4403 9.40547 36.4403 19C36.4403 28.5945 28.5945 36.4403 19 36.4403C9.40547 36.4403 1.5597 28.5945 1.5597 19Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};

WikiCategoryAccessibility.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

WikiCategoryAccessibility.defaultProps = {
  height: "38px",
  width: "38px",
  svgStyles: {},
  color: "#f4f4f4",
};

export default WikiCategoryAccessibility;
