import { string, number, shape, oneOf, arrayOf, bool } from "prop-types";
import userTypes from "../user";
import resourceTypes from "./resource";

export default shape({
  id: number,
  created_at: string,
  created_by: userTypes,
  updated_at: string,
  level: number,
  time_limit: string,
  wiki_resource: resourceTypes,
  published: bool,
  introduction: string,
  questions: arrayOf(
    shape({
      id: number,
      description: string,
      options: arrayOf(
        shape({
          id: number,
          correct: bool,
          answer: string,
        })
      ),
      position: number,
      question_type: oneOf([
        "single_answer",
        "multiple_answer",
        "written_answer",
      ]),
      keywords: string,
    })
  ),
});
