import React from "react";

const TestPassBase = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <rect
        x={215.23}
        y={156}
        width={649.55}
        height={768}
        rx={66.43}
        fill="#e8e8e8"
        data-name="Layer 2"
      />{" "}
    </svg>
  );
};

export default TestPassBase;
