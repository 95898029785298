import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactModalV2 from "../modals/react_modal_v2";
import ModalHeader from "../modals/common/modal_header";
import { ModalBody, ModalContent } from "../modals/common/modal_styles";
import { SubmitButton, CancelButton } from "../common/modal_buttons";
import { ControlsRow } from "./AddWikiResourceForm";
import useModalControl from "../../hooks/useModalControl";

const TakeAssessmentModal = (props) => {
  const { assessments } = props;

  const [selectedAssessment, setSelectedAssessment] = useState(assessments[0]);

  const { isOpen, toggle } = useModalControl();

  const handleClose = useCallback(() => {
    setSelectedAssessment(null);
    toggle();
  }, [toggle]);

  const handleSubmit = useCallback(() => {
    window.location.href = `/wiki-assessments/${selectedAssessment.id}/trials/new`;
  }, [selectedAssessment]);

  return (
    <>
      <OpenModalButton className="create-assessment-button" onClick={toggle}>
        Take Assessment
      </OpenModalButton>
      <ReactModalV2
        id="take-wiki-assessment-modal"
        isShowing={isOpen}
        data={{ customClass: "add-wiki-resource-modal" }}
        modalAction={handleClose}
      >
        <ModalBody>
          <ModalHeader
            title="Select Assessment Level"
            subTitle="Choose from the available options below."
            modalAction={handleClose}
            showHeaderImg={false}
            containerStyle={{
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
            }}
          />
          <ModalContent>
            <AvailableAssessments>
              {assessments.map((assessment) => (
                <AssessmentOption
                  key={assessment.id}
                  onClick={() => setSelectedAssessment(assessment)}
                  $isSelected={selectedAssessment?.id === assessment.id}
                >
                  <LevelBox>
                    <h4>Level {assessment.level}</h4>
                  </LevelBox>
                  <LastAttempt>
                    <p>
                      Last Attempt:{" "}
                      {assessment.last_attempt ? (
                        <span>
                          <br />
                          {new Date(
                            assessment.last_attempt.created_at
                          ).toLocaleDateString("en-US", {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric",
                          })}{" "}
                          -{" "}
                          {new Date(
                            assessment.last_attempt.created_at
                          ).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                            timeZone: "America/Los_Angeles",
                          })}{" "}
                          PST
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </LastAttempt>
                </AssessmentOption>
              ))}
            </AvailableAssessments>
            <ControlsRow>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              <SubmitButton
                onClick={handleSubmit}
                disabled={!selectedAssessment}
              >
                Launch
              </SubmitButton>
            </ControlsRow>
          </ModalContent>
        </ModalBody>
      </ReactModalV2>
    </>
  );
};

const OpenModalButton = styled(SubmitButton)`
  height: 48px;
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: none;
`;

const AvailableAssessments = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 14px;
  justify-content: center;
  align-items: center;
`;

const AssessmentOption = styled.button`
  cursor: pointer;
  height: 150px;
  width: 175px;
  display: flex;
  flex-direction: column;
  border-width: ${(props) => (props.$isSelected ? "2px" : "1px")};
  border-style: solid;
  border-radius: 6px;
  border-color: ${(props) => (props.$isSelected ? "#519acc" : "#d8d8d8")};
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  background-color: transparent;
`;

const LevelBox = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    font-family: "Manrope";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    text-align: center;
  }
`;

const LastAttempt = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  p {
    font-family: "Arial";
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }
`;

TakeAssessmentModal.propTypes = {
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      level: PropTypes.number,
      time_limit: PropTypes.string,
      created_at: PropTypes.string,
    })
  ).isRequired,
};

export default TakeAssessmentModal;
