import React from "react";

const AppTestingExpertSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__App_Testing_Expert_-_Small_svg__cls-2{fill:#519acc}.Skills__App_Testing_Expert_-_Small_svg__cls-4{fill:#f7d64b}.Skills__App_Testing_Expert_-_Small_svg__cls-5{fill:#fff}.Skills__App_Testing_Expert_-_Small_svg__cls-6{opacity:.2;isolation:isolate}.Skills__App_Testing_Expert_-_Small_svg__cls-7{fill:#00100f}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g
        id="Skills__App_Testing_Expert_-_Small_svg__Layer_2"
        data-name="Layer 2"
      >
        {" "}
        <circle cx={540} cy={540} r={418.69} fill="#edcc43" />{" "}
        <circle
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          cx={540}
          cy={540}
          r={361.97}
        />{" "}
        <rect
          x={275.47}
          y={404.86}
          width={530.38}
          height={270.29}
          rx={28.61}
          transform="rotate(90 540.65 540)"
          fill="#002856"
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-4"
          x={255.84}
          y={385.81}
          width={569.63}
          height={308.38}
          rx={46.22}
          transform="rotate(90 540.65 540)"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M433.83 274.82h213.52A28.63 28.63 0 01676 303.45v473.86a28.06 28.06 0 01-28.06 28.06H432.71a27.3 27.3 0 01-27.3-27.3V303.24a28.42 28.42 0 0128.42-28.42z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          d="M446.49 328.7h188.58a19.76 19.76 0 0119.76 19.76V804.6a.93.93 0 01-.93.93H427.66a.93.93 0 01-.93-.93V348.46a19.76 19.76 0 0119.76-19.76z"
        />{" "}
        <g className="Skills__App_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M575.51 381a4.12 4.12 0 011 2.7v57a4.76 4.76 0 01-4.82 4.53h-63.52a4.5 4.5 0 01-4.53-4.53v-57a4.49 4.49 0 013.87-4.45v51.86l14.9-15a4.59 4.59 0 016.43 0l5.84 5.85 15-14.9a4.58 4.58 0 016.43 0L571 422v-42.8h.73a5 5 0 013.78 1.8zm-3.58 51.86v-6.79l-16.51-16.51a2.16 2.16 0 00-3.21 0s-11 10.88-13.37 13.29-4 2.41-6.42 0l-4.24-4.23a2.28 2.28 0 00-3.21 0l-15.85 15.85a2.05 2.05 0 001.6.65h58.95a2.27 2.27 0 002.26-2.25z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M571 379.19V422l-14.9-14.9a4.58 4.58 0 00-6.43 0l-15 14.9-5.84-5.85a4.59 4.59 0 00-6.43 0l-14.9 15v-51.89a2.12 2.12 0 01.66-.07zm-36.59 13.29a8.88 8.88 0 10-17.75 0 8.88 8.88 0 1017.75 0z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M571 376.26v2.93h-62.83a2.12 2.12 0 00-.66.07v-3a2.27 2.27 0 012.26-2.26h59a2.27 2.27 0 012.23 2.26zM525.55 383.2a9.29 9.29 0 010 18.55 9.28 9.28 0 010-18.55zm5.48 9.28a5.49 5.49 0 10-11 0 5.48 5.48 0 1011 0z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M576.45 435.11v3.36a4.55 4.55 0 01-4.53 4.53h-63.47a4.5 4.5 0 01-4.53-4.53v-3.36a4.52 4.52 0 004.53 4.61h63.47a4.57 4.57 0 004.53-4.61z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M509.11 434.46a2 2 0 001.61.65h58.94a2.22 2.22 0 002.26-2.26v-6.79l-16.5-16.51a2.29 2.29 0 00-3.22 0s-11 10.88-13.36 13.29-4 2.41-6.43 0l-4.24-4.23a2.28 2.28 0 00-3.21 0zm67.34-59.53v60.18a4.57 4.57 0 01-4.53 4.61h-63.47a4.52 4.52 0 01-4.53-4.61v-60.18a4.51 4.51 0 014.53-4.53h63.47a4.55 4.55 0 014.53 4.53zm-4.53 44.7v-45.72a2.28 2.28 0 00-2.26-2.27h-58.94a2.28 2.28 0 00-2.27 2.27v54.85l14.9-15a4.58 4.58 0 016.43 0l5.84 5.84 15-14.9a4.59 4.59 0 016.43 0z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M576.45 371.64v3.29a4.55 4.55 0 00-4.53-4.53h-63.47a4.51 4.51 0 00-4.53 4.53v-3.29a4.5 4.5 0 014.53-4.52h63.47a4.54 4.54 0 014.53 4.52z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M571.92 373.91v45.72L557 404.73a4.59 4.59 0 00-6.43 0l-15 14.9-5.84-5.84a4.58 4.58 0 00-6.43 0l-14.9 15v-54.88a2.28 2.28 0 012.27-2.27h58.94a2.28 2.28 0 012.31 2.27zm-36.59 16.21a8.88 8.88 0 10-17.75 0 8.88 8.88 0 1017.75 0z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M526.49 380.85a9.29 9.29 0 010 18.55 9.28 9.28 0 010-18.55zm5.48 9.27a5.49 5.49 0 10-11 0 5.48 5.48 0 1011 0zM609.93 779h-29.77a3.37 3.37 0 110-6.73h29.77a3.37 3.37 0 110 6.73z"
        />{" "}
        <g className="Skills__App_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M620.22 711.16a3 3 0 01.67 1.85v40.09a3.39 3.39 0 01-3.39 3.19h-44.61a3.16 3.16 0 01-3.18-3.19V713a3.1 3.1 0 012.66-3.13v36.45l10.53-10.48a3.14 3.14 0 014.52 0l4.1 4.11L602 729.44a3.22 3.22 0 014.51 0L617 740v-30.17h.51a3.43 3.43 0 012.71 1.33zm-2.57 36.4v-4.77l-11.6-11.61a1.62 1.62 0 00-2.26 0l-9.34 9.35c-1.69 1.69-2.88 1.69-4.52 0s-3-3-3-3a1.53 1.53 0 00-2.2 0l-11.2 11.14a1.64 1.64 0 001.13.51h41.43a1.57 1.57 0 001.56-1.62z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M617 709.83V740l-10.48-10.52a3.22 3.22 0 00-4.51 0L591.52 740l-4.1-4.11a3.14 3.14 0 00-4.52 0l-10.53 10.48v-36.49a1.84 1.84 0 01.52 0zm-25.67 9.34a6.25 6.25 0 10-6.27 6.52 6.42 6.42 0 006.26-6.52zM617 707.77v2.06h-44.1a1.84 1.84 0 00-.52 0v-2.11a1.6 1.6 0 011.6-1.59h41.42a1.57 1.57 0 011.6 1.64z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M585.05 712.65a6.53 6.53 0 11-6.21 6.52 6.42 6.42 0 016.21-6.52zm3.85 6.52a3.85 3.85 0 10-3.85 4 3.91 3.91 0 003.85-4z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M620.88 749.19v2.31a3.21 3.21 0 01-3.23 3.18H573a3.2 3.2 0 01-3.19-3.18v-2.31a3.2 3.2 0 003.19 3.18h44.61a3.21 3.21 0 003.27-3.18z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M573.5 748.73a1.89 1.89 0 001.13.46h41.43a1.57 1.57 0 001.59-1.59v-4.78l-11.6-11.65a1.61 1.61 0 00-2.26 0s-7.65 7.7-9.35 9.39-2.87 1.65-4.51 0-3-3-3-3a1.61 1.61 0 00-2.26 0zm47.38-41.84v42.3a3.21 3.21 0 01-3.23 3.18H573a3.2 3.2 0 01-3.19-3.18v-42.3a3.22 3.22 0 013.19-3.24h44.61a3.22 3.22 0 013.27 3.24zm-3.23 31.42v-32.19a1.57 1.57 0 00-1.59-1.59h-41.43a1.6 1.6 0 00-1.59 1.59v38.55l10.52-10.47a3.14 3.14 0 014.52 0l4.11 4.11 10.47-10.53a3.23 3.23 0 014.52 0z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M620.88 704.53v2.36a3.22 3.22 0 00-3.23-3.24H573a3.22 3.22 0 00-3.19 3.24v-2.36a3.21 3.21 0 013.19-3.19h44.61a3.21 3.21 0 013.27 3.19z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M617.65 706.12v32.19l-10.47-10.53a3.23 3.23 0 00-4.52 0l-10.47 10.53-4.11-4.11a3.14 3.14 0 00-4.52 0L573 744.67v-38.55a1.6 1.6 0 011.59-1.59h41.43a1.57 1.57 0 011.63 1.59zM592 717.51a6.24 6.24 0 10-6.26 6.52 6.41 6.41 0 006.26-6.52z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M585.72 711a6.53 6.53 0 11-6.21 6.52 6.41 6.41 0 016.21-6.52zm3.85 6.52a3.85 3.85 0 10-3.85 4 3.92 3.92 0 003.85-4.01z"
        />{" "}
        <circle
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          cx={585.49}
          cy={717.48}
          r={3.97}
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M502.73 779H473a3.37 3.37 0 110-6.73h29.77a3.37 3.37 0 110 6.73z"
        />{" "}
        <g className="Skills__App_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M513 711.16a3 3 0 01.67 1.85v40.09a3.38 3.38 0 01-3.39 3.19h-44.59a3.16 3.16 0 01-3.18-3.19V713a3.11 3.11 0 012.67-3.13v36.45l10.52-10.48a3.14 3.14 0 014.52 0l4.1 4.11 10.48-10.52a3.22 3.22 0 014.51 0L509.79 740v-30.17h.51a3.42 3.42 0 012.7 1.33zm-2.57 36.4v-4.77l-11.6-11.61a1.62 1.62 0 00-2.26 0l-9.34 9.35c-1.69 1.69-2.87 1.69-4.52 0s-3-3-3-3a1.53 1.53 0 00-2.2 0l-11.19 11.14a1.6 1.6 0 001.12.51h41.43a1.57 1.57 0 001.58-1.62z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M509.79 709.83V740l-10.48-10.52a3.22 3.22 0 00-4.51 0L484.32 740l-4.1-4.11a3.14 3.14 0 00-4.52 0l-10.52 10.48v-36.49a1.77 1.77 0 01.51 0zm-25.67 9.34a6.25 6.25 0 10-6.26 6.52 6.41 6.41 0 006.26-6.52zM509.79 707.77v2.06h-44.1a1.77 1.77 0 00-.51 0v-2.11a1.59 1.59 0 011.59-1.59h41.43a1.57 1.57 0 011.59 1.64z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M477.86 712.65a6.53 6.53 0 11-6.22 6.52 6.41 6.41 0 016.22-6.52zm3.85 6.52a3.85 3.85 0 10-3.85 4 3.91 3.91 0 003.85-4z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M513.68 749.19v2.31a3.21 3.21 0 01-3.23 3.18h-44.61a3.19 3.19 0 01-3.18-3.18v-2.31a3.19 3.19 0 003.18 3.18h44.61a3.21 3.21 0 003.23-3.18z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M466.3 748.73a1.89 1.89 0 001.13.46h41.43a1.57 1.57 0 001.59-1.59v-4.78l-11.6-11.65a1.61 1.61 0 00-2.26 0l-9.34 9.39c-1.7 1.7-2.88 1.65-4.52 0s-3-3-3-3a1.61 1.61 0 00-2.26 0zm47.38-41.84v42.3a3.21 3.21 0 01-3.23 3.18h-44.61a3.19 3.19 0 01-3.18-3.18v-42.3a3.21 3.21 0 013.18-3.24h44.61a3.22 3.22 0 013.23 3.24zm-3.23 31.42v-32.19a1.57 1.57 0 00-1.59-1.59h-41.43a1.6 1.6 0 00-1.59 1.59v38.55l10.52-10.47a3.14 3.14 0 014.52 0l4.11 4.11 10.47-10.53a3.23 3.23 0 014.52 0z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M513.68 704.53v2.36a3.22 3.22 0 00-3.23-3.24h-44.61a3.21 3.21 0 00-3.18 3.24v-2.36a3.2 3.2 0 013.18-3.19h44.61a3.21 3.21 0 013.23 3.19z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M510.45 706.12v32.19L500 727.78a3.23 3.23 0 00-4.52 0L485 738.31l-4.11-4.11a3.14 3.14 0 00-4.52 0l-10.52 10.47v-38.55a1.6 1.6 0 011.59-1.59h41.43a1.57 1.57 0 011.58 1.59zm-25.67 11.39a6.24 6.24 0 10-6.26 6.52 6.41 6.41 0 006.26-6.52z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M478.52 711a6.53 6.53 0 11-6.21 6.52 6.41 6.41 0 016.21-6.52zm3.85 6.52a3.85 3.85 0 10-3.85 4 3.92 3.92 0 003.85-4.01z"
        />{" "}
        <circle
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          cx={478.29}
          cy={717.48}
          r={3.97}
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M502.73 677.77H473a3.37 3.37 0 110-6.73h29.77a3.37 3.37 0 110 6.73z"
        />{" "}
        <g className="Skills__App_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M513 610a3 3 0 01.67 1.85v40.1a3.38 3.38 0 01-3.39 3.18h-44.59a3.16 3.16 0 01-3.18-3.18V611.8a3.12 3.12 0 012.67-3.13v36.45l10.52-10.47a3.12 3.12 0 014.52 0l4.1 4.1 10.48-10.52a3.22 3.22 0 014.51 0l10.48 10.52v-30.13h.51A3.42 3.42 0 01513 610zm-2.57 36.4v-4.77L498.85 630a1.61 1.61 0 00-2.26 0l-9.34 9.34c-1.69 1.69-2.87 1.69-4.52 0s-3-3-3-3a1.53 1.53 0 00-2.2 0l-11.19 11.14a1.6 1.6 0 001.12.51h41.43a1.57 1.57 0 001.56-1.64z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M509.79 608.62v30.13l-10.48-10.52a3.22 3.22 0 00-4.51 0l-10.48 10.52-4.1-4.1a3.12 3.12 0 00-4.52 0l-10.52 10.47v-36.45a1.77 1.77 0 01.51 0zM484.12 618a6.25 6.25 0 10-6.26 6.52 6.41 6.41 0 006.26-6.52zM509.79 606.57v2h-44.1a1.77 1.77 0 00-.51 0v-2.1a1.6 1.6 0 011.59-1.6h41.43a1.57 1.57 0 011.59 1.7z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M477.86 611.44a6.53 6.53 0 11-6.22 6.52 6.41 6.41 0 016.22-6.52zm3.85 6.52a3.85 3.85 0 10-3.85 4 3.92 3.92 0 003.85-3.96z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M513.68 648v2.31a3.2 3.2 0 01-3.23 3.18h-44.61a3.19 3.19 0 01-3.18-3.18V648a3.19 3.19 0 003.18 3.18h44.61a3.2 3.2 0 003.23-3.18z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M466.3 647.52a1.83 1.83 0 001.13.46h41.43a1.57 1.57 0 001.59-1.59v-4.77L498.85 630a1.62 1.62 0 00-2.26 0l-9.34 9.4c-1.7 1.69-2.88 1.64-4.52 0l-3-3a1.61 1.61 0 00-2.26 0zm47.38-41.84V648a3.2 3.2 0 01-3.23 3.18h-44.61a3.19 3.19 0 01-3.18-3.18v-42.3a3.2 3.2 0 013.18-3.23h44.61a3.21 3.21 0 013.23 3.21zm-3.23 31.42v-32.19a1.57 1.57 0 00-1.59-1.59h-41.43a1.6 1.6 0 00-1.59 1.59v38.55L476.36 633a3.14 3.14 0 014.52 0l4.12 4.1 10.47-10.53a3.23 3.23 0 014.52 0z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M513.68 603.32v2.36a3.21 3.21 0 00-3.23-3.23h-44.61a3.2 3.2 0 00-3.18 3.23v-2.36a3.19 3.19 0 013.18-3.18h44.61a3.2 3.2 0 013.23 3.18z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M510.45 604.91v32.19L500 626.57a3.23 3.23 0 00-4.52 0L485 637.1l-4.12-4.1a3.14 3.14 0 00-4.52 0l-10.52 10.47v-38.56a1.6 1.6 0 011.59-1.59h41.43a1.57 1.57 0 011.59 1.59zm-25.67 11.4a6.24 6.24 0 10-6.26 6.52 6.41 6.41 0 006.26-6.52z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M478.52 609.79a6.53 6.53 0 11-6.21 6.52 6.41 6.41 0 016.21-6.52zm3.85 6.52a3.85 3.85 0 10-3.85 4 3.92 3.92 0 003.85-4z"
        />{" "}
        <circle
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          cx={478.29}
          cy={616.27}
          r={3.97}
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M609.93 677.77h-29.77a3.37 3.37 0 110-6.73h29.77a3.37 3.37 0 110 6.73z"
        />{" "}
        <g className="Skills__App_Testing_Expert_-_Small_svg__cls-6">
          {" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M620.22 610a3 3 0 01.67 1.85v40.1a3.39 3.39 0 01-3.39 3.18h-44.61a3.16 3.16 0 01-3.18-3.18V611.8a3.11 3.11 0 012.66-3.13v36.45l10.53-10.47a3.12 3.12 0 014.52 0l4.1 4.1L602 628.23a3.22 3.22 0 014.51 0L617 638.75v-30.13h.51a3.43 3.43 0 012.71 1.38zm-2.57 36.4v-4.77L606.05 630a1.61 1.61 0 00-2.26 0l-9.34 9.34c-1.69 1.69-2.88 1.69-4.52 0s-3-3-3-3a1.53 1.53 0 00-2.2 0l-11.2 11.14a1.64 1.64 0 001.13.51h41.43a1.57 1.57 0 001.56-1.64z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M617 608.62v30.13l-10.48-10.52a3.22 3.22 0 00-4.51 0l-10.48 10.52-4.1-4.1a3.12 3.12 0 00-4.52 0l-10.53 10.47v-36.45a1.84 1.84 0 01.52 0zM591.32 618a6.25 6.25 0 10-6.27 6.52 6.42 6.42 0 006.27-6.52zM617 606.57v2h-44.1a1.84 1.84 0 00-.52 0v-2.1A1.6 1.6 0 01574 605h41.42a1.57 1.57 0 011.58 1.57z"
          />{" "}
          <path
            className="Skills__App_Testing_Expert_-_Small_svg__cls-7"
            d="M585.05 611.44a6.53 6.53 0 11-6.21 6.52 6.42 6.42 0 016.21-6.52zm3.85 6.56a3.85 3.85 0 10-7.7 0 3.85 3.85 0 107.7 0z"
          />{" "}
        </g>{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M620.88 648v2.31a3.21 3.21 0 01-3.23 3.18H573a3.2 3.2 0 01-3.19-3.18V648a3.2 3.2 0 003.19 3.18h44.61a3.21 3.21 0 003.27-3.18z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M573.5 647.52a1.83 1.83 0 001.13.46h41.43a1.57 1.57 0 001.59-1.59v-4.77L606.05 630a1.62 1.62 0 00-2.26 0l-9.35 9.4c-1.69 1.69-2.87 1.64-4.51 0l-3-3a1.61 1.61 0 00-2.26 0zm47.38-41.84V648a3.21 3.21 0 01-3.23 3.18H573a3.2 3.2 0 01-3.19-3.18v-42.3a3.21 3.21 0 013.19-3.23h44.61a3.22 3.22 0 013.27 3.21zm-3.23 31.42v-32.19a1.57 1.57 0 00-1.59-1.59h-41.43a1.6 1.6 0 00-1.59 1.59v38.55L583.56 633a3.14 3.14 0 014.52 0l4.11 4.11 10.47-10.53a3.23 3.23 0 014.52 0z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M620.88 603.32v2.36a3.22 3.22 0 00-3.23-3.23H573a3.21 3.21 0 00-3.19 3.23v-2.36a3.2 3.2 0 013.19-3.18h44.61a3.21 3.21 0 013.27 3.18z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M617.65 604.91v32.19l-10.47-10.53a3.23 3.23 0 00-4.52 0l-10.47 10.53-4.11-4.1a3.14 3.14 0 00-4.52 0L573 643.46v-38.55a1.6 1.6 0 011.59-1.59h41.43a1.57 1.57 0 011.63 1.59zM592 616.31a6.24 6.24 0 10-6.26 6.52 6.41 6.41 0 006.26-6.52z"
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M585.72 609.79a6.53 6.53 0 11-6.21 6.52 6.41 6.41 0 016.21-6.52zm3.85 6.52a3.85 3.85 0 10-3.85 4 3.92 3.92 0 003.85-4z"
        />{" "}
        <circle
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          cx={585.49}
          cy={616.27}
          r={3.97}
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          d="M608.82 508.63H472.6a4.71 4.71 0 010-9.42h136.22a4.71 4.71 0 110 9.42zM579.63 486.74h-77.84a4.72 4.72 0 010-9.43h77.84a4.72 4.72 0 010 9.43zM579.63 530.53h-77.84a4.72 4.72 0 010-9.43h77.84a4.72 4.72 0 010 9.43z"
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-2"
          x={512.05}
          y={550.55}
          width={58.18}
          height={14.8}
          rx={3.65}
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-4"
          x={687.77}
          y={365.2}
          width={12.37}
          height={69.65}
          rx={4.66}
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-4"
          x={379.86}
          y={338.1}
          width={11.95}
          height={59.58}
          rx={4.24}
        />{" "}
        <path
          className="Skills__App_Testing_Expert_-_Small_svg__cls-4"
          d="M600 269.26H480a5.13 5.13 0 00-5.13 5.13v6.21c0 5 4.06 11.64 9 11.64H596.1c5 0 9-6.67 9-11.64v-6.21a5.13 5.13 0 00-5.1-5.13z"
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-4"
          x={379.86}
          y={404.56}
          width={11.95}
          height={59.58}
          rx={4.24}
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          x={424.21}
          y={295.17}
          width={29.2}
          height={3.86}
          rx={1.93}
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          x={424.21}
          y={303.82}
          width={29.2}
          height={3.86}
          rx={1.93}
        />{" "}
        <rect
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          x={424.21}
          y={312.46}
          width={29.2}
          height={3.86}
          rx={1.93}
        />{" "}
        <circle
          className="Skills__App_Testing_Expert_-_Small_svg__cls-5"
          cx={643.43}
          cy={305.75}
          r={10.57}
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default AppTestingExpertSmall;
