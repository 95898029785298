import React from "react";

class KudosModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  handleCancelButton = () => {
    this.props.modalAction(false, "", {});
  };

  handleMessage = (e) => {
    this.setState({ message: e.target.value ? e.target.value : "" });
  };

  handleSendKudos = () => {
    this.props.data.handleSendKudos(
      this.props.data.kudosUserId,
      this.state.message,
      this.props.data.resolve
    );
    this.props.modalAction(false, "", {});
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            borderBottom: "solid 1px lightgray",
            width: "calc(100% + 40px)",
            display: "flex",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          {this.props.data.view ? (
            <span
              style={{ margin: "20px", fontWeight: "700" }}
              className="small-title"
            >
              View Kudos comment
            </span>
          ) : (
            <span
              style={{ margin: "20px", fontWeight: "700" }}
              className="small-title"
            >
              Send a Kudos{" "}
              <span style={{ color: "#519acc", fontWeight: "700" }}>
                - {this.props.data.kudosUserName}
              </span>
            </span>
          )}
        </div>
        {this.props.data.view ? (
          <div style={{ padding: "20px" }}>
            <p style={{ textAlign: "left", lineHeight: "25px" }}>
              <span style={{ fontStyle: "italic" }}>
                {this.props.data.userName} says:
              </span>{" "}
              "{this.props.data.message}"
            </p>
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <p
              style={{
                textAlign: "left",
                lineHeight: "22px",
                marginBottom: "15px",
              }}
            >
              <span style={{ color: "#519acc", fontWeight: "600" }}>Kudos</span>{" "}
              are a way to recognize and celebrate your peers for the excellent
              work they do at PLUS QA.
            </p>
            <p style={{ textAlign: "left" }}>
              Let{" "}
              <span style={{ color: "#519acc", fontWeight: "600" }}>
                {this.props.data.kudosUserName}
              </span>{" "}
              know how they earned this kudos:
            </p>
            <textarea
              style={{ marginBottom: "0", marginTop: "4px", height: "114px" }}
              onChange={this.handleMessage}
              autoFocus={true}
              maxLength={255}
              type="text"
              value={this.state.message}
              placeholder={"Optional"}
              className="common-input browser-default"
            />
            <p style={{ textAlign: "left", lineHeight: "10px" }}>
              {this.state.message.length} / 255
            </p>
            <button
              onClick={this.handleSendKudos}
              style={{
                height: "48px",
                marginTop: "18px",
                marginLeft: "auto",
                display: "block",
              }}
              className="common-button-submit"
            >
              Send
            </button>
          </div>
        )}
        <button
          style={{ position: "absolute", top: "20px", right: "20px" }}
          onClick={this.handleCancelButton}
          aria-label="close-modal"
          className="icon-close-btn"
        ></button>
      </div>
    );
  }
}
export default KudosModal;
