import { string, number, arrayOf, shape } from "prop-types";

const topicTypes = shape({
  id: number,
  position: number,
  title: string,
  user_permissions: arrayOf(string),
});

const featuredTopicTypes = shape({
  id: number,
  created_at: string,
  featured_text: string,
  title: string,
  updated_at: string,
  resource: shape({
    id: number,
    category: string,
    position: number,
    title: string,
  }),
});

export default topicTypes;

export { topicTypes, featuredTopicTypes };
