import React from "react";
import Swal from "sweetalert2";

class FeaturedTopic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: this.props.data.edit
        ? this.props.data.topic.featured_text
        : "",
      permanent: this.props.data.edit
        ? this.props.data.topic.permanent_feature
          ? true
          : false
        : false,
    };
  }

  handleCancelButton = () => {
    this.props.modalAction(false, "", {});
  };

  handleMessage = (e) => {
    this.setState({ description: e.target.value ? e.target.value : "" });
  };

  handleConfirm = () => {
    var data = {
      featured: true,
      featured_text: this.state.description,
      permanent_feature: this.state.permanent,
      id: this.props.data.topic.id,
    };
    this.props.data.handler(data);
    this.props.modalAction(false, "", {});
  };

  handleRemove = () => {
    Swal.fire({
      title: "Remove Featured Topic",
      text: "Are you you want this topic to no longer be featured?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        var data = {
          featured: false,
          featured_text: "",
          permanent_feature: false,
          id: this.props.data.topic.id,
        };
        this.props.data.handler(data);
        this.props.modalAction(false, "", {});
      } else if (result.dismiss) {
        // Swal.fire("Cancelled", "Your file is safe");
      }
    });
  };

  handlePermanentCheck = () => {
    if (
      this.props.data.permanentFeaturedTopicsCount >= 4 &&
      !this.props.data.topic.permanent_feature
    ) {
      M.toast({
        html: "You already have 4 topics that are always visible",
        classes: "red darken-1 error-toast",
      });
    } else {
      this.setState({ permanent: !this.state.permanent });
    }
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            borderBottom: "solid 1px lightgray",
            padding: "20px",
            width: "calc(100%)",
            textAlign: "left",
          }}
        >
          {this.props.data.edit ? (
            <div>
              <span style={{ fontWeight: "700" }} className="small-title">
                Edit Featured Topic
              </span>
            </div>
          ) : (
            <span style={{ fontWeight: "700" }} className="small-title">
              Add Featured Topic
            </span>
          )}
        </div>
        <div style={{ padding: "20px", position: "relative" }}>
          <p style={{ textAlign: "left" }}>Description</p>
          <textarea
            style={{ marginBottom: "0", marginTop: "4px", height: "114px" }}
            onChange={this.handleMessage}
            autoFocus={true}
            maxLength={123}
            type="text"
            value={this.state.description}
            placeholder={"Provide a description to display with this Topic"}
            className="common-input browser-default"
          />
          <p style={{ textAlign: "left", lineHeight: "10px" }}>
            {this.state.description.length} / 123
          </p>
          {this.props.data.edit && (
            <button
              onClick={this.handleRemove}
              style={{
                height: "48px",
                position: "absolute",
                right: "130px",
                bottom: "20px",
              }}
              className="common-button-delete"
            >
              Remove
            </button>
          )}
          <button
            disabled={!this.state.description}
            onClick={this.handleConfirm}
            style={{
              height: "48px",
              marginTop: "18px",
              marginLeft: "auto",
              display: "block",
            }}
            className="common-button-submit"
          >
            {this.props.data.topic ? "Save" : "Confirm"}
          </button>
          {this.props.data.type !== "wiki" && (
            <label
              style={{
                height: "25px",
                position: "absolute",
                left: "20px",
                bottom: "30px",
              }}
            >
              <input
                className="filled-in"
                onChange={this.handlePermanentCheck}
                checked={this.state.permanent}
                value={1}
                type="checkbox"
              />
              <span
                style={{
                  whiteSpace: "nowrap",
                  height: "100%",
                  width: "100%",
                  paddingLeft: "30px",
                  textOverflow: "ellipsis",
                  lineHeight: "22px",
                  overflow: "hidden",
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Always Show
              </span>
            </label>
          )}
        </div>
        <button
          style={{ position: "absolute", top: "20px", right: "20px" }}
          onClick={this.handleCancelButton}
          aria-label="close-modal"
          className="icon-close-btn"
        ></button>
      </div>
    );
  }
}
export default FeaturedTopic;
