import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { components } from "react-select";
import {
  SearchableSelect,
  DropdownLabel,
  DropdownIconContainer,
  DropdownIconPlaceholder,
  DropdownName,
} from "../styles";

const SingleValue = (props) => {
  /* eslint-disable react/prop-types */
  // defined below when building options; user is added as a property of option.data
  const { data } = props;
  const { user } = data;
  const { name } = user;
  /* eslint-enable react/prop-types */

  return (
    <components.SingleValue {...props}>
      <DropdownLabel>
        <DropdownName>{name}</DropdownName>
      </DropdownLabel>
    </components.SingleValue>
  );
};

const PerformanceUserDropdown = ({ onUserSelect, usersList, queryParams }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [userSearchValue, setUserSearchValue] = useState("");

  const handleInputChange = (value) => {
    setUserSearchValue(value);
  };

  const options = useMemo(() => {
    if (!usersList || usersList.length === 0) {
      return [];
    }

    return usersList.map((user) => ({
      user,
      value: user.id,
      label: (
        <DropdownLabel>
          <IconContainer>
            {user.avatar ? (
              <img src={user.avatar} alt={`${user.name} avatar`} />
            ) : (
              <IconPlaceholder>{user.name[0]}</IconPlaceholder>
            )}
          </IconContainer>
          <div>{user.name}</div>
        </DropdownLabel>
      ),
    }));
  }, [usersList]);

  const filterSearch = (option, inputValue) => {
    if (!inputValue || inputValue.length === 0) {
      return true;
    }

    const { user } = option.data;
    const { name } = user;
    return name.toLowerCase().includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    // on fetch status 200, set selected user based on id returned in queryParams
    const userId = parseInt(queryParams.id, 10);
    if (userId !== selectedUser?.value) {
      setSelectedUser(options.find((option) => option.value === userId));
    }
  }, [queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!usersList || usersList.length === 0) {
    return null;
  }

  return (
    <UserSelectContainer>
      <SearchableSelect
        aria-label="User lookup field, filter performance dashboard view by user"
        placeholder="All users"
        classNamePrefix="Select"
        onChange={(option) => {
          setSelectedUser(option || null);
          onUserSelect(option?.value || null);
        }}
        value={selectedUser}
        isClearable
        isSearchable
        isMulti={false}
        options={options}
        components={{ SingleValue }}
        inputValue={userSearchValue}
        filterOption={filterSearch}
        onInputChange={handleInputChange}
        menuPortalTarget={document.querySelector("body")}
        menuPlacement="auto"
        menuPosition="fixed"
        menuShouldScrollIntoView={false}
        styles={{
          placeholder: (base, state) => ({
            ...base,
            display: state.isFocues || state.hasValue ? "none" : "block",
          }),
          menu: (base) => ({
            ...base,
            width: "328px",
            right: 0,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#519acc",
          },
        })}
      />
    </UserSelectContainer>
  );
};

const UserSelectContainer = styled.div``;

const IconContainer = styled(DropdownIconContainer)``;

const IconPlaceholder = styled(DropdownIconPlaceholder)``;

PerformanceUserDropdown.propTypes = {
  onUserSelect: PropTypes.func.isRequired,
  usersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.string,
      role: PropTypes.string,
      teams: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
  }),
};

export default PerformanceUserDropdown;
