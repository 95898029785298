import React, { useState, useContext } from "react";
import styled from "styled-components";
import ReactModalV2 from "@components/modals/react_modal_v2";
import { SubmitButton } from "@components/common/modal_buttons";
import EmailResultsForm from "./EmailResultsForm";
import { TrialsContext } from "./index";

const EmailResultsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { assessment, trial } = useContext(TrialsContext);

  if (!trial || !trial.trial_results) {
    return null;
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleExit = () => {
    toggleModal();
    window.location.href = `/wiki/${assessment.resource.id}`;
  };

  return (
    <Container>
      <AdvanceButton
        type="button"
        onClick={toggleModal}
        className="common-button common-button-submit"
      >
        Share Results and Exit
      </AdvanceButton>
      <ReactModalV2
        id="email-results-modal"
        isShowing={isModalOpen}
        data={{ customClass: "email-results-modal" }}
        page={() => <EmailResultsForm handleExit={handleExit} />}
        modalAction={toggleModal}
      />
    </Container>
  );
};

const Container = styled.div``;

const AdvanceButton = styled(SubmitButton)`
  margin-top: 36px;
  position: relative;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  min-width: 187px;
  height: 45px;
  font-family: "Manrope";
`;

export default EmailResultsModal;
