import React from "react";
import PropTypes from "prop-types";

const AdminPanel = ({ active, ...props }) => {
  const pathClassName = active ? "active-path-fill" : "admin-panel_svg__cls-1";

  return (
    <svg data-name="Layer 1" viewBox="0 0 64 64" {...props}>
      <path
        d="M32.52 3.76a12.44 12.44 0 0112.57 12.3v.13c-.44 7.15-5.81 12.32-12.57 12.43a12.43 12.43 0 110-24.86zM22.33 27.58a16.1 16.1 0 0020.46 0c6 2.85 10.36 8.29 10.36 16.79v8.12C39.27 62 26 62 12 52.43v-8c0-8.58 4.35-14 10.36-16.83zm-9.39 3.25a21.88 21.88 0 00-4.46 13.53v5.7l.23 4.14c19.89 15.89 39.11 7.8 47.65 0l.19-4.14v-5.7a21.84 21.84 0 00-4.42-13.57l-2.88-3.11a22.89 22.89 0 00-3.9-2.68 15.35 15.35 0 002.8-8.86A15.53 15.53 0 0032.52.66 15.54 15.54 0 0019.7 25a21.71 21.71 0 00-3.9 2.74z"
        className={pathClassName}
      />
    </svg>
  );
};

AdminPanel.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default AdminPanel;
