import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import styled, { css } from "styled-components";

const MultipleChoiceOption = (props) => {
  const { questionId, onSelect, optionLabel, optionText, answer } = props;

  const currentAnswer = useWatch({ name: questionId, defaultValue: [] });

  const isSelected = useMemo(() => {
    return currentAnswer?.includes(answer.id);
  }, [currentAnswer, answer.id]);

  const handleClick = () => {
    onSelect(answer);
  };

  return (
    <OptionButton type="button" onClick={handleClick} $isSelected={isSelected}>
      <OptionLabel $isSelected={isSelected}>{optionLabel}</OptionLabel>
      <OptionText $isSelected={isSelected}>{optionText}</OptionText>
    </OptionButton>
  );
};

const transitionStyles = css`
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: background-color, color;
`;

const OptionButton = styled.button`
  display: flex;
  flex-direction: row;
  flex-basis: calc(50% - 12px);
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  min-height: 85px;
  padding: 14px;
  margin-top: 25px;
  border-radius: 4px;
  background-color: ${(props) => (props.$isSelected ? "#519acc" : "#ebebeb")};
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  ${transitionStyles}

  &:focus {
    background-color: ${(props) => (props.$isSelected ? "#519acc" : "#ebebeb")};
  }
`;

const OptionLabel = styled.div`
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "Manrope";
  font-weight: 700;
  color: ${(props) => (props.$isSelected ? "#519acc" : "#ffffff")};
  background-color: ${(props) => (props.$isSelected ? "#ffffff" : "#519acc")};
  ${transitionStyles}
`;

const OptionText = styled.div`
  margin-left: 30px;
  font-family: "Arial";
  font-size: 16px;
  margin-left: 30px;
  text-align: justify;
  color: ${(props) => (props.$isSelected ? "#ffffff" : "#000000")};
  ${transitionStyles}
`;

MultipleChoiceOption.propTypes = {
  questionId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  optionId: PropTypes.number.isRequired,
  optionLabel: PropTypes.string.isRequired,
  optionText: PropTypes.string.isRequired,
  answer: PropTypes.shape({ id: PropTypes.number, answer: PropTypes.string })
    .isRequired,
};

export default MultipleChoiceOption;
