import React, { Component } from 'react'

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

class TimedTextArea extends Component {
  state = {
    value: this.props.value
  }

  timer = null

  handleChange = e => {
    clearTimeout(this.timer)
    if (e.target.value.includes("<")||e.target.value.includes(">")){
      e.target.value = e.target.value.replace(/</g, "")
      e.target.value = e.target.value.replace(/>/g, "")
    }
    this.setState({ value: e.target.value })
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
  }

  handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY && !e.shiftKey) {
      clearTimeout(this.timer)
      this.triggerChange()
    }
  }

  triggerChange = () => {
    const { value } = this.state

    this.props.onChange(value)
  }

  render() {
    const { ...rest } = this.props

    return (
      <textarea
        type={rest.type}
        id={rest.id}
        className={rest.className}
        placeholder={rest.placeholder}
        value={this.state.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        maxLength={10000}
      />
    )
  }
}

export default TimedTextArea