import React, { useState } from "react";
import WikiSection from "./wiki_section";
import CheckboxMenu from "../common/checkbox_menu";
import ApiClient from "../../utils/ApiClient";

const WikiTopic = (props) => {
  const { topic, loading } = props;
  const [dragging, setDragging] = useState(false);
  const [editingSection, setEditingSection] = useState(false);
  const [sections, setSections] = useState(topic.wiki_sections);
  const [selectedRoles, setSelectedRoles] = useState(topic.user_permissions);
  const [selectedTeams, setSelectedTeams] = useState(topic.team_permissions);

  function handleDragStart(id, position) {
    setDragging({ id, position });
  }

  function handleDragEnd(e) {
    setDragging(false);
  }

  function handleDragDrop(id, position) {
    const api = new ApiClient();
    if (!loading && dragging) {
      api
        .patch(`/wiki_sections/${id}`, {
          wiki_section: { position },
        })
        .then((res) => {
          setSections([
            ...res.data.map((update) => {
              return {
                ...sections.find((s) => s.id === update.id),
                position: update.position,
              };
            }),
          ]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function handleUpdateSection(section) {
    setSections(sections.map((s) => (s.id !== section.id ? s : section)));
    setEditingSection(false);
  }

  function handleEditSection(id) {
    if (editingSection === "new") {
      setSections(sections.slice(0, sections.length - 1));
      setEditingSection(id);
    } else {
      setEditingSection(id);
    }
  }

  function handleCreateSection(section) {
    setSections([...sections.slice(0, sections.length - 1), section]);
    setEditingSection(false);
  }

  function handleAddSection(e) {
    const { secure } = e.target.dataset;
    setSections([
      ...sections,
      {
        wiki_topic_id: topic.id,
        id: "new",
        section_type: secure ? "secure" : "text",
        style: {},
        image_style: {},
        position: sections.length + 1,
        content: "",
      },
    ]);
    setEditingSection("new");
  }

  function handleAddImage() {
    setSections([
      ...sections,
      {
        wiki_topic_id: topic.id,
        id: "new",
        section_type: "image",
        style: {},
        image_style: {},
        position: sections.length + 1,
        content: "",
      },
    ]);
    setEditingSection("new");
  }

  function handleDeleteSection(id) {
    setSections([...sections.filter((s) => s.id !== id)]);
  }

  function handleUsePagePermissionsCheck() {
    setSelectedRoles([...props.selectedRoles]);
    setSelectedTeams(props.selectedTeams);
  }

  function handleFeaturedTopicModal() {
    props.setModal(true, "featured-topic", {
      topic: props.topic,
      edit: props.topic.featured,
      handler: props.handleUpdateTopic,
      permanentFeaturedTopicsCount: props.permanentFeaturedTopicsCount,
      type: "wiki",
      customClass: "kudos-modal",
    });
  }

  function handleTeamChange(e) {
    const api = new ApiClient();
    const value = parseInt(e.target.value);
    const index = selectedTeams.indexOf(value);
    const newTeams =
      index === -1
        ? [...selectedTeams, value]
        : [...selectedTeams.slice(0, index), ...selectedTeams.slice(index + 1)];
    setSelectedTeams(newTeams);
    api.patch(`/wiki_topics/${props.topic.id}.json`, {
      wiki_topic: { team_permissions: newTeams },
    });
  }

  function handleRoleChange(e) {
    const api = new ApiClient();
    const { value } = e.target;
    let newRoles = [];

    if (value === "admin") {
      newRoles = ["admin"];
      if (selectedRoles.indexOf("admin") === -1) {
        newRoles = [...selectedRoles, "admin"];
      }
    } else {
      // Allow other roles to be selected/unselected as usual
      const index = selectedRoles.indexOf(value);
      if (index === -1) {
        newRoles = [...selectedRoles, value];
      } else {
        newRoles = [
          ...selectedRoles.slice(0, index),
          ...selectedRoles.slice(index + 1),
        ];
      }

      // Make sure "Admin" is always selected
      if (newRoles.indexOf("admin") === -1) {
        newRoles.push("admin");
      }
    }

    setSelectedRoles(newRoles);

    api
      .patch(`/wiki_topics/${props.topic.id}`, {
        wiki_topic: { user_permissions: newRoles },
      })
      .then((res) => {})
      .catch((err) => {});
  }
  const roleOptions = [...props.selectedRoles];
  const teamOptions =
    props.selectedTeams.length > 0
      ? props.teamOptions.filter(
          (o) => props.selectedTeams.indexOf(o.value) !== -1
        )
      : props.teamOptions;

  return (
    <div className="help-topic">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3
          id={`topic-${props.topic.id}`}
          style={{ textAlign: "left", fontSize: "18px", marginBottom: "10px" }}
        >
          {props.topic.title}
        </h3>
        {props.editing && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {selectedRoles.length === 0 && selectedTeams.length === 0 ? (
              <label style={{ height: "25px" }}>
                <input
                  onChange={handleUsePagePermissionsCheck}
                  disabled={
                    props.selectedRoles.length === 0 &&
                    props.selectedTeams.length === 1
                  }
                  className="filled-in"
                  checked
                  value={1}
                  type="checkbox"
                />
                <span
                  style={{
                    whiteSpace: "nowrap",
                    height: "100%",
                    width: "100%",
                    paddingLeft: "30px",
                    textOverflow: "ellipsis",
                    lineHeight: "22px",
                    overflow: "hidden",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Use Page Permission Settings
                </span>
              </label>
            ) : (
              <div style={{ display: "flex" }}>
                <CheckboxMenu
                  maxWidth="100%"
                  key={1}
                  count={selectedRoles.length}
                  active
                  options={roleOptions.map((r) => {
                    if (r === "admin") {
                      return {
                        label: "Admin",
                        value: "admin",
                        handler: false,
                        checked: true,
                      };
                    }
                    if (r.split(" ").length > 1 && r !== "admin") {
                      return {
                        label: `${r} Permission`,
                        value: r,
                        handler: handleRoleChange,
                        checked: selectedRoles.indexOf(r) !== -1,
                      };
                    }
                    return {
                      label: r[0].toUpperCase() + r.slice(1),
                      value: r,
                      handler: handleRoleChange,
                      checked: selectedRoles.indexOf(r) !== -1,
                    };
                  })}
                  name="filters"
                  title="Visible To"
                  id="1"
                  containerMinWidth="160px"
                  menuStyles={{
                    maxWidth: "255px",
                    width: "255px",
                    right: "-44px",
                  }}
                  narrow
                  icons={[
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                  ]}
                />
                <CheckboxMenu
                  maxWidth="100%"
                  key={2}
                  count={selectedTeams.length}
                  active
                  options={teamOptions.map((t) => ({
                    ...t,
                    handler: handleTeamChange,
                    checked: selectedTeams.indexOf(t.value) !== -1,
                  }))}
                  name="filters"
                  title="Teams Visible To"
                  id="2"
                  containerMinWidth="160px"
                  menuStyles={{
                    maxWidth: "255px",
                    width: "255px",
                    right: "-44px",
                  }}
                  containerStyles={{ marginLeft: "10px" }}
                  narrow
                  icons={[
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                  ]}
                />
              </div>
            )}
            <button
              type="button"
              onClick={handleFeaturedTopicModal}
              style={{
                height: "48px",
                marginLeft: "20px",
                width: props.topic.featured ? "150px" : "130px",
              }}
              className="common-button-submit"
            >
              {props.topic.featured ? "Edit Featured Topic" : "Set As Featured"}
            </button>
          </div>
        )}
      </div>
      {sections.map((s) => {
        return (
          <WikiSection
            key={s.id}
            section={s}
            editingSection={editingSection}
            sectionsLength={sections.length}
            handleUpdateSection={handleUpdateSection}
            handleEditSection={handleEditSection}
            handleCreateSection={handleCreateSection}
            handleDeleteSection={handleDeleteSection}
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
            handleDragDrop={handleDragDrop}
            handleSetLoading={props.handleSetLoading}
            dragging={dragging}
            loading={props.loading}
            editMode={props.editing}
            setModal={props.setModal}
            currentUser={props.currentUser}
          />
        );
      })}
      {props.editing && (
        <div>
          <button
            style={{ marginTop: "30px" }}
            disabled={editingSection}
            onClick={handleAddSection}
            className="common-button-submit"
          >
            Add Text
          </button>
          <button
            style={{ marginTop: "30px" }}
            disabled={editingSection}
            onClick={handleAddImage}
            className="common-button-submit"
          >
            Add Media
          </button>
          <button
            style={{ marginTop: "30px", width: "160px" }}
            disabled={editingSection}
            data-secure="true"
            onClick={handleAddSection}
            className="common-button-submit"
          >
            Add Secure Content
          </button>
        </div>
      )}
    </div>
  );
};

export default WikiTopic;
