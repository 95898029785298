import React from 'react'

class MoveIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" d="M38.34,52.23l-4.46,7a2.44,2.44,0,0,1-3.76,0l-4.46-7"/>
        <line fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="32" y1="58.33" x2="32" y2="32"/>
        <path fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" d="M52.23,25.66l7,4.46a2.44,2.44,0,0,1,0,3.76l-7,4.46"/>
        <line fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="58.33" y1="32" x2="32" y2="32"/>
        <path fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" d="M25.66,11.77l4.46-7a2.44,2.44,0,0,1,3.76,0l4.46,7"/>
        <line fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="32" y1="5.67" x2="32" y2="32"/>
        <path fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" d="M11.77,38.34l-7-4.46a2.44,2.44,0,0,1,0-3.76l7-4.46"/>
        <line fill="none" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" x1="5.67" y1="32" x2="32" y2="32"/>
      </svg>
    );
  }
}
MoveIcon.defaultProps = {
  color: "#444344",
  height: "16px",
  svgStyles: {},
  width: "16px",
};
export default MoveIcon