import { string, number, arrayOf, shape } from "prop-types";
import wikiTopicTypes from "./topic";

export default shape({
  id: number,
  category: string,
  position: number,
  title: string,
  user_permissions: arrayOf(string),
  team_permissions: arrayOf(number),
  wiki_topics: arrayOf(wikiTopicTypes),
});
