import styled, { css } from "styled-components";
import Select from "react-select";

export const PerformancePageLayout = styled.div`
  max-width: calc(100% - 100px);
  width: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
`;

export const PerformanceTitleRow = styled.div`
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  padding: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PerformanceTitle = styled.h1`
  font-size: 21px;
  font-weight: 800;
  font-family: Manrope;
  margin: 0;
`;

export const HeaderIconContainer = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PerformanceBody = styled.div`
  padding: 0 16px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const PerformanceBodyProfile = styled.div`
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
`;

export const ContextControlRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContextControl = styled.div``;

export const ProjectAndDateSelectors = styled.div``;

export const DashboardWidget = styled.div`
  width: 100%;
  padding: 32px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-top: 5px;
  background-color: #fff;
`;

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const WidgetTitle = styled.p`
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SplitWidgetRow = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;

  @media (max-width: 1441px) {
    flex-direction: column;
  }
`;

export const SplitWidget = styled(DashboardWidget)`
  flex-basis: calc(50% - 3px);
  background-color: #fff;
  padding: 32px 0px 32px 32px;
`;

export const DropdownSelectCss = css`
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  width: 212px;

  // to get these styles to apply, add prop classNamePrefix="Select" to the react-select component
  .Select__control {
    height: 38px;
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    cursor: pointer;
  }

  .Select__control:hover {
    border: 1px solid #d3d3d3;
  }

  .Select__control--is-focused {
    outline: none;
  }

  .Select__value-container {
    height: 38px;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__indicator {
    color: #519acc;
  }
`;

export const DropdownLabel = styled.div`
  padding: 6px;
  display: flex;
  align-items: center;
`;

export const DropdownIconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DropdownIconPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props?.$color || "#519acc"};
  color: white;
  font-size: 9px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownName = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchableSelect = styled(Select)`
  ${DropdownSelectCss}
  width: 328px;

  @media (max-width: 953px) {
    width: 212px;
  }
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

  .Select__container {
    height: 38px;
    min-height: 38px;
  }

  .Select__control {
    height: 38px;
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none;
  }

  .Select__value {
    height: 38px;
  }

  .Select__single-value {
    height: 38px;
  }

  .Select__value-container {
    height: 38px;
    min-height: 38px;
    padding: 0 12px;

    & > div:not(.Select__placeholder) {
      margin: 0;
    }
  }

  .Select__input {
    margin: 0;

    input {
      height: 38px;
      max-height: 38px;
    }
  }

  .Select__placeholder {
    color: #242424;
    height: 36px;
    max-height: 36px;
    line-height: 36px;
    transform: none;
    top: unset;
    box-sizing: border-box;
  }

  .Select__indicators {
    position: relative;
  }

  .Select__indicator {
    padding-left: 0;
  }
`;

export const NavigationTitle = styled.h2`
  color: #242424;
  font-size: 14px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  margin-left: 10px;
`;
export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-top: 5px;
  background-color: #fff;
`;

export const NavigationLink = styled.a`
  display: flex;
  align-items: center;
  margin-left: 10px;
  text-decoration: none;
  color: inherit;
  margin-right: 21px;
`;
