import React from "react";
import axios from "axios";
import BuildIcon from "../../icons/build_icon";
import AddDeviceIcon from "../../icons/add_device_icon";

class TestPassDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewBuild: false,
    };
    this.measureText = document.createElement("canvas");
  }

  clearActivePass = () => {
    this.props.clearActivePass();
    window.history.pushState(
      "page2",
      "Test Platform",
      "/projects/" + this.props.project.id + "/test_passes/"
    );
  };

  handleCollapseExpandAll = (e) => {
    if (e.target.id === "test-pass-expand-all") {
      var testCaseCount = this.props.testPass.test_cases.length;
      for (var index = 0; index < testCaseCount; index++) {
        var sectionHeadingElem = document.querySelector(
          `#test-case-${index} > button`
        );
        if (sectionHeadingElem) {
          sectionHeadingElem.setAttribute("aria-expanded", "true");
        }
        var elems = document.querySelectorAll(`.test-case-group-${index}`);
        var resultElems = document.querySelectorAll(
          `.test-case-result-group-${index}`
        );
        var leftRowArrow = document.getElementById(
          `test-case-title-arrow-${index}`
        );
        if (elems.length > 0 && elems[0].style.display === "none") {
          leftRowArrow.style.transform = "rotate(0deg)";
          elems.forEach((elem, i) => {
            resultElems[i].style.display = "block";
            elem.style.display = "block";
          });
        }
      }
    } else if (e.target.id === "test-pass-collapse-all") {
      var testCaseCount = this.props.testPass.test_cases.length;
      for (var index = 0; index < testCaseCount; index++) {
        var sectionHeadingElem = document.querySelector(
          `#test-case-${index} > button`
        );
        if (sectionHeadingElem) {
          sectionHeadingElem.setAttribute("aria-expanded", "false");
        }
        var elems = document.querySelectorAll(`.test-case-group-${index}`);
        var resultElems = document.querySelectorAll(
          `.test-case-result-group-${index}`
        );
        var leftRowArrow = document.getElementById(
          `test-case-title-arrow-${index}`
        );
        if (elems.length > 0 && elems[0].style.height !== "none") {
          leftRowArrow.style.transform = "rotate(-90deg)";
          elems.forEach((elem, i) => {
            resultElems[i].style.display = "none";
            elem.style.display = "none";
          });
        }
      }
    }
  };

  progressLabelMouseEnter = (e) => {
    if (e.target.dataset.message) {
      var rect = e.target.getBoundingClientRect();
      var elem = document.getElementById("test-pass-hover-dialog");
      elem.style.transition = "height 0s";
      elem.style.height = "30px";
      var context = this.measureText.getContext("2d");
      context.font = "14px Arial";
      var width = context.measureText(e.target.dataset.message).width;
      var elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "1";
      elemContainer.style.transform = `translate(${
        rect.x + e.target.clientWidth / 2 - (width / 2 + 10)
      }px, ${rect.y - 38}px)`;
      var elem = document.getElementById("test-pass-hover-dialog-inner");
      elem.style.opacity = "1";
      var message = e.target.dataset.message.split(" ");
      message = (
        <span>
          {message[0]} {message[1]}{" "}
          <span style={{ color: e.target.dataset.color, fontWeight: "600" }}>
            {message[2]}
          </span>
        </span>
      );
      this.props.hoverDialogRef.current.getProgressLabel(message);
    }
  };

  progressLabelMouseLeave = (e) => {
    if (e.target.dataset.message) {
      var elem = document.getElementById("test-pass-hover-dialog");
      elem.style.transition = "height 0.4s";
      var elemContainer = document.getElementById(
        "test-pass-hover-dialog-container"
      );
      elemContainer.style.opacity = "0";
    }
  };

  onDragEnter = (e) => {
    if (e.target.dataset.deviceId != this.state.draggingId) {
      var elem = document.getElementById(
        `test-device-${this.state.draggingId}-container`
      );
      elem.style.opacity = "0.3";
      if (
        parseInt(e.target.dataset.devicePosition) < this.state.draggingPosition
      ) {
        document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-shrink`
        ).style.marginRight = "-30px";
        var elem = document.getElementById(
          `test-device-${e.target.dataset.deviceId}-left-drag-area`
        );
        elem.style.width = "30px";
      } else if (
        parseInt(e.target.dataset.devicePosition) > this.state.draggingPosition
      ) {
        document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-shrink`
        ).style.marginLeft = "-30px";
        var elem = document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-area`
        );
        elem.style.width = "30px";
      }
    } else {
      var elem = document.getElementById(
        `test-device-${this.state.draggingId}-container`
      );
      elem.style.opacity = "1";
    }
  };

  onDragLeave = (e) => {
    if (e.target.dataset.deviceId != this.state.draggingId) {
      if (
        parseInt(e.target.dataset.devicePosition) < this.state.draggingPosition
      ) {
        document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-shrink`
        ).style.marginRight = "0px";
        var elem = document.getElementById(
          `test-device-${e.target.dataset.deviceId}-left-drag-area`
        );
        elem.style.width = "0px";
      } else if (
        parseInt(e.target.dataset.devicePosition) > this.state.draggingPosition
      ) {
        document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-shrink`
        ).style.marginLeft = "0px";
        var elem = document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-area`
        );
        elem.style.width = "0px";
      }
    }
  };

  onDragStart = (e) => {
    window.addEventListener("dragover", this.handleDragScroll);
    var elem = document.getElementById("left-test-pass-container");
    elem.style.overflow = "hidden";
    var data = e.target.dataset;
    var dragCopy = document.getElementById(
      `test-device-${data.deviceId}-label`
    );
    //move label to front for a moment for drag image
    dragCopy.style.zIndex = "20";
    e.dataTransfer.setData("text", "anything");
    e.dataTransfer.setDragImage(dragCopy, 12, 12);
    dragCopy.style.zIndex = "0";
    setTimeout(() => {
      this.setState({
        dragging: true,
        draggingId: parseInt(data.deviceId),
        draggingPosition: parseInt(data.devicePosition),
      });
    }, 100);
  };

  onDragEnd = (e) => {
    if (this.dragTimer) {
      clearInterval(this.dragTimer);
      this.dragTimer = null;
    }
    window.removeEventListener("dragover", this.handleDragScroll);
    var elem = document.getElementById("left-test-pass-container");
    elem.style.overflow = "auto";
    var elem = document.getElementById(
      `test-device-${this.state.draggingId}-container`
    );
    elem.style.opacity = "1";
    this.setState({
      dragging: false,
      draggingId: null,
      draggingPosition: null,
    });
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragDrop = (e) => {
    e.preventDefault();
    if (e.target.dataset.deviceId != this.state.draggingId) {
      if (
        parseInt(e.target.dataset.devicePosition) < this.state.draggingPosition
      ) {
        this.handleDevicePositionChange(
          parseInt(e.target.dataset.devicePosition),
          this.state.draggingId
        );
        document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-shrink`
        ).style.marginRight = "0px";
        var elem = document.getElementById(
          `test-device-${e.target.dataset.deviceId}-left-drag-area`
        );
        elem.style.width = "0px";
      } else if (
        parseInt(e.target.dataset.devicePosition) > this.state.draggingPosition
      ) {
        this.handleDevicePositionChange(
          parseInt(e.target.dataset.devicePosition),
          this.state.draggingId
        );
        document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-shrink`
        ).style.marginLeft = "0px";
        var elem = document.getElementById(
          `test-device-${e.target.dataset.deviceId}-right-drag-area`
        );
        elem.style.width = "0px";
      }
    }
  };

  handleDevicePositionChange = (newPosition, deviceId) => {
    axios
      .patch(`/test_devices/${deviceId}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        test_device: {
          position: newPosition,
        },
      })
      .then((res) => {
        M.toast({
          html: `Device Moved`,
          displayLength: 2000,
          classes: "green darken-1 shift-toast",
        });
      })
      .catch((err) => console.error(err));
  };

  handleDragScroll = (e) => {
    if (
      e.clientX - this.props.testPassDeviceContainer.left < 50 &&
      e.clientX - this.props.testPassDeviceContainer.left > 0
    ) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          var elem = document.getElementById("left-test-pass-container");
          if (elem.scrollWidth > elem.clientWidth) {
            var stickyElem = document.getElementById("sticky-device-container");
            elem.scrollLeft = elem.scrollLeft - 5;
            stickyElem.scrollLeft = stickyElem.scrollLeft - 5;
          }
        }, 10);
      }
    } else if (
      this.props.testPassContainer.right - e.clientX < 50 &&
      this.props.testPassContainer.right - e.clientX > 0
    ) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          var elem = document.getElementById("left-test-pass-container");
          if (elem.scrollWidth > elem.clientWidth) {
            var stickyElem = document.getElementById("sticky-device-container");
            elem.scrollLeft = elem.scrollLeft + 5;
            stickyElem.scrollLeft = stickyElem.scrollLeft + 5;
          }
        }, 10);
      }
    } else if (window.innerHeight - e.clientY < 120) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          window.scrollBy(0, 5);
        }, 10);
      }
    } else if (window.innerHeight - (window.innerHeight - e.clientY) < 80) {
      if (this.dragTimer === null) {
        this.dragTimer = setInterval(() => {
          window.scrollBy(0, -5);
        }, 10);
      }
    } else {
      if (this.dragTimer) {
        clearInterval(this.dragTimer);
        this.dragTimer = null;
      }
    }
  };

  handleAddNewDevice = () => {
    this.props.setModal(true, "add-test-device", {
      testPassId: this.props.testPass.id,
      testDevices: this.props.testDevices,
      currentPlatform: this.props.currentPlatform,
      customClass: "new-test-device-modal",
    });
  };

  handleEditTestDevice = (e) => {
    this.props.setModal(true, "edit-test-device", {
      testDevices: this.props.testDevices,
      currentPlatform: this.props.currentPlatform,
      testDeviceId: e.target.dataset.deviceId,
      testDeviceName: e.target.dataset.deviceName,
      testDeviceOs: e.target.dataset.deviceOs,
      testDeviceBrowser: e.target.dataset.deviceBrowser,
      testDevicePosition: e.target.dataset.devicePosition,
      customClass: "new-test-device-modal",
    });
  };

  deviceKeyDown = (e) => {
    var elem = document.getElementById("aria-custom-status");
    if (this.repeatHelper === ".") {
      this.repeatHelper = "";
    } else {
      this.repeatHelper = ".";
    }
    switch (e.keyCode) {
      case 40: //down arrow
        e.preventDefault();
        switch (e.target.dataset.type) {
          case "heading":
            document
              .getElementById(`device-select-${e.target.dataset.deviceId}`)
              .focus();
            break;
          case "select":
            document
              .getElementById(
                `device_${e.target.dataset.deviceId}_progress_elem`
              )
              .focus();
            break;
          case "progress":
            document.getElementById("test-case-title-0").focus();
            break;
        }
        break;
      case 38: //up arrow
        e.preventDefault();
        switch (e.target.dataset.type) {
          case "heading":
            break;
          case "select":
            document
              .querySelector(`h3.device-index-${e.target.dataset.index}`)
              .focus();
            break;
          case "progress":
            document
              .getElementById(`device-select-${e.target.dataset.deviceId}`)
              .focus();
            break;
        }
        break;
      case 37: //left arrow
        e.preventDefault();
        var deviceContainer = document.querySelector(
          `.test-device-${parseInt(e.target.dataset.index) - 1}-container`
        );
        if (
          deviceContainer &&
          deviceContainer.style.display === "inline-block"
        ) {
          document
            .querySelector(
              `h3.device-index-${parseInt(e.target.dataset.index) - 1}`
            )
            .focus();
        } else {
          elem.innerHTML = `No more columns in that direction${this.repeatHelper}`;
        }
        break;
      case 39: //right arrow
        e.preventDefault();
        var deviceContainer = document.querySelector(
          `.test-device-${parseInt(e.target.dataset.index) + 1}-container`
        );
        if (
          deviceContainer &&
          deviceContainer.style.display === "inline-block"
        ) {
          document
            .querySelector(
              `h3.device-index-${parseInt(e.target.dataset.index) + 1}`
            )
            .focus();
        } else {
          elem.innerHTML = `No more columns in that direction${this.repeatHelper}`;
        }
        break;
    }
  };

  setCurrentColumn = (e) => {
    var currentColumn = e.target ? parseInt(e.target.dataset.index) : e;
    this.props.setCurrentColumn(currentColumn);
  };

  componentDidMount() {
    for (var x = 0; x < this.props.testDevices.length; x++) {
      if (
        this.props.testDevices[x].device_platform_id ===
        this.props.currentPlatform
      ) {
        this.setCurrentColumn(x);
        break;
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPlatform !== prevProps.currentPlatform) {
      for (var x = 0; x < this.props.testDevices.length; x++) {
        if (
          this.props.testDevices[x].device_platform_id ===
          this.props.currentPlatform
        ) {
          this.setCurrentColumn(x);
          break;
        }
      }
    }
  }

  hideFocus = (e) => {
    if (!e.target.classList.contains("hide-focus")) {
      e.target.classList.add("hide-focus");
    }
  };

  showFocus = (e) => {
    if (e.target.classList.contains("hide-focus")) {
      e.target.classList.remove("hide-focus");
    }
  };

  handleAssigneeChange = (e) => {
    var v = e.target.value;
    var deviceId = e.target.id.split("-");
    deviceId = deviceId[deviceId.length - 1];
    axios
      .patch("/test_device_assignee", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        id: parseInt(deviceId),
        user_id: v,
      })
      .then((res) => {})
      .catch((err) => {
        console.error(err);
        Swal.fire({
          text: "There was a problem updating device assignee",
          customClass: "swal2-error-modal",
        });
      });
  };

  previewBuild = (e) => {
    var id = parseInt(e.target.dataset.deviceId);
    this.setState({ previewBuild: id }, () => {
      var elem = document.getElementById(`test-device-${id}-build-input`);
      if (elem) {
        elem.focus();
      }
    });
  };

  updateTestDeviceBuild = (e) => {
    var id = parseInt(e.target.dataset.deviceId);
    axios
      .patch(`/test_devices/${id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        test_device: {
          build: e.target.value,
        },
      })
      .then((_) => {
        setTimeout(() => {
          this.setState({ previewBuild: false });
        }, 500);
      })
      .catch((err) => console.error(err));
  };

  render() {
    var height =
      (this.props.shortDevice ? 140 : 160) + (this.props.showBuild ? 38 : 0);

    return (
      <div
        id="sticky-device-container"
        style={{
          width: "100%",
          overflow: "hidden",
          display: "grid",
          gridTemplateColumns: `50% minmax(max-content, 50%)`,
        }}
      >
        <div
          id="title-container"
          style={{
            position: "sticky",
            display: "flex",
            justifyContent: "flex-end",
            left: "0",
            zIndex: "100",
            backgroundColor: "white",
            height: `${height}px`,
          }}
        >
          <button
            onMouseDown={this.hideFocus}
            onBlur={this.showFocus}
            aria-label="Back To Landing Page"
            id="pass-back-button"
            className="common-link"
            style={{
              display: "block",
              position: "absolute",
              bottom: "43px",
              left: 0,
              fontWeight: "600",
              background: "none",
              border: "none",
            }}
            onClick={this.clearActivePass}
          >
            {"< Back"}
          </button>
          <button
            onMouseDown={this.hideFocus}
            onBlur={this.showFocus}
            id="test-pass-expand-all"
            onClick={this.handleCollapseExpandAll}
            className="test-pass-expand-collapse-buttons"
            style={{
              left: "60px",
              fontWeight: "600",
              background: "none",
              border: "none",
            }}
          >
            Expand All
          </button>
          <button
            onMouseDown={this.hideFocus}
            onBlur={this.showFocus}
            id="test-pass-collapse-all"
            onClick={this.handleCollapseExpandAll}
            className="test-pass-expand-collapse-buttons"
            style={{
              left: "140px",
              fontWeight: "600",
              background: "none",
              border: "none",
            }}
          >
            Collapse All
          </button>
          <section
            role="text"
            id="all-progress-bar"
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginBottom: "17px",
              backgroundColor: "transparent",
              flex: "1 1 100%",
              borderRadius: "4px",
              height: "12px",
              border: "1px solid gray",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <div
              aria-hidden="true"
              className="pass-progress-bar"
              id="all-pass-progress"
              onMouseOver={this.progressLabelMouseEnter}
              onMouseLeave={this.progressLabelMouseLeave}
              data-message="Pass - 0"
              style={{
                height: "100%",
                position: "relative",
                display: "inline-flex",
                width: "0%",
                justifyContent: "center",
                verticalAlign: "top",
                backgroundColor: "#9ddea8",
              }}
              data-color="#7a9e80"
            ></div>
            <div
              aria-hidden="true"
              className="pass-progress-bar"
              id="all-fail-progress"
              onMouseOver={this.progressLabelMouseEnter}
              onMouseLeave={this.progressLabelMouseLeave}
              data-message="Fail - 0"
              style={{
                height: "100%",
                display: "inline-flex",
                width: "0%",
                justifyContent: "center",
                verticalAlign: "top",
                backgroundColor: "#c3552b",
              }}
              data-color="#c3552b"
            ></div>
            <div
              aria-hidden="true"
              className="pass-progress-bar"
              id="all-blocked-progress"
              onMouseEnter={this.progressLabelMouseEnter}
              onMouseLeave={this.progressLabelMouseLeave}
              data-message="Blocked - 0"
              style={{
                height: "100%",
                display: "inline-flex",
                width: "0%",
                justifyContent: "center",
                verticalAlign: "top",
                backgroundColor: "#f5ee89",
              }}
              data-color="#c5bc3e"
            ></div>
            <div
              aria-hidden="true"
              className="pass-progress-bar"
              id="all-retest-progress"
              onMouseEnter={this.progressLabelMouseEnter}
              onMouseLeave={this.progressLabelMouseLeave}
              data-message="Retest - 0"
              style={{
                height: "100%",
                display: "inline-flex",
                width: "0%",
                justifyContent: "center",
                verticalAlign: "top",
                backgroundColor: "#519bcc",
              }}
              data-color="#519bcc"
            ></div>
            <div
              aria-hidden="true"
              className="pass-progress-bar"
              id="all-na-progress"
              onMouseEnter={this.progressLabelMouseEnter}
              onMouseLeave={this.progressLabelMouseLeave}
              data-message="N/A - 0"
              style={{
                height: "100%",
                display: "inline-flex",
                width: "0%",
                justifyContent: "center",
                verticalAlign: "top",
                backgroundColor: "lavender",
              }}
              data-color="#9c9cda"
            ></div>
            <div
              aria-hidden="true"
              className="pass-progress-bar"
              id="all-untested-progress"
              onMouseEnter={this.progressLabelMouseEnter}
              onMouseLeave={this.progressLabelMouseLeave}
              data-message="Untested - 0"
              style={{
                height: "100%",
                display: "inline-flex",
                width: "0%",
                justifyContent: "center",
                verticalAlign: "top",
                backgroundColor: "lightgray",
              }}
              data-color="#afafaf"
            ></div>
          </section>
          <div
            aria-hidden="true"
            style={{
              alignSelf: "flex-end",
              marginBottom: "10px",
              justifyContent: "center",
              display: "flex",
              padding: "0 10px",
              flex: 1,
              fontWeight: "600",
            }}
          >
            <div
              id="total-steps-container"
              style={{
                flex: 1,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <p style={{ justifySelf: "end" }}>
                <span id="total-steps-completed">0</span> / &nbsp;{" "}
              </p>
              <p style={{ justifySelf: "start" }}>
                <span id="total-steps"> 0</span>&nbsp; steps
              </p>
            </div>
          </div>
          <button
            onClick={this.props.setShowBuild}
            style={{
              background: "none",
              cursor: "pointer",
              border: " solid 2px",
              height: "30px",
              alignSelf: "flex-end",
              margin: "6px 10px 8px 0",
              borderRadius: "4px",
              borderColor: this.props.showBuild ? "#519acc" : "lightgray",
            }}
          >
            <BuildIcon width={25} height={25} />
          </button>
        </div>
        <div
          id="test-pass-device-container"
          style={{
            height: `${height}px`,
            borderLeft: "solid 1px #519acc",
            borderTopLeftRadius: "5px",
          }}
        >
          {this.props.testDevices.map((assignment, index) => {
            var index1 = index;
            var showBuild =
              this.state.previewBuild === assignment.id || assignment.build;
            if (document.querySelector(`#device-select-${assignment.id}`)) {
              document.querySelector(`#device-select-${assignment.id}`).value =
                assignment.assignee_id === null ? 0 : assignment.assignee_id;
            }
            return (
              <div
                id={`test-device-${assignment.id}-container`}
                className={`test-device-${index1}-container`}
                key={`device-${assignment.id}-${index}`}
                style={{
                  overflow: "hidden",
                  width: "150px",
                  position: "relative",
                  display: `${
                    assignment.device_platform_id === this.props.currentPlatform
                      ? "inline-flex"
                      : "none"
                  }`,
                  borderRight: "solid 1px #519acc",
                  borderTopLeftRadius: "5px",
                  backgroundColor: "white",
                  borderTopRightRadius: "5px",
                  borderTop: "solid 1px #519acc",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: this.state.dragging ? "10" : "-10",
                  }}
                  onDragLeave={this.onDragLeave}
                  onDragEnter={this.onDragEnter}
                  onDrop={this.onDragDrop}
                  onDragOver={this.onDragOver}
                  data-device-position={index + 1}
                  data-device-id={assignment.id}
                />
                <div
                  key={`test-device-${assignment.id}-left-drag-area`}
                  id={`test-device-${assignment.id}-left-drag-area`}
                  style={{
                    display: "inline-block",
                    width: "0px",
                    backgroundColor: "lightgray",
                    height: `${height + 4}px`,
                    verticalAlign: "bottom",
                  }}
                />
                <div
                  id={`test-device-${assignment.id}-right-drag-shrink`}
                  style={{ display: "inline-block" }}
                >
                  <h3
                    onMouseDown={this.hideFocus}
                    data-type="heading"
                    onBlur={this.showFocus}
                    data-device-id={assignment.id}
                    role="columnheader"
                    className={`device-index-${index1} test-pass-device-label-container`}
                    onFocus={this.setCurrentColumn}
                    onKeyDown={this.deviceKeyDown}
                    data-index={index1}
                    tabIndex={0}
                    aria-label={`${
                      assignment.device_name
                        ? assignment.device_name + ", "
                        : ""
                    } ${assignment.os_name} ${
                      ", " + assignment.browser_name
                        ? assignment.browser_name
                        : ""
                    }. ${
                      assignment.assignee_id > 0
                        ? `Assigned to ${
                            this.props.projectUsersList[assignment.assignee_id]
                          }. `
                        : "Unassigned. "
                    } Use arrow keys to navigate testing column.`}
                    style={{ fontSize: "13px" }}
                  >
                    {assignment.device_platform_id === 1 ||
                    assignment.device_platform_id === 2 ||
                    this.props.currentPlatform === 5 ? (
                      <span
                        aria-hidden="true"
                        data-index={index1}
                        onMouseEnter={this.props.deviceLabelMouseEnter}
                        onMouseLeave={this.props.deviceLabelMouseLeave}
                        className="test-pass-device-label"
                        id={`test-device-${assignment.id}-label`}
                        style={{
                          width: "150px",
                          height: "27px",
                          lineHeight: "32px",
                          textAlign: "center",
                          display: "block",
                          position: "relative",
                        }}
                      >
                        {assignment.os_name}
                      </span>
                    ) : (
                      [
                        <span
                          aria-hidden="true"
                          data-index={index1}
                          onMouseEnter={this.props.deviceLabelMouseEnter}
                          onMouseLeave={this.props.deviceLabelMouseLeave}
                          className="test-pass-device-label"
                          id={`test-device-${assignment.id}-label`}
                          key={`test-device-${assignment.id}-label`}
                          style={{
                            width: "150px",
                            height: "26px",
                            lineHeight: "31px",
                            textAlign: "center",
                            display: "block",
                            position: "relative",
                          }}
                        >
                          {assignment.device_name}
                        </span>,
                        <span
                          aria-hidden="true"
                          data-index={index1}
                          className="test-pass-device-os-label"
                          id={`test-device-${assignment.id}-os-label`}
                          key={`test-device-${assignment.id}-os-label`}
                          style={{
                            width: "150px",
                            height: "22px",
                            lineHeight: "24px",
                            textAlign: "center",
                            display: "block",
                            position: "relative",
                            marginBottom:
                              this.props.currentPlatform === 6 ? "6px" : "0px",
                          }}
                        >
                          {assignment.os_name}
                        </span>,
                      ]
                    )}
                    {this.props.adminBool && (
                      <img
                        aria-hidden="true"
                        data-index={index1}
                        src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Pencil.svg"
                        id={`edit-device-${index}`}
                        data-type="icon"
                        className="test-device-edit-icon"
                        data-device-position={index + 1}
                        data-device-id={assignment.id}
                        data-device-name={assignment.device_name}
                        data-device-os={assignment.os_name}
                        data-device-browser={assignment.browser_name}
                        onClick={this.handleEditTestDevice}
                        style={{
                          verticalAlign: "bottom",
                          marginLeft: "7px",
                          cursor: "pointer",
                          position: "absolute",
                          right: "4px",
                          top: "6px",
                          fontSize: "20px",
                          height: "18px",
                          backgroundColor: "#ffffffab",
                        }}
                      />
                    )}
                    {this.props.adminBool && (
                      <img
                        aria-hidden="true"
                        data-index={index1}
                        alt="drag icon"
                        src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Move.svg"
                        id={`drag-device-${index}`}
                        data-type="icon"
                        className="test-device-drag-icon"
                        draggable={true}
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDragEnd}
                        data-device-position={index + 1}
                        data-device-id={assignment.id}
                        data-device-name={assignment.device_name}
                        data-device-browser={assignment.browser_name}
                        style={{
                          verticalAlign: "bottom",
                          marginLeft: "7px",
                          position: "absolute",
                          cursor: "grab",
                          right: "4px",
                          top: "30px",
                          fontSize: "20px",
                          height: "18px",
                          backgroundColor: "#ffffffab",
                        }}
                      />
                    )}
                    {!(this.props.currentPlatform === 6) && (
                      <span
                        aria-hidden="true"
                        data-index={index1}
                        id={
                          assignment.browser_name
                            ? assignment.browser_name
                            : `${assignment.device_name}-1`
                        }
                        style={{
                          width: "150px",
                          height: "28px",
                          lineHeight: "24px",
                          textAlign: "center",
                          display: "block",
                        }}
                      >
                        {assignment.browser_name}
                      </span>
                    )}
                  </h3>
                  <div id="commonFocus" style={{ position: "relative" }}>
                    <select
                      className="common-focus"
                      data-device-id={assignment.id}
                      data-index={index1}
                      data-type="select"
                      onKeyDown={this.deviceKeyDown}
                      onMouseDown={this.hideFocus}
                      onBlur={this.showFocus}
                      id={`device-select-${assignment.id}`}
                      onChange={this.handleAssigneeChange}
                      defaultValue={
                        assignment.assignee_id === null
                          ? 0
                          : assignment.assignee_id
                      }
                      style={{
                        width: "140px",
                        height: "33px",
                        margin: "0px auto",
                        textAlign: "center",
                        display: "block",
                        marginTop: "5px",
                      }}
                    >
                      <option data-index={index1} value={0}>
                        Unassigned
                      </option>
                      {this.props.projectUsers.map((user, index) => {
                        return (
                          <option
                            aria-label={`Assigned to ${
                              this.props.projectUsersList[
                                assignment.assignee_id
                              ]
                            }`}
                            key={`user-${index1}-${index}`}
                            value={user.id}
                          >
                            {user.name != "" ? user.name : user.email}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {this.props.showBuild && (
                    <div style={{ marginBottom: "1px" }}>
                      <p
                        style={{
                          fontSize: "11px",
                          paddingLeft: "6px",
                          lineHeight: "25px",
                          height: "21px",
                        }}
                      >
                        BUILD
                      </p>
                      <input
                        maxLength={15}
                        onBlur={this.updateTestDeviceBuild}
                        id={`test-device-${assignment.id}-build-input`}
                        className="test-device-build-input"
                        data-device-id={assignment.id}
                        defaultValue={assignment.build}
                        style={{
                          height: "28px",
                          border: "solid 2px #dedede",
                          width: "130px",
                          margin: "0 auto",
                          display: "block",
                          lineHeight: "26px",
                          fontSize: "13px",
                          paddingLeft: "5px",
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      height: this.props.showBuild ? "30px" : "50px",
                      width: "150px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="column-percent-container"
                  >
                    <progress
                      tabIndex={-1}
                      onKeyDown={this.deviceKeyDown}
                      onMouseDown={this.hideFocus}
                      onBlur={this.showFocus}
                      data-device-id={assignment.id}
                      data-index={index1}
                      data-type="progress"
                      style={{
                        height: "0",
                        width: "140px",
                        display: "block",
                        overflow: "hidden",
                        position: "absolute",
                      }}
                      id={`device_${assignment.id}_progress_elem`}
                      value={0}
                      max={100}
                    >
                      0%
                    </progress>
                    <div
                      aria-hidden="true"
                      style={{
                        height: this.props.showBuild ? "16px" : "26px",
                        position: "relative",
                        width: "140px",
                        backgroundColor: "#e8e8e8",
                        borderRadius: "6px",
                        overflow: "hidden",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        id={`device_${assignment.id}_bar`}
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          height: "100%",
                          width: "0%",
                          backgroundColor: "#519acc",
                        }}
                      />
                      <span
                        className="percent-text"
                        id={`device_${assignment.id}_text`}
                        style={{
                          display: "block",
                          fontSize: this.props.showBuild ? "12px" : "14px",
                          lineHeight: this.props.showBuild ? "18px" : "28px",
                          position: "absolute",
                          textAlign: "right",
                          width: "100%",
                          right: "0px",
                          top: "-1px",
                          fontWeight: "600",
                        }}
                      >
                        0%
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  key={`test-device-${assignment.id}-right-drag-area`}
                  id={`test-device-${assignment.id}-right-drag-area`}
                  style={{
                    display: "inline-block",
                    width: "0px",
                    backgroundColor: "lightgray",
                    height: `${height + 4}px`,
                    verticalAlign: "bottom",
                  }}
                />
              </div>
            );
          })}
          {this.props.adminBool && (
            <div
              id="add-device-button-container"
              aria-hidden="true"
              tabIndex={-1}
              style={{
                display: "inline-flex",
                width: "70px",
                verticalAlign: "top",
                height: `${height}px`,
                backgroundColor: "white",
                borderRight: "solid 1px lightgray",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                tabIndex={-1}
                onClick={this.handleAddNewDevice}
                style={{
                  fontSize: "15px",
                  backgroundColor: "transparent",
                  color: "#519acc",
                  fontWeight: "600",
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                }}
                id="add-test-device-button"
              >
                <AddDeviceIcon width={35} />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default TestPassDevices;
