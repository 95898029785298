import React from "react";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import TestPassLandingItem from "./test_pass_landing_item";
import sleep from "../../common/sleep.js";
import RoundDropdown from "../../common/round_dropdown";

class TestPassLandingSuite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suiteBugs: this.props.suite.bugs ? this.props.suite.bugs : [],
      testPasses: this.props.suite.test_passes,
      retiredPasses: null,
      open: false,
      loading: false,
      showActive: true,
    };
    this.expandClick = this.expandClick.bind(this);
    this.open = this.open.bind(this);
    this.handleCableUpdate = this.handleCableUpdate.bind(this);
    this.setShowActive = this.setShowActive.bind(this);
    this.transition = false;
  }

  componentDidMount() {
    if (
      localStorage.getItem(`project_${this.props.project.id}_recent_suite`) ==
      this.props.suite.id
    ) {
      this.setState({ loading: true });
      axios
        .get(`/landing_suite/${this.props.suite.id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.setState(
            {
              suiteBugs: res.data.bugs,
              testPasses: res.data.test_passes,
              loading: false,
            },
            () => {
              let elem = document.getElementById(
                `suite-${this.props.suite.id}`
              );
              if (elem) {
                elem.scrollIntoView();
              }
              elem = document.querySelector(
                `#suite-${this.props.suite.id} .pass-landing-button:first-child`
              );
              if (elem) {
                this.open(elem);
              }
            }
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
        });
    }
  }

  expandClick(e) {
    if (!this.transition) {
      this.transition = true;
      const button = e.target;
      if (this.state.testPasses || this.state.open) {
        this.open(button);
      } else {
        this.setState({ loading: true });
        axios
          .get(`/landing_suite/${this.props.suite.id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.setState(
              {
                suiteBugs: res.data.bugs,
                testPasses: res.data.test_passes,
                loading: false,
              },
              () => {
                this.open(button);
              }
            );
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.error(err);
          });
      }
    }
  }

  async open(button) {
    if (this.state.open) {
      const elem = document.getElementById(`suite-${this.props.suite.id}`);
      if (elem) {
        button.setAttribute("aria-expanded", "false");
        const height = 40;
        elem.style.height = `${elem.clientHeight}px`;
        await sleep(100);
        elem.style.height = `${height}px`;
        await sleep(300);
        elem.style.height = "auto";
        this.setState({ open: false }, () => {
          this.transition = false;
        });
      } else {
        this.setState({ open: false }, () => {
          this.transition = false;
          button.setAttribute("aria-expanded", "false");
        });
      }
    } else {
      const elem = document.getElementById(`suite-${this.props.suite.id}`);
      if (elem) {
        elem.style.height = "40px";
        button.setAttribute("aria-expanded", "true");
        this.setState({ open: true }, async () => {
          await sleep(100);
          elem.style.height = `${elem.scrollHeight}px`;
          await sleep(300);
          elem.style.height = "auto";
          localStorage.setItem(
            `project_${this.props.project.id}_recent_suite`,
            this.props.suite.id
          );
          this.transition = false;
        });
      } else {
        this.setState({ open: true }, () => {
          localStorage.setItem(
            `project_${this.props.project.id}_recent_suite`,
            this.props.suite.id
          );
          this.transition = false;
          button.setAttribute("aria-expanded", "true");
        });
      }
    }
  }

  handleCableUpdate(testPass, bugs, delete_id) {
    if (testPass && this.state.testPasses) {
      const testPassIds = this.state.testPasses.map((t) => t.id);
      const index = testPassIds.indexOf(testPass.id);
      if (index !== -1 && testPass.status === "retired") {
        const retiredPass = this.state.testPasses[index];
        this.setState((prevState) => ({
          testPasses: prevState.testPasses.filter(
            (pass) => pass.id !== testPass.id
          ),
          retiredPasses: prevState.retiredPasses
            ? [...prevState.retiredPasses, ...[{ ...retiredPass, ...testPass }]]
            : prevState.retiredPasses,
        }));
      } else if (testPassIds.indexOf(testPass.id) !== -1) {
        this.setState((prevState) => ({
          testPasses: prevState.testPasses.map((pass) =>
            pass.id === testPass.id ? { ...pass, ...testPass } : pass
          ),
        }));
      } else if (
        testPassIds.indexOf(testPass.id) === -1 &&
        testPass.status === "active"
      ) {
        if (!this.state.retiredPasses) {
          axios
            .get(`/landing_suite/${this.props.suite.id}`)
            .then((res) => {
              this.setState({
                suiteBugs: res.data.bugs,
                testPasses: res.data.test_passes,
                loading: false,
              });
            })
            .catch((err) => {
              this.setState({ loading: false });
              console.error(err);
            });
        } else {
          const retiredPasses = this.state.retiredPasses.filter(
            (r) => r.id !== testPass.id
          );
          const activePass = this.state.retiredPasses.find(
            (r) => r.id === testPass.id
          );
          if (activePass) {
            this.setState((prevState) => ({
              testPasses: [
                ...prevState.testPasses,
                ...[{ ...activePass, ...testPass }],
              ],
              retiredPasses: retiredPasses.length > 0 ? retiredPasses : null,
              showActive:
                retiredPasses.length > 0 ? prevState.showActive : true,
            }));
          }
        }
      }
    } else if (bugs) {
      this.setState({ suiteBugs: bugs });
    } else if (delete_id && this.state.testPasses) {
      this.setState((prevState) => ({
        testPasses: prevState.testPasses.filter(
          (pass) => pass.id !== delete_id
        ),
      }));
    }
  }

  setShowActive() {
    if (!this.state.loading) {
      if (this.state.showActive && !this.state.retiredPasses) {
        this.setState({ loading: true });
        axios
          .get(`/get_retired_passes/${this.props.suite.id}`)
          .then((res) => {
            this.setState({
              retiredPasses: res.data,
              loading: false,
              showActive: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.error(err);
          });
      } else {
        this.setState({ showActive: !this.state.showActive });
      }
    }
  }

  render() {
    const { suite } = this.props;
    const bugs = this.state.suiteBugs;
    const passes = this.state.showActive
      ? this.state.testPasses
      : this.state.retiredPasses;
    return (
      <div
        id={`suite-${suite.id}`}
        key={suite.id}
        style={{
          height: "auto",
          position: "relative",
          overflow: "hidden",
          transition: "height 0.3s",
        }}
      >
        <button
          aria-label={`Test Passes for ${suite.name}`}
          aria-live="polite"
          aria-expanded="true"
          className="pass-landing-button"
          onMouseDown={this.props.hideFocus}
          onBlur={this.props.showFocus}
          onClick={this.expandClick}
          type="button"
          data-id={suite.id}
          style={{
            fontWeight: "600",
            cursor: "pointer",
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "4px",
            left: "0px",
            backgroundColor: "white",
            fontSize: "13pt",
            paddingRight: "35px",
          }}
        >
          {suite.name}
          <span
            style={{
              transition: "transform .5s",
              transform: this.state.open ? "rotateX(180deg)" : "",
              display: "flex",
              marginLeft: "5px",
              pointerEvents: "none",
            }}
          >
            <RoundDropdown />
          </span>
          <div aria-hidden style={{ position: "absolute", right: "5px" }}>
            <CSSTransition
              unmountOnExit
              in={this.state.loading}
              timeout={500}
              classNames="node-fade"
            >
              <div id="first-build-spinner" />
            </CSSTransition>
          </div>
        </button>
        <div
          style={{
            borderBottom: this.state.open ? "solid 1px lightgray" : "none",
            height: "20px",
            marginBottom: "20px",
            marginRight: "20px",
            position: "relative",
            bottom: "4px",
            zIndex: "-1",
          }}
        />
        {this.state.open && (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className="pass-landing-button"
                onBlur={this.props.showFocus}
                onMouseDown={this.props.hideFocus}
                type="button"
                aria-pressed={this.state.showActive ? "true" : "false"}
                onClick={this.setShowActive}
                style={{
                  display: "block",
                  border: "none",
                  background: "none",
                  marginRight: "14px",
                  fontSize: "10pt",
                  fontWeight: "600",
                  color: this.state.showActive ? "#519acc" : "#242424",
                  cursor: "pointer",
                  pointerEvents: this.state.showActive ? "none" : "all",
                }}
              >
                Active
              </button>
              <button
                className="pass-landing-button"
                onBlur={this.props.showFocus}
                onMouseDown={this.props.hideFocus}
                type="button"
                aria-pressed={!this.state.showActive ? "true" : "false"}
                onClick={this.setShowActive}
                style={{
                  display: "block",
                  border: "none",
                  background: "none",
                  marginRight: "18px",
                  fontSize: "10pt",
                  fontWeight: "600",
                  color: !this.state.showActive ? "#519acc" : "#242424",
                  cursor: "pointer",
                  pointerEvents: this.state.showActive ? "all" : "none",
                }}
              >
                Retired
              </button>
              {bugs && bugs.length > 0 && (
                <a
                  tabIndex={-1}
                  aria-label={`View the ${bugs.length} bugs linked to ${suite.name}`}
                  className="pass-landing-button"
                  onMouseDown={this.props.hideFocus}
                  onBlur={this.props.showFocus}
                  target="_blank"
                  rel="noreferrer"
                  href={`/projects/${
                    this.props.project.id
                  }/bugs?search=ID:${bugs.join(",")}`}
                  data-bugs={bugs}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "36px",
                    width: "36px",
                    borderRadius: "50%",
                    backgroundColor: "#e6e6fab0",
                    border: "solid 1px lightgray",
                  }}
                >
                  <img
                    alt="Bugs list icon"
                    src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/Bugs.svg"
                    style={{ height: "26px", pointerEvents: "none" }}
                  />
                  <span
                    style={{
                      right: "-5px",
                      top: "-5px",
                      fontSize: bugs.length > 99 ? "8px" : "10px",
                    }}
                    className="counter landing-pass-device-counter"
                  >
                    {bugs.length}
                  </span>
                </a>
              )}
            </div>
            <div
              aria-label={`${
                this.state.showActive ? "Active" : "Retired"
              } Test Passes`}
              role="list"
            >
              {passes.length > 0 ? (
                passes
                  .sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                  })
                  .map((testPass) => {
                    return (
                      <TestPassLandingItem
                        adminBool={this.props.adminBool}
                        showFocus={this.props.showFocus}
                        hideFocus={this.props.hideFocus}
                        setModal={this.props.setModal}
                        handleTestPassDelete={this.props.handleTestPassDelete}
                        deviceUrls={this.props.deviceUrls}
                        setTestPass={this.props.setTestPass}
                        suiteId={suite.id}
                        projectId={this.props.project.id}
                        testPass={testPass}
                        key={testPass.id}
                      />
                    );
                  })
              ) : (
                <div
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: "14pt", fontWeight: "600" }}>
                    No {this.state.showActive ? "active" : "retired"} test
                    passes found.
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default TestPassLandingSuite;
