import React from "react";

const BugsSilverSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Bugs_-_Silver_-_Small_svg__cls-1{fill:#939598}.Skills__Bugs_-_Silver_-_Small_svg__cls-2{fill:#bcbec0}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g id="Skills__Bugs_-_Silver_-_Small_svg__Layer_2" data-name="Layer 2">
        {" "}
        <path
          className="Skills__Bugs_-_Silver_-_Small_svg__cls-1"
          d="M474.94 897.11l-229.22-134.7a98.66 98.66 0 01-48.67-85.82l2-265.86a98.63 98.63 0 0150-85.05l231.29-131.16a98.66 98.66 0 0198.66.76L808.21 330a98.63 98.63 0 0148.67 85.81l-2 265.86a98.67 98.67 0 01-50 85.06L573.59 897.87a98.64 98.64 0 01-98.65-.76z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_-_Small_svg__cls-2"
          d="M474.94 884.72L245.72 750a98.65 98.65 0 01-48.67-85.81l2-265.86a98.65 98.65 0 0150-85.06l231.29-131.14a98.66 98.66 0 0198.66.76l229.21 134.7a98.64 98.64 0 0148.67 85.82l-2 265.86a98.65 98.65 0 01-50 85.05L573.59 885.48a98.64 98.64 0 01-98.65-.76z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_-_Small_svg__cls-2"
          d="M480.35 848.24L275 727.54a88.4 88.4 0 01-43.61-76.89l1.83-238.22A88.42 88.42 0 01278 336.21l207.19-117.52a88.41 88.41 0 0188.4.68L779 340.07A88.4 88.4 0 01822.59 417l-1.84 238.22A88.4 88.4 0 01776 731.4L568.75 848.92a88.41 88.41 0 01-88.4-.68z"
        />{" "}
        <path
          className="Skills__Bugs_-_Silver_-_Small_svg__cls-1"
          d="M752.47 511c-19.16-25.62-41-41.18-65-46.23a76.3 76.3 0 00-46.63 4.54c-12.5-35-39.8-61.54-76.87-68.58v-.74a66.19 66.19 0 017.86-29.89c11.51-21.79 33.6-35.89 65.63-41.89a11 11 0 00-4-21.55c-48.5 9.09-70.8 34-81 53.2a89.72 89.72 0 00-10.39 39.05l-27.29.24c-1.71 0-3.42.08-5.12.18a89.49 89.49 0 00-11.1-39c-10.52-19.05-33.27-43.51-81.93-51.72a11 11 0 10-3.63 21.67c74.79 12.63 74.81 68 74.78 70.49a10.85 10.85 0 00.15 2.14c-33.82 9.28-64.11 35-75.41 67.89a76.54 76.54 0 00-45.23-3.1c-23.87 5.49-45.45 21.44-64.13 47.4a11 11 0 0017.79 12.81c17.16-23.85 37.19-37.68 57.89-40a52.26 52.26 0 0128.7 4.65 104.92 104.92 0 00-.72 13.33l.4 44a77.86 77.86 0 00-32.05 3.5c-24.79 7.93-46.64 26.92-64.94 56.43a11 11 0 003.53 15.18 10.84 10.84 0 005.86 1.65 11 11 0 009.23-5.18c15.81-25.51 34.84-42 55-47.74a53.83 53.83 0 0123.51-1.57l.45 49.82a103.14 103.14 0 00.88 12.63 71.25 71.25 0 00-29.44 11.64c-22.84 15.5-37.68 43.09-44.08 82a11 11 0 009 12.6 12.13 12.13 0 001.89.15 11 11 0 0010.7-9.19c5.3-32.14 17.24-55.4 34.55-67.27a48.09 48.09 0 0122.47-8.42c14.67 41.41 54.5 71.11 100.72 70.69h2.73V527.89a10.55 10.55 0 0110.55-10.54 10.55 10.55 0 0110.55 10.54v198.64h4.78c46.26-.42 85.57-30.89 99.46-72.61a47.46 47.46 0 0124.29 8.1c17.51 11.55 29.88 34.59 35.76 66.63a11 11 0 0010.87 9 10.13 10.13 0 001.88-.18 11 11 0 008.81-12.76c-7.11-38.78-22.44-66.1-45.56-81.18a70.83 70.83 0 00-31.32-11.29 104.37 104.37 0 00.63-12.44l-.45-49.45c4.94-1.2 14-2.19 25.23.76 20.3 5.36 39.63 21.51 55.89 46.73a11 11 0 0018.5-11.94c-18.82-29.18-41-47.77-65.94-55.25a77.24 77.24 0 00-33.8-2.67l-.4-44.24c0-3.69-.27-7.35-.67-10.95a10.65 10.65 0 001.31-2.33 52.14 52.14 0 0128.7-5.22c20.75 1.92 41 15.38 58.6 38.91A11 11 0 00752.47 511z"
        />{" "}
        <path
          d="M752.47 499.59c-19.16-25.62-41-41.17-65-46.23a76.28 76.28 0 00-46.63 4.55c-12.5-35-39.8-61.55-76.87-68.58v-.75a66.19 66.19 0 017.86-29.89c11.51-21.78 33.6-35.88 65.63-41.88a11 11 0 00-4-21.55c-48.5 9.09-70.8 33.95-81 53.2a89.69 89.69 0 00-10.39 39l-27.29.25c-1.71 0-3.42.07-5.12.17a89.46 89.46 0 00-11.1-39c-10.52-19.06-33.27-43.52-81.93-51.73a11 11 0 00-3.63 21.69c74.79 12.63 74.81 68 74.78 70.49a11 11 0 00.15 2.15c-33.82 9.27-64.11 35-75.41 67.88a76.54 76.54 0 00-45.23-3.1c-23.87 5.49-45.45 21.44-64.13 47.4a11 11 0 0017.79 12.81c17.16-23.84 37.19-37.67 57.89-40a52.15 52.15 0 0128.7 4.66 104.76 104.76 0 00-.72 13.32l.4 44a78.2 78.2 0 00-32.05 3.5c-24.79 7.93-46.64 26.92-64.94 56.43A11 11 0 00328.85 610c15.81-25.51 34.84-42 55-47.73a53.68 53.68 0 0123.51-1.57l.45 49.81a103.31 103.31 0 00.88 12.64 71.07 71.07 0 00-29.44 11.63c-22.84 15.5-37.68 43.09-44.08 82a11 11 0 1021.63 3.56c5.3-32.14 17.24-55.4 34.55-67.27a48.09 48.09 0 0122.47-8.42c14.67 41.41 54.5 71.11 100.72 70.69h2.73V516.46a10.55 10.55 0 0110.55-10.55 10.55 10.55 0 0110.55 10.55v198.63h4.78c46.26-.42 85.57-30.89 99.46-72.6a47.26 47.26 0 0124.29 8.1c17.51 11.54 29.88 34.58 35.76 66.63a11 11 0 0010.87 9 12.19 12.19 0 001.88-.18 11 11 0 008.81-12.77c-7.11-38.77-22.44-66.09-45.56-81.18a71.08 71.08 0 00-31.32-11.29 104.2 104.2 0 00.63-12.43l-.45-49.46c4.94-1.2 14-2.19 25.23.77 20.3 5.35 39.63 21.51 55.89 46.73a11 11 0 0018.42-11.9c-18.82-29.17-41-47.76-65.94-55.24a77.26 77.26 0 00-33.8-2.68L647 492.3c0-3.69-.27-7.35-.67-10.94a10.72 10.72 0 001.27-2.36 52.07 52.07 0 0128.7-5.21c20.75 1.91 41 15.38 58.6 38.91a11 11 0 008.89 4.39 11 11 0 008.68-17.52z"
          fill="#fff"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default BugsSilverSmall;
