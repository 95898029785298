import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  InviteForm,
  InviteLabel,
  InviteInputWrapper,
  UserLookup,
} from "./UserInvites";
import { WikiAssessmentsContext } from "../index";

const TeamInvite = (props) => {
  const { handleTeamSelect } = props;
  const { teams } = useContext(WikiAssessmentsContext);

  const teamOptions = useMemo(() => {
    return teams
      .filter((team) => team.active)
      .map((team) => ({ value: team.id, label: team.name }));
  }, [teams]);

  const handleSelect = (selectedOption) => {
    const team = teams.find((t) => t.id === selectedOption.value);
    handleTeamSelect(team);
  };

  return (
    <InviteForm>
      <InviteLabel>Invite all team members to Assessment</InviteLabel>
      <TeamInviteInputWrapper>
        <TeamSelect
          options={teamOptions}
          onChange={handleSelect}
          placeholder="Select a team"
          classNamePrefix="Select"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.querySelector("body")}
        />
      </TeamInviteInputWrapper>
    </InviteForm>
  );
};

const TeamInviteInputWrapper = styled(InviteInputWrapper)`
  width: 100%;
`;

const TeamSelect = styled(UserLookup)`
  width: 100%;
  max-width: 450px;
  border-radius: 5px;
`;

TeamInvite.propTypes = {
  handleTeamSelect: PropTypes.func.isRequired,
};

export default TeamInvite;
