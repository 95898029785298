import { useEffect, useState } from "react";

const useSessionStorage = (key, fallback) => {
  const getSessionStorageValue = (k, f) => {
    const stored = sessionStorage.getItem(k);
    if (!stored) {
      return f;
    }
    return JSON.parse(stored);
  };

  const [value, setValue] = useState(getSessionStorageValue(key, fallback));

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new Event("storage"));
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
