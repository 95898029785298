import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ProgressRing from "../../common/progress_ring";
import TestPassLandingDevice from "./test_pass_landing_device";
import sleep from "../../common/sleep.js";
import BasicSelect from "../../common/basic_select";

class TestPassLandingItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      testPass: this.props.testPass,
      progress: this.props.testPass.progress,
      sortBy: {
        label: "By Platform",
        value: "platform",
        icons: [
          false,
          false,
          "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
        ],
      },
    };
    this.setPass = this.setPass.bind(this);
    this.open = this.open.bind(this);
    this.copyLink = this.copyLink.bind(this);
    this.setSort = this.setSort.bind(this);
    this.copyTestPass = this.copyTestPass.bind(this);
    this.intervalTimer = null;
    this.sortDevices = this.sortDevices.bind(this);
    this.retirePass = this.retirePass.bind(this);
    this.transition = false;
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
  }

  setPass() {
    this.props.setTestPass(this.props.testPass.id);
    const elem = document.getElementById("aria-custom-status");
    if (elem) {
      elem.innerHTML = "loading test pass";
    }
  }

  async open() {
    if (!this.transition) {
      this.transition = true;
      if (this.state.open) {
        var elem = document.getElementById(
          `test-pass-${this.props.testPass.id}`
        );
        if (elem) {
          const height = 118;
          elem.style.height = `${elem.clientHeight}px`;
          await sleep(50);
          elem.style.height = `${height}px`;
          await sleep(300);
          elem.style.height = "auto";
          this.setState({ open: false }, () => {
            this.transition = false;
          });
        } else {
          this.setState({ open: false }, () => {
            this.transition = false;
          });
        }
      } else {
        var elem = document.getElementById(
          `test-pass-${this.props.testPass.id}`
        );
        if (elem) {
          elem.style.height = "118px";
          this.setState({ open: true }, async () => {
            await sleep(50);
            elem.style.height = `${elem.scrollHeight}px`;
            await sleep(300);
            elem.style.height = "auto";
            this.transition = false;
          });
        } else {
          this.setState({ open: true }, () => {
            this.transition = false;
          });
        }
      }
    }
  }

  async copyLink() {
    const url = window.location.href;
    const last = url.substring(url.length, url.length - 1);
    if (last == "/") {
      await navigator.clipboard.writeText(
        `${window.location.href}${this.props.testPass.id}`
      );
    } else {
      await navigator.clipboard.writeText(
        `${window.location.href}/${this.props.testPass.id}`
      );
    }
    M.toast({
      html: '<p role="alert">Link Copied To Clipboard</p>',
      displayLength: 3000,
      classes: "green",
    });
  }

  setSort(sortBy) {
    this.setState({ sortBy });
  }

  sortDevices(devices) {
    const sortBy = this.state.sortBy.value;
    if (sortBy === "platform") {
      return devices.sort((a, b) => {
        if (a.device_platform < b.device_platform) {
          return -1;
        }
        if (a.device_platform > b.device_platform) {
          return 1;
        }
        return 0;
      });
    }
    if (sortBy === "percent") {
      return devices.sort((a, b) => {
        if (a.percent < b.percent) {
          return -1;
        }
        if (a.percent > b.percent) {
          return 1;
        }
        return 0;
      });
    }
    if (sortBy === "type") {
      return devices.sort((a, b) => {
        if (a.device_type < b.device_type) {
          return -1;
        }
        if (a.device_type > b.device_type) {
          return 1;
        }
        return 0;
      });
    }
    if (sortBy === "assignee") {
      return devices.sort((a, b) => {
        if (a.assignee.toLowerCase() < b.assignee.toLowerCase()) {
          return -1;
        }
        if (a.assignee.toLowerCase() > b.assignee.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
  }

  retirePass() {
    const self = this;
    Swal.fire({
      title: `${
        self.props.testPass.status === "active" ? "Retire" : "Activate"
      } Test Pass?`,
      text: `Are you sure you want to ${
        self.props.testPass.status === "active" ? "retire" : "activate"
      } this Test Pass?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .patch(`/test_passes/${self.props.testPass.id}.json`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            test_pass: {
              status:
                self.props.testPass.status === "active" ? "retired" : "active",
            },
          })
          .then((res) => {})
          .catch((err) => {
            Swal.fire({
              text: `There was a problem ${
                self.props.testPass.status === "active"
                  ? "retiring"
                  : "activating"
              } this Test Pass`,
              type: "error",
              customClass: "swal2-error-modal",
            });
          });
      }
    });
  }

  copyTestPass(e) {
    const elem = document.getElementById("test-pass-menu-selector");
    if (elem) {
      elem.click();
    }
    this.props.setModal(true, "new-test-pass", {
      newPass: false,
      testSuiteId: this.props.suiteId,
      testPassId: this.props.testPass.id,
      testPassName: this.props.testPass.name,
      customClass: "new-test-pass-modal new-scrollbar",
      testSuiteName: null,
    });
  }

  render() {
    const { progress } = this.props.testPass;
    const totalSteps = Object.values(progress).reduce((a, b) => a + b, 0);
    const updatedTime = this.props.testPass.last_updated
      ? this.props.testPass.last_updated.updated_at
      : "";
    const updatedUser = this.props.testPass.last_updated
      ? this.props.testPass.last_updated.user
      : "";

    return (
      <div
        role="list-item"
        aria-label={`${this.props.testPass.name}`}
        id={`test-pass-${this.props.testPass.id}`}
        style={{
          borderRadius: "6px",
          height: "auto",
          transition: "height 0.3s",
          position: "relative",
          overflow: "hidden",
          boxShadow: "rgba(0, 0, 0, 0.37) 0px 0px 4px",
          whiteSpace: "nowrap",
          margin: "15px 20px 15px 3px",
          padding: "15px 15px 13px 15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "3px",
          }}
        >
          <div
            style={{
              display: "inline-block",
              flex: ".45 .45 0",
              textAlign: "left",
              paddingRight: "10px",
            }}
          >
            <div
              aria-hidden="true"
              style={{
                display: "inline-block",
                margin: "0px 20px 0px 10px",
                position: "relative",
              }}
            >
              <ProgressRing
                radius={35}
                relative
                stroke={7}
                text={this.props.testPass.percent_complete}
                textStyles={{
                  fontWeight: "600",
                  fontSize: "13pt",
                  fill: "black",
                }}
                progress={this.props.testPass.percent_complete.slice(0, -1)}
                phantomRing
              />
            </div>
            <div style={{ display: "inline-block" }}>
              <div style={{ lineHeight: "14px" }}>
                <img
                  aria-hidden="true"
                  style={{ height: "30px" }}
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Pass%20-%20Black.svg"
                />
                <button
                  aria-label={`${
                    this.props.testPass.name
                  }, ${this.props.testPass.percent_complete.slice(
                    0,
                    -1
                  )}% complete, activate to navigate to this test pass.         `}
                  className="pass-landing-button"
                  onMouseDown={this.props.hideFocus}
                  onBlur={this.props.showFocus}
                  onClick={this.setPass}
                  style={{
                    color: "#519acc",
                    fontSize: `${
                      this.props.testPass.name.length > 30 ? "9.2pt" : "13pt"
                    }`,
                    verticalAlign: "8px",
                    paddingLeft: "5px",
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                  }}
                >
                  {this.props.testPass.name}
                </button>
              </div>
              {updatedTime ? (
                <div
                  aria-label={`Last updated ${updatedTime} ago ${
                    updatedUser ? `by ${updatedUser}` : ""
                  }`}
                  role="text"
                >
                  <span
                    aria-hidden
                    style={{
                      fontStyle: "italic",
                      fontSize: "11pt",
                      color: "#b7b7b7",
                      paddingLeft: "5px",
                    }}
                  >
                    Last updated{" "}
                    <span id={`updated-time-${this.props.testPass.id}`}>
                      {updatedTime}
                    </span>{" "}
                    ago {updatedUser ? "by" : ""}{" "}
                  </span>
                  {updatedUser && (
                    <span
                      aria-hidden
                      style={{ fontSize: "10pt", color: "#519acc" }}
                    >
                      {updatedUser}
                    </span>
                  )}
                </div>
              ) : (
                <div aria-hidden="true" style={{ height: "29px" }} />
              )}
              <div style={{ lineHeight: "22px" }}>
                <button
                  onClick={this.copyLink}
                  style={{
                    color: "#519acc",
                    fontSize: "10pt",
                    paddingLeft: "5px",
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                  }}
                >
                  Copy URL
                </button>
                {this.props.adminBool && (
                  <span
                    aria-hidden="true"
                    onClick={this.copyTestPass}
                    style={{
                      color: "#519acc",
                      fontSize: "10pt",
                      marginLeft: "20px",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                  >
                    Copy Test Pass
                  </span>
                )}
                {this.props.adminBool && (
                  <span
                    onClick={this.retirePass}
                    style={{
                      color: "#519acc",
                      fontSize: "10pt",
                      marginLeft: "20px",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                  >
                    {this.props.testPass.status === "active"
                      ? "Retire"
                      : "Activate"}
                  </span>
                )}
                {this.props.adminBool && (
                  <span
                    onClick={this.props.handleTestPassDelete}
                    data-id={this.props.testPass.id}
                    style={{
                      color: "#519acc",
                      fontSize: "10pt",
                      marginLeft: "20px",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                  >
                    Delete
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            style={{ display: "inline-block", flex: ".5 .5 0" }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "transparent",
                minWidth: "350px",
                borderRadius: "4px",
                height: "12px",
                border: "solid 1px gray",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "100%",
                  position: "relative",
                  display: "inline-flex",
                  width: `${(progress[1] / totalSteps) * 100}%`,
                  justifyContent: "center",
                  verticalAlign: "top",
                  backgroundColor: "#9ddea8",
                }}
                data-color="#7a9e80"
              />
              <div
                style={{
                  height: "100%",
                  display: "inline-flex",
                  width: `${(progress[2] / totalSteps) * 100}%`,
                  justifyContent: "center",
                  verticalAlign: "top",
                  backgroundColor: "#c3552b",
                }}
                data-color="#c3552b"
              />
              <div
                style={{
                  height: "100%",
                  display: "inline-flex",
                  width: `${(progress[3] / totalSteps) * 100}%`,
                  justifyContent: "center",
                  verticalAlign: "top",
                  backgroundColor: "#f5ee89",
                }}
                data-color="#c5bc3e"
              />
              <div
                style={{
                  height: "100%",
                  display: "inline-flex",
                  width: `${(progress[4] / totalSteps) * 100}%`,
                  justifyContent: "center",
                  verticalAlign: "top",
                  backgroundColor: "#519bcc",
                }}
                data-color="#519bcc"
              />
              <div
                style={{
                  height: "100%",
                  display: "inline-flex",
                  width: `${(progress[5] / totalSteps) * 100}%`,
                  justifyContent: "center",
                  verticalAlign: "top",
                  backgroundColor: "lavender",
                }}
                data-color="#9c9cda"
              />
              <div
                style={{
                  height: "100%",
                  display: "inline-flex",
                  width: `${(progress[0] / totalSteps) * 100}%`,
                  justifyContent: "center",
                  verticalAlign: "top",
                  backgroundColor: "lightgray",
                }}
                data-color="#afafaf"
              />
            </div>
            <div
              style={{
                minWidth: "350px",
                display: "flex",
                textAlign: "center",
                justifyContent: "space-between",
                fontSize: "10pt",
                lineHeight: "20px",
                marginTop: "12px",
              }}
            >
              <div style={{ fontWeight: "600" }}>
                <span>{progress[1]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Pass</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{progress[2]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Fail</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{progress[3]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Blocked</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{progress[4]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Retest</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{progress[5]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>N/A</span>
              </div>
              <div style={{ fontWeight: "600" }}>
                <span>{progress[0]}</span>
                <br />
                <span style={{ color: "#b3b3b3" }}>Untested</span>
              </div>
              <div style={{ lineHeight: "33px" }}>
                <span style={{ fontWeight: "600" }}>
                  {progress[1] +
                    progress[2] +
                    progress[3] +
                    progress[4] +
                    progress[5]}{" "}
                  / {totalSteps}
                </span>
              </div>
            </div>
          </div>
          <button
            tabIndex={-1}
            aria-hidden="true"
            onClick={this.open}
            style={{
              position: "relative",
              display: "flex",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              backgroundColor: "#e6e6fab0",
              border: this.state.open
                ? "solid 1px #519acc"
                : "solid 1px lightgray",
            }}
          >
            <img
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Multiple%20Devices.svg"
              style={{ height: "44px" }}
            />
            <span className="counter landing-pass-device-counter">
              {this.props.testPass.device_percents.length}
            </span>
          </button>
        </div>
        {this.state.open && [
          <div
            style={{
              width: "calc(100% + 35px",
              borderTop: "solid 1px lightgray",
              marginLeft: "auto",
              marginTop: "20px",
              position: "relative",
              left: "-17px",
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px",
            }}
            key={`test-pass-${this.props.testPass.id}-filter`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                position: "absolute",
                bottom: "-9px",
                left: "35px",
              }}
            >
              <span
                style={{
                  display: "block",
                  marginRight: "10px",
                  fontWeight: "600",
                }}
              >
                By Device Breakdown
              </span>
              <div
                style={{
                  backgroundColor: "lightgray",
                  height: "1px",
                  width: "calc(100% - 231px)",
                }}
              />
            </div>
            <BasicSelect
              options={[
                {
                  label: "By Assignee",
                  value: "assignee",
                  icons: [
                    false,
                    false,
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                  ],
                },
                {
                  label: "By Percent",
                  value: "percent",
                  icons: [
                    false,
                    false,
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                  ],
                },
                {
                  label: "By Platform",
                  value: "platform",
                  icons: [
                    false,
                    false,
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                  ],
                },
                {
                  label: "By Type",
                  value: "type",
                  icons: [
                    false,
                    false,
                    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
                  ],
                },
              ]}
              onChange={this.setSort}
              selectedOption={this.state.sortBy}
              selectedIconStyle={{
                height: "35px",
                width: "35px",
                verticalAlign: "middle",
                marginRight: "5px",
              }}
              listItemStyle={{
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingLeft: "18px",
              }}
              selectedItemStyle={{
                display: "flex",
                justifyContent: "space-around",
              }}
              // arrowStyle={{position:"relative", lineHeight:"10px", height:"10px", top:"12px", right:"0px"}}              selectedTextStyle={{display:"inline-block", minWidth:"96px", textAlign:"center", color:"#242424"}}
              listItemImgStyle={{ paddingRight: "10px", width: "40px" }}
              id="1"
              width="168px"
              maxHeight="155px"
            />
          </div>,
          this.sortDevices(this.props.testPass.device_percents).map(
            (device, index) => {
              return (
                <TestPassLandingDevice
                  index={index}
                  testDevice={device}
                  key={`device-${device.id}`}
                />
              );
            }
          ),
        ]}
      </div>
    );
  }
}
export default TestPassLandingItem;
