const baseColor = "#519acc";
const darkGrey = "#6e6e6e";
const lightGrey = "#f0f0f0";
const medLightG = "#d1d1d1";
const TaskSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: state.isFocused
      ? `1px 1px 1px 0px transparent`
      : `1px 1px 3px 0px ${lightGrey}`,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: state.menuIsOpen
      ? baseColor
      : state.isFocused
      ? baseColor
      : "lightgray",
    minHeight: "42px",
    maxHeight: "42px",
    "&:hover": {
      color: `${baseColor}`,
      borderColor: `${baseColor}`,
    },
  }),
  container: (styles) => ({
    ...styles,
    width: "250px",
    fontFamily: 'Arial',
    fontSize: '14px',
    margin: '5px 0'
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: "100%",
    marginTop: "0",
    backgroundColor: "transparent",
  }),
  input: (styles) => {
    return {
    ...styles,
      boxShadow: "none !important",
      margin: "0",
      padding: "0",
      color: "transparent"
    }
  },
  menu: (styles, state) => ({
    ...styles,
    width: "300px",
    height: "300px",
    top: "35px",
    borderRadius: "5px",
    boxShadow: "0 0 9px 2px hsla(0,0%,0%,0.3), 0 4px 11px hsla(0,0%,0%,0.1)",
    zIndex: 2,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    marginLeft: state.hasValue ? "30px" : "",
    display: "none"
  }),
  menuList: (styles) => ({ ...styles, borderRadius: "5px" }),
  valueContainer: (styles, state) => ({
    ...styles,
    paddingTop: state.hasValue ? "10px" : "",
    order: 2,
    top: state.hasValue ? "30px" : "",
    left: state.hasValue ? "-10px" : "",
    position: state.hasValue ? "absolute" : "",
    width: "450px",
    display: "flex",
    marginTop: "15px"
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    color: darkGrey,
    maxHeight: "42px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: `${baseColor}`,
    cursor: "pointer",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      fontFamily: "Arial",
      fontWeight: "400",
      textAlign: "center",
      borderRadius: "3px",
      flex: "45%",
      maxWidth: "45%",
      marginRight: "10px",
      marginBottom: "5px",
      height: "40px",
      alignItems: "center",
      boxShadow: "0 1px 1px 1px #d1d1d1",
      justifyContent: "space-between",
      backgroundColor: "white",
    };
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      color: "#242424",
      padding: "4px",
      paddingLeft: "12px",
      fontSize: "100%",
      height: "34px"
    };
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      color: "lightgrey",
      padding: "0",
      marginRight: "5px",
      cursor: "pointer"
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "white"
        : isSelected
        ? baseColor
        : isFocused
        ? baseColor
        : "white",
      fontFamily: 'Arial',
      color: isFocused ? "white" : isSelected ? "white" : "#242424",
      cursor: isDisabled ? "not-allowed" : "default",
      paddingLeft: "25px",
    };
  },
};

export default TaskSelectStyles;
