import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import ApiClient from "@utils/ApiClient";
import SingleSelectStyles from "@components/common/select_styles";
import Project from "./project";
import EditProjectModal from "./edit_project_modal";
import ErrorBoundary from "../common/error_boundary";
import ReactModalV2 from "../modals/react_modal_v2";
import ProjectStats from "./project_stats";
import TopTestersContainer from "./top_testers_container";
import { dropdownIndicator, customControl } from "../common/select_utils";
import DebugPanel from "../modals/debug_panel";

const modals = {
  "debug-panel": DebugPanel,
};

const NEW_PROJECT_MODAL_CLASS = "admin-projects-new-project-modal-portal";

const AdminProjects = (props) => {
  const { loadingHandler, current_user: currentUser, teams, users } = props;

  const [projects, setProjects] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [active, setActive] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [sortAsc, setSortAsc] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [currentModal, setCurrentModal] = useState("");

  const closeProjectModal = () => {
    // eslint-disable-next-line no-undef
    const NewProjectModalAnchor = document.querySelector(
      `.${NEW_PROJECT_MODAL_CLASS}`
    );
    const NewProjectModalPortal = document.querySelector("#modalContainer");
    if (NewProjectModalPortal) {
      // eslint-disable-next-line no-undef
      M.Modal.getInstance(NewProjectModalAnchor).close();
      ReactDOM.unmountComponentAtNode(NewProjectModalPortal);
    } else {
      console.error("NewProjectModal component not found");
    }
  };

  const updateSearchString = (event) => {
    setSearchString(event.target.value);
  };

  const clearInput = () => {
    const elem = document.getElementById("bug-search-input");
    if (elem) {
      elem.focus();
      elem.value = "";
    }
    setSearchString("");
  };

  const setModal = (bool, page, data) => {
    setModalOpen(bool);
    setCurrentModal(modals[page]);
    setModalData(data);
  };

  const openDebugModal = () => {
    setModal(true, "debug-panel", {
      projects: projects.map((p) => ({ label: p.label, value: p.value })),
      customClass: "debug-panel",
      backdropClickCheck: {
        title: "Close Debug",
        text: "Are you sure that you are done debugging?",
      },
    });
  };

  const handleDeleteApplication = (appID) => {
    setProjects(projects.filter((p) => p.id !== appID));
  };

  const projectHandler = useCallback(
    (project) => {
      setProjects(projects.map((p) => (p.id === project.id ? project : p)));
    },
    [projects]
  );

  const createHandler = useCallback(
    (project) => {
      setProjects(
        [...projects, project].sort((a, b) => a.name.localeCompare(b.name))
      );
    },
    [projects]
  );

  const handleSelectedType = (e) => {
    setSelectedType(e);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  const setSortProjects = (e) => {
    if (sortBy === e.target.id) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true);
      setSortBy(e.target.id);
    }
  };

  const openProjectModal = useCallback(async () => {
    loadingHandler();
    const api = new ApiClient();
    const resp = await api.get(`/admin_panel/projects/${projects[0].id}.json`);

    ReactDOM.render(
      <EditProjectModal
        currentUser={currentUser}
        handler={projectHandler}
        createHandler={createHandler}
        loadingHandler={loadingHandler}
        project={{
          name: "",
          identifier: "",
          users: [],
          members: [],
          a11y_active: false,
          automation: false,
          media: false,
          teams: [],
        }}
        teams={resp.data.teams}
        action="create"
        users={resp.data.users}
      />,
      document.getElementById("modalContainer")
    );

    loadingHandler();
  }, [loadingHandler, createHandler, currentUser, projectHandler, projects]);

  const checkProjectOpenOnMount = useCallback(() => {
    // check for query param value "new_project=create" in search string. if present, pop open the new project modal
    // ordinarily we would do this immediately in a useEffect, but we first need to wait for the project list to
    // finish fetching, so this will be called in a useEffect that waits for that state boolean to change to true.
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("new_project") === "create") {
      openProjectModal();
    }
  }, [openProjectModal]);

  const handleAddProjectClick = () => {
    if (modalOpen) {
      closeProjectModal();
    } else {
      openProjectModal();
    }
  };

  const getProjects = () => {
    // fetch projects list on initial page load, will be called in the on mount useEffect below.
    const api = new ApiClient();
    loadingHandler();
    api
      .get("/admin_panel/projects")
      .then((res) => {
        loadingHandler();
        setProjects(res.data.projects);
        setLoaded(true);
      })
      .catch((err) => console.error(err));
  };

  const tableProjects = useMemo(() => {
    // filters and sorts:
    // (1) filter by project.active === active state boolean (active = true => active projects, active = false => retired)
    // (2) filter by project.type === selectedType if an option in Filter By Project Type is selected
    // (3) if search string present, filter by project name/owner name/project identifier by search string
    // (4) sort

    const filterByActive = (list) => {
      return list.filter((p) => p.active === active);
    };

    const filterBySelectedType = (list) => {
      if (selectedType !== null) {
        return list.filter(
          (p) => p.type.toLowerCase() === selectedType.value.toLowerCase()
        );
      }
      return list;
    };

    const filterBySearchString = (list) => {
      // (1) if search string is undefined or under min character length, return the full array.
      // (2) else, for each element in list, return that element if one of the project's name,
      // identifier, or owner's name contains the search string as a substring.
      if (!searchString || searchString.length < 2) {
        return list;
      }

      return list.filter((p) => {
        const projectSearchFieldsConcatenated = [
          p.name,
          p.owner?.name,
          p.identifer,
        ]
          .filter(Boolean)
          .join("")
          .toLowerCase();
        return projectSearchFieldsConcatenated.includes(
          searchString.toLowerCase()
        );
      });
    };

    const PROJECT_SORTS = {
      created_at: (a, b) => {
        const date1 = new Date(a.created_at);
        const date2 = new Date(b.created_at);
        if (sortAsc) {
          return date1 - date2;
        }
        return date2 - date1;
      },
      owner: (a, b) => {
        const ownerA = a.owner?.name || "";
        const ownerB = b.owner?.name || "";
        if (sortAsc) {
          return ownerA.localeCompare(ownerB);
        }
        return ownerB.localeCompare(ownerA);
      },
      default: (a, b) => {
        if (!sortBy || sortBy === "") return 0;
        if (!a[sortBy] || !b[sortBy]) return 0;
        if (sortAsc) {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      },
    };

    const sortProjects = (list) => {
      if (!sortBy) {
        return list;
      }
      return list.sort(PROJECT_SORTS[sortBy] || PROJECT_SORTS.default);
    };

    const applyAllFilters = (list) => {
      const activeList = filterByActive(list);
      const typeList = filterBySelectedType(activeList);
      const searchList = filterBySearchString(typeList);
      const sortedList = sortProjects(searchList);
      return sortedList;
    };

    return applyAllFilters(projects);
  }, [active, projects, searchString, selectedType, sortBy, sortAsc]);

  const delay = useMemo(() => {
    return tableProjects.length > 10 ? 666 / tableProjects.length : 100;
  }, [tableProjects]);

  const sortArrow = useMemo(() => {
    return sortAsc ? "keyboard_arrow_up" : "keyboard_arrow_down";
  }, [sortAsc]);

  useEffect(() => {
    const initializeProjectModal = () => {
      // init project modal
      const NEW_PROJECT_MODAL_OPTIONS = {
        startingTop: "4%",
        endingTop: "15%",
        dismissible: false,
        complete: () => {
          closeProjectModal();
        },
      };

      const NewProjectModalPortal = document.querySelector(
        `.${NEW_PROJECT_MODAL_CLASS}`
      );
      if (NewProjectModalPortal) {
        // eslint-disable-next-line no-undef
        M.Modal.init(NewProjectModalPortal, NEW_PROJECT_MODAL_OPTIONS);
      }
    };

    const initializeProjectsList = () => {
      getProjects();
    };

    document.querySelector("body").style.overflow = "auto";
    initializeProjectModal();
    initializeProjectsList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loaded) {
      checkProjectOpenOnMount();
    }
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div style={{ height: "313px", display: "flex", marginBottom: "20px" }}>
        <div style={{ width: "750px" }}>
          <ProjectStats />
        </div>
        <div style={{ flex: 1 }}>
          <TopTestersContainer />
        </div>
      </div>
      <div style={{ margin: "0 5px" }}>
        <div
          className="col s12"
          id="projects-main"
          style={{
            borderRadius: "0px",
            boxShadow: "0px 0px 3px 1px lightgrey",
            padding: "20px",
            minHeight: "100vh",
          }}
        >
          <ReactModalV2
            isShowing={modalOpen}
            page={currentModal}
            data={modalData}
            modalAction={setModal}
          />
          <p
            style={{
              fontSize: "21px",
              fontWeight: 600,
              margin: "10px 0 30px",
            }}
          >
            Projects
          </p>
          <div style={{ display: "flex", margin: "10px 0" }}>
            <button
              type="button"
              style={{ marginRight: "20px" }}
              className="common-button-submit"
              onClick={handleAddProjectClick}
            >
              Add
            </button>
            <div
              id="ticketSearch"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  left: "6px",
                  color: "#ababab",
                }}
                className="material-icons prefix"
              >
                search
              </i>
              <input
                id="bug-search-input"
                className="browser-default"
                type="text"
                onChange={updateSearchString}
                placeholder=" "
              />
              {searchString !== "" && (
                <button
                  type="button"
                  onClick={clearInput}
                  className="link-btn material-icons grey-text"
                >
                  close
                </button>
              )}
            </div>
            <button
              type="button"
              style={{ padding: "0 5px 0 0", margin: "0 10px 0 20px" }}
              disabled={active}
              className="arial bold elPt link-btn pointer admin-add-button"
              onClick={toggleActive}
            >
              Active
            </button>
            <button
              type="button"
              style={{ padding: "0 5px" }}
              disabled={!active}
              className="arial bold elPt link-btn pointer admin-add-button"
              onClick={toggleActive}
            >
              Retired
            </button>
            <div style={{ margin: "0 5px 0 auto" }}>
              <Select
                aria-label="User dropdown for eod report submissions"
                value={selectedType}
                styles={SingleSelectStyles}
                onChange={handleSelectedType}
                placeholder="Filter By Project Type"
                blurInputOnSelect
                isClearable
                isSearchable={false}
                name="user"
                closeMenuOnSelect
                options={[
                  { value: "basic", label: "Basic" },
                  { value: "a11y", label: "A11y" },
                  { value: "usability", label: "Usability" },
                ]}
                components={{ d: dropdownIndicator, Control: customControl }}
                classNamePrefix="selectbox-menu"
              />
            </div>
          </div>
          <div
            style={{
              flex: ".5",
              display: "flex",
              flexDirection: "row",
            }}
          />
          <table className="striped highlight">
            <thead>
              <tr
                in="true"
                key="header"
                style={{
                  fontWeight: 600,
                  backgroundColor: "#519acc",
                  lineHeight: "25px",
                }}
              >
                <th
                  style={{ position: "relative" }}
                  className={`heading-hover ${
                    sortBy === "name" ? "heading-selected" : ""
                  }`}
                  id="name"
                  onClick={setSortProjects}
                >
                  Project{" "}
                  <span style={{ whiteSpace: "nowrap", pointerEvents: "none" }}>
                    Name{" "}
                    {sortBy === "name" && (
                      <i className="material-icons sort-arrows">{sortArrow}</i>
                    )}
                  </span>
                </th>
                <th
                  style={{
                    position: "relative",
                    whiteSpace: "nowrap",
                    minWidth: "90px",
                  }}
                  className={`heading-hover ${
                    sortBy === "identifier" ? "heading-selected" : ""
                  }`}
                  id="identifier"
                  onClick={setSortProjects}
                >
                  Identifier
                  {sortBy === "identifier" && (
                    <i className="material-icons sort-arrows">{sortArrow}</i>
                  )}
                </th>
                <th
                  style={{
                    position: "relative",
                    whiteSpace: "nowrap",
                    minWidth: "90px",
                  }}
                  className={`heading-hover ${
                    sortBy === "created_at" ? "heading-selected" : ""
                  }`}
                  id="created_at"
                  onClick={setSortProjects}
                >
                  Created{" "}
                  {sortBy === "created_at" && (
                    <i className="material-icons sort-arrows">{sortArrow}</i>
                  )}
                </th>
                <th
                  style={{
                    position: "relative",
                    whiteSpace: "nowrap",
                    minWidth: "180px",
                    textAlign: "center",
                  }}
                  className={`heading-hover ${
                    sortBy === "type" ? "heading-selected" : ""
                  }`}
                  id="type"
                  onClick={setSortProjects}
                >
                  Project Type
                  {sortBy === "type" && (
                    <i className="material-icons sort-arrows">{sortArrow}</i>
                  )}
                </th>
                <th
                  style={{ position: "relative", minWidth: "125px" }}
                  className={`heading-hover ${
                    sortBy === "owner" ? "heading-selected" : ""
                  }`}
                  id="owner"
                  onClick={setSortProjects}
                >
                  Project{" "}
                  <span style={{ whiteSpace: "nowrap", pointerEvents: "none" }}>
                    Owner{" "}
                    {sortBy === "owner" && (
                      <i className="material-icons sort-arrows">{sortArrow}</i>
                    )}
                  </span>
                </th>
                <th className="filler-column-projects" />
                <th
                  style={{ position: "relative", minWidth: "70px" }}
                  id="status"
                >
                  Status
                </th>
                <th className="filler-column-projects" />
                <th />
              </tr>
            </thead>
            <tbody>
              {tableProjects.map((project, index) => (
                <ErrorBoundary key={project.id}>
                  <Project
                    i={index}
                    delay={delay}
                    teams={teams}
                    currentUser={currentUser}
                    project={project}
                    loadingHandler={loadingHandler}
                    handleDeleteApplication={handleDeleteApplication}
                    editHandler={projectHandler}
                    users={users}
                  />
                </ErrorBoundary>
              ))}
            </tbody>
          </table>
          <div className="col s12">
            {projects.length < 1 && loaded !== false && (
              <div className="white left" id="noProjectsContainer">
                <div className="no-box-shadow card col s12">
                  <div className="card-content">
                    <p>
                      No Projects currently associated with this organization
                    </p>
                    <p>Add a New Project</p>
                  </div>
                </div>
              </div>
            )}
            {projects.length === 0 && searchString !== "" && (
              <div className="white center" id="noProjectsContainer">
                <div className="no-box-shadow card col s6 offset-s3">
                  <div className="card-content">
                    <p>No results for that query</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={NEW_PROJECT_MODAL_CLASS} />
          <div style={{ margin: "20px" }}>
            <button
              type="button"
              className="link-btn"
              style={{ cursor: "pointer" }}
              onClick={openDebugModal}
            >
              Open Debug Panel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProjects;
