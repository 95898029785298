var Sleep = (time) =>{
  // var timer = (t, s, d, r)=>{
  //   if(t - s > d){
  //     r("done")
  //   } else {
  //     requestAnimationFrame((newT)=>{ timer(newT, s, d, r)})
  //   }
  // }
  // return new Promise((r)=>{requestAnimationFrame((t)=>{timer(t, t, time, r)})})
  return new Promise((r)=>setTimeout(()=>{r("done")},time))
}
export default Sleep