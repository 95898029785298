import AddDeviceIcon from "./add_device_icon";
import ArrowIcon from "./arrow_icon";
import BookIcon from "./book_icon";
import BugDraftIcon from "./bug_draft_icon";
import BugsIcon from "./bugs_icon";
import BuildIcon from "./build_icon";
import BulletpointsIcon from "./bulletpoints_icon";
import CalendarIcon from "./calendar_icon";
import CancelIcon from "./cancel_icon";
import CheckboxSquircleIcon from "./checkbox_squircle_icon";
import CheckmarkIcon from "./checkmark_icon";
import CommunityIcon from "./community_icon";
import DeleteIcon from "./delete_icon";
import DownloadIcon from "./download_icon";
import EditIcon from "./edit_icon";
import EditNotepadIcon from "./edit_notepad_icon";
import EODReportIcon from "./eod_report_icon";
import FeedbackIcon from "./feedback_icon";
import FolderIcon from "./folder_icon";
import GridLibIcon from "./grid_lib_icon";
import HelpIcon from "./help_icon";
import ImageEmptyIcon from "./image_empty_icon";
import ImpersonateIcon from "./impersonate_icon";
import LibraryFilledIcon from "./library_filled_icon";
import LibraryIcon from "./library_icon";
import LikeIcon from "./like_icon";
import LinkIcon from "./link_icon";
import MoveIcon from "./move_icon";
import PencilCircleIcon from "./pencil_circle_icon";
import PlusIcon from "./plus_icon";
import PointTextBoxIcon from "./point_text_box_icon";
import QRCodeIcon from "./qr_code_icon";
import RedoIcon from "./redo_icon";
import RotateIcon from "./rotate_icon";
import RoundDropdownIcon from "./round_dropdown";
import ShapeIcon from "./shape_icon";
import TestPassIcon from "./test_pass_icon";
import TextBoxIcon from "./text_box_icon";
import TrashIcon from "./trash_icon";
import TrashSimpleIcon from "./trash_simple_icon";
import TwoCoolGuysIcon from "./two_cool_guys_icon";
import UndoIcon from "./undo_icon";
import UserIcon from "./user_icon";

export default {
  AddDeviceIcon,
  ArrowIcon,
  BookIcon,
  BugDraftIcon,
  BugsIcon,
  BuildIcon,
  BulletpointsIcon,
  CalendarIcon,
  CancelIcon,
  CheckboxSquircleIcon,
  CheckmarkIcon,
  CommunityIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EditNotepadIcon,
  EODReportIcon,
  FeedbackIcon,
  FolderIcon,
  GridLibIcon,
  HelpIcon,
  ImageEmptyIcon,
  ImpersonateIcon,
  LibraryFilledIcon,
  LibraryIcon,
  LikeIcon,
  LinkIcon,
  MoveIcon,
  PencilCircleIcon,
  PlusIcon,
  PointTextBoxIcon,
  QRCodeIcon,
  RedoIcon,
  RotateIcon,
  RoundDropdownIcon,
  ShapeIcon,
  TestPassIcon,
  TextBoxIcon,
  TrashIcon,
  TrashSimpleIcon,
  TwoCoolGuysIcon,
  UndoIcon,
  UserIcon,
};
