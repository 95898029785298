import React from "react";
import ReactDOM from "react-dom";
import InviteUserForm from "./invite_user_form";
import EditUserForm from "./edit_user_form";

class UserModal extends React.Component {
  constructor(props) {
    super(props);
    this.openEditUserModal = this.openEditUserModal.bind(this);
    this.closeEditUserModal = this.closeEditUserModal.bind(this);
    this.EditUserModal = React.createRef();
  }

  componentDidMount() {
    this.openEditUserModal();
  }

  openEditUserModal() {
    M.Modal.init(this.EditUserModal, { dismissible: false, endingTop: "7%" });
    M.Modal.getInstance(this.EditUserModal).open();
    $("body").css("overflow", "hidden");
  }

  closeEditUserModal() {
    M.Modal.getInstance(this.EditUserModal).close();
    ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer"));
    $("body").css("overflow", "scroll");
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.EditUserModal = node;
        }}
        id="userModal"
        style={{ ...(this.props.action == "invite" ? { width: "625px" } : {}) }}
        className="modal adminModal new-scrollbar"
      >
        <div className="modal-content">
          <div id="userForm">
            <div>
              <button
                type="button"
                style={{ position: "absolute", right: "19px", top: "21px" }}
                className="icon-close-btn"
                onClick={this.closeEditUserModal}
              />
              {this.props.action == "invite" && (
                <InviteUserForm
                  project={this.props.project}
                  openModal={this.openEditUserModal}
                  closeModal={this.closeEditUserModal}
                  users={this.props.users}
                  projects={this.props.projects}
                  userHandler={this.props.createHandler}
                />
              )}
              {this.props.action == "edit" && (
                <EditUserForm
                  users={this.props.users}
                  // openModal={this.openEditUserModal}
                  closeModal={this.closeEditUserModal}
                  handleEditUser={this.props.handleEditUser}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserModal;
