import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { string, shape, arrayOf, number, oneOfType } from "prop-types";
import styled from "styled-components";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import ApiClient from "../../utils/ApiClient";
import { userTypes, projectTypes } from "../../types";
import {
  wikiResourceTypes,
  wikiTopicTypes,
  wikiFeaturedTopicTypes,
} from "../../types/wiki";
import BookIcon from "../icons/book_icon";
import AssessmentsSectionToggle from "./assessments/AssessmentsSectionToggle";
import ReactModalV2 from "../modals/react_modal_v2";
import WikiDirectory from "./wiki_directory";
import WikiResource from "./wiki_resource";
import FeaturedTopic from "../modals/featured_topic";
import TwoFactorModal from "../modals/two_factor_modal";
import WikiContext from "./WikiContext";

const modals = {
  "featured-topic": FeaturedTopic,
  "two-fa": TwoFactorModal,
};

const WikiContainer = (props) => {
  const {
    current_user,
    project,
    team_options: teamOptions,
    wiki_resources,
    current_resource,
    featured_topics: featuredTopics,
  } = props;
  const [modalObject, setModalObject] = useState({
    modalOpen: false,
    currentModal: "",
    modalData: {},
  });
  const [direction, setDirection] = useState("node-forwards");
  const [currentResource, setCurrentResource] = useState(
    current_resource || null
  );
  const [wikiResources, setWikiResources] = useState(wiki_resources);

  const ariaCustomStatusRef = useRef(null);

  const setModal = (bool, page, data) => {
    setModalObject({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  const backToLandingPage = useCallback((updated) => {
    const api = new ApiClient();
    if (updated) {
      api
        .get("/refresh_wiki_landing_page")
        .then((res) => {
          setWikiResources(res.data);
          setCurrentResource(null);
          window.history.pushState("page2", "Test Platform", "/wiki/");
          setTimeout(() => {
            setDirection("node-forwards");
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          setCurrentResource(null);
          window.history.pushState("page2", "Test Platform", "/wiki/");
          setTimeout(() => {
            setDirection("node-forwards");
          }, 1000);
        });
    } else {
      setCurrentResource(null);
      window.history.pushState("page2", "Test Platform", "/wiki/");
      setTimeout(() => {
        setDirection("node-forwards");
      }, 1000);
    }
  }, []);

  const handleDragDropUpdate = (resource) => {
    setCurrentResource(resource);
  };

  useEffect(() => {
    setTimeout(() => {
      const url = new URL(window.location.href);
      const topic = url.searchParams.get("topic");
      if (topic) {
        const elem = document.getElementById(`topic-${topic}`);
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 500);
  }, []);

  const contextValue = useMemo(() => {
    return {
      current_user,
      project,
      teamOptions,
      backToLandingPage,
      currentResource,
      setCurrentResource,
      setDirection,
      wikiResources,
      setWikiResources,
    };
  }, [
    current_user,
    project,
    teamOptions,
    backToLandingPage,
    currentResource,
    setCurrentResource,
    setDirection,
    wikiResources,
    setWikiResources,
  ]);

  return (
    <WikiContext.Provider value={contextValue}>
      <div id="help-center-container">
        <div
          ref={ariaCustomStatusRef}
          style={{
            position: "fixed",
            top: "-200px",
            left: "-200px",
            pointerEvents: "none",
            height: "0px",
            width: "0px",
            overflow: "hidden",
          }}
          data-number="0"
          role="status"
          aria-live="assertive"
          id="aria-custom-status"
        />
        <ReactModalV2
          isShowing={modalObject.modalOpen}
          page={modalObject.currentModal}
          data={modalObject.modalData}
          modalAction={setModal}
        />
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            whiteSpace: "nowrap",
            marginLeft: "auto",
          }}
        >
          <WikiPageHeader>
            <TitleContainer>
              <IconContainer>
                <BookIcon
                  color="#519acc"
                  fill="#519acc"
                  height={23}
                  width={30}
                />
              </IconContainer>
              <WikiPageTitle>Wiki</WikiPageTitle>
            </TitleContainer>
            {!currentResource && current_user.role === "admin" && (
              <AssessmentsSectionToggle />
            )}
          </WikiPageHeader>
          <SwitchTransition>
            <CSSTransition
              key={!currentResource}
              timeout={{ appear: 300, enter: 390, exit: 390 }}
              classNames={direction}
            >
              {!currentResource ? (
                <WikiDirectory
                  wikiResources={wikiResources}
                  featuredTopics={featuredTopics}
                  setCurrentResource={setCurrentResource}
                  setDirection={setDirection}
                  user={current_user}
                />
              ) : (
                <WikiResource
                  // sideBarWidth={sideBarWidth}
                  currentUser={current_user}
                  teamOptions={teamOptions}
                  wikiResources={wikiResources}
                  permanentFeaturedTopicsCount={
                    featuredTopics.filter((f) => f.permanent_feature).length
                  }
                  handleDragDropUpdate={handleDragDropUpdate}
                  setModal={setModal}
                  backToLandingPage={backToLandingPage}
                  currentResource={currentResource}
                />
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </WikiContext.Provider>
  );
};

const WikiPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 26px;
  border-bottom: 1px solid #d3d3d3;
  min-height: 65px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const WikiPageTitle = styled.h1`
  margin: 0;
  margin-bottom: 0 !important;
  margin-left: 12px;
  font-family: "Manrope";
  font-weight: 800;
  font-size: 21px;
  line-height: 29px;
  color: #000000;
`;

const WikiProps = {
  current_user: userTypes,
  featured_topics: arrayOf(wikiFeaturedTopicTypes),
  project: projectTypes,
  team_options: arrayOf(
    shape({ label: string, value: oneOfType([number, string]) })
  ),
  wiki_resources: arrayOf(wikiResourceTypes),
  current_resource: shape({
    wiki_resource: wikiResourceTypes,
    wiki_topics: arrayOf(wikiTopicTypes),
  }),
};

WikiContainer.propTypes = WikiProps;

export default WikiContainer;

export { WikiContext, WikiProps };
