import React from "react";

class LibraryIcon extends React.Component {
  render() {
    return (
      <svg
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        stroke={this.props.stroke}
        style={this.props.svgStyles}
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 64 64"
      >
        <path d="M45.66,34.23l-5.08-3a1,1,0,0,0-1.13,0,1.18,1.18,0,0,0-.55.89v6.09A1.07,1.07,0,0,0,40,39.26a1.14,1.14,0,0,0,.58-.19l5.12-3a1.16,1.16,0,0,0,.49-.9A1.06,1.06,0,0,0,45.66,34.23Z"/>
        <path d="M60.43,16.57H23.18a1.34,1.34,0,0,0-1.35,1.35v8H3.64a1.35,1.35,0,0,0-1.35,1.35V49.56a1.35,1.35,0,0,0,1.35,1.35H31a1.35,1.35,0,0,0,1.34-1.35V47.49h28a1.35,1.35,0,0,0,1.35-1.36V17.92A1.3,1.3,0,0,0,60.43,16.57Zm-35.91,2.7H59.08v2.91H24.52ZM59.08,44.86H32.32V27.25A1.35,1.35,0,0,0,31,25.9H24.52V24.81H59.08ZM29.7,48.29H5v-.47l5-5,2.45,2.45a1.39,1.39,0,0,0,1.87,0L23,36.63l6.74,6.75Zm0-8.72-5.77-5.78a1.35,1.35,0,0,0-1.87,0l-8.65,8.66L11,40a1.35,1.35,0,0,0-1.87,0l-4,4V28.61H29.7Z"/>
        <path d="M12,32.2a2.27,2.27,0,0,0,0,4.54,2.27,2.27,0,1,0,0-4.54Z"/>
      </svg>
    );
  }
}

LibraryIcon.defaultProps = {
  height: "25px",
  width: "25px",
  svgStyles: {},
  color: "#444344"
}

export default LibraryIcon