import React, { useState, useEffect } from "react";
import styled from "styled-components";

// For login page
const ShowPasswordBtn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");

  useEffect(() => {
    showPassword ? setInputType("text") : setInputType("password");
    $("#user_password").attr("type", inputType);
  }, [showPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ShowPasswordToggleStyle
      src={`https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons%2FIcon_${
        showPassword ? "Show" : "Hide"
      }_Password.svg`}
      onClick={() => setShowPassword((showPassword) => !showPassword)}
      ariaHidden="false"
      id="show-pass-btn"
    />
  );
};

export default ShowPasswordBtn;

export const ShowPasswordToggleStyle = styled.img`
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 10px;
  top: 46px;
  cursor: pointer;
`;
