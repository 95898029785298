import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AssessmentsSectionToggle from "./AssessmentsSectionToggle";
import AssessmentsTable from "./AssessmentsTable";
import { userTypes, projectTypes, wikiTypes, teamTypes } from "../../../types";
import BookIcon from "../../icons/book_icon";

const WikiAssessmentsContext = React.createContext({});

const WikiAssessmentsContainer = ({
  current_user,
  project,
  wiki_assessments,
  wiki_resources,
  teams,
}) => {
  const [assessments, setAssessments] = useState(wiki_assessments || []);

  const updateSingleAssessment = useCallback(
    (assessment) => {
      const nextAssessments = [...assessments];
      const index = nextAssessments.findIndex((a) => a.id === assessment.id);
      nextAssessments[index] = assessment;
      setAssessments(nextAssessments);
    },
    [assessments]
  );

  const AssessmentsContextValue = useMemo(() => {
    return {
      current_user,
      project,
      wiki_assessments,
      wiki_resources,
      teams,
      assessments,
      setAssessments,
      updateSingleAssessment,
    };
  }, [
    current_user,
    project,
    wiki_assessments,
    wiki_resources,
    teams,
    assessments,
    setAssessments,
    updateSingleAssessment,
  ]);

  return (
    <WikiAssessmentsContext.Provider value={AssessmentsContextValue}>
      <div id="help-center-container">
        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            whiteSpace: "nowrap",
            marginLeft: "auto",
          }}
        >
          <AssessmentsPageHeader>
            <TitleContainer>
              <IconContainer>
                <BookIcon
                  color="#519acc"
                  fill="#519acc"
                  height={23}
                  width={30}
                />
              </IconContainer>
              <WikiPageTitle>Wiki</WikiPageTitle>
            </TitleContainer>
            <AssessmentsSectionToggle />
          </AssessmentsPageHeader>
          <AssessmentsTable assessments={assessments} />
        </div>
      </div>
    </WikiAssessmentsContext.Provider>
  );
};

const AssessmentsPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 26px;
  border-bottom: 1px solid #d3d3d3;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const WikiPageTitle = styled.h1`
  margin: 0;
  margin-bottom: 0 !important;
  margin-left: 12px;
  font-family: "Manrope";
  font-weight: 800;
  font-size: 21px;
  line-height: 29px;
  color: #000000;
`;

WikiAssessmentsContainer.propTypes = {
  current_user: userTypes.isRequired,
  project: projectTypes.isRequired,
  wiki_assessments: PropTypes.arrayOf(wikiTypes.wikiAssessmentTypes).isRequired,
  wiki_resources: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })
  ),
  teams: PropTypes.arrayOf(teamTypes).isRequired,
};

export default WikiAssessmentsContainer;

export { WikiAssessmentsContext };
