// eslint-disable-next-line import/prefer-default-export
export const viteConstructorRequireContext = (requireContext) => {
  const fromRequireContext = (reqCtx) => {
    const fromClassName = (className) => {
      const parts = className.split(".");
      const filename = parts.shift();
      const keys = parts;
      const componentPath = Object.keys(reqCtx).find(
        (path) => path.search(filename) > 0
      );
      let component = reqCtx[componentPath];
      keys.forEach((k) => {
        component = component[k];
      });
      component = component.default;
      return component;
    };
    return fromClassName;
  };

  const fromCtx = fromRequireContext(requireContext);
  const getComponent = (className) => {
    let component;
    try {
      component = fromCtx(className);
    } catch (firstErr) {
      console.error(firstErr);
    }
    return component;
  };
  return getComponent;
};
