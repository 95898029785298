import React from "react";
import moment from "moment";
import Swal from "sweetalert2";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import { CalendarIcon } from "@components/common/icons";
import RoundDropdown from "@components/common/round_dropdown";
import LoginIcon from "@icons/profile/login_icon";
import NotificationsIcon from "@icons/profile/notifications_icon";
import AboutMeIcon from "@icons/profile/about_me_icon";
import AvatarUploader from "./components/AvatarUploader";

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singlePickerFocus: false,
      startDate: moment(this.props.startDate),
    };
    this.nameRef = React.createRef();
    this.locationRef = React.createRef();
    this.titleRef = React.createRef();
    this.pronounsRef = React.createRef();
    this.slackIdRef = React.createRef();
    this.emailRef = React.createRef();
  }

  onSingleFocusChange = ({ focused }) => {
    this.setState({ singlePickerFocus: focused });
  };

  handleSingleDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleUpdateMembership = (e) => {
    const checked = !(e.target.dataset.checked == "true");
    const membershipId = parseInt(e.target.dataset.membershipId);
    this.props.handleUpdateMembership(checked, membershipId);
  };

  resetUserPassword = () => {
    const id = this.props.userId;
    Swal.fire({
      title: "Reset Password",
      text: "This will send an email with a form to change your Password. Are you sure you want to change your Password?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: `/users/${id}/password_reset.json`,
          dataType: "json",
          type: "GET",
          cache: false,
          beforeSend(xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          success() {
            Swal.fire({
              title: "Reset Email Sent",
              text: "Check your inbox for Password Reset email",
              confirmButtonText: "Ok",
            });
          },
          error(data) {
            Swal.fire({
              title: "User Error",
              text: "There was a problem with that request",
              customClass: "swal2-error-modal",
            });
          },
        }).done(() => {
          Swal("Reset", "Password reset email sent.", "success");
        });
      }
    });
  };

  handleSubmit = (e) => {
    this.setState({ loading: true });
    const employee =
      this.props.role !== "trialist" && this.props.role !== "client";
    const name = this.nameRef.current.value;
    const startDate = employee ? this.state.startDate : "";
    const location = this.locationRef.current.value;
    const slackId = employee ? this.slackIdRef.current.value : "";
    const pronouns = this.pronounsRef.current.value;
    const title = employee ? this.titleRef.current.value : "";
    const formData = new FormData();
    let updated = false;
    const stateData = {};

    if (!name) {
      // eslint-disable-next-line no-undef
      M.toast({ html: "Employee Name is required", classes: "red" });
      return null;
    }

    if (!location && employee) {
      // eslint-disable-next-line no-undef
      M.toast({ html: "Location is required", classes: "red" });
      return null;
    }

    if (name !== this.props.name) {
      formData.append("user[name]", name);
      stateData.name = name;
      updated = true;
    }

    if (pronouns !== this.props.pronouns) {
      formData.append("user[pronouns]", pronouns);
      stateData.pronouns = pronouns;
      updated = true;
    }

    if (
      employee &&
      moment(startDate).format("YYYY-MM-DD") !==
        moment(this.props.startDate).format("YYYY-MM-DD")
    ) {
      formData.append(
        "user[employee_started_at]",
        moment(startDate).format("YYYY-MM-DD")
      );
      stateData.startDate = moment(startDate).format("YYYY-MM-DD");
      updated = true;
    }

    if (location !== this.props.location) {
      formData.append("user[fact]", location);
      stateData.location = location;
      updated = true;
    }

    if (employee && slackId !== this.props.slackId) {
      formData.append("user[slack_id]", slackId);
      stateData.slackId = slackId;
      updated = true;
    }

    if (employee && title !== this.props.title) {
      formData.append("user[title]", title);
      stateData.title = title;
      updated = true;
    }

    if (updated) {
      this.props.submitEditUser(formData, stateData, false);
    }
  };

  handleSubmitEmail = () => {
    const email = this.emailRef.current.value;
    if (!this.validateEmail(email)) {
      // eslint-disable-next-line no-undef
      M.toast({ html: "Invalid Email", classes: "red" });
      return null;
    }

    if (email !== this.props.email) {
      const formData = new FormData();
      formData.append("user[email]", email);
      this.props.submitEditUser(formData, false, true);
    }
  };

  validateEmail = (email) => {
    return (
      email &&
      email.includes("@") &&
      email.includes(".") &&
      email.indexOf("@") !== 0 &&
      email.split("@").length === 2 &&
      email.split("@")[1].split(".").length === 2 &&
      email.split("@")[1].split(".")[0].length > 0 &&
      email.split("@")[1].split(".")[1].length > 1
    );
  };

  handleMuteAllProjects = () => {
    const formData = new FormData();
    formData.append(
      "user[mute_all_email_notifications]",
      !this.props.muteAllProjects
    );
    this.props.submitEditUser(
      formData,
      { muteAllProjects: !this.props.muteAllProjects },
      false
    );
  };

  render() {
    const {
      user,
      name,
      email,
      title,
      location,
      pronouns,
      slackId,
      otpEnabled,
      otpEmailEnabled,
      role,
    } = this.props;

    const employee = role !== "trialist" && role !== "client";
    return (
      <div id="account-details-container">
        <div>
          <div>
            <AboutMeIcon
              svgStyles={{ marginRight: "8px", height: "30px", width: "30px" }}
            />
            <h3 style={{ fontWeight: "600" }}>PERSONAL INFO</h3>
          </div>
          <div>
            <div className="common-input-pair" style={{ maxWidth: "314px" }}>
              <p>Profile Photo</p>
              <AvatarUploader user={user} />
            </div>
            <div className="common-input-pair" style={{ maxWidth: "314px" }}>
              <p>
                {employee ? "Employee " : ""}Name
                <span style={{ color: "#519acc" }}>*</span>
              </p>
              <input
                maxLength={50}
                style={{ maxWidth: "387px" }}
                ref={this.nameRef}
                defaultValue={name}
                placeholder="Enter your name"
              />
            </div>
            {employee && (
              <div className="common-input-pair">
                <p style={{ marginBottom: "0px" }}>
                  When did you start at PLUS QA?
                </p>
                <div id="start-date-container" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      left: "4px",
                      zIndex: 1,
                      top: "5px",
                    }}
                  >
                    <CalendarIcon
                      style={{ width: "30px", verticalAlign: "bottom" }}
                      className="icons"
                    />
                  </div>
                  <SingleDatePicker
                    date={this.state.startDate}
                    onDateChange={this.handleSingleDateChange}
                    focused={this.state.singlePickerFocus}
                    onFocusChange={this.onSingleFocusChange}
                    numberOfMonths={1}
                    placeholder="Select a date"
                    orientation="horizontal"
                    isOutsideRange={() => false}
                    daySize={30}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(90deg)" />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(-90deg)" />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition="after"
                    customInputIcon={
                      <i
                        aria-hidden="true"
                        style={{
                          transform: "rotateX(0deg)",
                          transition: "transform, .666s",
                          position: "relative",
                        }}
                        className="material-icons"
                      >
                        arrow_drop_down
                      </i>
                    }
                    id="single_date_picker"
                  />
                </div>
              </div>
            )}
            <div
              className="input-group-1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{ width: "50%", paddingRight: "10px" }}
                className="common-input-pair"
              >
                <p>
                  Location
                  {employee ? <span style={{ color: "#519acc" }}>*</span> : ""}
                </p>
                <input
                  ref={this.locationRef}
                  defaultValue={location}
                  placeholder="e.g. Portland, OR"
                  maxLength={50}
                />
              </div>
              {employee && (
                <div style={{ width: "50%" }} className="common-input-pair">
                  <p>Title</p>
                  <input
                    ref={this.titleRef}
                    defaultValue={title}
                    placeholder="Enter Job Title"
                    maxLength={50}
                  />
                </div>
              )}
            </div>
            <div
              className="input-group-2"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{ width: "50%", paddingRight: "10px" }}
                className="common-input-pair"
              >
                <p>Pronouns</p>
                <input
                  ref={this.pronounsRef}
                  defaultValue={pronouns}
                  placeholder="e.g. She / Her"
                  maxLength={50}
                />
              </div>
              {employee && (
                <div style={{ width: "50%" }} className="common-input-pair">
                  <p>Slack ID</p>
                  <input
                    ref={this.slackIdRef}
                    defaultValue={slackId}
                    placeholder="Enter Slack ID"
                    maxLength={50}
                  />
                </div>
              )}
            </div>

            <div>
              <button
                onClick={this.handleSubmit}
                style={{
                  width: "163px",
                  height: "42px",
                  display: "block",
                }}
                className="common-button-submit"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="login-details-container">
            <div>
              <LoginIcon
                svgStyles={{
                  marginRight: "8px",
                  height: "30px",
                  width: "30px",
                }}
              />
              <h3 style={{ fontWeight: "600" }}>LOGIN DETAILS</h3>
            </div>
            <div>
              <div className="common-input-pair">
                <p>
                  Email Address <span style={{ color: "#519acc" }}>*</span>
                </p>
                <div>
                  <input
                    ref={this.emailRef}
                    defaultValue={email}
                    style={{ width: "50%" }}
                    maxLength={50}
                  />
                  <button
                    onClick={this.handleSubmitEmail}
                    className="common-button-submit"
                  >
                    Update
                  </button>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <button
                  onClick={this.resetUserPassword}
                  style={{
                    width: "50%",
                    height: "42px",
                  }}
                  className="common-button-cancel"
                >
                  Change Password
                </button>
                <button
                  onClick={this.props.handleTwoFAModal}
                  style={{ width: "50%", height: "42px" }}
                  className="common-button-cancel"
                >{`${
                  otpEnabled || otpEmailEnabled ? "Disable" : "Enable"
                } 2FA`}</button>
              </div>
            </div>
          </div>
          {role !== "trialist" && (
            <div className="project-notifications-container">
              <div>
                <NotificationsIcon
                  svgStyles={{
                    marginRight: "8px",
                    height: "30px",
                    width: "30px",
                  }}
                />
                <h3 style={{ fontWeight: "600" }}>PROJECT NOTIFICATIONS</h3>
              </div>
              <div className="new-scrollbar">
                <div className="notification-project">
                  <span>Mute All Projects</span>
                  <div className="switch right">
                    <label>
                      <input type="hidden" value={this.props.muteAllProjects} />
                      <input
                        type="checkbox"
                        checked={this.props.muteAllProjects}
                        data-checked={this.props.muteAllProjects}
                        onChange={this.handleMuteAllProjects}
                      />
                      <span aria-hidden="true" className="lever">
                        <span className="switch-on">ON</span>
                      </span>
                      <span aria-hidden="true" className="switch-off">
                        OFF
                      </span>
                    </label>
                  </div>
                </div>
                {this.props.memberships.map((m) => {
                  return (
                    <div
                      style={{
                        opacity: this.props.muteAllProjects ? "0.5" : "1",
                      }}
                      className="notification-project"
                      key={m.id}
                    >
                      <span>{m.project_name}</span>
                      <div className="switch right">
                        <label>
                          <input
                            disabled={this.props.muteAllProjects}
                            type="hidden"
                            value={m.id}
                          />
                          <input
                            type="checkbox"
                            key={m.id}
                            data-membership-id={m.id}
                            checked={m.email_notifications}
                            data-checked={m.email_notifications}
                            onChange={this.handleUpdateMembership}
                            disabled={this.props.muteAllProjects}
                          />
                          <span aria-hidden="true" className="lever">
                            <span className="switch-on">ON</span>
                          </span>
                          <span aria-hidden="true" className="switch-off">
                            OFF
                          </span>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AccountDetails;
