import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { commentTypes } from "@types/ticket";
import { userTypes } from "@types";
import ApiClient from "@utils/ApiClient";
import LikeIcon from "@icons/like_icon";
import {
  useHover,
  useFloating,
  useInteractions,
  useTransitionStyles,
  offset,
} from "@floating-ui/react";

const CommentLikes = (props) => {
  const { comment, currentUser, updateCommentState } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isHoverOpen, setIsHoverOpen] = useState(false);

  const currentUserLiked = useMemo(() => {
    return comment.likes.current_user_liked;
  }, [comment]);

  const isLiked = useMemo(() => {
    return comment.likes.count > 0;
  }, [comment]);

  const likersString = useMemo(() => {
    if (!isLiked) {
      return null;
    }

    let likersArray = comment.likes.list.map((like) => ({
      id: like.user_id,
      name: like.name,
    }));
    if (currentUserLiked) {
      likersArray = likersArray.filter((liker) => liker.id !== currentUser.id);

      likersArray.unshift({
        id: currentUser.id,
        name: "You",
      });
    }

    let string = likersArray.map((liker) => liker.name).join(", ");

    if (likersArray.length < comment.likes.count) {
      string = `${string} + ${comment.likes.count - likersArray.length} others`;
    }
    return string;
  }, [comment, currentUser, currentUserLiked, isLiked]);

  const {
    refs,
    floatingStyles,
    context: floatingContext,
  } = useFloating({
    open: isHoverOpen,
    onOpenChange: setIsHoverOpen,
    placement: "left",
    transform: true,
    middleware: [offset(8)],
  });

  const hover = useHover(floatingContext, {
    enabled: isLiked,
    delay: { open: 25, close: 150 },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const { isMounted: isHoverMounted, styles: hoverStyles } =
    useTransitionStyles(floatingContext, {
      duration: 150,
      enabled: isLiked,
      initial: {
        opacity: 0,
        transform: "scale(0.9) translateX(-25%)",
      },
    });

  const submitLike = useCallback(() => {
    const api = new ApiClient();
    setIsLoading(true);
    api
      .put(`/comments/${comment.id}/react`, {
        reaction: { kind: "like" },
      })
      .then((res) => {
        updateCommentState(res.data);
      })
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line no-undef
        M.toast({
          html: "There was an error liking this comment.",
          classes: "red darken-1",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [comment, updateCommentState]);

  const submitUnlike = useCallback(() => {
    const api = new ApiClient();
    setIsLoading(true);

    api
      .put(`/comments/${comment.id}/unreact`, {
        reaction: { kind: "like" },
      })
      .then((res) => {
        updateCommentState(res.data);
      })
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line no-undef
        M.toast({
          html: "There was an error removing like from this comment.",
          classes: "red darken-1",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [comment, updateCommentState]);

  const handleLikeButtonClick = useCallback(() => {
    if (currentUserLiked) {
      submitUnlike();
    } else {
      submitLike();
    }
  }, [currentUserLiked, submitLike, submitUnlike]);

  useEffect(() => {
    // when unliking a comment, force hover close, otherwise get a dumb empty blue hover
    if (comment.likes.count === 0) {
      setIsHoverOpen(false);
    }
  }, [comment.likes.count]);

  return (
    <>
      <CommentLikesWrapper ref={refs.setReference} {...getReferenceProps()}>
        <LikesButton
          $isLiked={currentUserLiked}
          onClick={handleLikeButtonClick}
        >
          <LikesIcon>
            <LikeIcon filled={currentUserLiked} />
          </LikesIcon>
          <LikesCount $isLiked={currentUserLiked}>
            {isLoading ? <LoadingEllipsis /> : comment.likes.count}
          </LikesCount>
        </LikesButton>
      </CommentLikesWrapper>
      {isHoverMounted && (
        <LikesListHover
          ref={refs.setFloating}
          style={{ ...hoverStyles, ...floatingStyles }}
          {...getFloatingProps()}
        >
          <LikesListBody>{likersString}</LikesListBody>
        </LikesListHover>
      )}
    </>
  );
};

const CommentLikesWrapper = styled.div``;

const LikesButton = styled.button`
  height: 40px;
  width: 40px;
  border: 1px solid;
  border-color: ${({ $isLiked }) => ($isLiked ? "#519acc" : "#D7D7D7")};
  border-radius: 4px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #519acc;
  }
`;

const LikesIcon = styled.span`
  font-size: 10px;
  color: #519acc;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const LoadingEllipsis = styled.span`
  &:after {
    display: inline-block;
    overflow: hidden;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\u2026";
    width: 0;
    color: #242424;
    height: 13px;
  }

  @keyframes ellipsis {
    to {
      width: 16px;
    }
  }
`;

const LikesCount = styled.span`
  font-size: 13px;
  font-family: Manrope;
  font-weight: 600;
  min-height: 13px;
  color: ${({ $isLiked }) => ($isLiked ? "#519acc" : "#242424")};
`;

const LikesListHover = styled.div`
  min-height: 40px;
  border-radius: 4px;
  background-color: #519acc;
  padding: 4px 8px;
`;

const LikesListBody = styled.div`
  height: 100%;
  min-height: 28px;
  max-width: 300px;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  font-style: italic;
  line-clamp: 2;
  text-align: center;
  margin: auto 0;
`;

CommentLikes.propTypes = {
  comment: commentTypes,
  currentUser: userTypes,
  updateCommentState: PropTypes.func.isRequired,
};

export default CommentLikes;
