import React from "react";

class GridLibIcon extends React.Component {
  render() {
    return (
      <svg
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        style={this.props.svgStyles}
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 64 64"
      >
        <path d="M29,31.39H11.27A2.35,2.35,0,0,1,8.92,29V11.33A2.35,2.35,0,0,1,11.27,9H29a2.35,2.35,0,0,1,2.35,2.35V29A2.35,2.35,0,0,1,29,31.39Zm-17.56-2.5H28.83V11.48H11.42Z"/>
        <path d="M52.73,31.39H35A2.35,2.35,0,0,1,32.67,29V11.33A2.35,2.35,0,0,1,35,9H52.73a2.35,2.35,0,0,1,2.35,2.35V29A2.35,2.35,0,0,1,52.73,31.39Zm-17.56-2.5H52.58V11.48H35.17Z"/>
        <path d="M29,55H11.27a2.35,2.35,0,0,1-2.35-2.35V35a2.35,2.35,0,0,1,2.35-2.35H29A2.35,2.35,0,0,1,31.33,35V52.67A2.35,2.35,0,0,1,29,55Zm-17.56-2.5H28.83V35.11H11.42Z"/>
        <path d="M52.73,55H35a2.35,2.35,0,0,1-2.35-2.35V35A2.35,2.35,0,0,1,35,32.61H52.73A2.35,2.35,0,0,1,55.08,35V52.67A2.35,2.35,0,0,1,52.73,55Zm-17.56-2.5H52.58V35.11H35.17Z"/>
      </svg>
    );
  }
}

GridLibIcon.defaultProps = {
  height: "25px",
  width: "25px",
  svgStyles: {},
  color: "#444344"
}

export default GridLibIcon