import React from "react";
import PropTypes from "prop-types";
import wikiCategoryValues from "./wikiCategoryValues";
import CheckboxMenu from "../common/checkbox_menu";

const ResourceMultiSelect = ({ categoryFilters, setCategoryFilters }) => {
  const onCategorySelect = (value) => {
    const nextCategories = [...categoryFilters];
    const index = nextCategories.indexOf(value);
    if (index === -1) {
      nextCategories.push(value);
    } else {
      nextCategories.splice(index, 1);
    }
    setCategoryFilters(nextCategories);
  };

  return (
    <CheckboxMenu
      name="filters"
      title={`${categoryFilters.length === 0 ? "Filter by " : ""}Category`}
      maxWidth="100%"
      maxHeight="350px"
      count={categoryFilters.length}
      active
      options={Object.entries(wikiCategoryValues).map(([value, { label }]) => {
        return {
          label,
          value,
          handler: () => onCategorySelect(value),
          checked: categoryFilters.indexOf(value) !== -1,
        };
      })}
      containerMinWidth="160px"
      menuStyles={{
        width: "200px",
      }}
      narrow
      icons={[
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
      ]}
    />
  );
};

ResourceMultiSelect.propTypes = {
  categoryFilters: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(wikiCategoryValues))
  ).isRequired,
  setCategoryFilters: PropTypes.func.isRequired,
};

export default ResourceMultiSelect;
