import React from "react";

const YesterdaySelectedFilled = (props) => {
  return (
    <svg
      id="Icons_Yesterday_Outline_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {".Icons_Yesterday_Outline_svg__cls-2{fill:#519acc}"}
        </style>{" "}
      </defs>{" "}
      <rect
        x={34.94}
        y={33.59}
        width={4.7}
        height={4.7}
        rx={1.37}
        opacity={0.33}
        fill="#414042"
      />{" "}
      <rect
        className="Icons_Yesterday_Outline_svg__cls-2"
        x={24.45}
        y={33.59}
        width={4.7}
        height={4.7}
        rx={1.37}
      />{" "}
      <path
        className="Icons_Yesterday_Outline_svg__cls-2"
        d="M13.05 50.74H51A3.76 3.76 0 0054.71 47V17A3.76 3.76 0 0051 13.26H13.05A3.77 3.77 0 009.29 17v30a3.77 3.77 0 003.76 3.74zm37.9-2h-37.9A1.76 1.76 0 0111.29 47V25h41.42v22A1.76 1.76 0 0151 48.74zm-37.9-33.48H51A1.76 1.76 0 0152.71 17v6H11.29v-6a1.76 1.76 0 011.76-1.74z"
      />{" "}
    </svg>
  );
};

export default YesterdaySelectedFilled;
