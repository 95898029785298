import React from "react";
import styled from "styled-components";
import ReactModalV2 from "../modals/react_modal_v2";
import FeedbackIcon from "../icons/feedback_icon";
import useModalControl from "../../hooks/useModalControl";
import WikiFeedbackForm from "./WikiFeedbackForm";

const WikiFeedbackModal = (props) => {
  const { isOpen, toggle } = useModalControl();

  props.isModalOpen(isOpen);

  return (
    <>
      <OpenFeedbackModalButton
        onClick={toggle}
        id="add-wiki-resource-feedback"
        role="button"
        aria-label="Give Feedback"
      >
        <ButtonContentsContainer>
          <SmallOpenFeedback>
            <FeedbackIcon />
          </SmallOpenFeedback>
          <LargeOpenFeedback>
            <FeedbackIcon />{" "}
            <span style={{ marginLeft: "10px" }}>Feedback</span>
          </LargeOpenFeedback>
        </ButtonContentsContainer>
      </OpenFeedbackModalButton>
      <ReactModalV2
        id="add-wiki-resource-modal"
        isShowing={isOpen}
        data={{ customClass: "add-wiki-resource-modal" }}
        page={() => <WikiFeedbackForm toggleModalVisible={toggle} />}
        modalAction={toggle}
      />
    </>
  );
};

const OpenFeedbackModalButton = styled.div`
  display: block;
  position: fixed;
  right: 0;
  bottom: 25px;
  background: transparent;
  cursor: pointer;
  height: 32px;
`;

const ButtonContentsContainer = styled.div`
  display: block;
  position: relative;
  background: transparent;
  width: 100%;
  height: 100%;
`;

const commonStyle = `
  transition: opacity 0.15s linear;
  position: absolute;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #fff;
  background: linear-gradient(3.87deg, #43AEEA 2.34%, #1B71AC 138.67%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  font-family: "Manrope";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  `;

const SmallOpenFeedback = styled.div`
  ${commonStyle}
  opacity: 1;
  width: 42px;
  padding: 0 9px;

  &:hover {
    opacity: 0;
  }
`;

const LargeOpenFeedback = styled.div`
  ${commonStyle}
  opacity: 0;
  padding: 8px 16px;

  &:hover {
    opacity: 1;
  }
`;

export default WikiFeedbackModal;
