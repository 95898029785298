import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Swal from "sweetalert2";
import ApiClient from "../../utils/ApiClient";
import { wikiResourceTypes } from "../../types/wiki";
import WikiTopic from "./wiki_topic";
import PlusIcon from "../icons/plus_icon";
import EditIcon from "../icons/edit_icon";
import MoveIcon from "../icons/move_icon";
import DeleteIcon from "../icons/delete_icon";
import Arrow from "../icons/round_dropdown";
import VisibleRolesDropdown from "./VisibleRolesDropdown";
import VisibleTeamsDropdown from "./VisibleTeamsDropdown";
import WikiFeedbackModal from "./WikiFeedbackModal";
import TakeAssessmentModal from "./TakeAssessmentModal";
import EditWikiResourceModal from "./EditWikiResourceModal";
import { userTypes } from "../../types";

const resourceIcons = {
  Dashboard:
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Piechart.svg",
  Bugs: "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20-%20Filled.svg",
  "Test Cases":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Cases%20-%20Filled2.svg",
  "Test Passes":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Test%20Passes%20-%20Filled.svg",
  "Device Lab":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Device_Lab_Filled.svg",
  Performance:
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_Skills%20-%20Filled.svg",
  "Admin Panel":
    "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Admin_Panel_Filled.svg",
};

const WikiResource = ({
  backToLandingPage,
  currentResource,
  currentUser,
  handleDragDropUpdate,
  permanentFeaturedTopicsCount,
  setModal,
  sideBarWidth,
  teamOptions,
  wikiResources,
}) => {
  const [topics, setTopics] = useState(currentResource.wiki_topics);
  const [resourceSwitch, setResourceSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [editingTopic, setEditingTopic] = useState(false);
  const [showStickyIndex, setShowStickyIndex] = useState(false);
  const [stickyIndexExpanded, setStickyIndexExpanded] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState(
    currentResource.wiki_resource.user_permissions
  );
  const [selectedTeams, setSelectedTeams] = useState(
    currentResource.wiki_resource.team_permissions
  );
  const [direction, setDirection] = useState("node-forwards");
  const [currentResourceIndex, setCurrentResourceIndex] = useState(
    wikiResources.findIndex((r) => r.id === currentResource.wiki_resource.id)
  );
  const [resource, setResource] = useState(currentResource.wiki_resource);

  const topicEditInput = useRef();
  const indexContainerRef = useRef();

  const oldScroll = useRef(0);

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const isFeedbackModalOpen = (data) => {
    setFeedbackModalOpen(data);
  };

  const handleRoleChange = (e) => {
    const api = new ApiClient();
    const index = selectedRoles.indexOf(e.target.value);
    const newRoles =
      index === -1
        ? [...selectedRoles, e.target.value]
        : [...selectedRoles.slice(0, index), ...selectedRoles.slice(index + 1)];

    setSelectedRoles(newRoles);

    api.patch(`/wiki/${resource.id}.json`, {
      wiki_resource: { user_permissions: newRoles },
    });
  };

  const handleTeamChange = (e) => {
    const api = new ApiClient();
    const value = parseInt(e.target.value);
    const index = selectedTeams.indexOf(value);
    const newTeams =
      index === -1
        ? [...selectedTeams, value]
        : [...selectedTeams.slice(0, index), ...selectedTeams.slice(index + 1)];

    setSelectedTeams(newTeams);

    api.patch(`/wiki/${resource.id}.json`, {
      wiki_resource: { team_permissions: newTeams },
    });
  };

  const handleEditTopic = (e) => {
    const id = parseInt(e.target.dataset.id);
    if (editingTopic === "new") {
      setTopics(topics.slice(0, topics.length - 1));
    }
    setEditingTopic(id);
  };

  const handleScrollToTopic = (e) => {
    const { id } = e.target.dataset;
    const elem = document.getElementById(`topic-${id}`);

    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
      window.history.pushState(
        "page2",
        "Test Platform",
        `/wiki/${resource.id}?topic=${id}`
      );
    }
  };

  const handleAddTopic = (e) => {
    const { bottomButton } = e.target.dataset;
    setTopics([
      ...topics,
      {
        wiki_resource_id: resource.id,
        id: "new",
        position: topics.length + 1,
        title: "",
        bottomButton,
      },
    ]);
    setEditingTopic("new");
  };

  const handleCancelTopic = () => {
    if (editingTopic === "new") {
      setTopics(topics.slice(0, topics.length - 1));
    }
    setEditingTopic(false);
  };

  const handleUpdateTopic = (data) => {
    const api = new ApiClient();
    if (data && data.id) {
      api
        .patch(`/wiki_topics/${data.id}.json`, {
          wiki_topic: data,
        })
        .then((res) => {
          setTopics(topics.map((t) => (t.id === res.data.id ? res.data : t)));
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (topicEditInput.current) {
      if (!topicEditInput.current.value) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Please enter a topic title",
          classes: "red darken-1 error-toast",
        });
        return null;
      }
      if (editingTopic === "new") {
        const api = new ApiClient();
        api
          .post("/wiki_topics.json", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            wiki_topic: {
              title: topicEditInput.current.value,
              wiki_resource_id: resource.id,
            },
          })
          .then((res) => {
            if (
              topics[topics.length - 1] &&
              topics[topics.length - 1].bottomButton
            ) {
              setTimeout(() => {
                const elem = document.getElementById(`topic-${res.data.id}`);
                elem?.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }
            setTopics([...topics.slice(0, topics.length - 1), res.data]);
            setEditingTopic(false);
            setUpdated(true);
          });
      } else {
        api
          .patch(`/wiki_topics/${editingTopic}.json`, {
            wiki_topic: {
              title: topicEditInput.current.value,
            },
          })
          .then((res) => {
            setTopics(topics.map((t) => (t.id === res.data.id ? res.data : t)));
            setEditingTopic(false);
            setUpdated(true);
          });
      }
    }
    return null;
  };

  const handleDragStart = (e) => {
    const id = parseInt(e.target.dataset.id);
    const position = parseInt(e.target.dataset.position);
    setDragging({ id, position });
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDragOver = (e) => e.preventDefault();

  const handleDragEnter = (e) => {
    const { position } = e.target.dataset;
    if (position) {
      if (parseInt(position) > dragging.position) {
        e.target.classList.add("bottom-drag");
      } else if (parseInt(position) < dragging.position) {
        e.target.classList.add("top-drag");
      }
    }
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
  };

  const handleDragDrop = (e) => {
    const api = new ApiClient();
    e.target.classList.remove("top-drag");
    e.target.classList.remove("bottom-drag");
    const { position } = e.target.dataset;
    if (position) {
      const { id } = dragging;
      api
        .patch(`/wiki_topics/${id}`, {
          wiki_topic: { position },
        })
        .then((res) => {
          setTopics(res.data.wiki_topics);
          handleDragDropUpdate(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleDeleteTopic = (e) => {
    const api = new ApiClient();
    const { id } = e.target.dataset;
    Swal.fire({
      title: "Delete Topic",
      text: "Are you sure that you want to delete this topic?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        api
          .delete(`/wiki_topics/${id}`, {})
          .then((res) => {
            setTopics(res.data.wiki_topics);
            handleDragDropUpdate(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const handleResourceSwitch = (e) => {
    const api = new ApiClient();
    const { id, direction: switchDirection } = e.target.dataset;
    setDirection(switchDirection);

    api
      .get(`/get_wiki_resource/${id}.json`)
      .then((res) => {
        setResourceSwitch(!resourceSwitch);
        setTimeout(() => {
          setCurrentResourceIndex(
            wikiResources.findIndex((r) => r.id === res.data.wiki_resource.id)
          );
          setResource(res.data.wiki_resource);
          setTopics(res.data.wiki_topics);
        }, 300);
        window.history.pushState("page2", "Test Platform", `/wiki/${id}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const column1 =
    topics.length > 0 ? topics.slice(0, Math.ceil(topics.length / 2)) : [];
  const column2 =
    topics.length > 1 ? topics.slice(Math.ceil(topics.length / 2)) : [];

  useEffect(() => {
    const scrollListener = () => {
      const { bottom } = indexContainerRef.current.getBoundingClientRect();

      if (
        bottom < 0 &&
        !showStickyIndex &&
        oldScroll.current > window.scrollY
      ) {
        setShowStickyIndex(true);
      } else if (bottom >= 0 && showStickyIndex) {
        setShowStickyIndex(false);
        setStickyIndexExpanded(false);
      } else if (
        bottom < 0 &&
        showStickyIndex &&
        !stickyIndexExpanded &&
        oldScroll.current < window.scrollY
      ) {
        setShowStickyIndex(false);
      }
      oldScroll.current = window.scrollY;
    };

    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setResource(currentResource.wiki_resource);
  }, [currentResource.wiki_resource]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={resourceSwitch}
        timeout={{ appear: 300, enter: 390, exit: 390 }}
        classNames={direction}
      >
        <div
          style={{
            width: "100%",
            minHeight: "calc(100vh - 70px)",
            backgroundColor: "#efefef",
          }}
        >
          <CSSTransition
            unmountOnExit
            in={showStickyIndex}
            timeout={300}
            classNames="node-fade"
          >
            <div
              style={{
                position: "fixed",
                top: "0",
                zIndex: "100",
                left: `calc(50% + ${100 / 2}px)`,
                transform: "translateX(-50%)",
              }}
              id="sticky-index"
            >
              <div
                className="topic-viewing"
                style={{
                  height: `${22 + (column1.length + 1) * 30.1}px`,
                  maxHeight: stickyIndexExpanded
                    ? `${22 + (column1.length + 1) * 30.1}px`
                    : "0px",
                  boxShadow: stickyIndexExpanded
                    ? "0 1px 3px 1px #cccccc"
                    : "0 1px 3px 1px #cccccc00",
                  transition:
                    "max-height 0.3s, border-color 0.3s, background-color 0.3s, box-shadow 0.3s",
                  borderColor: stickyIndexExpanded ? "#519acc" : "transparent",
                  overflow: "hidden",
                  display: "flex",
                  padding: "0 10px",
                  backgroundColor: stickyIndexExpanded
                    ? "#ffffffed"
                    : "transparent",
                }}
              >
                <div style={{ padding: "10px 0" }}>
                  {column1.map((t, i) => {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className={`${editing ? "editing" : ""}`}
                        key={t.id}
                      >
                        <button
                          type="button"
                          onClick={handleScrollToTopic}
                          data-id={t.id}
                          style={{
                            padding: "7px 20px",
                            transition: "transform 0.3s",
                          }}
                          className="light-button"
                        >
                          {i + 1}. {t.title}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div style={{ padding: "10px 0" }}>
                  {column2.map((t, i) => {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className={`${editing ? "editing" : ""}`}
                        key={t.id}
                      >
                        <button
                          type="button"
                          onClick={handleScrollToTopic}
                          data-id={t.id}
                          style={{
                            padding: "7px 20px",
                            transition: "transform 0.3s",
                          }}
                          className="light-button"
                        >
                          {i + 1 + column1.length}. {t.title}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "57px",
                    left: "0",
                    right: "0",
                    margin: "0 auto",
                    width: "150px",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    style={{
                      padding: "7px 20px",
                      transition: "transform 0.3s",
                      fontStyle: "italic",
                      textDecoration: "none",
                      fontWeight: "600",
                      color: "#519acc",
                      fontSize: "14px",
                    }}
                    className="light-button"
                  >
                    Scroll to top
                  </button>
                </div>
              </div>
              {!feedbackModalOpen && (
                <button
                  type="button"
                  style={{
                    cursor: "pointer",
                    height: "30px",
                    borderRadius: "20px",
                    border: "solid 2px #519acc",
                    backgroundColor: stickyIndexExpanded ? "#519acc" : "white",
                    color: stickyIndexExpanded ? "white" : "#519acc",
                    display: "block",
                    width: "160px",
                    margin: "10px auto",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onClick={() => setStickyIndexExpanded(!stickyIndexExpanded)}
                >
                  {stickyIndexExpanded
                    ? "Hide Topic Glossary"
                    : "Show Topic Glossary"}
                </button>
              )}
            </div>
          </CSSTransition>
          <div ref={indexContainerRef} id="help-show-index-container">
            <div>
              <div>
                {resourceIcons[resource.title] && (
                  <img
                    alt={`${resource.title} icon`}
                    style={{ marginRight: "10px", width: "50px" }}
                    src={resourceIcons[resource.title]}
                  />
                )}
                <h2 style={{ marginRight: "16px" }}>
                  {resource.title} Wiki Topics
                </h2>{" "}
                {currentUser?.role === "admin" && (
                  <EditWikiResourceModal resource={resource} />
                )}
              </div>
              <div>
                {currentUser?.role === "admin" && (
                  <>
                    {editing && (
                      <VisibleRolesDropdown
                        selectedRoles={selectedRoles}
                        onRoleSelect={handleRoleChange}
                      />
                    )}
                    {editing && (
                      <VisibleTeamsDropdown
                        selectedTeams={selectedTeams}
                        onTeamSelect={handleTeamChange}
                        teamOptions={teamOptions}
                      />
                    )}
                    <button
                      type="button"
                      onClick={toggleEditing}
                      style={{
                        margin: "0 10px 0 20px",
                        height: "48px",
                        width: "140px",
                      }}
                      className="common-button-cancel"
                    >
                      {!editing ? "Enable" : "Disable"} Editing
                    </button>
                  </>
                )}
                <button
                  type="button"
                  onClick={() => backToLandingPage(updated)}
                  style={{ height: "48px", width: "180px" }}
                  className="common-button-submit"
                >
                  Back to Landing Page
                </button>
                {resource.assessments && resource.assessments.length > 0 && (
                  <TakeAssessmentModal assessments={resource.assessments} />
                )}
              </div>
            </div>
            {!editing ? (
              <div
                className="topic-viewing"
                style={{
                  height: `${22 + column1.length * 30.1}px`,
                  border: column1.length > 0 ? "1px solid #519acc" : "none",
                  display: "flex",
                }}
              >
                <div>
                  {column1.map((t, i) => {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className={`${editing ? "editing" : ""}`}
                        key={t.id}
                      >
                        <button
                          type="button"
                          onClick={handleScrollToTopic}
                          data-id={t.id}
                          style={{
                            padding: "7px 20px",
                            transition: "transform 0.3s",
                          }}
                          className="light-button"
                        >
                          {i + 1}. {t.title}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {column2.map((t, i) => {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className={`${editing ? "editing" : ""}`}
                        key={t.id}
                      >
                        <button
                          type="button"
                          onClick={handleScrollToTopic}
                          data-id={t.id}
                          style={{
                            padding: "7px 20px",
                            transition: "transform 0.3s",
                          }}
                          className="light-button"
                        >
                          {i + 1 + column1.length}. {t.title}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="topic-editing">
                {topics.map((t, i) => {
                  if (editingTopic === t.id) {
                    return (
                      <div>
                        <input
                          ref={topicEditInput}
                          placeholder="Enter a topic title"
                          defaultValue={t.title}
                          style={{
                            margin: "10px 5px 20px 20px",
                            width: "300px",
                            height: "35px",
                            fontSize: "14px",
                          }}
                          type="text"
                          className="common-input browser-default"
                        />
                        <button
                          type="button"
                          onClick={handleUpdateTopic}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{ color: "#519acc", fontSize: "21px" }}
                            className="material-icons"
                          >
                            done
                          </i>
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelTopic}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{ color: "#519acc", fontSize: "21px" }}
                            className="material-icons"
                          >
                            close
                          </i>
                        </button>
                      </div>
                    );
                  }
                  return (
                    <div
                      style={{ position: "relative" }}
                      className={`${editing ? "editing" : ""}`}
                      key={t.id}
                    >
                      <div
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={handleDragDrop}
                        className="trial-section-drag-overlay"
                        data-position={t.position}
                        style={{
                          zIndex:
                            dragging && dragging.id !== t.id ? "100" : "-1",
                        }}
                      />
                      <button
                        onClick={handleScrollToTopic}
                        data-id={t.id}
                        style={{
                          padding: "7px 20px",
                          transition: "transform 0.3s",
                        }}
                        className="light-button"
                      >
                        {i + 1}. {t.title}
                      </button>
                      {/* eslint-disable-next-line */}
                      <span
                        style={{ pointerEvents: editing ? "all" : "none" }}
                        data-id={t.id}
                        onClick={handleEditTopic}
                      >
                        <EditIcon
                          color="#519acc"
                          svgStyles={{ pointerEvents: "none" }}
                        />
                      </span>
                      {/* eslint-disable-next-line */}
                      <span
                        style={{
                          pointerEvents: editing ? "all" : "none",
                          margin: "0 8px",
                        }}
                        data-id={t.id}
                        onClick={handleDeleteTopic}
                      >
                        <DeleteIcon
                          color="#519acc"
                          svgStyles={{ pointerEvents: "none" }}
                        />
                      </span>
                      {topics.length > 1 && (
                        <span
                          style={{ pointerEvents: editing ? "all" : "none" }}
                          data-id={t.id}
                          data-position={t.position}
                          onDragStart={handleDragStart}
                          onDragEnd={handleDragEnd}
                          draggable
                        >
                          <MoveIcon
                            color="#519acc"
                            svgStyles={{ pointerEvents: "none" }}
                          />
                        </span>
                      )}
                    </div>
                  );
                })}
                {editing && !editingTopic && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="button"
                      onClick={handleAddTopic}
                      disabled={loading || editingTopic}
                      style={{
                        border: "none",
                        margin: "5px 0",
                        backgroundColor: "#519acc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        padding: "4px",
                      }}
                    >
                      <PlusIcon
                        svgStyles={{ pointerEvents: "none" }}
                        width={14}
                        height={14}
                      />
                    </button>
                  </div>
                )}
              </div>
            )}
            {!editing && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "20px 0 0",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    type="button"
                    onClick={handleResourceSwitch}
                    data-direction="node-backwards"
                    data-id={
                      currentResourceIndex > 0
                        ? wikiResources[currentResourceIndex - 1].id
                        : wikiResources[wikiResources.length - 1].id
                    }
                    className="trial-page-arrow"
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  >
                    <Arrow
                      color="white"
                      svgStyles={{
                        transform: "rotate(90deg)",
                        pointerEvents: "none",
                      }}
                    />
                  </button>
                  <span>
                    {currentResourceIndex > 0
                      ? wikiResources[currentResourceIndex - 1].title
                      : wikiResources[wikiResources.length - 1].title}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    {currentResourceIndex < wikiResources.length - 1
                      ? wikiResources[currentResourceIndex + 1].title
                      : wikiResources[0].title}
                  </span>
                  <button
                    type="button"
                    data-id={
                      currentResourceIndex < wikiResources.length - 1
                        ? wikiResources[currentResourceIndex + 1].id
                        : wikiResources[0].id
                    }
                    onClick={handleResourceSwitch}
                    data-direction="node-forwards"
                    className="trial-page-arrow"
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      height: "25px",
                      marginLeft: "10px",
                    }}
                  >
                    <Arrow
                      color="white"
                      svgStyles={{
                        transform: "rotate(-90deg)",
                        pointerEvents: "none",
                      }}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div id="help-section-container">
            {topics
              .filter((t) => t.id !== "new")
              .map((t) => {
                return (
                  <WikiTopic
                    permanentFeaturedTopicsCount={permanentFeaturedTopicsCount}
                    key={t.id}
                    topic={t}
                    loading={loading}
                    handleSetLoading={setLoading}
                    editing={editing}
                    setModal={setModal}
                    handleUpdateTopic={handleUpdateTopic}
                    selectedRoles={selectedRoles}
                    selectedTeams={selectedTeams}
                    teamOptions={teamOptions}
                    currentUser={currentUser}
                  />
                );
              })}
            {editing && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <button
                  type="button"
                  data-bottom-button="true"
                  onClick={handleAddTopic}
                  disabled={loading || editingTopic}
                  style={{
                    border: "none",
                    backgroundColor: "#519acc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    padding: "4px",
                  }}
                >
                  <PlusIcon
                    svgStyles={{ pointerEvents: "none" }}
                    width={14}
                    height={14}
                  />
                </button>
              </div>
            )}
          </div>
          <WikiFeedbackModal isModalOpen={isFeedbackModalOpen} />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

const { func, shape, arrayOf, number, string, oneOfType } = PropTypes;

WikiResource.propTypes = {
  backToLandingPage: func.isRequired,
  currentResource: wikiResourceTypes,
  currentUser: userTypes,
  handleDragDropUpdate: func,
  permanentFeaturedTopicsCount: number,
  setModal: func,
  sideBarWidth: number,
  teamOptions: arrayOf(
    shape({ label: string, value: oneOfType([string, number]) })
  ),
  wikiResources: arrayOf(wikiResourceTypes),
};

export default WikiResource;
