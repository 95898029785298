import React from 'react'

class CheckmarkIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50.04 50.04"
        className="icons"
        aria-hidden="true"
      >
        <path fill="#519acc" d="M25,0A25,25,0,1,0,50,25,25,25,0,0,0,25,0Z"/>
        <path fill="#fff" d="M37.73,16.56a1.9,1.9,0,0,0-2.68,0L22.4,29.49l-6.19-6.64a1.87,1.87,0,0,0-1.32-.6h-.06a1.9,1.9,0,0,0-1.3.51,1.87,1.87,0,0,0-.6,1.32,1.85,1.85,0,0,0,.51,1.36L21,33.53a2,2,0,0,0,1.37.6h0a1.91,1.91,0,0,0,1.35-.57l14-14.32A1.9,1.9,0,0,0,37.73,16.56Z"/>
        <path fill="#fff" d="M22.37,35.13a2.93,2.93,0,0,1-2.12-.92l-7.54-8.09A2.86,2.86,0,0,1,11.93,24a2.9,2.9,0,0,1,2.9-2.79h.11a2.93,2.93,0,0,1,2,.92l5.47,5.88L34.33,15.89a2.9,2.9,0,0,1,4.14,4.05l-14,14.32A2.91,2.91,0,0,1,22.37,35.13ZM14.86,23.25a.91.91,0,0,0-.93.86.88.88,0,0,0,.24.64l7.54,8.1a.92.92,0,0,0,.65.28.91.91,0,0,0,.65-.27L37,18.54a.89.89,0,0,0,0-1.26.9.9,0,0,0-1.27,0L22.39,30.94l-6.91-7.4a.88.88,0,0,0-.62-.29Z"/>
      </svg>
    );
  }
}
CheckmarkIcon.defaultProps = {
  color: "#519acc",
  height: "70px",
  svgStyles: {},
  width: "70px",
};
export default CheckmarkIcon