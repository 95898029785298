import React from "react";

const ExpertTrainerSmall = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {".Skills__Trainer_Expert_-_Small_svg__cls-1{fill:#edcc43}"}{" "}
        </style>{" "}
      </defs>{" "}
      <g id="Skills__Trainer_Expert_-_Small_svg__Layer_2" data-name="Layer 2">
        {" "}
        <circle
          className="Skills__Trainer_Expert_-_Small_svg__cls-1"
          cx={540}
          cy={540}
          r={418.69}
        />{" "}
        <circle cx={540} cy={540} r={363.39} fill="#519acc" />{" "}
        <path
          className="Skills__Trainer_Expert_-_Small_svg__cls-1"
          d="M362.55 549.23v-23.41a7.45 7.45 0 019.84-7.06l167.1 56.7a7.41 7.41 0 004.81 0L720 515.21a7.45 7.45 0 019.87 7.18l-1.5 84.83a12.56 12.56 0 01-2.56 7.41c-10.26 13.47-54.79 63-179.2 61-117.46-1.89-159.7-38.48-171.6-50.73a7.36 7.36 0 01-.86-9.12c3.64-5.68 9.2-16.12 9.2-26.75 0-14.31-4.56-26.83-16.74-33.19a7.44 7.44 0 01-4.06-6.61z"
        />{" "}
        <path
          d="M830.82 433.63l-286.55-95.8-282.71 94.69a8.45 8.45 0 00-.18 16l70.79 25.44c-6.89 6-27 28-25.76 79.68a28.88 28.88 0 00-15.51 25.58c0 10.43 5.68 18.61 14 23.65l-.05.86s-5.27 67.72-42.53 102.27a11.8 11.8 0 001.54 18.42l22.87 15.41a13.68 13.68 0 0014 .79c11.12-5.9 31.11-18.87 35.47-37.62 5.72-24.4 1.59-90.88 1-100.63a28.8 28.8 0 00-.09-46.34c.94-12 .72-25.66 4-37.37a68 68 0 0118.5-31.21 16.07 16.07 0 014.8-3l152.24-63.87s24.36-12.07 36-4.17c0 0 20.63 9.22-14.48 21.51s-144 59.17-144.16 59.24l152.93 52.52L831 449.59a8.45 8.45 0 00-.18-15.96z"
          fill="#f7d64b"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default ExpertTrainerSmall;
