import React from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import EditProjectModal from "./edit_project_modal.jsx";
import axios from "axios";
import EditIcon from "../icons/edit_icon.jsx";
import styled from "styled-components";

const Button = styled.button`
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: white !important;
  width: 25px;
  height: 25px;
  padding: 2px;
  > i {
    font-size: 20px;
  }
`;

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: this.props.project.active,
      name: this.props.project.name,
      project: this.props.project,
    };
    this.submitDeleteProject = this.submitDeleteProject.bind(this);
    this.deactivateProject = this.deactivateProject.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.editHandler = this.editHandler.bind(this);
  }

  editHandler(project) {
    this.setState({ project: project });
  }

  getProject() {
    axios
      .get(`/admin_panel/${this.props.project.id}/project`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ project: res.data.project });
      })
      .catch((err) => console.error(err));
  }

  async openEditModal() {
    this.props.loadingHandler();
    var resp = await axios.get(
      `/admin_panel/projects/${this.props.project.id}.json`
    );
    var members = resp.data.project.memberships
      .map((x) => x.member)
      .filter((x) => x.active && !x.deactivated);
    members.sort((a, b) => {
      var aName = a.name ? a.name.toLowerCase() : a.email.toLowerCase();
      var bName = b.name ? b.name.toLowerCase() : b.email.toLowerCase();
      return aName > bName ? 1 : aName < bName ? -1 : 0;
    });
    ReactDOM.render(
      <EditProjectModal
        teams={resp.data.teams}
        currentUser={this.props.currentUser}
        project={resp.data.project}
        loadingHandler={this.props.loadingHandler}
        members={members}
        handler={this.props.editHandler}
        action={"edit"}
        users={resp.data.users}
        key={this.props.project.id}
      />,
      document.getElementById("modalContainer")
    );
    this.props.loadingHandler();
  }

  deactivateProject() {
    var activeState = !this.props.project.active;
    var formData = new FormData();
    formData.append("project[active]", activeState);
    var component = this;
    var object = this.props;
    var project = object.project;
    var xhr = new XMLHttpRequest();
    xhr.open("PATCH", "/v1/projects/" + project.id, true);
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );
    xhr.onload = function () {
      if (xhr.status === 200) {
        var resp = JSON.parse(xhr.response);
        component.setState({ activeState: resp.active });
        component.closeEditProjectModal;
        component.props.editHandler(resp);
        Swal.fire({
          title: !resp.active ? "Deactivated" : "Activated",
          text: !resp.active
            ? "Your project was successfully deactivated"
            : "Your project was successfully activated",
        });
      } else {
        console.log(xhr.error);
        Swal.fire("Project deactivated error", "There was an issue with");
      }
    };
    Swal.fire({
      title: !project.active
        ? `Reactivate Project: ${object.project ? object.project.name : ""}`
        : `Deactivate Project: ${object.project ? object.project.name : ""}`,
      text: !project.active
        ? "Are you sure that you want to reactivate this Project?"
        : "Are you sure that you want to deactivate this Project?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        xhr.send(formData);
      } else if (result.dismiss) {
        // Swal.fire("Cancelled", "Your Project is safe");
      }
    });
  }

  submitDeleteProject(e) {
    var object = this.props;
    var user = object.user;
    var org = object.organization;
    var project = object.project;
    // Sweet alert that presents when user clicks trashcan icon
    Swal.fire({
      title: `Delete Project: ${object.project ? object.project.name : ""}`,
      text: "Are you sure that you want to delete this Project?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      // Send ajax call to delete application choosen
      if (result.value) {
        $.ajax({
          url: "/projects/" + object.project.id + ".json",
          dataType: "json",
          type: "DELETE",
          cache: false,
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          success: function (data) {
            Swal.fire("Deleted", "Your Project was successfully deleted");
            // Removes the application from the front end
            localStorage.removeItem(
              `project_${object.project.id}_recent_suite`
            );
            object.handleDeleteApplication(object.project.id);
          },
          error: function (data) {
            Swal.fire({
              title: "Project delete error",
              text: "There was a problem with that request",
              customClass: "swal2-error-modal",
            });
          },
        });
      } else if (result.dismiss) {
        // Swal.fire(
        // 'Cancelled',
        // 'Your Project is safe'
        // )
      }
    });
  }

  render() {
    return (
      <tr style={{ color: this.props.project.active ? "" : "#a5a5a5" }}>
        {this.props.project != undefined && this.props.project.active ? (
          <td>
            <a
              style={{ whiteSpace: "pre" }}
              href={`/projects/${this.props.project.id}/dashboard`}
            >
              {this.props.project.name}
            </a>
          </td>
        ) : (
          <td>
            <p style={{ whiteSpace: "pre" }}>{this.props.project.name}</p>
          </td>
        )}
        <td>{this.props.project.identifier}</td>
        <td>{this.props.project.created_at}</td>
        <td style={{ display: "flex", placeContent: "center" }}>
          <p
            style={{
              backgroundColor:
                this.props.project.type[0] == "U"
                  ? "#55a14d"
                  : this.props.project.type[0] == "A"
                  ? "#80281c"
                  : "",
              textTransform: "capitalize",
              marginTop: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              userSelect: "none",
              height: "20px",
              minWidth: "55px",
            }}
            className="pill"
          >
            {this.props.project.type}
          </p>
        </td>
        <td style={{ whiteSpace: "pre" }}>
          {(this.props.project.owner && this.props.project.owner.name) ||
            (this.props.project.owner && this.props.project.owner.email) ||
            ""}
        </td>
        <td></td>
        <td>
          <button
            style={{ padding: 0 }}
            type="button"
            className="link-btn"
            onClick={this.deactivateProject}
          >
            {this.props.project.active ? "Active" : "Deactivated"}
          </button>
        </td>
        <td></td>
        <td style={{ minWidth: "60px" }}>
          <Button
            style={{ marginRight: "5px" }}
            className="link-btn"
            onClick={this.openEditModal}
          >
            <i className="material-icons bc-blue">edit</i>
          </Button>
          <Button className="link-btn" onClick={this.submitDeleteProject}>
            <i className="material-icons bc-blue">delete</i>
          </Button>
        </td>
      </tr>
    );
  }
}

export default Project;
