import React from 'react'

class RedoIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.94 23.28"
        className="icons"
      >
        <g id="Layer_3" data-name="Layer 3">
          <path d="M14.24,0h-.11a.82.82,0,0,0-.82.84V4A17.79,17.79,0,0,0,4.23,7C1.67,8.78,0,11.32,0,14.13a7.75,7.75,0,0,0,2.73,5.53,16.57,16.57,0,0,0,6.15,3.57.8.8,0,0,0,.66-.05.82.82,0,0,0,.35-1.12c-1.19-2.35-2.41-3.58-2.41-7.34a3,3,0,0,1,.57-2,3.05,3.05,0,0,1,1.48-1,12.54,12.54,0,0,1,3.78-.41v2.75a.87.87,0,0,0,.13.45.84.84,0,0,0,1.16.24l10-6.65A1.15,1.15,0,0,0,24.8,8a.84.84,0,0,0-.22-1.16L14.6.15A.7.7,0,0,0,14.24,0ZM15,2.38l7.66,5.11L15,12.58V10.53a.83.83,0,0,0-.83-.83A15.81,15.81,0,0,0,9,10.22a4.69,4.69,0,0,0-2.25,1.52,4.73,4.73,0,0,0-.92,3,11.89,11.89,0,0,0,1.5,6,13.86,13.86,0,0,1-3.45-2.31,6.15,6.15,0,0,1-2.21-4.33C1.66,12,2.94,9.9,5.19,8.31a16.36,16.36,0,0,1,9-2.76A.84.84,0,0,0,15,4.72V2.38Z"/>
        </g>
      </svg>
    );
  }
}
RedoIcon.defaultProps = {
  color: "white",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default RedoIcon