const [PROJECT, TEAM, USER] = ["project", "team", "user"];

const PERFORMANCE_FILTERS = [PROJECT, TEAM, USER];

const [ALL, BUGS, TEST_STEPS] = ["all", "bugs", "test_steps"];

const BUG_BREAKDOWN_SELECT = [ALL, BUGS, TEST_STEPS];

const [USER_ID, PROJECT_ID, TEAM_ID, START_DATE, END_DATE] = [
  "id",
  "project_id",
  "team_id",
  "start_date",
  "end_date",
];

const FILTER_PARAMS = [USER_ID, PROJECT_ID, TEAM_ID, START_DATE, END_DATE];

const [THIS_MONTH, LAST_MONTH, THIS_YEAR, ALL_TIME, CUSTOM] = [
  "this_month",
  "last_month",
  "this_year",
  "all_time",
  "custom",
];

const DATERANGE_PRESETS = [THIS_MONTH, LAST_MONTH, THIS_YEAR, ALL_TIME, CUSTOM];

const [WEB_TESTING, MOBILE_APP_TESTING, TEST_AUTOMATION, ACCESSIBILITY, PROJECT_LEAD, TEAM_MANAGER] = ["web_testing", "mobile_app_testing", "test_automation", "accessibility", "project_lead", "team_manager"]

const ASSESSMENT_PERFORMANCE_CATEGORIES = [WEB_TESTING, MOBILE_APP_TESTING, TEST_AUTOMATION, ACCESSIBILITY, PROJECT_LEAD, TEAM_MANAGER];

export {
  PERFORMANCE_FILTERS,
  PROJECT,
  TEAM,
  USER,
  BUG_BREAKDOWN_SELECT,
  ALL,
  BUGS,
  TEST_STEPS,
  FILTER_PARAMS,
  USER_ID,
  PROJECT_ID,
  TEAM_ID,
  START_DATE,
  END_DATE,
  DATERANGE_PRESETS,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  ALL_TIME,
  CUSTOM,
  ASSESSMENT_PERFORMANCE_CATEGORIES,
  WEB_TESTING,
  MOBILE_APP_TESTING,
  TEST_AUTOMATION,
  ACCESSIBILITY,
  PROJECT_LEAD,
  TEAM_MANAGER
};
