import React from "react";
import styled from "styled-components";
import Icons from "./index";

const IconsDemo = ({ color, size }) => {
  // for quickly viewing all available icons, don't actually use this component

  if (process.env.NODE_ENV !== "development") {
    console.warn("IconsDemo should only be used in development mode");
    return null;
  }

  return (
    <IconsDemoContent>
      {Object.entries(Icons).map(([key, Icon]) => {
        if (typeof Icon !== "function") {
          console.log("found invalid react component", key, Icon);
          return null;
        }
        return (
          <IconContainer key={key}>
            <Icon height={size} width={size} color={color} />
            <div>{key}</div>
          </IconContainer>
        );
      })}
    </IconsDemoContent>
  );
};

const IconsDemoContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  padding: 24px;
  border: 1px solid #ededed;
  border-radius: 5px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 2px;
  font-size: 10px;
  color: #000;
  margin: 0 4px;
`;

IconsDemo.defaultProps = {
  color: "#000",
  size: "16px",
};

export default IconsDemo;
