import React from 'react'
import BasicSelect from '../common/basic_select.jsx'
import axios from 'axios'
import Sleep from '../common/sleep'
import styled from 'styled-components'

const FirstBuildContainer = styled.div `
  #step-dot-container > div > div:not(#build-flow-check) {
    width: 8px;
    height: 8px;
    border-radius:50%;
    background-color: #519acc;
    transition:background-color 0.4s;
    margin: 7px;
  }
  .build-slide {
    > h3 {
      margin-bottom: 16px;
      font-size: 14pt;
    }
  }
`

class FirstBuildFlow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentFlowSlide: 1,
      availability: {value:"24_hours", label:"24 hours"},
      buildFile: null 
    }
  }

  componentDidMount(){
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }

  nextSlide = () => {
    this.setState({currentFlowSlide: this.state.currentFlowSlide + 1},()=>{
      if(this.state.currentFlowSlide === 3){
        document.getElementById("build-slide-4").style.visibility = "hidden"
        setTimeout(()=>{
          document.getElementById("first-build-container").style.overflow = "visible"
        }, 400)
      } else {
        document.getElementById("first-build-container").style.overflow = "hidden"
        document.getElementById("build-slide-4").style.visibility = "visible"
      }
    })
  }

  backSlide = () => {
    this.setState({currentFlowSlide: this.state.currentFlowSlide - 1})
  }

  onAvailabilityChange = (option) => {
    this.setState({availability: option})
  }

  onFileChange = () => {
    document.activeElement.blur()
    var file = document.getElementById("build-file").files[0];
    if (file) {
      if (file.size/1024/1024 >= 200) {
        M.toast({html: 'Build exceeds file limit!', classes: "red darken-3 error-toast"})
        this.setState({buildFile: null});
      } else {
        this.setState({buildFile: file});
      }
    } else {
      this.setState({buildFile: null});
    }
  }

  removeFile = () => {
    this.setState({buildFile: null})
  }

  uploadBuild = () => {
    document.getElementById("next-button").style.opacity = "0"
    document.getElementById("first-build-spinner").style.display = "block"
    setTimeout(()=>{
      document.getElementById("first-build-spinner").style.opacity = "1"
      setTimeout(() => {
        var formData = new FormData();
        formData.append('file', this.state.buildFile, this.state.buildFile.name);
        formData.append('passcode', this.refs.build_password.value.trim());
        formData.append('availability', this.state.availability.value);
        formData.append('note', this.refs.build_new_text.value.trim());
        if (this.props.application_id) {
          formData.append('application_id', this.props.application_id);
          formData.append('app_id', this.props.application_id);
        }
        if (this.props.project_id) {
          formData.append('project_id', this.props.project_id);
        }
        var self = this;
        axios.post('/v1/builds', formData, 
          {onUploadProgress:(progressEvent) => {
            
          }})
        .then((res) => {
          this.props.downloadBuilds()
          this.props.flowCompleted()
          document.getElementById("first-build-spinner").style.opacity = "0"
          setTimeout(()=>{
            document.getElementById("first-build-spinner").style.display = "none"
            this.nextSlide()
          }, 300)
        })
        .catch((err) => {
          M.toast({html: 'Error uploading build', classes: "red darken-3 error-toast"})
        })
      }, 300);
    }, 300)
  }

  openBuildsHelp = async () => {
    document.getElementById('help-link').click()
    await Sleep(300)
    document.querySelector("#help-container > div > ul > li:nth-child(9) > div.collapsible-header").click()
    await Sleep(300)
    document.getElementById("builds-help").scrollIntoView({behavior: "smooth", block: "start"});
  }

  render() {
    var currentSlide = this.state.currentFlowSlide
    var height = currentSlide === 1 ? "255px" : currentSlide === 2 ? this.state.buildFile ? "220px" : "320px" : currentSlide === 3 ? "318px" : currentSlide === 4 ? "480px" : "220px"
    return (
      <FirstBuildContainer id="first-build-container" style={{maxWidth:"920px", marginLeft:"10px", height: height, transition:"height 0.4s", borderBottom:"solid 1px lightgray", overflow:"hidden"}}>
      <div id="slide-banner" style={{position:"relative", height: currentSlide !== 1 && currentSlide !== 5 ? "65px" : "80px", transition:"height 0.4s", transitionDelay:"0.4s"}}>
        <img style={{height:"60px", width:"60px", display:"block", marginBottom:"20px", opacity:currentSlide === 1 ? "1" : "0", transition: "opacity 0.4s"}} src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Builds%20-%20Filled.svg"></img>
        <img id="upload-complete-image" style={{ opacity:currentSlide === 5 ? "1" : "0"}} src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Checkmark.svg"></img>
        <div 
          id="step-dot-container" 
          style={{
            width:"60%", 
            minWidth:"400px", 
            position:"absolute", 
            left:"0px",
            top:"20px",
            transition:"opacity 0.4s",
            transitionDelay:"0.4s",
            opacity: currentSlide !== 1 && currentSlide !== 5 ? "1" : "0",
            pointerEvents: currentSlide !== 1 && currentSlide !== 5 ? "all" : "none"
            }}>
              <span style={{display:"inline-block", color:"#519acc", fontWeight:"600", fontSize:"12pt"}}>Step {currentSlide === 5 ? "3" : currentSlide - 1}</span>
              <div style={{display:"inline-flex", height:"40px", justifyContent:"center", alignItems:"center", marginLeft:"20px"}}>
                <div></div>
                <div style={{backgroundColor: currentSlide >= 3 ? "#519acc" : "#dddddd"}}></div>
                <div style={{backgroundColor: currentSlide >= 4 ? "#519acc" : "#dddddd"}}></div>
                <div id="build-flow-check" className={currentSlide === 5 ? "build-flow-check-active" : ""}></div>
              </div>
            <span
              id="next-button" 
              onClick={currentSlide === 4 ? this.uploadBuild : this.nextSlide} 
              style={{
                transition:"opacity 0.4s", 
                pointerEvents: currentSlide === 5 ? "none" || this.state.buildFile === null : "all", 
                opacity: currentSlide === 5 || this.state.buildFile === null ? "0" : "1", 
                display:"block", 
                cursor:"pointer", 
                position: "absolute", 
                right: "5px", 
                top:"10px", 
                color:"#519acc", 
                fontWeight:"600"
              }}>{currentSlide === 4 || currentSlide === 5 ? "Finish" : "Next Step"}</span>
            <div
              id="first-build-spinner" 
              style={{
                display:"none", 
                position: "absolute", 
                right: "15px", 
                top:"10px",  
                opacity:"0", 
                transition:"opacity 0.3s"
                }} 
              />
        </div>
      </div>
      <div style={{transform: `translateX(calc(-${(currentSlide-1)*100}%))`, transition: "height 0.4s, transform 0.4s", whiteSpace:"nowrap"}} id="slide-container">
        <div className="build-slide" id="build-slide-1" style={{display:"inline-block", width:"100%", whiteSpace:"normal"}}>
          <h3>Upload your first build</h3>
          <p>Welcome to the <span onClick={this.openBuildsHelp} style={{color:"#519acc", fontWeight:"600", cursor:"pointer"}}>Builds</span> screen. Here you are able to upload iOS and Android builds and distribute them to testers via a simple install link.</p>
          <p>Click the link below to get started.</p>
          <p role="link" onClick={this.nextSlide} style={{width:"80px", paddingBottom:"4px", color:"#519acc", fontWeight:"600", cursor:"pointer", marginBottom:"5px"}}>Get Started</p>
        </div>
        <div className="build-slide" id="build-slide-2" style={{display:"inline-block", width:"100%", verticalAlign:"top", whiteSpace:"normal"}}>
          <h3>Upload your build file</h3>
          <p style={{marginBottom:"10px", fontSize:"11pt", width:"60%", minWidth:"400px"}}>Simply either drag and drop a build or select a file from your device to upload.</p>

            {this.state.buildFile ? 
              <span style={{display:"flex", marginTop:"20px", alignItems:"center", width:"fit-content", maxWidth:"400px", whiteSpace:"nowrap", flexWrap:"nowrap", height:"30px", padding:"8px 0px 8px 8px", fontSize:"10pt", backgroundColor:"#e0e0e0"}}>
                <span style={{display:"inline-block", maxWidth:"290px", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>{this.state.buildFile.name}</span>
                <span style={{whiteSpace:"pre"}}>{"   - "}</span><span style={{fontStyle:"italic"}}>{(this.state.buildFile.size / 1000000).toFixed(1)} MB</span>
                <p onClick={this.removeFile} id="first-build-remove" style={{width:"26px", marginLeft:"4px", cursor:"pointer", textAlign:"center"}}>x</p>
              </span>
            : 
              <div style={{display:"flex", position:"absolute", top:"75px", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"160px", width:"145px", marginBottom:"20px"}}>
                <div className=" truncate" id="filepond-container">
                  <div className="input-field">
                    <div className="">
                      <input title="" className="color first-upload-input" style={{height:"125px", opacity:"0"}} id="build-file" type="file" accept=".ipa,.apk,.app,.png" ref="file" onChange={this.onFileChange} multiple />
                    </div>
                    <div id="file-wrapper" style={{paddingRight:"4px"}}>
                      <img style={{display:"block", height:"45px", width:"45px", marginLeft:"auto", marginRight:"auto"}} src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Upload_gray.svg" alt=""/>
                      <p style={{textAlign:"center", paddingBottom:"4px", fontSize:"11pt", marginBottom:"5px", fontWeight:"600"}}>
                        Drop files to upload <br/> or <span style={{color:"#519acc", cursor:"pointer"}}>browse</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              }
            
          
        </div>
        <div className="build-slide" id="build-slide-3" style={{display:"inline-block", width:"100%", verticalAlign:"top", whiteSpace:"normal", position:"relative"}}>
          <h3>Select how long your build will be available</h3>
          <p style={{marginBottom:"10px", width:"60%", minWidth:"400px"}}>Now that you&apos;ve uploaded a build, it&apos;s time to provide more details.</p>
          <p style={{marginBottom:"10px", width:"60%", minWidth:"400px"}}>For each build uploaded, select an <span style={{fontWeight:"600"}}>availability</span> - how long you would like the build to be available to test with before it expires.</p>
          <BasicSelect
            options={[
              {label: "10 minutes", value: "10_minutes"},
              {label: "1 hour", value: "1_hour"},
              {label: "3 hours", value: "3_hours"},
              {label: "6 hours", value: "6_hours"},
              {label: "12 hours", value: "12_hours"},
              {label: "24 hours", value: "24_hours"},
              {label: "1 week", value: "1_week"},
              {label: "2 weeks", value: "2_weeks"}
            ]}
            onChange={this.onAvailabilityChange} 
            selectedOption={this.state.availability}
            id="1"
            width="150px"
            margin="29px 30px"
            position="absolute"
            top="140px"
            left="20px"
            maxHeight="145px"
            // // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
          />
        </div>
        <div className="build-slide" id="build-slide-4" style={{display:"inline-block", width:"100%", verticalAlign:"top", whiteSpace:"normal"}}>
          <h3>Finishing touches</h3>
          <p style={{marginBottom:"10px", width:"60%", minWidth:"400px"}}>For each uploaded, build you have the option to describe <span style={{fontWeight:"600"}}>what is new</span> and <span style={{fontWeight:"600"}}>password-protect</span>.</p>
          <p style={{fontWeight:'600', marginTop:"10px"}}>Password</p>
          <input ref="build_password" type="password" placeholder="Optional" autoComplete="new-password" className="common-input" maxLength="25" style={{width:"300px"}} />
          <p style={{fontWeight:'600', marginTop:"10px"}}>What&apos;s new with this build?</p>
          <textarea placeholder="Describe what changes are present in this build." className="common-textarea" maxLength="2000" ref="build_new_text" style={{width:"300px"}}/>
        </div>
        <div className="build-slide" id="build-slide-5" style={{display:"inline-block", width:"100%", verticalAlign:"top", whiteSpace:"normal"}}>
          <h3>Upload complete!</h3>
          <p>The build was uploaded successfully.</p>
          <p>To share the build with a tester, simply copy the <span style={{color:"#519acc", fontWeight:"600"}}>install link</span> located next to the build in the table below.</p>
        </div>
      </div>
    </FirstBuildContainer>
    )
  }

}
export default FirstBuildFlow
