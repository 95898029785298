import React from "react";

class DownloadIcon extends React.Component {
  render() {
    return (
      <svg
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        style={this.props.svgStyles}
        id="Layer_1" 
        data-name="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 64 64"
      >
        <path d="M23.81,38.93l7.85,7.85a1.56,1.56,0,0,0,2.22,0l7.85-7.85a1.57,1.57,0,0,0-1.11-2.68,1.6,1.6,0,0,0-1.11.46l-5.17,5.17V29.44a1.57,1.57,0,0,0-3.14,0V41.88L26,36.71a1.58,1.58,0,0,0-1.11-.46,1.6,1.6,0,0,0-1.11.46A1.58,1.58,0,0,0,23.81,38.93Z"/>
        <path d="M32.77,47.49A1.81,1.81,0,0,1,31.49,47l-7.85-7.85a1.78,1.78,0,0,1-.54-1.29,1.81,1.81,0,0,1,.53-1.28,1.87,1.87,0,0,1,2.58,0L31,41.28V29.44a1.82,1.82,0,0,1,3.64,0V41.28l4.75-4.74a1.85,1.85,0,0,1,2.57,0,1.79,1.79,0,0,1,.53,1.28,1.75,1.75,0,0,1-.53,1.29L34.06,47A1.81,1.81,0,0,1,32.77,47.49Zm-7.85-11a1.29,1.29,0,0,0-.93.39,1.31,1.31,0,0,0-.39.93,1.29,1.29,0,0,0,.39.93h0l7.85,7.86a1.35,1.35,0,0,0,1.87,0l7.85-7.86a1.26,1.26,0,0,0,.38-.93,1.3,1.3,0,0,0-.38-.93,1.35,1.35,0,0,0-1.87,0l-5.6,5.6v-13a1.32,1.32,0,0,0-2.64,0V42.49l-5.6-5.6A1.29,1.29,0,0,0,24.92,36.5Z"/>
        <path d="M50,25.36a17.27,17.27,0,1,0-34.31,3.86l-.56,0a11.39,11.39,0,0,0,0,22.77h9.82a1.57,1.57,0,0,0,0-3.14H15.11a8.25,8.25,0,0,1,0-16.49,8.15,8.15,0,0,1,2.16.3,1.57,1.57,0,0,0,1.92-1.95A14.12,14.12,0,1,1,46.9,26.84a1.57,1.57,0,0,0,1.57,1.57,10.21,10.21,0,1,1,0,20.42H40.62a1.57,1.57,0,1,0,0,3.13h7.85A13.34,13.34,0,0,0,50,25.36Z"/>
        <path d="M24.93,52.22H15.11a11.64,11.64,0,0,1,0-23.27h.28a16.58,16.58,0,0,1-.14-2.1,17.52,17.52,0,0,1,35-1.71,13.6,13.6,0,0,1-1.74,27.08H40.62a1.82,1.82,0,1,1,0-3.64h7.85a10,10,0,1,0,0-19.92,1.82,1.82,0,0,1-1.82-1.82,13.88,13.88,0,1,0-27.76,0,13.65,13.65,0,0,0,.54,3.78,1.83,1.83,0,0,1-2.23,2.26,8.09,8.09,0,0,0-2.09-.29,8,8,0,0,0,0,16h9.82a1.82,1.82,0,0,1,0,3.64ZM15.11,29.45a11.14,11.14,0,0,0,0,22.27h9.82a1.32,1.32,0,0,0,0-2.64H15.11a8.5,8.5,0,0,1,0-17,8.2,8.2,0,0,1,2.22.31A1.32,1.32,0,0,0,19,30.76a14.37,14.37,0,1,1,28.2-3.92,1.33,1.33,0,0,0,1.32,1.32,10.46,10.46,0,1,1,0,20.92H40.62a1.32,1.32,0,1,0,0,2.64h7.85A13.1,13.1,0,0,0,50,25.61l-.2,0,0-.21a17,17,0,0,0-34,1.47,16.82,16.82,0,0,0,.17,2.33l0,.3h-.3Z"/>
      </svg>
    );
  }
}

DownloadIcon.defaultProps = {
  height: "25px",
  width: "25px",
  svgStyles: {},
  color: "#519acc"
}

export default DownloadIcon