import React from "react";

const HelpIcon = (props) => {
  return (
    <svg
      id="help_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 35 35"
      {...props}
    >
      <path
        opacity="0.3"
        d="M15.5 32.838C24.0604 32.838 31 25.7288 31 16.9591C31 8.18942 24.0604 1.0802 15.5 1.0802C6.93959 1.0802 0 8.18942 0 16.9591C0 25.7288 6.93959 32.838 15.5 32.838Z"
        fill="#444344"
      />
      <path
        d="M15.5 31.7578C24.0604 31.7578 31 24.6486 31 15.8789C31 7.10922 24.0604 0 15.5 0C6.93959 0 0 7.10922 0 15.8789C0 24.6486 6.93959 31.7578 15.5 31.7578Z"
        fill="#519ACC"
      />
      <path
        opacity="0.2"
        d="M15.5 0.108032C6.95918 0.108032 0 7.12931 0 15.7709C0 15.9869 0 16.203 0 16.419C0.263605 7.93946 7.11735 1.18823 15.5 1.18823C23.7772 1.18823 30.631 7.83144 30.9473 16.203C30.9473 16.0409 30.9473 15.8789 30.9473 15.7169C31 7.12931 24.0408 0.108032 15.5 0.108032Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M15.5 27.383C21.1487 27.383 25.7279 22.6919 25.7279 16.9051C25.7279 11.1183 21.1487 6.42719 15.5 6.42719C9.85128 6.42719 5.27209 11.1183 5.27209 16.9051C5.27209 22.6919 9.85128 27.383 15.5 27.383Z"
        fill="black"
      />
      <path
        d="M15.5 26.5188C21.1487 26.5188 25.7279 21.8277 25.7279 16.0409C25.7279 10.2541 21.1487 5.56302 15.5 5.56302C9.85128 5.56302 5.27209 10.2541 5.27209 16.0409C5.27209 21.8277 9.85128 26.5188 15.5 26.5188Z"
        fill="white"
      />
      <path
        d="M15.4473 8.85764C14.182 8.74962 12.7586 9.39773 11.8623 9.88382L12.3368 11.018C13.0749 10.4779 13.9184 10.2079 14.8147 10.1539C16.6599 10.2079 17.7143 11.072 17.7143 12.5843C17.7143 13.7725 16.9762 14.7987 15.7109 16.095C14.4456 17.3912 13.9711 18.6874 14.1293 19.9296L14.182 20.2537H15.6055V19.9837C15.5001 18.6334 16.0273 17.4992 17.2399 16.203C18.5579 14.7987 19.0851 13.7185 19.0851 12.2603C19.1378 10.2079 17.9252 9.07367 15.4473 8.85764Z"
        fill="#519ACC"
      />
      <path
        d="M15.8691 20.4697H14.0238V19.9836C13.8657 18.6334 14.3929 17.2832 15.6582 15.9869C16.9235 14.6907 17.6089 13.7725 17.6089 12.5843C17.6089 11.2341 16.6071 10.4239 14.9201 10.3699C14.0765 10.4239 13.233 10.694 12.5476 11.18L12.284 11.3421L11.6514 9.8298L11.8095 9.72178C12.8112 9.12767 14.2874 8.58757 15.5 8.64158C18.0306 8.85762 19.4014 10.0998 19.4014 12.3143C19.4014 13.8265 18.8742 14.9607 17.5034 16.419C16.2908 17.6612 15.8163 18.7414 15.9218 20.0377L15.8691 20.4697ZM14.4456 20.0377H15.4473V19.9836C15.3418 18.5794 15.8691 17.3912 17.1344 16.0409C18.3997 14.6907 18.9269 13.6105 18.9269 12.2602C18.9269 10.2619 17.767 9.23569 15.4473 9.07366C14.2874 9.07366 13.1803 9.39772 12.1786 9.99183L12.4949 10.694C13.233 10.2079 14.0238 9.93782 14.9201 9.88381C16.8708 9.93782 18.0306 10.91 18.0306 12.5303C18.0306 13.8265 17.2925 14.8527 15.9745 16.203C14.8146 17.4452 14.2874 18.6874 14.4456 19.8756V20.0377Z"
        fill="#519ACC"
      />
      <path
        d="M15.131 24.1964C15.7133 24.1964 16.1854 23.7128 16.1854 23.1162C16.1854 22.5196 15.7133 22.036 15.131 22.036C14.5486 22.036 14.0765 22.5196 14.0765 23.1162C14.0765 23.7128 14.5486 24.1964 15.131 24.1964Z"
        fill="#519ACC"
      />
      <path
        d="M15.1309 24.3044C14.4455 24.3044 13.9183 23.7643 13.9183 23.0622C13.9183 22.3601 14.4455 21.82 15.1309 21.82C15.8163 21.82 16.3435 22.3601 16.3435 23.0622C16.3435 23.7643 15.8163 24.3044 15.1309 24.3044ZM15.1309 22.144C14.6037 22.144 14.2347 22.5761 14.2347 23.0622C14.2347 23.6023 14.6564 23.9804 15.1309 23.9804C15.6581 23.9804 16.0272 23.5483 16.0272 23.0622C16.0799 22.5761 15.6581 22.144 15.1309 22.144Z"
        fill="#519ACC"
      />
    </svg>
  );
};

export default HelpIcon;
