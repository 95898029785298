import React from "react";
import PropTypes from "prop-types";

const BookIcon = ({ active, ...props }) => {
  const pathClassName = active ? "active-path-fill" : "BookIcon_svg__cls-1";
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        d="M28.8906 20.4055C29 20.2955 29 20.1305 29 20.0205V2.20046C29 1.92546 28.7811 1.65046 28.4528 1.48546C21.9415 -1.26454 17.4 0.330464 14.7736 2.14546L14.5 2.31046L14.2264 2.14546C11.5453 0.330464 7.05849 -1.26454 0.54717 1.48546C0.218868 1.65046 0 1.87046 0 2.20046V20.0205C0 20.4605 0.437736 20.7905 1.03962 20.7905C1.20377 20.7905 1.36792 20.7355 1.53208 20.6805C3.5566 19.8005 5.52641 19.3605 7.27736 19.3605C9.68491 19.3605 11.8736 20.1855 13.7887 21.7805C13.8434 21.8355 13.9528 21.8905 14.0623 21.9455C14.3358 22.0555 14.6094 22.0555 14.883 21.9455C14.9925 21.8905 15.1019 21.8355 15.2113 21.7805C18.4943 18.9755 22.5981 18.5905 27.4679 20.6805C27.9604 20.9555 28.617 20.7905 28.8906 20.4055ZM13.4604 19.5255L12.7491 19.1955C11.0528 18.3155 9.13774 17.9305 7.16792 17.8755C5.63585 17.8755 4.10377 18.0955 2.62642 18.5355L2.02453 18.7005V2.64046L2.35283 2.53046C6.62075 0.935464 10.2868 1.32046 13.2962 3.57546L13.4604 3.74046V19.5255ZM26.9755 18.7555L26.3189 18.5355C23.0358 17.4355 19.2604 17.6555 16.1962 19.1405L15.4849 19.4705V3.74046L15.6491 3.57546C18.6585 1.26546 22.3792 0.935464 26.5925 2.53046L26.9208 2.64046V18.7555H26.9755Z"
        className={pathClassName}
      />
    </svg>
  );
};

BookIcon.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default BookIcon;
