import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LineElement,
  LinearScale,
  Tooltip,
  PointElement,
  Filler,
} from "chart.js";
import styled from "styled-components";
import chartPlugins, { labelFormatter } from "./chartPlugins";
import LoadingSpinner from "../../common/loading_spinner";
import EmptyHours from "../../icons/performance_bio/EmptyHours";
import {
  ChartInnerContainer,
  LoadingContainer,
} from "./BugsStepsDualBreakdown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Filler
);

const [HOURS] = ["hours"];

const HoursBreakdown = (props) => {
  const { data, loading, parentFilterValue } = props;

  const datasets = useMemo(() => {
    const hours = {
      label: "Hours",
      backgroundColor: "#00B14733",
      borderColor: "#00B147",
      data: data.map((item) => item.hours),
      yAxisID: HOURS,
      pointStyle: "circle",
      pointBackgroundColor: "#00B147",
      pointRadius: 8,
      pointHoverRadius: 10,
      pointBorderColor: "#FFFFFF",
      pointBorderWidth: 4,
      fill: true,
    };

    return {
      labels: data.map((item) => item.label),
      datasets: [hours],
    };
  }, [data]);

  const chartOptions = useMemo(() => {
    const xAxis = {
      offset: true,
      linear: true,
      grid: { drawTicks: false, drawOnChartArea: false },
      ticks: {
        source: "auto",
        autoSkip: true,
        padding: 8,
        font: {
          color: "#242424",
          size: 14,
          family: "Arial",
          weight: 400,
        },
        callback: labelFormatter,
      },
    };

    const yAxis = {
      id: HOURS,
      position: "left",
      scalePositionLeft: true,
      stacked: true,
      grid: { drawTicks: false },
      border: {
        width: 3,
        color: "#00B147",
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 6,
        color: "#00B147",
        padding: 14,
        font: {
          size: 14,
          family: "Arial",
          weight: 700,
        },
      },
    };

    const axes = {
      x: xAxis,
      [HOURS]: yAxis,
    };

    return {
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 1,
      categoryPercentage: 0.5,
      interaction: {
        intersect: true,
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            padding: 10,
            usePointStyle: true,
            font: {
              size: 14,
              family: "Arial",
              weight: 400,
            },
          },
        },
      },
      scales: {
        ...axes,
      },
    };
  }, []);

  return (
    <HoursBreakdownContainer>
      {!loading && data.length === 0 ? (
        <NoDataContainer>
          <EmptyHours />
          <NoDataText>No EOD Report Data Found</NoDataText>
          <Description>
            Please verify that there are valid EOD Report submissions for the
            selected {parentFilterValue}, select a different time range, or
            choose another filter.
          </Description>
        </NoDataContainer>
      ) : (
        <ChartContainer>
          {loading && (
            <LoadingContainer>
              <LoadingSpinner loading={loading} size={48} />
            </LoadingContainer>
          )}
          <ChartInnerContainer $loading={loading}>
            <Line
              datasetIdKey={HOURS}
              data={datasets}
              options={chartOptions}
              plugins={chartPlugins}
            />
          </ChartInnerContainer>
        </ChartContainer>
      )}
    </HoursBreakdownContainer>
  );
};

const HoursBreakdownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  height: 400px;
  display: flex;
  justify-content: center;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  height: 294px;
  margin-top: 32px;
  border-left: 2px solid #00b147;
`;

const NoDataText = styled.p`
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 13px 0px;
`;

const Description = styled.p`
  color: #000;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

HoursBreakdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      hours: PropTypes.number,
    })
  ),
  loading: PropTypes.bool,
  parentFilterValue: PropTypes.string.isRequired,
};

HoursBreakdown.defaultProps = {
  data: [
    // dummy data, purely for the initial loading state.
    {
      label: "2023/05/01",
      count: 56,
      hours: 409.17,
    },
    {
      label: "2023/05/02",
      count: 66,
      hours: 480.42,
    },
    {
      label: "2023/05/03",
      count: 64,
      hours: 452.75,
    },
    {
      label: "2023/05/04",
      count: 58,
      hours: 417.5,
    },
    {
      label: "2023/05/05",
      count: 58,
      hours: 402,
    },
    {
      label: "2023/05/08",
      count: 53,
      hours: 408,
    },
    {
      label: "2023/05/09",
      count: 61,
      hours: 416.75,
    },
    {
      label: "2023/05/10",
      count: 60,
      hours: 424.25,
    },
    {
      label: "2023/05/11",
      count: 68,
      hours: 458.5,
    },
    {
      label: "2023/05/12",
      count: 60,
      hours: 393,
    },
  ],
  loading: false,
};

export default HoursBreakdown;
