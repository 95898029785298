import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import ReactModalV2 from "../modals/react_modal_v2";
import PlusIcon from "../icons/plus_icon";
import useModalControl from "../../hooks/useModalControl";
import AddWikiResourceForm from "./AddWikiResourceForm";

const AddWikiResourceModal = () => {
  const { isOpen, toggle } = useModalControl(false);

  return (
    <>
      <AddNewResourceButton
        onClick={toggle}
        id="add-new-wiki-resource"
        aria-label="Add new wiki resource"
      >
        <IconContainer>
          <PlusIcon height="16px" width="16px" />
        </IconContainer>
      </AddNewResourceButton>
      <ReactModalV2
        id="add-wiki-resource-modal"
        isShowing={isOpen}
        data={{ customClass: "add-wiki-resource-modal" }}
        page={() => <AddWikiResourceForm toggleModalVisible={toggle} />}
        modalAction={toggle}
      />
    </>
  );
};

export const AddNewResourceButton = styled.button`
  border: 1px dashed rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  cursor: pointer;
  background-color: #d8d8d8;
  width: 100%;
  height: 67px;
  margin-top: 14px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  &:focus {
    background-color: #d8d8d8;
  }

  &:hover {
    background-color: ${darken(0.1, "#d8d8d8")};
  }
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #519acc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default AddWikiResourceModal;
