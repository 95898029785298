import React from "react";
import PropTypes from "prop-types";

const BugsOutline = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      style={svgStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 23"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31295 3.96982C4.51693 5.11281 4.03641 6.539 4.03641 7.66683H2.875C2.875 6.27422 3.44886 4.62975 4.35255 3.33217C5.24575 2.04966 6.57089 0.958496 8.14583 0.958496C9.72078 0.958496 11.0459 2.04966 11.9391 3.33217C12.8428 4.62975 13.4167 6.27422 13.4167 7.66683H12.2553C12.2553 6.539 11.7747 5.11281 10.9787 3.96982C10.1722 2.81177 9.15227 2.09248 8.14583 2.09248C7.13939 2.09248 6.11947 2.81177 5.31295 3.96982Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1363 0.0811218C3.39592 -0.0756032 3.73728 0.00162373 3.89876 0.253613L5.66642 3.01217C5.82789 3.26416 5.74833 3.59549 5.4887 3.75221C5.22908 3.90894 4.88772 3.83171 4.72624 3.57972L2.95858 0.821164C2.79711 0.569175 2.87667 0.237847 3.1363 0.0811218Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1345 0.0886258C14.3809 0.251837 14.4492 0.58506 14.2869 0.8329L12.4805 3.59147C12.3183 3.83931 11.9869 3.90792 11.7405 3.74471C11.4941 3.5815 11.4258 3.24827 11.5881 3.00043L13.3945 0.241859C13.5567 -0.00598106 13.8881 -0.0745857 14.1345 0.0886258Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.625 6.92256C4.50913 6.92256 1.17256 10.2591 1.17256 14.375C1.17256 18.4909 4.50913 21.8274 8.625 21.8274C12.7409 21.8274 16.0774 18.4909 16.0774 14.375C16.0774 10.2591 12.7409 6.92256 8.625 6.92256ZM0 14.375C0 9.61154 3.86154 5.75 8.625 5.75C13.3885 5.75 17.25 9.61154 17.25 14.375C17.25 19.1385 13.3885 23 8.625 23C3.86154 23 0 19.1385 0 14.375Z"
        fill={color}
      />
    </svg>
  );
};

BugsOutline.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
};

BugsOutline.defaultProps = {
  height: 18,
  width: 23,
  svgStyles: {},
  color: "#519acc",
};

export default BugsOutline;
