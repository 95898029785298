import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import styled from "styled-components";
import { TrialsContext } from "./index";
import moment from "moment";

const TimeRemaining = () => {
  const { trial, refreshTrial } = useContext(TrialsContext);
  const { expires_at } = trial;

  const refreshAtTimeExpires = useRef(false);

  const [timeRemaining, setTimeRemaining] = useState(() => {
    if (!expires_at) return null;

    const now = new Date().getTime();
    const distance = new Date(expires_at).getTime() - now;
    refreshAtTimeExpires.current = distance > 0;
    return distance;
  });

  const timeDisplay = useMemo(() => {
    if (!expires_at) return null;

    if (timeRemaining <= 0) return [0, 0, 0];

    const hours = moment.duration(timeRemaining).hours().toString();
    const minutes = moment
      .duration(timeRemaining)
      .minutes()
      .toString()
      .padStart(2, "0");
    const seconds = moment
      .duration(timeRemaining)
      .seconds()
      .toString()
      .padStart(2, "0");

    return [hours, minutes, seconds];
  }, [timeRemaining, expires_at]);

  useEffect(() => {
    if (!expires_at) return;

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = new Date(expires_at).getTime() - now;
      setTimeRemaining(distance);
    }, 1000);

    return () => clearInterval(interval);
  }, [expires_at]);

  useEffect(() => {
    if (!expires_at) return;

    if (timeRemaining <= 0 && refreshAtTimeExpires.current) {
      refreshTrial();
      refreshAtTimeExpires.current = false;
    }
  }, [timeRemaining, expires_at, refreshTrial]);

  return (
    <TimeRemainingDisplay>
      <TimeHeading>
        <h3>Time Remaining</h3>
      </TimeHeading>
      <TimeValue>
        <p>
          {parseInt(timeDisplay[0]) > 0 && <span>{timeDisplay[0] + ":"}</span>}
          <span>
            {parseInt(timeDisplay[1]) > 0 ? timeDisplay[1] + ":" : "00:"}
          </span>
          <span>
            {parseInt(timeDisplay[2]) > 0
              ? timeDisplay[2].padStart(2, "0")
              : "00"}
          </span>
        </p>
      </TimeValue>
    </TimeRemainingDisplay>
  );
};

export const TimeRemainingDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 36px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
`;

export const TimeHeading = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 12px;

  h3 {
    margin: 0;
    padding: 0;
    line-height: 50%;
    font-size: 18px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    text-align: center;
  }
`;

export const TimeValue = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  p {
    margin: 0;
    padding: 0;
    line-height: 50%;
    font-size: 14px;
  }

  span {
    font-size: 16px;
    line-height: 33px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
`;

export default TimeRemaining;
