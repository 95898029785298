import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import rootReducer from "./slices/rootReducer";
import testPlatformApi from "./slices/api";

const store = configureStore({
  reducer: rootReducer,
  middleware: (gDM) => {
    return gDM().concat(testPlatformApi.middleware);
  },
});

setupListeners(store.dispatch);

export default store;
