import React, { useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import ApiClient from "../../../../utils/ApiClient";
import { EditAssessmentContext } from "../EditAssessment";
import HeaderControls from "./HeaderControls";
import IntroEditor from "./IntroEditor";
import AddAssessmentQuestion from "./AddAssessmentQuestion";
import QuestionsController from "./QuestionsController";
import formSchema from "./formSchema";

const AssessmentEditor = () => {
  const { assessment, setAssessment, QUESTION_TYPES } = useContext(
    EditAssessmentContext
  );
  const WRITTEN = QUESTION_TYPES[1];

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      introduction: assessment.introduction || "",
      questions: assessment.questions || [],
      delete_questions: [],
      delete_answers: [],
    },
  });

  const onError = (errors) => {
    console.error(errors);
    // eslint-disable-next-line no-undef
    M.toast({
      html: "There was an error updating assessment",
      classes: "red",
    });
  };

  const onSubmit = async (data) => {
    try {
      const body = {
        assessment: {
          introduction: data.introduction,
          publish: data.publish,
          questions_attributes: data.questions.map((question) => {
            const {
              id,
              position,
              question_type,
              description,
              options,
              keywords,
            } = question;
            const questionData = {
              position,
              question_type,
              description,
            };
            if (id) questionData.id = id;
            if (question_type === WRITTEN) {
              questionData.keywords = keywords.split(" ");
            } else {
              questionData.answers_attributes = options.map((option) => {
                const { id: optionId, answer, correct } = option;
                const optionData = { answer, correct };
                if (optionId) optionData.id = optionId;
                return optionData;
              });
            }
            return questionData;
          }),
        },
      };

      for (const q of data.delete_questions) {
        body.assessment.questions_attributes.push({
          ...q,
          _destroy: true,
        });
      }

      for (const a of data.delete_answers) {
        const questionIndex = body.assessment.questions_attributes.findIndex(
          (q) => q.id === a.question_id
        );
        if (questionIndex > -1) {
          body.assessment.questions_attributes[
            questionIndex
          ].answers_attributes.push({
            id: a.id,
            _destroy: true,
          });
        }
      }

      const api = new ApiClient();
      const response = await api.patch(
        `/wiki-assessments/${assessment.id}`,
        body
      );
      // eslint-disable-next-line no-undef
      M.toast({ html: "Assessment saved successfully", classes: "green" });
      const responseData = response.data;
      await setAssessment(responseData);
      const { introduction, questions } = responseData;
      methods.reset({
        introduction,
        questions,
        delete_questions: [],
        delete_answers: [],
      });
      return responseData;
    } catch (errors) {
      onError(errors);
      return null;
    }
  };

  return (
    <AssessmentEditorContent>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <HeaderControls postEditAssessment={onSubmit} />
          <EditorContent>
            <Editor>
              <IntroEditor />
              <QuestionsController />
              <AddAssessmentQuestion />
            </Editor>
          </EditorContent>
        </form>
      </FormProvider>
    </AssessmentEditorContent>
  );
};

const AssessmentEditorContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EditorContent = styled.div`
  position: relative;
  margin-top: 8px;
  padding: 17px 27px;
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 290px);
`;

const Editor = styled.div`
  margin: 8px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1150px;
`;

export default AssessmentEditor;
