import React from "react";

const BookIcon = ({ height, width, color, svgStyles }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyles}
    >
      <path
        d="M40.3266 2.33721V28.073L39.261 27.7357C33.7934 25.948 27.4872 26.319 22.4039 28.7307L21.251 29.2704V4.10802L21.5654 3.87191C26.6138 0.178514 32.7452 -0.394891 39.8025 2.1517L40.3266 2.33721Z"
        fill={color}
      />
      <path
        d="M19.7661 4.1085V29.2877L18.6132 28.7312C15.8008 27.3651 12.6041 26.6737 9.31998 26.6568C6.71717 26.6568 4.16677 27.0278 1.73865 27.753L0.708008 28.0566V2.33769L1.23206 2.15218C8.30681 -0.37755 14.4382 0.195855 19.4692 3.87239L19.7836 4.1085H19.7661Z"
        fill={color}
      />
    </svg>
  );
};

BookIcon.defaultProps = {
  fill: "none",
  height: "30px",
  width: "41px",
  color: "#519acc",
  svgStyles: {},
};

export default BookIcon;
