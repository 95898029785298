import React from "react";

const BugsBase = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <path
        d="M488 890.92l-229.25-134.7a98.67 98.67 0 01-48.67-85.82l2-265.86a98.66 98.66 0 0150-85.06l231.29-131.15a98.68 98.68 0 0198.66.75l229.22 134.7a98.67 98.67 0 0148.67 85.82l-2 265.86a98.66 98.66 0 01-50 85.06L586.63 891.67a98.68 98.68 0 01-98.63-.75z"
        fill="#e8e8e8"
        data-name="Layer 2"
      />{" "}
    </svg>
  );
};

export default BugsBase;
