import React, { useState, useContext } from "react";
import ReactModalV2 from "../../../modals/react_modal_v2";
import styled from "styled-components";
import AssessmentForm from "./form";
import { CancelButton } from "../../../common/modal_buttons";
import { wikiAssessmentTypes } from "../../../../types/wiki";
import { EditAssessmentContext } from "../EditAssessment";

const EditAssessmentDetailsModal = ({ assessment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { wiki_resources, setAssessment } = useContext(EditAssessmentContext);

  if (!assessment) return null;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmitSuccessCb = (newAssessmentData) => {
    setAssessment(newAssessmentData);
  };

  return (
    <>
      <OpenModalButton id="assessment-details-modal-toggle" type="button" onClick={toggleModal}>
        Edit Assessment Details
      </OpenModalButton>
      <ReactModalV2
        id="edit-assessment-details-modal"
        isShowing={isModalOpen}
        data={{ customClass: "create-new-assessment-modal" }}
        modalAction={toggleModal}
        page={() => (
          <AssessmentForm
            toggleModalVisible={toggleModal}
            assessment={assessment}
            wiki_resources={wiki_resources}
            onSubmitSuccessCb={onSubmitSuccessCb}
          />
        )}
      />
    </>
  );
};

const OpenModalButton = styled(CancelButton)`
  width: auto;
  margin-right: 0;
`;

EditAssessmentDetailsModal.propTypes = {
  assessment: wikiAssessmentTypes.isRequired,
};

export default EditAssessmentDetailsModal;
