import React from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import axios from "axios";
import ReactGA from "react-ga";
import Select, { components } from "react-select";
import TimedInput from "../common/timed_input";
import EditableTextArea from "../common/editable_text_area";
import TicketHistory from "./ticket_history";
import Comment from "./comment";
import CommentForm from "./comment_form";
import SingleSelectStyles from "../common/select_styles";
import AttachmentCarousel from "./attachment_carousel";
import Timer from "../common/timer";
import ErrorBoundary from "../common/error_boundary";
import TextDiff from "../common/text_diff";
import LinkIcon from "../icons/link_icon";
import QRCodeIcon from "../icons/qr_code_icon";
import MultiSelectWrapper from "../common/multi_select_wrapper";
import TaskSelectStyles from "../common/task_select_styles";
import TicketSelectStyles from "../common/ticket_select_styles";
import MultiSelectStyles from "../common/multi_select_styles";
import {
  dropdownIndicator,
  singleValue,
  assigneeSingleValue,
  assigneeOption,
  customMultiControl,
  customMultiValueRemove,
  customControl,
} from "../common/select_utils";

class TicketForm extends React.Component {
  constructor(props) {
    super(props);
    this.statusOptions = [];
    if (this.props.currentUser) {
      // TP-1177 order Open, In Progress, Fixed, Reopened,Won't Fix, Closed
      this.statusOptions.push(
        { value: 1, label: "Open" },
        { value: 4, label: "In Progress" },
        { value: 3, label: "Fixed" },
        { value: 6, label: "Reopened" },
        { value: 5, label: "Won't Fix" }
      );
    }
    if (this.props.currentUser.role != "client") {
      this.statusOptions.push({ value: 2, label: "Closed" });
    }
    this.priorityOptions = [
      { value: 1, label: "CRITICAL" },
      { value: 2, label: "HIGH" },
      { value: 3, label: "NORMAL" },
      { value: 4, label: "LOW" },
    ];
    this.priorityStrings = ["Critical", "High", "Normal", "Low"];
    const selectedOptionPriority =
      {
        label:
          this.priorityOptions[this.props.ticket.ticket_priority_id - 1].label,
        value: this.props.ticket.ticket_priority_id,
      } || "";
    const selectedOptionWCAG =
      this.props.ticket.a11y != undefined
        ? this.props.ticket.a11y.id != null
          ? {
              label: this.props.ticket.a11y.violation,
              value: this.props.ticket.a11y.id,
            }
          : { label: "none", value: 0 }
        : { label: "none", value: 0 };
    const selectedLabels = this.props.ticket.ticket_labels.map((label) => {
      return { label: label.name, value: label.id };
    });
    const selectedOptionStatus =
      {
        label: this.props.ticket.ticket_status_name,
        value: this.props.ticket.ticket_status_id,
      } || "";
    const groups =
      this.props.ticket.project.groups != undefined
        ? this.props.ticket.project.groups
        : [];
    const selectedOptionAssignee =
      {
        label:
          this.props.ticket.assignee_id == 0 ? (
            "Unassigned"
          ) : (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  height: "30px",
                  width: "30px",
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "4px",
                }}
              >
                <img
                  className="select-avatar-image"
                  src={`${
                    this.props.ticket.assignee_avatar_image
                      ? this.props.ticket.assignee_avatar_image
                      : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
                  }`}
                />
              </div>
              {this.props.ticket.assignee_name}
            </div>
          ),
        emailNotifications: this.props.ticket.assignee_email_notifications,
        value: this.props.ticket.assignee_id,
      } || "Unassigned";

    const watchers = this.props.ticket.watchers.sort((a, b) => {
      var a = a.user_name;
      var b = b.user_name;
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    this.state = {
      ticket: this.props.ticket,
      title: this.props.ticket.title || "",
      description: this.props.ticket.description || "",
      content: this.props.ticket.content || "",
      assignee: this.props.ticket.assignee_id || "",
      status: this.props.ticket.ticket_status_id || "",
      application: this.props.ticket.application,
      project_id: this.props.ticket.project_id || "",
      mentions: [],
      descEditable: false,
      statusUpdated: false,
      watcherMenuOpen: false,
      updateTrello: false,
      addGroup: false,
      descriptionEdit: false,
      priority: this.props.ticket.ticket_priority_id || "",
      build_version: this.props.ticket.build_version || "",
      selectedWatchers: watchers.map((elem) => ({
        label: (
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                height: "30px",
                width: "30px",
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            >
              <img className="select-avatar-image" src={elem.image} />
            </div>
            {elem.user_name}
          </div>
        ),
        value: elem.user_id,
        emailNotifications: elem.email_notifications,
        metaLabel: elem.user_name,
      })),
      selectedOptionStatus,
      selectedOptionPriority,
      selectedLabels,
      selectedOptionAssignee,
      selectedOptionProject:
        {
          label: this.props.ticket.project.name,
          value: this.props.ticket.project_id,
        } || "",
      selectedOptionDevices: this.props.ticket.devices,
      selectedOptionBrowsers: this.props.ticket.browsers,
      selectedOptionWCAG,
      comments: this.props.ticket.comments,
      files: [],
      userOptions: this.props.userOptions,
      watcherOptions: [
        { label: "Groups", options: groups },
        {
          label: "Users",
          options: this.props.watcherOptions.filter(
            (option) => option.value !== 0
          ),
        },
      ],
      violationOptions: [
        { label: "none", value: 0 },
        ...this.props.violationOptions,
      ],
      localFormUpdated: false,
      emailUpdated: false,
      emailUpdateData: {},
      groups,
      emailUpdateStartingData: {
        title: this.props.ticket.title,
        content: this.props.ticket.content
          ? this.props.ticket.content.replace("\n  ", "").replace(/\n/g, "")
          : this.props.ticket.content,
        comments: this.props.ticket.comments,
        selectedOptionPriority,
        selectedOptionStatus,
        selectedOptionWCAG,
        selectedOptionAssignee,
      },
      deviceOptions: this.props.deviceOptions,
      browserOptions: this.props.browserOptions,
    };
    this.updateTimer = null;
    this.textDiff = new TextDiff();
    this.formNode = React.createRef();
    this.description = React.createRef();
    this.title = React.createRef();
    this.watcherMenu = React.createRef();
    this.deviceSelectRef = React.createRef();
    this.browserSelectRef = React.createRef();
    this.labelSelectRef = React.createRef();
    this.handleNewImages = this.handleNewImages.bind(this);
    this.assigneeSelectRef = React.createRef();
    this.oldScrollHeight = 0;
    this.historyDeviceOptions = [].concat.apply(
      [],
      this.props.deviceOptions.map((x) => x.options)
    );
    this.deleteable =
      this.props.currentUser.role === "admin" ||
      this.props.currentUser.role === "lead";
    this.labelOption = (props) => {
      return (
        <div className="ticket-label-option" style={{ position: "relative" }}>
          <components.Option {...props} />
          {this.deleteable && (
            <i
              onClick={this.deleteTicketLabel}
              style={{ position: "absolute", right: "5px", top: "10px" }}
              data-label-id={props.value}
              className="material-icons"
            >
              delete
            </i>
          )}
        </div>
      );
    };

    this.assigneeOption = (props) => {
      return (
        <div>
          <components.Option {...props}>
            <div style={{ display: "inline-block" }}>
              {props.data.image && (
                <div
                  aria-hidden="true"
                  style={{
                    height: "30px",
                    width: "30px",
                    display: "inline-block",
                    verticalAlign: "bottom",
                    marginRight: "4px",
                  }}
                >
                  <img
                    aria-hidden="true"
                    className="select-avatar-image"
                    src={props.data.image}
                  />
                </div>
              )}
              {props.data.label}
            </div>
          </components.Option>
        </div>
      );
    };
    this.priorityOption = (props) => {
      return (
        <div>
          <components.Option {...props}>
            {props.data.label}
            {this.priorityDotOptions[props.data.value - 1]}
          </components.Option>
        </div>
      );
    };

    this.priorityColors = ["#ff0000", "#ff9900", "#0066cc", "#009900"];
  }

  handleClick = (e) => {
    const { target } = e;
    const elem = document.getElementById("root-sidebar");
    if (
      elem &&
      (elem.contains(target) ||
        (target &&
          target.classList.value &&
          target.classList.value.includes("swal2")) ||
        target.nodeName === "HTML")
    ) {
    } else if (
      (document.querySelector(".new-modal") &&
        document.querySelector(".new-modal").parentElement.contains(target)) ||
      e.target.id === "object-textarea"
    ) {
    } else if (this.state.localFormUpdated || this.state.descriptionEdit) {
      this.warnOnClose();
    } else {
      this.props.closeMethod();
    }
    // this.handleOutsideClick(e.target)
  };

  warnOnClose = () => {
    const self = this;
    Swal.fire({
      title: "Discard Changes",
      text: "Discard changes to the current Ticket?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        self.props.closeMethod();
      } else if (result.dismiss) {
      }
    });
  };

  setDescriptionEdit = (bool) => {
    this.setState({
      localFormUpdated: bool,
    });
  };

  setLocalFormUpdated = (bool) => {
    this.setState({ localFormUpdated: bool });
  };

  updateTrello = async (ticket) => {
    var ticket = ticket;
    const boards = [];
    // needs to all be on one line to not mess up trello card formatting
    const descriptionText = `**Priority**\n${
      ticket.ticket_priority_name
    }\n\n**Assignee**\n${ticket.assignee_name}\n\n**Description**\n${
      ticket.description
    }\n\n${
      ticket.devices
        ? `**Affected Devices**\n${ticket.devices
            .map((d) => d.label)
            .join(", ")}`
        : ""
    }\n\n${
      ticket.browsers
        ? `**Affected Browsers**\n${ticket.browsers
            .map((b) => b.label)
            .join(", ")}`
        : ""
    }`;
    /// ///
    Trello.put(
      `cards/${this.state.ticket.trello_card_id}`,
      {
        token: this.props.project.trello_token,
        name: `${ticket.project.identifier}-${ticket.app_key} ${ticket.title}`,
        desc: descriptionText,
      },
      (res) => {
        console.log("Ticket updated in Trello");
      },
      () => {
        Sentry.captureException(new Error("Ticket Trello Update Error"));
      }
    );
  };

  onApplicationSubmit = (formData) => {
    const self = this;
    const xhr = new XMLHttpRequest();
    const action = "PUT";
    const url = `/bugs/${this.state.ticket.id}`;
    xhr.open(action, url, true);
    xhr.upload.onprogress = (event) => {
      // event.loaded returns how many bytes are downloaded
      // event.total returns the total number of bytes
      // event.total is only available if server sends `Content-Length` header
      const complete = ((event.loaded / event.total) * 100) | 0;
      self.setState({ percentUploaded: complete });
      console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
    };
    xhr.setRequestHeader(
      "X-CSRF-Token",
      $('meta[name="csrf-token"]').attr("content")
    );

    xhr.onload = function () {
      if (xhr.status === 200) {
        if (self.state.ticket.trello_card_id && self.props.trelloConnected) {
          self.updateTrello(JSON.parse(xhr.response));
          self.props.handler(xhr.response, "update");
        }
        const ticket = JSON.parse(xhr.response);
        self.setState({
          // loading: false,
          localFormUpdated: false,
          emailUpdated: true,
          statusUpdated: false,
          descEditable: false,
          ticket,
          files: [],
        });
        // self.props.setFormUpdated(false)
        self.props.setLoadingClass("form-update-end-loading");
        self.props.setLoadingText(false);

        if (ticket.project_id !== self.props.project.id) {
          self.props.ticketDeleteHandler(ticket.id);
          self.props.closeMethod();
        }
        if (formData.get("attachments[]")) {
          ReactGA.event({
            category: "Ticket",
            action: "Ticket Updated w/ attachment/s",
          });
        } else {
          ReactGA.event({
            category: "Ticket",
            action: "Ticket Updated w/o attachment/s",
          });
        }
      } else {
        ReactGA.event({
          category: "Ticket",
          action: "Ticket Creation Error",
        });
        console.error(`Ticket Submit Backend Error: ${xhr.response}`);
        self.setState({ loading: false });
        Swal.fire(
          "Ticket Submit Error",
          "There was an error submitting this Ticket"
        );
      }
    };
    xhr.send(formData);
  };

  appendFiles = (formData, file) => {
    formData.append("attachments[]", file);
    formData.append("filenames[]", file.name);
  };

  handleSubmit = (e) => {
    let errorRelated = "";
    try {
      if (e && e !== "file-upload") {
        e.preventDefault();
      }

      if (timeout) {
        clearTimeout(timeout);
        var timeout = null;
      }

      this.props.setLoadingClass("form-update-start-loading");
      this.props.setLoadingText(true);

      const project = this.state.selectedOptionProject;
      const status = this.state.selectedOptionStatus;
      const assignee = this.state.selectedOptionAssignee;
      const priority = this.state.selectedOptionPriority.value;
      const { content } = this.state;
      const title = this.state.title.trim();
      const { files } = this.state;
      const { statusUpdated } = this.state;

      if (!project) {
        return null;
      }

      const formData = new FormData();

      if (this.state.mentions != []) {
        formData.append("ticket[mentions][]", this.state.mentions);
      }

      formData.append("ticket[project_id]", project.value);
      formData.append("ticket[ticket_status_id]", status.value);
      formData.append("ticket[assignee_id]", assignee.value);
      formData.append("ticket[title]", title === "" ? "untitled" : title);
      formData.append("ticket[ticket_priority_id]", priority);
      formData.append("ticket[content]", content);

      if (statusUpdated) {
        formData.append(
          "ticket[reopened_by_id]",
          status.value == 6 ? this.props.currentUser.id : ""
        );
      }

      if (this.props.ticket.project.a11y_active) {
        errorRelated = "Ticket Submit Error Frontend - a11y: ";
        if (this.state.selectedOptionWCAG.value === 0) {
          formData.append("ticket[a11y_id]", 0);
        } else {
          formData.append(
            "ticket[a11y_id]",
            this.state.selectedOptionWCAG.value
          );
        }
      }

      if (e === "file-upload") {
        errorRelated = "Ticket Submit Error Frontend - file upload: ";
        for (const file of files) {
          this.appendFiles(formData, file);
        }
      }

      if (this.state.selectedWatchers != this.props.ticket.watchers) {
        errorRelated = "Ticket Submit Error - watchers: ";
        if (
          this.state.ticket.watchers.length > this.state.selectedWatchers.length
        ) {
          const updatedWatchers = this.state.selectedWatchers.map(
            (w) => w.value
          );
          const oldWatchers = this.state.ticket.watchers.map(
            (ow) => ow.user_id
          );
          for (var x = 0; x < oldWatchers.length; x++) {
            if (!updatedWatchers.includes(oldWatchers[x])) {
              formData.append(
                "ticket[watchers_attributes][]",
                JSON.stringify({
                  id: this.state.ticket.watchers[x].id,
                  _destroy: true,
                })
              );
            } else {
              formData.append(
                "ticket[watchers_attributes][]",
                JSON.stringify({ user_id: oldWatchers[x] })
              );
            }
          }
        } else {
          for (var x = 0; x < this.state.selectedWatchers.length; x++) {
            formData.append(
              "ticket[watchers_attributes][]",
              JSON.stringify({ user_id: this.state.selectedWatchers[x].value })
            );
          }
        }
      }
      if (this.state.selectedLabels != this.props.ticket.ticket_labels) {
        errorRelated = "Ticket Submit Error Frontend - labels: ";
        if (this.state.selectedLabels.length === 0) {
          formData.append("ticket[ticket_label_ids][]", []);
        } else {
          for (var x = 0; x < this.state.selectedLabels.length; x++) {
            formData.append(
              "ticket[ticket_label_ids][]",
              this.state.selectedLabels[x].value
            );
          }
        }
      }
      if (this.state.selectedOptionDevices != this.props.ticket.devices) {
        errorRelated = "Ticket Submit Error Frontend - devices: ";
        if (this.state.selectedOptionDevices.length === 0) {
          formData.append("ticket[device_ids][]", []);
        } else {
          for (var x = 0; x < this.state.selectedOptionDevices.length; x++) {
            formData.append(
              "ticket[device_ids][]",
              this.state.selectedOptionDevices[x].value
            );
          }
        }
      }
      if (this.state.selectedOptionBrowsers != this.props.ticket.browsers) {
        errorRelated = "Ticket Submit Error Frontend - browsers: ";
        if (this.state.selectedOptionBrowsers.length === 0) {
          formData.append("ticket[browser_ids][]", []);
        } else {
          for (var x = 0; x < this.state.selectedOptionBrowsers.length; x++) {
            formData.append(
              "ticket[browser_ids][]",
              this.state.selectedOptionBrowsers[x].value
            );
          }
        }
      }
      this.onApplicationSubmit(formData);
    } catch (err) {
      if (this.updateTimer) {
        this.updateTimer.stopTimer();
        this.updateTimer = null;
      }
      this.props.setLoadingClass("form-update-end-loading");
      this.props.setLoadingText(false);
      err.message = errorRelated + err.message;
      console.error(err);
      Sentry.captureException(err);
      if (err.data) {
        // console.log(err.data)
      }
      Swal.fire(
        "Ticket Submit Error",
        "There was an error submitting this Ticket"
      );
    }
  };

  handleNewImages(images) {
    const { ticket } = this.state;
    ticket.ticket_attachments = images;
    this.setState({ ticket });
  }

  componentDidUpdate() {
    if (
      this.props.ticket.ticket_attachments.length >
        this.state.ticket.ticket_attachments.length &&
      this.state.ticket.ticket_attachments >= 1
    ) {
      const newTicket = this.state.ticket;
      newTicket.ticket_attachments = this.props.ticket.ticket_attachments;
      this.setState({ ticket: newTicket });
    }
  }

  componentCleanup = () => {
    if (this.updateTimer) {
      this.updateTimer.stopTimer();
    }
    if (this.state.emailUpdated) {
      this.sendUpdateEmail();
    }
    window.removeEventListener("beforeunload", this.componentCleanup);
  };

  componentDidMount() {
    document.title = `${this.state.ticket.project.identifier}-${this.state.ticket.app_key} - Test Platform`;
    const heading = document.querySelector("h2");
    if (heading) {
      heading.focus();
    }
    window.addEventListener("beforeunload", this.componentCleanup);
    const elem = document.querySelector("#root-sidebar > div:nth-child(1)");
    if (elem) {
      elem.scrollTop = 0;
    }
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    document.addEventListener("mousedown", this.handleClick, false);
    // a11y stuff
    $(".form-close-button").focus();
    setTimeout(() => {
      $("#filepond-container > div > div > input").attr("tabindex", "-1");
    }, 1000);
    //
    if (this.props.currentUserDevices || this.props.topDevices) {
      try {
        const modifiedDeviceOptions = JSON.parse(
          JSON.stringify(this.props.deviceOptions)
        );
        modifiedDeviceOptions.unshift({ label: "Top Devices", options: [] });
        this.props.topDevices.forEach((device) => {
          let deviceFound = false;
          for (let x = 1; x < 5; x++) {
            for (let y = 0; y < modifiedDeviceOptions[x].options.length; y++) {
              if (modifiedDeviceOptions[x].options[y].value == device[0]) {
                modifiedDeviceOptions[0].options.push(
                  modifiedDeviceOptions[x].options[y]
                );
                modifiedDeviceOptions[x].options.splice(y, 1);
                deviceFound = true;
                break;
              }
            }
            if (deviceFound) {
              break;
            }
          }
        });
        modifiedDeviceOptions.unshift({ label: "Your Devices", options: [] });
        this.props.currentUserDevices.forEach((device) => {
          let deviceFound = false;
          for (let x = 2; x < 6; x++) {
            for (let y = 0; y < modifiedDeviceOptions[x].options.length; y++) {
              if (modifiedDeviceOptions[x].options[y].value === device.id) {
                modifiedDeviceOptions[0].options.push(
                  modifiedDeviceOptions[x].options[y]
                );
                modifiedDeviceOptions[x].options.splice(y, 1);
                deviceFound = true;
                break;
              }
            }
            if (deviceFound) {
              break;
            }
          }
        });
        this.setState({ deviceOptions: modifiedDeviceOptions });
      } catch (err) {
        err.message = "Ticket Form Mount Error - suggested devices";
        Sentry.captureException(err);
        console.error(err.message);
      }
    }
  }

  componentWillUnmount() {
    document.title = "Bugs - Test Platform";
    this.componentCleanup();
    document.removeEventListener("mousedown", this.handleClick, false);
    const elem = document.getElementById(`${this.props.ticket.id}`);
    if (elem) {
      elem.focus();
    }
    // this.props.setFormUpdated(false)
  }

  groupHandler = (group, action) => {
    const options = this.state.watcherOptions;
    const optionGroup = {
      value: group.id,
      label: group.value,
      user_ids: group.user_ids,
      id: group.id,
    };
    if (action == "create") {
      if (options.length >= 2) {
        options[0].options.push(optionGroup);
      } else {
        options.unshift({ label: "Groups", options: [optionGroup] });
      }
    } else if (action == "delete") {
      const index = options[0].options.findIndex((x) => x.id == group.id);
      if (index != -1) {
        options[0].options.splice(index, 1);
      }
    }
    const self = this;
    if (group.users.length >= 1) {
      const e = self.state.selectedWatchers;
      const keys = e.map((x) => x.value);
      for (let index = 0; index < group.users.length; index++) {
        const element = group.users[index];
        if (!keys.includes(element.id)) {
          const watcher = self.state.watcherOptions[1].options.find(
            (watcher) => watcher.value == element.id
          );
          if (watcher) {
            e.push(watcher);
          }
        }
      }
      this.setState({ watcherOptions: options, selectedWatchers: e });
    } else {
      this.setState({ watcherOptions: options });
    }
  };

  deleteGroup = (e) => {
    const id = e.target.dataset.groupid;
    const self = this;
    Swal.fire({
      title: "Delete Group",
      text: "Are you sure that you want to delete this Group?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
      // ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/groups/${id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.status == 200) {
              self.groupHandler(res.data, "delete");
            }
          })
          .catch((err) => {
            err.message = `Ticket Group Delete Error - backend: ${err.message}`;
            console.error(err);
          });
      } else if (result.dismiss) {
      }
    });
  };

  closeGroupModal = () => {
    this.props.setModal(false, "", {});
  };

  groupModal = () => {
    this.props.setModal(true, "group-modal", {
      users: this.state.watcherOptions[1],
      customClass: "group-modal",
      selectedWatchers: this.state.selectedWatchers,
      projectId: this.state.project_id,
      closeHandler: this.closeGroupModal,
      groupHandler: this.groupHandler,
    });
  };

  cloneModal = () => {
    this.props.setModal(true, "clone-modal", {
      customClass: "group-modal",
      projectId: this.props.ticket.project_id,
      projectName: this.props.ticket.project.name,
      closeHandler: this.closeGroupModal,
      ticketHandler: this.props.handler,
      ticketId: this.props.ticket.id,
      ticketTitle: `(Clone) - ${this.props.ticket.title}`,
    });
  };

  handleWatcherChange = (e) => {
    var e = e || [];
    const self = this;
    const values = this.state.selectedWatchers.flatMap((x) =>
      typeof x.value === "number" ? x.value : []
    );
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (element.user_ids) {
        element.user_ids.forEach((user) => {
          if (!values.includes(user)) {
            const newUser = self.state.watcherOptions[1].options.find(
              (x) => x.value == user
            );
            e.push(newUser);
          }
        });
        e.splice(index, 1);
      }
    }
    e = e.sort((a, b) => {
      var a = a.label.props.children[1];
      var b = b.label.props.children[1];
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    this.handleMultiChange(e, "selectedWatchers");
  };

  handleLabelChange = (e) => {
    this.handleMultiChange(e, "selectedLabels");
  };

  handleDeviceChange = (e) => {
    const oldLength = this.state.selectedOptionDevices.length;
    this.handleMultiChange(e, "selectedOptionDevices");
    const elem = document.getElementById("aria-custom-status");
    if (elem) {
      this.ariaModifier = this.ariaModifier ? " " : ".";
      if (e && e.length < oldLength) {
        setTimeout(() => {
          elem.innerText = `Device removed${this.ariaModifier}`;
        }, 200);
      } else if (e && e.length > oldLength) {
        setTimeout(() => {
          elem.innerText = `Device ${e[e.length - 1].label} added${
            this.ariaModifier
          }`;
        }, 200);
      } else {
        setTimeout(() => {
          elem.innerText = `No devices selected${this.ariaModifier}`;
        }, 200);
      }
    }
  };

  handleBrowserChange = (e) => {
    const oldLength = this.state.selectedOptionBrowsers.length;
    this.handleMultiChange(e, "selectedOptionBrowsers");
    const elem = document.getElementById("aria-custom-status");
    if (elem) {
      this.ariaModifier = this.ariaModifier ? " " : ".";
      if (e && e.length < oldLength) {
        setTimeout(() => {
          elem.innerText = `Browser removed${this.ariaModifier}`;
        }, 300);
      } else if (e && e.length > oldLength) {
        setTimeout(() => {
          elem.innerText = `Browser ${e[e.length - 1].label} added${
            this.ariaModifier
          }`;
        }, 200);
      } else {
        setTimeout(() => {
          elem.innerText = `No browsers selected${this.ariaModifier}`;
        }, 200);
      }
    }
  };

  handleMultiChange = (e, key) => {
    const { emailUpdateData } = this.state;
    try {
      if (e.length < this.state[key].length) {
        var change = this.state[key].filter((item) =>
          item.label.props
            ? !e.map((i) => i.value).includes(item.value)
            : !e.includes(item)
        );
        if (emailUpdateData[key]) {
          if (change.length > 1) {
            delete emailUpdateData[key];
          } else {
            emailUpdateData[key] = emailUpdateData[key].filter(
              (item) => item.value !== change[0].value
            );
            if (emailUpdateData[key].length === 0) {
              delete emailUpdateData[key];
            }
          }
        }
        setTimeout(() => {
          document.activeElement.blur();
        }, 1000);
      } else if (this.state[key].length < e.length) {
        var change = e.filter((item) =>
          item.label.props
            ? !this.state[key].map((i) => i.value).includes(item.value)
            : !this.state[key].includes(item)
        );
        emailUpdateData[key] = emailUpdateData[key]
          ? [...emailUpdateData[key], ...change]
          : change;
      }
      this.setState(
        {
          [key]: e,
          emailUpdateData,
          emailUpdated: !!Object.values(emailUpdateData).some((e) => e),
        },
        () => {
          this.handleUpdateTimer();
          this.handleSubmit();
        }
      );
    } catch (err) {
      err.message = `Ticket MultiSelect Error - ${key}${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
  };

  deleteAttachmentHandler = (ticket, fileName, comment) => {
    if (comment) {
      this.setState({ comments: ticket.comments });
    } else {
      const { emailUpdateData } = this.state;
      if (emailUpdateData.files) {
        const x = emailUpdateData.files.filter((file) => file !== fileName);
        if (x.length === 0) {
          delete emailUpdateData.files;
        } else {
          emailUpdateData.files = x;
        }
      }
      const files = this.state.files.filter((file) => file.name !== fileName);
      this.setState(
        {
          ticket,
          files,
          emailUpdateData,
          emailUpdated: !!Object.values(emailUpdateData).some((e) => e),
        },
        this.handleUpdateTimer
      );
    }
  };

  deleteTicket = () => {
    Swal.fire({
      title: "Delete Bug",
      text: "Are you sure that you want to delete this Bug?",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonText: "Yes",
      // ally stuff
      customClass: "modal-button-outline",
    }).then((result) => {
      if (result.value) {
        const self = this;
        const { ticket } = self.props;
        axios
          .delete(`/bugs/${self.props.ticket.id}.json`, {})
          .then((res) => {
            M.toast({
              html: `Ticket ${ticket.project.identifier}-${ticket.app_key} Deleted`,
              displayLength: 3000,
              classes: "green",
            });
            self.props.ticketDeleteHandler(ticket.id);
            self.props.closeMethod();
          })
          .catch((err) => {
            err.message = `Ticket Delete Error - backend: ${err.message}`;
            console.error(err);
            Swal.fire({
              title: "Ticket Delete Error",
              text: "There was a problem deleting this Ticket",
              customClass: "swal2-error-modal",
            });
          });
      } else if (result.dismiss) {
      }
    });
  };

  commentHandler = (comment, action) => {
    try {
      const { emailUpdateData } = this.state;

      if (action == "create") {
        if (emailUpdateData.comments) {
          emailUpdateData.comments.push(comment);
        } else {
          emailUpdateData.comments = [comment];
        }

        this.setState(
          {
            comments: [...this.state.comments, comment],
            emailUpdated: true,
            emailUpdateData,
          },
          this.handleUpdateTimer
        );
      }

      if (action === "update") {
        const comments = [...this.state.comments]
          .map((c) => {
            if (c.id === comment.id) {
              return comment;
            }
            return c;
          })
          .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
        this.setState({ comments });
      }

      if (action === "delete") {
        const comments = [...this.state.comments].filter(
          (c) => c.id !== comment.id
        );
        if (emailUpdateData.comments) {
          const updateComments = emailUpdateData.comments.filter(
            (c) => c.id !== comment.id
          );
          if (updateComments.length === 0) {
            delete emailUpdateData.comments;
          } else {
            emailUpdateData.comments = updateComments;
          }
        }
        this.setState(
          {
            comments,
            emailUpdated: Object.values(emailUpdateData).length > 0,
            emailUpdateData,
          },
          this.handleUpdateTimer
        );
      }
    } catch (err) {
      err.message = `Ticket Form Comments - frontend ${action}: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
  };

  handleCancelButton = () => {
    if (this.state.localFormUpdated) {
      this.warnOnClose();
    } else {
      // a11y stuff
      if (this.props.ticket.id) {
        $(`#ticket_${this.props.ticket.id}`).focus();
      }
      //
      this.props.closeButtonHandler();
    }
  };

  handleKeyDown = (e) => {
    const code = e.which;
    if (code === 13) {
      this.handleCancelButton();
    }
  };

  handleSelectChange = (selectedOption, e) => {
    // this.props.setFormUpdated(true)
    let name = `selectedOption${e.name}`;
    const { emailUpdateData } = this.state;
    if (
      emailUpdateData[name] &&
      selectedOption.value === this.state.emailUpdateStartingData[name].value
    ) {
      delete emailUpdateData[name];
    } else {
      emailUpdateData[name] = selectedOption;
    }
    this.setState(
      {
        [name]: selectedOption,
        emailUpdated: !!Object.values(emailUpdateData).some((e) => e),
        emailUpdateData,
        localFormUpdated: true,
        statusUpdated: name == "selectedOptionStatus",
      },
      () => {
        this.handleUpdateTimer();
        this.handleSubmit();
      }
    );
    const elem = document.getElementById("aria-custom-status");
    if (elem) {
      name = e.name;
      this.ariaModifier = this.ariaModifier ? " " : ".";
      setTimeout(() => {
        elem.innerText = `${selectedOption.label} ${name} selected${this.ariaModifier}`;
      }, 200);
    }
  };

  handleTimeInput = (value) => {
    const { emailUpdateData } = this.state;
    if (
      emailUpdateData.title &&
      value === this.state.emailUpdateStartingData.title
    ) {
      delete emailUpdateData.title;
    } else {
      emailUpdateData.title = value;
    }
    this.setState(
      {
        title: value,
        emailUpdated: !!Object.values(emailUpdateData).some((e) => e),
        emailUpdateData,
        localFormUpdated: true,
      },
      () => {
        this.handleUpdateTimer();
        this.handleSubmit();
      }
    );
  };

  handleBuildTimeInput = (value) => {
    this.setState(
      {
        build_version: value,
        emailUpdated: true,
        localFormUpdated: true,
      },
      this.handleSubmit
    );
  };

  handleTimeArea = (value, mentions) => {
    const { emailUpdateData } = this.state;
    if (
      emailUpdateData.content &&
      value === this.state.emailUpdateStartingData.content
    ) {
      delete emailUpdateData.content;
    } else {
      emailUpdateData.content = value;
    }
    this.setState(
      {
        content: value,
        mentions,
        emailUpdated: !!Object.values(emailUpdateData).some((e) => e),
        emailUpdateData,
        localFormUpdated: true,
      },
      () => {
        this.handleUpdateTimer();
        this.handleSubmit();
      }
    );
  };

  handleDeletButtonKeyDown = (e) => {
    if (e.which === 13) {
      this.deleteTicket();
    }
  };

  handleA11yAttachmentDownload = (e) => {
    const { fileUrl, fileName } = e.target.dataset;
    const dl = document.createElement("a");
    dl.setAttribute("href", `${fileUrl}`);
    dl.setAttribute("download", `${fileName}`);
    dl.click();
    dl.remove();
  };

  handleA11yAddFile = () => {
    this.handleAddFile("a11y-bug-file");
    const ariaElem = document.getElementById("aria-custom-status");
    if (ariaElem) {
      setTimeout(() => {
        ariaElem.innerText = "Attachment was added";
      }, 800);
    }
  };

  handleAddFile = (id) => {
    var id = id === "a11y-bug-file" ? id : false;
    try {
      const fileExtension = document
        .getElementById(id || "bug-file")
        .value.split(".")
        .pop()
        .toLowerCase();
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "chls",
        "rtf",
        "txt",
        "doc",
        "docx",
        "zip",
        "pdf",
        "mp4",
        "mov",
        "json",
      ];
      if (!allowedExtensions.includes(fileExtension)) {
        M.toast({
          html: "Invalid File Type",
          classes: "red darken-1 error-toast",
        });
        this.refs.file.value = "";
        return;
      }
      const { files } = document.getElementById(id || "bug-file");
      var checkedFiles = [];
      for (const key in files) {
        if (files.length > 3) {
          M.toast({
            html: "Please limit selection to 3 files at a time",
            classes: "red darken-1 error-toast",
          });
          this.refs.file.value = "";
          return null;
        }
        if (files.hasOwnProperty(key)) {
          const file = files[key];
          // file size limit: 100MB
          if (file.size > 100 * 1024 * 1024) {
            M.toast({
              html: "File exceeds size limit",
              classes: "red darken-1 error-toast",
            });
            this.refs.file.value = "";
            return null;
          }
          checkedFiles.push(file);
        }
      }
      var { emailUpdateData } = this.state;
      emailUpdateData.files = [
        ...(emailUpdateData.files ? emailUpdateData.files : []),
        ...checkedFiles.map((file) => file.name),
      ];
    } catch (err) {
      err.message = `Ticket File Add Error - frontend: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
    this.setState(
      {
        emailUpdated: true,
        emailUpdateData,
        files: this.state.files.concat(checkedFiles),
      },
      () => {
        if (this.state.ticket.trello_card_id && this.props.trelloConnected) {
          const formData = new FormData();
          formData.append("token", this.props.project.trello_token);
          formData.append("key", "0897db51a0c97cd37a2191a9bfabb4ea");
          formData.append("file", this.state.files[0]);
          const request = new XMLHttpRequest();
          request.onreadystatechange = () => {
            if (request.readyState === 4) {
              console.log("Attachment Added");
            }
          };
          request.open(
            "POST",
            `https://api.trello.com/1/cards/${this.state.ticket.trello_card_id}/attachments`
          );
          request.onerror = () => {
            console.error("Trello Ticket File Update Error");
            Sentry.captureException(
              new Error("Trello Ticket File Update Error")
            );
          };
          request.send(formData);
        }
        this.handleUpdateTimer();
        this.handleSubmit("file-upload");
      }
    );
    this.refs.file.value = "";
  };

  createNewLabel = (e) => {
    const button = e.target;
    button.style.pointerEvents = "none";
    button.style.color = "lightgrey";
    const labelName = document.querySelector("#label_select_input input").value;
    if (labelName) {
      axios
        .post("/ticket_labels.json", {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          ticket_label: {
            name: labelName,
            project_id: this.state.ticket.project.id,
          },
        })
        .then((res) => {
          const newLabels = this.state.selectedLabels;
          const newTotalLabels = this.props.ticketLabelOptions;
          const { emailUpdateData } = this.state;
          if (emailUpdateData.selectedLabels) {
            emailUpdateData.selectedLabels.push({
              label: res.data.name,
              value: res.data.id,
            });
          } else {
            emailUpdateData.selectedLabels = [
              { label: res.data.name, value: res.data.id },
            ];
          }
          newLabels.push({ label: res.data.name, value: res.data.id });
          newTotalLabels.push({ label: res.data.name, value: res.data.id });
          newTotalLabels.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
          );
          const labelPromise = new Promise((resolve, reject) => {
            this.props.updateTicketLabels(newTotalLabels, resolve);
          });
          labelPromise.then((message) => {
            this.setState(
              {
                emailUpdated: true,
                selectedLabels: newLabels,
                emailUpdateData,
              },
              () => {
                this.handleUpdateTimer();
                this.handleSubmit();
              }
            );
            document.activeElement.blur();
          });
        })
        .catch((err) => {
          button.style.pointerEvents = "all";
          button.style.color = "#519acc";
          M.toast({
            html: "There was an error creating the label",
            displayLength: 3000,
            classes: "red",
          });
          err.message = `Ticket Label Create Error - backend: ${err.message}`;
          console.error(err);
        });
    }
  };

  deleteTicketLabel = (e) => {
    const { labelId } = e.target.dataset;
    const self = this;
    Swal.fire({
      title: "Delete Label",
      text: "Are you sure that you want to delete this Label?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/ticket_labels/${labelId}.json`)
          .then((res) => {
            const newTotalLabels = self.props.ticketLabelOptions.filter(
              (label) => label.value != res.data.id
            );
            self.props.updateTicketLabels(newTotalLabels);
          })
          .catch((err) => {
            M.toast({
              html: "There was an error deleting the label",
              displayLength: 3000,
              classes: "red",
            });
            err.message = `Ticket Label Delete Error - backend: ${err.message}`;
            console.error(err);
          });
      } else if (result.dismiss) {
      }
    });
  };

  outsideClickHandler = (e) => {
    const self = this;
    const elem = document.getElementById("watchersSelect");
    if (self.state.watcherMenuOpen && elem && !elem.contains(e.target)) {
      self.setState({ watcherMenuOpen: !self.state.watcherMenuOpen });
    }
  };

  handleSelectKeyDown = (e) => {
    if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  onSelectDevicesKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          const list = this[
            `${id.toLowerCase()}SelectRef`
          ].current.select.state.menuOptions.focusable.map((i) => i.label);
          const index = list.indexOf(elem.innerText);
          ariaElem.innerText = `option ${elem.innerText}. ${index + 1} of ${
            list.length
          } options`;
        }
      }, 50);
    } else if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  sendUpdateEmail = () => {
    try {
      var errorRelated = "";
      var data = this.state.emailUpdateData;
      const startingData = this.state.emailUpdateStartingData;
      var groupEmail = false;
      const user_ids = [
        ...new Set([
          ...this.state.selectedWatchers
            .filter((w) => w.emailNotifications)
            .map((w) => w.value),
          ...(this.props.ticket.assignee_email_notifications
            ? [this.state.selectedOptionAssignee.value]
            : []),
          ...(this.props.ticket.tester_email_notifications
            ? [this.props.ticket.tester_id]
            : []),
        ]),
      ].filter((id) => id !== this.props.currentUser.id && id !== 0);
      if (user_ids.length === 0) {
        return;
      }
      var emailData = {
        updated_by: this.props.currentUser.name,
        updated_at: new Date().toISOString().slice(0, 10),
        user_id: this.props.currentUser.id,
        user_ids,
        event_data: {},
        send_email: false,
        identifier: `${this.state.ticket.project.identifier}-${this.state.ticket.app_key}`,
        project_id: this.state.ticket.project.id,

        ticket_id: this.state.ticket.id,
      };
      Object.keys(data).forEach((key) => {
        switch (key) {
          case "title":
            emailData.title = [
              0,
              this.textDiff.diff(startingData[key], data[key]),
            ];
            emailData.send_email = user_ids.length > 0;
            emailData.event_data.title = [startingData[key], data[key]];
            groupEmail = true;
            break;
          case "content":
            emailData.content = [
              1,
              this.textDiff.diff(startingData[key], data[key]),
            ];
            emailData.send_email = user_ids.length > 0;
            emailData.event_data.content = [startingData[key], data[key]];
            groupEmail = true;
            break;
          case "selectedOptionStatus":
            emailData.status = [2, [startingData[key].label, data[key].label]];
            emailData.send_email = user_ids.length > 0;
            emailData.event_data.status = [
              startingData[key].label,
              data[key].label,
            ];
            groupEmail = true;
            break;
          case "selectedOptionAssignee":
            emailData.assignee = [
              3,
              [
                startingData[key].label.props
                  ? startingData[key].label.props.children[1]
                  : startingData[key].label,
                data[key].label.props
                  ? data[key].label.props.children[1]
                  : data[key].label,
              ],
            ];
            emailData.send_email = user_ids.length > 0;
            emailData.event_data.assignee = [
              startingData[key].label.props
                ? startingData[key].label.props.children[1]
                : startingData[key].label,
              data[key].label.props
                ? data[key].label.props.children[1]
                : data[key].label,
            ];
            groupEmail = true;
            break;
          case "comments":
            emailData.comments = [
              6,
              data[key].map((comment) => comment.value.replace(/\n/g, "<br>")),
            ];
            emailData.send_email = user_ids.length > 0;
            emailData.event_data.comments = data[key].map(
              (comment) => comment.value
            );
            groupEmail = true;
            break;
          case "selectedWatchers":
            emailData.send_email = true;
            emailData.watchers = [
              4,
              data[key].map((watcher) =>
                watcher.label.props
                  ? watcher.label.props.children[1]
                  : watcher.label
              ),
            ];
            emailData.event_data.watchers = [
              startingData[key]
                ? startingData[key].map((watcher) =>
                    watcher.label.props
                      ? watcher.label.props.children[1]
                      : watcher.label
                  )
                : [],
              data[key].map((watcher) =>
                watcher.label.props
                  ? watcher.label.props.children[1]
                  : watcher.label
              ),
            ];
            break;
          case "files":
            emailData.send_email = user_ids.length > 0;
            emailData.files = [5, data[key]];
            emailData.event_data.files = ["Files Added", ""];
            groupEmail = true;
            break;
        }
      });
    } catch (err) {
      err.message = `Ticket Update Email Error - frontend ${errorRelated}: ${err.message}`;
      console.error(err);
      Sentry.captureException(err);
    }
    if (!groupEmail && data.selectedWatchers) {
      emailData.user_ids = data.selectedWatchers.map((w) => w.value);
    }
    axios
      .get("/ticket_update_email.json", {
        params: { data: emailData },
      })
      .then((res) => {})
      .catch((err) => {
        err.message = `Ticket Update Email Error - backend: ${err.message}`;
        console.error(err);
      });
    const {
      selectedOptionPriority,
      selectedOptionStatus,
      selectedOptionWCAG,
      selectedOptionAssignee,
    } = this.state;
    this.setState({
      emailUpdated: false,
      emailUpdateStartingData: {
        title: this.state.title,
        content: this.state.content,
        comments: this.state.comments,
        selectedOptionPriority,
        selectedOptionStatus,
        selectedOptionWCAG,
        selectedOptionAssignee,
      },
      emailUpdateData: {},
    });
  };

  removeGroup = (e) => {
    const group = this.state.groups.find(
      (x) => x.id == e.target.dataset.groupid
    );
    const { selectedWatchers } = this.state;
    const filteredWatchers = selectedWatchers.filter(
      (watcher) => !group.user_ids.includes(watcher.value)
    );
    this.handleWatcherChange(filteredWatchers);
  };

  copyToClipboard = (e) => {
    const temp = $("<input>");
    $("body").append(temp);
    temp.val(location.href).select();
    document.execCommand("copy");
    temp.remove();
    M.toast({
      html: '<span aria-live="assertive">Link Copied</span>',
      displayLength: 3000,
      classes: "loading-toast",
    });
  };

  handleUpdateTimer = () => {
    if (this.updateTimer) {
      this.updateTimer.stopTimer();
      this.updateTimer = null;
    }
    if (this.state.emailUpdated) {
      this.updateTimer = new Timer(60000, this.sendUpdateEmail);
    }
  };

  draggedOver = (e) => {
    const elem = document.getElementById("file-wrapper");
    if (elem) {
      elem.children[0].style.color = "white";
      elem.children[1].style.backgroundColor = "#519acc";
    }
  };

  draggedOut = (e) => {
    const elem = document.getElementById("file-wrapper");
    if (elem) {
      elem.children[0].style.color = "black";
      elem.children[1].style.backgroundColor = "rgb(245, 245, 245)";
    }
  };

  Menu = (props) => {
    return (
      <components.Menu {...props}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #e0e0e0",
            height: "50px",
          }}
        >
          <p style={{ marginLeft: "20px", fontSize: "16px" }}>Add A Watcher</p>
          <button
            type="button"
            className="link-btn"
            style={{
              cursor: "pointer",
              color: "#519acc",
              paddingRight: "15px",
            }}
            onClick={this.groupModal}
          >
            <i style={{ fontSize: "12px", marginRight: "5px" }}>+</i>Create
            Group
          </button>
        </div>
        {props ? props.children : ""}
      </components.Menu>
    );
  };

  MenuList = (props) => {
    return (
      <components.MenuList className="watchersMenuList" {...props}>
        {props ? props.children : ""}
      </components.MenuList>
    );
  };

  onSelectKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          ariaElem.innerText = elem.classList.contains(
            "select__option--is-selected"
          )
            ? `option ${elem.innerText} ${id} currently selected`
            : `option ${elem.innerText} ${id}`;
        }
      }, 50);
    }
  };

  onSelectAssigneeKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          const list = this[
            `${id.toLowerCase()}SelectRef`
          ].current.select.state.menuOptions.focusable.map((i) => i.label);
          const index = list.indexOf(elem.innerText);
          ariaElem.innerText = elem.classList.contains(
            "select__option--is-selected"
          )
            ? `option ${elem.innerText} currently selected. ${index + 1} of ${
                list.length
              }${
                list.length < this.state.userOptions.length ? " search" : ""
              } options`
            : `option ${elem.innerText}. ${index + 1} of ${list.length}${
                list.length < this.state.userOptions.length ? " search" : ""
              } options`;
        }
      }, 50);
    }
  };

  onSelectA11yKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      const id = e.target.id.split("-")[2];
      setTimeout(() => {
        const elem = document.querySelector(".select__option--is-focused");
        const ariaElem = document.getElementById("aria-custom-status");
        if (elem && ariaElem) {
          const parentElem = elem.parentElement;
          const index = Array.prototype.indexOf.call(parentElem.children, elem);
          ariaElem.innerText = elem.classList.contains(
            "select__option--is-selected"
          )
            ? `option ${elem.innerText} currently selected. ${index + 1} of ${
                parentElem.children.length
              }${
                parentElem.children.length < this.state.violationOptions.length
                  ? " search"
                  : ""
              } options`
            : `option ${elem.innerText}. ${index + 1} of ${
                parentElem.children.length
              }${
                parentElem.children.length < this.state.violationOptions.length
                  ? " search"
                  : ""
              } options`;
        }
      }, 50);
    }
  };

  showQRCode = (e) => {
    e.preventDefault();
    this.props.setModal(true, "qr-code", {
      userId: this.props.currentUser.id,
      projectId: this.props.ticket.project_id,
      ticketId: this.props.ticket.id,
      customClass: "qr-modal",
      identifier: `${this.state.ticket.project.identifier} - ${this.state.ticket.app_key}`,
    });
  };

  render() {
    const comments = this.state.comments.sort((a, b) => a.id - b.id);

    const watcherIds = [];
    for (const key in this.state.ticket.watchers) {
      if (this.state.ticket.watchers.hasOwnProperty(key)) {
        watcherIds.push(this.state.ticket.watchers[key].user_id);
      }
    }

    const Option = (props) => {
      if (props.selectProps.options && props.data.user_ids) {
        if (props.data.user_ids.every((item) => watcherIds.includes(item))) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "inline-flex", marginLeft: "25px" }}>
                <img
                  alt="select icon"
                  style={{ marginLeft: "-3px", marginRight: "5px" }}
                  height="30"
                  width="30"
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Select.svg"
                />
                <div
                  data-groupid={props.data.id}
                  onClick={this.removeGroup}
                  style={{
                    height: "30px",
                    width: "30px",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px",
                  }}
                >
                  {props ? props.children : ""}
                </div>
              </div>
              <i
                data-groupid={props.data.id}
                onClick={this.deleteGroup}
                style={{
                  color: "#519acc",
                  marginRight: "12px",
                  cursor: "pointer",
                }}
                className="material-icons hover-red"
              >
                delete
              </i>
            </div>
          );
        }
        return (
          <div
            className="group-option"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <components.Option {...props}>
              <div
                style={{
                  height: "22px",
                  width: "22px",
                  marginLeft: "35px",
                  borderRadius: "50%",
                  border: "1px solid black",
                }}
              />
              {props ? props.children : ""}
              <i
                data-groupid={props.data.id}
                onClick={this.deleteGroup}
                style={{
                  color: "#519acc",
                  cursor: "pointer",
                }}
                className="material-icons hover-red"
              >
                delete
              </i>
            </components.Option>
          </div>
        );
      }
      if (props.isSelected) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "25px",
            }}
          >
            <components.Option {...props}>
              <img
                alt="Select Icon"
                style={{ marginLeft: "-3px", marginRight: "3px" }}
                height="30"
                width="30"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Select.svg"
              />
              {props ? props.children : ""}
            </components.Option>
          </div>
        );
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "25px",
          }}
        >
          <components.Option {...props}>
            <div
              style={{
                height: "22px",
                width: "22px",
                position: "relative",
                top: "3px",
                marginRight: "8px",
                borderRadius: "50%",
                border: "1px solid black",
              }}
            />
            {props ? props.children : ""}
          </components.Option>
        </div>
      );
    };

    this.prioritySingleValue = (props) => {
      return (
        <div
          aria-hidden="true"
          style={{
            width: "100%",
            height: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <components.SingleValue {...props} />
        </div>
      );
    };

    const GroupHeading = (props) => {
      return (
        <div
          style={{ display: "flex", alignItems: "center", flex: "1 0 100%" }}
        >
          <components.GroupHeading {...props}>
            {props ? props.children : ""}
          </components.GroupHeading>
          <div
            style={{
              flex: 0.95,
              height: "1px",
              backgroundColor: "#e0e0e0",
            }}
          />
        </div>
      );
    };
    // fix for extra newlines added to ticket form
    let { content } = this.state;
    if (content.indexOf("<span/>") !== -1) {
      const index = content.indexOf("<span/>");
      content = `${content.slice(0, index + 7)}</div>`;
    }

    return (
      <div role="dialog" aria-label="edit bug modal" ref={this.formNode}>
        <h2
          className="large-title"
          tabIndex={-1}
          style={{ padding: "20px 0px 10px 20px", outline: "none" }}
        >
          Edit Bug
        </h2>
        <form
          id="ticketForm"
          onClick={this.outsideClickHandler}
          onSubmit={this.handleSubmit}
          encType="multipart/form-data"
        >
          <div style={{ padding: "0", margin: "0" }}>
            <div
              style={{
                padding: "0 25px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "1" }}>
                <div className="ticket-form-priority-select select-aria-class">
                  <Select
                    aria-label={`Priority dropdown, ${this.state.selectedOptionPriority.label} selected. Press down to open menu`}
                    name="Priority"
                    classNamePrefix="select"
                    defaultValue="Select a priority"
                    value={this.state.selectedOptionPriority}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        backgroundColor:
                          this.priorityColors[
                            this.state.selectedOptionPriority.value - 1
                          ],
                      }),
                    }}
                    onKeyDown={this.onSelectKeyDown}
                    blurInputOnSelect
                    isSearchable={false}
                    instanceId="Priority"
                    components={{ SingleValue: this.prioritySingleValue }}
                    onChange={this.handleSelectChange}
                    options={this.priorityOptions}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    height: "70px",
                    justifyContent: "space-between",
                    fontSize: "24px",
                    fontWeight: "500",
                    verticalAlign: "middle",
                  }}
                >
                  <span style={{ pointerEvents: "none" }}>
                    {this.state.ticket.project.identifier}-
                    {this.state.ticket.app_key}
                  </span>
                  <div style={{ marginTop: "14px", display: "flex" }}>
                    <button
                      message="Copy Bug URL"
                      className="link-button qr-button-bottom copy-url"
                      onClick={this.copyToClipboard}
                      style={{
                        background: "none",
                        border: "solid 1px lightgray",
                        display: "inline-block",
                        // borderRadius:"6px",
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: "6px",
                        borderTopRightRadius:
                          this.props.currentUser.role !== "client"
                            ? "0px"
                            : "6px",
                        borderBottomRightRadius:
                          this.props.currentUser.role !== "client"
                            ? "0px"
                            : "6px",
                        padding: "1px 3px",
                        cursor: "pointer",
                      }}
                    >
                      <LinkIcon
                        svgStyles={{ position: "relative", top: "2px" }}
                        height={20}
                        width={20}
                        color="#242424"
                      />
                    </button>
                    {this.props.currentUser.role !== "client" && (
                      <button
                        message="Get my QR Code"
                        className="link-button qr-button-bottom ticket-qr"
                        onClick={this.showQRCode}
                        style={{
                          background: "none",
                          borderTop: "solid 1px lightgray",
                          borderBottom: "solid 1px lightgray",
                          borderRight: "solid 1px lightgray",
                          borderLeft: "none",
                          display: "inline-block",
                          borderTopRightRadius: "6px",
                          borderBottomRightRadius: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <QRCodeIcon
                          svgStyles={{ position: "relative", bottom: "1px" }}
                          color="#242424"
                        />
                      </button>
                    )}
                    <p style={{ paddingLeft: "15px" }} className="subtitle">
                      {this.props.ticket.created_at &&
                        `Created ${new Date(
                          this.props.ticket.created_at
                        ).toLocaleString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                          timeZone: "America/Los_Angeles",
                        })}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="edit-ticket-input-group">
                {this.props.currentUser.role == "admin" && (
                  <div className=" ticket-form-select-container select-aria-class">
                    <span style={{ fontWeight: "600" }}>Project</span>
                    <Select
                      aria-label={`Project dropdown, ${this.state.selectedOptionProject.label} selected. Type to refine list or press down to open menu`}
                      name="Project"
                      classNamePrefix="select"
                      onKeyDown={this.onSelectKeyDown}
                      blurInputOnSelect
                      instanceId="Project"
                      value={this.state.selectedOptionProject}
                      components={{
                        SingleValue: singleValue,
                        DropdownIndicator: dropdownIndicator,
                        Control: customControl,
                      }}
                      onChange={this.handleSelectChange}
                      styles={SingleSelectStyles}
                      options={this.props.projectOptions}
                      required
                    />
                  </div>
                )}
                <div className=" ticket-form-select-container select-aria-class">
                  <span style={{ fontWeight: "600" }}>Status</span>
                  <Select
                    aria-label={`Status dropdown, ${this.state.selectedOptionStatus.label} selected. Type to refine list or press down to open menu`}
                    name="Status"
                    classNamePrefix="select"
                    onKeyDown={this.onSelectKeyDown}
                    blurInputOnSelect
                    instanceId="Status"
                    value={this.state.selectedOptionStatus}
                    defaultValue={{ label: "Open", value: 1 }}
                    components={{
                      SingleValue: singleValue,
                      DropdownIndicator: dropdownIndicator,
                      Control: customControl,
                    }}
                    onChange={this.handleSelectChange}
                    styles={SingleSelectStyles}
                    options={this.statusOptions}
                    required
                  />
                </div>
                <div className="ticket-form-select-container select-aria-class">
                  <span style={{ fontWeight: "600" }}>Assignee</span>
                  <Select
                    ref={this.assigneeSelectRef}
                    aria-label={`Assignee dropdown, ${
                      this.state.selectedOptionAssignee.label.props
                        ? this.state.selectedOptionAssignee.label.props
                            .children[1]
                        : this.state.selectedOptionAssignee.label
                    } selected. Type to refine list or press down to open menu`}
                    name="Assignee"
                    classNamePrefix="select"
                    defaultValue="Select a user"
                    blurInputOnSelect
                    value={this.state.selectedOptionAssignee}
                    getOptionValue={(option) => {
                      return option.label.props
                        ? `${option.label.props.children[1]} ${option.value}`
                        : option.value;
                    }}
                    styles={{
                      ...TicketSelectStyles,
                      valueContainer: (styles, state) => ({
                        ...styles,
                        maxHeight: "unset",
                      }),
                      singleValue: (styles) => ({
                        color: "inherit",
                        marginLeft: "2px",
                        marginRight: "2px",
                        flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        boxSizing: "border-box",
                        height: "42px",
                        lineHeight: "38px",
                      }),
                    }}
                    onKeyDown={this.onSelectAssigneeKeyDown}
                    onChange={this.handleSelectChange}
                    options={this.state.userOptions}
                    instanceId="Assignee"
                    components={{
                      Option: assigneeOption,
                      SingleValue: assigneeSingleValue,
                      DropdownIndicator: dropdownIndicator,
                      Control: customControl,
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col s12">
              <div className="col s12 ticket-form-input-container">
                <p className="field-title">
                  Title<span className="bc-blue">*</span>
                </p>
                <TimedInput
                  textarea
                  className="common-textarea browser-default"
                  ref={this.title}
                  id="ticket[title]"
                  name="title"
                  type="text"
                  // updatedHandler={this.props.setFormUpdated}
                  updatedHandler={this.setLocalFormUpdated}
                  onChange={this.handleTimeInput}
                  value={this.state.title}
                  required
                />
              </div>
            </div>
            <div style={{ marginBottom: "15px" }} className="col s12">
              <div
                className={`col s12 editable-${this.state.descEditable}`}
                id="descContainer"
              >
                <span style={{ fontWeight: "600" }}>Description</span>
                <EditableTextArea
                  // refProp={this.description}
                  id="ticket[description]"
                  name="description"
                  type="textarea"
                  editable
                  updatedHandler={this.setDescriptionEdit}
                  // updatedHandler={this.props.setFormUpdated}
                  localFormUpdated={this.state.localFormUpdated}
                  ticketId={this.props.ticket.id}
                  users={this.state.userOptions}
                  placeholder="Describe your issue"
                  value={content}
                  onChange={this.handleTimeArea}
                />
              </div>
            </div>
            {this.props.ticket.project.a11y_active ? (
              <div style={{ marginBottom: "15px" }} className="col s12">
                <div className="ticket-form-select-container col s12">
                  <span className="field-title">Accessibility</span>
                  <Select
                    aria-label={`WCAG violation dropdown, ${this.state.selectedOptionWCAG.label} selected. Type to refine list or press down to open menu`}
                    name="WCAG"
                    classNamePrefix="select"
                    defaultValue="Accessibility Violations"
                    value={this.state.selectedOptionWCAG}
                    styles={SingleSelectStyles}
                    onChange={this.handleSelectChange}
                    components={{
                      SingleValue: singleValue,
                      DropdownIndicator: dropdownIndicator,
                    }}
                    onKeyDown={this.onSelectA11yKeyDown}
                    options={this.state.violationOptions}
                    instanceId="WCAG"
                    required
                  />
                </div>
              </div>
            ) : null}
            <div style={{ marginBottom: "15px" }} className="col s12">
              <div
                id="ticketCreatorBox"
                className="col s5 ticket-form-select-container"
              >
                <p className="field-title">Created By</p>
                <div>
                  <div
                    aria-hidden="true"
                    style={{
                      height: "30px",
                      width: "30px",
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  >
                    <img
                      alt="missing user avatar"
                      className="select-avatar-image"
                      src={`${
                        this.props.ticket.tester_avatar_image
                          ? this.props.ticket.tester_avatar_image
                          : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
                      }`}
                    />
                  </div>
                  {this.props.ticket.tester_name}
                </div>
              </div>
              {this.props.ticket.project.jira_url != null &&
                this.props.ticket.jira_ticket_key != null && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "32px",
                    }}
                  >
                    <span style={{ paddingRight: "5px" }}>
                      Linked to Jira Issue:
                    </span>
                    <a
                      target="_blank"
                      href={`${this.props.ticket.project.jira_url}/browse/${this.props.ticket.jira_ticket_key}`}
                      rel="noreferrer"
                    >{`${this.props.ticket.jira_ticket_key}`}</a>
                  </div>
                )}
            </div>
            <div
              style={{
                minHeight: "270px",
                maxHeight: `${
                  ((this.state.selectedOptionDevices.length +
                    this.state.selectedOptionBrowsers.length) /
                    2) *
                    50 +
                    200 >=
                  600
                    ? "600"
                    : ((this.state.selectedOptionDevices.length +
                        this.state.selectedOptionBrowsers.length) /
                        2) *
                        50 +
                      200
                }px`,
                transition: "max-height .5s",
                marginBottom: "15px",
              }}
              className="col s12"
            >
              <p
                className="field-title"
                style={{
                  paddingLeft: "12px",
                }}
              >
                Environment<span className="bc-blue">*</span>
              </p>
              <MultiSelectWrapper
                aria-label={`Device dropdown, ${
                  this.state.selectedOptionDevices
                    ? this.state.selectedOptionDevices.length
                    : 0
                } device${
                  this.state.selectedOptionDevices &&
                  this.state.selectedOptionDevices.length !== 1
                    ? "s"
                    : ""
                } selected. Type to refine list or press down to open menu`}
                value={this.state.selectedOptionDevices}
                label={<p>Affected Devices</p>}
                onChange={this.handleDeviceChange}
                name="colors"
                tagStyle
                isClearable={false}
                isSearchable
                // onSelectKeyDown={this.onSelectDevicesKeyDown}
                closeMenuOnSelect={false}
                options={this.state.deviceOptions}
                placeholder="Search for devices"
                width="500px"
                height="200px"
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <MultiSelectWrapper
                aria-label={`Browser dropdown, ${
                  this.state.selectedOptionBrowsers
                    ? this.state.selectedOptionBrowsers.length
                    : 0
                } browser${
                  this.state.selectedOptionBrowsers &&
                  this.state.selectedOptionBrowsers.length !== 1
                    ? "s"
                    : ""
                } selected. Type to refine list or press down to open menu`}
                value={this.state.selectedOptionBrowsers}
                label={<p>Affected Browsers</p>}
                onChange={this.handleBrowserChange}
                isSearchable
                // onKeyDown={this.onSelectDevicesKeyDown}
                name="colors"
                tagStyle
                isClearable={false}
                defaultValue="Select a Browser"
                closeMenuOnSelect={false}
                options={this.state.browserOptions}
                placeholder="Search for browsers"
                width="500px"
                height="200px"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="col s12" />
            {/* build field is hidden currently */}
            <div
              aria-hidden="true"
              style={{ height: "0px", overflow: "hidden", minHeight: "0px" }}
              className="col s12"
            >
              {" "}
              <div className="col l2 m3 s4 offset-l2 offset-s2">
                {" "}
                Build{" "}
                <ErrorBoundary>
                  {" "}
                  <TimedInput
                    className="ticketInput"
                    ref="build_version"
                    id="ticket[build_version]"
                    name="build_version"
                    type="text"
                    updatedHandler={this.setLocalFormUpdated}
                    onChange={this.handleBuildTimeInput}
                    value={this.state.build_version}
                  />{" "}
                </ErrorBoundary>{" "}
              </div>{" "}
            </div>
            {/* //// */}
            <div className="col s12" id="attachmentsContainer">
              <p
                className="field-title"
                style={{
                  marginTop: "4px",
                  paddingLeft: "0.9em",
                }}
              >
                Attachments
              </p>
              <div
                style={{ height: "0px", width: "0px", overflow: "hidden" }}
                id="screen-reader-attachment-container"
              >
                {this.state.ticket.ticket_attachments &&
                  this.state.ticket.ticket_attachments.length > 0 && (
                    <ul aria-label="Ticket attachments">
                      {this.state.ticket.ticket_attachments.map((a) => {
                        const {
                          image_content_type,
                          image_url,
                          image_file_name,
                          id,
                        } = a;
                        const extension = image_content_type.split("/")[1];
                        return (
                          <li
                            key={id}
                            onClick={this.handleA11yAttachmentDownload}
                            data-file-name={image_file_name}
                            data-file-url={image_url}
                            aria-label={`Download ${extension} file "${image_file_name}"`}
                          />
                        );
                      })}
                    </ul>
                  )}
                <input
                  id="a11y-bug-file"
                  type="file"
                  accept=".jpg,.gif,.png,.chls,.rtf,.txt,.doc,.docx,.zip,.pdf,.mp4,.mov,.json"
                  ref="file"
                  onChange={this.handleA11yAddFile}
                  role="button"
                  aria-label="Add a new attachment"
                  multiple
                />
              </div>
              <div
                aria-hidden="true"
                className="col s12"
                id="attachmentCarousel"
              >
                <ErrorBoundary>
                  <AttachmentCarousel
                    setModal={this.props.setModal}
                    action="edit"
                    handleNewImages={this.handleNewImages}
                    deleteAttachmentHandler={this.deleteAttachmentHandler}
                    setLoadingClass={this.props.setLoadingClass}
                    setLoadingText={this.props.setLoadingText}
                    fileInput={
                      <div
                        key={1}
                        onDragEnter={this.draggedOver}
                        onDragLeave={this.draggedOut}
                        style={{ pointerEvents: "all" }}
                        id="filepond-container"
                      >
                        <div
                          style={{
                            position: "absolute",
                            right: "0px",
                            bottom: "0px",
                          }}
                        >
                          <div className="file-field input-field">
                            <i
                              style={{ pointerEvents: "none" }}
                              className="material-icons"
                            >
                              {" "}
                              delete{" "}
                            </i>
                          </div>
                          <div className="">
                            <input
                              className="color"
                              style={{
                                height: "180px",
                                zIndex: "1",
                                width: "100%",
                                position: "relative",
                                cursor: "pointer",
                                top: "150px",
                              }}
                              id="bug-file"
                              type="file"
                              accept=".jpg,.gif,.png,.chls,.rtf,.txt,.doc,.docx,.zip,.pdf,.mp4,.mov,.json"
                              ref="file"
                              onChange={this.handleAddFile}
                              multiple
                            />
                          </div>
                          <div
                            id="file-wrapper"
                            style={{
                              position: "relative",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            <span
                              style={{
                                transition: "color .3s",
                                position: "absolute",
                                top: "64px",
                                left: "22px",
                                lineHeight: "16px",
                                pointerEvents: "none",
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              Drop or select file <br /> to upload
                            </span>
                            <input
                              id="validator"
                              style={{
                                pointerEvents: "none",
                                zIndex: "0",
                                transition: "background-color .3s",
                                fontFamily: "Arial",
                                fontSize: "15px",
                                height: "135px",
                                borderRadius: "5px",
                                padding: "10px",
                                backgroundColor: "#f5f5f5",
                                marginBottom: 0,
                                textAlign: "center",
                              }}
                              className="file-path validate attachment-drop-area"
                              placeholder=""
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    }
                    images={this.state.ticket.ticket_attachments}
                    newTicket={false}
                  />
                </ErrorBoundary>
              </div>
            </div>
            <div
              aria-hidden="true"
              style={{
                paddingLeft: "25px",
                minHeight: `${
                  (this.state.selectedWatchers.length / 2) * 50 + 50 >= 250
                    ? "250"
                    : (this.state.selectedWatchers.length / 2) * 50 + 115
                }px`,
                maxHeight: "250px",
                marginTop: "5px",
              }}
              className="col edit-ticket-select"
              id="watchersSelect"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="field-title">Watchers</p>
              </div>
              <Select
                key={JSON.stringify(this.state.watcherOptions[0])}
                isMulti
                aria-label="Watcher dropdown for edit bug form"
                hideSelectedOptions={false}
                onChange={this.handleWatcherChange}
                onKeyDown={this.handleSelectKeyDown}
                isClearable={false}
                name="watchers"
                placeholder="Search for watchers"
                styles={{
                  ...TaskSelectStyles,
                  indicatorsContainer: (styles) => {
                    return {
                      marginLeft: "auto",
                    };
                  },
                  option: (styles) => ({
                    ...styles,
                    marginLeft: "-20px",
                    display: "flex",
                  }),
                  valueContainer: (styles, state) => ({
                    ...styles,
                    paddingTop: state.hasValue ? "10px" : "",
                    order: 2,
                    width: "500px",
                    top: state.hasValue ? "40px" : "",
                    left: state.hasValue ? "-10px" : "",
                    position: "absolute",
                    padding: "0 0 0 10px",
                    overflow: state.hasValue ? "auto" : "",
                    display: "flex",
                    maxHeight: "150px",
                    marginTop: "0",
                  }),
                  menu: (styles) => ({
                    ...styles,
                    width: "300px",
                    right: "-30px",
                    zIndex: 3,
                  }),
                  groupHeading: (styles, state) => {
                    return {
                      ...styles,
                      color: "black",
                      fontSize: "12px",
                      paddingLeft: state.children == "Users" ? "25px" : "20px",
                      marginBottom: 0,
                    };
                  },
                }}
                value={this.state.selectedWatchers}
                components={{
                  MenuList: this.MenuList,
                  Menu: this.Menu,
                  Option,
                  GroupHeading,
                  DropdownIndicator: dropdownIndicator,
                  Control: customMultiControl,
                }}
                closeMenuOnSelect={false}
                options={this.state.watcherOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div
              style={{
                paddingLeft: "25px",
                minHeight: `${
                  (this.state.selectedLabels.length / 2) * 50 + 50 >= 250
                    ? "250"
                    : (this.state.selectedLabels.length / 6) * 30 + 105
                }px`,
                maxHeight: "250px",
              }}
              className="col s12 edit-ticket-select"
              id="labelsSelect"
            >
              <p className="field-title">Labels</p>
              <Select
                id="label_select_input"
                isMulti
                aria-label={`Label dropdown, ${
                  this.state.selectedLabels
                    ? this.state.selectedLabels.length
                    : 0
                } label${
                  this.state.selectedLabels &&
                  this.state.selectedLabels.length !== 1
                    ? "s"
                    : ""
                } selected. Type to refine list or press down to open menu`}
                onChange={this.handleLabelChange}
                onKeyDown={this.onSelectDevicesKeyDown}
                ref={this.labelSelectRef}
                name="labels"
                placeholder="Search for or create labels"
                isClearable={false}
                styles={{
                  ...MultiSelectStyles,
                  valueContainer: (styles, state) => ({
                    ...styles,
                    paddingTop: state.hasValue ? "10px" : "",
                    order: -1,
                    top: state.hasValue ? "40px" : "",
                    left: state.hasValue ? "-10px" : "",
                    position: state.hasValue ? "absolute" : "",
                    padding: "0 0 0 10px",
                    overflow: state.hasValue ? "auto" : "",
                    display: "flex",
                    width: "500px",
                  }),
                  indicatorsContainer: (styles) => {
                    return {
                      marginLeft: "auto",
                    };
                  },
                }}
                defaultValue={this.state.selectedLabels}
                closeMenuOnSelect={false}
                options={this.props.ticketLabelOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                components={{
                  Option: this.labelOption,
                  MultiValueRemove: customMultiValueRemove,
                  DropdownIndicator: dropdownIndicator,
                  Control: customMultiControl,
                }}
                noOptionsMessage={() =>
                  this.deleteable &&
                  document.querySelector("#label_select_input input").value ? (
                    <span>
                      Label not found. Click{" "}
                      <span
                        onClick={this.createNewLabel}
                        style={{ color: "#519acc", cursor: "pointer" }}
                      >
                        here
                      </span>{" "}
                      to create new &quot;
                      {
                        document.querySelector("#label_select_input input")
                          .value
                      }
                      &quot; label.
                    </span>
                  ) : this.deleteable ? (
                    <span>
                      No label options. Start typing to create new label.
                    </span>
                  ) : document.querySelector("#label_select_input input")
                      .value ? (
                    <span>Label not found.</span>
                  ) : (
                    <span>No label options.</span>
                  )
                }
              />
            </div>
          </div>
        </form>
        <div>
          <div style={{ marginTop: "5px" }} className="col s12">
            <p className="field-title" style={{ paddingLeft: "0.75em" }}>
              Comments
            </p>
            <div id="ticketCommentBox">
              <ErrorBoundary>
                <CommentForm
                  setLoadingClass={this.props.setLoadingClass}
                  currentUser={this.props.currentUser}
                  setLoadingText={this.props.setLoadingText}
                  users={this.state.userOptions}
                  ticketId={this.state.ticket.id}
                  handler={this.commentHandler}
                  setModal={this.props.setModal}
                />
              </ErrorBoundary>
            </div>
            <ul aria-label="Current ticket comments" id="comments-container">
              {/* {commentsArray.reverse()} */}
              {comments.reverse().map((comment) => {
                return (
                  <Comment
                    handleA11yAttachmentDownload={
                      this.handleA11yAttachmentDownload
                    }
                    deleteAttachmentHandler={this.deleteAttachmentHandler}
                    setModal={this.props.setModal}
                    setLoadingClass={this.props.setLoadingClass}
                    setLoadingText={this.props.setLoadingText}
                    users={this.state.userOptions}
                    key={comment.id}
                    id={comment.id}
                    comment={comment}
                    currentUser={this.props.currentUser}
                    handler={this.commentHandler}
                  />
                );
              })}
            </ul>
            <ErrorBoundary>
              <TicketHistory
                ticketId={this.state.ticket.id}
                deviceOptions={this.historyDeviceOptions}
                comments={comments}
                users={this.props.userOptions}
              />
            </ErrorBoundary>
          </div>
        </div>
        <div
          className="col"
          style={{ marginLeft: "12px", display: "flex", flexDirection: "row" }}
        >
          <button
            aria-hidden="true"
            tabIndex="0"
            className="btn-overflow common-button-cancel"
            onKeyDown={this.handleDeletButtonKeyDown}
            onClick={this.cloneModal}
            style={{ marginRight: "15px" }}
          >
            Duplicate Bug
          </button>
          {this.deleteable && (
            <button
              aria-label="delete bug"
              tabIndex="0"
              className="btn btn-red"
              onKeyDown={this.handleDeletButtonKeyDown}
              onClick={this.deleteTicket}
            >
              Delete
            </button>
          )}
        </div>
        <button
          type="button"
          tabIndex="0"
          aria-label="close edit bug modal"
          className="icon-close-btn"
          style={{
            display: "block",
            position: "absolute",
            top: "16px",
            right: "20px",
          }}
          onClick={this.handleCancelButton}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  }
}

export default TicketForm;
