import React from "react";

const BugsGold = (props) => {
  return (
    <svg viewBox="0 0 1080 1080" {...props}>
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".Skills__Bugs_-_Gold_svg__cls-1{fill:#d1b542}.Skills__Bugs_-_Gold_svg__cls-2{fill:#edcc43}.Skills__Bugs_-_Gold_svg__cls-4{fill:#fff}.Skills__Bugs_-_Gold_svg__cls-6{fill:#4281a5}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <g id="Skills__Bugs_-_Gold_svg__Layer_2" data-name="Layer 2">
        {" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-1"
          d="M488 897.88l-229.25-134.7a98.64 98.64 0 01-48.67-85.81l2-265.86a98.65 98.65 0 0150-85.06l231.29-131.16a98.66 98.66 0 0198.66.76l229.22 134.7a98.66 98.66 0 0148.67 85.82l-2 265.86a98.63 98.63 0 01-50 85.05L586.63 898.64a98.66 98.66 0 01-98.63-.76z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-1"
          d="M178.06 542.03l-39.87 8.52L168 595.84l-17.81 77.26 58.07-8.52 9.29-.77-6.2-121.78h-33.29zM905.61 542.03l42.2 8.52-32.13 45.29 17.8 77.26-58.06-8.52-9.29-.77 6.19-121.78h33.29z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-2"
          d="M488 884L258.75 749.25a98.66 98.66 0 01-48.67-85.82l2-265.86a98.63 98.63 0 0150-85.05l231.29-131.16a98.66 98.66 0 0198.66.76l229.22 134.7a98.64 98.64 0 0148.67 85.81l-2 265.86a98.65 98.65 0 01-50 85.06L586.63 884.71A98.66 98.66 0 01488 884z"
        />{" "}
        <path
          d="M493.38 847.47L288 726.77a88.4 88.4 0 01-43.61-76.9l1.83-238.21A88.42 88.42 0 01291 335.44l207.22-117.52a88.41 88.41 0 0188.4.68L792 339.29a88.43 88.43 0 0143.61 76.9l-1.83 238.22A88.41 88.41 0 01789 730.62L581.78 848.15a88.41 88.41 0 01-88.4-.68z"
          fill="#519acc"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-4"
          d="M538.17 867.11a95.88 95.88 0 01-48.59-13.18L284.19 733.24a96.19 96.19 0 01-47.31-83.42l1.83-238.22a96.17 96.17 0 0148.59-82.68l207.22-117.53a96.2 96.2 0 0195.9.74l205.39 120.7a96.18 96.18 0 0147.31 83.42l-1.83 238.22a96.2 96.2 0 01-48.59 82.68L585.48 854.67a95.85 95.85 0 01-47.31 12.44zm-41-26.11a81.13 81.13 0 0080.9.62L785.3 724.1a81.14 81.14 0 0041-69.75l1.83-238.22a81.13 81.13 0 00-39.91-70.37l-205.4-120.7a81.15 81.15 0 00-80.9-.62L294.7 342a81.16 81.16 0 00-41 69.75l-1.83 238.22a81.12 81.12 0 0039.91 70.37z"
        />{" "}
        <path
          d="M641 370.51c-8.54-11.42-18.28-18.35-29-20.61a34 34 0 00-20.79 2c-5.57-15.59-17.73-27.43-34.26-30.56a1.93 1.93 0 000-.34 29.49 29.49 0 013.51-13.32c5.13-9.71 15-16 29.24-18.66a4.89 4.89 0 00-1.8-9.61c-21.61 4.05-31.55 15.14-36.08 23.71a39.93 39.93 0 00-4.63 17.4l-12.17.11c-.76 0-1.52 0-2.28.08a39.75 39.75 0 00-4.94-17.37c-4.69-8.49-14.83-19.39-36.52-23.05a4.88 4.88 0 10-1.58 9.71c33.3 5.58 33.3 30.26 33.3 31.37a5 5 0 00.06.95c-15.07 4.14-28.57 15.6-33.61 30.26a34.07 34.07 0 00-20.15-1.38c-10.64 2.44-20.26 9.55-28.58 21.12a4.89 4.89 0 007.93 5.71c7.65-10.63 16.57-16.79 25.8-17.81a23.32 23.32 0 0112.79 2.07 48.6 48.6 0 00-.33 5.94l.18 19.61a34.84 34.84 0 00-14.28 1.56c-11 3.54-20.78 12-28.94 25.15a4.89 4.89 0 001.58 6.73 4.82 4.82 0 002.62.73 4.88 4.88 0 004.11-2.31c7-11.36 15.52-18.72 24.52-21.27a24 24 0 0110.48-.7l.2 22.2a45.35 45.35 0 00.4 5.63 31.68 31.68 0 00-13.12 5.19c-10.18 6.9-16.8 19.2-19.65 36.54a4.89 4.89 0 004 5.61 4.76 4.76 0 00.84.07 4.89 4.89 0 004.77-4.1c2.36-14.32 7.68-24.69 15.4-30a21.47 21.47 0 0110-3.76A47.32 47.32 0 00535 466.64h1.22V378a4.7 4.7 0 014.7-4.7 4.71 4.71 0 014.7 4.7v88.53h2.13a47.35 47.35 0 0044.33-32.36 21.18 21.18 0 0110.82 3.61c7.81 5.15 13.32 15.42 15.94 29.7a4.89 4.89 0 009.61-1.77c-3.17-17.28-10-29.45-20.31-36.17a31.52 31.52 0 00-14-5 44.11 44.11 0 00.28-5.54l-.2-22a23.55 23.55 0 0111.25.34c9 2.39 17.66 9.59 24.91 20.83a4.89 4.89 0 008.21-5.3c-8.39-13-18.28-21.29-29.39-24.62a34.37 34.37 0 00-15.07-1.2l-.13-19.79c0-1.65-.12-3.28-.3-4.88a5 5 0 00.58-1 23.21 23.21 0 0112.77-2.38c9.25.85 18.28 6.85 26.12 17.34a4.89 4.89 0 007.83-5.85z"
          fill="#4588ad"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-4"
          d="M641 365.41c-8.54-11.42-18.28-18.35-29-20.6a34.06 34.06 0 00-20.79 2c-5.57-15.59-17.73-27.43-34.26-30.56a1.82 1.82 0 000-.33 29.47 29.47 0 013.51-13.32c5.13-9.71 15-16 29.24-18.67a4.88 4.88 0 10-1.8-9.6c-21.61 4-31.55 15.13-36.08 23.7a40 40 0 00-4.63 17.4l-12.17.11c-.76 0-1.52 0-2.28.08a39.78 39.78 0 00-4.94-17.37c-4.69-8.49-14.83-19.39-36.52-23a4.89 4.89 0 10-1.62 9.64C523 290.48 523 315.16 523 316.27a5.11 5.11 0 00.06 1c-15.07 4.13-28.57 15.6-33.61 30.25a34.07 34.07 0 00-20.15-1.38c-10.64 2.45-20.26 9.55-28.58 21.12a4.89 4.89 0 007.93 5.71c7.65-10.62 16.57-16.79 25.8-17.81a23.32 23.32 0 0112.79 2.07 48.6 48.6 0 00-.33 5.94l.18 19.62a34.67 34.67 0 00-14.28 1.56c-11 3.53-20.78 12-28.94 25.15a4.89 4.89 0 008.31 5.15c7-11.37 15.52-18.73 24.52-21.28a24 24 0 0110.48-.7l.2 22.21a45.32 45.32 0 00.4 5.62 31.82 31.82 0 00-13.12 5.19c-10.18 6.91-16.8 19.2-19.65 36.54a4.9 4.9 0 004 5.62 5.93 5.93 0 00.84.06 4.88 4.88 0 004.77-4.09c2.36-14.33 7.68-24.69 15.4-30a21.47 21.47 0 0110-3.76A47.34 47.34 0 00535 461.55h1.22v-88.62a4.69 4.69 0 014.7-4.7 4.7 4.7 0 014.7 4.7v88.52h2.13a47.33 47.33 0 0044.33-32.35 21 21 0 0110.82 3.6c7.81 5.15 13.32 15.42 15.94 29.7a4.89 4.89 0 004.84 4 6.11 6.11 0 00.84-.08 4.9 4.9 0 003.93-5.69c-3.17-17.28-10-29.46-20.31-36.18a31.49 31.49 0 00-14-5 44.27 44.27 0 00.28-5.55l-.2-22a23.45 23.45 0 0111.25.35c9 2.38 17.66 9.58 24.91 20.82a4.89 4.89 0 008.21-5.3c-8.39-13-18.28-21.29-29.39-24.62a34.35 34.35 0 00-15.07-1.19l-.13-19.8c0-1.64-.12-3.28-.3-4.88a4.79 4.79 0 00.58-1 23.21 23.21 0 0112.79-2.32c9.25.85 18.28 6.85 26.12 17.34a4.89 4.89 0 007.83-5.85z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-1"
          d="M902.5 659.63a2303.14 2303.14 0 00-720.56 1.14q-1-66.69-1-133.49a2434.54 2434.54 0 01721.9-.07q.37 66.24-.34 132.42z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-2"
          d="M902.5 654a2303.14 2303.14 0 00-720.56 1.15q-1-66.71-1-133.49a2434.54 2434.54 0 01721.9-.08q.37 66.21-.34 132.42z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-4"
          d="M413.19 360.9l-44.13-31.35M413.19 364.4a3.56 3.56 0 01-2-.64L367 332.4a3.5 3.5 0 114.06-5.7l44.13 31.35a3.5 3.5 0 01-2 6.35zM391.98 400.67l-92.2 1.37M299.78 405.54a3.5 3.5 0 010-7l92.2-1.37a3.54 3.54 0 013.55 3.45 3.5 3.5 0 01-3.45 3.55l-92.2 1.37zM414.7 445.42l-47.19 20M367.52 468.92a3.5 3.5 0 01-1.37-6.73l47.19-20a3.5 3.5 0 012.73 6.45l-47.19 20a3.49 3.49 0 01-1.36.28zM668.67 360.9l44.13-31.35M668.68 364.4a3.5 3.5 0 01-2-6.35l44.13-31.35a3.5 3.5 0 114.05 5.7l-44.16 31.36a3.51 3.51 0 01-2.02.64zM689.89 400.67l92.19 1.37M782.09 405.54H782l-92.19-1.37a3.5 3.5 0 010-7h.05l92.2 1.37a3.5 3.5 0 010 7zM667.16 445.42l47.19 20M714.35 468.92a3.45 3.45 0 01-1.36-.28l-47.19-20a3.5 3.5 0 012.73-6.45l47.19 20a3.51 3.51 0 01-1.37 6.73z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-1"
          d="M410.44 525.3c17-1.21 28.74 7.8 29.5 22.16.49 8.84-3.59 15.59-10.28 18 8.09 1.47 13.66 8.57 14.2 18.76.75 14.38-9.45 24.5-25.41 25.62q-19.5 1.36-39 3.19a1.77 1.77 0 01-1.88-1.55l-7.65-81a1.91 1.91 0 011.63-2q19.45-1.79 38.89-3.18zM407 559c3.8-.29 6.18-2.67 5.94-6.11s-2.95-5.47-6.78-5.18l-8 .62.9 11.28zm4.45 29.27c3.61-.27 5.94-2.77 5.69-6.33s-2.92-5.72-6.56-5.45q-5.08.38-10.16.78l.93 11.77c3.35-.29 6.71-.55 10.08-.79zM526.08 520.81a2.13 2.13 0 012.21 2.08q.12 25.65.26 51.29c.06 21.8-11.59 33.19-33.82 33.72-22 .58-34.19-10.22-35.47-32q-1.17-25.62-2.32-51.24a2.21 2.21 0 012.1-2.18q11.13-.5 22.26-.84a2.12 2.12 0 012.26 2l1.62 53.48c.17 5.65 3.51 8.87 9 8.73s8.66-3.51 8.55-9q-.54-26.8-1.07-53.62a2.2 2.2 0 012.15-2.13q11.13-.22 22.27-.29zM621.17 555.63a1.91 1.91 0 011.82 1.81l-1.92 39.07a4.07 4.07 0 01-1.63 2.88c-7.74 5.9-19.19 9.11-31 8.75-26.49-.79-44.16-18.45-44.15-45s18.73-44.31 46.82-43.48a53.89 53.89 0 0126.89 8.12 1.88 1.88 0 011 2.22q-3.63 8.88-7.21 17.78a2 2 0 01-2.46.88 40.55 40.55 0 00-18.22-5c-11.78-.36-19.86 7.63-20.08 19.68-.21 12.3 7.37 20.54 18.84 20.89 4.61.15 7.76-.6 9.19-2 .09-2.37.17-4.75.26-7.13-3.85-.14-7.7-.26-11.55-.38-1.13 0-1.87-.54-2-1.4l-1.17-17a1.81 1.81 0 012.09-2q17.31.48 34.48 1.31zm-21.25 24.83v.5a4.17 4.17 0 010-.5zM646.29 583.27c.45-1.2 1.35-1.51 2.31-.84 5.54 3.57 12.23 6.38 16.93 7.11 4 .3 6.74-1.33 7-4.16.59-7.33-34.61-9.38-33.14-36 .91-16.44 14.62-26.67 34.32-25.17 9.19.7 20.53 5.25 28.13 11.28 1 .71 1.26 1.72.82 2.3q-4.89 8.21-9.66 16.48c-.49 1.19-1.41 1.48-2.37.78-5.93-4-13-7.15-17.43-7.86-4-.31-6.64 1.23-6.84 3.93-.51 7.12 35.47 9.66 32.83 36.7-1.58 16.3-15.25 25.93-34.13 24.5-10.31-.79-20.92-4.67-26.81-10-.94-.79-1.26-1.67-.86-2.27q4.41-8.37 8.9-16.78z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-4"
          d="M412.63 520.2c17-1.2 28.75 7.8 29.51 22.16.48 8.85-3.59 15.59-10.28 18 8.09 1.47 13.65 8.57 14.2 18.76.74 14.38-9.45 24.5-25.41 25.62q-19.5 1.37-39 3.19a1.76 1.76 0 01-1.88-1.55l-7.65-80.94a1.91 1.91 0 011.63-2q19.45-1.84 38.88-3.24zm-3.45 33.68c3.8-.28 6.18-2.67 5.94-6.11s-3-5.46-6.79-5.18l-8 .62q.45 5.64.89 11.29c2.63-.22 5.3-.42 7.96-.62zm4.44 29.27c3.62-.26 5.95-2.77 5.7-6.33s-2.92-5.71-6.56-5.45l-10.17.78q.48 5.9.94 11.77zM528.27 515.71a2.14 2.14 0 012.22 2.08q.12 25.65.26 51.29c0 21.8-11.59 33.19-33.82 33.73-22 .57-34.19-10.23-35.47-32q-1.17-25.61-2.32-51.23a2.21 2.21 0 012.1-2.19q11.13-.49 22.25-.84a2.14 2.14 0 012.27 2q.81 26.74 1.61 53.48c.18 5.66 3.52 8.88 9 8.74s8.67-3.52 8.56-9.06l-1.08-53.61A2.23 2.23 0 01506 516q11.14-.22 22.27-.29zM623.37 550.54a1.91 1.91 0 011.82 1.81l-1.92 39.07a4.07 4.07 0 01-1.63 2.87c-7.74 5.91-19.19 9.11-31 8.76-26.48-.8-44.15-18.45-44.15-45s18.74-44.32 46.83-43.49a53.89 53.89 0 0126.87 8.16 1.88 1.88 0 011.06 2.27q-3.63 8.88-7.21 17.78a2 2 0 01-2.46.88 40.55 40.55 0 00-18.22-5c-11.78-.36-19.86 7.62-20.08 19.67-.22 12.3 7.37 20.55 18.83 20.9 4.62.14 7.77-.61 9.2-2q.12-3.57.26-7.14c-3.85-.13-7.7-.26-11.55-.37-1.13 0-1.87-.55-2-1.41l-1.17-17a1.81 1.81 0 012.08-2q17.24.41 34.44 1.24zm-21.25 24.83v.49c-.02-.16-.01-.33 0-.49zM648.48 578.18c.46-1.21 1.36-1.52 2.32-.84 5.54 3.56 12.22 6.37 16.93 7.1 4 .3 6.74-1.33 7-4.15.59-7.34-34.61-9.39-33.14-36 .91-16.45 14.62-26.68 34.32-25.18 9.19.7 20.53 5.26 28.12 11.28 1 .71 1.27 1.73.83 2.3q-4.86 8.25-9.63 16.54c-.49 1.19-1.41 1.47-2.38.77-5.92-4-13-7.14-17.43-7.85-3.95-.31-6.63 1.22-6.83 3.92-.51 7.13 35.47 9.66 32.83 36.7-1.59 16.3-15.26 25.94-34.13 24.5-10.32-.78-20.92-4.67-26.81-9.94-1-.8-1.27-1.68-.86-2.27q4.38-8.48 8.86-16.88z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-6"
          d="M387.85 679c-.76 0-1.52-.43-1.41-1.3v-13.34a1.68 1.68 0 011.73-1.62l27.92-4.33c1-.11 1.52.32 1.52 1.08v72.83a1.57 1.57 0 01-1.52 1.62h-19.15a1.59 1.59 0 01-1.62-1.62V679zM496.82 696.61c0 23.37-14.07 38.95-35.06 38.95s-35.17-15.56-35.17-38.95 14.07-39 35.17-39 35.06 15.62 35.06 39zm-22.94 0c0-11.69-4.87-19.48-12.12-19.48s-12.23 7.79-12.23 19.48 4.87 19.48 12.23 19.48 12.12-7.8 12.12-19.48zM571.82 696.61c0 23.37-14.07 38.95-35.06 38.95s-35.17-15.56-35.17-38.95 14.06-39 35.17-39 35.06 15.62 35.06 39zm-22.94 0c0-11.69-4.87-19.48-12.12-19.48s-12.23 7.79-12.23 19.48 4.87 19.48 12.23 19.48 12.12-7.8 12.12-19.48zM646.81 696.61c0 23.37-14.07 38.95-35.06 38.95s-35.17-15.56-35.17-38.95 14.07-39 35.17-39 35.06 15.62 35.06 39zm-22.94 0c0-11.69-4.87-19.48-12.12-19.48s-12.23 7.79-12.23 19.48 4.87 19.48 12.23 19.48 12.12-7.8 12.12-19.48zM692.27 697.15a1.57 1.57 0 011.62 1.51v10.5a1.59 1.59 0 01-1.62 1.62h-10.39v10.39a1.57 1.57 0 01-1.52 1.62h-10.5a1.59 1.59 0 01-1.62-1.62v-10.39h-10.5a1.57 1.57 0 01-1.51-1.62v-10.5a1.55 1.55 0 011.51-1.51h10.5v-10.5a1.57 1.57 0 011.62-1.51h10.5a1.54 1.54 0 011.52 1.51v10.5z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-4"
          d="M387.85 674.12c-.76 0-1.52-.44-1.41-1.3v-13.31a1.69 1.69 0 011.73-1.63l27.92-4.32c1-.11 1.52.32 1.52 1.08v72.83a1.57 1.57 0 01-1.52 1.62h-19.15a1.59 1.59 0 01-1.62-1.62v-53.35zM496.82 691.76c0 23.37-14.07 39-35.06 39s-35.17-15.58-35.17-39 14.07-39 35.17-39 35.06 15.62 35.06 39zm-22.94 0c0-11.69-4.87-19.48-12.12-19.48s-12.23 7.79-12.23 19.48 4.87 19.48 12.23 19.48 12.12-7.8 12.12-19.48zM571.82 691.76c0 23.37-14.07 39-35.06 39s-35.17-15.58-35.17-39 14.06-39 35.17-39 35.06 15.62 35.06 39zm-22.94 0c0-11.69-4.87-19.48-12.12-19.48s-12.23 7.79-12.23 19.48 4.87 19.48 12.23 19.48 12.12-7.8 12.12-19.48zM646.81 691.76c0 23.37-14.07 39-35.06 39s-35.17-15.58-35.17-39 14.07-39 35.17-39 35.06 15.62 35.06 39zm-22.94 0c0-11.69-4.87-19.48-12.12-19.48s-12.23 7.79-12.23 19.48 4.87 19.48 12.23 19.48 12.12-7.8 12.12-19.48zM692.26 692.3a1.58 1.58 0 011.63 1.51v10.5a1.6 1.6 0 01-1.63 1.62h-10.38v10.39a1.57 1.57 0 01-1.52 1.62h-10.5a1.59 1.59 0 01-1.62-1.62v-10.39h-10.5a1.57 1.57 0 01-1.51-1.62v-10.5a1.55 1.55 0 011.51-1.51h10.5v-10.5a1.57 1.57 0 011.62-1.51h10.5a1.54 1.54 0 011.52 1.51v10.5zM361.46 676.75l4.63 9.37 10.34 1.5-7.49 7.3 1.77 10.3-9.25-4.86-9.25 4.86 1.77-10.3-7.49-7.3 10.34-1.5 4.63-9.37zM724.74 676.75l4.62 9.37 10.35 1.5-7.49 7.3 1.77 10.3-9.25-4.86-9.25 4.86 1.76-10.3-7.48-7.3 10.34-1.5 4.63-9.37z"
        />{" "}
        <circle
          className="Skills__Bugs_-_Gold_svg__cls-2"
          cx={538.1}
          cy={792.22}
          r={39.42}
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-4"
          d="M538.1 833.14a40.92 40.92 0 1140.9-40.92 41 41 0 01-40.9 40.92zm0-78.84a37.92 37.92 0 1037.9 37.92 38 38 0 00-37.9-37.92z"
        />{" "}
        <path
          className="Skills__Bugs_-_Gold_svg__cls-4"
          d="M558.05 772.79a9.28 9.28 0 00-5.15.52v-2.71h-29.59v2.71a9.24 9.24 0 00-5.14-.52 5.67 5.67 0 00-4.48 4.54c-.72 3.14.85 6.74 4.42 10.15a29.5 29.5 0 0010.56 6.28 15 15 0 006.43 5.34c-.39 1.43-1.76 5.56-5.07 8.09h16.18c-3.32-2.53-4.69-6.66-5.08-8.09a15.12 15.12 0 006.44-5.34 29.5 29.5 0 0010.56-6.28c3.56-3.41 5.13-7 4.42-10.15a5.69 5.69 0 00-4.5-4.54zM520.46 785c-2.58-2.46-3.86-5-3.44-6.9a2.31 2.31 0 011.87-2 6.67 6.67 0 014.6 1.06 41.64 41.64 0 002.56 11.71 24.61 24.61 0 01-5.59-3.87zm35.28 0a24.45 24.45 0 01-5.6 3.92 41.31 41.31 0 002.57-11.71 6.65 6.65 0 014.59-1.06 2.32 2.32 0 011.88 2c.42 1.85-.87 4.38-3.44 6.85zM527.71 809.35h20.77v4.49h-20.77z"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default BugsGold;
