import React from 'react'

class HelpIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <path d="M32 10.07A21.93 21.93 0 1053.93 32 22 22 0 0032 10.07z" fill="none"></path> 
        <path d="M32 6.35A25.65 25.65 0 1057.65 32 25.68 25.68 0 0032 6.35zm0 48.08A22.43 22.43 0 1154.43 32 22.45 22.45 0 0132 54.43z" ></path> 
        <path d="M32.45 14.67c-2.79-.21-6.09 1.2-8 2.26l1.08 2.45a10.94 10.94 0 015.57-1.9c4.09.06 6.53 2 6.53 5.29 0 2.65-1.64 4.84-4.46 7.72s-3.89 5.65-3.54 8.44l.06.68h3.21v-.55c-.2-3 .9-5.45 3.67-8.29 3-3.08 4.17-5.5 4.17-8.62 0-4.56-2.74-7.08-8.29-7.48z"></path> 
        <path d="M33.41 40.08H29.3L29.2 39c-.37-2.92.86-5.88 3.66-8.8s4.33-4.91 4.33-7.4c0-3-2.21-4.77-6.06-4.82a10.47 10.47 0 00-5.33 1.83l-.46.27-1.45-3.31.36-.21c2.2-1.25 5.52-2.51 8.23-2.33 5.71.42 8.72 3.17 8.72 8 0 3.26-1.2 5.76-4.29 9-2.68 2.73-3.73 5.1-3.54 7.93zm-3.26-.93h2.29v-.07c-.21-3.1.93-5.69 3.8-8.63s4-5.32 4-8.3c0-4.34-2.57-6.63-7.86-7a14.92 14.92 0 00-7.33 2l.69 1.58A10.94 10.94 0 0131.13 17c4.38.06 7 2.21 7 5.75 0 2.81-1.68 5.08-4.59 8.05-2.6 2.7-3.75 5.41-3.41 8z"></path> 
        <circle cx="17.76" cy="31.87" r="2.38"></circle> 
        <path d="M31.76 48.56a2.69 2.69 0 112.69-2.69 2.68 2.68 0 01-2.69 2.69zm0-4.76a2.07 2.07 0 102.07 2.07 2.07 2.07 0 00-2.07-2.07z"></path> 
      </svg>
    );
  }
}
HelpIcon.defaultProps = {
  color: "#519acc",
  height: "16px",
  svgStyles: {},
  width: "16px",
};
export default HelpIcon