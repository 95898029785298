import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";
import { DropdownSelectCss, DropdownLabel, DropdownName } from "../styles";

const PerformanceTeamDropdown = ({ onTeamSelect, teamsList, queryParams }) => {
  const [selectedTeam, setSelectedTeam] = useState(null);

  const options = useMemo(() => {
    if (!teamsList || teamsList.length === 0) {
      return [];
    }
    return teamsList?.map((team) => ({
      value: team.id,
      label: (
        <Label>
          <ProjectName>{team.name}</ProjectName>
        </Label>
      ),
    }));
  }, [teamsList]);

  useEffect(() => {
    // on fetch status 200, set selected project based on id returned in queryParams
    const teamId = parseInt(queryParams.team_id, 10);
    if (teamId !== selectedTeam?.value) {
      setSelectedTeam(options.find((option) => option.value === teamId));
    }
  }, [queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!teamsList || teamsList.length === 0) {
    return null;
  }

  return (
    <TeamSelectContainer>
      <StyledSelect
        aria-label="Team lookup field, filter performance dashboard view by team"
        placeholder="All teams"
        classNamePrefix="Select"
        onChange={(option) => {
          setSelectedTeam(option || null);
          onTeamSelect(option?.value || null);
        }}
        value={selectedTeam}
        isClearable
        isMulti={false}
        options={options}
        isSearchable={false}
        menuPortalTarget={document.querySelector("body")}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#519acc",
          },
        })}
      />
    </TeamSelectContainer>
  );
};

const TeamSelectContainer = styled.div``;

const StyledSelect = styled(Select)`
  ${DropdownSelectCss}

  width: 328px;

  @media (max-width: 953px) {
    width: 212px;
  }

  .Select__placeholder {
    color: #242424;
  }
`;

const Label = styled(DropdownLabel)``;

const ProjectName = styled(DropdownName)``;

PerformanceTeamDropdown.propTypes = {
  onTeamSelect: PropTypes.func.isRequired,
  teamsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
      identifier: PropTypes.string,
    })
  ).isRequired,
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
  }),
};

export default PerformanceTeamDropdown;
