import React from "react";

const Email = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
    >
      <path
        d="M16 1.625C16 0.73125 15.28 0 14.4 0H1.6C0.72 0 0 0.73125 0 1.625V11.375C0 12.2687 0.72 13 1.6 13H14.4C15.28 13 16 12.2687 16 11.375V1.625ZM14.4 1.625L8 5.6875L1.6 1.625H14.4ZM14.4 11.375H1.6V3.25L8 7.3125L14.4 3.25V11.375Z"
        fill="#519ACC"
      />
    </svg>
  );
};

export default Email;
