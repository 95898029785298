import React from "react";
import SkillsSettingRow from "./skills_setting_row.jsx";
import axios from "axios";
import moment from "moment";
import AvgTimeSelector from "./avg_time_selector";
import BasicSelect from "../common/basic_select";
import { CSSTransition } from "react-transition-group";

class SkillsUserProfile extends React.Component {
  constructor(props) {
    super(props);
    // var arr1 = this.props.currentUser.projects.map((p)=>p.)
    this.state = {
      startDate: moment(new Date()).startOf("week"),
      endDate: moment(new Date()).endOf("week"),
      scope: "last30",
      avgBugs: 0,
      avgCases: 0,
      loading: false,
      disablePerformance: false,
      selectedProject: { label: "All", value: "all" },
      projectOptions: [{ label: "All", value: "all" }],
      // projectOptions: arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
    };
    this.setSelectedUser = this.setSelectedUser.bind(this);
  }

  componentDidMount() {
    this.getSkillAvgs();
    this.getSkillProjectList();
  }

  setSelectedUser() {
    if (!this.props.active) {
      this.props.setSelectedUser(this.props.user);
    }
  }

  addToProject = () => {
    this.props.setModal(true, "add-to-project", {
      projectOptions: this.props.projects,
      selectedUser: this.props.user,
      customClass: "add-to-project-modal",
      updateUserProjects: this.props.updateUserProjects,
    });
  };

  getSkillProjectList = () => {
    axios
      .get(`/get_skill_project_list/${this.props.user.id}.json`)
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            projectOptions: [...this.state.projectOptions, ...res.data],
          });
        } else {
          this.setState({ disablePerformance: true });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  };

  getSkillAvgs = () => {
    this.setState({ loading: true });
    axios
      .get(
        `/get_skill_avgs/${this.props.user.id}.json?scope=${this.state.scope}&start_date=${this.state.startDate}&end_date=${this.state.endDate}&project_id=${this.state.selectedProject.value}`
      )
      .then((res) => {
        this.setState({
          avgBugs: res.data.avg_bugs_per_hour,
          avgCases: res.data.avg_tc_per_hour,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  };

  timeHandler = (scope) => {
    this.setState({ scope: scope }, () => {
      this.getSkillAvgs();
    });
  };

  dateHandler = (startDate, endDate) => {
    this.setState(
      { scope: "custom", startDate: startDate, endDate: endDate },
      () => {
        this.getSkillAvgs();
      }
    );
  };

  handleProject = (selectedProject) => {
    this.setState({ selectedProject }, () => {
      this.getSkillAvgs();
    });
  };

  render() {
    // var projectOptions = arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
    var expertise = Object.keys(this.props.skills)
      .filter((s) => this.props.user[s] === 3)
      .map((s) => this.props.skills[s]);
    return (
      <div>
        <div
          style={{
            minHeight: "85px",
            padding: "0px 30px",
            position: "relative",
            marginBottom: "25px",
          }}
          id="selected-user-heading"
        >
          <span
            style={{
              display: "block",
              position: "absolute",
              top: "-13px",
              backgroundColor: "white",
              fontSize: "9pt",
              paddingRight: "10px",
            }}
          >
            SKILLS PROFILE
          </span>
          <div
            style={{
              height: "1px",
              backgroundColor: "lightgray",
              marginTop: "7px",
              marginBottom: "30px",
            }}
          />
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <img
              style={{
                width: "45px",
                height: "45px",
                borderRadius: "50%",
                display: "inline-block",
                marginRight: "20px",
              }}
              src={
                this.props.user.avatar_image
                  ? this.props.user.avatar_image
                  : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
              }
            />
            <span
              style={{
                display: "flex",
                flexWrap: "wrap",
                position: "relative",
                paddingTop: "15px",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14pt",
                  top: "-14px",
                  position: "absolute",
                }}
              >
                {this.props.user.name}
              </span>
              {(this.props.user.languages.length > 1 ||
                this.props.user.languages[0] !== "") && (
                <>
                  <br />
                  <span style={{ fontSize: "10pt", paddingRight: "15px" }}>
                    Languages: {this.props.user.languages.join(", ")}
                  </span>
                </>
              )}
              {expertise.length > 0 && (
                <span style={{ fontSize: "10pt", whiteSpace: "normal" }}>
                  Expertise: {expertise.join(", ")}
                </span>
              )}
            </span>
          </div>
        </div>
        <div id="skill-performance-container" style={{ padding: "0px 30px" }}>
          <div
            style={{
              padding: "0px 15px",
              backgroundColor: "#519acc",
              color: "white",
              fontSize: "10pt",
            }}
            id="skill-settings-heading"
          >
            <span>Performance</span>
            {this.state.disablePerformance && (
              <div
                style={{
                  verticalAlign: "-4px",
                  fontSize: "17px",
                  marginLeft: "4px",
                }}
                className="material-icons"
              >
                info
              </div>
            )}
          </div>
          <div
            style={{
              opacity: this.state.disablePerformance ? "0.4" : "1",
              pointerEvents: this.state.disablePerformance ? "none" : "all",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "20px 15px 0",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                margin: "0 20px 20px 0",
                alignItems: "baseline",
              }}
            >
              <p style={{ fontSize: "11pt", marginRight: "10px" }}>Range</p>
              <AvgTimeSelector
                key={1}
                handler={this.timeHandler}
                customHandler={this.dateHandler}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                margin: "0 0 20px 0",
                alignItems: "baseline",
              }}
            >
              <p style={{ fontSize: "11pt", marginRight: "10px" }}>Project</p>
              <BasicSelect
                arrowStyle={{ position: "relative", top: "3px" }}
                options={this.state.projectOptions}
                onChange={this.handleProject}
                selectedOption={this.state.selectedProject}
                selectedTextStyle={{ color: "#242424", fontWeight: "500" }}
                listItemStyle={{
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  color: "#242424",
                }}
                placeholder="Select Project"
                id="1"
                width="200px"
                maxHeight="155px"
                // arrowStyle={{position:"absolute", top:"5px", right:"2px"}}
              />
            </div>
          </div>
          <div
            style={{
              margin: "0 0 25px",
              padding: "0 15px",
              display: "flex",
              opacity: this.state.disablePerformance ? "0.4" : "1",
              pointerEvents: this.state.disablePerformance ? "none" : "all",
            }}
          >
            <span style={{ paddingRight: "20px", marginRight: "25px" }}>
              Hourly Bug Average:{" "}
              <span style={{ color: "#519acc" }}>
                {this.state.avgBugs && !this.state.disablePerformance
                  ? this.state.avgBugs.toFixed(2)
                  : "0.00"}
              </span>
            </span>
            <span>
              Hourly Test Case Average:{" "}
              <span style={{ color: "#519acc" }}>
                {this.state.avgCases && !this.state.disablePerformance
                  ? this.state.avgCases.toFixed(2)
                  : "0.00"}
              </span>
            </span>
            <CSSTransition
              unmountOnExit
              in={this.state.loading}
              timeout={500}
              classNames="node-fade"
            >
              <div
                style={{ margin: "2px 0 0 10px" }}
                id="first-build-spinner"
              />
            </CSSTransition>
          </div>
        </div>
        <div style={{ padding: "0px 30px" }} id="skill-settings-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 15px",
              backgroundColor: "#519acc",
              color: "white",
              fontSize: "10pt",
            }}
            id="skill-settings-heading"
          >
            <span>Experience</span>
            <div>
              <span>Trained</span>
              <span style={{ padding: "0px 30px" }}>Experience</span>
              <span>Expert</span>
            </div>
          </div>
          {Object.keys(this.props.skills).map((s) => (
            <SkillsSettingRow
              updateUserLevel={this.props.updateUserLevel}
              key={s}
              skillValue={s}
              label={this.props.skills[s]}
              userLevel={this.props.user[s]}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default SkillsUserProfile;
