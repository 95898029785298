import React from 'react'

class StarIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        stroke={this.props.stroke}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        className="icons"
      >
        <circle style={{fill:"#00100f", isolation:"isolate", opacity:"0.2"}} cx="32.18" cy="33.08" r="28.42"/>
        <circle style={{fill:"#519acc"}} cx="32.18" cy="31.53" r="28.42"/>
        <path style={{isolation:"isolate", opacity:"0.2"}} d="M32.18,2.63A28.43,28.43,0,0,0,3.76,31.05c0,.4,0,.8.05,1.19a28.39,28.39,0,0,1,56.75-.35c0-.27,0-.56,0-.84A28.42,28.42,0,0,0,32.18,2.63Z"/>
        <polygon style={{fill:"#00100f", isolation:"isolate", opacity:"0.2"}} points="32.15 15.47 37.39 26.09 49.11 27.79 40.63 36.06 42.63 47.73 32.15 42.22 21.66 47.73 23.67 36.06 15.19 27.79 26.91 26.09 32.15 15.47"/>
        <polygon points="32.15 13.42 37.39 24.04 49.11 25.74 40.63 34.01 42.63 45.68 32.15 40.17 21.66 45.68 23.67 34.01 15.19 25.74 26.91 24.04 32.15 13.42"/>
      </svg>
    );
  }
}
StarIcon.defaultProps = {
  color: "#fff",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default StarIcon