import React, { Component } from 'react'

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

class TimedInput extends Component {
  state = {
    value: this.props.value
  }

  timer = null

  handleChange = e => {
    clearTimeout(this.timer)
    if (e.target.value.includes("<")||e.target.value.includes(">")){
      e.target.value = e.target.value.replace(/</g, "")
      e.target.value = e.target.value.replace(/>/g, "")
    }
    this.setState({ value: e.target.value })
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
  }

  handleKeyDown = e => {
    
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer)
      this.triggerChange()
    } else if(this.props.version) {
      
    } else {
      this.props.updatedHandler(true)
    }
  }

  triggerChange = () => {
    const { value } = this.state

    this.props.onChange(value)
  }

  render() {
    const { ...rest } = this.props
    if (this.props.textarea && this.props.textarea === true) {
      return (
        <textarea
          type={rest.type}
          style={{height: "60px",
            overflow: "hidden",
            wordBreak: "break-all"}}
          className={rest.className}
          placeholder={rest.placeholder}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          maxLength={220}
        />
      )
    } else {
    return (
      <input
        type={rest.type}
        className={rest.className}
        placeholder={rest.placeholder}
        value={this.state.value}
        style={{height:"60px"}}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        maxLength={200}
      />
    )
  }
  }
}

export default TimedInput