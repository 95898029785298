import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import EmptyPieIcon from "../../icons/performance_bio/EmptyPie";

ChartJS.register(ArcElement, Tooltip);

const colorMap = {
  iOS: "#62C6FF",
  Android: "#0DC958",
  "Web & Mobile": "#275BBF",
  "Web (MoWeb)": "#FCA804",
  "AR/VR": "#ED4949",
  "Test Documentation": "#275BBF",
  Regressions: "#ED4949",
  "New Features": "#FCA804",
  "Smoke Tests": "#62C6FF",
  "Bug Verification": "#0DC958",
  "Exploratory Testing": "#F376DF",
  Other: "#FF7438",
};

const defaultColors = [
  "#00A3FF",
  "#00B16A",
  "#6642FF",
  "#FF7529",
  "#FEBE4E",
  "#E84393",
];

const PieBreakdown = ({ data, title, parentFilterValue }) => {
  const hasData = !!data && data.length > 0;

  const dataWithLabelsAndColors = useMemo(() => {
    if (!hasData) {
      return [
        {
          id: 1,
          label: "No Data to Display",
          count: 1,
          color: "#D9D9D9",
        },
      ];
    }

    const total = data.reduce((acc, item) => acc + item.count, 0);

    return data.map((item) => {
      const ratio = ((item.count / total) * 100).toFixed(2);
      return {
        ...item,
        color: colorMap[item.label] || defaultColors.shift(),
        legendLabel: `${ratio}%`,
        tooltipLabel: `${item.label}: ${ratio}%`,
      };
    });
  }, [data, hasData]);

  const chartData = {
    labels: dataWithLabelsAndColors.map((item) => item.label),
    datasets: [
      {
        data: dataWithLabelsAndColors.map((item) => item.count),
        backgroundColor: dataWithLabelsAndColors.map((item) => item.color),
        cutout: "70%",
        tooltip: {
          callbacks: {
            label: (context) => {
              return dataWithLabelsAndColors[context.dataIndex].tooltipLabel;
            },
          },
        },
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Container>
      {hasData ? (
        <>
          <DataContainer>
            <WidgetTitle>{title}</WidgetTitle>
            {dataWithLabelsAndColors.map((item) => (
              <LabelContainer key={item.id}>
                <LabelColor color={item.color} />
                <Label>{item.label}</Label>
                <LabelText>{item.legendLabel}</LabelText>
              </LabelContainer>
            ))}
          </DataContainer>
          <DoughnutContainer>
            <Doughnut data={chartData} options={options} />
          </DoughnutContainer>
        </>
      ) : (
        <>
          <DataContainer>
            <WidgetTitle>{title}</WidgetTitle>
            <LabelContainer>
              <NoDataLabel>
                No EOD Report data found. Please verify that there are valid EOD
                Report submissions for the selected {parentFilterValue}, select
                a different time range, or choose another filter.
              </NoDataLabel>
            </LabelContainer>
          </DataContainer>
          <DoughnutContainer>
            <EmptyPieIcon />
          </DoughnutContainer>
        </>
      )}
    </Container>
  );
};

PieBreakdown.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  parentFilterValue: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const DataContainer = styled.div`
  margin-right: 10px;
  width: 100%;
`;

const LabelContainer = styled.div``;

const NoDataLabel = styled.p`
  color: #000;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const WidgetTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  font-family: Manrope;
  margin-bottom: 15px;
`;

const LabelColor = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  margin-right: 5px;
  border-radius: 50%;
`;

const Label = styled.span`
  margin-left: 10px;
`;

const LabelText = styled.span`
  float: right;
  font-size: 15px;
  font-weight: 700;
`;

const DoughnutContainer = styled.div`
  width: 100%;
  height: 236px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PieBreakdown;
