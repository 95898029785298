import React from "react";
import store from "../store";
import { Provider } from "react-redux";

const ReduxProvider = ({ children, ...rest }) => {
  // const renderChildren = () => {
  //   return React.Children.map(children, (child) => {
  //     return React.cloneElement(child, {
  //       ...rest,
  //     });
  //   });
  // };

  // return <Provider store={store}>{renderChildren()}</Provider>;
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
