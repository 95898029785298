import React from 'react'

class UndoIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.94 23.28"
        className="icons"
      >
        <g id="Layer_3" data-name="Layer 3">
          <path d="M10.7,0a.7.7,0,0,0-.36.14L.37,6.79A.85.85,0,0,0,.14,8a1,1,0,0,0,.23.22l10,6.65a.84.84,0,0,0,1.16-.24.88.88,0,0,0,.14-.45V11.38a12.5,12.5,0,0,1,3.77.41,3.05,3.05,0,0,1,1.48,1,3,3,0,0,1,.57,2c0,3.76-1.22,5-2.41,7.34a.83.83,0,0,0,.35,1.12.8.8,0,0,0,.66.05,16.57,16.57,0,0,0,6.15-3.57,7.75,7.75,0,0,0,2.73-5.53c0-2.81-1.67-5.35-4.23-7.17a17.79,17.79,0,0,0-9.07-3V.84A.84.84,0,0,0,10.81,0ZM10,2.38V4.72a.84.84,0,0,0,.81.83,16.36,16.36,0,0,1,9,2.76C22,9.9,23.28,12,23.28,14.13a6.15,6.15,0,0,1-2.21,4.33,13.86,13.86,0,0,1-3.45,2.31,11.89,11.89,0,0,0,1.5-6,4.73,4.73,0,0,0-.92-3A4.69,4.69,0,0,0,16,10.22,15.81,15.81,0,0,0,10.8,9.7a.83.83,0,0,0-.83.83v2.05L2.31,7.49,10,2.38Z"/>
        </g>
      </svg>
    );
  }
}
UndoIcon.defaultProps = {
  color: "white",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default UndoIcon