import React from 'react'

class InterestsIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
        aria-hidden="true"
      >
        <path d="M101.86,115.22a19.15,19.15,0,0,1-17.22-10.86L82.09,99a11.05,11.05,0,0,0-10-6.28H55.86a11.07,11.07,0,0,0-10,6.28l-2.55,5.36A19.07,19.07,0,0,1,7.07,96.15V63.73A23.22,23.22,0,0,1,30.24,40.55H97.78a23.18,23.18,0,0,1,23.15,23.18V96.15A19.07,19.07,0,0,1,101.86,115.22Zm-46-30.55H72.11A19.18,19.18,0,0,1,89.33,95.54l2.55,5.36a11,11,0,0,0,21-4.75V63.73A15.12,15.12,0,0,0,97.76,48.6H30.22A15.14,15.14,0,0,0,15.09,63.73V96.15a11,11,0,0,0,21,4.75l2.55-5.36A19.21,19.21,0,0,1,55.83,84.67Z"/>
        <path d="M35.8,80.76a4,4,0,0,1-4-4h0V60.05a4,4,0,0,1,8.05,0V76.73a4,4,0,0,1-4,4Z"/>
        <path d="M44.14,72.42H27.45a4,4,0,0,1,0-8H44.14a4,4,0,0,1,0,8Z"/>
        <path d="M93.33,80.76a1.8,1.8,0,0,1-.4,0,2.57,2.57,0,0,1-.4,0,1.89,1.89,0,0,1-.38-.11c-.11,0-.24-.08-.38-.13l-.34-.16a2.56,2.56,0,0,1-.35-.22l-.32-.24a1.29,1.29,0,0,1-.3-.27,1.48,1.48,0,0,1-.27-.29L90,78.93a3.37,3.37,0,0,1-.21-.34c-.06-.11-.11-.25-.16-.35a2,2,0,0,1-.14-.38c0-.13-.08-.27-.11-.37a2.87,2.87,0,0,1,0-.41c0-.13,0-.26,0-.4a1.8,1.8,0,0,1,0-.4,2.57,2.57,0,0,1,0-.4,1.89,1.89,0,0,1,.11-.38,1.86,1.86,0,0,1,.14-.37,1.92,1.92,0,0,1,.16-.35,2.16,2.16,0,0,1,.21-.35l.24-.32a1.29,1.29,0,0,1,.27-.3c.11-.08.19-.19.3-.27l.32-.24a2.69,2.69,0,0,1,.35-.21c.1-.06.24-.11.34-.16a1.57,1.57,0,0,1,.38-.14,3.44,3.44,0,0,0,.38-.11,2.18,2.18,0,0,1,.4-.05,3.57,3.57,0,0,1,.8,0,2.57,2.57,0,0,1,.4.05,1.89,1.89,0,0,1,.38.11c.11.06.24.08.38.14a2.16,2.16,0,0,1,.34.16,5.22,5.22,0,0,1,.68.43,1.8,1.8,0,0,1,.29.26,1.29,1.29,0,0,1,.27.3l.24.32c.08.11.13.22.21.35s.11.24.17.35a2.72,2.72,0,0,1,.13.37,3.18,3.18,0,0,1,.11.38,2.73,2.73,0,0,1,0,.4,1.84,1.84,0,0,1,0,.4,1.89,1.89,0,0,1,0,.41,2.91,2.91,0,0,1,0,.4,1.79,1.79,0,0,1-.11.37,2.43,2.43,0,0,1-.13.38c-.06.13-.11.24-.17.35a2.16,2.16,0,0,1-.21.35l-.24.32a1.24,1.24,0,0,1-.27.29,3.84,3.84,0,0,1-.29.27,3.71,3.71,0,0,1-.68.43c-.1.06-.24.11-.34.16a1.57,1.57,0,0,1-.38.14c-.13,0-.27.08-.38.1a1.53,1.53,0,0,1-.4.06A.72.72,0,0,1,93.33,80.76Z"/>
        <path d="M101.67,72.42a1.8,1.8,0,0,1-.4,0,2.73,2.73,0,0,1-.4,0,1.89,1.89,0,0,1-.38-.11,1.86,1.86,0,0,1-.37-.14,1.92,1.92,0,0,1-.35-.16,2.16,2.16,0,0,1-.35-.21l-.32-.24a1.29,1.29,0,0,1-.3-.27,2.7,2.7,0,0,1-.27-.3l-.24-.32a2.69,2.69,0,0,1-.21-.35,3.67,3.67,0,0,1-.3-.72c0-.13-.08-.24-.1-.38a1.76,1.76,0,0,1-.06-.4c0-.13,0-.27,0-.4a1.8,1.8,0,0,1,0-.4,1.93,1.93,0,0,1,.06-.41,1.44,1.44,0,0,1,.1-.37,2.22,2.22,0,0,1,.14-.38,3.06,3.06,0,0,1,.37-.69l.24-.33a1.48,1.48,0,0,1,.27-.29,1.29,1.29,0,0,1,.3-.27l.32-.24a3.59,3.59,0,0,1,.35-.21c.1-.06.24-.11.35-.17a2.72,2.72,0,0,1,.37-.13c.14,0,.27-.08.38-.11a3.57,3.57,0,0,1,1.18,0,2.73,2.73,0,0,1,.4,0,2.11,2.11,0,0,1,.38.11,2.72,2.72,0,0,1,.37.13l.35.17a2.16,2.16,0,0,1,.35.21l.32.24a1.82,1.82,0,0,1,.29.27,3.45,3.45,0,0,1,.27.29l.24.33a2.22,2.22,0,0,1,.22.34c.08.14.11.25.16.35a1.55,1.55,0,0,1,.13.38c0,.13.08.24.11.37a1.93,1.93,0,0,1,.06.41,4,4,0,0,1,0,.8,1.76,1.76,0,0,1-.06.4,1.41,1.41,0,0,1-.11.38,2.14,2.14,0,0,1-.13.38,3.68,3.68,0,0,1-.16.34,1.46,1.46,0,0,1-.22.35l-.24.32a1.29,1.29,0,0,1-.27.3,1.24,1.24,0,0,1-.29.27l-.32.24c-.11.08-.22.13-.35.21l-.35.16a1.86,1.86,0,0,1-.37.14l-.38.11a3.89,3.89,0,0,1-.78.08Z"/>
        <path d="M85.26,72.42H85a4,4,0,0,1,0-8h.27a4,4,0,0,1,0,8Z"/>
        <path d="M93.33,64.07a2.68,2.68,0,0,1-.4,0,1.76,1.76,0,0,1-.4-.06,1.41,1.41,0,0,1-.38-.1,7.33,7.33,0,0,1-.72-.3,1.8,1.8,0,0,1-.35-.21,4,4,0,0,1-1.1-1.1c-.08-.11-.14-.22-.22-.35a4.11,4.11,0,0,1-.29-.73c0-.13-.08-.24-.11-.37a1.81,1.81,0,0,1,0-.38c0-.13,0-.26,0-.4a1.8,1.8,0,0,1,0-.4,1.75,1.75,0,0,1,0-.4,1.41,1.41,0,0,1,.11-.38c.08-.24.19-.48.29-.72a4.58,4.58,0,0,1,.43-.65,3.45,3.45,0,0,1,.27-.29,5.1,5.1,0,0,1,.62-.51,2.67,2.67,0,0,1,.35-.22c.1,0,.24-.1.34-.16a2.12,2.12,0,0,1,.38-.13c.13,0,.24-.08.38-.11a1.75,1.75,0,0,1,.4,0,3.57,3.57,0,0,1,.8,0,1.75,1.75,0,0,1,.4,0,1.89,1.89,0,0,1,.38.11c.11.05.24.08.38.13l.34.16a2.56,2.56,0,0,1,.35.22l.33.24a3,3,0,0,1,.56.56l.24.33a3.37,3.37,0,0,1,.21.34c.06.11.11.25.17.35a1.88,1.88,0,0,1,.13.38c0,.13.08.24.11.37a2.87,2.87,0,0,1,0,.41,1.75,1.75,0,0,1,0,.4,1.8,1.8,0,0,1,0,.4,2.18,2.18,0,0,1,0,.38,2,2,0,0,1-.11.37,2.84,2.84,0,0,1-.13.38c-.06.13-.11.24-.17.35a2.09,2.09,0,0,1-.21.34,3.94,3.94,0,0,1-.8.89,3.71,3.71,0,0,1-.68.43,4.34,4.34,0,0,1-.72.29l-.38.11a1.75,1.75,0,0,1-.4,0Z"/>
        <path d="M64,48.6a4,4,0,0,1-4-4h0v-8.2a11,11,0,0,1,11-11,2.94,2.94,0,0,0,3-2.95V16.79a4,4,0,1,1,8,0v5.63a11,11,0,0,1-11,11h0A2.94,2.94,0,0,0,68,36.37v8.2a4,4,0,0,1-4,4Z"/>
      </svg>
    );
  }
}
InterestsIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default InterestsIcon