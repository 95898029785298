import React, { useMemo } from "react";
import PropTypes from "prop-types";

const LikeIcon = ({ height, width, color, svgStyles, filled }) => {
  const path = useMemo(() => {
    if (filled) {
      return "M2.54545 4.8V12H0V4.8H2.54545ZM5.09091 12C4.75336 12 4.42964 11.8736 4.19095 11.6485C3.95227 11.4235 3.81818 11.1183 3.81818 10.8V4.8C3.81818 4.47 3.95818 4.17 4.19364 3.954L8.38091 0L9.05545 0.636C9.22727 0.798 9.33545 1.02 9.33545 1.266L9.31636 1.458L8.71182 4.2H12.7273C13.0648 4.2 13.3885 4.32643 13.6272 4.55147C13.8659 4.77652 14 5.08174 14 5.4V6.6C14 6.756 13.9682 6.9 13.9109 7.038L11.9891 11.268C11.7982 11.7 11.3464 12 10.8182 12H5.09091Z";
    }

    return "M2.54545 4.8V12H0V4.8H2.54545ZM5.09091 12C4.75336 12 4.42964 11.8736 4.19095 11.6485C3.95227 11.4235 3.81818 11.1183 3.81818 10.8V4.8C3.81818 4.47 3.95818 4.17 4.19364 3.954L8.38091 0L9.05545 0.636C9.22727 0.798 9.33545 1.02 9.33545 1.266L9.31636 1.458L8.71182 4.2H12.7273C13.0648 4.2 13.3885 4.32643 13.6272 4.55147C13.8659 4.77652 14 5.08174 14 5.4V6.6C14 6.756 13.9682 6.9 13.9109 7.038L11.9891 11.268C11.7982 11.7 11.3464 12 10.8182 12H5.09091ZM5.09091 10.8H10.8373L12.7273 6.6V5.4H7.13364L7.85273 2.208L5.09091 4.818V10.8Z";
  }, [filled]);
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgStyles}
    >
      <path d={path} fill={color} />
    </svg>
  );
};

LikeIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  svgStyles: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
  filled: PropTypes.bool,
};

LikeIcon.defaultProps = {
  height: "14px",
  width: "12px",
  svgStyles: {},
  color: "#519acc",
};

export default LikeIcon;
