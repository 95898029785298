import React from "react";
import axios from "axios";
import Swal from "sweetalert2";

class CheckoutStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lineData: [
        [43, 34, 32, 23, 22, 3],
        [23, 14, 12, 3, 2, 33],
      ],
      barData: [45, 3, 5, 6, 13],
      rank: 1,
      rate: 1,
      scope: "last30",
      duration: 1,
      deviceName: "",
      innerDevice: null,
      location: true,
      loading: true,
      options: [],
      activeDevice: this.props.activeChartDevice,
      activeOption: null,
      currentOs: "",
    };
    this.stats = React.createRef();
    this.statsContainer = React.createRef();
  }

  osHandler = (os) => {
    this.setState({ currentOs: os });
  };

  getDeviceData = (deviceId, scope) => {
    this.setState({ loading: true });
    axios
      .get(`/devices/${deviceId}?scope=${scope}.json`, {})
      .then((res) => {
        this.setState({ activeDevice: res, loading: false });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Device Error",
          text: "There was a problem retrieving this device",
          customClass: "Swal2-error-modal",
        });
      });
  };

  getDeviceOptions = () => {
    this.setState({ loading: true });
    if (this.props.activeChartDevice == 0) {
      var url = `/devices/options/${this.props.activeFilter}.json?scope=${this.state.scope}&chartdata=true&charttype=stats&options=true`;
    } else {
      var url = `/devices/options/${this.props.activeFilter}.json?scope=${this.state.scope}&chartdata=true&charttype=stats&singleDevice=true&deviceId=${this.props.activeChartDevice}&options=true`;
    }
    axios
      .get(url, {})
      .then((res) => {
        var options = res.data.options;
        if (
          this.props.activeChartDevice != 0 &&
          !res.data.options.includes(res.data.deviceData[5])
        ) {
          options.push(res.data.deviceData[5]);
          this.setState({
            activeOS: res.data.deviceData[5].value,
            loading: false,
          });
        } else if (
          this.props.activeChartDevice != 0 &&
          res.data.options.includes(res.data.deviceData[5])
        ) {
          this.setState({
            activeOS: res.data.deviceData[5].value,
            loading: false,
          });
        }
        this.setState({
          loading: false,
          options: options,
          rate: res.data.deviceData[0],
          duration: res.data.deviceData[1],
          rank: res.data.deviceData[2],
          deviceName: res.data.deviceData[3],
          location: res.data.deviceData[4],
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Chart data error",
          text: "There was a problem retrieving chart data",
          customClass: "Swal2-error-modal",
        });
      });
  };

  componentDidMount() {
    this.setState({ loading: true });
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.getDeviceOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeFilter != prevProps.activeFilter) {
      this.getDeviceOptions();
    }

    if (this.props.activeChartDevice != prevProps.activeChartDevice) {
      this.getDeviceOptions();
    }
  }

  switcherHandler = (option) => {
    this.setState({ activeOS: option, innerDevice: 0 });
  };

  resetVersion = () => {
    this.props.chartDeviceReset();
    this.switcherHandler(null);
  };

  loadingHandler = (option) => {
    this.setState({ loading: option });
  };

  innerDeviceHandler = (id) => {
    this.setState({ innerDevice: id });
  };

  render() {
    var titles = {
      0: "All Devices",
      1: "Windows",
      2: "MacOS",
      3: "iOS",
      4: "Android",
      5: "Wearables",
    };

    return (
      <div ref={this.statsContainer} className="slide-right" id="checkoutStats">
        {this.state.loading && (
          <div id="loadingBlurOverlay">
            <div
              style={{ position: "relative", right: "30%", top: "30%" }}
              id="test-step-bugs-loader"
            ></div>
          </div>
        )}
        <div ref={this.stats} style={{ overflow: "hidden", maxHeight: "80vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {this.props.activeChartDevice != 0 ? (
              <>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    marginRight: "4px",
                  }}
                >
                  {this.state.deviceName}
                </p>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    marginRight: "10px",
                    color: "#519acc",
                  }}
                >
                  {" "}
                  -{" "}
                  {this.state.options.length >= 1
                    ? this.state.options[0].label
                    : ""}
                </p>
              </>
            ) : (
              <>
                <p
                  style={{
                    fontWeight: 600,
                    marginRight: "4px",
                    fontSize: "20px",
                  }}
                >
                  {titles[this.props.activeFilter]}
                </p>
                {this.state.activeOS == null ? (
                  <DeviceSwitcher
                    handler={this.switcherHandler}
                    options={this.state.options}
                    activeOS={this.state.activeOS}
                  />
                ) : (
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "20px",
                      marginRight: "10px",
                      color: "#519acc",
                    }}
                  >
                    {" "}
                    -{" "}
                    {this.state.options.length >= 1
                      ? this.state.options[0].label
                      : ""}
                  </p>
                )}
              </>
            )}
            {this.state.activeOS != null && (
              <p
                style={{
                  paddingLeft: "5px",
                  color: "#519acc",
                  cursor: "pointer",
                }}
                onClick={this.resetVersion}
              >
                Clear
              </p>
            )}
            {this.props.activeChartDevice != 0 && (
              <p
                style={{
                  marginLeft: "auto",
                  color: "lightgrey",
                  fontWeight: 600,
                  fontSize: "16px",
                  padding: "5px",
                  marginRight: "10px",
                }}
              >
                {this.state.location ? "Main Building" : "Annex"}
              </p>
            )}
          </div>
          <DeviceStats
            activeDevice={this.props.activeChartDevice}
            activeOption={this.props.activeChartDevice}
            rate={this.state.rate}
            rank={this.state.rank}
            duration={this.state.duration}
          />
          <DeviceChart
            containerRef={this.statsContainer}
            loadingHandler={this.loadingHandler}
            activeOS={this.state.activeOS}
            activeDevice={this.props.activeChartDevice}
            scope={this.state.scope}
            activeFilter={this.props.activeFilter}
            data={this.state.lineData}
            type={"line"}
          />
          <DeviceChart
            containerRef={this.statsContainer}
            osText={
              this.state.options.length >= 1 ? this.state.options[0].label : ""
            }
            innerDeviceHandler={this.innerDeviceHandler}
            innerDevice={this.state.innerDevice}
            loadingHandler={this.loadingHandler}
            activeOS={this.state.activeOS}
            activeDevice={this.props.activeChartDevice}
            scope={this.state.scope}
            activeFilter={this.props.activeFilter}
            data={this.state.barData}
            type={"bar"}
          />
        </div>
      </div>
    );
  }
}

export default CheckoutStats;
