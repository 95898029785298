import React from "react";
import PropTypes from "prop-types";
import {
  BadgeContainer,
  BadgeImage,
  BadgeName,
  BadgeEarned,
} from "../EarnedBadges";

const UnearnedBadges = ({ unearnedBadges }) => {
  if (!unearnedBadges) return null;

  return (
    <>
      {unearnedBadges.map((b) => {
        return (
          <BadgeContainer key={b.identity}>
            <BadgeImage
              $isTimeDependent={false}
              aria-hidden="true"
              src={b.url}
            />
            <BadgeName>{b.name}</BadgeName>
            <BadgeEarned>
              {b.details &&
              (b.name.includes("Expert") || b.name === "Tester Trial")
                ? "Not earned yet"
                : `${b.details} to earn`}
            </BadgeEarned>
          </BadgeContainer>
        );
      })}
    </>
  );
};

UnearnedBadges.propTypes = {
  unearnedBadges: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      details: PropTypes.string,
      id: PropTypes.number,
      identity: PropTypes.string,
      name: PropTypes.string,
      time_dependent: PropTypes.bool,
      url: PropTypes.string,
    })
  ),
};

export default UnearnedBadges;
