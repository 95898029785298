import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import SubNavbar from "../common/sub_navbar";
import ReactModalV2 from "../modals/react_modal_v2";
import EodReport from "../modals/eod_report";
import GenerateReport from "../modals/generate_report";
import OnboardingWelcome from "../modals/onboarding_welcome";
import ViewEod from "../modals/view_eod";
import BugsRow from "./bugs_row";
import MediaRow from "./media_row";
import TestPassRow from "./test_pass_row";
import ActivityRow from "./activity_row";
import EodRow from "./eod_row";
import PerformanceRow from "./PerformanceRow";

const modals = {
  "eod-report": EodReport,
  "generate-report": GenerateReport,
  "onboarding-welcome": OnboardingWelcome,
  "view-report": ViewEod,
};

moment.locale("en-US");
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: "https://662459c60a3a405daa0c3aa17f38ee25@sentry.io/2023356",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        if (event.exception.values.some((x) => x.handled === false)) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            title: "It looks like we dozed off and there was an error.",
            subtitle: "The Dev team has been notified.",
          });
        }
      }
      return event;
    },
  });
} else {
  Sentry.init({
    dsn: "https://3c2ed8a8f50148e582c6050933c50c31@sentry.io/5372657",
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: "It looks like we dozed off and there was an error.",
          subtitle: "The Dev team has been notified.",
        });
      }
      return event;
    },
  });
}

const TopButton = styled.button`
  display: block;
  position: absolute;
  top: 38px;
  color: white;
  cursor: pointer;
  width: 145px !important;
  > img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    vertical-align: text-top;
  }
`;
class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      testPassCount: this.props.testPassCount,
      bugCount: this.props.bugCount,
      activityCount: this.props.activityCount,
      eodReportCount: this.props.eodReportCount,
      project: this.props.project,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      hasBugs: this.props.project.has_bugs,
      hasTestCases: this.props.project.has_test_cases,
      media: this.props.project.media,
    };
  }

  componentDidMount() {
    Sentry.setUser({
      username: this.props.currentUser.name,
      id: this.props.currentUser.id,
    });

    Sentry.setTag("page", "dashboard");
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    if (
      !this.props.currentUser.onboarded ||
      this.props.currentUser.email == "client@plusqa.com"
    ) {
      this.setModal(true, "onboarding-welcome", {
        customClass: "onboarding-welcome-modal",
      });
    }
    // Add event listener for popstate event
    window.addEventListener("popstate", (e) => this.handlePopState(e));
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handlePopState);
  }

  handlePopState = (e) => {
    const projectId = e.target.location.pathname.split("/")[2];

    this.setState({ loading: true });

    axios
      .get(`/v1/projects/${projectId}/dashboard.json`)
      .then((response) => {
        const { project } = response.data;
        project.value = project.id;
        project.label = project.name;
        project.automation = project.automation;
        this.setState({
          project,
          testPassCount: response.data.testPassCount,
          activityCount: response.data.activityCount,
          eodReportCount: response.data.eodReportCount,
          bugCount: response.data.bugCount,
          hasBugs: project.has_bugs,
          hasTestCases: project.has_test_cases,
          media: project.media,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  handleReportModal = () => {
    this.setModal(true, "generate-report", {
      project: this.state.project,
      customClass: "eod-report-modal hide-scrollbar",
      backdropClickCheck: {
        title: "Exit Client Report",
        text: "Are you sure that you want to exit? Any changes made will not be saved.",
      },
    });
  };

  handleEodModal = () => {
    this.setModal(true, "eod-report", {
      currentUserEmail: this.props.currentUser.email,
      currentUserId: this.props.currentUser.id,
      currentProject: {
        value: this.state.project.id,
        label: this.state.project.name,
        automation: this.state.project.automation,
      },
      customClass: "eod-report-modal",
      backdropClickCheck: {
        title: "Close",
        text: "Close Report Modal?",
      },
    });
    const modalContent = document.querySelector(".new-modal");
    modalContent.focus();
  };

  handleViewEod = (id, projectId, managerOptions) => {
    if (id) {
      this.setModal(true, "view-report", {
        id,
        projectId,
        managerOptions,
        customClass: "view-eod-modal",
      });
    }
  };

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  render() {
    const { hasBugs, hasTestCases, media } = this.state;
    const rowOneVisible = this.state.project.has_bugs;
    let rowOffset;
    let minHeight = 530;
    if (hasBugs) {
      minHeight += 325;
    }
    if (hasTestCases) {
      minHeight += 400;
    }
    if (hasBugs || hasTestCases) {
      minHeight += 390;
    } else {
      minHeight += 440;
    }
    if (media) {
      minHeight += 315;
    }
    if (rowOneVisible) {
      rowOffset = 448;
    } else {
      rowOffset = 108;
    }
    return (
      <div>
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />

        <div
          style={{
            width: "100%",
            maxWidth: `calc(100% - ${100}px)`,
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            minHeight:
              this.props.currentUser.role == "client"
                ? "160vh"
                : `${minHeight}px`,
          }}
        >
          <div style={{ width: "100% ", height: "100%" }} id="graphContainer">
            <SubNavbar
              pageName="Dashboard"
              icon="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Piechart.svg"
              sectionOneChildren={[
                <label
                  style={{
                    flex: "1 0 100%",
                    display: "flex",
                    paddingLeft: "5px",
                    alignSelf: "flex-start",
                  }}
                  key={2}
                >
                  <i>
                    Updated {moment(this.state.project.updated_at).fromNow()}
                  </i>
                </label>,
              ]}
            />
            {(this.props.currentUser.role === "admin" ||
              this.props.currentUser.role === "lead") && (
              <TopButton
                className="btn btn-overflow"
                style={{ right: "225px" }}
                onClick={this.handleReportModal}
              >
                <img
                  aria-hidden
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Generate_Report.svg"
                />
                Client Report
              </TopButton>
            )}
            {this.props.currentUser.role !== "client" && (
              <TopButton
                className="btn btn-overflow"
                style={{ right: "62px" }}
                onClick={this.handleEodModal}
                id="modal-button"
              >
                <img
                  aria-hidden
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Generate_Report.svg"
                />
                EOD Report
              </TopButton>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                filter: `${this.state.loading ? "blur(10px" : ""}`,
              }}
            >
              {hasBugs && (
                <BugsRow
                  bugCount={this.state.bugCount}
                  currentUser={this.props.currentUser}
                  height={340}
                  project={this.state.project}
                />
              )}
              {media && <MediaRow project={this.state.project} />}
              {hasTestCases && (
                <TestPassRow
                  currentUser={this.props.currentUser}
                  project={this.state.project}
                  testPassCount={this.state.testPassCount}
                />
              )}
              {this.props.currentUser.role != "client" && (
                <EodRow
                  hasBugs={hasBugs}
                  hasTestCases={hasTestCases}
                  handleViewEod={this.handleViewEod}
                  handleEodModal={this.handleEodModal}
                  eodReportCount={this.state.eodReportCount}
                  currentUser={this.props.currentUser}
                  height="100%"
                  project={this.state.project}
                />
              )}
              {(hasBugs || hasTestCases) && (
                <ActivityRow
                  activityCount={this.state.activityCount}
                  currentUser={this.props.currentUser}
                  project={this.state.project}
                />
              )}

              <PerformanceRow />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardContainer;

DashboardContainer.propTypes = {
  currentUser: PropTypes.object,
  url: PropTypes.string,
  tickets: PropTypes.array,
  allEvents: PropTypes.array,
  users: PropTypes.array,
  testCycles: PropTypes.array,
  bugCount: PropTypes.number,
  buildCount: PropTypes.number,
  gaugeDataType: PropTypes.string,
  testPassCount: PropTypes.number,
  membership: PropTypes.object,
  project: PropTypes.object,
  ticketDeviceData: PropTypes.array,
  ticketEnvData: PropTypes.array,
  lineData: PropTypes.array,
  lineLabels: PropTypes.array,
  buildData: PropTypes.array,
  projectId: PropTypes.number,
};
