import React from "react";

const priorityColors = ["", "#ff0000", "#ff9900", "#0066cc", "#009900"];

function PriorityCircle({ priority }) {
  const color = priorityColors[priority] || ""; // Set default color to an empty string if priority is invalid

  return (
    <svg
      width="30"
      height="25"
      viewBox="-30 -20 200 255"
      preserveAspectRatio="xMinYMin meet"
    >
      <circle
        cx="100"
        cy="100"
        r="60"
        stroke="#e0e0e0"
        strokeWidth="2"
        fill={color}
      />
    </svg>
  );
}

export default PriorityCircle;
