import React, { useState, useContext, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Select from "react-select";
import ApiClient from "@utils/ApiClient";
import {
  ModalBody,
  ModalContent,
} from "@components/modals/common/modal_styles";
import ModalHeader from "@components/modals/common/modal_header";
import { SubmitButton } from "@components/common/modal_buttons";
import { TrialsContext } from "./index";
import { TimeRemainingDisplay, TimeHeading, TimeValue } from "./TimeRemaining";
import { UserLookupStyle } from "../assessments/InviteUsersForm/UserInvites";

const EmailResultsForm = ({ handleExit }) => {
  const [sendEmailToMyself, setSendEmailToMyself] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  // const { assessment, trial, users } = useContext(TrialsContext);
  const context = useContext(TrialsContext);
  const { assessment, trial, users } = context;

  const userSelectOptions = useMemo(() => {
    if (!users) return [];
    return users.map((user) => ({
      value: user.id,
      label: `${user.name} (${user.email})`,
    }));
  }, [users]);

  const inputRef = useRef(null);

  const handleSubmit = async () => {
    try {
      if (!selectedUser) {
        throw new Error("Please select a user to send the email to.");
      }

      const api = new ApiClient();

      const body = {
        recipient_id: selectedUser.id,
        send_to_user: sendEmailToMyself,
      };

      const response = await api.post(
        `/wiki-assessments/${assessment.id}/trials/${trial.id}/email_results`,
        body
      );
      if (response.status === 200) {
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Email sent successfully.",
          classes: "green darken-1 success-toast",
        });
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        handleExit();
      }
    } catch (error) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: error.message,
        classes: "red darken-1 error-toast",
      });
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleUserSelect = (option) => {
    const user = users.find((u) => u.id === option.value);
    setSelectedUser(user);
    inputRef.current.blur();
  };

  const isSubmitDisabled = () => {
    return !selectedUser?.id;
  };

  if (!trial || !trial.trial_results || !assessment) {
    return null;
  }

  return (
    <ModalBody>
      <ModalHeader
        title="Email Assessment Results"
        subTitle="Send your manager the results from this attempt."
        showHeaderImg
        modalAction={handleExit}
        containerStyle={{
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      />
      <ModalContent>
        <SelectRecipientRow>
          <RecipientLabel>
            Select a manager<span style={{ color: "#519acc" }}>*</span>
          </RecipientLabel>
          <InviteInputWrapper>
            <UserSelect
              ref={inputRef}
              aria-label="User lookup field for sending assessment results"
              placeholder="Search users by name or email"
              classNamePrefix="Select"
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onChange={handleUserSelect}
              isMulti={false}
              loadingMessage={() => "Searching for users..."}
              options={userSelectOptions}
              isSearchable
              menuPortalTarget={document.querySelector("body")}
              noOptionsMessage={() => "No valid users found."}
              styles={{
                placeholder: (base, state) => ({
                  ...base,
                  display: state.isFocused || state.hasValue ? "none" : "block",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#519acc",
                },
              })}
            />
          </InviteInputWrapper>
        </SelectRecipientRow>
        <EmailResultsContainer>
          <ResultsPanel>
            <p>Here&apos;s what will be shared:</p>
            <ResultsTable>
              <thead>
                <tr>
                  <th colSpan="2">
                    <TableCell>
                      <CellHeading>Resource Name</CellHeading>
                      <CellValue
                        style={{ fontSize: "18px", lineHeight: "24px" }}
                      >
                        {assessment.resource.title}
                      </CellValue>
                    </TableCell>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <TableCell>
                      <CellHeading>Assessment Level</CellHeading>
                      <CellValue>{assessment.level}</CellValue>
                    </TableCell>
                  </td>
                  <td>
                    <TableCell>
                      <CellHeading>Assessment Score</CellHeading>
                      <CellValue>
                        {trial.trial_results.correct_string}
                      </CellValue>
                    </TableCell>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TableCell>
                      <CellHeading>Attempt #</CellHeading>
                      <CellValue>
                        {trial.trial_results.attempt_number}
                      </CellValue>
                    </TableCell>
                  </td>
                  <td>
                    <CellHeading>Completion Time</CellHeading>
                    <CellValue>
                      {trial.trial_results.time_elapsed_string}
                    </CellValue>
                  </td>
                </tr>
              </tbody>
            </ResultsTable>
          </ResultsPanel>
          <SubmitRow>
            <CheckControl>
              <Checkbox aria-hidden="true">
                <input
                  aria-hidden="true"
                  onChange={() => setSendEmailToMyself(!sendEmailToMyself)}
                  className="filled-in"
                  checked={sendEmailToMyself}
                  type="checkbox"
                />
                <span aria-hidden="true" className="email-label">
                  Send a copy to me
                </span>
              </Checkbox>
            </CheckControl>
            <SubmitButton
              type="button"
              onClick={handleSubmit}
              disabled={isSubmitDisabled()}
            >
              Share
            </SubmitButton>
          </SubmitRow>
        </EmailResultsContainer>
      </ModalContent>
    </ModalBody>
  );
};

const EmailResultsContainer = styled.div``;

const SelectRecipientRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const labelStyle = css`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  color: #242424;
  font-family: "Manrope";
`;

const RecipientLabel = styled.label`
  ${labelStyle}
  margin-bottom: 6px;
`;

const InviteInputWrapper = styled.div``;

const UserSelect = styled(Select)`
  ${UserLookupStyle}
`;

const ResultsPanel = styled.div`
  display: flex;
  flex-direction: column;

  p {
    ${labelStyle}
    margin-bottom: 6px;
  }
`;

const ResultsTable = styled.table`
  border-spacing: 4px;
  border-collapse: separate;
  margin-left: -4px;

  th,
  td {
    border: 1px solid #d7d7d7;
  }

  td {
    padding: 0;
  }
`;

const TableCell = styled(TimeRemainingDisplay)`
  border: none;
  max-height: 85px;
`;

const CellHeading = styled(TimeHeading)`
  font-size: 14px;
  font-weight: 400;
  color: #242424;
  line-height: 16px;
  font-family: "Arial";
`;

const CellValue = styled(TimeValue)`
  font-weight: 700;
  color: #242424;
  font-size: 24px;
  line-height: 33px;
`;

const SubmitRow = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 4px;
`;

const CheckControl = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 16px;
`;

const Checkbox = styled.label`
  span.email-label {
    white-space: nowrap;
    color: #000000;
    padding-left: 25px;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    font-family: "Manrope";
  }
`;

EmailResultsForm.propTypes = {
  handleExit: PropTypes.func.isRequired,
};

export default EmailResultsForm;
