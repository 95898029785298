import React from 'react'

class LanguagesIcon extends React.Component {
  render() {
    return (
      <svg
        style={{
          ...{transform: this.props.rotate ? this.props.rotate : "",
          transition: "transform .45s"}
        , ...this.props.svgStyles}}
        // height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        className="icons"
        aria-hidden="true"
      >
        <path d="M114.67,4.88H61.58a8.55,8.55,0,0,0-8.44,8.45v35H13.32a8.55,8.55,0,0,0-8.44,8.44V105a8.55,8.55,0,0,0,8.44,8.45h3.62v6a3.77,3.77,0,0,0,3.86,3.62c1,0,1.21-.24,7.48-4.35,1.93-1.44,4.59-3.13,8-5.3H66.41A8.55,8.55,0,0,0,74.85,105V70H91.74L99,74.86c7.24,4.82,7.24,4.82,8.45,4.82a3.71,3.71,0,0,0,3.62-3.62V70h3.62a8.55,8.55,0,0,0,8.44-8.44V13.33A8.55,8.55,0,0,0,114.67,4.88Zm1,56.71a1.13,1.13,0,0,1-1.2,1.2h-7.24a3.71,3.71,0,0,0-3.62,3.62v2.9c-1.69-1.21-2.9-2.17-4.1-2.9-5.31-3.62-5.55-3.62-6.52-3.62H61.58a1.13,1.13,0,0,1-1.2-1.2V13.33a1.14,1.14,0,0,1,1.2-1.21h52.85a1.14,1.14,0,0,1,1.2,1.21Zm-48,43.67a1.21,1.21,0,0,1-1.2,1.21H35c-1.21,0-1.21,0-7,3.86L24.18,113v-2.89a3.32,3.32,0,0,0-1-2.66c-1-1-2.42-1.2-4.11-1.2l-5.57,0a1.41,1.41,0,0,1-1.42-1.42V56.76a1.21,1.21,0,0,1,1.2-1.21H53.14v6A8.47,8.47,0,0,0,61.58,70h6Z"/>
        <path d="M50,89.34,42.76,70a3.21,3.21,0,0,0-2.65-1.93h-.49a2,2,0,0,0-1,.24A2.93,2.93,0,0,0,37,70L29.73,89.34a2.81,2.81,0,0,0,0,2.41,2.4,2.4,0,0,0,1.69,1.69,2.81,2.81,0,0,0,2.41,0,2.38,2.38,0,0,0,1.69-1.69l1-2.9h6.75l1,2.9a3.78,3.78,0,0,0,1.69,1.69,1.8,1.8,0,0,0,1.2.24,11.13,11.13,0,0,1,1.21-.24A3.83,3.83,0,0,0,50,91.75,4.12,4.12,0,0,0,50,89.34Zm-7.72-4.83H37.45L39.87,78Z"/>
        <path d="M98.5,31.42A21.3,21.3,0,0,1,93.19,41.8L96.81,44a3.54,3.54,0,0,1,1.45,4.59c-.48,1-1,1.69-1.93,1.93a3.37,3.37,0,0,1-2.65,0,37.82,37.82,0,0,1-5.8-3.62,27.55,27.55,0,0,1-5.55,3.62c-.24,0-1,.24-1.44.24A4.14,4.14,0,0,1,77.75,49a3.36,3.36,0,0,1-.48-2.65A3.07,3.07,0,0,1,79,44.21L82.58,42c-.49-.72-1.21-1.45-1.69-2.17A3.69,3.69,0,0,1,81.61,35a3.54,3.54,0,0,1,5.07.73c.24.48.72,1,1,1.45a16.28,16.28,0,0,0,2.9-5.31H78.47a3.62,3.62,0,0,1,0-7.24h6V23a3.62,3.62,0,1,1,7.23,0v1.21h6a3,3,0,0,1,2.41,1.2,3.66,3.66,0,0,1,1,2.66A3.61,3.61,0,0,1,98.5,31.42Z"/>
      </svg>
    );
  }
}
LanguagesIcon.defaultProps = {
  color: "#519acc",
  height: "15px",
  svgStyles: {},
  width: "15px",
};
export default LanguagesIcon