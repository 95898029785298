import React from 'react'
import axios from "axios";
import Select, {components} from 'react-select'
import TicketSelectStyles from '../common/ticket_select_styles'
import {assigneeOption, dropdownIndicator, singleValue, assigneeSingleValue, customControl} from '../common/select_utils'
import Swal from "sweetalert2"
class PointAdjustment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      points: 0,
      selectedUser: null,
      adjustmentReason: {value:"event_attended", label:"Event Attended"},
      adjustmentAmount: "200",
      customReason:"",
      loading: false,
      userOptions: this.props.data.users.map((u)=>({value:u.id, label:u.name, points:u.points, image:u.avatar}))
    }
    this.userSelectRef = React.createRef()
  }

  componentDidMount(){
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
    
  }

  handleCancelButton = () => {
    this.props.modalAction(false, "", {})
  }

  handleAddButton=()=>{
    this.setState({points: this.state.points + parseInt(this.state.adjustmentAmount)})
  }

  handleMinusButton=()=>{
    this.setState({
      points: this.state.points - parseInt(this.state.adjustmentAmount) < 0 ? 0 : this.state.points - parseInt(this.state.adjustmentAmount)
    })
  }

  handleSetButton=()=>{
    this.setState({points: parseInt(this.state.adjustmentAmount)})
  }

  handleAdjustmentAmountChange = (e) => {
    if(e.target.value.length < 5){
      if(e.keyCode >= 48 && e.keyCode <= 57){
        if(!e.target.value && e.keyCode === 48){
          e.preventDefault()
        } else {
          this.setState({adjustmentAmount: e.target.value + e.key})
        }
      } else if(e.target.value && e.keyCode=== 8) {
        
        this.setState({adjustmentAmount: e.target.value.slice(0,-1)})
      }
    } else if(e.keyCode=== 8){
      this.setState({adjustmentAmount: e.target.value.slice(0,-1)})
    }
  }

  handleSelectedUser=(selectedUser)=>{
    this.setState({selectedUser, points: selectedUser.points})
  }

  handleSaveChanges=()=>{
    this.setState({loading:true})
    var reason = this.state.adjustmentReason.value === "custom" ? this.state.customReason : this.state.adjustmentReason.label
    axios.patch(`/adjust_points/${this.state.selectedUser.value}?reason=${reason}&points=${this.state.points}`).then((res) => {
      this.props.data.handleUserNewPoints(res.data)
      M.toast({html: `User Points Updated`, displayLength: 3000, classes: 'tess-blue' });
      this.setState({
        selectedUser: null, 
        adjustmentReason:{value:"event_attended", label:"Event Attended"},
        adjustmentAmount:"200",
        customReason:"",
        loading: false
      })
      })
      .catch((err) => {
        M.toast({html: `Error updating points`, displayLength: 3000, classes: 'red' });
        console.log(err)
        this.setState({loading:false})
      })
  }

  handleAdjustmentReason=(adjustmentReason)=>{
    this.setState({adjustmentReason})
  }

  handleCustomReason=(e)=>{
    this.setState({customReason:e.target.value?e.target.value:""})
  }

  render() {
    return (
    <div style={{width:"100%"}}>
      <h2>Adjust Points</h2>
      <div style={{display:"flex", padding:"20px", justifyContent:"flex-start"}}>
        <div style={{position:"relative"}} className="ticket-form-select-container new-ticket-select select-aria-class" >
          <p className="field-title" style={{marginBottom: "5px" }}>User</p>
          <Select
            name="User"
            ref={this.userSelectRef}
            classNamePrefix={"select"}
            placeholder="Select a User"
            blurInputOnSelect={true}
            value={this.state.selectedUser}
            styles={{...TicketSelectStyles, 
              valueContainer: (styles) => ({
                ...styles,
                maxHeight: "unset"
              }),
              container: (styles) => ({
                ...styles,
                width: "200px"
              }),
              singleValue: (styles) => ({
                color: "inherit",
                marginLeft: '2px',
                marginRight: '2px',
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                height: '42px',
                lineHeight: '38px',
              }),
              input: (styles) => ({
                ...styles,
                height:"auto"
              }),
              placeholder: (styles) => ({
                ...styles,
                top:"auto"
              }),
              menu: (styles) => ({
                ...styles,
                width: "300px",
                zIndex: 2,
                backgroundColor: "white",
                right: "-30px"
              })
            }}
            getOptionValue={(option) =>
              option.label.props
                ? `${option.label.props.children[1]} ${option.value}`
                : option.value
            }
            onChange={this.handleSelectedUser}
            instanceId="User"
            options={this.state.userOptions}
            components={{Option: assigneeOption, SingleValue: assigneeSingleValue, DropdownIndicator: dropdownIndicator, Control: customControl}}
            required
          />
          <p style={{marginTop:"10px"}} className="field-title">Adjustment Reason</p>
          <Select
            classNamePrefix={"select"}
            blurInputOnSelect={true}
            value={this.state.adjustmentReason}
            isSearchable={false}
            // onMenuOpen={this.checkScrolling}
            styles={{...TicketSelectStyles, 
              singleValue: (styles) => ({
                ...styles,
                top: "56%"
              })
            }}
            onChange={this.handleAdjustmentReason}
            components={{SingleValue: singleValue, DropdownIndicator: dropdownIndicator, Control: customControl}}
            options={[{value:"event_attended", label:"Event Attended"}, {value:"point_discrepancy", label:"Point Discrepancy"}, {value:"custom", label:"Custom"}]}
            required
          />
          {this.state.adjustmentReason.value === "custom"&&
          <div>
            <input
              type="text"
              className="text-input-v2 browser-default time" 
              tabIndex={0}  
              style={{marginTop:"10px", display:"block", position:"absolute", bottom:"-64px", left:"0px"}} 
              maxLength={30}
              autoFocus={true}
              onChange={this.handleCustomReason}
            />
          </div>
          }
        </div>
        
        <div style={{margin:"0 30px"}}>
          <p className="field-title">Adjustment Amount</p>
          <input
            type="text"
            style={{marginTop:"5px", width:"170px"}}
            className="text-input-v2 browser-default time" 
            tabIndex={0}  
            onKeyDown={this.handleAdjustmentAmountChange}
            maxLength={5}
            value={this.state.adjustmentAmount}
            disabled={!this.state.selectedUser}
          />
          <div style={{display:"flex", alignItems:"center"}}>
            <button style={{width:"50px", fontSize:"30px"}} disabled={!this.state.selectedUser} onClick={this.handleAddButton} className="common-button-submit">+</button>
            <button style={{width:"50px", fontSize:"40px", marginLeft:"10px", paddingBottom:"5px", display:"flex", justifyContent:"center", alignItems:"center"}} disabled={!this.state.selectedUser} onClick={this.handleMinusButton} className="common-button-submit">-</button>
            <button style={{width:"50px"}} disabled={!this.state.selectedUser} onClick={this.handleSetButton} className="common-button-submit">Set</button>
          </div>
        </div>
        {this.state.selectedUser&&
          <div>
            <div>
              <p className="field-title">Starting Points</p>
              <p style={{fontFamily:"Manrope", fontSize:"20px", fontWeight:"700", color:"#519acc"}}>{this.state.selectedUser.points}</p>
            </div>
            <div>
              <p className="field-title">New Points</p>
              <p style={{fontFamily:"Manrope", fontSize:"20px", fontWeight:"700", color:"#519acc"}}>{this.state.points}</p>
            </div>
          </div>
        }
      </div>
      <div style={{width:"100%", display:"flex", justifyContent:"flex-end", padding:"20px"}}>
        <button disabled={!this.state.selectedUser || !this.state.adjustmentAmount || (this.state.adjustmentReason.value==="custom"&&!this.state.customReason)||this.state.loading} style={{marginRight:"10px", height:"42px", width:"130px"}} className="common-button-submit" onClick={this.handleSaveChanges}>Save Changes</button>
        <button style={{height:"42px"}} className="common-button-cancel" onClick={this.handleCancelButton}>Cancel</button>
      </div>
    </div>
    )
  }
}
export default PointAdjustment