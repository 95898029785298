import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-use";
import queryString from "query-string";
import moment from "moment";
import ApiClient from "../../utils/ApiClient";

const usePerformanceDashboardData = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const isDashboardRoute = location.pathname.endsWith("/dashboard");
  const pathnameParts = location.pathname.split("/");
  const projectIdIndex = pathnameParts.indexOf("projects") + 1;
  const projectId = pathnameParts[projectIdIndex];

  const project_id = isDashboardRoute
    ? projectId
    : queryString.parse(location.search).project_id;
  const id = isDashboardRoute ? null : queryString.parse(location.search).id;
  const team_id = isDashboardRoute
    ? null
    : queryString.parse(location.search).team_id;
  const start_date = isDashboardRoute
    ? queryString.parse(location.search).start_date || "2020/09/01"
    : queryString.parse(location.search).start_date;
  const end_date = isDashboardRoute
    ? queryString.parse(location.search).end_date ||
      moment().format("YYYY/MM/DD")
    : queryString.parse(location.search).end_date;

  const dashboardFilterParams = useMemo(() => {
    return {
      project_id,
      id, // user id
      team_id,
      start_date,
      end_date,
    };
  }, [project_id, id, team_id, start_date, end_date]);

  useEffect(() => {
    // fetch dashboard data on initial load and on change to accepted
    // url search params as tracked by memoized dashboardFilterParams
    const api = new ApiClient();

    const fetchDashboardData = async () => {
      try {
        setDashboardLoading(true);
        setError(null);
        await api
          .get(
            `/performance/dashboard.json?${queryString.stringify(
              dashboardFilterParams
            )}`
          )
          .then((res) => {
            const { dashboard_data, query_params } = res.data;
            setDashboardData(dashboard_data);
            setQueryParams(query_params);
          });
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setDashboardLoading(false);
      }
    };

    fetchDashboardData();
  }, [dashboardFilterParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data: {
      dashboardData,
      queryParams,
    },
    dashboardLoading,
    error,
  };
};

export default usePerformanceDashboardData;
