export const validateEmail = (input) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emails = input.split(",").map((email) => email.trim());

  const allValid = emails.every((email) =>
    emailRegex.test(email.toLowerCase())
  );

  return allValid;
};

export default validateEmail;
