import React from "react";

const DeviceLabFilled = (props) => {
  return (
    <svg
      id="device-lab-blue_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>
          {" "}
          {
            ".device-lab-blue_svg__cls-1{fill:#519acc;stroke:#519acc;stroke-miterlimit:10;stroke-width:.5px}"
          }{" "}
        </style>{" "}
      </defs>{" "}
      <circle
        className="device-lab-blue_svg__cls-1"
        cx={38.64}
        cy={40.9}
        r={2.49}
      />{" "}
      <circle
        className="device-lab-blue_svg__cls-1"
        cx={25.7}
        cy={37.92}
        r={1.72}
      />{" "}
      <circle
        className="device-lab-blue_svg__cls-1"
        cx={38.76}
        cy={4.9}
        r={1.72}
      />{" "}
      <circle
        className="device-lab-blue_svg__cls-1"
        cx={33.02}
        cy={36.82}
        r={1.1}
      />{" "}
      <circle
        className="device-lab-blue_svg__cls-1"
        cx={32.08}
        cy={7.24}
        r={1.1}
      />{" "}
      <path
        className="device-lab-blue_svg__cls-1"
        d="M23.1 42.19a1.58 1.58 0 000 3.15h.54a1.57 1.57 0 00.07-3.14z"
      />{" "}
      <path
        className="device-lab-blue_svg__cls-1"
        d="M47.76 46.38a.82.82 0 00-.39-.84.82.82 0 00-1 .12 7.11 7.11 0 01-6.13 1.92 33.27 33.27 0 01-7.63-2.22c-1.35-.5-2.71-1-4-1.43A.75.75 0 0028 44a.81.81 0 00-.41.48.86.86 0 00.06.63.82.82 0 00.48.4c1.31.41 2.66.91 4 1.4a34.43 34.43 0 008 2.31 9.88 9.88 0 004.09-.33l1.57-.49-.66 1.51A14.2 14.2 0 0118 46a15.19 15.19 0 01-.11-1.78 14.25 14.25 0 019.3-13.33.83.83 0 00.54-.77V16.25a.83.83 0 00-.83-.82 1.76 1.76 0 010-3.52h10.33a1.76 1.76 0 110 3.52h-5.16a.82.82 0 000 1.64h4.33v13.06a.82.82 0 00.53.77 14.12 14.12 0 018.95 10.18.84.84 0 001 .62.82.82 0 00.62-1 15.9 15.9 0 00-3.57-7 16.14 16.14 0 00-5.44-4l-.44-.19L38 17l.47-.19a3.4 3.4 0 00-1.28-6.55H26.9a3.4 3.4 0 00-1.3 6.54l.46.2v12.56l-.44.2a15.82 15.82 0 00-9.39 14.47 16.61 16.61 0 00.12 2 15.84 15.84 0 0031.41.16z"
      />{" "}
    </svg>
  );
};

export default DeviceLabFilled;
