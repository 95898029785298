import React from "react";

const EmptyEOD = () => {
  return (
    <svg
      width="61"
      height="63"
      viewBox="0 0 61 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M30.5 62.9996C47.3447 62.9996 61 49.4274 61 32.6853C61 15.9432 47.3447 2.37109 30.5 2.37109C13.6553 2.37109 0 15.9432 0 32.6853C0 49.4274 13.6553 62.9996 30.5 62.9996Z"
            fill="#00100F"
          />
        </g>
      </g>
      <path
        d="M30.5 60.6285C47.3447 60.6285 61 47.0563 61 30.3142C61 13.5721 47.3447 0 30.5 0C13.6553 0 0 13.5721 0 30.3142C0 47.0563 13.6553 60.6285 30.5 60.6285Z"
        fill="#519ACC"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M30.5 0.206055C13.6939 0.206055 0 13.6103 0 30.1079C0 30.5203 0 30.9327 0 31.3452C0.518708 15.157 14.0051 2.26825 30.5 2.26825C46.7874 2.26825 60.2738 14.9507 60.8963 30.9327C60.8963 30.6234 60.8963 30.3141 60.8963 30.0047C61 13.6103 47.3061 0.206055 30.5 0.206055Z"
          fill="white"
        />
      </g>
      <path
        d="M28.6326 14.5381H20.0221V44.8523H28.6326V14.5381Z"
        fill="white"
      />
      <path d="M39.733 26.6025H31.1225V44.9561H39.733V26.6025Z" fill="white" />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 38.2539H6.74319V38.4601H54.3605V38.2539Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 44.75H6.74319V44.9562H54.3605V44.75Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 30.417H6.74319V30.6232H54.3605V30.417Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 23.1992H6.74319V23.4054H54.3605V23.1992Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M54.3605 14.4355H6.74319V14.6418H54.3605V14.4355Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default EmptyEOD;
