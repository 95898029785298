import React from "react";

const Week = (props) => {
  return (
    <svg
      id="Icons_Week_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      {...props}
    >
      {" "}
      <defs>
        {" "}
        <style>{".Icons_Week_svg__cls-1{fill:#414042}"}</style>{" "}
      </defs>{" "}
      <rect
        className="Icons_Week_svg__cls-1"
        x={22.94}
        y={31.89}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_svg__cls-1"
        x={15.27}
        y={31.89}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_svg__cls-1"
        x={31.1}
        y={31.89}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_svg__cls-1"
        x={39.27}
        y={31.89}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
      <rect
        className="Icons_Week_svg__cls-1"
        x={47.45}
        y={31.89}
        width={3.43}
        height={3.43}
        rx={1}
      />{" "}
    </svg>
  );
};

export default Week;
