import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MESSAGE_TYPE_CLASSES = {
  notice: "green darken-2 rounded",
  alert: "red darken-2 rounded",
  error: "red darken-2 rounded",
};

const RailsFlashMessage = ({ flash }) => {
  const messageList = useRef([]);

  useEffect(() => {
    if (flash && flash.length > 0) {
      const incomingMessages = [...flash];
      while (incomingMessages.length > 0) {
        const [message_type, message] = incomingMessages.shift();
        if (!messageList.current.includes(message)) {
          messageList.current.push(message);
          window.M.toast({
            html: message,
            classes: MESSAGE_TYPE_CLASSES[message_type],
          });
        }
      }
    }
  }, [flash]);

  return null;
};

RailsFlashMessage.propTypes = {
  flash: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default RailsFlashMessage;
