import React from 'react'
import axios from 'axios'

class ChangePassword extends React.Component{

  constructor(props) {
    super(props);
    
    this.state = {
      loading: false,
      emailSent: false
    }
    this.headerRef = React.createRef()
  } 

  componentDidMount(){
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    }
    if(this.headerRef.current){
      this.headerRef.current.focus()
    }
  }

  sendEmail = (e) => {
    this.setState({loading: true})
    axios.get(`/users/${this.props.user_id}/password_reset.json`)
    .then((res)=>{
      M.toast({html: `<span aria-live="assertive">Email sent</span>`, displayLength: 3000, classes: 'tess-blue' })
      this.setState({loading: false, emailSent: true})
    })
    .catch((err)=>{
      console.error(err)
      this.setState({loading: false})
    })
  }

  render() {
    return (
      <div style={{height:"calc(100vh - 140px)", display:"flex", flexDirection:"column", justifyContent:"center"}}>
        <div id="password-header" style={{marginTop:"60px"}} >
          <img alt='Test Platform logo' style={{width:"210px"}} id="login_logo" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/bc-images/assets/TPLogo_2021_Color.png" />
          <p style={{outline:"none", fontFamily:"manrope", marginBottom:"20px", fontWeight:"700", marginTop:"10px"}} tabIndex={0} role="heading" className="center">It's time to change your password</p>
        </div>
        <div style={{maxWidth:"438px", margin:"0 auto"}}>
          <p style={{textAlign:"center"}}>We've recently updated password requirements for Test Platform. Click the button below to receive an email for updating your password.</p>
          <p style={{textAlign:"center"}}>Thank you for your cooperation.</p>
          <button onClick={this.sendEmail} style={{width:"230px", display:"block", margin:"30px auto 0"}} className='common-button-submit'>{this.state.emailSent?"Resend":"Send"} Password Reset Email</button>
        </div>
      </div>    
    );
  }
}

export default ChangePassword