import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import EmailIcon from "../../icons/performance_bio/Email";
import PronounIcon from "../../icons/performance_bio/Pronoun";
import CalenderIcon from "../../icons/performance_bio/Calender";
import TeamIcon from "../../icons/performance_bio/Team";
import LocationIcon from "../../icons/performance_bio/Location";
import BioDisplay from "./BioDisplay";
import InfoDisplay from "./InfoDisplay";
import TitleDisplay from "./TitleDisplay";

import ProfileContext from "../ProfileContext";

const ProfileUserInfo = () => {
  const { user, assessment_performance } = useContext(ProfileContext);

  const metExpertiseCategories = useMemo(() => {
    return assessment_performance
      ?.filter((category) => category.percentage === 1)
      .map((category) => category.category);
  }, [assessment_performance]);

  if (!user) {
    return <div>Loading...</div>;
  }

  const userDataGrid = [
    {
      label: "Start Date",
      value: user.employee_started_at
        ? moment(user.employee_started_at).format("YYYY-MM-DD")
        : "N/A",
      icon: CalenderIcon,
    },
    { label: "Email Address", value: user.email || "N/A", icon: EmailIcon },
    { label: "Pronouns", value: user.pronouns || "N/A", icon: PronounIcon },
    { label: "Location", value: user.location || "N/A", icon: LocationIcon },
  ];

  const teamNames = user.teams ? user.teams.join(", ") : "N/A";

  const colorMapping = {
    web_testing: "#1C712E",
    mobile_app_testing: "#6c522c",
    test_automation: "#CF6402",
    accessibility: "#6C0AE2",
    project_lead: "#ce45b0",
    team_manager: "#557de3",
  };

  const expertiseTags = metExpertiseCategories.map((area) => ({
    color: colorMapping[area] || "#ddd",
    label: area
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
  }));

  return (
    <Container>
      <ProfileContainer>
        <UserCard>
          <ColorBar role={user.role || "tester"} />
          <UserInfo>
            <Avatar
              src={
                user.avatar
                  ? user.avatar
                  : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/skills/Icons_User%20Default.svg"
              }
              alt="User Avatar"
            />
            <UserName>{user.name}</UserName>
            <TitleDisplay user={user} />
          </UserInfo>
        </UserCard>
        <UserDataWrapper>
          <UserDataContainer>
            {userDataGrid.map((data) => (
              <UserDataItem key={data.label}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {data.icon && <IconWrapper>{data.icon()}</IconWrapper>}
                  <DataLabel>{data.label}</DataLabel>
                </div>
                <DataValue>{data.value}</DataValue>
              </UserDataItem>
            ))}
          </UserDataContainer>
          <UserDataItemEmail>
            <div style={{ display: "flex", alignItems: "center" }}>
              {TeamIcon && <IconWrapper>{TeamIcon()}</IconWrapper>}
              <DataLabel>Team Name</DataLabel>
            </div>
            <DataValueEmail>{teamNames}</DataValueEmail>
          </UserDataItemEmail>
        </UserDataWrapper>
      </ProfileContainer>
      <BioWrapper>
        <BioDisplay user={user} />
        <ExpertiseContainer>
          <ExpertiseTitle>Areas of Expertise</ExpertiseTitle>
          <TagContainer>
            {expertiseTags &&
              expertiseTags.map((tag) => (
                <ExpertiseLabel key={tag.label} color={tag.color}>
                  {tag.label}
                </ExpertiseLabel>
              ))}
          </TagContainer>
        </ExpertiseContainer>
        <InterestsContainer>
          <InfoDisplay user={user} />
        </InterestsContainer>
      </BioWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 240px;
  border-bottom: 1px solid #d7d7d7;
`;

const UserCard = styled.div`
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-right: 1px solid #d7d7d7;
`;

const ColorBar = styled.div`
  background-image: ${(props) => {
    const IMG = {
      tester:
        "url('https://tp-assets.sfo2.cdn.digitaloceanspaces.com/performance/USER_TESTER_BG.png')",
      lead: "url('https://tp-assets.sfo2.cdn.digitaloceanspaces.com/performance/USER_MANAGER_BG.png')",
      admin:
        "url('https://tp-assets.sfo2.cdn.digitaloceanspaces.com/performance/USER_ADMIN_BG.png')",
    };

    return IMG[props.role] || IMG.tester;
  }};
  background-size: cover;
  height: 94px;
  border-top-left-radius: 4px;
`;

const UserInfo = styled.div`
  height: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Avatar = styled.img`
  width: 108px;
  height: 108px;
  border-radius: 50%;
  position: relative;
  bottom: 75px;
`;

const UserName = styled.h3`
  margin: 0;
  font-size: 18px;
  position: relative;
  bottom: 70px;
  font-weight: 700;
  color: #242424;
  font-family: manrope;
`;

const UserDataContainer = styled.div`
  overflow-x: none;
  overflow-y: none;
  padding: 29px 15px 5px 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 10px;
  max-width: 200px;
`;

const UserDataItem = styled.div`
  width: 171px !important;
`;

const UserDataItemEmail = styled.div`
  padding-left: 30px;
`;

const DataLabel = styled.p`
  font-size: 14px;
  font-weight: 100;
  color: #666;
  white-space: nowrap;
`;

const IconWrapper = styled.span`
  margin-right: 5px;
  display: flex;
`;

const DataValue = styled.p`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 30ch;
  margin-top: -5px;
`;

const DataValueEmail = styled.p`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: -5px;
  max-height: 65px;
`;

const UserDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const BioWrapper = styled.div`
  padding: 22px 31px;
  width: 100%;
`;

const ExpertiseContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const TagContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const ExpertiseTitle = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  color: #666;
  margin-bottom: 5px;
`;

const ExpertiseLabel = styled.span`
  padding: 6px 18px;
  border-radius: 20px;
  background: ${(props) => props.color};
  color: white;
  margin-right: 5px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const InterestsContainer = styled.div`
  margin-top: 20px;
`;

ProfileUserInfo.propTypes = {
  user: PropTypes.shape({
    about: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
    employee_started_at: PropTypes.string,
    id: PropTypes.number,
    location: PropTypes.string,
    name: PropTypes.string,
    pronouns: PropTypes.string,
    role: PropTypes.string,
    teams: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    user_interests: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, id: PropTypes.number })
    ),
    user_skills: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, id: PropTypes.number })
    ),
  }),
};

export default ProfileUserInfo;
