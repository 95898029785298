import React, { useState } from "react";

function AddMedia(props) {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState([]);

 
  function submit(e) {
    e.preventDefault();
    var project = new FormData();
    project.append("media_item[owner_id]", props.data.currentUser.id)
    if (file) {
      if (file.length > 3) {
        M.toast({
          html: "Please limit selection to 3 files at a time",
          classes: "red darken-1 error-toast",
        }); 
      } else {
        for (let index = 0; index < file.length; index++) {
          const element = file[index];
          project.append("media_item[image]", element)
          handleSubmit(project)
        }
      }
    }
  }


  function handleSubmit(data) {
    props.setBackdropLoader(true)
    $.ajax({
     url: `/projects/${props.data.project.id}/media_items`,
     enctype: 'multipart/form-data',
     type: "POST",
     data: data,
     processData: false,
     contentType: false,
     cache: false,
     beforeSend: function (xhr) {
       xhr.setRequestHeader(
         "X-CSRF-Token",
         $('meta[name="csrf-token"]').attr("content")
       );
     },
     success: function (res) {
       M.toast({html: `Media Added`, displayLength: 5000, classes: 'green' })
       props.data.mediaHandler(res, "add")
       props.setBackdropLoader(false)
       props.modalAction(false, "", {})
     },
     error: function (data) {
       // If error present error swal message
       M.toast({html: `Error Adding Media`, displayLength: 5000, classes: 'red' })
       props.setBackdropLoader(false)
     },
   });
  }

  function handleFile(e) {
    var file = e.target.files
    setFile(file)
  }

  function handleFileName(e) {
    setFileName(e.target.files[0])
  }

  return (
    <div>
      <div>
        <form
          style={{ marginTop: "20px", flexWrap: "wrap" }}
          className="flex-container flex-row flex-al-center"
          onSubmit={submit}
        >
          <div
            style={{ width: "100%" }}
            className="flex-column flex-al-center flex-space-between flex-container"
          >
            <div className="input-field">
             <input 
              style={{ border: "1px solid #519acc", borderRadius: "5px", padding: "20px"}}
              type="file"
              multiple
              onChange={handleFile}
             />
            </div>
            <div className="flex-container" style={{alignItems:"center" }}>
            <button
              type="button"
              disabled={file.length < 1}
              onClick={submit}
              className="common-button-submit"
            >
              Save Media
            </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddMedia;
