import React from 'react'

class NewBadge extends React.Component {

  constructor(props) {
    super(props);
  }

  handleCancelButton = () => {
    this.props.modalAction(false, "", {})
  }

  render() {
    return (
    <div style={{position:"relative", textAlign:"center"}}>
      <img alt="new badge" style={{height:"400px", width:"400px", position:"relative", display:"inline-block", top:"-20px"}} src={this.props.data.imageLink} />
      <div style={{position:"relative", marginTop:"-65px"}}>
        <span style={{display:"block", color:"#519acc", fontSize:"11pt"}}>YOU&apos;VE EARNED A BADGE</span>
        <span style={{display:"block", fontSize:"16pt", fontWeight:"600", color:"#519acc", margin:"8px 0px 12px"}}>{this.props.data.badgeName}</span>
        <span style={{display:"block", fontSize:"11pt", margin:"12px 0px 25px"}}>{this.props.data.badgeDetails}</span>
        <a style={{width:"160px", marginBottom:"25px", lineHeight:"37px"}} className="common-button-submit" href="/account">View Badges Earned</a>
      </div>
      <button style={{position:"absolute", top:"20px", right:"25px"}} onClick={this.handleCancelButton} aria-label="close-modal" className="icon-close-btn" ></button>
    </div>
    )
  }
}
export default NewBadge